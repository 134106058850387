import React from 'react';
import { useHistory, useParams } from 'react-router-dom';
import * as R from 'ramda';
import { debounce } from 'lodash';

import { GoogleLogin } from 'react-google-login';
import { useSnackbar } from 'notistack';

import { useAuth, useUncontrolledField, useRegisterLimitUsed } from '../../hooks';

import {
  handleFormValidation,
  listProfileIds,
  getFieldsActions,
  getUserInfosValues,
  setFieldsToDefault,
} from './helper';

import {
  IconButton,
  Loading,
  GroupItems,
  OverlayLoading,
  HeaderToolbar,
  HealderToolbarAction,
} from '../../components';

import {
  fieldUserName,
  fieldUserEmail,
  fieldUserPhone,
  fieldUserPassword,
  fieldUserGoogleRefreshToken,
  fieldUserCompanyLimit,
  fieldUserCompanyGroupLimit,
  fieldUserLocationLimit,
  fieldUserLocationGroupLimit,
} from './field-schemes';

import {
  StyledButton,
  StyledTitle,
  StyledFields,
  StyledFieldsWrapper,
  StyledCreateUser,
  StyledRowUserInfoFieldsWrapper,
  StyledRowLimitFieldsWrapper,
  StyledGroupItemsWrapper,
  StyledGoogleTokenWrapper,
  StyledLimitWrapper,
  StyledLimitValue,
  StyledPasswordWrapper,
} from './update-user-styles';

import getUser from '../../services/users/getUser';
import getProfiles from '../../services/profiles/getProfiles';
import updateUserProfile from '../../services/users/updateUserProfile';
import changePassword from '../../services/users/changePassword';
import addProfiles from '../../services/users/addProfiles';
import removeProfiles from '../../services/users/removeProfiles';
import generateRefreshToken from '../../services/google/generateRefreshToken';
import getUserConnection from '../../services/connections/getUserConnections';
import updateConnection from '../../services/connections/updateConnection';
import refreshGoogleAccessToken from '../../services/google/refreshGoogleAccessToken';

const UpdateUser = () => {
  const params = useParams();
  const history = useHistory();

  const { enqueueSnackbar } = useSnackbar();

  const [loading, setLoading] = React.useState(false);
  const [overlayLoading, setOVerlayLoading] = React.useState(false);

  const [profilesToAdd, setProfilesToAdd] = React.useState([]);
  const [profilesToAddPage, setProfilesToAddPage] = React.useState(0);
  const [profilesToAddPageQuantity] = React.useState(0);
  const [profilesToAddQuery, setProfilesToAddQuery] = React.useState('');

  const [addedProfiles, setAddedProfiles] = React.useState([]);
  const [addedProfilesPageQuantity] = React.useState(0);
  const [addedProfilesPageQuery, setAddedProfilesPageQuery] = React.useState('');

  const [isLoadingRefreshToken, setIsLoadingRefreshToken] = React.useState(false);
  const [willNotUpdatePassword, setWillNotUpdatePassword] = React.useState(true);

  const [googleAccessToken, setGoogleAccessToken] = React.useState(null);
  const [googleRefreshToken, setGoogleRefreshToken] = React.useState([]);
  const [tokenExpiresIn, setTokenExpiresIn] = React.useState(null);
  const [googleAuthCode, setGoogleAuthCode] = React.useState(null);

  const [userInfos, setUserInfos] = React.useState(null);

  const { id } = params;

  const [fields, setFields] = React.useState([]);

  const handleChangeAddedProfileSearch = debounce(text => setAddedProfilesPageQuery(text), 500);

  const {
    userAccessToken,
    userSetTokenLikeExpired,
  } = useAuth();

  const {
    locationLimitUsed,
    locationGroupLimitUsed,
    companyLimitUsed,
    companyGroupLimitUsed,
  } = useRegisterLimitUsed(id);

  const { Field: Name } = useUncontrolledField({
    fieldScheme: fieldUserName,
    saveIn: setFields,
  });

  const { Field: Email } = useUncontrolledField({
    fieldScheme: fieldUserEmail,
    saveIn: setFields,
  });

  const { Field: Phone } = useUncontrolledField({
    fieldScheme: fieldUserPhone,
    saveIn: setFields,
  });

  const { Field: Password } = useUncontrolledField({
    fieldScheme: fieldUserPassword,
    saveIn: setFields,
  });

  const { Field: GoogleRefreshToken } = useUncontrolledField({
    fieldScheme: fieldUserGoogleRefreshToken,
    saveIn: setGoogleRefreshToken,
  });

  const { Field: CompanyLimit } = useUncontrolledField({
    fieldScheme: fieldUserCompanyLimit,
    saveIn: setFields,
  });

  const { Field: CompanyGroupLimit } = useUncontrolledField({
    fieldScheme: fieldUserCompanyGroupLimit,
    saveIn: setFields,
  });

  const { Field: LocationLimit } = useUncontrolledField({
    fieldScheme: fieldUserLocationLimit,
    saveIn: setFields,
  });

  const { Field: LocationGroupLimit } = useUncontrolledField({
    fieldScheme: fieldUserLocationGroupLimit,
    saveIn: setFields,
  });

  const getAllProfiles = React.useCallback(async () => {
    const allProfilesDataResponse = await getProfiles({
      accessToken: userAccessToken,
      setTokenLikeExpired: userSetTokenLikeExpired,
      query: profilesToAddQuery,
      setIsFetching: setOVerlayLoading,
    });

    if (R.isNil(allProfilesDataResponse)) return;

    const [allProfilesData] = allProfilesDataResponse;

    setProfilesToAdd(allProfilesData);
  }, [
    profilesToAddQuery,
  ]);

  React.useEffect(() => { getAllProfiles(); }, [getAllProfiles]);

  const getUserInfos = React.useCallback(async () => {
    const userInfosResponse = await getUser({
      accessToken: userAccessToken,
      id,
      setTokenLikeExpired: userSetTokenLikeExpired,
    });

    if (R.isNil(userInfosResponse)) return;

    setUserInfos(userInfosResponse);
  }, []);

  React.useEffect(() => { getUserInfos(); }, [getUserInfos]);

  const setUserFields = React.useCallback(async () => {
    if (R.isEmpty(fields)) return;
    if (R.isNil(userInfos)) return;

    const {
      userCompanyGroupLimit,
      userCompanyLimit,
      userEmail,
      userGoogleRefreshToken,
      userLocationGroupLimit,
      userLocationLimit,
      userName,
      userPhone,
      userProfiles,
    } = getUserInfosValues(userInfos);

    const {
      setFieldUserCompanyGroupLimit,
      setFieldUserCompanyLimit,
      setFieldUserEmail,
      setFieldUserLocationGroupLimit,
      setFieldUserLocationLimit,
      setFieldUserName,
      setFieldUserPhone,
    } = getFieldsActions(fields);

    const { setFieldUserRefreshToken } = getFieldsActions(googleRefreshToken);

    setFieldUserName(userName);
    setFieldUserPhone(userPhone);
    setFieldUserEmail(userEmail);
    setFieldUserCompanyGroupLimit(userCompanyGroupLimit);
    setFieldUserCompanyLimit(userCompanyLimit);
    setFieldUserLocationGroupLimit(userLocationGroupLimit);
    setFieldUserRefreshToken(userGoogleRefreshToken);
    setFieldUserLocationLimit(userLocationLimit);

    setAddedProfiles(userProfiles);
  }, [userInfos, fields]);

  React.useEffect(() => { setUserFields(); }, [setUserFields]);

  const redirectToList = () => history.push('/users/list');

  const checkItemAlreadyInGroup = (item) => {
    const foundProfile = addedProfiles.filter((profile) => profile.id === item.id);

    return foundProfile.length > 0;
  };

  const setCurrentValuesInFieldsToDefault = () => {
    fields.map(field => field.setDefaultValue(field.getValue()));
  };

  const googleSuccessResponse = async (response) => {
    const { setFieldUserRefreshToken } = getFieldsActions(googleRefreshToken);

    const {
      refresh_token: googleRefreshTokenResponse,
      access_token: generatedAccessToken,
      expires_in: expiresIn,
    } = await generateRefreshToken({
      authorizationCode: response.code,
      isLoading: setIsLoadingRefreshToken,
    });

    setGoogleAccessToken(generatedAccessToken);
    setFieldUserRefreshToken(googleRefreshTokenResponse);
    setTokenExpiresIn(expiresIn);
    setGoogleAuthCode(response.code);
  };

  const handleOpenGooglePopup = (renderProps) => {
    setCurrentValuesInFieldsToDefault();

    renderProps.onClick();
  };

  const handleUpdateConnection = async (
    userId,
    refreshToken,
    code,
    googleGeneratedAccessToken,
    googleTokenExpiresIn,
  ) => {
    const [userGoogleConnection, amount] = await getUserConnection({
      accessToken: userAccessToken,
      userId,
      query: 'google',
    });

    const tokenExpirationDate = new Date();
    tokenExpirationDate.setSeconds(tokenExpirationDate.getSeconds() + googleTokenExpiresIn);

    const inputData = {
      code,
      refresh_token: refreshToken,
      token: googleGeneratedAccessToken,
      token_expires_in: tokenExpirationDate.toUTCString(),
    };

    let updateData;

    if (amount > 0) {
      const connectionId = userGoogleConnection[0].id;
      updateData = await updateConnection({
        accessToken: userAccessToken,
        connectionId,
        inputData,
      });
    }

    return updateData;
  };

  const handleProfileToAddClicked = (profile) => {
    setCurrentValuesInFieldsToDefault();

    if (!checkItemAlreadyInGroup(profile)) {
      setAddedProfiles([...addedProfiles, profile]);
      return;
    }

    enqueueSnackbar('Este perfil já foi adicionado', { variant: 'warning' });
  };

  const handleAddedProfileClicked = (profile) => {
    setCurrentValuesInFieldsToDefault();

    setAddedProfiles(addedProfiles.filter((item) => profile.id !== item.id));

    if (!R.isNil(profilesToAdd.find(removedProfile => removedProfile.id === profile.id))) return;

    setProfilesToAdd([...profilesToAdd, profile]);
  };

  const handleSubmitUpdateUser = async () => {
    setLoading(true);

    setFieldsToDefault(fields);

    const validatedFields = handleFormValidation({
      fields,
      getFieldsActions,
      addedProfiles,
      googleRefreshToken: googleRefreshToken[0].getValue(),
      enqueueSnackbar,
      willNotUpdatePassword,
    });

    if (validatedFields) {
      setLoading(false);
      return;
    }

    const { id: paramId } = params;

    const {
      getFieldUserName,
      getFieldUserEmail,
      getFieldUserPhone,
      getFieldUserPassword,
      getFieldUserCompanyGroupLimit,
      getFieldUserCompanyLimit,
      getFieldUserLocationGroupLimit,
      getFieldUserLocationLimit,
    } = getFieldsActions(fields);

    const {
      getFieldUserRefreshToken,
    } = getFieldsActions(googleRefreshToken);

    if (
      getFieldUserCompanyLimit < companyLimitUsed
      || getFieldUserCompanyGroupLimit < companyGroupLimitUsed
      || getFieldUserLocationLimit < locationLimitUsed
      || getFieldUserLocationGroupLimit < locationGroupLimitUsed
    ) {
      setLoading(false);
      enqueueSnackbar('Os limites de cadastro não podem ser inferiores aos já utilizados', { variant: 'warning' });
    }

    setOVerlayLoading(true);

    const updateUserResponse = await updateUserProfile({
      accessToken: userAccessToken,
      id: paramId,
      updateProfileData: {
        name: getFieldUserName,
        email: getFieldUserEmail,
        google_refresh_token: getFieldUserRefreshToken,
        phone: getFieldUserPhone,
        company_limit: getFieldUserCompanyLimit,
        company_group_limit: getFieldUserCompanyGroupLimit,
        location_limit: getFieldUserLocationLimit,
        location_group_limit: getFieldUserLocationGroupLimit,
      },
      setTokenLikeExpired: userSetTokenLikeExpired,
    });

    if (R.isNil(updateUserResponse)) {
      setLoading(false);
      setOVerlayLoading(false);
      enqueueSnackbar('Não foi possível atualizar o usuário, tente novamente!', { variant: 'error' });
      return;
    }

    if (!willNotUpdatePassword) {
      await changePassword({
        accessToken: userAccessToken,
        id: paramId,
        password: getFieldUserPassword,
        setTokenLikeExpired: userSetTokenLikeExpired,
      });
    }

    if (!R.isEmpty(userInfos.profiles)) {
      const previousProfileIds = listProfileIds(userInfos.profiles);

      await removeProfiles({
        accessToken: userAccessToken,
        userId: paramId,
        profile_ids: previousProfileIds,
        setTokenLikeExpired: userSetTokenLikeExpired,
      });
    }

    if (!R.isEmpty(addedProfiles)) {
      const addedProfileIds = listProfileIds(addedProfiles);

      await addProfiles({
        accessToken: userAccessToken,
        userId: id,
        profile_ids: addedProfileIds,
        setTokenLikeExpired: userSetTokenLikeExpired,
      });
    }

    await handleUpdateConnection(
      id,
      getFieldUserRefreshToken,
      googleAuthCode,
      googleAccessToken,
      tokenExpiresIn,
    );

    enqueueSnackbar('Usuário alterado com sucesso', { variant: 'success' });
    setLoading(false);
    setOVerlayLoading(false);

    setTimeout(() => {
      redirectToList();
    }, 200);
  };

  const handleWillChangePassword = () => {
    fields.map(field => {
      if (field.name === 'field-user-password') {
        field.setIsDisabled(!willNotUpdatePassword);
      }
    });

    setWillNotUpdatePassword(!willNotUpdatePassword);
  };

  return (
    <StyledCreateUser>
      {overlayLoading && <OverlayLoading fullScreen textToLoading="Carregando dados..." />}

      <HeaderToolbar
        title="Editar Usuário"
      >
        <HealderToolbarAction
          title="Listar Usuários"
          icon="List"
          onClick={redirectToList}
        />
      </HeaderToolbar>

      <StyledFieldsWrapper>
        <StyledTitle>Informações Pessoais</StyledTitle>
        <StyledFields>
          <StyledRowUserInfoFieldsWrapper>
            <Name />
            <Email />
            <Phone />
          </StyledRowUserInfoFieldsWrapper>
        </StyledFields>

        <StyledPasswordWrapper>
          <StyledTitle>Mudar Senha?</StyledTitle>
          {!willNotUpdatePassword && (
            <IconButton
              icon="Close"
              onClick={handleWillChangePassword}
              tooltip="Cancelar"
            />
          )}

          {willNotUpdatePassword && (
            <IconButton
              icon="Create"
              onClick={handleWillChangePassword}
              tooltip="Alterar"
            />
          )}
        </StyledPasswordWrapper>

        <StyledFields>
          <Password />
        </StyledFields>

        <StyledTitle>Google Refresh Token</StyledTitle>

        <StyledFields>
          <StyledGoogleTokenWrapper>
            <GoogleRefreshToken />
            <GoogleLogin
              clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}
              onSuccess={googleSuccessResponse}
              onFailure={() => {}}
              scope="https://www.googleapis.com/auth/business.manage"
              prompt="consent"
              responseType="code"
              uxMode="redirect"
              redirectUri="https://saas.hublocal.com.br/receive-foursquare-code"
              render={(renderProps) => (isLoadingRefreshToken ? <Loading /> : (
                <IconButton
                  className="borderButton"
                  tooltip="Conectar com o Google"
                  icon="VpnKey"
                  placement="bottom"
                  onClick={() => handleOpenGooglePopup(renderProps)}
                />
              ))}
            />
          </StyledGoogleTokenWrapper>
        </StyledFields>

        <StyledTitle>Limites de Registro</StyledTitle>

        <StyledFields>
          <StyledRowLimitFieldsWrapper>
            <StyledLimitWrapper>
              <CompanyLimit />
              <StyledLimitValue>{`Utilizado: ${companyLimitUsed}`}</StyledLimitValue>
            </StyledLimitWrapper>

            <StyledLimitWrapper>
              <CompanyGroupLimit />
              <StyledLimitValue>{`Utilizado: ${companyGroupLimitUsed}`}</StyledLimitValue>
            </StyledLimitWrapper>
          </StyledRowLimitFieldsWrapper>

          <StyledRowLimitFieldsWrapper>
            <StyledLimitWrapper>
              <LocationLimit />
              <StyledLimitValue>{`Utilizado: ${locationLimitUsed}`}</StyledLimitValue>
            </StyledLimitWrapper>

            <StyledLimitWrapper>
              <LocationGroupLimit />
              <StyledLimitValue>{`Utilizado: ${locationGroupLimitUsed}`}</StyledLimitValue>
            </StyledLimitWrapper>
          </StyledRowLimitFieldsWrapper>
        </StyledFields>

        <StyledGroupItemsWrapper>
          <GroupItems
            mainTitle="Perfil de Acesso"
            itemsToAdd={profilesToAdd}
            addedItens={addedProfiles.filter((profile) => profile.name.toLowerCase().includes(addedProfilesPageQuery.toLowerCase()))}
            itemsToAddPagesQuantity={profilesToAddPageQuantity}
            addedItemsPagesQuantity={addedProfilesPageQuantity}
            primaryTextKey="name"
            onItemToAddClicked={handleProfileToAddClicked}
            onAddedItemClicked={handleAddedProfileClicked}
            setQueryItensToAddPage={setProfilesToAddQuery}
            onAddedItemsInputChanged={({ target: { value } }) => handleChangeAddedProfileSearch(value)}
            setItensToAddPage={setProfilesToAddPage}
            currentItensToAddPage={profilesToAddPage}
            inputLabelToAdd="Perfis Disponíveis"
            inputLabelAdded="Pesquisar Adicionados"
            buttonTooltipAddTitle="Adicionar Perfil"
            buttonTooltipRemoveTitle="Remover Perfil"
            emptyItemsToAdd="Nenhum perfil a adicionar"
            emptyAddedItems="Nenhum perfil adicionado"
            isCreation
          />

          <StyledButton
            onClick={handleSubmitUpdateUser}
            disabled={loading}
          >
            {loading && <Loading className="loading" />}
            {' '}
            Editar Usuário
          </StyledButton>
        </StyledGroupItemsWrapper>
      </StyledFieldsWrapper>
    </StyledCreateUser>
  );
};

export default UpdateUser;
