import axios from 'axios';

export default async function getGoogleCategories({ accessToken, searchTerm }) {
  try {
    const { data } = await axios.get(
      `${process.env.REACT_APP_GOOGLE_API_URL}/get/all/categories?search_term=${searchTerm}`,
      {
        headers: {
          'access-token': accessToken,
        },
      },
    );

    return data;
  } catch (err) {
    return err.response;
  }
}
