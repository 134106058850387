import axios from 'axios';

import type {
  TGetLocalPostsByGroupType,
  TGetLocalPostsByGroupTypeReturn,
} from '../../types/TBatchGoogleModules';

export const getLocalPostsByGroupType = async ({
  groupType,
  groupTypeId,
  page = 0,
  pageSize = 10,
  query = '',
  status,
  postType,
  ofPeriod = '',
  toPeriod = '',
  userAccessToken,
}: TGetLocalPostsByGroupType): Promise<TGetLocalPostsByGroupTypeReturn> => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_SAAS_BATCH_GOOGLE_MODULES}/local-posts/find-all-by-local-posts/${groupType}/${groupTypeId}?page=${page}&pageSize=${pageSize}&query=${query}&status=${status}&postType=${postType}&ofPeriod=${ofPeriod}&toPeriod=${toPeriod}`,
      {
        headers: {
          Authorization: `Bearer ${userAccessToken}`,
        },
      },
    );

    return response.data;
  } catch (err) {
    return null;
  }
};
