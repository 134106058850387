import React from 'react';
import { useSelector } from 'react-redux';
import * as R from 'ramda';
import { useSnackbar } from 'notistack';

import createTicket from '../../../../services/tickets/create';
import putObject from '../../../../services/aws/putObject';
import getValidGoogleAccessTokenFromLocation from '../../../../services/getValidGoogleAccessTokenFromLocation';
import APICreateGooglePost from '../../../../services/google/post/createPost';

import DragAndDropFiles from '../../../../components/drag-and-drop-file';
import patchNewsPost from '../../../../services/google/post/patchPost';

import type {
  TObjectInAWSS3BucketReturn,
  TLocationPostAddButtonType,
  TPostFormNewsBaseProps,
} from '../../../../types/TLocationPost';

import type { TRootStateRedux } from '../../../../types/TRootStateRedux';

import { useAuth, useLocationTickets } from '../../../../hooks';

import APIUpdateTicket from '../../../../services/tickets/update';

import { websiteValidate } from '../../../../utils/fields-validate';

import {
  getActionType,
  locationPostAddButtonsType,
  getActionTypeReverse,
  imagePostValidation,
} from '../../helpers';

import {
  handleFormHasManyImagesOrVideo,
  isVideoFiles,
} from '../helpers';

import {
  Textarea,
  Select,
  Input,
  Button,
  Loading,
} from '../../../../components';

import {
  StyledPostFormNews,
  StyledAlertMessage,
} from './post-form-news-styles';

const PostFormNews = ({ closeModal, dataToUpdate }: TPostFormNewsBaseProps) => {
  const { enqueueSnackbar } = useSnackbar();

  const [newsStatusText, setNewsStatusText] = React.useState('');
  const [addedButtonType, setAddedButtonType] = React.useState('Nenhum');
  const [addedButtonTypeValue, setAddedButtonTypeValue] = React.useState<string>('');
  const [isPublish, setIsPublish] = React.useState(false);
  const [isAddedButton, setIsAddedButton] = React.useState(false);
  const [isAddedButtonPhoneType, setIsAddedButtonPhoneType] = React.useState(false);
  const [filesToPost, setFilesToPost] = React.useState<any[]>([]);
  const [postNameId, setPostNameId] = React.useState<number | null>(null);
  const [googleAccessToken, setGoogleAccessToken] = React.useState<string | null>(null);
  const [googleAccountId, setGoogleAccountId] = React.useState<string | null>(null);
  const [googleLocationId, setGoogleLocationId] = React.useState<string | null>(null);

  const { activeLocation } = useSelector((state: TRootStateRedux) => state.LocationReducer);

  const {
    userAccessToken,
    userId,
    userProfile,
    userSetTokenLikeExpired,
  } = useAuth();

  const {
    locationPostTickets,
    locationPostOpenTickets,
  } = useLocationTickets();

  const getValidGoogleAccessToken = React.useCallback(async () => {
    const getValidGoogleAccessTokenResponse = await getValidGoogleAccessTokenFromLocation({
      accessToken: userAccessToken,
      userId,
      userProfile,
      locationId: activeLocation.id,
    });

    if (R.isNil(getValidGoogleAccessTokenResponse)) return;

    const { googleAccessToken: accessTokenGoogle, connectionInfo } = getValidGoogleAccessTokenResponse;

    setGoogleAccessToken(accessTokenGoogle);
    setGoogleAccountId(connectionInfo.google_account_id);
    setGoogleLocationId(connectionInfo.google_location_id);
  }, []);

  React.useEffect(() => { getValidGoogleAccessToken(); }, [getValidGoogleAccessToken]);

  const getObjectInAWSS3Bucket = React.useCallback(
    async (element: any): Promise<TObjectInAWSS3BucketReturn> => {
      if (R.isNil(googleAccountId) || R.isNil(googleLocationId)) return null;

      if (!R.isEmpty(element)) {
        const putObjectData = await putObject({
          accessToken: userAccessToken,
          bucketName: 'hub-saas-media',
          file: element,
          googleAccountId,
          googleLocationId,
          isCreating: setIsPublish,
          mediaType: element.type === 'video/mp4' ? 'VIDEO' : 'PHOTO',
        });

        const mediaFileUrl = await putObjectData.fileUrl;

        return {
          mediaFormat: element.type === 'video/mp4' ? 'VIDEO' : 'PHOTO',
          mediaSourceUrl: mediaFileUrl,
        };
      }

      return null;
    }, [googleAccountId, googleLocationId],
  );

  const isValidInput = React.useCallback(() => {
    const actionType = getActionType(addedButtonType);

    if (R.isEmpty(newsStatusText)) {
      enqueueSnackbar('O texto da postagem não pode ser vazio', { variant: 'warning' });
      return false;
    }

    if (newsStatusText.length > 1500) {
      enqueueSnackbar('O texto da postagem não pode conter mais de 1500 caracteres', { variant: 'warning' });
      return false;
    }

    const isInvalidActionType = (
      !R.isNil(actionType)
      && actionType !== 'CALL'
    ) && R.isEmpty(addedButtonTypeValue.trim());

    if (isInvalidActionType) {
      enqueueSnackbar('A URL do botão de ação não pode ser vazio!', { variant: 'warning' });

      return false;
    }

    if (actionType && actionType !== 'CALL') {
      if ((!R.isEmpty(addedButtonTypeValue) || !R.isNil(addedButtonTypeValue))) {
        if (!websiteValidate(addedButtonTypeValue)) {
          enqueueSnackbar('O formato do link inserido é inválido!', { variant: 'warning' });
          return false;
        }
      }
    }
    return true;
  }, [
    newsStatusText,
    addedButtonType,
    addedButtonTypeValue,
  ]);

  const hasOpenTicket = React.useCallback(async () => {
    if (R.isNil(activeLocation)) return null;

    const thisPostOpenTicket = locationPostOpenTickets.find(ticket => {
      const { post_id } = ticket.data;

      return post_id === postNameId;
    });

    if (!R.isNil(thisPostOpenTicket)) return thisPostOpenTicket;

    return null;
  }, [
    activeLocation,
    locationPostTickets,
    locationPostOpenTickets,
    postNameId,
  ]);

  const handleAddButton = (value: any) => {
    setAddedButtonType(value);

    if (value === 'Nenhum') setAddedButtonTypeValue('');
  };

  const handleUpdateClicked = React.useCallback(async () => {
    if (R.isNil(googleAccessToken)) {
      enqueueSnackbar('Não foi possível adquirir um token do Google para criar postagem', { variant: 'warning' });
    }
    const { name: postId, searchUrl } = dataToUpdate;

    const hasOpenedTicketResponse = await hasOpenTicket();

    const actionType = getActionType(addedButtonType);

    if (!isValidInput()) return;

    const inputData: any = {
      actionType,
      url: isAddedButton ? addedButtonTypeValue : null,
      languageCode: 'pt-BR',
      summary: newsStatusText,
      topicType: 'STANDARD',
    };

    if (!R.isEmpty(filesToPost)) {
      inputData.mediaItems = await Promise.all(filesToPost.map(async (element: any) => {
        if (!R.isNil(element.mediaFormat)) return ({ mediaFormat: element.type === 'video/mp4' ? 'VIDEO' : 'PHOTO', sourceUrl: element.sourceUrl });

        const mediaItems = await getObjectInAWSS3Bucket(element);

        return {
          mediaFormat: 'PHOTO',
          sourceUrl: mediaItems ? mediaItems.mediaSourceUrl : null,
        };
      }));
    }

    const platformsSyncInitialStatus = {
      google: 'IN_PROGRESS',
      facebook: 'IN_PROGRESS',
      foursquare: 'IN_PROGRESS',
      yelp: 'IN_PROGRESS',
      linkedin: 'IN_PROGRESS',
    };

    if (!R.isNil(hasOpenedTicketResponse)) {
      if (isVideoFiles(filesToPost) || filesToPost.length > 1) {
        const updateOfferPostTicket = await APIUpdateTicket({
          accessToken: userAccessToken,
          ticketId: hasOpenedTicketResponse.id,
          dataToUpdate: {
            data: {
              info_to_update: JSON.stringify(inputData),
              post_id: postNameId,
              searchUrl,
              status: platformsSyncInitialStatus,
            },
            status: 'OPEN',
          },
          setTokenLikeExpired: userSetTokenLikeExpired,
          setIsFetching: setIsPublish,
        });

        if (!R.isNil(updateOfferPostTicket)) enqueueSnackbar('Sua postagem está sendo processada e será lançada em breve', { variant: 'success' });
        closeModal();

        return;
      }

      const editNewsPostResponse = await patchNewsPost({
        accessToken: googleAccessToken,
        postId: postId.replaceAll('/', '%2F'),
        inputData,
        updateMask: 'callToAction.actionType,callToAction.url,summary,media',
        setIsFetching: setIsPublish,
        feedbackMessage: enqueueSnackbar,
      });

      if (R.isNil(editNewsPostResponse)) return;

      const platformsSyncDoneStatus = {
        google: 'DONE',
        facebook: 'DONE',
        foursquare: 'DONE',
        yelp: 'DONE',
        linkedin: 'DONE',
      };

      const editNewsTicketResponse = await APIUpdateTicket({
        accessToken: userAccessToken,
        ticketId: hasOpenedTicketResponse.id,
        dataToUpdate: {
          data: {
            info_to_update: JSON.stringify(inputData),
            post_id: postNameId,
            searchUrl,
            status: platformsSyncDoneStatus,
          },
          status: 'DONE',
          operator_id: userId,
        },
        setTokenLikeExpired: userSetTokenLikeExpired,
        setIsFetching: setIsPublish,
      });

      if (!R.isNil(editNewsTicketResponse)) enqueueSnackbar('Postagem atualizada com sucesso', { variant: 'success' });
      closeModal();
      return;
    }

    if (isVideoFiles(filesToPost) || filesToPost.length > 1) {
      const createEventPostTicket = await createTicket({
        accessToken: userAccessToken,
        location_id: activeLocation.id,
        type: 'POST',
        changedData: {
          info_to_update: JSON.stringify(inputData),
          new: true,
          status: platformsSyncInitialStatus,
          post_id: postNameId,
          searchUrl,

        },
        setTokenLikeExpired: null,
        created_by: userId,
        isSync: setIsPublish,
      });

      if (!R.isNil(createEventPostTicket)) enqueueSnackbar('Sua postagem está sendo processada e será lançada em breve', { variant: 'success' });
    } else {
      const editNewsPostResponse = await patchNewsPost({
        accessToken: googleAccessToken,
        postId: postId.replaceAll('/', '%2F'),
        inputData,
        updateMask: 'callToAction.actionType,callToAction.url,summary,media',
        setIsFetching: setIsPublish,
        feedbackMessage: enqueueSnackbar,
      });

      if (!R.isNil(editNewsPostResponse)) enqueueSnackbar('Postagem publicada com sucesso', { variant: 'success' });
    }
    closeModal();
  }, [
    dataToUpdate,
    newsStatusText,
    addedButtonType,
    addedButtonTypeValue,
    filesToPost,
    postNameId,
    userAccessToken,
    activeLocation,
    hasOpenTicket,
  ]);

  const handlePublishClicked = async () => {
    if (R.isNil(activeLocation)) return;
    if (R.isNil(googleAccessToken)) {
      enqueueSnackbar('Não foi possível adquirir um token do Google para criar postagem', { variant: 'warning' });
      return;
    }

    const actionType = getActionType(addedButtonType);

    if (!isValidInput()) return;

    setIsPublish(true);

    const mediasUploaded = await Promise.all(filesToPost.map(async (fileToPost) => {
      const putObjectData = await putObject({
        accessToken: userAccessToken,
        bucketName: 'hub-saas-media',
        file: fileToPost,
        googleAccountId,
        googleLocationId,
        mediaType: fileToPost.type === 'video/mp4' ? 'VIDEO' : 'PHOTO',
      });

      const mediaFileUrl = putObjectData.fileUrl;

      return {
        mediaFormat: fileToPost.type === 'video/mp4' ? 'VIDEO' : 'PHOTO',
        sourceUrl: mediaFileUrl,
      };
    }));

    const inputData = {
      actionType,
      url: !R.isEmpty(addedButtonTypeValue) ? addedButtonTypeValue : null,
      languageCode: 'pt-BR',
      summary: newsStatusText,
      topicType: 'STANDARD',
      mediaItems: mediasUploaded,
    };

    const platformsSyncInitialStatus = {
      google: 'IN_PROGRESS',
      facebook: 'IN_PROGRESS',
      foursquare: 'IN_PROGRESS',
      yelp: 'IN_PROGRESS',
      linkedin: 'IN_PROGRESS',
    };

    if (isVideoFiles(filesToPost) || filesToPost.length > 1) {
      const createNewsPostTicket = await createTicket({
        accessToken: userAccessToken,
        location_id: activeLocation.id,
        type: 'POST',
        changedData: {
          info_to_update: JSON.stringify(inputData),
          new: true,
          status: platformsSyncInitialStatus,
        },
        setTokenLikeExpired: null,
        created_by: userId,
        isSync: setIsPublish,
      });

      if (!R.isNil(createNewsPostTicket)) enqueueSnackbar('Sua postagem está sendo processada e será lançada em breve', { variant: 'success' });
    } else {
      const createNewsPostResponse = await APICreateGooglePost({
        locationId: `accounts%2F${googleAccountId}%2Flocations%2F${googleLocationId}`,
        inputData,
        accessToken: googleAccessToken,
        setIsPublish,
        feedbackMessage: enqueueSnackbar,
      });

      if (!R.isNil(createNewsPostResponse)) enqueueSnackbar('Postagem publicada com sucesso', { variant: 'success' });
    }

    closeModal();
  };

  React.useEffect(() => {
    if (R.isNil(dataToUpdate)) return;

    const {
      name = '',
      summary = '',
      topicType = '',
      media = [],
      callToAction = {
        actionType: 'Nenhum',
        url: '',
      },
    } = dataToUpdate;

    if (topicType !== 'STANDARD') return;

    if (summary) setNewsStatusText(summary);

    setPostNameId(name);

    if (callToAction) {
      const {
        actionType,
        url,
      } = callToAction;

      setAddedButtonType(getActionTypeReverse(actionType));
      setAddedButtonTypeValue(url);
    }

    if (!R.isEmpty(media)) {
      setFilesToPost(media.map((file: any) => ({
        mediaFormat: file.mediaFormat,
        sourceUrl: file.googleUrl,
      })));
    }
  }, [dataToUpdate]);

  React.useEffect(() => {
    if (addedButtonType !== 'Nenhum' && addedButtonType !== 'Ligar agora') {
      setIsAddedButton(true);
      setIsAddedButtonPhoneType(false);
    }

    if (addedButtonType === 'Ligar agora') {
      setIsAddedButtonPhoneType(true);
      setIsAddedButton(false);
    }

    if (addedButtonType === 'Nenhum') {
      setIsAddedButton(false);
      setIsAddedButtonPhoneType(false);
    }
  }, [addedButtonType]);

  return (
    <StyledPostFormNews>
      <DragAndDropFiles
        filesToPost={filesToPost}
        setFilesToPost={setFilesToPost}
        imageFileValidation={imagePostValidation}
        maxVideoSize={70}
      />

      <Textarea
        name="news-status"
        onChange={setNewsStatusText}
        label="Escrever Postagem *"
        border
        value={newsStatusText}
        className="news-status-textarea"
      />

      <Select<TLocationPostAddButtonType>
        label="Adicionar um botão (opcional)"
        value={addedButtonType}
        name="select-button-type"
        border
        onChange={({ target }) => handleAddButton(target.value)}
        options={locationPostAddButtonsType}
      />

      {(isAddedButton && !isAddedButtonPhoneType) && (
        <Input
          value={addedButtonTypeValue}
          border
          label="Link para o botão"
          onChange={(e) => setAddedButtonTypeValue(e.target.value)}
        />
      )}

      {!handleFormHasManyImagesOrVideo(filesToPost) ? (
        <Button
          className="publish-button"
          disabled={isPublish}
          onClick={dataToUpdate ? handleUpdateClicked : handlePublishClicked}
        >
          {isPublish && <Loading className="is-button-loading" />}
          {dataToUpdate ? 'Atualizar' : 'Publicar'}
        </Button>
      ) : (
        <StyledAlertMessage>
          No momento não é possível atualizar uma postagem que contenha videos ou múltiplas imagens
        </StyledAlertMessage>
      )}
    </StyledPostFormNews>
  );
};

export default PostFormNews;
