import React from 'react';
import * as R from 'ramda';

import { Dialog, Loading } from '../../../../components';
import { useAuth } from '../../../../hooks';
import { getAllFieldsByRequest } from '../../../../services/platform-sync-modules/get-all-fields-by-request';
import RequestsPopover from './requests-popover';
import { TRequestDialogProps } from './types';
import { StyledLoadingWrapper } from './requests-dialog-styles';

const RequestDialog = ({
  isDialogOpen,
  onClose,
  requestId = '',
  onRequestUpdate,
  requestStatus = '',
  locationId,
}: TRequestDialogProps) => {
  const [loading, setLoading] = React.useState(true);

  const {
    userAccessToken,
  } = useAuth();

  const [syncFieldData, setSyncFieldData] = React.useState<any[]>([]);
  const getFieldsFromRequest = React.useCallback(async () => {
    const getFieldsFromRequestResponse = await getAllFieldsByRequest({
      requestId,
      userAccessToken,
    });
    if (R.isNil(getFieldsFromRequestResponse)) return;

    const [syncFieldsFromRequestData] = getFieldsFromRequestResponse;
    setSyncFieldData(syncFieldsFromRequestData);
    setLoading(false);
  }, [requestId]);

  const getSensibleFields = (data) => data.filter((field) => !!field.sensible_data);

  React.useEffect(() => { getFieldsFromRequest(); }, [getFieldsFromRequest]);
  return (
    <Dialog
      onClose={onClose}
      open={isDialogOpen}
      title="Solicitações"
      wideDialog
    >
      <>
        {loading && <StyledLoadingWrapper><Loading /></StyledLoadingWrapper>}
        {
          !R.isEmpty(syncFieldData) && !loading
          && (
            <RequestsPopover
              closeModal={onClose}
              data={getSensibleFields(syncFieldData)}
              requestId={requestId}
              onRequestUpdate={onRequestUpdate}
              requestStatus={requestStatus}
              locationId={locationId}
            />
          )
        }
      </>
    </Dialog>
  );
};

export default RequestDialog;
