import React from 'react';
import * as R from 'ramda';
import { useSnackbar } from 'notistack';

import APISendEmailInvite from '../../services/invites/sendEmail';
import APIGetUser from '../../services/users/getUsers';
import APIDeleteInvite from '../../services/invites/delete';

import Button from '../button';

import Guests from './guests';
import PendingInvites from './pending-invites';
import SendInvite from './send-invite';

import {
  inviteFieldValidate,
} from './helpers';

import {
  StyledGuestList,
} from './guest-list-styles';
import { useAuth } from '../../hooks';
import getProfiles from '../../services/profiles/getProfiles';

export default ({
  guestAccessLevelProfiles,
  APIGetPendingInvites,
  userAccessToken,
  userSetTokenLikeExpired,
  targetParamToGetPendingInvites,
  activeRegisterIdToGetData,
  APIGetAllGuest,
  targetParamToGetGuests,
  userId,
  APICreateInvite,
  targetParamToCreateInvites,
  isCompany,
  isGroup,
  isLocationPage = false,
}) => {
  const userAuthenticated = useAuth();

  const { enqueueSnackbar } = useSnackbar();

  const [pendingInvitations, setPendingInvitations] = React.useState([]);
  const [pendingInvitesInputSearch, setPendingInvitesInputSearch] = React.useState('');
  const [pendingInvitesPage, setPendingInvitesPage] = React.useState(0);
  const [pendingInvitesPageQuantity, setPendingInvitesPageQuantity] = React.useState(0);
  const [guestsList, setGuestsList] = React.useState([]);
  const [openFormToSendInvite, setOpenFormToSendInvite] = React.useState(false);
  const [openFormToEditInvite, setOpenFormToEditInvite] = React.useState(false);
  const [inviteLoading, setInviteLoading] = React.useState(false);
  const [updateGuestLoading] = React.useState(false);
  const [isSendingInvite, setIsSendingInvite] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const sendInviteEmailRef = React.useRef(null);
  const sendInviteProfileRef = React.useRef(null);

  const hasAUserWithThisEmail = React.useCallback(async (userEmailToSearch) => {
    const usersResponse = await APIGetUser({
      accessToken: userAccessToken,
      setTokenLikeExpired: userSetTokenLikeExpired,
      query: userEmailToSearch,
    });

    if (R.isNil(usersResponse)) return false;

    const [usersData] = usersResponse;

    return !R.isEmpty(usersData);
  }, []);

  const hasAGuestWithThisEmail = React.useCallback(async (userEmailToSearch) => {
    const guestsResponse = await APIGetAllGuest({
      accessToken: userAccessToken,
      setTokenLikeExpired: userSetTokenLikeExpired,
      query: userEmailToSearch,
      [targetParamToGetGuests]: activeRegisterIdToGetData,
    });

    if (R.isNil(guestsResponse)) return false;

    const [guestsData] = guestsResponse;

    return !R.isEmpty(guestsData);
  }, []);

  const hasAInviteWithThisEmail = React.useCallback(async (userEmailToSearch) => {
    const invitesResponse = await APIGetPendingInvites({
      accessToken: userAccessToken,
      setTokenLikeExpired: userSetTokenLikeExpired,
      [targetParamToGetGuests]: activeRegisterIdToGetData,
      query: userEmailToSearch,
    });

    if (R.isNil(invitesResponse)) return false;

    const [invitesData] = invitesResponse;

    return !R.isEmpty(invitesData);
  }, []);

  const getPendingInvites = React.useCallback(async () => {
    if (R.isNil(APIGetPendingInvites)) return;

    setOpenFormToSendInvite(false);

    const pendingInvitesResponse = await APIGetPendingInvites({
      accessToken: userAccessToken,
      [targetParamToGetPendingInvites]: activeRegisterIdToGetData,
      page: pendingInvitesPage,
      query: pendingInvitesInputSearch,
      isLoading: setInviteLoading,
      setTokenLikeExpired: userSetTokenLikeExpired,
    });

    if (R.isNil(pendingInvitesResponse)) return;

    const [invitesList, inviteListCount] = pendingInvitesResponse;

    setPendingInvitations(invitesList);
    setPendingInvitesPageQuantity(Math.ceil(inviteListCount / 10));
  }, [
    APIGetPendingInvites,
    activeRegisterIdToGetData,
    targetParamToGetPendingInvites,
    pendingInvitesInputSearch,
    pendingInvitesPage,
  ]);

  const getGuestsList = React.useCallback(async () => {
    if (R.isNil(APIGetAllGuest)) return;

    const guestListResponse = await APIGetAllGuest({
      accessToken: userAccessToken,
      [targetParamToGetGuests]: activeRegisterIdToGetData,
      page: 0,
    });

    if (R.isNil(guestListResponse)) return;

    const [guestsData] = guestListResponse;

    setGuestsList(guestsData);
  }, [
    APIGetAllGuest,
    targetParamToGetGuests,
    activeRegisterIdToGetData,
  ]);

  React.useEffect(() => { getPendingInvites(); }, [getPendingInvites]);
  React.useEffect(() => { getGuestsList(); }, [getGuestsList]);

  const handleSendInvite = React.useCallback(async () => {
    const ADMINISTRATOR_PROFILE_ID = 1;
    const OPERATIONAL_PROFILE_ID = 6;

    const sendInviteEmail = sendInviteEmailRef?.current?.value || '';
    const sendInviteProfile = sendInviteProfileRef?.current?.value || '';

    const userIsAdminOrOperator =
      userAuthenticated.userProfile.id === ADMINISTRATOR_PROFILE_ID
      || userAuthenticated.userProfile.id === OPERATIONAL_PROFILE_ID;

    const isValidInviteFields = inviteFieldValidate({
      email: sendInviteEmail,
      profile: sendInviteProfile,
      feedbackMessageFunction: enqueueSnackbar,
      ignoreProfile: isCompany || isGroup,
      pendingInvitations,
      guestsList,
    });

    if (!isValidInviteFields) return;

    if (await hasAGuestWithThisEmail(sendInviteEmail) || await hasAInviteWithThisEmail(sendInviteEmail)) {
      enqueueSnackbar('Já existe um convidado com este email!', { variant: 'warning' });
      return;
    }

    if (R.isNil(APICreateInvite)) return;

    const createdInviteResponse = await APICreateInvite({
      accessToken: userAccessToken,
      email: sendInviteEmail,
      location_access_level_profile_id: sendInviteProfile,
      [targetParamToCreateInvites]: activeRegisterIdToGetData,
      created_by: userId,
      setTokenLikeExpired: userSetTokenLikeExpired,
      isLoading: setIsSendingInvite,
    });

    if (R.isNil(createdInviteResponse)) return;

    const {
      email: createdInviteEmail
    } = createdInviteResponse;

    if (R.isNil(APISendEmailInvite)) return;

    if (!await hasAUserWithThisEmail(sendInviteEmail))
      enqueueSnackbar(
        `E-mail de convite enviado para ${createdInviteEmail} que deve criar uma conta.`,
        { variant: 'success' }
      )
    else if (userIsAdminOrOperator)
      enqueueSnackbar(
        `O usuário ${createdInviteEmail} foi convidado e já possui acesso ao local.`,
        {
          variant: 'success'
        });
    else
      enqueueSnackbar(
        `E-mail de convite enviado para ${createdInviteEmail}`,
        {
          variant: 'success'
        });
    getPendingInvites();
  }, [
    sendInviteEmailRef,
    sendInviteProfileRef,
    pendingInvitations,
    guestsList,
    APICreateInvite,
    APISendEmailInvite,
  ]);

  const removeInvite = async (inviteId) => {
    setLoading(true);
    await APIDeleteInvite({ accessToken: userAccessToken, id: inviteId });
    setPendingInvitations(pendingInvitations.filter(el => el.id !== inviteId));
    setLoading(false);
    enqueueSnackbar('Convite apagado', { variant: 'success' });
  };

  const resendInviteEmail = async (inviteId) => {
    await APISendEmailInvite({ accessToken: userAccessToken, inviteId, isLoading: setLoading });
    enqueueSnackbar('E-mail de convite reenviado', { variant: 'success' });
  };

  const handleOpenInviteForm = () => {
    const ADMINISTRATOR_PROFILE_ID = 1;
    const OPERATIONAL_PROFILE_ID = 6;

    setOpenFormToSendInvite(true);

    const userIsAdminOrOperator =
      userAuthenticated.userProfile.id === ADMINISTRATOR_PROFILE_ID
      || userAuthenticated.userProfile.id === OPERATIONAL_PROFILE_ID;

    if (userIsAdminOrOperator)
      enqueueSnackbar('Cuidado: Por ser um usuario administrador ou operador, qualquer usuario que convidar automaticamente possuira as permissoes concedidas no convite.', { variant: 'warning' });
  }

  return (
    <StyledGuestList>
      {(!R.isEmpty(guestsList)) && (
        <Guests
          data={guestsList}
          guestAccessLevelProfiles={guestAccessLevelProfiles}
          openFormToEditInvite={openFormToEditInvite}
          setOpenFormToEditInvite={setOpenFormToEditInvite}
          guestsListLoading={updateGuestLoading}
          userAccessToken={userAccessToken}
          userSetTokenLikeExpired={userSetTokenLikeExpired}
          getGuestsList={getGuestsList}
        />
      )}

      {(!R.isEmpty(pendingInvitations) || !R.isEmpty(pendingInvitesInputSearch)) && (
        <PendingInvites
          data={pendingInvitations}
          setPendingInvitesInputSearch={setPendingInvitesInputSearch}
          pendingInvitesPage={pendingInvitesPage}
          pendingInvitesQuantity={pendingInvitesPageQuantity}
          setPendingInvitesPage={setPendingInvitesPage}
          pendingInvitesInputSearch={[pendingInvitesInputSearch]}
          accessToken={userAccessToken}
          removeInvite={removeInvite}
          resendInviteEmail={resendInviteEmail}
          loading={loading}
        />
      )}

      {openFormToSendInvite ? (
        <SendInvite
          pendingInvitesInputSearch={pendingInvitesInputSearch}
          isSendingInvite={isSendingInvite}
          setOpenFormToSendInvite={setOpenFormToSendInvite}
          inviteLoading={inviteLoading}
          handleSendInvite={handleSendInvite}
          guestAccessLevelProfiles={guestAccessLevelProfiles}
          sendInviteEmailRef={sendInviteEmailRef}
          sendInviteProfileRef={sendInviteProfileRef}
          isLocationPage={isLocationPage}
        />
      ) : (
        <Button
          className="button"
          onClick={handleOpenInviteForm}
        >
          Convidar
        </Button>
      )}
    </StyledGuestList>
  );
};
