import styled from 'styled-components';
import { lighten, shade } from 'polished';
import { Paper } from '@material-ui/core';

import { devices } from '../../assets/styles/devices';

export const StyledGroupItensPartitionDiv = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  .group-itens-pagination {
    margin-top: auto;
  }

  .input-margin {
    margin-bottom: 0;
  }
`;

export const StyledSides = styled(Paper)`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: 2rem;

  @media ${devices.desktop} {
    flex-direction: row;
    align-items: stretch;
  }
`;

export const StyledGroupItensDiv = styled.div`
  flex: 1;
  width: 100%;
  display: flex;
  flex-direction: column;

  border-bottom: 0.1rem solid ${props => shade(0.1, props.theme.colors.white)};
  margin-bottom: 2rem;
  padding-bottom: 2rem;

  @media ${devices.desktop} {
    border-right: 0.1rem solid ${props => shade(0.2, props.theme.colors.white)};
    margin-right: 2rem;
    padding-right: 2rem;
    border-bottom: none;
    margin-bottom: 0;
    padding-bottom: 0;
  }

  &:last-child {
    border: none;
    margin: 0;
    padding: 0;
  }

  .loading-data-group {
    margin: 3rem auto;
  }
`;

export const StyledListDiv = styled.div`
  max-height: 30rem;
  overflow: auto;
  margin-bottom: auto;

  .button-tooltip {
    width: 2.5rem;
    height: 2.5rem;

    svg {
      width: 2rem;
      height: 2rem;
      fill: ${props => props.theme.colors.white} !important;
    }
  }

  .button-tooltip-add {
    background: ${props => props.theme.colors.green};
  }

  .button-tooltip-remove {
    background: ${props => props.theme.colors.red};
  }
`;

export const StyledTitle = styled.h3`
  display: flex;
  font-weight: 700;
  font-size: 1.5rem;
  margin-bottom: 1.5rem;
`;

export const StyledIsEmptyData = styled.div`
  padding: 1rem 2rem;
  background: ${props => lighten(0.3, props.theme.colors.red)};
  color: ${props => shade(0.3, props.theme.colors.red)};
  border-radius: 0.5rem;
  margin-top: 1rem;
  text-align: center;
  border: 0.1rem solid ${props => shade(0.4, props.theme.colors.red)};
`;

export const StyledHeaderFilterWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 1rem;

  @media ${devices.tablet} {
    flex-direction: row;
  }

  .select-dropdown-company-label {
    font-size: 1.5rem;
    font-weight: normal;
    color: #000000;
  }
`;

export const StyledSelectDropdownWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-right: 1rem;
  margin-bottom: 1rem;
  justify-content: space-between;

  @media ${devices.tablet} {
    margin-bottom: 0;
  }
`;
