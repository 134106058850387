import * as R from 'ramda';

export const hasData = data => R.or(!R.isNil(data), !R.isEmpty(data));

export const getActiveCompanyId = activeCompany => (activeCompany?.length > 1 ? activeCompany[0].id : activeCompany.id);

export const getAdminAndOperatorCompanies = companies => (hasData(companies) ? companies[0] : []);

export const getClientCompanies = ({ companies = [], guestCompanies = [] }) => {
  if (!hasData(companies) && !hasData(guestCompanies)) return [];

  if (hasData(companies)) return companies;
  if (hasData(guestCompanies[0])) return guestCompanies[0];
  if (hasData(companies) && hasData(guestCompanies[0])) return [...guestCompanies[0], ...companies];
};

export const getCompaniesByUser = ({
  allUserCompanies = [],
  clientUserCompanies = [],
  userGuestCompanies = [],
}) => (userProfile = {}) => {
  if (R.isEmpty(userProfile)) return [];

  const {
    name: userProfileName = '',
  } = userProfile || {};

  if (userProfileName === 'Admin' || userProfileName === 'Operacional') {
    return getAdminAndOperatorCompanies(allUserCompanies);
  }

  return getClientCompanies(clientUserCompanies, userGuestCompanies);
};

export const formatContractList = contractList => {
  const formatted = [];

  if (!R.isNil(contractList)) {
    contractList.map(profile => {
      formatted.push({
        name: profile.name,
        value: profile.id,
      });
    });

    return formatted;
  }
};
