import React from 'react';
import { useSnackbar } from 'notistack';
import { isNil } from 'ramda';

import { Select } from '../../../../components';

import updateTicket from '../../../../services/tickets/update';

import { platformsLabel, hasOperator, isDone } from '../helpers';

import {
  StyledPlatformsStatus,
  StyledPlatformsWrap,
} from './platforms-status-styles';

const PlatformsStatus = ({
  platformStatus,
  ticketData,
  accessToken,
  setTokenLikeExpired,
  setIsTicketLoading,
  getTicketData,
}) => {
  const { enqueueSnackbar } = useSnackbar();

  const [formattedTicketData, setFormattedTicketData] = React.useState(null);
  const [statusForm, setStatusForm] = React.useState({});

  React.useEffect(() => {
    const dataJson = JSON.parse(ticketData.data);
    setFormattedTicketData(dataJson);
    setStatusForm(dataJson.status);
  }, [ticketData]);

  const onChangeStatusForm = async ({ name, value }) => {
    setStatusForm({ ...statusForm, [name]: value });

    const newTicketData = {
      ...formattedTicketData,
      status: {
        ...statusForm,
        [name]: value,
      },
    };

    const updateTicketResponse = await updateTicket({
      accessToken,
      ticketId: ticketData.id,
      dataToUpdate: { data: JSON.stringify(newTicketData) },
      setIsFetching: setIsTicketLoading,
      setTokenLikeExpired,
      feedbackMessage: enqueueSnackbar,
    });

    if (isNil(updateTicketResponse)) return;

    getTicketData();
  };

  return (
    <StyledPlatformsStatus>
      <h3>Plataformas</h3>
      <StyledPlatformsWrap>
        {!isNil(formattedTicketData)
          && Object.keys(formattedTicketData.status).map((element) => (
            <Select
              key={Math.random(Math.floor())}
              className="ticket-select"
              border
              label={platformsLabel[element]}
              value={statusForm[element]}
              onChange={({ target }) => onChangeStatusForm(target)}
              name={element}
              options={platformStatus}
              disabled={isDone(ticketData) || !hasOperator(ticketData) || statusForm[element] === 'AUTO_SYNC'}
            />
          ))}
      </StyledPlatformsWrap>
    </StyledPlatformsStatus>
  );
};

export default PlatformsStatus;
