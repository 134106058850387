import React from 'react';

import Button from '../button';

import {
  StyledBatchCreationEnable,
} from './batch-creation-enable-styles';

const BatchCreationEnable = ({
  title = 'Título',
  disclaimer = null,
  onClick,
  isBatchCreation,
  titleButton = 'Button',
}) => (
  <StyledBatchCreationEnable>
    <h3>{title}</h3>
    {disclaimer && <small>{disclaimer}</small>}
    <Button
      onClick={onClick}
      buttonType={isBatchCreation ? 'warning' : 'secondary'}
    >
      {isBatchCreation ? 'Cancelar' : titleButton}
    </Button>
  </StyledBatchCreationEnable>
);

export default BatchCreationEnable;
