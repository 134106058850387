import axios from 'axios';

export default async function removeProfiles({
  accessToken,
  userId,
  profile_ids,
  setTokenLikeExpired,
}) {
  const requestOptions = {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  };

  try {
    const { data } = await axios.post(
      `${process.env.REACT_APP_AUTH_API_URL}/remove/profiles/${userId}`,
      { profile_ids },
      requestOptions,
    );

    return data;
  } catch (err) {
    if (err.response?.status === 401 && setTokenLikeExpired) setTokenLikeExpired();

    return null;
  }
}
