import React from 'react';
import { useSnackbar } from 'notistack';
import { debounce, isNil } from 'lodash';
import { MUIDataTableOptions } from 'mui-datatables';

import { useAuth } from '../../../hooks';
import {
  tableColumns,
} from './helpers';

import APIGetAllLinkedLocations from '../../../services/locations/getAllLinkedLocations';

import { Table } from '../../../components';
import SyncPermissionsFilter from './sync-permissions-filter';
import SyncPermissionsRow from './sync-permissions-row';

import type { THandleChangeSearch, TTableLinkedLocation } from '../../../types/TSyncPermissions';

import { StyledInfo, StyledContainer, StyledContent } from './sync-permissions-styles';

const SyncPermissions = () => {
  const {
    userAccessToken, userSetTokenLikeExpired, userProfileName, userId,
  } = useAuth();
  const { enqueueSnackbar } = useSnackbar();

  const [companyFilter, setCompanyFilter] = React.useState('');
  const [locationFilter, setLocationFilter] = React.useState('');
  const [selectedStatusFilter, setSelectedStatusFilter] = React.useState('');
  const [isLoading, setIsLoading] = React.useState(false);
  const [page, setPage] = React.useState(0);
  const [pageSize, setPageSize] = React.useState(10);
  const [requestsCount, setRequestsCount] = React.useState(0);
  const [tableData, setTableData] = React.useState<TTableLinkedLocation[]>([]);

  const handleChangeSearch = ({ text, setField }: THandleChangeSearch) => {
    setPage(0);
    setField(text);
  };

  const handleSearchGroupWithDebounce = React.useCallback(
    debounce((text: string) => handleChangeSearch({ text, setField: setCompanyFilter }), 500),
    [],
  );

  const handleSearchLocationWithDebounce = React.useCallback(
    debounce((text: string) => handleChangeSearch({ text, setField: setLocationFilter }), 500),
    [],
  );

  const tableOptions = React.useMemo(
    (): MUIDataTableOptions => ({
      count: requestsCount,
      search: false,
      page,
      selectableRows: 'none',
    }),
    [requestsCount, page],
  );

  const getRequestsData = React.useCallback(async () => {
    const getAllLinkedLocationsResponse = await APIGetAllLinkedLocations({
      accessToken: userAccessToken,
      setTokenLikeExpired: userSetTokenLikeExpired,
      page,
      pageSize,
      companyName: companyFilter,
      locationName: locationFilter,
      status: selectedStatusFilter,
    });

    if (isNil(getAllLinkedLocationsResponse)) return;

    const [linkedLocationsData, linkedLocationsCount] = getAllLinkedLocationsResponse;

    const batchInfosNormalized = linkedLocationsData.map((locationItem) => [
      { columnRef: 'id', visible: false, value: locationItem.id },
      {
        columnRef: 'company',
        visible: true,
        value: locationItem.company?.name,
      },
      {
        columnRef: 'location',
        visible: true,
        value: locationItem.name,
      },
      {
        columnRef: 'sync-permission',
        visible: true,
        value: locationItem.sync_permission,
      },
    ]);

    setTableData(batchInfosNormalized);
    setRequestsCount(linkedLocationsCount);
  }, [
    userAccessToken,
    companyFilter,
    locationFilter,
    selectedStatusFilter,
    page,
    pageSize,
  ]);

  React.useEffect(() => {
    getRequestsData();
  }, [getRequestsData]);

  return (
    <StyledContainer>
      <StyledInfo>
        <SyncPermissionsFilter
          onChangeCompanyQuery={handleSearchGroupWithDebounce}
          searchCompanyLabel="Empresa:"
          onChangeLocationQuery={handleSearchLocationWithDebounce}
          searchLocationLabel="Local:"
          selectedTypeFilter={selectedStatusFilter}
          setSelectedTypeFilter={(text) => handleChangeSearch({ text, setField: setSelectedStatusFilter })}
          statusLabel="Status da aprovação:"
        />
      </StyledInfo>
      <StyledContent>
        <Table
          loading={isLoading}
          title="Permissão de Sincronização"
          setPage={setPage}
          setPageSize={setPageSize}
          columns={tableColumns}
          data={tableData}
          row={(rowData) => (
            <SyncPermissionsRow
              accessToken={userAccessToken}
              rowId={rowData[0]?.value}
              rowData={rowData}
              dataTableOptions={tableOptions}
              enqueueSnackbar={enqueueSnackbar}
              setTokenLikeExpired={userSetTokenLikeExpired}
              userId={userId}
              userProfile={userProfileName}
            />
          )}
          options={tableOptions}
        />

      </StyledContent>
    </StyledContainer>
  );
};

export default SyncPermissions;
