import * as R from 'ramda';
import React, { useCallback, useState } from 'react';
import { Dialog, Loading } from '../../../../components';
import { useAuth } from '../../../../hooks';
import { getAllFieldsByMonitor } from '../../../../services/platform-sync-modules/get-all-fields-by-monitor';
import isNilOrEmpty from '../../../../utils/is-null-or-empty';
import { StyledLoadingWrapper } from './monitor-dialog.styles';
import MonitorsPopover from './monitor-popover';
import { TMonitorDialogProps } from './types';

const MonitorDialog = ({
  isDialogOpen,
  onClose,
  monitorId = '',
  platform,
}: TMonitorDialogProps) => {
  const { userAccessToken } = useAuth();

  const [loading, setLoading] = useState(true);
  const [monitorFieldData, setMonitorFieldData] = useState<any[]>([]);

  const getFieldsFromMonitor = useCallback(async () => {
    const getFieldsFromMonitorResponse = await getAllFieldsByMonitor({
      userAccessToken,
      monitorId,
    });

    if (R.isNil(getFieldsFromMonitorResponse)) return;

    const [monitorFieldDataFromMonitor] = getFieldsFromMonitorResponse;

    setMonitorFieldData(monitorFieldDataFromMonitor);

    setLoading(false);
  }, [monitorId]);

  React.useEffect(() => { getFieldsFromMonitor(); }, [getFieldsFromMonitor]);

  return (
    <Dialog
      open={isDialogOpen}
      onClose={onClose}
      title={`Monitoramento - ${platform}`}
      wideDialog
    >
      <>
        {loading && <StyledLoadingWrapper><Loading /></StyledLoadingWrapper>}
        {
          !isNilOrEmpty(monitorFieldData) && !loading
          && (
          <MonitorsPopover
            data={monitorFieldData}
            platform={platform}
          />
          )
        }
      </>
    </Dialog>
  );
};

export default MonitorDialog;
