export const actionLogStatus = {
  NO_ACTION: 'Nenhuma Ação',
  CREATE: 'Criada',
  REMOVE: 'Removida',
  BATCH_REMOVE: 'Removida em Massa',
  CANCEL: 'Cancelada',
  MANUAL_PUBLISH: 'Publicada Manualmente',
  ERROR: 'Erro',
  WARNING: 'Aviso',
};
