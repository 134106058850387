import * as R from 'ramda';
import { getCurrentDate } from '../../../utils/get-currentDate';

import {
  TIsValidEventDate,
  TIsValidEventDateReturn,
  TIsValidEventTime,
  TIsValidEventTimeReturn,
} from '../../../types/TLocationPost';

import type { TTicketData } from '../../../types/TTickets';

import type {
  TSetCurrentSyncStatusData,
} from '../../../types/TLocationProfile';

export const isValidPostStartToEndDate = ({
  startDateData,
  endDateData,
  hasHour,
}: TIsValidEventDate): TIsValidEventDateReturn => {
  const [startDay, startMonth, startYear] = startDateData;
  const [endDay, endMonth, endYear] = endDateData;

  const {
    currentMonth,
    currentYear,
    daysInCurrentMonth,
    monthInYear,
    currentDay,
  } = getCurrentDate();

  if (R.isEmpty(startDateData)) {
    return {
      message: 'A data de início não pode ser vázia',
      isValid: false,
    };
  }

  if (R.isEmpty(endDateData)) {
    return {
      message: 'A data de término não pode ser vázia',
      isValid: false,
    };
  }

  const isValidStartDay = startDay >= currentDay && startDay <= daysInCurrentMonth;
  const isValidStartMonth = startMonth >= currentMonth && startMonth <= monthInYear;
  const isValidStartYear = startYear >= currentYear;

  const isValidEndDay = endDay >= startDay && endDay <= daysInCurrentMonth;
  const isValidEndMonth = endMonth >= startMonth && endMonth <= monthInYear;
  const isValidEndtYear = endYear >= startYear;

  const startDateObj = new Date(startYear, startMonth - 1, startDay);
  const endDateObj = new Date(endYear, endMonth - 1, endDay);

  const isInvalidStartDate = !isValidStartYear || !isValidStartMonth || !isValidStartDay;
  const isInvalidEndDate = (!isValidEndDay || !isValidEndMonth || !isValidEndtYear) && endDateObj < startDateObj;

  const isInvalidDateRangeWithNoTime = ((startDateData.toString() === endDateData.toString()) && !hasHour);

  const overYearRange = currentYear + 1;
  const overYearMonthRange = currentMonth;

  const isOverMaxDateRangeToStartDate = (
    (startYear > currentYear
    && startYear <= overYearRange)
    || startYear > overYearRange
  ) && startMonth > overYearMonthRange;

  const isOverMaxDateRangeToEndDate = (
    (endYear > overYearRange
    && endYear <= overYearRange)
    || endYear > overYearRange
  ) && endMonth > overYearMonthRange;

  if (isOverMaxDateRangeToStartDate || isOverMaxDateRangeToEndDate) {
    return {
      message: `A data de ${isOverMaxDateRangeToStartDate ? 'início' : 'término'} não pode ultrassar o limite de uma ano!`,
      isValid: false,
    };
  }

  if (isInvalidStartDate) {
    return {
      message: 'A data de início é inválida!',
      isValid: false,
    };
  }

  if (isInvalidEndDate) {
    return {
      message: 'A data de término é inválida!',
      isValid: false,
    };
  }

  return {
    message: null,
    isValid: true,
  };
};

export const isVideoFiles = (files: File[]) => (R.isEmpty(files) ? false : files.every(file => file.type === 'video/mp4'));

export const isValidPostStartToEndTime = ({
  openingHourData,
  closingHourData,
  startDateData,
  endDateData,
  hasHour,
}: TIsValidEventTime): TIsValidEventTimeReturn => {
  const [openingHour, openingHourMinutes] = openingHourData;
  const [closingHour, closingHourMinutes] = closingHourData;

  const isEqualTimes = openingHour === closingHour && openingHourMinutes === closingHourMinutes;
  const isInvalidHours = openingHour > closingHour;
  const isValidMinutes = openingHourMinutes > closingHourMinutes;

  const isSameDay = startDateData.toString() === endDateData.toString();

  if (!hasHour) {
    return {
      message: null,
      isValid: true,
    };
  }

  if (isSameDay && isEqualTimes) {
    return {
      message: 'Os horários não podem iguais!',
      isValid: false,
    };
  }

  if (isSameDay && (isInvalidHours || isValidMinutes)) {
    return {
      message: 'O horário de término não pode ser menor que o de início!',
      isValid: false,
    };
  }

  return {
    message: null,
    isValid: true,
  };
};

export const isValidPostHasDateAndTime = ({}) => {};

export const formatDate = (dateObject:any) => {
  let dateString = '';
  if (dateObject.day.toString().length === 1) dateString += 0;
  dateString += `${dateObject.day}/`;
  if (dateObject.month.toString().length === 1) dateString += 0;
  dateString += `${dateObject.month}/`;
  dateString += `${dateObject.year}`;

  return dateString;
};

export const handleFormHasManyImagesOrVideo = (filesToPost: any[]) => {
  if (R.isEmpty(filesToPost)) return false;

  if (filesToPost[0] instanceof File) return false;

  if (filesToPost[0].mediaFormat !== 'PHOTO') return true;

  if (filesToPost.length > 1) return true;

  return false;
};

export const setCurrentSyncStatusData = ({
  ticketData,
  setSyncStatusData,
}: TSetCurrentSyncStatusData) => {
  const ticketsOrdered = R.takeLast(1, R.sortBy(R.prop('id'), ticketData))[0];

  if (R.isNil(ticketsOrdered)) return;

  const data: TTicketData = JSON.parse(ticketsOrdered.data) || {};

  if (!data) return;

  const { status: platformsStatus } = data;

  setSyncStatusData(platformsStatus);
};
