import React from 'react';
import * as R from 'ramda';

import { isNil } from 'ramda';
import WorkingHoursInfos from './working-hours-info';
import SpecialDatesInfos from './special-dates-info';

import {
  StyledInfosToUpdate,
  StyledInfo,
  StyledInfosWrap,
} from './infos-to-update-styles';

import {
  mapLocationFields,
  isWorkingHours,
  phoneNotEmpty,
  isSpecialDate,
  isServiceArea,
} from './helper';
import EventPostInfo from './event-post-info';
import OfferPostInfo from './offer-post-info/offer-post-info';
import NewsPostInfo from './news-post-info';
import BasicInfo from './basic-info';
import LinksInfo from './links-info';
import ServiceAreaInfo from './service-area-info';

function InfosToUpdate({ data, ticketType }) {
  const [formattedData, setFormattedData] = React.useState(null);

  React.useEffect(() => {
    const dataToJson = JSON.parse(data);

    if (typeof dataToJson.info_to_update === 'string') {
      dataToJson.info_to_update = JSON.parse(dataToJson.info_to_update);
    }

    if (Object.keys(dataToJson.info_to_update).includes('other_links')) {
      const {
        menu, schedule, reservation, advanceOrder,
      } = JSON.parse(dataToJson.info_to_update.other_links);
      dataToJson.info_to_update = {
        ...dataToJson.info_to_update, menu, schedule, reservation, advanceOrder,
      };
      delete dataToJson.info_to_update.other_links;
    }
    dataToJson.info_to_update = Object.keys(dataToJson.info_to_update).reduce((acc, curr) => {
      acc[curr] = R.isEmpty(dataToJson.info_to_update[curr]) ? 'MUST_BE_EMPTY' : dataToJson.info_to_update[curr];
      return acc;
    }, {});

    setFormattedData(dataToJson);
  }, [data]);

  const handleFilterFormattedData = (info) => {
    if (
      !R.isNil(formattedData.info_to_update[info])
      && !R.isEmpty(formattedData.info_to_update[info])
    ) {
      if (info === 'id') return false;
      if (info === 'created_at') return false;
      if (info === 'updated_at') return false;
      if (info === 'created_by') return false;
      if (info === 'date_created') return false;
      if (info === 'date_updated') return false;
      if (info === 'responsible') return false;
      if (info === 'is_verified') return false;
      if (info === 'googleCategoriesObject') return false;
      if (info === 'fourSquareCategoriesObject') return false;
      if (info === 'facebookCategoriesObject') return false;
      if (info === 'languageCode') return false;
      if (info === 'company') return false;

      return true;
    }
  };

  const handleRenderSummaryType = ({ ticketTypeLabel, info, topicType }) => {
    if (ticketTypeLabel === 'POST' && info === 'summary') {
      if (topicType === 'EVENT') return 'Detalhes';
      if (topicType === 'OFFER') return 'Descrição';
      if (topicType === 'STANDARD') return 'Texto da Postagem';
    }
  };

  return (
    <StyledInfosToUpdate>
      <h3>
        {formattedData?.new
          ? ticketType === 'POST'
            ? formattedData.searchUrl
              ? 'Postagem para ser atualizada'
              : 'Postagem para ser criada'
            : 'Local para ser criado'
          : 'Informações para atualização'}
      </h3>
      <StyledInfosWrap>
        {ticketType === 'POST' && !isNil(formattedData?.searchUrl) && (
          <a
            href={formattedData.searchUrl}
            target="_blank"
            rel="noreferrer noopener"
          >
            Postagem para ser atualizada
          </a>
        )}

        {formattedData
          && Object.keys(formattedData.info_to_update)
            .filter(handleFilterFormattedData)
            .map(
              (info) => phoneNotEmpty(formattedData.info_to_update[info]) && (
              <StyledInfo key={Math.random(Math.floor())}>
                <h4>{mapLocationFields[info]}</h4>

                <h4>
                  {handleRenderSummaryType({
                    ticketTypeLabel: ticketType,
                    info,
                    topicType: formattedData.info_to_update.topicType,
                  })}
                </h4>

                {ticketType === 'ACTIVATION'
                && !isWorkingHours(mapLocationFields[info])
                && !isSpecialDate(mapLocationFields[info])
                && !isServiceArea(mapLocationFields[info])
                && (
                  <BasicInfo data={formattedData.info_to_update[info]} />
                )}

                {info === 'basic_infos'
                && (
                  <BasicInfo data={formattedData.info_to_update[info]} inLargeScale />
                )}

                {info === 'links' && (
                  <LinksInfo data={formattedData.info_to_update[info]} />
                )}

                {isWorkingHours(mapLocationFields[info]) && (
                  <WorkingHoursInfos
                    workingHoursData={formattedData.info_to_update[info]}
                    ticketType={ticketType}
                  />
                )}

                {isSpecialDate(mapLocationFields[info]) && (
                  <SpecialDatesInfos
                    data={formattedData.info_to_update[info]}
                  />
                )}

                {isServiceArea(mapLocationFields[info]) && (
                  <ServiceAreaInfo
                    data={formattedData.info_to_update[info]}
                  />
                )}

                {ticketType === 'POST'
                  && formattedData.info_to_update.topicType === 'EVENT' && (
                    <EventPostInfo
                      eventPostData={formattedData.info_to_update}
                      info={info}
                    />
                )}

                {ticketType === 'POST'
                  && formattedData.info_to_update.topicType === 'OFFER' && (
                    <OfferPostInfo
                      offerPostData={formattedData.info_to_update}
                      info={info}
                    />
                )}

                {ticketType === 'POST'
                  && formattedData.info_to_update.topicType === 'STANDARD' && (
                    <NewsPostInfo
                      newsPostData={formattedData.info_to_update}
                      info={info}
                    />
                )}
              </StyledInfo>
              ),
            )}
      </StyledInfosWrap>
    </StyledInfosToUpdate>
  );
}

export default InfosToUpdate;
