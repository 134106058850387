export const storeCode = {
  name: 'store-code',
  nickname: ' ',
  type: 'text',
  label: 'Código do Local',
  description: 'Código único para registro do local',
  hasborder: 'yes',
};

export const localName = {
  name: 'name',
  nickname: 'Nome',
  type: 'text',
  label: 'Nome do Local',
  description: 'Nome completo da localidade',
  hasborder: 'yes',
  required: true,
};

export const mainPhone = {
  name: 'main-phone',
  nickname: 'Telefone Principal',
  type: 'text',
  label: 'Telefone Principal',
  description: 'Telefone para contato',
  hasborder: 'yes',
  mask: '99 99999-9999',
  landline: '99 9999-9999',
};

export const secondaryPhone = {
  name: 'secondary-phone',
  nickname: 'Telefone Secundário',
  type: 'text',
  label: 'Telefone Secundário',
  description: 'Telefone alternativo para contato',
  hasborder: 'yes',
  mask: '99 99999-9999',
  landline: '99 9999-9999',
};

export const localEmail = {
  name: 'email',
  nickname: 'E-mail',
  type: 'text',
  label: 'E-mail',
  description: 'E-mail para contato',
  hasborder: 'yes',
  email: true,
};

export const dataStudioUrl = {
  name: 'data-studio',
  nickname: 'Link do Data Studio',
  type: 'text',
  label: 'Link do Data Studio (opcional)',
  description: 'Link para o relatório do Data Studio',
  hasborder: 'yes',
};

export const zipcode = {
  name: 'zipcode',
  nickname: 'CEP',
  type: 'text',
  label: 'CEP',
  description: 'CEP da localidade',
  hasborder: 'yes',
  mask: '99.999-999',
  required: true,
  zipcode: true,
};

export const countryCode = {
  name: 'country-code',
  nickname: 'País',
  type: 'text',
  label: 'País',
  description: 'Identificação do país da localidade',
  hasborder: 'yes',
  defaultValue: 'Brazil',
  disabled: true,
};

export const address = {
  name: 'address',
  nickname: 'Logradouro',
  type: 'text',
  label: 'Logradouro',
  description: 'Endereço da localidade',
  hasborder: 'yes',
  required: true,
};

export const addressNumber = {
  name: 'address-number',
  nickname: 'Número do Logradouro',
  type: 'text',
  label: 'Número do Logradouro',
  description: 'Número da localidade',
  hasborder: 'yes',
  required: true,
  number: true,
};

export const addressComplement = {
  name: 'address-complement',
  nickname: 'Complemento',
  type: 'text',
  label: 'Complemento',
  description: 'Detalhes adicionais sobre o endereço da localidade',
  hasborder: 'yes',
};

export const neighborhood = {
  name: 'neighborhood',
  nickname: 'Bairro',
  type: 'text',
  label: 'Bairro',
  description: 'Bairro da localidade',
  hasborder: 'yes',
  required: true,
};

export const city = {
  name: 'city',
  nickname: 'Cidade',
  type: 'text',
  label: 'Cidade',
  description: 'Cidade da localidade',
  hasborder: 'yes',
  required: true,
};

export const stateCode = {
  name: 'state-code',
  nickname: 'Código do Estado',
  type: 'text',
  label: 'Código do Estado',
  description: 'Estado da localidade, em formato sigla',
  hasborder: 'yes',
  required: true,
  maxLength: 2,
  uppercase: true,
};

export const answerAtThatAddress = {
  name: 'answer-at-that-address',
  type: 'text',
  nickname: 'Atende nesse endereço?',
  label: 'Atende nesse endereço?',
  description: 'Este endereço é apenas para referência?',
  typeSwitch: true,
};
