import styled from 'styled-components';
import { Paper } from '@material-ui/core';

export const StyledLocationPosts = styled.div`
  margin-bottom: 4rem;
  
  .is-loading-location-posts {
    margin: 3rem auto;
  }

  .pagination {
    margin: 2rem;
  }
`;

export const StyledCard = styled(Paper)`
  display: flex;
  padding: 2rem;
  align-items: center;
  justify-content: center;
  margin: 2rem;

  p {
    color: ${props => props.theme.colors.red};
    margin-bottom: 0;
  }
`;
