import { format } from 'date-fns';
import type { MUIDataTableColumn } from 'mui-datatables';
import type { TBatchGalleryLocation } from '../../../../types/TBatchGallery';
import type { TTableData } from '../../../../types/TTable';

export const initialTableColumns: MUIDataTableColumn[] = [
  {
    name: 'id',
    label: 'Id',
    options: {
      display: false,
      sort: false,
    },
  },
  {
    name: 'location-name',
    label: 'Nome do local',
    options: {
      sort: false,
    },
  },
  {
    name: 'updated-at',
    label: 'Data de atualização',
    options: {
      sort: false,
    },
  },
  {
    name: 'created-at',
    label: 'Data de criação',
    options: {
      display: false,
      sort: false,
    },
  },
  {
    name: 'status',
    label: 'Status',
    options: {
      sort: false,
    },
  },
  {
    name: 'history',
    label: 'Historico',
    options: {
      sort: false,
    },
  },
  {
    name: 'actions',
    label: 'Ações',
    options: {
      sort: false,
    },
  },
];

export const normalizeGalleryLocationToTable = (
  batchGalleriesLocations: TBatchGalleryLocation[],
): TTableData => batchGalleriesLocations.map((batchGalleriesLocation) => [
  {
    columnRef: 'id',
    visible: false,
    value: batchGalleriesLocation.id,
  },
  {
    columnRef: 'location-name',
    visible: true,
    value: batchGalleriesLocation.name,
  },
  {
    columnRef: 'updated_at',
    visible: true,
    value: format(
      new Date(batchGalleriesLocation.updated_at),
      'dd/MM/yyyy HH:mm:ss',
    ),
  },
  {
    columnRef: 'created-at',
    visible: false,
    value: format(
      new Date(batchGalleriesLocation.created_at),
      'dd/MM/yyyy HH:mm:ss',
    ),
  },
  {
    columnRef: 'status',
    visible: true,
    value: batchGalleriesLocation.status,
  },
  {
    columnRef: 'location-media-data',
    visible: false,
    value: batchGalleriesLocation.location_media_data,
  },
]);
