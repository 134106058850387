import { MUIDataTableColumn } from 'mui-datatables';

export const initialDateColumns: MUIDataTableColumn[] = [
  {
    name: 'id',
    label: 'Id',
    options: { display: false },
  },
  {
    name: 'created_at',
    label: 'Data e Hora do Login',
  },
  {
    name: 'ip_address',
    label: 'IP',
  },
  {
    name: 'ip_location',
    label: 'Localização',
  },
];

export type TTableData = {
  id: string;
  'created_at': Date;
  'ip_address': string;
  'ip_location': string;
}

export type TTableRowData = {
  columnRef: string;
  visible: boolean;
  value: any;
};
