import axios from 'axios';

type TCreateWorkingHoursTimesRange = {
  accessToken: string;
  timeRangeId: any;
  setTokenLikeExpired: () => void;
  isDelete?: any;
};

export default async function createWorkingHoursTimesRange({
  accessToken,
  timeRangeId,
  setTokenLikeExpired,
  isDelete = null,
}: TCreateWorkingHoursTimesRange) {
  const requestOptions = {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  };

  try {
    if (isDelete) isDelete(true);

    const { data } = await axios.delete(
      `${process.env.REACT_APP_TIME_API_URL}/${timeRangeId}`,
      requestOptions,
    );

    return data;
  } catch (err: any) {
    if (err.response?.status === 401 && setTokenLikeExpired) return setTokenLikeExpired();

    return null;
  } finally {
    if (isDelete) isDelete(false);
  }
}
