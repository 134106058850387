import styled from 'styled-components';
import { lighten, shade } from 'polished';
import {
  Accordion,
  AccordionSummary,
} from '@material-ui/core';
import { ExpandMore } from '@material-ui/icons';

import { MyBusiness as MyBusinessIcon } from '../../../../assets/custom-icons-svg';

import { devices } from '../../../../assets/styles/devices';

export const StyledGoogle = styled(Accordion)`
  width: 100%;

  &:hover {
    background: ${props => props.theme.colors.white} !important;
    box-shadow: 0px 2px 1px -1px rgb(0 0 0 / 20%), 
      0px 1px 1px 0px rgb(0 0 0 / 14%), 
      0px 1px 3px 0px rgb(0 0 0 / 12%);
  }

  .MuiAccordionSummary-root {
    padding: 0;
    background: ${props => props.theme.colors.white} !important;
  }

  .MuiAccordionSummary-content {
    padding: 0 !important;
    margin: 0 !important;
  }

  .accordion-expanded-action {
    margin-left: 1rem;

    .icon-button__icon {
      width: 3rem;
      height: 3rem;
    }
  }
`;

export const StyledHeader = styled.header`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2rem;
  background: ${(props) => props.theme.colors.white};
  border-bottom: 0.2rem solid ${(props) => shade(0.2, props.theme.colors.white)};
  width: 100%;

  @media ${devices.tabletLandscape} {
    justify-content: space-between;
    flex-direction: row;
    align-items: center;
  }

  p {
    margin-bottom: 0;
  }
`;

export const StyledGoogleIcon = styled(MyBusinessIcon)`
  width: 4rem;
  height: 4rem;
  margin-right: 2rem;
`;

export const StyledInputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 2rem;

  @media ${devices.tabletLandscape} {
    margin-bottom: 0;
    margin-right: 2rem;
  }

  .label {
    font-size: 1.4rem;
    line-height: 1;
    font-weight: 500;
    margin-bottom: 1rem;
  }

  .input {
    padding: 0 1.5rem;
    height: 4.1rem;
    width: 100%;
    border: 2px solid ${(props) => lighten(0.4, props.theme.colors.black)};
  }
`;

export const StyledHeaderActions = styled.div`
  display: flex;
  align-items: center;

  @media ${devices.tabletLandscape} {
    margin-right: 2rem;
  }

  .action-item {
    margin: 0 0.3rem;
  }
`;

export const StyledExpandMoreIcon = styled(ExpandMore)`
  width: 3rem;
  height: 3rem;
`;

export const StyledContent = styled(AccordionSummary)`
  .MuiAccordionSummary-content {
    padding-top: 2rem;
    padding-bottom: 2rem;
    display: flex;
    flex-direction: column;
    cursor: default;

    @media ${devices.widescreen} {
      flex-direction: row;
      align-items: flex-start;
    }
  }

  .dropdown-google-accounts {
    padding: 2rem;
    height: auto;
    border-right: none;
    
    @media ${devices.tabletLandscape} {
      flex: 1;
      border-left: 0.1rem solid ${props => shade(0.1, props.theme.colors.white)};
    }

    h2 {
      font-size: 1.5rem;
      font-weight: bold;
    }

    /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#e8e8e8+0,ffffff+18 */
    background: rgb(232,232,232); /* Old browsers */
    background: -moz-linear-gradient(top,  rgba(232,232,232,1) 0%, rgba(255,255,255,1) 18%); /* FF3.6-15 */
    background: -webkit-linear-gradient(top,  rgba(232,232,232,1) 0%,rgba(255,255,255,1) 18%); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to bottom,  rgba(232,232,232,1) 0%,rgba(255,255,255,1) 18%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#e8e8e8', endColorstr='#ffffff',GradientType=0 ); /* IE6-9 */    
  }
`;

export const StyledConfigContent = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  @media ${devices.tabletLandscape} {
    flex-direction: row;
  }

  @media ${devices.tabletLandscape} {
    flex: 1;
  }
`;

export const StyledHeaderLogoWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 2rem;

  @media ${devices.tabletLandscape} {
    margin-bottom: 0;
  }
`;

export const StyledActionsWrapper = styled.div`
  display: flex;
`;

export const StyledIconLabel = styled.h2`
  margin-bottom: 0;
  font-weight: 400;
  color: ${(props) => props.theme.colors.grey};
`;

export const StyledIconLabelBold = styled.h2`
  font-weight: 700;
  margin-bottom: 0;
  margin-right: 0.7rem;
  color: ${(props) => props.theme.colors.grey};
`;

export const StyledAddConnectionsButton = styled.button<{selected: boolean}>`
  border-radius: 2rem 0 0 2rem;
  flex: 1;
  line-height: 1.2rem;
  color: ${(props) => props.theme.colors.white};
  background-color: ${(props) => (props.selected
    ? props.theme.colors.secondary
    : shade(0.1, props.theme.colors.primary))};
  border: none;
  padding: 0.75rem 1.25rem;
  font-size: 1.3rem;
  border-right: 1px solid white;
  &:focus {
    outline: none;
  }
`;

export const StyledShowConnectionsButton = styled.button<{selected: boolean}>`
  border-radius: 0 2rem 2rem 0;
  line-height: 1.2rem;
  flex: 1;
  color: ${(props) => props.theme.colors.white};
  background-color: ${(props) => (props.selected ? props.theme.colors.secondary : props.theme.colors.primary)};
  border: none;
  padding: 0.75rem 1.25rem;
  font-size: 1.3rem;
  &:focus {
    outline: none;
  }
`;

export const StyledNoAccountLinkedWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 2rem;
`;

export const StyledConnectButton = styled.button`
  border: none;
  border-radius: 20px;
  color: ${(props) => props.theme.colors.white};
  background-color: ${(props) => props.theme.colors.primary};
  padding: 0.75rem 1.25rem;
  width: 15rem;
`;

export const StyledGroupingAndAccount = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;

`;
