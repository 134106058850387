import { Dialog } from '@material-ui/core';
import styled from 'styled-components';

export const StyledDialog = styled(Dialog)`
  .MuiDialog-paperWidthSm {
    width: 60rem;
  } 
`;
export const StyledAddMediaForm = styled.div`
  padding: 2rem 3rem 4rem;
  
  p {
    font-size: 2rem;
  }
`;

export const StyledAddMediaFormContent = styled.div`
  margin-top: 2rem;
`;

export const StyledPublishButton = styled.div`
  display: flex;
  justify-content: flex-end;
  
  .is-uploaded-media {
    margin-right: 1rem;
  }
`;
