import React from 'react';

import Switch from './switch';

import { StyledInputSwitch } from './input-switch-with-neutral-styles';
import { InputSwitchWithNeutralBaseProps } from '../../types/TInputSwitchWithNeutralBaseProps';

const InputSwitchWithNeutral = ({
  label,
  checked,
  changeChecked,
  name,
  alignRow,
  fieldRefSwitch,
  className,
  disabled = false,
  secondarySwitch,
}: InputSwitchWithNeutralBaseProps) => (
  <StyledInputSwitch
    alignRow={alignRow}
    className={className}
  >
    {label && <label htmlFor={name}>{label}</label>}

    <Switch
      fieldRefSwitch={fieldRefSwitch}
      name={name}
      id={name}
      disabled={disabled}
      checked={checked}
      changeChecked={changeChecked}
      secondarySwitch={secondarySwitch}
    />
  </StyledInputSwitch>
);

export default InputSwitchWithNeutral;
