import React, { ChangeEvent, useState } from 'react';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import RateReviewOutlinedIcon from '@material-ui/icons/RateReviewOutlined';
import DeleteIcon from '@material-ui/icons/Delete';

import {
  StyledTitle,
  StyledTooltip,
  IconTooltip,
  StyledAccordionDetails,
  StyledAccordionSummary,
  StyledContainer,
  StyledGroupButton,
} from './automatic-replies-styles';

import { ButtonV2 } from '../../../components';
import FilterAutomaticReplice from './filter-automatic-replies';
import CardAutomaticReplies from '../../../components/card-automatic-replies';
import AutomaticRepliesDialog from '../../../components/automatic-replies-dialog';

const AutomaticReplies = () => {
  const [expanded, setExpanded] = useState<string | false>(false);
  const [isAutomaticRepliesDialogOpen, setIsAutomaticRepliesDialogOpen] = useState(false);
  const [isEdit, setIsEdit] = useState(false);

  const handleChange = (panel: string) => (event: ChangeEvent<{}>, isExpanded: boolean) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <StyledContainer
      expanded={expanded === 'panel1'}
      onChange={handleChange('panel1')}
    >
      <StyledAccordionSummary expandIcon={<ExpandMoreIcon />}>
        <StyledTitle>Respostas Automáticas</StyledTitle>
        <StyledTooltip
          title="Tooltip Respostas Automáticas"
        >
          <IconTooltip />
        </StyledTooltip>
      </StyledAccordionSummary>

      <StyledAccordionDetails>
        <FilterAutomaticReplice
          label="Filtro"
          placeholder="Busca por palavras"
        />
        <StyledGroupButton>
          <ButtonV2
            typeButton="PRIMARY"
            icon={<RateReviewOutlinedIcon />}
            onClick={() => setIsAutomaticRepliesDialogOpen(true)}
          >
            Criar Resposta Automática
          </ButtonV2>
          <ButtonV2
            typeButton="GREY"
            icon={<DeleteIcon />}
          >
            Excluir Selecionada
          </ButtonV2>
        </StyledGroupButton>

        <CardAutomaticReplies
          company="Hublocal"
          onClickIconEmojis={() => {}}
          onClickIconUser={() => {}}
          onClickIconLocalization={() => {}}
          handleButtonConclude={() => {}}
          handleButtonEdit={() => setIsEdit(true)}
          handleButtonCancel={() => setIsEdit(false)}
          isEdit={isEdit}
        />

        <AutomaticRepliesDialog
          isOpen={isAutomaticRepliesDialogOpen}
          onClose={() => setIsAutomaticRepliesDialogOpen(false)}
        >
          <CardAutomaticReplies
            company="Hublocal"
            removeCheckbox
            onClickIconEmojis={() => {}}
            onClickIconUser={() => {}}
            onClickIconLocalization={() => {}}
            handleButtonConclude={() => {}}
            handleButtonCancel={() => setIsAutomaticRepliesDialogOpen(false)}
            isEdit
          />
        </AutomaticRepliesDialog>

      </StyledAccordionDetails>
    </StyledContainer>
  );
};

export default AutomaticReplies;
