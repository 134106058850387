import styled, { css } from "styled-components";
import { shade } from "polished";

export const StyledAlert = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 0.5rem;
  font-size: 1rem;
  padding: 0.75rem 1.25rem;
  width: 100%;
  background: ${(props) => props.theme.colors.grey};
  color: ${(props) => shade(0.25, props.theme.colors.grey)};
  border: 0.1rem solid transparent;
  text-align: center;

  ${(props) =>
    props.type === "secondary" &&
    css`
      background: ${(props) => props.theme.colors.secondary};
      color: ${(props) => props.theme.colors.white};
      border-color: ${(props) => props.theme.colors.secondary};
    `}

  ${(props) =>
    props.type === "sucess" &&
    css`
      background: ${(props) => props.theme.colors.alertSucessBg};
      color: ${(props) => props.theme.colors.alertSucessColor};
      border-color: ${(props) => props.theme.colors.alertSucessBorder};
    `}

    ${(props) =>
    props.type === "danger" &&
    css`
      background: ${(props) => props.theme.colors.alertDangerBg};
      color: ${(props) => props.theme.colors.alertDangerColor};
      border-color: ${(props) => props.theme.colors.alertDangerBorder};
    `}

    ${(props) =>
    props.type === "warning" &&
    css`
      background: ${(props) => props.theme.colors.alertWarningBg};
      color: ${(props) => props.theme.colors.alertWarningColor};
      border-color: ${(props) => props.theme.colors.alertWarningBorder};
    `}
`;
