import React from 'react';

import ConfigCard from '../../../components/config-card';
import { StyledLoginHistory } from './login-history-styles';

const LoginHistory = ({ navigation }: any) => (
  <ConfigCard title="Histórico de Logins" onClick={() => navigation.push('/login-history')}>
    <StyledLoginHistory>Registro de todos os logins feitos pelos usuários dentro da plataforma.</StyledLoginHistory>
  </ConfigCard>
);

export default LoginHistory;
