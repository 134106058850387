import * as R from 'ramda';

export const getActiveCompanyId = (activeCompany) => {
  if (R.isNil(activeCompany) || R.isEmpty(activeCompany)) return [];

  return activeCompany?.length > 1 ? activeCompany[0].id : activeCompany.id;
};

export const getActiveCompanyData = (activeCompany) => {
  if (R.isNil(activeCompany) || R.isEmpty(activeCompany)) return [];

  return activeCompany?.length > 1 ? activeCompany[0] : activeCompany;
};

export const hasActiveCompany = (activeCompany) => {
  if (R.isNil(activeCompany) || R.isEmpty(activeCompany)) return false;

  return !(activeCompany?.length > 1);
};
