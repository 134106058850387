import React, { useEffect, useMemo, useState } from 'react';
import { TableBodyCell, TableBodyRow } from '../../../components';
import { TTableRowData } from '../helpers';
import { TRenderCellDataLoginHistoryRow, TServiceLoginHistoryRowColumnsName } from './helpers';

const RowTableLoginHistory = ({ rowData, rowId }: any) => {
  const [currentRowData, setCurrentRowData] = useState<TTableRowData[]>([]);

  useEffect(() => {
    setCurrentRowData(rowData);
  }, [rowData]);

  const renderCellData = useMemo<TRenderCellDataLoginHistoryRow>(
    () => ({
      id: (value) => value,
      user_name: (value) => value,
      email: (value) => value,
      ip_address: (value) => value,
      ip_location: (value) => value,
      created_at: (value) => value,
    }),
    [],
  );
  return (
    <TableBodyRow
      key={rowId}
      dataRow={rowData}
      hiddenCheckbox
    >
      {currentRowData.map(
        (dataColumnCell: any) => dataColumnCell?.visible && (
        <TableBodyCell>
          {renderCellData[
                dataColumnCell.columnRef as TServiceLoginHistoryRowColumnsName
          ]?.(dataColumnCell.value)}
        </TableBodyCell>
        ),
      )}
    </TableBodyRow>
  );
};

export default RowTableLoginHistory;
