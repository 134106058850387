import React from 'react';
import { useHistory } from 'react-router-dom';
import { Helmet } from 'react-helmet';

import {
  DataTable,
  HeaderToolbar,
  HealderToolbarAction,
} from '../../components';

import { useLocationGroups } from '../../hooks';

import {
  StyledMyLocationGroups,
  StyledContent,
} from './my-location-group-styles';

const MyLocations = () => {
  const {
    activeTab,
    handleChangeTab,
    dataCount,
    page,
    pageSize,
    searchQuery,
    guestDataCount,
    setPage,
    setPageSize,
    setSearchQuery,
    columns,
    data,
    guestData,
    loading,
    onLocationGroupLocationsClicked,
    onDeleteLocationGroupClicked,
    onUpdateLocationGroupClicked,
  } = useLocationGroups();

  const history = useHistory();

  const [normalizedColumns, setNormalizedColumns] = React.useState([]);

  React.useEffect(() => {
    const newColumns = columns.map(column => {
      if (column.name === 'Status') {
        return {
          ...column,
          name: 'Está ativa?',
        };
      }

      return column;
    });
    setNormalizedColumns(newColumns);
  }, []);

  const a11yProps = (index) => ({
    id: `full-width-tab-${index}`,
    'aria-controls': `full-width-tabpanel-${index}`,
    className: 'tabbar-tab',
  });

  return (
    <StyledMyLocationGroups>
      <Helmet>
        <meta property="og:title" content="Meus locais - SaaS Hublocal" />
        <meta property="og:description" content="Gerencie seus locais." />

        <title>Meus locais - SaaS Hublocal</title>
      </Helmet>

      <HeaderToolbar
        title="Grupos de Locais"
      >
        <HealderToolbarAction
          title="Adicionar Grupo de locais"
          icon="AddLocation"
          onClick={() => history.push('/location-groups/create')}
        />
      </HeaderToolbar>

      <StyledContent>
        <DataTable
          count={activeTab === 0 ? dataCount : guestDataCount}
          page={page}
          setPage={setPage}
          pageSize={pageSize}
          setPageSize={setPageSize}
          searchQuery={searchQuery}
          setSearchQuery={setSearchQuery}
          loading={loading}
          data={activeTab === 0 ? data : guestData}
          columns={normalizedColumns}
          clickable={false}
          firstOperationName="Locais"
          onFirstOperationClicked={onLocationGroupLocationsClicked}
          onUpdateClicked={onUpdateLocationGroupClicked}
          onDeleteClicked={onDeleteLocationGroupClicked}
          addRegisterLabel="Adicionar Grupo de Locais"
          addRegisterLink="/location-groups/create"
          emptyTableTitle="Esta lista não contém nenhum grupo de local"
          emptyTableTitleButton="Adicionar Grupo de Local"
        />
      </StyledContent>
    </StyledMyLocationGroups>
  );
};

export default MyLocations;
