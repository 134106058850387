import styled, { css } from 'styled-components';
import { LinearProgress } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

import { light } from '../../assets/styles/themes';

export const StyledProgressBarWrapper = styled.div`
  position: relative;
  padding-top: 3rem;
`;

export const StyledProgressValue = styled.div`
  position: absolute;
  top: 0; 
  left: 0;
  
  ${props => props.isDifference && css`
    margin-left: -3rem;
  `}
`;

export const StyledProgress = styled.div`
  width: 1.9rem;
  height: 1.9rem;
  border-radius: 50%;
  background-color: #fff;
  border: solid;
  position: absolute;
  left: 0;
  z-index: 2;
  bottom: -0.4rem;

  ${props => props.isDifference && css`
    margin-left: -1.8rem;
  `}
`;

const BorderLinearProgress = withStyles({
  root: {
    height: 10,
    width: '100%',
    borderRadius: 5,
  },
  colorPrimary: {
    /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#f33604+0,e6a805+51,19f334+100 */
    background: '#f33604', /* Old browsers */
    background: '-moz-linear-gradient(left,  #f33604 0%, #e6a805 51%, #19f334 100%)', /* FF3.6-15 */
    background: '-webkit-linear-gradient(left,  #f33604 0%,#e6a805 51%,#19f334 100%)', /* Chrome10-25,Safari5.1-6 */
    background: 'linear-gradient(to right,  #f33604 0%,#e6a805 51%,#19f334 100%)', /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: 'progid:DXImageTransform.Microsoft.gradient( startColorstr=#f33604, endColorstr=#19f334,GradientType=1 )', /* IE6-9 */
  },
  bar: {
    borderRadius: 5,
    backgroundColor: light.colors.primary,
  },
})(LinearProgress);

export const StyledProgressBar = styled(BorderLinearProgress)``;
