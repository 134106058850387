import React from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { isNil, isEmpty } from 'ramda';
import IsNilOrEmpty from '../../utils/is-null-or-empty';

import { TRootStateRedux } from '../../types/TRootStateRedux';

import useAuth from '../use-auth';

import {
  companyRoutes,
  locationRoutes,
  permissionsList,
} from './helpers';

const useHasAccess = () => {
  const { pathname } = useLocation();

  const {
    userId,
    userProfileName,
  } = useAuth();

  const [currentActiveGroup, setCurrentActiveGroup] = React.useState<any>(null);
  const [pathType, setPathType] = React.useState<string | null>(null);
  const [hasAccess, setHasAccess] = React.useState<boolean>(false);
  const [guestType, setGuestType] = React.useState<string | null>(null);

  React.useEffect(() => {
    if (userProfileName !== 'Usuário Padrão') return;
    if (isNil(companyRoutes[pathname]) && isNil(locationRoutes[pathname])) return;
    if (!isNil(companyRoutes[pathname])) {
      setPathType('Company');
      setGuestType('Company');
      return;
    }

    if (!isNil(locationRoutes[pathname])) {
      setPathType('Location');
    }
  }, [
    pathname,
    companyRoutes,
    locationRoutes,
  ]);

  const {
    activeLocation,
  } = useSelector((state: TRootStateRedux) => state.LocationReducer);

  const {
    showLocationsByCompany,
    activeCompany,
  } = useSelector((state: TRootStateRedux) => state.CompanyReducer);

  const {
    showLocationsByGroup,
    activeLocationGroup,
  } = useSelector((state: TRootStateRedux) => state.LocationGroupReducer);

  const getCurrentGroup = React.useCallback(() => {
    if (showLocationsByCompany) {
      setCurrentActiveGroup({
        groupType: 'COMPANY',
        activeGroup: activeCompany,
      });
      return;
    }

    setCurrentActiveGroup({
      groupType: 'LOCATION_GROUP',
      activeGroup: activeLocationGroup,
    });
  }, [
    showLocationsByGroup,
    showLocationsByCompany,
    activeCompany,
    activeLocationGroup,
  ]);

  const handleUserHasAccessToPath = React.useCallback((): boolean => {
    if (userProfileName !== 'Usuário Padrão') return true;
    if (isNil(pathType)) return false;

    if (isNil(currentActiveGroup)) return false;

    if (currentActiveGroup.activeGroup?.owner_user?.id === userId) {
      setGuestType('Owner');
      return true;
    }
    const activeGroupHasMyGuest = !isEmpty(currentActiveGroup.activeGroup?.guests.filter((guest: any) => guest.user?.id === userId));

    if (activeGroupHasMyGuest) {
      setGuestType('CompanyGuest');
      return true;
    }

    if (pathType === 'Location') {
      setGuestType('Location');
      if (isNil(activeLocation)) return false;
      if (isEmpty(activeLocation.guests)) return false;

      const locationHasMyGuest = activeLocation.guests.filter((guest: any) => guest.user?.id === userId);

      if (isEmpty(locationHasMyGuest)) return false;

      if (IsNilOrEmpty(locationHasMyGuest[0].location_access_level_profile?.rules)) return false;
      const guestPermits = JSON.parse(locationHasMyGuest[0].location_access_level_profile?.rules);

      setGuestType('LocationGuest');

      return guestPermits[permissionsList[pathname]];
    }

    return false;
  }, [
    userId,
    userProfileName,
    currentActiveGroup,
    activeLocation,
    pathType,
    permissionsList,
    pathname,
  ]);

  React.useEffect(() => {
    if (userProfileName !== 'Usuário Padrão') return;
    getCurrentGroup();
  }, [getCurrentGroup]);

  React.useEffect(() => {
    setHasAccess(handleUserHasAccessToPath());
  }, [handleUserHasAccessToPath]);

  return {
    hasAccess,
    guestType,
  };
};

export default useHasAccess;
