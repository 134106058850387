import axios from 'axios';

export default async function listLocationPosts({
  accessToken,
  locationId,
  pageToken = '',
  pageSize = 100,
}) {
  const requestOptions = {
    headers: {
      'access-token': accessToken,
    },
  };

  try {
    const { data } = await axios.get(
      `${process.env.REACT_APP_GOOGLE_API_URL}/posts/get-many/${locationId}?pageSize=${pageSize}&pageToken=${pageToken}`,
      requestOptions,
    );

    return data;
  } catch (err) {
    return err.response;
  }
}
