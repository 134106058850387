import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  DialogTitle, List, ListItem, ListItemText, ListItemIcon, ListItemSecondaryAction,
} from '@material-ui/core';
import { Mail } from '@material-ui/icons';
import { StyledDialog } from './verification-dialog-styles';

import { Creators as LocationActions } from '../../../../../../redux/ducks/location';

import Loading from '../../../../../loading';
import Input from '../../../../../input';
import Button from '../../../../../button';
import Snackbar from '../../../../../snackbar';

import sendVerification from '../../../../../../services/google/sendVerification';
import sendAutoVerification from '../../../../../../services/google/sendAutoVerification';
import getLocation from '../../../../../../services/google/getLocation';
import updateLocation from '../../../../../../services/locations/updateLocation';

const VerificationDialog = ({
  isOpen, handleClose, options, loading, googleId, locationId, googleAccessToken, activeLocation,
}) => {
  const dispatch = useDispatch();
  const [loadingSend, setLoadingSend] = React.useState(false);
  const [sentVerification, setSentVerification] = React.useState(false);
  const [isSnackbarOpen, setIsSnackbarOpen] = React.useState(false);
  const [snackBarSeverity, setSnackBarSeverity] = React.useState('success');
  const [snackBarMessage, setSnackbarMessage] = React.useState('');

  const [form, setForm] = React.useState({
    mailerContactName: '',
    phoneNumber: '',
    phoneNumberSms: '',
    emailAddress: '',
  });

  const { user } = useSelector(state => state.AuthReducer);

  React.useEffect(() => {
    setSentVerification(false);
  }, []);

  const handleCloseSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setIsSnackbarOpen(false);
    setSentVerification(false);
  };

  const getVerificationOption = (option) => {
    switch (option.verificationMethod) {
      case 'ADDRESS':
        return {
          label: 'Verificação por correspondencia',
          info: `Será enviada uma carta para o endereço do local : ${option.addressData.address.addressLines}`,
          inputLabel: 'Nome do destinatário',
          inputName: 'mailerContactName',
          value: form.mailerContactName,
          onChange: ({ target: { value } }) => setForm({ ...form, mailerContactName: value }),
          icon: <Mail style={{ fontSize: '3rem' }} />,
          successMessage: 'Será enviada para este endereço uma carta com os dados necessários para continuar a confirmação',
        };
      case 'AUTO':
        return {
          label: 'Verificação Automática',
          info: 'A verificação será feita de forma automática, sem outra ação do usuário necessária',
          icon: <Mail style={{ fontSize: '3rem' }} />,
          successMessage: 'Verificação automática concluída. Seu local será revisado pelo google antes de ser publicado',
        };
      default:
        break;
    }
  };

  const onSendAutoVerificationClicked = async (option) => {
    setLoadingSend(true);
    const { successMessage } = getVerificationOption(option);

    try {
      const data = await sendAutoVerification(
        googleAccessToken,
        googleId,
        locationId,
      );

      dispatch(LocationActions.setActiveLocation({ ...activeLocation, google_verification_status: 'PENDING' }));
      setLoadingSend(false);
      setSentVerification(true);
      setSnackbarMessage(successMessage);
      setIsSnackbarOpen(true);
    } catch (err) {
      setSnackBarSeverity('error');
      setSnackbarMessage('Algo deu errado. Verifique os dados preenchidos e tente novamente');
      setIsSnackbarOpen(true);
    } finally {
      setLoadingSend(false);
    }
  };

  const onSendClicked = async (option) => {
    setLoadingSend(true);
    const { value, successMessage } = getVerificationOption(option);

    const {
      address: {
        addressLines, administrativeArea, locality, postalCode, regionCode,
      },
      serviceArea: { businessType },
    } = await getLocation(googleAccessToken, googleId, locationId);

    try {
      const data = await sendVerification(
        googleAccessToken,
        googleId,
        locationId,
        option.verificationMethod,
        value,
        addressLines,
        regionCode,
        postalCode,
        locality,
        administrativeArea,
        businessType,
      );

      const verificationId = data.verification.name.split('/')[5];
      dispatch(LocationActions.setActiveLocation({ ...activeLocation, google_verification_status: 'PENDING' }));

      setLoadingSend(false);
      setSentVerification(true);
      setSnackbarMessage(successMessage);
      setIsSnackbarOpen(true);
    } catch (err) {
      setSnackBarSeverity('error');
      setSnackbarMessage('Algo deu errado. Verifique os dados preenchidos e tente novamente');
      setIsSnackbarOpen(true);
      console.log(err);
    } finally {
      setLoadingSend(false);
    }
  };

  const handleSubmit = (event, option) => {
    event.preventDefault();
    onSendClicked(option);
  };

  return (
    <StyledDialog open={isOpen} onClose={handleClose} fullWidth maxWidth="md">
      <DialogTitle>
        <span className="dialog-title">Escolha um dos métodos de verificação</span>
      </DialogTitle>
      {loading ? <div className="container"><Loading large /></div>

        : (
          <List>
            {Array.isArray(options) ? options.map(option => {
              if (option.verificationMethod === 'AUTO') {
                const {
                  label, info, icon,
                } = getVerificationOption(option);
                return (
                  <>
                    <ListItem>
                      <ListItemIcon>
                        {icon}
                      </ListItemIcon>
                      <ListItemText
                        primary={<span className="list-primary-text">{label}</span>}
                        secondary={<span className="list-secondary-text">{info}</span>}
                      />
                    </ListItem>
                    <div className="button-div">
                      <Button onClick={() => onSendAutoVerificationClicked(option)} disabled={sentVerification || loadingSend}>
                        {loadingSend ? <Loading large /> : 'Enviar'}
                      </Button>
                    </div>
                  </>
                );
              }
              const {
                label, info, inputLabel, inputName, value, onChange, icon,
              } = getVerificationOption(option);
              return (
                <>
                  <ListItem>
                    <ListItemIcon>
                      {icon}
                    </ListItemIcon>
                    <ListItemText
                      primary={<span className="list-primary-text">{label}</span>}
                      secondary={<span className="list-secondary-text">{info}</span>}
                    />

                  </ListItem>
                  <form onSubmit={(e) => handleSubmit(e, option)}>
                    <Input
                      disabled={sentVerification}
                      className="input"
                      label={inputLabel}
                      inputOptions={{ name: inputName }}
                      value={value}
                      onChange={onChange}
                      border
                    />
                  </form>
                  <div className="button-div">
                    <Button type="submit" onClick={() => onSendClicked(option)} disabled={sentVerification || loadingSend}>
                      {loadingSend ? <Loading large /> : 'Enviar'}
                    </Button>
                  </div>
                </>
              );
            })
              : <div className="container"><span>Não há mais métodos de verificação disponíveis, entre em contato com o suporte do Google ou tente criar um novo local</span></div>}
          </List>
        )}
      <Snackbar
        message={snackBarMessage}
        open={isSnackbarOpen}
        severity={snackBarSeverity}
        onClose={handleCloseSnackbar}
        autoHideDuration={6000}
      />
    </StyledDialog>
  );
};

export default VerificationDialog;
