import React, { useState } from 'react';
import { isNil } from 'lodash';

import APIUpdateLocation from '../../../../services/locations/updateLocation';
import APIGetValidGoogleAccessTokenFromLocation from '../../../../services/getValidGoogleAccessTokenFromLocation';

import { TableBodyRow, TableBodyCell, InputSwitch } from '../../../../components';

import type {
  TRenderCellDataSyncPermissionRow,
  TSyncPermissionRowColumnsName,
  TSyncPermissionsRowBaseProps,
  TTableRowData,
} from '../../../../types/TSyncPermissions';

import { SwitchWrapper } from './sync-permissions-row-styles';

const SyncPermissionsRow = ({
  accessToken,
  rowData,
  rowId,
  dataTableOptions,
  enqueueSnackbar,
  userId,
  userProfile,
}: TSyncPermissionsRowBaseProps) => {
  const [checked, setChecked] = useState(false);
  const [currentRowData, setCurrentRowData] = useState<TTableRowData[]>([]);
  const [isPermitted, setIsPermitted] = useState<boolean>(rowData[3].value);

  React.useEffect(() => {
    setCurrentRowData(rowData);
    setIsPermitted(rowData[3].value);
  }, [rowData]);

  const handleChangeChecked = React.useCallback(async () => {
    const googleAccessTokenResponse = await APIGetValidGoogleAccessTokenFromLocation({
      accessToken,
      userId,
      userProfile,
      feedbackMessage: enqueueSnackbar,
      locationId: rowId,
    });

    if (!googleAccessTokenResponse) return;

    const { googleAccessToken } = googleAccessTokenResponse;

    const newPermission = !isPermitted;
    const updateLocationResponse = await APIUpdateLocation({
      googleAccessToken,
      accessToken,
      inputData: { sync_permission: newPermission },
      locationId: rowId,
      feedbackMessage: enqueueSnackbar,
      shouldNotGenerateRequest: true,
    });

    if (isNil(updateLocationResponse)) return;

    setIsPermitted(prev => !prev);
  }, [rowId, rowData, isPermitted]);

  const renderCellData = React.useMemo<TRenderCellDataSyncPermissionRow>(() => ({
    id: (value) => value,
    company: (value) => value,
    location: (value) => value,
    'sync-permission': (value) => (
      <SwitchWrapper>
        <InputSwitch
          changeChecked={() => handleChangeChecked()}
          checked={isPermitted}
        />
      </SwitchWrapper>
    ),
  }), [isPermitted, rowData, rowId]);

  return (
    <TableBodyRow
      key={rowId}
      dataTableOptions={dataTableOptions}
      dataRow={rowData}
      checked={checked}
      setChecked={setChecked}
      hiddenCheckbox
      subTableColSpan={4}
    >
      {currentRowData.map(
        (dataColumnCell: any) => dataColumnCell?.visible && (
          <TableBodyCell>
            {renderCellData[
              dataColumnCell.columnRef as TSyncPermissionRowColumnsName
            ]?.(dataColumnCell.value)}
          </TableBodyCell>
        ),
      )}
    </TableBodyRow>
  );
};

export default SyncPermissionsRow;
