import styled, { css } from 'styled-components';
import { Update } from '@material-ui/icons';
import { lighten } from 'polished';
import { devices } from '../../assets/styles/devices';

export const StyledTickets = styled.div`
    display: flex;
    flex-direction: column;

    .line-detail {
        display: flex;
    }

    .is-loading{
        margin: 1rem auto;
    }
`;

export const StyledContent = styled(StyledTickets)`
    display: flex;
    flex-direction: column;
    margin-top: 0.2rem;

    @media ${devices.tablet} {
        flex-direction: row;
    }
`;

export const StyledAside = styled.aside`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    max-width: 40rem;
`;

export const StyledRefresh = styled.div`
    display: flex;
    padding: 1rem 2rem;
    margin: 1rem auto;
    display: flex;
    align-items: center;
    justify-content: center;
    background: ${props => lighten(0.80, props.theme.colors.black)};
    border-radius: 3rem;
    font-size: 1.2rem;
    transition: 0.3s;
    cursor: pointer;
    max-height: 4rem;

    &:hover {
        transform: scale(1.1);
        background: ${props => props.theme.colors.secondary};
        color: ${props => props.theme.colors.white};
    }

    ${props => props.isRefresh && css`
        transform: scale(1.1);
        background: ${props.theme.colors.secondary};
        color: ${props.theme.colors.white};
    `};

    .loading-update {
        margin-right: 1rem;
    }
`;

export const StyledIconRefreshTickets = styled(Update)`
    width: 2rem;
    height: 2rem;
    margin-right: 0.5rem;
`;
