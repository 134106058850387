import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { isNil, isEmpty } from 'ramda';

import { Creators as CompanyActions } from '../../redux/ducks/company';
import { Creators as LocationActions } from '../../redux/ducks/location';

import type { TRootStateRedux } from '../../types/TRootStateRedux';
import type { TLocationEntity } from '../../types/TLocation';
import type { TUseCompanyLocationsProps } from '../../types/TUseCompanyLocations';

import filterLocationsOwnerOrGuest from '../../utils/filter-locations-owner-or-guest';

import { useCompany, useAuth } from '..';
import { isLocationFromThisCompany } from './helpers';

const useCompanyLocations = ({ isLocationDropDown = false }: TUseCompanyLocationsProps) => {
  const reduxDispatch = useDispatch();

  const { activeLocation } = useSelector((state: TRootStateRedux) => state.LocationReducer);
  const { showLocationsByCompany } = useSelector((state: TRootStateRedux) => state.CompanyReducer);

  const {
    activeCompanyId,
    activeCompany,
  } = useCompany();

  const {
    userAccessToken,
    userSetTokenLikeExpired,
    userId,
    userProfileName,
  } = useAuth();

  const [currentActiveLocation, setCurrentActiveLocation] = React.useState<TLocationEntity | null>(null);

  const [userCompanyLocations, setUserCompanyLocations] = React.useState([]);
  const [pageIndex, setPageIndex] = React.useState(0);
  const [pageSize, setPageSize] = React.useState(10);
  const [pageQuery, setPageQuery] = React.useState('');
  const [pageQuantity, setPageQuantity] = React.useState(0);
  const [isLoading, setIsLoading] = React.useState(false);

  const [currentActiveCompanyId, setCurrentActiveCompanyId] = React.useState<number | null>(null);
  const [triggerGetAPICompanyLocations, setTriggerGetAPICompanyLocations] = React.useState(true);

  const getCompanyLocations = React.useCallback(async () => {
    if (isNil(currentActiveCompanyId)) return;
    if (isNil(activeCompany)) return;

    const filteredLocationsByUserResponse = await filterLocationsOwnerOrGuest({
      currentCompany: activeCompany,
      userId,
      userProfileName,
      userAccessToken,
      userSetTokenLikeExpired,
      companyLocationsPageQuery: pageQuery,
      companyLocationsPageSize: pageSize,
      companyLocationsPage: pageIndex,
      setCompanyLocationsIsLoading: setIsLoading,
    })();

    if (isNil(filteredLocationsByUserResponse)) return;

    const {
      locationCount,
      locationDataList,
    } = filteredLocationsByUserResponse;

    reduxDispatch(CompanyActions.setCompanyLocations(locationDataList));

    setPageQuantity(Math.ceil(locationCount / pageSize));
    setUserCompanyLocations(locationDataList);
    setTriggerGetAPICompanyLocations(true);
  }, [currentActiveCompanyId, pageIndex, pageQuery]);

  React.useEffect(() => { getCompanyLocations(); }, [getCompanyLocations]);

  const handleSetActiveLocation = React.useCallback(() => {
    const newActiveLocationByCompany = userCompanyLocations[0];

    setCurrentActiveLocation(newActiveLocationByCompany);
    reduxDispatch(LocationActions.setActiveLocation(newActiveLocationByCompany));
  }, [userCompanyLocations]);

  const handleSetActiveLocationToNull = () => {
    setCurrentActiveLocation(null);
    reduxDispatch(LocationActions.setActiveLocation(null));
  };

  React.useEffect(() => {
    const hasCurrentActiveCompanyId = !isNil(currentActiveCompanyId);
    const hasActiveCompanyIdRedux = !isNil(activeCompanyId);

    if (!hasCurrentActiveCompanyId && !hasActiveCompanyIdRedux) return;

    if (!hasCurrentActiveCompanyId && hasActiveCompanyIdRedux) {
      setCurrentActiveCompanyId(activeCompanyId);
      setTriggerGetAPICompanyLocations(false);
      return;
    }

    if (hasCurrentActiveCompanyId && hasActiveCompanyIdRedux) {
      if (currentActiveCompanyId !== activeCompanyId) {
        setCurrentActiveCompanyId(activeCompanyId);
        setTriggerGetAPICompanyLocations(false);
      }
    }
  }, [activeCompanyId, currentActiveCompanyId]);

  const setActiveLocation = React.useCallback(async () => {
    const hasLocation = !isNil(activeLocation);
    const hasUserCompanyLocation = !isEmpty(userCompanyLocations);
    if (isLocationDropDown && !showLocationsByCompany) return;

    if (!hasLocation && !hasUserCompanyLocation) {
      handleSetActiveLocationToNull();
      return;
    }

    if (!hasLocation && hasUserCompanyLocation) {
      handleSetActiveLocation();
      return;
    }

    if (isNil(currentActiveCompanyId)) return;

    const isLocationFromThisCompanyResponse = await isLocationFromThisCompany({
      activeLocationName: activeLocation.name,
      activeCompanyId: currentActiveCompanyId,
      userAccessToken,
    });

    if (!isLocationFromThisCompanyResponse) {
      handleSetActiveLocation();
      return;
    }

    setCurrentActiveLocation(activeLocation);
  }, [
    activeLocation,
    userCompanyLocations,
    currentActiveCompanyId,
    showLocationsByCompany,
  ]);

  React.useEffect(() => {
    if (!triggerGetAPICompanyLocations) return;

    setActiveLocation();
  }, [triggerGetAPICompanyLocations]);

  React.useEffect(() => {
    if (!showLocationsByCompany) return;

    setActiveLocation();
  }, [showLocationsByCompany]);

  return {
    userCompanyLocations,
    currentActiveCompanyId,
    currentActiveLocation,
    setPageIndex,
    setPageQuery,
    setPageQuantity,
    setPageSize,
    setCurrentActiveCompanyId,
    setCurrentActiveLocation,
    setIsLoading,
    pageQuantity,
    pageIndex,
    pageQuery,
    pageSize,
    isLoading,
  };
};

export default useCompanyLocations;
