import React, { useContext, useEffect, useState } from 'react';

import WorkingHoursContext from '../../working-hours-context';

import { Time } from '../../../../../../components';

import TimeActions from '../../time-actions';

import {
  updateTimeRange,
  addNewTimeRange,
  removeTimeRange,
} from './helpers';

import {
  StyledTimeRange,
} from './time-range-styles';

const TimeRange = ({
  open,
  close,
  dropDownToBottom,
  timeRange,
  dayTitle,
  changeIsOpenDay,
  timesCount,
}) => {
  const [timeOpen, setTimeOpen] = useState(open);
  const [timeClose, setTimeClose] = useState(close);

  const { setWorkingHoursPerDay } = useContext(WorkingHoursContext);

  const updateTimeInitialValue = {
    workingHoursStateData: setWorkingHoursPerDay,
    dayTarget: dayTitle,
    dayTargetTimeRange: timeRange,
  };

  const updateOpenTimeRange = updateTimeRange({ ...updateTimeInitialValue, keyTime: 'open' });
  const updateCloseTimeRange = updateTimeRange({ ...updateTimeInitialValue, keyTime: 'close' });

  const handleSetTimeOpen = newOpenTimeValue => {
    setTimeOpen(newOpenTimeValue);
    updateOpenTimeRange(newOpenTimeValue);
  };

  const handleSetTimeClose = newCloseTimeValue => {
    setTimeClose(newCloseTimeValue);
    updateCloseTimeRange(newCloseTimeValue);
  };

  const handleDeleteTime = () => {
    if (timesCount === 1) {
      changeIsOpenDay({
        workingHoursStateData: setWorkingHoursPerDay,
        dayTarget: dayTitle,
        isOpen: false,
      });
      updateCloseTimeRange('00:00');
      updateOpenTimeRange('00:00');

      return;
    }

    removeTimeRange({
      workingHoursStateData: setWorkingHoursPerDay,
      dayTarget: dayTitle,
      timeRange,
    });
  };

  const handleAddNewTimeRange = () => {
    addNewTimeRange({
      workingHoursStateData: setWorkingHoursPerDay,
      dayTarget: dayTitle,
    });
  };

  useEffect(() => {
    setTimeOpen(open);
    setTimeClose(close);
  }, [open, close]);

  return (
    <StyledTimeRange>
      <Time
        className="time"
        label="Abre"
        dropDownToBottom={dropDownToBottom}
        timeValue={timeOpen}
        setTimeValue={handleSetTimeOpen}
        type="open"
        timeRange={timeRange}
        readonly={false}
      />
      <Time
        className="time"
        label="Fecha"
        dropDownToBottom={dropDownToBottom}
        timeValue={timeClose}
        setTimeValue={handleSetTimeClose}
        type="close"
        timeRange={timeRange}
        readonly={false}
      />
      <TimeActions
        handleAddNewTimeRange={handleAddNewTimeRange}
        handleDeleteTimeRange={handleDeleteTime}
      />
    </StyledTimeRange>
  );
};

export default TimeRange;
