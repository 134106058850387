import { isEmpty, isNil } from 'ramda';
import React from 'react';
import { connect } from 'react-redux';

import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';

import { FreeRoute, PrivateRoute } from './components';

import * as Pages from './pages';

type TRoutesProps = {
  user: {
    accessToken: string;
    user: any;
  };
};

function Routes({ user }: TRoutesProps) {
  const isAuthenticated = () => {
    if (isNil(user) || isEmpty(isNil(user))) return false;
    if (user.accessToken === 'expired') return false;

    return true;
  };

  return (
    <Router>
      <Switch>
        <Route
          path="/receive-invite/:inviteId"
          component={Pages.ReceiveInvite}
        />
        <Route
          path="/:token/reset-password"
          component={Pages.ResetPassword}
        />
        <Route path="/forgot-password" component={Pages.ForgotPassword} />
        <FreeRoute
          exact
          isAuthenticated={isAuthenticated}
          path="/:token/validate-user"
          component={Pages.Login}
        />
        <FreeRoute
          exact
          isAuthenticated={isAuthenticated}
          path="/"
          component={Pages.Login}
        />
        <FreeRoute
          exact
          isAuthenticated={isAuthenticated}
          path="/receive-foursquare-code"
          component={Pages.Register}
        />

        <PrivateRoute
          currentUser={user?.user}
          exact
          isAuthenticated={isAuthenticated}
          adminAndOperatorOnly
          path="/my-company-groups"
          component={Pages.MyCompanyGroups}
        />
        <PrivateRoute
          currentUser={user?.user}
          exact
          isAuthenticated={isAuthenticated}
          path="/my-companies"
          component={Pages.MyCompanies}
        />
        <PrivateRoute
          currentUser={user?.user}
          exact
          isAuthenticated={isAuthenticated}
          path="/my-locations"
          component={Pages.LocationList}
        />
        <PrivateRoute
          currentUser={user?.user}
          exact
          adminAndOperatorOnly
          isAuthenticated={isAuthenticated}
          path="/company/info-models"
          component={Pages.CompanyInfoModels}
        />
        <PrivateRoute
          currentUser={user?.user}
          exact
          adminAndOperatorOnly
          isAuthenticated={isAuthenticated}
          path="/functions/create"
          component={Pages.CreateOrUpdateFunction}
        />
        <PrivateRoute
          currentUser={user?.user}
          exact
          adminAndOperatorOnly
          isAuthenticated={isAuthenticated}
          path="/functions/list"
          component={Pages.FunctionList}
        />
        <PrivateRoute
          currentUser={user?.user}
          exact
          adminAndOperatorOnly
          isAuthenticated={isAuthenticated}
          path="/functions/update/:id"
          component={Pages.CreateOrUpdateFunction}
        />
        <PrivateRoute
          currentUser={user?.user}
          exact
          adminAndOperatorOnly
          isAuthenticated={isAuthenticated}
          path="/groups/list"
          component={Pages.GroupList}
        />
        <PrivateRoute
          currentUser={user?.user}
          exact
          adminAndOperatorOnly
          isAuthenticated={isAuthenticated}
          path="/groups/create"
          component={Pages.CreateOrUpdateGroup}
        />
        <PrivateRoute
          currentUser={user?.user}
          exact
          adminAndOperatorOnly
          isAuthenticated={isAuthenticated}
          path="/groups/update/:id"
          component={Pages.CreateOrUpdateGroup}
        />
        <PrivateRoute
          currentUser={user?.user}
          exact
          adminAndOperatorOnly
          isAuthenticated={isAuthenticated}
          path="/menus/list"
          component={Pages.MenuList}
        />
        <PrivateRoute
          currentUser={user?.user}
          exact
          adminAndOperatorOnly
          isAuthenticated={isAuthenticated}
          path="/menus/create"
          component={Pages.CreateOrUpdateMenu}
        />
        <PrivateRoute
          currentUser={user?.user}
          exact
          adminAndOperatorOnly
          isAuthenticated={isAuthenticated}
          path="/menus/update/:id"
          component={Pages.CreateOrUpdateMenu}
        />
        <PrivateRoute
          currentUser={user?.user}
          exact
          adminAndOperatorOnly
          isAuthenticated={isAuthenticated}
          path="/profiles/list"
          component={Pages.ProfileList}
        />
        <PrivateRoute
          currentUser={user?.user}
          exact
          adminAndOperatorOnly
          isAuthenticated={isAuthenticated}
          path="/deleted-companies/list"
          component={Pages.DeletedCompaniesList}
        />
        <PrivateRoute
          currentUser={user?.user}
          exact
          adminAndOperatorOnly
          isAuthenticated={isAuthenticated}
          path="/deleted-locations/list"
          component={Pages.DeletedLocationsList}
        />
        <PrivateRoute
          currentUser={user?.user}
          exact
          adminAndOperatorOnly
          isAuthenticated={isAuthenticated}
          path="/deleted-company-groups/list"
          component={Pages.DeletedCompanyGroupsList}
        />
        <PrivateRoute
          currentUser={user?.user}
          exact
          adminAndOperatorOnly
          isAuthenticated={isAuthenticated}
          path="/deleted-location-groups/list"
          component={Pages.DeletedLocationGroupsList}
        />
        <PrivateRoute
          currentUser={user?.user}
          exact
          isAuthenticated={isAuthenticated}
          path="/location/report"
          component={Pages.LocationReport}
        />
        <PrivateRoute
          currentUser={user?.user}
          exact
          isAuthenticated={isAuthenticated}
          path="/company/report"
          component={Pages.CompanyReport}
        />
        <PrivateRoute
          currentUser={user?.user}
          exact
          adminAndOperatorOnly
          isAuthenticated={isAuthenticated}
          path="/profiles/create"
          component={Pages.CreateOrUpdateProfile}
        />
        <PrivateRoute
          currentUser={user?.user}
          exact
          adminAndOperatorOnly
          isAuthenticated={isAuthenticated}
          path="/profiles/update/:id"
          component={Pages.CreateOrUpdateProfile}
        />
        <PrivateRoute
          currentUser={user?.user}
          exact
          adminAndOperatorOnly
          isAuthenticated={isAuthenticated}
          path="/handlers/list"
          component={Pages.HandlerList}
        />
        <PrivateRoute
          currentUser={user?.user}
          exact
          adminAndOperatorOnly
          isAuthenticated={isAuthenticated}
          path="/handlers/create"
          component={Pages.CreateOrUpdateHandler}
        />
        <PrivateRoute
          currentUser={user?.user}
          exact
          adminAndOperatorOnly
          isAuthenticated={isAuthenticated}
          path="/handlers/update/:id"
          component={Pages.CreateOrUpdateHandler}
        />
        <PrivateRoute
          currentUser={user?.user}
          exact
          adminAndOperatorOnly
          isAuthenticated={isAuthenticated}
          path="/users/list"
          component={Pages.UserList}
        />
        <PrivateRoute
          currentUser={user?.user}
          exact
          adminAndOperatorOnly
          isAuthenticated={isAuthenticated}
          path="/users/create"
          component={Pages.CreateUser}
        />
        <PrivateRoute
          currentUser={user?.user}
          exact
          adminAndOperatorOnly
          isAuthenticated={isAuthenticated}
          path="/users/update/:id"
          component={Pages.UpdateUser}
        />
        <PrivateRoute
          currentUser={user?.user}
          exact
          adminAndOperatorOnly
          isAuthenticated={isAuthenticated}
          path="/company/working-hours-models"
          component={Pages.MyCompanyScheduleModels}
        />
        <PrivateRoute
          currentUser={user?.user}
          exact
          adminAndOperatorOnly
          isAuthenticated={isAuthenticated}
          path="/company/link-models"
          component={Pages.CompanyLinkModels}
        />
        <PrivateRoute
          currentUser={user?.user}
          exact
          adminAndOperatorOnly
          isAuthenticated={isAuthenticated}
          path="/company/create-link-model"
          component={Pages.CreateLinkModel}
        />
        <PrivateRoute
          currentUser={user?.user}
          exact
          adminAndOperatorOnly
          isAuthenticated={isAuthenticated}
          path="/company/update-link-model/:linkModelId"
          component={Pages.CreateLinkModel}
        />
        <PrivateRoute
          currentUser={user?.user}
          exact
          adminAndOperatorOnly
          isAuthenticated={isAuthenticated}
          path="/company/create-info-model"
          component={Pages.CreateInfoModel}
        />
        <PrivateRoute
          currentUser={user?.user}
          exact
          adminAndOperatorOnly
          isAuthenticated={isAuthenticated}
          path="/company/update-info-model/:infoModelId"
          component={Pages.CreateInfoModel}
        />
        <PrivateRoute
          currentUser={user?.user}
          exact
          adminAndOperatorOnly
          isAuthenticated={isAuthenticated}
          path="/company/create-working-hours-model"
          component={Pages.CreateWorkingHoursModel}
        />
        <PrivateRoute
          currentUser={user?.user}
          exact
          adminAndOperatorOnly
          isAuthenticated={isAuthenticated}
          path="/company/update-working-hours-model/:workingHoursModelId"
          component={Pages.CreateWorkingHoursModel}
        />
        <PrivateRoute
          currentUser={user?.user}
          exact
          adminAndOperatorOnly
          isAuthenticated={isAuthenticated}
          path="/company/create-special-date-model"
          component={Pages.CreateSpecialDateModel}
        />
        <PrivateRoute
          currentUser={user?.user}
          exact
          adminAndOperatorOnly
          isAuthenticated={isAuthenticated}
          path="/company/update-special-date-model/:specialDateModelId"
          component={Pages.CreateSpecialDateModel}
        />
        <PrivateRoute
          currentUser={user?.user}
          exact
          isAuthenticated={isAuthenticated}
          path="/companies/list"
          component={Pages.CompanyList}
        />
        <PrivateRoute
          currentUser={user?.user}
          exact
          isAuthenticated={isAuthenticated}
          path="/companies/create"
          component={Pages.CreateCompany}
        />
        <PrivateRoute
          currentUser={user?.user}
          exact
          adminAndOperatorOnly
          isAuthenticated={isAuthenticated}
          path="/company-group/create"
          component={Pages.CreateCompanyGroup}
        />
        <PrivateRoute
          currentUser={user?.user}
          exact
          isAuthenticated={isAuthenticated}
          path="/companies/update"
          component={Pages.EditCompany}
        />
        <PrivateRoute
          currentUser={user?.user}
          exact
          adminAndOperatorOnly
          isAuthenticated={isAuthenticated}
          path="/company-groups/update"
          component={Pages.EditCompanyGroup}
        />
        <PrivateRoute
          currentUser={user?.user}
          exact
          adminAndOperatorOnly
          isAuthenticated={isAuthenticated}
          path="/location-groups/create"
          component={Pages.CreateLocationGroup}
        />
        <PrivateRoute
          currentUser={user?.user}
          exact
          adminAndOperatorOnly
          isAuthenticated={isAuthenticated}
          path="/location-groups/edit"
          component={Pages.EditLocationGroup}
        />
        <PrivateRoute
          currentUser={user?.user}
          exact
          adminAndOperatorOnly
          isAuthenticated={isAuthenticated}
          path="/location-groups/list-locations"
          component={Pages.LocationGroupLocations}
        />
        <PrivateRoute
          currentUser={user?.user}
          exact
          isAuthenticated={isAuthenticated}
          path="/locations/create"
          component={Pages.CreateLocation}
        />
        <PrivateRoute
          currentUser={user?.user}
          exact
          isAuthenticated={isAuthenticated}
          path="/location/list"
          component={Pages.LocationList}
        />
        <PrivateRoute
          currentUser={user?.user}
          exact
          isAuthenticated={isAuthenticated}
          path="/location/profile"
          component={Pages.LocationProfile}
        />
        <PrivateRoute
          currentUser={user?.user}
          exact
          isAuthenticated={isAuthenticated}
          path="/location/posts"
          component={Pages.LocationPosts}
        />
        <PrivateRoute
          currentUser={user?.user}
          exact
          isAuthenticated={isAuthenticated}
          path="/location/gallery"
          component={Pages.LocationGallery}
        />
        <PrivateRoute
          currentUser={user?.user}
          exact
          isAuthenticated={isAuthenticated}
          path="/location/services"
          component={Pages.LocationServices}
        />
        <PrivateRoute
          currentUser={user?.user}
          exact
          isAuthenticated={isAuthenticated}
          path="/category-grouping"
          component={Pages.CategoryGrouping}
        />
        <PrivateRoute
          currentUser={user?.user}
          exact
          isAuthenticated={isAuthenticated}
          path="/locations-as-guest"
          component={Pages.LocationsAsGuest}
        />
        <PrivateRoute
          currentUser={user?.user}
          exact
          adminAndOperatorOnly
          isAuthenticated={isAuthenticated}
          path="/my-location-groups"
          component={Pages.LocationGroupList}
        />
        <PrivateRoute
          currentUser={user?.user}
          exact
          isAuthenticated={isAuthenticated}
          path="/configurations"
          component={Pages.Configs}
        />
        <PrivateRoute
          currentUser={user?.user}
          exact
          isAuthenticated={isAuthenticated}
          path="/user-configurations"
          component={Pages.UserConfigs}
        />
        <PrivateRoute
          currentUser={user?.user}
          exact
          isAuthenticated={isAuthenticated}
          path="/location-access-level-profiles"
          component={Pages.LocationAccessLevelProfile}
        />
        <PrivateRoute
          currentUser={user?.user}
          exact
          isAuthenticated={isAuthenticated}
          path="/platforms-link-with-location"
          component={Pages.PlatformsLinkWithLocation}
        />
        <PrivateRoute
          currentUser={user?.user}
          exact
          adminAndOperatorOnly
          isAuthenticated={isAuthenticated}
          path="/tickets"
          component={Pages.Tickets}
        />
        <PrivateRoute
          currentUser={user?.user}
          exact
          isAuthenticated={isAuthenticated}
          path="/reviews"
          component={Pages.Reviews}
        />
        <PrivateRoute
          currentUser={user?.user}
          exact
          isAuthenticated={isAuthenticated}
          path="/newreviews"
          component={Pages.NewReviews}
        />
        <PrivateRoute
          currentUser={user?.user}
          exact
          isAuthenticated={isAuthenticated}
          path="/reviews/:location"
          component={Pages.Reviews}
        />
        <PrivateRoute
          currentUser={user?.user}
          exact
          isAuthenticated={isAuthenticated}
          path="/no-access"
          component={Pages.NoAcess}
        />
        <PrivateRoute
          currentUser={user?.user}
          exact
          isAuthenticated={isAuthenticated}
          path="/user-profile"
          component={Pages.UserProfile}
        />
        <PrivateRoute
          currentUser={user?.user}
          exact
          adminAndOperatorOnly
          isAuthenticated={isAuthenticated}
          path="/batch-update-info-form"
          component={Pages.BatchUpdateInfoForm}
        />
        <PrivateRoute
          currentUser={user?.user}
          exact
          adminAndOperatorOnly
          isAuthenticated={isAuthenticated}
          path="/batch-update-info-status"
          component={Pages.BatchUpdateInfoStatus}
        />
        <PrivateRoute
          currentUser={user?.user}
          exact
          adminAndOperatorOnly
          isAuthenticated={isAuthenticated}
          path="/batch-platforms-linked-with-locations"
          component={Pages.BatchPlatformsLinkedWithLocations}
        />
        <PrivateRoute
          currentUser={user?.user}
          exact
          isAuthenticated={isAuthenticated}
          path="/batch-update-posts-status"
          component={Pages.BatchUpdatePostsStatus}
        />
        <PrivateRoute
          currentUser={user?.user}
          exact
          isAuthenticated={isAuthenticated}
          path="/gallery-batch"
          component={Pages.GalleryBatch}
        />
        <PrivateRoute
          currentUser={user?.user}
          exact
          isAuthenticated={isAuthenticated}
          path="/service-batch"
          component={Pages.ServiceBatch}
        />
        <PrivateRoute
          currentUser={user?.user}
          exact
          adminAndOperatorOnly
          isAuthenticated={isAuthenticated}
          path="/sync-monitoring"
          component={Pages.SyncMonitoring}
        />
        <PrivateRoute
          currentUser={user?.user}
          exact
          adminAndOperatorOnly
          isAuthenticated={isAuthenticated}
          path="/bi-monitor"
          component={Pages.BIMonitor}
        />
        <PrivateRoute
          currentUser={user?.user}
          exact
          adminAndOperatorOnly
          isAuthenticated={isAuthenticated}
          path="/login-history"
          component={Pages.LoginHistory}
        />
        <PrivateRoute
          currentUser={user?.user}
          exact
          isAuthenticated={isAuthenticated}
          path="/location/last-sync-history"
          component={Pages.LastSyncHistory}
        />
        <Route
          path="*"
          component={() => <Pages.NotFound404 currentUser={user?.user} />}
        />
      </Switch>
    </Router>
  );
}

export default connect((state: any) => ({
  user: state.AuthReducer.user,
}))(Routes);
