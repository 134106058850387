import React from 'react';
import * as R from 'ramda';

import { StyledBasicInfoContainer, StyledList } from './basic-info-styles';

type TBasicInfosProps = {
  data: any;
  inLargeScale: boolean;
};

function BasicInfos({ data, inLargeScale = false }: TBasicInfosProps) {
  const formatDataToList = React.useCallback((dataToConvert) => {
    if (!dataToConvert) return [];
    const fields = Object.keys(dataToConvert);
    return fields.map((field, index) => {
      let fieldName = field;
      let fieldOrder = index;
      let value = dataToConvert[field];

      if (Array.isArray(value)) {
        const names: string[] = [];
        value.forEach((valueArrayItem) => {
          if (valueArrayItem.name) names.push(valueArrayItem.name);
        });
        value = names.join(', ');
      }

      const dictionary: any = {
        main_phone: { name: 'Telefone principal', order: 1 },
        secondary_phone: { name: 'Telefone secundário', order: 2 },
        google_categories: { name: 'Categorias do Google', order: 3 },
        facebook_categories: { name: 'Categorias do Facebook', order: 4 },
        foursquare_categories: { name: 'Categorias do Foursquare', order: 5 },
        short_description: { name: 'Descrição curta', order: 6 },
        long_description: { name: 'Descrição', order: 7 },
      };

      if (dictionary[field]) {
        fieldName = dictionary[field].name;
        fieldOrder = dictionary[field].order;
      }

      return {
        name: fieldName,
        order: fieldOrder,
        value,
      };
    });
  }, []);

  const isJsonString = (string) => {
    try {
      JSON.parse(string);
    } catch (e) {
      return false;
    }
    return true;
  };

  const handledData = isJsonString(data) ? JSON.parse(data) : data;
  const dataIsArray = Array.isArray(handledData);

  return (
    <StyledBasicInfoContainer>
      {!!data && !R.isEmpty(data) && (
        <StyledList>
          {inLargeScale &&
            formatDataToList(data)
              .sort((firstArg, secondArg) => firstArg.order - secondArg.order)
              .filter((item) => !!item.value)
              .map((item) => (
                <li key={item.name}>
                  <b>{item.name}: </b>{' '}
                  {item.value === 'MUST_BE_EMPTY'
                    ? 'Informação removida'
                    : item.value}
                </li>
              ))}
        </StyledList>
      )}

      {!inLargeScale && !dataIsArray && (
        <StyledList>
          <li>{data === 'MUST_BE_EMPTY' ? 'Informação removida' : data}</li>
        </StyledList>
      )}
      {!inLargeScale && dataIsArray && (
        <StyledList>
          {handledData.map((category) => (
            <li key={category.id}>{category.name}</li>
          ))}
        </StyledList>
      )}
    </StyledBasicInfoContainer>
  );
}

export default BasicInfos;
