import styled from 'styled-components';

export const StyledContainer = styled.div`
`;

export const StyledInfoCell = styled.div`
  display: flex;
`;

export const StyledIconWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;
