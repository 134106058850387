export const websiteScheme = {
  name: 'website',
  nickname: 'Website',
  website: true,
  hasborder: 'yes',
  label: 'Website',
  isDisabledInput: true,
};

export const facebookScheme = {
  name: 'facebook',
  nickname: 'Facebook',
  website: true,
  hasborder: 'yes',
  label: 'Facebook',
  isDisabledInput: true,
};

export const instagramScheme = {
  name: 'instagram',
  nickname: 'Instagram',
  website: true,
  hasborder: 'yes',
  label: 'Instagram',
  isDisabledInput: true,
};

export const twitterScheme = {
  name: 'twitter',
  nickname: 'Twitter',
  website: true,
  hasborder: 'yes',
  label: 'Twitter',
  isDisabledInput: true,
};

export const youtubeScheme = {
  name: 'youtube',
  nickname: 'Youtube',
  website: true,
  hasborder: 'yes',
  label: 'Youtube',
  isDisabledInput: true,
};

export const foursquareScheme = {
  name: 'foursquare',
  nickname: 'Foursquare',
  website: true,
  hasborder: 'yes',
  label: 'Foursquare',
  isDisabledInput: true,
};

export const linkedinScheme = {
  name: 'linkedin',
  nickname: 'Linkedin',
  website: true,
  hasborder: 'yes',
  label: 'Linkedin',
  isDisabledInput: true,
};

export const yelpScheme = {
  name: 'yelp',
  nickname: 'Yelp',
  website: true,
  hasborder: 'yes',
  label: 'Yelp',
  isDisabledInput: true,
};

export const googlemapsScheme = {
  name: 'googlemaps',
  nickname: 'Google Maps',
  website: true,
  hasborder: 'yes',
  label: 'Google Maps',
  isDisabledInput: true,
};

export const wazerScheme = {
  name: 'wazer',
  nickname: 'Wazer',
  website: true,
  hasborder: 'yes',
  label: 'Wazer',
  isDisabledInput: true,
};

export const menuScheme = {
  name: 'menu',
  nickname: 'Cardápio',
  website: true,
  hasborder: 'yes',
  label: 'Cardápio',
  isDisabledInput: true,
};

export const scheduleScheme = {
  name: 'schedule',
  nickname: 'Agendamento',
  website: true,
  hasborder: 'yes',
  label: 'Agendamento',
  isDisabledInput: true,
};

export const reservationsScheme = {
  name: 'reservations',
  nickname: 'Reservas',
  website: true,
  hasborder: 'yes',
  label: 'Reservas',
  isDisabledInput: true,
};

export const advanceOrderScheme = {
  name: 'advance-order',
  nickname: 'Pedido antecipado',
  website: true,
  hasborder: 'yes',
  label: 'Pedido antecipado',
  isDisabledInput: true,
};
