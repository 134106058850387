export const changeIsOpenDay = ({
  workingHoursStateData,
  dayTarget,
  isOpen,
}) => {
  const updateWorkingHoursDay = workingHoursDay => {
    if (workingHoursDay.day_title === dayTarget) {
      return {
        ...workingHoursDay,
        is_open: isOpen,
      };
    }
    return workingHoursDay;
  };

  const workingHouserUpdateStateData = prevStateData => prevStateData.map(updateWorkingHoursDay);

  workingHoursStateData(workingHouserUpdateStateData);
};

export const changeIs24Hours = ({
  workingHoursStateData,
  dayTarget,
  is24hours,
}) => {
  const updateWorkingHoursDay = workingHoursDay => {
    if (workingHoursDay.day_title === dayTarget) {
      return {
        ...workingHoursDay,
        is_24_hours: is24hours,
      };
    }
    return workingHoursDay;
  };

  const workingHouserUpdateStateData = prevStateData => prevStateData.map(updateWorkingHoursDay);

  workingHoursStateData(workingHouserUpdateStateData);
};
