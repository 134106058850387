import styled from 'styled-components';

export const StyledSubPlatform = styled.li`
    display: flex;
    align-items: center;
    margin-bottom: 1rem;

    &:last-of-type {
        margin-bottom: 0;
    }

    .subplatform-icon {
        width: 2rem;
        height: 2rem;
        margin-right: 0.5rem;
    }
`;
