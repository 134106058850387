import styled from 'styled-components';
import { Paper, Popover } from '@material-ui/core';
import {
  ArrowDropDown as ArrowDropDownIcon,
  ArrowDropUp as ArrowDropUpIcon,
} from '@material-ui/icons';
import { shade, lighten } from 'polished';

export const StyledLocationsFilter = styled.div`
  width: 100%;
  margin-bottom: 3rem;
  max-width: 90rem;
`;

export const StyledContent = styled(Paper)`
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  padding: 2rem;
`;

export const StyledChooseLocation = styled.div`
  display: flex;
  align-items: flex-end;
  cursor: pointer;
  position: relative;
  z-index: 2;

  &:hover {
    text-decoration: underline;
  }

  .title {
    font-size: 1.4rem;
    line-height: 1;
  }
`;

export const StyledArrowDropUpIcon = styled(ArrowDropUpIcon)`
  width: 2rem;
  height: 2rem;
`;

export const StyledArrowDropDownIcon = styled(ArrowDropDownIcon)`
  width: 2rem;
  height: 2rem;
`;

export const StyledPopoverListing = styled(Popover)`
  .MuiPopover-paper {
    box-shadow: 0px 5px 5px -3px rgb(0 0 0 / 20%),
      0px 8px 10px 1px rgb(0 0 0 / 14%), 0px 3px 14px 2px rgb(0 0 0 / 12%);
  }
`;

export const StyledPopoverListingContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;

  .search-to-locations {
    padding: 1rem 2rem;
    border-bottom: 1px solid ${(props) => shade(0.05, props.theme.colors.white)};
  }
`;

export const StyledLocationListing = styled.ul`
  width: 100%;
  max-height: 30rem;
  display: flex;
  flex-direction: column;
  overflow-y: scroll;

  li {
    font-size: 1.4rem;
    padding: 1rem 2rem;
    cursor: pointer;
    transition: 0.3s;

    &:nth-child(even) {
      background: ${(props) => shade(0.05, props.theme.colors.white)};
    }

    &:hover {
      background: ${(props) => props.theme.colors.primary};
      color: ${(props) => props.theme.colors.white};
    }
  }
`;

export const StyledLocationsToFilter = styled.ul`
  display: flex;
  flex-wrap: wrap;
  align-content: center;
`;

export const StyledLocationsToFilterItem = styled.li`
  padding: 1rem 2rem;
  padding: 1rem 2rem;
  border: 1px solid ${(props) => props.theme.colors.primary};
  color: ${(props) => props.theme.colors.primary};
  font-weight: bold;
  border-radius: 3rem;
  margin: 0.5rem;

  display: flex;
  align-items: center;

  .location-to-filter-remove {
    width: 2rem;
    height: 2rem;
    background: ${(props) => props.theme.colors.primary};
    margin-left: 1rem;

    .icon {
      width: 1.5rem;
      height: 1.5rem;
      fill: ${(props) => props.theme.colors.white} !important;
    }
  }
`;

export const StyledSpan = styled.span`
  color: ${(props) => lighten(0.6, props.theme.colors.black)};
  margin-top: 1rem;
`;

export const StyledLocationFilterPopover = styled.div`
  position: absolute;
  width: 100%;
  border-radius: 0.5rem;
  top: 3.5rem;
  z-index: 10;
  background: ${(props) => props.theme.colors.white};
  box-shadow: 0px 5px 5px -3px rgb(0 0 0 / 20%),
    0px 8px 10px 1px rgb(0 0 0 / 14%), 0px 3px 14px 2px rgb(0 0 0 / 12%);
`;

export const StyledLocationFilterWrapper = styled.div`
  display: flex;
  position: relative;
  margin-bottom: 2rem;

  .locations-filter__search {
    width: 100%;
  }
`;
