import styled, { css } from 'styled-components';
import { lighten, shade } from 'polished';

import { StarRate } from '@material-ui/icons';

export const StyledResponse = styled.div`
    display: flex;
    flex-direction: column;
    padding: 1rem 2rem;
    border-left: 0.4rem solid ${props => props.theme.colors.primary};
    background: ${props => lighten(0.92, props.theme.colors.black)};
    width: 100%;
    transition: 0.3s;
    
    .quotation-mark {
        font-size: 2rem;
        font-weight: bold;
        color: ${props => props.theme.colors.primary};
    }

    .options{
        display: flex;
        justify-content: flex-end;
    }

    .infos-of-this-response {
        display: flex;
        width: 100%;
        align-items: center;
        justify-content: space-between;
        margin-top: 2rem;
        margin-left: auto;
        border-top: 1px solid ${props => lighten(0.80, props.theme.colors.black)};
        padding-top: 0.5rem;

        span {
            font-size: 1.2rem;
            margin-right: 1rem;
            font-weight: bold;

            &:last-child {
                margin-right: 0;
            }
        }

        small {
            font-size: 1.2rem;
            margin-left: 0.5rem;
        }
    }

    ${props => props.isSelected && css`
        background: ${props => props.theme.colors.primary};
        color: ${props => props.theme.colors.white};
        border: 1px solid ${props => props.theme.colors.primary};
        border-left: 1rem solid ${props => props.theme.colors.primary};

        .icon-option {
            &:last-child {
                margin-right: 0;
            }

            .icon {
                fill: ${props => props.theme.colors.white} !important;
            }
        }

        .quotation-mark,
        .response-text-quotation {
            color: ${props => shade(0.1, props.theme.colors.white)};
        }

        .infos-of-this-response {
            color: ${props => props.theme.colors.white};
        }
    `}
`;

export const StyledStar = styled(StarRate)`
  width: 2rem;
  height: 2rem;
  cursor: pointer;
  fill: ${props => props.theme.colors.god};
`;

export const StyledStarWrapper = styled.div`
  display: flex;
  flex-direction: row;
`;
