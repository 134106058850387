import React from 'react';
import { useSelector } from 'react-redux';
import * as R from 'ramda';

import { Button, Pagination } from '../../../../../components';
import { useUncontrolledField } from '../../../../../hooks';

import ResponseOption from './response-option';

import {
  replyCommentField,
} from './filds-scheme';

import {
  defaultResponsesMocked,
} from '../helpers';

import {
  StyledReviewReply,
  StyledButtons,
  StyledPopoverDefaultResponses,
  StyledResponseOptions,
} from './review-reply-styles';

import getAllFromCompany from '../../../../../services/auto-responses/getAllFromCompany';

const ReplyComment = ({
  stars,
  cancelReply,
  onReplyClicked,
  editReplyComment,
  reviewData,
}) => {
  const [fields, setFields] = React.useState([]);
  const [anchorElToPopover, setAnchorElToPopover] = React.useState(null);
  const [page, setPage] = React.useState(0);
  const [pageSize, setPageSize] = React.useState(10);
  const [query, setQuery] = React.useState('');
  const [isFetching, setIsFetching] = React.useState(false);
  const [defaultResponses, setDefaultResponses] = React.useState([]);
  const [totalAmount, setTotalAmount] = React.useState(0);

  const { user: { accessToken } } = useSelector(state => state.AuthReducer);
  const { activeCompany: { id: companyId } } = useSelector(state => state.CompanyReducer);

  const { Field: ReplyCommentField } = useUncontrolledField({
    fieldScheme: replyCommentField,
    saveIn: setFields,
  });

  const getAutoResponses = React.useCallback(async () => {
    const data = await getAllFromCompany({
      accessToken, companyId, stars, page, pageSize, query, setIsFetching,
    });
    setDefaultResponses(data[0]);
    setTotalAmount(Math.ceil(data[1] / pageSize));
  }, [page, pageSize]);

  React.useEffect(() => { getAutoResponses(); }, [getAutoResponses]);

  React.useEffect(() => {
    const setDefaultValue = fields.map(field => field.setDefaultValue)[0];

    if (!R.isNil(editReplyComment) && !R.isEmpty(fields)) {
      setDefaultValue(editReplyComment);
    }
  }, [editReplyComment, fields]);

  const openPopover = Boolean(anchorElToPopover);
  const id = openPopover ? 'simple-popover' : undefined;

  const handleSetDefaultResponse = defaultResponse => {
    const setDefaultValue = fields.map(field => field.setDefaultValue)[0];

    setDefaultValue(defaultResponse);
    setAnchorElToPopover(null);
  };

  return (
    <StyledReviewReply>
      <ReplyCommentField />
      <StyledButtons>
        <StyledResponseOptions
          aria-describedby={id}
          onClick={({ currentTarget }) => setAnchorElToPopover(currentTarget)}
        >
          Opções de resposta
        </StyledResponseOptions>

        <Button
          className="button"
          onClick={cancelReply}
          buttonType="cancel"
        >
          Cancelar
        </Button>

        <Button
          onClick={() => {
            onReplyClicked({
              reply: fields[0].getValue(),
            });
          }}
          className="button"
        >
          Postar resposta
        </Button>
      </StyledButtons>

      <StyledPopoverDefaultResponses
        id={id}
        open={openPopover}
        anchorEl={anchorElToPopover}
        onClose={() => setAnchorElToPopover(null)}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
      >
        <ul>
          {defaultResponses
          && defaultResponses.map(defaultResponse => (
            <ResponseOption
              key={Math.random(Math.floor())}
              setDefaultResponse={handleSetDefaultResponse}
              responseText={defaultResponse.text}
            />
          ))}
        </ul>
        <Pagination page={page} setPage={setPage} pagesQuantity={totalAmount} />
      </StyledPopoverDefaultResponses>
    </StyledReviewReply>
  );
};

export default ReplyComment;
