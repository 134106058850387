import styled from 'styled-components';

export const StyledSection = styled.section`
  width: 100%;
  padding: 1rem 2rem;

  background-color: #0385FD;
  color: #FFFFFF;

  display: flex;
  justify-content: space-between;
  align-items: center;
`;
