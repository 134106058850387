import axios from 'axios';

import type { TFindBatchInfos } from '../../../types/TBatchGoogleModules';

export const findBatchInfos = async ({
  isFetching,
  userAccessToken,
  page = 0,
  pageSize = 10,
  status,
  updateType,
  groupType,
  groupTypeId,
  startDate,
  endDate,
}: TFindBatchInfos): Promise<{
  status: 'SUCCESS' | 'ERROR';
  data: any;
}> => {
  try {
    if (isFetching) isFetching(true);

    let pathUrl = `batch-infos?groupTypeId=${groupTypeId}&groupType=${groupType}&pageSize=${pageSize}&page=${page}`;
    if (status) pathUrl += `&status=${status}`;
    if (updateType) pathUrl += `&updateType=${updateType}`;
    if (startDate) pathUrl += `&startDate=${startDate}`;
    if (endDate) pathUrl += `&endDate=${endDate}`;

    const { data } = await axios.get(
      `${process.env.REACT_APP_SAAS_BATCH_GOOGLE_MODULES}/${pathUrl}`,
      {
        headers: {
          Authorization: `Bearer ${userAccessToken}`,
        },
      },
    );

    return {
      status: 'SUCCESS',
      data,
    };
  } catch (err: any) {
    return {
      status: 'ERROR',
      data: err.response,
    };
  } finally {
    if (isFetching) isFetching(false);
  }
};
