import api from './api';

import dataNormalize from '../../utils/data-normalize';

const facebookAPIPageCategories = async (token) => {
  try {
    const response = await api({
      method: 'GET',
      url: 'fb_page_categories',
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    const { data } = await response;

    return dataNormalize({ data, pivotKey: 'fb_page_categories' });
  } catch (err) {
    console.log(err);
  }
};

export default facebookAPIPageCategories;
