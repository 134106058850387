import styled, { css } from 'styled-components';
import { Avatar, Checkbox } from '@material-ui/core';

import { Star as StarIcon } from '@material-ui/icons';

import { lighten } from 'polished';

export const StyledReview = styled.div`
    display: flex;
    flex-direction: column;
    padding: 2rem 2rem 4rem;
    background: ${props => props.theme.colors.white};
    margin-bottom: 0.2rem;
    width: 100%;
    position: relative;
    transition: 0.3s;
  
    ${props => props.isChecked && css`
      transform: scale(1.05);
      box-shadow: 
        0px 5px 5px -3px rgb(0 0 0 / 20%), 
        0px 8px 10px 1px rgb(0 0 0 / 14%), 
        0px 3px 14px 2px rgb(0 0 0 / 12%);
      z-index: ${props.reviewOverlayOrder};
      margin-bottom: -0.1rem;
    `}

    &:last-child {
        margin-bottom: 0;
    }
`;

export const StyledReviewer = styled.div`
    display: flex;
    flex-direction: column;
    position: relative;

    ${props => props.reviewReply && css`
      &::after {
        content: '';
        display: block;
        width: 0.2rem;
        height: 100%;
        position: absolute;
        left: 30px;
        background: #ccc;
        top: 5rem;
      }
    `}

    .reviewer-button-reply {
      max-width: 12rem;
      margin-top: 2rem;
      margin-left: 6rem;
    }
`;

export const StyledHeader = styled.header`
    display: flex;
    padding: 1rem;
    span {
      color: #0385fd;
    }
`;

export const StyledProfile = styled.div`
    display: flex;
    align-items: center;

    .profile-basic-infos {
      display: flex;
      flex-direction: column;
      margin-left: 1rem;
    }

    .profile-name {
      font-size: 1.6rem;
      line-height: 1;
      text-transform: capitalize;
      margin-bottom: 0;
    }

    .profile-created-time {
      font-weight: 500;
      color: ${props => lighten(0.30, props.theme.colors.black)};
    }

    .profile-location {
      font-size: 1.2rem;

      small {
        font-size: 1.2rem;
        color: ${props => props.theme.colors.primary};
      }
    }

    .star-rating-list {
      display: flex;
      margin-right: 0.5rem;
    } 

    .star-rating-and-date {
      display: flex;
      align-items: center;
      line-height: 1;
    }
`;

export const StyledProfilePhoto = styled(Avatar)`
  position: relative;
  z-index: 2;

  ${props => props.isowner && css`
      background: ${props.theme.colors.primary};

    .MuiSvgIcon-root {
      width: 2.5rem;
      height: 2.5rem;
      fill: ${props.theme.colors.white} !important;
    }
  `}
`;

export const StyledStar = styled(StarIcon)`
  fill: ${props => props.theme.colors.god};
  width: 2rem;
  height: 2rem;
`;

export const StyledFlagIcons = styled.div`
  display: flex;
  align-items: center;
  margin-left: auto;
  cursor: pointer;
  margin-bottom: auto;

  .check-icon-reviews-with-response {
    .icon {
      fill: ${props => props.theme.colors.green} !important;
    }
  }
`;

export const StyledReviewerComment = styled.p`
  display: flex;
  margin-bottom: 0;
  font-size: 1.4rem;
  padding-left: 6rem;
`;

export const StyledReviewReply = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 1rem;
  margin-top: 2rem;
  position: relative;

  .review-replyc-comment {
    font-style: italic;
    margin-top: 1rem;
    padding-left: 5rem;
  }
`;

export const StyledReviewReplyButtons = styled.div`
  display: flex;
  margin-top: 1.5rem;
  padding-left: 5rem;

  .review-reply-button {
    margin-right: 1rem;
  }
`;

export const StyledCheckbox = styled(Checkbox)`
  padding: 0;
  width: 4rem;
  height: 4rem;
  margin-right: 2rem;
  margin-top: 0.6rem;
  
  svg {
    width: 1.7rem;
    height: 1.7rem;
    margin-right: 1rem;
    margin-left: 1rem;
  }
`;
