import { createActions, createReducer } from 'reduxsauce';

export const { Types, Creators } = createActions({
  setActiveCompany: ['activeCompany'],
  setActiveCompanyId: ['activeCompanyId'],
  setUserCompanies: ['userCompanies'],
  setUserCompaniesLoading: ['userCompaniesLoading'],
  setCompanyLocations: ['companyLocations'],
  setCompanyLocationGroups: ['companyLocationGroups'],
  setShowLocationsByCompany: ['showLocationsByCompany'],
});

const initialState = {
  activeCompany: {},
  companyLocations: null,
  companyLocationGroups: null,
  activeCompanyId: null,
  userCompanies: null,
  userCompaniesLoading: false,
  showLocationsByCompany: false,
};

const reduxOnSetActiveCompany = (
  state = initialState, { activeCompany },
) => ({ ...state, activeCompany });

const reduxOnSetActiveCompanyId = (
  state = initialState, { activeCompanyId },
) => ({ ...state, activeCompanyId });

const reduxOnSetUserCompanies = (
  state = initialState, { userCompanies },
) => ({ ...state, userCompanies });

const reduxOnSetUserCompaniesLoading = (
  state = initialState, { userCompaniesLoading },
) => ({ ...state, userCompaniesLoading });

const reduxOnSetCompanyLocations = (
  state = initialState, { companyLocations },
) => ({ ...state, companyLocations });

const reduxOnSetCompanyLocationGroups = (
  state = initialState, { companyLocationGroups },
) => ({ ...state, companyLocationGroups });

const reduxOnSetShowLocationsByCompany = (
  state = initialState, { showLocationsByCompany },
) => ({ ...state, showLocationsByCompany });

export default createReducer(initialState, {
  [Types.SET_ACTIVE_COMPANY]: reduxOnSetActiveCompany,
  [Types.SET_ACTIVE_COMPANY_ID]: reduxOnSetActiveCompanyId,
  [Types.SET_USER_COMPANIES]: reduxOnSetUserCompanies,
  [Types.SET_USER_COMPANIES_LOADING]: reduxOnSetUserCompaniesLoading,
  [Types.SET_COMPANY_LOCATIONS]: reduxOnSetCompanyLocations,
  [Types.SET_COMPANY_LOCATION_GROUPS]: reduxOnSetCompanyLocationGroups,
  [Types.SET_SHOW_LOCATIONS_BY_COMPANY]: reduxOnSetShowLocationsByCompany,
});
