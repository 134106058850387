import React from 'react';
import { Helmet } from 'react-helmet';
import { useHistory } from 'react-router-dom';
import * as R from 'ramda';

import { Button } from '../../components';
import { MainContainer } from '../../containers';
import type { TUserEntity } from '../../types/TUser';

import NotFoundIllustration from '../../assets/images/erro-404-min.png';

import {
  Styled404,
} from './404-styles';

type TNotFoundProps = {
  currentUser: TUserEntity
}

function NotFound404({ currentUser }: TNotFoundProps) {
  const { push: pushToAScreen } = useHistory();

  const { profiles } = currentUser || {};
  const [userProfile] = profiles || [];

  if (R.isNil(userProfile)) pushToAScreen('/');

  if (userProfile.name === 'Usuário Padrão') pushToAScreen('/no-access');

  return (
    <MainContainer>
      <Styled404>
        <Helmet>
          <meta property="og:title" content="Página não encontrada" />
          <meta property="og:description" content="A página que você busca não pode ser encontrada." />

          <title>Página não encontrada</title>
        </Helmet>

        <img
          className="notfound-illustration"
          src={NotFoundIllustration}
          alt="Página não encontrada"
        />

        <h2>Página não encontrada</h2>

        <p>
          A página que você buscava não se encontra em nosso sistema ou algum erro, nível
          de acesso ou
          algo parecido impossibilitou você de acessá-la.
          Ajude-nos a resolver entrando em contato conosco!
        </p>

        <Button>Fale conosco</Button>
      </Styled404>
    </MainContainer>
  );
}

export default NotFound404;
