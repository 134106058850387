import axios from 'axios';

export default async function getAccessToken({ accessToken, redirectUri, code }) {
  const requestOptions = {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  };

  try {
    const { data } = await axios.get(`${process.env.REACT_APP_FOURSQUARE_API_URL}/get-access-token?redirectUri=${redirectUri}&code=${code}`, requestOptions);

    return data;
  } catch (err) {
    return err.respoonse;
  }
}
