import * as R from 'ramda';

export const setDefaultInputValue = ({ fields, initialData }) => {
  R.forEach((field) => {
    if (field.name === 'name') { field.setDefaultValue(initialData.name); }
    if (field.name === 'description') { field.setDefaultValue(initialData.description); }
  }, fields);
};

export const isValidData = ({
  responsibles,
  groupNameField,
  locationsGroup,
  enqueueSnackbar,
}) => {
  if (R.isEmpty(responsibles)) {
    enqueueSnackbar('Esse grupo não pode ficar sem responsáveis', { variant: 'warning' });
    return false;
  }

  if (R.isEmpty(groupNameField.trim())) {
    enqueueSnackbar('O nome do grupo é obrigatório', { variant: 'warning' });
    return false;
  }

  if (R.isEmpty(locationsGroup)) {
    enqueueSnackbar('Tem que ter pelo menos um local adicionado a esse grupo', { variant: 'warning' });
    return false;
  }

  return true;
};
