export const fieldPasswordInfoCurrentPassword = {
  name: 'currentPassword',
  nickname: 'CurrentPassword',
  type: 'password',
  label: '',
  description: '',
  hasborder: 'yes',
  password: true,
};

export const fieldPasswordInfoNewPassword = {
  name: 'newPassword',
  nickname: 'NewPassword',
  type: 'password',
  label: '',
  description: '',
  hasborder: 'yes',
  password: true,
};

export const fieldPasswordInfoConfirmNewPassword = {
  name: 'confirmNewPassword',
  nickname: 'ConfirmNewPassword',
  type: 'password',
  label: '',
  description: '',
  hasborder: 'yes',
};
