import axios from 'axios';

const getZipCode = async ({ zipcode, feedbackMessage }) => {
  try {
    const { data } = await axios.get(`https://viacep.com.br/ws/${zipcode}/json/`);

    if (data.erro && feedbackMessage) feedbackMessage('CEP não encontrado', { variant: 'warning' });

    return data;
  } catch (err) {
    console.log(err);
  }
};

export default getZipCode;
