import React from 'react';

import { StyledFiltersContainer, StyledDatesWrapper } from './requests-filter-styles';

import {
  Input, Select, InputDatePicker,
} from '../../../../components';
import { StatusOptions, TRequestsFilterProps, TStatusOptions } from './helpers';

const RequestsFilter = ({
  searchCompanyLabel,
  onChangeCompanyQuery,
  searchLocationLabel,
  onChangeLocationQuery,
  selectedTypeFilter,
  setSelectedTypeFilter,
  statusLabel,
  toPeriod,
  setToPeriod,
  ofPeriod,
  setOfPeriod,
}: TRequestsFilterProps) => (
  <StyledFiltersContainer>
    <Input
      className="filter-content"
      label={searchCompanyLabel}
      onChange={(e) => onChangeCompanyQuery(e.target.value)}
      inputOptions={{
        type: 'text',
        name: 'batch-filter-search-query',
      }}
      border
      placeholder="Escreva algo aqui"
    />
    <Input
      className="filter-content"
      label={searchLocationLabel}
      onChange={(e) => onChangeLocationQuery(e.target.value)}
      inputOptions={{
        type: 'text',
        name: 'batch-filter-search-query',
      }}
      border
      placeholder="Escreva algo aqui"
    />
    <Select<TStatusOptions>
      name="type-update-filter"
      value={selectedTypeFilter}
      label={statusLabel}
      options={StatusOptions ?? []}
      onChange={(event) => setSelectedTypeFilter(event.target.value === 'Selecione uma opção' ? '' : event.target.value)}
      border
      className="filter-content"
    />
    <StyledDatesWrapper>
      <InputDatePicker
        selectedDate={ofPeriod}
        setSelectedDate={setOfPeriod}
        label="De:"
        maxDate={toPeriod || null}
        className="input-date-picker"
      />

      <InputDatePicker
        selectedDate={toPeriod}
        setSelectedDate={setToPeriod}
        minDate={ofPeriod || null}
        label="Até:"
        className="input-date-picker"
      />
    </StyledDatesWrapper>
  </StyledFiltersContainer>
);

export default RequestsFilter;
