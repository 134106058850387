/* eslint-disable react/jsx-props-no-spreading */
// eslint-disable-next-line no-use-before-define
import { isNil } from 'lodash';
import React, { useCallback } from 'react';
import ControlledInput from '../../controlled-input';
import {
  emailAttr,
  locationNameAttr,
  mainPhoneAttr,
  secondaryPhoneAttr,
} from './helpers';
import { StyledFieldsWrapper } from './location-info-styles';
import { TLocationInfoProps } from './types';

const LocationInfo = ({
  setUpdatedLocation,
  updatedLocation,
  fieldOnFocus,
  setFieldOnFocus,
}: TLocationInfoProps) => {
  const mainPhone = { ...mainPhoneAttr, required: !(updatedLocation.secondary_phone === '__ ____-____' || updatedLocation.secondary_phone === '__ _____-____') };

  const [phoneLandLine, setPhoneLandLine] = React.useState(false);
  const [secPhoneLandLine, setSecPhoneLandLine] = React.useState(false);

  const setDefaultLandlineEnabled = (phone: string | undefined) => {
    if (isNil(phone)) return false;

    const landlineRegex = /(.{2}) (.{4})-(.{4})/g;
    const mobileRegex = /(.{2}) (.{5})-(.{4})/g;

    if (landlineRegex.test(phone)) return true;
    if (mobileRegex.test(phone)) return false;
    return false;
  };

  const initializeLandlineValue = React.useCallback(() => {
    setPhoneLandLine(setDefaultLandlineEnabled(updatedLocation.phone));
  }, [updatedLocation]);

  React.useEffect(() => {
    initializeLandlineValue();
  }, [initializeLandlineValue]);

  const LocationName = useCallback(() => (
    <ControlledInput
      {...locationNameAttr}
      fieldOnFocus={fieldOnFocus}
      setFieldOnFocus={setFieldOnFocus}
      setParentValue={setUpdatedLocation}
      defaultValue={updatedLocation.name}
    />
  ), [updatedLocation, fieldOnFocus]);

  const LocationEmail = useCallback(() => (
    <ControlledInput
      {...emailAttr}
      fieldOnFocus={fieldOnFocus}
      setFieldOnFocus={setFieldOnFocus}
      setParentValue={setUpdatedLocation}
      defaultValue={updatedLocation.email}
    />
  ), [updatedLocation, fieldOnFocus]);

  const MainPhone = useCallback(() => (
    <ControlledInput
      {...mainPhone}
      fieldOnFocus={fieldOnFocus}
      setFieldOnFocus={setFieldOnFocus}
      setParentValue={setUpdatedLocation}
      defaultValue={updatedLocation.phone}
      defaultLandline={phoneLandLine}
      setDefaultLandline={setPhoneLandLine}
    />
  ), [updatedLocation, fieldOnFocus]);

  const SecondaryPhone = useCallback(() => (
    <ControlledInput
      {...secondaryPhoneAttr}
      fieldOnFocus={fieldOnFocus}
      setFieldOnFocus={setFieldOnFocus}
      setParentValue={setUpdatedLocation}
      defaultValue={updatedLocation.secondary_phone}
      defaultLandline={secPhoneLandLine}
      setDefaultLandline={setSecPhoneLandLine}
    />
  ), [updatedLocation, fieldOnFocus]);

  return (
    <StyledFieldsWrapper threeColumns>
      <LocationName />
      <LocationEmail />
      <MainPhone />
      <SecondaryPhone />
    </StyledFieldsWrapper>
  );
};

export default LocationInfo;
