import axios from 'axios';

export default async function removeAllSensibleWords({
  accessToken,
  companyId,
  setIsDelete,
}) {
  const requestOptions = {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  };

  try {
    if (setIsDelete) setIsDelete(true);

    const { data } = await axios.post(
      `${process.env.REACT_APP_COMPANIES_API_URL}/remove-all-sensible-words/${companyId}`, null,
      requestOptions,
    );

    return data;
  } catch (err) {
    return null;
  } finally {
    if (setIsDelete) setIsDelete(false);
  }
}
