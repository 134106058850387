import React from 'react';
import * as MaterialUIIcons from '@material-ui/icons';
import * as R from 'ramda';

import { TDropDownDialog } from '../../types/TDropDownDialog';

import OverlayLoading from '../overlay-loading';

import Popover from './popover';

import limitedText from '../../utils/limited-text';

import {
  StyledDropDownDialog,
  StyledCurrentSelectedOption,
  StyledInfo,
} from './dropdown-dialog-styles';

const DropDownDialog = ({
  title,
  icon = 'Help',
  currentOption = null,
  options = null,
  isGuest = false,
  onChange = null,
  optionDisableIfNotHaveLocations = false,
  shouldDisableOptionForLocationGuest = false,
  companyType = false,
  locationGroupType = false,
  companyAndGroupType = false,
  activeTab = 0,
  handleChangeTab = null,
  pageQuantity = 0,
  showPagination = false,
  googlePagination = false,
  page = 0,
  setPage,
  setQuery,
  searchQueryRef = null,
  isLoading = false,
  classNameDropDownContainer,
  popoverWitdh = null,
  nextPageToken = null,
  onNextPageClicked = null,
  onBackPageClicked = null,
  onResetClicked = null,
  googlePaginationLoading = false,
  overlayLoadingNoDisplay = false,
  pageList = null,
  setPageList = null,
}: TDropDownDialog) => {
  const [
    anchorElement,
    setAnchorElement,
  ] = React.useState<HTMLDivElement | null>(null);

  const DropDownDialogArrow = MaterialUIIcons[anchorElement ? 'ExpandLess' : 'ExpandMore'];

  const handleOpenPopover = (target: EventTarget & HTMLDivElement) => {
    setAnchorElement(target);
  };

  const optionsIsPaginated = (optionsPagineted: any) => {
    if (R.isNil(optionsPagineted)) return [];

    return (R.is(Array, optionsPagineted[0]) ? optionsPagineted[0] : optionsPagineted);
  };

  return (
    <>
      <StyledDropDownDialog
        title={`${currentOption?.name}`}
        onClick={(e) => handleOpenPopover(e.currentTarget)}
        className={classNameDropDownContainer}
      >
        {isLoading && !overlayLoadingNoDisplay && <OverlayLoading textToLoading="Carregando Informações" />}

        <h2>
          {title}
          :
        </h2>

        <StyledCurrentSelectedOption>
          <StyledInfo>
            <span>
              {limitedText(currentOption?.name || 'Selecione uma opção', 30)}
            </span>
            {isGuest && (
              <span className="guest-company-name">
                <b>Empresa:</b>
                {' '}
                {limitedText(currentOption?.company?.name || 'Selecione uma opção', 30)}
              </span>
            )}
          </StyledInfo>
          <DropDownDialogArrow className="drop-down-dialog-arrow" />
        </StyledCurrentSelectedOption>
      </StyledDropDownDialog>

      <Popover
        isGuest={isGuest}
        options={optionsIsPaginated(options)}
        onChange={onChange}
        anchorElement={anchorElement}
        setAnchorElement={setAnchorElement}
        optionDisableIfNotHaveLocations={optionDisableIfNotHaveLocations}
        companyType={companyType}
        locationGroupType={locationGroupType}
        companyAndGroupType={companyAndGroupType}
        activeTab={activeTab}
        handleChangeTab={handleChangeTab}
        pageQuantity={pageQuantity}
        showPagination={showPagination}
        googlePagination={googlePagination}
        page={page}
        setPage={setPage}
        setQuery={setQuery}
        searchQueryRef={searchQueryRef}
        popoverWitdh={popoverWitdh}
        nextPageToken={nextPageToken}
        onNextPageClicked={onNextPageClicked}
        onBackPageClicked={onBackPageClicked}
        onResetClicked={onResetClicked}
        googlePaginationLoading={googlePaginationLoading}
        isLoading={isLoading}
        pageList={pageList}
        setPageList={setPageList}
        currentSelected={currentOption?.name}
        shouldDisableOptionForLocationGuest={shouldDisableOptionForLocationGuest}
      />
    </>
  );
};

export default DropDownDialog;
