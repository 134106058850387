import axios from 'axios';
/**
 *
 * @param {parentId} "accounts%2F*%2Flocations%2F*"
 * @returns
 */
export default async function uploadMediaToLocationWithBytes({
  accountId,
  locationId,
  accessToken,
  mediaFormat,
  category,
  sourceUrl,
  isUpload,
}) {
  const requestOptions = {
    headers: {
      'access-token': accessToken,
    },
  };

  try {
    if (isUpload) isUpload(true);

    const data = await axios.post(
      `${process.env.REACT_APP_GOOGLE_API_URL}/media/url/${accountId}/${locationId}`,
      { mediaFormat, category, sourceUrl },
      requestOptions,
    );

    return data;
  } catch (err) {
    if (err.response.data.error === 'Not Acceptable') {
      return err.response.data;
    }

    return null;
  } finally {
    if (isUpload) isUpload(false);
  }
}
