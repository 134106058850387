import React from 'react';
import {
  StyledContainer, StyledIconClose, StyledTitle, StyledButtonClose,
} from './selected-filter-styles';

type selectedFilterProps = {
    title: string,
    onClickRemove(): void;
}

const SelectedFilter = ({ title, onClickRemove }: selectedFilterProps) => (
  <StyledContainer>
    <StyledTitle>{title}</StyledTitle>
    <StyledButtonClose
      onClick={onClickRemove}
    >
      <StyledIconClose />
    </StyledButtonClose>
  </StyledContainer>
);

export default SelectedFilter;
