import axios, { AxiosResponse } from 'axios';
import { TServiceResponse } from '../../types';
import { TGetAllBIJob, TGetAllBIJobResponse } from './types';

export const getAllBIJob = async ({
  userToken,
  companyName,
  locationName,
  locationAddress,
  locationStatus,
  platformLinkStatus,
  startDate,
  endDate,
  page,
  pageSize,
  statusList,
  platformList,
  errorByModuleList,
}: TGetAllBIJob): Promise<TServiceResponse<TGetAllBIJobResponse, unknown>> => {
  try {
    const { data }: AxiosResponse<TGetAllBIJobResponse> = await axios.get(
      `${process.env.REACT_APP_BI_API}/bi-job`,
      {
        params: {
          companyName: companyName || undefined,
          locationName: locationName || undefined,
          locationAddress: locationAddress || undefined,
          locationStatus,
          platformLinkStatus,
          startDate,
          endDate,
          page,
          pageSize,
          statusList: statusList && statusList?.length > 0 ? statusList.toString() : undefined,
          platformList: platformList && platformList?.length > 0 ? platformList.toString() : undefined,
          errorByModuleList: errorByModuleList && errorByModuleList.length > 0 ? errorByModuleList.toString() : undefined,
        },
        headers: {
          Authorization: `Bearer ${userToken}`,
        },
      }
    );

    return {
      type: 'SUCCESS',
      response: data,
    };
  } catch (err: any) {
    return {
      type: 'ERROR',
      response: err,
    };
  }
};
