import axios from 'axios';

/**
 *
 * @param {mediaId} "accounts%2F*%2Flocations%2F*%2Fmedia%2F*"
 * @returns
 */
export default function deleteLocationMedia({
  mediaId,
  accessToken,
  isDelete,
}) {
  const requestOptions = {
    headers: {
      'access-token': accessToken,
    },
  };

  try {
    if (isDelete) isDelete(true);

    const data = axios.delete(
      `${process.env.REACT_APP_GOOGLE_API_URL}/delete-media/${mediaId}`,
      requestOptions,
    );

    return data;
  } catch (err) {
    return null;
  } finally {
    if (isDelete) isDelete(false);
  }
}
