import React from 'react';
import { useHistory } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { useSelector } from 'react-redux';
import * as R from 'ramda';

import getCompanyGroupResponsibles from '../../services/company-groups/getCompanyGroupResponsibles';
import getCompanyGroup from '../../services/company-groups/getOne';
import getCompanyGroupGuests from '../../services/guest/getCompanyGroupGuests';
import createCompanyGroupInvite from '../../services/invites/createCompanyGroupInvite';
import getCompanyGroupCompanies from '../../services/companies/getCompanyGroupCompanies';
import getUserCompanies from '../../services/companies/getUserCompaniesPaginated';
import getCompanies from '../../services/companies/getCompanies';
import getAllCompanyGroupInvites from '../../services/invites/getAllCompanyGroupInvites';

import {
  useAuth,
} from '../../hooks';

import { saveEditionInDB } from './helpers';

import {
  HeaderToolbar,
  HealderToolbarAction,
  BlockWithActionButton,
  CompanyInfo,
  ResponsibleInfo,
  GroupItems,
  GuestList,
} from '../../components';

import {
  StyledEditCompanyGroup,
  StyledButtonsGroup,
  StyledContent,
  StyledContentMain,
  StyledContentAside,
} from './edit-company-group-styles';

const EditCompanyGroup = () => {
  const {
    push: pushToScreen,
  } = useHistory();

  const { activeCompanyGroup } = useSelector((state) => state.CompanyGroupReducer);

  const { enqueueSnackbar } = useSnackbar();

  const {
    userAccessToken,
    userSetTokenLikeExpired,
    userId,
    userProfileName,
  } = useAuth();

  const responsibleData = React.useRef(null);
  const companyInfoData = React.useRef(null);

  const [currentCompanyData, setCurrentCompanyData] = React.useState(null);
  const [currentCompanyResponsibles, setCurrentCompanyResponsible] = React.useState(null);
  const [isSaveEdition, setIsSaveEdition] = React.useState(false);
  const [companiesToAdd, setCompaniesToAdd] = React.useState([]);
  const [addedCompanies, setAddedCompanies] = React.useState([]);
  const [companiesToAddPage, setCompaniesToAddPage] = React.useState(0);
  const [addedCompaniesPage, setAddedCompaniesPage] = React.useState(0);
  const [companiesToAddQuery, setCompaniesToAddQuery] = React.useState('');
  const [addedCompaniesQuery, setAddedCompaniesQuery] = React.useState('');
  const [companiesToAddPageSize, setCompaniesToAddPageSize] = React.useState(10);
  const [addedCompaniesPageSize, setAddedCompaniesPageSize] = React.useState(10);
  const [companiesToAddPageQuantity, setCompaniesToAddPageQuantity] = React.useState(1);
  const [addedCompaniesPageQuantity, setAddedCompaniesPageQuantity] = React.useState(1);
  const [addedCompaniesToRemove, setAddedCompaniesToRemove] = React.useState([]);
  const [companiesToSave, setCompaniesToSave] = React.useState([]);

  const getCompanyInfos = React.useCallback(async () => {
    const responseCompanyInfos = await getCompanyGroup({
      accessToken: userAccessToken,
      id: activeCompanyGroup?.length > 1 ? activeCompanyGroup[0].id : activeCompanyGroup.id,
      setTokenLikeExpired: userSetTokenLikeExpired,
    });

    if (responseCompanyInfos) {
      const {
        name,
        is_active,
        website,
      } = responseCompanyInfos;

      setCurrentCompanyData({ name, is_active, website });
    }
  }, [activeCompanyGroup]);

  const getResponsible = React.useCallback(async () => {
    const { id: companyID } = activeCompanyGroup && activeCompanyGroup?.length > 1
      ? activeCompanyGroup[0] : activeCompanyGroup;

    const responsiblesResponse = await getCompanyGroupResponsibles({
      accessToken: userAccessToken,
      companyGroupId: companyID,
    });

    if (!R.isNil(responsiblesResponse)) {
      const {
        main_responsible: mainResponsible,
        responsibles: otherResponsibles,
      } = responsiblesResponse;

      const mergeResponsibles = [
        ...otherResponsibles,
        {
          ...mainResponsible,
          name: mainResponsible.name,
          phone: mainResponsible.phone,
          email: mainResponsible.email,
          main: true,
          id: mainResponsible.id,
        },
      ];

      const normalizedResponsiblesData = mergeResponsibles.map(responsible => ({
        ...responsible,
        id: responsible.id,
        name: responsible.name,
        phone: responsible.phone,
        email: responsible.email,
        main: responsible.main === true,
      }));

      setCurrentCompanyResponsible(normalizedResponsiblesData);
    } else {
      setCurrentCompanyResponsible([]);
    }
  }, [activeCompanyGroup]);

  const getCompaniesToAdd = React.useCallback(async () => {
    let data; let amount;

    if (userProfileName === 'Usuário Padrão') {
      [data, amount] = await getUserCompanies({
        accessToken: userAccessToken,
        userId,
        page: companiesToAddPage,
        pageSize: companiesToAddPageSize,
        query: companiesToAddQuery,
        setTokenLikeExpired: userSetTokenLikeExpired,
      });
    } else {
      [data, amount] = await getCompanies({
        accessToken: userAccessToken,
        page: companiesToAddPage,
        pageSize: companiesToAddPageSize,
        query: companiesToAddQuery,
        setTokenLikeExpired: userSetTokenLikeExpired,
      });
    }

    setCompaniesToAdd(data);
    setCompaniesToAddPageQuantity(Math.ceil(amount / companiesToAddPageSize));
  }, [companiesToAddPage, companiesToAddQuery, addedCompanies]);

  const getAddedCompanies = React.useCallback(async () => {
    const { id: companyGroupId } = activeCompanyGroup
    && activeCompanyGroup?.length > 1 ? activeCompanyGroup[0] : activeCompanyGroup;

    const [data, amount] = await getCompanyGroupCompanies({
      accessToken: userAccessToken,
      companyGroupId,
      page: addedCompaniesPage,
      pageSize: addedCompaniesPageSize,
      query: addedCompaniesQuery,
      setTokenLikeExpired: userSetTokenLikeExpired,
    });

    const addedCompanyIds = addedCompanies.map(element => element.id);

    setAddedCompanies(data.filter(element => !addedCompanyIds.includes(element)));

    setAddedCompaniesPageQuantity(Math.ceil(amount / addedCompaniesPageSize));
  }, [activeCompanyGroup, addedCompaniesPage, addedCompaniesQuery]);

  React.useEffect(() => { getCompanyInfos(); }, [getCompanyInfos]);
  React.useEffect(() => { getResponsible(); }, [getResponsible]);
  React.useEffect(() => { getCompaniesToAdd(); }, [getCompaniesToAdd]);
  React.useEffect(() => { getAddedCompanies(); }, [getAddedCompanies]);

  const handleSaveEdition = React.useCallback(() => {
    const { responsibles } = responsibleData.current;
    const { company } = companyInfoData.current;

    const companyChecks = company.map(companyField => companyField.firedCheck);
    const companyValues = company.map(companyField => companyField.getValue());
    const companySetValues = company.map(companyField => companyField.setDefaultValue);

    if (companyChecks.every(companyFiredCheck => companyFiredCheck())) {
      if (!responsibles.length) {
        enqueueSnackbar(
          'Para atualizar uma empresa é necessário ter pelo menos um responsável',
          { variant: 'error' },
        );
        return;
      }

      const companyData = {
        name: companyValues[0],
        website: companyValues[1],
        is_active: companyValues[2],
      };

      companySetValues[0](companyValues[0]);
      companySetValues[1](companyValues[1]);
      companySetValues[2](companyValues[2]);

      const addedCompaniesToRemoveIds = addedCompaniesToRemove.map(element => element.id);
      const companiesToSaveIds = companiesToSave.map(element => element.id);

      saveEditionInDB({
        companyGroupData: companyData,
        companyGroupID: activeCompanyGroup?.length > 1 ? activeCompanyGroup[0].id
          : activeCompanyGroup.id,
        responsibles,
        setIsSaveEdition,
        setTokenLikeExpired: userSetTokenLikeExpired,
        userHublocalToken: userAccessToken,
        userHublocalID: userId,
        companiesToAdd: companiesToSaveIds,
        companiesToRemove: addedCompaniesToRemoveIds,
        feedbackMessage: enqueueSnackbar,
      });
    }
  }, [responsibleData, companyInfoData, addedCompaniesToRemove, companiesToSave]);

  const handleCompanyToAddClicked = (company) => {
    setAddedCompanies([...addedCompanies, company]);
    setCompaniesToSave([...companiesToSave, company]);
  };

  const handleAddedCompanyClicked = (company) => {
    setAddedCompanies(addedCompanies.filter(element => element.id !== company.id));
    setAddedCompaniesToRemove([...addedCompaniesToRemove, company]);

    if (!R.isNil(companiesToAdd.find(removedCompany => removedCompany.id === company.id))) return;

    setCompaniesToAdd([...companiesToAdd, company]);
  };

  const handlePushToCreateCompany = () => pushToScreen('/company-group/create');
  const handlePushMyCompaniesGroup = () => pushToScreen('/my-company-groups');

  return (
    <StyledEditCompanyGroup>
      <HeaderToolbar
        title="Editar grupo de empresa"
        isCompanyHeader
      >
        <StyledButtonsGroup>
          <HealderToolbarAction
            title="Adicionar Grupo"
            icon="AddBox"
            onClick={handlePushToCreateCompany}
          />
          <HealderToolbarAction
            title="Grupos Empresariais"
            icon="List"
            onClick={handlePushMyCompaniesGroup}
          />
        </StyledButtonsGroup>
      </HeaderToolbar>

      <StyledContent>
        <StyledContentMain>
          {currentCompanyResponsibles && (
            <ResponsibleInfo
              className="block-form"
              ref={responsibleData}
              initialData={currentCompanyResponsibles}
              editingMode
              isCompanyGroup
              groupId={activeCompanyGroup?.length > 1 ? activeCompanyGroup[0].id
                : activeCompanyGroup.id}
            />
          )}

          {currentCompanyData && (
            <CompanyInfo
              ref={companyInfoData}
              className="block-form"
              title="Informações básicas da empresa"
              initialData={{
                ...currentCompanyData,
                nameLabel: 'Nome do Grupo',
                websiteLabel: 'Website do grupo',
                isActiveLabel: 'Está ativa?',
              }}
            />
          )}

        </StyledContentMain>
        <StyledContentAside>
          <BlockWithActionButton
            onClick={handleSaveEdition}
            titleButton="Salvar edição"
            loading={isSaveEdition}
          />

          {!R.isNil(activeCompanyGroup) && (
            <GuestList
              APIGetPendingInvites={getAllCompanyGroupInvites}
              userAccessToken={userAccessToken}
              userSetTokenLikeExpired={userSetTokenLikeExpired}
              targetParamToGetPendingInvites="companyGroupId"
              activeRegisterIdToGetData={activeCompanyGroup.id}
              APIGetAllGuest={getCompanyGroupGuests}
              targetParamToGetGuests="companyGroupId"
              userId={userId}
              APICreateInvite={createCompanyGroupInvite}
              targetParamToCreateInvites="company_group_id"
              isGroup
            />
          )}
        </StyledContentAside>

        <GroupItems
          itemsToAdd={companiesToAdd}
          addedItens={addedCompanies.filter((company) => company.name.toLowerCase()
            .includes(addedCompaniesQuery.toLowerCase()))}
          itemsToAddPagesQuantity={companiesToAddPageQuantity}
          addedItemsPagesQuantity={addedCompaniesPageQuantity}
          primaryTextKey="name"
          onItemToAddClicked={handleCompanyToAddClicked}
          onAddedItemClicked={handleAddedCompanyClicked}
          setQueryItensToAddPage={setCompaniesToAddQuery}
          setQueryAddedItensPage={setAddedCompaniesQuery}
          setItensToAddPage={setCompaniesToAddPage}
          currentItensToAddPage={companiesToAddPage}
          setAddedItensPage={setAddedCompaniesPage}
          currentAddedItensPage={addedCompaniesPage}
        />
      </StyledContent>
    </StyledEditCompanyGroup>
  );
};

export default EditCompanyGroup;
