import React from 'react';

import {
  StyledPageBindedProfile,
} from './page-binded-profile-styles';

const PageBindedProfile = ({ facebookPageData }) => {
  const [page, setPage] = React.useState(null);
  const [location, setLocation] = React.useState(null);

  React.useEffect(() => {
    setLocation(facebookPageData?.page?.selectedLocation || null);
    setPage(facebookPageData?.page || null);
  }, [facebookPageData]);

  return (
    <StyledPageBindedProfile> 
      {(page && !location) && (
        <>
          <small>Este local está vinculado com a seguinte página:</small>
          <a href={`https://www.facebook.com/${page.id}`} target="_blank" rel="noopener noreferrer">{page.name}</a>
        </>
      )}

      {location && (
        <>
          <small>Está vinculado com o seguinte local:</small>
          <a 
            href={`https://www.facebook.com/${location.id}`} 
            target="_blank" rel="noopener noreferrer"
          >
            {location.address}
          </a>
          <small>da seguinte página:</small>
          {' '}
          <a href={`https://www.facebook.com/${page.id}`} target="_blank" rel="noopener noreferrer">{page.name}</a>
        </>
      )}
    </StyledPageBindedProfile>
  )
};

export default PageBindedProfile;
