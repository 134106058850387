import * as R from 'ramda';
import { defaultDateFormatRegExp } from '../../../../../utils/regexp';

import { TAddSpecialDateFieldsIsValid, TAddSpecialDateFieldsIsValidIsUpdate } from '../../../../../types/TWorkingHours';
import { TTimeData } from '../../../../../types/TTime';

export const mockedTimes: TTimeData[] = [
  {
    id: Math.floor(Math.random()),
    open: '00:00',
    close: '00:00',
    is_deleted: false,
    is_mocked: true,
  },
];

export const setFieldsToIsValid = ({
  title,
  date,
  feedbackMessage,
  currentSpecialDates,
}: TAddSpecialDateFieldsIsValid) => ({ isUpdateMode }: TAddSpecialDateFieldsIsValidIsUpdate) => {
  const currentDateTime = new Date();

  if (R.isNil(title)) return false;
  if (R.isNil(date)) return false;

  const monthInYear = 12;
  const currentDay = currentDateTime.getDate();
  const currentMonth = currentDateTime.getMonth() + 1;
  const currentYear = currentDateTime.getFullYear();
  const daysInCurrentMonth = new Date(currentYear, currentMonth, 0).getDate();

  if (R.isEmpty(title.trim())) {
    feedbackMessage('O campo título não pode ser vázio!', { variant: 'warning' });

    return false;
  }

  if (R.isEmpty(date.trim())) {
    feedbackMessage('O campo data não pode ser vázio!', { variant: 'warning' });

    return false;
  }

  if (!defaultDateFormatRegExp.test(date.trim())) {
    feedbackMessage('A data inserida não é válida!', { variant: 'warning' });

    return false;
  }

  const hasDateInCurrentSpecialDates = currentSpecialDates.find(specialDate => specialDate.date === date);

  if (!R.isNil(hasDateInCurrentSpecialDates) && !isUpdateMode) {
    feedbackMessage('Já existe um registro com a mesma data inserida!', { variant: 'warning' });

    return false;
  }

  const [
    dayFromField,
    monthFromField,
    yearFromField,
  ] = date.split('/').map(dateItem => Number(dateItem)) || [];

  const isValidDay = dayFromField >= currentDay && dayFromField <= daysInCurrentMonth;
  const isValidMonth = monthFromField >= currentMonth && monthFromField <= monthInYear;
  const isValidYear = yearFromField >= currentYear;

  if (!isValidYear) {
    feedbackMessage('A ano inserido não é válida!', { variant: 'warning' });

    return false;
  }

  if (!isValidMonth && yearFromField <= currentYear) {
    feedbackMessage('O mês inserido não é válida!!', { variant: 'warning' });

    return false;
  }

  if (!isValidDay && (monthFromField <= currentMonth && yearFromField <= currentYear)) {
    feedbackMessage('O dia inserido não é válida!', { variant: 'warning' });

    return false;
  }

  return true;
};
