import React from 'react';
import * as MaterialIcons from '@material-ui/icons/';
import { SvgIconComponent } from '@material-ui/icons/';
import * as R from 'ramda';

import { TRoundedButtonBaseProps } from '../../types/TRoundedButton';

import {
  StyledRoundedButton,
} from './rounded-button-styles';
import Loading from '../loading';

const RoundedButton = ({
  title = 'Botão',
  icon = 'Help',
  onClick,
  variant = 'primary',
  className,
  disabled = false,
  loading = false,
}: TRoundedButtonBaseProps) => {
  const [
    IconComponent,
    setIconComponent,
  ] = React.useState<SvgIconComponent>(MaterialIcons.Help);

  React.useEffect(() => {
    R.forEachObjIndexed((
      SVGIcon: SvgIconComponent,
      SVGComponentName: string,
    ) => {
      if (SVGComponentName === icon) setIconComponent(SVGIcon);
    }, MaterialIcons);
  }, []);

  return (
    <StyledRoundedButton
      variant={variant}
      onClick={onClick}
      className={className}
      disabled={disabled || loading}
    >
      {icon && !loading && <IconComponent className="icon-rounded-button" />}
      {loading ? <Loading /> : title}
    </StyledRoundedButton>
  );
};

export default RoundedButton;
