import React from 'react';
import { isNil, isEmpty } from 'ramda';
import { useSnackbar } from 'notistack';
import { useDropzone } from 'react-dropzone';
import * as MaterialUIIcons from '@material-ui/icons/';

import { NotificationBox } from '..';

import type {
  TDragAndDropFiles,
  TFile,
} from '../../types/TDragAndDropFiles';

import UploadedImagesListing from '../../pages/locations-posts/google-posts-form/uploaded-images-listing';

import {
  isValidImageFile, isValidVideo, singleModeTypes, multipleModeTypes,
} from './helpers';

import {
  StyledDragAndDropFile,
  StyledDragAndDropZone,
} from './drag-and-drop-file-styles';

const DragAndDropFiles = ({
  setFilesToPost,
  filesToPost,
  imageFileValidation,
  maxVideoSize,
  singleMode = false,
  editable = true,
  category = null,
}: TDragAndDropFiles) => {
  const { enqueueSnackbar } = useSnackbar();
  const { AddAPhoto } = MaterialUIIcons;

  const onDrop = React.useCallback(async (acceptedFiles: any) => {
    const acceptedFilesNormalized = acceptedFiles.map(
      (file: TFile) => Object.assign(file, {
        sourceUrl: URL.createObjectURL(file),
        isVideo: Boolean(file.type === 'video/mp4'),
      }),
    );

    const filesWithImageValidation = await Promise.all<TFile>(
      acceptedFilesNormalized.map(async (file: TFile) => {
        if (!singleMode && file.isVideo) return file;
        if (singleMode && file.isVideo) {
          enqueueSnackbar(imageFileValidation.isSingleUploadMessage, { variant: 'warning' });
          return null;
        }

        if (isNil(imageFileValidation)) return file;

        const isValidImage = await isValidImageFile({
          imageFileValidation,
          imageFile: file,
          callbackValidationMessage: enqueueSnackbar,
          category,
        });

        return Object.assign(file, {
          isValidImage,
        });
      }),
    );

    const filteredValidatedImages = filesWithImageValidation.filter((file) => !isNil(file));

    if (singleMode && (isNil(filteredValidatedImages) || isEmpty(filteredValidatedImages))) return;

    const singleOrMultipleImagesSelected = singleMode ? filteredValidatedImages : filesWithImageValidation;

    const validFiles = singleOrMultipleImagesSelected.filter((file) => {
      if (isNil(file)) return false;

      if (file.isVideo && isValidVideo({
        fileVideo: file,
        callbackValidationMessage: enqueueSnackbar,
        maxVideoSize,
      })) return true;

      if (!isNil(file.isValidImage) && file.isValidImage) return true;

      return false;
    });

    if (isEmpty(validFiles)) {
      return;
    }

    if (!singleMode) {
      setFilesToPost([...filesToPost, ...validFiles]);
      return;
    }

    setFilesToPost([validFiles[0]]);
  }, [filesToPost, singleMode]);

  const {
    getInputProps,
    getRootProps,
    fileRejections,
  } = useDropzone({
    onDrop,
    accept: singleMode ? singleModeTypes : multipleModeTypes,
  });

  const handleDeleteImage = (imageIndex: number) => {
    setFilesToPost(filesToPost.filter((element:any, index:number) => index !== imageIndex));
  };

  React.useEffect(() => {
    if (isEmpty(fileRejections)) return;

    enqueueSnackbar('O arquivo submetido não possui um formato válido!', { variant: 'warning' });
  }, [fileRejections]);

  const renderCoverImageAlert = (
    <p>
      Imagens do tipo Capa devem conter dimensões no formato 16:9.
      {' '}
      <a target="_blank" href="https://pt.wikipedia.org/wiki/Lista_de_resolu%C3%A7%C3%B5es_de_v%C3%ADdeo" rel="noreferrer">Entenda mais</a>
    </p>
  );

  return (
    <StyledDragAndDropFile>
      {!isNil(category) && category === 'Capa' && (
      <NotificationBox
        text={renderCoverImageAlert}
        type="WARNING"
      />
      )}

      {((singleMode && isEmpty(filesToPost)) || !singleMode) && (
        <StyledDragAndDropZone {...getRootProps()}>
          <input {...getInputProps()} />
          <AddAPhoto className="add-a-photo-icon" />
          <p>Clique ou arraste para publicar fotos e vídeos</p>
        </StyledDragAndDropZone>
      )}

      {!isEmpty(filesToPost) && (
        <UploadedImagesListing
          handleDeleteImage={handleDeleteImage}
          mediaToUpdate={filesToPost}
          editable={editable}
        />
      )}

    </StyledDragAndDropFile>
  );
};

export default DragAndDropFiles;
