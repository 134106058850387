import React from 'react';
import { Dialog } from '..';
import LinkInfoPopover from './link-info-popover/link-info-popover';
import { RequestDialogProps } from './types';

const LinkInfoDialog = ({
  isDialogOpen, onClose, onConfirm, type, unlinkedLocations = [],
}: RequestDialogProps) => (
  <Dialog
    onClose={onClose}
    open={isDialogOpen}
    title="Atenção:"
    wideDialog
    maxWidth="md"
  >
    <LinkInfoPopover
      onClose={onClose}
      type={type}
      onConfirm={onConfirm}
      unlinkedLocationsInfo={unlinkedLocations}
    />
  </Dialog>
);

export default LinkInfoDialog;
