import React from 'react';
import { MUIDataTableColumn, MUIDataTableColumnDef } from 'mui-datatables';

import type {
  TTableBaseProps,
  TChangedColumn,
  TTabledTableData,
} from '../../types/TTable';

import Loading from '../loading';

import {
  changedColumnState,
  onDebounceSearchChange,
  optionsHandleOnDownloadCSV,
} from './helpers';

import { StyledTable } from './table-styles';

const Table = ({
  options,
  title,
  columns,
  data = [],
  row,
  setPage,
  setPageSize,
  setQuery,
  loading,
  ...rest
}: TTableBaseProps) => {
  const [changedColumn, setChangedColumn] = React.useState<TChangedColumn>(changedColumnState);
  const [dataTable, setDataTable] = React.useState<TTabledTableData>([]);
  const [tableColumns, setTableColumns] = React.useState<MUIDataTableColumn[]>(
    [],
  );
  const [rowsExpanded, setRowsExpanded] = React.useState<any[]>([]);

  const handleRowsExpanded = React.useCallback(
    (rowIndex: number, action: 'add' | 'remove') => {
      const addRowExpanded = (prevState: number[]) => [...prevState, rowIndex];
      const removeRowExpanded = (prevState: number[]) => prevState.filter((currentRowIndex) => currentRowIndex !== rowIndex);
      setRowsExpanded(action === 'add' ? addRowExpanded : removeRowExpanded);
    },
    [],
  );

  React.useEffect(() => {
    setDataTable(data);
    setTableColumns(columns);
  }, [data, columns]);

  React.useEffect(() => {
    if (changedColumn.action === null || changedColumn.changedColumn === null) return;

    setDataTable((prevDataTable: any) => prevDataTable.map((dataTableRow: any) => dataTableRow.map((dataTableRowColumn: any) => (dataTableRowColumn.columnRef === changedColumn.changedColumn
      ? {
        ...dataTableRowColumn,
        visible: changedColumn.action !== 'remove',
      }
      : dataTableRowColumn))));

    setTableColumns((prevTableColumns: any) => prevTableColumns.map((prevTableColumnsItem: any) => (prevTableColumnsItem.name === changedColumn.changedColumn
      ? {
        ...prevTableColumnsItem,
        options: {
          display: changedColumn.action !== 'remove',
        },
      }
      : prevTableColumnsItem)));
  }, [changedColumn]);

  const defaultOptions = React.useMemo(
    () => ({
      onDownload: optionsHandleOnDownloadCSV,
      onViewColumnsChange: (currentChangedColumn: string, action: string) => {
        setChangedColumn(() => ({
          changedColumn: currentChangedColumn,
          action,
        }));
      },
      customRowRender: (
        rowData: any,
        rowDataIndex: number,
        rowIndex: number,
      ) => row(rowData, rowDataIndex, rowIndex, handleRowsExpanded),
      onSearchChange: (queryText: string) => {
        if (!setQuery) return;

        if (!queryText) {
          if (setQuery) setQuery('');
          return;
        }

        if (setPage) setPage(0);
        onDebounceSearchChange(setQuery, queryText);
      },
      onSearchClose: () => {
        if (setPage) setPage(0);
        if (setQuery) setQuery('');
      },
      onChangePage: (currentPage: number) => {
        if (setPage) setPage(currentPage);
      },
      onChangeRowsPerPage: (numberOfRows: number) => {
        if (setPage) setPage(0);
        if (setPageSize) setPageSize(numberOfRows);
      },
      rowsExpanded,
      serverSide: true,
      rowsPerPageOptions: [10, 50, 100],
      elevation: 0,
      responsive: 'simple',
      draggableColumns: {
        enabled: true,
      },
      fixedHeader: true,
      download: false,
      filter: false,
      textLabels: {
        body: {
          noMatch:
            loading === true ? (
              <Loading className="data-table-loading" />
            ) : (
              'Nenhum resultado encontrado'
            ),
        },
        pagination: {
          next: 'Próxima página',
          previous: 'Página anterior',
          rowsPerPage: 'Linhas por página',
          displayRows: 'de',
        },
        toolbar: {
          search: 'Pesquisar',
          downloadCsv: 'Baixar como CSV',
          print: 'Imprimir',
          viewColumns: 'Visualizar colunas',
          filterTable: 'Filtrar',
        },
        filter: {
          all: 'Todos',
          title: 'Título',
          reset: 'Resetar',
        },
        viewColumns: {
          title: 'Mostrar Colunas',
          titleAria: 'Mostrar/esconder colunas da tabela',
        },
        selectedRows: {
          text: 'linha(s) selecionada(s)',
          delete: 'Apagar',
          deleteAria: 'Apagar Linhas Selecionadas',
        },
      },
      ...options,
    }),
    [options, rowsExpanded, loading, setQuery],
  );

  return (
    <StyledTable
      title={title}
      options={defaultOptions}
      columns={tableColumns}
      data={dataTable}
      {...rest}
    />
  );
};

export default Table;
