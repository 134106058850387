import axios from 'axios';

export default async function getHandler(accessToken, id) {
  const requestOptions = {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  };
  try {
    const { data } = await axios.get(
      `${process.env.REACT_APP_HANDLERS_API_URL}/${id}`,
      requestOptions,
    );

    console.log(data);
    return data;
  } catch (err) {
    console.log(err);
    return err;
  }
}
