import axios from 'axios';
import React from 'react';

type TGetAllLoginHistory = {
  accessToken: string;
  page: number;
  pageSize: number;
  username: string;
  email: string;
  'ip_address': string;
  'ip_location': string;
  'start_date': string;
  'end_date': string;
  setIsFetching?: React.Dispatch<React.SetStateAction<boolean>>;
  setTokenLikeExpired?: () => void;
}

export default async function getAllLoginHistory({
  accessToken,
  page,
  pageSize,
  username,
  email,
  ip_address,
  ip_location,
  start_date,
  end_date,
  setIsFetching,
  setTokenLikeExpired,
}: TGetAllLoginHistory): Promise<TGetAllLoginHistory | null> {
  try {
    if (setIsFetching) setIsFetching(true);

    const { data } = await axios.get(
      `${process.env.REACT_APP_LOGIN_HISTORY_API_URL}/get-all`,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
        params: {
          username,
          email,
          ip_address,
          ip_location,
          start_date,
          end_date,
          page,
          pageSize,
        },
      },
    );

    return data;
  } catch (err: any) {
    if (err.response?.status === 401 && setTokenLikeExpired) setTokenLikeExpired();
    return null;
  } finally {
    if (setIsFetching) setIsFetching(false);
  }
}
