import axios from 'axios';
import qs from 'querystring';

import { TRefreshGoogleAccessToken } from '../../types/TConnections';

export default async function refreshGoogleAccessToken({
  refreshToken,
  isRefreshToken,
  onError,
}: TRefreshGoogleAccessToken) {
  try {
    if (isRefreshToken) isRefreshToken(true);

    const requestBody = {
      refresh_token: refreshToken,
      client_id: process.env.REACT_APP_GOOGLE_CLIENT_ID,
      client_secret: process.env.REACT_APP_GOOGLE_CLIENT_SECRET,
      redirect_uri: process.env.REACT_APP_GOOGLE_CLIENT_REDIRECT_URL,
      grant_type: 'refresh_token',
    };

    const config = {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };

    const { data } = await axios.post('https://oauth2.googleapis.com/token', qs.stringify(requestBody), config);

    return data;
  } catch (err: any) {
    if (err.response?.status === 404 && onError) {
      return onError(true);
    }

    if (onError) return onError(false);

    return null;
  } finally {
    if (isRefreshToken) isRefreshToken(false);
  }
}
