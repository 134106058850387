export default function formatWorkingHours(workingHours) {
  const {
    monday, tuesday, wednesday, thursday, friday, saturday, sunday,
  } = workingHours[0];

  const periods = [];

  const [mondayOpen, mondayClose] = monday.split(' - ');
  const [tuesdayOpen, tuesdayClose] = tuesday.split(' - ');
  const [wednesdayOpen, wednesdayClose] = wednesday.split(' - ');
  const [thursdayOpen, thursdayClose] = thursday.split(' - ');
  const [fridayOpen, fridayClose] = friday.split(' - ');
  const [saturdayOpen, saturdayClose] = saturday.split(' - ');
  const [sundayOpen, sundayClose] = sunday.split(' - ');

  if (mondayOpen !== '') {
    periods.push({
      openDay: 'Monday',
      closeDay: 'Monday',
      openTime: mondayOpen,
      closeTime: mondayClose,
    });
  }
  if (tuesdayOpen !== '') {
    periods.push({
      openDay: 'Tuesday',
      closeDay: 'Tuesday',
      openTime: tuesdayOpen,
      closeTime: tuesdayClose,
    });
  }
  if (wednesdayOpen !== '') {
    periods.push({
      openDay: 'Wednesday',
      closeDay: 'Wednesday',
      openTime: wednesdayOpen,
      closeTime: wednesdayClose,
    });
  }
  if (thursdayOpen !== '') {
    periods.push({
      openDay: 'Thursday',
      closeDay: 'Thursday',
      openTime: thursdayOpen,
      closeTime: thursdayClose,
    });
  }
  if (fridayOpen !== '') {
    periods.push({
      openDay: 'Friday',
      closeDay: 'Friday',
      openTime: fridayOpen,
      closeTime: fridayClose,
    });
  }
  if (saturdayOpen !== '') {
    periods.push({
      openDay: 'Saturday',
      closeDay: 'Saturday',
      openTime: saturdayOpen,
      closeTime: saturdayClose,
    });
  }
  if (sundayOpen !== '') {
    periods.push({
      openDay: 'Sunday',
      closeDay: 'Sunday',
      openTime: sundayOpen,
      closeTime: sundayClose,
    });
  }

  return periods;
}
