import * as R from 'ramda';

import type {
  TSetCurrentSyncStatusData,
} from '../../types/TLocationProfile';

import type { TTicketData } from '../../types/TTickets';

import type { TLocationEntity } from '../../types/TLocation';

import {
  EInfoFieldsFocus,
  ELinksFieldsFocus,
  EUpdateLocationInfoFields,
  EUpdateLocationLinksFields,
  EUpdateLocationWorkingHoursFields,
  EWorkingHoursFocus,
  TContractItem,
  TValidateFields,
} from './types';

import {
  phoneValidate,
} from '../../utils/fields-validate';
import isNilOrEmpty from '../../utils/is-null-or-empty';
import { TWorkingHoursEntity } from '../../types/TWorkingHours';
import { sortByDay } from './working-hours/helpers';

export const setCurrentSyncStatusData = ({
  ticketData,
  setSyncStatusData,
}: TSetCurrentSyncStatusData) => {
  const ticketsOrdered = R.takeLast(1, R.sortBy(R.prop('id'), ticketData))[0];

  if (R.isNil(ticketsOrdered)) return;

  const data: TTicketData = JSON.parse(ticketsOrdered.data) || {};

  if (!data) return;

  const { status: platformsStatus } = data;

  setSyncStatusData(platformsStatus);
};

export const formatContractList = (contractList: any): TContractItem[] => {
  const formatted: {name: string, value: number}[] = [];

  if (!R.isNil(contractList)) {
    contractList.forEach((profile: any) => {
      formatted.push({
        name: profile.name,
        value: profile.id,
      });
    });
  }

  return formatted;
};

export const cleanUpWorkingHours = (workingHours: TWorkingHoursEntity[]) => workingHours
  .map((workingHoursDay) => ({
    ...workingHoursDay,
    times: workingHoursDay.times?.filter((time) => !!time).map((time) => ({
      id: time.id, open: time.open, close: time.close, deleted_at: time.deleted_at,
    })),
  })).sort((prevDay, nextDay) => sortByDay({ prevDay, nextDay }));

export const cleanDeletedTimes = (workingHours) => cleanUpWorkingHours(workingHours
  .map((workingHoursDay) => ({
    ...workingHoursDay,
    times: workingHoursDay.times
      .filter((time) => !time.is_deleted),
  })));

export const getDataToUpdate = (
  updatedLocation: TLocationEntity,
  googleServiceArea,
) => ({
  store_code: updatedLocation.store_code,
  name: updatedLocation.name,
  phone: updatedLocation.phone,
  secondary_phone: updatedLocation.secondary_phone,
  email: updatedLocation.email,
  google_categories: updatedLocation.google_categories,
  facebook_categories: updatedLocation.facebook_categories,
  foursquare_categories: updatedLocation.foursquare_categories,
  zip_code: updatedLocation.zip_code,
  country: updatedLocation.country,
  address1: updatedLocation.address1,
  address2: updatedLocation.address2,
  neighborhood: updatedLocation.neighborhood,
  city: updatedLocation.city,
  state: updatedLocation.state,
  does_not_serve_at_this_address:
      updatedLocation.does_not_serve_at_this_address,
  google_service_area: googleServiceArea,
  short_description: updatedLocation.short_description,
  long_description: updatedLocation.long_description,
  website: updatedLocation.website || '',
  facebook: updatedLocation.facebook || '',
  instagram: updatedLocation.instagram || '',
  twitter: updatedLocation.twitter || '',
  youtube: updatedLocation.youtube || '',
  foursquare: updatedLocation.foursquare || '',
  linkedin: updatedLocation.linkedin || '',
  yelp: updatedLocation.yelp || '',
  other_links: updatedLocation.other_links || '{}',
  working_hours: cleanDeletedTimes(updatedLocation.working_hours) || [],
  special_dates: updatedLocation.special_dates || [],
});

const SensibleFields = [
  'name',
  'phone',
  'secondary_phone',
  'google_categories',
  'facebook_categories',
  'foursquare_categories',
  'zip_code',
  'country',
  'address1',
  'address2',
  'address',
  'address-number',
  'neighborhood',
  'city',
  'state',
  'does_not_serve_at_this_address',
];

export const getFieldsToChange = (
  updatedLocation:TLocationEntity, activeLocation:TLocationEntity, requestStatus: string,
) => {
  const alternateUpdatedLocation = { ...updatedLocation };
  alternateUpdatedLocation.working_hours = cleanUpWorkingHours(alternateUpdatedLocation.working_hours);
  const alternateActiveLocation = { ...activeLocation };
  alternateActiveLocation.working_hours = cleanUpWorkingHours(alternateActiveLocation.working_hours);
  const checkedFieldsToChange = requestStatus === 'APPROVED' ? Object.keys(alternateUpdatedLocation) : Object.keys(alternateUpdatedLocation).filter((locationKey) => !SensibleFields.includes(locationKey));

  const fieldsToChange = checkedFieldsToChange.reduce((result, key) => {
    if (!R.equals(alternateUpdatedLocation[key], alternateActiveLocation[key])) {
      return { ...result, [key]: alternateUpdatedLocation[key] };
    }
    return result;
  }, {});

  return fieldsToChange;
};

export const noLongerHasGoogleCategories = (previousCategories: string, newCategories: string) => !R.isEmpty(JSON.parse(previousCategories)) && R.isEmpty(JSON.parse(newCategories));

export const hasSomethingNew = (
  updatedLocation: TLocationEntity,
  activeLocation: TLocationEntity,
) => {
  const alternateUpdatedLocation = { ...updatedLocation };
  alternateUpdatedLocation.working_hours = cleanUpWorkingHours(alternateUpdatedLocation.working_hours);
  const alternateActiveLocation = { ...activeLocation, placeInfos: activeLocation.google_service_area?.places.placeInfos };
  alternateActiveLocation.working_hours = cleanUpWorkingHours(alternateActiveLocation.working_hours);

  const keys = Object.keys(alternateUpdatedLocation);
  for (let i = 0; i < keys.length; i += 1) {
    const key = keys[i];

    if (
      key in EUpdateLocationInfoFields
      || key in EUpdateLocationLinksFields
      || key in EUpdateLocationWorkingHoursFields
    ) {
      if (!R.equals(alternateUpdatedLocation[key], alternateActiveLocation[key])) return true;
    }
  }
  return false;
};

export const getFieldTabIndex = (fieldName: string) => {
  if (EInfoFieldsFocus[fieldName]) return 0;
  if (ELinksFieldsFocus[fieldName]) return 1;
  if (EWorkingHoursFocus[fieldName]) return 2;

  return -1;
};

export const getGooglePlatformStatus = (userProfileName: string, activeLocation: any) => {
  const locationIsLinkedToGoogle = activeLocation.google_location_id && activeLocation.google_location_id !== '';
  const locationHasSyncPermission = activeLocation.sync_permission;
  const isDefaultUser = userProfileName === 'Usuário Padrão';

  if (!locationIsLinkedToGoogle) return 'IN_PROGRESS';
  if (!locationHasSyncPermission && !isDefaultUser) return 'AUTO_SYNC';
  if (!locationHasSyncPermission) return 'IN_PROGRESS';

  return 'AUTO_SYNC';
};

export const checkInputFields = (inputData: any, message) => Object.entries(inputData).every(([key, value]: any) => {
  if (key === 'name' && !value.trim()) {
    message('O campo Nome é obrigatório', { variant: 'error' });
    return false;
  }

  if (key === 'phone') {
    if (phoneValidate(inputData['secondary_phone']) && !phoneValidate(value)) {
      message('O campo Telefone Principal é obrigatório caso exista telefone secundário', { variant: 'error' });
      return false;
    }
  }

  if (key === 'long_description' && value?.length > 750) {
    message('O campo Descrição só pode conter no máximo 750 caracteres', { variant: 'error' });
    return false;
  }

  if (key === 'short_description' && value?.length > 250) {
    message('O campo Descrição Curta só pode conter no máximo 250 caracteres', { variant: 'error' });
    return false;
  }
  return true;
});

export const getDeletedSpecialDates = (activeLocation: TLocationEntity, updatedLocation: TLocationEntity) => {
  if (isNilOrEmpty(activeLocation.special_dates)) return;

  const deletedSpecialDates = activeLocation.special_dates
    .filter((specialDate) => !updatedLocation.special_dates
      .map((newSpecialDates) => newSpecialDates.id).includes(specialDate.id));

  if (isNilOrEmpty(deletedSpecialDates)) return;

  return deletedSpecialDates;
};

const fields = {
  phone: { name: 'phone', nickname: 'Telefone Principal', required: false },
  secondary_phone: { name: 'phone', nickname: 'Telefone Secundário', required: false },
  email: { name: 'email', nickname: 'E-mail', required: true },
  zip_code: { name: 'zipcode', nickname: 'CEP', required: true },
  long_description: { name: 'longDescription', nickname: 'Descrição', required: false },
};

export const validateFields = ({
  isOutsideBrazil,
  message,
  updatedLocation,
}: TValidateFields) => {
  const validations = Object.keys(fields).map(key => {
    const field = fields[key];
    const locationValue = updatedLocation[key];
    let value: string | null = `${locationValue}`;

    if (key === 'phone' || key === 'secondary_phone') {
      const verificationPhone = locationValue.replaceAll(/\D/g, '');

      if (!verificationPhone) value = null;
    }

    return ({
      scheme: { [field.name]: locationValue },
      message,
      value,
      nickname: field.nickname,
      isOutsideBrazil,
      isRequired: field.required,
    });
  });

  return validations;
};
