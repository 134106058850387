import axios from 'axios';
import qs from 'querystring';

export default async function generateRefreshToken({
  authorizationCode,
  isLoading = null,
}) {
  try {
    if (isLoading) isLoading(true);

    const requestBody = {
      code: authorizationCode,
      client_id: process.env.REACT_APP_GOOGLE_CLIENT_ID,
      client_secret: process.env.REACT_APP_GOOGLE_CLIENT_SECRET,
      redirect_uri: 'postmessage',
      grant_type: 'authorization_code',
    };

    const config = {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };

    const { data } = await axios.post('https://oauth2.googleapis.com/token', qs.stringify(requestBody), config);

    return data;
  } catch (err) {
    console.log('ERROR =>>', err.response);

    return err;
  } finally {
    if (isLoading) isLoading(false);
  }
}
