import Axios from 'axios';
import { TForceUpdateBatchInfosLocations } from '../../../types/TBatchUpdate';

export const forceUpdateBatchInfosLocations = async ({
  batchInfosLocationsId,
  userAccessToken,
  isUpdating,
}: TForceUpdateBatchInfosLocations) => {
  try {
    if (isUpdating) isUpdating(true);

    const { data } = await Axios.post(
      `${process.env.REACT_APP_SAAS_BATCH_GOOGLE_MODULES}/batch-infos-locations/manual-update/${batchInfosLocationsId}`,
      null,
      {
        headers: {
          Authorization: `Bearer ${userAccessToken}`,
        },
      },
    );
    return {
      status: 'SUCCESS',
      data,
    };
  } catch (err: any) {
    return {
      status: 'ERROR',
      data: err.response,
    };
  } finally {
    if (isUpdating) isUpdating(false);
  }
};
