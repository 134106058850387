import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams, useHistory } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import * as R from 'ramda';
import { Helmet } from 'react-helmet';

import APIGetInvite from '../../services/invites/getOne';
import validateInvite from '../../services/invites/validateInvite';
import deleteInvite from '../../services/invites/delete';
import signInUser from '../../services/signInUser';
import createGuest from '../../services/guest/create';
import createCompanyGuest from '../../services/guest/createCompanyGuest';
import createCompanyGroupGuest from '../../services/guest/createCompanyGroupGuest';
import createLocationGroupGuest from '../../services/guest/createLocationGroupGuest';
import getProfiles from '../../services/profiles/getProfiles';
import getProfileFunctions from '../../services/profiles/getProfileFunctions';
import getProfileMenu from '../../services/profiles/getProfileMenu';
import addUserAccessibleLocation from '../../services/users/addUserAccessibleLocation';
import addUserAccessibleCompany from '../../services/users/addUserAccessibleCompany';
import addUserAccessibleCompanyGroups from '../../services/users/addUserAccessibleCompanyGroups';
import addUserAccessibleLocationGroups from '../../services/users/addUserAccessibleLocationGroups';

import { translations } from '../../translations';

import { Creators as AuthActions } from '../../redux/ducks/auth';
import { Creators as RulesActions } from '../../redux/ducks/rules';

import hublocalLogo from '../../assets/images/hublocal-logo.png';

import { useUncontrolledField } from '../../hooks';

import {
  emailScheme,
  nameScheme,
  passwordScheme,
} from './fields-scheme';

import {
  Loading,
  Button,
  OverlayLoading,
} from '../../components';

import {
  StyledReceiveInvite,
  StyledForm,
  StyledFieldsGroup,
  StyledExpiredInvite,
} from './receive-invite-styles';
import signUpUser from '../../services/signUpUser';
import getUserByEmail from '../../services/users/getUserByEmail';
import createUserInvited from '../../services/invites/create-user-invited';
import { EInviteType } from './enum/invite-type.enum';
import { handleFormValidation } from './helpers';

const ReceiveInvite = () => {
  const params = useParams();
  const history = useHistory();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  const { language, user } = useSelector((state) => state.AuthReducer);
  const { login: loginTranslations } = translations[language];

  const [isConfirm, setIsConfirm] = React.useState(false);
  const [fields, setFields] = React.useState([]);

  const [inviteId, setInviteId] = React.useState(null);
  const [isValidInvite, setIsValidInvite] = React.useState(false);
  const [, setInviteStatus] = React.useState(null);
  const [, setInviteUserEmail] = React.useState(null);
  const [inviteEmail, setInviteEmail] = React.useState(null);
  const [invitePermission, setInvitePermission] = React.useState(null);
  const [inviteLocationOriginName, setInviteLocationOriginName] = React.useState(null);
  const [inviteLocationOriginId, setInviteLocationOriginId] = React.useState(null);
  const [inviteLocationGroupOriginName, setInviteLocationGroupOriginName] = React.useState(null);
  const [inviteLocationGroupOriginId, setInviteLocationGroupOriginId] = React.useState(null);
  const [inviteCompanyGroupOriginName, setInviteCompanyGroupOriginName] = React.useState(null);
  const [inviteCompanyGroupOriginId, setInviteCompanyGroupOriginId] = React.useState(null);
  const [inviteCompanyOriginName, setInviteCompanyOriginName] = React.useState(null);
  const [inviteCompanyOriginId, setInviteCompanyOriginId] = React.useState(null);
  const [isLoadingTicket, setIsLoadingTicket] = React.useState(false);
  const [hasTicket, setHasTicket] = React.useState(true);
  const [inviteExternalInvitation, setInviteExternalInvitation] = React.useState(false);

  const {
    Field: Name
  } = useUncontrolledField({
    fieldScheme: { ...nameScheme, label: 'Nome' },
    saveIn: setFields
  })

  const {
    Field: Email,
    setDefaultValue: setEmailCurrentValue,
  } = useUncontrolledField({
    fieldScheme: { ...emailScheme, label: loginTranslations.user },
    saveIn: setFields,
  });

  const { Field: Password } = useUncontrolledField({
    fieldScheme: { ...passwordScheme, label: loginTranslations.password },
    saveIn: setFields,
  });

  const { Field: ConfirmPassword } = useUncontrolledField({
    fieldScheme: { ...passwordScheme, label: 'Confirmar Senha' },
    saveIn: setFields,
  });

  const setTokenLikeExpired = () => {
    dispatch(AuthActions.setUser({ ...user, accessToken: 'expired' }));
  };

  const getInviteData = React.useCallback(async () => {
    const queryParams = {};

    window.location.search.slice(1).split('&').map((element) => {
      const [key, value] = element.split('=');
      queryParams[key] = value;
    });

    setInviteUserEmail(queryParams.user);
    setInviteId(queryParams.inviteId);

    const getInviteResponse = await APIGetInvite({
      id: params.inviteId,
      setTokenLikeExpired,
      isFetchinging: setIsLoadingTicket,
    });

    if (R.isNil(getInviteResponse) || R.isEmpty(getInviteResponse)) {
      setHasTicket(false);
      return;
    }

    const {
      id: inviteIdValue,
      location: inviteLocationOriginNameData = null,
      location_access_level_profile: inviteAccessLevel = null,
      status: inviteStatusInfo = 'PENDING',
      company: inviteCompanyOriginData,
      email: inviteEmailTarget = null,
      company_group: inviteCompanyGroupData = null,
      location_group: inviteLocationGroupData = null,
      external_invitation
    } = getInviteResponse;

    setEmailCurrentValue(inviteEmailTarget);

    setInviteId(inviteIdValue);
    setInviteEmail(inviteEmailTarget);
    setInvitePermission(inviteAccessLevel);
    setInviteStatus(inviteStatusInfo);
    setInviteLocationOriginName(inviteLocationOriginNameData?.name || null);
    setInviteLocationOriginId(inviteLocationOriginNameData?.id || null);
    setInviteCompanyOriginName(inviteCompanyOriginData?.name || null);
    setInviteCompanyOriginId(inviteCompanyOriginData?.id || null);
    setInviteCompanyGroupOriginId(inviteCompanyGroupData?.id || null);
    setInviteCompanyGroupOriginName(inviteCompanyGroupData?.name || null);
    setInviteLocationGroupOriginId(inviteLocationGroupData?.id || null);
    setInviteLocationGroupOriginName(inviteLocationGroupData?.name || null);
    setInviteExternalInvitation(external_invitation);

    const { isValid } = await validateInvite({ id: params.inviteId });

    setIsValidInvite(isValid);
  }, []);

  React.useEffect(() => {
    getInviteData();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();

    setIsConfirm(true);

    const fieldsValue = fields.map(field => field.getValue());
    const fieldsCheck = fields.map(field => field.firedCheck);

    if (fieldsCheck.every(check => check())) {
      const userEmail = fieldsValue[0];
      const userPassword = fieldsValue[1];

      const userSignInResponse = await signInUser(userEmail, userPassword);

      if (R.isNil(userSignInResponse)) {
        enqueueSnackbar('Usuário ou senha inválidos!', { variant: 'error' });

        return;
      }

      if (userEmail !== inviteEmail) {
        enqueueSnackbar('Email inválido para este convite', { variant: 'error' });
        setIsConfirm(false);

        return;
      }

      const {
        accessToken,
        user: userThisAccount,
      } = userSignInResponse;

      const {
        id: userId,
        profiles: [userProfile],
      } = userThisAccount;

      try {
        if (inviteLocationOriginId) {
          await createGuest({
            accessToken,
            userId,
            locationAccessLevelProfileId: invitePermission.id,
            locationId: inviteLocationOriginId,
            created_by: userId,
            setTokenLikeExpired,
          });

          await addUserAccessibleLocation({
            accessToken,
            userId,
            locationId: inviteLocationOriginId,
            setTokenLikeExpired,
          });
        }

        if (inviteCompanyOriginId) {
          await createCompanyGuest({
            accessToken,
            userId,
            companyId: inviteCompanyOriginId,
            created_by: userId,
            setTokenLikeExpired,
          });

          await addUserAccessibleCompany({
            accessToken,
            userId,
            companyId: inviteCompanyOriginId,
            setTokenLikeExpired,
          });
        }

        if (inviteCompanyGroupOriginId) {
          await createCompanyGroupGuest({
            accessToken,
            userId,
            companyGroupId: inviteCompanyGroupOriginId,
            created_by: userId,
            setTokenLikeExpired,
          });

          await addUserAccessibleCompanyGroups({
            accessToken,
            userId,
            companyGroupId: inviteCompanyGroupOriginId,
            setTokenLikeExpired,
          });
        }

        if (inviteLocationGroupOriginId) {
          await createLocationGroupGuest({
            accessToken,
            userId,
            locationGroupId: inviteLocationGroupOriginId,
            created_by: userId,
            setTokenLikeExpired,
          });

          await addUserAccessibleLocationGroups({
            accessToken,
            userId,
            locationGroupId: inviteLocationGroupOriginId,
            setTokenLikeExpired,
          });
        }

        await deleteInvite({
          accessToken,
          id: inviteId,
          setTokenLikeExpired,
        });

        const { functions: profileFunctions } = await getProfileFunctions({
          accessToken,
          profileId: userProfile.id,
          setTokenLikeExpired,
        });

        const { menu: profileMenu } = await getProfileMenu({
          accessToken,
          profileId: userProfile.id,
          setTokenLikeExpired,
        });

        const formattedProfile = { ...userProfile, functions: profileFunctions, menu: profileMenu };

        const formattedData = { ...userSignInResponse, user: { ...userThisAccount, profiles: [formattedProfile] } };

        dispatch(AuthActions.setUser(formattedData));

        const rules = {};
        const profiles = await getProfiles({ accessToken, setTokenLikeExpired });

        profiles[0].forEach(async (element) => {
          const { functions } = await getProfileFunctions({ accessToken, profileId: element.id, setTokenLikeExpired });
          const funcs = [];
          functions.forEach((func) => {
            funcs.push(func.full_name);
          });
          rules[element.name] = { static: funcs };
          dispatch(RulesActions.setRules(rules));
        });

        history.push('/my-companies');
      } catch (err) {
        enqueueSnackbar('Algo deu errado', { variant: 'error' });
      } finally {
        setIsConfirm(false);
      }
    }
  };

  const handleCreateAccount = async (event) => {
    event.preventDefault();

    const [name, email, password, confirmPassword] =
      fields.map((field) => field.getValue());

    if (password !== confirmPassword)
      return enqueueSnackbar('As senhas nao coincidem', { variant: 'warning' });

    const formErrors = handleFormValidation({ fields, enqueueSnackbar });

    if (formErrors) return;

    if (inviteEmail !== email)
      return enqueueSnackbar(
        'O e-mail nao coincide com o e-mail cadastrado no convite',
        { variant: 'error' }
      );

    try {
      let inviteType;

      if (inviteLocationOriginId)
        inviteType = EInviteType.LOCATION;

      if (inviteLocationGroupOriginId)
        inviteType = EInviteType.LOCATION_GROUP;

      if (inviteCompanyOriginId)
        inviteType = EInviteType.COMPANY;

      if (inviteCompanyGroupOriginId)
        inviteType = EInviteType.COMPANY_GROUP;

      await createUserInvited({
        email,
        password,
        name,
        inviteId,
        inviteType,
        setIsFetching: setIsConfirm,
        enqueueSnackbar,
        history
      });
    } catch (error) {
      return error;
    }
  }

  return (
    <>
      {isLoadingTicket && <OverlayLoading textToLoading="Carregando informações do convite" />}

      <StyledReceiveInvite>
        <Helmet>
          <meta property="og:title" content="Você recebeu um convite - SaaS Hublocal" />
          <meta property="og:description" content={inviteExternalInvitation ? "Crie sua conta para aceitar o convite" : "Comprove sua identidade para aceitar o convite"} />

          <title>Convite - SaaS HubLocal</title>
        </Helmet>

        <img className="hublocal-logo" src={hublocalLogo} alt="Saas Hublocal" />

        {(hasTicket && !isLoadingTicket && isValidInvite) && (
          <StyledForm>
            <small>
              Você foi convidado para participar d
              {inviteLocationOriginName ? 'o' : 'a'}
              {' '}
              {inviteLocationOriginName ? 'local' : 'empresa'}
              <br />
              <b>{inviteLocationOriginName || inviteCompanyOriginName}</b>
              {' '}
              {(!R.isNil(invitePermission) && !R.isEmpty(invitePermission))
                && (
                  <>
                    como
                    <b>
                      {' '}
                      {invitePermission.name}
                    </b>
                  </>
                )}
              <br />
              {
                inviteExternalInvitation
                  ? 'Crie sua conta para aceitar o convite:'
                  : 'Comprove sua identidade para aceitar o convite:'
              }
            </small>
            <StyledFieldsGroup>
              <>
                {inviteExternalInvitation && <Name />}
                <Email />
                <Password />
                {inviteExternalInvitation && <ConfirmPassword />}
                <Button
                  onClick={inviteExternalInvitation ? handleCreateAccount : handleSubmit}
                  className="confirm-button"
                  disabled={isConfirm}
                >
                  {isConfirm ?
                    (<Loading />)
                    :
                    (inviteExternalInvitation ? 'Criar Conta' : 'Confirmar')
                  }
                </Button>
              </>
            </StyledFieldsGroup>
          </StyledForm>
        )}

        {(!hasTicket && !isLoadingTicket) && <StyledExpiredInvite>Esse convite expirou!</StyledExpiredInvite>}
      </StyledReceiveInvite>
    </>
  );
};

export default ReceiveInvite;
