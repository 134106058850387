import styled, { css } from 'styled-components';

import { lighten } from 'polished';

import { TTextarea } from '../../types/TTextarea';

type StyledTextareaCustomProps = Pick<TTextarea, 'border' | 'borderRadius' | 'borderAlert'>;

export const StyledTextarea = styled.div<StyledTextareaCustomProps>`
  width: 100%;
  display: flex;
  flex-direction: column;

  label {
    font-size: 1.5rem;
    font-weight: normal;
    color: {props => props.theme.colors.black};
    display: flex;
    flex-direction: column;
  }

  .sublabel {
    font-weight: 500;
    font-size: 1.2rem;
    color: ${props => lighten(0.5, props.theme.colors.black)};  
  }

  textarea {
    resize: none;
    min-height: 25rem;
    padding: 2rem;
    width: 100%;

    ${(props) => props.border && !props.borderAlert && css`
        border: 2px solid ${lighten(0.4, props.theme.colors.black)};
    `};

    ${(props) => props.border && props.borderAlert && css`
      border: 2px solid ${props.theme.colors.red};
    `};

    ${(props) => props.borderRadius
      && css`
        border-radius: 0.4rem;
      `};
  }
`;

export const StyledTextareaValidate = styled.div<{ type: string }>`
  margin-top: 0.3rem;
  font-size: 1rem;
  font-weight: 500;
  text-transform: uppercase;

  ${(props) => props.type === 'error'
    && css`
      color: ${props.theme.colors.red};
    `};
`;
