import styled from 'styled-components';
import { Paper } from '@material-ui/core';
import { ChevronRight } from '@material-ui/icons';

export const StyledProgressInfo = styled(Paper)`
  display: flex;
  flex-direction: column;
  padding: 2rem;
  max-height: 30rem;
  overflow-y: auto;

  h2 {
    font-weight: 700;
    font-size: 1.5rem;
    margin-bottom: 2rem;
  }
`;

export const StyledProgressContent = styled.div`
  margin-top: 2rem;
  display: flex;
  flex-direction: column;

  p {
    font-size: 1.2rem;
    font-weight: 500;
    margin-bottom: 1.5rem;
  }

  span {
    font-size: 1.3rem;
    font-weight: 500;
    margin-bottom: 1rem;
  }

  ul {
    list-style: disc;
  }

  li {
    display: flex;
    align-items: center;
    font-size: 1.2rem;
    font-weight: bolder;
    color: ${props => props.theme.colors.primary};
    cursor: pointer;
    list-style-type: none;
  }
`;

export const StyledChevronRight = styled(ChevronRight)`
  height: 2rem;
  width: 2rem;
`;
