import styled, { css } from 'styled-components';
import { rgba } from 'polished';

import { devices } from '../../assets/styles/devices';

export const StyledMainContainer = styled.div`
  display: flex;
  width: 100%;
  height: 100%;

  @media ${devices.tabletLandscape} {
    flex-direction: row;
    align-items: flex-start;
  }
`;

export const StyledContainer = styled.div`
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  height: 100%;
  width: 100%;
  transition: 0.3s;
  position: relative;

  ${props => props.menuMobileIsOpen
    && css`
      margin-right: -25rem;
    `}

  @media ${devices.tabletLandscape} {
    flex-direction: row;
  }
`;

export const StyledWrapperContent = styled.div`
  width: 100%;
  height: 100%;
  padding-top: 7rem;

  @media ${devices.tabletLandscape} {
    padding-left: 7rem;
    padding-top: 0;
  }
`;

export const StyledShadowOverlay = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1102;
  background: ${props => rgba(props.theme.colors.black, 0.6)};

  @media ${devices.tabletLandscape} {
    display: none;
  }
`;
