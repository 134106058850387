import styled from 'styled-components';
import { lighten } from 'polished';
import { ChevronRight } from '@material-ui/icons';

export const StyledSendInvite = styled.div`
  display: flex;
  flex-direction: column;
  background: ${props => lighten(0.95, props.theme.colors.black)};
  padding: 2rem;
  position: relative;

  .button {
    margin-bottom: 1.5rem;

    &:last-of-type {
      margin-bottom: 0;
    }
  }

  .empty-profiles {
    color: ${props => props.theme.colors.red};
  }

  .loading-pending-invite {
    margin: 1rem auto;
  }
`;

export const StyledFormButtons = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 2rem;
`;

export const StyledLink = styled.p`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  font-size: 1.2rem;
  font-weight: bolder;
  color: ${props => props.theme.colors.primary};
  cursor: pointer;
  list-style-type: none;

  p {
    margin-bottom: 0;
  }
`;

export const StyledChevronRight = styled(ChevronRight)`
  height: 2rem;
  width: 2rem;
`;
