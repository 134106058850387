import styled from 'styled-components';
import { Popover, Tab } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

import { shade, lighten } from 'polished';

import { light } from '../../../assets/styles/themes';

const DropDownDialogPopover = withStyles({
  paper: {
    background: light.colors.white,
    color: light.colors.white,
    borderRadius: 'none',
    width: '100%',
    maxWidth: '35rem',
  },
})(Popover);

export const StyledDropDownDialogPopover = styled(DropDownDialogPopover)<{ width: string }>`
  .MuiPopover-paper {
    width: ${props => (props.width ? `${props.width} !important` : 'auto')};
    border-radius: 0.5rem !important;
    border: 0.1rem solid ${props => lighten(0.90, props.theme.colors.black)};
  } 
  
  .popover-pagination {
    border-top: 0.2rem solid ${props => lighten(0.90, props.theme.colors.black)};
    color: ${props => props.theme.colors.black};
  } 

  .dropdown-popover-pagination {
    border-top: 0.2rem solid ${props => lighten(0.90, props.theme.colors.black)};
    color: ${props => props.theme.colors.black};
  }
  
  .is-loading-options {
    margin: 2rem auto;
  }
`;

export const StyledFormPopover = styled.form`
  display: flex;
  flex-direction: column;
  padding: 1.5rem 2rem;
  border-bottom: 1.5px solid ${props => lighten(0.3, props.theme.colors.grey)};

  input {
    border: none;
    width: 100%;
    color: ${props => props.theme.colors.black};
    border-radius: 0.5rem;
    height: 4.5rem;
    border: 1.5px solid ${props => lighten(0.2, props.theme.colors.grey)};
    font-size: 1.4rem;
    padding: 0 2rem;
    background: solid ${props => props.theme.colors.white};
    font-weight: 500;

    &::placeholder {
      color: ${props => lighten(0.2, props.theme.colors.black)};
    }
  }
`;

export const StyledDropDownDialogOptions = styled.ul`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 0;
  max-height: 22rem;
  overflow-y: scroll;
`;

export const StyledEmptyOptions = styled.span`
  padding: 1rem 2rem;
  color: ${props => props.theme.colors.red};
  font-size: 1.2rem;
  font-weight: 500;
  display: flex;
`;

export const StyledTab = styled(Tab)`
  padding: 0;
`;
