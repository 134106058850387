import React from 'react';
import { getActionType } from '../helper';

import {
  StyledOfferPostInfo,
  StyledMediaItems,
} from './offer-post-info-styles';

type TOfferPostInfo = {
  offerPostData: any;
  info: any
}

const OfferPostInfo = ({ offerPostData, info = null }: TOfferPostInfo) => {
  const {
    title,
    mediaItems,
    schedule: {
      startDate,
      endDate,
      startTime,
      endTime,
    },
    summary,
    actionType,
    url,
    termsConditions,
    couponCode,
    redeemOnlineUrl,
  } = offerPostData;

  return (
    <StyledOfferPostInfo>
      {info === 'topicType' && <p>Oferta</p>}

      {info === 'title' && <p>{title}</p>}

      {info === 'mediaItems' && (
        <StyledMediaItems>
          {mediaItems.map((element:any) => <li><a href={element.sourceUrl}>{element.sourceUrl}</a></li>)}
        </StyledMediaItems>
      )}

      {info === 'schedule'
        && (
        <>
          <span>
            {`Data de início: ${startDate.day}/${startDate.month}/${startDate.year}`}
          </span>
          <span>{`Horário de início: ${startTime.hours}:${startTime.minutes}`}</span>
          <span>
            {`Data de término: ${endDate.day}/${endDate.month}/${endDate.year}`}
          </span>
          <span>{`Horário de término: ${endTime.hours}:${endTime.minutes}`}</span>
        </>
        )}

      {info === 'summary' && summary}

      {(info === 'termsConditions' && termsConditions) && <a href={termsConditions}>{termsConditions}</a>}

      {(info === 'couponCode' && couponCode) && couponCode}
      {(info === 'redeemOnlineUrl' && redeemOnlineUrl) && <a href={redeemOnlineUrl}>{redeemOnlineUrl}</a>}

      {info === 'actionType' && <b>{`Tipo: ${getActionType(actionType)}`}</b>}
      {info === 'url' && `Url: ${url}`}
    </StyledOfferPostInfo>
  );
};

export default OfferPostInfo;
