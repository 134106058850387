import axios from 'axios';

export default async function getLocation(
  accessToken,
  userId,
  locationId,
) {
  const requestOptions = {
    headers: {
      'access-token': accessToken,
    },
  };

  try {
    const { data } = await axios.get(
      `${process.env.REACT_APP_GOOGLE_API_URL}/search/user/location/${userId}/${locationId}`,
      requestOptions,
    );

    return data;
  } catch (err) {
    return err.response;
  }
}
