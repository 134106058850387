import React from 'react';

import { TOverlayLoadingBaseProps } from '../../types/TOverlayLoading';

import Loading from '../loading';

import {
  StyledOverlayLoading,
} from './overlay-loading-styles';

const OverlayLoading = ({
  textToLoading = 'Carregando...',
  fullScreen = false,
}: TOverlayLoadingBaseProps) => (
  <StyledOverlayLoading fullScreen={fullScreen}>
    <Loading />
    <span className="text-disclaimer">{textToLoading}</span>
  </StyledOverlayLoading>
);

export default OverlayLoading;
