import React from 'react';
import { StyledContainer } from './action-button-styled';

type ActionButtonProps = {
  onClick(): void;
  iconImage: any;
}

const ActionButton = ({ onClick, iconImage }: ActionButtonProps) => (
  <StyledContainer
    onClick={onClick}
  >
    <img src={iconImage} alt={iconImage} />
  </StyledContainer>
);

export default ActionButton;
