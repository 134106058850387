import React from 'react';
import * as R from 'ramda';
import { format } from 'date-fns';
import { useSelector } from 'react-redux';
import { useHistory, Redirect } from 'react-router-dom';
import { Button, SvgIcon } from '@material-ui/core';
import * as Icons from '@material-ui/icons';

import { useAuth } from '../../hooks';

import {
  DataTable,
  Can,
} from '../../components';

import getUsers from '../../services/users/getUsers';
import getAllUserFunctions from '../../utils/getAllUserFunctions';
import getFunctions from '../../services/functions/getFunctions';
import deleteFunction from '../../services/functions/deleteFunction';
import getUserName from '../../services/users/getUserName';
import getDeletedCompanyGroups from '../../services/company-groups/getDeletedCompanyGroups';
import restoreDeletedCompanyGroup from '../../services/company-groups/restoreDeletedCompanyGroup';

import formatDate from '../../utils/formatDate';

const DeletedCompanyGroupsList = () => {
  const history = useHistory();
  const [loading, setLoading] = React.useState(false);
  const [deletedCompanyGroups, setDeletedCompanyGroups] = React.useState([]);
  const [dialogOpen, setDialogOpen] = React.useState(false);
  const [selectedFunction, setSelectedFunction] = React.useState({});
  const [functionGotDeleted, setFunctionGotDeleted] = React.useState(true);
  const [tableData, setTableData] = React.useState([]);

  const [searchQuery, setSearchQuery] = React.useState('');
  const [page, setPage] = React.useState(0);
  const [pageSize, setPageSize] = React.useState(10);
  const [dataCount, setDataCount] = React.useState(0);

  const {
    userAccessToken,
    userSetTokenLikeExpired,
  } = useAuth();

  const columns = [
    { name: 'Id', options: { display: false } },
    { name: 'Nome' },
    { name: 'Data da Criação', options: { sort: false } },
    { name: 'Data da Deleção', options: { sort: false } },
    { name: 'Proprietário', options: { sort: false } },
  ];

  const getDeletedCompanyGroupsList = async () => {
    const deletedCompanyGroupsResponse = await getDeletedCompanyGroups({
      accessToken: userAccessToken,
      page,
      pageSize,
      searchQuery,
      setTokenLikeExpired: userSetTokenLikeExpired,
    });

    if (R.isNil(deletedCompanyGroupsResponse)) return;

    const [data, amount] = deletedCompanyGroupsResponse;

    setDataCount(amount);
    setDeletedCompanyGroups(data);
  };

  React.useEffect(() => {
    setLoading(true);
    getDeletedCompanyGroupsList();

    setLoading(false);
  }, [functionGotDeleted, page, pageSize, searchQuery]);

  React.useEffect(() => {
    const data = [];

    deletedCompanyGroups.map(element => {
      const formattedDateDeleted = format(new Date(element.deleted_at), 'dd/MM/yyyy HH:mm:ss');

      data.push([
        element.id,
        element.name,
        element.date_created,
        formattedDateDeleted,
        element.owner_user.name,
      ]);
    });
    setTableData(data);
  }, [deletedCompanyGroups]);

  const onAddFunctionClicked = () => {
    history.push('/functions/create');
  };

  const onUpdateFunctionClicked = (id) => {
    history.push(`/functions/update/${id}`);
  };

  const onDeleteFunctionClicked = async (id) => {
    const deleteFunctionResponse = await deleteFunction({
      accessToken: userAccessToken, id,
    });

    if (deleteFunctionResponse) getDeletedCompanyGroupsList();
  };

  const onRestoreClicked = async (id) => {
    const restoreDeleted = await restoreDeletedCompanyGroup({
      accessToken: userAccessToken,
      id,
    });

    if (restoreDeleted) getDeletedCompanyGroupsList();
  };

  return (
    <Can
      perform="Functions/List"
      yes={() => (
        <div style={{ padding: '1em' }}>
          <DataTable
            isDeleteTable
            count={dataCount}
            page={page}
            setPage={setPage}
            pageSize={pageSize}
            setPageSize={setPageSize}
            searchQuery={searchQuery}
            setSearchQuery={setSearchQuery}
            title="Grupos Apagados de Empresas"
            columns={columns}
            data={tableData}
            onRestoreClicked={onRestoreClicked}
            onUpdateClicked={onUpdateFunctionClicked}
            onDeleteClicked={onDeleteFunctionClicked}
            addRegisterLabel="Criar Função"
            addRegisterLink="/functions/create"
            emptyTableTitle="Esta tabela não possui grupos de empresas a exibir"
            isGuestTable
          />
        </div>
      )}
      no={() => <Redirect to="/main" />}
    />
  );
};

export default DeletedCompanyGroupsList;
