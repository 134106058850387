import axios from 'axios';

import type { TGetAllTicketsFromLocation, TGetAllTicketsFromLocationResponse } from '../../types/TTickets';

export default async function getAllLocationTickets({
  accessToken,
  locationId,
  page = 0,
  pageSize = 20,
  query = '',
  status = '',
  setIsFetching = null,
  setTokenLikeExpired,
}: TGetAllTicketsFromLocation) {
  try {
    if (setIsFetching) setIsFetching(true);

    const requestOptions = {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    };

    const { data } = await axios.get(
      `${process.env.REACT_APP_TICKETS_API_URL}/location/${locationId}?page=${page}&pageSize=${pageSize}&query=${query}&status=${status}`,
      requestOptions,
    );

    return data;
  } catch (err: any) {
    if (err.response?.status === 401 && setTokenLikeExpired) { setTokenLikeExpired(); }

    return null;
  } finally {
    if (setIsFetching) setIsFetching(false);
  }
}
