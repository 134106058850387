import React from 'react';
import { MUIDataTableOptions } from 'mui-datatables';
import { useSnackbar } from 'notistack';

import { useAuth } from '../../../hooks';

import {
  BatchStatusIcon,
  DestructiveActionDialog,
  IconButton,
  Loading,
  TableBodyCell,
  TableBodyRow,
} from '../../../components';
import { ServiceBatchLocationsTable } from './service-batch-locations-table';
import { ServiceBatchInfoDialog } from './service-batch-info-dialog';
import {
  StyledActionLoadingWrap,
  StyledCellCustomButtons,
} from './service-batch-row-styles';
import { findOneServiceBatch, updateServiceBatch } from '../../../services/google-batch-modules/batch-services';
import {
  constants,
  getDataRowCellValue,
  TRenderCellDataServiceBatchRow,
  TServiceBatchRowColumnsName,
  updateRowDataByColumn,
} from '../helpers';
import { getServiceDisplayPrice } from '../../location-services/helpers';

import type { TTableRowData } from '../../../types/TTable';
import type { TGoogleMoney } from '../../../types/TGoogle';
import type { EBatchPlatformStatus } from '../../../types/TBatchGoogleModules';

export type TServiceBatchTableRowProps = {
  rowData: TTableRowData;
  batchId: string;
  dataTableOptions: MUIDataTableOptions;
};

export const ServiceBatchRow = ({
  rowData,
  batchId,
  dataTableOptions,
}: TServiceBatchTableRowProps) => {
  const { userAccessToken, userProfileName } = useAuth();
  const { enqueueSnackbar } = useSnackbar();
  const [currentRowData, setCurrentRowData] = React.useState<TTableRowData>([]);

  const [checked, setChecked] = React.useState(false);
  const [isExpandable, setIsExpandable] = React.useState(false);
  const [actionLoading, setActionLoading] = React.useState(false);
  const [fetchingStateLoading, setFetchingStateLoading] = React.useState(false);
  const [isRemoveModalOpened, setIsRemoveModalOpened] = React.useState(false);
  const [isServiceInfoOpen, setIsServiceInfoOpen] = React.useState(false);

  React.useEffect(() => {
    setCurrentRowData(rowData);
  }, [rowData]);

  const serviceBatchStatus = React.useMemo(
    () => getDataRowCellValue({
      columnRef: 'status',
      dataRowCell: currentRowData,
    }),
    [currentRowData],
  );

  const serviceData = React.useMemo(
    () => getDataRowCellValue({
      columnRef: 'service-data',
      dataRowCell: currentRowData,
    }),
    [currentRowData],
  );

  const renderCellData = React.useMemo<TRenderCellDataServiceBatchRow>(
    () => ({
      id: (value) => value,
      'service-name': (value) => value,
      'updated-at': (value) => value,
      'created-at': (value) => value,
      'service-data': (value) => value,
      'service-price': (value: TGoogleMoney) => getServiceDisplayPrice(value),
      status: (value) => (fetchingStateLoading ? (
        'Atualizando status...'
      ) : (
        <BatchStatusIcon status={value} batchType="service" />
      )),
      actions: (value) => value,
    }),
    [fetchingStateLoading],
  );

  const showCancel = React.useMemo(() => {
    const userAsAdminOrOperator = userProfileName !== 'Usuário Padrão';
    if (serviceBatchStatus === 'PENDING' && userAsAdminOrOperator) {
      return true;
    }
    return false;
  }, [serviceBatchStatus]);

  const onCancel = React.useCallback(async () => {
    const updateResponse = await updateServiceBatch({
      serviceBatchId: batchId,
      userAccessToken,
      status: 'CANCELING' as EBatchPlatformStatus.CANCELING,
      setIsLoading: setActionLoading,
    });

    if (updateResponse.status !== 'SUCCESS') {
      return enqueueSnackbar('Erro ao solicitar cancelamento de serviço em massa', { variant: 'error' });
    }

    const { data: { status } } = updateResponse || {};

    setCurrentRowData((prevState: any) => updateRowDataByColumn({
      rowData: prevState,
      payload: {
        status,
      },
    }));
  }, [batchId, setCurrentRowData]);

  const onRemove = React.useCallback(async () => {
    setIsRemoveModalOpened(false);
    const updateResponse = await updateServiceBatch({
      serviceBatchId: batchId,
      userAccessToken,
      status: 'REMOVING' as EBatchPlatformStatus.REMOVING,
      setIsLoading: setActionLoading,
    });

    if (updateResponse.status !== 'SUCCESS') {
      return enqueueSnackbar('Erro ao solicitar exclusão de serviço em massa', { variant: 'error' });
    }

    const { data: { status } } = updateResponse || {};

    setCurrentRowData((prevState: any) => updateRowDataByColumn({
      rowData: prevState,
      payload: {
        status,
      },
    }));
  }, [batchId, setCurrentRowData]);

  const getServiceBatch = React.useCallback(
    async (shouldCollapse: boolean = false) => {
      if (shouldCollapse) {
        setIsExpandable(false);
      }

      setFetchingStateLoading(true);

      const findServiceBatchResponse = await findOneServiceBatch({
        serviceBatchId: batchId,
        userAccessToken,
      });

      if (findServiceBatchResponse.status !== 'SUCCESS') return;

      const serviceBatch = findServiceBatchResponse.data;

      setCurrentRowData((prevState) => prevState.map((columnRowData) => {
        if (columnRowData?.columnRef === 'status') {
          return {
            ...columnRowData,
            value: serviceBatch.status,
          };
        }

        return columnRowData;
      }));

      setTimeout(() => setFetchingStateLoading(false), 500);
    },
    [batchId],
  );

  React.useEffect(() => {
    const getServiceBatchByInterval = setInterval(() => {
      const cellDataStatus = currentRowData.find(
        (cellData) => cellData.columnRef === 'status',
      );
      if (
        cellDataStatus?.value === 'Pendente'
        || cellDataStatus?.value === 'Processando'
        || cellDataStatus?.value === 'Cancelando'
      ) {
        getServiceBatch();
      }
    }, constants.FETCH_STATUS_SETINTERVAL);

    return () => clearInterval(getServiceBatchByInterval);
  }, [currentRowData]);

  return (
    <TableBodyRow
      key={batchId}
      dataTableOptions={dataTableOptions}
      checked={checked}
      setChecked={setChecked}
      isExpandable={isExpandable}
      setIsExpandable={setIsExpandable}
      subTable={(data: any) => (
        <ServiceBatchLocationsTable
          serviceBatchRowData={data}
          batchId={batchId}
          updateBatch={getServiceBatch}
        />
      )}
      dataRow={currentRowData}
      subTableColSpan={7}
      hiddenCheckbox
    >
      {currentRowData.map(
        (dataColumnCell: any) => dataColumnCell?.visible && (
        <TableBodyCell>
          {renderCellData[
                dataColumnCell.columnRef as TServiceBatchRowColumnsName
          ]?.(dataColumnCell.value)}
        </TableBodyCell>
        ),
      )}
      <TableBodyCell>
        <StyledCellCustomButtons>
          {actionLoading ? (
            <StyledActionLoadingWrap>
              <Loading className="action-loading" />
              Aplicando ação...
            </StyledActionLoadingWrap>
          ) : (
            <>
              <IconButton
                tooltip="Visualizar"
                icon="VisibilityOutlined"
                onClick={() => setIsServiceInfoOpen(true)}
              />

              {showCancel && (
                <IconButton
                  tooltip="Cancelar"
                  icon="Cancel"
                  onClick={onCancel}
                />
              )}
              {serviceBatchStatus === 'DONE' && (
                <IconButton
                  tooltip="Excluir serviço"
                  icon="Delete"
                  onClick={() => setIsRemoveModalOpened(true)}
                />
              )}
            </>
          )}
        </StyledCellCustomButtons>
      </TableBodyCell>
      {serviceData && (
        <ServiceBatchInfoDialog
          isOpen={isServiceInfoOpen}
          onClose={() => setIsServiceInfoOpen(false)}
          serviceItemInfo={serviceData}
        />
      )}
      <DestructiveActionDialog
        open={isRemoveModalOpened}
        onClose={() => setIsRemoveModalOpened(false)}
        onConfirm={onRemove}
        warningMessage={[
          'Você está prestes a excluir o serviço para todos os locais afetados.',
          'Deseja continuar?',
        ]}
        confirmButtonLabel="SIM, DESEJO CONTINUAR"
        cancelButtonLabel="CANCELAR"
      />
    </TableBodyRow>
  );
};
