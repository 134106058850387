import styled from 'styled-components';
import { shade, lighten } from 'polished';

export const StyledDropDownDialog = styled.div`
  height: 9.2rem;
  flex-direction: column;
  display: flex;
  position: relative;
  padding: 1.3rem 4rem;
  cursor: pointer;
  transition: 0.3s;
  border-right: 1.5px solid ${props => lighten(0.3, props.theme.colors.grey)};

  &:hover {
    background: ${props => shade(0.1, props.theme.colors.white)};
  }

  h2 {
    font-size: 1.2rem;
    font-weight: 500;
    margin-bottom: 1rem;
  }
`;

export const StyledCurrentSelectedOption = styled.div`
  display: flex;
  align-items: center;

  .drop-down-dialog-icon {
    width: 2.4rem;
    height: 2.4rem;
    fill: ${props => props.theme.colors.primary};
    margin-right: 1rem;
  }

  .drop-down-dialog-arrow {
    width: 2rem;
    height: 2rem;
  }

  .guest-company-name{
    margin-top: 0.5rem;
    font-size: 1rem;
    font-weight: 400;
    color: ${props => props.theme.colors.black};
  }

  span {
    font-weight: 700;
    font-size: 1.4rem;
    margin-right: 3rem;
    line-height: 1;
    color: ${props => props.theme.colors.primary};
  }
`;

export const StyledInfo = styled.div`
  display: flex;
  flex-direction: column;
`;
