import { isEmpty, isNil } from 'ramda';

export default function getLocationCompleteness(location) {
  let completeness = 0;
  const isMissing = [];
  const {
    name,
    address1,
    address2,
    store_code: storeCode,
    short_description: shortDescription,
    long_description: longDescription,
    zip_code: zipCode,
    neighborhood,
    city,
    state,
    country,
    phone: mainPhone,
    secondary_phone: secondaryPhone,
    email,
    latitude,
    longitude,
    website,
    facebook,
    instagram,
    twitter,
    youtube,
    foursquare,
    linkedin,
    yelp,
    working_hours: workingHours,
  } = location;

  const regexValidatePhoneField = /_/g;

  if (!isEmpty(storeCode) && !isNil(storeCode)) {
    completeness += 0;
  } else {
    isMissing.push({ name: 'Código da Loja', identifier: 'storeCode' });
  }

  if (!isEmpty(name) && !isNil(name)) {
    completeness += 3;
  } else {
    isMissing.push({ name: 'Nome do Local', identifier: 'name' });
  }

  if (!isEmpty(email) && !isNil(email)) {
    completeness += 3;
  } else {
    isMissing.push({ name: 'E-mail', identifier: 'email' });
  }

  if (!isEmpty(mainPhone) && !isNil(mainPhone) && !regexValidatePhoneField.test(mainPhone)) {
    completeness += 3;
  } else {
    isMissing.push({ name: 'Telefone Principal', identifier: 'mainPhone' });
  }

  if (!isNil(secondaryPhone) && !isEmpty(secondaryPhone) && !regexValidatePhoneField.test(secondaryPhone)) {
    completeness += 3;
  } else {
    isMissing.push({ name: 'Telefone Secundário', identifier: 'secondaryPhone' });
  }

  if (!isEmpty(zipCode) && !isNil(zipCode)) {
    completeness += 4;
  } else {
    isMissing.push({ name: 'CEP', identifier: 'zipCode' });
  }

  if (!isEmpty(country) && !isNil(country)) {
    completeness += 0;
  } else {
    isMissing.push({ name: 'País', identifier: 'country' });
  }

  if (!isEmpty(address1) && !isNil(address1)) {
    completeness += 3;
  } else {
    isMissing.push({ name: 'Endereço', identifier: 'address1' });
  }

  if (!isEmpty('addressNumber') && !isNil('addressNumber')) {
    completeness += 3;
  } else {
    isMissing.push({ name: 'Número do Logradouro', identifier: 'adressNumber' });
  }

  if (!isEmpty(address2) && !isNil(address2)) {
    completeness += 3;
  } else {
    isMissing.push({ name: 'Complemento', identifier: 'address2' });
  }

  if (!isEmpty(neighborhood) && !isNil(neighborhood)) {
    completeness += 3;
  } else {
    isMissing.push({ name: 'Bairro', identifier: 'neighborhood' });
  }

  if (!isEmpty(city) && !isNil(city)) {
    completeness += 2;
  } else {
    isMissing.push({ name: 'Cidade', identifier: 'city' });
  }

  if (!isEmpty(state) && !isNil(state)) {
    completeness += 1;
  } else {
    isMissing.push({ name: 'Estado', identifier: 'state' });
  }

  if (!isEmpty(longDescription) && !isNil(longDescription)) {
    completeness += 10;
  } else {
    isMissing.push({ name: 'Descrição', identifier: 'longDescription' });
  }

  if (!isEmpty(latitude) && !isNil(latitude)) {
    completeness += 0;
  } else {
    isMissing.push({ name: 'Coordenadas', identifier: 'latitude' });
  }

  if (!isEmpty(longitude) && !isNil(longitude)) {
    completeness += 0;
  } else {
    isMissing.push({ name: 'Coordenadas', identifier: 'longitude' });
  }

  if (!isEmpty(website) && !isNil(website)) {
    completeness += 10;
  } else {
    isMissing.push({ name: 'Link do Website', identifier: 'website' });
  }

  if (!isEmpty(instagram) && !isNil(instagram)) {
    completeness += 7;
  } else {
    isMissing.push({ name: 'Link do Instagram', identifier: 'instagram' });
  }

  if (!isEmpty(youtube) && !isNil(youtube)) {
    completeness += 5;
  } else {
    isMissing.push({ name: 'Link do Youtube', identifier: 'youtube' });
  }

  if (!isEmpty(linkedin) && !isNil(linkedin)) {
    completeness += 5;
  } else {
    isMissing.push({ name: 'Link do Linkedin', identifier: 'linkedin' });
  }

  if (!isEmpty(facebook) && !isNil(facebook)) {
    completeness += 7;
  } else {
    isMissing.push({ name: 'Link do Facebook', identifier: 'facebook' });
  }

  if (!isEmpty(twitter) && !isNil(twitter)) {
    completeness += 5;
  } else {
    isMissing.push({ name: 'Link do Twitter', identifier: 'twitter' });
  }

  if (!isEmpty(foursquare) && !isNil(foursquare)) {
    completeness += 5;
  } else {
    isMissing.push({ name: 'Link do Foursquare', identifier: 'foursquare' });
  }

  if (!isEmpty(yelp) && !isNil(yelp)) {
    completeness += 5;
  } else {
    isMissing.push({ name: 'Link do Yelp', identifier: 'yelp' });
  }

  const open = workingHours.find(element => element.is_open === true);
  if (open) {
    completeness += 10;
  } else {
    isMissing.push({ name: 'Horários de Funcionamento', identifier: 'workingHours' });
  }

  return [completeness, isMissing];
}
