import styled from 'styled-components';
import { Paper } from '@material-ui/core';
import { devices } from '../../assets/styles/devices';

export const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;

  overflow-y: hidden;
  height: 100vh;
`;

export const StyledLoadingContainer = styled(Paper)`
  width: 100%;
  height: 100%;
  background: ${(props) => props.theme.page.body};
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;

  .MuiCircularProgress-colorPrimary {
    color: ${(props) => props.theme.colors.primary} !important;
  }
`;

export const StyledEmbedContainer = styled(Paper)`
  width: 100%;
  padding: 2rem;
  height: 100%;
  background: ${(props) => props.theme.page.body};

  .iframe {
    display: flex;
    width: 100%;
    height: 100%;
  }
`;

export const StyledEmptyEmbed = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 2rem;
  padding: 2rem;
  color: ${(props) => props.theme.colors.white};
  background-color: ${(props) => props.theme.colors.primary};
  border-radius: 5rem;
`;

export const StyledNoAcess = styled.div`
  height: 100%;
  width: 100%;
  padding: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  .no-acess-illustration {
    margin-bottom: 3rem;
    max-width: 40rem;

    @media ${devices.widescreen} {
      max-width: 60rem;
    }
  }

  h2 {
    font-weight: bold;
    margin-bottom: 3rem;
    color: ${(props) => props.theme.colors.black};
    max-width: 70rem;
    text-align: center;

    @media ${devices.tablet} {
      font-size: 3rem;
    }
  }

  p {
    max-width: 90rem;
    width: 100%;
    text-align: center;
    font-size: 2rem;
    line-height: 2.5rem;
    margin-bottom: 2rem;
  }
`;
