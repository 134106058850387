import styled from 'styled-components';
import {
  CalendarToday as CalendarTodayIcon,
  Visibility as VisibilityIcon,
} from '@material-ui/icons';

import { devices } from '../../../assets/styles/devices';

export const StyledLocationGalleryMediaDialog = styled.div`
  padding: 0rem 1rem 2rem;
  
  .BrainhubCarousel__arrow--disable > div:first-child {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
`;

export const StyledMediaDialogContent = styled.div`
  display: flex;
  flex-direction: column;
  padding: 1rem;
`;

export const StyledMediaView = styled.div`
  display: flex;
  border-radius: 0.5rem;
  overflow: hidden;
  margin-bottom: 1rem;
  max-height: 35rem;

  img {
    width: 100%;
    object-fit: cover;
  }
`;

export const StyledMediaViewInfos = styled.div`
  display: flex;
  flex-direction: column;

  @media ${devices.tablet} {
    flex-direction: row;
    align-items: center;
  }

  .media-view-info {
    font-size: 1.4rem;
    display: flex;
    align-items: center;
  }

  .icon-button-remove-media {
    margin-left: 0.5rem;
  }

  .media-view-info--views {
    @media ${devices.tablet} {
      margin-left: 1rem;
      margin-right: auto;
    }
  }
`;

export const StyledCalendarTodayIcon = styled(CalendarTodayIcon)`
  margin-right: 0.5rem;
`;

export const StyledVisibilityIcon = styled(VisibilityIcon)`
  margin-right: 0.5rem;
`;
