import React, { useCallback } from 'react';
import { StyledListItem, StyledListItemWrapper } from './requests-list-popover-styles';
import { fieldsName } from '../helpers';
import { TRequestsListPopoverProps } from './types';
import InputSwitchWithNeutral from '../../../../../../components/input-switch-with-neutral';
import SyncStatusIcon from '../../../../../../components/sync-status-icon';

const RequestsListPopover = ({
  data,
  switches,
  setSwitches,
  hasStatusAlready = false,
}: TRequestsListPopoverProps) => {
  const handleSwitchChange = useCallback((key: string) => {
    const newSwitches = { ...switches };
    newSwitches[key] = !newSwitches[key];
    setSwitches(newSwitches);
  }, [switches]);

  const getFieldTitle = useCallback((key: string) => {
    const { addressInfo, infoBasic, categories } = fieldsName;
    if (addressInfo[key]) return addressInfo[key].name;
    if (infoBasic[key]) return infoBasic[key].name;
    return categories[key].name;
  }, [fieldsName]);

  return (
    <>
      {
      data.sort(
        (firstArg, secondArg) => firstArg.order - secondArg.order,
      ).map(item => (
        (
          <StyledListItemWrapper>
            <StyledListItem>
              <p className="title">{getFieldTitle(item.label.toLowerCase())}</p>
              <p>
                {item.new_data.value}
              </p>
            </StyledListItem>
            {
              !hasStatusAlready
                ? (
                  <InputSwitchWithNeutral
                    checked={switches[item.label.toLowerCase()]}
                    changeChecked={() => handleSwitchChange(item.label.toLowerCase())}
                  />
                )
                : (
                  <SyncStatusIcon
                    status={item.status}
                    syncType="requests"
                  />
                )
            }
          </StyledListItemWrapper>
        )
      ))
    }
    </>
  );
};

export default RequestsListPopover;
