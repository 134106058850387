import * as R from 'ramda';

import { TFilterLocationsOwnerOrGuestLocationGroup } from '../types/TUtils';

import APIGetAllGroupLocations from '../services/locations/getAllGroupLocation';

const filterLocationsOwnerOrGuestLocationGroup = ({
  currentLocationGroup = null,
  userId,
  userProfileName,
  userAccessToken,
  userSetTokenLikeExpired,
  setLocationGroupLocationsIsLoading = null,
  isDataTable = false,
  mountDataTable = null,
}: TFilterLocationsOwnerOrGuestLocationGroup) => async () => {
  if (R.isNil(currentLocationGroup)) return null;

  const locationGroupLocationsResponse = await APIGetAllGroupLocations({
    accessToken: userAccessToken,
    locationGroupId: currentLocationGroup.id,
    setTokenLikeExpired: userSetTokenLikeExpired,
    setIfFetching: setLocationGroupLocationsIsLoading,
  });

  if (R.isNil(locationGroupLocationsResponse)) return null;

  const [locationDataList, locationDataListCount] = locationGroupLocationsResponse;

  if (userProfileName !== 'Usuário Padrão') {
    if (R.isNil(locationGroupLocationsResponse)) {
      return {
        locationDataList: null,
        locationCount: 0,
      };
    }

    return {
      locationDataList,
      locationCount: locationDataListCount,
    };
  }

  if (
    !R.isNil(currentLocationGroup.owner_user)
    && currentLocationGroup.owner_user.id === userId
  ) {
    return {
      locationDataList: isDataTable ? mountDataTable({
        locationDataList,
        permissionStatus: 'owner',
        userProfileName,
      }) : locationDataList,
      locationCount: locationDataList.locations?.length,
    };
  }

  const isCompanyGuest = currentLocationGroup.guests?.find((guest: any) => guest.user.id === userId);

  if (!R.isNil(isCompanyGuest)) {
    return {
      locationDataList: isDataTable ? mountDataTable({
        locationDataList,
        permissionStatus: 'guest',
        userProfileName,
      }) : locationDataList,
      locationCount: locationDataList.locations?.length,
    };
  }

  const filteredCompanyLocationsList = locationDataList.filter((location: any) => {
    const locationsWhereUserIsGuest = location.guests.find((locationGuest: any) => locationGuest.user.id === userId);
    return locationsWhereUserIsGuest;
  });

  return {
    locationDataList: isDataTable ? mountDataTable({
      locationDataList: filteredCompanyLocationsList,
      permissionStatus: 'locationGuest',
      userProfileName,
    }) : filteredCompanyLocationsList,
    locationsLenght: filteredCompanyLocationsList.length,
    locationCount: locationDataListCount,
  };
};

export default filterLocationsOwnerOrGuestLocationGroup;
