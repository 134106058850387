import React, { useCallback } from 'react';
import ReactFadeIn from 'react-fade-in';
import { useSnackbar } from 'notistack';
import * as R from 'ramda';

import {
  Input,
  InputSwitch,
  Button,
  IconButton,
} from '../../../../../components';

import type { TTimeData } from '../../../../../types/TTime';
import type { TAddSpecialDate } from '../../../../../types/TWorkingHours';

import WorkingHoursConfig from '../working-hours-config';
import Is24Hours from '../../is-24-hours-button';

import {
  mockedTimes,
  setFieldsToIsValid,
} from './helpers';

import {
  StyledAddSpecialDate,
  StyledHeader,
  StyledFieldsWrapper,
  StyledHeaderTitle,
  StyledEditActions,
  StyledSpecialDateIs24Hours,
} from './add-special-date-styles';
import { parse } from 'date-fns';
import { ptBR } from 'date-fns/locale';

const AddSpecialDate = ({
  hasSpeciaDates,
  activeLocation,
  currentSpecialDates = [],
  specialDateToEdit,
  setSpecialDateToEdit,
  userId,
  setSpecialDates,
}: TAddSpecialDate) => {
  const { enqueueSnackbar } = useSnackbar();
  const [newSpecialDateIsOpen, setNewSpecialDateIsOpen] = React.useState(false);
  const [newSpecialDateTitle, setNewSpecialDateTitle] = React.useState('');
  const [newSpecialDateDate, setNewSpecialDateDate] = React.useState('');
  const [newDateIs24Hours, setNewDateIs24Hours] = React.useState(false);
  const [newDateTimes, setNewDateTimes] = React.useState<TTimeData[]>(mockedTimes);

  const formatDate = useCallback((data: string) => {
    return parse(data, 'dd/MM/yyyy', new Date(), { locale: ptBR }).toISOString().split('T')[0];
  }, []);

  React.useEffect(() => {
    if (R.isNil(specialDateToEdit)) return;

    const {
      is_open: isOpen,
      name,
      date,
      is_24_hours: is24Hours,
      times,
    } = specialDateToEdit;

    setNewSpecialDateTitle(name);
    setNewSpecialDateDate(formatDate(date));
    setNewSpecialDateIsOpen(isOpen);
    setNewDateIs24Hours(is24Hours);

    setNewDateTimes(R.isEmpty(times) || R.isNil(times) ? mockedTimes : times);
  }, [specialDateToEdit]);

  const formatedNewSpecialDate = useCallback((specialDate: string) => {
    const date = new Date(specialDate);

    if (Number.isNaN(date.getDate())) return '';
    date.setDate(date.getDate() + 1); // Add a day
    return date.toLocaleDateString('pt-BR');
  }, []);

  const fieldsIsValid = setFieldsToIsValid({
    title: newSpecialDateTitle,
    date: formatedNewSpecialDate(newSpecialDateDate),
    feedbackMessage: enqueueSnackbar,
    currentSpecialDates,
  });

  const resetFields = () => {
    setSpecialDateToEdit(null);
    setNewSpecialDateIsOpen(false);
    setNewSpecialDateTitle('');
    setNewSpecialDateDate('');
    setNewDateTimes(mockedTimes);
    setNewDateIs24Hours(false);
  };

  const handleSaveEdit = React.useCallback(async () => {
    if (!fieldsIsValid({ isUpdateMode: true })) return;
    if (R.isNil(specialDateToEdit)) return;

    const newTimesRangeFiltered = newDateTimes.filter(({
      open,
      close,
      is_mocked: isMocked,
      is_deleted: isDeleted,
    }) => {
      const isValidaTime = open !== '00:00' || close !== '00:00';

      return isValidaTime && isMocked && !isDeleted;
    });

    const newTimesToUpdated = newDateTimes.filter(({
      is_deleted: isDeleted,
      is_mocked: isMocked,
    }) => !isDeleted && !isMocked);

    const newTimes = R.isEmpty(newTimesRangeFiltered) ? [] : newTimesRangeFiltered;

    const updatedSpecialDate = {
      ...specialDateToEdit,
      name: newSpecialDateTitle,
      date: formatedNewSpecialDate(newSpecialDateDate),
      is_open: newSpecialDateIsOpen,
      is_24_hours: newDateIs24Hours,
      times: [
        ...newTimesToUpdated,
        ...newTimes,
      ],
    };
    if (setSpecialDates) {
      setSpecialDates(prev => [...prev.filter((specialDate) => specialDate.id !== specialDateToEdit.id), updatedSpecialDate]);
    }

    resetFields();
  }, [
    newSpecialDateTitle,
    newSpecialDateDate,
    specialDateToEdit,
    newSpecialDateIsOpen,
    newDateIs24Hours,
    activeLocation,
    newDateTimes,
  ]);

  const handleAddSpecialDate = React.useCallback(async () => {
    if (!fieldsIsValid({ isUpdateMode: false })) return;

    const newTimesRangeFiltered = newDateTimes.filter(({ open, close }) => !(open === '00:00' && close === '00:00'));

    const newTimes = R.isEmpty(newTimesRangeFiltered) ? [] : newTimesRangeFiltered;

    const newSpecialDate = {
      date: formatedNewSpecialDate(newSpecialDateDate),
      name: newSpecialDateTitle,
      times: newTimes,
      created_by: userId,
      is_open: newSpecialDateIsOpen,
      is_24_hours: newDateIs24Hours,
    };

    if (setSpecialDates) {
      setSpecialDates(prev => ([...prev, newSpecialDate]));
    }

    resetFields();
  }, [
    newSpecialDateTitle,
    newSpecialDateDate,
    newSpecialDateIsOpen,
    newDateIs24Hours,
    activeLocation,
    newDateTimes,
  ]);

  const handleIs24Hours = () => {
    setNewDateIs24Hours(!newDateIs24Hours);
    setNewSpecialDateIsOpen(true);
  };

  const handleIsOpen = (isOpenState: boolean) => {
    setNewSpecialDateIsOpen(isOpenState);

    if (!isOpenState) setNewDateIs24Hours(false);
  };

  return (
    <StyledAddSpecialDate hasSpeciaDates={hasSpeciaDates}>
      <StyledHeader
        newSpecialDateIsOpen={newSpecialDateIsOpen}
      >
        <StyledHeaderTitle>
          Adicionar uma nova data
        </StyledHeaderTitle>
        <StyledFieldsWrapper>
          <Input
            className="add-special-date-input"
            label="Título"
            value={newSpecialDateTitle}
            onChange={e => setNewSpecialDateTitle(e.target.value)}
            inputOptions={{
              type: 'text',
              name: 'specia-date-title',
            }}
            border
          />

          <Input
            className="add-special-date-input"
            label="Data"
            value={newSpecialDateDate}
            onChange={e => setNewSpecialDateDate(e.target.value)}
            inputOptions={{
              type: 'date',
              name: 'specia-date-title',
            }}
            border
          />

          <InputSwitch
            label="Aberto?"
            checked={newSpecialDateIsOpen}
            changeChecked={() => handleIsOpen(!newSpecialDateIsOpen)}
            alignRow
            className="add-special-date-switch"
          />

          <Is24Hours
            isActive={newDateIs24Hours}
            className="is-24-hours-button"
            onClick={handleIs24Hours}
          />

          {R.isNil(specialDateToEdit) ? (
            <Button
              className="add-special-date-button"
              onClick={handleAddSpecialDate}
            >
              Adicionar
            </Button>
          ) : (
            <StyledEditActions>
              <Button
                className="edit-special-date-button"
                onClick={handleSaveEdit}
                buttonType="secondary"
              >
                Salvar
              </Button>
              <IconButton
                icon="Close"
                onClick={() => resetFields()}
                tooltip="Cancelar edição"
              />
            </StyledEditActions>
          )}
        </StyledFieldsWrapper>
      </StyledHeader>

      {(newSpecialDateIsOpen && newDateIs24Hours) && (
        <StyledSpecialDateIs24Hours>
          Não há faixas de horários para datas fixadas como aberta 24 horas
        </StyledSpecialDateIs24Hours>
      )}

      {(newSpecialDateIsOpen && !newDateIs24Hours) && (
        <ReactFadeIn>
          <WorkingHoursConfig
            times={newDateTimes}
            setTimes={(newTimes) => setNewDateTimes(newTimes)}
          />
        </ReactFadeIn>
      )}
    </StyledAddSpecialDate>
  );
};

export default AddSpecialDate;
