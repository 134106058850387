import styled, { css } from 'styled-components';
import InputMask from 'react-input-mask';
import { lighten } from 'polished';

import { TTimeBaseProps } from '../../types/TWorkingHours';

export const StyledTime = styled.div<Pick<TTimeBaseProps, 'columnAlign'>>`
    display: flex;
    align-items: center;
    position: relative;

    ${(props) => props.columnAlign && css`
      flex-direction: column;
      align-items: flex-start;
    `};
`;

export const StyledLabel = styled.span<Pick<TTimeBaseProps, 'columnAlign'>>`
    display: flex;
    margin-right: 1rem;
    font-size: 1.5rem;

    ${(props) => props.columnAlign && css`
      margin-right: 0;
      margin-bottom: 0.5rem;
    `};
`;

export const StyledTimeInput = styled(InputMask)`
    min-height: 4.1rem;
    max-width: 7rem;
    padding: 0.5rem 1rem;
    border: 2px solid ${(props) => lighten(0.4, props.theme.colors.black)};
`;
