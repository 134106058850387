import axios from 'axios';

export default async function getAllCompaniesWhereUserIsGuest({
  accessToken,
  userId,
  page = 0,
  pageSize = 1000,
  query = '',
  setTokenLikeExpired,
  isLoading,
}) {
  const requestOptions = {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  };

  try {
    if (isLoading) isLoading(true);

    const { data } = await axios.get(
      `${process.env.REACT_APP_GUESTS_API_URL}/user-company/${userId}?page=${page}&pageSize=${pageSize}&query=${query}`,
      requestOptions,
    );

    return data;
  } catch (err) {
    if (err.response?.status === 401 && setTokenLikeExpired) setTokenLikeExpired();

    console.log(err.response);

    return null;
  } finally {
    if (isLoading) isLoading(false);
  }
}
