import axios from 'axios';

export default async function searchGoogleLocations(keyword, address, city, state, key) {
  try {
    const { data } = await axios.get(
      `${process.env.REACT_APP_GOOGLE_API_URL}/search/all/locations?keyword=${keyword}&address=${address}&city=${city}&state=${state}&key=${key}`,
    );

    return data;
  } catch (err) {
    throw err.response;
  }
}
