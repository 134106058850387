import styled, { css } from 'styled-components';

export const StyledBurguer = styled.div`
  height: 2.2rem;
  width: 3rem;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  ${(props) => props.open && css`
    .center {
      opacity: 0;
    }

    .start {
      transform: rotate(45deg);
      top: 1rem !important;
    }

    .end {
      transform: rotate(-45deg);
      bottom: 0.9rem !important;
    }
  `};
`;

export const StyledBurguerLine = styled.span`
  width: 100%;
  height: 0.3rem;
  background: ${(props) => props.theme.colors.white};
  position: absolute;
  transition: 0.3s;
  opacity: 1;

  &.start {
    top: 0;
  }

  &.end {
    bottom: 0;
  }
`;
