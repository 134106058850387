import React from 'react';
import { useHistory } from 'react-router-dom';
import { AppBar, Toolbar, Button } from '@material-ui/core';

const CompanyInfoNavBar = ({ activeButton = -1 }) => {
  const history = useHistory();

  return (
    <div>
      <AppBar
        position="static"
        elevation={0}
        style={{ backgroundColor: '#F2F2F2', color: 'black' }}
      >
        <Toolbar style={{ display: 'flex', justifyContent: 'space-around' }}>
          <Button
            onClick={() => {
              history.push('/company/info-models');
            }}
          >
            <text
              style={activeButton === 0 ? { color: '#4080EB' } : {}}
              className="font-poppins"
            >
              Informações
            </text>
          </Button>
          <Button
            onClick={() => {
              history.push('/company/link-models');
            }}
          >
            <text
              style={activeButton === 1 ? { color: '#4080EB' } : {}}
              className="font-poppins"
            >
              Links
            </text>
          </Button>
          <Button
            onClick={() => {
              history.push('/company/working-hours-models');
            }}
          >
            <text
              style={activeButton === 2 ? { color: '#4080EB' } : {}}
              className="font-poppins"
            >
              Funcionamento
            </text>
          </Button>
        </Toolbar>
      </AppBar>
    </div>
  );
};

export default CompanyInfoNavBar;
