import axios from 'axios';

import type { TUpdateLocalPostsLocations } from '../../types/TBatchGoogleModules';

export const updateLocalPostsLocations = async ({
  isUpdating,
  userAccessToken,
  localPostsLocationId,
  localPostsLocationStatus,
  action,
  user_id,
  user_name,
  user_email,
}: TUpdateLocalPostsLocations) => {
  try {
    if (isUpdating) isUpdating(true);

    const apiPath = `
      ${process.env.REACT_APP_SAAS_BATCH_GOOGLE_MODULES}/local-posts-locations/${localPostsLocationId}
    `;

    const requestOptions = {
      headers: {
        Authorization: `Bearer ${userAccessToken}`,
      },
    };

    const { data } = await axios.put(
      apiPath,
      {
        status: localPostsLocationStatus,
        action,
        user_id,
        user_name,
        user_email,
      },
      requestOptions,
    );

    return data;
  } catch (err) {
    return null;
  } finally {
    if (isUpdating) isUpdating(false);
  }
};
