import axios from 'axios';

export default async function createSpecialDate({
  accessToken,
  name,
  date,
  is_open,
  times,
  is_24_hours,
  created_by,
  location,
  setTokenLikeExpired,
  isLoading,
}) {
  try {
    if (isLoading) isLoading(true);

    const { data } = await axios.post(
      `${process.env.REACT_APP_SPECIAL_DATE_API_URL}`,
      {
        name,
        date,
        is_open,
        created_by,
        times,
        is_24_hours,
        location,
      },
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      },
    );

    return data;
  } catch (err) {
    if (err?.response?.status === 401 && setTokenLikeExpired) return setTokenLikeExpired();

    return null;
  } finally {
    if (isLoading) isLoading(false);
  }
}
