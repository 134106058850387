import styled from 'styled-components';

export const StyledDialog = styled.div`
`;

export const StyledLoadingWrapper = styled.div`
  display:flex;
  justify-content:center;
  padding: 2rem 0;
`;

export const StyledErrorWrapper = styled.div`
  display: flex;
  margin: 2rem;

  code {
    white-space: pre-wrap;
    word-break: break-word;
    color: ${props => props.theme.colors.text};
  }
`;
