import {
  ESyncMonitoringPlatformStatus,
  TIconByStatusPropsValue,
  TSyncType,
} from './types';

export const syncEntityNames: Record<TSyncType, string> = {
  monitor: 'monitoramento',
  requests: 'solicitações',
};

export const iconByStatus: Record<ESyncMonitoringPlatformStatus, TIconByStatusPropsValue> = {
  PENDING: {
    tooltip: (syncEntityName) => `Atualização de ${syncEntityName} aguardando processamento.`,
    label: 'Pendente',
    icon: 'History',
  },
  DONE: {
    tooltip: (syncEntityName) => `Atualização de ${syncEntityName} finalizada.`,
    label: 'Finalizado',
    icon: 'CheckCircle',
    isSuccess: true,
    tooltipVariant: 'green',
  },
  APPROVED: {
    tooltip: (syncEntityName) => `Atualização de ${syncEntityName} aprovada.`,
    label: 'Aprovado',
    icon: 'CheckCircle',
    isSuccess: true,
    tooltipVariant: 'green',
  },
  ANALYSIS: {
    tooltip: (syncEntityName) => `Atualização de ${syncEntityName} em análise.`,
    label: 'Em análise',
    icon: 'History',
    tooltipVariant: 'primary',
  },
  PROCESSING: {
    tooltip: (syncEntityName) => `Atualização de ${syncEntityName} em processamento.`,
    label: 'Processando',
    icon: 'History',
    tooltipVariant: 'primary',
  },
  PROGRESS: {
    tooltip: (syncEntityName) => `Atualização de ${syncEntityName} em progresso.`,
    label: 'Em progresso',
    icon: 'History',
    isWarning: true,
    tooltipVariant: 'yellow',
  },
  REJECTED: {
    tooltip: (syncEntityName) => `Atualização de ${syncEntityName} foi rejeitada pela plataforma.`,
    label: 'Rejeitado',
    icon: 'Error',
    isError: true,
    tooltipVariant: 'red',
  },
  ERROR: {
    tooltip: (syncEntityName) => `Ocorreu um erro na atualização de ${syncEntityName}.`,
    label: 'Erro',
    icon: 'Error',
    isWarning: true,
    tooltipVariant: 'yellow',
  },
  LOCATION_DELETED: {
    tooltip: (syncEntityName) => `Local relacionado à ${syncEntityName} foi excluído`,
    label: 'Local Excluído',
    icon: 'Error',
    isError: true,
    tooltipVariant: 'red',
  },
  _: {
    label: '',
    tooltip: (syncEntityName) => '',
  },
};
