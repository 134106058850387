import styled from 'styled-components';
import { lighten } from 'polished';

export const StyledProfileHeader = styled.div`
  display: flex;
  width: 100%;
  height: 9.2rem;
  background: ${props => props.theme.colors.white};
`;

export const StyledUserAvatarLetter = styled.div`
  height: 5.2rem;
  width: 5.2rem;
  border-radius: 0.5rem;
  background: ${props => props.theme.colors.primary};
  color: ${props => props.theme.colors.white};
  font-weight: 700;
  font-size: 3.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 2px solid transparent;
  transition: 0.3s;
`;

export const StyledProfileHeaderContent = styled.div`
  display: flex;
  flex-direction: row;
  margin: 4rem;
  align-items: center;
`;

export const StyledUserProfileTextWrapper = styled.div`
  display:flex;
  flex-direction: column;
  margin-left: 2rem;
`;

export const StyledUserName = styled.span`
  color: ${props => props.theme.colors.black};
  font-weight: 700;
  font-size: 1.5rem;
`;

export const StyledUserRole = styled.span`
  color: ${props => lighten(0.1, props.theme.colors.grey)};
  font-weight: 400;
  font-size: 1.5rem;
`;
