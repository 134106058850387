import React from 'react';

import {
  Dialog,
  ButtonV2,
} from '../../../components';
import ReplyBox from './reply-box';

import APIGetAllRepliesFromCompany from '../../../services/auto-responses/getAllFromCompany';

import {
  StyledContent,
  StyledActions,
  StyledButtonGroup,
  StyledRepliesList,
} from './select-reply-dialog-styles';

import { testArray } from './helpers';

type TSelectReplyDialog = {
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const SelectReplyDialog = ({
  isOpen,
  setIsOpen,
}: TSelectReplyDialog) => {
  const [selectedReply, setSelectedReply] = React.useState<number | null>(null);
  const handleChange = () => {
    console.log(1);
  };

  return (
    <Dialog
      open={isOpen}
      onClose={() => setIsOpen(false)}
      title="Responder Selecionadas"
      wideDialog
      isHeaderBlue
    >
      <StyledContent>
        <p>Selecione uma de suas respostas automáticas para utilizar</p>

        <StyledRepliesList>
          {testArray.map(reply => (
            <ReplyBox
              title={reply.title}
              text={reply.text}
              onClick={() => setSelectedReply(reply.id)}
              isSelected={selectedReply === reply.id}
            />
          ))}
        </StyledRepliesList>

        <StyledActions>
          <ButtonV2
            typeButton="INVERTED_PRIMARY"
          >
            <b>Criar Resposta</b>
          </ButtonV2>

          <StyledButtonGroup>
            <ButtonV2
              typeButton="GREY"
              onClick={() => setIsOpen(false)}
            >
              Cancelar
            </ButtonV2>

            <ButtonV2
              typeButton="PRIMARY"
            >
              Responder
            </ButtonV2>
          </StyledButtonGroup>
        </StyledActions>
      </StyledContent>
    </Dialog>
  );
};

export default SelectReplyDialog;
