import styled from 'styled-components';
import { devices } from '../../../assets/styles/devices';

export const StyledPostListDiv = styled.div`
  padding: 2rem;
  }

  .MuiGrid-container {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    
    @media ${devices.tablet} {
      grid-template-columns: repeat(2, 1fr);
    };

    @media ${devices.tabletLandscape} {
      grid-template-columns: repeat(3, 1fr);
    };
  }
`;

export const StyledPostList = styled.ul`
  margin-top:2rem
`;
