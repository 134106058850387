import axios, { AxiosRequestConfig } from 'axios';
import { TRunMonitoring } from './types';

export const runMonitoring = async ({
  userAccessToken,
  isFetching,
  page = 0,
}: TRunMonitoring) => {
  try {
    if (isFetching) isFetching(true);

    const apiPath = `${process.env.REACT_APP_SYNC_PLATFORM_MONITORING}/run/${page}`;

    const requestOptions: AxiosRequestConfig = {
      headers: {
        Authorization: `Bearer ${userAccessToken}`,
      },
    };

    const response = await axios.post(apiPath, {}, requestOptions);

    return response;
  } catch (err) {
    return null;
  } finally {
    if (isFetching) isFetching(false);
  }
};
