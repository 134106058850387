import React from 'react';
import Tooltip from '../tooltip';
import {
  StyledContainer,
  StyledLabel,
  StyledSelect,
  StyledWrapper,
} from './country-code-select-styles';
import { countriesOptions } from './helpers';

const CountryCodeSelect = ({ countryCode, setCountryCode, hasLabel = true }) => {
  return hasLabel ? (
    <StyledContainer>
      <StyledLabel htmlFor="country-code">
        <Tooltip
          title="Identificação do país da localidade"
          arrow
          placement="right"
        >
          <span>
            País*
          </span>
        </Tooltip>
      </StyledLabel>
      <StyledWrapper hasBorder>
        <StyledSelect id="country-code" value={countryCode} onChange={e => setCountryCode(e.target.value)}>
          {countriesOptions.map(country => (<option value={country}>{country}</option>))}
        </StyledSelect>
      </StyledWrapper>
    </StyledContainer>
  ) : (
    <StyledWrapper>
      <StyledSelect
        id="country-code"
        value={countryCode}
        onChange={e => setCountryCode(e.target.value)}
      >
        {countriesOptions.map(country => (<option value={country}>{country}</option>))}
      </StyledSelect>
    </StyledWrapper>

  );
};

export default CountryCodeSelect;
