import React from 'react';
import * as R from 'ramda';
import getEstablishment from '../../services/getEstablishment';

import AutocompleteInput from '../autocomplete-input';

const LocationNameByGoogle = ({}, ref) => {
  const [locationName, setLocationName] = React.useState([]);
  const [locationNameSelected, setLocationNameSelected] = React.useState([]);

  const currentInputValueByRef = React.useRef({ current: null });

  const getEstablishments = React.useCallback(async (establishmentQuery) => {
    const establishmentsResponse = await getEstablishment({ query: establishmentQuery });

    if (R.isNil(establishmentsResponse)) return;

    setLocationName(establishmentsResponse);
  }, []);

  React.useImperativeHandle(ref, () => locationNameSelected[0] || currentInputValueByRef.current);

  return (
    <AutocompleteInput
      label="Nome do Local*"
      options={locationName}
      saveSelectedOpionsIn={setLocationNameSelected}
      initialValue={locationNameSelected}
      filterSearchKey="predictionName"
      categoryId="predictionId"
      disclaimer="Nenhum local encontrado"
      apiSearchMode={getEstablishments}
      singleMode
      currentInputValueByRef={currentInputValueByRef}
    />
  );
};

export default React.forwardRef(LocationNameByGoogle);
