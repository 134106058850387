import axios from 'axios';

export default async function signInUser(email, password) {
  try {
    const { data } = await axios.post(
      `${process.env.REACT_APP_AUTH_API_URL}/signin`,
      {
        email,
        password,
      },
    );

    return data;
  } catch (err) {
    return null;
  }
}
