import styled, { css } from 'styled-components';
import { Paper } from '@material-ui/core';

import { shade } from 'polished';

export const StyledReplyBox = styled(Paper)<{ isSelected: boolean }>`
  display: flex;
  flex-direction: column;
  padding: 2rem;
  background-color: ${props => shade(0.05, props.theme.colors.white)};
  justify-content: center;
  transition: 0.2s;

  ${(props) => props.isSelected && css`
    background-color: ${props.theme.colors.primary};
  `};
  `;

export const StyledTitle = styled.b<{ isSelected: boolean }>`
  margin-bottom: 0;
  
  ${(props) => props.isSelected && css`
    color: ${props.theme.colors.white};
  `};
  `;

export const StyledText = styled.p<{ isSelected: boolean }>`
  margin-bottom: 0;

  ${(props) => props.isSelected && css`
    color: ${props.theme.colors.white};
  `};
`;
