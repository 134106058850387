import React from 'react';

import { ReactComponent as AppleMaps } from './apple-maps.svg';
import { ReactComponent as Booking } from './booking.svg';
import { ReactComponent as Facebook } from './facebook.svg';
import { ReactComponent as Foursquare } from './foursquare.svg';
import { ReactComponent as GoogleArcore } from './google-arcore.svg';
import { ReactComponent as GoogleMyBusiness } from './google-my-business.svg';
import { ReactComponent as GoogleStreetView } from './google-street-view.svg';
import { ReactComponent as Google } from './google.svg';
import { ReactComponent as GoogleEarth } from './googleearth.svg';
import { ReactComponent as History } from './history.svg';
import { ReactComponent as Instagram } from './instagram.svg';
import { ReactComponent as Linkedin } from './linkedin.svg';
import { ReactComponent as Tripadvisor } from './tripadvisor.svg';
import { ReactComponent as Uber } from './uber.svg';
import { ReactComponent as UberEats } from './ubereats.svg';
import { ReactComponent as Waze } from './waze.svg';
import { ReactComponent as Whatsapp } from './whatsapp.svg';
import { ReactComponent as Yelp } from './yelp.svg';

export const PlatformsLogosSVG: Record<
  string,
  React.FunctionComponent<React.SVGProps<SVGSVGElement> & { title?: string }>
> = {
  Google,
  AppleMaps,
  Booking,
  Facebook,
  Foursquare,
  GoogleArcore,
  GoogleMyBusiness,
  GoogleStreetView,
  GoogleEarth,
  History,
  Instagram,
  Whatsapp,
  Waze,
  UberEats,
  Yelp,
  Tripadvisor,
  Uber,
  Linkedin,
};

type Test = {
  platform: string;
  className?: string;
};

export const getPlatformLogo = ({ platform, className = '' }: Test) => {
  const platformName = platform.charAt(0).toUpperCase() + platform.slice(1).toLowerCase();

  const PlatformLogo = PlatformsLogosSVG[platformName];
  return (
    <PlatformLogo className={['platform-logo-svg', className].join(' ')} />
  );
};
