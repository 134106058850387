import * as R from 'ramda';

export const updateKeyValueByObject = ({ objectTarget, keyTarget, newValueForKey }) => {
  const newObjectToUpdated = objectTarget;

  newObjectToUpdated[keyTarget] = newValueForKey;

  return newObjectToUpdated;
};

export const updateTimeRange = ({
  workingHoursStateData = [],
  dayTarget = 'Segunda',
  dayTargetTimeRange = 0,
  keyTime = 'open',
}) => (newTime = '00:00') => {
  const newTimeFromWorkingHoursDay = currentTime => {
    if (currentTime.id === dayTargetTimeRange) {
      return updateKeyValueByObject({
        objectTarget: currentTime,
        keyTarget: keyTime,
        newValueForKey: newTime,
      });
    }

    return currentTime;
  };

  const updateWorkingHoursDay = workingHoursDay => {
    if (workingHoursDay.day_title === dayTarget) {
      const { times } = workingHoursDay;

      const newTimes = times.map(newTimeFromWorkingHoursDay);

      return {
        ...workingHoursDay,
        times: newTimes,
      };
    }
    return workingHoursDay;
  };

  const workingHouserUpdateStateData = prevStateData => prevStateData.map(updateWorkingHoursDay);

  workingHoursStateData(workingHouserUpdateStateData);
};

export const addNewTimeRange = ({
  workingHoursStateData,
  dayTarget,
}) => {
  workingHoursStateData(prevStateData => prevStateData.map(workingHoursDay => {
    if (workingHoursDay.day_title === dayTarget) {
      const { times } = workingHoursDay;

      const lastTimeRange = R.takeLast(1, times)[0];

      const newTimeRange = {
        ...lastTimeRange,
        is_deleted: false,
        id: `a${lastTimeRange.id}`,
      };

      return {
        ...workingHoursDay,
        times: [
          ...times,
          newTimeRange,
        ],
      };
    }

    return workingHoursDay;
  }));
};

export const removeTimeRange = ({
  workingHoursStateData,
  dayTarget,
  timeRange,
}) => {
  workingHoursStateData(prevStateData => prevStateData.map(workingHoursDay => {
    if (workingHoursDay.day_title === dayTarget) {
      const { times } = workingHoursDay;
      const newTimesRange = times.map(time => {
        if (time.id === timeRange) {
          return {
            ...time,
            is_deleted: true,
          };
        }

        return time;
      });

      return {
        ...workingHoursDay,
        times: newTimesRange,
      };
    }

    return workingHoursDay;
  }));
};
