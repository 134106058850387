import styled, { css } from 'styled-components';
import { TableRow, TableCell, Checkbox } from '@material-ui/core';
import MUIDataTable from 'mui-datatables';
import { shade, rgba } from 'polished';

import { TTableBodyCell } from '../../types/TTable';

export const StyledTable = styled<any>(MUIDataTable)`
  width: 100%;
  height: fit-content;

  .MuiPaper-root.MuiPaper-elevation1 {
    background: ${(props) => props.theme.colors.primary};
    color: ${(props) => props.theme.colors.white};
    margin-bottom: 1.5rem;

    .MuiTypography-root {
      font-size: 1.6rem;
    }

    .MuiSvgIcon-root {
      fill: ${(props) => props.theme.colors.white};
      width: 2.5rem;
      height: 2.5rem;
    }
  }

  .MuiTableCell-root,
  .MuiTypography-body2 {
    font-family: "Poppins", sans-serif !important;
  }

  .MuiToolbar-gutters {
    padding-left: 0;
  }

  .MuiTypography-h6 {
    font-family: "Poppins", sans-serif;
    font-size: 2.5rem;
    font-weight: bold;

    margin-left: 1rem;
  }

  .MuiToolbar-root {
    .MuiSvgIcon-root {
      fill: ${(props) => props.theme.colors.primary};
      width: 2.5rem;
      height: 2.5rem;
    }
  }

  .MuiTableHead-root,
  .MuiTableBody-root {
    .MuiSvgIcon-root {
      width: 2rem;
      height: 2rem;
      fill: ${(props) => props.theme.colors.primary};
    }
  }

  .MuiTableHead-root {
    th {
      background: ${(props) => shade(0.05, props.theme.colors.primary)};
      color: ${(props) => shade(0.05, props.theme.colors.white)};
      padding-top: 1.2rem;
      padding-bottom: 1.2rem;
    }

    th:first-child {
      border-top-left-radius: 0.5rem;
    }

    th:last-child {
      border-top-right-radius: 0.5rem;
    }

    .MuiTableCell-root,
    .MuiButton-text {
      color: ${(props) => shade(0.05, props.theme.colors.white)};
      font-family: "Poppins", sans-serif;
      font-size: 1.6rem;
      font-weight: bold;
    }

    .MuiSvgIcon-root {
      fill: ${(props) => props.theme.colors.white};
    }
  }

  .MuiTablePagination-caption,
  .MuiTablePagination-input {
    font-size: 1.6rem;
  }

  .data-table-loading {
    margin: 2rem auto;
  }
`;

export const StyledTableBodyRow = styled(TableRow)`
  &:nth-child(even) {
    background: ${(props) => shade(0.05, props.theme.colors.white)};

    ${(props) => props.selected
      && css`
        background: ${rgba(props.theme.colors.pink, 0.2)} !important;
      `}
  }

  ${(props) => props.selected
    && css`
      background: ${rgba(props.theme.colors.pink, 0.1)} !important;
    `}

  .wide {
    .MuiDialog-paper {
      width: 100%;
    }
  }
`;

export const StyledTableBodyCell = styled(TableCell)<
  Pick<TTableBodyCell, 'isCentered'>
>` TableBodyCell 
  padding: 1rem 1.6rem;
  font-size: 1.6rem;
  border: none;

  ${(props) => props.isCentered
    && css`
      text-align: center;
    `};

  &.table-body-cell-checked {
    padding-left: 0.4rem;
  }
`;

export const StyledCheckbox = styled(Checkbox)``;
