import React from 'react';
import { isEmpty, isNil } from 'ramda';

import { TChecklistItem } from '../../../types/TChecklist';

import { Checkbox, IconButton } from '../..';

import {
  StyledChecklistItem,
  StyledLabel,
} from './checklist-item-styles';

const ChecklistItem = ({
  item,
  selected,
  setSelected,
  masterCheckbox,
  showWarningWorkingHours,
}: TChecklistItem) => {
  const [t, setT] = React.useState(true);

  const [warnings, setWarnings] = React.useState<string[]>([]);

  const handleSetSelected = (itemTarget: any) => {
    if (t && masterCheckbox) {
      setSelected((prevState: any) => ({
        ...prevState,
        ids: [...prevState.ids, itemTarget],
      }));
      return;
    }

    if (!t && !masterCheckbox) {
      setSelected((prevState: any) => ({
        ...prevState,
        ids: [...prevState.ids, itemTarget],
      }));
      return;
    }

    if (!t && masterCheckbox) {
      setSelected((prevState: any) => ({
        ...prevState,
        ids: prevState.ids.filter((itemItem: any) => itemItem !== itemTarget),
      }));
      return;
    }

    if (t && !masterCheckbox) {
      setSelected((prevState: any) => ({
        ...prevState,
        ids: prevState.ids.filter((itemItem: any) => itemItem !== itemTarget),
      }));
    }
  };

  React.useEffect(() => {
    const checkItemHasInSelecteds = Boolean(
      selected.ids.find((itemSelected: any) => itemSelected === item.id),
    );

    if (masterCheckbox && checkItemHasInSelecteds) {
      setT(false);
      return;
    }

    if (!masterCheckbox && checkItemHasInSelecteds) {
      setT(true);
      return;
    }

    if (masterCheckbox && !checkItemHasInSelecteds) {
      setT(true);
      return;
    }

    if (!masterCheckbox && !checkItemHasInSelecteds) {
      setT(false);
    }
  }, [selected, masterCheckbox, item]);

  const isEmptyOrNull = (value: any) => isEmpty(value) || isNil(value);

  const setNewWarningMessages = React.useCallback(() => {
    const hasWorkingHoursFilled = item.working_hours?.length === 7;
    const isLocationLinked = !isEmpty(item.location_connections);
    if (!isLocationLinked) {
      setWarnings((prevState) => [...prevState, 'Local sem vinculação']);
    }
    if (!hasWorkingHoursFilled && showWarningWorkingHours) {
      setWarnings((prevState) => [
        ...prevState,
        'Este local não tem horários de funcionamento cadastrados',
      ]);
    }
  }, []);

  React.useEffect(() => {
    setNewWarningMessages();
  }, []);

  return (
    <StyledChecklistItem>
      <StyledLabel>{item.name}</StyledLabel>

      {!isEmpty(warnings) && (
        <IconButton
          isWarning
          icon="Error"
          tooltip={warnings}
          tooltipVariant="white"
        />
      )}

      {!isEmptyOrNull(item.location_connections) && (
        <Checkbox
          label=""
          labelPlacement="right"
          checked={t}
          onChange={() => handleSetSelected(item.id)}
        />
      )}
    </StyledChecklistItem>
  );
};
export default ChecklistItem;
