import React from 'react';
import * as R from 'ramda';

import {
  IconButton,
} from '../../../components';

import {
  StyledAddMidiaDialog,
  StyledHeader,
  StyledFooter,
  StyledFormAndLocationsWithoutLinkWrapper,
  StyledHasLocationsWithoutLinked,
  StyledErrorLocationList,
  StyledErrorIcon,
  StyledRow,
  StyledConfirmButton,
} from './add-midia-dialog-styles';
import { GalleryBatchForm } from '../gallery-batch-form';
import { TBatchAddMidiaDialogFormProps } from '../../../types/TBatchGallery';

const AddMidiaDialog = ({
  open = false,
  locationsWithoutLinkedList,
  closeModal,
}: TBatchAddMidiaDialogFormProps) => {
  const [showBatchForm, setShowBatchForm] = React.useState(!R.isNil(locationsWithoutLinkedList) && locationsWithoutLinkedList.count === 0);

  const onConfirmClicked = () => {
    setShowBatchForm(true);
  };

  return (
    <StyledAddMidiaDialog
      open={open}
      aria-labelledby="simple-dialog-title"
    >
      {!showBatchForm && (
        <>
          <StyledHeader>
            <p>Atenção!</p>
            <IconButton
              icon="Close"
              tooltip="Fechar"
              onClick={() => closeModal(false)}
            />
          </StyledHeader>
          <StyledFormAndLocationsWithoutLinkWrapper>
            <StyledHasLocationsWithoutLinked>
              <b>Existem Locais que estão com a vinculação pendente:</b>
              <p>Os locais abaixo não serão afetados pela adição da imagem se não estiverem vinculados.</p>

              {!R.isNil(locationsWithoutLinkedList) && locationsWithoutLinkedList.count > 0 && (
              <StyledErrorLocationList>
                {locationsWithoutLinkedList.locations.map(location => (
                  <StyledRow>
                    <StyledErrorIcon />
                    {' '}
                    {location}
                  </StyledRow>
                ))}
              </StyledErrorLocationList>
              )}
            </StyledHasLocationsWithoutLinked>
          </StyledFormAndLocationsWithoutLinkWrapper>
          <StyledFooter>
            <StyledConfirmButton
              onClick={onConfirmClicked}
            >
              Prosseguir mesmo assim
            </StyledConfirmButton>
          </StyledFooter>
        </>
      )}
      {
         showBatchForm
         && (
         <GalleryBatchForm
           open={showBatchForm}
           closeModal={closeModal}
         />
         )
      }
    </StyledAddMidiaDialog>
  );
};

export default AddMidiaDialog;
