import * as R from 'ramda';

import { TGetValidGoogleAccessToken, TGetValidGoogleAccessTokenFromLocation } from '../types/TConnections';

import APIGetLocationConnections from './location-connections/getAllLocationConnectionsFromLocation';
import APIUpdateLocationConnections from './location-connections/updateLocationConnection';

import refreshGoogleAccessToken from './google/refreshGoogleAccessToken';

import generateTokenExpirationDate from '../utils/generate-token-expiration-date';
import { LocationConnectionStatusEnum } from '../types/TPlatformsLinkWithLocation';

export const refreshTokenInvalid = ({
  feedbackMessage,
  userProfile,
}: Pick<TGetValidGoogleAccessToken, 'feedbackMessage' | 'userProfile'>) => () => {
  if (R.isNil(feedbackMessage)) return;

  if (userProfile !== 'Usuário Padrão') {
    feedbackMessage('O Refresh token não é mais valido ou não existe!', {
      variant: 'warning',
    });

    return;
  }

  feedbackMessage(
    'Encontramos um problema no seu token de acesso às avaliações. Entre em contato com o suporte!',
    { variant: 'warning' },
  );
};

export default async function getValidGoogleAccessTokenFromLocation({
  userId,
  accessToken,
  feedbackMessage = null,
  userProfile,
  locationId,
}: TGetValidGoogleAccessTokenFromLocation) {
  const feedbackMessageTokenInvalidByUser = refreshTokenInvalid({
    feedbackMessage,
    userProfile,
  });

  const getValidLocationConnectionsResponse = await APIGetLocationConnections({
    accessToken,
    locationId,
    status: LocationConnectionStatusEnum.VALID,
  });

  if (R.isNil(getValidLocationConnectionsResponse)) return null;

  const [data, amount] = getValidLocationConnectionsResponse;

  if (amount === 0) {
    feedbackMessageTokenInvalidByUser();
    return null;
  }

  const userConnection = data.filter((connection) => connection.created_by === userId);

  const {
    id: connectionId,
    token_expires_at: expirationTime,
    token: googleAccessToken,
    refresh_token: googleRefreshToken,
    code,
    connection_info: connectionInfo,
  } = userConnection[0] || data[0];

  const currentDate = new Date();
  const expirationDate = new Date(expirationTime);

  const isValid = currentDate < expirationDate;

  if (isValid) {
    return {
      googleAccessToken,
      expirationTime,
      googleRefreshToken,
      googleAuthCode: code,
      connectionInfo,
    };
  }

  const updateOnError = async (statusIs404: boolean) => {
    await APIUpdateLocationConnections({
      accessToken,
      userId,
      id: connectionId,
      status: statusIs404
        ? LocationConnectionStatusEnum.UNAUTHORIZED
        : LocationConnectionStatusEnum.REFRESH_TOKEN_EXPIRED,
    });
  };

  const refreshGoogleAccessTokenResponse = await refreshGoogleAccessToken({
    refreshToken: googleRefreshToken,
    onError: updateOnError,
  });

  if (R.isNil(refreshGoogleAccessTokenResponse)) {
    feedbackMessageTokenInvalidByUser();
    return null;
  }

  const {
    access_token: refreshedAccessToken,
    expires_in: expiresIn,
  } = refreshGoogleAccessTokenResponse;

  await APIUpdateLocationConnections({
    accessToken,
    userId,
    id: connectionId,
    token: refreshedAccessToken,
    tokenExpiresAt: generateTokenExpirationDate(expiresIn),
  });

  return {
    googleAccessToken: refreshedAccessToken,
    expirationTime: expiresIn,
    googleRefreshToken,
    googleAuthCode: code,
    connectionInfo,
  };
}
