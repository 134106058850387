import styled, { css } from 'styled-components';
import { lighten } from 'polished';

import { TInputBaseProps } from '../../types/TInput';

type StyledInputCustomProps = Pick<TInputBaseProps, 'border' | 'borderRadius' | 'disabled' | 'isDateInput' | 'borderAlert'>;

export const StyledCurrencyInput = styled.div<StyledInputCustomProps>`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;

  label {
    font-size: 1.5rem;
    font-weight: normal;
    color: ${props => props.theme.colors.black};
  }

  input {
    width: 100%;
    padding: 0 1.5rem;
    border: none;

    &:disabled {
      background-color: rgba(0, 0, 0, 0);
    }
  }
`;

export const StyledInputWrapper = styled.div<{ disabled: boolean }>`
  width: 100%;
  height: 4.1rem;
  display: flex;
  align-items: center;
  position: relative;
  border: 2px solid ${props => lighten(0.4, props.theme.colors.black)};
  background-color: ${props => props.theme.colors.white};

  ${(props) => props.disabled && css`
    background-color: ${lighten(0.9, props.theme.colors.black)};
  `};
`;
