import React from 'react';
import * as R from 'ramda';

import {
  StyledProfileHeader,
  StyledUserAvatarLetter,
  StyledProfileHeaderContent,
  StyledUserProfileTextWrapper,
  StyledUserName,
  StyledUserRole,
} from './profile-header-styles';

const ProfileHeader = ({
  userName,
  userProfileName,
}) => {
  const nameLetter = userName[0];

  return (
    <StyledProfileHeader>
      <StyledProfileHeaderContent>
        <StyledUserAvatarLetter className="letter">
          {nameLetter}
        </StyledUserAvatarLetter>
        <StyledUserProfileTextWrapper>
          <StyledUserName>
            {userName}
          </StyledUserName>

          <StyledUserRole>
            {userProfileName}
          </StyledUserRole>
        </StyledUserProfileTextWrapper>
      </StyledProfileHeaderContent>
    </StyledProfileHeader>
  );
};

export default ProfileHeader;
