import styled from 'styled-components';

export const StyledFacebook = styled.div`
  display: flex;
  flex-direction: column;

  .disclaimer-sync {
    font-size: 1.3rem;
    margin-top: 1rem;
    margin-bottom: 1rem;

    small {
      font-size: 1.3rem;
      color: ${props => props.theme.colors.red};
    }
  }
`;

export const StyledButtonsGroup = styled.div`
  display: flex;
  flex-direction: column;

  .button {
    margin-bottom: 1.5rem;

    &:last-of-type {
      margin-bottom: 0;
    }
  }
`;
