import React from 'react';

import {
  StyledUber,
} from './uber-styles';

const Uber = () => (
  <StyledUber>
    Uber
  </StyledUber>
);

export default Uber;
