export const emailScheme = {
  name: 'field-user-email',
  nickname: 'Email',
  label: 'E-mail',
  type: 'text',
  required: true,
  hasborder: 'yes',
  email: true,
  disabled: true,
};

export const passwordScheme = {
  name: 'field-user-password',
  nickname: 'password',
  label: 'Senha',
  required: true,
  hasborder: 'yes',
  password: true,
  type: 'password',
};

export const nameScheme = {
  name: 'field-user-name',
  nickname: 'name',
  label: 'Nome',
  required: true,
  hasborder: 'yes',
}