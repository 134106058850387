import React from 'react';

import ConfigCard from '../../../components/config-card';

import {
  StyledDeletedCompanies,
} from './deleted-companies-styles';

const HandlerList = ({ navigation }) => (
  <ConfigCard
    title="Empresas Apagadas"
    onClick={() => navigation.push('/deleted-companies/list')}
  >
    <StyledDeletedCompanies>
      Gerência de empresas apagadas pelo usuário proprietário
    </StyledDeletedCompanies>
  </ConfigCard>
);

export default HandlerList;
