import React, { useState } from 'react';
import { isNil } from 'lodash';
import { useAuth } from '../../../../hooks';

import { Dialog, Loading } from '../../../../components';

import { getAllErrorsFromMonitor } from '../../../../services/platform-sync-modules/get-all-errors-from-monitor';

import isNilOrEmpty from '../../../../utils/is-null-or-empty';

import { StyledLoadingWrapper, StyledErrorWrapper } from './error-dialog.styles';
import { TErrorDialogProps } from './types';

const MonitorDialog = ({
  isDialogOpen,
  onClose,
  monitorId,
}: TErrorDialogProps) => {
  const { userAccessToken, userSetTokenLikeExpired } = useAuth();

  const [loading, setLoading] = useState(true);
  const [monitorErrorData, setMonitorErrorData] = useState<string>('');

  const getErrorData = React.useCallback(async () => {
    const getErrorDataResponse = await getAllErrorsFromMonitor({
      monitorId,
      userAccessToken,
      isFetching: setLoading,
      setTokenLikeExpired: userSetTokenLikeExpired,
    });

    if (isNil(getErrorDataResponse)) return;

    setMonitorErrorData(getErrorDataResponse);
  }, [monitorId]);

  React.useEffect(() => { getErrorData(); }, [getErrorData]);

  return (
    <Dialog
      open={isDialogOpen}
      onClose={onClose}
      title="Erros de Monitoramento"
      wideDialog
    >
      <StyledErrorWrapper>
        {loading && <StyledLoadingWrapper><Loading /></StyledLoadingWrapper>}
        {
          !isNilOrEmpty(monitorErrorData) && !loading
          && (
          <code>{monitorErrorData}</code>
          )
        }
      </StyledErrorWrapper>
    </Dialog>
  );
};

export default MonitorDialog;
