import styled from 'styled-components';

export const StyledTitle = styled.h4`
  font-size: 1.5rem;
`;

export const StyledInfoUpdate = styled.div`
  width: 100%;

  h4 {
    margin-bottom: 0;
    font-weight: 500;
    line-height: 1.2;
  }

  .MuiAccordionDetails-root {
    padding: 16px 16px;
  }

  .MuiAccordionSummary-root {
    background: ${props => props.theme.colors.primary};
    color: ${props => props.theme.colors.white};

  }
  
  .first-accordion {
    border-top-left-radius: 0.5rem;
    border-top-right-radius: 0.5rem;
  }

  .last-accordion {
    border-bottom-left-radius: 0.5rem;
    border-bottom-right-radius: 0.5rem;
  }

  .Mui-expanded {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }

  svg {
    width: 2rem;
    height: 2rem;
    color: ${props => props.theme.colors.white};
  }
`;

export const StyledWorkingHoursWrapper = styled.div`
  width: 100%;
`;
