import React from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import * as R from 'ramda';

import getAllCompanyGuests from '../../services/guest/getAllCompanyGuests';
import createCompanyInvite from '../../services/invites/createCompanyInvite';
import getAllCompanyInvites from '../../services/invites/getAllCompanyInvites';

import { Creators as CompanyActions } from '../../redux/ducks/company';

import {
  useAuth,
  useCompany,
} from '../../hooks';

import { saveEditionInDB } from './helpers';

import {
  HeaderToolbar,
  BlockWithActionButton,
  CompanyInfo,
  HealderToolbarAction,
  GuestList,
} from '../../components';

import {
  StyledEditCompany,
  StyledButtonsGroup,
  StyledContent,
  StyledContentMain,
  StyledContentAside,
} from './edit-company-styles';

const EditCompany = () => {
  const { push: pushToScreen } = useHistory();

  const { enqueueSnackbar } = useSnackbar();

  const reduxDispatch = useDispatch();

  const {
    userId,
    userProfileName,
    userAccessToken,
    userSetTokenLikeExpired,
  } = useAuth();

  const {
    activeCompany,
    activeCompanyId,
  } = useCompany();

  const companyInfoData = React.useRef(null);

  const [currentCompanyData, setCurrentCompanyData] = React.useState(null);
  const [isSaveEdition, setIsSaveEdition] = React.useState(false);

  const urlValidation = (url) => {
    if ((url.includes('https://datastudio.google.com/u/0/reporting/')
    || url.includes('https://datastudio.google.com/reporting/'))
    ) return true;

    return false;
  };

  const getCompanyInfos = React.useCallback(async () => {
    if (R.isNil(activeCompany)) return;

    const {
      name,
      is_active,
      website,
      data_studio,
    } = activeCompany;

    setCurrentCompanyData({
      name, is_active, website, data_studio,
    });
  }, [activeCompany]);

  React.useEffect(() => { getCompanyInfos(); }, [getCompanyInfos]);

  const handleSaveEdition = React.useCallback(() => {
    const { company } = companyInfoData.current;

    const companyChecks = company.map(companyField => companyField.firedCheck);
    const companyValues = company.map(companyField => companyField.getValue());
    const companySetValues = company.map(companyField => companyField.setDefaultValue);

    if (companyChecks.every(companyFiredCheck => companyFiredCheck())) {
      const companyData = {
        name: companyValues[0],
        website: companyValues[1],
        is_active: companyValues[2],
        data_studio: companyValues[3],
      };

      companySetValues[0](companyValues[0]);
      companySetValues[1](companyValues[1]);
      companySetValues[2](companyValues[2]);
      companySetValues[3](companyValues[3]);

      if (userProfileName !== 'Usuário Padrão' && !R.isEmpty(companyData.data_studio)) {
        if (!urlValidation(companyData.data_studio)) {
          enqueueSnackbar('O formato do link de Data Studio está inválido', { variant: 'warning' });
          return;
        }
      }

      saveEditionInDB({
        companyData,
        companyID: activeCompanyId,
        setIsSaveEdition,
        setTokenLikeExpired: userSetTokenLikeExpired,
        userHublocalToken: userAccessToken,
        userHublocalID: userId,
        feedbackMessage: enqueueSnackbar,
      })
        .then((company) => {
          reduxDispatch(CompanyActions.setActiveCompany(company)); // Atualiza a empresa no redux
        });
    }
  }, [
    companyInfoData,
    activeCompanyId,
  ]);

  const handlePushToMyCompanies = () => pushToScreen('/my-companies');
  const handlePushToCreateCompany = () => pushToScreen('/companies/create');

  return (
    <StyledEditCompany>
      <HeaderToolbar
        title="Editar empresa"
      >
        <StyledButtonsGroup>
          <HealderToolbarAction
            title="Adicionar empresa"
            icon="AddBox"
            onClick={handlePushToCreateCompany}
          />
          <HealderToolbarAction
            title="Empresas"
            icon="Business"
            onClick={handlePushToMyCompanies}
          />
        </StyledButtonsGroup>
      </HeaderToolbar>
      <StyledContent>
        <StyledContentMain>
          {currentCompanyData && (
            <CompanyInfo
              ref={companyInfoData}
              className="block-form"
              title="Informações básicas da empresa"
              initialData={currentCompanyData}
              userProfileType={userProfileName}
            />
          )}
        </StyledContentMain>
        <StyledContentAside>
          <BlockWithActionButton
            onClick={handleSaveEdition}
            titleButton="Salvar edição"
            loading={isSaveEdition}
          />

          {!R.isNil(activeCompanyId) && (
            <GuestList
              APIGetPendingInvites={getAllCompanyInvites}
              userAccessToken={userAccessToken}
              userSetTokenLikeExpired={userSetTokenLikeExpired}
              targetParamToGetPendingInvites="companyId"
              activeRegisterIdToGetData={activeCompanyId}
              APIGetAllGuest={getAllCompanyGuests}
              targetParamToGetGuests="companyId"
              userId={userId}
              APICreateInvite={createCompanyInvite}
              targetParamToCreateInvites="company_id"
              isCompany
            />
          )}
        </StyledContentAside>
      </StyledContent>
    </StyledEditCompany>
  );
};

export default EditCompany;
