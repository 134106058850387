import styled from 'styled-components';
import { Paper } from '@material-ui/core';

export const StyledBatchCreationEnable = styled(Paper)`
  display: flex;
  flex-direction: column;
  margin-bottom: 3rem;
  padding: 2rem;

  h3 {
    font-size: 1.5rem;
    font-weight: 700;
    margin-bottom: 2rem;
  }

  small {
      font-size: 1.2rem;
      margin-bottom: 2rem;
  }

  .button {
    width: 100%;
  }
`;
