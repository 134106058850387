import React from 'react';

import { StyledButton } from './button-styles';

import { TButtonBaseProps } from '../../types/TButton';

const Button = ({
  children,
  type,
  disabled,
  onClick,
  as,
  url,
  className,
  buttonType,
}: TButtonBaseProps) => (
  <StyledButton
    as={as}
    className={className}
    buttonType={buttonType}
    to={url}
    onClick={onClick}
    disabled={disabled}
    type={type}
  >
    {children}
  </StyledButton>
);

export default Button;
