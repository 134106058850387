export default function formatDate(timestamp) {
  const timestampParts = timestamp.split(' ');
  const date = timestampParts[0];
  const time = timestampParts[1];

  const dateParts = date.split('-');
  if (dateParts[2]) {
    const day = dateParts[2];
    const month = dateParts[1];
    const year = dateParts[0];

    return `${day}/${month}/${year} ${time}`;
  }
  const parts = timestamp.split(',');
  const datePart = parts[0].split('/');
  const dayPart = datePart[1];
  const monthPart = datePart[0];
  const yearPart = datePart[2];

  const timePart = parts[1].split(' ')[1].split(':');
  let hourPart = timePart[0];
  const minutePart = timePart[1];
  const secondPart = timePart[2];

  if (parts[1].split(' ')[2] === 'PM') hourPart = parseInt(hourPart) + 12;

  return `${dayPart}/${monthPart}/${yearPart} ${hourPart}:${minutePart}:${secondPart}`;
}
