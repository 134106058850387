import axios from 'axios';
import type { TBatchCreateGallery } from '../../types/TBatchUpdate';

export default async function createMediaGallery({
  accessToken,
  groupType,
  groupId,
  mediaData,
  createdBy,
  locations,
  setIsLoading = null,
  feedbackMessage,
  userData,
}: TBatchCreateGallery) {
  try {
    if (setIsLoading) setIsLoading(true);

    const { data } = await axios.post(`${process.env.REACT_APP_GOOGLE_API_URL}/gallery/batch`,
      {
        group_type: groupType,
        group_id: groupId,
        media_data: mediaData,
        created_by: createdBy,
        locations,
        user_data: userData,
      },
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });

    if (data.status === 406) {
      if (feedbackMessage) feedbackMessage('Nenhum local foi selecionado ao iniciar as mídias em massa. Selecione um ou mais locais e tente novamente.', { variant: 'warning' });
      return data;
    }

    if (feedbackMessage) feedbackMessage('Mídia em massa iniciada com sucesso', { variant: 'success' });

    return data;
  } catch (err: any) {
    if (feedbackMessage) feedbackMessage('Erro ao iniciar publicação de galeria em massa, tente novamente!', { variant: 'error' });

    return null;
  } finally {
    if (setIsLoading) setIsLoading(false);
  }
}
