import styled, { css } from 'styled-components';
import { shade } from 'polished';

const isFilterVisible = css`
  transform: scale(1.1);
  background: ${(props) => props.theme.colors.primary};
  color: ${(props) => props.theme.colors.white};
  width: auto;
  height: auto;
  padding: 0.5rem 1rem;
  font-size: 1.1rem;
  margin: 0 1.5rem;
`;

export const StyledSensitiveWord = styled.li`
  display: flex;
  align-items: flex-end;
  padding: 1rem 2rem;
  border: 1px solid ${(props) => props.theme.colors.primary};
  color: ${(props) => props.theme.colors.primary};
  border-radius: 6rem;
  cursor: pointer;
  transition: 0.3s;
  margin: 0.5rem;

  &:hover {
    transform: scale(1.1);
    background: ${(props) => shade(0.1, props.theme.colors.primary)};
    color: ${(props) => props.theme.colors.white};
    border-color: transparent;

    .apply-filter {
      ${isFilterVisible}
      background: ${(props) => props.theme.colors.white};
      color: ${(props) => props.theme.colors.primary};
    }

    .count-word {
      background: ${(props) => props.theme.colors.white};
      color: ${(props) => props.theme.colors.primary};
    }

    .icon-button-delete {
      background: ${(props) => props.theme.colors.white};

      .icon-button__icon {
        fill: ${(props) => props.theme.colors.primary} !important;
      }
    }
  }

  .icon-button-delete {
    background: ${(props) => props.theme.colors.primary};
    width: 1.5rem;
    height: 1.5rem;

    &:hover {
      .icon-button__icon {
        fill: ${(props) => props.theme.colors.primary} !important;
      }
    }

    .icon-button__icon {
      fill: ${(props) => props.theme.colors.white} !important;
      width: 1.6rem;
      height: 1.6rem;
    }
  }

  .count-word {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 0.5rem;
    font-weight: bold;
    width: 2.4rem;
    height: 2.4rem;
    border-radius: 6rem;
    background: ${(props) => props.theme.colors.primary};
    color: ${(props) => props.theme.colors.white};
  }
`;

export const StyledSensitiveWordTitle = styled.span`
  font-weight: bold;

  ${(props) => props.hasNoWordCounter
    && css`
      margin-right: 1rem;
    `};
`;

export const StyledFilter = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 6rem;
  margin: 0 0.5rem;
  transition: 0.3s;
  font-weight: bold;
  width: 0;
  height: 0;
  padding: 0;
  font-size: 0;

  ${(props) => props.isFilter && isFilterVisible}
`;
