import React, { useState } from 'react';
import {
  TableBodyRow, TableBodyCell, IconButton,
} from '../../../../components';
import { TTableRowData } from '../../../../types/TTable';
import { TRenderCellDataRequestsRow, TServiceRequestsRowColumnsName } from './helpers';
import SyncStatusIcon from '../../../../components/sync-status-icon';
import { StyledIconsWrapper, StyledIconWrapper } from './requests-row-styles';
import { History } from '../../../../assets/icons';

const RequestsRow = ({
  rowData,
  rowId,
  dataTableOptions,
  onRequestsDialogOpen,
  onOpenActionLogDialog,
}: any) => {
  const [checked, setChecked] = useState(false);
  const [currentRowData, setCurrentRowData] = useState<TTableRowData>([]);

  React.useEffect(() => {
    setCurrentRowData(rowData);
  }, [rowData]);

  const renderCellData = React.useMemo<TRenderCellDataRequestsRow>(
    () => ({
      id: (value) => value,
      company: (value) => value,
      location: (value) => value,
      created_at: (value) => value,
      requester: (value) => value,
      status: (value) => <StyledIconsWrapper><SyncStatusIcon status={value} syncType="requests" /></StyledIconsWrapper>,
      request: (value) => (
        <StyledIconsWrapper>
          <StyledIconWrapper>
            <IconButton
              icon="VisibilityOutlined"
              tooltip="Visualizar Solicitações"
              onClick={() => onRequestsDialogOpen(value)}
            />
          </StyledIconWrapper>
          <StyledIconWrapper>
            <IconButton
              tooltip="Visualizar Histórico de Ações da Sincronização"
              onClick={() => {
                onOpenActionLogDialog(value);
              }}
              showCustomIcon
              CustomIcon={History}
            />
          </StyledIconWrapper>
        </StyledIconsWrapper>
      ),
    }),
    [],
  );
  return (
    <TableBodyRow
      key={rowId}
      dataTableOptions={dataTableOptions}
      dataRow={rowData}
      checked={checked}
      setChecked={setChecked}
      hiddenCheckbox
    >
      {currentRowData.map(
        (dataColumnCell: any) => dataColumnCell.visible && (
          <TableBodyCell>
            {renderCellData[
                dataColumnCell.columnRef as TServiceRequestsRowColumnsName
            ]?.(dataColumnCell.value)}
          </TableBodyCell>
        ),
      )}
    </TableBodyRow>
  );
};

export default RequestsRow;
