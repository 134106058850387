import axios from 'axios';

import {
  TLocalPostsLocationsEntity,
  TDeleteLocalPostLocation,
} from '../../types/TBatchGoogleModules';

export const deleteLocalPostsLocations = async ({
  userAccessToken,
  localPostLocationId,
  isDeleting,
  user_id,
  user_name,
  user_email,
}: TDeleteLocalPostLocation): Promise<TLocalPostsLocationsEntity | null> => {
  try {
    if (isDeleting) isDeleting(true);

    const apiPath = `
      ${process.env.REACT_APP_SAAS_BATCH_GOOGLE_MODULES}/local-posts-locations/delete-post/${localPostLocationId}?user_id=${user_id}&user_name=${user_name}&user_email=${user_email}
    `;

    const requestOptions = {
      headers: {
        Authorization: `Bearer ${userAccessToken}`,
      },
    };

    const { data } = await axios.delete(apiPath, requestOptions);

    return data;
  } catch (err) {
    return null;
  } finally {
    if (isDeleting) isDeleting(false);
  }
};
