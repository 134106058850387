import React from 'react';

import {
  ButtonTypeStyleProps,
  StyledContainer,
} from './button-v2-styles';

type ButtonProps = {
  icon?: any;
  children: any;
  typeButton: ButtonTypeStyleProps;
  onClick?: () => void;
}

const Button = ({
  icon = null,
  children,
  typeButton = 'PRIMARY',
  onClick,
}: ButtonProps) => (
  <StyledContainer
    typeButton={typeButton}
    type="button"
    startIcon={icon}
    onClick={onClick}
  >
    <b>{children}</b>
  </StyledContainer>
);

export default Button;
