import axios from 'axios';

export default async function createInfoModel(
  accessToken,
  name,
  facebook,
  instagram,
  twitter,
  youtube,
  linkedin,
  yelp,
  foursquare,
  website,
  other_links,
  created_by = 1,
) {
  const requestOptions = {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  };

  const { data } = await axios.post(
    `${process.env.REACT_APP_LINK_MODEL_API_URL}`,
    {
      name,
      facebook,
      instagram,
      twitter,
      youtube,
      linkedin,
      yelp,
      foursquare,
      website,
      other_links,
      created_by,
    },
    requestOptions,
  );

  return data;
}
