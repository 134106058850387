import React from 'react';
import {
  CheckCircle as CheckCircleIcon,
  Warning as WarningIcon,
  Error as ErrorIcon,
} from '@material-ui/icons';
import * as R from "ramda";

import statusOption from './status';
import Hours from "./hours";

import {
  StyledFieldsRow,
  StyledFieldsName,
  StyledFieldsGroup,
} from './fields-row-styles';

const FieldsRow = ({
  title,
  valueHublocal,
  valuePlatform,
  name,
}) => {
  const [status, setStatus] = React.useState(null);
  const [valueHublocalState, setValueHublocalState] = React.useState();

  React.useEffect(() => {
    setValueHublocalState(valueHublocal);

    if (valueHublocal !== valuePlatform && name !== 'hours' && name !== 'phone') setStatus('wrong');

    if(
      valueHublocal?.tuesday !== valuePlatform?.tuesday || 
      valueHublocal?.monday !== valuePlatform?.monday || 
      valueHublocal?.wednesday !== valuePlatform?.wednesday || 
      valueHublocal?.thursday !== valuePlatform?.thursday ||
      valueHublocal?.friday !== valuePlatform?.friday ||
      valueHublocal?.saturday !== valuePlatform?.saturday ||
      valueHublocal?.sunday !== valuePlatform?.sunday
      ) {
        setStatus('wrong');
        return;
      } else if(name === 'hours') {
        setStatus('right');
        return;
      }

    if (!valueHublocal && !valuePlatform && name !== "hours") {
      setStatus('empty');
      return;
    }

    if(name === "hours") {
      if(R.isNil(valueHublocal) && valuePlatform) {
        setStatus('empty');
        return;
      }
    }

    if(name === "phone") {
      if(R.isEmpty(valueHublocal) || R.isNil(valueHublocal)) {
        setStatus("wrong");
        return;
      }

      const regExpPhone = new RegExp(valueHublocal.replace(/[^A-Z0-9]/ig, ""), "g");
      const isPhoneEquals = regExpPhone.test(valuePlatform);

      if(isPhoneEquals) setStatus("right");
      if(!isPhoneEquals) setStatus("wrong");
    }

    if(name === 'website' && (valueHublocal && valuePlatform)) {
      if(valuePlatform.includes(valueHublocal)) setStatus('right');
      if(!valuePlatform.includes(valueHublocal)) setStatus('wrong');
    }

    if(name === 'facebook-categories' && valueHublocal) {
      const facebookCategoryParce = JSON.parse(valueHublocal);

      if(typeof facebookCategoryParce === 'object') {
        const facebookCategories = facebookCategoryParce.map(category => {
          return category.name;
        }).join(', ').trim();

        if(facebookCategories === valuePlatform) setStatus('right');
        if(facebookCategories !== valuePlatform) setStatus('wrong');

        setValueHublocalState(facebookCategories);
        return;
      }
    }

    if (valueHublocal && !valuePlatform) {
      setStatus('nonexistent');
      return;
    }

    if (valueHublocal === valuePlatform) {
      setStatus('right');
      return;
    }
  }, [status, name, valueHublocal, valuePlatform]);

  return (
    <StyledFieldsRow>
      <StyledFieldsName>
        <span>{title}</span>
      </StyledFieldsName>
      <StyledFieldsGroup>
        <span className={`hublocal-value ${name === 'hours' && "hours"}`}>
          {name !== 'hours' ? valueHublocalState || '' : !R.isNil(valueHublocalState) && <Hours dataHours={valueHublocal} />}
        </span>

        <span className={`platform-value ${name === 'hours' && "hours"}`}>
          <small
            className={`status-text ${status}`}
          >
            {status && status === 'right' && <CheckCircleIcon className="icon-status icon-right" />}
            {status && (status !== 'right' && status !== 'empty') && <WarningIcon className="icon-status icon-warning" />}
            {status && status === 'empty' && <ErrorIcon className="icon-status icon-error" />}

            {status && `${statusOption[status]}`}
          </small>

          <div className="platform-value-raw">
            {name !== 'hours' ? valuePlatform || '' : !R.isNil(valuePlatform) && <Hours dataHours={valuePlatform} />}
          </div>
        </span>
      </StyledFieldsGroup>
    </StyledFieldsRow>
  );
};

export default FieldsRow;
