import React from 'react';

const useMemoizedCallback = (
  callback: (...callbackArgs: any[]) => any,
  deps: React.DependencyList
) => {
  const callbackRef = React.useRef(callback);

  const memoizedCallback = React.useCallback((...args) => {
    return callbackRef.current(...args);
  }, []);

  const updatedCallback = React.useCallback(callback, deps);

  React.useEffect(() => {
    callbackRef.current = updatedCallback;
  }, deps);

  return memoizedCallback;
};

export default useMemoizedCallback;
