import { createActions, createReducer } from 'reduxsauce';

export const { Types, Creators } = createActions({
  setFacebookPagesData: ['facebookPagesData'],
});

const initialState = {
  pagesData: {},
};

const reduxOnSetFacebookPagesData = (state = initialState, { facebookPagesData }) => (
  { ...state, facebookPagesData }
);

export default createReducer(initialState, {
  [Types.SET_FACEBOOK_PAGES_DATA]: reduxOnSetFacebookPagesData,
});
