import { isEmpty } from 'ramda';
import { TCreateFacebookLocationConnection } from '../../../../types/TPlatformsLinkWithLocation';

const permissions = [
  'pages_read_engagement',
  'pages_manage_metadata',
  'pages_read_user_content',
  'pages_show_list',
  'public_profile',
  'pages_manage_cta',
  'pages_manage_instant_articles',
  'pages_manage_engagement',
  'pages_manage_posts',
  'pages_messaging',
  'email',
];

export const getFacebookPermissions = () => permissions.join(',');

export const getButtonConfig = (
  locationConnections: TCreateFacebookLocationConnection[],
  facebookPageId: string,
  isAdminOrOwner: boolean,
  userId: number
) => {
  if (isEmpty(locationConnections))
    return { disabled: false, message: 'Adicionar conexão' };

  const filteredByFacebookLocationId = locationConnections.filter(
    (connection) =>
      connection.connection_info.facebook_page_id === facebookPageId
  );

  if (filteredByFacebookLocationId.length > 0) {
    const filteredByUser = filteredByFacebookLocationId.filter(
      (connection: any) => connection.created_by === userId
    );
    if (filteredByUser.length > 0)
      return { disabled: false, message: 'Conectado' };

    return { disabled: false, message: 'Adicionar conexão' };
  }

  if (isAdminOrOwner) return { disabled: false, message: 'Trocar conexão' };

  return { disabled: true, message: 'Impossível conectar' };
};
