import * as R from 'ramda';
import type { TLocationEntity } from '../../types/TLocation';
import type { TIsLocationFromGroup } from '../../types/TUseLocationsGroup';

export const isLocationFromGroup = ({
  activeLocationGroup,
  activeLocation,
}: TIsLocationFromGroup) => {
  if (R.isNil(activeLocationGroup)) return null;
  if (R.isNil(activeLocation)) return null;

  const locationsGroupsinActiveLocationGroup = activeLocationGroup.locations;

  if (R.isNil(locationsGroupsinActiveLocationGroup)) return null;

  const findLocationInGroup = locationsGroupsinActiveLocationGroup.find((location: any) => activeLocation.id === location.id);

  if (R.isNil(findLocationInGroup)) return null;

  return activeLocation;
};
