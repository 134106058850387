import styled from 'styled-components';
import { lighten } from 'polished';

export const StyledSearch = styled.div`
    padding: 2rem;
    margin-bottom: 0.1rem;
    background: ${props => props.theme.colors.white};
    width: 100%;
`;

export const StyledLabel = styled.label`
    font-size: 1.6rem;
    font-weight: 500;
    color: ${props => props.theme.colors.black};
    margin-bottom: 1rem;
`;

export const StyledInput = styled.input`
    width: 100%;
    height: 4.1rem;
    padding: 0 1.5rem;
    border: 2px solid ${(props) => lighten(0.4, props.theme.colors.black)};
`;
