import React from 'react';
import { useSelector } from 'react-redux';
import { format } from 'date-fns';
import { Storefront } from '@material-ui/icons';
import { isNil, includes } from 'ramda';

import {
  Button,
  IconButton,
  OverlayLoading,
  Loading,
} from '../../../../components';

import { useReviewsContext } from '../../../../contexts/reviews';

import { useAuth } from '../../../../hooks';
import ReviewReply from './review-reply';

import { starRating, starRatingInNumber } from './helpers';

import getCompanyLocations from '../../../../services/locations/getCompanyLocations';
import getLocationsGroup from '../../../../services/locations/getAllGroupLocation';

import replyToGoogleReview from '../../../../services/google/reviews/replyToReview';
import deleteGoogleReply from '../../../../services/google/reviews/deleteReply';

import {
  StyledReview,
  StyledHeader,
  StyledProfilePhoto,
  StyledProfile,
  StyledFlagIcons,
  StyledReviewerComment,
  StyledReviewer,
  StyledReviewReply,
  StyledStar,
  StyledReviewReplyButtons,
  StyledCheckbox,
} from './review-styles';
import isNilOrEmpty from '../../../../utils/is-null-or-empty';
import { capitalizeName } from '../automatic-response/helpers';

const Review = ({
  as,
  reviewerName,
  reviewerProfilePhoto,
  createTime,
  starRatingWord,
  reviwerComment,
  reviewReply,
  locationThisReview,
  reviewOverlayOrder,
  reviewData,
}) => {
  const {
    reviewsChecked,
    setReviewsChecked,
    googleUserAccessToken,
    reviewsData,
    setReviewsData,
    listAutomaticResponsesBy: { currentListBy },
    starRating: { currentStarRating },
    enqueueSnackbar,
  } = useReviewsContext();

  const { userAccessToken, userSetTokenLikeExpired } = useAuth();

  const { activeCompanyId, showLocationsByCompany } = useSelector((state) => state.CompanyReducer);
  const { activeLocationGroupId, showLocationsByGroup } = useSelector((state) => state.LocationGroupReducer);

  const [replyComment, setReplyComment] = React.useState(false);
  const [editReplyComment, setEditReplyComment] = React.useState(null);
  const [name, setName] = React.useState(null);
  const [loading, setLoading] = React.useState(false);
  const [updatedReply, setUpdatedReply] = React.useState(null);
  const [replyWasDeleted, setReplyWasDeleted] = React.useState(false);
  const [overlayLoadingText, setOverlayLoadingText] = React.useState('Atualizando...');
  const [hasReviewReply, setHasReviewReply] = React.useState(!!reviewReply);
  const [isChecked, setIsChecked] = React.useState(false);
  const [enableReviewCheckBox, setEnableReviewCheckBox] = React.useState(false);
  const [company, setCompany] = React.useState(null);

  const [loadingLocationName, setLoadingLocationName] = React.useState(false);

  let filteredReviewerCommentParts;
  let filteredReviewerComment;
  if (reviwerComment) {
    filteredReviewerCommentParts = reviwerComment.split('(Original)');
    filteredReviewerComment = filteredReviewerCommentParts.length > 1
      ? filteredReviewerCommentParts[1]
      : filteredReviewerCommentParts[0];
  }

  const getLocationName = React.useCallback(async () => {
    if (locationThisReview) {
      const locationId = locationThisReview.split('/')[3];

      let locationData = [];

      if (showLocationsByCompany) {
        locationData = await getCompanyLocations({
          accessToken: userAccessToken,
          companyId: activeCompanyId,
          query: locationId,
          setTokenLikeExpired: userSetTokenLikeExpired,
          setIfFetching: setLoadingLocationName,
        });
      }

      if (showLocationsByGroup) {
        locationData = await getLocationsGroup({
          accessToken: userAccessToken,
          locationGroupId: activeLocationGroupId,
          googleLocationId: locationId,
          setTokenLikeExpired: userSetTokenLikeExpired,
        });
      }

      if (isNil(locationData)) return;

      const [locations] = locationData || [];

      if (isNil(locations)) return;

      const [locationToThisReview] = locations || [];

      if (isNil(locationToThisReview)) return;

      setName(locationToThisReview.name);
    }
  }, [
    locationThisReview,
    activeCompanyId,
    showLocationsByCompany,
    showLocationsByGroup,
    activeLocationGroupId,
  ]);

  React.useEffect(() => {
    getLocationName();
  }, [getLocationName]);

  React.useEffect(() => {
    if (reviewReply) {
      setReplyWasDeleted(false);
      setHasReviewReply(true);
      setIsChecked(false);
      setUpdatedReply(reviewReply);
    }
  }, [reviewReply]);

  React.useEffect(() => {
    if (
      !(currentListBy !== 'unanswered' || currentStarRating.rateNumber === 0)
    ) {
      setEnableReviewCheckBox(true);
    } else {
      setEnableReviewCheckBox(false);
      setIsChecked(false);
    }
  }, [currentListBy, currentStarRating]);

  const handleReplyComment = () => {
    setReplyComment(!replyComment);
    setEditReplyComment(null);
  };

  const handleEditReplyComment = () => {
    if (updatedReply) {
      setEditReplyComment(updatedReply.comment);
    } else {
      setEditReplyComment(reviewReply.comment);
    }
    setReplyComment(!replyComment);
  };

  const onReplyClicked = async ({ reply }) => {
    setOverlayLoadingText('Postando resposta...');

    if (!reply) {
      enqueueSnackbar('A resposta à avaliação não pode ser vazia', {
        variant: 'error',
      });
      return;
    }

    const [, userId, , locationId, , reviewId] = locationThisReview.split('/');

    const replyText = reply || '';
    let replyTextWithProperties = replyText;

    const isNameReviewer = includes(
      '[[fulano]]',
      replyText.toLowerCase(),
    );

    if (isNameReviewer) {
      const reviewerNameCapitalized = capitalizeName(reviewerName);
      replyTextWithProperties = replyTextWithProperties.replace(
        /\[\[fulano\]\]/gi,
        reviewerNameCapitalized,
      );
    }

    const isCompanyName = includes(
      '[[local]]',
      replyText.toLowerCase()
    );

    if (isCompanyName) {
      replyTextWithProperties = replyTextWithProperties.replace(
        /\[\[local\]\]/gi,
        name,
      );
    }

    const replyResponseData = await replyToGoogleReview({
      accessToken: googleUserAccessToken,
      userId,
      locationId,
      reviewId,
      reply:
        (isNameReviewer || isCompanyName) ? replyTextWithProperties : reply,
      setIsReply: setLoading,
    });

    if (isNil(replyResponseData)) return;

    setReplyComment(false);
    setEditReplyComment(null);

    enqueueSnackbar('Avaliação respondida com sucesso', { variant: 'success' });
    setUpdatedReply(replyResponseData);
    setReplyWasDeleted(false);
  };

  const onRemoveReplyClicked = async () => {
    setOverlayLoadingText('Excluindo resposta...');
    const [, userId, , locationId, , reviewId] = locationThisReview.split('/');

    await deleteGoogleReply(
      googleUserAccessToken,
      userId,
      locationId,
      reviewId,
      setLoading,
    );

    const newReviewsByLocation = reviewsData.map((reviewItem) => {
      const { review } = reviewItem;

      if (review.reviewId === reviewId) {
        delete review.reviewReply;

        return {
          ...reviewItem,
          review: {
            ...reviewItem.review,
          },
        };
      }

      return reviewItem;
    });

    setReviewsData(newReviewsByLocation);

    enqueueSnackbar('Resposta excluída com sucesso', { variant: 'success' });

    setHasReviewReply(true);
    setUpdatedReply(null);
    setReplyComment(false);
    setReplyWasDeleted(true);
    setHasReviewReply(false);
  };

  const formatDate = (date) => {
    if (isNilOrEmpty(date)) return '';

    const formattedDate = new Date(date);

    return format(formattedDate, 'dd/MM/yyyy HH:mm');
  };

  const handleIsChecked = () => {
    const newIsChecked = !isChecked;

    if (newIsChecked) {
      setReviewsChecked([...reviewsChecked, reviewData]);
    }

    if (!newIsChecked) {
      const filteredReviews = reviewsChecked.filter(
        ({ review: { reviewId } }) => {
          const { review: selectedReview } = reviewData;

          return reviewId !== selectedReview.reviewId;
        },
      );

      setReviewsChecked(filteredReviews);
    }

    setIsChecked(!isChecked);
  };

  return (
    <StyledReview
      as={as}
      isChecked={isChecked}
      reviewOverlayOrder={reviewOverlayOrder}
    >
      {(loading || loadingLocationName) && (
        <OverlayLoading textToLoading={overlayLoadingText} />
      )}

      <StyledReviewer
        reviewReply={(reviewReply || updatedReply) && !replyWasDeleted}
      >
        <StyledHeader>
          {enableReviewCheckBox && (
            <StyledCheckbox
              type="checkbox"
              checked={isChecked}
              onChange={handleIsChecked}
            />
          )}
          <StyledProfile>
            <StyledProfilePhoto src={reviewerProfilePhoto} alt={reviewerName} />
            <div className="profile-basic-infos">
              <h4 className="profile-name">{reviewerName}</h4>
              {' '}
              <span className="profile-location">
                {loadingLocationName ? (
                  <Loading />
                ) : (
                  <>
                    Local:
                    {' '}
                    <small>{name}</small>
                  </>
                )}
              </span>
              <div className="star-rating-and-date">
                <ul className="star-rating-list">
                  {starRating
                    .filter(
                      (star) => star <= starRatingInNumber[starRatingWord],
                    )
                    .map(() => (
                      <li key={Math.random(Math.floor())}>
                        <StyledStar />
                      </li>
                    ))}
                </ul>
                <small className="profile-created-time">
                  {formatDate(createTime)}
                </small>
              </div>
            </div>
          </StyledProfile>
          <StyledFlagIcons>
            {(reviewReply || updatedReply) && !replyWasDeleted ? (
              <IconButton
                icon="CheckCircle"
                tooltip="Avaliação respondida"
                className="check-icon-reviews-with-response"
              />
            ) : (
              <IconButton
                isError
                icon="Warning"
                tooltip="Avaliação não respondida"
              />
            )}
          </StyledFlagIcons>
        </StyledHeader>

        {filteredReviewerComment && (
          <StyledReviewerComment>
            {filteredReviewerComment}
          </StyledReviewerComment>
        )}

        {!hasReviewReply && !replyComment && !updatedReply && (
          <Button
            onClick={handleReplyComment}
            className="reviewer-button-reply"
            disabled={loading}
          >
            Responder
          </Button>
        )}
      </StyledReviewer>

      {(reviewReply || updatedReply) && !replyWasDeleted && (
        <StyledReviewReply>
          <StyledProfile>
            <StyledProfilePhoto isowner src={null} alt="Proprietário">
              <Storefront />
            </StyledProfilePhoto>

            <div className="profile-basic-infos">
              <h4 className="profile-name">Proprietário</h4>
              <small className="profile-created-time">
                {updatedReply && formatDate(updatedReply.updateTime)}
              </small>
            </div>
          </StyledProfile>
          {!editReplyComment && (
            <>
              <StyledReviewerComment className="review-replyc-comment">
                {updatedReply ? updatedReply.comment : reviewReply.comment}
              </StyledReviewerComment>
              <StyledReviewReplyButtons>
                <Button
                  onClick={handleEditReplyComment}
                  className="review-reply-button"
                  disabled={loading}
                >
                  Editar
                </Button>
                <Button
                  className="review-reply-button"
                  buttonType="red"
                  disabled={loading}
                  onClick={onRemoveReplyClicked}
                >
                  Excluir
                </Button>
              </StyledReviewReplyButtons>
            </>
          )}
        </StyledReviewReply>
      )}

      {(replyComment || editReplyComment) && (
        <ReviewReply
          stars={starRatingInNumber[starRatingWord]}
          onReplyClicked={onReplyClicked}
          editReplyComment={editReplyComment}
          cancelReply={handleReplyComment}
          reviewData={reviewData}
        />
      )}
    </StyledReview>
  );
};

export default Review;
