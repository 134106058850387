import React, { ChangeEvent, useCallback, useState } from 'react';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { debounce } from 'lodash';
import {
  Input,
  InputDatePicker,
  Select,
} from '../../../components';

import {
  starFilterTypes,
  platformTypes,
  reviewTypes,
  LocationList,
} from '../helpers';

import {
  StyledReviewFilter,
  StyledContentRow,
  StyledContentTitle,
  StyledTitle,
  StyledTooltip,
  IconTooltip,
  StyledLabel,
  StyledAccordionData,
  StyledAccordionSummary,
  StyledDataPlaceholder,
  StyledAccordionDetails,
  StyledSelectOptionData,
  StyledSelectOptionButton,
  ButtonSelectOptionDataProps,
} from './review-filter-styles';

import { TReviewFilter } from '../../../types/TNewReviews';

const ReviewFilter = ({
  platformFilter,
  setPlatformFilter,
  starTypeFilter,
  setStarTypeFilter,
  starValueFilter,
  setStarValueFilter,
  reviewTypeFilter,
  setReviewTypeFilter,
  locationFilter,
  setLocationFilter,
  wordFilter,
  setWordFilter,
  selectedDateOf,
  setSelectedDateOf,
  selectedDateTo,
  setSelectedDateTo,
}: TReviewFilter) => {
  const [availableLocationList, setAvailableLocationList] = useState([]);

  const [expanded, setExpanded] = useState<string | false>(false);
  const [isOptionDataSelect, setIsOptionDataSelect] = useState(true);

  const handleChange = (panel: string) => (event: ChangeEvent<{}>, isExpanded: boolean) => {
    setExpanded(isExpanded ? panel : false);
  };
  const test = useCallback(async () => {
    const testResponse = null;
  }, []);

  const handleChangeOfDateDebounced = useCallback(
    debounce((date) => setSelectedDateOf(date), 500),
    [],
  );
  const handleChangeToDateDebounced = useCallback(
    debounce((date) => setSelectedDateTo(date), 500),
    [],
  );

  return (
    <StyledReviewFilter>
      <StyledContentTitle>
        <StyledTitle>Avaliações</StyledTitle>

        <StyledTooltip
          title="Tooltip Avaliações"
        >
          <IconTooltip />
        </StyledTooltip>
      </StyledContentTitle>

      <StyledLabel>Filtros</StyledLabel>

      <StyledContentRow>
        <Select
          options={starFilterTypes}
          name="Classificação"
          className="star-filter"
          lightBorder
          borderRadius
          noMargin
        />

        <Select
          options={platformTypes}
          name="Plataforma"
          className="platform-filter"
          disabled
          lightBorder
          borderRadius
          noMargin
        />
      </StyledContentRow>

      <StyledContentRow>
        <Select
          options={reviewTypes}
          name="Tipos de Avaliações"
          placeholder="Tipos de Avaliações"
          className="review-type-filter"
          lightBorder
          borderRadius
          noMargin
        />

        <Select
          options={LocationList}
          name="Tipos de Avaliações"
          className="location-list"
          lightBorder
          borderRadius
          noMargin
        />
      </StyledContentRow>

      <StyledContentRow>
        <Input
          placeholder="Buscar por palavras"
          className="search-input"
          border
          inputOptions={{
            type: 'text',
            name: 'search-input',
          }}
        />

        <StyledAccordionData
          expanded={expanded === 'panel1'}
          onChange={handleChange('panel1')}
          className="accordion-data-filter"
        >
          <StyledAccordionSummary expandIcon={<ExpandMoreIcon />}>
            <StyledDataPlaceholder>Data</StyledDataPlaceholder>
          </StyledAccordionSummary>
          <StyledAccordionDetails>
            <StyledSelectOptionData>
              <StyledSelectOptionButton
                selectedOption={isOptionDataSelect ? 'SELECTED' : 'NOTSELECTED'}
                onClick={() => setIsOptionDataSelect(true)}
              >
                Da avaliação
              </StyledSelectOptionButton>

              <StyledSelectOptionButton
                selectedOption={!isOptionDataSelect ? 'SELECTED' : 'NOTSELECTED'}
                onClick={() => setIsOptionDataSelect(false)}
              >
                Da resposta
              </StyledSelectOptionButton>
            </StyledSelectOptionData>

            <InputDatePicker
              className="filter-date-picker"
              label="De"
              selectedDate={selectedDateOf}
              setSelectedDate={handleChangeOfDateDebounced}
              maxDate={selectedDateTo}
            />
            <InputDatePicker
              className="filter-date-picker"
              label="Até"
              selectedDate={selectedDateTo}
              setSelectedDate={handleChangeToDateDebounced}
              minDate={selectedDateOf}
            />
          </StyledAccordionDetails>
        </StyledAccordionData>
      </StyledContentRow>
    </StyledReviewFilter>
  );
};

export default ReviewFilter;
