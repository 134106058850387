import React from 'react';
import { isEmpty } from 'ramda';
import { useSnackbar } from 'notistack';

import { Dialog, Button, Loading } from '../../../../components';
import { MediaItem } from './media-item';

import { DeleteBatchMedia as APIDeleteBatchMedia } from '../../../../services/google-batch-modules/batch-gallery/delete-batch-media';

import {
  StyledMediaList,
  StyledMediasPopover,
  StyledButtonWrapper,
  StyledConfirmDeleteWrapper,
} from './medias-popover-style';

import type { TBatchGalleryMediaData } from '../../../../types/TBatchGallery';
import { getDataRowCellValue } from '../../helpers';
import type { EBatchPlatformStatus } from '../../../../types/TBatchGoogleModules';

type TMediasPopoverProps = {
  open: boolean;
  data: TBatchGalleryMediaData[];
  onClose: () => void;
  creationDate: string;
  galleryBatchId: string;
  galleryBatchStatus: string;
  userAccessToken: string;
  updateBatch: (shouldCollapse: boolean) => Promise<void>;
};

const MediasPopover = ({
  open,
  data,
  onClose,
  creationDate,
  galleryBatchId,
  userAccessToken,
  updateBatch,
  galleryBatchStatus,
}: TMediasPopoverProps) => {
  const { enqueueSnackbar } = useSnackbar();

  const [rowData, setRowData] = React.useState<TBatchGalleryMediaData[]>([]);
  const [selectedMedia, setSelectedMedia] = React.useState<string[]>([]);
  const [loading, setLoading] = React.useState(false);
  const [confirmDeletePrompt, setConfirmDeletePrompt] = React.useState(false);

  React.useEffect(() => {
    setRowData(data);
  }, [data, updateBatch]);

  const handleMediaSubmit = React.useCallback(async () => {
    if (isEmpty(selectedMedia)) return;

    const { status } = await APIDeleteBatchMedia({
      userAccessToken,
      galleryBatchId,
      mediaIds: selectedMedia,
      isUpdating: setLoading,
    });

    if (status === 'ERROR') {
      enqueueSnackbar('Erro ao iniciar remoção de mídia', { variant: 'error' });
      return;
    }

    await updateBatch(true);
    onClose();
  }, [selectedMedia, galleryBatchId, userAccessToken]);

  const statusIsDone = galleryBatchStatus === 'DONE';

  return (
    <Dialog open={open} onClose={onClose} title="Mídias" closeIcon>
      <>
        <StyledMediasPopover>
          <StyledMediaList mediaLength={data.length}>
            {rowData.map((item) => (
              <MediaItem
                item={item}
                selected={selectedMedia}
                setSelected={setSelectedMedia}
                creationDate={creationDate}
                key={item.id}
                lockedCheckboxes={confirmDeletePrompt}
                batchStatus={galleryBatchStatus as EBatchPlatformStatus}
              />
            ))}
          </StyledMediaList>
        </StyledMediasPopover>

        {!isEmpty(selectedMedia)
          && !confirmDeletePrompt
          && statusIsDone
          && (
            <StyledButtonWrapper>
              <Button
                onClick={() => setConfirmDeletePrompt(true)}
                className="button"
                disabled={loading}
              >
                {loading && <Loading className="is-loading" />}
                {' '}
                Excluir
              </Button>
            </StyledButtonWrapper>
          )}

        {confirmDeletePrompt && (
        <StyledButtonWrapper>
          <p>Tem certeza que deseja excluir esta(s) mídias(s)</p>
          <StyledConfirmDeleteWrapper>
            <Button
              onClick={() => setConfirmDeletePrompt(false)}
              className="button"
              disabled={loading}
              buttonType="cancel"
            >
              Cancelar
            </Button>

            <Button
              onClick={() => handleMediaSubmit()}
              className="button"
              disabled={loading}
              buttonType="red"
            >
              {loading && <Loading className="is-loading" />}
              {' '}
              Excluir
            </Button>

          </StyledConfirmDeleteWrapper>
        </StyledButtonWrapper>
        )}
      </>
    </Dialog>
  );
};

export { MediasPopover };
