import styled from 'styled-components';
import { DialogContentText, DialogActions } from '@material-ui/core';

export const StyledForm = styled.form``;

export const StyledContentText = styled(DialogContentText)`
    span{
        font-size: 1.5rem;
        font-family: Poppins;
    }
`;

export const StyledDialogActions = styled(DialogActions)``;
