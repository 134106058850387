import React from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { format } from 'date-fns';
import { MUIDataTableOptions } from 'mui-datatables';

import { isNil, is, isEmpty } from 'ramda';

import {
  BatchFilter,
  BatchHeader,
  Button,
  Table,
  Loading,
} from '../../components';

import { useAuth } from '../../hooks';

import {
  EBatchPlatformGroupType,
  EBatchPlatformStatus,
  TGetActiveGroupTypeResponse,
} from '../../types/TBatchGoogleModules';
import { EGroupType, TGetLocationsWithoutLinkedResponse } from '../../types/TLocation';

import { findBatchInfos } from '../../services/google-batch-modules';
import { TRootStateRedux } from '../../types/TRootStateRedux';
import { getLocationsWithoutLinked } from '../../services/locations/get-locations-without-linked';

import { initialTableColumns, onDownloadCSVDataBuildHeadColumns, onDownloadCSVDataMount } from './helpers';
import { BatchInfosRow } from './batch-infos-row';

import {
  StyledBatchUpdateInfoStatus,
  StyledContent,
  StyledTitleWrapper,
  StyledInfo,
} from './batch-update-info-status-styles';

import UpdateInfoDialog from './update-info-dialog';

import {
  isValidDateInput,
  parseDateInput,
} from '../batch-update-posts-status/helpers';

const BatchUpdateInfoStatus = () => {
  const history = useHistory();
  const { userAccessToken } = useAuth();

  const { activeLocationGroupId, showLocationsByGroup } = useSelector(
    (state: TRootStateRedux) => state.LocationGroupReducer,
  );
  const { activeCompanyId, showLocationsByCompany } = useSelector(
    (state: TRootStateRedux) => state.CompanyReducer,
  );

  const [batchInfosDataPage, setBatchInfosDataPage] = React.useState(0);
  const [batchInfosDataPageSize, setBatchInfosPageSize] = React.useState(10);
  const [batchInfosDataCount, setBatchInfosDataCount] = React.useState(0);
  const [selectedStatusFilter, setSelectedStatusFilter] = React.useState('');
  const [selectedTypeFilter, setSelectedTypeFilter] = React.useState<string[]>(
    [],
  );
  const [ofPeriod, setOfPeriod] = React.useState<Date | null>(null);
  const [toPeriod, setToPeriod] = React.useState<Date | null>(null);
  const [openUpdateInfoDialog, setOpenUpdateInfoDialog] = React.useState(false);

  const [disabledAddButton, setDisabledAddButton] = React.useState(false);

  const updateTypeOptions = [
    { id: 1, name: 'Informações Básicas' },
    { id: 2, name: 'Links' },
    { id: 3, name: 'Hórarios de funcionamento' },
    { id: 4, name: 'Datas Especiais' },
  ];

  const tableColumns = React.useMemo(
    () => [...initialTableColumns],
    [initialTableColumns],
  );

  const activeGroupType = React.useMemo((): TGetActiveGroupTypeResponse => {
    if (showLocationsByCompany) {
      return {
        type: 'COMPANY' as EBatchPlatformGroupType,
        id: activeCompanyId,
      };
    }
    return {
      type: 'LOCATIONS_GROUP' as EBatchPlatformGroupType,
      id: activeLocationGroupId,
    };
  }, [
    showLocationsByGroup,
    showLocationsByCompany,
    activeLocationGroupId,
    activeCompanyId,
  ]);

  const dataTableOptions = React.useMemo(
    (): MUIDataTableOptions => ({
      count: batchInfosDataCount,
      search: false,
      page: batchInfosDataPage,
      selectableRowsHideCheckboxes: true,
      onDownload: (buildHead, buildBody, columns, data) => {
        const columnsToExport = onDownloadCSVDataBuildHeadColumns(columns);
        const dataToExport = data.map((row: any, index: number) => {
          const dataFormatted = onDownloadCSVDataMount(row.data);
          return {
            index,
            data: dataFormatted,
          };
        });

        return `\uFEFF${buildHead(columnsToExport)}${buildBody(dataToExport)}`;
      },
    }),
    [batchInfosDataCount, batchInfosDataPage],
  );

  const [batchInfosData, setBatchInfosData] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const [unlinkedLocalsLoading, setUnlinkedLocalsLoading] = React.useState(false);
  const [locationsWithoutLinkedList, setLocationsWithoutLinkedList] = React.useState<TGetLocationsWithoutLinkedResponse>(null);

  const getBatchInfosData = React.useCallback(async () => {
    let requestBody: any = {
      groupType: activeGroupType.type,
      groupTypeId: activeGroupType.id,
      userAccessToken,
      isFetching: setLoading,
      page: batchInfosDataPage,
      pageSize: batchInfosDataPageSize,
      status: selectedStatusFilter as EBatchPlatformStatus,
    };

    if (isValidDateInput(ofPeriod)) {
      requestBody = {
        ...requestBody,
        startDate: parseDateInput({ input: ofPeriod, dayPart: 'start' }),
      };
    }

    if (isValidDateInput(toPeriod)) {
      requestBody = {
        ...requestBody,
        endDate: parseDateInput({ input: toPeriod, dayPart: 'end' }),
      };
    }

    if (!isNil(selectedTypeFilter) && selectedTypeFilter.length > 0) {
      requestBody = {
        ...requestBody,
        updateType: JSON.stringify(selectedTypeFilter),
      };
    }

    const { status: batchInfosResponseStatus, data: batchInfosResponseData } = await findBatchInfos(requestBody);

    if (batchInfosResponseStatus === 'ERROR') return;
    if (!is(Array, batchInfosResponseData)) return;

    const [batchInfos, batchInfosCount]: any = batchInfosResponseData;

    const batchInfosNormalized = batchInfos.map((batchInfoItem: any) => [
      { columnRef: 'id', visible: false, value: batchInfoItem.id },
      {
        columnRef: 'applied-informations',
        visible: true,
        value: 'Visualizar informações',
      },
      {
        columnRef: 'updated-at',
        visible: true,
        value: format(
          new Date(batchInfoItem.updated_at),
          'dd/MM/yyyy HH:mm:ss',
        ),
      },
      {
        columnRef: 'created-at',
        visible: true,
        value: format(
          new Date(batchInfoItem.created_at),
          'dd/MM/yyyy HH:mm:ss',
        ),
      },
      {
        columnRef: 'status',
        visible: true,
        value: batchInfoItem.status as EBatchPlatformStatus,
      },
      {
        columnRef: 'data-infos',
        visible: false,
        value: batchInfoItem.data_infos,
      },
    ]);

    setDisabledAddButton(!isEmpty(batchInfos.filter((batchInfo: any) => batchInfo.status === 'PENDING' || batchInfo.status === 'PROCESSING')));

    setBatchInfosDataCount(batchInfosCount);
    setBatchInfosData(batchInfosNormalized);
  }, [
    activeGroupType,
    batchInfosDataPage,
    batchInfosDataPageSize,
    selectedStatusFilter,
    selectedTypeFilter,
    ofPeriod,
    toPeriod,
  ]);

  React.useEffect(() => {
    getBatchInfosData();
  }, [
    getBatchInfosData,
  ]);

  const handleChangeFilter = (filter: any, setFilter: React.Dispatch<React.SetStateAction<any>>) => {
    setBatchInfosDataPage(0);
    setFilter(filter);
  };

  const getLocationWithoutLinked = React.useCallback(async () => {
    const group = showLocationsByCompany ? EGroupType.COMPANY : EGroupType.LOCATIONS_GROUP;
    const groupId = showLocationsByCompany ? activeCompanyId : activeLocationGroupId;

    const locationsWithoutLinked = await getLocationsWithoutLinked({
      group,
      groupId,
      userAccessToken,
      isFetching: setUnlinkedLocalsLoading,
    });

    if (isNil(locationsWithoutLinked)) return;

    if (locationsWithoutLinked.count === 0) history.push('/batch-update-info-form');

    setLocationsWithoutLinkedList(locationsWithoutLinked);
    setOpenUpdateInfoDialog(true);
  }, [
    activeCompanyId,
    activeLocationGroupId,
    showLocationsByGroup,
    showLocationsByCompany,
  ]);

  return (
    <StyledBatchUpdateInfoStatus>
      <BatchHeader />
      <StyledContent>
        <StyledTitleWrapper>
          <Button
            onClick={() => getLocationWithoutLinked()}
            className="info-nav-button"
            disabled={disabledAddButton || unlinkedLocalsLoading}
          >
            {unlinkedLocalsLoading ? <Loading /> : 'Atualizar Informações'}
          </Button>
        </StyledTitleWrapper>

        <h2>Filtros</h2>
        <StyledInfo>
          <BatchFilter
            typeLabel="Tipo de atualização"
            filterType={{
              type: 'UPDATE',
              updateTypeOptions,
            }}
            selectedStatusFilter={selectedStatusFilter}
            setSelectedStatusFilter={(filter) => handleChangeFilter(filter, setSelectedStatusFilter)}
            selectedTypeFilter={selectedTypeFilter}
            setSelectedTypeFilter={(filter) => handleChangeFilter(filter, setSelectedTypeFilter)}
            ofPeriod={ofPeriod}
            setOfPeriod={(filter) => handleChangeFilter(filter, setOfPeriod)}
            toPeriod={toPeriod}
            setToPeriod={(filter) => handleChangeFilter(filter, setToPeriod)}
          />
        </StyledInfo>

        <StyledInfo>
          <Table
            loading={loading}
            setPage={setBatchInfosDataPage}
            setPageSize={setBatchInfosPageSize}
            data={batchInfosData}
            title="Listagem das Informações em Massa"
            columns={tableColumns}
            options={dataTableOptions}
            row={(rowData, rowDataIndex, rowIndex) => (
              <BatchInfosRow
                rowData={rowData}
                rowDataIndex={rowDataIndex}
                rowIndex={rowIndex}
                dataTableOptions={dataTableOptions}
              />
            )}
          />
        </StyledInfo>
        {openUpdateInfoDialog && <UpdateInfoDialog open={openUpdateInfoDialog} setOpen={setOpenUpdateInfoDialog} locationsWithoutLinkedList={locationsWithoutLinkedList} />}
      </StyledContent>
    </StyledBatchUpdateInfoStatus>
  );
};

export default BatchUpdateInfoStatus;
