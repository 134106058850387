import styled, { css } from 'styled-components';
import Button from '@material-ui/core/Button';
import { lighten, shade } from 'polished';

import { TButtonBaseProps } from '../../types/TButton';

export const StyledButton = styled(Button)<TButtonBaseProps>`
  border: none;
  border-radius: 0.5rem;
  min-height: 4.1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${(props) => props.theme.colors.primary} !important;
  color: ${(props) => props.theme.colors.white} !important;
  position: relative;
  transition: 0.3s;
  padding: 0 2rem;
  font-size: 1.2rem;
  text-transform: uppercase;
  font-weight: normal;
  box-shadow: 0 6px  ${(props) => shade(0.1, props.theme.colors.primary)} !important;

  &:disabled {
    background: ${(props) => lighten(0.2, props.theme.colors.grey)} !important;
    box-shadow: 0 4px  ${(props) => shade(0.3, props.theme.colors.grey)} !important;
  }

  .MuiButton-label {
    font-size: 1.2rem;
    font-weight: normal;
  }

  svg {
    margin-right: 0.5rem;
  }

  &:hover,
  &:focus {
    background: ${(props) => props.theme.colors.primary} !important;
    box-shadow: 0 4px  ${(props) => shade(0.2, props.theme.colors.primary)} !important;
    top: 0.2rem;
    color: ${(props) => props.theme.colors.white};
    outline: none;
    text-decoration:none;
  }

  ${(props) => props.buttonType === 'secondary' && css`
    background: ${props.theme.colors.secondary} !important;
    box-shadow: 0 6px  ${shade(0.1, props.theme.colors.secondary)} !important;

    &:hover,
    &:focus {
      background: ${props.theme.colors.secondary} !important;
      box-shadow: 0 4px  ${shade(0.2, props.theme.colors.secondary)} !important;
      color: ${props.theme.colors.white};
    }
  `}

  ${(props) => props.buttonType === 'warning' && css`
    background: ${props.theme.colors.tertiary} !important;
    box-shadow: 0 6px  ${shade(0.1, props.theme.colors.tertiary)} !important;

    &:hover,
    &:focus {
      background: ${props.theme.colors.tertiary} !important;
      box-shadow: 0 4px  ${shade(0.2, props.theme.colors.tertiary)} !important;
      color: ${props.theme.colors.white};
    }
  `}

  ${(props) => props.buttonType === 'red' && css`
    background: ${props.theme.colors.red} !important;
    box-shadow: 0 6px  ${shade(0.1, props.theme.colors.red)} !important;

    &:hover,
    &:focus {
      background: ${props.theme.colors.red} !important;
      box-shadow: 0 4px  ${shade(0.2, props.theme.colors.red)} !important;
      color: ${props.theme.colors.white};
    }
  `}

  ${(props) => props.buttonType === 'cancel' && css`
    background: ${props.theme.colors.grey} !important;
    box-shadow: 0 6px  ${shade(0.1, props.theme.colors.grey)} !important;

    &:hover,
    &:focus {
      background: ${props.theme.colors.grey} !important;
      box-shadow: 0 4px  ${shade(0.2, props.theme.colors.grey)} !important;
      color: ${props.theme.colors.white};
    }
  `}
`;
