import React from 'react';

import { TLocationHeader } from '../../types/TLocationHeader';

import LocationNavBar from '../location-nav-bar';

import {
  StyledLocationHeader,
} from './location-header-styles';

const LocationHeader = ({
  children,
  hideDashboard = false,
  linkedLocation = false,
  isLastSyncHistory = false,
}: TLocationHeader) => (
  <StyledLocationHeader as="div">
    <LocationNavBar
      hideDashboard={hideDashboard}
      linkedLocation={linkedLocation}
      isLastSyncHistory={isLastSyncHistory}
    />
    {children}
  </StyledLocationHeader>
);

export default LocationHeader;
