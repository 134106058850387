import axios from 'axios';

type TGetUserConnections = {
  accessToken: string;
  userId: number;
  page?: number;
  pageSize?: number;
  query?: string;
  setTokenLikeExpired?: () => void;
};

export default async function getUserConnections({
  accessToken = '',
  userId = 0,
  page = 0,
  pageSize = 10,
  query = '',
  setTokenLikeExpired,
}: TGetUserConnections) {
  try {
    const { data } = await axios.get(
      `${process.env.REACT_APP_CONNECTIONS_API_URL}/user/${userId}?page=${page}&pageSize=${pageSize}&query=${query}`,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      },
    );

    return data;
  } catch (err: any) {
    if (err.response?.status === 401 && setTokenLikeExpired) setTokenLikeExpired();

    return null;
  }
}
