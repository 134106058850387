import styled from 'styled-components';
import { Paper } from '@material-ui/core';

export const StyledAddLocationAccessLevelProfile = styled.div`
    display: flex;
    flex-direction: column;

    h3 {
        font-weight: 700;
        font-size: 1.5rem;
        margin-bottom: 2rem;
    }
`;

export const StyledFieldsContainer = styled(Paper)`
    display: flex;
    flex-direction: column;
    padding: 2rem;
`;

export const StyledFields = styled.div`
    padding: 0;
    display: flex;
    flex-direction: column;

    .field {
        margin-bottom: 2rem;

        &:last-of-type {
            margin-bottom: 3rem;
        }
    }

    .button-add {
        width: 100%;
        max-width: 20rem;
        margin-left: auto;
    }
`;

export const StyledCheckboxGroup = styled.div`
    display: grid;
    grid-template-columns: auto auto auto;

    span{
        font-family: Poppins;
        font-size: 1.3rem;
    }
`;

export const StyledButtonsGroup = styled.div`
    display: flex;
    margin-left: auto;

    button {
        margin-right: 1rem;

        &:last-of-type {
            margin-right: 0;
        }
    }
`;
