import { EBatchPlatformStatus } from '../../types/TBatchGoogleModules';
import { TBatchType } from '../../types/TBatchUpdate';
import { TIconByStatusPropsValue } from './types';

export const batchEntityNames: Record<TBatchType, string> = {
  info: 'informações',
  post: 'postagens',
  gallery: 'galeria',
  service: 'serviços',
};

export const iconByStatus: Record<EBatchPlatformStatus, TIconByStatusPropsValue> = {
  CANCELING: {
    tooltip: (batchEntityName) => `Atualização de ${batchEntityName} em processo de cancelamento.`,
    icon: 'RemoveCircle',
    label: 'Cancelando',
    isError: true,
    tooltipVariant: 'red',
  },
  CANCELLED: {
    tooltip: (batchEntityName) => `Atualização de ${batchEntityName} foi cancelada.`,
    icon: 'RemoveCircle',
    label: 'Cancelado',
    isError: true,
    tooltipVariant: 'red',
  },
  DONE: {
    tooltip: (batchEntityName) => `Atualização de ${batchEntityName} concluída`,
    icon: 'CheckCircle',
    label: 'Concluído',
    isSuccess: true,
    tooltipVariant: 'green',
  },
  ERROR: {
    tooltip: (batchEntityName) => `Atualização de ${batchEntityName} concluída com erro`,
    icon: 'Error',
    label: 'Erro',
    isWarning: true,
    tooltipVariant: 'yellow',
  },
  ERROR_REMOVING: {
    tooltip: (batchEntityName) => `Erro na remoção de ${batchEntityName}`,
    icon: 'Error',
    label: 'Error',
    isWarning: true,
    tooltipVariant: 'yellow',
  },
  PENDING: {
    tooltip: (batchEntityName) => `Atualização de ${batchEntityName} aguardando processamento`,
    icon: 'History',
    label: 'Pendente',
  },
  PROCESSING: {
    tooltip: (batchEntityName) => `Atualização de ${batchEntityName} aguardando processamento`,
    icon: 'History',
    label: 'Processando',
  },
  REJECTED: {
    tooltip: (batchEntityName) => `Atualização de ${batchEntityName} foi rejeitada pela plataforma. Verifique seu texto e imagem e tente novamente.`,
    icon: 'RemoveCircle',
    label: 'Rejeitado',
    isError: true,
    tooltipVariant: 'red',
  },
  REMOVED: {
    tooltip: (batchEntityName) => `Atualização de ${batchEntityName} removida`,
    icon: 'Cancel',
    label: 'Removido',
    isError: true,
    tooltipVariant: 'red',
  },
  REMOVING: {
    tooltip: (batchEntityName) => `Atualização de ${batchEntityName} em processo de remoção`,
    icon: 'RemoveCircle',
    label: 'Removendo...',
    isWarning: true,
    tooltipVariant: 'yellow',
  },
  REMOVING_MEDIA: {
    tooltip: (batchEntityName) => 'Removendo mídias dos locais',
    icon: 'History',
    label: 'Removendo Mídia',
  },
};
