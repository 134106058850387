import { MUIDataTableColumn } from 'mui-datatables';
import { isNil, prop, has } from 'ramda';
import { EBatchPlatformStatus, TBatchStatusLabel } from '../../types/TBatchGoogleModules';

import type { TGetDataRowCellValue, TUpdateRowDataByColumn } from '../../types/TBatchUpdate';

export const initialTableColumns: MUIDataTableColumn[] = [
  {
    name: 'expandable',
    label: 'Expandir',
    options: {
      sort: false,
      viewColumns: false,
    },
  },
  {
    name: 'id',
    label: 'Id',
    options: { display: false },
  },
  {
    name: 'applied-informations',
    label: 'Informações atualizadas',
    options: { sort: false },
  },
  {
    name: 'updated-at',
    label: 'Última atualização',
    options: { sort: false },
  },
  {
    name: 'created-at',
    label: 'Data de Criação',
    options: { sort: false },
  },
  {
    name: 'status',
    label: 'Status',
    options: { sort: false },
  },
  {
    name: 'actions',
    label: 'Ações',
    options: { sort: false, filter: false, empty: true },
  },
];

export const getDataRowCellValue = ({
  dataRowCell,
  columnRef,
}: TGetDataRowCellValue) => {
  const currentRowDataCell = dataRowCell.find(
    (rowDataCell: any) => rowDataCell && rowDataCell.columnRef === columnRef,
  );

  return !isNil(currentRowDataCell) ? currentRowDataCell.value : '';
};

export const batchInfosConstants: Record<string, any> = {
  BATCH_INFOS_FETCH_STATUS_SETINTERVAL: 300000,
  BATCH_INFOS_LOCATION_FETCH_STATUS_SETINTERVAL: 150000,
};

export const updateRowDataByColumn = ({
  rowData,
  payload,
}: TUpdateRowDataByColumn): any[] => rowData.map((cellData: any) => {
  if (!isNil(cellData) && has(cellData.columnRef, payload)) {
    return {
      ...cellData,
      value: prop(cellData.columnRef, payload),
    };
  }

  return cellData;
});

export const batchInfosRowActions = ['Cancelar'];

export const onDownloadCSVDataBuildHeadColumns = (dataColumnToMount: any) => dataColumnToMount
  .filter((dataColumn: any) => dataColumn.name !== 'expandable'
    && dataColumn.name !== 'actions'
    && dataColumn.display === 'true');

export const batchGalleryCategories: Partial<Record<EBatchPlatformStatus, TBatchStatusLabel>> = {
  DONE: 'Concluído',
  PENDING: 'Pendente',
  PROCESSING: 'Processando',
  CANCELLED: 'Cancelado',
  CANCELING: 'Cancelando',
  REJECTED: 'Rejeitado',
  REMOVED: 'Removido',
  REMOVING: 'Removendo',
  REMOVING_MEDIA: 'Removendo Mídia',
  ERROR: 'Erro',
};

export const onDownloadCSVDataMount = (dataToMount: any) => dataToMount
  .filter((dataColumn: any) => !isNil(dataColumn))
  .map((dataColumn: any) => {
    if (dataColumn.columnRef === 'status') {
      return {
        ...dataColumn,
        value: batchGalleryCategories[dataColumn.value as EBatchPlatformStatus],
      };
    }

    if (dataColumn.columnRef === 'applied-informations') {
      const { value } = dataToMount.find((data: any) => data.columnRef === 'data-infos');

      const dictionary = {
        basic_infos: 'Informações básicas',
        working_hours: 'Horário de funcionamento',
        links: 'Links',
        special_dates: 'Datas Especiais',
      };

      const dataValues: string[] = [];
      Object.keys(value).forEach((key: string) => {
        dataValues.push(dictionary[key as keyof typeof dictionary]);
      });

      return {
        ...dataColumn,
        value: dataValues.join(', '),
      };
    }

    return dataColumn;
  })
  .filter((dataColumn: any) => dataColumn.visible === true)
  .map((dataColumn: any) => dataColumn.value);
