import React, { useEffect, useState } from 'react';
import * as R from 'ramda';

import cloneDeep from 'lodash/cloneDeep';
import WorkingHoursContext from '../../working-hours-context';

import TimeRange from '../time-range';
import ReplicatedPopover from './replicated-popover';
import Is24HoursButton from '../../is-24-hours-button';

import {
  InputSwitch,
} from '../../../../../../components';

import {
  StyledTimeOfDay,
  StyledHoursDayName,
  StyledTimes,
  StyledTimesRow,
  StyledReplicate,
  StyledArrowDownward,
  Styled24HourReplicateWrapper,
  StyledOverlay,
  StyledHoursWrapper,
} from './time-of-day-styles';

const TimeOfDay = ({
  dayTitle = 'Dia da semana',
  isOpen = false,
  times = [],
  isReplicated,
  changeIsOpenDay,
  changeIs24Hours,
  changeWillUpdate,
  is24hours,
  dropDownToBottom,
  willUpdate,
}) => {
  const [switchIsOpen, setSwitchIsOpen] = useState(isOpen);
  const [switchWillUpdate, setSwitchWillUpdate] = useState(willUpdate);
  const [currentTimes, setCurrentTimes] = useState(times);
  const [currentIs24Hours, setcurrentIs24Hours] = useState(is24hours);
  const [anchorEl, setAnchorEl] = useState(null);
  const [replicatedDaysSelected, setReplicatedDaysSelected] = useState([]);

  const {
    setWorkingHoursPerDay,
    workingHoursPerDay,
  } = React.useContext(WorkingHoursContext);

  const replicatedPopoverOpen = Boolean(anchorEl);
  const replicatedPopoverId = replicatedPopoverOpen ? 'replicated-popover' : undefined;

  const handleIsOpen = (value) => {
    setSwitchIsOpen(value);

    changeIsOpenDay({
      workingHoursStateData: setWorkingHoursPerDay,
      dayTarget: dayTitle,
      isOpen: value,
    });
  };

  const handleIsChangeIs24Hours = () => {
    setcurrentIs24Hours(!currentIs24Hours);
    changeIs24Hours({
      workingHoursStateData: setWorkingHoursPerDay,
      dayTarget: dayTitle,
      is24hours: !currentIs24Hours,
    });
  };

  const handleWillUpdate = () => {
    if (switchWillUpdate === true) handleIsOpen(false);

    changeWillUpdate({
      workingHoursStateData: setWorkingHoursPerDay,
      dayTarget: dayTitle,
      willUpdate: !switchWillUpdate,
    });

    setSwitchWillUpdate(!switchWillUpdate);
  };

  const handleOpenReplicatedPopover = e => {
    setReplicatedDaysSelected([]);
    setAnchorEl(e.currentTarget);
  };

  const handleReplicatedApply = (dayToReplicate) => {
    if (R.isEmpty(replicatedDaysSelected)) return;

    const workingHoursDayToReplicated = workingHoursPerDay.find(
      (value) => value.day_title === dayToReplicate,
    );

    replicatedDaysSelected.forEach(replicatedDaySelected => {
      setWorkingHoursPerDay(prevWorkingHours => prevWorkingHours.map(workingHoursDay => {
        if (workingHoursDay.day_title === replicatedDaySelected) {
          const { times: timesToReplicate } = workingHoursDayToReplicated;
          return {
            ...workingHoursDayToReplicated,
            is_replicated: false,
            day_title: workingHoursDay.day_title,
            will_update: true,
            times: cloneDeep(
              timesToReplicate,
            ),
          };
        }
        return workingHoursDay;
      }));
    });
  };

  useEffect(() => {
    if (replicatedPopoverOpen) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style = '';
    }
  }, [anchorEl]);

  useEffect(() => {
    setSwitchWillUpdate(willUpdate);
    handleIsOpen(isOpen);
    setcurrentIs24Hours(is24hours);
    setCurrentTimes(times);
  }, [willUpdate, isOpen, is24hours, times]);

  return (
    <StyledTimeOfDay>
      <StyledHoursDayName
        className="day-title"
      >
        <p>{dayTitle}</p>

        <InputSwitch
          alignRow
          changeChecked={handleWillUpdate}
          checked={switchWillUpdate}
          className="white-label"
          secondarySwitch
        />
      </StyledHoursDayName>

      <StyledHoursWrapper>
        {!switchWillUpdate && (
          <>
            <p>Não será atualizado</p>
            <StyledOverlay />
          </>
        )}

        {switchWillUpdate && (
          <InputSwitch
            alignRow
            changeChecked={() => handleIsOpen(!switchIsOpen)}
            checked={switchIsOpen}
            label={switchIsOpen ? 'Aberto' : 'Fechado'}
            className="input-switch"
          />
        )}

        {switchIsOpen && (
        <Styled24HourReplicateWrapper>
          <Is24HoursButton
            isActive={currentIs24Hours}
            onClick={handleIsChangeIs24Hours}
            className="button-24h"
          />

          {(switchIsOpen && isReplicated) && (
          <StyledReplicate
            aria-describedby={replicatedPopoverId}
            onClick={handleOpenReplicatedPopover}
          >
            <StyledArrowDownward />
            Replicar
          </StyledReplicate>
          )}
        </Styled24HourReplicateWrapper>
        )}

        {(switchIsOpen && !currentIs24Hours) && (
        <StyledTimes
          isSeveralTimes={currentTimes.length >= 1}
        >
            {currentTimes.filter(time => time.is_deleted !== true).map(time => (
              <StyledTimesRow
                key={time.id}
              >
                <TimeRange
                  open={time.open}
                  close={time.close}
                  timeRange={time.id}
                  dropDownToBottom={dropDownToBottom}
                  setIsOpen={setSwitchIsOpen}
                  dayTitle={dayTitle}
                  changeIsOpenDay={changeIsOpenDay}
                  timesCount={currentTimes.filter(timeItem => timeItem.is_deleted !== true).length}
                />
              </StyledTimesRow>
            ))}
        </StyledTimes>
        )}

        <ReplicatedPopover
          id={replicatedPopoverId}
          open={replicatedPopoverOpen}
          anchorEl={anchorEl}
          onClose={() => setAnchorEl(null)}
          handleReplicatedApply={() => handleReplicatedApply(dayTitle)}
          handleSetSelectedDays={setReplicatedDaysSelected}
          replicatedDaysSelected={replicatedDaysSelected}
        />
      </StyledHoursWrapper>
    </StyledTimeOfDay>
  );
};

export default TimeOfDay;
