import React from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { Creators as LocationActions } from '../../redux/ducks/location';

import { useCompanyLocations, useLocationsGroup } from '../../hooks';

import DropDownDialog from '../dropdown-dialog';

import { getLocationByCompanyOrGroup } from './helpers';

const DropdownLocationsDialog = () => {
  const reduxDispatch = useDispatch();

  const locationsGroup = useLocationsGroup({ isLocationDropDown: true });
  const companyLocations = useCompanyLocations({ isLocationDropDown: true });

  const { showLocationsByGroup } = useSelector(state => state.LocationGroupReducer);
  const { showLocationsByCompany } = useSelector(state => state.CompanyReducer);

  const handleChangeLocation = React.useCallback(async (activeLocationId) => {
    if (showLocationsByCompany) {
      companyLocations.setIsLoading(true);

      const [newActiveLocation] = companyLocations.userCompanyLocations.filter(
        (locationItem) => locationItem.id === activeLocationId,
      );

      companyLocations.setCurrentActiveLocation(newActiveLocation);
      reduxDispatch(LocationActions.setActiveLocation(newActiveLocation));
    }

    if (showLocationsByGroup) {
      locationsGroup.setIsLoading(true);

      const [newActiveLocation] = locationsGroup.locationsGroup.filter(
        (locationItem) => locationItem.id === activeLocationId,
      );

      locationsGroup.setCurrentActiveLocation(newActiveLocation);
      reduxDispatch(LocationActions.setActiveLocation(newActiveLocation));
    }

    setTimeout(() => {
      locationsGroup.setIsLoading(false);
      companyLocations.setIsLoading(false);
    }, 1500);
  }, [
    showLocationsByCompany,
    showLocationsByGroup,
    companyLocations,
    locationsGroup,
  ]);

  const dropDownOptions = React.useMemo(() => getLocationByCompanyOrGroup({
    byCompany: showLocationsByCompany,
    byGroup: showLocationsByGroup,
    companyLocations,
    locationsGroup,
  }), [
    companyLocations,
    locationsGroup,
    showLocationsByCompany,
    showLocationsByGroup,
  ]);

  return (
    <DropDownDialog
      options={dropDownOptions.options}
      currentOption={dropDownOptions.currentOption}
      icon="Store"
      title="Locais"
      onChange={handleChangeLocation}
      optionDisableIfNotHaveLocations
      page={dropDownOptions.pageIndex}
      setPage={dropDownOptions.setPageIndex}
      pageQuantity={dropDownOptions.pageQuantity}
      setQuery={dropDownOptions.setPageQuery}
      isLoading={dropDownOptions.isLoading}
      showPagination
    />
  );
};

export default DropdownLocationsDialog;
