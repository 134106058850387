import styled from 'styled-components';
import { Paper } from '@material-ui/core';

export const StyledDescription = styled.div`
    display: flex;
    flex-direction: column;
`;

export const StyledForm = styled(Paper)`
    display: grid;
    grid-template-columns: 1fr;
    row-gap: 2rem;
    padding: 2rem;
`;

export const StyledTitle = styled.h3`
  font-weight: 700;
  font-size: 1.5rem;
  margin-bottom: 0.5rem;
`;

export const StyledDisclaimer = styled.small`
  font-size: 1.2rem;
  display: inline-block;
  margin-bottom: 2rem;
`;
