import styled from 'styled-components';
import { Paper } from '@material-ui/core';
import { devices } from '../../../../assets/styles/devices';

export const StyledActions = styled(Paper)`
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    column-gap: 2rem;
    row-gap: 2rem;
    align-items: center;
    width: 100%;
    padding: 2rem;

    .list-by-select {
        margin-bottom: 0;
    }

    @media ${devices.tabletLandscape} {
      grid-template-columns: repeat(3, 1fr);
    }
`;

export const StyledButtonWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;

    .icon-info{
        margin-right: 1rem;
        
        .icon{
            width: 3rem;
            height: 3rem;
        }
    }
`;
