import styled from 'styled-components';

export const StyledReviews = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  .reviews-empty-feedback {
    padding: 2rem;
    background: ${(props) => props.theme.colors.white};
    color: ${(props) => props.theme.colors.red};
  }
`;

export const StyledContent = styled(StyledReviews)`
  padding: 3rem 2rem;
  align-items: center;
  max-width: 90rem;
  width: 100%;
  margin: 0 auto;
`;

export const StyledLoadingInfos = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  .loading-infos-label {
    margin-left: 1rem;
  }
`;
