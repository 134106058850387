import axios from 'axios';

export default async function getUserCompanyGroups({
  accessToken,
  userId,
  page = 0,
  pageSize = 20,
  query = '',
  setTokenLikeExpired,
}) {
  try {
    const { data } = await axios.get(
      `${process.env.REACT_APP_COMPANY_GROUPS_API_URL}/user-company-groups/${userId}?page=${page}&pageSize=${pageSize}&query=${query}`,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      },
    );

    return data;
  } catch (err) {
    if (err.response?.status === 401 && setTokenLikeExpired) setTokenLikeExpired();

    return null;
  }
}
