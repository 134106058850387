import axios from 'axios';

export default async function createMenu(
  accessToken,
  name,
  description,
  slug,
  groups,
  functions,
  created_by = null,
) {
  const requestOptions = {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  };

  try {
    const { data } = await axios.post(
      `${process.env.REACT_APP_MENUS_API_URL}`,
      created_by
        ? {
          name, description, slug, groups, functions, created_by,
        }
        : {
          name, description, slug, groups, functions,
        },
      requestOptions,
    );

    console.log(data);
    return data;
  } catch (err) {
    console.log(err);
    return err;
  }
}
