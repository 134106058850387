import React from 'react';
import { useSelector } from 'react-redux';
import { useHistory, Redirect } from 'react-router-dom';
import { Button, IconButton } from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';

import {
  Can,
  DataTable,
} from '../../components';

import getUsers from '../../services/users/getUsers';
import getUserName from '../../services/users/getUserName';
import getHandlers from '../../services/handlers/getHandlers';
import deleteHandler from '../../services/handlers/deleteHandler';

import formatDate from '../../utils/formatDate';

const HandlerList = () => {
  const history = useHistory();
  const [loading, setLoading] = React.useState(false);
  const [handlers, setHandlers] = React.useState([]);

  const [searchQuery, setSearchQuery] = React.useState('');
  const [page, setPage] = React.useState(0);
  const [pageSize, setPageSize] = React.useState(10);
  const [dataCount, setDataCount] = React.useState(0);

  const [handlerGotDeleted, setHandlerGotDeleted] = React.useState(true);
  const { user } = useSelector((state) => state.AuthReducer);

  const columns = [
    { name: 'Id', options: { display: false, sort: false } },
    { name: 'Nome', options: { sort: false } },
    { name: 'Data da criação', options: { sort: false } },
    { name: 'Data da última atualização', options: { sort: false } },
  ];

  const data = [];
  for (let i = 0; i < handlers.length; i++) {
    data.push([
      handlers[i].id,
      handlers[i].name,
      handlers[i].date_created,
      handlers[i].date_updated,
    ]);
  }

  const handlerList = async () => {
    const handlersData = await getHandlers(user.accessToken, page, pageSize, searchQuery);
    setHandlers(handlersData[0]);
    setDataCount(handlersData[1]);
  };

  React.useEffect(() => {
    setLoading(true);
    handlerList();
    setLoading(false);
  }, [handlerGotDeleted, searchQuery, page, pageSize]);

  const onAddHandlerClicked = () => {
    history.push('/handlers/create');
  };

  const onUpdateHandlerClicked = async (id) => {
    history.push(`/handlers/update/${id}`);
  };

  const onDeleteHandlerClicked = async (id) => {
    const deleteHandlerResponse = await deleteHandler(user.accessToken, id);
    if (deleteHandlerResponse) handlerList();
  };

  return (
    <Can
      perform="Handlers/List"
      yes={() => (
        <div style={{ padding: '1em' }}>
          <div className="create-function-button">
            <Button
              variant="contained"
              startIcon={<FontAwesomeIcon icon={faPlus} />}
              onClick={onAddHandlerClicked}
              style={{
                marginBottom: '1em',
                color: 'white',
                backgroundColor: '#31ab70',
                fontFamily: 'Poppins',
                fontWeight: 'bold',
              }}
            >
              Criar Handler
            </Button>
          </div>
          <DataTable
            count={dataCount}
            page={page}
            setPage={setPage}
            pageSize={pageSize}
            setPageSize={setPageSize}
            searchQuery={searchQuery}
            setSearchQuery={setSearchQuery}
            title="Handlers"
            columns={columns}
            data={data}
            onUpdateClicked={onUpdateHandlerClicked}
            onDeleteClicked={onDeleteHandlerClicked}
            addRegisterLabel="Criar Handler"
            addRegisterLink="/handlers/create"
          />
        </div>
      )}
      no={() => <Redirect to="/main" />}
    />
  );
};

export default HandlerList;
