import axios from 'axios';

export default async function getMenus({
  accessToken,
  setTokenLikeExpired,
  page = 0,
  pageSize = 10, query = '',
}) {
  try {
    const requestOptions = {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    };

    const { data } = await axios.get(
      `${process.env.REACT_APP_MENUS_API_URL}?page=${page}&pageSize=${pageSize}&query=${query}`,
      requestOptions,
    );

    console.log(data);
    return data;
  } catch (err) {
    if (err.response?.status === 401 && setTokenLikeExpired) return setTokenLikeExpired();

    return err;
  }
}
