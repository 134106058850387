import React from 'react';
import { useSelector } from 'react-redux';
import { useHistory, Redirect } from 'react-router-dom';
import { Button } from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';

import {
  Can,
  DataTable,
} from '../../components';

import getUserName from '../../services/users/getUserName';
import getUsers from '../../services/users/getUsers';
import getCompanies from '../../services/companies/getCompanies';
import deleteCompany from '../../services/companies/deleteCompany';

import formatDate from '../../utils/formatDate';

const CompanyList = () => {
  const history = useHistory();
  const [companies, setCompanies] = React.useState([]);
  const [users, setUsers] = React.useState([]);
  const { user } = useSelector((state) => state.AuthReducer);

  const columns = [
    { name: 'Id', options: { display: false } },
    { name: 'Nome' },
    {
      name: 'Localizações',
      options: {
        customBodyRender(value, tableMeta, updateValue) {
          return (
            <div className="items-table-cell">
              {value.map((element) => (
                <text key={element.id}>{`- ${element.name}`}</text>
              ))}
            </div>
          );
        },
      },
    },
    { name: 'Data da Criação' },
    { name: 'Data da última atualização' },
    { name: 'Criado por' },
  ];

  const data = [];

  for (let i = 0; i < companies.length; i++) {
    data.push([
      companies[i].id,
      companies[i].name,
      companies[i].locations,
      formatDate(companies[i].date_created),
      formatDate(companies[i].date_updated),
      getUserName(users, companies[i].created_by),
    ]);
  }

  React.useEffect(() => {
    const fetchData = async () => {
      setCompanies(await getCompanies({ accessToken: user.accessToken }));
      setUsers(await getUsers(user.accessToken));
    };
    fetchData();
  }, []);

  const onAddCompanyClicked = () => {
    history.push('/companies/create');
  };

  const onUpdateCompanyClicked = (id) => {
    history.push(`/companies/update/${id}`);
  };

  const onDeleteCompanyClicked = async (id) => {
    await deleteCompany(user.accessToken, id);
  };

  return (
    <div style={{ padding: '1em' }}>
      <Can
        perform="Companies/List"
        yes={() => (
          <>
            <Can
              perform="Companies/Create"
              yes={() => (
                <div className="create-function-button">
                  <Button
                    variant="contained"
                    startIcon={<FontAwesomeIcon icon={faPlus} />}
                    onClick={onAddCompanyClicked}
                    style={{
                      marginBottom: '1em',
                      color: 'white',
                      backgroundColor: '#31ab70',
                      fontFamily: 'Poppins',
                      fontWeight: 'bold',
                    }}
                  >
                    Adicionar Empresa
                  </Button>
                </div>
              )}
              no={() => <></>}
            />
            <div>
              <DataTable
                title="Empresas"
                data={data}
                columns={columns}
                onUpdateClicked={onUpdateCompanyClicked}
                onDeleteClicked={onDeleteCompanyClicked}
                onRowClickURL="/company"
              />
            </div>
          </>
        )}
        no={() => <Redirect to="/main" />}
      />
    </div>
  );
};

export default CompanyList;
