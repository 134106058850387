export const commentScheme = {
  name: 'comment-to-done',
  nickname: 'Comentário',
  textarea: {
    rows: 6,
  },
  hasborder: 'yes',
  hasradius: 'yes',
  longDescription: 750,
};
