import styled from 'styled-components';
import { devices } from '../../assets/styles/devices';

export const StyledLocationGroupLocations = styled.div``;

export const StyledContent = styled.div`
  width: 100%;
  padding: 3rem 2rem 2rem;
  display: grid;
  flex-direction: row;
  margin-bottom: 0;

  @media ${devices.desktop} {
    grid-template-columns: 70% 30%;
  };
`;
