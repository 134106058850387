import React from 'react';

import { IconButton } from '../../../../../components';

import { TTimeActionsBaseProps } from '../../../../../types/TWorkingHours';

import {
  StyledTimeActions,
} from './time-actions-styles';

const TimeActions = ({
  handleDeleteTimeRange,
  handleAddNewTimeRange,
}: TTimeActionsBaseProps) => (
  <StyledTimeActions>
    <IconButton
      className="time-row-icon"
      tooltip="Apagar horário"
      icon="Close"
      onClick={handleDeleteTimeRange}
    />
    <IconButton
      className="time-row-icon"
      tooltip="Adicionar horário"
      icon="Add"
      onClick={handleAddNewTimeRange}
    />
  </StyledTimeActions>
);

export default TimeActions;
