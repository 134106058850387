import axios from 'axios';

const categoryGroupingsCreate = async ({
  accessToken,
  payload,
  setTokenLikeExpired,
  setIsFetching,
  snackbar,
  categoryGroupingID,
}) => {
  try {
    if (setIsFetching) setIsFetching(true);

    const response = await axios({
      method: 'PUT',
      url: `${process.env.REACT_APP_CATEGORY_GROUPINGS_API_URL}/${categoryGroupingID}`,
      data: payload,
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });

    return response;
  } catch (err) {
    const {
      response,
    } = err;

    if (response?.status === 401 && setTokenLikeExpired) setTokenLikeExpired();

    if (response?.status === 409 && snackbar) {
      snackbar('Já existe um agrupamento com esse nome.', { variant: 'error' });
    } else if (snackbar) {
      snackbar('Deu erro ao tentar criar agrupamento. Tente novamente!', { variant: 'error' });
    }

    return;
  } finally {
    if (setIsFetching) setIsFetching(false);
  }
};

export default categoryGroupingsCreate;
