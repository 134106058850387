import axios from 'axios';

export default async function getHandlers(accessToken, page = 0, pageSize = 100, query = '') {
  const requestOptions = {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  };
  try {
    const { data } = await axios.get(
      `${process.env.REACT_APP_HANDLERS_API_URL}?page=${page}&pageSize=${pageSize}&query=${query}`,
      requestOptions,
    );

    return data;
  } catch (err) {
    console.log(err);
    return err;
  }
}
