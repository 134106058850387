import { useSelector } from 'react-redux';

const check = (rules, profiles, action) => {
  let permitted = false;
  for (let i = 0; i < profiles.length; i++) {
    const permissions = rules[profiles[i].name];

    if (!permissions) {
      permitted = false;
    } else {
      const staticPermissions = permissions.static;
      if (staticPermissions && staticPermissions.includes(action)) {
        return true;
      }
    }
  }
  return permitted;
};

const Can = ({
  perform, data, yes, no,
}) => {
  const { rules } = useSelector((state) => state.RulesReducer);

  const {
    user: {
      user: { profiles },
    },
  } = useSelector((state) => state.AuthReducer);

  Can.defaultProps = {
    yes: () => null,
    no: () => null,
  };

  return check(rules, profiles, perform, data) ? yes() : no();
};

export default Can;
