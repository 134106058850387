import styled, { css } from 'styled-components';
import { devices } from '../../assets/styles/devices';

import { THeaderToolbar } from '../../types/THeaderToolbar';

export const StyledHeaderToolbar = styled.header<
  Pick<THeaderToolbar, 'paddingLeft' | 'paddingRight'>
  >`
  background: ${props => props.theme.colors.white};
  display: flex;
  flex-direction: column;
  width: 100%;
  height: auto;

  @media ${devices.tabletLandscape} {
    flex-direction: row;
    align-items: center;
    height: 9.2rem;
    justify-content: space-between;
  }

  @media ${devices.desktop} {
    justify-content: space-between;
  }

  ${props => props.paddingLeft && css`
    padding-left: 2rem;
  `};

  ${props => props.paddingRight && css`
    padding-right: 2rem;
  `};

  .button-goback {
    margin-right: 1.5rem;
  }
`;

export const StyledTitlePage = styled.h2`
  font-size: 3rem;
  font-weight: 700;
  padding: 3rem 2rem;
  margin-bottom: 0;
  text-align: center;
  width: 100%;

  @media ${devices.tabletLandscape} {
    width: auto;
  }
`;

export const StyledRightControls = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: auto;
  height: auto;
  width: 100%;
  overflow-x: scroll;
 
  @media ${devices.tabletLandscape} {
    height: 9.2rem;
    overflow-x: unset;
    width: auto;
  }
`;

export const StyledHeaderDropdownWrapper = styled.div`
  display: flex;
  flex-direction: column;
  
  @media ${devices.tabletLandscape} {
    flex-direction: row;
  }
`;
