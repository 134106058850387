import { isNil, flatten } from 'ramda';
import refreshGoogleAccessToken from '../refreshGoogleAccessToken';
import updateAllLocationConnectionsByRefreshToken from '../../location-connections/updateAllLocationConnectionsByRefreshToken';

import getBatchLocationReviews from './getBatchLocationReviews';
import {
  TGetReviewsBYLocations,
  TGetReviewsBYLocationsResponse,
} from '../../../types/TGoogle';

export const getReviewsByLocations = async ({
  userAccessToken,
  locations,
  pageToken = null,
  googleAccounts,
  isFetching,
}: TGetReviewsBYLocations): Promise<TGetReviewsBYLocationsResponse> => {
  try {
    if (isFetching) isFetching(true);
    let googleLocationsByAccountId: any = {};
    let nextPageToken: string | null = null;
    let auxiliarNextPageToken: string | null = null;

    locations.map((element) => {
      const accountId = element.name.split('/')[1];

      if (googleLocationsByAccountId[accountId]) {
        googleLocationsByAccountId = {
          ...googleLocationsByAccountId,
          [accountId]: [...googleLocationsByAccountId[accountId], element.name],
        };
      } else {
        googleLocationsByAccountId = {
          ...googleLocationsByAccountId,
          [accountId]: [element.name],
        };
      }
    });

    const reviews = flatten(
      await Promise.all(
        Object.keys(googleLocationsByAccountId).map(async (accountGroupId) => {
          const { refreshToken } = googleAccounts.filter((account) => account.googleAccountId === accountGroupId)[0];

          if (isNil(refreshToken)) return null;
          const refreshGoogleAccessTokenResponse = await refreshGoogleAccessToken({
            refreshToken,
          });

          if (isNil(refreshGoogleAccessTokenResponse)) {
            await updateAllLocationConnectionsByRefreshToken({ accessToken: userAccessToken, refreshToken });
            return null;
          }

          const {
            access_token: googleAccessToken,
          } = refreshGoogleAccessTokenResponse;

          const { locationReviews, nextPageToken: nextPageTokenResponse } = await getBatchLocationReviews({
            accessToken: googleAccessToken,
            accountGroupId,
            pageToken,
            locationNames: googleLocationsByAccountId[accountGroupId],
          });

          if (!isNil(nextPageTokenResponse)) auxiliarNextPageToken = nextPageTokenResponse;

          nextPageToken = auxiliarNextPageToken;

          return locationReviews || null;
        }),
      ),
    );

    return {
      reviews: reviews.filter((review) => !isNil(review)),
      nextPageToken,
      googleLocationsByAccountId,
    };
  } catch (err: any) {
    return null;
  } finally {
    if (isFetching) isFetching(false);
  }
};
