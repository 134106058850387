import React from 'react';

import { Burguer } from '..';

import {
  StyledHeaderMobile,
  Logo,
} from './header-mobile-styles';

import hublocalLogo from '../../assets/images/logo-hub-branco.png';

const HeaderMobile = ({ menuMobile }) => (
  <StyledHeaderMobile>
    <Logo>
      <img src={hublocalLogo} alt="Hublocal Saas" />
    </Logo>
    <Burguer
      status={menuMobile.open}
      changeStatus={menuMobile.openOrClose}
    />
  </StyledHeaderMobile>
);

export default HeaderMobile;
