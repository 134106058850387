import React from 'react';
import * as R from 'ramda';

import {
  StyledWorkingIHoursDays,
  StyledWorkingHoursInfo,
} from './working-hours-info-styles';
import SyncStatusIcon from '../../../sync-status-icon';

const WorkingHoursInfo = ({ workingHoursData, status }) => (
  <>
    {workingHoursData.map((workingHoursDay, index) => (
      <StyledWorkingHoursInfo key={workingHoursDay.id} isLastOfSection={index + 1 === workingHoursData.length}>
        <StyledWorkingIHoursDays>
          <li>
            <p className="title">{workingHoursDay.day_title || workingHoursDay.dayTitle}</p>

            <p>
              {workingHoursDay.is_open || workingHoursDay.isOpen
                ? `Aberto${workingHoursDay.is_24_hours || workingHoursDay.is24Hours
                  ? ': 24 horas'
                  : ''}
                  ${!R.isEmpty(workingHoursDay.times)
                      && !R.isNil(workingHoursDay.times)
                       && !workingHoursDay.is_24_hours
                        && !workingHoursDay.is24Hours
                  ? workingHoursDay.times.map((time, timeIndex) => `${timeIndex === 0 ? ':' : ''}${' '}${time.open}-${time.close}`)
                  : ''}`
                : 'Fechado'}
            </p>

          </li>
        </StyledWorkingIHoursDays>
        <SyncStatusIcon syncType="requests" status={status} />
      </StyledWorkingHoursInfo>
    ))}
  </>
);

export default WorkingHoursInfo;
