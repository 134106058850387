import styled from 'styled-components';
import { shade } from 'polished';
import { Checkbox } from '@material-ui/core';

export const StyledDayOption = styled.li`
  display: flex;
  align-items: center;
  padding: 0.5rem 1rem;

  &:nth-child(even) {
    background: ${props => shade(0.1, props.theme.colors.white)};
  }
`;

export const StyledCheckbox = styled(Checkbox)``;
