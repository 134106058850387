import React, { useEffect, useMemo, useState } from 'react';
import * as R from 'ramda';

import {
  IconButton, Input, Pagination, Checkbox,
} from '..';
import ChecklistItem from './checklist-item';

import type { TChecklist } from '../../types/TChecklist';
import type { TLocationEntity } from '../../types/TLocation';

import {
  StyledChecklist,
  StyledTitle,
  StyledLabel,
  StyledEmptyList,
  StyledCheckAllWrapper,
  StyledListContainer,
  StyledLocationOptions,
  StyledCountSelectedLocations,
} from './checklist-styles';

const Checklist = ({
  options,
  optionsTotalLength,
  optionsNoEllegible,
  optionsPageIndex,
  setOptionsPageIndex,
  optionsPageQuantity,
  handleInputSearch,
  selectedOptions,
  setSelectedOptions,
  masterCheckbox,
  setMasterCheckbox,
  showWarningWorkingHours,
}: TChecklist) => {
  const [optionsOriginalTotalCount, setOptionsOriginalTotalCount] = useState(0);
  const [noEllegibleOriginalCount, setNoEllegibleOriginalCount] = useState(0);
  const [shouldSetCount, setShouldSetCount] = useState(true);

  useEffect(() => {
    if (!shouldSetCount) return;
    if (optionsTotalLength === 0) return;
    if (R.isNil(optionsTotalLength)) return;

    setOptionsOriginalTotalCount(optionsTotalLength ?? 0);
    setNoEllegibleOriginalCount(optionsNoEllegible ?? 0);

    setShouldSetCount(false);
  }, [optionsTotalLength])

  const handleMasterCheckbox = () => {
    let newMasterCheckbox = !masterCheckbox;

    if (masterCheckbox) {
      newMasterCheckbox = selectedOptions.ids.length > 0 ? true : !masterCheckbox;
    }

    if (!masterCheckbox) {
      newMasterCheckbox = selectedOptions.ids.length > 0 ? false : !masterCheckbox;
    }

    const masterCheckboxValue = newMasterCheckbox ? 'EXCLUDED' : 'ADDED';

    setMasterCheckbox(newMasterCheckbox);
    setSelectedOptions({
      type: masterCheckboxValue,
      ids: [],
    });
  };

  const getCountLocationMessage = () => {
    if (R.isNil(optionsTotalLength) || R.isNil(optionsNoEllegible)) return '';

    const ellegibleLocationCounter = optionsTotalLength - optionsNoEllegible;
    let totalSelected = 0;

    if (selectedOptions.type === 'EXCLUDED') {
      totalSelected = ellegibleLocationCounter - selectedOptions.ids.length;
    } else {
      totalSelected = selectedOptions.ids.length;
    }

    if (masterCheckbox) {
      const ellegibleLocationCounter = optionsOriginalTotalCount - noEllegibleOriginalCount;
      return `${ellegibleLocationCounter} de ${optionsOriginalTotalCount} selecionados`;
    }

    return `${totalSelected} de ${optionsTotalLength} selecionados`;
  };

  return (
    <StyledChecklist>
      {!R.isNil(options) && (
        <>
          <StyledTitle>Locais Afetados</StyledTitle>
          <Input
            border
            label="Buscar"
            className="input"
            onChange={({ target: { value } }) => { handleInputSearch(value); }}
          />
          {R.isEmpty(options) && (
            <StyledEmptyList>Nenhum local a ser exibido</StyledEmptyList>
          )}

          {!R.isEmpty(options) && (
            <>
              <StyledCheckAllWrapper>
                <StyledLabel>Locais</StyledLabel>

                <StyledLocationOptions>
                  <StyledCountSelectedLocations>
                    {getCountLocationMessage()}
                  </StyledCountSelectedLocations>
                  <Checkbox
                    label=""
                    labelPlacement="right"
                    checked={masterCheckbox}
                    onChange={handleMasterCheckbox}
                    indeterminate={selectedOptions.ids.length > 0}
                  />
                </StyledLocationOptions>
              </StyledCheckAllWrapper>

              {options.map((item: any) => (
                <StyledListContainer>

                  <ChecklistItem
                    key={item.id}
                    item={item}
                    selected={selectedOptions}
                    setSelected={setSelectedOptions}
                    masterCheckbox={masterCheckbox}
                    showWarningWorkingHours={showWarningWorkingHours}
                  />
                </StyledListContainer>
              ))}

              <Pagination
                className="batch-update-pagination"
                page={optionsPageIndex}
                setPage={setOptionsPageIndex}
                pagesQuantity={optionsPageQuantity}
              />
            </>
          )}
        </>
      )}
    </StyledChecklist>
  );
};

export default Checklist;
