import axios from 'axios';

export default async function update({
  accessToken,
  autoResponseId,
  inputData,
  setTokenLikeExpired,
  setIsFetching,
}) {
  if (setIsFetching) setIsFetching(true);

  try {
    const { data } = await axios.put(
      `${process.env.REACT_APP_AUTO_RESPONSES_API_URL}/${autoResponseId}`,
      inputData,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      },
    );

    return data;
  } catch (err) {
    if (err.response?.status === 401 && setTokenLikeExpired) setTokenLikeExpired();

    return err;
  } finally {
    if (setIsFetching) setIsFetching(false);
  }
}
