import styled from 'styled-components';
import {
  Avatar,
  Checkbox,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  FormControlLabel,
} from '@material-ui/core';

import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

export const StyledExpandMoreIcon = styled(ExpandMoreIcon)``;

export const StyledAccordion = styled(Accordion)``;

export const StyledFormControlLabel = styled(FormControlLabel)``;

export const StyledAccordionSummary = styled(AccordionSummary)`
  display: flex;
  align-items: center;
`;

export const StyledAccordionDetails = styled(AccordionDetails)`
  display: flex;
  flex-direction: column;
`;

export const StyledUserName = styled.span`
  display: flex;
  align-items: center;
`;

export const StyledAvatar = styled(Avatar)`
  margin-right: 1rem;
  width: 3rem;
  height: 3rem;
`;

export const StyledCheckbox = styled(Checkbox)`
  margin-right: 1rem;
`;

export const StyledUserInfo = styled.div`
  font-size: 1.2rem;
  display: flex;
  flex-direction: column;
  margin-bottom: 0.5rem;
  
  &:last-child {
    margin-bottom: 0;
  }
  
  span {
    font-weight: bold;
    color: ${props => props.theme.colors.primary};
  }
`;
