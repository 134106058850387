import React from 'react';
import * as R from 'ramda';

import { IconButton, Select, Loading } from '../../../components';

import { TCreatePostDialogBaseProps } from '../../../types/TCreatePostDialog';
import { TLocationEntity } from '../../../types/TLocation';
import { TSelectPlatform } from '../../../types/TPlatforms';
import { TLocationPostGooglePostType } from '../../../types/TLocationPost';

import {
  GooglePostFormCovid19,
  GooglePostFormNews,
  GooglePostFormOffer,
  GooglePostFormEvent,
} from '../google-posts-form';

import {
  locationPostPlatformsMocked,
} from '../../../utils/select-platforms-mocked';

import {
  createLocationGooglePostsType,
  getTopicType,
} from '../helpers';

import {
  StyledCreatePostDialog,
  StyledHeader,
  StyledOptionsPost,
  StyledFormContent,
} from './create-post-dialog-styles';

const CreatePostDialog = ({
  open = false,
  setOpen,
  getGooglePosts,
  postInfoToUpdate = null,
  setPostInfoToUpdate,
  postDialogLoading,
}: TCreatePostDialogBaseProps) => {
  const [currentSelectedPlatform, setCurrentSelectedPlatform] = React.useState<string>('Google');
  const [currentSelectedPostType, setCurrentSelectedPostType] = React.useState<string>('');

  const handleCloseModal = React.useCallback(() => {
    setCurrentSelectedPostType('');
    getGooglePosts();
    setPostInfoToUpdate(null);

    setOpen(false);
  }, [currentSelectedPostType]);

  const handleOnCloseModal = () => {
    setCurrentSelectedPostType('');
    setPostInfoToUpdate(null);
    setOpen(false);
  };

  const renderPostFormType = React.useCallback((currentPostType: string): React.ReactComponentElement<any> => {
    if (currentPostType === 'Atualizações da COVID-19') {
      return (
        <GooglePostFormCovid19
          dataToUpdate={postInfoToUpdate}
          closeModal={handleCloseModal}
        />
      );
    }
    if (currentPostType === 'Novidades') {
      return (
        <GooglePostFormNews
          closeModal={handleCloseModal}
          dataToUpdate={postInfoToUpdate}
        />
      );
    }
    if (currentPostType === 'Oferta') return <GooglePostFormOffer dataToUpdate={postInfoToUpdate} closeModal={handleCloseModal} />;
    if (currentPostType === 'Evento') return <GooglePostFormEvent dataToUpdate={postInfoToUpdate} closeModal={handleCloseModal} />;

    return <span>Nenhum tipo de post selecionado!</span>;
  },
    [postInfoToUpdate]);

  const renderDialogLabel = () => {
    if (postDialogLoading) {
      return (
        <h2>Carregando...</h2>
      );
    }

    return (
      R.isNil(postInfoToUpdate) ? (
        <h2>Criar Postagem</h2>
      ) : (
        <h2>Alterar Postagem</h2>
      )
    );
  };

  React.useEffect(() => {
    if (postInfoToUpdate) {
      const { topicType } = postInfoToUpdate;
      setCurrentSelectedPostType(getTopicType(topicType));
    }
  }, [postInfoToUpdate]);

  return (
    <StyledCreatePostDialog
      open={open}
      aria-labelledby="simple-dialog-title"
      onClose={handleOnCloseModal}
    >
      <StyledHeader>
        {renderDialogLabel()}

        <IconButton
          icon="Close"
          tooltip="Fechar"
          onClick={handleOnCloseModal}
        />
      </StyledHeader>
      <StyledOptionsPost>
        <Select<TSelectPlatform>
          border
          value={currentSelectedPlatform}
          label="Plataforma"
          options={locationPostPlatformsMocked}
          name="platform-to-post"
          onChange={({ target }) => setCurrentSelectedPlatform(target.value)}
          disabled
        />
        <Select<TLocationPostGooglePostType>
          border
          value={currentSelectedPostType}
          label="Tipo de Post"
          options={createLocationGooglePostsType}
          name="posts-type"
          onChange={({ target }) => setCurrentSelectedPostType(target.value)}
          hasPlaceholder
          disabled={postInfoToUpdate}
        />
      </StyledOptionsPost>
      <StyledFormContent>
        {postDialogLoading ? <Loading className="post-dialog-loading" /> : renderPostFormType(currentSelectedPostType)}
      </StyledFormContent>
    </StyledCreatePostDialog>
  );
};

export default CreatePostDialog;
