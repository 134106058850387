import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { AppBar, Toolbar } from '@material-ui/core';
import BusinessIcon from '@material-ui/icons/Business';

import CompanyDropdown from '../company-dropdown';

import { Creators as CompanyActions } from '../../redux/ducks/company';
import { Creators as LocationActions } from '../../redux/ducks/location';

import './company-nav-bar-styles.css';
import { translations } from '../../translations';

const CompanyNavBar = ({ returnUrl }) => <h1>h1</h1>;

export default CompanyNavBar;
