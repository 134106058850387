import axios, { AxiosResponse } from 'axios';
import type {
  TBatchGallery,
  TDeleteBatchMedia,
} from '../../../types/TBatchGallery';

export const DeleteBatchMedia = async ({
  userAccessToken,
  galleryBatchId,
  mediaIds,
  isUpdating,
}: TDeleteBatchMedia): Promise<{
  status: 'ERROR' | 'SUCCESS';
  data: TBatchGallery | object;
}> => {
  try {
    if (isUpdating) isUpdating(true);

    const { data }: AxiosResponse<TBatchGallery> = await axios.post(
      `${process.env.REACT_APP_SAAS_BATCH_GOOGLE_MODULES}/gallery-batch/delete-media/${galleryBatchId}`,
      {
        mediaIds,
      },
      {
        headers: {
          Authorization: `Bearer ${userAccessToken}`,
        },
      },
    );

    return {
      status: 'SUCCESS',
      data,
    };
  } catch (err: any) {
    return {
      status: 'ERROR',
      data: err.response.data,
    };
  } finally {
    if (isUpdating) isUpdating(false);
  }
};
