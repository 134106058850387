import React from 'react';
import * as R from 'ramda';

import limitedText from '../../../../utils/limited-text';

import {
  StyledPostCard,
  StyledPostCardActions,
  StyledPostCardContent,
  StyledPostCardMedia,
  StyledPostCardHeaderDiv,
  StyledPopover,
  StyledCardMenuList,
  StyledCardMenuListItem,
  StyledDateValue,
  StyledPostCardMediaOverlay,
  StyledMediaCardMedia,
  StyledLinkButton,
  StyledRejectedPost,
} from './post-card-styles';

import { IconButton } from '../../../../components';

import { TPostCard } from '../../../../types/TPostCard';

import { getActionTypeReverse } from '../../helpers';

const convertDate = (dateTime:string) => new Date(dateTime).toLocaleString();

const noImage = `${process.env.PUBLIC_URL}/images/missing-image.png`;

export default ({
  summary,
  createTime,
  media,
  callToAction,
  onDeleteClicked,
  onEditClicked,
  phone,
  state,
}: TPostCard) => {
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);

  const open = Boolean(anchorEl);

  const handleClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(e.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const getLinkButtonLabel = (actionType: any) => getActionTypeReverse(actionType);

  const getLinkButtonValue = () => {
    if (R.isNil(callToAction)) return null;

    if (callToAction?.actionType === 'CALL') return `tel:%2861%${phone}`;

    return callToAction?.url;
  };

  const handleImgError = (e: any) => {
    e.target.src = 'http://placehold.it/800x800?text=M%C3%ADdia';
  };

  return (
    <StyledPostCard>
      <StyledMediaCardMedia>
        <StyledDateValue>
          <b>Data: </b>
          <span>{`${convertDate(createTime)}`}</span>
        </StyledDateValue>

        <StyledPostCardMedia
          component="img"
          image={media ? media[0].googleUrl : noImage}
          height="100%"
          onError={handleImgError}
        />

        <StyledPostCardMediaOverlay />

        {state === 'REJECTED' && <StyledRejectedPost>Rejeitado</StyledRejectedPost>}
      </StyledMediaCardMedia>

      <StyledPostCardContent>
        <span>{limitedText(summary, 60)}</span>
      </StyledPostCardContent>

      <StyledPopover
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        onClose={handlePopoverClose}
      >
        <StyledCardMenuList>
          {state !== 'REJECTED' && (
            <StyledCardMenuListItem
              onClick={() => {
                if (onEditClicked) onEditClicked();
                handlePopoverClose();
              }}
            >
              Editar
            </StyledCardMenuListItem>
          )}
          <StyledCardMenuListItem
            onClick={() => {
              if (onDeleteClicked)onDeleteClicked();
              handlePopoverClose();
            }}
          >
            Excluir
          </StyledCardMenuListItem>
        </StyledCardMenuList>
      </StyledPopover>

      <StyledPostCardActions disableSpacing>
        <StyledPostCardHeaderDiv>
          {callToAction && (
            <StyledLinkButton onClick={() => window.open(`${getLinkButtonValue()}`, '_blank')}>
              {getLinkButtonLabel(callToAction?.actionType)}
            </StyledLinkButton>
          )}
        </StyledPostCardHeaderDiv>
        <IconButton tooltip="Mais opções" icon="MoreVert" onClick={handleClick} />
      </StyledPostCardActions>

    </StyledPostCard>
  );
};
