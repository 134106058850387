import React from 'react';
import * as R from 'ramda';

import {
  categoryGroupingsCreate,
  updateCategoryGrouping,
} from '../../../services/category-groupings';

import getPlatformCategories from '../../../services/categories/getPlatformCategories';

import {
  enableButtonAddCategoryGrouping,
  resetField,
} from './helpers';

import {
  AutocompleteInput,
  Button,
  Loading,
} from '../../../components';

import { useUncontrolledField } from '../../../hooks';

import {
  fieldCategoryGroupDescription,
  fieldCategoryGroupName,
} from './fields-scheme';

import {
  StyledAddCategoryGrouping,
  StyleFieldsContainer,
  StyledFields,
  StyledIsFetchingToEdit,
  StyledButtonsGroup,
} from './add-category-grouping-styles';

const AddCategoryGrouping = ({
  setTokenLikeExpired,
  userToken,
  snackbar,
  setUpdateTable,
  isFetchingToEdit,
  dataToEdit,
  setDataToEdit,
}) => {
  const [facebookCategories, setFacebookCategories] = React.useState([]);
  const [googleCategories, setGoogleCategories] = React.useState([]);
  const [foursquareCategories, setFoursquareCategories] = React.useState([]);

  const [selectedFacebookCategories, setSelectedFacebookCategories] = React.useState([]);
  const [selectedGoogleCategories, setSelectedGoogleCategories] = React.useState([]);
  const [selectedFoursquareCategories, setSelectedFoursquareCategories] = React.useState([]);

  const groupName = React.useRef(null);
  const groupDescription = React.useRef(null);

  const [isFetchingCreate, setIsFetchingCreate] = React.useState(false);
  const [isFetchingUpdate, setIsFetchingUpdate] = React.useState(false);

  const [enableAddGroup, setEnableAddGroup] = React.useState(false);
  const [openUpdatedControls, setOpenUpdatedControls] = React.useState(false);

  const [categoryGroupingIDToEdit, setCategoryGroupingIDToEdit] = React.useState(null);

  const [fields, setFields] = React.useState([]);

  const [initialValueCategoryGroupNameField, setInitialValueCategoryGroupNameField] = React.useState('');
  const [initialValueCategoryGroupDescriptionField, setInitialValueCategoryGroupDescriptionField] = React.useState('');

  const { Field: CategoryGroupNameField } = useUncontrolledField({
    fieldScheme: {
      ...fieldCategoryGroupName,
      defaultValue: initialValueCategoryGroupNameField,
    },
    saveIn: setFields,
  });

  const { Field: CategoryGroupDescriptionField } = useUncontrolledField({
    fieldScheme: {
      ...fieldCategoryGroupDescription,
      defaultValue: initialValueCategoryGroupDescriptionField,
    },
    saveIn: setFields,
  });

  const getGoogleCategories = React.useCallback(async () => {
    const categories = await getPlatformCategories({ accessToken: userToken, query: '', platform: 'google' });
    setGoogleCategories(categories[0]);
  }, []);

  const getFacebookCategories = React.useCallback(async () => {
    const categories = await getPlatformCategories({ accessToken: userToken, query: '', platform: 'facebook' });
    setFacebookCategories(categories[0]);
  }, []);

  const getFoursquareCategories = React.useCallback(async () => {
    const categories = await getPlatformCategories({ accessToken: userToken, query: '', platform: 'foursquare' });
    setFoursquareCategories(categories[0]);
  }, []);

  React.useEffect(() => { getGoogleCategories(); }, [getGoogleCategories]);
  React.useEffect(() => { getFacebookCategories(); }, [getFacebookCategories]);
  React.useEffect(() => { getFoursquareCategories(); }, [getFoursquareCategories]);

  React.useEffect(() => {
    const [
      setCurrentCategoryGroupingName,
      setCurrentCategoryGroupingDescription,
    ] = fields.map(field => field.setCurrentValue);

    if (!R.isEmpty(initialValueCategoryGroupNameField)) {
      setCurrentCategoryGroupingName(initialValueCategoryGroupNameField);
    }

    if (!R.isEmpty(initialValueCategoryGroupDescriptionField)) {
      setCurrentCategoryGroupingDescription(initialValueCategoryGroupDescriptionField);
    }
  }, [initialValueCategoryGroupNameField, initialValueCategoryGroupDescriptionField]);

  React.useEffect(() => {
    if (R.isEmpty(fields)) return;

    const [
      categoryGroupName,
      categoryGroupDescription,
    ] = fields.map(field => ({
      value: field.getValue(),
      setNewValue: field.setDefaultValue,
    }));

    const fieldToValidate = {
      foursquareCategories,
      facebookCategories,
      googleCategories,
      selectedFoursquareCategories,
      selectedGoogleCategories,
      selectedFacebookCategories,
      facebookInitialValue: dataToEdit?.facebook || null,
      googleInitialValue: dataToEdit?.google || null,
      foursquareInitialValue: dataToEdit?.foursquare || null,
      groupNameValue: categoryGroupName.value,
      groupDescriptionValue: categoryGroupDescription.value,
    };

    if (enableButtonAddCategoryGrouping(fieldToValidate)) setEnableAddGroup(true);
    if (!enableButtonAddCategoryGrouping(fieldToValidate)) setEnableAddGroup(false);

    if (dataToEdit) {
      categoryGroupName.setNewValue(dataToEdit.name || '');
      categoryGroupDescription.setNewValue(dataToEdit.description || '');
      setSelectedFoursquareCategories(dataToEdit.foursquare || []);
      setSelectedFacebookCategories(dataToEdit.facebook || []);
      setSelectedGoogleCategories(dataToEdit.google || []);
      setOpenUpdatedControls(true);
      setCategoryGroupingIDToEdit(dataToEdit.id);

      setDataToEdit(null);
    }
  }, [
    selectedFacebookCategories,
    selectedGoogleCategories,
    selectedFoursquareCategories,
    dataToEdit,
    fields,
  ]);

  const handleAddGroup = async () => {
    const [
      newCategoryGroupingsName,
      newCategoryGroupingsDescription,
    ] = fields.map(field => {
      const fieldValue = field.getValue();
      field.reset();

      return fieldValue;
    });

    if (newCategoryGroupingsName.length < 4 && snackbar) {
      snackbar('O nome do agrupamento precisa ter no mínimo 4 caracteres', { variant: 'error' });
      setCurrentValuesToUncontrolledFields(fields);
      return;
    }

    if (newCategoryGroupingsDescription.length < 10 && snackbar) {
      snackbar('A descrição do agrupamento precisa ter no mínimo 10 letras', { variant: 'error' });
      setCurrentValuesToUncontrolledFields(fields);
      return;
    }

    const payload = {
      name: newCategoryGroupingsName,
      description: newCategoryGroupingsDescription,
      google_categories: JSON.stringify(selectedGoogleCategories),
      facebook_categories: JSON.stringify(selectedFacebookCategories),
      foursquare_categories: JSON.stringify(selectedFoursquareCategories),
    };

    const response = await categoryGroupingsCreate({
      accessToken: userToken,
      payload,
      setIsFetching: setIsFetchingCreate,
      snackbar,
      setTokenLikeExpired,
    });

    if (response?.status === 201) {
      snackbar('Agrupamento registrado com sucesso!', { variant: 'success' });

      resetField({
        setSelectedFacebookCategories,
        setSelectedGoogleCategories,
        setSelectedFoursquareCategories,
      });

      setUpdateTable(true);
    }
  };

  const handleUpdate = async () => {
    const [
      newCategoryGroupingsName,
      newCategoryGroupingsDescription,
    ] = fields.map(field => {
      const fieldValue = field.getValue();
      field.reset();

      return fieldValue;
    });

    if (newCategoryGroupingsName.length < 4 && snackbar) {
      snackbar('O nome do agrupamento precisa ter no mínimo 4 caracteres', { variant: 'error' });
      setCurrentValuesToUncontrolledFields(fields);
      return;
    }

    if (newCategoryGroupingsDescription.length < 10 && snackbar) {
      snackbar('A descrição do agrupamento precisa ter no mínimo 10 letras', { variant: 'error' });
      setCurrentValuesToUncontrolledFields(fields);
      return;
    }

    const payload = {
      name: newCategoryGroupingsName,
      description: newCategoryGroupingsDescription,
      google_categories: JSON.stringify(selectedGoogleCategories),
      facebook_categories: JSON.stringify(selectedFacebookCategories),
      foursquare_categories: JSON.stringify(selectedFoursquareCategories),
    };

    const response = await updateCategoryGrouping({
      accessToken: userToken,
      payload,
      setIsFetching: setIsFetchingUpdate,
      snackbar,
      setTokenLikeExpired,
      categoryGroupingID: categoryGroupingIDToEdit,
    });

    if (response?.status === 200) {
      snackbar('Agrupamento atualizado com sucesso!', { variant: 'success' });

      setOpenUpdatedControls(false);

      resetField({
        setSelectedFacebookCategories,
        setSelectedGoogleCategories,
        setSelectedFoursquareCategories,
      });

      setUpdateTable(true);
    }
  };

  const handleCancel = () => {
    setDataToEdit(null);
    setEnableAddGroup(false);
    setSelectedFacebookCategories([]);
    setSelectedGoogleCategories([]);
    setSelectedFoursquareCategories([]);
    setOpenUpdatedControls(false);
    
    fields.map(field => {
      field.reset()
    })
  };

  const setCurrentValuesToUncontrolledFields = currentFieldsData => {
    const [
      categoryGroupNameFieldCurrentValue,
      categoryGroupDescriptionFieldCurrentValue,
    ] = currentFieldsData.map(field => {
      field.setDefaultValue(field.getValue());

      return field.getValue();
    });

    setInitialValueCategoryGroupNameField(categoryGroupNameFieldCurrentValue);
    setInitialValueCategoryGroupDescriptionField(categoryGroupDescriptionFieldCurrentValue);
  };

  const handleSelectedCategoryGroupingsFacebook = currentSelectedData => {
    setSelectedFacebookCategories(currentSelectedData);
    setCurrentValuesToUncontrolledFields(fields);
  };

  const handleSelectedCategoryGroupingsGoogle = currentSelectedData => {
    setSelectedGoogleCategories(currentSelectedData);
    setCurrentValuesToUncontrolledFields(fields);
  };

  const handleSelectedCategoryGroupingsFoursquare = currentSelectedData => {
    setSelectedFoursquareCategories(currentSelectedData);
    setCurrentValuesToUncontrolledFields(fields);
  };

  return (
    <StyledAddCategoryGrouping>
      <h3>Adicionar Grupo</h3>

      <StyleFieldsContainer>
        {isFetchingToEdit && (
        <StyledIsFetchingToEdit>
          <Loading className="is-loading-to-edit" />
          Carregando informações para edição...
        </StyledIsFetchingToEdit>
        )}

        <StyledFields>
          <CategoryGroupNameField />
          <CategoryGroupDescriptionField />

          {facebookCategories && (
            <AutocompleteInput
              className="field"
              initialValue={selectedFacebookCategories}
              saveSelectedOpionsIn={handleSelectedCategoryGroupingsFacebook}
              filterSearchKey="name"
              categoryId="platform_id"
              options={facebookCategories}
              label="Categorias do Facebook"
              saveAllObject
            />
          )}

          {googleCategories && (
            <AutocompleteInput
              className="field"
              label="Categorias do Google"
              options={googleCategories}
              saveSelectedOpionsIn={handleSelectedCategoryGroupingsGoogle}
              initialValue={selectedGoogleCategories}
              filterSearchKey="name"
              categoryId="platform_id"
              saveAllObject
            />
          )}

          {foursquareCategories && (
            <AutocompleteInput
              className="field"
              label="Categorias do Foursquare"
              options={foursquareCategories}
              saveSelectedOpionsIn={handleSelectedCategoryGroupingsFoursquare}
              initialValue={selectedFoursquareCategories}
              filterSearchKey="name"
              categoryId="platform_id"
              saveAllObject
            />
          )}

          {openUpdatedControls
            ? (
              <StyledButtonsGroup>
                <Button
                  buttonType="secondary"
                  onClick={handleCancel}
                >
                  Cancelar
                </Button>
                <Button
                  onClick={handleUpdate}
                  disabled={!enableAddGroup || isFetchingUpdate}
                >
                  {isFetchingUpdate ? <Loading /> : 'Atualizar'}
                </Button>
              </StyledButtonsGroup>
            )
            : (
              <Button
                disabled={isFetchingCreate}
                onClick={handleAddGroup}
                className="button-add"
              >
                {isFetchingCreate ? <Loading /> : 'Adicionar'}
              </Button>
            )}
        </StyledFields>
      </StyleFieldsContainer>
    </StyledAddCategoryGrouping>
  );
};

export default AddCategoryGrouping;
