import React from 'react';
import { useSelector } from 'react-redux';
import * as R from 'ramda';
import { useSnackbar } from 'notistack';

import APIGoogleLocationMediaList from '../../services/google/media/listLocationMedias';
import APIGetValidGoogleAccessTokenFromLocation from '../../services/getValidGoogleAccessTokenFromLocation';

import APIDeleteGoogleGalleryMedia from '../../services/google/media/deleteLocationMedia';

import {
  useAuth,
  useHasAccess,
} from '../../hooks';
import isNilOrEmpty from '../../utils/is-null-or-empty';

import {
  LocationHeader,
  Loading,
  ConfirmDialog,
  NoAccessCard,
} from '../../components';

import type { TSelectPlatforms } from '../../types/TPlatforms';
import type { TRootStateRedux } from '../../types/TRootStateRedux';
import type { TLocationGalleryMedias } from '../../types/TLocationGallery';

import {
  locationPostPlatformsMocked,
} from '../../utils/select-platforms-mocked';

import LocationGalleryFilters from './location-gallery-filters';
import LocationGalleryListing from './location-gallery-listing';
import LocationGalleryMediaDialog from './location-gallery-media-dialog';

import {
  renderAlertArea,
  filteredGalleryMedias,
} from './helpers';

import {
  StyledLocationGallery,
  StyledContent,
  StyledAlert,
  StyledCard,
} from './location-gallery-styles';

const LocationGallery = () => {
  const { enqueueSnackbar } = useSnackbar();

  const [
    selectedPlatform,
  ] = React.useState<TSelectPlatforms>(locationPostPlatformsMocked);
  const [
    selectedGalleryMediaType,
    setSelectedGalleryMediaType,
  ] = React.useState('');
  const [
    selectedGalleryCategories,
    setSelectedGalleryCategories,
  ] = React.useState<string[]>([]);

  const [ofDate, setOfDate] = React.useState<Date | null>(null);
  const [toDate, setToDate] = React.useState<Date | null>(null);

  const [openMediaDialog, setOpenMediaDialog] = React.useState(false);
  const [selectedMediaOnClickedIndex, setSelectedMediaOnClickedIndex] = React.useState(0);

  const [googleAccessToken, setGoogleAccessToken] = React.useState('');
  const [locationGoogleName, setLocationGoogleName] = React.useState<string | null>(null);
  const [isLoadingMedias, setIsLoadingMedias] = React.useState(true);

  const [openModal, setOpenModal] = React.useState(false);

  const [mediaToDelete, setMediaToDelete] = React.useState<string | null>(null);
  const [connectedLocation, setConnectedLocation] = React.useState(false);

  const [googleLocationId, setGoogleLocationId] = React.useState<string | null>(null);
  const [googleAccountId, setGoogleAccountId] = React.useState<string | null>(null);

  const [
    locationGalleryMedias,
    setLocationGalleryMedias,
  ] = React.useState<TLocationGalleryMedias>([]);

  const { activeLocation } = useSelector((state: TRootStateRedux) => state.LocationReducer);

  const {
    userAccessToken,
    userId,
    userProfileName,
  } = useAuth();

  const { hasAccess } = useHasAccess();

  const getGoogleAccessToken = React.useCallback(async () => {
    if (!hasAccess) return;

    const googleAccessTokenResponse = await APIGetValidGoogleAccessTokenFromLocation({
      accessToken: userAccessToken,
      userId,
      userProfile: userProfileName,
      feedbackMessage: enqueueSnackbar,
      locationId: activeLocation.id,
    });

    if (R.isNil(googleAccessTokenResponse)) return;

    const { googleAccessToken: accessTokenGoogle, connectionInfo } = googleAccessTokenResponse;

    setGoogleLocationId(connectionInfo.google_location_id);
    setGoogleAccountId(connectionInfo.google_account_id);
    setGoogleAccessToken(accessTokenGoogle);
  }, [hasAccess, activeLocation]);

  React.useEffect(() => { getGoogleAccessToken(); }, [getGoogleAccessToken]);

  const getLocationMediaList = React.useCallback(async () => {
    if (R.isNil(activeLocation)) {
      setIsLoadingMedias(false);

      return;
    }

    if (R.isEmpty(googleAccessToken)) {
      setIsLoadingMedias(false);
      return;
    }

    if (isNilOrEmpty(googleAccountId)) {
      setIsLoadingMedias(false);

      return;
    }

    if (isNilOrEmpty(googleLocationId)) {
      setIsLoadingMedias(false);
      return;
    }

    const newLocationGoogleName = String(`accounts%2F${googleAccountId}%2Flocations%2F${googleLocationId}`);

    setLocationGoogleName(newLocationGoogleName);

    const locationMediaListResponse = await APIGoogleLocationMediaList({
      parentId: newLocationGoogleName,
      accessToken: googleAccessToken,
      isLoading: setIsLoadingMedias,
    });

    if (R.isNil(locationMediaListResponse)) return;

    const {
      data: {
        mediaItems: locationGalleryMediasResponse,
      },
    } = locationMediaListResponse;

    setLocationGalleryMedias(locationGalleryMediasResponse);
  }, [
    activeLocation,
    googleAccessToken,
  ]);

  React.useEffect(() => { getLocationMediaList(); }, [getLocationMediaList]);

  React.useEffect(() => { setLocationGalleryMedias([]); }, [activeLocation]);

  const handleOpenModal = React.useCallback((mediaToDeleteId: string | null) => {
    setOpenModal(true);
    setMediaToDelete(mediaToDeleteId);
  }, []);

  const handleDeleteMedia = React.useCallback(async (targetMediaIdToDelete: string | null) => {
    if (R.isNil(targetMediaIdToDelete)) {
      enqueueSnackbar('Não foi possível deletar a mídia. Tente novamente!', { variant: 'warning' });

      setOpenModal(false);
      return;
    }

    const deletedGalleryMediaResponse = await APIDeleteGoogleGalleryMedia({
      mediaId: targetMediaIdToDelete.replaceAll('/', '%2F'),
      accessToken: googleAccessToken,
    });

    if (R.isNil(deletedGalleryMediaResponse)) {
      enqueueSnackbar('Não foi possível deletar a mídia. Tente novamente!', { variant: 'warning' });

      setOpenModal(false);

      return;
    }

    const newLocationGalleryMedias = locationGalleryMedias.filter(galleryMedia => galleryMedia.name !== targetMediaIdToDelete);

    setLocationGalleryMedias(newLocationGalleryMedias);
    setOpenModal(false);
  }, [
    googleAccessToken,
    locationGalleryMedias,
  ]);

  const {
    isRenderAlertArea,
    renderAlertAreaMessage,
  } = renderAlertArea({
    locationGoogleName,
    locationGalleryMedias,
    activeLocation,
    isLoadingMedias,
  });

  const getCurrentGalleryMedias = filteredGalleryMedias({
    mediaFormat: selectedGalleryMediaType,
    toDate,
    ofDate,
    categories: selectedGalleryCategories,
  });

  const handleChangeLocation = React.useCallback(async () => {
    if (isNilOrEmpty(googleLocationId)) {
      setConnectedLocation(false);
    } else {
      setConnectedLocation(true);
    }
  }, [activeLocation, googleLocationId]);

  React.useEffect(() => {
    handleChangeLocation();
  }, [handleChangeLocation]);

  return (
    <StyledLocationGallery>
      <LocationHeader
        hideDashboard={!activeLocation?.data_studio}
        linkedLocation={!isNilOrEmpty(googleAccessToken)}
      />

      {isLoadingMedias && <Loading className="is-loading-medias" />}

      {!hasAccess && <NoAccessCard />}

      {hasAccess && (
        <>
          {!connectedLocation && (
            <StyledCard>
              <p>Local não conectado, entre em contato com o operacional para acessar esta funcionalidade</p>
            </StyledCard>
          )}

          {connectedLocation && (
            <StyledContent>
              {isRenderAlertArea && (
                <StyledAlert>
                  {renderAlertAreaMessage}
                </StyledAlert>
              )}

              {!R.isEmpty(locationGalleryMedias) && (
                <LocationGalleryFilters
                  getLocationMediaList={getLocationMediaList}
                  className="gallery-filters"
                  selectedGalleryCategories={selectedGalleryCategories}
                  selectedPlatform={selectedPlatform}
                  selectedGalleryMediaType={selectedGalleryMediaType}
                  setSelectedGalleryCategories={setSelectedGalleryCategories}
                  setSelectedGalleryMediaType={setSelectedGalleryMediaType}
                  setOfDate={setOfDate}
                  setToDate={setToDate}
                  ofDate={ofDate}
                  toDate={toDate}
                />
              )}

              {!R.isEmpty(locationGalleryMedias) && (
                <LocationGalleryListing
                  setOpenMediaDialog={setOpenMediaDialog}
                  data={getCurrentGalleryMedias(locationGalleryMedias)}
                  setSelectedMediaOnClickedIndex={setSelectedMediaOnClickedIndex}
                  handleDeleteMedia={handleOpenModal}
                />
              )}
            </StyledContent>
          )}
        </>
      )}

      {(!R.isEmpty(locationGalleryMedias) && openMediaDialog) && (
        <LocationGalleryMediaDialog
          selectedMediaOnClickedIndex={selectedMediaOnClickedIndex}
          data={getCurrentGalleryMedias(locationGalleryMedias)}
          open={openMediaDialog}
          onClose={setOpenMediaDialog}
          title="Mídia"
          handleDeleteMedia={handleOpenModal}
          googleAccessToken={googleAccessToken}
        />
      )}

      {openModal && (
        <ConfirmDialog
          open={openModal}
          title="Excluir mídia"
          confirmTitle="Excluir"
          confirmType="red"
          onClose={() => setOpenModal(false)}
          onConfirm={() => handleDeleteMedia(mediaToDelete)}
          onCancel={() => setOpenModal(false)}
        >
          Tem certeza que deseja excluir essa mídia?
        </ConfirmDialog>
      )}
    </StyledLocationGallery>
  );
};

export default LocationGallery;
