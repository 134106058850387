import axios, { AxiosRequestConfig } from 'axios';

import { TGetGoogleUserLocations } from '../../types/TGoogle';

export default async function getGoogleUserLocations({
  accessToken,
  userId,
  query = '',
  setIsFetching,
  pageToken = '',
  pageSize,
  filterInvalid,
}: TGetGoogleUserLocations) {
  const requestOptions: AxiosRequestConfig = {
    headers: {
      'access-token': accessToken,
    },
    params: {
      pageSize: pageSize || undefined,
      filterInvalid,
    },
  };

  try {
    if (setIsFetching) setIsFetching(true);

    const { data } = await axios.get(
      `${process.env.REACT_APP_GOOGLE_API_URL}/${userId}?query=${query}&pageToken=${pageToken}`,
      requestOptions,
    );

    return data;
  } catch (err: any) {
    throw err.response;
  } finally {
    if (setIsFetching) setIsFetching(false);
  }
}
