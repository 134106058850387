import axios, { AxiosRequestConfig } from 'axios';
import { TGetAllFieldsByMonitorProps, TGetAllFieldsByMonitorPropsResponse } from './types';

export const getAllFieldsByMonitor = async ({
  monitorId,
  userAccessToken,
  isFetching,
}: TGetAllFieldsByMonitorProps): Promise<TGetAllFieldsByMonitorPropsResponse> => {
  try {
    if (isFetching) isFetching(true);
    const apiPath = `${process.env.REACT_APP_SYNC_PLATFORM_MONITORING_FIELDS}/monitor-fields/${monitorId}`;

    const requestOptions: AxiosRequestConfig = {
      headers: {
        Authorization: `Bearer ${userAccessToken}`,
      },
      params: {
        monitorId,
      },
    };

    const { data } = await axios.get(apiPath, requestOptions);

    return data;
  } catch (err) {
    return null;
  } finally {
    if (isFetching) isFetching(false);
  }
};
