import styled from 'styled-components';
import { Paper } from '@material-ui/core';
import { shade } from 'polished';
import {
  Visibility,
  CalendarToday,
} from '@material-ui/icons';

import { devices } from '../../../assets/styles/devices';

export const StyledGalleryListing = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  row-gap: 2rem;
  padding-bottom: 6rem;

  @media ${devices.tablet} {
    grid-template-columns: repeat(2, 1fr);
    column-gap: 2rem;
  }

  @media ${devices.tabletLandscape} {
    grid-template-columns: repeat(3, 1fr);
  }

  @media ${devices.fullhd} {
    grid-template-columns: repeat(4, 1fr);
  }
`;

export const StyledMediaCard = styled(Paper)`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  border-radius: 0.5rem;
  overflow: hidden;
  position: relative;
`;

export const StyledMediaCardMedia = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 20rem;
  max-height: 40rem;
  position: relative;
  background: ${props => shade(0.1, props.theme.colors.white)};
  font-size: 1.2rem;
  overflow: hidden;
  cursor: pointer;

  @media ${devices.desktop} {
    height: 30rem;
  };

  @media ${devices.fullhd} {
    height: 35rem;
  };

  &:hover {
    img {
      transform: scale(1.1);
    }
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: .5s;
  }
`;

export const StyledMediaCardMediaOverlay = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 2;
  cursor: pointer;
  
  background: -moz-linear-gradient(top,  rgba(0,0,0,0) 0%, rgba(0,0,0,0) 2%, rgba(0,0,0,0.9) 100%);
  background: -webkit-linear-gradient(top,  rgba(0,0,0,0) 0%,rgba(0,0,0,0) 2%,rgba(0,0,0,0.9) 100%);
  background: linear-gradient(to bottom,  rgba(0,0,0,0) 0%,rgba(0,0,0,0) 2%,rgba(0,0,0,0.9) 100%); 
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00000000', endColorstr='#e6000000',GradientType=0 );
`;

export const StyledMediaCardFooter = styled.footer`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 2rem;
  background: ${props => props.theme.colors.white};
  width: 100%;
`;

export const StyledLineInfo = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  border-bottom: 0.1rem dashed ${props => shade(0.1, props.theme.colors.white)};
  margin-bottom: 1rem;
  padding-bottom: 1rem;

  &:last-child {
    margin-bottom: 0;
    padding-bottom: 0;
    border-bottom: 0;
  }

  .gallery-card-date {
    font-size: 1.4rem;
    font-weight: 500;
    display: flex;
    align-items: center;
  }

  .gallery-categories {
    font-size: 1.5rem;
    display: flex;
    align-items: center;

    b {
      margin-right: 0.5rem;
    }
  }

  .gallery-views {
    display: flex;
    align-items: center;
  }
`;

export const StyledCalendarIcon = styled(CalendarToday)`
  margin-right: 1rem;
  font-size: 1.8rem;
`;

export const StyledVisibilityIcon = styled(Visibility)`
  font-size: 1.8rem;
  margin-right: 1rem;
`;
