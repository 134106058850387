import axios from 'axios';

export default async function createCompanyGroup({
  accessToken,
  name,
  description,
  website,
  owner_user_id,
  main_responsible_id,
  created_by = 1,
  setTokenLikeExpired,
  feedbackMessage,
  setIsCreatingCompany,
}) {
  try {
    if (setIsCreatingCompany) setIsCreatingCompany(true);
    if (feedbackMessage) feedbackMessage('Criando Grupo...', { variant: 'warning' });

    const { data } = await axios.post(
      `${process.env.REACT_APP_COMPANY_GROUPS_API_URL}`,
      {
        name,
        description,
        website,
        owner_user_id,
        main_responsible_id,
        created_by,
      },
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      },
    );

    if (feedbackMessage) feedbackMessage('Grupo criado com sucesso', { variant: 'success' });

    return data;
  } catch (err) {
    if (err.response?.status === 401 && setTokenLikeExpired) setTokenLikeExpired();

    console.log(err);

    return err;
  } finally {
    if (setIsCreatingCompany) setIsCreatingCompany(false);
  }
}
