import styled from 'styled-components';
import { devices } from '../../../../../assets/styles/devices';

export const StyledGoogleLoggedAccountWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 2rem;

  @media ${devices.tabletLandscape} {
    flex: 1;
    align-items: flex-start;
  }

  /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#e8e8e8+0,ffffff+18 */
  background: rgb(232,232,232); /* Old browsers */
  background: -moz-linear-gradient(top,  rgba(232,232,232,1) 0%, rgba(255,255,255,1) 18%); /* FF3.6-15 */
  background: -webkit-linear-gradient(top,  rgba(232,232,232,1) 0%,rgba(255,255,255,1) 18%); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to bottom,  rgba(232,232,232,1) 0%,rgba(255,255,255,1) 18%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#e8e8e8', endColorstr='#ffffff',GradientType=0 ); /* IE6-9 */  
`;

export const StyledInfoWrapper = styled.div`
  display: flex;
  flex-direction: column;

  p {
    margin: 0;
    font-size: 1.4rem;
  }

  b {
    font-size: 1.5rem;
  }
`;
export const StyledLogoutButton = styled.button`
  border: none;
  border-radius: 20px;
  background-color: ${props => (props.theme.colors.red)};
  padding: 0 2rem;
  font-size: 1.2rem;
  color: ${props => props.theme.colors.white};
  height: 3rem;
  margin-top: 1rem;
  
  @media ${devices.tablet} {
    width: fit-content;
  }
`;
