export const starRating = [
  1,
  2,
  3,
  4,
  5,
];

export const starRatingInNumber = {
  ONE: 1,
  TWO: 2,
  THREE: 3,
  FOUR: 4,
  FIVE: 5,
};

export const defaultResponsesMocked = [
  'Muito obrigado por sua avaliação [[Fulano]] É bom saber que você ficou satisfeito com o nosso serviço. Nós da Equipe do Vereador Rodrigo Nobrega ficamos gratos por sua preferência.',
  'Value, obrigado por sua avaliação [[Fulano]] É bom saber que você ficou satisfeito com o nosso serviço. Nós da Equipe do Vereador Rodrigo Nobrega ficamos gratos por sua preferência.',
  'Ai sim, obrigado por sua avaliação [[Fulano]] É bom saber que você ficou satisfeito com o nosso serviço. Nós da Equipe do Vereador Rodrigo Nobrega ficamos gratos por sua preferência.',
];
