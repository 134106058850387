import { isEmpty, isNil } from 'ramda';

import type {
  TBillingType,
  TIsValidServiceFields,
  TMoney,
  TServiceItem,
} from '../../../types/TLocationService';

export type TBillingTypeOption = {
  id: number,
  name: TBillingType,
}

export const billingTypes: TBillingTypeOption[] = [
  {
    id: 1,
    name: 'Sem Preço',
  },
  {
    id: 2,
    name: 'Grátis',
  },
  {
    id: 3,
    name: 'Fixo',
  },
];

export const formatServicePriceValue = (
  servicePrice: string | undefined,
  billingType: TBillingType,
): TMoney | null => {
  if (billingType === 'Grátis') {
    return {
      currencyCode: 'BRL',
      units: '0',
      nanos: 0,
    };
  }

  if (isNil(servicePrice)) return null;

  const [units, nanos] = servicePrice.split(',');

  if (!isNil(nanos)) {
    const formattedNanos = parseInt(nanos) * 10000000;

    return {
      currencyCode: 'BRL',
      units,
      nanos: formattedNanos,
    };
  }

  return {
    currencyCode: 'BRL',
    units,
  };
};

export const isValidFields = (serviceData: TIsValidServiceFields): boolean => {
  const {
    serviceName,
    serviceNameLength,
    billingType,
    servicePrice,
    serviceDescriptionLength,
    feedbackMessage,
  } = serviceData;

  if (isEmpty(serviceName)) {
    feedbackMessage('Nome de serviço não pode ser vazio!', { variant: 'warning' });
    return false;
  }

  if (serviceNameLength > 120) {
    feedbackMessage('Nome de serviço possui caracteres que excedem o limite permitido!', { variant: 'warning' });
    return false;
  }

  if (serviceDescriptionLength > 300) {
    feedbackMessage('Descrição de serviço possui caracteres que excedem o limite permitido!', { variant: 'warning' });
    return false;
  }

  if (billingType === 'Fixo' && (isEmpty(servicePrice) || isNil(servicePrice))) {
    feedbackMessage('Preço de serviço não pode ser vazio!', { variant: 'warning' });
    return false;
  }

  return true;
};

export const isServiceDoesNotExist = (serviceName: string, serviceItems: TServiceItem[]) => {
  const findServiceResult = serviceItems.find((serviceItem) => {
    const { freeFormServiceItem, structuredServiceItem } = serviceItem;
    const displayName =
      freeFormServiceItem?.label?.displayName ||
      structuredServiceItem?.displayName;
    return displayName?.toUpperCase() === serviceName.toUpperCase();
  });

  if (isNil(findServiceResult)) return true;

  return false;
};

export const getServiceBillingType = (
  servicePrice: TMoney | undefined | null,
): TBillingType => {
  if (isNil(servicePrice)) return 'Sem Preço';

  const { units, nanos } = servicePrice;

  if ((isNil(units) || units === '0') && (isNil(nanos) || nanos === 0)) { return 'Grátis'; }

  return 'Fixo';
};
