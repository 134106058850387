import axios from 'axios';

export default async function addCompanies({
  accessToken,
  userId,
  company_ids,
  setTokenLikeExpired,
  feedbackMessage,
}) {
  try {
    const { data } = await axios.post(
      `${process.env.REACT_APP_AUTH_API_URL}/add/companies/${userId}`,
      { company_ids },
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      },
    );

    return data;
  } catch (err) {
    if (err.response?.status === 401 && setTokenLikeExpired) setTokenLikeExpired();
    if (err.response?.status === 403) feedbackMessage('Falha ao vincular empresa: Você já alcançou o limite de empresas disponíveis', { variant: 'error' });

    return null;
  }
}
