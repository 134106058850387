import styled from 'styled-components';

export const StyledCurrentDates = styled.div`
    display: flex;
    flex-direction: column;
`;

export const StyledSpecialDatesRowListing = styled.ul`
  display: flex;
  flex-direction: column;
  margin: 0;
`;
