import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import * as R from 'ramda';
import { useSelector } from 'react-redux';

import { NoAcess } from '../../pages';

import {
  MainContainer,
  ErrorBoundary,
} from '../../containers';

const PrivateRoute = ({
  currentUser,
  isAuthenticated,
  component: Component,
  adminAndOperatorOnly = false,
  path: routePath = '/',
  ...rest
}) => {
  const hasAccess = React.useCallback(() => {
    if (R.isNil(currentUser)) return false;

    const { profiles } = currentUser;
    const [userProfile] = profiles;

    if (adminAndOperatorOnly && userProfile.name === 'Usuário Padrão') return false;

    return true;
  }, [
    currentUser,
    adminAndOperatorOnly,
  ]);

  React.useEffect(() => {
    if (R.isNil(currentUser)) return;

    const { email: userEmail, name: userName, profiles } = currentUser;
    const [currentProfile] = profiles;

    if (!window.userGuiding) return;

    window.userGuiding.identify(userEmail, {
      nome: userName,
      tipo_cliente: currentProfile.name,
    });
  }, [window, currentUser]);

  return (
    <Route
      path={routePath}
      {...rest}
      render={props => (isAuthenticated()
        ? (
          <ErrorBoundary>
            <MainContainer currentUser={currentUser}>
              {hasAccess() ? <Component {...props} /> : <NoAcess /> }
            </MainContainer>
          </ErrorBoundary>
        )
        : (
          <Redirect to={{
            pathname: '/',
            state: { from: props.location },
          }}
          />
        ))}
    />
  );
};

export default PrivateRoute;
