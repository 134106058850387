import axios from 'axios';

/**
 *
 * @param {*} accessToken
 * @param {*} query
 * @param {*} platform : valores 'google', 'facebook' ou 'foursquare'
 */
export default async function getPlatformCategories({ accessToken, query, platform }) {
  const requestOptions = {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  };

  const { data } = await axios.get(
    `${process.env.REACT_APP_CATEGORIES_API_URL}/from-platform?platform=${platform}&query=${query}`,
    requestOptions,
  );

  return data;
}
