import React, { useCallback, useState } from 'react';
import { debounce } from 'lodash';
import { MUIDataTableOptions } from 'mui-datatables';
import { Table } from '../../../components';
import { tableColumns } from './helpers';
import { StyledInfo, StyledContainer, StyledContent } from './requests-styles';
import RequestsFilter from './requests-filter';
import RequestsRow from './requests-row';
import RequestsDialog from './requests-dialog';
import { useAuth } from '../../../hooks';
import { getAllRequests } from '../../../services/platform-sync-modules/get-all-requests';
import { TPlatformSyncRequestsEntity } from '../../../services/platform-sync-modules/types';
import ActionLogDialog from './action-log-dialog';
import { THandleChangePeriodFilter, THandleChangeSearch } from '../../../types/TSyncPermissions';

const SyncPermissions = () => {
  const { userAccessToken } = useAuth();

  const [company, setCompany] = useState('');
  const [location, setLocation] = useState('');
  const [selectedStatusFilter, setSelectedStatusFilter] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [requestsCount, setRequestsCount] = useState(0);
  const [tableData, setTableData] = useState<any[][]>([]);
  const [ofPeriod, setOfPeriod] = useState<Date | null>(null);
  const [toPeriod, setToPeriod] = useState<Date | null>(null);
  const [isRequestsDialogOpen, setIsRequestsDialogOpen] = useState(false);
  const [isActionLogDialogOpen, setIsActionLogDialogOpen] = useState(false);
  const [selectedRow, setSelectedRow] = useState('');
  const [selectedRowStatus, setSelectedRowStatus] = useState('');
  const [selectedRowLocationId, setSelectedRowLocationId] = useState<number | null>(null);

  const onRequestDialogClose = useCallback(() => {
    setIsRequestsDialogOpen(false);
    setSelectedRow('');
  }, []);

  const onRequestDialogOpen = useCallback((rowId: string) => {
    setIsRequestsDialogOpen(true);
    setSelectedRow(rowId);
  }, []);

  const onActionLogDialogOpen = useCallback((rowId: string) => {
    setIsActionLogDialogOpen(true);
    setSelectedRow(rowId);
  }, []);

  const onActionLogDialogClose = useCallback(() => {
    setIsActionLogDialogOpen(false);
    setSelectedRow('');
  }, []);

  const handleChangeSearch = ({ text, setField }: THandleChangeSearch) => {
    setPage(0);
    setField(text);
  };

  const handleChangePeriodFilter = ({ period, setField }: THandleChangePeriodFilter) => {
    setPage(0);
    setField(period);
  };

  const handleSearchGroupWithDebounce = useCallback(
    debounce((text: string) => handleChangeSearch({ text, setField: setCompany }), 500),
    [],
  );

  const handleSearchLocationWithDebounce = useCallback(
    debounce((text: string) => handleChangeSearch({ text, setField: setLocation }), 500),
    [],
  );

  const tableOptions = React.useMemo(
    (): MUIDataTableOptions => ({
      count: requestsCount,
      search: false,
      page,
      selectableRows: 'none',
    }),
    [requestsCount, page],
  );

  const formattedTableData = useCallback((data: TPlatformSyncRequestsEntity[]) => data.map((syncRequest: TPlatformSyncRequestsEntity) => [
    { columnRef: 'id', value: syncRequest.id, visible: false },
    { columnRef: 'company', value: syncRequest.location.company?.name, visible: true },
    { columnRef: 'location', value: syncRequest.location.name, visible: true },
    { columnRef: 'requester', value: syncRequest.creator_user.name, visible: true },
    { columnRef: 'created_at', value: syncRequest.date_created, visible: true },
    { columnRef: 'status', value: syncRequest.status, visible: true },
    { columnRef: 'request', value: syncRequest.id, visible: true },
  ]), []);

  const formattedDate = useCallback((date: Date | null) => (date
    ? new Date(date).toISOString().split('T').toString()
    : ''),
    []);

  const getRequestsData = useCallback(async () => {
    const syncRequestsResponse = await getAllRequests({
      userAccessToken,
      status: selectedStatusFilter,
      ofPeriod: formattedDate(ofPeriod),
      toPeriod: formattedDate(toPeriod),
      locationName: location,
      companyName: company,
      isFetching: setIsLoading,
      page,
      pageSize,
    });
    if (!syncRequestsResponse) return;
    const newTableData = formattedTableData(syncRequestsResponse[0]);

    const request = syncRequestsResponse[0].find(syncRequest => syncRequest.id === selectedRow);
    if (request) {
      setSelectedRowStatus(request.status);
      setSelectedRowLocationId(request.location.id);
    }

    setTableData(newTableData);
    setRequestsCount(syncRequestsResponse[1]);
  }, [
    company,
    location,
    selectedStatusFilter,
    ofPeriod,
    toPeriod,
    page,
    pageSize,
    selectedRow,
  ]);

  const onRequestUpdate = useCallback(async () => {
    await getRequestsData();
    onRequestDialogClose();
  }, [getRequestsData]);

  React.useEffect(() => {
    getRequestsData();
  }, [getRequestsData]);

  return (
    <StyledContainer>
      <StyledInfo>
        <RequestsFilter
          onChangeCompanyQuery={handleSearchGroupWithDebounce}
          searchCompanyLabel="Empresa:"
          onChangeLocationQuery={handleSearchLocationWithDebounce}
          searchLocationLabel="Local:"
          selectedTypeFilter={selectedStatusFilter}
          setSelectedTypeFilter={(status) => handleChangeSearch({ text: status, setField: setSelectedStatusFilter })}
          ofPeriod={ofPeriod}
          setOfPeriod={(period) => handleChangePeriodFilter({ period, setField: setOfPeriod })}
          toPeriod={toPeriod}
          setToPeriod={(period) => handleChangePeriodFilter({ period, setField: setToPeriod })}
          statusLabel="Status:"
        />
      </StyledInfo>
      <StyledContent>
        <Table
          loading={isLoading}
          title="Solicitações de Sincronização"
          setPage={setPage}
          setPageSize={setPageSize}
          columns={tableColumns}
          data={tableData}
          row={(rowData, rowDataIndex, rowIndex) => (
            <RequestsRow
              rowData={rowData}
              rowId={rowData[0]?.value}
              dataTableOptions={tableOptions}
              onRequestsDialogOpen={onRequestDialogOpen}
              onOpenActionLogDialog={onActionLogDialogOpen}
            />
          )}
          options={tableOptions}
        />

      </StyledContent>
      {isRequestsDialogOpen && (
        <RequestsDialog
          isDialogOpen={isRequestsDialogOpen}
          onClose={onRequestDialogClose}
          requestId={selectedRow}
          onRequestUpdate={onRequestUpdate}
          requestStatus={selectedRowStatus}
          locationId={selectedRowLocationId}
        />
      )}
      {isActionLogDialogOpen && (
        <ActionLogDialog
          isDialogOpen={isActionLogDialogOpen}
          onClose={onActionLogDialogClose}
          requestId={selectedRow}
        />
      )}
    </StyledContainer>
  );
};

export default SyncPermissions;
