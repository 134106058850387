import React from 'react';

import { isEmpty, isNil } from 'ramda';

import { StyledInfosPopover } from './infos-popover-style';
import { InfosListPopover } from './infos-list-popover';
import { InfosSectionPopover } from './infos-section-popover';

import type { TLinks } from '../../../../types/TLinks';
import type { TDictionaryWithOrder } from '../../../../types/TDictionaryWithOrder';

type TInfoPopoverProps = {
  id: string | undefined,
  data: any,
}

const InfosPopover = ({
  id,
  data,
}: TInfoPopoverProps) => {
  const [openedBasicInfos, setOpenedBasicInfos] = React.useState(false);
  const [openedLinksInfos, setOpenedLinksInfos] = React.useState(false);
  const [openedWorkingHoursInfos, setOpenedWorkingHoursInfos] = React.useState(false);

  const [workingHoursData, setWorkingHoursData] = React.useState([]);

  const [specialDatesData, setSpecialDatesData] = React.useState([]);
  const [openedSpecialDates, setOpenedSpecialDates] = React.useState(false);

  const formatDataToList = React.useCallback((dataToConvert) => {
    if (isNil(dataToConvert) || isEmpty(dataToConvert)) return [];
    const fields = Object.keys(dataToConvert);
    const fieldsTreated = fields.map(
      field => {
        let fieldName = field;
        let value = dataToConvert[field];
        let fieldOrder = 0;

        if (Array.isArray(value)) {
          const names: string[] = [];
          value.forEach((valueArrayItem) => {
            if (valueArrayItem.name) names.push(valueArrayItem.name);
          });
          value = names.join(', ');
        }

        const linksDictionary: TDictionaryWithOrder<keyof TLinks> = {
          website: { name: 'Website', order: 1 },
          facebook: { name: 'Facebook', order: 2 },
          instagram: { name: 'Instagram', order: 3 },
          twitter: { name: 'Twitter', order: 4 },
          youtube: { name: 'Youtube', order: 5 },
          foursquare: { name: 'Foursquare', order: 6 },
          linkedin: { name: "Linkedin", order: 7 },
          yelp: { name: 'Yelp', order: 8 },
          menu: { name: 'Cardápio', order: 9 },
          schedule: { name: 'Agendamento', order: 10 },
          advance_order: { name: 'Pedido Antecipado', order: 11 },
          reservations: { name: 'Reservas', order: 12 },
        }

        const basicInfosDictionary: TDictionaryWithOrder<any> = {
          main_phone: { name: 'Telefone principal', order: 1 },
          secondary_phone: { name: 'Telefone secundário', order: 2 },
          short_description: { name: 'Descrição curta', order: 3 },
          long_description: { name: 'Descrição', order: 4 },
          google_categories: { name: 'Categorias do Google', order: 5 },
          facebook_categories: { name: 'Categorias do Facebook', order: 6 },
          foursquare_categories: { name: 'Categorias do Foursquare', order: 7 },
        };

        const dictionary: TDictionaryWithOrder<any> = {
          ...linksDictionary,
          ...basicInfosDictionary,
        }

        if (dictionary[field]) {
          fieldName = dictionary[field].name;
          fieldOrder = dictionary[field].order;
        }
        return {
          name: fieldName,
          value,
          order: fieldOrder,
        };
      },
    );

    return fieldsTreated.sort(
      (firstField, secondField) => firstField.order - secondField.order,
    );
  }, []);

  const formatWorkingHoursToList = React.useCallback(
    (dataToConvert) => {
      if (!dataToConvert) return [];

      const parsedWorkingHoursData = dataToConvert
        .filter((workingHour: any) => workingHour.will_update)
        .map((workingHour: any) => {
          const dayTitle = workingHour.day_title;

          const mergedTimes = workingHour.is_24_hours ? '24 horas' : workingHour.times.map((time: any, index: any) => {
            let text = `${time.open}-${time.close}`;
            if (index !== workingHour.times.length - 1) {
              text += ',';
            }

            return text;
          });

          const dataFormated = {
            name: dayTitle,
            value: workingHour.is_open ? mergedTimes : 'Fechado',
          };

          return dataFormated;
        });

      setWorkingHoursData(parsedWorkingHoursData);
    }, [],
  );

  const formatSpecialDatesToList = React.useCallback(
    (dataToConvert) => {
      if (!dataToConvert) return [];

      const parsedSpecialDatesData = dataToConvert
        .map((specialDate: any) => {
          const mergedTimes = specialDate.is_24_hours ? '24 horas' : specialDate.times.map((time: any, index: any) => {
            let text = `${time.open}-${time.close}`;
            if (index !== specialDate.times.length - 1) {
              text += ',';
            }

            return text;
          });

          const dataFormated = {
            name: `${specialDate.name} - ${specialDate.date}`,
            value: specialDate.is_open ? mergedTimes : 'Fechado',
          };

          return dataFormated;
        });

      setSpecialDatesData(parsedSpecialDatesData);
    }, [],
  );

  React.useEffect(() => {
    const workingHours = data.working_hours || data.workingHours;
    const specialDates = data.special_dates || data.specialDates;

    formatWorkingHoursToList(workingHours);
    formatSpecialDatesToList(specialDates);
  }, [formatWorkingHoursToList]);

  return (
    <StyledInfosPopover
      id={id}
    >
      <>
        {
          !isEmpty(formatDataToList(data.basic_infos))
          && (
            <>
              <InfosSectionPopover
                title="Informações Básicas"
                open={openedBasicInfos}
                setOpen={() => setOpenedBasicInfos(!openedBasicInfos)}
              />

              {
                openedBasicInfos && (
                  <InfosListPopover
                    data={formatDataToList(data.basic_infos)}
                  />
                )
              }
            </>
          )
        }

        {
          !isEmpty(formatDataToList(data.links))
          && (
            <>
              <InfosSectionPopover
                title="Links"
                open={openedLinksInfos}
                setOpen={() => setOpenedLinksInfos(!openedLinksInfos)}
              />

              {
                openedLinksInfos && (
                  <InfosListPopover
                    data={formatDataToList(data.links)}
                  />
                )
              }
            </>
          )
        }

        {
         !isEmpty(workingHoursData) && (
         <InfosSectionPopover
           title="Horário de funcionamento"
           open={openedWorkingHoursInfos}
           setOpen={() => setOpenedWorkingHoursInfos(!openedWorkingHoursInfos)}
         />
         )
        }
        {
          openedWorkingHoursInfos && (
            <InfosListPopover
              data={workingHoursData}
            />
          )
        }

        {
         !isEmpty(specialDatesData) && (
         <InfosSectionPopover
           title="Datas especiais"
           open={openedSpecialDates}
           setOpen={() => setOpenedSpecialDates(!openedSpecialDates)}
         />
         )
        }
        {
          openedSpecialDates && (
            <InfosListPopover
              data={specialDatesData}
            />
          )
        }
      </>
    </StyledInfosPopover>
  );
};

export { InfosPopover };
