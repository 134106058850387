import React from 'react';
import { IconButton } from '@material-ui/core';
import { Clear } from '@material-ui/icons';

import { StyledRow } from './day-row-styles';

import Input from '../../input';

const DayRow = ({
  day, label, openName, openValue, closeName, closeValue, onChange, onClearClicked,
}) => (
  <StyledRow>
    <div style={{ width: '10em' }}>
      <text>{label}</text>
    </div>
    <div>
      <Input
        border
        value={openValue}
        inputOptions={{ type: 'time', name: openName }}
        onChange={onChange}
      />
    </div>
    <div>
      <Input
        border
        value={closeValue}
        inputOptions={{
          name: closeName,
          type: 'time',
        }}
        onChange={onChange}
      />
    </div>
    <IconButton
      onClick={() => {
        onClearClicked(day);
      }}
    >
      <Clear />
    </IconButton>
  </StyledRow>
);

export default DayRow;
