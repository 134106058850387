import axios from 'axios';

export default async function createHandler(
  accessToken,
  name,
  created_by = null,
) {
  const requestOptions = {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  };

  try {
    const { data } = await axios.post(
      `${process.env.REACT_APP_HANDLERS_API_URL}`,
      created_by ? { name, created_by } : { name },
      requestOptions,
    );

    console.log(data);
    return data;
  } catch (err) {
    console.log(err);
    return err;
  }
}
