import api from './api';

const facebookAPIGetPageLocationInfo = async ({
  userToken,
  pageID,
  setIsFetching,
}) => {
  try {
    if (setIsFetching) setIsFetching(true);

    const response = await api({
      method: 'GET',
      url: `${pageID}?fields=locations{access_token,location,name,category_list,emails,hours,phone,company_overview,directed_by,personal_info,single_line_address}`,
      headers: {
        Authorization: `Bearer ${userToken}`,
      },
    });

    
    if (response?.locations?.data) {
      const { data } = response?.locations;

      return data;
    } else {
      return null
    }
  } catch (err) {
    console.log(err);
  } finally {
    if (setIsFetching) setIsFetching(false);
  }
};

export default facebookAPIGetPageLocationInfo;
