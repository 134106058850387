import axios from 'axios';

export default async function removeLocationGroup({
  accessToken,
  id,
  setTokenLikeExpired,
}) {
  try {
    const { data } = await axios.delete(
      `${process.env.REACT_APP_LOCATION_GROUPS_API_URL}/${id}`,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      },
    );

    return data;
  } catch (err) {
    if (err.response?.status === 401 && setTokenLikeExpired) setTokenLikeExpired();

    return null;
  }
}
