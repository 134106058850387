import React from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Helmet } from 'react-helmet';
import { useSnackbar } from 'notistack';

import { isEmpty, sortBy, prop } from 'ramda';
import axios from 'axios';
import {
  Loading,
  Input,
  Button,
} from '../../components';

import { Creators as AuthActions } from '../../redux/ducks/auth';
import { Creators as RulesActions } from '../../redux/ducks/rules';

import { emailValidate as emailIsValid } from '../../utils/fields-validate';

import getProfiles from '../../services/profiles/getProfiles';

import hublocalLogo from '../../assets/images/hublocal-logo.png';

import {
  StyledLogin,
  StyledForm,
  StyleInputGroup,
  StyledForgotPassword,
  StyledButtonsGroup,
} from './login-styles';
import sendValidationMail from '../../services/users/sendUserValidationMail';

import { translations } from '../../translations';
import getProfileFunctions from '../../services/profiles/getProfileFunctions';
import getProfileMenu from '../../services/profiles/getProfileMenu';

import validateUser from '../../services/users/validateUser';
import { registerLoginHistory } from '../../services/login-history/register-login-history';

const Login = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  const { language, user } = useSelector((state) => state.AuthReducer);
  const { login: loginTranslations } = translations[language];

  const params = useParams();
  const [isFetching, setIsFetching] = React.useState(false);
  const [showPassword, setShowPassword] = React.useState(false);
  const [validatedUser, setValidatedUser] = React.useState(false);

  const { enqueueSnackbar } = useSnackbar();

  const setTokenLikeExpired = () => {
    dispatch(AuthActions.setUser({ ...user, accessToken: 'expired' }));
  };

  React.useEffect(() => {
    if (user?.accessToken === 'expired') {
      enqueueSnackbar('Sua sessão em nossa plataforma expirou. Faça o seu login novamente!', { variant: 'warning' });
    }

    const { token } = params;
    if (token) {
      const validateUserResponse = validateUser(token);

      if (!validateUserResponse) return;
      setValidatedUser(true);
    }
  }, []);

  const emailRef = React.useRef(null);
  const [emailValidate, setEmailValidate] = React.useState({
    visible: false,
    type: 'error',
    message: '',
  });

  const passwordRef = React.useRef(null);
  const [passwordValidate, setPasswordValidate] = React.useState({
    visible: false,
    type: 'error',
    message: '',
  });

  const handleChangeLanguage = ({ target: { value } }) => {
    dispatch(AuthActions.setLanguage(value));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const emailFieldValue = emailRef.current.value;
    const passwordFieldValue = passwordRef.current.value;

    if (isEmpty(emailFieldValue)) {
      setEmailValidate((oldState) => ({
        ...oldState,
        visible: true,
        message: loginTranslations.fill_in_the_field,
      }));
      return;
    }

    if (!emailIsValid(emailFieldValue)) {
      setEmailValidate((oldState) => ({
        ...oldState,
        visible: true,
        message: loginTranslations.invalid_format_email,
      }));
      return;
    }
    setEmailValidate((oldState) => ({
      ...oldState,
      visible: false,
      message: '',
    }));

    if (isEmpty(passwordFieldValue)) {
      setPasswordValidate((oldState) => ({
        ...oldState,
        visible: true,
        message: loginTranslations.fill_in_the_field,
      }));
      return;
    }

    setPasswordValidate((oldState) => ({
      ...oldState,
      visible: false,
      message: '',
    }));

    try {
      setIsFetching(true);

      const { data } = await axios.post(
        `${process.env.REACT_APP_AUTH_API_URL}/signin`,
        {
          email: emailFieldValue,
          password: passwordFieldValue,
        },
      );

      const userProfile = data.user.profiles[0];

      await registerLoginHistory({
        userId: data.user.id,
        accessToken: data.accessToken
      });

      const { functions: profileFunctions } = await getProfileFunctions({
        accessToken: data.accessToken,
        profileId: userProfile.id,
        setTokenLikeExpired,
      });

      const { menu: profileMenu } = await getProfileMenu({
        accessToken: data.accessToken,
        profileId: userProfile.id,
        setTokenLikeExpired,
      });

      const sortById = sortBy(prop('id'));
      const sortedGroups = sortById(profileMenu.groups.map(group => {
        const groupFunction = group.functions;
        const sortedFunctions = sortById(groupFunction);

        return {
          ...group,
          functions: sortedFunctions,
        };
      }));

      const formattedProfile = {
        ...userProfile,
        functions: profileFunctions,
        menu: {
          ...profileMenu,
          groups: sortedGroups,
        },
      };

      const formattedData = { ...data, user: { ...data.user, profiles: [formattedProfile] } };

      const rules = {};
      const profiles = await getProfiles({ accessToken: data.accessToken, setTokenLikeExpired });

      profiles[0].forEach(async (element) => {
        const { functions } = await getProfileFunctions({
          accessToken: data.accessToken,
          profileId: element.id,
          setTokenLikeExpired,
        });

        const funcs = [];
        functions.forEach((func) => {
          funcs.push(func.full_name);
        });
        rules[element.name] = { static: funcs };
        dispatch(RulesActions.setRules(rules));
      });

      dispatch(AuthActions.setUser(formattedData));
    } catch (err) {
      const variantError = 'error';
      const variantWarning = 'warning';

      if (err?.response?.status === 401) enqueueSnackbar('Usuário ou senha inválidos', { variant: variantError });

      if (err?.response?.status === 401 && err?.response?.data?.status === 'USER_NOT_VALIDATED') {
        enqueueSnackbar('O Usuário ainda não foi validado. Acesse o link que enviamos para o seu e-mail para validá-lo', { variant: variantWarning });
        sendValidationMail(err?.response?.data?.userId);
      }

      if (err?.response?.status === 400) enqueueSnackbar('A Senha está num formato inválido', { variant: variantError });
    } finally {
      setIsFetching(false);
    }
  };

  return (
    <StyledLogin>
      <Helmet>
        <meta property="og:title" content="Login - SaaS Hublocal" />
        <meta property="og:description" content="Faça login em nossa plataforma." />

        <title>Login - SaaS HubLocal</title>
      </Helmet>

      {validatedUser && (<h2>Seu cadastro foi validado, agora você deve inserir seus dados abaixo:</h2>)}
      <img src={hublocalLogo} alt="Hublocal SaaS" />

      <StyledForm onSubmit={(e) => handleSubmit(e)}>
        <StyleInputGroup>
          <Input
            fieldRef={emailRef}
            inputOptions={{
              type: 'text',
              name: 'user',
            }}
            inputValidate={emailValidate}
            label={loginTranslations.user}
          />
          <Input
            fieldRef={passwordRef}
            inputOptions={{
              type: `${showPassword ? 'text' : 'password'}`,
              name: 'password',
            }}
            password
            showPassword={showPassword}
            changeShowPassword={setShowPassword}
            inputValidate={passwordValidate}
            label={loginTranslations.password}
          />
          <StyledForgotPassword to="/forgot-password">
            {loginTranslations.forgot_password}
          </StyledForgotPassword>
        </StyleInputGroup>

        <StyledButtonsGroup>
          <Button
            className="buttons-group-button"
            type="submit"
            disabled={isFetching}
          >
            {isFetching ? (
              <Loading />
            ) : loginTranslations.log_in}
          </Button>

        </StyledButtonsGroup>
      </StyledForm>
    </StyledLogin>
  );
};

export default Login;
