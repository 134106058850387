import * as R from 'ramda';

export const getUserAssociatedRegisters = async ({
  APIToGetUserAssociated,
  accessToken,
  setGetUserAssociatedAccounts,
  userId,
  dataKeyName,
}) => {
  let userGoogleAccounts = [];

  const recurFuntion = async (nextPageToken) => {
    if (R.isNil(nextPageToken)) return userGoogleAccounts;

    const registerResponse = await APIToGetUserAssociated({
      accessToken,
      setGetUserAssociatedAccounts,
      pageToken: nextPageToken,
      userId,
    });

    if (R.isEmpty(registerResponse) || R.isNil(registerResponse)) return userGoogleAccounts;

    userGoogleAccounts = [
      ...userGoogleAccounts,
      ...registerResponse[dataKeyName],
    ];

    const { nextPageToken: nextPageTokenResponse } = registerResponse;

    return recurFuntion(nextPageTokenResponse);
  };

  const userAssociatedAccountsAll = await recurFuntion('');

  return userAssociatedAccountsAll.filter(register => !R.isNil(register));
};
