import React from 'react';

import { IconButton } from '..';
import { iconByStatus, syncEntityNames } from './helpers';
import { TSyncMonitoringStatusIconProps } from './types';

export default ({ status, syncType }: TSyncMonitoringStatusIconProps) => {
  const syncEntityName = syncEntityNames[syncType];

  return (
    <IconButton
      tooltip={iconByStatus[status || iconByStatus.NON_AVAILABLE].tooltip(syncEntityName)}
      icon={iconByStatus[status || iconByStatus.NON_AVAILABLE].icon}
      className="status-icon"
      isSuccess={iconByStatus[status || iconByStatus.NON_AVAILABLE].isSuccess}
      isWarning={iconByStatus[status || iconByStatus.NON_AVAILABLE].isWarning}
      isError={iconByStatus[status || iconByStatus.NON_AVAILABLE].isError}
      label={iconByStatus[status || iconByStatus.NON_AVAILABLE].label}
      tooltipVariant={iconByStatus[status || iconByStatus.NON_AVAILABLE].tooltipVariant}
    />
  );
};
