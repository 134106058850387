import axios from 'axios';

export default async function deleteGroup(accessToken, groupId) {
  const requestOptions = {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  };
  try {
    await axios.delete(
      `${process.env.REACT_APP_GROUPS_API_URL}/${groupId}`,
      requestOptions,
    );

    return true;
  } catch (err) {
    console.log(err);

    return null;
  }
}
