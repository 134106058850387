import React from 'react';
import { useHistory } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { useDispatch, useSelector } from 'react-redux';
import { debounce } from 'lodash';
import * as R from 'ramda';

import { Creators as AuthActions } from '../../redux/ducks/auth';

import {
  HeaderToolbar,
  BlockWithActionButton,
  CompanyInfo,
  ResponsibleInfo,
  HealderToolbarAction,
  GroupItems,
} from '../../components';

import { saveCompanyGroupInDB } from './helper';

import {
  StyledCreateCompany,
  StyledContentMain,
  StyledContentAside,
  StyledContent,
} from './create-company-group-styles';

import getUserCompaniesPaginated from '../../services/companies/getUserCompaniesPaginated';
import getCompanies from '../../services/companies/getCompanies';

const CreateCompanyGroup = () => {
  const {
    push: pushToScreen,
  } = useHistory();

  const reduxDispatch = useDispatch();

  const {
    user: {
      accessToken: userHublocalToken,
      user,
    },
  } = useSelector(state => state.AuthReducer);

  const {
    id: userHublocalID,
    profiles,
  } = user || {};

  const { name: userProfileType } = profiles[0] || {};

  const { enqueueSnackbar } = useSnackbar();

  const responsibleData = React.useRef(null);
  const companyInfoData = React.useRef(null);

  const [isCreating, setIsCreating] = React.useState(false);
  const [companiesToAdd, setCompaniesToAdd] = React.useState([]);
  const [addedCompanies, setAddedCompanies] = React.useState([]);
  const [companiesToAddPage, setCompaniesToAddPage] = React.useState(0);
  const [addedCompaniesPage, setAddedCompaniesPage] = React.useState(0);
  const [companiesToAddQuery, setCompaniesToAddQuery] = React.useState('');
  const [addedCompaniesQuery, setAddedCompaniesQuery] = React.useState('');
  const [companiesToAddPageSize, setCompaniesToAddPageSize] = React.useState(10);
  const [addedCompaniesPageSize, setAddedCompaniesPageSize] = React.useState(10);
  const [companiesToAddPageQuantity, setCompaniesToAddPageQuantity] = React.useState(1);
  const [addedCompaniesPageQuantity, setAddedCompaniesPageQuantity] = React.useState(1);

  const handleChangeCompaniesToAddSearch = debounce(text => setCompaniesToAddQuery(text), 500);
  const handleChangeAddedCompaniesSearch = debounce(text => setAddedCompaniesQuery(text), 500);

  const setTokenLikeExpired = () => {
    reduxDispatch(AuthActions.setUser({ ...user, accessToken: 'expired' }));
  };

  const handlePushToMyCompanies = () => pushToScreen('/my-company-groups');

  const handleGetUserCompanies = React.useCallback(async () => {
    let getCompaniesResponse = null;
    if (user.profiles[0].name === 'Usuário Padrão') {
      getCompaniesResponse = await getUserCompaniesPaginated({
        accessToken: userHublocalToken,
        userId: user.id,
        page: companiesToAddPage,
        query: companiesToAddQuery,
        pageSize: companiesToAddPageSize,
        setTokenLikeExpired,
      });
    } else {
      getCompaniesResponse = await getCompanies({
        accessToken: userHublocalToken,
        page: companiesToAddPage,
        query: companiesToAddQuery,
        pageSize: companiesToAddPageSize,
        setTokenLikeExpired,
      });
    }

    if (R.isNil(getCompaniesResponse)) return;

    const [data, amount] = getCompaniesResponse;

    const addedCompanyIds = addedCompanies.map(element => element.id);

    setCompaniesToAdd(data.filter((element) => !addedCompanyIds.includes(element.id)));
    setCompaniesToAddPageQuantity(Math.ceil(amount / companiesToAddPageSize));
  }, [companiesToAddQuery, companiesToAddPage]);

  React.useEffect(() => { handleGetUserCompanies(); }, [handleGetUserCompanies]);

  const checkItemAlreadyInGroup = (item) => {
    const foundCompany = addedCompanies.filter((company) => company.id === item.id);

    return foundCompany.length > 0;
  };

  const handleCompanyToAddClicked = (company) => {
    if (!checkItemAlreadyInGroup(company)) {
      setAddedCompanies([...addedCompanies, company]);
      return;
    }
    enqueueSnackbar('Esta empresa já está inserida no grupo', { variant: 'warning' });
  };

  const handleAddedCompanyClicked = (company) => {
    setAddedCompanies(addedCompanies.filter((item) => company.id !== item.id));

    if (!R.isNil(companiesToAdd.find(removedCompany => removedCompany.id === company.id))) return;

    setCompaniesToAdd([...companiesToAdd, company]);
  };

  const handleSave = React.useCallback(() => {
    const { responsibles, reset: resetResponsibles } = responsibleData.current;
    const { company } = companyInfoData.current;

    const addedCompaniesIds = addedCompanies.map(element => element.id);

    const companyChecks = company.map(companyField => companyField.firedCheck);
    const companyResets = company.map(companyField => companyField.reset);
    const companyValues = company.map(companyField => companyField.getValue());

    if (companyChecks.every(companyFiredCheck => companyFiredCheck())) {
      if (!responsibles.length) {
        enqueueSnackbar(
          'Para cadastrar um grupo é necessário ter pelo menos um responsável',
          { variant: 'error' },
        );
        return;
      }

      const companyGroupData = {
        name: companyValues[0],
        website: companyValues[1],
        is_active: companyValues[2],
      };

      saveCompanyGroupInDB({
        companyData: companyGroupData,
        responsibles,
        setIsCreating,
        setTokenLikeExpired,
        userHublocalToken,
        userHublocalID,
        feedbackMessage: enqueueSnackbar,
        resetCompanyFields: () => companyResets.map(companyReset => companyReset()),
        resetResponsibles: () => resetResponsibles(),
        redirect: handlePushToMyCompanies,
        companiesToAdd: addedCompaniesIds,
      });
    }
  }, [responsibleData, companyInfoData, addedCompanies]);

  return (
    <StyledCreateCompany>
      <HeaderToolbar
        title="Adicionar Grupo empresarial"
      >
        <HealderToolbarAction
          title="Lista de grupos"
          icon="List"
          onClick={handlePushToMyCompanies}
        />
      </HeaderToolbar>
      <StyledContent>
        <StyledContentMain>
          <ResponsibleInfo
            className="block-form"
            ref={responsibleData}
            isCompanyGroup
          />
          <CompanyInfo
            ref={companyInfoData}
            initialData={{
              nameLabel: 'Nome do Grupo',
              websiteLabel: 'Website do grupo',
              isActiveLabel: 'Está ativa?',
            }}
            className="block-form"
            title="Informações básicas do grupo"
            userProfileType={userProfileType}
          />
          <GroupItems
            mainTitle="Adicionar empresas"
            itemsToAdd={companiesToAdd}
            addedItens={addedCompanies.filter((company) => company.name.toLowerCase().includes(addedCompaniesQuery.toLowerCase()))}
            itemsToAddPagesQuantity={companiesToAddPageQuantity}
            addedItemsPagesQuantity={addedCompaniesPageQuantity}
            primaryTextKey="name"
            onItemToAddClicked={handleCompanyToAddClicked}
            onAddedItemClicked={handleAddedCompanyClicked}
            setQueryItensToAddPage={setCompaniesToAddQuery}
            onAddedItemsInputChanged={({ target: { value } }) => handleChangeAddedCompaniesSearch(value)}
            setItensToAddPage={setCompaniesToAddPage}
            currentItensToAddPage={companiesToAddPage}
            inputLabelToAdd="Pesquisar empresas"
            inputLabelAdded="Pesquisar empresas desse grupo"
            buttonTooltipAddTitle="Adicionar Empresa"
            buttonTooltipRemoveTitle="Remover Empresa"
            emptyItemsToAdd="Nenhuma empresa a adicionar"
            emptyAddedItems="Nenhuma empresa adicionada"
            isCreation
          />
        </StyledContentMain>
        <StyledContentAside>
          <BlockWithActionButton
            onClick={handleSave}
            titleButton="Criar Grupo"
            loading={isCreating}
          />

        </StyledContentAside>

      </StyledContent>

    </StyledCreateCompany>
  );
};

export default CreateCompanyGroup;
