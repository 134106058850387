import { createActions, createReducer } from 'reduxsauce';

export const { Types, Creators } = createActions({
  setGuestRules: ['guestRules'],
});

const initialState = {
  guestRules: {},
};

const reduxOnSetGuestRules = (
  state = initialState, { guestRules },
) => ({ ...state, guestRules });

export default createReducer(initialState, {
  [Types.SET_GUEST_RULES]: reduxOnSetGuestRules,
});
