import axios, { AxiosResponse } from 'axios';

import type { TArrayResponseWithCount } from '../../../types/TArrayResponseWithCount';
import type {
  TBatchGalleryLocation,
  TFindBatchGalleryLocations,
} from '../../../types/TBatchGallery';

export const findBatchGalleryLocations = async ({
  batchGalleryId,
  isFetching,
  userAccessToken,
  page = 0,
  pageSize = 50,
  status,
  query = '',
  affectedPlatforms = '',
  affectedPlatformsChecked = false,
}: TFindBatchGalleryLocations): Promise<{
  status: 'SUCCESS' | 'ERROR';
  data: TArrayResponseWithCount<TBatchGalleryLocation> | object;
}> => {
  try {
    if (isFetching) isFetching(true);

    let queryPath = `batchId=${batchGalleryId}`;

    if (status) queryPath += `&status=${status}`;

    const {
      data,
    }: AxiosResponse<TArrayResponseWithCount<TBatchGalleryLocation>> =
      await axios.get(
        `${process.env.REACT_APP_SAAS_BATCH_GOOGLE_MODULES}/gallery-batch-locations?${queryPath}&page=${page}&pageSize=${pageSize}&query=${query}&affectedPlatforms=${affectedPlatforms}&affectedPlatformsChecked=${affectedPlatformsChecked}`,
        {
          headers: {
            Authorization: `Bearer ${userAccessToken}`,
          },
        }
      );

    return {
      status: 'SUCCESS',
      data,
    };
  } catch (err: any) {
    return {
      status: 'ERROR',
      data: err.response,
    };
  } finally {
    if (isFetching) isFetching(false);
  }
};
