import React from 'react';

import {
  StyledYelp,
} from './yelp-styles';

const Foursquare = () => (
  <StyledYelp>
    Yelp
  </StyledYelp>
);

export default Foursquare;
