import styled from 'styled-components';
import { Paper } from '@material-ui/core';

export const StyledPlatforms = styled(Paper)`
  display: flex;
  flex-direction: column;
  margin-bottom: 3rem;
  overflow-y: auto;
  padding: 2rem;

  .button {
    margin-bottom: 3rem;
  }

  .is-sync-loading {
    margin-right: 1rem;
  }
`;

export const StyledTitle = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 1rem;

  h3 {
    font-size: 1.5rem;
    font-weight: 700;
    width: 100%;
    margin-bottom: 0;
  }

  span {
    font-weight: normal;
    cursor: pointer;
    font-size: 1.2rem;
    color: ${props => props.theme.colors.red};
    text-decoration: underline;
  }
`;

export const StyledPlatformListing = styled.ul`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 0;
`;
