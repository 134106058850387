import styled from 'styled-components';
import { RemoveCircle } from '@material-ui/icons';

export const StyledDragAndDropFile = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 1rem;
`;

export const StyledDragAndDropZone = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  border-width: 2px;
  border-radius: 2px;
  border-color: #eeeeee;
  border-style: dashed;
  background-color: #fafafa;
  color: #bdbdbd;
  outline: none;
  transition: border .24s ease-in-out;
  cursor: pointer;
  margin-bottom: 2rem;

  &:focus {
    border-color: ${props => props.theme.colors.primary};
  }

  .add-a-photo-icon {
    width: 3rem;
    height: 3rem;
    margin-bottom: 2rem
  }

  h4 {
    text-transform: uppercase;
    font-size: 1.4rem;
    margin-bottom: 1rem;
    text-align: center;
  }
  
  p {
    font-size: 1.4rem;
    text-align: center;
  }
`;

export const StyledDropZoneListingFile = styled.ul`
  display: flex;
  flex-direction: column;
  align-items: center;

  li {
    padding: 1rem;
    font-size: 1.2rem;
    color: ${props => props.theme.colors.primary};
    
    &.is-invalid-size-file {
      color: ${props => props.theme.colors.red};
    }
  }

  img {
    width: 10rem;
    height: 10rem;
  }

  .error-rejection {
    color: ${props => props.theme.colors.red};
  }
`;

export const StyledRemoveCircle = styled(RemoveCircle)`
  margin-left: 0.5rem;
  cursor: pointer;
`;
