import { ErrorOutline } from '@material-ui/icons';
import axios from 'axios';
import { TCreateUserInvited } from '../../types/TCreateUserInvited';

const createUserInvited = async ({
  email,
  password,
  name,
  inviteId,
  setTokenLikeExpired,
  inviteType,
  enqueueSnackbar,
  setIsFetching,
  history
} : TCreateUserInvited) => {
  try {
    if (setIsFetching) setIsFetching(true);

    const { data } = await axios.post(
      `${process.env.REACT_APP_INVITES_API_URL}/create-invited/${inviteId}`,
      {
        email,
        password,
        name,
        invite_type: inviteType
      }
    );

    enqueueSnackbar('Usuario criado com sucesso!', { variant: 'success' });

    history.push('/');

    return data;
  } catch (err: any) {
    if (err.response?.status === 401 && setTokenLikeExpired)
      return setTokenLikeExpired();

    if (err.response?.data.response.errorCode === 'EMAIL_NOT_AVAILABLE')
      return enqueueSnackbar('Este e-mail ja esta em uso', { variant: 'error' });

    
    if (err.response?.data.response.errorCode === 'ACCESS_DENIED')
      return enqueueSnackbar('Acesso Negado', { variant: 'error' });

    enqueueSnackbar('Erro', { variant: 'error' });

    return err;
  } finally {
    if (setIsFetching) setIsFetching(false);
  }
}

export default createUserInvited;