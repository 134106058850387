import styled from 'styled-components';

export const StyledSublisting = styled.ul`
    display: flex;
    flex-direction: column;
    padding: 0 2rem;
    max-height: 40rem;
    overflow-y: scroll;

    img {
        width: 2rem;
        height: 2rem;
        margin-right: 1.5rem;
        display: block;
    }
`;
