import { TIconButtonTooltipVariant } from '../../types/TIconButton';

export enum ESyncMonitoringPlatformStatus {
  PENDING = 'PENDING',
  PROGRESS = 'PROGRESS',
  DONE = 'DONE',
  APPROVED = 'APPROVED',
  REJECTED = 'REJECTED',
  PROCESSING = 'PROCESSING',
  ANALYSIS = 'ANALYSIS',
  ERROR = 'ERROR',
  LOCATION_DELETED = 'LOCATION_DELETED',
  'UNSELECTED_OPTION' = '_',
}

export type TSyncType = 'requests' | 'monitor';

export type TSyncMonitoringStatusIconProps = {
  status: ESyncMonitoringPlatformStatus;
  syncType: TSyncType;
};

export type TIconByStatusPropsValue = {
  tooltip: (syncEntityName: string) => string;
  icon?: string;
  isSuccess?: boolean;
  isError?: boolean;
  isWarning?: boolean;
  tooltipVariant?: TIconButtonTooltipVariant;
  label: string;
}
