import React from 'react';
import * as R from 'ramda';
import { useSelector } from 'react-redux';

import useAuth from '../use-auth';

import type { TRootStateRedux } from '../../types/TRootStateRedux';
import type { TTicketData, TTicketsEntity, TTicketsData } from '../../types/TTickets';

import APIGetAllTicketsFromLocation from '../../services/tickets/getAllFromLocation';

const useLocationTickets = () => {
  const {
    userAccessToken,
    userSetTokenLikeExpired,
  } = useAuth();

  const {
    activeLocation,
  } = useSelector((state: TRootStateRedux) => state.LocationReducer);

  const [locationTickets, setLocationTickets] = React.useState<TTicketsData[]>([]);
  const [locationTicketsCount, setLocationTicketsCount] = React.useState(0);

  const [locationOpenTickets, setLocationOpenTickets] = React.useState<TTicketsData[]>([]);

  const [locationPostTickets, setLocationPostTickets] = React.useState<TTicketsData[]>([]);
  const [locationPostOpenTickets, setLocationPostOpenTickets] = React.useState<TTicketsData[]>([]);

  const getAllTicketsFromLocation = React.useCallback(async () => {
    if (R.isNil(activeLocation)) return;

    const getAllTicketsFromLocationResponse = await APIGetAllTicketsFromLocation({
      accessToken: userAccessToken,
      setTokenLikeExpired: userSetTokenLikeExpired,
      locationId: activeLocation.id,
      pageSize: 200,
    });

    if (R.isNil(getAllTicketsFromLocationResponse)) return;

    const [locationTicketsData, locationTicketsDataCount] = getAllTicketsFromLocationResponse;

    const locationTicketsDataNormalized = locationTicketsData.map((ticketItem: TTicketsEntity): TTicketsData => {
      const currentTicketData = JSON.parse(ticketItem.data);

      if (typeof currentTicketData.info_to_update === 'string') {
        currentTicketData.info_to_update = JSON.parse(currentTicketData.info_to_update);
      }

      return {
        ...ticketItem,
        data: {
          ...currentTicketData,
        },
      };
    });

    const openedTickets = locationTicketsDataNormalized.filter((ticketItem: TTicketsEntity) => ticketItem.status === 'OPEN');
    const postTickets = locationTicketsDataNormalized.filter((ticketItem: TTicketsEntity) => ticketItem.type === 'POST');
    const postOpenedTickets = postTickets.filter((ticketItem: TTicketsEntity) => ticketItem.status === 'OPEN');

    setLocationTickets(locationTicketsDataNormalized);
    setLocationTicketsCount(locationTicketsDataCount);
    setLocationOpenTickets(openedTickets);
    setLocationPostTickets(postTickets);
    setLocationPostOpenTickets(postOpenedTickets);
  }, [activeLocation]);

  React.useEffect(() => {
    getAllTicketsFromLocation();
  }, [getAllTicketsFromLocation]);

  return {
    locationTickets,
    locationOpenTickets,
    locationPostTickets,
    locationPostOpenTickets,
  };
};

export default useLocationTickets;
