import axios from 'axios';

const deleteCategoryGrouping = async ({
  accessToken,
  categoryGroupingID,
  setTokenLikeExpired,
  setIsFetching,
  snackbar,
}) => {
  try {
    if (setIsFetching) setIsFetching(true);

    const response = await axios({
      method: 'DELETE',
      url: `${process.env.REACT_APP_CATEGORY_GROUPINGS_API_URL}/${categoryGroupingID}`,
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });

    return response;
  } catch (err) {
    const {
      response,
    } = err;

    if (response?.status === 401 && setTokenLikeExpired) setTokenLikeExpired();

    if (response?.status === 404 && snackbar) {
      snackbar('Registro de agrupamento já apagado ou inexistente. Tente atualizar a página!', { variant: 'error' });
    } else if (snackbar) {
      snackbar('Deu erro ao tentar criar agrupamento. Tente novamente!', { variant: 'error' });
    }
  } finally {
    if (setIsFetching) setIsFetching(false);
  }
};

export default deleteCategoryGrouping;
