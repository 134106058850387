import React from 'react';
import { useSnackbar } from 'notistack';
import * as R from 'ramda';

import APIUpdatedTicked from '../../../../services/tickets/update';
import APIGetOneUser from '../../../../services/users/getUser';
import { findBatchInfosLocations } from '../../../../services/google-batch-modules/batch-infos';

import {
  Button,
  Select,
} from '../../../../components';

import { useUncontrolledField, useAuth } from '../../../../hooks';

import getCurrentDate from '../../../../utils/get-current-date';

import { commentScheme } from './field-scheme';

import {
  commentIsEmpty,
  isDone,
  hasOperator,
} from '../helpers';

import {
  StyledTicketInfos,
  StyledInfosSelect,
  StyledContent,
  StyledBasicInfos,
  StyledActions,
  StyledActionsOptions,
  StyledWrapInfo,
  StyledRequesterInfo,
  StyledResponsibleInfo,
  StyledWrapInfoTitle,
  StyledComment,
  StyledDisclaimer,
  StyledCommentList,
  StyledCommentItem,
} from './ticket-infos-styles';

const TicketInfos = ({
  ticketStatus,
  ticketData,
  accessToken,
  getTicketData,
  operatorsList,
  setTicketHasBeenUpdated,
  getAmounts,
  setIsTicketLoading,
  setTokenLikeExpired,
  currentPlatformUser,
}) => {
  const { enqueueSnackbar } = useSnackbar();

  const { userAccessToken } = useAuth();

  const [currentStatus, setCurrentStatus] = React.useState(null);
  const [currentResponsible, setCurrentResponsible] = React.useState(null);
  const [commentsThisTicket, setCommentsThisTicket] = React.useState([]);

  const [isEnableCommentInfo, setIsEnableCommentInfo] = React.useState(false);

  const [fields, setFields] = React.useState([]);

  const { Field: Comment } = useUncontrolledField({
    fieldScheme: commentScheme,
    saveIn: setFields,
  });

  React.useEffect(() => {
    const { operator, comment, status } = ticketData;

    if (!R.isNil(operator)) setCurrentResponsible(operator.id);

    if (!R.isNil(comment)) setCommentsThisTicket(JSON.parse(comment));

    setCurrentStatus(status);
  }, [ticketData]);

  const updatedTicketParams = {
    accessToken,
    ticketId: ticketData.id,
    setIsFetching: setIsTicketLoading,
  };

  const updateScreen = () => {
    getTicketData();
    getAmounts();
    setTicketHasBeenUpdated();
  };

  const onUpdateStatusClicked = async (newTicketStatus) => {
    if (newTicketStatus === 'OPEN') return;

    const { status } = JSON.parse(ticketData.data);
    const platformsStatus = Object.values(status);

    let platformsHaveBeenDone = false;

    if (!R.includes(ticketData.type, 'BATCH_INFOS')) {
      platformsHaveBeenDone = platformsStatus.every(platformStatus => platformStatus === 'DONE');
    } else {
      const { data, status: statusResponse } = await findBatchInfosLocations({
        batchInfosId: ticketData.batch_infos_id,
        affectedPlatforms: 'LINKEDIN, FACEBOOK, FOURSQUARE, YELP, GOOGLE',
        userAccessToken,
      });

      if (statusResponse === 'ERROR') {
        platformsHaveBeenDone = false;
        return;
      }

      const [, batchInfosLocationsCount] = data;

      if (batchInfosLocationsCount === 0) platformsHaveBeenDone = true;
    }

    if (newTicketStatus === 'IN_PROGRESS') {
      setCurrentStatus(newTicketStatus);

      await APIUpdatedTicked({
        ...updatedTicketParams,
        dataToUpdate: {
          status: newTicketStatus,
        },
      });

      updateScreen();
    }

    if (newTicketStatus === 'DONE') {
      if (!platformsHaveBeenDone && commentIsEmpty(commentsThisTicket)) {
        setIsEnableCommentInfo(true);

        enqueueSnackbar(
          'Há plataformas com sincronização pendente! Caso queira continuar com a conclusão do Ticket, deixe um comentário.',
          { variant: 'warning' },
        );

        return;
      }

      setCurrentStatus(newTicketStatus);

      await APIUpdatedTicked({
        ...updatedTicketParams,
        dataToUpdate: {
          status: newTicketStatus,
        },
      });

      updateScreen();
    }
  };

  const onUpdateResponsible = async (newOperator) => {
    setCurrentResponsible(newOperator);

    const updatedResponsibleResponse = await APIUpdatedTicked({
      accessToken,
      ticketId: ticketData.id,
      dataToUpdate: { operator_id: newOperator },
      setIsFetching: setIsTicketLoading,
      feedbackMessage: enqueueSnackbar,
    });

    if (R.isNil(updatedResponsibleResponse)) return;

    updateScreen();
  };

  const addComment = async () => {
    const {
      user,
    } = currentPlatformUser;

    const {
      id: currentPlatformUserId,
      name: currentPlatformUserName,
      email: currentPlatformUserEmail,
      profiles: [currentPlatformUserProfile],
    } = user;

    const {
      commentToDoneTicketValue,
    } = fields.map(field => ({
      commentToDoneTicketValue: field.getValue() || '',
      commentToDoneTicketSetCurrentValue: field.setCurrentValue,
    }))[0];

    if (R.isEmpty(commentToDoneTicketValue)) return;

    const createdBy = await APIGetOneUser({
      accessToken,
      setTokenLikeExpired,
      id: currentResponsible,
    });

    if (R.isNil(createdBy)) {
      enqueueSnackbar(
        'Não foi possível salvar o comentário. Tente novamente!',
        { variant: 'error' },
      );
      return;
    }

    const {
      id: responsibleThisTicketId,
      name: responsibleThisTicketName,
      email: responsibleThisTicketEmail,
      profiles: [responsibleThisTicketProfile],
    } = createdBy;

    const name = responsibleThisTicketId !== currentPlatformUserId
      ? currentPlatformUserName : responsibleThisTicketName;

    const email = responsibleThisTicketId !== currentPlatformUserId
      ? currentPlatformUserEmail : responsibleThisTicketEmail;

    const profile = responsibleThisTicketId !== currentPlatformUserId
      ? currentPlatformUserProfile.name : responsibleThisTicketProfile.name;

    const newComment = {
      name,
      email,
      profile,
      comment: commentToDoneTicketValue,
      created_at: getCurrentDate(),
    };

    const comment = [
      ...commentsThisTicket,
      newComment,
    ];

    setCommentsThisTicket(comment);

    await APIUpdatedTicked({
      ...updatedTicketParams,
      dataToUpdate: {
        comment: JSON.stringify(comment),
      },
    });
  };

  return (
    <StyledTicketInfos>
      <h3>Informações do Ticket</h3>
      <StyledContent>
        <StyledBasicInfos>
          <StyledWrapInfo>
            {isDone(ticketData || {}) && currentResponsible === currentPlatformUser.user.id && (
              <>
                <StyledWrapInfoTitle>Responsável</StyledWrapInfoTitle>
                <StyledResponsibleInfo>
                  <span>{currentPlatformUser.user.name}</span>
                </StyledResponsibleInfo>
              </>
            )}
            <StyledWrapInfoTitle>Solicitante</StyledWrapInfoTitle>
            <StyledRequesterInfo>
              <span>{ticketData.requesterName}</span>
              <small>{ticketData.requesterEmail}</small>
            </StyledRequesterInfo>
          </StyledWrapInfo>
          <StyledWrapInfo>
            <StyledWrapInfoTitle>Data de criação</StyledWrapInfoTitle>
            <span>{ticketData.date_created}</span>
          </StyledWrapInfo>
        </StyledBasicInfos>
        {!isDone(ticketData || {}) && (
          <StyledActions>
            <StyledWrapInfoTitle>Ações</StyledWrapInfoTitle>
            <StyledActionsOptions
              hasOperator={hasOperator(ticketData)}
              isDone={isDone}
            >
              {hasOperator(ticketData) && (
                <StyledInfosSelect>
                  <Select
                    className="ticket-select"
                    border
                    label="Status"
                    name="ticket-status"
                    value={currentStatus || ''}
                    onChange={({ target }) => onUpdateStatusClicked(target.value)}
                    options={ticketStatus}
                    disabled={isDone(ticketData)}
                  />
                </StyledInfosSelect>
              )}

              <StyledInfosSelect>
                <Select
                  className="ticket-select"
                  border
                  label="Responsáveis"
                  name="ticket-status"
                  value={currentResponsible || ''}
                  onChange={({ target }) => onUpdateResponsible(target.value)}
                  options={operatorsList}
                  disabled={isDone(ticketData)}
                  hasPlaceholder
                />
              </StyledInfosSelect>
            </StyledActionsOptions>
          </StyledActions>
        )}

        {(!R.isEmpty(commentsThisTicket) || isEnableCommentInfo) && (
          <StyledComment>
            <StyledWrapInfoTitle>Comentários</StyledWrapInfoTitle>
            {commentIsEmpty(commentsThisTicket) && (
              <StyledDisclaimer>
                Você deixou uma ou mais plataformas sem sincronização. Mude o status da(s) plataforma(s) caso
                tenha esquecido ou deixe um comentário justificando por que não foi concluído.
              </StyledDisclaimer>
            )}

            {!R.isEmpty(commentsThisTicket) && (
              <StyledCommentList>
                {commentsThisTicket.map(comment => (
                  <StyledCommentItem key={Math.random(Math.floor())}>
                    <div className="comment-profile">
                      <span className="comment-avatar-letter">{comment.name[0]}</span>
                      <div className="comment-infos">
                        <span className="comment-name">
                          {comment.name}
                          {' '}
                          (
                          {comment.profile}
                          )
                        </span>
                        <small>{comment.email}</small>
                        <small>{comment.created_at}</small>
                      </div>
                    </div>
                    <div className="comment-text">{comment.comment}</div>
                  </StyledCommentItem>
                ))}
              </StyledCommentList>
            )}

            {!isDone(ticketData) && (
              <>
                <Comment />
                <Button
                  className="add-comment-button"
                  onClick={addComment}
                >
                  Adicionar comentário
                </Button>
              </>
            )}
          </StyledComment>
        )}
      </StyledContent>
    </StyledTicketInfos>
  );
};

export default TicketInfos;
