import { TGetCurrentDateReturnData } from '../types/TUtils';

export const getCurrentDate = (): TGetCurrentDateReturnData => {
  const currentDateTime = new Date();

  const monthInYear = 12;
  const currentDay: number = currentDateTime.getDate();
  const currentMonth: number = currentDateTime.getMonth() + 1;
  const currentYear: number = currentDateTime.getFullYear();
  const daysInCurrentMonth: number = new Date(currentYear, currentMonth, 0).getDate();

  return {
    monthInYear,
    currentDay,
    currentMonth,
    currentYear,
    daysInCurrentMonth,
  };
};
