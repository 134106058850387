import axios from 'axios';

import { TGetAllLocationsFromCompanyWithoutLink } from '../../types/TLocation';

export default async function getAllLocationsFromCompanyWithoutLink({
  accessToken,
  companyId,
  limit,
  setIfFetching,
  setTokenLikeExpired = null,
}: TGetAllLocationsFromCompanyWithoutLink) {
  try {
    if (setIfFetching) setIfFetching(true);

    const { data } = await axios.get(
      `${process.env.REACT_APP_LOCATIONS_API_URL}/location-connection/${companyId}`,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
        params: {
          limit,
        },
      },
    );

    return data;
  } catch (err: any) {
    if (err.response?.status === 401 && setTokenLikeExpired) return setTokenLikeExpired();

    return null;
  } finally {
    if (setIfFetching) setIfFetching(false);
  }
}
