import type {
  TTopicType,
} from '../../../types/TLocationPost';

export const getTopicType = (topicTypeToGet: string): string => {
  const topicTypes: TTopicType = {
    ALERT: 'Atualizações da COVID-19',
    STANDARD: 'Novidades',
    OFFER: 'Oferta',
    EVENT: 'Evento',
  };

  return topicTypes[topicTypeToGet];
};

export const actionLogStatus = {
  NO_ACTION: 'Nenhuma Ação',
  CREATE: 'Criada',
  REMOVE: 'Removida',
  BATCH_REMOVE: 'Removida em Massa',
  CANCEL: 'Cancelada',
  MANUAL_PUBLISH: 'Publicada Manualmente',
  ERROR: 'Erro',
  WARNING: 'Aviso',
};
