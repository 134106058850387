import axios from 'axios';

export default async function updateGroup(accessToken, id, name, icon) {
  const requestOptions = {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  };

  try {
    const { data } = await axios.put(
      `${process.env.REACT_APP_GROUPS_API_URL}/${id}`,
      { name, icon },
      requestOptions,
    );

    console.log(data);
    return data;
  } catch (err) {
    console.log(err);
    return err;
  }
}
