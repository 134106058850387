import styled from 'styled-components';
import { Paper } from '@material-ui/core';
import { devices } from '../../assets/styles/devices';

export const StyledBatchUpdateInfo = styled.div`
`;

export const StyledContent = styled.div`
  display: flex;
  flex-direction: column;
  margin: 2rem;

  .info-nav-button {
    width: 100%;
  }

  .sticky-button {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-row-gap: 3rem;
  }
`;

export const StyledGroup = styled.div`
  display: flex;
  flex-direction: column;
  
  @media ${devices.tabletLandscape} {
    display: grid;
    grid-template-columns: 75% 23%;
    grid-column-gap: 2rem;
  }
`;

export const StyledTitle = styled.h3`
  display: inline-block;
  width: 100%;
  font-size: 1.5rem;
  font-weight: 700;
  margin-bottom: 2rem;
`;

export const StyledTitleWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 2rem;
`;

export const StyledSideContent = styled.div`
  display: flex;
  flex-direction: column;
  height: max-content;
  align-items: center;
`;

export const StyledInfo = styled(Paper)`
  display: flex;
  flex-direction: column;
  padding: 2rem;
  height: max-content;
  align-items: center;
  margin-bottom: 2rem;
  width: 100%;
  row-gap: 2rem;
`;

export const StyledFooter = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 2rem 2rem 4rem 2rem;
`;

export const StyledStickyContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  position: sticky;
  top: 1rem;
  width: 100%;
`;
