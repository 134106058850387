export const fieldUserName = {
  name: 'field-user-name',
  nickname: 'Nome',
  type: 'text',
  required: true,
  hasborder: 'yes',
  label: 'Nome',
  placeholder: 'Ex: Cliente Hublocal',
};

export const fieldUserEmail = {
  name: 'field-user-email',
  nickname: 'Email',
  type: 'text',
  required: true,
  hasborder: 'yes',
  label: 'Email',
  placeholder: 'Ex: usuario@usuario.com.br',
};

export const fieldUserGoogleRefreshToken = {
  name: 'field-user-refresh-token',
  nickname: 'Google Refresh Token',
  type: 'text',
  required: true,
  hasborder: 'yes',
  label: 'Google Refresh Token',
  disabled: true,
};

export const fieldUserPhone = {
  name: 'field-user-phone',
  nickname: 'Telefone',
  type: 'text',
  required: true,
  hasborder: 'yes',
  label: 'Telefone',
  placeholder: 'Ex: 12345678',
};

export const fieldUserPassword = {
  name: 'field-user-password',
  nickname: 'Senha',
  type: 'password',
  required: false,
  hasborder: 'yes',
  label: 'Senha',
  password: true,
};

export const fieldUserCompanyLimit = {
  name: 'field-user-company-limit',
  nickname: 'Limite de Empresa',
  type: 'number',
  defaultValue: 0,
  required: true,
  hasborder: 'yes',
  label: 'Limite de Empresa',
};

export const fieldUserCompanyGroupLimit = {
  name: 'field-user-company-group-limit',
  nickname: 'Limite de Grupos de Empresas',
  type: 'number',
  defaultValue: 0,
  required: true,
  hasborder: 'yes',
  label: 'Limite de Grupos de Empresas',
};

export const fieldUserLocationLimit = {
  name: 'field-user-location-limit',
  nickname: 'Limite de Locais',
  type: 'number',
  defaultValue: 0,
  required: true,
  hasborder: 'yes',
  label: 'Limite de Locais',
};

export const fieldUserLocationGroupLimit = {
  name: 'field-user-location-group-limit',
  nickname: 'Limite de Grupos de Locais',
  type: 'number',
  defaultValue: 0,
  required: true,
  hasborder: 'yes',
  label: 'Limite de Grupos de Locais',
};
