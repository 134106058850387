import styled, { css } from 'styled-components';
import { Paper } from '@material-ui/core';
import { lighten } from 'polished';

import { devices } from '../../assets/styles/devices';

export const StyledLocationFormUncontrolled = styled.div`
    display: flex;
    flex-direction: column;
    padding-bottom: 4rem;

    @media ${devices.fullhd} {
        padding-bottom: 6rem;
    }
`;

export const StyledTitleBlock = styled.h3`
  font-weight: 700;
  font-size: 1.5rem;
  margin-bottom: 2rem;
`;

export const StyledFormContainer = styled(Paper)`
    display: flex;
    flex-direction: column;
`;

export const StyledForm = styled.form`
    display: flex;
    flex-direction: column;
    padding: 3rem 2rem 3rem;

    .google-service-area-input {
      margin-bottom: 2rem;
    }
`;

export const StyledSectionTitle = styled.h3`
    font-size: 1.4rem;
    font-weight: bold;
    color: ${props => props.theme.colors.primary};
    margin-bottom: 1.5rem;
    line-height: 1;
`;

export const StyledSeparator = styled.div`
    width: 100%;
    border-bottom: 1px solid ${props => lighten(0.9, props.theme.colors.black)};
    margin: 3rem 0;

    &:last-of-type {
        margin-bottom: 0;
    }
`;

export const StyledDataStudioWrapper = styled.div`
  width: 100%;
  margin-top: 2rem;
`;

export const StyledFieldsWrapper = styled.div`
    width: 100%;
    display: grid;
    grid-template-columns: 1fr;
    row-gap: 2rem;
    margin-bottom: 2rem;

    @media ${devices.desktop} {
        grid-template-columns: repeat(2, 1fr);
        column-gap: 2rem;
    }

    ${props => props.threeColumns && css`
        @media ${devices.fullhd} {
            grid-template-columns: repeat(3, 1fr);
        }
    `}

    ${props => props.storeCode && css`
        @media ${devices.tablet} {
            grid-template-columns: repeat(3, 1fr);
        }

        @media ${devices.tabletLandscape} {
            grid-template-columns: repeat(4, 1fr);
        }

        @media ${devices.desktop} {
            grid-template-columns: repeat(5, 1fr);
        }
    `}
`;
