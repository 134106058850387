import styled from 'styled-components';

export const StyledContainer = styled.button`
    width: 28px;
    height: 28px;
    background: transparent;
    border: none;

    img { 
        width: 30px;
        height: 30px;
    }

    :focus {
        outline: none;
    }
`;
