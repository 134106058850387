import styled from 'styled-components';
import { IconButton } from '@material-ui/core';
import { ChevronLeft, ChevronRight } from '@material-ui/icons';

export const StyledPagination = styled.div`
    background: ${props => props.theme.colors.white};
    padding: 1rem 2rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 0.2rem;
`;

export const StyledPageControls = styled.div`
    font-weight: 600;
    font-size: 2rem;

    span {
        font-weight: bold;
        font-size: 2rem;
    }
`;

export const StyledIconButton = styled(IconButton)``;

export const StyledChevronLeft = styled(ChevronLeft)`
    height: 2rem;
    width: 2rem;
`;

export const StyledChevronRight = styled(ChevronRight)`
    height: 2rem;
    width: 2rem;
`;
