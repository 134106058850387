import styled from 'styled-components';
import { Paper } from '@material-ui/core';
import { lighten } from 'polished';

export const StyledAddCategoryGrouping = styled.div`
    display: flex;
    flex-direction: column;

    h3 {
        font-weight: 700;
        font-size: 1.5rem;
        margin-bottom: 2rem;
    }

    .add-category-group-field {
        margin-bottom: 2rem;
    }
`;

export const StyledDisclaimer = styled.div`
    font-size: 1.2rem;
    color: ${props => lighten(0.2, props.theme.colors.black)};
    font-style: italic;
    margin-bottom: 2rem;

    small {
        font-size: inherit;
        color: ${props => props.theme.colors.red};
    }
`;

export const StyleFieldsContainer = styled(Paper)`
    display: flex;
    flex-direction: column;
    padding: 2rem;
`;

export const StyledFields = styled.div`
    padding: 0;
    display: flex;
    flex-direction: column;

    .field {
        margin-bottom: 2rem;

        &:last-of-type {
            margin-bottom: 3rem;
        }
    }

    .button-add {
        width: 100%;
        max-width: 20rem;
        margin-left: auto;
    }
`;

export const StyledButtonsGroup = styled.div`
    display: flex;
    margin-left: auto;

    button {
        margin-right: 1rem;

        &:last-of-type {
            margin-right: 0;
        }
    }
`;

export const StyledIsFetchingToEdit = styled.div`
    display: flex;
    font-size: 1.4rem;
    color: ${props => props.theme.colors.secondary};
    margin-bottom: 2rem;

    .is-loading-to-edit {
        margin-right: 1rem;
    }
`;

