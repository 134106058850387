import getPlatformCategoryByPlatformId from '../services/categories/getCategoryByPlatformId';

export default async function showGoogleCategories(accessToken, googleCategories) {
  if (!googleCategories) return null;
  const categoriesList = googleCategories.split(',');

  const categories = [];

  for (let i = 0; i < categoriesList.length; i++) {
    const { name } = await getPlatformCategoryByPlatformId(accessToken, categoriesList[i]);
    categories.push(name);
  }

  return categories.join(', ');
}
