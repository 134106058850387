import styled, { css } from 'styled-components';
import { withStyles } from '@material-ui/core/styles';
import { Accordion, AccordionSummary, AccordionDetails } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import { shade } from 'polished';
import { light } from '../../../../../assets/styles/themes';

export const StyledSubMenuItem = styled(Accordion)`
  background: ${props => props.theme.colors.primary};
  box-shadow: none;

  &::before {
    display: none !important;
  }

  &.Mui-expanded {
    margin: 0;
  }
`;

export const StyledSAccordionSummary = styled(AccordionSummary)`
  padding: 0;
  min-height: unset;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  overflow: hidden;

  &::before {
    display: none !important;
  }

  &.Mui-expanded {
    min-height: unset;
    background: ${props => shade(0.1, props.theme.colors.primary)};
  }

  .MuiAccordionSummary-content {
    margin: 0;
  }

  .MuiIconButton-root {
    transition: 0.3s;
    position: absolute;
    right: -27rem;
  }
`;

export const StyledAccordionMenu = styled(AccordionDetails)`
  flex-direction: column;
  transition: 0.3s;
  padding: 0;
  background: ${props => shade(0.3, props.theme.colors.primary)};

  &::before {
    display: none !important;
  }
`;

export const StyledExpandIcon = styled(ExpandMoreIcon)`
  color: ${props => props.theme.colors.white};
`;
