import React from 'react';
import * as R from 'ramda';
import { debounce, isNil } from 'lodash';
import { useSelector } from 'react-redux';
import { Helmet } from 'react-helmet';

import APIGetValidGoogleAccessTokenFromLocation from '../../services/getValidGoogleAccessTokenFromLocation';
import listLocationPosts from '../../services/google/post/listLocationPosts';

import type { TPostCard, TPostDataTable } from '../../types/TPostCard';
import type { TPostListView } from '../../types/TLocationPost';

import { useAuth, useHasAccess } from '../../hooks';
import isNilOrEmpty from '../../utils/is-null-or-empty';

import {
  LocationHeader,
  Loading,
  GooglePagination,
  NoAccessCard,
} from '../../components';

import { TRootStateRedux } from '../../types/TRootStateRedux';

import PostList from './post-list';
import CreatePostDialog from './create-post-dialog';
import FiltersPostHeader from './filters-post-header';

import {
  convertPostType,
  matchKeyword,
} from './helpers';

import {
  StyledLocationPosts,
  StyledCard,
} from './location-posts-styles';

const LocationPosts = () => {
  const { hasAccess } = useHasAccess();

  const {
    userAccessToken,
    userId,
  } = useAuth();

  const [posts, setPosts] = React.useState([]);
  const [filteredPosts, setFilteredPosts] = React.useState([]);
  const [loading, setLoading] = React.useState(false);

  const { activeLocation } = useSelector((state: TRootStateRedux) => state.LocationReducer);
  const [openCreatePostDialog, setOpenCreatePostDialog] = React.useState(false);
  const [postDialogLoading, setPostDialogLoading] = React.useState(false);

  const [viewModePost, setViewModePost] = React.useState<TPostListView>('table' as TPostListView);
  const [selectedPlatformFilter, setSelectedPlatformFilter] = React.useState('Google');
  const [selectedPostTypeFilter, setSelectedPostTypeFilter] = React.useState<string[]>([]);
  const [keyWordsInput, setKeyWordsInput] = React.useState('');
  const [ofPeriod, setOfPeriod] = React.useState<Date | null>(null);
  const [toPeriod, setToPeriod] = React.useState<Date | null>(null);
  const [postInfoToUpdate, setPostInfoToUpdate] = React.useState<TPostCard | null>(null);
  const [currentPageToken, setCurrentPageToken] = React.useState('');
  const [nextPageToken, setNextPageToken] = React.useState('');
  const [googlePaginationPageIndex, setgooglePaginationPageIndex] = React.useState(1);
  const [googlePaginationPageList, setgooglePaginationPageList] = React.useState<string[]>(['']);

  const [googleAccessToken, setGoogleAccessToken] = React.useState<string | null>(null);
  const [googleLocationId, setGoogleLocationId] = React.useState<string | null>(null);
  const [googleAccountId, setGoogleAccountId] = React.useState<string | null>(null);

  const keyWordsInputRef = React.useRef<HTMLInputElement>(null);

  const [connectedLocation, setConnectedLocation] = React.useState(false);

  const handleChangeKeywordSearch = debounce(text => setKeyWordsInput(text), 500);

  const resetFilters = () => {
    if (!R.isNil(keyWordsInputRef.current)) keyWordsInputRef.current.value = '';

    setOfPeriod(null);
    setToPeriod(null);
  };

  const clearPosts = () => {
    resetFilters();
    setPosts([]);
    setFilteredPosts([]);
  };

  const findPostTitle = (post: TPostCard) => {
    const { topicType } = post;

    if (topicType === 'OFFER') return post.event?.title;
    if (topicType === 'EVENT') return post.event?.title;
    if (topicType === 'STANDARD') return post.summary;
    if (topicType === 'ALERT') return post.summary;

    return 'N/A';
  };
  const getGoogleAccessToken = React.useCallback(async () => {
    if (!hasAccess) return;

    const googleAccessTokenResponse = await APIGetValidGoogleAccessTokenFromLocation({
      accessToken: userAccessToken,
      userId,
      locationId: activeLocation.id,
    });

    if (R.isNil(googleAccessTokenResponse)) return;

    const { googleAccessToken: accessTokenGoogle, connectionInfo } = googleAccessTokenResponse;

    setGoogleLocationId(connectionInfo.google_location_id);
    setGoogleAccountId(connectionInfo.google_account_id);
    setGoogleAccessToken(accessTokenGoogle);
  }, [hasAccess, activeLocation]);

  React.useEffect(() => { getGoogleAccessToken(); }, [getGoogleAccessToken]);

  const getGooglePosts = React.useCallback(async () => {
    if (isNil(googleAccessToken)) return;
    setLoading(true);
    const localPostsResponse = await listLocationPosts({
      accessToken: googleAccessToken,
      locationId: `accounts%2F${googleAccountId}%2Flocations%2F${googleLocationId}`,
      pageToken: currentPageToken,
    });

    if (localPostsResponse.nextPageToken) {
      setNextPageToken(localPostsResponse.nextPageToken);
    }

    if (R.isNil(localPostsResponse)) {
      setLoading(false);

      return;
    }

    const { localPosts } = localPostsResponse;

    if (R.isNil(localPosts)) {
      setLoading(false);

      return;
    }

    const localPostsToDataTable = localPosts.map((postItem: TPostCard): TPostDataTable => [
      postItem.media,
      findPostTitle(postItem),
      postItem.state,
      postItem.createTime,
      postItem.offer,
      postItem.alertType,
      postItem.callToAction,
      postItem.event,
      postItem.topicType,
      postItem.name,
    ]);

    setPosts(localPostsToDataTable);
    setFilteredPosts(localPostsToDataTable);

    setLoading(false);
    resetFilters();
  }, [
    activeLocation,
    currentPageToken,
    hasAccess,
    googleAccessToken,
    googleAccountId,
    googleLocationId,
  ]);

  const handleSearchPostWithKeyword = (post: any[]) => {
    if (matchKeyword(post[1], keyWordsInput)) return post;
  };

  const handleFilterPostByType = (post: any[]) => {
    if (R.isEmpty(selectedPostTypeFilter)) return post;
    if (selectedPostTypeFilter.indexOf(convertPostType(post[7])) > -1) return post;
  };

  const handleFilterPostByDate = (post: any[]) => {
    if (R.isNil(ofPeriod)) return post;
    if (R.isNil(toPeriod)) return post;

    const hourFixedOfPeriod = ofPeriod;
    hourFixedOfPeriod.setHours(0, 0, 0);

    const hourFixedToPeriod = toPeriod;
    hourFixedToPeriod.setHours(23, 59, 59);

    const createTimeParsed = new Date(post[2]);

    if (
      createTimeParsed >= (ofPeriod || new Date())
      && createTimeParsed <= (toPeriod || new Date())
    ) return post;
  };

  const handleApplyFilters = React.useCallback(() => {
    const filteredPostList = posts
      .filter(handleSearchPostWithKeyword)
      .filter(handleFilterPostByType)
      .filter(handleFilterPostByDate);

    setFilteredPosts(filteredPostList);
  }, [
    keyWordsInput,
    selectedPostTypeFilter,
    ofPeriod,
    toPeriod,
  ]);

  React.useEffect(() => {
    if (!R.isNil(googleLocationId) && !R.isNil(googleAccountId)) getGooglePosts();
    else clearPosts();
  }, [getGooglePosts]);

  React.useEffect(() => {
    handleApplyFilters();
  }, [handleApplyFilters]);

  const onNextPageClicked = () => {
    setCurrentPageToken(nextPageToken);
    setNextPageToken('');
  };

  const onBackPageClicked = React.useCallback(async () => {
    const removeLastPositionFromList = googlePaginationPageList.pop();
    if (R.isNil(removeLastPositionFromList)) return;

    const lastTokenFromList = R.last(googlePaginationPageList);
    if (R.isNil(lastTokenFromList)) return;

    setCurrentPageToken(lastTokenFromList);
  }, [googlePaginationPageList]);

  const handleChangeLocation = React.useCallback(async () => {
    if (isNilOrEmpty(googleLocationId)) {
      setConnectedLocation(false);
    } else {
      setConnectedLocation(true);
    }
  }, [activeLocation, googleLocationId]);

  React.useEffect(() => {
    handleChangeLocation();
  }, [handleChangeLocation]);

  return (
    <StyledLocationPosts>
      <Helmet>
        <meta property="og:title" content="Local - Postagens - SaaS Hublocal" />
        <meta
          property="og:description"
          content="Veja as postagens de seus locais."
        />

        <title>Local - Postagens - SaaS Hublocal</title>
      </Helmet>

      <LocationHeader
        hideDashboard={!activeLocation?.data_studio}
        linkedLocation={!isNilOrEmpty(googleAccessToken)}
      />

      {!hasAccess && <NoAccessCard />}

      {!connectedLocation && hasAccess && (
        <StyledCard>
          <p>Local não conectado, entre em contato com o operacional para acessar esta funcionalidade</p>
        </StyledCard>
      )}

      {connectedLocation && hasAccess && (
        <>
          <FiltersPostHeader
            onAddPostClicked={() => setOpenCreatePostDialog(true)}
            viewModePost={viewModePost}
            onViewModePost={setViewModePost}
            keyWordsInput={keyWordsInput}
            setKeyWordsInput={handleChangeKeywordSearch}
            ofPeriod={ofPeriod}
            setOfPeriod={setOfPeriod}
            toPeriod={toPeriod}
            setToPeriod={setToPeriod}
            selectedPlatformFilter={selectedPlatformFilter}
            setSelectedPlatformFilter={setSelectedPlatformFilter}
            selectedPostTypeFilter={selectedPostTypeFilter}
            setSelectedPostTypeFilter={setSelectedPostTypeFilter}
            keyWordsInputRef={keyWordsInputRef}
          />

          <GooglePagination
            onResetClicked={() => { setCurrentPageToken(''); setNextPageToken(''); setgooglePaginationPageList(['']); }}
            nextPageToken={nextPageToken}
            onNextPageClicked={onNextPageClicked}
            onBackPageClicked={onBackPageClicked}
            pageIndex={googlePaginationPageIndex}
            setPageIndex={setgooglePaginationPageIndex}
            pageList={googlePaginationPageList}
            setPageList={setgooglePaginationPageList}
            loading={loading}
            className="pagination"
            isLabelButton
          />

          {loading
            ? <Loading className="is-loading-location-posts" />
            : (
              <PostList
                view={viewModePost}
                data={filteredPosts}
                getGooglePosts={getGooglePosts}
                setLoading={setLoading}
                setPostInfoToUpdate={setPostInfoToUpdate}
                setOpenCreatePostDialog={setOpenCreatePostDialog}
                setPostDialogLoading={setPostDialogLoading}
                activeLocation={activeLocation}
                googleAccessToken={googleAccessToken}
              />
            )}
        </>
      )}

      {hasAccess && !isNil(googleAccessToken) && (
        <CreatePostDialog
          open={openCreatePostDialog}
          setOpen={setOpenCreatePostDialog}
          getGooglePosts={getGooglePosts}
          postInfoToUpdate={postInfoToUpdate}
          setPostInfoToUpdate={setPostInfoToUpdate}
          postDialogLoading={postDialogLoading}
        />
      )}
    </StyledLocationPosts>
  );
};

export default LocationPosts;
