import styled, { css } from 'styled-components';
import { Paper } from '@material-ui/core';
import {
  AddCircle as AddCircleIcon,
  List as ListIcon,
  ViewComfy as ViewComfyIcon,
} from '@material-ui/icons';
import { shade, lighten } from 'polished';

import { devices } from '../../../assets/styles/devices';

export const StyledFilterPostHeader = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 0 2rem;
  margin: 2rem auto;
`;

export const StyledHeader = styled.header`
  display: flex;
  flex-direction: column-reverse;
  margin-bottom: 1rem;
  
  @media ${devices.tablet} {
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-end;
    margin-bottom: 2rem;
  }
`;

export const StyledTitle = styled.h2`
  font-size: 1.6rem;
  display: flex;
  margin-bottom: 0;
`;

export const StyledContent = styled(Paper)`
  padding: 2rem;
  
  @media ${devices.desktop} {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    column-gap: 2rem;
  }

  @media ${devices.widescreen} {
    grid-template-columns: repeat(4, 1fr);
  }

  .filter-content {
    @media ${devices.desktop} {
      margin-bottom: 0;
    }    
  }
`;

export const StyledAddCircle = styled(AddCircleIcon)`
  fill: ${props => props.theme.colors.white};
  margin-bottom: 0;
  width: 2rem;
  height: 2rem;
  margin-right: 0.5rem;
`;

export const StyledAddPostButton = styled.div`
  display: flex;
  align-content: center;
  justify-content: center;
  border-radius: 3rem;
  font-size: 1.4rem;
  font-weight: 500;
  background: ${props => props.theme.colors.primary};
  color: ${props => props.theme.colors.white};
  padding: 1rem 2rem;
  margin-bottom: 2rem;
  cursor: pointer;
  transition: 0.5s;
  
  &:hover {
    background: ${props => shade(0.1, props.theme.colors.primary)};
    transform: scale(1.1);
  }

  @media ${devices.tablet} {
    margin-bottom: 0;
    margin-left: auto;
    margin-right: 2rem;
  }
`;

export const StyledViewType = styled.div`
  display: flex;
  align-content: center;
  justify-content: center;
  flex-direction: column;
  margin-bottom: 2rem;

  @media ${devices.tablet} {
    margin-bottom: 0;
  }
  
  h3 {
    font-size: 1.5rem;
    font-weight: normal;
    color: ${props => props.theme.colors.black};    
  }
`;

export const StyledViewTypeItems = styled.div`
  display: flex;
  align-content: center;
  justify-content: center;
  border: 0.2rem solid ${props => lighten(0.4, props.theme.colors.black)};
  max-width: 15rem;
`;

export const StyledViewTypeItem = styled.div<{ isSelected?: boolean }>`
  padding: 1rem 2rem;
  height: 100%;
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: 0.5s;
  cursor: pointer;
  
  &:hover {
    background: ${props => shade(0.7, props.theme.colors.white)};
    
    svg {
      fill: ${props => props.theme.colors.white};
    }
  }
  
 ${props => props.isSelected && css`
   background: ${shade(0.5, props.theme.colors.white)};
   
   svg {
     fill: ${props.theme.colors.white};
   }
 `}
`;

export const StyledViewsTypeItemIconList = styled(ListIcon)`
  width: 2.1rem;
  height: 2.1rem;
`;

export const StyledViewsTypeItemIconViewColumn = styled(ViewComfyIcon)`
  width: 2.1rem;
  height: 2.1rem;
`;

export const StyledPeriods = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  
  h3 {
    font-size: 1.5rem;
    font-weight: normal;
  }
`;

export const StyledPeriodsWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  
  @media ${devices.tablet} {
    flex-direction: row;
  }
  
  .period-input {
    @media ${devices.tablet} {
      margin-bottom: 0;
    }

    &:first-child {
      @media ${devices.tablet} {
        margin-right: 2rem;
      }
    }
  }
`;

export const StyledDatesWrapper = styled.div`
  display: flex;
  flex-direction: column;

  @media ${devices.desktop} {
    flex-direction: row;
  }

  .input-date-filter {
    @media ${devices.desktop} {
      margin-bottom: 0;
      margin-right: 2rem;
    }

    &:last-child {
      @media ${devices.desktop} {
        margin-right: 0;
      }
    }
  }
  
  .input-date-picker {
    margin-bottom: 2rem;

    @media ${devices.desktop} {
      margin-right: 2rem;
      margin-bottom: 0;
    }
    
    &:last-of-type {
      margin-right: 0;
    }
  }
`;
