import React from 'react';

import {
  StyledTextarea,
  StyledTextareaValidate,
} from './textarea-styles';

import { TTextarea } from '../../types/TTextarea';

const Textarea = ({
  label,
  subLabel,
  name,
  border,
  borderRadius,
  value,
  onChange,
  textareaValidate,
  className,
  fieldRef,
  disabled = false,
  borderAlert = false,
}: TTextarea) => (
  <StyledTextarea
    className={className}
    border={border}
    borderAlert={borderAlert}
    borderRadius={borderRadius}
  >
    {label && (
    <label htmlFor={name}>
      {label}
      {subLabel && <span className="sublabel">{subLabel}</span>}
    </label>
    )}

    <textarea
      disabled={disabled}
      ref={fieldRef}
      name={name}
      defaultValue={value}
      value={value}
      onChange={(event) => onChange(event.target.value)}
    />

    {textareaValidate && textareaValidate.visible && (
      <StyledTextareaValidate type={textareaValidate.type}>
        {textareaValidate.message}
      </StyledTextareaValidate>
    )}
  </StyledTextarea>
);

export default Textarea;
