import React from 'react';
import * as R from 'ramda';

import {
  StyledSpecialDatesInfo,
  StyledSpecialDatesInfosContainer,
  StyledSpecialDatesInfoLine,
  StyledSpecialDateWrapper,
  StyledExcludedMessage,
} from './special-dates-info-styles';
import SyncStatusIcon from '../../../sync-status-icon';

const SpecialDatesInfos = ({ data = [], status }) => (
  <StyledSpecialDatesInfosContainer>
    {(!R.isEmpty(data) && !R.isNil(data)) && (
      <>
          {data.map((specialDateInfo, index) => (
            <StyledSpecialDateWrapper isLastOfSection={index + 1 === data.length}>
              <StyledSpecialDatesInfo>
                <StyledSpecialDatesInfoLine>
                  <p className="title">
                    {specialDateInfo.name}
                    {' '}
                    -
                    {' '}
                    {specialDateInfo.date}
                  </p>
                </StyledSpecialDatesInfoLine>

                <StyledSpecialDatesInfoLine>
                  <p>
                    {specialDateInfo.is_open ? `Aberto${specialDateInfo.is_24_hours ? ': 24 horas' : ''}${!R.isEmpty(specialDateInfo.times)
                      && !R.isNil(specialDateInfo.times) && !specialDateInfo.is_24_hours
                      ? specialDateInfo.times.map((time, timeIndex) => `${timeIndex === 0 ? ':' : ''}${' '}${time.open}-${time.close}`)
                      : ''}`
                      : 'Fechado'}
                  </p>
                </StyledSpecialDatesInfoLine>
              </StyledSpecialDatesInfo>
              <SyncStatusIcon syncType="requests" status={status} />
            </StyledSpecialDateWrapper>
          ))}
      </>
    )}
    {(R.isEmpty(data)) && (
    <StyledSpecialDateWrapper isLastOfSection>
      <StyledExcludedMessage>Excluídas</StyledExcludedMessage>
      <SyncStatusIcon syncType="requests" status={status} />
    </StyledSpecialDateWrapper>
    )}
  </StyledSpecialDatesInfosContainer>
);

export default SpecialDatesInfos;
