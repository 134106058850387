import React from 'react';

import { IconButton } from '../../..';
import { StyledSection } from './sync-info-section-popover-styles';
import { TSyncInfoSectionPopoverProps } from './types';

const SyncInfoSectionPopover = ({
  title,
  open,
  setOpen,
}: TSyncInfoSectionPopoverProps) => (
  <StyledSection>
    <h2>{title}</h2>

    <IconButton
      label=""
      tooltip=""
      icon={open ? 'KeyboardArrowUp' : 'KeyboardArrowDown'}
      isWhite
      onClick={() => setOpen(!open)}
    />
  </StyledSection>
);

export default SyncInfoSectionPopover;
