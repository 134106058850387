/* eslint-disable no-unused-expressions */
import { isNil } from 'ramda';
import React, { useCallback, useEffect } from 'react';
import {
  StyledFacebookLoggedAccountWrapper,
  StyledInfoWrapper,
  StyledLogoutButton,
} from './facebook-logged-account-styles';
import { TFacebookLoggedAccountProps } from './types';

const FacebookLoggedAccount = ({
  handleFacebookLogout,
  facebookUserEmail,
}: TFacebookLoggedAccountProps) => {

  const removeFacebookScript = useCallback((script: HTMLScriptElement) => {
    return () => {
      script.parentElement?.removeChild(script);

      const sdkScript = document.getElementById('facebook-jssdk');

      if (!isNil(sdkScript)) {
        const prevSibling: any = sdkScript.previousElementSibling;

        if (!isNil(prevSibling)) {
          const { src } = prevSibling;
          const hasFacebookInSrc = src?.includes('facebook');

          if (hasFacebookInSrc) prevSibling.parentElement?.removeChild(prevSibling);
        }

        sdkScript.parentElement?.removeChild(sdkScript);
      }
    };
  }, []);

  const createFacebookScript = useCallback(() => {
    const script = document.createElement('script');
    script.innerHTML = `
      window.fbAsyncInit = function() {
        FB.init({
          appId      : ${process.env.REACT_APP_FACEBOOK_APP_ID},
          cookie     : true,
          xfbml      : true,
          version    : 'v15.0'
        });
          
        FB.AppEvents.logPageView();
          
      };
    
      (function(d, s, id){
        var js, fjs = d.getElementsByTagName(s)[0];
        if (d.getElementById(id)) {return;}
        js = d.createElement(s); js.id = id;
        js.src = "https://connect.facebook.net/en_US/sdk.js";
        fjs.parentNode.insertBefore(js, fjs);
      }(document, 'script', 'facebook-jssdk'));
    `;

    const after = document.getElementById('root');
    after?.parentElement?.insertBefore(script, after);

    return removeFacebookScript(script);
  }, []);

  const logout = useCallback(() => {
    window['FB']?.getLoginStatus((responseStatus) => {
      if (responseStatus.status === 'connected') {
        window['FB']?.logout((response) => {
          handleFacebookLogout();
        });
      } else {
        handleFacebookLogout();
      }
    });
  }, []);

  useEffect(() => createFacebookScript(), []);

  return (
    <StyledFacebookLoggedAccountWrapper>
      <StyledInfoWrapper>
        <b>Conta conectada:</b>
        <p>{facebookUserEmail}</p>
      </StyledInfoWrapper>
      <StyledLogoutButton onClick={logout}>
        Alterar conta
      </StyledLogoutButton>
    </StyledFacebookLoggedAccountWrapper>
  );
};

export default FacebookLoggedAccount;
