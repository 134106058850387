import styled from 'styled-components';
import { Checkbox } from '@material-ui/core';
import { lighten, shade, rgba } from 'polished';

import type { TCard } from '../../../types/TResponsibleInfo';

export const StyledCard = styled.div<Pick<TCard, 'checked'>>`
  padding: 2rem;
  cursor: pointer;
  transition: 0.3s;
  border-radius: 0.5rem;
  background: ${props => props.theme.colors.white};
  box-shadow: 0.4rem 0.4rem 0.1rem  ${props => props.theme.colors.primary};
  border: 0.1rem solid ${props => rgba(props.theme.colors.black, 0.4)};
  opacity: ${props => (props.checked ? 1 : 0.6)};
  position: relative;

  &:hover,
  &:focus {
    box-shadow: 0.2rem 0.2rem 0.1rem  ${props => props.theme.colors.primary};
    opacity: 1;
  }

  .icon-button {
    width: 3rem;
    height: 3rem;
    background: ${props => rgba(props.theme.colors.black, 0.04)};

    &:hover {
      background: ${props => props.theme.colors.primary};

      svg {
        fill: ${props => props.theme.colors.white};
      }
    }

    svg {
      width: 1.5rem;
      height: 1.5rem;
    }
  }

  .MuiSvgIcon-root {
    fill: ${props => props.theme.colors.primary};
    width: 2rem;
    height: 2rem;
  }

  .MuiButtonBase-root {
    margin-right: 1rem;

    &:focus {
      outline: none;
    }
  }
`;

export const StyledCardHeader = styled.header`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1rem;

  .main-responsible-label {
    font-weight: 600;
    font-size: 1.6rem;
    color: ${props => props.theme.colors.primary};
    margin-right: auto;
  }
`;

export const StyleCardHeaderControlls = styled.div`
  display: flex;
`;

export const StyledContent = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;

  .responsible-info {
    display: flex;
    flex-direction: column;
    font-size: 1.4rem;
    color: ${props => props.theme.colors.black};
    border-bottom: 0.1rem solid ${props => lighten(0.3, props.theme.colors.grey)};
    font-weight: 400;
    margin-bottom: 0.5rem;
    padding-bottom: 0.5rem;

    &:last-of-type {
      padding-bottom: 0;
      margin-bottom: 0;
      border-bottom: none;
    }

    span {
      font-weight: 600;
      color: ${props => shade(0.2, props.theme.colors.primary)};
    }
  }
`;

export const StyledCheckBox = styled(Checkbox)``;

export const StyledOverlayLoading = styled.div`
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  position: absolute;
  z-index: 10;
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${props => rgba(props.theme.colors.black, 0.6)};
  border-radius: 0.5rem;

  .text {
    display: block;
    margin-left: 1rem;
    color: ${props => props.theme.colors.white};
    font-size: 1.4rem;
  }
`;
