import styled from 'styled-components';

export const StyledFormAddSensitiveWord = styled.div`
    display: flex;
    width: 100%;
    margin-bottom: 2rem;
`;

export const StyledIconButtonWrapper = styled.div`
  margin-left: 2rem;
`;
