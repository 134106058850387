export const fieldCompanyInfoName = {
  name: 'company-info-name',
  nickname: 'Nome da empresa',
  type: 'text',
  required: true,
  hasborder: 'yes',
  label: 'Nome da empresa',
};

export const fieldCompanyInfoWebsite = {
  name: 'company-info-website',
  type: 'text',
  hasborder: 'yes',
  nickname: 'Website da empresa',
  label: 'Website da empresa',
  website: true,
};

export const fieldCompanyInfoIsActive = {
  name: 'company-info-is-active',
  type: 'text',
  nickname: 'Está ativa',
  label: 'Está ativa?',
  typeSwitch: true,
};

export const fieldCompanyInfoDataStudioLink = {
  name: 'company-info-datastudio-link',
  type: 'text',
  nickname: 'Link Data Studio',
  hasborder: 'yes',
  required: false,
  placeholder: 'Ex: https://datastudio.google.com/reporting/qwerty/page/asdf',
};
