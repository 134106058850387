import React, { useState, useEffect } from 'react';
import { Button, CircularProgress } from '@material-ui/core';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';

import { Creators as CompanyActions } from "../../redux/ducks/company";

import getCompany from '../../services/companies/getCompany';
import updateSpecialDate from '../../services/specialDate/updateSpecialDate';
import createSpecialDate from '../../services/specialDate/createSpecialDate';
import addSpecialDateModels from '../../services/companies/addSpecialDateModels';

const buttonStyle = {
  background: '#F2F2F2',
  fontFamily: 'Poppins',
  fontWeight: 'bold',
};

export const useCreateSpecialDateModel = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const params = useParams();
  const [form, setForm] = useState({
    modelName: '',
    modelDate: '',
    openingHour: '',
    closingHour: '',
  });

  const [loading, setLoading] = useState(false);
  const [isOpen, setIsOpen] = useState(true);

  const { specialDateModelId } = params;

  const { user } = useSelector((state) => state.AuthReducer);
  const { activeCompany } = useSelector((state) => state.CompanyReducer);

  useEffect(() => {
    if (specialDateModelId) {
      let currentModel;
      const companySDModels = activeCompany.special_date_models;
      for (let i = 0; i < companySDModels.length; i++) {
        if (specialDateModelId === companySDModels[i].id.toString()) { currentModel = companySDModels[i]; }
      }
      setForm({
        modelName: currentModel.name,
        modelDate: currentModel.date,
        openingHour: currentModel.opening_hour,
        closingHour: currentModel.closing_hour,
      });
      setIsOpen(currentModel.is_open);
    }
  }, []);

  const handleChangeForm = ({ target: { name, value } }) => {
    setForm({ ...form, [name]: value });
  };

  const onChangeIsOpen = () => {
    setIsOpen(!isOpen);
  };

  const onCancelClicked = () => {
    history.push('/company/working-hours-models');
  };

  const onModelUpdated = async () => {
    setLoading(true);
    const specialDate = await updateSpecialDate(
      user.accessToken,
      specialDateModelId,
      form.modelName,
      form.modelDate,
      isOpen,
      form.openingHour,
      form.closingHour,
    );
    const company = await getCompany(user.accessToken, activeCompany.id);

    dispatch(CompanyActions.setActiveCompany(company));
    setLoading(false);
    history.push('/company/working-hours-models');
  };

  const onModelCreated = async () => {
    setLoading(true);
    const specialDate = await createSpecialDate(
      user.accessToken,
      form.modelName,
      form.modelDate,
      isOpen,
      form.openingHour,
      form.closingHour,
      user.user.id,
    );

    const company = await addSpecialDateModels(
      user.accessToken,
      activeCompany.id,
      [specialDate.id],
    );
    dispatch(CompanyActions.setActiveCompany(company));

    setLoading(false);

    history.push('/company/working-hours-models');
  };

  const onBackArrowClicked = () => {
    history.push('/company/working-hours-models');
  };

  const renderButton = () => {
    if (loading) {
      return <CircularProgress />;
    }
    return (
      <>
        <Button
          style={buttonStyle}
          variant="contained"
          onClick={onCancelClicked}
        >
          Cancelar
        </Button>
        <Button
          style={{
            ...buttonStyle,
            marginLeft: '1em',
          }}
          disabled={
            form.modelName === ''
            || form.modelDate === ''
            || (isOpen && (form.openingHour === '' || form.closingHour === ''))
          }
          variant="contained"
          onClick={specialDateModelId ? onModelUpdated : onModelCreated}
        >
          {specialDateModelId ? 'Atualizar Data' : 'Criar Data'}
        </Button>
      </>
    );
  };

  return {
    handleChangeForm,
    onChangeIsOpen,
    onBackArrowClicked,
    renderButton,
    form,
    isOpen,
  };
};
