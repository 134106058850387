import axios from 'axios';

export default async function getUsers({
  accessToken,
  page = 0,
  pageSize = 10,
  query = '',
  setTokenLikeExpired,
  isLoading,
}) {
  const requestOptions = {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  };

  try {
    if (isLoading) isLoading(true);

    const { data } = await axios.get(
      `${process.env.REACT_APP_AUTH_API_URL}/get-users?page=${page}&pageSize=${pageSize}&query=${query}`,
      requestOptions,
    );

    return data;
  } catch (err) {
    if (err.response?.status === 401 && setTokenLikeExpired) return setTokenLikeExpired();

    return null;
  } finally {
    if (isLoading) isLoading(false);
  }
}
