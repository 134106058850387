import styled from 'styled-components';

export const StyledListItemWrapper = styled.div`
  display: flex;
  align-items: center;
  padding: 1rem 2rem;
  border-bottom: 1px solid #757575;
`;

export const StyledListItem = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin: 0 auto 0 20px;

  p.title {
    font-weight: bold;
  }
`;
