import axios from 'axios';
import { TGooglePlacesAutocompleteResponse } from '../../types/TGooglePlaces';

type TGetServiceArea = {
  keyword: string;
  location?: string;
  types?: string;
  components?: string;
};

export default async function getPlacesWithAutocomplete({
  keyword,
  location = '',
  types = '',
  components = '',
}: TGetServiceArea): Promise<TGooglePlacesAutocompleteResponse | null> {
  try {
    const key = process.env.REACT_APP_GOOGLE_PLACES_API_KEY;

    const { data } = await axios.get(
      `${process.env.REACT_APP_GOOGLE_API_URL}/search/location/autocomplete?&key=${key}&keyword=${keyword}&location=${location}&types=${types}&components=${components}`
    );

    return data;
  } catch (err: any) {
    return null;
  }
}
