import React from 'react';
import InputAdornment from '@material-ui/core/InputAdornment';
import SearchIcon from '@material-ui/icons/Search';
import { StyledContainer, StyledLabel, StyledInput } from './filter-automatic-replies-styles';

type Props = {
    label: string;
    placeholder?: string;
}

const FilterAutomaticReplies = ({ label, placeholder }: Props) => {
  return (
    <StyledContainer>
      <StyledLabel>{label}</StyledLabel>
      <StyledInput
        placeholder={placeholder}
        variant="outlined"
        InputProps={{
          endAdornment:
  <InputAdornment
    position="end"
  >
    <SearchIcon fontSize="large" style={{ color: '#C4C4C4' }} />
  </InputAdornment>,
        }}
      />
    </StyledContainer>
  );
};

export default FilterAutomaticReplies;
