import React from 'react';
import { isEmpty, isNil } from 'ramda';
import { debounce } from 'lodash';

import { TDropDownDialogPopover } from '../../../types/TDropDownDialog';

import {
  Pagination,
  GooglePagination,
  Tab,
  TabBar,
  Loading,
} from '../..';

import Option from './option';

import {
  StyledDropDownDialogOptions,
  StyledDropDownDialogPopover,
  StyledFormPopover,
  StyledEmptyOptions,
} from './popover-styles';

const Popover = ({
  page = 0,
  setPage,
  pageQuantity = 0,
  nextPageToken,
  onNextPageClicked,
  onBackPageClicked,
  pageList,
  setPageList,
  onResetClicked,
  setQuery,
  searchQueryRef = null,
  options,
  popoverWitdh,
  anchorElement,
  setAnchorElement,
  isGuest = false,
  onChange,
  optionDisableIfNotHaveLocations,
  companyType,
  locationGroupType,
  companyAndGroupType,
  activeTab,
  handleChangeTab,
  showPagination,
  googlePagination = false,
  googlePaginationLoading,
  isLoading,
  currentSelected = null,
  shouldDisableOptionForLocationGuest = false,
}: TDropDownDialogPopover) => {
  const [googlePaginationPageIndex, setgooglePaginationPageIndex] = React.useState(1);

  const handleChangeInputSearch = debounce(text => setQuery(text), 500);

  const handleClosePopover = () => {
    setAnchorElement(null);
    if (setPage) setPage(0);
    if (setQuery) setQuery('');
  };

  const a11yProps = (index: number) => ({
    id: `full-width-tab-${index}`,
    'aria-controls': `full-width-tabpanel-${index}`,
    className: 'tabbar-smalltab',
  });

  return (
    <StyledDropDownDialogPopover
      width={`${popoverWitdh}px`}
      open={Boolean(anchorElement)}
      anchorEl={anchorElement}
      onClose={handleClosePopover}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
    >
      {companyAndGroupType && (
        <TabBar handleChange={handleChangeTab} activeTab={activeTab}>
          <Tab
            roundedFirst
            smallTab={companyAndGroupType}
            isActive={activeTab === 0}
            label="Empresas"
            {...a11yProps(0)}
          />

          <Tab
            roundedLast
            smallTab={companyAndGroupType}
            isActive={activeTab === 1}
            label="Grupos de Locais"
            {...a11yProps(1)}
          />
        </TabBar>
      )}

      {!googlePagination && (
        <StyledFormPopover>
          <input
            type="text"
            placeholder="Pesquisar"
            id="input-search"
            onChange={e => handleChangeInputSearch(e.target.value)}
            autoComplete="off"
            ref={companyAndGroupType ? searchQueryRef : null}
          />
        </StyledFormPopover>
      )}

      {isLoading && <Loading className="is-loading-options" />}

      {(!isEmpty(options) && !isNil(options) && !isLoading) && (
        <StyledDropDownDialogOptions>
          {options.map((option: any) => (
            <Option
              isGuest={isGuest}
              companyName={isGuest ? option.company.name : ''}
              locationAccessLevelProfile={isGuest ? option.location_access_level_profile.name : ''}
              key={option.id}
              companyId={option.id}
              onClick={onChange}
              disabled={shouldDisableOptionForLocationGuest && companyType && option?.permissionStatus === 'locationGuest'}
              name={option.name}
              optionDisableIfNotHaveLocations={optionDisableIfNotHaveLocations}
              companyType={companyType}
              locationGroupType={locationGroupType}
              setAnchorElement={setAnchorElement}
              option={option}
              currentSelected={currentSelected}
            />
          ))}
        </StyledDropDownDialogOptions>
      )}

      {(isEmpty(options)
        && isNil(options) && !isLoading)
        && (
        <StyledEmptyOptions>
          Não há opções a serem exibidas
        </StyledEmptyOptions>
        )}

      {(showPagination && pageQuantity > 1) && (
        <Pagination
          className="dropdown-popover-pagination"
          page={page}
          setPage={setPage}
          pagesQuantity={pageQuantity}
        />
      )}

      {googlePagination && (
        <GooglePagination
          className="dropdown-popover-pagination"
          nextPageToken={nextPageToken}
          onNextPageClicked={onNextPageClicked}
          onBackPageClicked={onBackPageClicked}
          onResetClicked={onResetClicked}
          pageIndex={googlePaginationPageIndex}
          setPageIndex={setgooglePaginationPageIndex}
          pageList={pageList}
          setPageList={setPageList}
          loading={googlePaginationLoading}
        />
      )}
    </StyledDropDownDialogPopover>
  );
};

export default Popover;
