import React from 'react';
import ReactFadeIn from 'react-fade-in';
import { isEmpty } from 'ramda';

import Carousel, { arrowsPlugin } from '@brainhubeu/react-carousel';
import '@brainhubeu/react-carousel/lib/style.css';

import type {
  TLocationGalleryMediaDialogBaseProps,
} from '../../../types/TLocationGalleryMediaDialog';

import {
  IconButton,
  Dialog,
} from '../../../components';

import { renderCreateTimeGalleryMedia } from '../helpers';

import {
  StyledMediaDialogContent,
  StyledMediaView,
  StyledMediaViewInfos,
  StyledLocationGalleryMediaDialog,
  StyledCalendarTodayIcon,
  StyledVisibilityIcon,
} from './location-gallery-media-dialog-styles';

const LocationGalleryMediaDialog = ({
  onClose,
  open = false,
  data,
  selectedMediaOnClickedIndex = 0,
  handleDeleteMedia,
}: TLocationGalleryMediaDialogBaseProps) => {
  const [currentSlide, setCurrentSlide] = React.useState(0);
  const [animationSpeedCarousel, setAnimationSpeedCarousel] = React.useState(0);

  React.useEffect(() => {
    setCurrentSlide(selectedMediaOnClickedIndex);
    setAnimationSpeedCarousel(0);
  }, [selectedMediaOnClickedIndex]);

  const carouselArrow = (icon: string, isDisabled?: boolean): React.ReactElement => (
    <IconButton
      tooltip="Anterior"
      icon={icon}
      placement="bottom"
      disabled={isDisabled}
    />
  );

  const handleChange = React.useCallback((newActiveSlide: number) => {
    setCurrentSlide(newActiveSlide);
    setAnimationSpeedCarousel(1000);
  }, []);

  return (
    <Dialog
      open={open}
      onClose={() => onClose(false)}
    >
      <StyledLocationGalleryMediaDialog>
        {!isEmpty(data) && (
          <Carousel
            animationSpeed={animationSpeedCarousel}
            value={currentSlide}
            onChange={handleChange}
            plugins={[
              {
                resolve: arrowsPlugin,
                options: {
                  numberOfSlides: 1,
                  arrowLeft: carouselArrow('ChevronLeft'),
                  arrowLeftDisabled: carouselArrow('ChevronLeft', true),
                  arrowRight: carouselArrow('ChevronRight'),
                  arrowRightDisabled: carouselArrow('ChevronRight', true),
                  addArrowClickHandler: true,
                },
              },
            ]}
          >
            {data.map(mediaItem => (
              <ReactFadeIn key={mediaItem.name}>
                <StyledMediaDialogContent>
                  <StyledMediaView>
                    <img src={mediaItem.googleUrl} alt={mediaItem.name} />
                  </StyledMediaView>
                  <StyledMediaViewInfos>
                    <div className="media-view-info">
                      <StyledCalendarTodayIcon />
                      {renderCreateTimeGalleryMedia(mediaItem.createTime)}
                    </div>

                    <div className="media-view-info media-view-info--views">
                      <StyledVisibilityIcon />
                      {mediaItem.insights.viewCount}
                    </div>

                    <IconButton
                      className="icon-button-remove-media"
                      tooltip="Excluir mídia"
                      placement="bottom"
                      tooltipVariant="red"
                      icon="Delete"
                      onClick={() => handleDeleteMedia(mediaItem.name)}
                    />
                  </StyledMediaViewInfos>
                </StyledMediaDialogContent>
              </ReactFadeIn>
            ))}
          </Carousel>
        )}
      </StyledLocationGalleryMediaDialog>
    </Dialog>
  );
};

export default LocationGalleryMediaDialog;
