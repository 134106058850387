import React from 'react';

import { StyledFiltersContainer } from './sync-permissions-filter-styles';

import {
  Input, Select,
} from '../../../../components';
import { StatusOptions, TSyncPermissionsFilterProps } from './helpers';

const SyncPermissionsFilter = ({
  searchCompanyLabel,
  onChangeCompanyQuery,
  searchLocationLabel,
  onChangeLocationQuery,
  selectedTypeFilter,
  setSelectedTypeFilter,
  statusLabel,
}: TSyncPermissionsFilterProps) => (
  <StyledFiltersContainer>
    <Input
      className="filter-content"
      label={searchCompanyLabel}
      onChange={(e) => onChangeCompanyQuery(e.target.value)}
      inputOptions={{
        type: 'text',
        name: 'batch-filter-search-query',
      }}
      border
      placeholder="Escreva algo aqui"
    />
    <Input
      className="filter-content"
      label={searchLocationLabel}
      onChange={(e) => onChangeLocationQuery(e.target.value)}
      inputOptions={{
        type: 'text',
        name: 'batch-filter-search-query',
      }}
      border
      placeholder="Escreva algo aqui"
    />
    <Select<any>
      name="type-update-filter"
      value={selectedTypeFilter}
      label={statusLabel}
      options={StatusOptions ?? []}
      onChange={(event) => setSelectedTypeFilter(event.target.value === 'Todos' ? '' : event.target.value)}
      border
      className="filter-content"
    />
  </StyledFiltersContainer>
);

export default SyncPermissionsFilter;
