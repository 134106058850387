import React from 'react';
import * as R from 'ramda';
import { useDispatch, useSelector } from 'react-redux';
import { Edit } from '@material-ui/icons';

import MapView from '../map-view';
import EditMarker from '../edit-marker';

import { Creators as LocationActions } from '../../redux/ducks/location';
import APIGetLocation from '../../services/locations/getLocation';
import { useAuth } from '../../hooks';

import updateLocation from '../../services/locations/updateLocation';

import Button from '../button';

import {
  StyledMapInfoContainer,
  StyledMapView,
  StyledAddress,
} from './map-info-container-styles';

type TMapInfoContainerProps = {
  isEditLocation?: boolean;
};

const MapInfoContainer = ({ isEditLocation = false }: TMapInfoContainerProps) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = React.useState(false);
  const [open, setOpen] = React.useState(false);

  const { userAccessToken, userSetTokenLikeExpired } = useAuth();
  const { activeLocation } = useSelector((state: any) => state.LocationReducer);

  const [mapAddress, setMapAddress] = React.useState<string | null>(null);
  const defaultLatitude = -4.2195;
  const defaultLongitude = -38.04293;
  const [locationLatitude, setLocationLatitude] = React.useState<number | null>(defaultLatitude);
  const [locationLongitude, setLocationLongitude] = React.useState<number | null>(defaultLongitude);
  const [editMarkerLatitude, setEditMarkerLatitude] = React.useState(defaultLatitude);
  const [editMarkerLongitude, setEditMarkerLongitude] = React.useState(defaultLongitude);

  const getLocationData = React.useCallback(async () => {
    if (R.isNil(activeLocation)) return;

    const getLocationResponse = await APIGetLocation({
      accessToken: userAccessToken,
      id: activeLocation.id,
      setTokenLikeExpired: userSetTokenLikeExpired,
    });

    if (R.isNil(getLocationResponse)) return;

    const {
      address1 = null,
    } = getLocationResponse;

    setMapAddress(address1);
    setLocationLatitude(getLocationResponse.latitude || null);
    setLocationLongitude(getLocationResponse.longitude || null);
    setEditMarkerLatitude(getLocationResponse.latitude || defaultLatitude);
    setEditMarkerLongitude(getLocationResponse.longitude || defaultLongitude);
  }, [activeLocation]);

  React.useEffect(() => {
    getLocationData();
  }, [getLocationData]);

  const onMapClicked = ({ latLng: { lat, lng } }) => {
    setEditMarkerLatitude(lat());
    setEditMarkerLongitude(lng());
  };

  const onSave = async () => {
    setLoading(true);

    const locationUpdate = await updateLocation(
      {
        accessToken: userAccessToken,
        locationId: activeLocation.id,
        inputData: {
          latitude: editMarkerLatitude,
          longitude: editMarkerLongitude,
        },
        setTokenLikeExpired: userSetTokenLikeExpired,
      },
    );

    if (R.isNil(locationUpdate)) return;

    dispatch(LocationActions.setActiveLocation(locationUpdate));
    setLoading(false);
    setOpen(false);
  };

  const handleEditMarkerClose = () => {
    setEditMarkerLatitude(locationLatitude || defaultLatitude);
    setEditMarkerLongitude(locationLongitude || defaultLongitude);
    setOpen(false);
  };

  return (
    <StyledMapInfoContainer isEditLocation={isEditLocation}>
      <EditMarker
        isOpen={open}
        isLoading={loading}
        onClose={handleEditMarkerClose}
        latitude={editMarkerLatitude}
        longitude={editMarkerLongitude}
        onMapClicked={onMapClicked}
        onSave={onSave}
        zoomLevel={18}
      />

      <StyledMapView>
        <MapView
          latitude={locationLatitude}
          longitude={locationLongitude}
          zoomLevel={18}
        />
      </StyledMapView>

      {!R.isNil(mapAddress) && (
        <StyledAddress>
          {mapAddress}
        </StyledAddress>
      )}

      <Button
        className="edit-button"
        onClick={() => setOpen(true)}
      >
        Alterar Localização
        <Edit className="edit-button-icon" />
      </Button>
    </StyledMapInfoContainer>
  );
};

export default MapInfoContainer;
