import React from 'react';

import { useHistory } from 'react-router-dom';
import LastSyncList from './last-sync-list';
import { StyledLastSyncHistory, StyledTitle, StyledButton } from './last-sync-history-styles';

type TLastSyncHistoryProps = {
  data: any[];
  onLastSyncDialogOpen(id: string): void;
}

const LastSyncHistory = ({ data, onLastSyncDialogOpen }: TLastSyncHistoryProps) => {
  const { push: routerPush } = useHistory();
  return (
    <StyledLastSyncHistory>
      <StyledTitle>Últimas sincronizações realizadas nas plataformas acima:</StyledTitle>
      <LastSyncList data={data} onLastSyncDialogOpen={onLastSyncDialogOpen} />
      <StyledButton
        onClick={() => routerPush('/location/last-sync-history')}
        className="button"
      >
        Ver Todas
      </StyledButton>
    </StyledLastSyncHistory>
  );
};

export default LastSyncHistory;
