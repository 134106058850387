import styled from 'styled-components';
import { Paper } from '@material-ui/core';
import { devices } from '../../assets/styles/devices';

export const StyledLocationServices = styled.div`
`;

export const StyledFilter = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
`;

export const StyledContent = styled.div`
  margin: 2rem;
`;

export const StyledCreateButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: flex-end;
  margin-bottom: 1rem;
`;

export const StyledContentFilter = styled(Paper)`
  display: flex;
  padding: 2rem;
  align-items: center;
  justify-content: center;
  margin-bottom: 2rem;
  & > div {
    width: 100%;
  }

  @media ${devices.tablet} {
    justify-content: flex-start;
    & > div {
      width: 50%;
    }
  }
`;

export const StyledTableHeader = styled.header`
  display: flex;
  flex-direction: column;
  padding: 0 2rem;
  margin-bottom: 2rem;

  h1 {
    font-weight: 700;
  }

  h2 {
    color: ${props => props.theme.colors.primary};
    font-size: 1.8rem;
    font-weight: 700;
  }
`;

export const StyledTableWrapper = styled.div`
  .MuiPaper-root {
    padding: 2rem;
  }
`;

export const StyledCard = styled(Paper)`
  display: flex;
  padding: 2rem;
  align-items: center;
  justify-content: center;
  margin: 2rem;

  p {
    color: ${props => props.theme.colors.red};
    margin-bottom: 0;
  }
`;
