import api from './api';

const facebookAPIGetPicture = async ({ userID, token }) => {
  try {
    const response = await api.get(`${userID}/picture?width=600&height=600&redirect=0&fields=url`, {}, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    return response.data.url;
  } catch (err) {
    console.log(err);
  }
};

export default facebookAPIGetPicture;
