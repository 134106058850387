import React from 'react';
import { IconButton } from '../../../../../../components';
import {
  StyledBox, StyledPlatformHeader, StyledSection, StyledSectionHeader, StyledStatusHeader,
} from './monitor-section-popover.styles';
import { TMonitorsSectionPopoverProps } from './types';

const MonitorsSectionPopover = ({
  title,
  open,
  setOpen,
  platform,
}: TMonitorsSectionPopoverProps) => (
  <StyledSection>
    <StyledBox>
      <h2>{title}</h2>
      <IconButton
        label=""
        tooltip=""
        icon={open ? 'KeyboardArrowDown' : 'KeyboardArrowRight'}
        isWhite
        onClick={() => setOpen(!open)}
      />
    </StyledBox>
    {open && (
    <StyledSectionHeader>
      <StyledPlatformHeader>SAAS</StyledPlatformHeader>
      <StyledPlatformHeader>{platform}</StyledPlatformHeader>
      <StyledStatusHeader>Status</StyledStatusHeader>
    </StyledSectionHeader>
    )}
  </StyledSection>
);
export default MonitorsSectionPopover;
