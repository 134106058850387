import { isEmpty, isNil } from 'ramda';
import getUserAssociatedAccounts from '../../../../services/google/getUserAssociatedAccounts';
import { TLocationEntity } from '../../../../types/TLocation';

import {
  TLocationConnection,
  TSetOptionsInDropDownAccounts,
  TSetOptionsInDropDownAccountsResponse,
} from '../../../../types/TPlatformsLinkWithLocation';

export const getGoogleUserAccounts = async ({
  googleAccessToken,
  googleUserAccountsPage = '',
}: TSetOptionsInDropDownAccounts): Promise<TSetOptionsInDropDownAccountsResponse> => {
  if (isNil(googleAccessToken) || isNil(googleUserAccountsPage)) {
    return {
      googleUserAccounts: [],
      nextGoogleUserAccountPageToken: '',
    };
  }

  const { accounts, nextPageToken } = await getUserAssociatedAccounts({
    accessToken: googleAccessToken,
    pageToken: googleUserAccountsPage,
  });

  const formattedGoogleAccounts = accounts.map((account: any) => ({
    name: account.accountName,
    id: account.name.split('/')[1],
  }));

  return {
    googleUserAccounts: formattedGoogleAccounts,
    nextGoogleUserAccountPageToken: nextPageToken,
  };
};

export const getLocationAddress = (location: TLocationEntity) =>
  `${location.address1}${location.address2 || ''} - ${
    location.neighborhood
  } CEP: ${location.zip_code} - ${location.city}`;

export const getButtonConfig = (
  locationConnections: TLocationConnection[],
  googleLocationNameId: string,
  isAdminOrOwner: boolean,
  userId: number
) => {
  const googleLocationId = googleLocationNameId.split('/')[1];
  if (isEmpty(locationConnections))
    return { disabled: false, message: 'Adicionar conexão' };

  const filteredByGoogleLocationId = locationConnections.filter(
    (connection) =>
      connection.connection_info.google_location_id === googleLocationId
  );

  if (filteredByGoogleLocationId.length > 0) {
    const filteredByUser = filteredByGoogleLocationId.filter(
      (connection) => connection.created_by === userId
    );
    if (filteredByUser.length > 0)
      return { disabled: false, message: 'Conectado' };

    return { disabled: false, message: 'Adicionar conexão' };
  }

  if (isAdminOrOwner) return { disabled: false, message: 'Trocar conexão' };

  return { disabled: true, message: 'Impossível conectar' };
};
