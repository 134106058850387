import axios from 'axios';

import { TAddOtherResponsibles } from '../../types/TLocation';

export default async function addOtherResponsibles({
  accessToken,
  locationId,
  responsibleIds,
  setTokenLikeExpired,
  setIsAddingResponsibles,
}: TAddOtherResponsibles) {
  try {
    if (setIsAddingResponsibles) setIsAddingResponsibles(true);

    const { data } = await axios.post(
      `${process.env.REACT_APP_LOCATIONS_API_URL}/other-responsibles/add/${locationId}`,
      { responsibleIds },
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      },
    );

    return data;
  } catch (err: any) {
    if (err.response?.status === 401 && setTokenLikeExpired) setTokenLikeExpired();

    return err;
  } finally {
    if (setIsAddingResponsibles) setIsAddingResponsibles(false);
  }
}
