import axios from 'axios';

export default async function getProfiles({
  accessToken,
  page = 0,
  pageSize = 10,
  query = '',
  setIsFetching,
  setTokenLikeExpired,
}) {
  const requestOptions = {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  };

  try {
    if (setIsFetching) setIsFetching(true);

    const { data } = await axios.get(
      `${process.env.REACT_APP_PROFILES_API_URL}?page=${page}&pageSize=${pageSize}&query=${query}`,
      requestOptions,
    );

    return data;
  } catch (err) {
    if (err.response?.status === 401 && setTokenLikeExpired) return setTokenLikeExpired();

    return null;
  } finally {
    if (setIsFetching) setIsFetching(false);
  }
}
