import axios from 'axios';

export default async function updateHandler(accessToken, id, name) {
  const requestOptions = {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  };

  try {
    const { data } = await axios.put(
      `${process.env.REACT_APP_HANDLERS_API_URL}/${id}`,
      { name },
      requestOptions,
    );

    console.log(data);
    return data;
  } catch (err) {
    console.log(err);
    return err;
  }
}
