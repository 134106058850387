import axios from 'axios';

export default async function deleteFunction(accessToken, functionId, setTokenLikeExpired) {
  try {
    const requestOptions = {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    };

    const { data } = await axios.delete(
      `${process.env.REACT_APP_FUNCTIONS_API_URL}/${functionId}`,
      requestOptions,
    );

    return data;
  } catch (err) {
    if (err.response?.status === 401 && setTokenLikeExpired) return setTokenLikeExpired();
    console.log(err);
    return null;
  }
}
