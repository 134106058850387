import { lighten } from 'polished';
import styled from 'styled-components';

export const StyledSyncMonitoring = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 3rem;
  
  .tabbar-tab:nth-child(even) {
    background: ${props => lighten(0.77, props.theme.colors.black)};

    &:hover {
      background: #025db1;
    }

    &.Mui-selected {
      background: #026aca;
    }
  }
`;
