import styled from 'styled-components';

export const StyledSectionHeader = styled.header`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 2rem;
    position: relative;
`;

export const StyledTitleSection = styled.h3`
    font-size: 2rem;
    color: ${props => props.theme.colors.primary};
    margin-bottom: 0;
    line-height: 1;
`;
