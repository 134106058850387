import React, { useState } from 'react';
import { useHistory, useParams, Redirect } from 'react-router-dom';
import { CircularProgress } from '@material-ui/core';
import { useSelector, useDispatch } from 'react-redux';

import { Creators as RuleActions } from '../../redux/ducks/rules';

import {
  Can,
  ItemBoard,
} from '../../components';

import getMenus from '../../services/menus/getMenus';
import getFunctions from '../../services/functions/getFunctions';
import getProfile from '../../services/profiles/getProfile';
import getProfiles from '../../services/profiles/getProfiles';
import createProfile from '../../services/profiles/createProfile';
import updateProfile from '../../services/profiles/updateProfile';
import addFunctions from '../../services/profiles/addFunctions';
import removeFunctions from '../../services/profiles/removeFunctions';

const CreateOrUpdateProfile = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const params = useParams();
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [menus, setMenus] = useState([]);
  const [menu, setMenu] = useState(0);
  const [functionsToAdd, setFunctionsToAdd] = useState([]);
  const [addedFunctions, setAddedFunctions] = useState([]);
  const [profilePreviousFunctions, setProfilePreviousFunctions] = useState([]);
  const [loading, setLoading] = useState(false);

  const { id } = params;
  const { user } = useSelector((state) => state.AuthReducer);

  React.useEffect(() => {
    const fetchData = async () => {
      const menusData = await getMenus({ accessToken: user.accessToken });
      setMenus(menusData[0]);
      const [functionData] = await getFunctions(user.accessToken);

      if (id) {
        const profileData = await getProfile(user.accessToken, id);
        setName(profileData.name);
        setDescription(profileData.description);
        setMenu(profileData.menu?.id);
        setProfilePreviousFunctions(profileData.functions);
        setAddedFunctions(profileData.functions);

        profileData.functions.map((element) => {
          const { id } = element;
          for (let i = 0; i < functionData.length; i++) {
            if (functionData[i].id === id) {
              functionData.splice(i, 1);
            }
          }
        });
      }

      setFunctionsToAdd(functionData);
    };
    fetchData();
  }, []);

  const renderButton = () => {
    if (loading) return <CircularProgress />;
    return (
      <button
        className="btn btn-primary button-margin-top font-poppins"
        onClick={
          id ? handleOnUpdateProfileClicked : handleOnCreateProfileClicked
        }
      >
        {id ? 'Salvar Profile' : 'Criar Profile'}
      </button>
    );
  };

  const refreshReducer = async () => {
    const rules = {};
    const profilesData = await getProfiles({ accessToken: user.accessToken });
    const profiles = profilesData[0];
    profiles.forEach((element) => {
      const funcs = [];
      element.functions.forEach((func) => {
        funcs.push(func.full_name);
      });
      rules[element.name] = { static: funcs };
    });
    dispatch(RuleActions.setRules(rules));
  };

  const handleOnCreateProfileClicked = async () => {
    setLoading(true);
    const functionIds = [];
    addedFunctions.map((element) => {
      functionIds.push(element.id);
    });

    await createProfile(
      user.accessToken,
      name,
      description,
      menu,
      functionIds,
      user.user.id,
    );

    await refreshReducer();

    setLoading(false);
    history.push('/profiles/list');
  };

  const handleOnUpdateProfileClicked = async () => {
    setLoading(true);

    await updateProfile(user.accessToken, id, name, description, menu);

    if (profilePreviousFunctions.length > 0) {
      const previousFunctionIds = [];
      profilePreviousFunctions.map((element) => {
        previousFunctionIds.push(element.id);
      });

      await removeFunctions(user.accessToken, id, previousFunctionIds);
    }
    if (addedFunctions) {
      const functionIds = [];
      addedFunctions.map((element) => {
        functionIds.push(element.id);
      });
      await addFunctions(user.accessToken, id, functionIds);
    }

    await refreshReducer();

    setLoading(false);
    history.push('/profiles/list');
  };

  const handleSetName = ({ target: { value } }) => {
    setName(value);
  };

  const handleSetDescription = ({ target: { value } }) => {
    setDescription(value);
  };

  const handleSetMenu = ({ target: { value } }) => {
    setMenu(value);
  };

  const onAvailableFunctionClicked = (id) => {
    const func = functionsToAdd.filter((element) => element.id === id);
    setAddedFunctions([...addedFunctions, func[0]]);
    setFunctionsToAdd(functionsToAdd.filter((item) => item.id !== func[0].id));
  };

  const onAddedFunctionClicked = (id) => {
    const func = addedFunctions.filter((element) => element.id === id);
    setFunctionsToAdd([...functionsToAdd, func[0]]);
    setAddedFunctions(addedFunctions.filter((item) => item.id !== func[0].id));
  };

  return (
    <Can
      perform="Profiles/Create"
      yes={() => (
        <div style={{ padding: '1em' }}>
          <div className="title-div">
            <text className="font-poppins page-title">
              {id ? 'Editar Profile' : 'Cadastro de profile'}
            </text>
          </div>
          <label className="font-poppins">Nome</label>
          <input
            className="form-control font-poppins"
            name="name"
            value={name}
            onChange={handleSetName}
          />

          <label className="font-poppins">Descrição</label>
          <input
            className="form-control font-poppins"
            name="description"
            value={description}
            onChange={handleSetDescription}
          />

          <label className="font-poppins">Menu</label>
          <select
            className="form-control font-poppins"
            value={menu}
            onChange={handleSetMenu}
          >
            <option value="" selected disabled hidden />
            {menus.map((element) => (
              <option value={element.id}>{element.name}</option>
            ))}
          </select>

          <ItemBoard
            availableItemText="Funções Disponíveis"
            itemsToAdd={functionsToAdd}
            onAvailableItemClicked={onAvailableFunctionClicked}
            addedItemText="Funções Adicionadas"
            addedItems={addedFunctions}
            onAddedItemClicked={onAddedFunctionClicked}
          />

          <div className="create-button-div">{renderButton()}</div>
        </div>
      )}
      no={() => <Redirect to="/main" />}
    />
  );
};

export default CreateOrUpdateProfile;
