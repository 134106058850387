import styled from 'styled-components';

export const StyledDialog = styled.div`
`;

export const StyledLoadingWrapper = styled.div`
  display:flex;
  justify-content:center;
  padding: 2rem 0;
`;
