import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useHistory, useParams, Redirect } from 'react-router-dom';
import { CircularProgress } from '@material-ui/core';

import { Can } from '../../components';

import getHandler from '../../services/handlers/getHandler';
import updateHandler from '../../services/handlers/updateHandler';
import createHandler from '../../services/handlers/createHandler';

const CreateOrUpdateHandler = () => {
  const history = useHistory();
  const params = useParams();
  const [loading, setLoading] = useState(false);
  const [name, setName] = useState('');

  const { user } = useSelector((state) => state.AuthReducer);

  const { id } = params;

  useEffect(() => {
    setLoading(true);
    const fetchData = async () => {
      const data = await getHandler(user.accessToken, id);
      setName(data.name);
      return data;
    };

    if (id) {
      fetchData();
    }
    setLoading(false);
  }, []);

  const handleChangeName = ({ target: { value } }) => {
    setName(value);
  };

  const handleOnCreateClick = async () => {
    setLoading(true);
    let result;
    if (id) result = await updateHandler(user.accessToken, id, name);
    else result = await createHandler(user.accessToken, name, user.user.id);
    console.log(result);
    setLoading(false);
    history.push('/handlers/list');
  };

  const renderButton = () => {
    if (loading) return <CircularProgress />;
    return (
      <button
        className="btn btn-primary button-margin-top font-poppins"
        onClick={handleOnCreateClick}
      >
        {id ? 'Salvar Handler' : 'Criar Handler'}
      </button>
    );
  };

  return (
    <Can
      perform="Handlers/Create"
      yes={() => (
        <div style={{ padding: '1em' }}>
          <div className="title-div">
            <text className="font-poppins page-title">
              {id ? 'Editar Handler' : 'Cadastro de Handler'}
            </text>
          </div>
          <label className="font-poppins">Nome</label>
          <input
            className="form-control font-poppins"
            name="name"
            value={name}
            onChange={handleChangeName}
          />

          <div className="create-button-div">{renderButton()}</div>
        </div>
      )}
      no={() => <Redirect to="/main" />}
    />
  );
};

export default CreateOrUpdateHandler;
