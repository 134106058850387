import React from 'react';
import * as R from 'ramda';

import { IconButton, Loading } from '../../../../../components';

import {
  StyledResponse,
  StyledStar,
  StyledStarWrapper,
} from './response-styles';

const Response = ({
  id,
  loading,
  onDeleteClicked,
  onEditClicked,
  createdAt,
  text,
  star_rating,
  className,
  isDefault,
  selectedResponse,
  setSelectedResponse,
}) => {
  const [isSelected, setIsSelected] = React.useState(false);

  React.useEffect(() => {
    if (!R.isNil(setSelectedResponse) && selectedResponse?.id === id) {
      setIsSelected(true);
    } else {
      setIsSelected(false);
    }
  }, [selectedResponse]);

  const handleIsSelected = () => {
    setSelectedResponse({ text, id });
  };

  return (
    <StyledResponse
      className={className}
      isDefault={isDefault}
      onClick={handleIsSelected}
      isSelected={isSelected}
    >
      <div className="options">
        {loading ? <Loading /> : (
          <>
            <IconButton
              className="icon-option"
              icon="Edit"
              tooltip="Editar"
              onClick={onEditClicked}
              isWhite={isSelected}
            />
            <IconButton
              className="icon-option"
              icon="Delete"
              tooltip="Excluir"
              onClick={onDeleteClicked}
              isWhite={isSelected}
            />
          </>
        )}
      </div>
      <div className="response-text-quotation">
        <small className="quotation-mark">"</small>
        {text}
        <small className="quotation-mark">"</small>
      </div>
      <div className="infos-of-this-response">
        <StyledStarWrapper>
          {[...Array.from(Array(star_rating).keys())].map((item) => (
            <StyledStar key={`${id}_${star_rating}_${item}`} />
          ))}
        </StyledStarWrapper>
        <span>
          Data de criação:
          <small>{createdAt}</small>
        </span>
      </div>
    </StyledResponse>
  );
};

export default Response;
