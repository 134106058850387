import axios from 'axios';

export default async function getCategories(accessToken, reduxAuth) {
  const requestOptions = {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  };

  try {
    const { data } = await axios.get(
      `${process.env.REACT_APP_CATEGORIES_API_URL}`,
      requestOptions,
    );

    return data;
  } catch (err) {
    if (err.response?.status === 401 && reduxAuth) {
      const {
        AuthActions,
        reduxDispatch,
        userData,
      } = reduxAuth;

      reduxDispatch(AuthActions.setUser({ ...userData, accessToken: 'expired' }));
    }

    console.log(err.response);
  }
}
