import axios, { AxiosRequestConfig } from 'axios';
import { TGetAllErrorsFromMonitor } from './types';

export const getAllErrorsFromMonitor = async ({
  userAccessToken,
  monitorId,
  isFetching,
  setTokenLikeExpired,
}: TGetAllErrorsFromMonitor): Promise<string | null> => {
  try {
    if (isFetching) isFetching(true);
    const apiPath = `${process.env.REACT_APP_SYNC_PLATFORM_MONITORING}/get-error/${monitorId}`;

    const requestOptions: AxiosRequestConfig = {
      headers: {
        Authorization: `Bearer ${userAccessToken}`,
      },
    };

    const { data } = await axios.get(apiPath, requestOptions);

    return JSON.stringify(data, null, 2);
  } catch (err: any) {
    if (err.response?.status === 401 && setTokenLikeExpired) setTokenLikeExpired();

    return null;
  } finally {
    if (isFetching) isFetching(false);
  }
};
