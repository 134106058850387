import { emailValidate, passwordValidate } from "../../utils/fields-validate";

import * as R from 'ramda';

export const normalizedNameLocationOrCompany = nameValue => nameValue.replace(/%20/g, ' ');

export const handleFormValidation = ({
  fields,
  addedProfiles,
  googleRefreshToken,
  enqueueSnackbar,
}) => {
  let checkIfAnyEmptyField = false;
  let checkIfEmailIsInvalid = false;
  let checkIfPasswordIsInvalid = false;
  let checkIfInvalidLimit = false;

  fields.map(field => {
    if (field.getValue() === '') {
      checkIfAnyEmptyField = true;
    }

    if (field.getValue() < 0) {
      checkIfInvalidLimit = true;
    }

    if (field.name === 'field-user-email') {
      if (!emailValidate(field.getValue())) {
        checkIfEmailIsInvalid = true;
      }
    }

    if (field.name === 'field-user-password') {
      if (!passwordValidate(field.getValue())) {
        checkIfPasswordIsInvalid = true;
      }
    }
  });

  if (checkIfAnyEmptyField) {
    enqueueSnackbar('Todos os campos devem ser preenchidos', { variant: 'warning' });
    return true;
  }

  if (checkIfEmailIsInvalid) {
    enqueueSnackbar('Formato de email inválido', { variant: 'warning' });
    return true;
  }

  if (checkIfPasswordIsInvalid) {
    enqueueSnackbar('Senha deve conter 8 letras, números e uma letra maiúscula', { variant: 'warning' });
    return true;
  }

  if (googleRefreshToken === '') {
    enqueueSnackbar('Insira um Google Refresh Token válido', { variant: 'warning' });
    return true;
  }

  if (checkIfInvalidLimit) {
    enqueueSnackbar('Os limites de registro não podem ser negativos', { variant: 'warning' });
    return true;
  }

  if (R.isEmpty(addedProfiles)) {
    enqueueSnackbar('Adicione um perfil de usuário antes de prosseguir', { variant: 'warning' });
    return true;
  }

  return false;
};