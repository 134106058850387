import axios from 'axios';

export default async function createInfoModel(
  accessToken,
  name,
  short_description,
  long_description,
  categories,
  tags,
  created_by = 1,
) {
  const requestOptions = {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  };

  const { data } = await axios.post(
    `${process.env.REACT_APP_INFO_MODEL_API_URL}`,
    {
      name,
      short_description,
      long_description,
      categories,
      tags,
      created_by,
    },
    requestOptions,
  );

  return data;
}
