import axios from 'axios';

export default async function getTickets({
  accessToken,
  page = 0,
  pageSize = 20,
  query = '',
  status = '',
  setIsFetching,
  setTokenLikeExpired,
}) {
  try {
    if (setIsFetching) setIsFetching(true);

    const requestOptions = {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    };

    const { data } = await axios.get(
      `${process.env.REACT_APP_TICKETS_API_URL}?page=${page}&pageSize=${pageSize}&query=${query}&status=${status}`,
      requestOptions,
    );

    return data;
  } catch (err) {
    if (err.response?.status === 401 && setTokenLikeExpired) setTokenLikeExpired();

    return err;
  } finally {
    if (setIsFetching) setIsFetching(false);
  }
}
