import React from 'react';
import { Input, InputDatePicker } from '../../../components';
import { TLoginHistoryFilterProps } from './helpers';
import {
  StyledDatesWrapper, StyledFilter, StyledFiltersContainer, StyledTitle,
} from './login-history-filter-styles';

const LoginHistoryFilter = ({
  searchUserLabel,
  onChangeUserQuery,
  searchEmailLabel,
  onChangeEmailQuery,
  toPeriod,
  setToPeriod,
  ofPeriod,
  setOfPeriod,
  searchIPLocationLabel,
  onChangeIPLocationQuery,
  searchIPLabel,
  onChangeIPQuery,
}: TLoginHistoryFilterProps) => (
  <StyledFilter>
    <StyledTitle>Filtros</StyledTitle>
    <StyledFiltersContainer>
      <Input
        className="filter-content"
        label={searchUserLabel}
        onChange={(e) => onChangeUserQuery(e.target.value)}
        inputOptions={{
          type: 'text',
          name: 'batch-filter-search-query',
        }}
        border
        placeholder="Escreva algo aqui"
      />
      <Input
        className="filter-content"
        label={searchEmailLabel}
        onChange={(e) => onChangeEmailQuery(e.target.value)}
        inputOptions={{
          type: 'text',
          name: 'batch-filter-search-query',
        }}
        border
        placeholder="Escreva algo aqui"
      />
      <StyledDatesWrapper>
        <InputDatePicker
          className="filter-date-picker filter-date-picker__date-of"
          selectedDate={ofPeriod}
          setSelectedDate={setOfPeriod}
          label="De:"
          maxDate={toPeriod || null}
          isBackground
        />
        <InputDatePicker
          className="filter-date-picker filter-date-picker__date-to"
          selectedDate={toPeriod}
          setSelectedDate={setToPeriod}
          minDate={ofPeriod || null}
          label="Até:"
          isBackground
        />
      </StyledDatesWrapper>
      <Input
        className="filter-content"
        label={searchIPLocationLabel}
        onChange={(e) => onChangeIPLocationQuery(e.target.value)}
        inputOptions={{
          type: 'text',
          name: 'batch-filter-search-query',
        }}
        border
        placeholder="Escreva algo aqui"
      />
      <Input
        className="filter-content"
        label={searchIPLabel}
        onChange={(e) => onChangeIPQuery(e.target.value)}
        inputOptions={{
          type: 'text',
          name: 'batch-filter-search-query',
        }}
        border
        placeholder="Escreva algo aqui"
      />
    </StyledFiltersContainer>
  </StyledFilter>
);

export default LoginHistoryFilter;
