import getZipCodeData from '../../services/get-zipcode';

export const checkMessage = feedbackMessage => message => feedbackMessage(message, { variant: 'error' });

export const setLocationDataInFields = async ({
  zipcode,
  refOfAllFields,
  feedbackMessage,
  isOutsideBrazil,
}) => {
  const zipcodeNormalized = zipcode.replace(/[^\w\s]/gi, '');
  const validZipCodeRegExp = /^\d{8}$/;

  if (isOutsideBrazil) {
    refOfAllFields.forEach(({ name, setCurrentValue }) => {
      if (name === 'zipcode') return setCurrentValue(zipcode);
    });

    return;
  }

  if (validZipCodeRegExp.test(zipcodeNormalized)) {
    const responseZipCode = await getZipCodeData({ zipcode: zipcodeNormalized, feedbackMessage });

    if (responseZipCode && responseZipCode.erro) return;

    const {
      bairro,
      localidade,
      logradouro,
      uf,
      cep,
    } = responseZipCode;

    refOfAllFields.map(({ name, setCurrentValue }) => {
      if (name === 'zipcode') return setCurrentValue(cep);
      if (name === 'city') return setCurrentValue(localidade);
      if (name === 'state-code') return setCurrentValue(uf);
      if (name === 'neighborhood') return setCurrentValue(bairro);
      if (name === 'address') return setCurrentValue(logradouro);
    });
  }
};
