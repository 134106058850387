import React from 'react';
import * as R from 'ramda';

import { Dialog, Input, RoundedButton, Loading } from '../../../../components';
import { Creators as LocationActions } from '../../../../redux/ducks/location';
import APIUpdateLocation from '../../../../services/locations/updateLocation';

import {
  StyledContainer,
  StyledButtonsWrapper,
} from './data-studio-dialog-styles';
import { useAuth } from '../../../../hooks';
import { useDispatch } from 'react-redux';
import { useSnackbar } from 'notistack';
import isValidDataStudioUrl from '../../../../utils/is-valid-data-studio';

export type TDataStudioDialogProps = {
  initialValue?: string | null;
  locationId?: number | null;
  open: boolean;
  onClose: () => void;
};

const DataStudioDialog = ({
  initialValue,
  open,
  locationId,
  onClose,
}: TDataStudioDialogProps) => {
  const { userAccessToken, userSetTokenLikeExpired } = useAuth();
  const reduxDispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const [isLoadingUpdate, setIsLoadingUpdate] = React.useState(false);
  const [dataStudioLink, setDataStudioLink] = React.useState(
    initialValue || ''
  );

  const updateLocationDataStudio = React.useCallback(
    async (newDataStudioValue: string) => {
      setIsLoadingUpdate(true);
      try {
        if (R.isNil(locationId) || R.isNil(userAccessToken)) return;

        const updatedDataStudio = R.isEmpty(newDataStudioValue)
          ? null
          : newDataStudioValue;

        if (
          !R.isNil(updatedDataStudio) &&
          !isValidDataStudioUrl(updatedDataStudio)
        ) {
          enqueueSnackbar('Link do Data Studio inválido', { variant: 'error' });
          return;
        }

        const updateResponse = await APIUpdateLocation({
          accessToken: userAccessToken,
          locationId: locationId,
          setTokenLikeExpired: userSetTokenLikeExpired,
          inputData: {
            data_studio: R.isEmpty(newDataStudioValue)
              ? null
              : newDataStudioValue,
          },
        });

        if (R.isNil(updateResponse))
          throw new Error('error-updating-data-studio');

        reduxDispatch(LocationActions.setActiveLocation(updateResponse));

        enqueueSnackbar('Link atualizado com sucesso', { variant: 'success' });
        onClose();
      } catch (e: any) {
        enqueueSnackbar('Erro ao atualizar link', { variant: 'error' });
      } finally {
        setIsLoadingUpdate(false);
      }
    },
    [locationId, userAccessToken, userSetTokenLikeExpired]
  );

  return (
    <Dialog
      open={open}
      wideDialog
      fullWidth
      maxWidth="md"
      onClose={onClose}
      title={`${initialValue ? 'Editar' : 'Adicionar'} Link para o Data Studio`}
      closeIcon
    >
      <StyledContainer>
        <Input
          border
          value={dataStudioLink}
          onChange={({ target }) => setDataStudioLink(target.value)}
          disabled={isLoadingUpdate}
        />
        <StyledButtonsWrapper>
          {isLoadingUpdate ? (
            <Loading className="data-studio-loading" />
          ) : (
            <>
              <RoundedButton
                title="Salvar"
                onClick={() => {
                  updateLocationDataStudio(dataStudioLink);
                }}
                icon={null}
                className="confirm-button"
              />
              <RoundedButton
                title="Cancelar"
                onClick={onClose}
                icon={null}
                variant="grey"
                className="cancel-button"
              />
            </>
          )}
        </StyledButtonsWrapper>
      </StyledContainer>
    </Dialog>
  );
};

export default DataStudioDialog;
