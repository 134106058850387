import styled from 'styled-components';
import { devices } from '../../../../assets/styles/devices';

export const StyledPlatformsStatus = styled.div`
    display: flex;
    flex-direction: column;
    padding: 3rem 3rem 6rem;
    background: ${props => props.theme.colors.white};

    h3 {
        font-size: 3rem;
        margin-bottom: 2rem;
    }

    .platforms-apply-button {
        max-width: max-content;
        margin-left: auto;
    }

    .loading-platform-apply {
        margin-right: 1rem;
    }
`;

export const StyledPlatformsWrap = styled.div`
    display: grid;
    grid-template-columns: 1fr;
    width: 100%;
    row-gap: 2rem;
    margin-bottom: 3rem;

    .ticket-select {
        margin-bottom: 0;
    }

    @media ${devices.tabletLandscape} {
        grid-template-columns: repeat(2, 1fr);
        column-gap: 2rem;
    }

    @media ${devices.desktop} {
        grid-template-columns: repeat(3, 1fr);
    }

    @media ${devices.widescreen} {
        grid-template-columns: repeat(4, 1fr);
    }
`;
