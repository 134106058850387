import React from 'react';
import axios from 'axios';
import type { EBatchPlatformStatus } from '../../../types/TBatchGoogleModules';
import type { TArrayResponseWithCount } from '../../../types/TArrayResponseWithCount';
import type { TServiceBatchLocation } from '../../../types/TServiceBatch';

export type TFindServiceBatchLocations = {
  userAccessToken: string;
  isFetching?: React.Dispatch<React.SetStateAction<boolean>>;
  batchId?: string;
  page?: number;
  pageSize?: number;
  query?: string;
  status?: EBatchPlatformStatus | null;
  affectedPlatforms?: string;
  affectedPlatformsChecked?: boolean | string;
};

type TSuccessResponse = {
  status: 'SUCCESS',
  data: TArrayResponseWithCount<TServiceBatchLocation>,
};

type TErrorResponse = {
  status: 'ERROR',
  data: any,
};

export const findServiceBatchLocations = async ({
  batchId,
  isFetching,
  userAccessToken,
  page = 0,
  pageSize = 50,
  status,
  query = '',
  affectedPlatforms = '',
  affectedPlatformsChecked = false,
}: TFindServiceBatchLocations): Promise<TSuccessResponse | TErrorResponse> => {
  try {
    if (isFetching) isFetching(true);

    let queryPath = `page=${page}&pageSize=${pageSize}&query=${query}&affectedPlatforms=${affectedPlatforms}&affectedPlatformsChecked=${affectedPlatformsChecked}`;

    if (batchId) queryPath += `&batchId=${batchId}`;
    if (status) queryPath += `&status=${status}`;

    const { data } = await axios.get(
      `${process.env.REACT_APP_SAAS_BATCH_GOOGLE_MODULES}/service-batch-locations?${queryPath}`,
      {
        headers: {
          Authorization: `Bearer ${userAccessToken}`,
        },
      },
    );

    return {
      status: 'SUCCESS',
      data,
    };
  } catch (err: any) {
    return {
      status: 'ERROR',
      data: err.response,
    };
  } finally {
    if (isFetching) isFetching(false);
  }
};
