import { MUIDataTableColumn } from 'mui-datatables';
import React from 'react';

export const tableColumns: MUIDataTableColumn[] = [
  {
    label: 'Id',
    name: 'id',
    options: {
      display: false,
      sort: false,
    },
  },
  {
    label: 'Empresa',
    name: 'company',
    options: {
      sort: false,
    },
  },
  {
    label: 'Local',
    name: 'location',
    options: {
      sort: false,
    },
  },
  {
    label: 'Solicitante',
    name: 'requester',
    options: {
      sort: false,
    },
  },
  {
    label: 'Data',
    name: 'created_at',
    options: {
      sort: false,
    },
  },
  {
    label: 'Status',
    name: 'status',
    options: {
      sort: false,
      customHeadRender: (columnMeta): React.ReactNode => React.createElement('th', { style: { textAlign: 'center' } }, columnMeta.label),

    },
  },
  {
    label: 'Solicitação',
    name: 'request',
    options: {
      sort: false,
      customHeadRender: (columnMeta): React.ReactNode => React.createElement('th', { style: { textAlign: 'center' } }, columnMeta.label),
    },
  },
];
