import React from 'react';
import { Helmet } from 'react-helmet';
import { useSelector, useDispatch } from 'react-redux';

import { Creators as AuthActions } from '../../redux/ducks/auth';

import { useSnackbar } from 'notistack';

import AddCategoryGrouping from './add-category-grouping';
import GroupsListing from './groups-listing';

import {
  StyledCategoryGrouping,
  StyledHeader,
  StyledContent,
} from './category-grouping-styles';

const CategoryGrouping = () => {
  const reduxDispatch = useDispatch();

  const { user } = useSelector(state => state.AuthReducer);
  const { accessToken: userToken } = user;

  const { enqueueSnackbar } = useSnackbar();

  const [updateTable, setUpdateTable] = React.useState(false);

  const [isFetchingToEdit, setIsFetchingToEdit] = React.useState(false);
  const [dataToEdit, setDataToEdit] = React.useState(null);

  const setTokenLikeExpired = () => {
    reduxDispatch(AuthActions.setUser({ ...user, accessToken: 'expired' }));
  };

  return (
    <StyledCategoryGrouping>
      <Helmet>
        <meta property="og:title" content="Grupos de Categoria - SaaS Hublocal" />
        <meta property="og:description" content="Crie grupos de categoria baseado nas categorias das principais plataformas." />

        <title>Grupos de Categoria - SaaS Hublocal</title>
      </Helmet>

      <StyledHeader>
        <h2>Grupos de categorias</h2>
      </StyledHeader>

      <StyledContent>
        <AddCategoryGrouping
          userToken={userToken}
          setTokenLikeExpired={setTokenLikeExpired}
          snackbar={enqueueSnackbar}
          setUpdateTable={setUpdateTable}
          isFetchingToEdit={isFetchingToEdit}
          dataToEdit={dataToEdit}
          setDataToEdit={setDataToEdit}
        />
        <GroupsListing
          userToken={userToken}
          setTokenLikeExpired={setTokenLikeExpired}
          snackbar={enqueueSnackbar}
          setUpdateTable={setUpdateTable}
          updateTable={updateTable}
          setIsFetchingToEdit={setIsFetchingToEdit}
          setDataToEdit={setDataToEdit}
        />
      </StyledContent>
    </StyledCategoryGrouping>
  );
};

export default CategoryGrouping;
