import axios from 'axios';

export default async function removeLocations(
  {
    accessToken,
    locationGroupId,
    locationIds,
    setTokenLikeExpired,
    feedbackMessage,
    isDeleting,
  },
) {
  const requestOptions = {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  };

  try {
    if (isDeleting) isDeleting(true);
    if (feedbackMessage) feedbackMessage('Removendo local desse grupo...', { variant: 'warning' });

    const { data } = await axios.post(
      `${process.env.REACT_APP_LOCATION_GROUPS_API_URL}/location/remove/${locationGroupId}`,
      { locationIds },
      requestOptions,
    );

    if (feedbackMessage) feedbackMessage('Local removido com sucesso!', { variant: 'success' });

    return data;
  } catch (err) {
    if (err.response?.status === 401 && setTokenLikeExpired) setTokenLikeExpired();
    if (err.response?.status === 403) feedbackMessage('Falha ao vincular empresa: Você já alcançou o limite de empresas disponíveis', { variant: 'error' });

    return null;
  } finally {
    if (isDeleting) isDeleting(false);
  }
}
