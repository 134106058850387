import axios from 'axios';

export default async function removeResponsibles({
  accessToken,
  locationGroupId,
  responsibleIds,
  setTokenLikeExpired,
}) {
  try {
    const { data } = await axios.post(
      `${process.env.REACT_APP_LOCATION_GROUPS_API_URL}/responsible/remove/${locationGroupId}`,
      { responsibleIds },
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      },
    );

    return data;
  } catch (err) {
    if (err.response?.status === 401 && setTokenLikeExpired) setTokenLikeExpired();

    return err;
  }
}
