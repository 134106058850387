import React from 'react';
import * as R from 'ramda';

import {
  TWorkingHoursConfigBaseProps,
  THandleSetTime,
} from '../../../../../types/TWorkingHours';

import Time from '../../../../../components/time';
import TimeActions from '../../time-actions';

import {
  StyledWorkingHoursConfig,
  StyledTimes,
  StyledTimeItem,
  StyledAddNewTime,
} from './working-hours-configs-styles';

const WorkingHoursConfig = ({
  readonly,
  times,
  className,
  setTimes,
}: TWorkingHoursConfigBaseProps) => {
  const handleSetNewTime = React.useCallback(({
    timeTargetId,
    timeValue, openOrCloseKey,
  }: THandleSetTime): void => {
    const newTimes = times.map(time => {
      if (time.id === timeTargetId) {
        return {
          ...time,
          [openOrCloseKey]: timeValue,
        };
      }

      return time;
    });

    if (R.isNil(newTimes) || R.isNil(setTimes)) return;

    setTimes(newTimes);
  }, [times]);

  const handleDeleteTimeRange = React.useCallback((timeRangeId: number) => {
    const newTimes = times.map(time => {
      if (time.id === timeRangeId) {
        return {
          ...time,
          is_deleted: true,
        };
      }

      return time;
    });

    if (R.isNil(setTimes)) return;

    setTimes(newTimes);
  }, [times]);

  const handleAddNewTimeRange = React.useCallback(() => {
    const newTimes = [
      ...times,
      {
        id: Math.floor(Math.random() * 1000),
        open: '00:00',
        close: '00:00',
        is_deleted: false,
        is_mocked: true,
      },
    ];

    if (R.isNil(setTimes)) return;

    setTimes(newTimes);
  }, [times]);

  return (
    <StyledWorkingHoursConfig
      className={className}
    >
      <StyledTimes>
        {times
          .filter(({ is_deleted: isDeleted }) => !isDeleted)
          .map(time => (
            <StyledTimeItem key={time.id}>
              <Time
                readonly={readonly}
                className="time-input"
                label="Abre"
                timeValue={time.open}
                setTimeValue={(newTime) => {
                  handleSetNewTime({
                    timeTargetId: time.id,
                    timeValue: newTime,
                    openOrCloseKey: 'open',
                  });
                }}
              />
              <Time
                readonly={readonly}
                className="time-input"
                label="Fecha"
                timeValue={time.close}
                setTimeValue={(newTime) => {
                  handleSetNewTime({
                    timeTargetId: time.id,
                    timeValue: newTime,
                    openOrCloseKey: 'close',
                  });
                }}
              />
              {!readonly && (
                <TimeActions
                  handleAddNewTimeRange={handleAddNewTimeRange}
                  handleDeleteTimeRange={() => handleDeleteTimeRange(time.id)}
                />
              )}
            </StyledTimeItem>
          ))}

        {(R.isEmpty(times.filter(({
          is_deleted: isDeleted,
        }) => !isDeleted))) && (
          <StyledAddNewTime onClick={handleAddNewTimeRange}>
            Adicionar faixa de horário
          </StyledAddNewTime>
        )}
      </StyledTimes>
    </StyledWorkingHoursConfig>
  );
};

export default WorkingHoursConfig;
