import * as R from 'ramda';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Creators as AuthActions } from '../../redux/ducks/auth';

import { getCategoryGroupingPaginated } from '../../services/category-groupings';

import getPlatformCategories from '../../services/categories/getPlatformCategories';

import { useUncontrolledField } from '../../hooks/index';

import AutocompleteInput from '../autocomplete-input';
import GoogleServiceAreaPlacesInput from '../google-service-area-places-input';
import LocationNameByGoogle from '../location-name-by-google';

import {
  filterRepeated, getAddressName,
  getAddressNumber,
} from './helpers';

import {
  address,
  addressComplement,
  addressNumber,
  answerAtThatAddress,
  city,
  dataStudioUrl,
  localEmail,
  localName,
  mainPhone,
  neighborhood,
  secondaryPhone,
  stateCode,
  storeCode,
  zipcode,
} from './fields-scheme';

import {
  StyledDataStudioWrapper, StyledFieldsWrapper, StyledForm, StyledFormContainer, StyledLocationFormUncontrolled, StyledSectionTitle, StyledSeparator, StyledTitleBlock,
} from './location-form-uncontrolled-styles';
import CountryCodeSelect from '../country-code-select';

const LocationFormUncontrolled = ({
  platformsCategory,
  initialData,
  editMode = false,
  ignoreNameField = false,
  inputStartedFocus,
  setCountryCode = null,
  countryCode = 'Brazil',
}, ref) => {
  const [fields, setFields] = React.useState([]);

  const reduxDispatch = useDispatch();
  const {
    user: {
      accessToken: hublocalUserAccessToken,
      user,
    },
  } = useSelector(state => state.AuthReducer);

  const { profiles } = user;
  const [userProfile] = profiles;

  const {
    store_code: locationStoreCode,
    id: locationId,
    name: locationName,
    address1: locationMainAddres,
    address2: locationAddressComplement,
    city: locationCity,
    email: locationEmail,
    neighborhood: locationNeighborhood,
    state: locationState,
    zip_code: locationZipCode,
    phone: locationMainPhone,
    data_studio: locationDataStudioUrl,
    secondary_phone: locationSecondaryPhone,
    does_not_serve_at_this_address: locationAnswerAtThatAddress,
    google_service_area: googleServiceArea,
    google_categories: initialGoogleCategories,
    facebook_categories: initialFacebookCategories,
    foursquare_categories: initialFoursquareCategories,
  } = initialData || {};

  const { Field: StoreCode } = useUncontrolledField({
    fieldScheme: storeCode,
    saveIn: setFields,
  });

  const { Field: LocalName } = useUncontrolledField({
    fieldScheme: {
      ...localName,
      ignoreField: ignoreNameField,
    },
    saveIn: setFields,
  });

  const { Field: MainPhone } = useUncontrolledField({
    fieldScheme: mainPhone,
    saveIn: setFields,
  });

  const { Field: SecondaryPhone } = useUncontrolledField({
    fieldScheme: secondaryPhone,
    saveIn: setFields,
  });

  const { Field: LocalEmail } = useUncontrolledField({
    fieldScheme: localEmail,
    saveIn: setFields,
  });

  const { Field: LocalDataStudioUrl } = useUncontrolledField({
    fieldScheme: dataStudioUrl,
    saveIn: setFields,
  });

  const { Field: ZipCode } = useUncontrolledField({
    fieldScheme: zipcode,
    saveIn: setFields,
    refOfAllFields: fields,
    isOutsideBrazil: countryCode !== 'Brazil',
  });

  const { Field: Address } = useUncontrolledField({
    fieldScheme: address,
    saveIn: setFields,
    isOutsideBrazil: countryCode !== 'Brazil',
  });

  const { Field: AddressNumber } = useUncontrolledField({
    fieldScheme: addressNumber,
    saveIn: setFields,
    isOutsideBrazil: countryCode !== 'Brazil',
  });

  const { Field: AddressComplement } = useUncontrolledField({
    fieldScheme: addressComplement,
    saveIn: setFields,
  });

  const { Field: City } = useUncontrolledField({
    fieldScheme: city,
    saveIn: setFields,
    isOutsideBrazil: countryCode !== 'Brazil',
  });

  const { Field: Neighborhood } = useUncontrolledField({
    fieldScheme: neighborhood,
    saveIn: setFields,
    isOutsideBrazil: countryCode !== 'Brazil',
  });

  const { Field: StateCode } = useUncontrolledField({
    fieldScheme: stateCode,
    saveIn: setFields,
    isOutsideBrazil: countryCode !== 'Brazil',
  });

  const { Field: AnswerAtThatAddress } = useUncontrolledField({
    fieldScheme: answerAtThatAddress,
    saveIn: setFields,
  });

  const locationNameByGoogleRef = React.useRef(null);

  const [categoryGroupings, setCategoryGroupings] = React.useState([]);
  const [categoryGroupingsSelected, setCategoryGroupingsSelected] = React.useState([]);

  const [googleCategories, setGoogleCategories] = React.useState([]);
  const [selectedGoogleCategories, setSelectedGoogleCategories] = React.useState([]);

  const [facebookCategories, setFacebookCategories] = React.useState([]);
  const [selectedFacebookCategories, setSelectedFacebookCategories] = React.useState([]);

  const [foursquareCategories, setFoursquareCategories] = React.useState([]);
  const [selectedFoursquareCategories, setSelectedFoursquareCategories] = React.useState([]);

  const selectedServiceAreaPlaceInfosRef = React.useRef([]); // TGooglePlaceInfo[] || MUST_BE_EMPTY (Only if switch is activated)

  const onChangeSelectedPlaces = (selectedPlaces) => {
    selectedServiceAreaPlaceInfosRef.current = selectedPlaces;
  };

  const setTokenLikeExpired = () => {
    reduxDispatch(AuthActions.setUser({ ...user, accessToken: 'expired' }));
  };

  const getCategoryGroupings = React.useCallback(async () => {
    const groupings = await getCategoryGroupingPaginated({
      accessToken: hublocalUserAccessToken,
      setTokenLikeExpired,
    });

    if (groupings) {
      const { groupingsData } = groupings;

      const groupingsDataNormalized = groupingsData.map(grouping => ({
        id: grouping.id,
        name: grouping.name,
        facebookCategories: JSON.parse(grouping.facebook_categories),
        googleCategories: JSON.parse(grouping.google_categories),
        foursquareCategories: JSON.parse(grouping.foursquare_categories),
      }));

      setCategoryGroupings(groupingsDataNormalized);
    }
  }, [hublocalUserAccessToken]);

  const getGoogleCategories = React.useCallback(async () => {
    const categories = await getPlatformCategories({
      accessToken: hublocalUserAccessToken,
      query: '',
      platform: 'google',
    });
    setGoogleCategories(categories[0]);
  }, []);

  const getFacebookCategories = React.useCallback(async () => {
    const categories = await getPlatformCategories({
      accessToken: hublocalUserAccessToken,
      query: '',
      platform: 'facebook',
    });
    setFacebookCategories(categories[0]);
  }, []);

  const getFoursquareCategories = React.useCallback(async () => {
    const categories = await getPlatformCategories({
      accessToken: hublocalUserAccessToken,
      query: '',
      platform: 'foursquare',
    });
    setFoursquareCategories(categories[0]);
  }, []);

  const formatGoogleCategories = React.useCallback(() => {
    if (initialGoogleCategories) {
      const categories = JSON.parse(initialGoogleCategories);

      setSelectedGoogleCategories(categories);

      return;
    }

    setSelectedGoogleCategories([]);
  }, [initialData]);

  const formatFacebookCategories = React.useCallback(async () => {
    if (initialFacebookCategories) {
      const categories = JSON.parse(initialFacebookCategories);

      setSelectedFacebookCategories(categories);

      return;
    }

    setSelectedFacebookCategories([]);
  }, [initialData]);

  const formatFoursquareCategories = React.useCallback(async () => {
    if (initialFoursquareCategories) {
      const categories = JSON.parse(initialFoursquareCategories);

      setSelectedFoursquareCategories(categories);

      return;
    }

    setSelectedFoursquareCategories([]);
  }, [initialData]);

  React.useEffect(() => { getGoogleCategories(); }, [getGoogleCategories]);
  React.useEffect(() => { getFacebookCategories(); }, [getFacebookCategories]);
  React.useEffect(() => { getFoursquareCategories(); }, [getFoursquareCategories]);
  React.useEffect(() => { getCategoryGroupings(); }, [getCategoryGroupings]);

  React.useEffect(() => {
    formatGoogleCategories();
  }, [formatGoogleCategories]);

  React.useEffect(() => {
    formatFacebookCategories();
  }, [formatFacebookCategories]);

  React.useEffect(() => {
    formatFoursquareCategories();
  }, [formatFoursquareCategories]);

  React.useEffect(() => {
    if (fields.length && initialData) {
      let fieldToSetDefaultValue = {};

      R.forEach(field => {
        const propName = field.name;
        fieldToSetDefaultValue = {
          ...fieldToSetDefaultValue,
          [propName]: {
            setDefaultValue: field.setDefaultValue,
            setFocus: field.setInputFocus,
          },
        };
      }, fields);

      fieldToSetDefaultValue.name.setDefaultValue(locationName);
      fieldToSetDefaultValue.address.setDefaultValue(getAddressName(locationMainAddres));
      fieldToSetDefaultValue['address-number'].setDefaultValue(getAddressNumber(locationMainAddres));
      fieldToSetDefaultValue['address-complement'].setDefaultValue(locationAddressComplement);
      fieldToSetDefaultValue.email.setDefaultValue(locationEmail);
      fieldToSetDefaultValue['data-studio'].setDefaultValue(locationDataStudioUrl);
      fieldToSetDefaultValue.zipcode.setDefaultValue(locationZipCode);
      fieldToSetDefaultValue.city.setDefaultValue(locationCity);
      fieldToSetDefaultValue['main-phone'].setDefaultValue(locationMainPhone);
      fieldToSetDefaultValue['secondary-phone'].setDefaultValue(locationSecondaryPhone);
      fieldToSetDefaultValue.neighborhood.setDefaultValue(locationNeighborhood);
      fieldToSetDefaultValue['store-code'].setDefaultValue(locationStoreCode);
      fieldToSetDefaultValue['state-code'].setDefaultValue(locationState);
      fieldToSetDefaultValue['answer-at-that-address'].setDefaultValue(locationAnswerAtThatAddress);
    }
  }, [fields, initialData]);

  const handleSetCategoryGroupingSelected = categoryGroupingSelected => {
    if (!R.isEmpty(categoryGroupingSelected)) {
      R.forEach(categoryGrouping => {
        const {
          facebookCategories: facebookCategoriesValue = [],
          googleCategories: googleCategoriesValue = [],
          foursquareCategories: foursquareCategoriesValue = [],
        } = categoryGrouping || {};

        setSelectedFacebookCategories(prevState => [
          ...prevState,
          ...facebookCategoriesValue.filter(categoryItem => filterRepeated({
            categoryItem,
            categoriesReference: prevState,
          })),
        ]);
        setSelectedGoogleCategories(prevState => [
          ...prevState,
          ...googleCategoriesValue.filter(categoryItem => filterRepeated({
            categoryItem,
            categoriesReference: prevState,
          })),
        ]);
        setSelectedFoursquareCategories(prevState => [
          ...prevState,
          ...foursquareCategoriesValue.filter(categoryItem => filterRepeated({
            categoryItem,
            categoriesReference: prevState,
          })),
        ]);
      }, categoryGroupingSelected);
    }

    setCategoryGroupingsSelected(categoryGroupingSelected);
  };

  React.useEffect(() => {
    if (!R.isEmpty(fields) && !R.isNil(inputStartedFocus)) {
      R.forEach(({ name, setInputFocus }) => {
        if (name === inputStartedFocus.name) setInputFocus();
      }, fields);
    }
  }, [
    fields,
    inputStartedFocus,
    googleCategories,
    selectedGoogleCategories,
    facebookCategories,
    selectedFacebookCategories,
    formatFoursquareCategories,
    selectedFoursquareCategories,
  ]);

  React.useImperativeHandle(ref, () => ({
    fields,
    locationNameByGoogleRef,
    categoryGroupingsSelected,
    selectedGoogleCategories,
    selectedFacebookCategories,
    selectedFoursquareCategories,
    selectedServiceAreaPlaceInfosRef,
  }));

  return (
    <StyledLocationFormUncontrolled>
      <StyledTitleBlock>Informações do local</StyledTitleBlock>
      <StyledFormContainer>
        <StyledForm as="div">
          <StyledSectionTitle>Novo local</StyledSectionTitle>
          <StyledFieldsWrapper storeCode>
            <StoreCode />
          </StyledFieldsWrapper>

          <StyledSeparator />

          <StyledFieldsWrapper threeColumns>
            {editMode
              ? <LocalName />
              : (
                <LocationNameByGoogle
                  ref={locationNameByGoogleRef}
                />
              )}

            <LocalEmail />
            <MainPhone />
            <SecondaryPhone />
          </StyledFieldsWrapper>
          {userProfile.name !== 'Usuário Padrão' && !editMode && (
            <StyledDataStudioWrapper>
              <LocalDataStudioUrl />
            </StyledDataStudioWrapper>
          )}

          <StyledSeparator />

          {platformsCategory && (
            <>
              {userProfile.name !== 'Usuário Padrão' && (
                <>
                  <StyledSectionTitle>Categorias</StyledSectionTitle>
                  <StyledFieldsWrapper threeColumns>
                    <AutocompleteInput
                      label="Agrupamento de Categorias"
                      options={categoryGroupings}
                      saveSelectedOpionsIn={handleSetCategoryGroupingSelected}
                      initialValue={categoryGroupingsSelected}
                      filterSearchKey="name"
                      categoryId="id"
                      saveAllObject
                      disclaimer="Nenhuma categoria encontrada"
                    />

                    <AutocompleteInput
                      label="Google"
                      options={googleCategories}
                      saveSelectedOpionsIn={setSelectedGoogleCategories}
                      initialValue={selectedGoogleCategories}
                      filterSearchKey="name"
                      categoryId="platform_id"
                      saveAllObject
                      disclaimer="Nenhuma categoria encontrada"
                    />

                    <AutocompleteInput
                      label="Facebook"
                      options={facebookCategories}
                      saveSelectedOpionsIn={setSelectedFacebookCategories}
                      initialValue={selectedFacebookCategories}
                      filterSearchKey="name"
                      categoryId="platform_id"
                      saveAllObject
                      disclaimer="Nenhuma categoria encontrada"
                    />

                    <AutocompleteInput
                      label="Foursquare"
                      options={foursquareCategories}
                      saveSelectedOpionsIn={setSelectedFoursquareCategories}
                      initialValue={selectedFoursquareCategories}
                      filterSearchKey="name"
                      categoryId="platform_id"
                      saveAllObject
                      disclaimer="Nenhuma categoria encontrada"
                    />
                  </StyledFieldsWrapper>

                  <StyledSeparator />
                </>
              )}
            </>
          )}

          <StyledSectionTitle>Informações de endereço</StyledSectionTitle>
          <StyledFieldsWrapper threeColumns>
            <ZipCode />
            <CountryCodeSelect countryCode={countryCode} setCountryCode={setCountryCode} />
            <Address />
            <AddressNumber />
            <AddressComplement />
            <Neighborhood />
            <City />
            <StateCode />
          </StyledFieldsWrapper>
          <GoogleServiceAreaPlacesInput
            label="Área de cobertura"
            className="google-service-area-input"
            initialSelectedPlaces={googleServiceArea?.places?.placeInfos}
            onSelectedPlacesChange={onChangeSelectedPlaces}
          />
          <AnswerAtThatAddress />
        </StyledForm>
      </StyledFormContainer>
    </StyledLocationFormUncontrolled>
  );
};

export default React.memo(React.forwardRef(LocationFormUncontrolled));
