import axios from 'axios';
import { TRefreshPageToken } from './types';

const RefreshPageToken = async ({
  accessToken,
  setTokenLikeExpired,
  facebookUserAccessToken,
  facebookPageId,
}: TRefreshPageToken): Promise<string> => {
  try {
    /* Refresh user access token to 2 months */
    const response = await axios.get(
      `${process.env.REACT_APP_FACEBOOK_API_URL}/refresh-user-token`,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          fb_user_access_token: facebookUserAccessToken,
        },
      }
    );

    /* Use the refreshed user's access token to get permanent page access token */
    const { data } = await axios.get(
      `${process.env.REACT_APP_FACEBOOK_API_URL}/page-access-token/${facebookPageId}`,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          fb_user_access_token: response.data.access_token,
        },
      }
    );

    return data.access_token;
  } catch (err: any) {
    if (err.response?.status === 401 && setTokenLikeExpired)
      setTokenLikeExpired();
    return err;
  }
};

export default RefreshPageToken;
