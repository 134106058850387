import styled from 'styled-components';
import { shade } from 'polished';

import { devices } from '../../../../../assets/styles/devices';

export const StyledLocationList = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0;
  width: 100%;
  padding: 2rem;

  /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#e8e8e8+0,ffffff+18 */
  background: rgb(232,232,232); /* Old browsers */
  background: -moz-linear-gradient(top,  rgba(232,232,232,1) 0%, rgba(255,255,255,1) 18%); /* FF3.6-15 */
  background: -webkit-linear-gradient(top,  rgba(232,232,232,1) 0%,rgba(255,255,255,1) 18%); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to bottom,  rgba(232,232,232,1) 0%,rgba(255,255,255,1) 18%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#e8e8e8', endColorstr='#ffffff',GradientType=0 ); /* IE6-9 */  
  
  @media ${devices.desktop} {
    padding-left: 2rem;
  }

  @media ${devices.widescreen} {
    flex: 2;
    border-left: 0.1rem solid ${props => shade(0.1, props.theme.colors.white)};
  }
  
  .google-pagination {
    border-bottom-left-radius: 0.5rem;
    border-bottom-right-radius: 0.5rem;
    margin-top: 0;
    display: flex;
    flex-direction: column;

    @media ${devices.tabletLandscape} {
      flex-direction: row;
    }
  }
  
  .load-locations {
    margin: 1rem auto 2rem;
  }
`;

export const StyledTitle = styled.h3`
  font-size: 1.5rem;
  color: ${props => props.theme.colors.black};
  margin-bottom: 2rem;
  font-weight: bold;
  flex: 1;
  em {
    font-style: normal;
    color: ${props => props.theme.colors.primary}
  }
`;

export const StyledListing = styled.ul`
  display: flex;
  flex-direction: column;
  margin: 0;
`;

export const StyledEmptyLocationList = styled.span`
  font-size: 1.4rem;
  color: ${props => props.theme.colors.red};
`;

export const SwitchWrapper = styled.div`
  .MuiSwitch-root  {
    align-self: center;
  }
  div {
    label {
      margin-bottom: 0.5rem;
    }
  }
`;

export const StyledTitleAndFilterSwitch = styled.div`
  display: flex;
  margin: 0 0 2rem 0;
`;
