import React from 'react';
import * as R from 'ramda';

import { SelectMultiple } from '../../components';

import { StyledContractTag } from './my-locations-styles';
import isNilOrEmpty from '../../utils/is-null-or-empty';

const statusTag = {
  owner: 'Proprietário',
  guest: 'Convidado',
  locationGuest: 'Conv. Local',
};

export const EContractTypeColorPalette = {
  'Ativado | Standard': '#32CD32',
  'Ativado | Phonetrack': '#006400',
  'Ativado | Mobcall': '#3CB371',
  'Trial | Standard': '#00BFFF',
  'Trial | Phonetrack': '#4169E1',
  'Trial | Mobcall': '#1E90FF',
  HubPartner: '#8A2BE2',
  'Traz Clientes': '#FF00FF',
};

export const getColumnsToTable = ({ userProfile, contractsList }) => {
  const customColumns = [
    {
      name: 'Id',
      options: { display: false, sort: false, filter: false },
    },
    { name: 'Nome', options: { filter: false } },
    { name: 'Proprietário', options: { sort: false, filter: false } },
    { name: 'Endereço', options: { sort: false, filter: false } },
    { name: 'Telefone', options: { sort: false, filter: false } },
    {
      name: 'Replicada no Bi',
      options: { display: false, sort: false, filter: false },
    },
    {
      name: 'Location Id',
      options: { display: false, sort: false, filter: false },
    },
    {
      name: 'Acessos',
      options: { display: 'excluded', sort: false, filter: false },
    },
  ];

  const columnStatusToInsert = {
    name: 'Permissão',
    options: {
      customBodyRender: (value) => (
        <span className={`tag ${value}`}>{statusTag[value]}</span>
      ),
      sort: false,
      filter: false,
    },
  };

  const columnContractToInsert = {
    name: 'Contrato',
    options: {
      filter: true,
      filterType: 'custom',
      filterOptions: {
        logic: (location, filters) => {
          if (filters.length) return !filters.includes(location);
          return false;
        },
        display: (filterList, onChange, index, column) => (
          <SelectMultiple
            name="Contratos"
            label="Contratos"
            options={contractsList}
            value={filterList[index]}
            onChange={(test) => {
              filterList[index] = test;
              onChange(filterList[index], index, column);
            }}
          />
        ),
      },
      customBodyRender: (value) => (
        <>
          {R.isNil(value)
            ? <></> : <StyledContractTag style={{ background: EContractTypeColorPalette[value] }} className="contract-tag">{value}</StyledContractTag>}
        </>
      ),
    },
  };

  if (userProfile !== 'Usuário Padrão') {
    return R.insert(2, columnContractToInsert, customColumns);
  }

  return R.insert(2, columnStatusToInsert, customColumns);
};

export const getColumnValues = ({
  location, permissionStatus, userProfileName, userId,
}) => {
  const guestPermissions = userProfileName === 'Usuário Padrão' ? location.guests.filter(guest => guest.user?.id === userId) : null;
  const hasNoValidConnection = R.isEmpty(location.location_connections.filter((connection) => connection.status === 'VALID'));

  const columnsList = [
    location.id,
    location.name,
    location?.responsible?.name,
    location.address1,
    location.phone,
    location.is_replicated_on_BI,
    hasNoValidConnection,
    isNilOrEmpty(guestPermissions) ? null : JSON.parse(guestPermissions[0].location_access_level_profile.rules),
  ];

  const contractNameOrNull = R.isNil(location.contract_type) ? null : location.contract_type.name;

  if (userProfileName !== 'Usuário Padrão') {
    return R.insert(2, contractNameOrNull, columnsList);
  }

  const columnStatusToInsert = permissionStatus;

  return R.insert(2, columnStatusToInsert, columnsList);
};

export const initialTableWidths = {
  '&:nth-child(2)': {
    width: '30%',
  },
};

export const companyLocationsInitialColumnsTable = [
  {
    name: 'Id',
    options: { display: false },
  },
  {
    name: 'Nome',
  },
  { name: 'Endereço', options: { sort: false } },
  { name: 'Telefone', options: { sort: false } },
  { name: 'Replicada no Bi', options: { display: false } },
  { name: 'Location Id', options: { display: false } },
  { name: 'Permissões', options: { display: false } },
];

export const mountDataTableFormt = ({
  locationDataList,
  permissionStatus = null,
  userProfileName,
  userId,
}) => {
  if (R.isNil(permissionStatus)) {
    return locationDataList.map(location => getColumnValues({ location, userProfileName }));
  }

  return locationDataList.map(location => getColumnValues({
    location, permissionStatus, userProfileName, userId,
  }));
};
