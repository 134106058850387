import axios, { AxiosRequestConfig } from 'axios';
import type { TServiceItem } from '../../../types/TLocationService';

export type TGetServiceItems = {
  googleAccessToken: string;
  googleLocationId: string;
};

export default async function getServiceItems({
  googleAccessToken,
  googleLocationId,
}: TGetServiceItems): Promise<TServiceItem[] | null> {
  try {
    const requestOptions: AxiosRequestConfig = {
      headers: {
        'access-token': googleAccessToken,
      },
    };

    const { data } = await axios.get(
      `${process.env.REACT_APP_GOOGLE_BUSINESS_PROFILE_API_URL}/location/${googleLocationId}/serviceItems`,
      requestOptions,
    );
    return data.serviceItems;
  } catch (err: any) {
    return null;
  }
}
