import styled, { css } from 'styled-components';
import { lighten, shade } from 'polished';
import { isNil } from 'ramda';

import { TDropDownDialog } from '../../../../types/TDropDownDialog';

export const StyledOption = styled.li<
  Pick<TDropDownDialog, 'isGuest'> & {
  disableOption: boolean,
  currentSelected: string | null,
  optionName: string | null,
}
  >`
  cursor: pointer;
  color: ${props => props.theme.colors.primary};
  font-weight: 600;
  font-size: 1.4rem;
  padding: 1rem 2rem;
  min-height: auto;
  height: auto;
  display: flex;
  align-items: center;
  background: transparent;    
  transition: 0.3s;
  border-bottom: 1.5px solid ${props => lighten(0.3, props.theme.colors.grey)};
  max-width: 40rem;
  border-left: 0.5rem solid transparent;

  ${props => props.isGuest && css`
      height: 10rem;
      justify-content: center;
      justify-content: flex-start;
      flex-direction: column;
  `}

  ${props => props.disableOption && css`
      pointer-events: none;
      cursor: not-allowed;
      opacity: 0.3;

      &:hover {
          border-left: 0;
      }
  `}

  ${props => (!isNil(props.currentSelected)) && (props.currentSelected === props.optionName) && css`
      background: ${shade(0.05, props.theme.colors.white)};
      border-left: 0.5rem solid ${shade(0.1, props.theme.colors.primary)};
  `}

  &:last-child {
    border-bottom: 0;
  }

  &:hover {
      border-left: 0.5rem solid ${props => shade(0.1, props.theme.colors.primary)};
  }

  .option-text {
      
  }
`;

export const StyledLocationQuantity = styled.span<{
  isFetching: boolean;
  hasLocation: boolean;
}>`
  display: flex;
  align-items: center;
  padding: 0.5rem 1rem;
  margin-left: 1rem;
  background: ${props => (props.isFetching ? 'transparent' : props.theme.colors.secondary)};
  color: ${props => props.theme.colors.white};
  font-weight: 600;
  font-size: 1.2rem;
  border-radius: 0.5rem;

  ${props => (!props.hasLocation && !props.isFetching) && css`
      background: ${props.theme.colors.red};
  `}
`;

export const StyledGuestInfos = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const StyledGuestInfosText = styled.span`
  font-size: 1rem;
  color: ${props => props.theme.colors.black};
  font-size: 1.2rem;
  font-weight: normal;
`;
