import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import * as R from 'ramda';

import hublocalLogoBranca from '../../assets/images/logo-hub-branco.png';

import Nav from './nav';

import {
  StyledSideMenu,
  StyledSideMenuLogo,
  StyledSideMenuLogoLabel,
  StyledSideMenuLogoImg,
  StyledUserAvatar,
  StyledUserAvatarLetter,
  StyledUserAvatarLabel,
  StyledSideMenuLogoLabel2,
  StyledItemsGroup,
} from './side-menu-styles';

const SideMenu = () => {
  const { user } = useSelector(state => state.AuthReducer);
  const { user: { profiles } } = user;
  const { menu: { functions, groups } } = profiles[0];

  const userName = user.user.name;
  const userLetter = user.user.name[0];

  const [isOpenSideMenu, setIsOpenSideMenu] = React.useState(false);
  const [openAccordionMenu, setOpenAccordionMenu] = React.useState(0);

  return (
    <StyledSideMenu
      isOpenSideMenu={isOpenSideMenu}
      onMouseEnter={() => setIsOpenSideMenu(true)}
      onMouseLeave={() => setIsOpenSideMenu(false)}
    >
      <StyledSideMenuLogo
        to="/my-companies"
        onClick={() => setOpenAccordionMenu(0)}
        className="nav-item-logo"
      >
        <StyledSideMenuLogoImg src={hublocalLogoBranca} alt="Saas Hublocal" isOpenSideMenu={isOpenSideMenu} />
        <StyledSideMenuLogoLabel className="nav-item-label">
          Hub
          <StyledSideMenuLogoLabel2>Local</StyledSideMenuLogoLabel2>
        </StyledSideMenuLogoLabel>
      </StyledSideMenuLogo>

      <StyledItemsGroup>
        {!R.isEmpty(groups) && (
          <Nav
            items={groups}
            setIsOpenSideMenu={setIsOpenSideMenu}
            openAccordionMenu={openAccordionMenu}
            setOpenAccordionMenu={setOpenAccordionMenu}
            hasSubMenu
          />
        )}
        {!R.isEmpty(functions) && (
          <Nav
            items={functions}
            setIsOpenSideMenu={setIsOpenSideMenu}
            openAccordionMenu={openAccordionMenu}
            setOpenAccordionMenu={setOpenAccordionMenu}
          />
        )}

      </StyledItemsGroup>

      <StyledUserAvatar
        to="/user-profile"
        onClick={() => setOpenAccordionMenu(0)}
      >
        <StyledUserAvatarLetter className="letter">
          {userLetter}
        </StyledUserAvatarLetter>
        <StyledUserAvatarLabel className="nav-item-label">
          {userName}
        </StyledUserAvatarLabel>
      </StyledUserAvatar>
    </StyledSideMenu>
  );
};

export default SideMenu;
