import axios from 'axios';

export default async function getInvite({
  id,
  setTokenLikeExpired,
  isFetchinging,
}) {
  try {
    if (isFetchinging) isFetchinging(true);

    const { data } = await axios.get(
      `${process.env.REACT_APP_INVITES_API_URL}/${id}`,
    );

    return data;
  } catch (err) {
    if (err.response?.status === 401 && setTokenLikeExpired) return setTokenLikeExpired();

    return null;
  } finally {
    if (isFetchinging) isFetchinging(false);
  }
}
