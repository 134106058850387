import styled, { css } from 'styled-components';
import { Dialog } from '@material-ui/core';
import { GrClose } from 'react-icons/gr';

import { devices } from '../../../assets/styles/devices';

export const StyledServiceBatchForm = styled(Dialog)`
  .MuiDialog-paper {
    max-width: 90%;
    max-height: 90%;
    background: #e5e5e5;

    @media ${devices.tabletLandscape} {
      max-width: 70%;
    }

    @media ${devices.desktop} {
      max-width: 60%;
    }

    border-radius: 12px 12px 0 0;
  }
`;

export const StyledDialogHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;

  background: #f2f2f2;
  border-bottom: 1px solid #000000;
`;

export const StyledCloseIcon = styled(GrClose)`
  cursor: pointer;
`;

export const StyledDialogBody = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 2rem;

  @media ${devices.tablet} {
    grid-template-columns: 1fr 1fr;
  }

  padding: 1rem;
`;

export const StyledDialogForm = styled.form`
  width: 100%;
  height: max-content;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: 1rem;

  background: #f2f2f2;

  .loading-icon {
    margin-right: 0.5rem;
  }

  .form-input {
    margin-bottom: 2rem;
  }

  .save-button {
    margin-top: 1rem;
  }
`;

export const StyledInputWithCounterWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  margin-bottom: 0.5rem;

  .service-description {
    textarea {
      min-height: 3rem;
      resize: vertical;
    }
  }
`;

export const StyledTextCounter = styled.span<{ count: boolean }>`
  font-size: 1.2rem;
  line-height: 1.2rem;
  margin-top: 0.3rem;

  ${(props) =>
    props.count &&
    css`
      color: ${props.theme.colors.red};
    `}
`;

export const StyledDialogSubmitButton = styled.button`
  color: #fff;
  background: #0385fd;
  padding: 1rem;
  border: 0;
  border-radius: 0.5rem;

  ::after {
    content: 'Publicar';
  }
`;

export const LoadingContainer = styled.div`
  width: 100%;
  height: 100%;

  display: flex;
  justify-content: center;
  align-items: center;
`;
