import axios from 'axios';
import type { TGetLocations } from '../../types/TLocation';

export default async function getLocations({
  accessToken,
  page = 0,
  pageSize = 10,
  query = '',
  setIsFetching,
  setTokenLikeExpired,
}: TGetLocations) {
  try {
    if (setIsFetching) setIsFetching(true);

    const requestOptions = {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    };

    const { data } = await axios.get(
      `${process.env.REACT_APP_LOCATIONS_API_URL}?page=${page}&pageSize=${pageSize}&query=${query}`,
      requestOptions,
    );

    return data;
  } catch (err: any) {
    if (err.response.status === 401 && setTokenLikeExpired) setTokenLikeExpired();
    return null;
  } finally {
    if (setIsFetching) setIsFetching(false);
  }
}
