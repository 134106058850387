import axios from 'axios';

export default async function getAllLocations({
  accessToken,
  page = 0,
  pageSize = 10,
  query = '',
  companyId = 0,
  orderByName = 'ASC',
  setTokenLikeExpired,
}) {
  const requestOptions = {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  };

  try {
    const { data } = await axios.get(
      `${process.env.REACT_APP_LOCATIONS_API_URL}/all-locations?page=${page}&pageSize=${pageSize}&query=${query}&companyId=${companyId}&orderByName=${orderByName}`,
      requestOptions,
    );

    return data;
  } catch (err) {
    if (err.response?.status === 401 && setTokenLikeExpired) setTokenLikeExpired();

    return null;
  }
}
