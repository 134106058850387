import React from 'react';
import { Dialog } from '../../../../../../../components';
import ConfirmLinkWarning from './confirm-link-warning';

const ConfirmLinkDialog = ({
  isDialogOpen, onClose, onConfirm,
}) => (
  <Dialog
    onClose={onClose}
    open={isDialogOpen}
    title="Atenção:"
    wideDialog
    maxWidth="md"
  >
    <ConfirmLinkWarning onClose={onClose} onConfirm={onConfirm} />
  </Dialog>
);

export default ConfirmLinkDialog;
