import * as R from 'ramda';

export const filters = [
  {
    id: Math.random(Math.floor()),
    name: 'Aberto',
    quantity: 2,
  },
  {
    id: Math.random(Math.floor()),
    name: 'Em progresso',
    quantity: 10,
  },
  {
    id: Math.random(Math.floor()),
    name: 'Feito',
    quantity: 10,
  },

];

export const ticketStatus = [
  { name: 'Aberto', value: 'OPEN' },
  { name: 'Em progresso', value: 'IN_PROGRESS' },
  { name: 'Concluído', value: 'DONE' },
];

export const platformStatus = [
  { name: 'Em progresso', value: 'IN_PROGRESS' },
  { name: 'Sem acesso', value: 'NO_ACCESS' },
  { name: 'Concluído', value: 'DONE' },
  { name: 'Sincronização Automatizada', value: 'AUTO_SYNC' },
];

export const ticketStatusBoxData = {
  DONE: {
    status: 'done',
    letter: 'E',
    color: 'grey',
    translateTitle: 'Encerrado',
  },
  IN_PROGRESS: {
    status: 'in_progress',
    letter: 'P',
    color: 'primary',
    translateTitle: 'Em progresso',
  },
  OPEN: {
    status: 'open',
    letter: 'A',
    color: 'green',
    translateTitle: 'Aberto',
  },
};

export const selectStatus = [
  { name: 'Aberto' },
  { name: 'Em progresso' },
  { name: 'Feito' },
];

export const mockedTickets = [
  {
    id: 12,
    type: 'Ativação',
    status: 'DONE',
    name: '12 - Ativação - Drogasil Aldeota 1',
    created_at: '16/02/2021',
    responsible: 'Naja Oliveira',
  },
  {
    id: 13,
    type: 'Manutenção',
    status: 'IN_PROGRESS',
    name: '13 - Manutenção -Pague Menos',
    created_at: '17/02/2021',
    responsible: 'Talitona',
  },
  {
    id: 14,
    type: 'Ativação',
    status: 'OPEN',
    name: '13 - Manutenção -Pague Menos',
    created_at: '17/02/2021',
    responsible: 'Talitona',
  },
  {
    id: 15,
    type: 'Ativação',
    status: 'OPEN',
    name: '13 - Manutenção -Pague Menos',
    created_at: '17/02/2021',
    responsible: 'Talitona',
  },
  {
    id: 16,
    type: 'Ativação',
    status: 'OPEN',
    name: '13 - Manutenção -Pague Menos',
    created_at: '17/02/2021',
    responsible: 'Talitona',
  },
  {
    id: 17,
    type: 'Ativação',
    status: 'OPEN',
    name: '13 - Manutenção -Pague Menos',
    created_at: '17/02/2021',
    responsible: 'Talitona',
  },
  {
    id: 18,
    type: 'Ativação',
    status: 'DONE',
    name: '12 - Ativação - Drogasil Aldeota 1',
    created_at: '16/02/2021',
    responsible: 'Naja Oliveira',
  },
];

export const getFilterStatus = {
  open: 'OPEN',
  in_progress: 'IN_PROGRESS',
  done: 'DONE',
};

export const hasOperators = () => operators => (!R.isEmpty(operators) || !R.isNil(operators)) && R.is(Array, operators);
