import React from 'react';
import * as R from 'ramda';

import Ticket from './ticket';
import { Loading, Pagination } from '../../../components';

import { StyledTicketsList, StyledTicketListContainer } from './tickets-list-styles';

const TicketsList = ({
  dataTickets,
  handleSelectTicket,
  isLoading,
  page,
  setPage,
  pagesQuantity,
}) => (isLoading ? <Loading className="is-loading" /> : (
  <StyledTicketListContainer>
    {!R.isEmpty(dataTickets)
      && <Pagination page={page} setPage={setPage} pagesQuantity={pagesQuantity} />}

    <StyledTicketsList>
      {dataTickets ? dataTickets.map(ticket => (

        <Ticket
          onClick={() => handleSelectTicket(ticket.id)}
          key={ticket.id}
          ticket={ticket}
          date_created={ticket.date_created}
        />
      )) : <li>Nenhum ticket criado!</li>}
    </StyledTicketsList>
  </StyledTicketListContainer>
));

export default TicketsList;
