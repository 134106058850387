export const responseTextScheme = {
  name: 'automatic-response-text',
  nickname: 'Resposta Automática',
  label: 'Resposta Automática',
  textarea: {
    rows: 6,
  },
  hasborder: 'yes',
  hasradius: 'yes',
  longDescription: 750,
};
