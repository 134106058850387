import styled from 'styled-components';
import { lighten } from 'polished';
import { Dialog } from '@material-ui/core';

export const StyledGoogle = styled.div`
  display: flex;
  flex-direction: column;

  .button {
    margin-bottom: 1.5rem; 
  }

  .disclaimer-sync {
    font-size: 1.3rem;
    margin-top: 1rem;
    margin-bottom: 1rem;

    small {
      font-size: 1.3rem;
      color: ${props => props.theme.colors.red};
    }
  }

  .pending-verification{
    font-size: 1.1rem;
    color: ${props => props.theme.colors.yellow};
  }

  .awaiting-review{
    font-size: 1.1rem;
    color: ${props => props.theme.colors.yellow};
  }

  .no-ownership{
    font-size: 1.1rem;
    color: ${props => props.theme.colors.yellow};
  }

  .not-verified{
    font-size: 1.1rem;
    color: ${props => props.theme.colors.red};
  }

  .verified{
    font-size: 1.1rem;
    color: ${props => props.theme.colors.green};
  }
`;

export const StyledDialog = styled(Dialog)`

  span{
    font-family: Poppins;
  }

  .loading{
    margin-left: 1rem;
  }

  .dialog-title{
    font-size: 2rem;
    font-family: Poppins;
  }

  .dialog-sub-title{
    font-size: 1.5rem;
    margin: 1rem;
  }

  .location-name{
    font-size: 1.8rem;
  }

  .location-secondary-text{
    display: flex;
    flex-direction: column;
  }

  .location-address{
    font-size: 1.5rem;
  }

  .pending-verification{
    font-size: 1.7rem;
    color: ${props => props.theme.colors.yellow};
  }

  .awaiting-review{
    font-size: 1.7rem;
    color: ${props => props.theme.colors.yellow};
  }

  .not-verified{
    font-size: 1.7rem;
    color: ${props => props.theme.colors.red};
  }

  .verified{
    font-size: 1.7rem;
    color: ${props => props.theme.colors.green};
  }

  .avatar{
    background: ${(props) => props.theme.colors.primary};
  }

  .select-icon-button{
    color: ${props => props.theme.colors.primary};
  }

  .icon{
    font-size: 3rem;
  }

  .actions{
    display: grid;
    grid-auto-flow: column;
    grid-gap: 1rem;
    margin: 2rem;
  }
`;

export const StyledButtonsGroup = styled.div`
  display: flex;
  flex-direction: column;

  .button {
    margin-bottom: 1.5rem;

    &:last-of-type {
      margin-bottom: 0;
    }
  }
`;

export const StyledProfile = styled.div`
  background: #fff;
  display: flex;
  flex-direction: column;
  margin-bottom: 2rem;
  padding: 1rem;

  span{
    font-size: 80%;
  }
  
  a{
    font-size: 80%;
  }
  `;

export const StyledDisclaimer = styled.div`
  margin-bottom: 1.5rem;
  line-height: 1.5rem;
  font-size: 1.2rem;
  color: ${props => props.theme.colors.red};
`;

export const StyledSyncDisclaimer = styled(StyledDisclaimer)`
  color: ${props => props.theme.colors.black};
  margin-bottom: 0;
  margin-top: 1rem;
`;

export const StyledProfileAccount = styled.div`
  display: flex;
  margin-bottom: 1.5rem;

  .profile-avatar {
    margin-right: 1rem;
  }

  .profile-infos {
    display: flex;
    flex-direction: column;
  }

  .place-infos {
    display: flex;
    flex-direction: column;
  }

  .profile-name {
    font-size: 1.2rem;
    font-weight: 600;
  }

  .profile-account {
    font-size: 1.1rem;
  }

  .profile-place-id{
    font-size: 0.8rem;
  }
`;
