import React from 'react';
import * as R from 'ramda';

import {
  StyledSpecialDatesInfo,
  StyledSpecialDatesInfos,
  StyledSpecialDatesInfosContainer,
  StyledSpecialDatesInfoLine,
  StyledSpecialDatesInfoTimes,
} from './special-dates-info-styles';

const SpecialDatesInfos = ({ data = [] }) => {
  const [specialDatesInfo, setSpecialDatesInfo] = React.useState([]);

  React.useEffect(() => {
    if (R.isNil(data) || data === 'MUST_BE_EMPTY') return;

    const specialDatesMerged = R.mergeAll(data.map(specialDate => ({ [specialDate.id]: specialDate })));
    const specialDatesaNormalized = Object.values(specialDatesMerged);

    setSpecialDatesInfo(specialDatesaNormalized);
  }, [data]);

  return (
    <StyledSpecialDatesInfosContainer>
      {(!R.isEmpty(specialDatesInfo) && !R.isNil(specialDatesInfo)) && (
      <StyledSpecialDatesInfos>
        {specialDatesInfo.map(specialDateInfo => (
          <StyledSpecialDatesInfo>
            <StyledSpecialDatesInfoLine>
              <b>Título:</b>
              <small>
                {specialDateInfo.name}
              </small>
            </StyledSpecialDatesInfoLine>

            <StyledSpecialDatesInfoLine>
              <b>Data:</b>
              <small>
                {specialDateInfo.date}
              </small>
            </StyledSpecialDatesInfoLine>

            <StyledSpecialDatesInfoLine>
              <b>Aberto:</b>
              <small>
                {specialDateInfo.is_open ? 'Sim' : 'Não'}
              </small>
            </StyledSpecialDatesInfoLine>

            {specialDateInfo.is_24_hours && (
              <StyledSpecialDatesInfoLine>
                <b>24 horas:</b>
                <small>
                  {specialDateInfo.is_24_hours ? 'Sim' : 'Não'}
                </small>
              </StyledSpecialDatesInfoLine>
            )}

            {(!R.isEmpty(specialDateInfo.times)
            && !R.isNil(specialDateInfo.times)
            && !specialDateInfo.is_24_hours
            && specialDateInfo.is_open) && (
              <StyledSpecialDatesInfoTimes>
                {specialDateInfo.times.map(time => (
                  <li>
                    <b>
                      Abre:
                      {' '}
                      <small>{time.open}</small>
                    </b>
                    <span>-</span>
                    <b>
                      Fecha:
                      {' '}
                      <small>{time.close}</small>
                    </b>
                  </li>
                ))}
              </StyledSpecialDatesInfoTimes>
            )}
          </StyledSpecialDatesInfo>
        ))}
      </StyledSpecialDatesInfos>
      )}
      {R.isEmpty(specialDatesInfo) && (
        <p>Informação removida</p>
      )}
    </StyledSpecialDatesInfosContainer>
  );
};

export default SpecialDatesInfos;
