import axios from 'axios';

export default async function replyToReview({
  accessToken,
  userId,
  locationId,
  reviewId,
  reply,
  setIsReply,
}) {
  const requestOptions = {
    headers: {
      'access-token': accessToken,
    },
  };

  if (setIsReply) setIsReply(true);

  try {
    const { data } = await axios.put(
      `${process.env.REACT_APP_GOOGLE_API_URL}/reply-to-review/${userId}/${locationId}/${reviewId}`,
      { reply },
      requestOptions,
    );

    return data;
  } catch (err) {
    return null;
  } finally {
    if (setIsReply) setIsReply(false);
  }
}
