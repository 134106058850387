import styled from 'styled-components';

export const StyledNoLinkWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 2rem;
`;

export const StyledLocationConnectionListContainer = styled.div`
  padding: 2rem;
`;
