/* eslint-disable react/require-default-props */
import React from 'react';
import {
  StyleColorProps, StyledContainer, StyledCount, StyledTitle,
} from './accountant-reviews-styles';

type AccountantReviewsProps = {
  count: number;
  title: string;
  color?: StyleColorProps;
}

const AccountantReviews = ({ count, title, color = 'PRIMARY' }: AccountantReviewsProps) => (
  <StyledContainer color={color}>
    <StyledCount color={color}>{count}</StyledCount>
    <StyledTitle color={color}>{title}</StyledTitle>
  </StyledContainer>
);

export default AccountantReviews;
