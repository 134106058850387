export const fieldPersonalInfoName = {
  name: 'name',
  nickname: 'Nome',
  type: 'text',
  label: '',
  description: '',
  hasborder: 'yes',
  required: true,
  disabled: true,
};

export const fieldPersonalInfoEmail = {
  name: 'email',
  nickname: 'Email',
  type: 'text',
  label: '',
  description: '',
  hasborder: 'yes',
  required: true,
  disabled: true,
};

export const fieldPersonalInfoPhone = {
  name: 'phone',
  nickname: 'Telefone',
  type: 'text',
  label: '',
  description: '',
  hasborder: 'yes',
  disabled: true,
};
