import styled, { css } from 'styled-components';

export const StyledMyLocations = styled.div`
  width: 100%;
  
  ${props => props.isEmptyCompanies === true && css`
      height: 100vh;
  `};

  .header-toolbar {
    padding-right: 2rem;
  }
  
  .is-loading-locations {
    margin: 2rem auto;
  }
`;

export const StyledContent = styled.div`
  width: 100%;
  padding: 3rem 2rem 2rem;
`;

export const StyledPageTitle = styled.h2`
  font-size: 2rem;
  font-weight: 700;
  padding: 3rem 2rem;      
  width: 100%;
`;

export const StyledEmptyTableContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  h4 {
    font-weight: 400;
    font-family: Poppins;
  }
`;

export const StyledContractTag = styled.span`
  color: ${props => props.theme.colors.white};
  border-radius: 5rem;
  padding: 0.5rem 2rem;
  text-transform: capitalize;
  font-size: 1.2rem;
  white-space: nowrap;
`;
