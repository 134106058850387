import * as R from 'ramda';

export const getAddressNumber = address => {
  const formattedNumber = String(address).trim().split(/\s/g);

  return formattedNumber[formattedNumber.length - 1];
};

export const getAddressName = address => {
  const formattedAddress = address.replace(/[,|]/gi, '').split(/\s/g);

  let completeAddress = '';

  formattedAddress.map((e, index) => {
    if (index !== formattedAddress.length - 1) { completeAddress += ` ${e}`; }
  });

  return completeAddress.trim();
};

export const parseToJSON = stringData => JSON.parse(stringData);

export const filterRepeated = ({ categoryItem, categoriesReference }) => {
  if (!Array.isArray(categoriesReference)) return false;

  const foundCategory = categoriesReference.find(
    (categoryReference) => categoryReference.id === categoryItem.id,
  );

  if (!R.isNil(foundCategory)) return false;

  return true;
};
