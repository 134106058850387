import React from 'react';
import { useSnackbar } from 'notistack';
import * as R from 'ramda';

import {
  getByUser,
  getOne,
  delete as deleteLocationAccessLevelProfile,
} from '../../../services/locationAccessLevelProfiles';

import { StyledProfileListing } from './profile-listing-style';

import { DataTable } from '../../../components';

const ProfileListing = ({
  userToken,
  userId,
  setDataToEdit,
  updateTable,
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const [page, setPage] = React.useState(0);
  const [pageSize, setPageSize] = React.useState(10);
  const [query, setQuery] = React.useState('');
  const [dataCount, setDataCount] = React.useState(10);
  const [dataToTable, setDataToTable] = React.useState([]);

  const [isFetching] = React.useState(false);
  const [isFecthingDelete] = React.useState(false);

  const loadUserLocationAccessLevelProfiles = React.useCallback(async () => {
    const accessLevelProfilesResponse = await getByUser({
      accessToken: userToken,
      userId,
      query,
      page,
      pageSize,
    });

    if (R.isNil(accessLevelProfilesResponse)) return;

    const [
      accessLevelProfilesData,
      accessLevelProfilesDataCount,
    ] = accessLevelProfilesResponse;

    setDataToTable(accessLevelProfilesData);
    setDataCount(accessLevelProfilesDataCount);
  }, [
    userId,
    userToken,
    query,
    page,
    pageSize,
    setDataToTable,
    updateTable,
  ]);

  const handleDeleteProfile = async (profileId) => {
    const response = await deleteLocationAccessLevelProfile(
      {
        accessToken: userToken,
        id: profileId,
      },
    );
    if (response?.status === 200) {
      enqueueSnackbar('Perfil deletado com sucesso', { variant: 'success' });
      loadUserLocationAccessLevelProfiles();
    }
  };

  const handleUpdateProfile = async (profileId) => {
    const response = await getOne({ accessToken: userToken, id: profileId });
    if (response) {
      setDataToEdit(response);
    }
  };

  React.useEffect(() => {
    loadUserLocationAccessLevelProfiles();
  }, [loadUserLocationAccessLevelProfiles]);

  return (
    <StyledProfileListing>
      <h3>Perfis de acesso do usuário:</h3>
      <DataTable
        className="data-table-groups"
        count={dataCount}
        page={page}
        setPage={setPage}
        pageSize={pageSize}
        setPageSize={setPageSize}
        searchQuery={query}
        setSearchQuery={setQuery}
        columns={[
          { name: 'id', options: { display: false, sort: false } },
          { name: 'name', label: 'Nome', options: { sort: false } },
          { name: 'description', label: 'Descrição', options: { sort: false } },
        ]}
        data={dataToTable}
        loading={isFetching || isFecthingDelete}
        onDeleteClicked={handleDeleteProfile}
        onUpdateClicked={handleUpdateProfile}
      />
    </StyledProfileListing>
  );
};

export default ProfileListing;
