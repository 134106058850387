import React from 'react';

import * as PlatformsIcons from '../../platforms-icons';

import {
  StyledSubPlatform,
} from './subplatform-styles';

const SubPlatform = ({ title }) => {
  const titleWihtoutSpaces = title.replace(/\s/g, '');

  const isFirstCharacterNumber = (text) => !isNaN(parseInt(text.charAt(0)));

  const iconName = `${isFirstCharacterNumber(titleWihtoutSpaces) ? '_' : ''}${titleWihtoutSpaces}`;

  const SubPlatformIcon = PlatformsIcons[iconName] || null;

  return (
    <StyledSubPlatform>
      {SubPlatformIcon && <SubPlatformIcon className="subplatform-icon" />}
      {title}
    </StyledSubPlatform>
  );
};

export default SubPlatform;
