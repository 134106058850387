import axios from 'axios';

export default async function getLocationAccessLevelProfile({ accessToken, id }) {
  const requestOptions = {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  };

  const { data } = await axios.get(
    `${process.env.REACT_APP_LOCATION_ACCESS_LEVEL_PROFILE_API_URL}/${id}`,
    requestOptions,
  );

  return data;
}
