import React from 'react';

import type {
  EBatchPlatformGroupType,
  EBatchPlatformStatus,
  TAffectedPlatforms,
} from './TBatchGoogleModules';
import type { TUserData } from './TBatchUpdate';
import { TGetLocationsWithoutLinkedResponse } from './TLocation';

export type TBatchGalleryDialogFormProps = {
  open: boolean;
  closeModal: (refreshData?: boolean) => void;
};

export type TBatchAddMidiaDialogFormProps = {
  open: boolean;
  closeModal: (refreshData?: boolean) => void;
  locationsWithoutLinkedList: TGetLocationsWithoutLinkedResponse;
};

export enum EBatchGalleryCategories {
  CATEGORY_UNSPECIFIED = 'CATEGORY_UNSPECIFIED',
  COVER = 'COVER',
  LOGO = 'LOGO',
}

export type TBatchGalleryCategoriesLabel =
  | 'Geral'
  | 'Capa'
  | 'Logo';

export type TMediaFormat =
  | 'PHOTO'
  | 'VIDEO'

export enum EBatchMediaStatus {
  PUBLISHED = 'PUBLISHED',
  REMOVED = 'REMOVED',
  REMOVING = 'REMOVING',
  ERROR = 'ERROR',
}

export enum ELocationMediaStatus {
  PUBLISHED = 'PUBLISHED',
  REMOVED = 'REMOVED',
  ERROR = 'ERROR',
}

export type TBatchGalleryMediaData = {
  id: string;
  mediaFormat: TMediaFormat;
  sourceUrl: string;
  category: EBatchGalleryCategories;
  status: EBatchMediaStatus;
};

export type TBatchGalleryLocationMediaData = {
  id: string;
  mediaFormat: TMediaFormat;
  name: string;
  googleUrl: string;
  thumbnailUrl: string;
  status: ELocationMediaStatus;
};

export type TFindBatchGalleries = {
  userAccessToken: string;
  groupType: EBatchPlatformGroupType;
  groupTypeId: number;
  isFetching?: React.Dispatch<React.SetStateAction<boolean>>;
  pageSize?: number;
  page?: number;
  status?: EBatchPlatformStatus;
  mediaType?: string;
  startDate?: Date;
  endDate?: Date;
  category: string;
};

export type TFindBatchGalleryLocations = {
  userAccessToken: string;
  batchGalleryId: string;
  pageSize?: number;
  page?: number;
  query?: string;
  status?: EBatchPlatformStatus;
  isFetching?: React.Dispatch<React.SetStateAction<boolean>>;
  affectedPlatforms?: string;
  affectedPlatformsChecked?: boolean;
};

export type TBatchGalleryLocation = {
  id: string;
  created_at: Date;
  updated_at: Date;
  deleted_at: Date;
  name: string;
  status: EBatchPlatformStatus;
  location_id: number;
  location_address: string | null;
  google_location_name: string;
  location_media_data: TBatchGalleryLocationMediaData[] | null;
  galleryBatch: TBatchGallery;
  affected_platforms: TAffectedPlatforms[];
};

export type TBatchGallery = {
  id: string;
  created_at: Date;
  updated_at: Date;
  deleted_at: Date;
  group_type: EBatchPlatformGroupType;
  group_type_id: number;
  status: EBatchPlatformStatus;
  user_refresh_token: string;
  media_data: TBatchGalleryMediaData[];
  user_data: TUserData | null;
  locations?: TBatchGalleryLocation[];
};

export type TForceUpdateBatchGalleryLocations = {
  userAccessToken: string;
  batchGalleryLocationsId: string;
  isUpdating?: React.Dispatch<React.SetStateAction<boolean>>;
};

export type TFindOneBatchGallery = {
  batchGalleryId: string;
  isFetching?: React.Dispatch<React.SetStateAction<boolean>>;
  userAccessToken: string;
};

export type TUpdateBatchGallery = {
  userAccessToken: string;
  batchGalleryId: string;
  status: EBatchPlatformStatus;
  isUpdating?: React.Dispatch<React.SetStateAction<boolean>>;
};

export type TUpdateBatchGalleryLocation = {
  userAccessToken: string;
  batchGalleryLocationId: string;
  status?: EBatchPlatformStatus;
  isUpdating?: React.Dispatch<React.SetStateAction<boolean>>;
  affectedTicketPlatforms?: TAffectedPlatforms;
};

export type TMediaItem = {
  item: TBatchGalleryMediaData;
  selected: string[];
  setSelected: React.Dispatch<React.SetStateAction<string[]>>;
  creationDate: string;
  lockedCheckboxes: boolean;
  batchStatus: EBatchPlatformStatus;
};

export type TLocationMediaItem = {
  item: TBatchGalleryLocationMediaData;
  selected: string[];
  setSelected: React.Dispatch<React.SetStateAction<string[]>>;
  creationDate: string;
  lockedCheckboxes: boolean;
  batchLocationStatus: EBatchPlatformStatus;
};

export type TDeleteBatchMedia = {
  userAccessToken: string;
  galleryBatchId: string;
  mediaIds: string[];
  isUpdating?: React.Dispatch<React.SetStateAction<boolean>>;
};

export type TDeleteBatchLocationMedia = {
  userAccessToken: string;
  galleryBatchLocationId: string;
  mediaIds: string[];
  isUpdating?: React.Dispatch<React.SetStateAction<boolean>>;
};
