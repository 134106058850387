import React from 'react';

import SpecialDateRow from '../special-date-row';

import {
  TCurrentDatesBaseProps,
} from '../../../../../types/TWorkingHours';

import {
  StyledCurrentDates,
  StyledSpecialDatesRowListing,
} from './current-dates-styles';

const CurrentDates = ({
  data = [],
  activeLocation,
  setSpecialDateToEdit,
  setSpecialDates,
}: TCurrentDatesBaseProps) => {
  const handleDeleteSpecialDate = (specialDateId: number) => {
    const newSpecialDates = data.filter((specialDate) => specialDate.id !== specialDateId);

    if (setSpecialDates) setSpecialDates(newSpecialDates);
  };
  return (
    <StyledCurrentDates>
      <StyledSpecialDatesRowListing>
        {data.map(specialDateRow => (
          <SpecialDateRow
            id={specialDateRow.id}
            key={specialDateRow.id}
            date={specialDateRow.date}
            isOpen={specialDateRow.is_open}
            name={specialDateRow.name}
            is24Hours={specialDateRow.is_24_hours}
            times={specialDateRow.times}
            activeLocation={activeLocation}
            specialDateRow={specialDateRow}
            setSpecialDateToEdit={setSpecialDateToEdit}
            handleDeleteSpecialDate={handleDeleteSpecialDate}
          />
        ))}
      </StyledSpecialDatesRowListing>
    </StyledCurrentDates>
  );
};

export default CurrentDates;
