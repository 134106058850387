import axios from 'axios';

import { TGetAllContractTypes } from '../../types/TContractTypes';

export default async function getAllContractTypes({
  accessToken,
  setIsFetching,
  setTokenLikeExpired,
}: TGetAllContractTypes) {
  try {
    if (setIsFetching) setIsFetching(true);

    const requestOptions = {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    };

    const { data } = await axios.get(
      `${process.env.REACT_APP_CONTRACT_API_URL}/`,
      requestOptions,
    );

    return data;
  } catch (err: any) {
    if (err.response?.status === 401 && setTokenLikeExpired) setTokenLikeExpired();

    return null;
  } finally {
    if (setIsFetching) setIsFetching(false);
  }
}
