import styled from 'styled-components';

import { Dialog } from '@material-ui/core';
import { shade } from 'polished';

import { devices } from '../../assets/styles/devices';

export const StyledDialog = styled(Dialog)`
  .MuiDialog-paper {
    min-width: 28rem;
    width: 100%;
    overflow-y: hidden;
  }

  .MuiDialog-paperWidthSm {
    max-width: 90rem;

    .pagination-log {
      background-color: transparent;
    }
  }
`;

export const StyledHeader = styled.header`
  padding: 1rem 2rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 0.1rem solid ${props => shade(0.2, props.theme.colors.white)};
  background: ${props => shade(0.05, props.theme.colors.white)};

  h2 {
    margin-bottom: 0;
  }
`;

export const StyledText = styled.p`
  color: ${props => shade(0.5, props.theme.colors.white)};
  margin-left: 2rem;
  margin-top: 1rem;
`;

export const StyledPostAndFilterWrapper = styled.div`
  display: flex;
  flex-direction: column;
  background: ${props => shade(0.1, props.theme.colors.white)};
  column-gap: 1rem;
  padding: 1rem;

  @media ${devices.tabletLandscape} {
    flex-direction: row;
  }
`;

export const StyledLogRow = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 0 2rem 1rem 2rem;
  margin-bottom: 1rem;
  border-bottom: 1px solid ${props => shade(0.2, props.theme.colors.white)};

  &:last-of-type {
    border-bottom: none;
    margin-bottom: 0;
  }
`;

export const StyledInfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const StyledFormContent = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-top: 1rem;
  align-items: center;
  justify-content: center;
  background: ${props => shade(0.1, props.theme.colors.white)};

  .post-dialog-loading {
    margin: 0 auto;
  }
`;

export const StyledInfo = styled.div`
  display: flex;
  flex-direction: row;
  column-gap: 0.5rem;
  align-items: center;
  width: 100%;

  p {
    margin-bottom: 0.2rem;
    word-break: break-word;
  }
`;

export const StyledRowInfo = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  column-gap: 3rem;

  @media ${devices.tablet} {
    flex-direction: row;
  }
`;

export const StyledLogDate = styled.p`
  color: ${props => shade(0.5, props.theme.colors.white)};
`;

export const StyledLogsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  overflow-y: auto;
`;
