import React from 'react';

import ConfigCard from '../../../components/config-card';

import {
  StyledUsers,
} from './users-styles';

const HandlerList = ({ navigation }) => (
  <ConfigCard
    title="Usuários"
    onClick={() => navigation.push('/users/list')}
  >
    <StyledUsers>
      usuários
    </StyledUsers>
  </ConfigCard>
);

export default HandlerList;
