import React from 'react';
import AccountantReviews from '../../../components/accountant-reviews';
import SelectedFilter from '../../../components/selected-filter';
import {
  StyledContainer, StyledContent, StyledLabel, StyledContentRow, StyledTitleFilter, StyledSelectedFilterListOne, StyledSelectedFilterListTwo,
} from './search result-styles';

const SearchResult = () => (
  <StyledContainer>
    <StyledLabel>Resultado da pesquisa</StyledLabel>
    <StyledContent>
      <StyledContentRow>
        <StyledTitleFilter>Filtros aplicados:</StyledTitleFilter>
        <StyledSelectedFilterListOne>
          <SelectedFilter title="Avaliações iguais ou menores a " onClickRemove={() => {}} />
          <SelectedFilter title="Avaliações no Google" onClickRemove={() => {}} />
          <SelectedFilter title="Avaliações respondidas e não respondidas" onClickRemove={() => {}} />
          <SelectedFilter title="Avaliações no local 4Pump Suplementos Center Natal: Creatina, Whey Protein, Proteínas em Natal RN" onClickRemove={() => {}} />
          <SelectedFilter title="Avaliações respondidas e não respondidas" onClickRemove={() => {}} />
        </StyledSelectedFilterListOne>
      </StyledContentRow>
      <StyledContentRow>
        <StyledTitleFilter>Quantidade de avaliações encontradas:</StyledTitleFilter>
        <StyledSelectedFilterListTwo>
          <AccountantReviews count={32} title="Avaliações respondidas" />
          <AccountantReviews color="SECONDARY" count={5} title="Avaliações não respondidas" />
        </StyledSelectedFilterListTwo>
      </StyledContentRow>
    </StyledContent>
  </StyledContainer>
);

export default SearchResult;
