import { useSelector, useDispatch } from 'react-redux';

import { Creators as AuthActions } from '../../redux/ducks/auth';

const useAuth = () => {
  const reduxDispatch = useDispatch();

  const {
    user: userMainData,
    language: userLanguage,
  } = useSelector(state => state.AuthReducer);

  const {
    accessToken: userAccessToken,
    user: userAllData,
  } = userMainData;

  const {
    company_group_limit: userCompanyGroupLimit,
    company_limit: userCompanyLimit,
    email: userEmail,
    facebook_user_id: userFacebookUserId,
    google_refresh_token: userGoogleRefreshToken,
    google_user_id: userGoogleUserId,
    id: userId,
    is_active: userIsActive,
    location_access_level_profiles: userLocationAccessLevelProfile,
    location_group_limit: userLocationGroupLimit,
    location_limit: userLocationLimit,
    name: userName,
    phone: userPhone,
    profiles: [userProfile],
  } = userAllData;

  const {
    name: userProfileName,
  } = userProfile;

  const userSetTokenLikeExpired = () => {
    reduxDispatch(AuthActions.setUser({ ...userMainData, accessToken: 'expired' }));
  };

  return {
    userMainData,
    userAccessToken,
    userIsActive,
    userProfile,
    userEmail,
    userCompanyLimit,
    userGoogleUserId,
    userFacebookUserId,
    userGoogleRefreshToken,
    userId,
    userLocationAccessLevelProfile,
    userLocationGroupLimit,
    userLocationLimit,
    userName,
    userPhone,
    userCompanyGroupLimit,
    userProfileName,
    userLanguage,
    userSetTokenLikeExpired,
  };
};

export default useAuth;
