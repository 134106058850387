import React from 'react';
import { useHistory } from 'react-router-dom';
import * as R from 'ramda';
import { debounce } from 'lodash';

import { GoogleLogin } from 'react-google-login';
import { useSnackbar } from 'notistack';

import { useAuth, useUncontrolledField } from '../../hooks';

import {
  handleFormValidation,
  listProfileIds,
  getFieldsCurrentValue,
  setFieldsToDefault,
  getFieldsActions,
  listProfileIdsToCreate,
} from './helper';

import {
  IconButton,
  Loading,
  GroupItems,
  OverlayLoading,
  HeaderToolbar,
  HealderToolbarAction,
} from '../../components';

import {
  fieldUserName,
  fieldUserEmail,
  fieldUserPhone,
  fieldUserPassword,
  fieldUserGoogleRefreshToken,
  fieldUserCompanyLimit,
  fieldUserCompanyGroupLimit,
  fieldUserLocationLimit,
  fieldUserLocationGroupLimit,
} from './field-schemes';

import {
  StyledButton,
  StyledTitle,
  StyledFields,
  StyledFieldsWrapper,
  StyledCreateUser,
  StyledRowUserInfoFieldsWrapper,
  StyledRowLimitFieldsWrapper,
  StyledGroupItemsWrapper,
  StyledGoogleTokenWrapper,
} from './create-user-styles';

import getUserByEmail from '../../services/users/getUserByEmail';
import getProfiles from '../../services/profiles/getProfiles';
import signUp from '../../services/signUpUser';
import addProfiles from '../../services/users/addProfiles';
import generateRefreshToken from '../../services/google/generateRefreshToken';
import getUserConnection from '../../services/connections/getUserConnections';
import createConnection from '../../services/connections/createConnection';

const CreateUser = () => {
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();

  const [loading, setLoading] = React.useState(false);
  const [overlayLoading, setOVerlayLoading] = React.useState(false);

  const [profilesToAdd, setProfilesToAdd] = React.useState([]);
  const [profilesToAddPage, setProfilesToAddPage] = React.useState(0);
  const [profilesToAddPageSize, setProfilesToAddPageSize] = React.useState(10);
  const [profilesToAddPageQuantity, setProfilesToAddPageQuantity] = React.useState(0);
  const [profilesToAddQuery, setProfilesToAddQuery] = React.useState('');

  const [addedProfiles, setAddedProfiles] = React.useState([]);
  const [addedProfilesPage, setAddedProfilesPage] = React.useState(0);
  const [addedProfilesPageSize, setAddedProfilesPageSize] = React.useState(10);
  const [addedProfilesPageQuantity, setAddedProfilesPageQuantity] = React.useState(0);
  const [addedProfilesPageQuery, setAddedProfilesPageQuery] = React.useState('');

  const [isLoadingRefreshToken, setIsLoadingRefreshToken] = React.useState(false);

  const [googleAccessToken, setGoogleAccessToken] = React.useState(null);
  const [googleRefreshToken, setGoogleRefreshToken] = React.useState([]);
  const [tokenExpiresIn, setTokenExpiresIn] = React.useState(null);
  const [googleAuthCode, setGoogleAuthCode] = React.useState(null);

  const [fields, setFields] = React.useState([]);

  const handleChangeAddedProfileSearch = debounce(text => setAddedProfilesPageQuery(text), 500);

  const {
    userAccessToken,
    userSetTokenLikeExpired,
  } = useAuth();

  const { Field: Name } = useUncontrolledField({
    fieldScheme: fieldUserName,
    saveIn: setFields,
  });

  const { Field: Email } = useUncontrolledField({
    fieldScheme: fieldUserEmail,
    saveIn: setFields,
  });

  const { Field: Phone } = useUncontrolledField({
    fieldScheme: fieldUserPhone,
    saveIn: setFields,
  });

  const { Field: Password } = useUncontrolledField({
    fieldScheme: fieldUserPassword,
    saveIn: setFields,
  });

  const { Field: GoogleRefreshToken } = useUncontrolledField({
    fieldScheme: fieldUserGoogleRefreshToken,
    saveIn: setGoogleRefreshToken,
  });

  const { Field: CompanyLimit } = useUncontrolledField({
    fieldScheme: fieldUserCompanyLimit,
    saveIn: setFields,
  });

  const { Field: CompanyGroupLimit } = useUncontrolledField({
    fieldScheme: fieldUserCompanyGroupLimit,
    saveIn: setFields,
  });

  const { Field: LocationLimit } = useUncontrolledField({
    fieldScheme: fieldUserLocationLimit,
    saveIn: setFields,
  });

  const { Field: LocationGroupLimit } = useUncontrolledField({
    fieldScheme: fieldUserLocationGroupLimit,
    saveIn: setFields,
  });

  const fetchProfiles = React.useCallback(async () => {
    const profileData = await getProfiles({
      accessToken: userAccessToken,
      setTokenLikeExpired: userSetTokenLikeExpired,
      query: profilesToAddQuery,
      setIsFetching: setOVerlayLoading,
    });

    if (R.isNil(profileData)) return;

    setProfilesToAdd(profileData[0]);
  }, [profilesToAddPage, profilesToAddQuery]);

  const redirectToList = () => {
    history.push('/users/list');
  };

  const checkItemAlreadyInGroup = (item) => {
    const foundProfile = addedProfiles.filter((profile) => profile.id === item.id);

    return foundProfile.length > 0;
  };

  const resetFieldValues = () => {
    fields.map(field => {
      if (field.name.includes('limit')) {
        field.setDefaultValue(0);
        field.setCurrentValue(0);
        return;
      }

      field.setDefaultValue('');
      field.setCurrentValue('');
    });

    googleRefreshToken[0].setDefaultValue('');
    googleRefreshToken[0].setCurrentValue('');

    setAddedProfiles([]);

    fetchProfiles();
  };

  const googleSuccessResponse = async (response) => {
    const {
      refresh_token: googleRefreshTokenResponse,
      access_token: generatedAccessToken,
      expires_in: expiresIn,
    } = await generateRefreshToken({
      authorizationCode: response.code,
      isLoading: setIsLoadingRefreshToken,
    });

    setGoogleAccessToken(generatedAccessToken);

    googleRefreshToken[0].setCurrentValue(googleRefreshTokenResponse);
    googleRefreshToken[0].setDefaultValue(googleRefreshTokenResponse);

    setTokenExpiresIn(expiresIn);
    setGoogleAuthCode(response.code);
  };

  const handleOpenGooglePopup = (renderProps) => {
    setFieldsToDefault(fields);

    renderProps.onClick();
  };

  const handleCreateConnection = async (
    userId,
    refreshToken,
    code,
    googleGeneratedAccessToken,
    googleTokenExpiresIn,
  ) => {
    const tokenExpirationDate = new Date();
    tokenExpirationDate.setSeconds(tokenExpirationDate.getSeconds() + googleTokenExpiresIn);

    const inputData = {
      code,
      refresh_token: refreshToken,
      token: googleGeneratedAccessToken,
      token_expires_in: tokenExpirationDate.toUTCString(),
    };

    const createData = await createConnection({
      accessToken: userAccessToken,
      userId: parseInt(userId),
      channel: 'google',
      code,
      refreshToken,
      createdBy: parseInt(userId),
      token: googleGeneratedAccessToken,
      tokenExpiresIn: inputData.token_expires_in,
    });

    return createData;
  };

  const handleProfileToAddClicked = (profile) => {
    setFieldsToDefault(fields);

    if (!checkItemAlreadyInGroup(profile)) {
      setAddedProfiles([...addedProfiles, profile]);
      return;
    }

    enqueueSnackbar('Este perfil já foi adicionado', { variant: 'warning' });
  };

  const handleAddedProfileClicked = (profile) => {
    setFieldsToDefault(fields);

    setAddedProfiles(addedProfiles.filter((item) => profile.id !== item.id));

    if (!R.isNil(profilesToAdd.find(removedProfile => removedProfile.id === profile.id))) return;

    setProfilesToAdd([...profilesToAdd, profile]);
  };

  const handleSubmitCreateUser = async () => {
    setFieldsToDefault(fields);

    const validatedFields = handleFormValidation({
      fields,
      getFieldsActions,
      addedProfiles,
      googleRefreshToken: googleRefreshToken[0].getValue(),
      enqueueSnackbar,
    });

    if (validatedFields) return;

    setLoading(true);

    const {
      getFieldUserName,
      getFieldUserEmail,
      getFieldUserPhone,
      getFieldUserPassword,
      getFieldUserCompanyGroupLimit,
      getFieldUserCompanyLimit,
      getFieldUserLocationGroupLimit,
      getFieldUserLocationLimit,
    } = getFieldsActions(fields);

    const {
      getFieldUserRefreshToken,
    } = getFieldsActions(googleRefreshToken);

    const emailAlreadyExists = await getUserByEmail({
      accessToken: userAccessToken,
      email: getFieldUserEmail,
      setTokenLikeExpired: userSetTokenLikeExpired,
    });

    if (!R.isNil(emailAlreadyExists)) {
      setLoading(false);
      enqueueSnackbar('O email inserido já está em uso!', { variant: 'error' });
      return;
    }

    setOVerlayLoading(true);

    const userData = await signUp({
      form: {
        name: getFieldUserName,
        email: getFieldUserEmail,
        phone: getFieldUserPhone,
        google_refresh_token: getFieldUserRefreshToken,
        password: getFieldUserPassword,
        company_group_limit: getFieldUserCompanyGroupLimit,
        company_limit: getFieldUserCompanyLimit,
        location_group_limit: getFieldUserLocationGroupLimit,
        location_limit: getFieldUserLocationLimit,
      },
      accessToken: userAccessToken,
      setTokenLikeExpired: userSetTokenLikeExpired,
    });

    if (R.isNil(userData)) {
      setLoading(false);
      setOVerlayLoading(false);
      enqueueSnackbar('Não foi possível criar o usuário, tente novamente!', { variant: 'error' });
      return;
    }

    const { id, profileIds } = listProfileIdsToCreate({
      userData,
      addedProfiles,
    });

    const profileDataResponse = await addProfiles({
      accessToken: userAccessToken,
      userId: id,
      profile_ids: profileIds,
      setTokenLikeExpired: userSetTokenLikeExpired,
    });

    if (R.isNil(profileDataResponse)) {
      setLoading(false);
      setOVerlayLoading(false);
      enqueueSnackbar('Erro ao criar perfis, tente novamente', { variant: 'error' });
      return;
    }

    const connectionDataResponse = await handleCreateConnection(
      id,
      getFieldUserRefreshToken,
      googleAuthCode,
      googleAccessToken,
      tokenExpiresIn,
    );

    if (R.isNil(connectionDataResponse)) {
      setLoading(false);
      setOVerlayLoading(false);
      enqueueSnackbar('Erro ao criar conexão do Refresh Token, tente novamente', { variant: 'error' });
      return;
    }

    enqueueSnackbar('Usuário criado com sucesso', { variant: 'success' });
    resetFieldValues();
    setAddedProfiles([]);
    fetchProfiles();
    setLoading(false);
    setOVerlayLoading(false);
  };

  React.useEffect(() => {
    fetchProfiles();
  }, [fetchProfiles]);

  return (
    <StyledCreateUser>
      {overlayLoading && <OverlayLoading fullScreen textToLoading="Carregando dados..." />}

      <HeaderToolbar
        title="Criar Usuário"
      >
        <HealderToolbarAction
          title="Listar Usuários"
          icon="List"
          onClick={redirectToList}
        />
      </HeaderToolbar>

      <StyledFieldsWrapper>
        <StyledTitle>Informações Pessoais</StyledTitle>
        <StyledFields>
          <StyledRowUserInfoFieldsWrapper>
            <Name />
            <Email />
            <Phone />
            <Password />
          </StyledRowUserInfoFieldsWrapper>
        </StyledFields>

        <StyledTitle>Google Refresh Token</StyledTitle>

        <StyledFields>
          <StyledGoogleTokenWrapper>
            <GoogleRefreshToken />
            <GoogleLogin
              clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}
              onSuccess={googleSuccessResponse}
              onFailure={() => {}}
              scope="https://www.googleapis.com/auth/business.manage"
              prompt="consent"
              responseType="code"
              uxMode="redirect"
              redirectUri="https://saas.hublocal.com.br/receive-foursquare-code"
              render={(renderProps) => (isLoadingRefreshToken ? <Loading /> : (
                <IconButton
                  className="borderButton"
                  tooltip="Conectar com o Google"
                  icon="VpnKey"
                  placement="left"
                  onClick={() => handleOpenGooglePopup(renderProps)}
                />
              ))}
            />
          </StyledGoogleTokenWrapper>
        </StyledFields>

        <StyledTitle>Limites de Registro</StyledTitle>

        <StyledFields>
          <StyledRowLimitFieldsWrapper>
            <CompanyLimit />
            <CompanyGroupLimit />
          </StyledRowLimitFieldsWrapper>

          <StyledRowLimitFieldsWrapper>
            <LocationLimit />
            <LocationGroupLimit />
          </StyledRowLimitFieldsWrapper>
        </StyledFields>

        <StyledGroupItemsWrapper>
          <GroupItems
            mainTitle="Perfil de Acesso"
            itemsToAdd={profilesToAdd}
            addedItens={addedProfiles.filter((profile) => profile.name.toLowerCase().includes(addedProfilesPageQuery.toLowerCase()))}
            itemsToAddPagesQuantity={profilesToAddPageQuantity}
            addedItemsPagesQuantity={addedProfilesPageQuantity}
            setQueryItensToAddPage={setProfilesToAddQuery}
            setQueryAddedItensPage={setAddedProfilesPageQuery}
            setItensToAddPage={setProfilesToAddPage}
            currentItensToAddPage={profilesToAddPage}
            setAddedItensPage={setAddedProfilesPage}
            currentAddedItensPage={addedProfilesPage}
            primaryTextKey="name"
            onItemToAddClicked={handleProfileToAddClicked}
            onAddedItemClicked={handleAddedProfileClicked}
            onAddedItemsInputChanged={({ target: { value } }) => handleChangeAddedProfileSearch(value)}
            inputLabelToAdd="Perfis Disponíveis"
            inputLabelAdded="Pesquisar Adicionados"
            buttonTooltipAddTitle="Adicionar Perfil"
            buttonTooltipRemoveTitle="Remover Perfil"
            emptyItemsToAdd="Nenhum perfil a adicionar"
            emptyAddedItems="Nenhum perfil adicionado"
            isCreation
          />

          <StyledButton
            onClick={handleSubmitCreateUser}
            disabled={loading}
          >
            {loading && <Loading className="loading" />}
            {' '}
            Criar Usuário
          </StyledButton>
        </StyledGroupItemsWrapper>
      </StyledFieldsWrapper>
    </StyledCreateUser>
  );
};

export default CreateUser;
