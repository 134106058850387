import axios from 'axios';

import { TGetCompanies } from '../../types/TCompany';

export default async function getCompanies({
  accessToken,
  page = 0,
  pageSize = 100,
  query = '',
  orderByName = 'ASC',
  setIsFetching,
  setTokenLikeExpired,
}: TGetCompanies) {
  try {
    if (setIsFetching) setIsFetching(true);

    const requestOptions = {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    };

    const { data } = await axios.get(
      `${process.env.REACT_APP_COMPANIES_API_URL}?page=${page}&pageSize=${pageSize}&query=${query}&orderByName=${orderByName}`,
      requestOptions,
    );

    return data;
  } catch (err: any) {
    if (err.response?.status === 401 && setTokenLikeExpired) setTokenLikeExpired();

    return null;
  } finally {
    if (setIsFetching) setIsFetching(false);
  }
}
