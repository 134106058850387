import React from 'react';
import { isNil } from 'ramda';

import type { TUpdatePostStatus } from '../../../types/TBatchUpdate';

import { BatchStatusIcon, GroupDataTable } from '../../../components';

import { StyledUpdateStatus, StyledImg } from './update-status-styles';

const noImage = `${process.env.PUBLIC_URL}/images/missing-image.png`;

const UpdateStatus = ({
  setOpenCreatePostDialog,
  setPostIdToVisualize,
  data,
  getLocalPosts,
  localPostsCount,
  localPostsPageSize,
  localPostsPage,
  setLocalPostsQuery,
  setLocalPostsPage,
  setLocalPostsPageSize,
  isFetching,
  removeLocalPosts,
  cancelLocalPosts,
  setInfoToVisualize,
}: TUpdatePostStatus) => {
  const customColumns = [
    {
      name: 'Id',
      options: { display: false, sort: false },
    },
    {
      name: 'Mídia',
      options: {
        customBodyRender: (value: string) => (
          <>
            {isNil(value) ? (
              <StyledImg src={noImage} width="100rem" />
            ) : (
              <StyledImg src={value} width="100rem" />
            )}
          </>
        ),
        sort: false,
      },
    },
    {
      name: 'Data de Criação',
      options: { sort: false },
    },
    {
      name: 'Tipo',
      options: { sort: false },
    },
    {
      name: 'Status',
      options: {
        customBodyRender: (value: any) => <BatchStatusIcon status={value} batchType="post" />,
        sort: false,
      },
    },
  ];

  const subTableColumns = [
    { name: 'Id', options: { display: false, sort: false } },
    { name: 'Nome do Local', options: { sort: false } },
    { name: 'Id da Postagem', options: { display: false, sort: false } },
    { name: 'Link da Postagem', options: { display: false, sort: false } },
    {
      name: 'Status',
      options: {
        customBodyRender: (value: any) => <BatchStatusIcon status={value} batchType="post" />,
        sort: false,
      },
    },
  ];

  const handlePreviewPost = (rowData: any) => {
    setPostIdToVisualize(rowData);
    setInfoToVisualize(null);
    setOpenCreatePostDialog(true);
  };

  return (
    <StyledUpdateStatus>
      <GroupDataTable
        count={localPostsCount}
        page={localPostsPage}
        setPage={setLocalPostsPage}
        pageSize={localPostsPageSize}
        setPageSize={setLocalPostsPageSize}
        setSearchQuery={setLocalPostsQuery}
        loading={isFetching}
        title="Status das Postagens"
        data={data}
        columns={customColumns}
        subTableColumns={subTableColumns}
        clickable={false}
        className="table"
        onDeleteClicked={removeLocalPosts}
        onCancelClicked={cancelLocalPosts}
        onRowClickURL={() => console.log('row')}
        onUpdateClicked={getLocalPosts}
        onPreviewClicked={handlePreviewPost}
        subTableDataType="BATCH_POST"
        addRegisterLabel="Register"
        addRegisterLink="/company-group/create"
        editable={false}
        statusTable
        colspan="9"
      />
    </StyledUpdateStatus>
  );
};

export default UpdateStatus;
