import React from 'react';
import styled, { css } from 'styled-components';
import { shade } from 'polished';

import { Tooltip } from '@material-ui/core';

export const StyledTooltip = styled(props => (
  <Tooltip
    classes={{
      popper: props.className,
      tooltip: 'icon-button-tooltip',
      arrow: 'icon-button-tooltip-arrow',
    }}
    {...props}
  />
))`
  .icon-button-tooltip {
    box-shadow: 0.1rem 0.1rem 0.2rem 0 rgba(0, 0, 0, 0.58);

    ${props => props.variant && css`
      background: ${props.theme.colors[props.variant]} !important;
    `};

    ${props => props.variant !== 'primary' && css`
      color: ${props.theme.colors.white}
    `};

    ${props => props.variant === 'white' && css`
      color: ${props.theme.colors.black}
    `};

    white-space: pre-line;
  }

  .icon-button-tooltip-arrow {
    ${props => props.variant && css`
      color: ${props.theme.colors[props.variant]} !important;
    `};
  }
`;

export const StyledTooltipTitleAsList = styled.ul`
  display: flex;
  flex-direction: column;
  margin: 0;
  padding: 0 1rem;

  li {
    font-size: 1.3rem;
    border-bottom: 0.1rem solid ${props => shade(0.3, props.theme.colors[props.variant])};
    padding: 1rem 0;
    font-weight: bold;

    &:last-child {
      border-bottom: 0;
    }
  }
`;
