export const testArray = [
  {
    id: 1,
    title: 'teste1',
    text: 'text teste1',
  },
  {
    id: 2,
    title: 'teste2',
    text: 'text teste2',
  },
  {
    id: 3,
    title: 'teste1',
    text: 'text teste1',
  },
  {
    id: 4,
    title: 'teste2',
    text: 'text teste2',
  },
  {
    id: 5,
    title: 'teste1',
    text: 'text teste1',
  },
  {
    id: 6,
    title: 'teste2',
    text: 'text teste2',
  },
  {
    id: 7,
    title: 'teste1',
    text: 'text teste1',
  },
  {
    id: 8,
    title: 'teste2',
    text: 'text teste2',
  },
];
