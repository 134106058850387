import React from 'react';
import { isEmpty } from 'ramda';
import { useSnackbar } from 'notistack';

import { isNil } from 'lodash';
import { Dialog, Button, Loading } from '../../../../../components';

import { MediaItem } from './media-item';

import { DeleteBatchLocationMedia as APIDeleteBatchLocationMedia } from '../../../../../services/google-batch-modules/batch-gallery/delete-batch-location-media';

import {
  StyledMediaList,
  StyledMediasPopover,
  StyledButtonWrapper,
  StyledConfirmDeleteWrapper,
} from './medias-locations-popover-style';

import type { TBatchGalleryLocationMediaData } from '../../../../../types/TBatchGallery';
import type { EBatchPlatformStatus } from '../../../../../types/TBatchGoogleModules';

type TMediasPopoverProps = {
  open: boolean;
  data: TBatchGalleryLocationMediaData[];
  onClose: () => void;
  creationDate: string;
  galleryBatchLocationId: string;
  userAccessToken: string;
  updateBatch: () => Promise<void>;
  updateBatchLocation: () => Promise<void>;
  batchLocationStatus: EBatchPlatformStatus;
};

const MediasLocationsPopover = ({
  open,
  data,
  onClose,
  creationDate,
  galleryBatchLocationId,
  userAccessToken,
  updateBatch,
  updateBatchLocation,
  batchLocationStatus,
}: TMediasPopoverProps) => {
  const { enqueueSnackbar } = useSnackbar();

  const [rowData, setRowData] = React.useState<TBatchGalleryLocationMediaData[]>([]);
  const [selectedMedia, setSelectedMedia] = React.useState<string[]>([]);
  const [loading, setLoading] = React.useState(false);
  const [confirmDeletePrompt, setConfirmDeletePrompt] = React.useState(false);

  React.useEffect(() => {
    setRowData(data.filter(media => !isNil(media)));
  }, [data, updateBatchLocation]);

  const handleMediaSubmit = React.useCallback(async () => {
    if (isEmpty(selectedMedia)) return;

    const { status } = await APIDeleteBatchLocationMedia({
      userAccessToken,
      galleryBatchLocationId,
      mediaIds: selectedMedia,
      isUpdating: setLoading,
    });

    if (status === 'ERROR') {
      enqueueSnackbar('Erro ao iniciar remoção de mídia', { variant: 'error' });
      return;
    }

    await updateBatchLocation();
    await updateBatch();
    onClose();
  }, [selectedMedia, galleryBatchLocationId, userAccessToken]);

  return (
    <Dialog open={open} onClose={onClose} title="Mídias" closeIcon>
      <>
        <StyledMediasPopover>
          <StyledMediaList mediaLength={data.length}>
            {rowData.map((item) => (
              <MediaItem
                item={item}
                selected={selectedMedia}
                setSelected={setSelectedMedia}
                creationDate={creationDate}
                key={item.id}
                lockedCheckboxes={confirmDeletePrompt}
                batchLocationStatus={batchLocationStatus}
              />
            ))}
          </StyledMediaList>
        </StyledMediasPopover>

        {!isEmpty(selectedMedia) && !confirmDeletePrompt && (
        <StyledButtonWrapper>
          <Button
            onClick={() => setConfirmDeletePrompt(true)}
            className="button"
            disabled={loading}
          >
            {loading && <Loading className="is-loading" />}
            {' '}
            Excluir
          </Button>
        </StyledButtonWrapper>
        )}

        {confirmDeletePrompt && (
        <StyledButtonWrapper>
          <p>Tem certeza que deseja excluir esta(s) mídias(s)</p>
          <StyledConfirmDeleteWrapper>
            <Button
              onClick={() => setConfirmDeletePrompt(false)}
              className="button"
              disabled={loading}
              buttonType="cancel"
            >
              Cancelar
            </Button>

            <Button
              onClick={() => handleMediaSubmit()}
              className="button"
              disabled={loading}
              buttonType="red"
            >
              {loading && <Loading className="is-loading" />}
              {' '}
              Excluir
            </Button>

          </StyledConfirmDeleteWrapper>
        </StyledButtonWrapper>
        )}
      </>
    </Dialog>
  );
};

export { MediasLocationsPopover };
