import React from 'react';
import * as R from 'ramda';

import { useUncontrolledField } from '../../../hooks';

import APIUpdatePersonalInfo from '../../../services/users/updateUserProfile';

import { IconButton, Loading } from '../../../components';

import {
  fieldPersonalInfoName,
  fieldPersonalInfoEmail,
  fieldPersonalInfoPhone,
} from './fields-scheme';

import {
  StyledPersonalInfo,
  StyledPersonalInfoContainer,
  StyledInputGroup,
  StyledTextLabel,
  StyledTextWrapper,
  StyledTitleLabel,
  StyledTitleLabelWrapper,
  StyledButton,
  StyledButtonContainer,
} from './personal-info-styles';

const PersonalInfo = ({
  userAccessToken,
  userId,
  userName,
  userEmail,
  userPhone,
  userMainData,
  userSetTokenLikeExpired,
  dispatchRedux,
  AuthActions,
  feedbackMessage,
}) => {
  const [fields, setFields] = React.useState([]);
  const [notEditableFields, setNotEditableFields] = React.useState(true);
  const [isLoading, setIsLoading] = React.useState(false);

  const { Field: Name } = useUncontrolledField({
    fieldScheme: {
      ...fieldPersonalInfoName,
      defaultValue: userName,
    },
    saveIn: setFields,
  });

  const { Field: Email } = useUncontrolledField({
    fieldScheme: {
      ...fieldPersonalInfoEmail,
      defaultValue: userEmail,
    },
    saveIn: setFields,
  });

  const { Field: Phone } = useUncontrolledField({
    fieldScheme: {
      ...fieldPersonalInfoPhone,
      defaultValue: userPhone,
    },
    saveIn: setFields,
  });

  const updatePersonalInfo = React.useCallback(async () => {
    const [fieldName, fieldEmail, fieldPhone] = fields.map(field => field.getValue());

    if (fieldName === '') {
      feedbackMessage('Nome é obrigatório', { variant: 'warning' });
      return;
    }

    if (fieldEmail === '') {
      feedbackMessage('Email é obrigatório', { variant: 'warning' });
      return;
    }

    if (fieldName === userName && fieldEmail === userEmail && fieldPhone === userPhone) {
      feedbackMessage('Nenhuma informação a ser atualizada', { variant: 'warning' });
      return;
    }

    const updateProfileData = {
      name: fieldName,
      email: fieldEmail,
      phone: fieldPhone,
    };

    fields.map(field => {
      if (field.name === 'name') field.setDefaultValue(fieldName);
      if (field.name === 'email') field.setDefaultValue(fieldEmail);
      if (field.name === 'phone') field.setDefaultValue(fieldPhone);
    });

    const personalInfoResponse = await APIUpdatePersonalInfo({
      accessToken: userAccessToken,
      id: userId,
      updateProfileData,
      setTokenLikeExpired: userSetTokenLikeExpired,
      feedbackMessage,
      isLoading: setIsLoading,
    });

    if (R.isNil(personalInfoResponse)) return;

    dispatchRedux(AuthActions.setUser({
      ...userMainData,
      user: {
        ...userMainData.user,
        ...updateProfileData,
      },
    }));

    setNotEditableFields(true);
  }, [fields]);

  const handleSwitchEditableFields = () => {
    setNotEditableFields(!notEditableFields);

    fields.map(field => {
      field.setIsDisabled(!notEditableFields);
    });
  };

  return (
    <StyledPersonalInfo>
      <StyledTitleLabelWrapper>
        <StyledTitleLabel>Informações Pessoais</StyledTitleLabel>
        {notEditableFields && (
          <IconButton
            icon="Create"
            placement="left"
            tooltip="Alterar Informações"
            onClick={handleSwitchEditableFields}
          />
        )}

        {!notEditableFields && (
          <IconButton
            icon="Close"
            placement="left"
            tooltip="Cancelar Alterações"
            onClick={handleSwitchEditableFields}
          />
        )}
      </StyledTitleLabelWrapper>

      <StyledPersonalInfoContainer>
        <StyledInputGroup>
          <StyledTextWrapper>
            <StyledTextLabel>Nome:</StyledTextLabel>
            <Name />
          </StyledTextWrapper>

          <StyledTextWrapper>
            <StyledTextLabel>Email:</StyledTextLabel>
            <Email />
          </StyledTextWrapper>

          <StyledTextWrapper>
            <StyledTextLabel>Telefone:</StyledTextLabel>
            <Phone />
          </StyledTextWrapper>
        </StyledInputGroup>

        {!notEditableFields && (
          <StyledButtonContainer>
            <StyledButton
              onClick={handleSwitchEditableFields}
              className="button"
              buttonType="cancel"
            >
              Cancelar
            </StyledButton>

            <StyledButton
              onClick={updatePersonalInfo}
              className="button"
              buttonType="primary"
              disabled={isLoading}
            >
              {isLoading && <Loading className="is-loading-save-infos" />}
              Salvar
            </StyledButton>
          </StyledButtonContainer>
        )}
      </StyledPersonalInfoContainer>
    </StyledPersonalInfo>
  );
};

export default PersonalInfo;
