/* eslint-disable react/require-default-props */
import { FormControlLabel } from '@material-ui/core';
import React, { ChangeEvent, useState } from 'react';
import GroupActionButton from '../group-action-button';
import ButtonCard from './button-card';
import {
  StyledContainer,
  StyledFormGroup,
  StyledDate,
  StyledPositionCheckbox,
  StyledCheckbox,
  StyledInput,
  StyledTextArea,
  StyledLabel,
  StyledFooter,
  StyledTitleFooter,
  StyledSwitch,
  StyledResponseState,
  StyledGroupButtonCard,
  StyledPositionButtonAction,
} from './card-automatic-replies-styles';

type Props = {
    company: string;
    onClickIconEmojis(): void;
    onClickIconUser(): void;
    onClickIconLocalization(): void;
    handleButtonConclude(): void;
    handleButtonEdit?(): void | undefined;
    handleButtonCancel(): void;
    removeCheckbox?: boolean;
    isEdit?: boolean;
}

const CardAutomaticReplies = ({
  company, onClickIconEmojis, onClickIconUser, onClickIconLocalization, handleButtonConclude, removeCheckbox, isEdit, handleButtonEdit, handleButtonCancel,
}: Props) => {
  const [switchChecked, setSwitchChecked] = useState(true);
  const [checkboxChecked, setCheckboxChecked] = useState(true);

  const handleChangeChecked = (event: ChangeEvent<HTMLInputElement>) => {
    setSwitchChecked(event.target.checked);
  };

  const handleChangeCheckboxChecked = (event: ChangeEvent<HTMLInputElement>) => {
    setCheckboxChecked(event.target.checked);
  };

  return (
    <StyledContainer
      variant="outlined"
    >
      <StyledFormGroup>
        <StyledPositionCheckbox>
          <StyledLabel>Título da resposta</StyledLabel>
          { removeCheckbox
            ? <></>
            : (
              <StyledCheckbox
                onChange={handleChangeCheckboxChecked}
              />
            )}
        </StyledPositionCheckbox>
        <StyledInput
          placeholder={`Resposta para ${company}`}
          variant="outlined"
        />
        { isEdit ? (
          <StyledPositionButtonAction>
            <StyledLabel>Resposta</StyledLabel>
            <GroupActionButton
              onClickIconEmojis={onClickIconEmojis}
              onClickIconUser={onClickIconUser}
              onClickIconLocalization={onClickIconLocalization}
            />
          </StyledPositionButtonAction>
        ) : (
          <StyledPositionButtonAction>
            <StyledLabel>Resposta</StyledLabel>
          </StyledPositionButtonAction>
        ) }
        <StyledTextArea
          minRows={5}
        />
      </StyledFormGroup>
      <StyledDate>Última edição em 20/07/2022 às 18h03</StyledDate>
      <StyledFooter>
        <StyledResponseState>
          <StyledTitleFooter>Estado da resposta:</StyledTitleFooter>
          <FormControlLabel
            className="FormControlLabel"
            label={switchChecked ? 'Ativa' : 'Desativa'}
            control={(
              <StyledSwitch
                checked={switchChecked}
                onChange={handleChangeChecked}
              />
            )}
          />
        </StyledResponseState>
        { isEdit ? (
          <StyledGroupButtonCard>
            <ButtonCard typeButton="SECONDARY" onClick={handleButtonCancel}>
              Cancelar
            </ButtonCard>
            <ButtonCard typeButton="PRIMARY" onClick={handleButtonConclude}>
              Salvar
            </ButtonCard>
          </StyledGroupButtonCard>
        )
          : (
            <ButtonCard
              typeButton="PRIMARY"
              onClick={handleButtonEdit}
            >
              Editar
            </ButtonCard>
          )}

      </StyledFooter>
    </StyledContainer>
  );
};
export default CardAutomaticReplies;
