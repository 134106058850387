import React from 'react';
import * as R from 'ramda';

import { Dialog } from '..';
import SyncInfoPopover from './sync-info-popover';
import { getAllFieldsByRequest } from '../../services/platform-sync-modules/get-all-fields-by-request';
import { useAuth } from '../../hooks';

interface RequestDialogProps {
  isDialogOpen: boolean;
  onClose(): void;
  syncDialogId: string;
}

const SyncInfoDialog = ({ isDialogOpen, onClose, syncDialogId }: RequestDialogProps) => {
  const {
    userAccessToken,
  } = useAuth();

  const [syncFieldData, setSyncFieldData] = React.useState<any[]>([]);
  const getFieldsFromRequest = React.useCallback(async () => {
    const getFieldsFromRequestResponse = await getAllFieldsByRequest({
      requestId: syncDialogId,
      userAccessToken,
    });

    if (R.isNil(getFieldsFromRequestResponse)) return;

    const [syncFieldsFromRequestData] = getFieldsFromRequestResponse;

    setSyncFieldData(syncFieldsFromRequestData);
  }, [syncDialogId]);

  React.useEffect(() => { getFieldsFromRequest(); }, [getFieldsFromRequest]);
  return (
    <>
      {!R.isEmpty(syncFieldData) && (
      <Dialog
        onClose={onClose}
        open={isDialogOpen}
        title="Informações Sincronizadas"
        wideDialog
      >
        <SyncInfoPopover data={syncFieldData} />
      </Dialog>
      )}
    </>
  );
};

export default SyncInfoDialog;
