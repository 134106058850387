import axios, { AxiosRequestConfig } from 'axios';
import {
  TUpdateRequestProps,
} from './types';

export const updateRequest = async ({
  googleAccessToken,
  isUpdating,
  platformSyncData,
  requestId,
  status = 'PROCESSING',
  userAccessToken,
}: TUpdateRequestProps) => {
  try {
    if (isUpdating) isUpdating(true);
    const apiPath = `${process.env.REACT_APP_SYNC_PLATFORM_REQUESTS}/${requestId}`;

    const requestOptions: AxiosRequestConfig = {
      headers: {
        Authorization: `Bearer ${userAccessToken}`,
        'access-token': googleAccessToken,
      },
    };

    const { data } = await axios.put(apiPath, { status, platformSyncData }, requestOptions);

    return data;
  } catch (err) {
    return null;
  } finally {
    if (isUpdating) isUpdating(false);
  }
};
