import React from 'react';
import { isNil, isEmpty, last } from 'ramda';
import { GoogleLogin } from 'react-google-login';
import { useDispatch, useSelector } from 'react-redux';

import APIGenerateRefreshToken from '../../../../services/google/generateRefreshToken';
import APIUpdateUserProfile from '../../../../services/users/updateUserProfile';
import APIGetUserConnections from '../../../../services/connections/getUserConnections';
import APIUpdateConnection from '../../../../services/connections/updateConnection';
import APICreateConnection from '../../../../services/connections/createConnection';

import APIGetLocationConnections from '../../../../services/location-connections/getAllLocationConnectionsFromLocation';

import getValidGoogleAccessToken from '../../../../services/getValidGoogleAccessToken';
import getGoogleUserLocations from '../../../../services/google/getGoogleUserLocations';

import { Creators as AuthActions } from '../../../../redux/ducks/auth';

import {
  TGoogleUserAccountsData,
  TGetGoogleUserAccountLocations,
  TGetGoogleUserAccountLocationsResponse,
  TLocationConnectionInfo,
  TLocationConnection,
} from '../../../../types/TPlatformsLinkWithLocation';

import type { TRootStateRedux } from '../../../../types/TRootStateRedux';

import { useAuth } from '../../../../hooks';

import {
  DropDownDialog,
  Loading,
} from '../../../../components';

import LocationList from './location-list';

import { getGoogleUserAccounts } from './helpers';

import {
  StyledGoogle,
  StyledGoogleIcon,
  StyledHeader,
  StyledHeaderLogoWrapper,
  StyledContent,
  StyledActionsWrapper,
  StyledIconLabel,
  StyledIconLabelBold,
  StyledAddConnectionsButton,
  StyledShowConnectionsButton,
  StyledNoAccountLinkedWrapper,
  StyledConnectButton,
  StyledConfigContent,
} from './google-styles';
import { getAccountData } from '../../../../services/google/getAccountData';
import GoogleLoggedAccount from './google-logged-account';
import LocationConnectionList from '../../../../components/location-connection-list';

const Google = () => {
  const reduxDispatch = useDispatch();

  const { activeCompany } = useSelector((state: TRootStateRedux) => state.CompanyReducer);
  const { activeLocation } = useSelector((state: TRootStateRedux) => state.LocationReducer);

  const {
    userAccessToken,
    userSetTokenLikeExpired,
    userId,
    userProfile,
    userGoogleRefreshToken,
    userMainData,
    userProfileName,
  } = useAuth();

  const [loadGoogleAccessToken, setLoadGoogleAccessToken] = React.useState(false);
  const [googleAccessToken, setGoogleAccessToken] = React.useState('');

  const [googleUserAccountsData, setGoogleUserAccountsData] = React.useState<TGoogleUserAccountsData[]>([]);
  const [googleUserCurrentAccount, setGoogleUserCurrentAccounts] = React.useState<TGoogleUserAccountsData>();
  const [googleUserAccountsLoading, setGoogleUserAccountsLoading] = React.useState(false);
  const [googleUserCurrentAccountNextPage, setGoogleUserCurrentAccountNextPage] = React.useState('');

  const [googleUserLocations, setGoogleUserLocations] = React.useState([]);
  const [googleUserLocationsPage, setGoogleUserLocationsPage] = React.useState('');

  const [googlePaginationPageList, setGooglePaginationPageList] = React.useState(['']);
  const [googlePaginationLocationPageList, setGooglePaginationLocationPageList] = React.useState(['']);

  const [expanded, setExpanded] = React.useState(false);
  const [addConnectionIsSelected, setAddConnectionIsSelected] = React.useState(false);
  const [viewConnectionsIsSelected, setViewConnectionsIsSelected] = React.useState(false);

  const [locationConnectionInfo, setLocationConnectionInfo] = React.useState<TLocationConnectionInfo>();
  const [locationConnectionList, setLocationConnectionList] = React.useState<TLocationConnection[]>([]);

  const handleClickAddConnection = () => {
    if (addConnectionIsSelected) {
      setAddConnectionIsSelected(prev => !prev);
      return setExpanded(false);
    }
    setExpanded(true);
    setViewConnectionsIsSelected(false);
    setAddConnectionIsSelected(true);
  };

  const handleClickViewConnections = () => {
    if (viewConnectionsIsSelected) {
      setViewConnectionsIsSelected(prev => !prev);
      return setExpanded(false);
    }
    setExpanded(true);
    setAddConnectionIsSelected(false);
    setViewConnectionsIsSelected(true);
  };

  const getGoogleUserAccountLocations = React.useCallback(async ({
    accessToken,
    currentUserAccount,
    queryUserLocation,
    userLocationsPage,
    filterInvalidLocations,
    isFetching,
  }: TGetGoogleUserAccountLocations): Promise<TGetGoogleUserAccountLocationsResponse> => {
    if (!currentUserAccount) {
      return {
        locations: [],
        locationsPageToken: '',
      };
    }

    if (userLocationsPage) {
      const { locations, nextPageToken } = await getGoogleUserLocations({
        accessToken,
        userId: currentUserAccount.id,
        pageToken: userLocationsPage,
        query: queryUserLocation,
        filterInvalid: filterInvalidLocations,
        setIsFetching: isFetching,
      });

      return {
        locations,
        locationsPageToken: nextPageToken,
      };
    }

    const { locations, nextPageToken } = await getGoogleUserLocations({
      accessToken,
      userId: currentUserAccount.id,
      pageToken: userLocationsPage,
      query: queryUserLocation,
      filterInvalid: filterInvalidLocations,
      setIsFetching: isFetching,
    });

    return {
      locations,
      locationsPageToken: nextPageToken,
    };
  }, []);

  const getGoogleUserAccountsData = React.useCallback(async (accessToken: string, nextPageToken: string) => {
    setGoogleUserAccountsLoading(true);

    const {
      googleUserAccounts,
      nextGoogleUserAccountPageToken,
    } = await getGoogleUserAccounts({
      googleAccessToken: accessToken,
      googleUserAccountsPage: nextPageToken,
    });

    const formattedGoogleUserAccounts = googleUserAccounts.map((account, index) => ({
      ...account,
      name: index === 0 ? 'Desagrupada' : account.name,
    }));

    setGoogleUserAccountsData(formattedGoogleUserAccounts);
    setGoogleUserCurrentAccounts(formattedGoogleUserAccounts[0]);
    setGoogleUserCurrentAccountNextPage(nextGoogleUserAccountPageToken);

    setGoogleUserAccountsLoading(false);
  }, []);

  const getGoogleAccessToken = React.useCallback(async () => {
    setLoadGoogleAccessToken(true);

    const googleAccessTokenResponse = await getValidGoogleAccessToken({
      accessToken: userAccessToken,
      userId,
      setTokenLikeExpired: userSetTokenLikeExpired,
      userProfile,
      userGoogleRefreshToken,
    });

    if (isNil(googleAccessTokenResponse)) {
      setLoadGoogleAccessToken(false);
      return;
    }
    const {
      googleAccessToken: accessTokenGoogle,
      expirationTime,
      googleRefreshToken,
      googleAuthCode,
    } = googleAccessTokenResponse;

    getGoogleUserAccountsData(accessTokenGoogle, '');

    const googleProfile = await getAccountData({ accessToken: accessTokenGoogle });

    setGoogleAccessToken(accessTokenGoogle);
    setLocationConnectionInfo({
      token: accessTokenGoogle,
      code: googleAuthCode,
      token_expires_in: expirationTime,
      refresh_token: googleRefreshToken,
      email: googleProfile.email,
      grouping_name: googleUserCurrentAccount?.name || 'Desagrupada',
    });
    setLoadGoogleAccessToken(false);
  }, []);

  React.useEffect(() => { getGoogleAccessToken(); }, []);

  const handleOnChangeDropDownDialog = React.useCallback(currentOptionId => {
    const newGoogleUserCurrentAccount = googleUserAccountsData.find(account => account.id === currentOptionId);
    if (newGoogleUserCurrentAccount && locationConnectionInfo) {
      setLocationConnectionInfo(
        {
          ...locationConnectionInfo,
          grouping_name: newGoogleUserCurrentAccount.name,
        },
      );
    }
    setGoogleUserCurrentAccounts(newGoogleUserCurrentAccount);
  }, [googleUserAccountsData]);

  const handleResetDropDownDialog = React.useCallback(async () => {
    setGooglePaginationPageList(['']);
    getGoogleAccessToken();
  }, []);

  const handleGoogleLogout = React.useCallback(() => {
    setGoogleAccessToken('');
    setGoogleUserAccountsData([]);
    setExpanded(false);
    setAddConnectionIsSelected(false);
    setViewConnectionsIsSelected(false);
  }, []);

  const handleGoogleLogin = React.useCallback(async (loginResponse: any) => {
    const { code } = loginResponse;

    const tokenData = await APIGenerateRefreshToken({ authorizationCode: code });

    const {
      access_token: responseAccessToken,
      refresh_token: refreshToken,
      expires_in: expiresIn,
    } = tokenData;

    const googleProfile = await getAccountData({ accessToken: responseAccessToken });

    const tokenExpirationDate = new Date();

    tokenExpirationDate.setSeconds(tokenExpirationDate.getSeconds() + expiresIn);

    const connectionInfoToUpdate = {
      token: responseAccessToken,
      code,
      token_expires_in: tokenExpirationDate.toUTCString(),
      refresh_token: refreshToken,
      email: googleProfile.email,
      grouping_name: googleUserCurrentAccount?.name || 'Desagrupada',
    };

    if (refreshToken) {
      await APIUpdateUserProfile({
        accessToken: userAccessToken,
        id: userId,
        updateProfileData: {
          google_refresh_token: refreshToken,
        },
      });

      reduxDispatch(AuthActions.setUser({
        ...userMainData,
        user: {
          ...userMainData.user,
          google_refresh_token: refreshToken,
        },
      }));
    }

    const [
      googleConnectionsData,
      googleConnectionsAmount,
    ] = await APIGetUserConnections({
      accessToken: userAccessToken,
      query: 'google',
      userId,
    });

    if (googleConnectionsAmount > 0) {
      await APIUpdateConnection({
        accessToken: userAccessToken,
        connectionId: googleConnectionsData[0].id,
        inputData: connectionInfoToUpdate,
        setTokenLikeExpired: userSetTokenLikeExpired,
      });
    } else {
      await APICreateConnection({
        accessToken: userAccessToken,
        userId,
        channel: 'google',
        code: connectionInfoToUpdate.code,
        token: connectionInfoToUpdate.token,
        refreshToken,
        tokenExpiresIn: connectionInfoToUpdate.token_expires_in,
        setTokenLikeExpired: userSetTokenLikeExpired,
        createdBy: userId,
      });
    }

    setLocationConnectionInfo(connectionInfoToUpdate);
    getGoogleUserAccountsData(responseAccessToken, '');
    setGoogleAccessToken(responseAccessToken);
  }, []);

  const handleNextPageUserAccount = React.useCallback(async () => {
    getGoogleUserAccountsData(googleAccessToken, googleUserCurrentAccountNextPage);
  }, [googleAccessToken, googleUserCurrentAccountNextPage]);

  const handleBackPageUserAccount = React.useCallback(async () => {
    const removeLastPositionFromList = googlePaginationPageList.pop();
    if (isNil(removeLastPositionFromList)) return;

    const lastTokenFromList = last(googlePaginationPageList);
    if (isNil(lastTokenFromList)) return;

    getGoogleUserAccountsData(googleAccessToken, lastTokenFromList);
  }, [googleAccessToken, googlePaginationPageList]);

  const isAdminOrOwner = React.useMemo(() => {
    if (userProfileName !== 'Usuário Padrão') return true;
    if (!isNil(activeCompany) && activeCompany.owner_user.id === userId) return true;
    return false;
  }, [userProfileName, activeCompany]);

  React.useEffect(() => {
    if (isAdminOrOwner) return;
    setExpanded(false);
  }, [isAdminOrOwner]);

  const getLocationConnections = React.useCallback(async () => {
    const [locationConnections] = await APIGetLocationConnections({
      accessToken: userAccessToken,
      locationId: activeLocation.id,
    });

    if (isNil(locationConnections)) return;

    setLocationConnectionList(locationConnections);
  }, [activeLocation]);

  React.useEffect(() => {
    getLocationConnections();
  }, [activeLocation]);

  return (
    <StyledGoogle expanded={expanded}>
      <StyledHeader>
        {loadGoogleAccessToken && <span>Carregando informações...</span>}

        {(!loadGoogleAccessToken && isNil(activeLocation)) && <p>Nenhum local ativo encontrado!</p>}

        {(!isNil(activeCompany)) && !isAdminOrOwner && <p>Seu perfil não possui permissão para alterar conexões desse local</p>}

        {(!loadGoogleAccessToken && !isNil(activeLocation)) && isAdminOrOwner && (
          <>
            <StyledHeaderLogoWrapper>
              <StyledGoogleIcon />
              <>
                <StyledIconLabelBold>Google</StyledIconLabelBold>
                <StyledIconLabel>Perfil da Empresa</StyledIconLabel>
              </>
            </StyledHeaderLogoWrapper>

            <StyledActionsWrapper>
              <StyledAddConnectionsButton
                selected={addConnectionIsSelected}
                onClick={handleClickAddConnection}
              >
                Adicionar conexão
              </StyledAddConnectionsButton>
              <StyledShowConnectionsButton
                selected={viewConnectionsIsSelected}
                onClick={handleClickViewConnections}
              >
                Ver conexões
              </StyledShowConnectionsButton>
            </StyledActionsWrapper>
          </>
        )}
      </StyledHeader>

      {isEmpty(googleUserAccountsData) && expanded && !loadGoogleAccessToken && (
        <StyledNoAccountLinkedWrapper>
          <b>Você ainda não possui nenhuma conta vinculada.</b>
          <p>Conecte-se com uma conta Google para ter acesso a listagem dos Locais e, em seguida, prosseguir com as conexões.</p>
          <GoogleLogin
            clientId={String(process.env.REACT_APP_GOOGLE_CLIENT_ID)}
            onSuccess={handleGoogleLogin}
            onFailure={() => {
              setGoogleUserAccountsLoading(false);
            }}
            scope="https://www.googleapis.com/auth/business.manage"
            prompt="consent"
            responseType="code"
            uxMode="redirect"
            redirectUri="https://saas.hublocal.com.br/receive-foursquare-code"
            render={(renderProps) => (googleUserAccountsLoading ? <Loading /> : (
              <StyledConnectButton onClick={renderProps.onClick}>Conectar</StyledConnectButton>
            ))}
          />

        </StyledNoAccountLinkedWrapper>
      )}

      {!isEmpty(googleUserAccountsData) && expanded && addConnectionIsSelected && (
        <StyledContent>
          <StyledConfigContent>
            {!isNil(locationConnectionInfo)
              && (
                <GoogleLoggedAccount
                  handleGoogleLogout={handleGoogleLogout}
                  loggedGoogleEmail={locationConnectionInfo.email}
                />
              )}
            <DropDownDialog
              classNameDropDownContainer="dropdown-google-accounts"
              title="Agrupamentos"
              icon="Business"
              currentOption={googleUserCurrentAccount}
              options={googleUserAccountsData}
              googlePagination
              onResetClicked={handleResetDropDownDialog}
              nextPageToken={googleUserCurrentAccountNextPage}
              onNextPageClicked={handleNextPageUserAccount}
              onBackPageClicked={handleBackPageUserAccount}
              onChange={handleOnChangeDropDownDialog}
              googlePaginationLoading={googleUserAccountsLoading}
              pageList={googlePaginationPageList}
              setPageList={setGooglePaginationPageList}
            />
          </StyledConfigContent>

          {!isNil(googleUserCurrentAccount) && !isNil(locationConnectionInfo) && (
            <LocationList
              data={googleUserLocations}
              locationsFrom={googleUserCurrentAccount}
              getGoogleUserLocations={getGoogleUserAccountLocations}
              initialDataToken={googleUserLocationsPage}
              googleAccessToken={googleAccessToken}
              userAccessToken={userAccessToken}
              userId={userId}
              userSetTokenLikeExpired={userSetTokenLikeExpired}
              pageList={googlePaginationLocationPageList}
              setPageList={setGooglePaginationLocationPageList}
              locationConnectionInfo={locationConnectionInfo}
              isAdminOrOwner={isAdminOrOwner}
              locationConnectionList={locationConnectionList}
              setLocationConnectionList={setLocationConnectionList}
            />
          )}
        </StyledContent>
      )}

      {!isEmpty(googleUserAccountsData) && expanded && viewConnectionsIsSelected && (
        <LocationConnectionList
          userAccessToken={userAccessToken}
          userProfileName={userProfileName}
          userId={userId}
          setLocationConnectionList={setLocationConnectionList}
          locationConnections={locationConnectionList}
          getLocationConnections={getLocationConnections}
        />
      )}
    </StyledGoogle>
  );
};

export default Google;
