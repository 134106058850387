import React from 'react';
import { useSelector } from 'react-redux';

import {
  isEmpty, isNil, find, propEq,
} from 'ramda';

import APIGetLocationLinkedWithGoogle from '../../../services/locations/getCompanyLocationsLinkedWithGoogle';
import APIGetLocationsGroup from '../../../services/locations/getAllGroupLocation';

import { TLocationEntity } from '../../../types/TLocation';
import { TRootStateRedux } from '../../../types/TRootStateRedux';

import { useReviewsContext } from '../../../contexts/reviews';

import { useAuth, useHasAccess } from '../../../hooks';
import isNilOrEmpty from '../../../utils/is-null-or-empty';
import isEllebigleLocationForGuest from '../../../utils/is-ellegible-location-for-guest';

import {
  IconButton,
  SelectDropdown,
} from '../../../components';

import SectionHeader from '../section-header';

import {
  StyledLocationsFilter,
  StyledContent,
  StyledLocationsToFilterItem,
  StyledLocationsToFilter,
  StyledSpan,
  StyledLocationFilterWrapper,
} from './locations-filter-styles';

const LocationsFilters = () => {
  const { setLocationsToFilter, locationsToFilter } = useReviewsContext();
  const {
    userId,
    userProfileName,
    userAccessToken,
    userSetTokenLikeExpired,
  } = useAuth();

  const { guestType } = useHasAccess();

  const { showLocationsByGroup, activeLocationGroupId } = useSelector(
    (state: TRootStateRedux) => state.LocationGroupReducer,
  );
  const { showLocationsByCompany, activeCompanyId } = useSelector(
    (state: TRootStateRedux) => state.CompanyReducer,
  );

  const [locationsFilterData, setLocationsFilterData] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [pageSize, setPageSize] = React.useState(10);
  const [query, setQuery] = React.useState('');
  const [pageQuantity, setPageQuantity] = React.useState(0);
  const [isLoading, setIsLoading] = React.useState(true);

  const getLocationsGroupLinkedWithGoogle = React.useCallback(async () => {
    if (!showLocationsByGroup) return;

    const getLocationsGroupResponse = await APIGetLocationsGroup({
      accessToken: userAccessToken,
      page,
      query,
      pageSize,
      setIfFetching: setIsLoading,
      setTokenLikeExpired: userSetTokenLikeExpired,
      locationGroupId: activeLocationGroupId,
    });

    if (isNil(getLocationsGroupResponse)) return;

    const [locationsGroupData, locationsGroupCount] = getLocationsGroupResponse;

    setLocationsFilterData(locationsGroupData);
    setPageQuantity(Math.ceil(locationsGroupCount / pageSize));
  }, [showLocationsByGroup, pageSize, page, query, activeLocationGroupId]);

  React.useEffect(() => {
    getLocationsGroupLinkedWithGoogle();
  }, [getLocationsGroupLinkedWithGoogle]);

  const getCompanyLocationsLinkedWithGoogle = React.useCallback(async () => {
    if (!showLocationsByCompany) return;

    const getCompanyLocationsResponse = await APIGetLocationLinkedWithGoogle({
      accessToken: userAccessToken,
      page,
      query,
      pageSize,
      setIfFetching: setIsLoading,
      setTokenLikeExpired: userSetTokenLikeExpired,
      companyId: activeCompanyId,
    });

    if (isNil(getCompanyLocationsResponse)) return;

    const [companyLocationsData, companyLocationsCount] = getCompanyLocationsResponse;

    const filteredData = companyLocationsData.filter((location: any) => !isNilOrEmpty(location.google_location_id)
      && isEllebigleLocationForGuest({
        location,
        userId,
        guestType,
        userProfileName,
      }));

    setLocationsFilterData(filteredData);
    setPageQuantity(Math.ceil(companyLocationsCount / pageSize));
  }, [
    showLocationsByCompany,
    page,
    pageSize,
    query,
    activeCompanyId,
    userId,
    guestType,
    userProfileName,
    userAccessToken,
  ]);

  React.useEffect(() => {
    getCompanyLocationsLinkedWithGoogle();
  }, [getCompanyLocationsLinkedWithGoogle]);

  const handleNewlocationstofilter = React.useCallback(
    ({ google_location_id: googleLocationId, name }: TLocationEntity) => {
      setLocationsToFilter((prevState) => {
        const hasLocationInLocationsToFilter = Boolean(
          find(propEq('googleLocationId', googleLocationId), prevState),
        );

        if (hasLocationInLocationsToFilter) return prevState;

        return [
          ...prevState,
          {
            googleLocationId,
            name,
          },
        ];
      });
    },
    [],
  );

  const handleRemoveLocationToFilter = React.useCallback(
    (locationToFilterId) => {
      setLocationsToFilter((prevState) => prevState.filter(
        (locationToFilter) => locationToFilter.googleLocationId !== locationToFilterId,
      ));
    },
    [],
  );

  return (
    <StyledLocationsFilter>
      <SectionHeader title="Locais" />

      <StyledContent>
        <StyledLocationFilterWrapper>
          <SelectDropdown
            selectedOptionsHidden
            className="location-filter__search"
            data={locationsFilterData}
            idKey="id"
            page={page}
            setPage={setPage}
            pageQuantity={pageQuantity}
            setQuery={setQuery}
            searchLabel="Pesquisar locais"
            onOptionClicked={handleNewlocationstofilter}
            isLoading={isLoading}
            textKey="name"
          />
        </StyledLocationFilterWrapper>

        <StyledLocationsToFilter>
          {!isEmpty(locationsToFilter) ? (
            locationsToFilter.map((location) => (
              <StyledLocationsToFilterItem key={location.googleLocationId}>
                {location.name}
                <IconButton
                  onClick={() => handleRemoveLocationToFilter(location.googleLocationId)}
                  className="location-to-filter-remove"
                  tooltip="Remover"
                  icon="Close"
                  isWhite
                />
              </StyledLocationsToFilterItem>
            ))
          ) : (
            <StyledSpan>Nenhum filtro aplicado!</StyledSpan>
          )}
        </StyledLocationsToFilter>
      </StyledContent>
    </StyledLocationsFilter>
  );
};

export default LocationsFilters;
