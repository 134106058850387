import React from 'react';
import * as R from 'ramda';

export const addTagOwnerOrGuest = ({ locationGroup, userId }: any) => {
  if (R.isNil(locationGroup)) return '';

  if (locationGroup.owner_user.id === userId) {
    return (<span className="tag owner">Proprietário</span>);
  }

  const findGuest = locationGroup.guests.find((guest: any) => guest.id === userId);

  if (findGuest !== -1) return (<span className="tag guest">Convidado</span>);
};
