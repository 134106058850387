import axios from 'axios';

export default async function removeFunctions(
  accessToken,
  groupId,
  function_ids,
) {
  const requestOptions = {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  };

  try {
    const { data } = await axios.post(
      `${process.env.REACT_APP_GROUPS_API_URL}/remove/functions/${groupId}`,
      { function_ids },
      requestOptions,
    );

    console.log(data);
    return data;
  } catch (err) {
    console.log(err);
    return err;
  }
}
