import styled, { css } from 'styled-components';
import { shade } from 'polished';

export const StyledHeader = styled.header<{ specialDateIsOpen: boolean; }>`
  display: grid;
  grid-template-columns: 45% 18% 10% 10% 12%;
  column-gap: 1rem;
  width: 100%;

  ${props => props.specialDateIsOpen && css`
    margin-bottom: 2rem;
  `};
  
  .is-24-hours {
    margin: auto;
    transform: scale(1) !important;
  }
`;

export const StyledSpecialDatesRow = styled.li`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 2rem 2rem 4rem;
  border-bottom: 0.1rem dashed ${props => shade(0.1, props.theme.colors.white)};
  margin-bottom: 2rem;
  position: relative;
  
  &:last-child {
    margin-bottom: 0;
    border-bottom: 0;
  }
`;

export const StyledSpecialDatesRowTitle = styled.h3`
  color: ${props => props.theme.colors.primary};
  margin-bottom: 0;
  display: flex;
  align-items: center;
`;

export const StyledSpecialDatesRowDate = styled.div`
  display: flex;
  align-items: center;
  margin: auto;
`;

export const StyledNoWorkingHours = styled.span`
  font-size: 1.2rem;
  color: ${props => props.theme.colors.red};
  font-weight: 500;
  margin-top: 1rem;
`;

export const StyledHeaderActions = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  grid-area: 1/5;
`;

export const StyledIsOpen = styled.div<{ isOpen: boolean; }>`
  margin: auto;
  font-weight: 500;
  font-size: 1.2rem;
  border-radius: 3rem;
  text-transform: uppercase;
  padding: 0.5rem 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${props => (props.isOpen ? props.theme.colors.secondary : props.theme.colors.red)};
  color: ${props => props.theme.colors.white};
`;
