import * as R from 'ramda';

export const starRatingState = {
  currentStarRating: {
    word: null,
    rateNumber: 0,
  },
  starRating: [
    { word: 'ONE', rateNumber: 1 },
    { word: 'TWO', rateNumber: 2 },
    { word: 'THREE', rateNumber: 3 },
    { word: 'FOUR', rateNumber: 4 },
    { word: 'FIVE', rateNumber: 5 },
  ],
};

export const listAutomaticResponsesByState = {
  currentListBy: 'all',
  listAutomaticResponsesBy: [
    { value: 'all', name: 'Todas' },
    { value: 'answered', name: 'Respondidas' },
    { value: 'unanswered', name: 'Não Respondidas' },
  ],
};

export const isReview = (reviews) => {
  if (R.isNil(reviews)) return false;
  if (R.isEmpty(reviews)) return false;
  return true;
};

export const isDefaultUser = ({ name }) => name === 'Usuário Padrão';
