import React from 'react';

import {
  Input, UncontrolledField,
} from '../../../../components';

import { useUncontrolledField } from '../../../../hooks';

import type { TLinks } from '../../../../types/TBatchUpdate';

import {
  websiteScheme,
  facebookScheme,
  instagramScheme,
  twitterScheme,
  youtubeScheme,
  foursquareScheme,
  linkedinScheme,
  yelpScheme,
  advanceOrderScheme,
  scheduleScheme,
  menuScheme,
  reservationsScheme,
} from './fields-scheme';

import {
  StyledLinks,
  StyledSectionTitle,
  StyledInputGroup,
} from './links-styles';

const Links = ({
  linksFields,
  setLinksFields,
  otherLinksFields,
  setOtherLinksFields,
}: TLinks) => {
  const { fieldProps: websiteFieldProps } = useUncontrolledField({
    fieldScheme: { ...websiteScheme },
    saveIn: setLinksFields,
  });

  const { fieldProps: facebookFieldProps } = useUncontrolledField({
    fieldScheme: { ...facebookScheme },
    saveIn: setLinksFields,
  });

  const { fieldProps: instagramFieldProps } = useUncontrolledField({
    fieldScheme: { ...instagramScheme },
    saveIn: setLinksFields,
  });

  const { fieldProps: twitterFieldProps } = useUncontrolledField({
    fieldScheme: { ...twitterScheme },
    saveIn: setLinksFields,
  });

  const { fieldProps: youtubeFieldProps } = useUncontrolledField({
    fieldScheme: { ...youtubeScheme },
    saveIn: setLinksFields,
  });

  const { fieldProps: foursquareFieldProps } = useUncontrolledField({
    fieldScheme: { ...foursquareScheme },
    saveIn: setLinksFields,
  });

  const { fieldProps: linkedinFieldProps } = useUncontrolledField({
    fieldScheme: { ...linkedinScheme },
    saveIn: setLinksFields,
  });

  const { fieldProps: yelpFieldProps } = useUncontrolledField({
    fieldScheme: { ...yelpScheme },
    saveIn: setLinksFields,
  });

  const { fieldProps: advanceOrderFieldProps } = useUncontrolledField({
    fieldScheme: { ...advanceOrderScheme },
    saveIn: setOtherLinksFields,
  });

  const { fieldProps: scheduleFieldProps } = useUncontrolledField({
    fieldScheme: { ...scheduleScheme },
    saveIn: setOtherLinksFields,
  });

  const { fieldProps: menuFieldProps } = useUncontrolledField({
    fieldScheme: { ...menuScheme },
    saveIn: setOtherLinksFields,
  });

  const { fieldProps: reservationsFieldProps } = useUncontrolledField({
    fieldScheme: { ...reservationsScheme },
    saveIn: setOtherLinksFields,
  });

  return (
    <StyledLinks>
      <StyledSectionTitle>Links Principais</StyledSectionTitle>

      <StyledInputGroup>
        <UncontrolledField {...websiteFieldProps} />
        <UncontrolledField {...facebookFieldProps} />
        <UncontrolledField {...instagramFieldProps} />
        <UncontrolledField {...twitterFieldProps} />
        <UncontrolledField {...youtubeFieldProps} />
        <UncontrolledField {...foursquareFieldProps} />
        <UncontrolledField {...linkedinFieldProps} />
        <UncontrolledField {...yelpFieldProps} />
      </StyledInputGroup>

      <StyledSectionTitle>Outros Links</StyledSectionTitle>

      <StyledInputGroup>
        <UncontrolledField {...menuFieldProps} />
        <UncontrolledField {...scheduleFieldProps} />
        <UncontrolledField {...advanceOrderFieldProps} />
        <UncontrolledField {...reservationsFieldProps} />
      </StyledInputGroup>
    </StyledLinks>
  );
};

export default Links;
