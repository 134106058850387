import axios from 'axios';

export default async function createProfile(
  accessToken,
  name,
  description,
  menu_id,
  function_ids,
  created_by = null,
) {
  const requestOptions = {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  };

  try {
    const { data } = await axios.post(
      `${process.env.REACT_APP_PROFILES_API_URL}`,
      created_by
        ? {
          name, description, menu_id, function_ids, created_by,
        }
        : {
          name, description, menu_id, function_ids,
        },
      requestOptions,
    );

    return data;
  } catch (err) {
    return err;
  }
}
