import styled, { css } from 'styled-components';
import { shade, lighten } from 'polished';

export const StyledDropDownTimes = styled.div`
    display: flex;
    flex-direction: column;
    position: absolute;
    width: 20rem;
    background: ${props => props.theme.colors.white};
    max-height: 20rem;
    overflow-y: scroll;
    z-index: 2;
    top: 3.3rem;
    border: 1px solid ${props => lighten(0.90, props.theme.colors.black)};
    border-radius: 0.5rem;

    ${props => props.dropDownToBottom && css`
        bottom: 3.5rem;
        top: unset;
    `};
`;

export const StyledItem = styled.div`
    display: flex;
    align-items: center;
    padding: 2rem;
    color: ${props => props.theme.colors.primary};
    font-weight: bold;
    cursor: pointer;
    transition: 0.3s;

    &:hover {
        background: ${props => props.theme.colors.primary} !important;
        color: ${props => props.theme.colors.white} !important;
    }
    
    &:nth-child(even) {
        background: ${props => shade(0.05, props.theme.colors.white)};
    }
`;
