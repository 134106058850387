import styled from 'styled-components';
import { shade } from 'polished';

export const StyledGalleryBatchLocationsTable = styled.div`
  display: flex;
  width: 100%;
  padding: 2rem;
  background: ${(props) => shade(0.1, props.theme.colors.white)};
`;

export const StyledHeader = styled.header`
  display: flex;
  flex-direction: column;
  padding: 2rem 0 0 2rem;
`