import styled from 'styled-components';

export const StyledSection = styled.section`
  width: 100%;
  padding: 1rem 2rem;

  background-color: ${props => props.theme.colors.primary};
  color: ${props => props.theme.colors.white};

  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const StyledBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;

  h2 {
    margin-bottom: 0;
  }
`;
