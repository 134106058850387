import React from 'react';
import * as R from 'ramda';
import { useSnackbar } from 'notistack';
import { Grid } from '@material-ui/core';

import { useAuth, useLocationTickets } from '../../../hooks';

import APIUpdateTicket from '../../../services/tickets/update';
import getPost from '../../../services/google/post/getPost';
import deletePost from '../../../services/google/post/deletePost';

import type { TPostList, TPostListView } from '../../../types/TLocationPost';
import type { TPostCard, TPostDataTable } from '../../../types/TPostCard';

import PostCard from './post-card';
import PostTable from './post-table';

import { ConfirmDialog } from '../../../components';

import { StyledPostListDiv } from './post-list-styles';

const PostList = ({
  data,
  view = 'table',
  getGooglePosts,
  setLoading,
  setPostInfoToUpdate,
  setOpenCreatePostDialog,
  setPostDialogLoading,
  activeLocation,
  googleAccessToken,
}: TPostList) => {
  const {
    userId,
    userAccessToken,
    userProfile,
    userSetTokenLikeExpired,
  } = useAuth();
  const { enqueueSnackbar } = useSnackbar();

  const { locationPostOpenTickets } = useLocationTickets();

  const [openDeletePostDialog, setOpenDeletePostDialog] = React.useState(false);
  const [currentPostToDeleteData, setCurrentPostToDeleteData] = React.useState<TPostDataTable | null>(null);

  const handleOpenDeletePostDialog = (post: TPostDataTable) => {
    setOpenDeletePostDialog(true);
    setCurrentPostToDeleteData(post);
  };

  const handleCloseDeletePostDialog = () => {
    setOpenDeletePostDialog(false);
  };

  const hasOpenTicket = React.useCallback(async (postNameId: string) => {
    const thisPostOpenTicket = locationPostOpenTickets.find(ticket => {
      const { post_id } = ticket.data;

      return post_id === postNameId;
    });

    if (!R.isNil(thisPostOpenTicket)) return thisPostOpenTicket;

    return null;
  }, [locationPostOpenTickets]);

  const handleDeletePost = React.useCallback(async (postToDelete: TPostDataTable | null) => {
    if (R.isNil(postToDelete)) return;
    if (R.isNil(googleAccessToken)) return;

    const postNameId = postToDelete[9];

    const deleteCurrentPost = async () => {
      setLoading(true);

      await deletePost({
        accessToken: googleAccessToken,
        postId: postNameId.replaceAll('/', '%2F'),
      });

      getGooglePosts(activeLocation);

      enqueueSnackbar('Postagem excluída com sucesso', { variant: 'success' });
    };

    const hasOpenedTicketResponse = await hasOpenTicket(postNameId);

    if (!R.isNil(hasOpenedTicketResponse)) {
      const {
        id,
        data: ticketData,
      } = hasOpenedTicketResponse;

      const platformsSyncDoneStatus = {
        google: 'DONE',
        facebook: 'DONE',
        foursquare: 'DONE',
        yelp: 'DONE',
        linkedin: 'DONE',
      };

      const closeTicketResponse = await APIUpdateTicket({
        accessToken: userAccessToken,
        ticketId: id,
        dataToUpdate: {
          data: {
            ...ticketData,
            status: platformsSyncDoneStatus,
          },
          status: 'DONE',
          operator_id: userId,
        },
        setTokenLikeExpired: userSetTokenLikeExpired,
        setIsFetching: null,
      });

      if (R.isNil(closeTicketResponse)) {
        enqueueSnackbar(
          'Não foi possível deletar a postagem. Tente novamente!',
          { variant: 'warning' },
        );
      }

      deleteCurrentPost();
    } else {
      deleteCurrentPost();
    }
  }, [locationPostOpenTickets, activeLocation]);

  const loadPostDataToUpdate = async (postId: string) => {
    if (R.isNil(googleAccessToken)) return;
    setOpenCreatePostDialog(true);
    setPostDialogLoading(true);

    const post = await getPost({ accessToken: googleAccessToken, postId: postId.replaceAll('/', '%2F') });

    setPostInfoToUpdate(post);
    setPostDialogLoading(false);
  };

  return (
    <StyledPostListDiv>
      <ConfirmDialog
        open={openDeletePostDialog}
        onClose={handleCloseDeletePostDialog}
        title="Confirmar Exclusão"
        onCancel={handleCloseDeletePostDialog}
        onConfirm={() => handleDeletePost(currentPostToDeleteData)}
        confirmTitle="Deletar"
        confirmType="red"
      >
        Tem certeza que deseja excluir essa postagem?
      </ConfirmDialog>

      <Grid container spacing={3}>
        {(view === 'cards' as TPostListView) && data.map((element: any) => (
          <Grid item>
            <PostCard
              state={element[2]}
              summary={element[1]}
              media={element[0]}
              createTime={element[3]}
              callToAction={element[6]}
              event={element[7]}
              alertType={element[5]}
              offer={element[4]}
              topicType={element[8]}
              name={element[9]}
              onDeleteClicked={() => handleOpenDeletePostDialog(element)}
              onEditClicked={() => loadPostDataToUpdate(element[9])}
              phone={activeLocation.phone}
            />
          </Grid>
        ))}
      </Grid>
      {(view === 'table' as TPostListView) && (
        <PostTable
          data={data}
          onUpdateClicked={loadPostDataToUpdate}
          onDeleteClicked={handleDeletePost}
        />
      )}
    </StyledPostListDiv>
  );
};

export default PostList;
