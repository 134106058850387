import styled from 'styled-components';
import { Paper } from '@material-ui/core';

import { devices } from '../../../assets/styles/devices';

export const StyledLocationGalleryFilters = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 1rem;

  .add-gallery-midia {
    margin-bottom: 2rem;
    margin-left: auto;
  }
`;

export const StyledContentFilter = styled(Paper)`
  padding: 2rem;
  display: flex;
  flex-direction: column;

  @media ${devices.desktop} {
    display: grid;
    grid-template-columns: 20% 20% 20% 35%;
    column-gap: 2rem;
  }

  @media ${devices.widescreen} {
    grid-template-columns: repeat(4, 1fr);
  }

  .select-filter {
    @media ${devices.desktop} {
      margin-bottom: 0;
    }
  }
`;

export const StyledDatesWrapper = styled.div`
  display: flex;
  flex-direction: column;

  @media ${devices.desktop} {
    flex-direction: row;
  }

  .input-date-filter {
    @media ${devices.desktop} {
      margin-bottom: 0;
      margin-right: 2rem;
    }

    &:last-child {
      @media ${devices.desktop} {
        margin-right: 0;
      }
    }
  }
  
  .input-date-picker {
    margin-bottom: 2rem;

    @media ${devices.desktop} {
      margin-right: 2rem;
      margin-bottom: 0;
    }
    
    &:last-of-type {
      margin-right: 0;
    }
  }
`;

export const StyledAddMedia = styled.span`

`;
