import axios from 'axios';
import type { TGetUserLocationGroupsWithGuests } from '../../types/TLocationGroup';

export default async function getUserLocationGroupsWithGuests({
  accessToken,
  userId,
  page = 0,
  pageSize = 20,
  query = '',
  setTokenLikeExpired,
  setIsLoading,
}: TGetUserLocationGroupsWithGuests) {
  try {
    if (setIsLoading) setIsLoading(true);

    const { data } = await axios.get(
      `${process.env.REACT_APP_LOCATION_GROUPS_API_URL}/user-groups-with-guests/${userId}?page=${page}&pageSize=${pageSize}&query=${query}`,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      },
    );

    return data;
  } catch (err: any) {
    if (err.response?.status === 401 && setTokenLikeExpired) setTokenLikeExpired();

    return null;
  } finally {
    if (setIsLoading) setIsLoading(false);
  }
}
