import React, { FormEvent, useEffect, useState } from 'react';
import { debounce } from 'lodash';
import { useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';
import * as R from 'ramda';

import {
  Checklist,
  Loading,
  Select,
  Button,
  Input,
  CurrencyInput,
  Textarea,
} from '../../../components';

import {
  StyledCloseIcon,
  StyledServiceBatchForm,
  StyledDialogBody,
  StyledDialogHeader,
  StyledDialogForm,
  StyledInputWithCounterWrapper,
  LoadingContainer,
  StyledTextCounter,
} from './service-batch-form-styles';

import { billingTypes, constants } from './helpers';

import APICreateBatchService from '../../../services/batch-update/batchCreateService';

import { useAuth } from '../../../hooks';
import { ListLocations } from '../../../utils/list-locations';
import { formatServicePriceValue } from '../../location-services/create-service-dialog/helpers';

import type { TChecklistType } from '../../../types/TChecklist';
import type { TRootStateRedux } from '../../../types/TRootStateRedux';
import type {
  TBillingType,
  TServiceItemSaaS,
} from '../../../types/TLocationService';

import { EGroupType } from '../../../types/TLocation';
import { TServiceBatchFormProps } from '../../../types/TServiceBatch';

const ServiceBatchForm = ({
  open = false,
  closeModal,
}: TServiceBatchFormProps) => {
  const {
    userAccessToken, userId, userName, userEmail,
  } = useAuth();
  const { enqueueSnackbar } = useSnackbar();

  const [locationsList, setLocationsList] = useState([]);
  const [locationsListNoEligible, setLocationsListNoEligible] = useState(0);
  const [locationsListTotalLength, setLocationsListTotalLength] = React.useState(0);
  const [locationsListPageIndex, setLocationsListPageIndex] = React.useState(0);
  const [locationsListPageSize] = React.useState(10);
  const [locationsListPageQuantity, setLocationsListPageQuantity] = useState(0);

  const [serviceName, setServiceName] = React.useState('');
  const [billingType, setBillingType] = React.useState<TBillingType>('Sem Preço');
  const [servicePrice, setServicePrice] = React.useState<string | undefined>(
    '',
  );
  const [serviceDescription, setServiceDescription] = React.useState('');

  const [masterCheckbox, setMasterCheckbox] = React.useState(true);
  const [locationsListQuery, setLocationsListQuery] = React.useState('');
  const [locationsToExclude, setLocationsToExclude] = React.useState<TChecklistType>({
    type: 'EXCLUDED',
    ids: [],
  });

  const [isLoading, setIsLoading] = React.useState(false);

  const { activeLocationGroupId } = useSelector(
    (state: TRootStateRedux) => state.LocationGroupReducer,
  );
  const { activeCompanyId, showLocationsByCompany } = useSelector(
    (state: TRootStateRedux) => state.CompanyReducer,
  );

  const platformOptions = [{ id: 1, name: 'Google' }];

  const { locations, locationsNoEligibles, isFetching } = ListLocations({
    accessToken: userAccessToken,
    page: locationsListPageIndex,
    query: locationsListQuery,
  });

  const handleSearchLocation = (locationName: string) => {
    setLocationsListPageIndex(0);
    setLocationsListQuery(locationName);
  };

  const handleInputSearch = debounce(
    (value) => handleSearchLocation(value),
    500,
  );

  const handleSubmit = React.useCallback(
    async (event: FormEvent) => {
      event.preventDefault();

      setIsLoading(true);
      let status: 'SUCCESS' | 'ERROR' = 'ERROR';
      try {
        const { SERVICE_NAME_MAX_LENGTH, SERVICE_DESCRIPTION_MAX_LENGTH } = constants;

        if (R.isEmpty(serviceName)) {
          return enqueueSnackbar('Nome do serviço é obrigatório', {
            variant: 'error',
          });
        }

        if (serviceName.length >= SERVICE_NAME_MAX_LENGTH) {
          return enqueueSnackbar(
            `O nome do serviço deve ter menos de ${SERVICE_NAME_MAX_LENGTH} caracteres`,
            { variant: 'error' },
          );
        }

        if (serviceDescription.length >= SERVICE_DESCRIPTION_MAX_LENGTH) {
          return enqueueSnackbar(
            `A descrição do serviço deve ter menos de ${SERVICE_DESCRIPTION_MAX_LENGTH} caracteres`,
            { variant: 'error' },
          );
        }

        const serviceItemInfo: TServiceItemSaaS = {
          freeFormServiceItem: {
            label: {
              displayName: serviceName,
              languageCode: 'pt',
              description: R.isEmpty(serviceDescription)
                ? undefined
                : serviceDescription,
            },
          },
        };

        if (billingType !== 'Sem Preço') {
          serviceItemInfo.price = formatServicePriceValue(
            billingType === 'Fixo' ? servicePrice : '0',
            billingType,
          );
        }

        const serviceBatch = await APICreateBatchService({
          groupType: showLocationsByCompany
            ? EGroupType.COMPANY
            : EGroupType.LOCATIONS_GROUP,
          groupId: showLocationsByCompany
            ? activeCompanyId
            : activeLocationGroupId,
          accessToken: userAccessToken,
          serviceItemInfo,
          locations: locationsToExclude,
          userData: {
            user_id: userId,
            user_name: userName,
            user_email: userEmail,
          },
          feedbackMessage: enqueueSnackbar,
        });

        if (!R.isNil(serviceBatch)) status = 'SUCCESS';
      } finally {
        setIsLoading(false);
        if (status === 'SUCCESS') closeModal(true);
      }
    },
    [
      serviceName,
      serviceDescription,
      servicePrice,
      billingType,
      showLocationsByCompany,
      userAccessToken,
      locationsToExclude,
      activeCompanyId,
      activeLocationGroupId,
      userId,
      userName,
      userEmail,
    ],
  );

  useEffect(() => {
    setLocationsList(locations[0]);
    setLocationsListTotalLength(locations[1]);
    setLocationsListNoEligible(locationsNoEligibles.count);
    setLocationsListPageQuantity(
      Math.ceil(locations[1] / locationsListPageSize),
    );
  }, [locations, locationsNoEligibles]);

  return (
    <StyledServiceBatchForm open={open} fullWidth maxWidth="md">
      <StyledDialogHeader>
        <h2>Adicionar um serviço</h2>

        <StyledCloseIcon onClick={() => closeModal(false)} />
      </StyledDialogHeader>

      <StyledDialogBody>
        <StyledDialogForm>
          <Select
            className="form-input"
            label="Plataforma"
            options={platformOptions}
            name="platform"
            disabled
          />

          <StyledInputWithCounterWrapper>
            <Input
              border
              value={serviceName}
              label="Nome do serviço *"
              onChange={({ target: { value } }) => {
                setServiceName(value);
              }}
              borderAlert={
                serviceName.length >= constants.SERVICE_NAME_MAX_LENGTH
              }
            />
            <StyledTextCounter
              count={serviceName.length >= constants.SERVICE_NAME_MAX_LENGTH}
            >
              {serviceName.length}
              /
              {constants.SERVICE_NAME_MAX_LENGTH}
            </StyledTextCounter>
          </StyledInputWithCounterWrapper>

          <Select
            name="billing"
            className="form-input"
            label="Tipo de Cobrança *"
            value={billingType}
            onChange={({ target: { value } }) => setBillingType(value as TBillingType)}
            border
            options={billingTypes}
          />

          {billingType === 'Fixo' && (
            <div className="form-input">
              <CurrencyInput
                id="currency-input"
                label="Preço do Serviço"
                value={servicePrice}
                disabled={billingType !== 'Fixo'}
                integerLimit={12}
                decimalsLimit={2}
                onValueChange={(value) => setServicePrice(value)}
                prefix="R$"
              />
            </div>
          )}

          <StyledInputWithCounterWrapper>
            <Textarea
              name="service-description"
              className="service-description"
              label="Descrição do Serviço (opcional)"
              value={serviceDescription}
              onChange={(value) => setServiceDescription(value)}
              border
              borderAlert={
                serviceDescription.length
                >= constants.SERVICE_DESCRIPTION_MAX_LENGTH
              }
            />
            <StyledTextCounter
              count={
                serviceDescription.length
                >= constants.SERVICE_DESCRIPTION_MAX_LENGTH
              }
            >
              {serviceDescription.length}
              /
              {constants.SERVICE_DESCRIPTION_MAX_LENGTH}
            </StyledTextCounter>
          </StyledInputWithCounterWrapper>

          <Button
            className="save-button"
            onClick={handleSubmit}
            disabled={isLoading}
          >
            {isLoading && <Loading className="loading-icon" />}
            {' '}
            Salvar
          </Button>
        </StyledDialogForm>

        {isFetching ? (
          <LoadingContainer>
            <Loading />
          </LoadingContainer>
        ) : (
          <Checklist
            options={locationsList}
            optionsTotalLength={locationsListTotalLength}
            optionsNoEllegible={locationsListNoEligible}
            optionsPageIndex={locationsListPageIndex}
            setOptionsPageIndex={setLocationsListPageIndex}
            optionsPageQuantity={locationsListPageQuantity}
            handleInputSearch={handleInputSearch}
            selectedOptions={locationsToExclude}
            setSelectedOptions={setLocationsToExclude}
            masterCheckbox={masterCheckbox}
            setMasterCheckbox={setMasterCheckbox}
          />
        )}
      </StyledDialogBody>
    </StyledServiceBatchForm>
  );
};

export { ServiceBatchForm };
