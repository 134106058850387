import React from 'react';

import {
  StyledSearch,
  StyledInput,
  StyledLabel,
} from './search-styles';

const Search = ({
  handleOnChangeInput,
}) => (
  <StyledSearch>
    <StyledLabel>Pesquisar</StyledLabel>
    <StyledInput
      type="text"
      name="input-search"
      id="input-search"
      onChange={handleOnChangeInput}
    />
  </StyledSearch>
);

export default Search;
