import React, { ReactElement } from 'react';
import { MUIDataTableColumn } from 'mui-datatables';

import { EPlatforms } from '../../services/business-intelligence/enums';

export const initialDateColumns: MUIDataTableColumn[] = [
  {
    name: 'id',
    label: 'Id',
    options: { display: false },
  },
  {
    name: 'platform',
    label: 'Plataforma',
  },
  {
    name: 'company',
    label: 'Empresa',
  },
  {
    name: 'local',
    label: 'Local',
  },
  {
    name: 'address',
    label: 'Endereço',
  },
  {
    name: 'date',
    label: 'Data',
  },
  {
    name: 'status',
    label: 'Status',
  },
  {
    name: 'actions',
    label: 'Ações',
    options: { sort: false, filter: false, empty: true },
  },
];
