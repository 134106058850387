import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  Avatar, DialogTitle, DialogContent, IconButton, List, ListItem, ListItemText, ListItemAvatar, ListItemSecondaryAction, Link, DialogActions,
} from '@material-ui/core';
import {
  Add, Block, Warning, ArrowForward,
} from '@material-ui/icons';
import { isEmpty } from 'ramda';
import { GoogleLogin, GoogleLogout } from 'react-google-login';

import { Creators as PlatformsActions } from '../../../../../redux/ducks/platforms';
import { Creators as LocationActions } from '../../../../../redux/ducks/location';

import Loading from '../../../../loading';
import Button from '../../../../button';
import Snackbar from '../../../../snackbar';
import Tooltip from '../../../../tooltip';

import ListingDialog from '../../listing-dialog';

import VerificationDialog from './verification-dialog';
import CompleteVerificationDialog from './complete-verification-dialog';
import ToCompareDialog from '../../to-compare-dialog';
import GoogleListingDialog from './google-listing-dialog';

import getGoogleUserLocations from '../../../../../services/google/getGoogleUserLocations';
import updateUserProfile from '../../../../../services/users/updateUserProfile';
import updateLocation from '../../../../../services/locations/updateLocation';
import searchGoogleLocations from '../../../../../services/google/searchGoogleLocations';
import createGoogleLocation from '../../../../../services/google/createGoogleLocation';
import validateCreateGoogleLocation from '../../../../../services/google/validateCreateGoogleLocation';
import updateGoogleLocation from '../../../../../services/google/updateGoogleLocation';
import validateUpdateGoogleLocation from '../../../../../services/google/validateUpdateGoogleLocation';
import validateDeleteGoogleLocation from '../../../../../services/google/validateDeleteGoogleLocation';
import deleteGoogleLocation from '../../../../../services/google/deleteGoogleLocation';
import getLocation from '../../../../../services/google/getLocation';
import getPlaceDetails from '../../../../../services/google/getPlaceDetails';
import getUserLocation from '../../../../../services/google/getLocation';
import fetchVerificationOptions from '../../../../../services/google/fetchVerificationOptions';
import getVerificationStatus from '../../../../../services/google/getVerificationStatus';
import getUserAssociatedAccounts from '../../../../../services/google/getUserAssociatedAccounts';

import formatWorkingHours from '../../../../../utils/formatWorkingHours';
import formatSpecialDates from '../../../../../utils/formatSpecialDates';
import formatGoogleRegularHours from '../../../../../utils/formatGoogleRegularHours';
import formatGoogleCategories from '../../../../../utils/formatGoogleCategories';
import showGoogleCategories from '../../../../../utils/showGoogleCategories';
import { formatAccounts } from '../../../../../utils/formatAccounts';

import {
  StyledGoogle,
  StyledProfile,
  StyledButtonsGroup,
  StyledProfileAccount,
  StyledDisclaimer,
  StyledSyncDisclaimer,
  StyledDialog,
} from './google-styles';
import getGoogleCategories from '../../../../../services/google/getGoogleCategories';
import { CountryList } from '../../../../../utils/country-list';

const Google = ({
  needsSync,
  setNeedsSync,
  isFetching,
  connected,
  setConnected,
  setIsFetching,
  optimizationProgress,
  setProfileHeader,
}) => {
  const dispatch = useDispatch();

  const { user } = useSelector((state) => state.AuthReducer);
  const { activeLocation } = useSelector((state) => state.LocationReducer);
  const { googleData, locationsList } = useSelector(state => state.PlatformsReducer);

  const [profile, setProfile] = React.useState({ photoUrl: 'https://picsum.photos/id/122/200/300', name: 'Nome', email: 'email@gmail.com' });
  const [loginError, setLoginError] = React.useState(false);
  const [locations, setLocations] = React.useState([]);
  const [searchedLocations, setSearchedLocations] = React.useState([]);
  const [userAssociatedAccounts, setUserAssociatedAccounts] = React.useState([]);
  const [googleSearchInput, setGoogleSearchInput] = React.useState('');
  const [openDialog, setOpenDialog] = React.useState(false);
  const [openSnackbar, setOpenSnackbar] = React.useState(false);
  const [openBindDialog, setOpenBindDialog] = React.useState(false);
  const [loadPlaceCreated, setLoadPlaceCreated] = React.useState(false);
  const [loadPlaceUpdated, setLoadPlaceUpdated] = React.useState(false);
  const [verificationId, setVerificationId] = React.useState('');
  const [verificationOptions, setVerificationOptions] = React.useState([]);
  const [verificationDialogOpen, setVerificationDialogOpen] = React.useState(false);
  const [verificationLoading, setVerificationLoading] = React.useState(false);
  const [locationIdToVerificate, setLocationIdToVerificate] = React.useState('');
  const [completeVerificationDialogOpen, setCompleteVerificationDialogOpen] = React.useState(false);
  const [openToCompareDialog, setOpenToCompareDialog] = React.useState(false);
  const [placeDataFromGoogle, setPlaceDataFromGoogle] = React.useState({});
  const [formattedActiveLocation, setFormattedActiveLocation] = React.useState(activeLocation);
  const [isFetchingSearch, setIsFetchingSearch] = React.useState(false);
  const [isFetchingLocations, setIsFetchingLocations] = React.useState(false);
  const [selectedGroup, setSelectedGroup] = React.useState('');

  const [snackBarShow, setSnackBarShow] = React.useState(false);
  const [snackbarMessage, setSnackbarMessage] = React.useState('');
  const [snackbarSeverity, setSnackbarSeverity] = React.useState('');

  const connectToGoogle = React.useCallback(async (googleInfo) => {
    if (activeLocation.google_location_id) {
      setIsFetching(true);
      try {
        await getGoogleUserLocations({
          accessToken: googleInfo.accessToken,
          userId: googleInfo.googleId,
        });
        const data = await getLocation(googleInfo.accessToken, googleInfo.googleId, activeLocation.google_location_id);
        if (data.error) {
          if (data.error.status === 'INVALID_ARGUMENT') {
            dispatch(LocationActions.setActiveLocation({ ...activeLocation, google_location_id: null }));
            await updateLocation(
              {
                accessToken: user.accessToken,
                locationId: activeLocation.id,
                inputData: { },
                deleteGoogle: true,
                deleteFacebook: false,
              },
            );
          }
        }
        setConnected(true);
      } catch (err) {
        console.log(err);
        setConnected(false);
        setNeedsSync(true);
      } finally {
        setIsFetching(false);
      }
    }
  }, [activeLocation]);

  React.useEffect(() => {
    if (googleData[activeLocation.id]) {
      setProfile({
        photoUrl: googleData[activeLocation.id].profileObj.imageUrl,
        name: `${googleData[activeLocation.id].profileObj.givenName} ${googleData[activeLocation.id].profileObj.familyName}`,
        email: googleData[activeLocation.id].profileObj.email,
      });

      setProfileHeader({
        url: googleData[activeLocation.id].profileObj.imageUrl,
        alt: googleData[activeLocation.id].profileObj.email,
      });
    }
  }, [connectToGoogle]);

  const handleGoogleLogin = async (response) => {
    const { categories } = await getGoogleCategories(response.accessToken, '');
    if (!response.accessToken) {
      setLoginError(true);
    } else {
      setIsFetching(true);
      const googleLocationData = { accessToken: response.accessToken, profileObj: response.profileObj };
      dispatch(PlatformsActions.setGoogleData({ ...googleData, [activeLocation.id]: googleLocationData, googleCategories: categories }));
      setProfile({
        name: `${response.profileObj.givenName} ${response.profileObj.familyName}`,
        email: response.profileObj.email,
        photoUrl: response.profileObj.imageUrl,
      });

      connectToGoogle(response);
      setProfileHeader({
        url: response.profileObj.imageUrl,
        alt: response.profileObj.email,
      });

      const { accounts } = await getUserAssociatedAccounts(response.accessToken);
      const formattedAccounts = formatAccounts(accounts);
      setUserAssociatedAccounts(formattedAccounts);

      if (!user.user.google_user_id) {
        await updateUserProfile({
          accessToken: user.accessToken,
          id: user.user.id,
          updateProfileData: { google_user_id: response.googleId },
        });
      }

      if (!activeLocation.google_location_id) {
        const data = await getGoogleUserLocations({
          accessToken: response.accessToken,
          userId: response.googleId,
        });
        setLocations(data.locations);
        setSearchedLocations(await searchGoogleLocations(activeLocation.name, `${activeLocation.address1}, ${activeLocation.address2}`, activeLocation.city, activeLocation.city, process.env.REACT_APP_GOOGLE_PLACES_API_KEY));

        setOpenDialog(true);
      }

      setNeedsSync(false);
      setConnected(true);
      setIsFetching(false);
    }
  };

  const handleGoogleLogout = async (response) => {
    setIsFetching(true);
    setConnected(false);
    setNeedsSync(false);
    dispatch(LocationActions.setActiveLocation({ ...activeLocation, google_location_id: null }));
    await updateLocation(
      {
        accessToken: user.accessToken,
        locationId: activeLocation.id,
        inputData: { },
        deleteGoogle: true,
        deleteFacebook: false,
      },
    );
    delete googleData[activeLocation.id];
    delete googleData.googleCategories;

    dispatch(PlatformsActions.setGoogleData(googleData));
    setProfileHeader(null);

    setIsFetching(false);
  };

  const handleOnLocationClicked = async (location) => {
    const isVerified = location.locationKey.placeId !== undefined;
    let verificationStatus = 'UNVERIFIED';
    if (location.locationState.isVerified) verificationStatus = 'VERIFIED';
    if (location.locationState.isPendingReview) verificationStatus = 'REVIEW';
    if (location.locationState.hasPendingVerification) verificationStatus = 'PENDING';

    const placeId = location.name.split('/')[3];
    setOpenDialog(false);
    setIsFetching(true);
    try {
      const locationUpdate = await updateLocation(
        {
          accessToken: user.accessToken,
          locationId: activeLocation.id,
          inputData: {
            google_verification_status: verificationStatus,
            google_location_id: placeId,
            is_verified: isVerified,
          },
          deleteGoogle: false,
          deleteFacebook: false,
        },
      );
      dispatch(LocationActions.setActiveLocation(locationUpdate));
      setOpenSnackbar(true);
    } catch (err) {
      console.log(err);
    } finally {
      setIsFetching(false);
    }
  };

  const handleOnNotOwnedLocationClicked = async (location) => {
    setOpenDialog(false);
    setIsFetching(true);
    try {
      const locationUpdate = await updateLocation(
        {
          accessToken: user.accessToken,
          locationId: activeLocation.id,
          inputData: {
            google_verification_status: 'NO_OWNERSHIP',
            google_location_id: location.place_id,
          },
          deleteGoogle: false,
          deleteFacebook: false,
        },
      );
      dispatch(LocationActions.setActiveLocation(locationUpdate));
      setOpenSnackbar(true);
    } catch (err) {
      console.log(err);
    } finally {
      setIsFetching(false);
    }
  };

  const onConnectPlaceClicked = async (e) => {
    setIsFetchingLocations(true);
    e.preventDefault();
    const data = await getGoogleUserLocations({
      accessToken: googleData[activeLocation.id].accessToken,
      userId: googleData[activeLocation.id].profileObj.googleId,
    });
    setLocations(data.locations);
    const { accounts } = await getUserAssociatedAccounts(googleData[activeLocation.id].accessToken);

    const formattedAccounts = formatAccounts(accounts);

    setUserAssociatedAccounts(formattedAccounts);
    setSearchedLocations(await searchGoogleLocations(activeLocation.name, `${activeLocation.address1}, ${activeLocation.address2}`, activeLocation.city, activeLocation.city, process.env.REACT_APP_GOOGLE_PLACES_API_KEY));
    setSelectedGroup(googleData[activeLocation.id].profileObj.googleId);
    setIsFetchingLocations(false);
    setOpenDialog(true);
  };

  const onBindPlaceClicked = (placeId) => {
    window.open(`https://business.google.com/arc/p/${placeId}?hl=pt-BR`);
    setOpenDialog(false);
    setOpenBindDialog(true);
  };

  const onCreatePlaceClicked = async () => {
    setLoadPlaceCreated(true);
    const {
      store_code: storeCode,
      name: locationName,
      long_description: longDescription,
      country: regionCode,
      zip_code: postalCode,
      state: administrativeArea,
      city: locality,
      neighborhood: sublocality,
      address1,
      address2,
      phone: primaryPhone,
      secondary_phone: secondaryPhone,
      website: websiteUrl,
      google_categories: googleCategories,
      working_hours: workingHours,
      special_dates: specialDates,
      latitude, longitude,
      google_service_area: googleServiceArea,
      does_not_serve_at_this_address: doesNotServeAtThisAddress,
      labels,
    } = activeLocation;

    const { result } = await getPlaceDetails(googleServiceArea, process.env.REACT_APP_GOOGLE_PLACES_API_KEY);
    const serviceAreaName = result.name;

    const categories = googleCategories.split(',');
    const primaryCategory = { categoryId: categories[0] };
    const additionalCategories = [];
    for (let i = 1; i < categories.length; i++) {
      additionalCategories.push({ categoryId: categories[i] });
    }
    const periods = formatWorkingHours(workingHours);
    const specialHourPeriods = formatSpecialDates(specialDates);

    const data = await createGoogleLocation(googleData[activeLocation.id].accessToken, googleData[activeLocation.id].profileObj.googleId, {
      storeCode,
      languageCode: 'pt_BR',
      locationName,
      address: {
        regionCode: CountryList[regionCode],
        postalCode: postalCode.replace('.', ''),
        administrativeArea,
        locality,
        sublocality,
        addressLines: [address1, address2],
      },
      primaryPhone,
      additionalPhones: secondaryPhone ? [secondaryPhone] : null,
      websiteUrl,
      primaryCategory,
      additionalCategories,
      regularHours: {
        periods,
      },
      specialHours: {
        specialHourPeriods,
      },
      profile: {
        description: longDescription,
      },
      labels: labels.split(';'),
      latlng: {
        latitude, longitude,
      },
      serviceArea: {
        businessType: doesNotServeAtThisAddress ? 'CUSTOMER_LOCATION_ONLY' : 'CUSTOMER_AND_BUSINESS_LOCATION',
        places: {
          placeInfos: [
            {
              name: serviceAreaName,
              placeId: googleServiceArea,
            },
          ],
        },
      },
    });

    if (data.error) {
      const { details } = data.error;
      for (let j = 0; j < details.length; j++) {
        const { errorDetails } = details[j];
        switch (errorDetails[0].message) {
          case 'Invalid country code.': setSnackbarMessage('Código de país inválido'); break;
          case 'address.address_lines is required': setSnackbarMessage('O endereço deve ser preenchido'); break;
          case 'The latlng provided doesn\'t appear to lie within the designated country / region.': setSnackbarMessage('As coordenadas do pino não estão dentro do país especificado'); break;
          case 'Enter an address and marker that specify the same place.': setSnackbarMessage('O endereço e as coordenadas não coincidem'); break;
          default: break;
        }
      }
      setSnackbarSeverity('error');
      setSnackBarShow(true);
    } else {
      setSnackbarSeverity('success');
      setSnackbarMessage('Seu local foi criado com sucesso no Google. Realize a verificação para poder sincronizá-lo');
      setSnackBarShow(true);
    }

    const { locations: locationsUpdate } = await getGoogleUserLocations({
      accessToken: googleData[activeLocation.id].accessToken,
      userId: googleData[activeLocation.id].profileObj.googleId,
    });
    setLocations(locationsUpdate);

    setLoadPlaceCreated(false);
  };

  const onUpdateLocationClicked = async () => {
    setLoadPlaceUpdated(true);
    const {
      store_code: storeCode,
      name: locationName,
      long_description: longDescription,
      country: regionCode,
      zip_code: postalCode,
      state: administrativeArea,
      city: locality,
      neighborhood: sublocality,
      address1,
      address2,
      latitude, longitude,
      phone: primaryPhone,
      secondary_phone: secondaryPhone,
      website: websiteUrl,
      google_categories: googleCategories,
      working_hours: workingHours,
      special_dates: specialDates,
      google_service_area: googleServiceArea,
      does_not_serve_at_this_address: doesNotServeAtThisAddress,
    } = activeLocation;

    const { result } = await getPlaceDetails(googleServiceArea, process.env.REACT_APP_GOOGLE_PLACES_API_KEY);
    const serviceAreaName = result.name;

    const categories = googleCategories.split(',');
    const primaryCategory = { categoryId: categories[0] };
    const additionalCategories = [];
    for (let i = 1; i < categories.length; i++) {
      additionalCategories.push({ categoryId: categories[i] });
    }
    const periods = formatWorkingHours(workingHours);
    const specialHourPeriods = formatSpecialDates(specialDates);

    const data = await updateGoogleLocation(googleData[activeLocation.id].accessToken, googleData[activeLocation.id].profileObj.googleId, activeLocation.google_location_id, {
      storeCode,
      locationName,
      address: {
        languageCode: 'pt_BR',
        addressLines: [address1, address2],
        regionCode: CountryList[regionCode],
        postalCode: postalCode.replace('.', ''),
        administrativeArea,
        locality,
        sublocality,
      },
      primaryPhone,
      additionalPhones: secondaryPhone ? [secondaryPhone] : null,
      websiteUrl,
      primaryCategory,

      additionalCategories,
      regularHours: {
        periods,
      },
      specialHours: {
        specialHourPeriods,
      },
      profile: {
        description: longDescription,
      },
      latlng: { latitude, longitude },
      serviceArea: {
        businessType: doesNotServeAtThisAddress ? 'CUSTOMER_LOCATION_ONLY' : 'CUSTOMER_AND_BUSINESS_LOCATION',
        places: {
          placeInfos: [
            {
              name: serviceAreaName,
              placeId: googleServiceArea,
            },
          ],
        },
      },
    });

    if (data.error) {
      if (data.error.status === 'UNAUTHORIZED') {
        setSnackbarMessage('A conexão com o google foi interrompida. Conecte-se novamente.');
        setSnackbarSeverity('error');
        setSnackBarShow(true);
        return;
      }
      if (data.error.status === 'RESOURCE_EXHAUSTED') {
        setSnackbarMessage('Recurso exaurido');
        setSnackbarSeverity('error');
        setSnackBarShow(true);
        return;
      }
      const { details } = data.error;
      for (let j = 0; j < details.length; j++) {
        const { errorDetails } = details[j];
        switch (errorDetails[0].message) {
          case 'Invalid country code.': setSnackbarMessage('Código de país inválido'); break;
          case 'address.address_lines is required': setSnackbarMessage('O endereço deve ser preenchido'); break;
          case 'The latlng provided doesn\'t appear to lie within the designated country / region.': setSnackbarMessage('As coordenadas do pino não estão dentro do país especificado'); break;
          case 'Enter an address and marker that specify the same place.': setSnackbarMessage('O endereço e as coordenadas não coincidem'); break;
          case 'The specified address cannot be located. Please use the UI to provide a pin drop.': setSnackbarMessage('Não é possível sincronizar com um local ainda não verificado'); break;
          default: break;
        }
      }
      setSnackbarSeverity('error');
      if (details[0]?.errorDetails[0]?.message === 'field is read only' && details[0]?.errorDetails[0]?.field === 'latlng') {
        setSnackbarMessage('Local atualizado, mas não foi possível alterar suas coordenadas');
        setSnackbarSeverity('success');
      }
      setSnackBarShow(true);
    } else {
      setSnackbarSeverity('success');
      setSnackbarMessage('Local atualizado com sucesso no Google.');
      setSnackBarShow(true);
    }
    setLoadPlaceUpdated(false);
    setOpenDialog(false);
  };

  const onDeleteLocationClicked = async (location) => {
    setIsFetching(true);
    const locationId = location.name.split('/')[3];
    await deleteGoogleLocation(googleData[activeLocation.id].accessToken, googleData[activeLocation.id].profileObj.googleId, locationId);
    setIsFetching(false);
    setSnackbarMessage('Local removido com sucesso');
    setSnackbarSeverity('success');
    setSnackBarShow(true);

    const { locations: locationsUpdate } = await getGoogleUserLocations({
      accessToken: googleData[activeLocation.id].accessToken,
      userId: googleData[activeLocation.id].profileObj.googleId,
    });
    setLocations(locationsUpdate);
  };

  const onCompareLocationsClicked = async () => {
    const data = await getLocation(googleData[activeLocation.id].accessToken, googleData[activeLocation.id].profileObj.googleId, activeLocation.google_location_id);

    if (data.error?.status === 'NOT_FOUND') {
      setSnackbarSeverity('error');
      setSnackbarMessage('O local não foi encontrado no Google My Business');
      setSnackBarShow(true);
      return;
    }

    const {
      address: {
        locality: city,
        sublocality: neighborhood,
        addressLines: address,
        administrativeArea: state,
        postalCode: zip,
        regionCode: country,
      }, locationName: name,
      primaryPhone: phone,
      additionalPhones,
      labels,
      serviceArea,
      regularHours,
      profile: profileData,
      websiteUrl: website,
      primaryCategory,
      additionalCategories,
    } = data;

    const formattedGoogleCategories = [primaryCategory.displayName];
    if (additionalCategories) {
      additionalCategories.map(category => {
        formattedGoogleCategories.push(category.displayName);
      });
    }

    const formattedActiveLocationCategories = await showGoogleCategories(user.accessToken, activeLocation.google_categories);

    setFormattedActiveLocation({ ...activeLocation, google_categories: formattedActiveLocationCategories });

    setPlaceDataFromGoogle({
      city,
      name,
      street: address[0],
      address2: address[1],
      state,
      phone,
      labels: labels ? labels.join(';') : null,
      secondaryPhone: additionalPhones ? additionalPhones[0] || null : null,
      tertiaryPhone: additionalPhones ? additionalPhones[1] || null : null,
      zip,
      doesNotAttend: serviceArea && serviceArea.businessType !== 'CUSTOMER_AND_BUSINESS_LOCATION',
      hours: regularHours && formatGoogleRegularHours(regularHours),
      country,
      neighborhood,
      longDescription: profileData && profileData.description,
      website,
      google_categories: formattedGoogleCategories.join(', '),
    });

    setOpenToCompareDialog(true);
  };

  const initiateVerificationProcess = async (locationId) => {
    setVerificationLoading(true);
    setVerificationDialogOpen(true);

    const {
      address: {
        addressLines, administrativeArea, locality, postalCode, regionCode,
      },
      serviceArea: { businessType },
    } = await getLocation(googleData[activeLocation.id].accessToken, googleData[activeLocation.id].profileObj.googleId, locationId);

    const { options } = await fetchVerificationOptions(
      googleData[activeLocation.id].accessToken,
      googleData[activeLocation.id].profileObj.googleId,
      locationId,
      addressLines,
      regionCode,
      postalCode,
      locality,
      administrativeArea,
      businessType,
    );

    setLocationIdToVerificate(locationId);
    setVerificationOptions(options);
    setVerificationLoading(false);
  };

  const finishVerificationProcess = async (locationId) => {
    setLocationIdToVerificate(locationId);
    setVerificationLoading(true);
    setCompleteVerificationDialogOpen(true);
    const data = await getVerificationStatus(googleData[activeLocation.id].accessToken, googleData[activeLocation.id].profileObj.googleId, locationId);
    setVerificationId(data.verifications[0].name.split('/')[5]);

    setVerificationLoading(false);
  };

  const handleChangeGoogleSearchInput = async (query = '') => {
    const { locations } = await getGoogleUserLocations({
      accessToken: googleData[activeLocation.id].accessToken,
      userId: googleData[activeLocation.id].profileObj.googleId,
      query,
      setIsFetching: setIsFetchingSearch,
    });
    setLocations(locations);
  };

  const handleChangeAccountGroup = async (e) => {
    setIsFetchingSearch(true);
    setSelectedGroup(e.target.value);
    const googleLocationData = { accessToken: googleData[activeLocation.id].accessToken, profileObj: { ...googleData[activeLocation.id].profileObj, googleId: e.target.value } };
    dispatch(PlatformsActions.setGoogleData({ ...googleData, [activeLocation.id]: googleLocationData }));
    const { locations } = await getGoogleUserLocations({
      accessToken: googleData[activeLocation.id].accessToken,
      userId: e.target.value,
    });
    setLocations(locations);
    setIsFetchingSearch(false);
  };

  return (
    <StyledGoogle>
      {googleData[activeLocation.id] ? (
        <>
          {activeLocation.google_location_id ? (
            <StyledProfile>
              <StyledProfileAccount>
                <div className="profile-infos">

                  <>
                    <span>Google Place Id: </span>
                    <div className="place-infos">
                      <a href={`https://maps.google.com/maps?cid=${activeLocation.google_location_id}`} target="_blank" rel="noreferrer">{activeLocation.google_location_id}</a>

                      {activeLocation.google_verification_status === 'UNVERIFIED' && <span className="not-verified">Local não verificado</span>}
                      {activeLocation.google_verification_status === 'PENDING' && <span className="pending-verification">Verificação pendente</span>}
                      {activeLocation.google_verification_status === 'REVIEW' && <span className="awaiting-review">Em Revisão</span>}
                      {activeLocation.google_verification_status === 'NO_OWNERSHIP' && <span className="no-ownership">Você não tem posse sobre esse local</span>}
                      {activeLocation.google_verification_status === 'VERIFIED' && <span className="verified">Local verificado</span>}
                    </div>
                  </>
                </div>
              </StyledProfileAccount>
            </StyledProfile>
          )
            : (
              <Button onClick={onConnectPlaceClicked} disabled={isFetchingLocations} className="button">
                {isFetchingLocations ? <Loading /> : 'Vincular'}
              </Button>
            )}

          <StyledButtonsGroup>
            { activeLocation.google_location_id
            && (
            <>
              {activeLocation.google_verification_status === 'UNVERIFIED'
              && (
              <Button
                className="button"
                onClick={() => initiateVerificationProcess(activeLocation.google_location_id)}
              >
                Verificar
              </Button>
              )}

              {activeLocation.google_verification_status === 'PENDING'
              && (
              <>
                <Button
                  className="button"
                  onClick={() => finishVerificationProcess(activeLocation.google_location_id)}
                >
                  Concluir Verificação
                </Button>
                <Button
                  className="button"
                  onClick={() => initiateVerificationProcess(activeLocation.google_location_id)}
                >
                  Reiniciar Verificação
                </Button>
              </>
              )}
              {activeLocation.google_verification_status !== 'NO_OWNERSHIP' ? (
                <>
                  <Button className="button" onClick={onCompareLocationsClicked}>Comparar</Button>
                  <span className="disclaimer-sync">
                    Preecha corretamente todos os campos que estão na
                    {' '}
                    <small>comparação</small>
                    {' '}
                    para habilitar a
                    {' '}
                    <small>sincronização.</small>
                  </span>
                  <Button
                    className="button"
                    disabled={
                      loadPlaceUpdated
                      || isEmpty(activeLocation.google_location_id)
                      || isEmpty(activeLocation.name)
                      || isEmpty(activeLocation.address1)
                      || isEmpty(activeLocation.neighborhood)
                      || isEmpty(activeLocation.city)
                      || isEmpty(activeLocation.state)
                      || isEmpty(activeLocation.zip_code)
                      || isEmpty(activeLocation.google_categories)
                      || isEmpty(activeLocation.long_description)
                      || isEmpty(activeLocation.website)
                      || isEmpty(activeLocation.phone)
                      || activeLocation.working_hours.length < 1
}
                    onClick={onUpdateLocationClicked}
                  >
                    {loadPlaceUpdated ? <Loading /> : 'Sincronizar'}
                  </Button>
                </>
              ) : <Button className="button" onClick={() => onBindPlaceClicked(activeLocation.google_location_id)}>Solicitar Posse</Button>}
            </>
            ) }
            <GoogleLogout
              clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}
              onLogoutSuccess={handleGoogleLogout}
              render={renderProps => (
                <Button
                  className="button"
                  buttonType="secondary"
                  disabled={renderProps.isFetching}
                  onClick={renderProps.onClick}
                >
                  {isFetching ? <Loading /> : 'Desconectar'}
                </Button>
              )}
            />
          </StyledButtonsGroup>
        </>
      ) : (
        <>
          {needsSync && <StyledDisclaimer>Seu acesso ao google expirou. Por favor conecte-se novamente:</StyledDisclaimer>}
          {loginError && <StyledDisclaimer>Falha ao autenticar com o google. Recarregue a página e tente novamente</StyledDisclaimer>}
          <GoogleLogin
            clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}
            scope="https://www.googleapis.com/auth/business.manage"
            prompt="select_account"
            isSignedIn
            onSuccess={handleGoogleLogin}
            onFailure={response => {

            }}
            render={renderProps => (
              <Button
                onClick={renderProps.onClick}
                disabled={renderProps.isFetching}
              >
                {isFetching ? <Loading /> : 'Conectar'}
              </Button>
            )}
          />
        </>
      )}

      <GoogleListingDialog
        selectedGroup={selectedGroup}
        groups={userAssociatedAccounts}
        loadingCreate={loadPlaceCreated}
        open={openDialog}
        allowCreate={searchedLocations.length < 1}
        noImages
        headerTitle="Escolha um local do Google para conectar-se"
        headerTitleCreateButton="Criar novo local"
        handleHeaderCreateButton={onCreatePlaceClicked}
        handleClose={() => setOpenDialog(false)}
        data={locations}
        handleChangeGoogleSearchInput={handleChangeGoogleSearchInput}
        noOwnershipData={searchedLocations}
        handleBindWithLocation={handleOnLocationClicked}
        keyToFilter="locationName"
        noOwnershipDataKeyToFilter="name"
        name="locationName"
        noOwnershipDataName="name"
        handleBindNoOwnershipPlaceWithLocation={handleOnNotOwnedLocationClicked}
        isFetchingSearch={isFetchingSearch}
        onChangeAccountGroup={handleChangeAccountGroup}
      />

      <StyledDialog open={openBindDialog} onClose={() => setOpenBindDialog(false)}>
        <DialogTitle>
          <span className="dialog-title">Vincule o local à sua conta no google</span>
        </DialogTitle>
        <DialogContent>
          <span>Preencha o painel de vinculação na página do Google Meu Negócio e aguarde uma confirmação do dono.</span>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenBindDialog(false)}>Ok</Button>
        </DialogActions>
      </StyledDialog>
      {googleData[activeLocation.id] && (
      <>
        <VerificationDialog
          isOpen={verificationDialogOpen}
          handleClose={() => setVerificationDialogOpen(false)}
          options={verificationOptions}
          loading={verificationLoading}
          googleAccessToken={googleData[activeLocation.id].accessToken}
          googleId={googleData[activeLocation.id].profileObj.googleId}
          locationId={locationIdToVerificate}
          activeLocation={activeLocation}
        />
        <CompleteVerificationDialog
          isOpen={completeVerificationDialogOpen}
          handleClose={() => setCompleteVerificationDialogOpen(false)}
          googleAccessToken={googleData[activeLocation.id].accessToken}
          googleId={googleData[activeLocation.id].profileObj.googleId}
          locationId={locationIdToVerificate}
          loading={verificationLoading}
          verificationId={verificationId}
        />
      </>
      )}
      <ToCompareDialog
        open={openToCompareDialog}
        handleClose={() => setOpenToCompareDialog(false)}
        dataFromLocation={formattedActiveLocation}
        dataFromPlatform={placeDataFromGoogle}
        titlePlatformColumn="Local (Google)"
        currentPlatform="google"
      />
      <Snackbar
        autoHideDuration={6000}
        severity={snackbarSeverity}
        message={snackbarMessage}
        open={snackBarShow}
        onClose={() => setSnackBarShow(false)}
      />
    </StyledGoogle>

  );
};

export default Google;
