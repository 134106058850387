import * as R from 'ramda';

const addTagOwnerOrGuestColumn = ({
  registerTarget,
  currentUserId,
}) => registerTarget.map(registerTargetItem => {
  const { guests } = registerTargetItem || {};

  const hasCurrentUserOnGuests = guests.find(userGuest => userGuest.user.id === currentUserId);

  let permissionStatus = 'locationGuest';

  const { owner_user: ownerUser } = registerTargetItem;

  if (R.isNil(ownerUser)) permissionStatus = 'guest';

  if (currentUserId === ownerUser?.id) permissionStatus = 'owner';

  if (!R.isNil(hasCurrentUserOnGuests)) permissionStatus = 'guest';

  return {
    ...registerTargetItem,
    permissionStatus,
  };
});

export default addTagOwnerOrGuestColumn;
