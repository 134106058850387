import React from 'react';
import * as R from 'ramda';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { format } from 'date-fns';
import { useSnackbar } from 'notistack';
import { debounce } from 'lodash';
import { Helmet } from 'react-helmet';

import {
  HeaderToolbar,
  HealderToolbarAction,
  DataTable,
} from '../../components';

import { Creators as LocationActions } from '../../redux/ducks/location';
import { Creators as CompanyActions } from '../../redux/ducks/company';
import { Creators as LocationGroupActions } from '../../redux/ducks/location-group';

import {
  useAuth,
  useCompany,
  useLocationGroups,
} from '../../hooks';

import {
  StyledLocationGroupLocations,
  StyledContent,
} from './location-group-locations-styles';

import getAllGroupLocations from '../../services/locations/getAllGroupLocation';
import removeLocationFromGroup from '../../services/location-groups/removeLocations';
import APIGetUserLocations from '../../services/locations/get-user-locations';
import APIGetAllLocations from '../../services/locations/getAllLocations';
import addLocationsToGroup from '../../services/location-groups/addLocations';

import LocationsToAdd from './locations-to-add';

import type { TLocationEntity } from '../../types/TLocation';

import { TDropdownSelectProps } from '../../types/TLocationsToAdd';

const LocationGroupLocations = () => {
  const reduxDispatch = useDispatch();
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();

  const [loading, setLoading] = React.useState(false);
  const [groupLocations, setGroupLocations] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [pageSize, setPageSize] = React.useState(10);
  const [searchQuery, setSearchQuery] = React.useState('');
  const [dataCount, setDataCount] = React.useState(0);
  const [formattedData, setFormattedData] = React.useState([]);

  const [locationsToAddLoading, setLocationsToAddLoading] = React.useState(false);
  const [locationsToAddSearchQuery, setLocationsToAddSearchQuery] = React.useState('');
  const [locationsToAdd, setLocationsToAdd] = React.useState<TLocationEntity[]>([]);
  const [locationsToAddPage, setLocationsToAddPage] = React.useState(0);
  const [locationsToAddPagesQuantity, setLocationsToAddPagesQuantity] = React.useState(1);
  const [locationsToAddPageSize, setLocationsToAddPageSize] = React.useState(10);
  const [companyFilter, setCompanyFilter] = React.useState(0);

  const [userCompaniesWithPlaceholder, setUserCompaniesWithPlaceholder] = React.useState<TDropdownSelectProps[] | null>(null);

  const {
    userId,
    userProfileName,
    userAccessToken,
    userSetTokenLikeExpired,
  } = useAuth();

  const {
    userCompanies,
    userCompaniesPageIndex,
    userCompaniesPageQuantity,
    userCompaniesPageQuery,
    setUserCompaniesPageIndex,
    setUserCompaniesPageQuery,
  } = useCompany();

  const {
    activeCompany,
    activeLocationGroup,
  } = useLocationGroups();

  const columns = [
    { name: 'id', options: { display: false, sort: false } },
    { name: 'Nome', options: { sort: false } },
    { name: 'Empresa', options: { sort: false } },
    { name: 'Endereço', options: { sort: false } },
    { name: 'Data de criação', options: { sort: false } },
  ];

  const getLocations = React.useCallback(async () => {
    if (R.isNil(activeLocationGroup)) return;

    const getLocationsResponse = await getAllGroupLocations({
      accessToken: userAccessToken,
      locationGroupId: activeLocationGroup.id,
      setIfFetching: setLoading,
      setTokenLikeExpired: userSetTokenLikeExpired,
      page,
      pageSize,
      query: searchQuery,
    });

    if (R.isNil(getLocationsResponse)) return;

    const [data, amount] = getLocationsResponse;

    const handleRenderAddress = (currentLocation: TLocationEntity) => {
      const {
        address1,
        address2,
        city,
        state,
        country,
      } = currentLocation;

      if (!R.isNil(address2) && !R.isEmpty(address2)) {
        return `${address1.trim()}, ${address2.trim()}, ${city} ${state}, ${country}`;
      }

      return `${address1.trim()}, ${city} ${state}, ${country}`;
    };

    setFormattedData(data.map((currentLocation: TLocationEntity) => ([
      currentLocation.id,
      currentLocation.name,
      currentLocation.company.name,
      handleRenderAddress(currentLocation),
      format(new Date(currentLocation.created_at), 'dd/MM/yyyy'),
    ])));

    setGroupLocations(data);
    setDataCount(amount);
  }, [
    activeLocationGroup,
    page,
    pageSize,
    searchQuery,
  ]);

  const retrieveCompanyLocations = React.useCallback(async () => {
    setLocationsToAddLoading(true);
    let getCompanyLocationsResponse: any = null;

    if (userProfileName === 'Usuário Padrão') {
      getCompanyLocationsResponse = await APIGetUserLocations({
        accessToken: userAccessToken,
        userId,
        companyId: companyFilter,
        page: locationsToAddPage,
        pageSize: locationsToAddPageSize,
        query: locationsToAddSearchQuery,
        setTokenLikeExpired: userSetTokenLikeExpired,
      });
    } else {
      getCompanyLocationsResponse = await APIGetAllLocations({
        accessToken: userAccessToken,
        companyId: companyFilter,
        page: locationsToAddPage,
        pageSize: locationsToAddPageSize,
        query: locationsToAddSearchQuery,
        setTokenLikeExpired: userSetTokenLikeExpired,
      });
    }

    if (R.isNil(getCompanyLocationsResponse)) return;

    const [data, amount] = getCompanyLocationsResponse;

    setLocationsToAddPagesQuantity(Math.ceil(amount / pageSize));
    setLocationsToAdd(data);
    setLocationsToAddLoading(false);
  }, [locationsToAddPage, locationsToAddPageSize, locationsToAddSearchQuery, companyFilter]);

  const onAddClicked = async (location:any) => {
    if (R.isNil(activeLocationGroup)) return;

    const [, amount] = await getAllGroupLocations({
      accessToken: userAccessToken,
      locationGroupId: activeLocationGroup.id,
      searchId: location.id,
      setIfFetching: setLocationsToAddLoading,
      setTokenLikeExpired: userSetTokenLikeExpired,
    });

    setLoading(false);

    if (amount > 0) {
      enqueueSnackbar('Este local já existe no grupo', { variant: 'warning' });
      return;
    }

    await addLocationsToGroup({
      accessToken: userAccessToken,
      locationGroupId: activeLocationGroup.id,
      locationIds: [location.id],
      isAddLocationToGroup: setLocationsToAddLoading,
      setTokenLikeExpired: null,
      feedbackMessage: null,
    });

    await retrieveCompanyLocations();
    await getLocations();
    setLoading(false);
  };

  const handleChangeCompany = (companyId: number) => {
    setLocationsToAddPage(0);
    setCompanyFilter(companyId);
  };

  const handleSearchLocation = (locationName: string) => {
    setLocationsToAddPage(0);
    setLocationsToAddSearchQuery(locationName);
  };

  React.useEffect(() => { retrieveCompanyLocations(); }, [retrieveCompanyLocations]);

  React.useEffect(() => { getLocations(); }, [getLocations]);

  React.useEffect(() => {
    if (!R.isNil(userCompanies)) {
      const placeholderCompany = {
        name: 'Seleciona Opção',
        id: 0,
      };

      const parsedUserCompanies = userCompanies.map((company: any): TDropdownSelectProps => ({
        name: company.name,
        id: company.id,
      }));

      setUserCompaniesWithPlaceholder([
        placeholderCompany,
        ...parsedUserCompanies,
      ]);
    }
  }, [userCompanies]);

  const handleRemoveLocation = async (locationId:number) => {
    if (R.isNil(activeLocationGroup)) return;

    await removeLocationFromGroup({
      accessToken: userAccessToken,
      locationGroupId: activeLocationGroup.id,
      locationIds: [locationId],
      isDeleting: setLoading,
      feedbackMessage: null,
      setTokenLikeExpired: userSetTokenLikeExpired,
    });

    setLoading(true);
    await getLocations();
    await retrieveCompanyLocations();
    setLoading(false);
  };

  const handleUpdateLocation = (e:any) => {
    const [selectedLocation] = groupLocations.filter((element:any) => element.id === e);
    reduxDispatch(LocationActions.setActiveLocation(selectedLocation));
    reduxDispatch(LocationGroupActions.setShowLocationsByGroup(true));
    reduxDispatch(CompanyActions.setShowLocationsByCompany(false));
    history.push('/location/profile');
  };

  const handleInputSearch = debounce(value => handleSearchLocation(value), 500);

  return (
    <StyledLocationGroupLocations>
      <Helmet>
        <meta property="og:title" content="Meus locais - SaaS Hublocal" />
        <meta property="og:description" content="Gerencie seus locais." />

        <title>Meus locais - SaaS Hublocal</title>
      </Helmet>
      {activeCompany && (
        <HeaderToolbar
          dropdownsLocationGroups
        >
          <HealderToolbarAction
            title="Editar Grupo"
            icon="Create"
            onClick={() => { history.push('/location-groups/edit'); }}
          />
        </HeaderToolbar>
      )}

      <StyledContent>
        <DataTable
          page={page}
          setPage={setPage}
          loading={loading}
          data={formattedData}
          title="Locais do grupo"
          pageSize={pageSize}
          setPageSize={setPageSize}
          setSearchQuery={setSearchQuery}
          count={dataCount}
          columns={columns}
          onUpdateClicked={handleUpdateLocation}
          onDeleteClicked={handleRemoveLocation}
          onRestoreClicked={() => {}}
          onReportClicked={null}
          onRowClickURL={null}
          userProfileName={null}
          handleChangedColumns={null}
          addRegisterLink={null}
          className="locations-datatable"
          isLocationGroupTable
        />

        {!R.isNil(activeLocationGroup) && (
          <LocationsToAdd
            activeLocationGroupId={activeLocationGroup.id}
            page={locationsToAddPage}
            setPage={setLocationsToAddPage}
            pageQuantity={locationsToAddPagesQuantity}
            companyFilter={companyFilter}
            handleChangeCompany={handleChangeCompany}
            onAddClicked={onAddClicked}
            locationsToAdd={locationsToAdd}
            locationsToAddLoading={locationsToAddLoading}
            handleInputSearch={handleInputSearch}
            userCompaniesPageIndex={userCompaniesPageIndex}
            setUserCompaniesPageIndex={setUserCompaniesPageIndex}
            userCompaniesPageQuantity={userCompaniesPageQuantity}
            setUserCompaniesPageQuery={setUserCompaniesPageQuery}
            userCompaniesWithPlaceholder={userCompaniesWithPlaceholder}
          />
        )}
      </StyledContent>

    </StyledLocationGroupLocations>
  );
};

export default LocationGroupLocations;
