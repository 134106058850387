import React from 'react';
import * as R from 'ramda';
import { v4 as UUIDV4 } from 'uuid';

import {
  StyledWorkingIHoursDays,
  StyledWorkingHoursInfo,
  StyledIconWrapper,
} from './working-hours-info-styles';
import SyncStatusIconMonitor from '../../../../../../components/sync-status-icon-monitor';

const WorkingHoursInfo = ({
  platformWorkingHours,
  saasWorkingHours,
  status,
}) => {
  const extractKey = (day: any) => Object.keys(day)[0];

  const renderTimes = (workingHourDay) => {
    const currentDay = extractKey(workingHourDay);
    const currentDayTimes = workingHourDay[currentDay];

    return (
      <p>
        {!currentDayTimes.isOpen && 'Fechado' }
        { currentDayTimes.isOpen && currentDayTimes.is24hours && '24 Horas' }
        { currentDayTimes.isOpen && !currentDayTimes.is24hours && `${currentDayTimes.times.open}-${currentDayTimes.times.close}` }
      </p>
    );
  };

  return (
    <section>
      {platformWorkingHours.map((workingHoursDay, index) => (
        <StyledWorkingHoursInfo
          key={UUIDV4()}
          isLastOfSection={index + 1 === platformWorkingHours.length}
        >
          <StyledWorkingIHoursDays className="saasField">
            <li>
              <p className="title">{extractKey(saasWorkingHours[index])}</p>

              {renderTimes(saasWorkingHours[index])}

            </li>
          </StyledWorkingIHoursDays>
          <StyledWorkingIHoursDays className="platformField">
            <li>
              <p className="title">{extractKey(workingHoursDay)}</p>

              {renderTimes(workingHoursDay)}

            </li>
          </StyledWorkingIHoursDays>
          <StyledIconWrapper className="statusIcon">
            <SyncStatusIconMonitor syncType="monitor" status={status} />
          </StyledIconWrapper>
        </StyledWorkingHoursInfo>
      ))}
    </section>
  );
};

export default WorkingHoursInfo;
