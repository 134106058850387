import React from 'react';
import { useSelector } from 'react-redux';
import * as R from 'ramda';
import { useSnackbar } from 'notistack';

import APIBatchCreatePosts from '../../../../services/batch-update/batchCreatePosts';
import type { TRootStateRedux } from '../../../../types/TRootStateRedux';
import APIGetValidaGoogleAccessToken from '../../../../services/getValidGoogleAccessToken';

import type {
  TBatchPostFormCovid19,
  TLocationPostAddButtonType,
} from '../../../../types/TLocationPost';

import {
  useAuth,
} from '../../../../hooks';

import { websiteValidate } from '../../../../utils/fields-validate';

import {
  locationPostAddButtonsType,
  getActionType,
  getActionTypeReverse,
} from '../../helpers';

import {
  Textarea,
  Select,
  Input,
  Button,
  Loading,
} from '../../../../components';

import {
  StyledPostFormCovid19,
} from './post-form-covid-19-styles';

const PostFormCovid19 = ({
  closeModal,
  dataToUpdate = null,
  affectedLocations,
  getLocalPosts,
}: TBatchPostFormCovid19) => {
  const { enqueueSnackbar } = useSnackbar();

  const {
    userAccessToken,
    userId,
    userName,
    userEmail,
    userSetTokenLikeExpired,
    userProfile,
  } = useAuth();

  const { activeLocationGroupId, showLocationsByGroup } = useSelector((state: TRootStateRedux) => state.LocationGroupReducer);
  const { activeCompanyId, showLocationsByCompany } = useSelector((state: TRootStateRedux) => state.CompanyReducer);

  const [covid19StatusText, setCovid19StatusText] = React.useState('');
  const [addedButtonType, setAddedButtonType] = React.useState('Nenhum');
  const [addedButtonTypeValue, setAddedButtonTypeValue] = React.useState<string>('');
  const [isPublish, setIsPublish] = React.useState<React.SetStateAction<boolean>>(false);
  const [isAddedButton, setIsAddedButton] = React.useState(false);
  const [isAddedButtonPhoneType, setIsAddedButtonPhoneType] = React.useState(false);

  const [googleAccessToken, setGoogleAccessToken] = React.useState<string>();

  const getValidGoogleAccessToken = React.useCallback(async () => {
    const getValidGoogleAccessTokenResponse = await APIGetValidaGoogleAccessToken({
      accessToken: userAccessToken,
      userId,
      setTokenLikeExpired: userSetTokenLikeExpired,
      userProfile,
    });

    if (R.isNil(getValidGoogleAccessTokenResponse)) return;

    const { googleAccessToken: accessTokenGoogle } = getValidGoogleAccessTokenResponse;

    setGoogleAccessToken(accessTokenGoogle);
  }, []);

  const isValidInput = React.useCallback(() => {
    const postActionType = getActionType(addedButtonType);

    if (R.isEmpty(covid19StatusText)) {
      enqueueSnackbar('O conteúdo da postagem não pode ser vazio!', { variant: 'warning' });
      return false;
    }

    if (covid19StatusText.length > 1500) {
      enqueueSnackbar('O conteúdo da postagem não podem conter mais de 1500 caracteres!', { variant: 'warning' });
      return false;
    }

    if (R.isEmpty(addedButtonTypeValue) && R.isNil(addedButtonTypeValue) && !R.isNil(postActionType) && postActionType !== 'CALL') {
      enqueueSnackbar('O link do botão da postagem não pode ser vazio!', { variant: 'warning' });
      return false;
    }

    if (!R.isEmpty(postActionType) && !R.isNil(postActionType)) {
      if (postActionType !== 'CALL' && !websiteValidate(addedButtonTypeValue)) {
        enqueueSnackbar('O formato do link inserido é inválido!', { variant: 'warning' });
        return false;
      }
    }

    return true;
  }, [
    covid19StatusText,
    addedButtonType,
    addedButtonTypeValue,
  ]);

  React.useEffect(() => { getValidGoogleAccessToken(); }, [getValidGoogleAccessToken]);

  React.useEffect(() => {
    if (R.isNil(dataToUpdate)) return;

    const {
      call_to_action_type,
      call_to_action_value,
      summary,
    } = dataToUpdate.data_post;

    setCovid19StatusText(summary);
    setAddedButtonType(getActionTypeReverse(call_to_action_type) || 'Nenhum');
    setAddedButtonTypeValue(call_to_action_value || '');
  }, [dataToUpdate]);

  React.useEffect(() => {
    if (addedButtonType !== 'Nenhum' && addedButtonType !== 'Ligar agora') {
      setIsAddedButton(true);
      setIsAddedButtonPhoneType(false);
    }

    if (addedButtonType === 'Ligar agora') {
      setIsAddedButtonPhoneType(true);
      setIsAddedButton(false);
    }

    if (addedButtonType === 'Nenhum') {
      setIsAddedButton(false);
      setIsAddedButtonPhoneType(false);
    }
  }, [addedButtonType]);

  const createGooglePost = React.useCallback(async () => {
    if (R.isNil(googleAccessToken)) return;
    if (!isValidInput()) return;

    const postActionType = getActionType(addedButtonType);

    const postActionUrl = postActionType !== 'CALL' ? addedButtonTypeValue : null;

    const createGooglePostResponse = await APIBatchCreatePosts({
      accessToken: userAccessToken,
      created_by: userId,
      groupType: showLocationsByCompany ? 'COMPANY' : 'LOCATIONS_GROUP',
      groupId: showLocationsByCompany ? activeCompanyId : activeLocationGroupId,
      locations: affectedLocations,
      postData: {
        call_to_action_type: postActionType,
        call_to_action_value: postActionUrl || null,
        summary: covid19StatusText,
        type: 'ALERT',
        alert_type: 'COVID_19',
      },
      feedbackMessage: enqueueSnackbar,
      setIsLoading: setIsPublish,
      setTokenLikeExpired: userSetTokenLikeExpired,
      userData: {
        user_id: userId,
        user_name: userName,
        user_email: userEmail,
      },
    });

    if (R.isNil(createGooglePostResponse) || createGooglePostResponse.status === 406) return;

    getLocalPosts();
    closeModal();
  }, [
    addedButtonTypeValue,
    addedButtonType,
    covid19StatusText,
    googleAccessToken,
    showLocationsByCompany,
    activeCompanyId,
    activeLocationGroupId,
    affectedLocations,
    userId,
    userName,
    userEmail,
  ]);

  const handleAddButton = (value: any) => {
    setAddedButtonType(value);

    if (value === 'Nenhum') setAddedButtonTypeValue('');
  };

  return (
    <StyledPostFormCovid19>
      <Textarea
        onChange={setCovid19StatusText}
        name="covid-status"
        label="Atualizar Status de Covid-19 *"
        border
        disabled={!R.isNil(dataToUpdate)}
        value={covid19StatusText}
        className="covid-status-textarea"
      />

      <Select<TLocationPostAddButtonType>
        label="Adicionar um botão (opcional)"
        value={addedButtonType}
        name="select-button-type"
        disabled={!R.isNil(dataToUpdate)}
        border
        onChange={({ target }) => handleAddButton(target.value)}
        options={locationPostAddButtonsType}
      />

      {(isAddedButton && !isAddedButtonPhoneType) && (
        <Input
          border
          disabled={!R.isNil(dataToUpdate)}
          value={addedButtonTypeValue}
          label="Link para o botão"
          onChange={({ target }) => setAddedButtonTypeValue(target.value)}
        />
      )}

      {R.isNil(dataToUpdate) && (
        <Button
          className="publish-button"
          disabled={Boolean(isPublish)}
          onClick={createGooglePost}
        >
          {isPublish && <Loading className="is-button-loading" />}
          Publicar
        </Button>
      )}
    </StyledPostFormCovid19>
  );
};

export default PostFormCovid19;
