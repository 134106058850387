import React from 'react';
import * as R from 'ramda';
import { debounce } from 'lodash';

import APIGetUsers from '../../services/users/getUsers';

import { useAuth } from '../../hooks';

import ConfirmDialog from '../confirm-dialog';
import Loading from '../loading';
import Input from '../input';
import Pagination from '../pagination';

import UserRow from './user-row';

import {
  StyledChooseUserDialogContent,
  StyledDisclaimer,
} from './choose-user-dialog-styles';

const ChooseUserDialog = ({
  open = false,
  onCancel,
  onConfirm,
  selectedUser,
  setSelectedUser,
  disclaimer = '',
  onClose,
}) => {
  const {
    userAccessToken,
    userSetTokenLikeExpired,
  } = useAuth();

  const [users, setUsers] = React.useState([]);
  const [loadingUsers, setLoadingUsers] = React.useState(false);

  const [inputUserSearch, setUserSearch] = React.useState('');
  const [APIPageResponse, setAPISetPageResponse] = React.useState(0);
  const [APIPageQuantity, setAPIPageQuantity] = React.useState(0);
  const [APIPageSize, setAPIPageSize] = React.useState(10);

  const getUsers = React.useCallback(async () => {
    if (inputUserSearch) setAPISetPageResponse(0);

    const userResponse = await APIGetUsers({
      accessToken: userAccessToken,
      setTokenLikeExpired: userSetTokenLikeExpired,
      isLoading: setLoadingUsers,
      pageSize: APIPageSize,
      page: inputUserSearch ? 0 : APIPageResponse,
      query: inputUserSearch,
    });

    if (R.isNil(userResponse)) return;

    const [userListingData, userListingDataCount] = userResponse;

    setUsers(userListingData);
    setAPIPageQuantity(Math.ceil(userListingDataCount / APIPageSize));
  }, [inputUserSearch, APIPageSize, APIPageResponse]);

  React.useEffect(() => {
    getUsers();
  }, [getUsers]);

  const handleChangeUserSearch = debounce(text => setUserSearch(text), 500);

  return (
    <ConfirmDialog
      open={open}
      onCancel={onCancel}
      onConfirm={onConfirm}
      title="Usuários"
      contentNoPadding
      onClose={onClose}
    >

      {(loadingUsers && R.isEmpty(users)) ? <Loading style={{ margin: '2rem auto' }} /> : (
        <StyledChooseUserDialogContent>
          {disclaimer && (
          <StyledDisclaimer>
            {disclaimer}
          </StyledDisclaimer>
          )}

          <Input
            placeholder="Pesquisar usuário"
            autoFocus
            className="search-to-locations"
            inputOptions={{
              type: 'text',
            }}
            onChange={({ target: { value } }) => {
              handleChangeUserSearch(value);
            }}
            border
          />

          {!R.isEmpty(users) && users.map(user => (
            <UserRow
              key={user.id}
              userId={user.id}
              userName={user.name}
              userEmail={user.email}
              userPhone={user.phone}
              userCompanyLimit={user.company_limit}
              userLocationsLimit={user.location_limit}
              userCompanyGroupLimit={user.company_group_limit}
              userLocationGroupLimit={user.location_group_limit}
              selectedUser={selectedUser}
              setSelectedUser={setSelectedUser}
            />
          ))}

          {(APIPageQuantity !== 0) && (
            <Pagination
              className="choose-user-pagination"
              page={APIPageResponse}
              setPage={setAPISetPageResponse}
              pagesQuantity={APIPageQuantity}
            />
          )}
        </StyledChooseUserDialogContent>
      )}
    </ConfirmDialog>
  );
};

export default ChooseUserDialog;
