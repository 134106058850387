import styled from 'styled-components';
import { rgba } from 'polished';

export const StyledLoading = styled.div`
    position: absolute;
    z-index: 10;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background: ${props => rgba(props.theme.colors.black, 0.80)};
    display: flex;
    align-items: center;
    justify-content: center;

    .text-disclaimer {
        font-size: 1.4rem;
        color: ${props => props.theme.colors.white};
        margin-left: 0.5rem;
    }
`;
