import React from 'react';

import ConfigCard from '../../../components/config-card';

import {
  StyledPlatformsLinkWithLocation,
} from './platforms-link-with-location-styles';

const HandlerList = ({ navigation }) => (
  <ConfigCard
    title="Vinculação de locais com plataformas"
    onClick={() => navigation.push('/platforms-link-with-location')}
  >
    <StyledPlatformsLinkWithLocation>
      Vincular um local com varias plataformas como:
      {' '}
      <b>Facebook, </b>
      <b>Google, </b>
      <b>Foursquare </b>
      {' '}
      e outras.
    </StyledPlatformsLinkWithLocation>
  </ConfigCard>
);

export default HandlerList;
