import React from 'react';
import { Helmet } from 'react-helmet';
import { useHistory } from 'react-router-dom';

import LocationAccessLevelProfiles from './local-access-level-profiles';
import PlatformsLinkWithLocation from './platforms-link-with-location';

import { useAuth } from '../../hooks';

import {
  StyledUserConfigs,
  StyledHeader,
  StyledContent,
} from './user-configs-styles';

const UserConfigs = () => {
  const history = useHistory();

  const { userProfileName } = useAuth();

  return (
    <StyledUserConfigs>
      <Helmet>
        <meta property="og:title" content="Configurações Pessoas - SaaS Hublocal" />
        <meta property="og:description" content="Configurações do usuário." />

        <title>Configurações Pessoais - SaaS Hublocal</title>
      </Helmet>

      <StyledHeader>
        <h2>Configurações Pessoais</h2>
      </StyledHeader>
      <StyledContent>
        <LocationAccessLevelProfiles navigation={history} />
        <PlatformsLinkWithLocation navigation={history} />

        {userProfileName === 'Admin' && (
          <button type="button" onClick={() => history.push('/batch-update-info-status')}>Infos</button>
        )}
        {userProfileName === 'Admin' && (
          <button type="button" onClick={() => history.push('/batch-update-posts-status')}>Posts</button>
        )}
        {userProfileName === 'Admin' && (
          <button type="button" onClick={() => history.push('/gallery-batch')}>Galeria</button>
        )}
        {userProfileName === 'Admin' && (
          <button type="button" onClick={() => history.push('/service-batch')}>Serviços</button>
        )}
        {userProfileName === 'Admin' && (
          <button type="button" onClick={() => history.push('/newreviews')}>Refatoração - Modulo de Avaliação</button>
        )}
      </StyledContent>
    </StyledUserConfigs>
  );
};

export default UserConfigs;
