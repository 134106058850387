import styled from 'styled-components';
import { devices } from '../../../assets/styles/devices';

export const StyledBottomActions = styled.div`
    display: flex;
    flex-direction: column;
    @media ${devices.tablet} {
      flex-direction: row;
      justify-content: center; 
      margin: 1rem 0;
    }


    .button {
      margin-bottom: 2rem;
      &:last-child {
        margin-bottom: 0;
      }
      @media ${devices.tablet} {
        margin: 0 1rem;
      }
    }

    .is-loading-confirm-dialog {
      margin-right: 1rem;
    }
`;

export const StyledWarningMessage = styled.p<{ isPrimaryColor: boolean }>`
  color: ${props => (props.isPrimaryColor ? props.theme.colors.primary : props.theme.colors.black)};
  font-weight: bold;
  text-align: center;
`;

export const StyledMainMessage = styled.p`
  color: ${props => props.theme.colors.red};
  font-weight: bold;
`;

export const StyledSubMessage = styled.p``;

export const StyledUnlinkedLocationsList = styled.div``;

export const StyledUnlinkedLocation = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  p {
    margin: 0;
  }
`;

export const StyledCompanyWithUnlinkedSection = styled.div`
  display: flex;
  flex-direction: column;
`;

export const StyledDialogBody = styled.div`
  display: flex;
  padding: 2rem;
  flex-direction: column;

  hr {
    margin: 2rem 0;
  }
`;
