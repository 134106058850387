import api from './api';

const getUserInfo = async (facebookUserToken: string, fields: string) => {
  try {
    const url = 'v15.0/me';
    const params = {
      access_token: facebookUserToken,
      fields,
    };

    const response: any = await api({
      method: 'GET',
      url,
      params,
    });

    return response;
  } catch (err) {
    console.log(err);
    return null;
  }
};

export default getUserInfo;
