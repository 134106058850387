import styled from 'styled-components';

import { devices } from '../../assets/styles/devices';

export const StyledEditCompanyGroup = styled.div`
    display: flex;
    flex-direction: column;

    .block-form {
        margin-bottom: 3rem;
    }
`;

export const StyledIconButton = styled.div`
    margin-right: 1rem;
`;

export const StyledButtonsGroup = styled.div`
    display: flex;
    margin-left: auto;

    .button {
        margin-right: 1.5rem;

        &:last-of-type {
            margin-right: 0;
        }
    }
`;

export const StyledContent = styled.div`
    display: grid;
    grid-template-columns: 1fr;
    row-gap: 2rem;
    padding: 3rem 2rem 2rem;

    @media ${devices.tabletLandscape} {
        grid-template-columns: 75% 23%;
        column-gap: 2%;
    }
`;

export const StyledContentMain = styled.div`
    display: flex;
    flex-direction: column;
`;

export const StyledContentAside = styled.aside`
    display: flex;
    flex-direction: column;
`;
