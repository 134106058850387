import styled from 'styled-components';
import { shade } from 'polished';
import { Error } from '@material-ui/icons';

export const StyledChecklistItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid ${props => shade(0.1, props.theme.colors.white)};
  padding-bottom: 0.5rem;
  margin-top: 0.5rem;

  &:last-of-type {
    border-bottom: none;
  }
`;

export const StyledLocationRow = styled.div`
`;

export const StyledLabel = styled.h4`
  margin-right: auto;
  font-weight: 400;
  line-height: 2rem;
  font-size: 1.3rem;
  margin-bottom: 0;
`;
