import axios, { AxiosResponse } from 'axios';
import type {
  TBatchGallery,
  TUpdateBatchGallery,
} from '../../../types/TBatchGallery';

export const updateBatchGallery = async ({
  batchGalleryId,
  userAccessToken,
  status,
  isUpdating,
}: TUpdateBatchGallery): Promise<{
  status: 'ERROR' | 'SUCCESS';
  data: TBatchGallery | object;
}> => {
  try {
    if (isUpdating) isUpdating(true);

    const { data }: AxiosResponse<TBatchGallery> = await axios.put(
      `${process.env.REACT_APP_SAAS_BATCH_GOOGLE_MODULES}/gallery-batch/${batchGalleryId}`,
      {
        status,
      },
      {
        headers: {
          Authorization: `Bearer ${userAccessToken}`,
        },
      }
    );

    return {
      status: 'SUCCESS',
      data,
    };
  } catch (err: any) {
    return {
      status: 'ERROR',
      data: err.response.data,
    };
  } finally {
    if (isUpdating) isUpdating(false);
  }
};
