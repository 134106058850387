import React from 'react';
import { GoogleLogout } from 'react-google-login';
import { TGoogleLoggedAccount } from '../../../../../types/TPlatformsLinkWithLocation';
import {
  StyledGoogleLoggedAccountWrapper,
  StyledInfoWrapper,
  StyledLogoutButton,
} from './google-logget-account-styles';

const GoogleLoggedAccount = ({ handleGoogleLogout, loggedGoogleEmail }: TGoogleLoggedAccount) => (
  <StyledGoogleLoggedAccountWrapper>
    <StyledInfoWrapper>
      <b>Conta conectada:</b>
      <p>{loggedGoogleEmail}</p>
    </StyledInfoWrapper>
    <GoogleLogout
      clientId={String(process.env.REACT_APP_GOOGLE_CLIENT_ID)}
      onLogoutSuccess={handleGoogleLogout}
      render={(renderProps) => (
        <StyledLogoutButton onClick={renderProps.onClick}>Alterar conta</StyledLogoutButton>)}
    />
  </StyledGoogleLoggedAccountWrapper>
);

export default GoogleLoggedAccount;
