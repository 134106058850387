import styled from 'styled-components';
import { lighten, shade } from 'polished';

export const StyledGoogle = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const StyledHeader = styled.header`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const StyledHeaderPlatform = styled.div`
  display: flex;
  align-items: center;

  .platform-icon {
    width: 2rem;
    height: 2rem;
    margin-right: 1rem;
  }

  h3 {
    font-size: 2rem;
    margin-bottom: 0;
  }
`;

export const StyledHeaderWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

export const StyledHeaderActions = styled.div`
  display: flex;
  align-items: center;

  .platform-connected .icon {
    fill: ${props => props.theme.colors.green} !important;
  }

  .platform-disconnect .icon {
    fill: ${props => props.theme.colors.red} !important;
  }

  .platform-show-location .icon {
    fill: ${props => props.theme.colors.black} !important;
  }
`;

export const StyledContent = styled.div`
  display: flex;
  flex-direction: column;

  .google-review-config-dropdown {
    padding: 0;
    border-right: 0;
    width: max-content;
    height: auto;
    margin-bottom: 2rem;

    &:hover {
      background: transparent;
    }
    
    h2 {
      font-size: 1.3rem;
      margin-bottom: 1rem;
    }
    
    li {
      height: 2.9rem;
    }
  }
  
  .google-reviews-config-input-search {
    margin-bottom: 0.5rem;
  }

  .loading-location-rows {
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

export const StyledLocationsList = styled.ul`
  display: flex;
  flex-direction: column;
  max-height: 50rem;
  overflow-y: scroll;
  margin-bottom: 0.1rem;
`;

export const StyledEmptyBindedLocations = styled.div`
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  margin-top: 1rem;

  span {
    color: ${props => props.theme.colors.primary};
    text-decoration: underline;
    cursor: pointer;
  }
`;

export const StyledLocationsWrapper = styled.div`
  margin-top: 2rem;
`;

export const StyledLoadingWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 2rem;
`;
