import styled from 'styled-components';
import { Cancel } from '@material-ui/icons';
import { shade } from 'polished';

export const StyledUploadedImagesListing = styled.ul`
  align-items: flex-start;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  column-gap: 1.5rem;
  row-gap: 1.5rem;
  margin-bottom: 3rem;
`;

export const StyledUploadedImageListingItem = styled.li`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 0.1rem solid ${props => props.theme.colors.primary};
  border-radius: 0.5rem;
  padding: 1rem;
  height: 100%;

  img,
  video {
    position: relative;
    z-index: 1;
    object-fit: cover;
  }
  
  video {
    max-width: 15rem;
  }
`;

export const StyledRemoveUploadedImage = styled(Cancel)`
  position: absolute;
  top: -1.2rem;
  right: -1.2rem;
  z-index: 2;
  fill: ${props => props.theme.colors.red};
  width: 3rem;
  height: 3rem;
  cursor: pointer;
  transition: 0.3s;
  background: ${props => props.theme.colors.white};
  border-radius: 3rem;
  box-shadow: 0.1rem 0.1rem rgba(${props => props.theme.colors.black}, 0.5);
  
  &:hover {
    transform: scale(1.1);
  }
`;
