import axios from 'axios';

export default async function deleteSpecialDate({
  accessToken,
  id,
  setTokenLikeExpired,
  isLoading,
}) {
  const requestOptions = {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  };

  try {
    if (isLoading) isLoading(true);
    const { data } = await axios.delete(
      `${process.env.REACT_APP_SPECIAL_DATE_API_URL}/${id}`,
      requestOptions,
    );

    return data;
  } catch (err) {
    if (err.response?.status === 401 && setTokenLikeExpired) return setTokenLikeExpired();

    return err;
  } finally {
    if (isLoading) isLoading(false);
  }
}
