import React from 'react';

import { THeaderToolbar } from '../../types/THeaderToolbar';

import DropdownCompanyOrGroupDialog from '../dropdown-company-or-group-dialog';
import DropdownCompaniesDialog from '../dropdown-companies-dialog';
import DropdownLocationsDialog from '../dropdown-locations-dialog';
import DropdownLocationGroupsDialog from '../dropdown-location-groups-dialog';

import {
  StyledHeaderToolbar,
  StyledHeaderDropdownWrapper,
  StyledTitlePage,
  StyledRightControls,
} from './new-header-toolbar-styles';

const NewHeaderToolbar = ({
  children,
  className,
  paddingLeft,
  paddingRight,
  title,
  dropdownToggle = false,
  dropdownToggleWithLocations = false,
  dropdownsCompany = false,
  dropdownsLocation = false,
  dropdownsLocationGroups,
  as = null,
  overlayLoadingNoDisplay = false,
  shouldDisableOptionForLocationGuest = false,
}: THeaderToolbar) => (
  <StyledHeaderToolbar
    as={as}
    className={className}
    paddingLeft={paddingLeft}
    paddingRight={paddingRight}
  >
    {title && (
      <StyledTitlePage>
        {title}
      </StyledTitlePage>
    )}

    <StyledHeaderDropdownWrapper>
      {dropdownToggle && (
        <>
          <DropdownCompanyOrGroupDialog />
          {dropdownToggleWithLocations && <DropdownLocationsDialog />}
        </>
      )}
      {dropdownsCompany && <DropdownCompaniesDialog overlayLoadingNoDisplay={overlayLoadingNoDisplay} shouldDisableOptionForLocationGuest={shouldDisableOptionForLocationGuest} />}
      {dropdownsLocation && <DropdownLocationsDialog />}
      {dropdownsLocationGroups && <DropdownLocationGroupsDialog />}
    </StyledHeaderDropdownWrapper>

    {children && (
      <StyledRightControls>
        {children}
      </StyledRightControls>
    )}
  </StyledHeaderToolbar>
);

export default NewHeaderToolbar;
