import axios from 'axios';

export default async function fetchVerificationOptions(
  accessToken,
  userId,
  locationId,
  addressLines,
  regionCode,
  postalCode,
  locality,
  administrativeArea,
  businessType,
) {
  const requestOptions = {
    headers: {
      'access-token': accessToken,
    },
  };

  try {
    const { data } = await axios.post(
      `${process.env.REACT_APP_GOOGLE_API_URL}/fetch/verification/options/${userId}/${locationId}`,
      {
        businessType, addressLines, regionCode, postalCode, locality, administrativeArea,
      },
      requestOptions,
    );

    return data;
  } catch (err) {
    console.log(err.response);
    return err.response;
  }
}
