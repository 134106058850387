import axios from 'axios';

export default async function validateUser(passwordToken) {
  try {
    const { data } = await axios.post(
      `${process.env.REACT_APP_AUTH_API_URL}/validate-user/`,
      { passwordToken },
    );

    return data;
  } catch (err) {
    return err;
  }
}
