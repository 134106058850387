import styled from 'styled-components';
import MUIDataTable from 'mui-datatables';

export const StyledGroupDataTable = styled(MUIDataTable)`
  width: 100%;
  border-radius: 0 !important;

  .loading {
    margin: 0 auto;
  }

  .tag {
    color: ${props => props.theme.colors.white};
    border-radius: 5rem;
    padding: 0.5rem 2rem;
    text-transform: capitalize;
    font-size: 1.2rem;

    &.owner {
      background-color: ${props => props.theme.colors.primary};
    }

    &.guest {
      background-color: ${props => props.theme.colors.secondary};
    }
  }

  .MuiTypography-h6 {
    font-family: Poppins;
    font-size: 2rem;
  }

  .MuiButton-label{
    font-size: 1.5rem;
  }
  
  .MuiTableCell-head {
    font-size: 1.5rem;
    font-weight: 600;
    font-family: Poppins;
    border-bottom: none;
    vertical-align: middle;
  }

  .MuiTableCell-body {
    font-size: 1.5rem;
    font-weight: 400;
    font-family: Poppins;
    vertical-align: middle;
  }

  .MuiTableCell-root {
    border-bottom: none;
  }

  .MuiSvgIcon-root {
    width: 2.4rem;
    height: 2.4rem;
    fill: ${props => props.theme.colors.primary};
  }

  .MuiPaper-root {
    background: ${props => props.theme.colors.primary};
    border-top-left-radius: 0;
    border-top-right-radius: 0;

    .MuiTypography-subtitle1 {
      font-size: 1.6rem;
      color: ${props => props.theme.colors.white};
    }
  }

  .MuiTablePagination-toolbar {
    .MuiTablePagination-caption,
    .MuiTablePagination-input {
      font-size: 1.5rem;
      font-family: Poppins;
    }
  }

  .MuiChip-label {
    font-size: 1.5rem;
  }

  .MuiToolbar-root {
    div[class*="MUIDataTableToolbar-actions"] {
      display: flex;
      align-items: center;
      flex-direction: row-reverse;
    }
  }

  .MuiInputBase-root   {
    font-size: 1.4rem;
  }

  .is-removing-batch {
    margin: auto 0 auto 3.5rem;
  }
`;

export const StyledCellCustomButtons = styled.div`
  display: flex;

  button {
    margin: 0 1rem;
    color: ${props => props.theme.colors.white};

    svg {
      fill: ${props => props.theme.colors.white} !important;
    }

    &:last-of-type {
      margin-right: 0;
    }
  }
`;

export const StyledEmptyLabel = styled.div`
  padding: 2rem 0;
`;
