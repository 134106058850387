import React from 'react';
import * as R from 'ramda';
import { useSnackbar } from 'notistack';
import { debounce, includes } from 'lodash';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { isEmpty, isNil } from 'ramda';
import {
  BatchHeader,
  Button,
  Checklist,
} from '../../components';

import InfoUpdate from './info-update';

import UpdateInfoContext from './update-info-context';

import { useAuth, useHasAccess } from '../../hooks';

import { isValidWorkingHours, isInvalidPhone } from './helpers';

import APIBatchUpdateInfo from '../../services/google-batch-modules/batchUpdateInfo';
import APICreateBatchInfo from '../../services/batch-update/batchCreateInfos';
import APIGetAllGroupLocations from '../../services/locations/getAllGroupLocation';
import APIGetCompanyLocations from '../../services/locations/getCompanyLocations';
import { findBatchInfos as APIFindBatchInfos } from '../../services/google-batch-modules/batch-infos/find-batch-infos';

import type { TChecklistType } from '../../types/TChecklist';
import type { TBatchWorkingHoursRef } from '../../types/TWorkingHours';
import type { TLocationFormData } from '../../types/TLocationProfile';
import type { TRootStateRedux } from '../../types/TRootStateRedux';
import {
  EBatchPlatformGroupType,
  EBatchPlatformStatus,
  TGetActiveGroupTypeResponse,
} from '../../types/TBatchGoogleModules';

import {
  StyledBatchUpdateInfo,
  StyledContent,
  StyledTitle,
  StyledInfo,
  StyledGroup,
  StyledSideContent,
  StyledStickyContentWrapper,
} from './batch-update-info-form-styles';
import { getLocationsWithoutLinked } from '../../services/locations/get-locations-without-linked';
import { EGroupType } from '../../types/TLocation';

const BatchUpdateInfoForm = () => {
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();

  const {
    userAccessToken,
    userSetTokenLikeExpired,
    userName,
    userId,
    userEmail,
  } = useAuth();

  const { activeLocationGroupId, showLocationsByGroup } = useSelector(
    (state: TRootStateRedux) => state.LocationGroupReducer,
  );
  const { activeCompanyId, showLocationsByCompany } = useSelector(
    (state: TRootStateRedux) => state.CompanyReducer,
  );

  const [expanded, setExpanded] = React.useState('panel1');
  const [locationFormData, setLocationFormData] = React.useState<TLocationFormData | null>(null);
  const [updatedWorkingHours, setUpdatedWorkingHours] = React.useState(false);

  const [categoryGroupingsSelected, setCategoryGroupingsSelected] = React.useState<any[]>([]);
  const [selectedGoogleCategories, setSelectedGoogleCategories] = React.useState<any>([]);
  const [selectedFacebookCategories, setSelectedFacebookCategories] = React.useState<any>([]);
  const [selectedFoursquareCategories, setSelectedFoursquareCategories] = React.useState<any>([]);

  const [infoFields, setInfoFields] = React.useState<any[]>([]);
  const [linksFields, setLinksFields] = React.useState<any[]>([]);
  const [otherLinksFields, setOtherLinksFields] = React.useState<any[]>([]);

  const workingHoursRef = React.useRef<TBatchWorkingHoursRef | null>(null);
  const specialDatesRef = React.useRef<{ specialDates: any } | null>(null);

  const [locationsList, setLocationsList] = React.useState<any[]>([]);
  const [locationsListNoElegibles, setLocationsListNoElegibles] = React.useState(0);
  const [locationsListTotalLength, setLocationsListTotalLength] = React.useState(0);
  const [locationsListPageIndex, setLocationsListPageIndex] = React.useState(0);
  const [locationsListPageSize, setLocationsListPageSize] = React.useState(10);
  const [locationsListPageQuantity, setLocationsListPageQuantity] = React.useState(0);
  const [locationsListQuery, setLocationsListQuery] = React.useState('');
  const [locationsToExclude, setLocationsToExclude] = React.useState<TChecklistType>({
    type: 'EXCLUDED',
    ids: [],
  });
  const [masterCheckbox, setMasterCheckbox] = React.useState(true);

  const [isBatchCreating, setIsBatchCreating] = React.useState(false);
  const [disabledCreateButton, setDisabledCreateButton] = React.useState(false);

  const setFieldsToDefault = () => {
    infoFields.map((field) => {
      field.setDefaultValue(field.getValue());
    });

    linksFields.map((field) => {
      field.setDefaultValue(field.getValue());
    });

    otherLinksFields.map((field) => {
      field.setDefaultValue(field.getValue());
    });
  };

  const mountDataToUpdate = React.useCallback(() => {
    const categoryMapped = ({
      created_by,
      date_created,
      date_updated,
      id,
      ...rest
    }: any) => rest;

    const replaceByUnderline = (value: string) => value.replace('-', '_');
    const getFieldValue = (value: any, field: string) => {
      if (includes(value, 'MUST_BE_EMPTY')) return value;

      return value || null;
    };

    const infoFieldsData = R.mergeAll(
      infoFields.map((field) => ({
        [replaceByUnderline(field.name)]: getFieldValue(
          field.getValue(),
          field.name,
        ),
      })),
    );

    const linksFieldsData = R.mergeAll(
      linksFields.map((field) => ({
        [replaceByUnderline(field.name)]: field.getValue() || null,
      })),
    );

    const otherLinksFieldsData = R.mergeAll(
      otherLinksFields.map((field) => ({
        [replaceByUnderline(field.name)]: field.getValue() || null,
      })),
    );

    const googleCategories = selectedGoogleCategories !== 'MUST_BE_EMPTY'
      ? selectedGoogleCategories.map(categoryMapped)
      : selectedGoogleCategories;
    const facebookCategories = selectedFacebookCategories !== 'MUST_BE_EMPTY'
      ? selectedFacebookCategories.map(categoryMapped)
      : selectedFacebookCategories;
    const foursquareCategories = selectedFoursquareCategories !== 'MUST_BE_EMPTY'
      ? selectedFoursquareCategories.map(categoryMapped)
      : selectedFoursquareCategories;

    let mountedFields = {};

    const workingHours = R.equals(
      workingHoursRef.current?.initialWorkingHours,
      workingHoursRef.current?.workingHours,
    )
      ? null
      : workingHoursRef.current?.workingHours;

    if (!R.isEmpty(workingHours) && !R.isNil(workingHours)) {
      const workingHoursWithNoDeletedTimes = workingHours.map(
        (workingHour) => ({
          ...workingHour,
          times: workingHour.times?.filter((time) => !time.is_deleted),
        }),
      );
      mountedFields = {
        ...mountedFields,
        working_hours: workingHoursWithNoDeletedTimes,
      };
    }

    const specialDates = specialDatesRef.current?.specialDates;
    if (!R.isEmpty(specialDates)) mountedFields = { ...mountedFields, special_dates: specialDates };

    const links = {
      ...linksFieldsData,
      ...otherLinksFieldsData,
    };

    const hasLinks = R.values(links).some((link) => !R.isNil(link));
    if (hasLinks) mountedFields = { ...mountedFields, links };

    const basicInfos = {
      ...infoFieldsData,
      google_categories: R.isEmpty(googleCategories) ? null : googleCategories,
      facebook_categories: R.isEmpty(facebookCategories)
        ? null
        : facebookCategories,
      foursquare_categories: R.isEmpty(foursquareCategories)
        ? null
        : foursquareCategories,
    };

    const hasBasicInfos = R.values(basicInfos).some(
      (basicInfo) => !R.isNil(basicInfo),
    );

    if (hasBasicInfos) mountedFields = { ...mountedFields, basic_infos: basicInfos };

    return mountedFields;
  }, [
    infoFields,
    linksFields,
    otherLinksFields,
    workingHoursRef,
    specialDatesRef,
    categoryGroupingsSelected,
    selectedGoogleCategories,
    selectedFacebookCategories,
    selectedFoursquareCategories,
  ]);

  const getGroupToBatchInfos = React.useCallback((): {
    groupType: string;
    groupId: number;
  } => {
    if (showLocationsByCompany) {
      return {
        groupType: 'COMPANY',
        groupId: activeCompanyId,
      };
    }

    return {
      groupType: 'LOCATION_GROUP',
      groupId: activeLocationGroupId,
    };
  }, [
    showLocationsByGroup,
    showLocationsByCompany,
    activeCompanyId,
    activeLocationGroupId,
  ]);

  const handleChangeCategories = (
    category: any[],
    setCategory: React.Dispatch<React.SetStateAction<any[]>>,
  ) => {
    setFieldsToDefault();
    setCategory(category);
  };

  const handleChangeTab = (panel: any) => (event: any, isExpanded: any) => {
    setFieldsToDefault();
    setExpanded(isExpanded ? panel : false);
  };

  const isValidUpdateData = React.useCallback((dataInfos: any): boolean => {
    if (isInvalidPhone(dataInfos.basic_infos?.main_phone)) {
      enqueueSnackbar(
        'Telefone principal inválido, favor ajustar e tentar novamente!',
        { variant: 'warning' },
      );
      return false;
    }

    if (isInvalidPhone(dataInfos.basic_infos?.secondary_phone)) {
      enqueueSnackbar(
        'Telefone secundário inválido, favor ajustar e tentar novamente!',
        { variant: 'warning' },
      );
      return false;
    }

    const isValidWorkHours = isValidWorkingHours(dataInfos.working_hours);

    if (
      !isValidWorkHours
      && !isEmpty(dataInfos.working_hours)
      && !isNil(dataInfos.working_hours)
    ) {
      enqueueSnackbar(
        'Horário de Funcionamento inválido, favor ajustar e tentar novamente!',
        { variant: 'warning' },
      );
      return false;
    }

    if (!isNil(dataInfos.links)) {
      const isLinksValid = linksFields.every(field => field.firedCheck());
      const isOtherLinksValid = otherLinksFields.every(field => field.firedCheck());
      if (!isLinksValid || !isOtherLinksValid) return false;
    }

    return true;
  }, [linksFields, otherLinksFields]);

  const submitUpdate = React.useCallback(async () => {
    setTimeout(async () => {
      const dataInfos: any = mountDataToUpdate();

      if (R.isEmpty(dataInfos)) return;

      if (!isValidUpdateData(dataInfos)) return;

      const { groupId, groupType } = getGroupToBatchInfos();

      const newBatchInfosResponse = await APICreateBatchInfo({
        dataInfos,
        groupId,
        groupType,
        accessToken: userAccessToken,
        setTokenLikeExpired: userSetTokenLikeExpired,
        setIsLoading: setIsBatchCreating,
        locations: locationsToExclude,
        userData: {
          user_id: userId,
          user_email: userEmail,
          user_name: userName,
        },
      });

      if (R.isNil(newBatchInfosResponse)) return;

      setFieldsToDefault();
      history.push('/batch-update-info-status');
    }, 300);
  }, [
    getGroupToBatchInfos,
    mountDataToUpdate,
    isValidWorkingHours,
    locationsToExclude,
  ]);

  const getActiveCompanyOrGroupLocations = React.useCallback(async () => {
    if (showLocationsByCompany) {
      const getActiveCompanyLocationsResponse = await APIGetCompanyLocations({
        accessToken: userAccessToken,
        companyId: activeCompanyId,
        query: locationsListQuery,
        page: locationsListPageIndex,
      });

      if (R.isNil(getActiveCompanyLocationsResponse)) return;
      const [companyLocationsData, companyLocationsCount] = getActiveCompanyLocationsResponse;

      const noElegiblesLocations = await getLocationsWithoutLinked({
        group: EGroupType.COMPANY,
        groupId: activeCompanyId,
        userAccessToken,
      });

      setLocationsListNoElegibles(noElegiblesLocations?.count || 0);

      setLocationsList(companyLocationsData);
      setLocationsListTotalLength(companyLocationsCount);
      setLocationsListPageQuantity(
        Math.ceil(companyLocationsCount / locationsListPageSize),
      );
    }

    if (showLocationsByGroup) {
      const getActiveGroupLocationsResponse = await APIGetAllGroupLocations({
        accessToken: userAccessToken,
        locationGroupId: activeLocationGroupId,
        page: locationsListPageIndex,
        query: locationsListQuery,
      });

      if (R.isNil(getActiveGroupLocationsResponse)) return;
      const [groupLocationsData, groupLocationsCount] = getActiveGroupLocationsResponse;

      const noElegiblesLocations = await getLocationsWithoutLinked({
        group: EGroupType.LOCATIONS_GROUP,
        groupId: activeLocationGroupId,
        userAccessToken,
      });

      if (isNil(noElegiblesLocations)) return;

      setLocationsListNoElegibles(noElegiblesLocations?.count || 0);
      setLocationsList(groupLocationsData);
      setLocationsListTotalLength(groupLocationsCount);
      setLocationsListPageQuantity(
        Math.ceil(groupLocationsCount / locationsListPageSize),
      );
    }
  }, [
    showLocationsByGroup,
    showLocationsByCompany,
    userAccessToken,
    activeCompanyId,
    activeLocationGroupId,
    locationsListPageIndex,
    locationsListPageQuantity,
    locationsListQuery,
    locationsToExclude,
    locationsListNoElegibles,
    isValidUpdateData,
  ]);

  const handleSearchLocation = (locationName: string) => {
    setLocationsListPageIndex(0);
    setLocationsListQuery(locationName);
  };

  const handleInputSearch = debounce(
    (value) => handleSearchLocation(value),
    500,
  );

  const activeGroupType = React.useMemo((): TGetActiveGroupTypeResponse => {
    if (showLocationsByCompany) {
      return {
        type: 'COMPANY' as EBatchPlatformGroupType,
        id: activeCompanyId,
      };
    }
    return {
      type: 'LOCATIONS_GROUP' as EBatchPlatformGroupType,
      id: activeLocationGroupId,
    };
  }, [
    showLocationsByGroup,
    showLocationsByCompany,
    activeLocationGroupId,
    activeCompanyId,
  ]);

  const getPendingBatchInfos = React.useCallback(async () => {
    const { data: batchInfosData, status: batchInfosStatus } = await APIFindBatchInfos({
      userAccessToken,
      groupType: activeGroupType.type,
      groupTypeId: activeGroupType.id,
    });

    if (batchInfosStatus === 'ERROR') return;
    if (isEmpty(batchInfosData[0])) return;

    if (!isEmpty(batchInfosData[0].filter((batchInfo: any) => batchInfo.status === 'PENDING' || batchInfo.status === 'PROCESSING'))) {
      enqueueSnackbar('Uma atualização de informação está em andamento ou pendente, aguarde o término antes de solicitar outra', { variant: 'warning' });
      setDisabledCreateButton(true);
    }
  }, [activeGroupType]);

  React.useEffect(() => {
    getPendingBatchInfos();
  }, [getPendingBatchInfos]);

  React.useEffect(() => {
    getActiveCompanyOrGroupLocations();
  }, [getActiveCompanyOrGroupLocations]);

  const UpdateInfoContextValue = {
    userAccessToken,
    userSetTokenLikeExpired,
    infoFields,
    setInfoFields,
    linksFields,
    setLinksFields,
    otherLinksFields,
    setOtherLinksFields,
    categoryGroupingsSelected,
    setCategoryGroupingsSelected,
    selectedGoogleCategories,
    setSelectedGoogleCategories,
    selectedFacebookCategories,
    setSelectedFacebookCategories,
    selectedFoursquareCategories,
    setSelectedFoursquareCategories,
    handleChangeCategories,
    locationFormData,
    updatedWorkingHours,
    handleChangeTab,
    workingHoursRef,
    specialDatesRef,
    expanded,
    setExpanded,
    setFieldsToDefault,
  };

  return (
    <StyledBatchUpdateInfo>
      <BatchHeader />
      <StyledContent>
        <StyledTitle>Informações</StyledTitle>

        <StyledGroup>
          <UpdateInfoContext.Provider value={UpdateInfoContextValue}>
            <StyledInfo>
              <InfoUpdate />
            </StyledInfo>
          </UpdateInfoContext.Provider>

          <StyledSideContent>
            <StyledStickyContentWrapper>
              <StyledInfo>
                <Button
                  onClick={() => submitUpdate()}
                  className="info-nav-button"
                  disabled={isBatchCreating || disabledCreateButton}
                >
                  {isBatchCreating ? 'Atualizando...' : 'Atualizar Informações'}
                </Button>

                <Button
                  onClick={() => history.push('/batch-update-info-status')}
                  className="info-nav-button"
                  buttonType="secondary"
                >
                  Voltar
                </Button>
              </StyledInfo>

              <Checklist
                options={locationsList}
                optionsTotalLength={locationsListTotalLength}
                optionsNoEllegible={locationsListNoElegibles}
                optionsPageIndex={locationsListPageIndex}
                setOptionsPageIndex={setLocationsListPageIndex}
                optionsPageQuantity={locationsListPageQuantity}
                handleInputSearch={handleInputSearch}
                selectedOptions={locationsToExclude}
                setSelectedOptions={setLocationsToExclude}
                masterCheckbox={masterCheckbox}
                setMasterCheckbox={setMasterCheckbox}
                showWarningWorkingHours
              />
            </StyledStickyContentWrapper>
          </StyledSideContent>
        </StyledGroup>
      </StyledContent>
    </StyledBatchUpdateInfo>
  );
};

export default BatchUpdateInfoForm;
