import { useSnackbar } from 'notistack';
import * as R from 'ramda';
import React, { useCallback } from 'react';

import UncontrolledField from '../../components/uncontrolled-field';

import { checkMessage, setLocationDataInFields } from './helpers';
import initialFieldScheme from './initial-field-scheme';

import checkField from './check-field';

const useUncontrolledField = ({
  fieldScheme,
  saveIn,
  refOfAllFields = null,
  isOutsideBrazil = false,
}) => {
  const { enqueueSnackbar } = useSnackbar();

  const fieldRef = React.useRef(null);
  const switchRef = React.useRef({ value: false });
  const isDisabledInputRef = React.useRef({ value: false });

  const [fieldSchemeData, setFieldSchemeData] = React.useState(fieldScheme || initialFieldScheme);
  const { ignoreField } = fieldScheme;

  const firedCheck = (outside = false) => {
    if (ignoreField) return true;

    const value = fieldRef?.current?.value || '';
    const nickname = fieldScheme.nickname || '';

    const message = checkMessage(enqueueSnackbar);

    if (!fieldScheme.required && !value.trim()) return true;

    return checkField({
      message, value, nickname, scheme: fieldScheme, isOutsideBrazil: outside,
    });
  };

  const setDefaultValue = value => {
    if (ignoreField) return;

    setFieldSchemeData(prevState => ({
      ...prevState,
      defaultValue: value === 'MUST_BE_EMPTY' ? '' : value,
    }));
  };

  const setIsDisabled = disabled => {
    if (ignoreField) return;

    setFieldSchemeData(prevState => ({ ...prevState, disabled }));
  };

  const setCurrentValue = value => {
    if (ignoreField) return;

    fieldRef.current.value = value;
  };

  const setInputFocus = () => {
    const field = fieldRef.current;
    if (R.isNil(field)) return;
    if (field.getInputDOMNode) {
      field.getInputDOMNode().focus();
      return;
    }
    field.focus();
  };

  const checkIfValueMustbeEmpty = (value) => {
    const hasUnderine = /_/g;
    const hasNumber = /\d/g;
    const isPhoneField = fieldScheme.name === 'main-phone' || fieldScheme.name === 'secondary-phone';
    const isPhoneEmpty = isPhoneField && (hasUnderine.test(value) && !hasNumber.test(value));
    if (R.isNil(value) || R.isEmpty(value) || isPhoneEmpty) return true;
    return false;
  };

  const getValue = () => {
    if (ignoreField) return;

    if (fieldScheme.typeSwitch) return switchRef?.current?.value;

    if (fieldScheme.isDisabledInput) {
      if (isDisabledInputRef?.current?.value && checkIfValueMustbeEmpty(fieldRef?.current?.value)) return 'MUST_BE_EMPTY';

      return fieldRef?.current?.value;
    }

    return fieldRef?.current?.value;
  };

  const reset = () => setFieldSchemeData(prevState => ({ ...prevState, defaultValue: '' }));

  const getZipCodeData = zipcode => setLocationDataInFields({
    zipcode,
    refOfAllFields,
    feedbackMessage: enqueueSnackbar,
    isOutsideBrazil,
  });

  React.useEffect(() => {
    saveIn((prevState) => [
      ...prevState,
      {
        name: fieldScheme.name,
        getValue,
        setDefaultValue,
        firedCheck,
        reset,
        setCurrentValue,
        setIsDisabled,
        fieldRef,
        setInputFocus,
      },
    ]);
  }, []);

  const field = useCallback(() => (
    <UncontrolledField
      {...fieldSchemeData}
      fieldRef={fieldRef}
      switchRef={switchRef}
      isDisabledInputRef={isDisabledInputRef}
      getZipCodeData={getZipCodeData}
      isOutsideBrazil={isOutsideBrazil}
    />
  ), [fieldSchemeData, isOutsideBrazil, refOfAllFields, fieldRef, switchRef, isDisabledInputRef]);

  const Field = field;

  return {
    Field,
    setDefaultValue,
    fieldProps: {
      ...fieldSchemeData,
      fieldRef,
      switchRef,
      isDisabledInputRef,
      getZipCodeData,
    },
  };
};

export default useUncontrolledField;
