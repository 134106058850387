import styled from 'styled-components';
import { Paper } from '@material-ui/core';

export const StyledReviews = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;

    .reviews-empty-feedback {
        padding: 2rem;
        background: ${props => props.theme.colors.white};
        color: ${props => props.theme.colors.red};
    }
`;

export const StyledContent = styled(StyledReviews)`
    padding: 3rem;
    align-items: center;
    width: 100%;
    margin: 0 auto;
`;

export const StyledContainer = styled(Paper)`
  margin: 2rem;
`;
