import {
  TMediaFormatByFileType,
} from '../../../types/TLocationGallery';
import { TImageFileValidation } from '../../../types/TDragAndDropFiles';

export const platforms = [
  { id: 0, value: 'Google', name: 'Google' },
];

export const categories = [
  { id: 0, value: 'CATEGORY_UNSPECIFIED', name: 'Geral' },
  { id: 1, value: 'INTERIOR', name: 'Interior' },
  { id: 2, value: 'EXTERIOR', name: 'Exterior' },
  { id: 3, value: 'AT_WORK', name: 'No Trabalho' },
  { id: 4, value: 'TEAMS', name: 'Equipe' },
  { id: 5, value: 'COVER', name: 'Capa' },
  { id: 6, value: 'PROFILE', name: 'Logo' },
];

export const types = [
  { id: 0, value: 'PHOTO', name: 'Foto' },
  { id: 1, value: 'VIDEO', name: 'Vídeo' },
];

export const mediaFormatByFileType: TMediaFormatByFileType = {
  jpeg: 'PHOTO',
  jpg: 'PHOTO',
  png: 'PHOTO',
  gif: 'PHOTO',
  apng: 'PHOTO',
  avif: 'PHOTO',
  svg: 'PHOTO',
  webp: 'PHOTO',
  bmp: 'PHOTO',
  ico: 'PHOTO',
  tiff: 'PHOTO',
  mp4: 'VIDEO',
  webm: 'VIDEO',
  ogg: 'VIDEO',
};

export const photoValidateObject: TImageFileValidation = {
  imageSize: 10240,
  imageSizeMessage: (imageName) => `O tamanho em KB da imagem ${imageName} é muito pequeno`,
  minImageHeight: 250,
  minImageWidth: 250,
  maxImageHeight: 1192,
  maxImageWidth: 2120,
  tooLargeImageMessage: (imageName) => `A imagem ${imageName} é muito grande. Tamanho max: 2120px/1192px (w/h)`,
  tooSmallImageMessage: (imageName) => `A imagem ${imageName} é muito pequena. Tamanho min: 250px/250px (w/h)`,
  wrongAspectRatioImageMessage: (imageName) => `A imagem ${imageName} não possui um formato válido`,
  isSingleUploadMessage: 'Não é possível enviar múltiplas imagens ou videos',
};
