import React from 'react';
import { FormControlLabel } from '@material-ui/core';
import { StyledCheckbox } from './checkbox-style';

export default ({
  label = '',
  labelPlacement = '',
  checked = false,
  onChange,
  indeterminate = false,
  disabled = false,
}) => (
  <FormControlLabel
    style={{
      marginRight: 0,
      marginBottom: 0,
    }}
    control={(
      <StyledCheckbox
        checked={checked}
        onChange={onChange}
        indeterminate={indeterminate}
      />
    )}
    label={label}
    labelPlacement={labelPlacement}
    disabled={disabled}
  />
);
