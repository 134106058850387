import axios from 'axios';

export default async function getAllFromCompany({
  accessToken,
  companyId,
  stars = '',
  page = 0,
  pageSize = 10,
  query = '',
  orderBy,
  setTokenLikeExpired,
  setIsFetching,
}) {
  if (setIsFetching) setIsFetching(true);

  let starsReset = stars;
  if (stars === '0') starsReset = '';

  try {
    const { data } = await axios.get(
      `${process.env.REACT_APP_AUTO_RESPONSES_API_URL}/from-company/${companyId}?stars=${starsReset}&page=${page}&pageSize=${pageSize}&query=${query}&orderBy=${orderBy}`,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      },
    );

    return data;
  } catch (err) {
    if (err.response?.status === 401 && setTokenLikeExpired) setTokenLikeExpired();

    return err;
  } finally {
    setIsFetching(false);
  }
}
