import {
  ESyncMonitoringPlatformStatus,
  TIconByStatusPropsValue,
  TSyncType,
} from './types';

export const syncEntityNames: Record<TSyncType, string> = {
  monitor: 'monitoramento',
  requests: 'solicitações',
};

export const iconByStatus: Record<ESyncMonitoringPlatformStatus, TIconByStatusPropsValue> = {
  NON_AVAILABLE: {
    tooltip: (syncEntityName) => `Não existe ${syncEntityName}.`,
    label: 'Não Existe',
    icon: 'Cancel',
    isError: true,
    tooltipVariant: 'red',
  },
  ERROR: {
    tooltip: (syncEntityName) => `${syncEntityName} possuem erros.`,
    label: 'Erro',
    icon: 'Error',
    isError: true,
    tooltipVariant: 'red',
  },
  ADJUSTED: {
    tooltip: (syncEntityName) => `Correção das ${syncEntityName} finalizada.`,
    label: 'Corrigido',
    icon: 'CheckCircle',
    isSuccess: true,
    tooltipVariant: 'green',
  },
  DONE: {
    tooltip: (syncEntityName) => `Verificação das ${syncEntityName} finalizada.`,
    label: 'Verificado',
    icon: 'CheckCircle',
    tooltipVariant: 'primary',
  },
  SKIPPED: {
    tooltip: (syncEntityName) => `Não existe ${syncEntityName}.`,
    label: 'Pulado',
    icon: 'Error',
    isWarning: true,
    tooltipVariant: 'yellow',
  },
  PENDING: {
    tooltip: (syncEntityName) => `${syncEntityName} está pendente.`,
    label: 'Pendente',
    icon: 'History',
  },
  SYNC_PENDING: {
    tooltip: () => 'O monitoramento não pôde ser realizado pois o local está aguardando aprovação/rejeição de dados sensíveis',
    label: 'Sinc. Pendente',
    icon: 'Error',
    isWarning: true,
    tooltipVariant: 'yellow',
  },
  _: {
    label: '',
    tooltip: (syncEntityName) => '',
  },
};
