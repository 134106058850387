import React from 'react';
import * as R from 'ramda';
import { useSelector } from 'react-redux';
import { Redirect, useHistory, useLocation } from 'react-router-dom';
import { CircularProgress } from '@material-ui/core';
import APIGetUserCompaniesWithGuests from '../../services/companies/getUserCompaniesWithGuests';
import APICountCompanyReplicatedLocationsOnBi from '../../services/locations/countCompanyReplicatedLocationsOnBi';
import reportErrorIllustration from '../../assets/images/report-error.png';
import { HeaderToolbar, HealderToolbarAction } from '../../components';

import {
  StyledContainer,
  StyledEmbedContainer,
  StyledLoadingContainer,
  StyledNoAcess,
} from './company-report-styles';
import { TRootStateRedux } from '../../types/TRootStateRedux';
import { useAuth, useWindowDimensions } from '../../hooks';

const CompanyReport = () => {
  const navigation = useHistory();

  const { activeCompany } = useSelector(
    (state: TRootStateRedux) => state.CompanyReducer
  );

  const { userAccessToken, userSetTokenLikeExpired, userId, userProfile } = useAuth();

  const { state: routerState } = useLocation<any>();

  const { permissionStatus } = activeCompany;

  const { width } = useWindowDimensions();

  const desktopDataStudioUrl =
    process.env.REACT_APP_DATA_STUDIO_DESKTOP_URL || '';

  const mobileDataStudioUrl =
    process.env.REACT_APP_DATA_STUDIO_MOBILE_URL || '';

  const baseUrl = width < 500 ? mobileDataStudioUrl : desktopDataStudioUrl;

  const formatUrl = (url: string): string => {
    let params = {} as any;
    if (activeCompany?.uuid)
      params = {
        ...params,
        insights_selected_company_id: activeCompany.uuid,
        posts_selected_company_id: activeCompany.uuid,
        reviews_selected_company_id: activeCompany.uuid,
      };

    const encodedParams = encodeURIComponent(JSON.stringify(params));
    const finalUrl = `${url}?params=${encodedParams}`;
    return finalUrl;
  };

  const [url, setUrl] = React.useState<string>(formatUrl(baseUrl));

  React.useEffect(() => {
    if (!R.isNil(activeCompany)) {
      setUrl(formatUrl(baseUrl));
    }
  }, [activeCompany, baseUrl]);

  const [isLoadingUserCompanies, setIsLoadingUserCompanies] =
    React.useState(false);
  const [userCompaniesCount, setUserCompaniesCount] = React.useState<
    number | null
  >(null);

  const getUserCompanies = React.useCallback(async () => {
    const userCompaniesResponse = await APIGetUserCompaniesWithGuests({
      accessToken: userAccessToken,
      userId,
      setTokenLikeExpired: userSetTokenLikeExpired,
      page: 0,
      pageSize: 1,
      setIsFetching: setIsLoadingUserCompanies,
    });
    if (!userCompaniesResponse) return;
    const [_, companiesCount] = userCompaniesResponse;
    setUserCompaniesCount(companiesCount);
  }, [userId, userAccessToken]);

  React.useEffect(() => {
    getUserCompanies();
  }, [getUserCompanies]);

  const [isLoadingReplicatedCount, setIsLoadingReplicatedCount] =
    React.useState(false);
  const [replicatedLocationsCount, setReplicatedLocationsCount] =
    React.useState<number | null>(null);

  const updateReplicatedLocationsCount = React.useCallback(async () => {
    if (!activeCompany?.id || !userAccessToken || isLoadingReplicatedCount)
      return;
    const replicatedLocations = await APICountCompanyReplicatedLocationsOnBi({
      companyId: activeCompany.id,
      accessToken: userAccessToken,
      setTokenLikeExpired: userSetTokenLikeExpired,
      setIfFetching: setIsLoadingReplicatedCount,
    });
    if (replicatedLocations !== null)
      setReplicatedLocationsCount(replicatedLocations);
  }, [activeCompany, userAccessToken]);

  React.useEffect(() => {
    updateReplicatedLocationsCount();
  }, [updateReplicatedLocationsCount]);

  const isAdminOrOperator = userProfile && userProfile.id !== 5;

  const userNotAllowedToAccess = isAdminOrOperator
    ? false
    : permissionStatus === 'locationGuest' || userCompaniesCount === 0;

  const shouldHideReport = userNotAllowedToAccess || replicatedLocationsCount === 0;

  if (
    routerState?.redirected &&
    (shouldHideReport || permissionStatus === 'locationGuest')
  ) {
    return <Redirect to="/my-companies" />;
  }

  if (shouldHideReport) {
    return (
      <StyledNoAcess>
        <img
          className="no-acess-illustration"
          src={reportErrorIllustration}
          alt="Sem acesso"
        />

        <h2>O relatório que você tentou acessar ainda não está disponível</h2>
        {permissionStatus === 'locationGuest' ? (
          <>
            <p>
              Atualmente você não possui acesso total a Empresa, apenas a um
              local (ou locais) específico.
            </p>
            <p>
              Para ter acesso ao relatório da Empresa, solicite seu acesso ao
              responsável.
            </p>
          </>
        ) : (
          <>
            <p>
              Você está tentando acessar um relatório de uma empresa sem locais ou cujos locais ainda não
              possuem conexão.
            </p>
            <p>Para ter acesso ao relatório, conecte seu local.</p>
          </>
        )}
      </StyledNoAcess>
    );
  }

  return (
    <StyledContainer>
      <HeaderToolbar dropdownsCompany shouldDisableOptionForLocationGuest>
        <HealderToolbarAction
          title="Listar Empresas"
          icon="List"
          onClick={() => navigation.push('/')}
        />
      </HeaderToolbar>
      {isLoadingUserCompanies || isLoadingReplicatedCount ? (
        <StyledLoadingContainer>
          <CircularProgress />
        </StyledLoadingContainer>
      ) : (
        <StyledEmbedContainer>
          <iframe src={url} frameBorder="0" title="teste" className="iframe" />
        </StyledEmbedContainer>
      )}
    </StyledContainer>
  );
};

export default CompanyReport;
