import React from 'react';

import { StyledTabPanel } from './tabpanel-styles';

const TabPanel = ({
  children,
  activeTab,
  activeTabIndex,
  ...props
}) => (
  <StyledTabPanel
    role="tabpanel"
    hidden={activeTab !== activeTabIndex}
    id={`full-width-tabpanel-${activeTabIndex}`}
    aria-labelledby={`full-width-tab-${activeTabIndex}`}
    {...props}
  >
    {activeTab === activeTabIndex && children}
  </StyledTabPanel>
);

export default TabPanel;
