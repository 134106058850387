import styled from 'styled-components';
import { shade } from 'polished';

import { devices } from '../../../assets/styles/devices';

export const StyledCurrentOverview = styled.div`
  display: flex;
  flex-direction: column;
  border: 0.1rem solid ${(props) => shade(0.5, props.theme.colors.white)};
  border-radius: 0.5rem;
`;

export const StyledHeader = styled.header`
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${(props) => props.theme.colors.primary};
  padding: 1rem 2rem;
`;

export const StyledHeaderTitle = styled.h3`
  font-weight: 700;
  font-size: 1.5rem;
  text-align: center;
  color: ${(props) => props.theme.colors.white};
  width: 100%;
`;

export const StyledContent = styled.div`
  padding: 2rem;
  background: ${(props) => props.theme.colors.white};
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  column-gap: 2rem;
  row-gap: 2rem;

  @media (min-width: 360px) {
    grid-template-columns: repeat(3, 1fr);
  }
`;
