import axios from 'axios';

export default async function updateMenu(
  accessToken,
  id,
  name,
  description,
  groups,
  functions,
  slug,
) {
  const requestOptions = {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  };

  try {
    const { data } = await axios.put(
      `${process.env.REACT_APP_MENUS_API_URL}/${id}`,
      {
        name, description, slug, groups, functions,
      },
      requestOptions,
    );

    console.log(data);
    return data;
  } catch (err) {
    console.log(err);
    return err;
  }
}
