export function formatAccounts(accounts) {
  const formattedAccounts = [];
  accounts.map(account => {
    let name = account.accountName;
    const id = account.name.split('/')[1];
    if (account.type === 'PERSONAL') name = 'Desagrupado';
    formattedAccounts.push({ name, value: id });
  });
  return formattedAccounts;
}
