import axios from 'axios';

import { TGetBatchLocationReviews } from '../../../types/TGoogle';

export default async function getBacthLocationReviews({
  accessToken,
  accountGroupId,
  locationNames,
  pageSize = 50,
  pageToken,
  orderBy,
  ignoreRatingOnlyReviews,
}: TGetBatchLocationReviews) {
  const requestOptions = {
    headers: {
      'access-token': accessToken,
    },
  };

  try {
    const { data } = await axios.post(
      `${process.env.REACT_APP_GOOGLE_API_URL}/batch-reviews/${accountGroupId}`,
      {
        locationNames,
        pageSize,
        pageToken,
        orderBy,
        ignoreRatingOnlyReviews,
      },
      requestOptions,
    );

    return data;
  } catch (err: any) {
    return err.response;
  }
}
