import styled, { css } from 'styled-components';
import { lighten } from 'polished';

export const StyledLocationRow = styled.li`
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 0.2rem;
    background: ${props => props.theme.colors.white};
    width: 100%;
    position: relative;

    .icon-cancel-location,
    .icon-change-location,
    .icon-remove-location {
        margin: auto;
    }

    .icon-remove-location {
        margin-left: 0;

        .icon {
            fill: ${props => props.theme.colors.red} !important;
        }
    }

    .icon-change-location {
        margin-right: 0;
    }
`;

export const StyledBinding = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    align-items: stretch;
    width: 88%;

    ${props => props.locationBinded && css`
        width: 100%;
    `};

    .location-name {
        display: flex;
        align-items: center;
    }

    .location-name-binded,
    .select-locations {
      display: flex;
      flex-direction: column;
      justify-content: center;
      width: 50%;
      padding: 1rem 3rem;
      background: ${props => lighten(0.9, props.theme.colors.black)};
    }
    
    .location-address-binded {
      font-size: 1.3rem;
      color: ${props => lighten(0.6, props.theme.colors.black)};
    }

    .link-infos {
        margin: auto -2rem;
        background: ${props => lighten(0.8, props.theme.colors.black)};
        
        .icon {
            fill: ${props => props.theme.colors.black} !important;
            width: 2.5rem;
            height: 2.5rem;
        }
    }
`;

export const StyledSelectWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 50%;
  padding: 1rem 3rem;
  background: ${props => lighten(0.85, props.theme.colors.black)};
`;

export const StyledAddressText = styled.span`
  font-size: 1.3rem;
  color: ${props => lighten(0.7, props.theme.colors.black)};
`;

export const StyledLocationToBindName = styled.div`
  display: flex;
  flex-direction: row;
  width: 60%;
  align-items: center;
  justify-content: flex-start;
  padding: 1rem 3rem;
  background: ${props => lighten(0.95, props.theme.colors.black)};
`;

export const StyledLocationNameAndAddressWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 2rem;
`;

export const StyledBindedName = styled.div`
  display: flex;
  justify-content: center;
`;
