import { combineReducers } from 'redux';

import AuthReducer from './auth';
import CompanyReducer from './company';
import CompanyGroupReducer from './company-group';
import RulesReducer from './rules';
import LocationReducer from './location';
import LocationGroupReducer from './location-group';
import GoogleInfoReducer from './google';
import FacebookInfoReducer from './facebook';
import PlatformsReducer from './platforms';
import GuestRulesReducer from './guest-rules';
import ReviewReducer from './reviews';

export default combineReducers({
  AuthReducer,
  CompanyGroupReducer,
  CompanyReducer,
  RulesReducer,
  LocationGroupReducer,
  LocationReducer,
  GoogleInfoReducer,
  FacebookInfoReducer,
  PlatformsReducer,
  GuestRulesReducer,
  ReviewReducer,
});
