import styled from 'styled-components';
import { Popover } from '@material-ui/core';
import { CloudDone } from '@material-ui/icons';

export const StyledCloudDone = styled(CloudDone)`
  fill: ${props => props.theme.colors.white};
  margin-right: 1rem;
  width: 2rem;
  height: 2rem;
`;

export const StyledLocationNameWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: fit-content;
`;

export const StyledAddress = styled.div`
  display: flex;
  flex-direction: row;
  font-weight: 400;
  font-size: 1.2rem;
  color: ${props => props.theme.colors.grey};
  column-gap: 0.3rem;

  p {
    margin-bottom: 0;
  };
`;

export const StyledPopover = styled(Popover)`
  .MuiPopover-paper {
    box-shadow: 
      0px 5px 5px -3px rgb(0 0 0 / 20%), 
      0px 8px 10px 1px rgb(0 0 0 / 14%), 
      0px 3px 14px 2px rgb(0 0 0 / 12%);
  }
`;

export const StyledPopoverContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 40rem;
  padding: 1rem 2rem;
`;

export const StyledLinkedLocation = styled.div`
  display: flex;
  margin: 0;
  width: 100%;
`;

export const StyledLinkedLocations = styled.div`
  font-size: 1.4rem; 
  font-weight: 500;
  border-radius: 3rem;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.5rem 2rem;
  background: ${props => props.theme.colors.secondary};
  color: ${props => props.theme.colors.white};
  white-space: nowrap;
  cursor: pointer;
  
  .see-linked-locations-icon {
    margin-right: 1rem;
  }
`;
export const StyledActionWrapper = styled.div`
  display: flex;
`;
