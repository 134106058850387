import axios from 'axios';
import type { TBatchCreatePosts } from '../../types/TBatchUpdate';

export default async function updateCompany({
  accessToken,
  groupType,
  groupId,
  postData,
  created_by,
  locations,
  setIsLoading = null,
  setTokenLikeExpired,
  feedbackMessage,
  userData,
}: TBatchCreatePosts) {
  try {
    if (setIsLoading) setIsLoading(true);

    const { data } = await axios.post(`${process.env.REACT_APP_GOOGLE_API_URL}/posts/batch`,
      {
        group_type: groupType,
        group_id: groupId,
        data_post: postData,
        created_by,
        locations,
        user_data: userData,
      },
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });

    if (data.status === 406) {
      if (feedbackMessage) feedbackMessage('Nenhum local foi selecionado ao iniciar as postagens em massa. Selecione um ou mais locais e tente novamente.', { variant: 'warning' });
      return data;
    }

    if (feedbackMessage) feedbackMessage('Postagem em massa iniciada com sucesso', { variant: 'success' });

    return data;
  } catch (err: any) {
    if (err.response?.status === 401 && setTokenLikeExpired) setTokenLikeExpired();

    if (feedbackMessage) feedbackMessage('Erro ao iniciar postagens em massa, tente novamente!', { variant: 'error' });

    return null;
  } finally {
    if (setIsLoading) setIsLoading(false);
  }
}
