import axios from 'axios';

export default async function validatePassword({
  accessToken,
  email,
  password,
}) {
  try {
    const requestOptions = {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    };
    const { data } = await axios.post(
      `${process.env.REACT_APP_AUTH_API_URL}/validate-password`, {
        email,
        password,
      },
      requestOptions,
    );

    return data;
  } catch (err) {
    return err.response;
  }
}
