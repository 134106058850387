import { TIconButtonTooltipVariant } from '../../types/TIconButton';

export enum ESyncMonitoringPlatformStatus {
  NON_AVAILABLE = 'NON_AVAILABLE',
  ERROR = 'ERROR',
  ADJUSTED = 'ADJUSTED',
  DONE = 'DONE',
  SKIPPED = 'SKIPPED',
  PENDING = 'PENDING',
  SYNC_PENDING = 'SYNC_PENDING',
  'UNSELECTED_OPTION' = '_',
}

export type TSyncType = 'requests' | 'monitor';

export type TSyncMonitoringStatusIconProps = {
  status: ESyncMonitoringPlatformStatus;
  syncType: TSyncType;
};

export type TIconByStatusPropsValue = {
  tooltip: (syncEntityName: string) => string;
  icon?: string;
  isSuccess?: boolean;
  isError?: boolean;
  isWarning?: boolean;
  tooltipVariant?: TIconButtonTooltipVariant;
  label: string;
}
