import getZipCodeData from '../../../services/get-zipcode';
import { TLocationAddress } from './types';

export const masks = {
  cellphone: '99 99999-9999',
  phone: '99 9999-9999',
  zipcode: '99.999-999',
};

export const getLocationAddress = async (
  { zipcode, feedbackMessage },
): Promise<(TLocationAddress | undefined)> => {
  const zipcodeNormalized = zipcode.replace(/[^\w\s]/gi, '');
  const validZipCodeRegExp = /^\d{8}$/;

  if (validZipCodeRegExp.test(zipcodeNormalized)) {
    const responseZipCode = await getZipCodeData({ zipcode: zipcodeNormalized, feedbackMessage });
    if (responseZipCode && responseZipCode.erro) return;

    const {
      bairro,
      localidade,
      logradouro,
      uf,
      cep,
    } = responseZipCode;

    return {
      bairro,
      localidade,
      logradouro,
      uf,
      cep,
    };
  }
};
