import styled, { css } from 'styled-components';
import { shade } from 'polished';

export const StyledDialogContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 50rem;
`;

export const StyledInfoContainer = styled.div<{isRow?: boolean}>`
  display: flex;
  flex-direction: ${props => (props.isRow ? 'row' : 'column')};
  padding: ${props => (props.isRow ? 0 : '2rem')};

  &:nth-child(even) {
    background-color: ${props => shade(0.05, props.theme.colors.white)};
  }

  p {
    margin-bottom: 0;
    font-size: 1.4rem;
  }
`;

export const StyledInfoTitle = styled.h3`
    color: ${props => props.theme.colors.primary};
    font-size: 1.6rem;
    font-weight: bold;
`;

export const StyledInfoColumn = styled.div`
  display: flex;
  flex-direction: column;
  padding: 2rem;
`;
