import React from 'react';

import Button from '../button';

import {
  StyledBatchCreation,
  StyledTitle,
  StyledContent,
  StyledDisclaimer,
  StyledBatchOption,
  StyledBatchOptions,
} from './batch-creation-styles';

const BatchCreation = ({
  title = 'Título...',
  instructionTitle = 'Instruções',
  className,
  handleImportModal,
  downloadSpreadsheetModel,
}) => (
  <StyledBatchCreation
    className={className}
  >
    <StyledTitle>{title}</StyledTitle>
    <StyledContent>
      <StyledDisclaimer>
        <h4>{instructionTitle}</h4>
        <ul>
          <li>Baixar modelo de planilha</li>
          <li>Preencher os dados corretamente</li>
          <li>Após preencher, importar a planilha na nossa plataforma</li>
          <li>Nossa plataforma validará os dados que você preencheu</li>
          <li>Se os dados estiverem corretos, a função de criar em lote será executada</li>
        </ul>
      </StyledDisclaimer>
      <StyledBatchOptions>
        <StyledBatchOption secondary>
          <small>Planilha padrão para preenchimento dos dados</small>
          <Button
            onClick={downloadSpreadsheetModel}
            className="button"
            buttonType="secondary"
          >
            Baixar
          </Button>
        </StyledBatchOption>
        <StyledBatchOption primary>
          <small>Importar planilha preenchida corretamenta</small>
          <Button
            onClick={handleImportModal}
            className="button"
            buttonType="primary"
          >
            Importar
          </Button>
        </StyledBatchOption>
      </StyledBatchOptions>
    </StyledContent>
  </StyledBatchCreation>
);

export default BatchCreation;
