import styled, { css } from 'styled-components';
import { lighten, rgba, shade } from 'polished';
import { Tooltip } from '@material-ui/core';
import { BsPinAngle } from 'react-icons/bs';

export const StyledAutocompleteInput = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;

  .disclaimer-disabled {
    color: ${props => props.theme.colors.red};
    font-size: 1rem;
  }

  label {
    font-size: 1.5rem;
  }
`;

export const StyledInputSearch = styled.div`
  display: flex;
  border: 2px solid ${(props) => lighten(0.4, props.theme.colors.black)};
  min-height: 4rem;
  position: relative;
  
  .clean-text-input {
    position: absolute;
    width: 2rem;
    height: 2rem;
    right: 2rem;
    bottom: 1rem;
    z-index: 2rem;
    cursor: pointer;
  }
  
  input {
    height: 100%;
    width: 100%;
    border: none;
    min-height: 3.62rem;
    padding: 1rem 3rem 1rem 2rem;
    font-size: 1.4rem;
  }
`;

export const StyledSelectedOptions = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-y: scroll;
  border: 2px solid ${(props) => lighten(0.4, props.theme.colors.black)};
  height: 12rem;

  &::-webkit-scrollbar {
    width: 5px;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
  }

  &::-webkit-scrollbar-thumb  {
    background-color: #C4C4C4;
  }
`;

export const StyledSelectedOption = styled.span<{ primary: boolean }>`
  padding: 0.5rem 1rem;
  background: #40A3FF;
  border-radius: 3rem;
  color: ${props => props.theme.colors.white};
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 90%;
  font-weight: 300;
  font-size: 1.4rem;
  text-align: left;
  margin: 0.5rem 0;

  ${props => props.primary && css`
    background: ${props.theme.colors.ocean};
  `}
  
  &:last-child {
    margin-right: 0;
  }

  svg {
    margin-left: 0.5rem;
    cursor: pointer;
  }
`;

export const StyledSelectedActionsWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const StyledListingSearch = styled.ul`
  display: flex;
  flex-direction: column;
  position: absolute;
  width: 100%;
  background: ${props => props.theme.colors.white};;
  top: 98%;
  z-index: 10;
  box-shadow: 0 1px 3px 0px ${props => lighten(0.5, props.theme.colors.black)};
  max-height: 25rem;
  overflow-y: scroll;

  &:hover {
    box-shadow: 0 1px 3px 0px ${props => lighten(0.5, props.theme.colors.black)};
  }
    
  li {
    padding: 1rem 2rem;
    border-bottom: 1px solid ${props => rgba(props.theme.colors.black, 0.12)};
    font-size: 1.4rem;
    cursor: pointer;
    transition: 0.3s;
    
    &:hover {
      color: ${props => props.theme.colors.white};
      background: ${props => props.theme.colors.primary};
    }
    
    &:last-child {
      border: 0;   
    }
  }
`;

export const StyledInputSwitchWrap = styled.div`
  display: flex;
  align-items: center;
  border-left: 2px solid ${(props) => lighten(0.4, props.theme.colors.black)};
  padding: 0rem 1rem;
`;

export const StyledDisabledInput = styled.div`
  font-size: 1.4rem;
  font-style: italic;
  font-weight: 500;
  display: block;
  align-self: center;
  margin-right: auto;
  margin-bottom: 0;
  color: ${(props) => shade(0.2, props.theme.colors.white)};

  padding: 1rem 2rem;
`;

export const StyledTooltip = styled(Tooltip)`
  margin: 0;
  padding: 0;
`;

export const StyledPinIcon = styled(BsPinAngle)`
  max-width: 1.5rem;
`;
