import React from 'react';
import * as R from 'ramda';

import {
  StyledDayOption,
  StyledCheckbox,
} from './day-option-styled';

const DayOption = ({
  dayOption,
  handleSetSelectedDays,
  replicatedDaysSelected,
  allDays,
}) => {
  const [isChecked, setIsChecked] = React.useState(false);

  React.useEffect(() => {
    const isSelectedDay = replicatedDaysSelected.find(replicatedDaySelected => replicatedDaySelected === dayOption);

    if (!R.isNil(isSelectedDay)) setIsChecked(true);
  }, [replicatedDaysSelected]);

  const handleIsChecked = newCheckedState => {
    if (dayOption === 'Todos os dias') {
      handleSetSelectedDays(allDays);
      setIsChecked(newCheckedState);

      return;
    }

    handleSetSelectedDays(prevState => {
      if (newCheckedState) return [...prevState, dayOption];

      return prevState
        .filter(prevDayOption => prevDayOption !== dayOption)
        .filter(prevDayOption => prevDayOption !== 'Todos os dias');
    });

    setIsChecked(newCheckedState);
  };

  return (
    <StyledDayOption>
      <StyledCheckbox
        checked={isChecked}
        onChange={() => handleIsChecked(!isChecked)}
      />
      {dayOption}
    </StyledDayOption>
  );
};

export default DayOption;
