import { createActions, createReducer } from 'reduxsauce';

export const { Types, Creators } = createActions({
  setActiveCompanyGroup: ['activeCompanyGroup'],
  setUserCompanyGroups: ['userCompanyGroup'],
});

const initialState = {
  activeCompanyGroup: {},
  userCompanyGroup: null,
};

const reduxOnSetActiveCompanyGroup = (
  state = initialState, { activeCompanyGroup },
) => ({ ...state, activeCompanyGroup });

const reduxOnSetUserCompanyGroup = (
  state = initialState, { userCompanyGroup },
) => ({ ...state, userCompanyGroup });

export default createReducer(initialState, {
  [Types.SET_ACTIVE_COMPANY_GROUP]: reduxOnSetActiveCompanyGroup,
  [Types.SET_USER_COMPANY_GROUPS]: reduxOnSetUserCompanyGroup,
});
