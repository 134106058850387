import React from 'react';

import TabMenu from './tab-menu';

import {
  StyledLocationNavBar,
} from './location-nav-bar-styles';

import { moduleMenuItems } from './helpers';

const LocationNavBar = ({ hideDashboard, linkedLocation, isLastSyncHistory }) => {
  const filterDashboard = hideDashboard ? moduleMenuItems.filter((item) => item.title !== 'Dashboard')
    : moduleMenuItems;

  let menuItems = linkedLocation ? filterDashboard.filter((item) => item.title !== 'Conectar') : filterDashboard;

  menuItems = isLastSyncHistory
    ? menuItems.filter((item) => item.title !== 'Conectar' && item.title !== 'Informações')
    : menuItems.filter((item) => item.title !== 'Editar');

  return (
    <StyledLocationNavBar dropdownToggle dropdownToggleWithLocations>
      <TabMenu
        menuItems={menuItems}
        linkedLocation={linkedLocation}
      />
    </StyledLocationNavBar>
  );
};

export default React.memo(LocationNavBar);
