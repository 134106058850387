import React from 'react';

import {
  StyledBackToTop,
  StyledArrowUpward,
} from './back-to-top-styles';

const BackToTop = () => {
  const handleScrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  return (
    <StyledBackToTop onClick={handleScrollToTop}>
      <StyledArrowUpward />
      Voltar ao Topo
    </StyledBackToTop>
  );
};

export default BackToTop;
