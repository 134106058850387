import React from 'react';
import { GoogleMap, LoadScript, Marker } from '@react-google-maps/api';

import { StyledWarning } from './map-view-styles';

const mapStyles = {
  width: '100%',
  height: '100%',
};

export default ({ latitude, longitude, zoomLevel = 1 }) => (
  <>
    {(!latitude && !longitude)
    && (
    <StyledWarning>
      <h5 className="warning-message">As coordenadas ainda não foram definidas.</h5>
    </StyledWarning>
    )}
    <LoadScript googleMapsApiKey={process.env.REACT_APP_GOOGLE_MAPS_KEY}>
      <GoogleMap
        mapContainerStyle={mapStyles}
        zoom={zoomLevel}
        center={{
          lat: latitude || 0.00,
          lng: longitude || 0.00,
        }}
      >
        <Marker
          position={{
            lat: latitude,
            lng: longitude,
          }}
        />
      </GoogleMap>
    </LoadScript>
  </>
);
