import styled from 'styled-components';

export const StyledInfoPanel = styled.div`
    display: flex;
    flex-direction: row;
`;

export const StyledMainPanel = styled.div`
    margin-right: 1em;
    width: 80%;
`;
