import styled from 'styled-components';

export const StyledRegisterClients = styled.div`
    padding: 2rem;
    width: 100%;

    .register-client-input {
        margin-bottom: 2rem;

        &:last-of-type {
         margin-bottom: 0; 
        }
    }
`;

export const StyledForm = styled.form``;

export const StyleInputGroup = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 2rem;
`;

export const StyledRefreshTokenDiv = styled.div`
    display: flex;
    align-items: center;
    margin-bottom: 2rem;
`;
