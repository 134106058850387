import React from 'react';

import {
  OverlayLoading,
  LetsStart,
} from '../../../components';

const EmptyCompanies = ({
  isLoading,
  letsStartRedirect,
}) => (isLoading
  ? <OverlayLoading textToLoading="Carregando informações..." fullScreen />
  : <LetsStart begin={letsStartRedirect} />);

export default EmptyCompanies;
