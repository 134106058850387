import React from 'react';
import { debounce } from 'lodash';

import { updateBatchInfosLocations } from '../../../../../../services/google-batch-modules/batch-infos';

import { updateRowDataByColumn } from '../../../../../batch-update-info-status/helpers';
import { getPlatformLogo } from '../../../../../../assets/images/svg';
import { Checkbox } from '../../../../../../components';

import type { TAffectedTicketPlatforms } from '../../../../../../types/TBatchUpdate';

import { useAuth } from '../../../../../../hooks';

import {
  StyledCheckPlatform,
} from './check-platform-styles';

type TCheckPlatform = {
  platform: string;
  affected: boolean;
  platformsData: TAffectedTicketPlatforms[];
  locationRowId: string;
  setLocationRowData: React.Dispatch<React.SetStateAction<any>>;
  isLoadingCheckPlatform: boolean;
  setIsLoadingCheckPlatform: React.Dispatch<React.SetStateAction<boolean>>;
};

export const CheckPlatform = ({
  platform,
  affected = false,
  platformsData = [],
  locationRowId,
  setLocationRowData,
  isLoadingCheckPlatform,
  setIsLoadingCheckPlatform,
}: TCheckPlatform) => {
  const { userAccessToken } = useAuth();
  const [checked, setChecked] = React.useState(false);

  React.useEffect(() => {
    setChecked(affected);
  }, [affected]);

  const onCheckDebounced = React.useCallback(debounce(async (onFinish: () => void) => {
    const affectedPlatformsUpdated = platformsData.map(
      (affectedPlatform) => {
        if (affectedPlatform.platform === platform) {
          return {
            platform,
            affected: !checked,
          };
        }

        return affectedPlatform;
      },
    );

    const checkPlatformResponse = await updateBatchInfosLocations({
      userAccessToken,
      batchInfosLocationsId: locationRowId,
      affectedTicketPlatforms: affectedPlatformsUpdated,
    });

    if (checkPlatformResponse.status === 'ERROR') {
      onFinish();
      return;
    };

    setLocationRowData((prevState: any) => updateRowDataByColumn({
      rowData: prevState,
      payload: {
        affectedPlatforms: affectedPlatformsUpdated,
      },
    }));

    setChecked(!checked);
    onFinish();
  }, 500), [platform, checked, platformsData]);

  const handleOnCheck = React.useCallback(() => {
    setIsLoadingCheckPlatform(true);
    onCheckDebounced(() => setIsLoadingCheckPlatform(false));
  }, [onCheckDebounced])

  const platformNameCapitalized = React.useCallback(
    (platformName: string) => Array.from(platformName)
      .map((charItem, index) => (index === 0 ? charItem.toUpperCase() : charItem.toLowerCase()))
      .join(''),
    [],
  );

  return (
    <StyledCheckPlatform>
      {getPlatformLogo({
        platform: platformNameCapitalized(platform),
        className: 'platform-icon',
      })}
      <Checkbox
        checked={checked}
        onChange={() => handleOnCheck()}
        disabled={isLoadingCheckPlatform}
      />
    </StyledCheckPlatform>
  );
};
