import React from 'react';
import { useSnackbar } from 'notistack';
import { useHistory } from 'react-router-dom';
import HeaderToolbarAction from '../../header-toolbar-action';

import { disableClickTitles, redirectWarningLabel } from '../helpers';

import {
  StyledTabMenu,
} from './tab-menu-styles';

const TabMenu = ({ menuItems, linkedLocation }) => {
  const { push: routerPush } = useHistory();
  const { enqueueSnackbar } = useSnackbar();

  const handleClick = (title, link) => {
    const shouldNotRedirect = disableClickTitles[title];
    if (linkedLocation || !shouldNotRedirect) {
      routerPush(link);
      return;
    }

    enqueueSnackbar(`${redirectWarningLabel[title]} Entre em contato com nossos operadores para liberar o acesso a essa funcionalidade.`, { variant: 'warning' });
  };

  return (
    <StyledTabMenu>
      {menuItems.map(item => (
        <>
          <HeaderToolbarAction
            title={item.title}
            key={Math.random() * 1000}
            icon={item.icon}
            onClick={() => (handleClick(item.title, item.link))}
            disabled={!linkedLocation && disableClickTitles[item.title]}
          />
        </>
      ))}
    </StyledTabMenu>
  );
};

export default TabMenu;
