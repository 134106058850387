import { TLocationEntity } from "../../types/TLocation";

export type TContractItem = {
  name: string;
  value: number;
}

export enum EUpdateLocationInfoFields {
  'name' = 'name',
  'address1' = 'address1',
  'address2' = 'address2',
  'store_code' = 'store_code',
  'short_description' = 'short_description',
  'long_description' = 'long_description',
  'data_studio' = 'data_studio',
  'zip_code' = 'zip_code',
  'neighborhood' = 'neighborhood',
  'city' = 'city',
  'state' = 'state',
  'country' = 'country',
  'phone' = 'phone',
  'secondary_phone' = 'secondary_phone',
  'email' = 'email',
  'does_not_serve_at_this_address' = 'does_not_serve_at_this_address',
  'google_categories' = 'google_categories',
  'facebook_categories' =  'facebook_categories',
  'foursquare_categories' = 'foursquare_categories',
  'google_service_area' = 'google_service_area'
};

export enum EUpdateLocationLinksFields {
  'website' = 'website',
  'facebook' = 'facebook',
  'instagram' = 'instagram',
  'twitter' = 'twitter',
  'youtube' = 'youtube',
  'foursquare' = 'foursquare',
  'linkedin' = 'linkedin',
  'yelp' = 'yelp',
  'other_links' = 'other_links',
};

export enum EUpdateLocationWorkingHoursFields {
  'working_hours' = 'working_hours',
  'special_dates' = 'special_dates',
};

export enum EUpdateLocationListFields {
  'working_hours' = 'working_hours',
  'special_dates' = 'special_dates',
  'google_categories' = 'google_categories',
  'facebook_categories' =  'facebook_categories',
  'foursquare_categories' = 'foursquare_categories',
}

export enum EFieldsToBeFilled {
  'storeCode' = 'store_code',
  'name' = 'name',
  'email' = 'email',
  'mainPhone' = 'phone',
  'secondaryPhone' = 'secondary_phone',
  'zipCode' = 'zip_code',
  'address2' = 'address2',
  'neighborhood' = 'neighborhood',
  'city' = 'city',
  'state' = 'state',
  'shortDescription' = 'short_description',
  'longDescription' = 'long_description',
};

export enum EInfoFieldsFocus {
  'storeCode' = 'storeCode',
  'name' = 'name',
  'email' = 'email',
  'mainPhone' = 'phone',
  'secondaryPhone' = 'secondaryPhone',
  'zipCode' = 'zipCode',
  'address2' = 'address2',
  'neighborhood' = 'neighborhood',
  'city' = 'city',
  'state' = 'state',
  'shortDescription' = 'shortDescription',
  'longDescription' = 'longDescription',
}

export enum ELinksFieldsFocus {
  'website' = 'website',
  'instagram' = 'instagram',
  'facebook' = 'facebook',
  'twitter' = 'twitter',
  'youtube' = 'youtube',
  'linkedin' = 'linkedin',
  'foursquare' = 'foursquare',
  'yelp' = 'yelp',
}

export enum EWorkingHoursFocus {
  'workingHours' = 'workingHours',
}

export type TValidateFields = {
  updatedLocation: TLocationEntity,
  message: any,
  isOutsideBrazil: boolean;
}