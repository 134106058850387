import React from 'react';
import * as R from 'ramda';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import APIGetUserCompaniesWithGuests from '../../services/companies/getUserCompaniesWithGuests';
import { HeaderToolbar, HealderToolbarAction } from '../../components';
import reportErrorIllustration from '../../assets/images/report-error.png';

import {
  StyledContainer,
  StyledEmbedContainer,
  StyledLoadingContainer,
  StyledNoAcess,
} from './location-report-styles';
import { TRootStateRedux } from '../../types/TRootStateRedux';
import { useAuth, useWindowDimensions } from '../../hooks';
import { CircularProgress } from '@material-ui/core';

const LocationReport = () => {
  const navigation = useHistory();

  const { activeLocation } = useSelector(
    (state: TRootStateRedux) => state.LocationReducer
  );
  const { activeCompany } = useSelector(
    (state: TRootStateRedux) => state.CompanyReducer
  );
  const { userAccessToken, userId, userSetTokenLikeExpired, userProfile } = useAuth();

  const { width } = useWindowDimensions();

  const desktopDataStudioUrl = process.env.REACT_APP_DATA_STUDIO_DESKTOP_URL || "";
  const mobileDataStudioUrl = process.env.REACT_APP_DATA_STUDIO_MOBILE_URL || "";

  const baseUrl = width < 500 ? mobileDataStudioUrl : desktopDataStudioUrl;

  const formatUrl = (url: string): string => {
    let params = {} as any;
    if (activeLocation?.bi_id && activeCompany?.uuid) {
      params = {
        ...params,
        insights_selected_company_id: activeCompany.uuid,
        reviews_selected_company_id: activeCompany.uuid,
        posts_selected_company_id: activeCompany.uuid,

        insights_selected_location_id: activeLocation.bi_id,
        reviews_selected_location_id: activeLocation.bi_id,
        posts_selected_location_id: activeLocation.bi_id,
      };
    }
    const encodedParams = encodeURIComponent(JSON.stringify(params));
    const finalUrl = `${url}?params=${encodedParams}`;
    return finalUrl;
  };

  const [url, setUrl] = React.useState<string>(formatUrl(baseUrl));

  React.useEffect(() => {
    if (!R.isNil(activeLocation)) {
      setUrl(formatUrl(baseUrl));
    }
  }, [activeLocation, activeCompany, baseUrl]);

  const [isLoadingUserCompanies, setIsLoadingUserCompanies] =
    React.useState(false);
  const [userCompaniesCount, setUserCompaniesCount] = React.useState<
    number | null
  >(null);

  const getUserCompanies = React.useCallback(async () => {
    const userCompaniesResponse = await APIGetUserCompaniesWithGuests({
      accessToken: userAccessToken,
      userId,
      setTokenLikeExpired: userSetTokenLikeExpired,
      page: 0,
      pageSize: 1,
      setIsFetching: setIsLoadingUserCompanies,
    });
    if (!userCompaniesResponse) return;
    const [_, companiesCount] = userCompaniesResponse;
    setUserCompaniesCount(companiesCount);
  }, [userId, userAccessToken]);

  React.useEffect(() => {
    getUserCompanies();
  }, [getUserCompanies]);

  const isAdminOrOperator = userProfile && userProfile.id !== 5;

  const userNotAllowedToAccess = isAdminOrOperator
    ? false
    : userCompaniesCount === 0;

  const shouldHideReport =
    userNotAllowedToAccess || !activeLocation?.is_replicated_on_BI;

  if (shouldHideReport) {
    return (
      <StyledContainer>
        <HeaderToolbar dropdownsLocation>
          <HealderToolbarAction
            title="Listar Locais"
            icon="List"
            onClick={() => navigation.push('/my-locations')}
          />
        </HeaderToolbar>
        <StyledNoAcess>
          <img
            className="no-acess-illustration"
            src={reportErrorIllustration}
            alt="Sem acesso"
          />

          <h2>O relatório que você tentou acessar ainda não está disponível</h2>
          {userCompaniesCount === 0 ? (
            <>
              <p>
                Você ainda não possui locais cadastrados e conectados a
                plataformas externas.
              </p>
              <p>
                Para ter acesso ao relatório, cadastre uma empresa e um local e
                conecte seu local.
              </p>
            </>
          ) : (
            <>
              <p>
                Você está tentando acessar um relatório de um local que ainda
                não possui conexão.
              </p>
              <p>Para ter acesso ao relatório, conecte seu local.</p>
            </>
          )}
        </StyledNoAcess>
      </StyledContainer>
    );
  }

  return (
    <StyledContainer>
      <HeaderToolbar dropdownsLocation>
        <HealderToolbarAction
          title="Listar Locais"
          icon="List"
          onClick={() => navigation.push('/my-locations')}
        />
      </HeaderToolbar>
      {isLoadingUserCompanies ? (
        <StyledLoadingContainer>
          <CircularProgress />
        </StyledLoadingContainer>
      ) : (
        <StyledEmbedContainer>
          <iframe src={url} frameBorder="0" title="teste" className="iframe" />
        </StyledEmbedContainer>
      )}
    </StyledContainer>
  );
};

export default LocationReport;
