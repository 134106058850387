import styled, { css } from 'styled-components';

import { lighten } from 'polished';

export const StyledPlatform = styled.li`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  border-bottom: 1px solid ${props => lighten(0.9, props.theme.colors.black)};
  margin-bottom: 0.5rem;
  padding-bottom: 0.5rem;

  &:last-of-type {
    border-bottom: 0;
    padding-bottom:0;
  }

  .done {
    fill: ${props => props.theme.colors.secondary};
  }

  .open {
    fill: ${props => props.theme.colors.black};
  }

  .no-access {
    fill: ${props => props.theme.colors.red};
  }

  .in-progress {
    fill: ${props => props.theme.colors.yellow};
  }

  .platform-icon {
    width: 1.5rem;
    height: 1.5rem;
    margin-right: 1rem;
  }
`;

export const StyledHeader = styled.div`
    display:flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;

    .dropdown-icon-button{
        padding: 0.4rem;

        &:focus {
          outline: none;
        }
    }
`;

export const StyledPlatformTitle = styled.div`
  display: flex;
  align-items: center;
`;

export const StyledHeaderInfoRight = styled(StyledPlatformTitle)`
  margin-left: auto;
`;

export const StyledContent = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 1rem;
  overflow: hidden;
  height: 0;
  transition: 0.3s ease;

  span{
    font-size: 1.2rem;
    margin-bottom: 0.5rem;
  }

  li{
    font-size: 1.1rem;
    color: ${props => props.theme.colors.primary};
  }

  ${props => props.openContent && css`
    height: auto;
    overflow: visible;
    min-height: 0px;
    padding: 1rem;
    transition-duration: 204ms;
  `}

  .content-button {
    width: 100%;
    margin-bottom: 1rem;

    &:last-of-type {
      margin-bottom: 0;
    }
  }
`;

export const StyledSubplatformsList = styled.ul`
`;
