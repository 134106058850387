import React from 'react';
import { debounce } from 'lodash';

import { SelectMultiple, InputDatePicker, Input } from '../../../components';

import {
  filterSelectPlatformsOptions,
  filterSelectErrorByModule,
  filterSelectStatus,
} from './helpers';

import {
  StyledFilter,
  StyledTitle,
  StyledFields,
  StyledDataPickerWrap,
} from './filter-styles';

export type TBiMonitorFilterProps = {
  selectedPlatforms: string[];
  setSelectedPlatforms: React.Dispatch<React.SetStateAction<string[]>>;
  selectedStatus: string[];
  setSelectedStatus: React.Dispatch<React.SetStateAction<string[]>>;
  selectedErrorByModule: string[];
  setSelectedErrorByModule: React.Dispatch<React.SetStateAction<string[]>>;
  setCompanyField: React.Dispatch<React.SetStateAction<string>>;
  setPlaceField: React.Dispatch<React.SetStateAction<string>>;
  setAddressField: React.Dispatch<React.SetStateAction<string>>;
  selectedDateOf: Date | null;
  setSelectedDateOf: React.Dispatch<React.SetStateAction<Date | null>>;
  selectedDateTo: Date | null;
  setSelectedDateTo: React.Dispatch<React.SetStateAction<Date | null>>;
};

export const Filter = ({
  selectedPlatforms,
  setSelectedPlatforms,
  selectedStatus,
  setSelectedStatus,
  selectedErrorByModule,
  setSelectedErrorByModule,
  setCompanyField,
  setPlaceField,
  setAddressField,
  selectedDateOf,
  setSelectedDateOf,
  selectedDateTo,
  setSelectedDateTo,
}: TBiMonitorFilterProps) => {
  const handleChangeCompanyDebounced = React.useCallback(
    debounce((text) => setCompanyField(text), 500),
    []
  );
  const handleChangePlaceDebounced = React.useCallback(
    debounce((text) => setPlaceField(text), 500),
    []
  );
  const handleChangeAddressDebounced = React.useCallback(
    debounce((text) => setAddressField(text), 500),
    []
  );
  const handleChangeOfDateDebounced = React.useCallback(
    debounce((date) => setSelectedDateOf(date), 500),
    []
  );
  const handleChangeToDateDebounced = React.useCallback(
    debounce((date) => setSelectedDateTo(date), 500),
    []
  );
  return (
    <StyledFilter>
      <StyledTitle>Filter</StyledTitle>
      <StyledFields>
        <SelectMultiple
          className="filter-field filter-filter__platforms"
          label="Plataformas"
          name="filter-select-platforms"
          onChange={setSelectedPlatforms}
          value={selectedPlatforms}
          borderRadius
          options={filterSelectPlatformsOptions}
          noBackground
          shouldSelectValue
        />
        <Input
          className="filter-field"
          autoComplete="off"
          border
          label="Empresa"
          onChange={({ target }) => handleChangeCompanyDebounced(target.value)}
          noBackground
        />
        <Input
          className="filter-field"
          autoComplete="off"
          border
          label="Local"
          onChange={({ target }) => handleChangePlaceDebounced(target.value)}
          noBackground
        />
        <Input
          className="filter-field"
          autoComplete="off"
          border
          label="Endereço"
          onChange={({ target }) => handleChangeAddressDebounced(target.value)}
          noBackground
        />
        <SelectMultiple
          className="filter-field filter-filter__status"
          label="Status"
          name="filter-select-status"
          onChange={setSelectedStatus}
          value={selectedStatus}
          borderRadius
          options={filterSelectStatus}
          noBackground
          shouldSelectValue
        />
        <SelectMultiple
          className="filter-field filter-filter__error-by-module"
          label="Error por módulo"
          name="filter-select-error-by-module"
          onChange={setSelectedErrorByModule}
          value={selectedErrorByModule}
          borderRadius
          options={filterSelectErrorByModule}
          noBackground
          shouldSelectValue
        />
        <StyledDataPickerWrap>
          <InputDatePicker
            className="filter-date-picker filter-date-picker__date-of"
            label="De:"
            selectedDate={selectedDateOf}
            setSelectedDate={handleChangeOfDateDebounced}
            maxDate={selectedDateTo}
          />
          <InputDatePicker
            className="filter-date-picker filter-date-picker__date-to"
            label="Até:"
            selectedDate={selectedDateTo}
            setSelectedDate={handleChangeToDateDebounced}
            minDate={selectedDateOf}
          />
        </StyledDataPickerWrap>
      </StyledFields>
    </StyledFilter>
  );
};
