import React from 'react';
import * as R from 'ramda';
import ReactFadeIn from 'react-fade-in';

import { TSpecialDateRowBaseProps } from '../../../types/TSpecialDates';
import { TTimeData } from '../../../types/TTime';

import {
  IconButton,
  OverlayLoading,
} from '../..';

import { useAuth } from '../../../hooks';

import WorkingHoursConfig from '../working-hours-config';
import Is24HoursButton from '../is-24-hours-button';

import { isWorkingHoursPrepare } from './helpers';

import {
  StyledSpecialDatesRow,
  StyledHeader,
  StyledSpecialDatesRowTitle,
  StyledSpecialDatesRowDate,
  StyledNoWorkingHours,
  StyledHeaderActions,
  StyledIsOpen,
} from './special-date-row-styles';

const SpecialDateRow = ({
  id,
  name = 'Título da data',
  date = 'Dia Mês Ano',
  isOpen,
  times,
  is24Hours,
  specialDateRow,
  setSpecialDateToEdit,
  handleDelete,
}: TSpecialDateRowBaseProps) => {
  const [specialDateIsOpen, setSpecialDateIsOpen] = React.useState(isOpen);
  const [currentIs24Hours, setCurrentIs24Hours] = React.useState(false);
  const [currentTimes, setCurrentTimes] = React.useState<TTimeData[]>([]);
  const [isSpecialDateDeleting, setIsSpecialDateDeleting] = React.useState(false);

  React.useEffect(() => {
    setSpecialDateIsOpen(isOpen || false);
    setCurrentIs24Hours(is24Hours || false);

    if (R.isNil(times) || R.isEmpty(times)) return;

    setCurrentTimes(times);
  }, [times, isOpen, is24Hours]);

  const isWorkingHours = isWorkingHoursPrepare({ times, is24Hours, isOpen });

  return (
    <StyledSpecialDatesRow>
      {isSpecialDateDeleting && <OverlayLoading textToLoading="Apagando data..." />}

      <StyledHeader
        specialDateIsOpen={specialDateIsOpen && !is24Hours}
      >
        <StyledSpecialDatesRowTitle>{name}</StyledSpecialDatesRowTitle>
        <StyledSpecialDatesRowDate>{date}</StyledSpecialDatesRowDate>

        <StyledIsOpen isOpen={isOpen}>
          {isOpen ? 'Aberto' : 'Fechado'}
        </StyledIsOpen>

        {is24Hours && (
          <Is24HoursButton
            className="is-24-hours"
            isActive={is24Hours}
          />
        )}

        <StyledHeaderActions>
          <IconButton
            tooltip="Editar data"
            onClick={() => setSpecialDateToEdit(specialDateRow)}
            placement="bottom"
            icon="Edit"
          />
          <IconButton
            tooltip="Remover data"
            onClick={() => handleDelete(id)}
            placement="bottom"
            icon="Delete"
          />
        </StyledHeaderActions>
      </StyledHeader>

      {(isWorkingHours() && !is24Hours) && (
        <ReactFadeIn>
          <WorkingHoursConfig times={times} readonly />
        </ReactFadeIn>
      )}

      {(!isWorkingHours() && !is24Hours) && (
        <StyledNoWorkingHours>
          Nenhuma faixa de horário cadastrada para essa data!
        </StyledNoWorkingHours>
      )}
    </StyledSpecialDatesRow>
  );
};

export default SpecialDateRow;
