import styled from 'styled-components';
import { devices } from '../../assets/styles/devices';

export const StyledFiltersContainer = styled.section`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 2rem;
  width: 100%;

  .filter-content, .input-date-picker {
    margin: 0;
    flex-grow: 1;
    width: 100%;
  }

  .filter-content {
    flex-basis: 18rem;
  }
`;

export const StyledDatesWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex-basis: 32rem;
  flex-grow: 1;
  gap: 2rem;
  width: 100%;

  @media ${devices.mobile} {
    flex-direction: row;
  }
`;
