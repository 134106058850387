import styled from 'styled-components';

export const StyledContainer = styled.div`
`;

export const SwitchWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const StyledIconWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  .platform-icon {
    width: 3rem;
    height: 3rem;
    margin: 0.5rem;
  }
`;
