import { EBatchGalleryCategories } from '../../types/TBatchGallery';
import { EBatchPlatformStatus } from '../../types/TBatchGoogleModules';
import type { TBatchUpdateStatusFilter, TBatchUpdateCategoryFilter } from '../../types/TBatchUpdate';

export const updateStatusOptions: TBatchUpdateStatusFilter[] = [
  { id: 2, name: 'Concluído', value: 'DONE' as EBatchPlatformStatus.DONE },
  { id: 3, name: 'Pendente', value: 'PENDING' as EBatchPlatformStatus.PENDING },
  { id: 4, name: 'Removido', value: 'REMOVED' as EBatchPlatformStatus.REMOVED },
  { id: 5, name: 'Removendo', value: 'REMOVING' as EBatchPlatformStatus.REMOVING },
  { id: 6, name: 'Removendo Mídia', value: 'CANCELING' as EBatchPlatformStatus.REMOVING_MEDIA },
  { id: 7, name: 'Com erro', value: 'ERROR' as EBatchPlatformStatus.ERROR },
  { id: 8, name: 'Cancelado', value: 'CANCELLED' as EBatchPlatformStatus.CANCELLED },
  { id: 9, name: 'Cancelando', value: 'CANCELING' as EBatchPlatformStatus.CANCELING },
];

export const updateMediaCategoryOptions: TBatchUpdateCategoryFilter[] = [
  { id: 0, name: 'Todos', value: '' },
  { id: 1, name: 'Geral', value: EBatchGalleryCategories.CATEGORY_UNSPECIFIED },
  { id: 2, name: 'Capa', value: EBatchGalleryCategories.COVER },
  { id: 3, name: 'Logo', value: EBatchGalleryCategories.LOGO },
];

export const updateStatusOptionsWithAllOption: TBatchUpdateStatusFilter[] = [
  { id: 0, name: 'Todos', value: '' },
  ...updateStatusOptions,
];
