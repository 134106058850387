import styled from 'styled-components';
import { Paper } from '@material-ui/core';

export const StyledUpdateStatus = styled.div`
  width: 100%;
`;

export const StyledContent = styled.div`
  padding: 2rem;
`;

export const StyledTitle = styled.h3`
  display: inline-block;
  width: 100%;
  font-size: 1.5rem;
  font-weight: 700;
  margin-bottom: 2rem;
`;

export const StyledImg = styled.img`
  height: 5rem;
  object-fit: cover;
`;
