import axios from 'axios';

import { TUpdateLocation } from '../../types/TLocation';

export default async function updateLocation({
  accessToken,
  locationId,
  inputData = {},
  deleteGoogle = false,
  deleteFacebook = false,
  deleteFoursquare = false,
  setIsFetching,
  setTokenLikeExpired,
  feedbackMessage,
  googleAccessToken = '',
  shouldNotGenerateRequest = false,
}: TUpdateLocation) {
  try {
    if (setIsFetching) setIsFetching(true);
    if (feedbackMessage) feedbackMessage('Atualizando local...', { variant: 'warning' });

    const { data } = await axios.put(
      `${process.env.REACT_APP_LOCATIONS_API_URL}/${locationId}?delete-google=${deleteGoogle}&delete-facebook=${deleteFacebook}&delete-foursquare=${deleteFoursquare}&shouldNotGenerateRequest=${shouldNotGenerateRequest}`,
      inputData,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          'access-token': googleAccessToken,
        },
      },
    );

    return data;
  } catch (err: any) {
    if (err.response?.status === 401 && setTokenLikeExpired) setTokenLikeExpired();

    if (feedbackMessage) feedbackMessage('Erro ao tentar atualizar o local', { variant: 'error' });

    return null;
  } finally {
    if (setIsFetching) setIsFetching(false);
    if (feedbackMessage) feedbackMessage('Local atualizado com sucesso', { variant: 'success' });
  }
}
