import { Dialog, DialogContent } from '@material-ui/core';
import { shade } from 'polished';
import styled from 'styled-components';

export const StyledDialog = styled(Dialog)``;

export const StyledDialogTitleWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 2rem;
  font-weight: 600;
  background: ${props => props.theme.colors.primary};
`;

export const StyledDialogTitle = styled.h1`
  font-size: 1.2rem;
  font-weight: 700;
  line-height: 1.4rem;
  color: ${props => props.theme.colors.white};
`;

export const StyledDialogContent = styled(DialogContent)`
  padding: 0 !important;
`;

export const StyledContent = styled.div`
  display: flex;
  flex-direction: column;
  border-top: 1px solid ${props => shade(0.1, props.theme.colors.white)};
`;
