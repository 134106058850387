import * as R from 'ramda';

import { TFilterLocationsOwnerOrGuest } from '../types/TUtils';

import isUserGuestOfCompany from './is-user-guest-of-company';

import APIGetCompanyLocations from '../services/locations/getCompanyLocations';

const filterLocationsOwnerOrGuest = ({
  currentCompany = null,
  userId,
  userProfileName,
  userAccessToken,
  companyLocationsPage = 0,
  companyLocationsPageSize = 10,
  companyLocationsPageQuery = '',
  companyLocationsOrderByName = null,
  companyLocationsOrderByContract = null,
  filterByContract = null,
  userSetTokenLikeExpired,
  setCompanyLocationsIsLoading = null,
  isDataTable = false,
  mountDataTable = null,
}: TFilterLocationsOwnerOrGuest) => async () => {
  let formattedContractFilter: any = null;

  if (R.isNil(currentCompany)) return null;
  if (R.isNil(currentCompany?.id)) return null;

  if (!R.isNil(filterByContract)) {
    formattedContractFilter = filterByContract.join(',');
  }

  const companyLocationsResponse = await APIGetCompanyLocations({
    accessToken: userAccessToken,
    companyId: currentCompany.id,
    page: companyLocationsPage,
    pageSize: companyLocationsPageSize,
    query: companyLocationsPageQuery,
    setTokenLikeExpired: userSetTokenLikeExpired,
    setIfFetching: setCompanyLocationsIsLoading,
    orderByName: companyLocationsOrderByName,
    orderByContract: companyLocationsOrderByContract,
    filterByContract: formattedContractFilter,
    isGuest: isUserGuestOfCompany({ currentCompany, userId, userProfileName }),
    userId,
  });

  if (R.isNil(companyLocationsResponse)) return null;

  const [locationDataList, locationDataListCount] = companyLocationsResponse;

  if (userProfileName !== 'Usuário Padrão') {
    if (isDataTable) {
      return {
        locationDataList: mountDataTable({
          locationDataList,
          userProfileName,
        }),
        locationCount: locationDataListCount,
      };
    }

    return {
      locationDataList,
      locationCount: locationDataListCount,
    };
  }

  if (
    !R.isNil(currentCompany.owner_user)
    && currentCompany.owner_user.id === userId
  ) {
    return {
      locationDataList: isDataTable ? mountDataTable({
        locationDataList,
        permissionStatus: 'owner',
        userProfileName,
      }) : locationDataList,
      locationCount: locationDataListCount,
    };
  }

  const isCompanyGuest = currentCompany.guests?.find(guest => guest.user?.id === userId);

  if (!R.isNil(isCompanyGuest)) {
    return {
      locationDataList: isDataTable ? mountDataTable({
        locationDataList,
        permissionStatus: 'guest',
        userProfileName,
      }) : locationDataList,
      locationCount: locationDataListCount,
    };
  }

  const filteredCompanyLocationsList = locationDataList.filter((location: any) => {
    const locationsWhereUserIsGuest = location.guests.find(
      (locationGuest: any) => locationGuest.user.id === userId,
    );
    return locationsWhereUserIsGuest;
  });

  return {
    locationDataList: isDataTable ? mountDataTable({
      locationDataList: filteredCompanyLocationsList,
      permissionStatus: 'locationGuest',
      userProfileName,
      userId,
    }) : filteredCompanyLocationsList,
    locationsLenght: filteredCompanyLocationsList.length,
    locationCount: locationDataListCount,
  };
};

export default filterLocationsOwnerOrGuest;
