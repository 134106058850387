import axios from 'axios';

import {
  TGetLocalPostsLocationsByGroup,
  TLocalPostsLocationsEntity,
  TGetLocalPostsLocationsByGroupResponse,
} from '../../types/TBatchGoogleModules';

export const getLocalPostsLocationsByGroup = async ({
  localPostsUUID,
  userAccessToken,
  page = 0,
  pageSize = 10,
  query = '',
  status,
  isFetching,
}: TGetLocalPostsLocationsByGroup): Promise<
  TGetLocalPostsLocationsByGroupResponse
> => {
  try {
    if (isFetching) isFetching(true);

    const apiPath = `
    ${process.env.REACT_APP_SAAS_BATCH_GOOGLE_MODULES}/local-posts-locations/by-local-posts/${localPostsUUID}?page=${page}&pageSize=${pageSize}&query=${query}&status=${status}
  `;

    const requestOptions = {
      headers: {
        Authorization: `Bearer ${userAccessToken}`,
      },
    };

    const { data } = await axios.get(apiPath, requestOptions);

    return data;
  } catch (err) {
    return null;
  } finally {
    if (isFetching) isFetching(false);
  }
};
