import React from 'react';

import {
  StyledResponseOption,
} from './response-option-styles';

const ResponseOptions = ({
  responseText,
  setDefaultResponse,
}) => (
  <StyledResponseOption
    onClick={() => setDefaultResponse(responseText)}
  >
    {responseText}
  </StyledResponseOption>
);

export default ResponseOptions;
