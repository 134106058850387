import * as R from 'ramda';

import APICreateLocationGroup from '../../services/location-groups/create';
import APIAddResponsibles from '../../services/location-groups/addResponsibles';

export const saveLocationGroupInDB = async ({
  userHublocalToken,
  userHublocalID,
  companyId,
  setTokenLikeExpired,
  responsibles,
  locationGroupData,
  setIsCreating,
  feedbackMessage,
  resetLocationFields,
  resetResponsibles,
  redirect,
}) => {
  try {
    if (setIsCreating) setIsCreating(true);

    const mainResponsible = R.dissoc('main', responsibles.filter(responsible => responsible.main === true)[0]);
    const responsibleWithoutMain = responsibles.filter(responsible => responsible.main !== true).map(responsible => responsible.id);

    const { name, description } = locationGroupData;

    const { id: newLocationGroupID } = await APICreateLocationGroup({
      accessToken: userHublocalToken,
      name,
      description,
      owner_user_id: userHublocalID,
      main_responsible_id: mainResponsible.id,
      created_by: userHublocalID,
      feedbackMessage,
      setTokenLikeExpired,
    });

    if (responsibleWithoutMain.length) {
      await APIAddResponsibles({
        accessToken: userHublocalToken,
        locationGroupId: newLocationGroupID,
        setTokenLikeExpired,
        responsibleIds: responsibleWithoutMain,
        feedbackMessage,
      });
    }

    if (resetLocationFields) resetLocationFields();
    if (resetResponsibles) resetResponsibles();
    if (redirect) redirect();
  } catch (err) {
    feedbackMessage('Não foi possível criar o grupo. Tente novamente!', { variant: 'error' });
  } finally {
    if (setIsCreating) setIsCreating(false);
  }
};

export const isValidData = ({
  responsibles,
  groupNameField,
  locationsGroup,
  enqueueSnackbar,
}) => {
  if (R.isEmpty(responsibles)) {
    enqueueSnackbar('Esse grupo não pode ficar sem responsáveis', { variant: 'warning' });
    return false;
  }

  if (R.isEmpty(groupNameField.trim())) {
    enqueueSnackbar('O nome do grupo é obrigatório', { variant: 'warning' });
    return false;
  }

  if (R.isEmpty(locationsGroup)) {
    enqueueSnackbar('Tem que ter pelo menos um local adicionado a esse grupo', { variant: 'warning' });
    return false;
  }

  return true;
};
