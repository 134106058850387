import React from 'react';
import ReactFadeIn from 'react-fade-in';
import { useSnackbar } from 'notistack';
import * as R from 'ramda';

import APIUpdateLocation from '../../../../../../services/locations/updateLocation';
import APICreateSpecialDate from '../../../../../../services/specialDate/createSpecialDate';
import APIUpdateSpecialDate from '../../../../../../services/specialDate/updateSpecialDate';
import APICreateTime from '../../../../../../services/workingHours/create-working-hours-times-range';
import APIUpdateTime from '../../../../../../services/workingHours/update-time-range';
import APIDeleteTime from '../../../../../../services/workingHours/delete-time-range';

import {
  Input,
  InputSwitch,
  Button,
  Loading,
  IconButton,
} from '../../../../../../components';

import type { TTimeData } from '../../../../../../types/TTime';
import type { TAddSpecialDateBatch } from '../../../../../../types/TWorkingHours';

import WorkingHoursConfig from '../working-hours-config';
import Is24Hours from '../../is-24-hours-button';

import {
  mockedTimes,
  setFieldsToIsValid,
} from './helpers';

import {
  StyledAddSpecialDate,
  StyledHeader,
  StyledFieldsWrapper,
  StyledHeaderTitle,
  StyledEditActions,
  StyledSpecialDateIs24Hours,
} from './add-special-date-styles';

const AddSpecialDate = ({
  hasSpeciaDates,
  activeLocation,
  currentSpecialDates = [],
  specialDateToEdit,
  setSpecialDateToEdit,
  userId,
  userSetTokenLikeExpired,
  userAccessToken,
  getActiveLocationInfos,
}: TAddSpecialDateBatch) => {
  const { enqueueSnackbar } = useSnackbar();

  const [newSpecialDateIsOpen, setNewSpecialDateIsOpen] = React.useState(false);
  const [newSpecialDateTitle, setNewSpecialDateTitle] = React.useState('');
  const [newSpecialDateDate, setNewSpecialDateDate] = React.useState('');
  const [newDateIs24Hours, setNewDateIs24Hours] = React.useState(false);
  const [newDateTimes, setNewDateTimes] = React.useState<TTimeData[]>(mockedTimes);

  const [isCreatingSpecialDate, setIsCreatingSpecialDate] = React.useState(false);
  const [isUpdateSpecialDate, setIsUpdateSpecialDate] = React.useState(false);

  React.useEffect(() => {
    if (R.isNil(specialDateToEdit)) return;

    const {
      is_open: isOpen,
      name,
      date,
      is_24_hours: is24Hours,
      times,
    } = specialDateToEdit;

    setNewSpecialDateTitle(name);
    setNewSpecialDateDate(date);
    setNewSpecialDateIsOpen(isOpen);
    setNewDateIs24Hours(is24Hours);

    setNewDateTimes(R.isEmpty(times) || R.isNil(times) ? mockedTimes : times);
  }, [specialDateToEdit]);

  const fieldsIsValid = setFieldsToIsValid({
    title: newSpecialDateTitle,
    date: newSpecialDateDate,
    feedbackMessage: enqueueSnackbar,
    currentSpecialDates,
  });

  const resetFields = () => {
    setSpecialDateToEdit(null);
    setNewSpecialDateIsOpen(false);
    setNewSpecialDateTitle('');
    setNewSpecialDateDate('');
    setNewDateTimes(mockedTimes);
    setNewDateIs24Hours(false);
    getActiveLocationInfos();
  };

  const handleSaveEdit = React.useCallback(async () => {
    if (!fieldsIsValid({ isUpdateMode: true })) return;
    if (R.isNil(specialDateToEdit)) return;

    R.forEach(async ({
      is_deleted: isDeleted,
      is_mocked: isMocked,
      id: timeRangeId,
    }) => {
      if (isDeleted && !isMocked) {
        await APIDeleteTime({
          accessToken: userAccessToken,
          setTokenLikeExpired: userSetTokenLikeExpired,
          timeRangeId,
        });
      }
    }, newDateTimes);

    const newTimesRangeFiltered = newDateTimes.filter(({
      open,
      close,
      is_mocked: isMocked,
      is_deleted: isDeleted,
    }) => {
      const isValidaTime = open !== '00:00' || close !== '00:00';

      return isValidaTime && isMocked && !isDeleted;
    });

    const newTimesToUpdated = newDateTimes.filter(({
      is_deleted: isDeleted,
      is_mocked: isMocked,
    }) => !isDeleted && !isMocked);

    const newTimesRangeResponse = R.isEmpty(newTimesRangeFiltered) ? [] : await Promise.all(newTimesRangeFiltered.map(async newTime => {
      const newTimeResponse = await APICreateTime({
        accessToken: userAccessToken,
        setTokenLikeExpired: userSetTokenLikeExpired,
        open: newTime.open,
        close: newTime.close,
      });

      return newTimeResponse;
    }));

    const newTimesToUpdatedResponse = R.isEmpty(newTimesToUpdated) ? [] : await Promise.all(newTimesToUpdated.map(async newTime => {
      const newTimeUpdatedResponse = await APIUpdateTime({
        accessToken: userAccessToken,
        setTokenLikeExpired: userSetTokenLikeExpired,
        open: newTime.open,
        close: newTime.close,
        idTimeRange: newTime.id,
      });

      return newTimeUpdatedResponse;
    }));

    const updateSpecialDate = await APIUpdateSpecialDate({
      accessToken: userAccessToken,
      setTokenLikeExpired: userSetTokenLikeExpired,
      special_date_id: specialDateToEdit.id,
      name: newSpecialDateTitle,
      date: newSpecialDateDate,
      is_open: newSpecialDateIsOpen,
      is_24_hours: newDateIs24Hours,
      times: [
        ...newTimesToUpdatedResponse,
        ...newTimesRangeResponse,
      ],
      isLoading: setIsUpdateSpecialDate,
    });

    if (R.isNil(updateSpecialDate)) return;

    resetFields();
  }, [
    newSpecialDateTitle,
    newSpecialDateDate,
    specialDateToEdit,
    newSpecialDateIsOpen,
    newDateIs24Hours,
    activeLocation,
    newDateTimes,
  ]);

  const handleAddSpecialDate = React.useCallback(async () => {
    if (!fieldsIsValid({ isUpdateMode: false })) return;

    const newTimesRangeFiltered = newDateTimes.filter(({ open, close }) => !(open === '00:00' && close === '00:00'));

    const newTimesRangeResponse = R.isEmpty(newTimesRangeFiltered) ? [] : await Promise.all(newTimesRangeFiltered.map(async newTime => {
      const newTimeResponse = await APICreateTime({
        accessToken: userAccessToken,
        setTokenLikeExpired: userSetTokenLikeExpired,
        open: newTime.open,
        close: newTime.close,
      });

      return newTimeResponse;
    }));

    const createdSpecialDateResponse = await APICreateSpecialDate({
      accessToken: userAccessToken,
      setTokenLikeExpired: userSetTokenLikeExpired,
      date: newSpecialDateDate,
      name: newSpecialDateTitle,
      times: newTimesRangeResponse,
      created_by: userId,
      is_open: newSpecialDateIsOpen,
      is_24_hours: newDateIs24Hours,
      isLoading: setIsCreatingSpecialDate,
      location: null,
    });

    if (R.isNil(createdSpecialDateResponse)) return;

    const updateLocationResponse = await APIUpdateLocation({
      accessToken: userAccessToken,
      setTokenLikeExpired: userSetTokenLikeExpired,
      locationId: activeLocation.id,
      inputData: {
        special_dates: [
          ...currentSpecialDates,
          createdSpecialDateResponse,
        ],
      },
    });

    if (R.isNil(updateLocationResponse)) return;

    resetFields();
  }, [
    newSpecialDateTitle,
    newSpecialDateDate,
    newSpecialDateIsOpen,
    newDateIs24Hours,
    activeLocation,
    newDateTimes,
  ]);

  const handleIs24Hours = () => {
    setNewDateIs24Hours(!newDateIs24Hours);
    setNewSpecialDateIsOpen(true);
  };

  const handleIsOpen = (isOpenState: boolean) => {
    setNewSpecialDateIsOpen(isOpenState);

    if (!isOpenState) setNewDateIs24Hours(false);
  };

  return (
    <StyledAddSpecialDate hasSpeciaDates={hasSpeciaDates}>
      <StyledHeader
        newSpecialDateIsOpen={newSpecialDateIsOpen}
      >
        <StyledHeaderTitle>
          Adicionar uma nova data
        </StyledHeaderTitle>
        <StyledFieldsWrapper>
          <Input
            className="add-special-date-input"
            label="Título"
            value={newSpecialDateTitle}
            onChange={e => setNewSpecialDateTitle(e.target.value)}
            inputOptions={{
              type: 'text',
              name: 'specia-date-title',
            }}
            border
          />

          <Input
            className="add-special-date-input"
            label="Data"
            value={newSpecialDateDate}
            onChange={e => setNewSpecialDateDate(e.target.value)}
            inputOptions={{
              type: 'text',
              name: 'specia-date-title',
            }}
            mask="99/99/9999"
            border
          />

          <InputSwitch
            label="Aberto?"
            checked={newSpecialDateIsOpen}
            changeChecked={() => handleIsOpen(!newSpecialDateIsOpen)}
            alignRow
            className="add-special-date-switch"
          />

          <Is24Hours
            isActive={newDateIs24Hours}
            className="is-24-hours-button"
            onClick={handleIs24Hours}
          />

          {R.isNil(specialDateToEdit) ? (
            <Button
              className="add-special-date-button"
              disabled={isCreatingSpecialDate}
              onClick={handleAddSpecialDate}
            >
              {isCreatingSpecialDate && (
                <Loading
                  className="add-special-date-loading"
                />
              )}
              Adicionar
            </Button>
          ) : (
            <StyledEditActions>
              <Button
                className="edit-special-date-button"
                disabled={isUpdateSpecialDate}
                onClick={handleSaveEdit}
                buttonType="secondary"
              >
                {isUpdateSpecialDate && (
                  <Loading
                    className="update-special-date-loading"
                  />
                )}
                Salvar
              </Button>
              <IconButton
                icon="Close"
                onClick={() => resetFields()}
                tooltip="Cancelar edição"
              />
            </StyledEditActions>
          )}
        </StyledFieldsWrapper>
      </StyledHeader>

      {(newSpecialDateIsOpen && newDateIs24Hours) && (
        <StyledSpecialDateIs24Hours>
          Não há faixas de horários para datas fixadas como aberta 24 horas
        </StyledSpecialDateIs24Hours>
      )}

      {(newSpecialDateIsOpen && !newDateIs24Hours) && (
        <ReactFadeIn>
          <WorkingHoursConfig
            times={newDateTimes}
            setTimes={(newTimes) => setNewDateTimes(newTimes)}
          />
        </ReactFadeIn>
      )}
    </StyledAddSpecialDate>
  );
};

export default AddSpecialDate;
