import axios from 'axios';

export default async function updateFunction(
  accessToken,
  id,
  name,
  description,
  friendly_name,
  handlerId,
  icon,
  slug,
  path_to_redirect,
  direct_access,
  is_available_in_menu,
) {
  const requestOptions = {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  };

  try {
    const { data } = await axios.put(
      `${process.env.REACT_APP_FUNCTIONS_API_URL}/${id}`,
      {
        name,
        description,
        handlerId,
        friendly_name,
        icon,
        slug,
        path_to_redirect,
        direct_access,
        is_available_in_menu,
      },
      requestOptions,
    );

    console.log(data);
    return data;
  } catch (err) {
    console.log(err);
    return err;
  }
}
