import styled, { css } from 'styled-components';
import { Button, Checkbox } from '@material-ui/core';
import { devices } from '../../../../assets/styles/devices';

export const StyledMediaList = styled.ul<{ mediaLength: number}>`
  display: grid;
  margin-bottom: 0;
  grid-column-gap: 2rem;
  grid-row-gap: 2rem;
  
  li {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    background: ${props => props.theme.colors.white};
    border-radius: 0.5rem;
    
    img, video {
      object-fit: cover;
      height: 18rem;
      width: 18rem;
    }
  }

  ${props => props.mediaLength === 1 && css`
    grid-template-columns: repeat(1, 1fr);
  `}

  ${props => props.mediaLength === 2 && css`
    grid-template-columns: repeat(2, 1fr);
  `}

  ${props => props.mediaLength >= 3 && css`
    grid-template-columns: repeat(3, 1fr);
  `}
`;

export const StyledMediasPopover = styled.div`
  display: flex;
  flex-direction: column;
  background: ${props => props.theme.page.body};
  align-items: center;
  padding: 2rem;
`;

export const StyledButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 1rem;
  margin-bottom: 0.5rem;
  align-items: center;
  justify-content: center;

  .is-loading {
    margin-right: 1rem;
  }

  p {
    font-size: 1.2rem;
    color: ${props => props.theme.colors.red};
  }
`;

export const StyledConfirmDeleteWrapper = styled.div`
  display: flex;
  justify-content: space-around;
  width: 100%;
`;
