import React from 'react';

import ConfigCard from '../../../components/config-card';

import { StyledPlatformsLinkWithLocation } from './platforms-link-with-location-styles';

export default ({ navigation }) => (
  <ConfigCard
    title="Vincular locais a plataformas"
    onClick={() => navigation.push('/platforms-link-with-location')}
  >
    <StyledPlatformsLinkWithLocation>
      Vinculação de locais
    </StyledPlatformsLinkWithLocation>
  </ConfigCard>
);
