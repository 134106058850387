import axios from 'axios';

/**
 *
 * @param {parentId} "accounts%2Flocations"
 * @returns
 */

export default async function listLocationMedia({
  parentId,
  accessToken,
  isLoading = null,
}) {
  const requestOptions = {
    headers: {
      'access-token': accessToken,
    },
  };

  try {
    if (isLoading) isLoading(true);

    const data = await axios.get(
      `${process.env.REACT_APP_GOOGLE_API_URL}/media/list/${parentId}`,
      requestOptions,
    );
    return data;
  } catch (err) {
    return null;
  } finally {
    if (isLoading) isLoading(false);
  }
}
