import styled, { css } from 'styled-components';
import { shade } from 'polished';
import {
  CloudDone, InsertLink, CloudOff, Error,
} from '@material-ui/icons';
import { devices } from '../../../../../../assets/styles/devices';

export const StyledLocationListItem = styled.li`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 1rem 2rem;
  background: ${props => shade(0.05, props.theme.colors.white)};
  cursor: default;
  row-gap: 0.5rem;

  @media ${devices.tablet} {
    flex-direction: row;
    justify-content: space-between;
    row-gap: 0;
  }

  &:nth-child(even) {
    background: ${props => shade(0.1, props.theme.colors.white)};
  }
  

  h4 {
    margin-bottom: 0;
    word-break: break-all;

    @media ${devices.tablet} {
      margin-right: 2rem;
    }
  }
`;

export const StyledLinkLocation = styled.span<{
  isLoading?: boolean;
  isLinked?: boolean;
  disabledLink?: boolean;
  unlink?: boolean;
}>`
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${props => props.theme.colors.primary};
  color: ${props => props.theme.colors.white};
  border-radius: 3rem; 
  padding: 0.5rem 2rem;
  font-size: 1.4rem;
  font-weight: 500;
  transition: all 0.3s;
  /* margin-left: auto; */
  cursor: pointer;
  
  &:hover {
    background: ${props => shade(0.1, props.theme.colors.primary)};
    transform: scale(1.1);
    
    ${props => props.isLinked && css`
      background: ${props => shade(0.1, props.theme.colors.secondary)};
      transform: scale(1);
    `};
    
    ${props => props.unlink && css`
      background: ${props => shade(0.1, props.theme.colors.red)};
    `};
  }
  
  ${props => props.unlink && css`
    background: ${props.theme.colors.red};
  `};

  ${props => props.isLoading && css`
    background: ${props => shade(0.2, props.theme.colors.white)};
    color: ${props => props.theme.colors.black};
    cursor: default;
    pointer-events: none;
  `};

  ${props => props.isLinked && css`
    margin-left: 1rem;
    background: ${props => props.theme.colors.secondary};
    cursor: default;
    pointer-events: none;
  `};

  ${props => props.disabledLink && !props.isLinked && css`
    background: ${shade(0.2, props.theme.colors.white)};
    cursor: default;
    pointer-events: none;
    &:hover {
      background: ${shade(0.2, props.theme.colors.white)};
      transform: scale(1);
    }
  `};

  .is-link-load {
    margin-right: 1rem;
  }
  
  b {
    margin-right: 1rem;
  }

  p {
    display: flex;
    align-items: center;
    margin-bottom: 0;
  }
`;

export const StyledInsertLink = styled(InsertLink)`
  fill: ${props => props.theme.colors.white};
  margin-right: 0.5rem;
  width: 2rem;
  height: 2rem;
`;

export const StyledError = styled(Error)`
  fill: ${props => props.theme.colors.white};
  margin-right: 0.5rem;
  width: 2rem;
  height: 2rem;
`;

export const StyledCloudDone = styled(CloudDone)`
  fill: ${props => props.theme.colors.white};
  margin-right: 1rem;
  width: 2rem;
  height: 2rem;
`;

export const StyledCloudOff = styled(CloudOff)`
  fill: ${props => props.theme.colors.white};
  margin-right: 1rem;
  width: 2rem;
  height: 2rem;
`;

export const StyledLocationNameWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: fit-content;
`;

export const StyledAddress = styled.div`
  display: flex;
  flex-direction: row;
  font-weight: 400;
  font-size: 1.2rem;
  color: ${props => props.theme.colors.grey};
  column-gap: 0.3rem;

  p {
    margin-bottom: 0;
  };
`;
