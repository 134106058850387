import React from 'react';
import { IconButton } from '..';
import { batchEntityNames, iconByStatus } from './helpers';
import { TBatchStatusIconPros } from './types';

export default ({ status, batchType }: TBatchStatusIconPros) => {
  const batchEntityName = batchEntityNames[batchType];

  if (iconByStatus[status]) {
    return (
      <IconButton
        tooltip={iconByStatus[status].tooltip(batchEntityName)}
        icon={iconByStatus[status].icon}
        isError={iconByStatus[status].isError}
        isWarning={iconByStatus[status].isWarning}
        isSuccess={iconByStatus[status].isSuccess}
        label={iconByStatus[status].label}
        className="status-icon"
        tooltipVariant={iconByStatus[status].tooltipVariant}
      />
    );
  }
  return <>{status}</>;
};
