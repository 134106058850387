import styled, { css } from "styled-components";
import { rgba, lighten } from "polished";

export const StyledGroupLinks = styled.div``;

export const StyledTitle = styled.div`
  padding: 2rem;
  background: transparent;
  border-bottom: 1px dashed ${props => rgba(props.theme.colors.white, 0.2)};
  color: ${props => props.theme.colors.white};
  display: flex;
  align-items: center;
  cursor: pointer;

  &:hover,
  &:focus {
    color: ${props => props.theme.colors.white};
    text-decoration: none;
    background: ${props => lighten(0.16, props.theme.colors.black)};
  }

  .group-links-icon {
    width: 2rem;
    height: 2rem;
    fill: ${props => props.theme.colors.white};
    margin-right: 0.5rem;
  }

  span {
    font-size: 1.4rem;
    line-height: 1;
  }
`;

export const StyledChevron = styled.div`
  margin-left: auto;

  svg {
    width: 2.5rem;
    height: 2.5rem;
  }
`;

export const StyledSubMenu = styled.nav`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: ${props => lighten(0.18, props.theme.colors.black)};
  overflow: hidden;
  height: 0rem;
  transition: 0.4s;

  ${props => props.open && css`height: auto;`}

  .submenu-link {
  }
`;