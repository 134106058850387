import React from 'react';
import { Link, useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { isEmpty } from 'ramda';
import { Helmet } from 'react-helmet';
import { useSnackbar } from 'notistack';

import signUpClientUser from '../../services/signUpClientUser';

import {
  Loading,
  Input,
  Button,
  LoginDialog,
} from '../../components';

import {
  emailValidate as emailIsValide,
} from '../../utils/fields-validate';

import hublocalLogo from '../../assets/images/hublocal-logo.png';

import {
  StyledRegister,
  StyledForm,
  StyledWelcome,
  StyleInputGroup,
  Disclaimer,
  StyledButtonsGroup,
} from './register-styles';
import sendValidationMail from '../../services/users/sendUserValidationMail';

import { translations } from '../../translations';

import { useAuth } from '../../hooks';

const Register = () => {
  const history = useHistory();

  const [isFetching, setIsFetching] = React.useState(false);
  const [isAllowed, setIsAllowed] = React.useState(false);
  const { language } = useSelector((state) => state.AuthReducer);
  const { register: registerTranslations } = translations[language];

  const { enqueueSnackbar } = useSnackbar();

  const { user } = useSelector(state => state.AuthReducer);

  const nameRef = React.useRef(null);
  const [nameValidate, setNameValidate] = React.useState({
    visible: false,
    type: 'error',
    message: '',
  });

  const emailRef = React.useRef(null);
  const [emailValidate, setEmailValidate] = React.useState({
    visible: false,
    type: 'error',
    message: '',
  });

  const {
    userAccessToken,
  } = useAuth();

  React.useEffect(() => {
    if (user) {
      const currentUserProfileName = user.user.profiles[0].name;
      if (currentUserProfileName === 'Admin' || currentUserProfileName === 'Operacional') setIsAllowed(true);
    }
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();

    const nameFieldValue = nameRef.current.value;
    const emailFieldValue = emailRef.current.value;

    if (isEmpty(nameFieldValue.trim())) {
      setNameValidate((oldState) => ({
        ...oldState,
        visible: true,
        message: 'Campo em branco',
      }));
      return;
    }
    setNameValidate((oldState) => ({ ...oldState, visible: false }));

    if (isEmpty(emailFieldValue.trim())) {
      setEmailValidate((oldState) => ({
        ...oldState,
        visible: true,
        message: 'Campo em branco',
      }));
      return;
    }
    setEmailValidate((oldState) => ({ ...oldState, visible: false }));

    if (!emailIsValide(emailFieldValue)) {
      setEmailValidate((oldState) => ({
        ...oldState,
        visible: true,
        message: 'Formato de email inválido',
      }));
      return;
    }
    setEmailValidate((oldState) => ({ ...oldState, visible: false }));

    try {
      setIsFetching(true);

      const data = await signUpClientUser(
        {
          name: nameFieldValue,
          email: emailFieldValue,
          accessToken: userAccessToken,
        },
      );

      sendValidationMail(data.id);

      nameRef.current.value = '';
      emailRef.current.value = '';

      enqueueSnackbar(
        'UsuÃ¡rio cadastrado com sucesso. Enviamos um link para o email contendo a url para redefinição de senha',
        { variant: 'success' },
      );
    } catch (err) {
      const variantError = 'error';

      if (err.status !== 409 && err.status !== 400) {
        enqueueSnackbar('Error ao tentar cadastrar seu usuário. Tente mais tarde!', { variant: variantError });
      }

      if (err.status === 409) enqueueSnackbar('Já existe uma conta com esse email.', { variant: variantError });
      if (err.status === 400) enqueueSnackbar('Formato de senha inválido.', { variant: variantError });
    } finally {
      setIsFetching(false);
    }
  };

  return (
    <StyledRegister>
      <Helmet>
        <meta property="og:title" content="Registre-se - SaaS Hublocal" />
        <meta property="og:description" content="FaÃ§a seu registro em nossa plataforma." />

        <title>Registre-se - SaaS HubLocal</title>
      </Helmet>

      <img src={hublocalLogo} alt="Hublocal SaaS" />

      <StyledWelcome>{registerTranslations.welcome}</StyledWelcome>
      {!isAllowed && (
        <LoginDialog
          open={!isAllowed}
          title="ConteÃºdo Restrito"
          contentText="VocÃª precisa acessar com uma conta e nÃ­vel Administrador ou Operacional para visualizar este conteÃºdo."
          onLogIn={() => {
            setIsAllowed(true);
          }}
          onCancelClicked={() => history.push('/login')}
        />
      )}
      {isAllowed && (
        <StyledForm onSubmit={(e) => handleSubmit(e)}>
          <StyleInputGroup>
            <Input
              fieldRef={nameRef}
              inputOptions={{
                name: 'name',
              }}
              inputValidate={nameValidate}
              label={registerTranslations.name}
            />
            <Input
              fieldRef={emailRef}
              inputOptions={{
                name: 'email',
              }}
              inputValidate={emailValidate}
              label={registerTranslations.email}
            />
          </StyleInputGroup>

          <StyledButtonsGroup>
            <Button
              className="buttons-group-button"
              type="submit"
              disabled={isFetching}
            >
              {isFetching ? <Loading /> : registerTranslations.sign_up}
            </Button>

            <span className="buttons-group-or">Ou</span>

            <Button
              className="buttons-group-button"
              as={Link}
              url="/"
              buttonType="secondary"
            >
              {registerTranslations.log_in}
            </Button>
          </StyledButtonsGroup>
        </StyledForm>
      )}

      <Disclaimer>
        {registerTranslations.use_terms_1}
        <a href="">{registerTranslations.use_terms_2}</a>
        {registerTranslations.use_terms_3}
        <a href="">{registerTranslations.use_terms_4}</a>
      </Disclaimer>
    </StyledRegister>
  );
};

export default Register;
