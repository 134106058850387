import axios from 'axios';

export default async function updateConnection({
  accessToken,
  connectionId,
  inputData,
  setTokenLikeExpired,
}) {
  try {
    const { data } = await axios.put(
      `${process.env.REACT_APP_CONNECTIONS_API_URL}/${connectionId}`,
      inputData,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      },
    );

    return data;
  } catch (err) {
    if (err.response?.status === 401 && setTokenLikeExpired) setTokenLikeExpired();

    return null;
  }
}
