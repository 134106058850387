import React from 'react';
import {
  Input, InputDatePicker, Select, SelectMultiple,
} from '../../../../components';
import {
  PlatformOptions,
  StatusOptions,
  TMonitorFilterProps,
  TPlatformOptions,
  TStatusOptions,
} from './helpers';
import { StyledDatesWrapper, StyledFiltersContainer } from './monitor-filter-styles';

const MonitorFilter = ({
  onChangeCompanyQuery,
  onChangeLocationQuery,
  selectedPlatformFilter,
  setSelectedPlatformFilter,
  selectedTypeFilter,
  setSelectedTypeFilter,
  ofPeriod,
  setOfPeriod,
  toPeriod,
  setToPeriod,
}: TMonitorFilterProps) => (
  <StyledFiltersContainer>
    <SelectMultiple<TPlatformOptions>
      name="platform-filter"
      value={selectedPlatformFilter}
      label="Plataforma(as):"
      options={PlatformOptions ?? []}
      onChange={(target) => setSelectedPlatformFilter(target)}
      border
      className="filter-content"

    />
    <Input
      className="filter-content"
      label="Empresa:"
      onChange={(e) => onChangeCompanyQuery(e.target.value)}
      inputOptions={{
        type: 'text',
        name: 'batch-filter-search-query',
      }}
      border
      placeholder="Escreva algo aqui"
    />
    <Input
      className="filter-content"
      label="Local:"
      onChange={(e) => onChangeLocationQuery(e.target.value)}
      inputOptions={{
        type: 'text',
        name: 'batch-filter-search-query',
      }}
      border
      placeholder="Escreva algo aqui"
    />
    <Select<TStatusOptions>
      name="type-update-filter"
      value={selectedTypeFilter}
      label="Status:"
      options={StatusOptions ?? []}
      onChange={(event) => setSelectedTypeFilter(event.target.value === 'Selecione uma opção' ? '' : event.target.value)}
      border
      className="filter-content"
    />
    <StyledDatesWrapper>
      <InputDatePicker
        selectedDate={ofPeriod}
        setSelectedDate={setOfPeriod}
        label="De:"
        maxDate={toPeriod || null}
        className="input-date-picker"
      />

      <InputDatePicker
        selectedDate={toPeriod}
        setSelectedDate={setToPeriod}
        minDate={ofPeriod || null}
        label="Até:"
        className="input-date-picker"
      />
    </StyledDatesWrapper>
  </StyledFiltersContainer>
);

export default MonitorFilter;
