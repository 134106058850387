import * as R from 'ramda';

export const filterRepeated = ({ categoryItem, categoriesReference }) => {
  if (!Array.isArray(categoriesReference)) return false;

  const foundCategory = categoriesReference.find(
    (categoryReference) => categoryReference.id === categoryItem.id,
  );

  if (!R.isNil(foundCategory)) return false;

  return true;
};
