import axios, { AxiosResponse } from 'axios';
import { TServiceResponse } from '../../types';

import { TBiJobsSummary, TGetBiJobsSummary } from './types';

export const getBIJobsSummary = async ({
  userToken,
}: TGetBiJobsSummary): Promise<TServiceResponse<TBiJobsSummary, unknown>> => {
  try {
    const { data }: AxiosResponse<TBiJobsSummary> = await axios.get(
      `${process.env.REACT_APP_BI_API}/bi-job/summary`,
      {
        headers: {
          Authorization: `Bearer ${userToken}`,
        },
      }
    );

    return {
      type: 'SUCCESS',
      response: data,
    };
  } catch (err) {
    return {
      type: 'ERROR',
      response: err,
    };
  }
};
