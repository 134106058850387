import React from 'react';

import ConfigCard from '../../../components/config-card';

import { StyledSyncMonitoring } from './sync-monitoring-styles';

const SyncMonitoring = ({ navigation }: any) => (
  <ConfigCard
    title="Monitoramento de Sincronizações"
    onClick={() => navigation.push('/sync-monitoring')}
  >
    <StyledSyncMonitoring>
      Gerencie e monitore solicitações de alteração de dados dos usuários.
    </StyledSyncMonitoring>
  </ConfigCard>
);

export default SyncMonitoring;
