import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory, useParams, Redirect } from 'react-router-dom';
import { CircularProgress } from '@material-ui/core';
import './CreateOrUpdateFunction.css';

import { Creators as RulesReducer } from '../../redux/ducks/rules';

import { Can } from '../../components';

import createFunction from '../../services/functions/createFunction';
import updateFunction from '../../services/functions/updateFunction';
import getFunction from '../../services/functions/getFunction';
import getHandlers from '../../services/handlers/getHandlers';
import getProfiles from '../../services/profiles/getProfiles';

const CreateFunction = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const params = useParams();
  const [loading, setLoading] = useState(false);
  const [handlers, setHandlers] = useState([]);
  const [form, setForm] = useState({
    name: '',
    description: '',
    handler: '',
    friendlyName: '',
    pathToRedirect: '',
    icon: '',
    slug: '',
  });

  const [directAccess, setDirectAccess] = useState(false);
  const [isAvailableInMenu, setIsAvailableInMenu] = useState(false);

  const { user } = useSelector((state) => state.AuthReducer);

  const { id } = params;

  React.useEffect(() => {
    setLoading(true);
    const fetchData = async () => {
      const handlerList = await getHandlers(user.accessToken);
      setHandlers(handlerList[0]);
      if (id) {
        const data = await getFunction(user.accessToken, id);
        setForm({
          name: data.name,
          description: data.description,
          handler: data.__handler__.id,
          icon: data.icon,
          pathToRedirect: data.path_to_redirect,
          friendlyName: data.friendly_name,
          slug: data.slug,
        });
        setDirectAccess(data.direct_access);
        setIsAvailableInMenu(data.is_available_in_menu);

        return data;
      }
    };

    fetchData();

    setLoading(false);
  }, []);

  const refreshReducer = async () => {
    const rules = {};
    const profiles = await getProfiles(user.accessToken);
    profiles[0].forEach((element) => {
      const funcs = [];
      element.functions.forEach((func) => {
        funcs.push(func.full_name);
      });
      rules[element.name] = { static: funcs };
    });
    dispatch(RulesReducer.setRules(rules));
  };

  const handleChangeForm = ({ target: { value, name } }) => {
    setForm({ ...form, [name]: value });
  };

  const handleChangeDirectAccess = () => {
    setDirectAccess(!directAccess);
  };

  const handleChangeIsAvailableInMenu = () => {
    setIsAvailableInMenu(!isAvailableInMenu);
  };

  const handleOnCreateClick = async () => {
    setLoading(true);
    let result;

    if (id) {
      await updateFunction(
        user.accessToken,
        id,
        form.name,
        form.description,
        form.friendlyName,
        form.handler,
        form.icon,
        form.slug,
        form.pathToRedirect,
        directAccess,
        isAvailableInMenu,
      );

      await refreshReducer();
    } else {
      await createFunction(
        user.accessToken,
        form.name,
        form.description,
        form.friendlyName,
        form.handler,
        form.pathToRedirect,
        form.icon,
        form.slug,
        directAccess,
        isAvailableInMenu,
        user.user.id,
      );
    }
    setLoading(false);
    history.push('/functions/list');
  };

  const renderButton = () => {
    if (loading) return <CircularProgress />;
    return (
      <button
        className="btn btn-primary button-margin-top font-poppins"
        onClick={handleOnCreateClick}
      >
        {id ? 'Salvar Função' : 'Criar Função'}
      </button>
    );
  };

  return (
    <Can
      perform="Functions/Create"
      yes={() => (
        <div style={{ padding: '1em' }}>
          <div className="title-div">
            <text className="font-poppins page-title">
              {id ? 'Editar Função' : 'Cadastro de função'}
            </text>
          </div>
          <label className="font-poppins">Nome</label>
          <input
            className="form-control font-poppins"
            name="name"
            value={form.name}
            onChange={handleChangeForm}
          />

          <label className="font-poppins">Descrição</label>
          <input
            className="form-control font-poppins"
            name="description"
            value={form.description}
            onChange={handleChangeForm}
          />

          <label className="font-poppins">Handler</label>
          <select
            value={form.handler}
            name="handler"
            onChange={handleChangeForm}
            className="form-control font-poppins"
          >
            <option value="" selected disabled hidden />
            {handlers.map((element) => (
              <option value={element.id}>{element.name}</option>
            ))}
          </select>

          <label className="font-poppins">Ícone</label>
          <input
            className="form-control font-poppins"
            name="icon"
            value={form.icon}
            onChange={handleChangeForm}
          />

          <label className="font-poppins">URL para redirecionar</label>
          <input
            className="form-control font-poppins"
            name="pathToRedirect"
            placeholder="/path-example/example"
            value={form.pathToRedirect}
            onChange={handleChangeForm}
          />

          <label className="font-poppins">Nome Amigável</label>
          <input
            className="form-control font-poppins"
            name="friendlyName"
            value={form.friendlyName}
            onChange={handleChangeForm}
          />

          <label className="font-poppins">Slug</label>
          <input
            className="form-control font-poppins"
            name="slug"
            value={form.slug}
            onChange={handleChangeForm}
          />

          <div>
            <label className="font-poppins checkbox-label">
              Acesso Direto?
              <input
                type="checkbox"
                className="checkbox"
                checked={directAccess}
                onChange={handleChangeDirectAccess}
              />
            </label>

            <label className="font-poppins checkbox-label">
              Acessível no menu?
              <input
                type="checkbox"
                className="checkbox"
                checked={isAvailableInMenu}
                onChange={handleChangeIsAvailableInMenu}
              />
            </label>
          </div>

          <div className="create-button-div">{renderButton()}</div>
        </div>
      )}
      no={() => <Redirect to="/main" />}
    />
  );
};

export default CreateFunction;
