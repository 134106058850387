import React, { FormEvent, useEffect, useState } from 'react';
import { isEmpty, invertObj } from 'ramda';
import { debounce } from 'lodash';
import { v4 as uuidv4 } from 'uuid';
import { useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';

import {
  DragAndDropFile,
  Checklist,
  Loading,
  Select,
  Button,
} from '../../../components';

import { useAuth } from '../../../hooks';
import { imagePostValidation } from '../../locations-posts/helpers';

import { ListLocations } from '../../../utils/list-locations';
import { TChecklistType } from '../../../types/TChecklist';
import { EGroupType } from '../../../types/TLocation';
import { TRootStateRedux } from '../../../types/TRootStateRedux';
import { TBatchCreateGallery } from '../../../types/TBatchUpdate';
import createMediaGallery from '../../../services/batch-update/batchCreateGallery';
import { batchGalleryCategories } from '../helpers';
import putObject from '../../../services/aws/putObject';

import { EBatchGalleryCategories, TBatchGalleryDialogFormProps } from '../../../types/TBatchGallery';

import {
  StyledCloseIcon,
  StyledGalleryBatchForm,
  StyledDialogBody,
  StyledDialogHeader,
  StyledDialogForm,
  LoadingContainer,
} from './gallery-batch-form-styles';

const GalleryBatchForm = ({
  open = false,
  closeModal,
}: TBatchGalleryDialogFormProps) => {
  const {
    userAccessToken,
    userId,
    userName,
    userEmail,
  } = useAuth();
  const { enqueueSnackbar } = useSnackbar();

  const [locationsList, setLocationsList] = useState([]);
  const [locationsListNoEligible, setLocationsListNoEligible] = useState(0);
  const [locationsListTotalLength, setLocationsListTotalLength] = React.useState(0);
  const [locationsListPageIndex, setLocationsListPageIndex] = React.useState(0);
  const [locationsListPageSize] = React.useState(10);
  const [locationsListPageQuantity, setLocationsListPageQuantity] = useState(0);

  const [masterCheckbox, setMasterCheckbox] = React.useState(true);
  const [locationsListQuery, setLocationsListQuery] = React.useState('');
  const [locationsToExclude, setLocationsToExclude] = React.useState<TChecklistType>({
    type: 'EXCLUDED',
    ids: [],
  });

  const [selectedCategory, setSelectedCategory] = useState<EBatchGalleryCategories>('Geral' as EBatchGalleryCategories.CATEGORY_UNSPECIFIED);
  const [files, setFiles] = useState<File[]>([] as File[]);

  const [isLoading, setIsLoading] = React.useState(false);

  const { activeLocationGroupId } = useSelector(
    (state: TRootStateRedux) => state.LocationGroupReducer,
  );
  const { activeCompanyId, showLocationsByCompany } = useSelector(
    (state: TRootStateRedux) => state.CompanyReducer,
  );

  const platformOptions = [
    { id: 1, name: 'Google' },
  ];

  const categoriesOptions = [
    {
      id: 1, name: batchGalleryCategories.CATEGORY_UNSPECIFIED,
    },
    {
      id: 2,
      name: batchGalleryCategories.COVER,
    },
    {
      id: 3,
      name: batchGalleryCategories.LOGO,
    },
  ];

  const { locations, locationsNoEligibles, isFetching } = ListLocations({
    accessToken: userAccessToken,
    page: locationsListPageIndex,
    query: locationsListQuery,
  });

  const handleSearchLocation = (locationName: string) => {
    setLocationsListPageIndex(0);
    setLocationsListQuery(locationName);
  };

  const handleInputSearch = debounce(
    (value) => handleSearchLocation(value),
    500,
  );

  useEffect(() => {
    setLocationsList(locations[0]);
    setLocationsListTotalLength(locations[1]);
    setLocationsListNoEligible(locationsNoEligibles.count);
    setLocationsListPageQuantity(Math.ceil(locations[1] / locationsListPageSize));
  }, [locations, locationsNoEligibles]);

  const handleSubmit = async (event: FormEvent) => {
    event.preventDefault();

    if (isEmpty(files)) return;

    setIsLoading(true);

    const mediaData = await Promise.all(files.map(async (fileToGallery) => {
      const putObjectData = await putObject({
        accessToken: userAccessToken,
        bucketName: 'hub-saas-media',
        file: fileToGallery,
        googleAccountId: null,
        googleLocationId: null,
        mediaType: fileToGallery.type === 'video/mp4' ? 'VIDEO' : 'PHOTO',
      });

      const mediaFileUrl = putObjectData.fileUrl;

      return {
        id: uuidv4(),
        mediaFormat: fileToGallery.type === 'video/mp4' ? 'VIDEO' : 'PHOTO',
        sourceUrl: mediaFileUrl,
        category: invertObj(batchGalleryCategories)[selectedCategory],
      };
    }));

    const bodyToSend: TBatchCreateGallery = {
      groupType: showLocationsByCompany ? EGroupType.COMPANY : EGroupType.LOCATIONS_GROUP,
      groupId: showLocationsByCompany ? activeCompanyId : activeLocationGroupId,
      accessToken: userAccessToken,
      createdBy: userId,
      mediaData,
      locations: locationsToExclude,
      userData: {
        user_id: userId,
        user_name: userName,
        user_email: userEmail,
      },
      feedbackMessage: enqueueSnackbar,
    };

    await createMediaGallery(bodyToSend);
    setIsLoading(false);

    closeModal(true);
  };

  return (
    <StyledGalleryBatchForm
      open={open}
      fullScreen
    >
      <StyledDialogHeader>
        <h2>Adicionar mídia</h2>

        <StyledCloseIcon onClick={() => closeModal(false)} />
      </StyledDialogHeader>

      <StyledDialogBody>
        <StyledDialogForm>
          <Select
            label="Plataforma"
            options={platformOptions}
            name="platform"
            disabled
          />

          <Select
            name="Categories"
            label="Categoria"
            options={categoriesOptions}
            value={selectedCategory}
            onChange={(e) => {
              setFiles([]);
              setSelectedCategory(e.target.value as EBatchGalleryCategories);
            }}
          />

          <DragAndDropFile
            filesToPost={files}
            setFilesToPost={setFiles}
            imageFileValidation={imagePostValidation}
            maxVideoSize={70}
            singleMode={selectedCategory.toString() !== batchGalleryCategories.CATEGORY_UNSPECIFIED}
            category={selectedCategory}
          />
          <Button onClick={handleSubmit} disabled={isLoading}>
            {isLoading && <Loading className="loading-icon" />}
            {' '}
            Publicar
          </Button>
        </StyledDialogForm>

        {isFetching
          ? <LoadingContainer><Loading /></LoadingContainer>
          : (
            <Checklist
              options={locationsList}
              optionsTotalLength={locationsListTotalLength}
              optionsNoEllegible={locationsListNoEligible}
              optionsPageIndex={locationsListPageIndex}
              setOptionsPageIndex={setLocationsListPageIndex}
              optionsPageQuantity={locationsListPageQuantity}
              handleInputSearch={handleInputSearch}
              selectedOptions={locationsToExclude}
              setSelectedOptions={setLocationsToExclude}
              masterCheckbox={masterCheckbox}
              setMasterCheckbox={setMasterCheckbox}
            />
          )}
      </StyledDialogBody>
    </StyledGalleryBatchForm>
  );
};

export { GalleryBatchForm };
