import axios from 'axios';

export default async function getUserCompanies({
  accessToken,
  userId,
  page = 0,
  pageSize = 10,
  query = '',
  orderBy = null,
  setTokenLikeExpired,
  setIsFetching,
}) {
  try {
    if (setIsFetching) setIsFetching(true);

    const { data } = await axios.get(
      `${process.env.REACT_APP_COMPANIES_API_URL}/user-companies/${userId}?page=${page}&pageSize=${pageSize}&query=${query}&orderBy=${orderBy}`,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      },
    );

    return data;
  } catch (err) {
    if (err.response?.status === 401 && setTokenLikeExpired) setTokenLikeExpired();

    return null;
  } finally {
    if (setIsFetching) setIsFetching(false);
  }
}
