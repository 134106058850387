import * as R from 'ramda';

import { emailRegExp } from '../../utils/regexp';

export const formatLocationAccessLevelProfiles = locationAccessLevelProfiles => {
  const formatted = [];

  if (!R.isNil(locationAccessLevelProfiles)) {
    locationAccessLevelProfiles.map(profile => {
      formatted.push({
        name: profile.name,
        value: profile.id,
      });
    });

    return formatted;
  }
};

export const inviteFieldValidate = ({
  email,
  profile,
  feedbackMessageFunction,
  ignoreProfile,
}) => {
  if (R.isEmpty(email)) {
    feedbackMessageFunction('O campo email não pode ser vázil para o envio do convite!', { variant: 'warning' });

    return false;
  }

  if (!emailRegExp.test(email)) {
    feedbackMessageFunction('Preencha um email válido para o envio do convite!', { variant: 'warning' });

    return false;
  }

  if (!ignoreProfile && R.isEmpty(profile)) {
    feedbackMessageFunction('O convite precisa ter um perfil!', { variant: 'warning' });

    return false;
  }

  return true;
};
