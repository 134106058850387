import React from 'react';
import * as R from 'ramda';

import APIUpdateLocation from '../../../../services/locations/updateLocation';
import APIGetGoogleLocationReviews from '../../../../services/google/reviews/getLocationReviews';
import APIGetLocationByGoogleLocationId from '../../../../services/locations/getLocations';

import {
  IconButton,
  OverlayLoading,
  SelectDropdown,
} from '../../../../components';

import {
  StyledLocationRow,
  StyledBinding,
  StyledSelectWrapper,
  StyledAddressText,
  StyledLocationToBindName,
  StyledLocationNameAndAddressWrapper,
} from './location-row-styles';

import Context from '../../context';

const LocationRow = ({
  locationGoogleName,
  companyLocations,
  locationNameWithId,
  locationAddress,
  accessToken,
  setTokenLikeExpired,
  loadGoogleLocations,
  getCompanyLocations,
  googleAccessToken,
  disabledLink,
}) => {
  const {
    companyLocationsPage,
    companyLocationsPagesQuantity,
    setCompanyLocationsQuery,
    setCompanyLocationsPage,
    isLoadingCompanyLocations,
    activeCompanyId,
    enqueueSnackbar,
  } = React.useContext(Context);

  const [isLoading, setIsLoading] = React.useState(false);
  const [isLoadingText, setIsLoadingText] = React.useState('Carregando...');

  const [isChangeLocation, setIsChangeLocation] = React.useState(false);

  const [googleLocationId, setGoogleLocationId] = React.useState(null);
  const [locationBinded, setLocationBinded] = React.useState(null);

  const [accountGroupId, setAccountGroupId] = React.useState(null);

  const getCompanyLocationWithGoogleLocationId = React.useCallback(async () => {
    setLocationBinded(null);

    if (R.isNil(googleLocationId)) return;

    const locationWithGoogleId = await APIGetLocationByGoogleLocationId({
      accessToken,
      setTokenLikeExpired,
      query: `${googleLocationId}`,
    });

    const [companyLocationsResponse] = locationWithGoogleId;

    if (R.isEmpty(companyLocationsResponse)) return;

    setLocationBinded(companyLocationsResponse[0]);
  }, [activeCompanyId, googleLocationId]);

  React.useEffect(() => {
    if (R.isNil(locationNameWithId)) return;

    const [, accountGroupIdResponse, , accountGroupLocationIdResponse] = locationNameWithId.split('/');

    setGoogleLocationId(accountGroupLocationIdResponse);
    setAccountGroupId(accountGroupIdResponse);
  }, [locationNameWithId]);

  React.useEffect(() => {
    getCompanyLocationWithGoogleLocationId();
  }, [getCompanyLocationWithGoogleLocationId]);

  const handleSelectLocation = async (selectedLocation) => {
    if (R.isNil(companyLocations)) return;

    setLocationBinded(null);

    setIsLoadingText('Salvando...');

    const updateLocationResponse = await APIUpdateLocation({
      accessToken,
      locationId: selectedLocation.id,
      setIsFetching: setIsLoading,
      setTokenLikeExpired,
      inputData: {
        google_account_id: accountGroupId,
        google_location_id: googleLocationId,
      },
      shouldNotGenerateRequest: true,
    });

    if (R.isNil(updateLocationResponse)) return;

    setLocationBinded(selectedLocation);
    setIsChangeLocation(false);
  };

  const handleRemoveLocation = async () => {
    setIsLoadingText('Removendo...');

    const updateLocationResponse = await APIUpdateLocation({
      accessToken,
      locationId: locationBinded.id,
      setIsFetching: setIsLoading,
      setTokenLikeExpired,
      inputData: {
        google_account_id: null,
        google_location_id: null,
      },
      deleteGoogle: true,
    });

    if (R.isNil(updateLocationResponse)) return;

    setLocationBinded(null);
    loadGoogleLocations();
    getCompanyLocations();
  };

  return (
    <StyledLocationRow>
      {isLoading && <OverlayLoading textToLoading={isLoadingText} />}

      <StyledBinding locationBinded={!locationBinded}>
        <StyledLocationToBindName>
          {disabledLink ? (
            <IconButton
              tooltip="Local Inválido"
              tooltipVariant="red"
              forceUseDefaultCursor
              icon="Error"
              isError
              label="Inválido"
            />
          ) : (
            <IconButton
              tooltip="Local Válido"
              tooltipVariant="green"
              forceUseDefaultCursor
              icon="CheckCircle"
              isSuccess
              label="OK"
            />
          )}

          <StyledLocationNameAndAddressWrapper>
            <span className="location-name">{locationGoogleName}</span>
            <StyledAddressText>
              {locationAddress?.addressLines[0] || ''}
            </StyledAddressText>
          </StyledLocationNameAndAddressWrapper>
        </StyledLocationToBindName>

        <IconButton
          className="link-infos"
          icon="Link"
          tooltip="Relação"
          isError={disabledLink}
          tooltipVariant={disabledLink ? 'red' : 'primary'}
          forceUseDefaultCursor
        />
        {!R.isNil(locationBinded) && !isChangeLocation ? (
          <div className="location-name-binded">
            <span>{locationBinded.name}</span>
            <span className="location-address-binded">
              {locationBinded.address1 || ''}
            </span>
          </div>
        ) : (
          <StyledSelectWrapper>
            <SelectDropdown
              data={companyLocations}
              onOptionClicked={handleSelectLocation}
              page={companyLocationsPage}
              pageQuantity={companyLocationsPagesQuantity}
              setPage={setCompanyLocationsPage}
              setQuery={setCompanyLocationsQuery}
              isLoading={isLoadingCompanyLocations}
              keyToSetDisable="google_location_id"
              hasSubLabel
            />
          </StyledSelectWrapper>
        )}
      </StyledBinding>

      {!R.isNil(locationBinded) && !isChangeLocation && (
        <IconButton
          icon="Edit"
          tooltip="Alterar"
          className="icon-change-location"
          onClick={() => setIsChangeLocation(!isChangeLocation)}
        />
      )}

      {isChangeLocation && (
        <IconButton
          icon="Close"
          tooltip="Cancelar edição"
          className="icon-cancel-location"
          onClick={() => setIsChangeLocation(!isChangeLocation)}
        />
      )}

      {!R.isNil(locationBinded) && (
        <IconButton
          icon="RemoveCircle"
          tooltip="Remover local"
          className="icon-remove-location"
          onClick={handleRemoveLocation}
        />
      )}
    </StyledLocationRow>
  );
};

export default LocationRow;
