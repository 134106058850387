import styled, { css } from 'styled-components';
import {
  ErrorOutline as ErrorOutlineIcon,
  Visibility as VisibilityIcon,
  VisibilityOff as VisibilityOffIcon,
  Phone as PhoneIcon,
  PhoneAndroid as PhoneAndroidIcon,
} from '@material-ui/icons';
import { Tooltip } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import InputMask from 'react-input-mask';

import { lighten, shade } from 'polished';

import { light } from '../../assets/styles/themes';

export const StyledTooltipWarning = withStyles({
  tooltip: {
    fontSize: '1.2rem',
    color: light.colors.white,
    background: `${light.colors.red} !important`,
    fontWeight: 'bold',
  },
  arrow: {
    color: light.colors.red,
  },
})(Tooltip);

export const StyledTooltipPassword = withStyles({
  tooltip: {
    fontSize: '1.2rem',
    color: light.colors.white,
    background: `${light.colors.black} !important`,
    fontWeight: 'bold',
  },
  arrow: {
    color: light.colors.black,
  },
})(Tooltip);

export const StyledTooltipLandline = withStyles({
  tooltip: {
    fontSize: '1.2rem',
    color: light.colors.white,
    background: `${light.colors.black} !important`,
    fontWeight: 'bold',
  },
  arrow: {
    color: light.colors.black,
  },
})(Tooltip);

export const StyledUncontrolledField = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  .input-mask {
    width: 100%;
    height: 4.1rem;
    padding: 0 1.5rem;
    border: none;

    ${(props) => props.required
      && css`
        padding-right: 4.2rem;
      `};
  }
`;

export const StyledLabel = styled.label`
  font-size: 1.5rem;
  font-weight: normal;
  color: ${(props) => props.theme.colors.black};
`;

export const StyledWarningIcon = styled(ErrorOutlineIcon)`
  width: 2.5rem !important;
  height: 2.5rem !important;
  cursor: pointer;
  fill: ${(props) => props.theme.colors.red} !important;
`;

export const StyledVisibilityPasswordIcon = styled(VisibilityIcon)`
  width: 2.5rem !important;
  height: 2.5rem !important;
  cursor: pointer;
  fill: ${(props) => props.theme.colors.black} !important;
`;

export const StyledVisibilityOffPasswordIcon = styled(VisibilityOffIcon)`
  width: 2.5rem !important;
  height: 2.5rem !important;
  cursor: pointer;
  fill: ${(props) => props.theme.colors.black} !important;
`;

export const StyledInputWrap = styled.div`
  position: relative;
  display: flex;
  align-items: stretch;
  border: none;

  ${(props) => props.hasborder === 'yes'
    && css`
      border: 2px solid ${(props) => lighten(0.4, props.theme.colors.black)};
    `};

  ${(props) => props.hasradius === 'yes'
    && css`
      border-radius: 0.4rem;
    `};

  ${(props) => props.inputFocus
    && css`
      border-color: ${props.theme.colors.primary};
      transform: scale(1.01);
    `};
`;

export const StyledInput = styled.input`
  width: 100%;
  height: 4.1rem;
  padding: 0 1.5rem;
  border: none;
  transition: 0.3s;

  ${(props) => props.isrequerid === 'yes'
    && css`
      padding-right: 4.2rem;
    `};

  ${(props) => props.password
    && css`
      padding-right: 7.3rem;
    `};

  ${(props) => props.uppercase
    && css`
      text-transform: uppercase;
    `}
`;

export const StyledTextarea = styled(StyledInput).attrs((props) => ({
  as: 'textarea',
}))`
  padding: 1.5rem;
  resize: none;
  height: auto;
  transition: 0.3s;

  ${(props) => props.isMaxLength
    && css`
      border-color: ${(props) => props.theme.colors.red};
    `}

  ${(props) => props.isrequerid == 'yes'
    && css`
      padding-right: 3rem;
    `}
`;

export const StyledInputMask = styled(InputMask)`
  width: 100%;
  height: 4.1rem;
  padding: 0 1.5rem;
  border: none;
  transition: 0.3s;

  ${(props) => props.isrequerid === 'yes'
    && css`
      padding-right: 4.2rem;
    `};
`;

export const StyledDisclaimer = styled.span`
  font-size: 1.1rem;
  color: ${(props) => props.theme.colors.gray};
  line-height: 1;
  margin-top: 0.5rem;
`;

export const StyledSelect = styled.select`
  width: 100%;
  height: 4.1rem;
  padding: 0 1.5rem;
  border: none;

  ${(props) => props.hasborder === 'yes'
    && css`
      border: 2px solid ${(props) => lighten(0.4, props.theme.colors.black)};
    `};

  ${(props) => props.hasradius === 'yes'
    && css`
      border-radius: 0.4rem;
    `};

  ${(props) => props.required
    && css`
      padding-right: 4.2rem;
    `};
`;

export const StyledPhoneIcon = styled(PhoneIcon)`
  width: 2.5rem !important;
  height: 2.5rem !important;
  cursor: pointer;
  fill: ${(props) => props.theme.colors.black} !important;
`;

export const StyledPhoneAndroidIcon = styled(PhoneAndroidIcon)`
  width: 2.5rem !important;
  height: 2.5rem !important;
  cursor: pointer;
  fill: ${(props) => props.theme.colors.black} !important;
`;

export const StyledFloatingIcons = styled.div`
  display: flex;
  z-index: 2;
  cursor: pointer;
  align-self: center;
  margin: 0rem 1rem;
`;

export const StyledDescriptionAreaCount = styled.span`
  font-size: 1.2rem;
  font-weight: 500;
  margin-top: 0.5rem;
  margin-left: auto;

  ${(props) => props.isMaxLength
    && css`
      color: ${(props) => props.theme.colors.red};
    `}
`;

export const StyledDescriptionWrapper = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
`;

export const StyledSwitchInputEnable = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  border-left: 2px solid ${(props) => lighten(0.4, props.theme.colors.black)};
  padding: 0rem 1rem;
`;

export const StyledDisabledInput = styled.div`
  font-size: 1.4rem;
  font-style: italic;
  font-weight: 500;
  display: block;
  align-self: center;
  margin-right: auto;
  margin-bottom: 0;
  color: ${(props) => shade(0.2, props.theme.colors.white)};

  padding: 1rem 2rem;
`;
