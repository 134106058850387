import React from 'react';

import { Input, Select } from '../../../../../components';
import { updateStatusOptionsWithAllOption } from '../../../../../components/batch-filter/helpers';

import { StyledFiltersContainer } from './filters-bar-styles';

type TFiltersBarProps = {
  onChangeSearchQuery: (text: string) => void;
  selectedStatus: string;
  setSelectedStatus: React.Dispatch<React.SetStateAction<string>>;
};

const FiltersBar = ({
  onChangeSearchQuery,
  selectedStatus,
  setSelectedStatus,
}: TFiltersBarProps) => {
  const onChangeSelectedStatus = React.useCallback(
    (event: React.ChangeEvent<HTMLSelectElement>) => {
      const { value } = event.currentTarget;
      setSelectedStatus(value === 'Todos' ? '' : value);
    },
    []
  );

  return (
    <StyledFiltersContainer>
      <Input
        label="Pesquisar locais"
        onChange={(e) => onChangeSearchQuery(e.target.value)}
        inputOptions={{
          type: 'text',
          name: 'search-location',
        }}
        border
      />

      <Select
        name="service-batch-location-status"
        value={selectedStatus}
        label="Status"
        options={updateStatusOptionsWithAllOption}
        onChange={onChangeSelectedStatus}
        border
        className="filter-content"
      />
    </StyledFiltersContainer>
  );
};

export default FiltersBar;
