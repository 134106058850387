import React from 'react';

import ProgressBar from '../progress-bar';

import {
  StyledProgressInfo,
  StyledProgressContent,
  StyledChevronRight,
} from './progress-info-styles';

export default ({
  progress,
  className,
  isMissing,
  fieldsToBeFilled,
}) => (
  <StyledProgressInfo className={className}>
    <h2>Nível de otimização</h2>

    {progress && <ProgressBar progressValue={progress} />}

    {isMissing.length > 0 && (
      <StyledProgressContent>
        <span>Sugestões de melhorias:</span>
        <ul>
          {isMissing.map(element => (
            <li key={Math.random(Math.floor())} onClick={() => fieldsToBeFilled(element)}>
              {element.name}
              <StyledChevronRight />
            </li>
          ))}
        </ul>
      </StyledProgressContent>
    )}
  </StyledProgressInfo>
);
