import styled from 'styled-components';
import { Dialog } from '@material-ui/core';
import { Error } from '@material-ui/icons';
import { shade } from 'polished';

import { devices } from '../../../assets/styles/devices';

export const StyledCreateServiceDialog = styled(Dialog)`
  .MuiDialog-paper {
    min-width: 28rem;
    width: 100%;
    overflow-y: hidden;
  }

  .MuiDialog-paperWidthSm {
    max-width: 90rem;
  }
`;

export const StyledHeader = styled.header`
  padding: 1rem 2rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 0.1rem solid ${props => shade(0.2, props.theme.colors.white)};
  background: ${props => shade(0.05, props.theme.colors.white)};

  h2 {
    margin-bottom: 0;
  }

  p {
    margin-bottom: 0;
  }
`;

export const StyledFooter = styled.header`
  padding: 1rem 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border-bottom: 0.1rem solid ${props => shade(0.2, props.theme.colors.white)};
  background: ${props => shade(0.05, props.theme.colors.white)};

  h2 {
    margin-bottom: 0;
  }
`;

export const StyledPostAndFilterWrapper = styled.div`
  display: flex;
  flex-direction: column;
  background: ${props => shade(0.1, props.theme.colors.white)};
  column-gap: 1rem;
  padding: 1rem;
  overflow-y: scroll;

  @media ${devices.tabletLandscape} {
    flex-direction: row;
  }
  
`;

export const StyledHasLocationsWithoutLinked = styled.div`
  padding: 2rem;
  border-radius: 0.5rem;
  font-size: 1.4rem;
  width: 100%;

  b {
    color: ${props => props.theme.colors.red};
  }
`;

export const StyledErrorLocationList = styled.div`
  margin-top: 2rem;
  align-items: center;
`;

export const StyledErrorIcon = styled(Error)`
  color: ${props => props.theme.colors.god};
  height: 2.2rem;
  width: 2.2rem;
`;

export const StyledRow = styled.div`
  display: flex;
  align-items: center;
  column-gap: 1rem;
  padding: 1rem;

  &:nth-child(even) {
    background: ${props => shade(0.2, props.theme.colors.white)};
  }
`;

export const StyledConfirmButton = styled.div`
  display: flex;
  align-content: center;
  justify-content: center;
  border-radius: 3rem;
  font-size: 1.4rem;
  font-weight: 500;
  background: ${props => props.theme.colors.primary};
  color: ${props => props.theme.colors.white};
  padding: 1rem 2rem;
  cursor: pointer;
  transition: 0.5s;
  
  &:hover {
    background: ${props => shade(0.1, props.theme.colors.primary)};
    transform: scale(1.1);
  }
`;
