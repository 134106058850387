import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import * as R from 'ramda';
import { useSnackbar } from 'notistack';

import checkUserProfile from '../../services/checkUserProfile';

import { HeaderToolbar, BackToTop } from '../../components';

import { Creators as AuthActions } from '../../redux/ducks/auth';

import Context from './context';

import {
  starRatingState,
  listAutomaticResponsesByState,
  isReview,
} from './helpers';

import {
  StyledReviews,
  StyledContent,
  StyledContainer,
} from './batch-platforms-linked-with-locations-styles';

import APIGetCompanyLocations from '../../services/locations/getCompanyLocations';

import Google from './google';

const BatchPlatformsLinkedWithLocations = () => {
  const routerPagesHistory = useHistory();
  const reduxDispatch = useDispatch();

  const [starRating, setStarRating] = React.useState(starRatingState);
  const [listAutomaticResponsesBy, setListAutomaticResponsesBy] = React.useState(listAutomaticResponsesByState);

  const [companyLocationsPage, setCompanyLocationsPage] = React.useState(0);
  const [companyLocationsPageSize, setCompanyLocationsPageSize] = React.useState(10);
  const [companyLocationsQuery, setCompanyLocationsQuery] = React.useState('');
  const [companyLocationsPagesQuantity, setCompanyLocationsPagesQuantity] = React.useState(0);
  const [companyLocationsData, setCompanyLocationsData] = React.useState(null);
  const [isLoadingCompanyLocations, setIsLoadingCompanyLocations] = React.useState(false);

  const [reviewsByLocation, setReviwesByLocation] = React.useState([]);
  const [reviewsByLocationFiltered, setReviewsByLocationFiltered] = React.useState([]);
  const [sensitiveWordsToFilter, setSensitiveWordsToFilter] = React.useState(
    [],
  );
  const [locationsToFilter, setLocationsToFilter] = React.useState([]);

  const [googleLocationByAccountId, setGoogleLocationByAccountId] = React.useState([]);

  const [isLoadingInformations, setIsLoadingInformations] = React.useState(false);
  const [isLoadinInformationsText, setIsLoadinInformationsText] = React.useState('Carregando informações...');

  const { enqueueSnackbar } = useSnackbar();

  const [googleAccessToken, setGoogleAccessToken] = React.useState(null);
  const [reviewsChecked, setReviewsChecked] = React.useState([]);

  const { user } = useSelector((state) => state.AuthReducer);

  const { activeCompany, activeCompanyId } = useSelector(
    (state) => state.CompanyReducer,
  );

  const {
    accessToken,
    user: { profiles },
  } = user || {};

  const [userProfile] = profiles || [];

  const setTokenLikeExpired = () => {
    reduxDispatch(AuthActions.setUser({ ...user, accessToken: 'expired' }));
  };

  const filtersReset = React.useCallback(() => {
    setLocationsToFilter([]);
    setSensitiveWordsToFilter([]);
    setReviewsByLocationFiltered([]);
    setStarRating(starRatingState);
    setListAutomaticResponsesBy(listAutomaticResponsesByState);
  }, []);

  const getCompanyLocations = React.useCallback(async () => {
    filtersReset();

    const response = await APIGetCompanyLocations({
      accessToken,
      companyId: activeCompany.id,
      page: companyLocationsPage,
      pageSize: companyLocationsPageSize,
      query: companyLocationsQuery,
      setIfFetching: setIsLoadingCompanyLocations,
    });

    if (response) {
      const [data, amount] = response;
      setCompanyLocationsPagesQuantity(
        Math.ceil(amount / companyLocationsPageSize),
      );

      setCompanyLocationsData(data);
    }
  }, [
    activeCompany,
    companyLocationsPage,
    companyLocationsPageSize,
    companyLocationsQuery,
  ]);

  React.useEffect(() => {
    getCompanyLocations();
  }, [getCompanyLocations]);

  const isNoClient = !checkUserProfile(user.user, 'Usuário Padrão');

  const reviewListingFilter = ({
    currentListBy,
    currentStarRating,
    sensitiveWords,
    reviewsPaginated = [],
  }) => {
    setReviewsChecked([]);

    const listByFiltered = (reviewItem) => {
      if (currentListBy === 'answered') return R.has('reviewReply', reviewItem.review);
      if (currentListBy === 'unanswered') return !R.has('reviewReply', reviewItem.review);

      return true;
    };

    const starRatingFiltered = (reviewItem) => {
      if (!R.isNil(currentStarRating.word)) {
        return reviewItem.review.starRating === currentStarRating.word;
      }

      return reviewItem;
    };

    const sensitiveWordFiltered = (reviewItem) => {
      const { review } = reviewItem;

      if (!R.isEmpty(sensitiveWords) && !R.isNil(sensitiveWords)) {
        return sensitiveWords.every((sensitiveWord) => R.contains(R.toLower(sensitiveWord), R.toLower(review.comment || '')));
      }

      return reviewItem;
    };

    const reviewToApplyFilter = R.isEmpty(reviewsPaginated)
      ? reviewsByLocation
      : reviewsPaginated;

    if (isReview(reviewToApplyFilter)) {
      const filteredReviews = reviewToApplyFilter
        .filter(sensitiveWordFiltered)
        .filter(listByFiltered)
        .filter(starRatingFiltered);

      if (isReview(filteredReviews)) {
        setReviewsByLocationFiltered(filteredReviews);
      } else {
        setReviewsByLocationFiltered([]);
      }

      return filteredReviews.length || 0;
    }
  };

  const ReviewsContextValue = {
    starRating,
    setStarRating,
    listAutomaticResponsesBy,
    setListAutomaticResponsesBy,
    reviewsByLocation,
    reviewsByLocationFiltered,
    reviewListingFilter,
    sensitiveWordsToFilter,
    setSensitiveWordsToFilter,
    locationsToFilter,
    setLocationsToFilter,
    hublocalUserToken: accessToken,
    activeCompany,
    setTokenLikeExpired,
    enqueueSnackbar,
    setIsLoadinInformationsText,
    routerPagesHistory,
    reduxDispatch,
    user,
    accessToken,
    setIsLoadingInformations,
    setReviwesByLocation,
    setReviewsByLocationFiltered,
    googleAccessToken,
    setGoogleAccessToken,
    getCompanyLocations,
    companyLocations: companyLocationsData,
    companyLocationsPage,
    companyLocationsPageSize,
    companyLocationsPagesQuantity,
    isLoadingCompanyLocations,
    setCompanyLocationsQuery,
    setCompanyLocationsPage,
    setCompanyLocationsPageSize,
    userProfile,
    isNoClient,
    activeCompanyId,
    reviewsChecked,
    setReviewsChecked,
    setGoogleLocationByAccountId,
    googleLocationByAccountId,
  };

  return (
    <StyledReviews>
      <Helmet>
        <meta property="og:title" content="Vinculação de Locais em Massa" />
        <meta
          property="og:description"
          content="Vinculação de Locais em Massa com plataformas"
        />

        <title>Vinculação de Locais em Massa - SaaS Hublocal</title>
      </Helmet>
      <Context.Provider value={ReviewsContextValue}>
        <HeaderToolbar
          title="Vinculação de Locais em Massa"
          dropdownsCompany
          paddingLeft
          overlayLoadingNoDisplay
        />
        <StyledContainer>
          <StyledContent>
            <Google isNoClient={isNoClient} />
          </StyledContent>
        </StyledContainer>
      </Context.Provider>
      <BackToTop />
    </StyledReviews>
  );
};

export default BatchPlatformsLinkedWithLocations;
