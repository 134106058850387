import React from 'react';
import { useSelector } from 'react-redux';
import * as R from 'ramda';
import { parse, format } from 'date-fns';

import { useSnackbar } from 'notistack';

import {
  ExpandLess,
  ExpandMore,
} from '@material-ui/icons';

import APIBatchCreatePosts from '../../../../services/batch-update/batchCreatePosts';
import createTicket from '../../../../services/tickets/create';
import getValidGoogleAccessToken from '../../../../services/getValidGoogleAccessToken';
import createOfferPost from '../../../../services/google/post/createPost';
import { TRootStateRedux } from '../../../../types/TRootStateRedux';
import putObject from '../../../../services/aws/putObject';
import DragAndDropFiles from '../../../../components/drag-and-drop-file';
import { inputTimeToArrayData } from '../../../../utils/input-time-to-array-data';

import type {
  TBatchPostFormOfferBaseProps,
} from '../../../../types/TLocationPost';

import {
  Input,
  InputDatePicker,
  Textarea,
  Time,
  Button,
  Loading,
  InputSwitch,
} from '../../../../components';

import { websiteValidate } from '../../../../utils/fields-validate';

import { useAuth } from '../../../../hooks';

import {
  formatDateObjectToString,
  imagePostValidation,
} from '../../helpers';

import {
  isValidPostStartToEndDate,
  isValidPostStartToEndTime,
  isVideoFiles,
} from '../helpers';

import {
  StyledPostFormOffer,
  StyledButton,
  StyledAdditionalDetailsWrapper,
  StyledInputSwitchWrapper,
  StyledHourFieldWrapper,
} from './post-form-offer-styles';

const PostFormOffer = ({
  closeModal,
  dataToUpdate,
  affectedLocations,
  getLocalPosts,
}: TBatchPostFormOfferBaseProps) => {
  const { enqueueSnackbar } = useSnackbar();

  const {
    userAccessToken,
    userId,
    userName,
    userEmail,
    userProfile,
    userSetTokenLikeExpired,
  } = useAuth();

  const { activeLocationGroupId, showLocationsByGroup } = useSelector((state: TRootStateRedux) => state.LocationGroupReducer);
  const { activeCompanyId, showLocationsByCompany } = useSelector((state: TRootStateRedux) => state.CompanyReducer);

  const [offerTitle, setOfferTitle] = React.useState('');
  const [hasHour, setHasHour] = React.useState(false);
  const [addDetails, setAddDetails] = React.useState(false);
  const [openingHour, setOpeningHour] = React.useState<string>('00:00');
  const [closingHour, setClosingHour] = React.useState<string>('00:00');
  const [offerDetails, setOfferDetails] = React.useState('');
  const [filesToPost, setFilesToPost] = React.useState<any[]>([]);
  const [isPublish, setIsPublish] = React.useState<React.SetStateAction<boolean>>(false);
  const [startDate, setStartDate] = React.useState<Date | null>(null);
  const [endDate, setEndDate] = React.useState<Date | null>(null);
  const [couponCode, setCouponCode] = React.useState('');
  const [offerLink, setOfferLink] = React.useState('');
  const [termsOfService, setTermsOfService] = React.useState('');

  const getInputDateData = inputTimeToArrayData('/');
  const getInputTimeData = inputTimeToArrayData(':');

  const isInputFieldsValid = () => {
    if (R.isEmpty(offerTitle)) {
      enqueueSnackbar('O título não pode ser vazio!', { variant: 'warning' });
      return false;
    }

    if (R.isNil(startDate)) {
      enqueueSnackbar('A data de início não pode ser vazia!', { variant: 'warning' });
      return false;
    }

    if (R.isNil(endDate)) {
      enqueueSnackbar('A data de término não pode ser vazia!', { variant: 'warning' });
      return false;
    }

    if (offerDetails.length > 1500) {
      enqueueSnackbar('Os detalhes da postagem não podem conter mais de 1500 caracteres!', { variant: 'warning' });
      return false;
    }

    if (!R.isEmpty(offerLink) && !websiteValidate(offerLink)) {
      enqueueSnackbar('O link inserido não é válido', { variant: 'warning' });
      return false;
    }

    return true;
  };

  const handlePublishClicked = React.useCallback(async () => {
    if (!isInputFieldsValid()) return;

    const googleAccessToken = await getValidGoogleAccessToken({
      accessToken: userAccessToken,
      userId,
      userProfile,
    });

    if (R.isNil(googleAccessToken)) {
      enqueueSnackbar(
        'Não foi possível adquirir um token do Google para criar postagem',
        { variant: 'warning' },
      );

      return;
    }

    if (R.isNil(startDate)) return;
    if (R.isNil(endDate)) return;

    const formattedStartDate = format(startDate, 'dd/MM/yyyy');
    const formattedEndDate = format(endDate, 'dd/MM/yyyy');

    const startDateData = getInputDateData(formattedStartDate);
    const [startDay, startMonth, startYear] = startDateData;

    const endDateData = getInputDateData(formattedEndDate);
    const [endDay, endMonth, endYear] = endDateData;

    const openingHourData = hasHour ? getInputTimeData(openingHour) : [0, 0];
    const [openingHours, openingMinutes] = openingHourData;

    const closingHourData = hasHour ? getInputTimeData(closingHour) : [23, 59];
    const [closingHours, closingMinutes] = closingHourData;

    const {
      isValid: isValidEventDate,
      message: messageEventDateFeedBack,
    } = isValidPostStartToEndDate({
      startDateData,
      endDateData,
      hasHour,
    });

    if (!isValidEventDate) {
      enqueueSnackbar(messageEventDateFeedBack, { variant: 'warning' });
      return;
    }

    if (offerDetails.length > 1500) {
      enqueueSnackbar('O texto da postagem deve conter no máximo 1500 caracteres', { variant: 'warning' });
      return;
    }

    const {
      isValid: isValidEventTime,
      message: messageEventTimeFeedBack,
    } = isValidPostStartToEndTime({
      openingHourData,
      closingHourData,
      startDateData,
      endDateData,
      hasHour,
    });

    if (!isValidEventTime) {
      enqueueSnackbar(messageEventTimeFeedBack, { variant: 'warning' });

      return;
    }

    setIsPublish(true);

    let mediaData: any = null;

    if (!R.isEmpty(filesToPost)) {
      mediaData = await Promise.all(filesToPost.map(async (fileToPost) => {
        const putObjectData = await putObject({
          accessToken: userAccessToken,
          bucketName: 'hub-saas-media',
          file: fileToPost,
          googleAccountId: null,
          googleLocationId: null,
          mediaType: fileToPost.type === 'video/mp4' ? 'VIDEO' : 'PHOTO',
        });

        const mediaFileUrl = putObjectData.fileUrl;

        return {
          mediaFormat: fileToPost.type === 'video/mp4' ? 'VIDEO' : 'PHOTO',
          sourceUrl: mediaFileUrl,
        };
      }));
    }

    if (isVideoFiles(filesToPost) || filesToPost.length > 1) {
      return;
    }

    let postData: any = {
      media_url: R.isNil(mediaData) ? null : mediaData[0].sourceUrl,
      type: 'OFFER',
      title: offerTitle,
      schedule: {
        startDate: {
          day: startDay,
          month:
            startMonth,
          year: startYear,
        },
        endDate: {
          day: endDay,
          month: endMonth,
          year: endYear,
        },
        startTime: {
          hours: closingHours === 0 && openingHours === 0 ? 0 : openingHours,
          minutes: closingHours === 0 && openingHours === 0 ? 0 : openingMinutes,
          seconds: 0,
          nanos: 0,
        },
        endTime: {
          hours: closingHours === 0 && openingHours === 0 ? 0 : closingHours,
          minutes: closingHours === 0 && openingHours === 0 ? 0 : closingMinutes,
          seconds: 0,
          nanos: 0,
        },
      },
    };

    if (!R.isEmpty(offerDetails)) {
      postData = {
        ...postData,
        details: offerDetails,
      };
    }

    if (!R.isEmpty(couponCode)) {
      postData = {
        ...postData,
        coupon: couponCode,
      };
    }

    if (!R.isEmpty(offerLink)) {
      postData = {
        ...postData,
        rescue_link_offer: offerLink,
      };
    }

    if (!R.isEmpty(termsOfService)) {
      postData = {
        ...postData,
        terms_and_conditions: termsOfService,
      };
    }

    const createOfferPostResponse = await APIBatchCreatePosts({
      accessToken: userAccessToken,
      created_by: userId,
      groupType: showLocationsByCompany ? 'COMPANY' : 'LOCATIONS_GROUP',
      groupId: showLocationsByCompany ? activeCompanyId : activeLocationGroupId,
      locations: affectedLocations,
      postData,
      feedbackMessage: enqueueSnackbar,
      setIsLoading: setIsPublish,
      setTokenLikeExpired: userSetTokenLikeExpired,
      userData: {
        user_id: userId,
        user_name: userName,
        user_email: userEmail,
      },
    });

    if (R.isNil(createOfferPostResponse) || createOfferPostResponse.status === 406) return;

    getLocalPosts();
    closeModal();
  }, [
    filesToPost,
    offerTitle,
    startDate,
    endDate,
    openingHour,
    closingHour,
    hasHour,
    addDetails,
    offerDetails,
    couponCode,
    termsOfService,
    offerLink,
    showLocationsByCompany,
    activeCompanyId,
    activeLocationGroupId,
    affectedLocations,
    userId,
    userName,
    userEmail,
  ]);

  React.useEffect(() => {
    if (R.isNil(dataToUpdate)) return;

    const {
      media_url,
      title,
      schedule,
      type,
      details,
      coupon,
      rescue_link_offer,
      terms_and_conditions,
    } = dataToUpdate.data_post;

    const {
      formattedEndDate,
      formattedEndTime = '',
      formattedStartDate,
      formattedStartTime = '',
    } = formatDateObjectToString(schedule);

    if (type === 'OFFER') {
      setOfferTitle(title);
      if (details) setAddDetails(true);
      if (details) setOfferDetails(details);

      setStartDate(parse(formattedStartDate, 'dd/MM/yyyy', new Date()));
      setEndDate(parse(formattedEndDate, 'dd/MM/yyyy', new Date()));

      if (formattedStartTime && formattedEndTime) {
        if (formattedStartTime === '0000' && formattedEndTime === '2359') setHasHour(false);
        else setHasHour(true);
      }

      setOpeningHour(formattedStartTime);
      setClosingHour(formattedEndTime);

      if (coupon) setCouponCode(coupon);
      if (terms_and_conditions) setTermsOfService(terms_and_conditions);
      if (rescue_link_offer) setOfferLink(rescue_link_offer);

      if (media_url) {
        setFilesToPost([{
          mediaFormat: 'PHOTO',
          sourceUrl: media_url,
        }]);
      }
    }
  }, [dataToUpdate]);

  return (
    <StyledPostFormOffer>
      {R.isNil(dataToUpdate) && (
        <DragAndDropFiles
          filesToPost={filesToPost}
          setFilesToPost={setFilesToPost}
          imageFileValidation={imagePostValidation}
          maxVideoSize={70}
          singleMode
          editable={R.isNil(dataToUpdate)}
        />
      )}

      <Input
        value={offerTitle}
        disabled={!R.isNil(dataToUpdate)}
        border
        label="Título da Oferta *"
        onChange={(e) => setOfferTitle(e.currentTarget.value)}
      />

      {R.isNil(dataToUpdate) && (
        <StyledInputSwitchWrapper>
          <InputSwitch
            name="has-hour"
            label="Adicionar Hora?"
            checked={hasHour}
            changeChecked={() => setHasHour(!hasHour)}
            alignRow
            className="offer-status-hour-toggle"
          />
        </StyledInputSwitchWrapper>
      )}

      <StyledHourFieldWrapper hasHour={hasHour}>
        <InputDatePicker
          disabled={!R.isNil(dataToUpdate)}
          selectedDate={startDate}
          setSelectedDate={e => setStartDate(e)}
          label="De: *"
          className="input-date-picker"
        />

        {hasHour && (
          <Time
            readonly={!R.isNil(dataToUpdate)}
            label="Horário de Inicio"
            timeValue={openingHour}
            setTimeValue={setOpeningHour}
            columnAlign
          />
        )}
      </StyledHourFieldWrapper>

      <StyledHourFieldWrapper hasHour={hasHour} className="last-field">
        <InputDatePicker
          disabled={!R.isNil(dataToUpdate)}
          selectedDate={endDate}
          setSelectedDate={e => setEndDate(e)}
          label="Até: *"
          className="input-date-picker"
        />

        {hasHour && (
          <Time
            readonly={!R.isNil(dataToUpdate)}
            label="Horário de Término"
            timeValue={closingHour}
            setTimeValue={setClosingHour}
            columnAlign
          />
        )}
      </StyledHourFieldWrapper>

      {R.isNil(dataToUpdate) && (
        <StyledButton
          onClick={() => setAddDetails(!addDetails)}
        >
          Adicionar detalhes (opcional)
          {addDetails ? <ExpandLess /> : <ExpandMore />}
        </StyledButton>
      )}

      {addDetails && (
        <StyledAdditionalDetailsWrapper>
          <Textarea
            disabled={!R.isNil(dataToUpdate)}
            border
            label="Detalhes da Oferta"
            name="offer-details"
            className="offer-details"
            value={offerDetails}
            onChange={setOfferDetails}
          />

          <Input
            label="Código do Cupom (Opcional)"
            disabled={!R.isNil(dataToUpdate)}
            border
            value={couponCode}
            onChange={(e) => setCouponCode(e.currentTarget.value)}
          />
          <Input
            label="Link para resgatar a oferta (Opcional)"
            disabled={!R.isNil(dataToUpdate)}
            border
            value={offerLink}
            onChange={(e) => setOfferLink(e.currentTarget.value)}
          />
          <Input
            label="Termos e condições (Opcional)"
            disabled={!R.isNil(dataToUpdate)}
            border
            value={termsOfService}
            onChange={(e) => setTermsOfService(e.currentTarget.value)}
          />
        </StyledAdditionalDetailsWrapper>
      )}

      {R.isNil(dataToUpdate) && (
        <Button
          className="publish-button"
          disabled={Boolean(isPublish)}
          onClick={handlePublishClicked}
        >
          {isPublish && <Loading className="is-button-loading" />}
          Publicar
        </Button>
      )}
    </StyledPostFormOffer>
  );
};

export default PostFormOffer;
