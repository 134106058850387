import React from 'react';
import { useSelector } from 'react-redux';
import { isNil } from 'ramda';

import APICreateLocationConnection from '../../../../../../services/location-connections/createLocationConnection';
import APIGetAllConnectionsByGoogleLocationId from '../../../../../../services/location-connections/getAllLocationConnectionsFromGoogleLocation';
import APIDeleteAllConnectionsFromLocation from '../../../../../../services/location-connections/deleteAllConnectionsFromLocation';

import type { TLocationListItem } from '../../../../../../types/TPlatformsLinkWithLocation';
import type { TRootStateRedux } from '../../../../../../types/TRootStateRedux';

import { Loading } from '../../../../../../components';

import {
  StyledLocationListItem,
  StyledLinkLocation,
  StyledError,
  StyledLocationNameWrapper,
  StyledAddress,
} from './location-list-item-styles';
import { LocationConnectionPlatforms, LocationConnectionStatusEnum } from '../../../../../../types/TPlatformsLinkWithLocation';
import { getButtonConfig } from '../../helpers';
import ConfirmLinkDialog from './confirm-link-dialog';
import isNilOrEmpty from '../../../../../../utils/is-null-or-empty';
import LinkedLocation from './linked-location';

const LocationListItem = ({
  googleLocationName,
  googleLocationNameId,
  googleLocationAddress,
  userAccessToken,
  userId,
  googleUserId,
  disabledLink,
  userSetTokenLikeExpired,
  locationConnectionInfo,
  isAdminOrOwner,
  locationConnectionList,
  setLocationConnectionList,
}: TLocationListItem) => {
  const { activeLocation } = useSelector((state: TRootStateRedux) => state.LocationReducer);

  const [linkLocationLoading, setLinkLocationLoading] = React.useState(false);
  const [confirmDialogIsOpen, setConfirmDialogIsOpen] = React.useState(false);
  const [googleLocationConnections, setGoogleLocationConnections] = React.useState<any[]>([]);

  const updateGoogleLocationId = React.useCallback(async (
    newGoogleAccountId: string,
    newGoogleLocationId: string,
  ) => {
    setLinkLocationLoading(true);
    const createdConnection = await APICreateLocationConnection({
      accessToken: userAccessToken,
      platform: LocationConnectionPlatforms.GOOGLE,
      code: locationConnectionInfo.code,
      token: locationConnectionInfo.token,
      refreshToken: locationConnectionInfo.refresh_token,
      tokenExpiresAt: locationConnectionInfo.token_expires_in,
      setTokenLikeExpired: userSetTokenLikeExpired,
      createdBy: userId,
      location: activeLocation,
      status: LocationConnectionStatusEnum.VALID,
      connectionInfo: {
        google_account_id: newGoogleAccountId,
        google_location_id: newGoogleLocationId,
        user_email: locationConnectionInfo.email,
        location_name: googleLocationName,
        address: googleLocationAddress?.toString(),
        grouping_name: locationConnectionInfo.grouping_name,
      },
    });

    if (isNil(createdConnection)) {
      setLinkLocationLoading(false);
      return;
    }

    setLocationConnectionList(prev => [...prev, createdConnection]);
    setLinkLocationLoading(false);
  }, [activeLocation]);

  const getLocationConnectionsFromGoogleId = React.useCallback(async () => {
    const [, googleLocationId] = googleLocationNameId.split('/');
    const locationConnectionsByGoogleLocation = await APIGetAllConnectionsByGoogleLocationId({
      accessToken: userAccessToken,
      googleLocationId,
    });

    if (isNil(locationConnectionsByGoogleLocation)) return;
    const activeLocationConnectionIds = locationConnectionList.map((connection) => connection.id);
    const filteredLocationConnectionsByGoogleLocation = locationConnectionsByGoogleLocation
      .filter((connection) => !activeLocationConnectionIds.includes(connection.id));

    setGoogleLocationConnections(filteredLocationConnectionsByGoogleLocation);
  }, [locationConnectionList]);

  React.useEffect(() => {
    getLocationConnectionsFromGoogleId();
  }, [getLocationConnectionsFromGoogleId]);

  const handleLinkLocation = React.useCallback(async (currentGoogleLocationName: string, isConfirmationNeeded: boolean) => {
    if (disabledLink) return;
    if (isConfirmationNeeded) {
      setConfirmDialogIsOpen(true);
      return;
    }

    const [, googleLocationId] = currentGoogleLocationName.split('/');

    updateGoogleLocationId(googleUserId, googleLocationId);
  }, [activeLocation, disabledLink, googleUserId]);


  const buttonConfig = getButtonConfig(locationConnectionList,
    googleLocationNameId,
    isAdminOrOwner,
    userId);

  const isConfirmationNeeded = buttonConfig ? buttonConfig.message.startsWith('Trocar') : false;

  const handleLinkSwitch = React.useCallback(async () => {
    const deleteAllActiveLinksResponse = await APIDeleteAllConnectionsFromLocation({ accessToken: userAccessToken, locationId: activeLocation.id });

    if (isNil(deleteAllActiveLinksResponse)) return;

    const [, googleLocationId] = googleLocationNameId.split('/');

    const createdConnection = await APICreateLocationConnection({
      accessToken: userAccessToken,
      platform: LocationConnectionPlatforms.GOOGLE,
      code: locationConnectionInfo.code,
      token: locationConnectionInfo.token,
      refreshToken: locationConnectionInfo.refresh_token,
      tokenExpiresAt: locationConnectionInfo.token_expires_in,
      setTokenLikeExpired: userSetTokenLikeExpired,
      createdBy: userId,
      location: activeLocation,
      status: LocationConnectionStatusEnum.VALID,
      connectionInfo: {
        google_account_id: googleUserId,
        google_location_id: googleLocationId,
        user_email: locationConnectionInfo.email,
        location_name: googleLocationName,
        address: googleLocationAddress?.toString(),
        grouping_name: locationConnectionInfo.grouping_name,
      },
    });
    if (isNil(createdConnection)) {
      setLocationConnectionList([]);
      setConfirmDialogIsOpen(false);
      return;
    }
    setLocationConnectionList([createdConnection]);
    setConfirmDialogIsOpen(false);
  }, []);

  return (
    <StyledLocationListItem>
      <StyledLocationNameWrapper>
        <h4>{googleLocationName}</h4>
        {!isNil(googleLocationAddress) && (
          <StyledAddress>
            {googleLocationAddress.map((location:string) => (
              <p>{location}</p>
            ))}
          </StyledAddress>
        )}
      </StyledLocationNameWrapper>

      {isNilOrEmpty(googleLocationConnections) && !isNilOrEmpty(buttonConfig) && (
      <StyledLinkLocation
        isLoading={linkLocationLoading}
        disabledLink={disabledLink || buttonConfig.disabled}
        onClick={() => handleLinkLocation(googleLocationNameId, isConfirmationNeeded)}
        isLinked={buttonConfig.message === 'Conectado'}
      >
        {disabledLink && (
        <p>
          <StyledError />
          Local inválido
        </p>
        )}

        {!disabledLink && (
        <>
          {linkLocationLoading && (
          <Loading className="is-link-load" />
          )}
          {linkLocationLoading ? 'Carregando...' : `${buttonConfig.message}`}
        </>
        )}
      </StyledLinkLocation>
      )}
      {!isNilOrEmpty(googleLocationConnections)
            && (
              <LinkedLocation
                disabledLink={disabledLink}
                location={googleLocationConnections[0].location}
              />
            )}
      {confirmDialogIsOpen
        && (
        <ConfirmLinkDialog
          onClose={() => setConfirmDialogIsOpen(false)}
          isDialogOpen={confirmDialogIsOpen}
          onConfirm={handleLinkSwitch}
        />
        )}

    </StyledLocationListItem>
  );
};

export default LocationListItem;
