import React from 'react';

import {
  StyledTabBar,
  StyledTabs,
} from './tabbar-styles';

const TabBar = ({
  children, handleChange, activeTab, noBorderTop = false,
}) => (
  <StyledTabBar position="static" color="default" noBorderTop>
    <StyledTabs
      value={activeTab}
      onChange={handleChange}
      indicatorColor="primary"
      textColor="primary"
      variant="fullWidth"
      aria-label="full width tabs example"
    >
      {children}
    </StyledTabs>
  </StyledTabBar>
);

export default TabBar;
