import { includes, isEmpty, isNil } from 'ramda';

import type { TListAutomaticResponsesByState, TStarRatingState } from '../../types/TReviewsContext';
import type { TLocationReview, TGetLocationsToGetReviews } from '../../types/TReviews';
import type { TGoogleLocation } from '../../types/TGoogle';

import isEllebigleLocationForGuest from '../../utils/is-ellegible-location-for-guest';

export const getLocationsToGetReviews = ({
  ellegibleLocations,
  googleLocations,
  locationsFiltered,
  userId,
  guestType,
  userProfileName,
}: TGetLocationsToGetReviews): TGoogleLocation[] => {
  const ellegibleLocationsId: string[] = ellegibleLocations
    .filter(location => isEllebigleLocationForGuest({
      location, guestType, userId, userProfileName,
    }))
    .map(location => location.location_connections[0].connection_info.google_location_id);

  const locationsFilterId: string[] = !locationsFiltered
    ? []
    : locationsFiltered.map((location) => location.googleLocationId);

  return googleLocations.filter(({ name: googleLocationNameId }) => {
    const googleLocationId = googleLocationNameId.split('/')[3];

    if (locationsFilterId.length > 0) {
      return (
        includes(googleLocationId, ellegibleLocationsId)
        && includes(googleLocationId, locationsFilterId)
      );
    }

    return includes(googleLocationId, ellegibleLocationsId);
  });
};

export const starRatingState: TStarRatingState = {
  currentStarRating: {
    word: null,
    rateNumber: 0,
  },
  starRating: [
    { word: 'ONE', rateNumber: 1 },
    { word: 'TWO', rateNumber: 2 },
    { word: 'THREE', rateNumber: 3 },
    { word: 'FOUR', rateNumber: 4 },
    { word: 'FIVE', rateNumber: 5 },
  ],
};

export const listAutomaticResponsesByState: TListAutomaticResponsesByState = {
  currentListBy: 'all',
  listAutomaticResponsesBy: [
    { value: 'all', name: 'Todas' },
    { value: 'answered', name: 'Respondidas' },
    { value: 'unanswered', name: 'Não Respondidas' },
  ],
};

export const isReview = (reviews: TLocationReview[]) => {
  if (isNil(reviews)) return false;
  if (isEmpty(reviews)) return false;
  return true;
};
