import React from 'react';
import { debounce } from 'lodash';
import { isNil, isEmpty, last } from 'ramda';

import type { TLocationList } from '../../../../../types/TPlatformsLinkWithLocation';

import {
  Loading, Input, GooglePagination, InputSwitch,
} from '../../../../../components';

import LocationListItem from './location-list-item';

import {
  StyledLocationList,
  StyledListing,
  StyledTitle,
  StyledEmptyLocationList,
  SwitchWrapper,
  StyledTitleAndFilterSwitch,
} from './location-list-styles';

const LocationList = ({
  data,
  locationsFrom,
  getGoogleUserLocations,
  initialDataToken,
  googleAccessToken,
  userAccessToken,
  userSetTokenLikeExpired,
  userId,
  pageList,
  setPageList,
  locationConnectionInfo,
  isAdminOrOwner,
  locationConnectionList,
  setLocationConnectionList,
}: TLocationList) => {
  const [queryLocation, setQueryLocation] = React.useState('');
  const [locationsList, setLocationsList] = React.useState<any>([]);

  const [googlePaginationPageToken, setGooglePaginationPageToken] = React.useState('');
  const [googlePaginationPageIndex, setGooglePaginationPageIndex] = React.useState(1);
  const [googlePaginationLoading, setGooglePaginationLoading] = React.useState(false);
  const [loadLocations, setLoadLocations] = React.useState(false);
  const [filterInvalidLocations, setFilterInvalidLocations] = React.useState(false);

  React.useEffect(() => {
    if (data) setLocationsList(data);
    if (initialDataToken) setGooglePaginationPageToken(initialDataToken);
  }, [data, initialDataToken]);

  const getGoogleUserLocationsByParams = React.useCallback(
    async (query = '', nextPageToken = '', filterInvalid: boolean | undefined) => {
      const { locations, locationsPageToken } = await getGoogleUserLocations({
        accessToken: googleAccessToken,
        currentUserAccount: locationsFrom,
        userLocationsPage: nextPageToken,
        queryUserLocation: query.toLowerCase(),
        filterInvalidLocations: filterInvalid,
        isFetching: setLoadLocations,
      });

      if (isNil(locations)) {
        setLocationsList([]);
        return;
      }
      setLocationsList(locations.filter((location: any) => isNil(location.metadata?.duplicateLocation)));
      setGooglePaginationPageToken(locationsPageToken);
    }, [locationsFrom],
  );

  React.useEffect(() => {
    getGoogleUserLocationsByParams(queryLocation, '', filterInvalidLocations);

    setGooglePaginationPageToken('');
    setGooglePaginationPageIndex(1);
    setPageList(['']);
  }, [locationsFrom, queryLocation, filterInvalidLocations]);

  const handleBackPageClicked = React.useCallback(async () => {
    const removeLastPositionFromList = pageList.pop();
    if (isNil(removeLastPositionFromList)) return;

    const lastTokenFromList = last(pageList);
    if (isNil(lastTokenFromList)) return;

    getGoogleUserLocationsByParams(queryLocation, lastTokenFromList, filterInvalidLocations);
  }, [googleAccessToken, pageList]);

  const handleResetClicked = React.useCallback(async () => {
    setPageList(['']);

    getGoogleUserLocationsByParams(queryLocation, '', filterInvalidLocations);
  }, [googleAccessToken, pageList]);

  const handleOnChangeInputQueryLocation = debounce(text => { setQueryLocation(text); }, 500);

  return (
    <StyledLocationList>
      <StyledTitleAndFilterSwitch>
        <StyledTitle>
          Locais do agrupamento:
          {' '}
          <em>{locationsFrom.name}</em>
        </StyledTitle>
        <SwitchWrapper>
          <InputSwitch label="Filtrar inválidos" checked={filterInvalidLocations} changeChecked={() => setFilterInvalidLocations(prev => !prev)} />
        </SwitchWrapper>
      </StyledTitleAndFilterSwitch>
      <Input
        className="query-location-input"
        inputOptions={{
          name: 'query-location',
          type: 'text',
        }}
        border
        placeholder="Pesquisar um local..."
        onChange={e => handleOnChangeInputQueryLocation(e.target.value)}
      />

      {(
        (isNil(locationsList)
          || isEmpty(locationsList))
        && !loadLocations && !isEmpty(queryLocation)
      ) && (
        <StyledEmptyLocationList>
          Este agrupamento não possui locais com esse nome!
        </StyledEmptyLocationList>
      )}

      {(!isNil(locationsList) && !isEmpty(locationsList) && !loadLocations) && (
        <StyledListing>
          {locationsList.map((googleLocation: any) => (
            <LocationListItem
              key={googleLocation.name}
              googleLocationName={googleLocation.title}
              googleLocationNameId={googleLocation.name}
              googleLocationAddress={isNil(googleLocation.storefrontAddress) ? null : googleLocation.storefrontAddress.addressLines}
              userAccessToken={userAccessToken}
              userId={userId}
              googleUserId={locationsFrom.id}
              disabledLink={isNil(googleLocation.metadata?.hasVoiceOfMerchant)}
              userSetTokenLikeExpired={userSetTokenLikeExpired}
              locationConnectionInfo={locationConnectionInfo}
              isAdminOrOwner={isAdminOrOwner}
              locationConnectionList={locationConnectionList}
              setLocationConnectionList={setLocationConnectionList}
            />
          ))}
        </StyledListing>
      )}

      {loadLocations && <Loading className="load-locations" />}

      {(!loadLocations && !isNil(locationsList) && !isEmpty(locationsList)) && (
        <GooglePagination
          isLabelButton
          loading={googlePaginationLoading}
          nextPageToken={googlePaginationPageToken}
          pageIndex={googlePaginationPageIndex}
          setPageIndex={setGooglePaginationPageIndex}
          onNextPageClicked={() => getGoogleUserLocationsByParams(queryLocation, googlePaginationPageToken, filterInvalidLocations)}
          onBackPageClicked={handleBackPageClicked}
          onResetClicked={handleResetClicked}
          pageList={pageList}
          setPageList={setPageList}
        />
      )}
    </StyledLocationList>
  );
};

export default LocationList;
