import * as R from 'ramda';

import APICreateCompanyGroup from '../../services/company-groups/create';
import APIAddCompanyGroups from '../../services/users/addCompanyGroups';
import APIAddResponsibles from '../../services/company-groups/addResponsibles';
import APIAddCompanies from '../../services/company-groups/addCompanies';

export const saveCompanyGroupInDB = async ({
  userHublocalToken,
  userHublocalID,
  setTokenLikeExpired,
  responsibles,
  companyData,
  setIsCreating,
  feedbackMessage,
  resetCompanyFields,
  resetResponsibles,
  redirect,
  companiesToAdd,
}) => {
  try {
    if (setIsCreating) setIsCreating(true);

    const mainResponsible = R.dissoc('main', responsibles.filter(responsible => responsible.main === true)[0]);
    const responsibleWithoutMain = responsibles.filter(responsible => responsible.main !== true).map(responsible => responsible.id);

    const { name, website, is_active: isActive } = companyData;

    const { id: newCompanyID } = await APICreateCompanyGroup({
      accessToken: userHublocalToken,
      name,
      description: '',
      website,
      owner_user_id: userHublocalID,
      main_responsible_id: mainResponsible.id,
      is_active: isActive,
      created_by: userHublocalID,
      feedbackMessage,
      setTokenLikeExpired,
    });

    if (responsibleWithoutMain.length) {
      await APIAddResponsibles({
        accessToken: userHublocalToken,
        companyGroupId: newCompanyID,
        setTokenLikeExpired,
        responsible_ids: responsibleWithoutMain,
        feedbackMessage,
      });
    }

    await APIAddCompanyGroups({
      setTokenLikeExpired,
      accessToken: userHublocalToken,
      userId: userHublocalID,
      company_group_ids: [newCompanyID],
      feedbackMessage,
    });

    const companyGroup = await APIAddCompanies({
      accessToken: userHublocalToken,
      companyGroupId: newCompanyID,
      company_ids: companiesToAdd,
      setTokenLikeExpired,
    });

    if (resetCompanyFields) resetCompanyFields();
    if (resetResponsibles) resetResponsibles();
    if (redirect) redirect();
  } catch (err) {
    console.log(err);
    feedbackMessage('Não foi possível criar o grupo. Tente novamente!', { variant: 'error' });
  } finally {
    if (setIsCreating) setIsCreating(false);
  }
};
