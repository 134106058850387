import React from 'react';
import * as R from 'ramda';
import useAuth from '../use-auth';

import APIGetUserCompanies from '../../services/companies/getUserCompaniesPaginated';
import APIGetUserCompanyGroups from '../../services/company-groups/getUserCompanyGroups';
import APIGetUserLocations from '../../services/locations/get-user-locations';
import APIGetUserLocationGroups from '../../services/location-groups/getUserLocationGroups';

const useRegisterLimitUsed = (currentPageUserId) => {
  const {
    userAccessToken,
    userSetTokenLikeExpired,
  } = useAuth();

  const [locationLimitUsed, setLocationLimitUsed] = React.useState(0);
  const [locationGroupLimitUsed, setLocationGroupLimitUsed] = React.useState(0);
  const [companyLimitUsed, setCompanyLimitUsed] = React.useState(0);
  const [companyGroupLimitUsed, setCompanyGroupLimitUsed] = React.useState(0);

  const getUserCompanies = React.useCallback(async () => {
    const userCompaniesResponse = await APIGetUserCompanies({
      accessToken: userAccessToken,
      userId: currentPageUserId,
      setTokenLikeExpired: userSetTokenLikeExpired,
    });

    if (R.isNil(userCompaniesResponse)) return;

    const [, userCompaniesCount] = userCompaniesResponse;

    setCompanyLimitUsed(userCompaniesCount);
  }, []);

  const getUserLocations = React.useCallback(async () => {
    const userLocationsResponse = await APIGetUserLocations({
      accessToken: userAccessToken,
      userId: currentPageUserId,
      setTokenLikeExpired: userSetTokenLikeExpired,
    });

    if (R.isNil(userLocationsResponse)) return;

    const [, userLocationsCount] = userLocationsResponse;

    setLocationLimitUsed(userLocationsCount);
  }, []);

  const getUserCompanyGroups = React.useCallback(async () => {
    const userCompanyGroupsResponse = await APIGetUserCompanyGroups({
      accessToken: userAccessToken,
      userId: currentPageUserId,
      setTokenLikeExpired: userSetTokenLikeExpired,
    });

    if (R.isNil(userCompanyGroupsResponse)) return;

    const [, userCompanyGroupsCount] = userCompanyGroupsResponse;

    setCompanyGroupLimitUsed(userCompanyGroupsCount);
  }, []);

  const getUserLocationGroups = React.useCallback(async () => {
    const userLocationGroupsResponse = await APIGetUserLocationGroups({
      accessToken: userAccessToken,
      userId: currentPageUserId,
      setTokenLikeExpired: userSetTokenLikeExpired,
    });

    if (R.isNil(userLocationGroupsResponse)) return;

    const [, userLocationGroupsCount] = userLocationGroupsResponse;

    setLocationGroupLimitUsed(userLocationGroupsCount);
  }, []);

  React.useEffect(() => {
    if (!currentPageUserId) return;

    getUserCompanies();
    getUserLocations();
    getUserCompanyGroups();
    getUserLocationGroups();
  }, []);

  return {
    locationLimitUsed,
    locationGroupLimitUsed,
    companyLimitUsed,
    companyGroupLimitUsed,
  };
};
export default useRegisterLimitUsed;
