import styled from 'styled-components';

export const StyledContent = styled.div`
  display: flex;
  flex-direction: column;
  padding: 2rem;
  row-gap: 1rem;
`;

export const StyledActions = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const StyledButtonGroup = styled.div`
  display: flex;
  flex-direction: row;
  column-gap: 2rem;
`;

export const StyledRepliesList = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 1rem;
  row-gap: 1rem;
  max-height: 60rem;
  overflow-y: auto;
`;
