import styled from 'styled-components';

export const StyledMyLocationGroups = styled.div`
  width: 100%;

  .header-toolbar {
    padding-right: 2rem;
  }
`;

export const StyledContent = styled.div`
  width: 100%;
  padding: 3rem 2rem 2rem;
`;

export const StyledPageTitle = styled.h2`
  font-size: 2rem;
  font-weight: 700;
  padding: 3rem 2rem;      
  width: 100%;
`;
