import styled, { css } from 'styled-components';

import { InputSwitchBaseProps } from '../../types/TInputSwitch';

export const StyledInputSwitch = styled.div<Pick<InputSwitchBaseProps, 'alignRow'>>`
  display: flex;
  flex-direction: column;

  label {
    font-size: 1.5rem;
    font-weight: normal;
    color: ${props => props.theme.colors.black};
    margin-bottom: 1rem;
  }

  ${props => props.alignRow && css`
    flex-direction: row;
    align-items: center;

    label {
      margin-bottom: 0;
      margin-right: 1rem;
    }
  `}
`;
