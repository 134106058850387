export const countriesOptions = [
  'Brazil',
  'Argentina',
  'Colombia',
  'United States',
  'Spain',
  'United Arab Emirates',
  'Saudi Arabia',
  'Kuwait',
];
