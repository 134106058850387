import React from 'react';
import {
  StyledChevronLeft, StyledChevronRight, StyledPagination, StyledIconButton, StyledPageControls,
} from './pagination-styles';

export default ({
  page = 0,
  setPage,
  pagesQuantity = 10,
  className,
}) => (
  <StyledPagination className={className}>
    <StyledIconButton disabled={page === 0} onClick={() => setPage(page - 1)}>
      <StyledChevronLeft />
    </StyledIconButton>
    <StyledPageControls>
      <span>{page + 1}</span>
      /
      <span>{pagesQuantity}</span>
    </StyledPageControls>
    <StyledIconButton
      disabled={page >= pagesQuantity - 1}
      onClick={() => setPage(page + 1)}
    >
      <StyledChevronRight />
    </StyledIconButton>
  </StyledPagination>
);
