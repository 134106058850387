import axios from 'axios';
import type { OptionsObject, SnackbarKey, SnackbarMessage } from 'notistack';
import type { TUserData } from '../../types/TBatchUpdate';
import type { TChecklistType } from '../../types/TChecklist';
import type { TServiceItemSaaS } from '../../types/TLocationService';
import { TServiceBatch } from '../../types/TServiceBatch';

export type TBatchCreateService = {
  accessToken: string;
  groupType: string;
  groupId: number;
  serviceItemInfo: TServiceItemSaaS;
  locations?: TChecklistType | null;
  setIsLoading?: React.Dispatch<React.SetStateAction<boolean>> | null;
  setTokenLikeExpired?: () => void;
  feedbackMessage?:
    | ((message: SnackbarMessage, options?: OptionsObject) => SnackbarKey)
    | null;
  userData: TUserData;
};

export default async function batchCreateService({
  accessToken,
  groupType,
  groupId,
  serviceItemInfo,
  locations = null,
  setIsLoading = null,
  setTokenLikeExpired,
  feedbackMessage,
  userData,
}: TBatchCreateService): Promise<TServiceBatch | null> {
  try {
    if (setIsLoading) setIsLoading(true);

    const { data } = await axios.post(
      `${process.env.REACT_APP_GOOGLE_BUSINESS_PROFILE_API_URL}/service/batch`,
      {
        group_type: groupType,
        group_id: groupId,
        service_item_info: serviceItemInfo,
        locations,
        user_data: userData,
      },
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );

    if (data.status === 406) {
      if (feedbackMessage) {
        feedbackMessage(
          'Nenhum local foi selecionado ao iniciar a criação de serviços em massa. Selecione um ou mais locais e tente novamente.',
          { variant: 'warning' }
        );
      }
      return null;
    }

    if (feedbackMessage) {
      feedbackMessage('Criação de serviços em massa iniciada com sucesso', {
        variant: 'success',
      });
    }

    return data;
  } catch (err: any) {
    if (err.response?.status === 401 && setTokenLikeExpired)
      setTokenLikeExpired();

    if (feedbackMessage) {
      feedbackMessage('Erro ao criar serviços em massa, tente novamente!', {
        variant: 'error',
      });
    }

    return null;
  } finally {
    if (setIsLoading) setIsLoading(false);
  }
}
