import React from 'react';
import { useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';
import { isNil } from 'ramda';

import { useAuth } from '../../../hooks';

import {
  Select,
  Button,
  Loading,
  Dialog,
  Input,
  Textarea,
  CurrencyInput,
} from '../../../components';

import {
  billingTypes,
  formatServicePriceValue,
  isValidFields,
  isServiceDoesNotExist,
  getServiceBillingType,
} from './helpers';
import { convertGooogleMoneyToNumber } from '../../../utils/google-money';

import { updateServiceItems as APIUpdateServiceItems } from '../../../services/google-business-profile';

import type {
  TBillingType,
  TCreateServiceDialogBaseProps,
  TEditingService,
  TServiceItem,
} from '../../../types/TLocationService';
import type { TRootStateRedux } from '../../../types/TRootStateRedux';

import {
  StyledContent,
  StyledBoldText,
  StyledRowWrapper,
  StyledColumnWrapper,
  StyledTextCounter,
  StyledTextInputAlert,
} from './create-service-dialog-styles';

const CreateServiceDialog = ({
  openDialog = false,
  setOpenDialog,
  serviceItems,
  getServiceItems,
  editingService = null,
  setEditingService,
  googleAccessToken,
  googleLocationId,
}: TCreateServiceDialogBaseProps) => {
  const { userAccessToken } = useAuth();

  const { enqueueSnackbar } = useSnackbar();

  const [currentSelectedPostType, setCurrentSelectedPostType] = React.useState<string>('');

  const [serviceName, setServiceName] = React.useState('');
  const [serviceNameLength, setServiceNameLength] = React.useState(0);
  const [billingType, setBillingType] = React.useState<TBillingType>('Sem Preço');
  const [servicePrice, setServicePrice] = React.useState<string | undefined>('');
  const [serviceDescription, setServiceDescription] = React.useState('');
  const [serviceDescriptionLength, setServiceDescriptionLength] = React.useState(0);

  const [loading, setLoading] = React.useState(false);

  const isEditingStructuredService = React.useMemo(
    () => !isNil(editingService?.service.structuredServiceItem),
    [editingService],
  );

  const handleCloseModal = React.useCallback(() => {
    if (loading) {
      enqueueSnackbar('Aguarde. Carregando...', { variant: 'info' });
      return;
    }
    setCurrentSelectedPostType('');
    setOpenDialog(false);
    setEditingService(null);
  }, [currentSelectedPostType, loading]);

  const handleChangeServiceName = (text: string) => {
    setServiceNameLength(text.length);
    setServiceName(text);
  };

  const handleChangeServiceDescription = (text: string) => {
    setServiceDescriptionLength(text.length);
    setServiceDescription(text);
  };

  const handleSubmit = React.useCallback(async () => {
    if (isNil(googleAccessToken)) return;
    if (isNil(googleLocationId)) return;
    const validFields = isValidFields({
      serviceName,
      serviceNameLength,
      billingType,
      servicePrice,
      serviceDescriptionLength,
      feedbackMessage: enqueueSnackbar,
    });

    if (!validFields) return;

    const middleMultipleSpacesRegExp = new RegExp(/\s\s+/g);
    const formattedServiceName = serviceName
      .replace(middleMultipleSpacesRegExp, ' ')
      .trim();
    const formattedServiceDescription = serviceDescription
      .replace(middleMultipleSpacesRegExp, ' ')
      .trim();

    let updatedServiceItems: TServiceItem[] = [...serviceItems];
    if (editingService) {
      const { index } = editingService;
      updatedServiceItems.splice(index, 1);
    }

    if (!isServiceDoesNotExist(formattedServiceName, updatedServiceItems)) {
      enqueueSnackbar('O serviço solicitado já existe neste local!', { variant: 'warning' });
      return;
    }

    const mountedServiceItem: TServiceItem = {};

    if (editingService?.service.structuredServiceItem) {
      mountedServiceItem.structuredServiceItem = {
        ...editingService?.service.structuredServiceItem,
        description: formattedServiceDescription,
      };
    } else {
      mountedServiceItem.freeFormServiceItem = {
        label: {
          displayName: formattedServiceName,
          description: formattedServiceDescription,
          languageCode: 'pt',
        },
      };
    }

    if (billingType !== 'Sem Preço') {
      mountedServiceItem.price = formatServicePriceValue(
        billingType === 'Fixo' ? servicePrice : '0',
        billingType,
      );
    }

    updatedServiceItems = [mountedServiceItem, ...updatedServiceItems];

    const handleSubmitResponse = await APIUpdateServiceItems({
      userAccessToken,
      locationId: googleLocationId,
      serviceItems: updatedServiceItems,
      googleAccessToken,
      isFetching: setLoading,
    });

    if (handleSubmitResponse.status === 'ERROR') {
      enqueueSnackbar(
        `Erro ao ${editingService ? 'editar' : 'criar'
        } serviço, tente novamente!`,
        { variant: 'error' },
      );
      return;
    }

    enqueueSnackbar(
      `Serviço ${editingService ? 'editado' : 'criado'} com sucesso!`,
      { variant: 'success' },
    );
    await getServiceItems();
    handleCloseModal();
  }, [
    serviceItems,
    editingService,
    googleLocationId,
    serviceName,
    serviceNameLength,
    billingType,
    servicePrice,
    serviceDescription,
    serviceDescriptionLength,
  ]);

  const initializeEditingServiceValues = React.useCallback(
    (editingService: TEditingService | null) => {
      if (isNil(editingService)) return;
      const {
        service: { freeFormServiceItem, structuredServiceItem, price },
      } = editingService;
      const serviceBillingType = getServiceBillingType(price);
      setBillingType(serviceBillingType);

      if (!isNil(price)) {
        const servicePriceNumber = convertGooogleMoneyToNumber(price);
        setServicePrice(
          servicePriceNumber.toFixed(2).toString().replace('.', ','),
        );
      }

      handleChangeServiceName(
        freeFormServiceItem?.label.displayName
        || structuredServiceItem?.displayName
        || '',
      );
      handleChangeServiceDescription(
        freeFormServiceItem?.label.description
        || structuredServiceItem?.description
        || '',
      );
    },
    [],
  );

  React.useEffect(() => {
    initializeEditingServiceValues(editingService);
  }, [editingService]);

  return (
    <Dialog
      title={`${editingService ? 'Editar' : 'Adicionar'} Serviço`}
      onClose={handleCloseModal}
      open={openDialog}
      closeIcon
      wideDialog
    >
      <StyledContent>
        <StyledBoldText>
          {editingService
            ? 'Edite as informações do serviço selecionado:'
            : 'Adicione o serviço que sua empresa oferece:'}
        </StyledBoldText>

        <StyledColumnWrapper>
          <Input
            border
            value={serviceName}
            className="input"
            onChange={({ target: { value } }) => { handleChangeServiceName(value); }}
            borderAlert={serviceNameLength >= 120}
            disabled={isEditingStructuredService}
          />
          {isEditingStructuredService ? (
            <StyledTextInputAlert>
              Este é um serviço pré sugerido pelo Google. Não é possível editar o nome de exibição.
            </StyledTextInputAlert>
          ) : (
            <StyledTextCounter
              count={serviceNameLength >= 120}
            >
              {serviceNameLength}
              /120
            </StyledTextCounter>
          )}
        </StyledColumnWrapper>

        <StyledRowWrapper>
          <Select
            name="billing"
            className="select-billing-type"
            label="Tipo de Cobrança"
            value={billingType}
            onChange={({ target: { value } }) => setBillingType(value as TBillingType)}
            border
            options={billingTypes}
          />

          {billingType === 'Fixo' && (
            <CurrencyInput
              id="currency-input"
              label="Preço do Serviço"
              value={servicePrice}
              disabled={billingType !== 'Fixo'}
              integerLimit={12}
              decimalsLimit={2}
              onValueChange={(value) => setServicePrice(value)}
              prefix="R$"
            />
          )}
        </StyledRowWrapper>

        <StyledColumnWrapper>
          <Textarea
            name="covid-status"
            label="Descrição do Serviço (opcional)"
            value={serviceDescription}
            onChange={handleChangeServiceDescription}
            className="service-description"
            border
            borderAlert={serviceDescriptionLength >= 300}
          />
          <StyledTextCounter
            count={serviceDescriptionLength >= 300}
          >
            {serviceDescriptionLength}
            /300
          </StyledTextCounter>
        </StyledColumnWrapper>

        <StyledRowWrapper>
          <Button
            onClick={handleSubmit}
            className="button"
            disabled={loading}
          >
            {loading && <Loading className="is-loading" />}
            {' '}
            {`${editingService ? 'Editar' : 'Criar'} Serviço`}
          </Button>
        </StyledRowWrapper>
      </StyledContent>
    </Dialog>
  );
};

export default CreateServiceDialog;
