import axios from 'axios';

export default async function getCompanyLinkModels({ accessToken, companyGroupId }) {
  try {
    const { data } = await axios.get(
      `${process.env.REACT_APP_COMPANY_GROUPS_API_URL}/get-relation/responsibles/${companyGroupId}`,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      },
    );

    return data;
  } catch (err) {
    console.log(err);

    return null;
  }
}
