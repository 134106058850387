import React, { useState } from 'react';

import {
  TableBodyRow, TableBodyCell, IconButton,
} from '../../../../components';

import { TTableRowData } from '../../../../types/TTable';

import { TRenderCellDataLastSyncRow, TServiceLastSynRowColumnsName } from './helpers';

import SyncStatusIcon from '../../../../components/sync-status-icon';
import { StyledIconWrapper, StyledInfoCell } from './last-sync-row-styles';

const LastSyncRow = ({
  rowData,
  rowId,
  dataTableOptions,
  onLastSyncDialogOpen,
}: any) => {
  const [checked, setChecked] = useState(false);
  const [currentRowData, setCurrentRowData] = useState<TTableRowData>([]);

  React.useEffect(() => {
    setCurrentRowData(rowData);
  }, [rowData]);

  const renderCellData = React.useMemo<TRenderCellDataLastSyncRow>(
    () => ({
      id: (value) => value,
      updated_info: (value: boolean[]) => (
        <StyledInfoCell>
          <IconButton tooltip="Inform. básicas" icon="AccountBoxOutlined" disabled={!value[0]} />
          <IconButton tooltip="Links" icon="Link" disabled={!value[1]} />
          <IconButton tooltip="Horário de funcionamento" icon="AccessTime" disabled={!value[2]} />
          <IconButton tooltip="Datas especiais" icon="CalendarTodayOutlined" disabled={!value[3]} />
        </StyledInfoCell>
      ),
      created_at: (value) => value,
      status: (value) => <StyledIconWrapper><SyncStatusIcon status={value} syncType="requests" /></StyledIconWrapper>,
      operations: (value) => (
        <StyledIconWrapper>
          <IconButton
            icon="VisibilityOutlined"
            tooltip="Visualizar Solicitações"
            onClick={() => onLastSyncDialogOpen(value)}
          />
        </StyledIconWrapper>
      ),
    }),
    [],
  );

  return (
    <TableBodyRow
      key={rowId}
      dataTableOptions={dataTableOptions}
      dataRow={rowData}
      checked={checked}
      setChecked={setChecked}
      hiddenCheckbox
    >
      {currentRowData.map(
        (dataColumnCell: any) => dataColumnCell.visible && (
          <TableBodyCell>
            {renderCellData[
                dataColumnCell.columnRef as TServiceLastSynRowColumnsName
            ]?.(dataColumnCell.value)}
          </TableBodyCell>
        ),
      )}
    </TableBodyRow>
  );
};

export default LastSyncRow;
