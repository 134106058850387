import styled from 'styled-components';
import {
  Tooltip,
  IconButton,
} from '@material-ui/core';

import { withStyles } from '@material-ui/core/styles';

import { light } from '../../../../assets/styles/themes';

const HeaderProfileTooltip = withStyles({
  arrow: {
    color: light.colors.primary,
  },
})(Tooltip);

export const StyledHeader = styled.header`
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 1rem 2rem;
  align-items: center;
  position: relative;

  h4 {
    font-size: 1.4rem;
    margin-bottom: 0;
  }

  .linear-progress {
    position: absolute;
    bottom: 0;
    left: 0;
  }

  .profile-header {
    width: 2rem;
    height: 2rem;
    margin-right: 1rem;
  }
`;

export const StyledProfileHeader = styled(HeaderProfileTooltip)`
  cursor: pointer;
`;

export const StyledTitle = styled.div`
  display: flex;

  .header-platform-icon {
    height: 2rem;
    width: 2rem;
    margin-right: 1rem;
  }

  h4 {
    display: flex;
    align-items: center;
  }
`;

export const StyledHeaderActions = styled.div`
  display: flex;

  .connected {
    fill: ${props => props.theme.colors.secondary};
  }

  .disconnected {
    fill: ${props => props.theme.colors.red};
  }

  .needs-reconnect {
    fill: ${props => props.theme.colors.yellow};
  }
`;

export const StyledHeaderAction = styled(IconButton)`
  padding: 0.5rem;

  &:not(.no-event):hover {
    background-color: ${props => props.theme.colors.primary};

    .MuiSvgIcon-root {
      fill: ${props => props.theme.colors.white};
    }
  }

  &.no-event:hover {
    background: none;
    cursor: default;
  }

  &:focus {
    outline: none;
  }

  .MuiSvgIcon-root {
    width: 2rem;
    height: 2rem;
  }
`;

export const StyledOptimizationProgress = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.5rem;
  position: relative;

  .progress-value {
    font-size: 1rem;
    font-weight: 600;
  }

  .linear-progress {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
  }
`;
