import styled from 'styled-components';
import { devices } from '../../../../../../../../assets/styles/devices';

export const StyledBottomActions = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2rem 0 0 0;

  @media ${devices.tablet} {
    flex-direction: row;
    justify-content: center;
  }
`;

export const StyledWarningMessage = styled.b`
  text-align: center;
`;

export const StyledDialogBody = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 2rem; 
  max-width: 46rem;
`;

export const StyledButton = styled.button<{isCancelButton?: boolean}>`
  border-radius: 20px;
  color: ${(props) => props.theme.colors.white};
  background-color: ${(props) => (!props.isCancelButton ? props.theme.colors.primary : props.theme.colors.grey)};
  margin: 0.5rem 0; 
  border: none;
  padding: 0.75rem 1.25rem;
  font-size: 1.3rem;
  border-right: 1px solid white;
  width: 17.5rem;

  @media ${devices.tablet} {
    margin: 0 1rem;
  }
`;
