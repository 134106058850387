import React from 'react';
import axios from 'axios';
import { useSnackbar } from 'notistack';
import { useSelector, useDispatch } from 'react-redux';

import { Creators as LocationActions } from '../../../../../redux/ducks/location';

import {
  StyledFoursquare,
  StyledButtonGroup,
} from './foursquare-styles';

import ListingDialog from '../../listing-dialog';

import Button from '../../../../button';
import Loading from '../../../../loading';

import searchForVenue from '../../../../../services/foursquare/searchForVenue';
import getFoursquareAccessToken from '../../../../../services/foursquare/getAccessToken';
import getMyVenues from '../../../../../services/foursquare/getMyVenues';
import updateLocation from '../../../../../services/locations/updateLocation';
import getVenueDetails from '../../../../../services/foursquare/getVenueDetails';
import createVenue from '../../../../../services/foursquare/createVenue';

const Foursquare = () => {
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  const [isLoading, setIsLoading] = React.useState(false);
  const [myVenues, setMyVenues] = React.useState([]);
  const [venues, setVenues] = React.useState([]);
  const [openDialog, setOpenDialog] = React.useState(false);

  const { user } = useSelector(
    (state) => state.AuthReducer,
  );
  const { activeLocation } = useSelector(state => state.LocationReducer);

  const onBindClicked = async () => {
    setIsLoading(true);
    const { latitude, longitude, name } = activeLocation;
    const { venues: results } = await searchForVenue(user.accessToken, latitude, longitude, name);

    setVenues(results);
    setIsLoading(false);
    setOpenDialog(true);
  };

  const winOpen = async (url) => {
    const newWin = window.open(url, '', 'width=200,height=200');

    newWin.onload = async function () {
      const code = newWin.location.search.split('=')[1];
      const accessToken = await getFoursquareAccessToken({ accessToken: user.accessToken, redirectUri: process.env.REACT_APP_FOURSQUARE_REDIRECT_URL, code });
      console.log(accessToken);
      newWin.close();
    };
  };

  const onConnectClicked = () => {
    winOpen(`https://foursquare.com/oauth2/authenticate?client_id=${process.env.REACT_APP_FOURSQUARE_CLIENT_ID}&response_type=code&redirect_uri=${process.env.REACT_APP_FOURSQUARE_REDIRECT_URL}`);
  };

  const handleOnBindVenueClicked = async ({ selectedPageId }) => {
    console.log(selectedPageId);
    const locationUpdate = (await updateLocation(
      {
        accessToken: user.accessToken,
        locationId: activeLocation.id,
        inputData: { foursquare_venue_id: selectedPageId },
      },
    ));
    dispatch(LocationActions.setActiveLocation(locationUpdate));
    setOpenDialog(false);
  };

  const handleOnUnbindClicked = async () => {
    const locationUpdate = (await updateLocation(
      {
        accessToken: user.accessToken,
        locationId: activeLocation.id,
        inputData: { foursquare_venue_id: null },
        deleteFoursquare: true,
      },
    ));
    dispatch(LocationActions.setActiveLocation(locationUpdate));
  };

  const goToFoursquare = async () => {
    const { venue: { canonicalUrl } } = await getVenueDetails(user.accessToken, activeLocation.foursquare_venue_id);
    window.open(canonicalUrl);
  };

  const handleCreateVenue = async () => {
    const foursquareCategoriesJson = JSON.parse(activeLocation.foursquare_categories);
    let additionalCategories = '';
    for (let i = 1; i < foursquareCategoriesJson.length; i++) {
      additionalCategories = `${additionalCategories}${foursquareCategoriesJson[i].id},`;
    }
    additionalCategories = additionalCategories.slice(0, -1);
    const inputData = {
      name: activeLocation.name,
      latlng: {
        lat: activeLocation.latitude,
        lng: activeLocation.longitude,
      },
      primaryCategoryId: foursquareCategoriesJson[0].id,
      address: `${activeLocation.address1} ${activeLocation.address2}`,
      city: activeLocation.city,
      state: activeLocation.state,
      zipCode: activeLocation.zip_code,
      phone: activeLocation.phone,
      allCategoryIds: additionalCategories,
      cc: 'BR',
      twitter: activeLocation.twitter,
      description: activeLocation.long_description,
      url: activeLocation.website,
    };

    const newWin = window.open(`https://foursquare.com/oauth2/authenticate?client_id=${process.env.REACT_APP_FOURSQUARE_CLIENT_ID}&response_type=code&redirect_uri=${process.env.REACT_APP_FOURSQUARE_REDIRECT_URL}`, '', 'width=200,height=200');

    const venueCreation = async function () {
      const code = newWin.location.search.split('=')[1];
      const { access_token: accessToken } = await getFoursquareAccessToken({ accessToken: user.accessToken, redirectUri: process.env.REACT_APP_FOURSQUARE_REDIRECT_URL, code });
      try {
        await createVenue({ accessToken: user.accessToken, foursquareToken: accessToken, createVenueData: inputData });
        enqueueSnackbar('Local criado com sucesso', {
          variant: 'success',
        });
      } catch (err) {
        enqueueSnackbar('Erro ao criar local no Foursquare', {
          variant: 'error',
        });
      }
      newWin.close();
    };

    newWin.onload = venueCreation;
  };

  return (
    <StyledFoursquare>
      {activeLocation.foursquare_venue_id
        ? (
          <>
            <div className="venue-info">
              <span className="primary-text">Foursquare id:</span>
              <span className="secondary-text">{activeLocation.foursquare_venue_id}</span>
            </div>
            <StyledButtonGroup>
              <Button className="button" onClick={goToFoursquare}>Visualizar no foursquare</Button>
              <Button className="button" onClick={handleOnUnbindClicked}>Desvincular</Button>
            </StyledButtonGroup>
          </>
        )
        : (
          <Button onClick={onBindClicked} disabled={isLoading}>
            {isLoading ? <Loading /> : 'Vincular'}
          </Button>
        )}
      <ListingDialog
        open={openDialog}
        handleClose={() => setOpenDialog(false)}
        data={venues}
        name="name"
        keyToFilter="name"
        handleBindWithLocation={handleOnBindVenueClicked}
        headerTitle="Encontramos estes locais no foursquare com base nas suas informações"
        headerTitleCreateButton="Criar"
        handleHeaderCreateButton={handleCreateVenue}
        noImages
        allowCreate
      />
    </StyledFoursquare>
  );
};

export default Foursquare;
