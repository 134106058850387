import React from 'react';
import {
  StyledActionWrapper,
  StyledCloudDone,
  StyledLinkedLocations,
  StyledPopover,
  StyledPopoverContent,
  StyledLinkedLocation,
  StyledLocationNameWrapper,
  StyledAddress,
} from './linked-location-styles';

const LinkedLocation = ({ location }) => {
  const [anchorEl, setAnchorEl] = React.useState<HTMLDivElement | null>(null);
  const popoverId = anchorEl ? 'simple-popover' : undefined;
  return (
    <>
      <StyledActionWrapper>
        <StyledLinkedLocations
          aria-describedby={popoverId}
          onClick={e => setAnchorEl(e.currentTarget)}
        >
          <StyledCloudDone className="see-linked-locations-icon" />
          Ver local vinculado
        </StyledLinkedLocations>
      </StyledActionWrapper>
      <StyledPopover
        id={popoverId}
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(null)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'center',
          horizontal: 'center',
        }}
      >
        <StyledPopoverContent>
          <StyledLinkedLocation>
            <StyledLocationNameWrapper>
              <h4>{location.name}</h4>
              <StyledAddress>
                <p>{location.address1}</p>
              </StyledAddress>
            </StyledLocationNameWrapper>
          </StyledLinkedLocation>
        </StyledPopoverContent>
      </StyledPopover>
    </>
  );
};

export default LinkedLocation;
