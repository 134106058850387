import axios from 'axios';

export default async function createLocationAccessLevelProfile({
  accessToken, userId, name, description, rules,
}) {
  const requestOptions = {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  };

  try {
    const { data } = await axios.post(
      `${process.env.REACT_APP_LOCATION_ACCESS_LEVEL_PROFILE_API_URL}`,
      {
        user_id: userId, name, description, rules,
      },
      requestOptions,
    );

    return data;
  } catch (err) {
    console.log(err);
    return err;
  }
}
