import { TextField } from '@material-ui/core';
import styled from 'styled-components';

export const StyledContainer = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
`;

export const StyledLabel = styled.text`
    font-size: 1.3rem;
    font-weight: bold;
    line-height: 1.4rem;
    padding: 12px 0;
`;

export const StyledInput = styled(TextField)`
    .MuiInputBase-root{
        font-size: 1.3rem;
        border-radius: 6px;
    }
    
    .MuiInputBase-input {
        box-sizing: inherit !important;
    }

`;
