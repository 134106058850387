import styled from 'styled-components';

export const StyledContainer = styled.div`
`;

export const StyledIconWrapper = styled.div`
  display: flex;
  justify-content: center;
`;

export const StyledIconsWrapper = styled.div`
  display:flex;
  justify-content:center;
  align-items:center;
`;
