import axios from 'axios';

import type {
  TGetLocationsWithoutLinked,
  TLocationEntity,
  TGetLocationsWithoutLinkedResponse,
} from '../../types/TLocation';

export const getLocationsWithoutLinked = async ({
  group,
  groupId,
  userAccessToken,
  isFetching,
}: TGetLocationsWithoutLinked): Promise<TGetLocationsWithoutLinkedResponse> => {
  try {
    if (isFetching) isFetching(true);

    const path = `
      ${process.env.REACT_APP_LOCATIONS_API_URL}/get-locations-without-linked-google/${group}/${groupId}
    `;

    const config = {
      headers: {
        Authorization: `Bearer ${userAccessToken}`,
      },
    };

    const { data } = await axios.get(path, config);

    const [locationsData, locationDataCount] = data;

    return {
      locations: locationsData.map((location: TLocationEntity) => location.name),
      count: locationDataCount,
    };
  } catch (err) {
    return null;
  } finally {
    if (isFetching) isFetching(false);
  }
};
