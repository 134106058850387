import { isNil } from 'lodash';
import { TIsValidImageFile, TIsValidBaseProps, TFile } from '../../types/TDragAndDropFiles';

import addImageProcess from '../../utils/add-image-process';

export const isValidImageFile = async ({
  imageFileValidation,
  imageFile,
  callbackValidationMessage,
  category = null,
}: TIsValidImageFile) => {
  const { imageWidth, imageHeight } = await addImageProcess(imageFile.sourceUrl);

  if (imageFile.size < imageFileValidation.imageSize) {
    callbackValidationMessage(
      imageFileValidation.imageSizeMessage(imageFile.name),
      { variant: 'warning' },
    );

    return false;
  }

  const imageTooSmall = imageWidth < imageFileValidation.minImageWidth
      || imageHeight < imageFileValidation.minImageHeight;

  const imageTooLarge = imageWidth > imageFileValidation.maxImageWidth
      || imageHeight > imageFileValidation.maxImageHeight;

  if (imageTooSmall) {
    callbackValidationMessage(
      imageFileValidation.tooSmallImageMessage(imageFile.name),
      { variant: 'warning' },
    );

    return false;
  }

  if (imageTooLarge) {
    callbackValidationMessage(
      imageFileValidation.tooLargeImageMessage(imageFile.name),
      { variant: 'warning' },
    );

    return false;
  }

  if (!isNil(category) && category === 'Capa') {
    const imageRatio = imageWidth / imageHeight;

    if (parseFloat(imageRatio.toFixed(5)) !== 1.77778) {
      callbackValidationMessage(
        imageFileValidation.wrongAspectRatioImageMessage(imageFile.name),
        { variant: 'warning' },
      );

      return false;
    }
  }

  return true;
};

export const isValidVideo = ({
  fileVideo,
  callbackValidationMessage,
  maxVideoSize,
}: TIsValidBaseProps) => {
  if (Math.ceil(fileVideo.size / 1000000) < maxVideoSize) return true;

  callbackValidationMessage(`O tamanho do video tem que ser menor do que ${maxVideoSize}MB`, { variant: 'warning' });

  return false;
};

export const validFileTypes = [
  'image/jpeg',
  'image/jpg',
  'image/png',
  'image/bmp',
  'video/avi',
  'video/mp4',
  'video/mov',
  'video/flv',
  'video/wmv',
  'video/mpg',
  'video/m4v',
  'video/mkv',
  'video/m2ts',
  'video/mts',
];

export const singleModeTypes = '.jpeg,.jpg,.png,.bmp';
export const multipleModeTypes = '.jpeg,.jpg,.png,.bmp.avi,.mp4,.mov,.flv,.wmv,.mpg,.m4v,.mkv,.m2ts,.mts';

export const isValidFileType = (file: TFile) => {
  const { type } = file;

  if (validFileTypes.includes(type)) return true;

  return false;
};
