import React from 'react';
import { IconButton } from '@material-ui/core';
import { Clear } from '@material-ui/icons';

import { type } from 'ramda';
import { StyledContent, StyledRow } from './day-selector-styles';

import DayRow from './day-row';
import Input from '../input';

export default ({
  onClearClicked,
  onChange,
  mondayOpen,
  mondayClose,
  tuesdayOpen,
  tuesdayClose,
  wednesdayOpen,
  wednesdayClose,
  thursdayOpen,
  thursdayClose,
  fridayOpen,
  fridayClose,
  saturdayOpen,
  saturdayClose,
  sundayOpen,
  sundayClose,
}) => (
  <StyledContent>
    <StyledRow>
      <div style={{ width: '0em' }} />
      <h3>Abre</h3>
      <h3>Fecha</h3>
    </StyledRow>
    <DayRow
      day="monday"
      label="Segunda-Feira"
      openName="mondayOpen"
      openValue={mondayOpen}
      closeName="mondayClose"
      closeValue={mondayClose}
      onChange={onChange}
      onClearClicked={onClearClicked}
    />
    <DayRow
      day="tuesday"
      label="Terça-Feira"
      openName="tuesdayOpen"
      openValue={tuesdayOpen}
      closeName="tuesdayClose"
      closeValue={tuesdayClose}
      onChange={onChange}
      onClearClicked={onClearClicked}
    />
    <DayRow
      day="wednesday"
      label="Quarta-Feira"
      openName="wednesdayOpen"
      openValue={wednesdayOpen}
      closeName="wednesdayClose"
      closeValue={wednesdayClose}
      onChange={onChange}
      onClearClicked={onClearClicked}
    />
    <DayRow
      day="thursday"
      label="Quinta-Feira"
      openName="thursdayOpen"
      openValue={thursdayOpen}
      closeName="thursdayClose"
      closeValue={thursdayClose}
      onChange={onChange}
      onClearClicked={onClearClicked}
    />
    <DayRow
      day="friday"
      label="Sexta-Feira"
      openName="fridayOpen"
      openValue={fridayOpen}
      closeName="fridayClose"
      closeValue={fridayClose}
      onChange={onChange}
      onClearClicked={onClearClicked}
    />
    <DayRow
      day="saturday"
      label="Sábado"
      openName="saturdayOpen"
      openValue={saturdayOpen}
      closeName="saturdayClose"
      closeValue={saturdayClose}
      onChange={onChange}
      onClearClicked={onClearClicked}
    />
    <DayRow
      day="sunday"
      label="Domingo"
      openName="sundayOpen"
      openValue={sundayOpen}
      closeName="sundayClose"
      closeValue={sundayClose}
      onChange={onChange}
      onClearClicked={onClearClicked}
    />
  </StyledContent>
);
