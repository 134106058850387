import { isNil } from 'ramda';
import React, {
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useAuth } from '../../../../../hooks';
import { updateRequest } from '../../../../../services/platform-sync-modules/update-request';
import isNilOrEmpty from '../../../../../utils/is-null-or-empty';
import { formatDataToList, getSwitchValue, splitDataIntoSections } from './helpers';
import APIGetValidGoogleAccessTokenFromLocation from '../../../../../services/getValidGoogleAccessTokenFromLocation';

import RequestsListPopover from './requests-list-popover';
import {
  StyledButton,
  StyledButtonsWrapper,
  StyledDoubleCheckMessage,
  StyledRequestsPopover,
  StyledTitle,
} from './requests-popover-styles';
import RequestsSectionPopover from './requests-section-popover';
import {
  TAddressSwitches, TBasicInfoSwitches, TCategoriesSwitches, TRequestsPopoverProps,
} from './types';
import { Loading } from '../../../../../components';

const RequestsPopover = ({
  data,
  requestId = '',
  onRequestUpdate,
  requestStatus = '',
  locationId,
}: TRequestsPopoverProps) => {
  const {
    userAccessToken,
    userId,
    userProfileName,
    userSetTokenLikeExpired,
  } = useAuth();

  const [isBasicInfoOpen, setIsBasicInfoOpen] = useState(true);
  const [isCategoriesOpen, setIsCategoriesOpen] = useState(true);
  const [isAddressOpen, setIsAddressOpen] = useState(true);
  const [isDoubleCheckOpen, setIsDoubleCheckOpen] = useState(false);

  const [basicInfoSwitches, setBasicInfoSwitches] = useState<TBasicInfoSwitches>({} as TBasicInfoSwitches);
  const [categoriesSwitches, setCategoriesSwitches] = useState<TCategoriesSwitches>({} as TCategoriesSwitches);
  const [addressSwitches, setAddressSwitches] = useState<TAddressSwitches>({} as TAddressSwitches);

  const [isLoading, setLoading] = useState(false);

  const dataSplitIntoSections = splitDataIntoSections(data);
  const { addressInfo, infoBasic, categories } = dataSplitIntoSections;
  const getFields = array => array.map((field) => field.label.toLowerCase());

  useEffect(() => {
    const getInitialSwitchesValues = (fields: any) => fields
      .reduce((result: any, key: string) => {
        const { status } = data.find(fieldData => fieldData.label.toLowerCase() === key) ?? { status: 'PENDING' };
        return ({ ...result, [key]: getSwitchValue[status] });
      }, {} as any);

    if (!isNilOrEmpty(addressInfo)) {
      const addressInfoFields = getFields(addressInfo);
      const initialAddressSwitchesValues = getInitialSwitchesValues(addressInfoFields);
      setAddressSwitches(initialAddressSwitchesValues);
    }
    if (!isNilOrEmpty(infoBasic)) {
      const infoBasicFields = getFields(infoBasic);
      const initialBasicInfoSwitchesValues = getInitialSwitchesValues(infoBasicFields);
      setBasicInfoSwitches(initialBasicInfoSwitchesValues);
    }

    if (!isNilOrEmpty(categories)) {
      const categoriesFields = getFields(categories);
      const initialCategoriesSwitchesValues = getInitialSwitchesValues(categoriesFields);
      setCategoriesSwitches(initialCategoriesSwitchesValues);
    }
  }, [data]);

  const getAllSwitches = useCallback(() => ({ ...basicInfoSwitches, ...categoriesSwitches, ...addressSwitches }), [basicInfoSwitches, categoriesSwitches, addressSwitches]);

  const isEverySwithMarked = useMemo(() => {
    const allSwitches = getAllSwitches();
    return Object.keys(allSwitches).every(key => !isNil(allSwitches[key]));
  }, [getAllSwitches]);

  const hasStatusAlready = useMemo(() => (requestStatus ? (requestStatus !== 'PENDING' && requestStatus !== 'PROCESSING') : false), [requestStatus]);

  const updateRequestFields = useCallback(async () => {
    if (isNil(locationId)) return;
    const googleAccessTokenResponse = await APIGetValidGoogleAccessTokenFromLocation({
      accessToken: userAccessToken,
      userId,
      userProfile: userProfileName,
      locationId,
    });

    if (!googleAccessTokenResponse) return;

    const { googleAccessToken } = googleAccessTokenResponse;
    const allSwitches = getAllSwitches();
    const platformSyncData: any = data.map(field => {
      const label = field.label.toLowerCase();
      const isApproved = allSwitches[label];
      return { ...field, status: isApproved ? 'APPROVED' : 'REJECTED' };
    });

    await updateRequest({
      googleAccessToken,
      platformSyncData,
      requestId,
      userAccessToken,
    });
  }, [getAllSwitches, data]);

  const isBasicInfoChecked = useMemo(() => {
    if (Object.keys(basicInfoSwitches).every(key => basicInfoSwitches[key] === true)) return true;
    if (Object.keys(basicInfoSwitches).every(key => basicInfoSwitches[key] === false)) return false;
    return null;
  }, [basicInfoSwitches]);

  const isCategoriesChecked = useMemo(() => {
    if (Object.keys(categoriesSwitches).every(key => categoriesSwitches[key] === true)) return true;
    if (Object.keys(categoriesSwitches).every(key => categoriesSwitches[key] === false)) return false;
    return null;
  }, [categoriesSwitches]);

  const isAddressChecked = useMemo(() => {
    if (Object.keys(addressSwitches).every(key => addressSwitches[key] === true)) return true;
    if (Object.keys(addressSwitches).every(key => addressSwitches[key] === false)) return false;
    return null;
  }, [addressSwitches]);

  const handleSectionSwitch = useCallback((
    switches: any,
    setSwitches: any,
    isChecked: boolean | null,
  ) => {
    const newSwitches = { ...switches };
    const keys = Object.keys(switches);
    keys.forEach(key => { newSwitches[key] = !isChecked; });
    setSwitches(newSwitches);
  }, [isBasicInfoChecked, isCategoriesChecked, isAddressChecked]);

  const handleApprove = useCallback(() => {
    setIsDoubleCheckOpen(true);
  }, [basicInfoSwitches, categoriesSwitches, addressSwitches]);

  const handleContinue = useCallback(async () => {
    setLoading(true);
    await updateRequestFields();
    onRequestUpdate();
  }, [basicInfoSwitches, categoriesSwitches, addressSwitches]);

  const handleGoBack = useCallback(() => {
    setIsDoubleCheckOpen(false);
  }, []);

  return (
    <StyledRequestsPopover>
      {!isNilOrEmpty(dataSplitIntoSections.infoBasic) && (
        <>
          <RequestsSectionPopover
            open={isBasicInfoOpen}
            setOpen={() => setIsBasicInfoOpen(prev => !prev)}
            title="Informações básicas"
            isChecked={isBasicInfoChecked}
            handleChecked={() => handleSectionSwitch(
              basicInfoSwitches,
              setBasicInfoSwitches,
              isBasicInfoChecked,
            )}
            hasStatusAlready={hasStatusAlready}
          />
          {isBasicInfoOpen
            && (
              <RequestsListPopover
                data={formatDataToList(dataSplitIntoSections.infoBasic)}
                switches={basicInfoSwitches}
                setSwitches={setBasicInfoSwitches}
                hasStatusAlready={hasStatusAlready}
              />
            )}
        </>
      )}
      {!isNilOrEmpty(dataSplitIntoSections.categories) && (
        <>
          <RequestsSectionPopover
            open={isCategoriesOpen}
            setOpen={() => setIsCategoriesOpen(prev => !prev)}
            title="Categorias"
            isChecked={isCategoriesChecked}
            handleChecked={() => handleSectionSwitch(
              categoriesSwitches,
              setCategoriesSwitches,
              isCategoriesChecked,
            )}
            hasStatusAlready={hasStatusAlready}
          />
          {isCategoriesOpen
            && (
              <RequestsListPopover
                data={formatDataToList(dataSplitIntoSections.categories)}
                switches={categoriesSwitches}
                setSwitches={setCategoriesSwitches}
                hasStatusAlready={hasStatusAlready}
              />
            )}
        </>
      )}
      {!isNilOrEmpty(dataSplitIntoSections.addressInfo) && (
        <>
          <RequestsSectionPopover
            open={isAddressOpen}
            setOpen={() => setIsAddressOpen(prev => !prev)}
            title="Endereço"
            isChecked={isAddressChecked}
            handleChecked={() => handleSectionSwitch(
              addressSwitches,
              setAddressSwitches,
              isAddressChecked,
            )}
            hasStatusAlready={hasStatusAlready}
          />
          {isAddressOpen
            && (
              <RequestsListPopover
                data={formatDataToList(dataSplitIntoSections.addressInfo)}
                switches={addressSwitches}
                setSwitches={setAddressSwitches}
                hasStatusAlready={hasStatusAlready}
              />
            )}
        </>
      )}
      {isDoubleCheckOpen && (
        <StyledDoubleCheckMessage>
          <StyledTitle>Atenção, você está prestes a executar uma ação que não poderá ser desfeita. Deseja continuar?</StyledTitle>
        </StyledDoubleCheckMessage>
      )}
      {isDoubleCheckOpen ? (
        <StyledButtonsWrapper>
          <StyledButton onClick={handleContinue} disabled={isLoading} className="approve" type="button">{isLoading ? <Loading /> : 'Continuar'}</StyledButton>
          <StyledButton onClick={handleGoBack} disabled={isLoading} className="back" type="button">Voltar</StyledButton>
        </StyledButtonsWrapper>
      ) : (
        <StyledButtonsWrapper>
          <StyledButton
            disabled={!isEverySwithMarked || hasStatusAlready}
            onClick={handleApprove}
            className="initial"
            type="button"
          >
            Enviar
          </StyledButton>
        </StyledButtonsWrapper>
      )}
    </StyledRequestsPopover>
  );
};

export default RequestsPopover;
