export const getLocationByCompanyOrGroup = ({
  byGroup,
  byCompany,
  companyLocations,
  locationsGroup,
}) => {
  if (byCompany) {
    return {
      ...companyLocations,
      options: companyLocations.userCompanyLocations,
      currentOption: companyLocations.currentActiveLocation,
    };
  }

  if (byGroup) {
    return {
      ...locationsGroup,
      options: locationsGroup.locationsGroup,
      currentOption: locationsGroup.currentActiveLocation,
    };
  }

  return {
    ...companyLocations,
    options: companyLocations.userCompanyLocations,
    currentOption: companyLocations.currentActiveLocation,
  };
};
