import axios from 'axios';

import { TGetAccountData } from '../../types/TGoogle';

export const getAccountData = async ({
  accessToken,
  isFetching,
}: TGetAccountData): Promise<any> => {
  const config = {
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
  };

  try {
    if (isFetching) isFetching(true);

    const { data } = await axios.get(
      `https://www.googleapis.com/oauth2/v1/userinfo?access_token=${accessToken}`,
      config,
    );

    return data;
  } catch (err: any) {
    return err.response;
  } finally {
    if (isFetching) isFetching(true);
  }
};
