import React from 'react';
import { useDispatch } from 'react-redux';
import { useParams, useHistory } from 'react-router-dom';
import axios from 'axios';
import { Helmet } from 'react-helmet';
import { useSnackbar } from 'notistack';

import { Creators as AuthActions } from '../../redux/ducks/auth';

import { useUncontrolledField } from '../../hooks';

import { Button, Loading } from '../../components';

import {
  passwordScheme,
  confirmPasswordScheme,
} from './fields-scheme';

import hublocalLogo from '../../assets/images/hublocal-logo.png';

import {
  StyledResetPassword,
  StyledForm,
} from './reset-password-styles';

const ResetPassword = () => {
  const params = useParams();
  const history = useHistory();
  const reduxDispatch = useDispatch();

  const { enqueueSnackbar } = useSnackbar();

  const [fields, setFields] = React.useState([]);
  const [isResetting, setIsResetting] = React.useState(false);

  const { Field: Password } = useUncontrolledField({ fieldScheme: passwordScheme, saveIn: setFields });
  const { Field: ConfirmPassword } = useUncontrolledField({ fieldScheme: confirmPasswordScheme, saveIn: setFields });

  const handleChangePasswordClicked = async (e) => {
    e.preventDefault();

    const { token } = params;

    const checkFields = fields.map(field => field.firedCheck);

    const password = fields[0].getValue();
    const confirmPassword = fields[1].getValue();

    if (checkFields.every(check => check())) {
      if (password !== confirmPassword) {
        enqueueSnackbar('As senhas estão diferentes', { variant: 'error' });
        return;
      }

      try {
        if (setIsResetting) setIsResetting(true);

        const responseAlterPassword = await axios.put(`${process.env.REACT_APP_AUTH_API_URL}/unsignedChangePassword`,
          {
            password,
            passwordToken: token,
          });

        if (responseAlterPassword) {
          enqueueSnackbar('Senha alterada com sucesso', { variant: 'success' });
          reduxDispatch(AuthActions.setUser(null));
          history.push('/');
        }
      } catch (err) {
        if (err.data.statusCode === 401) {
          enqueueSnackbar('A validade da credencial para redefinir sua senha expirou. Entre em contato com um de nossos operadores para gerar-mos um novo link.', { variant: 'error' });
        } else {
          enqueueSnackbar('Formato inválido. Faça uma senha de 8 a 20 digitos que contenha letras (maiúsculas e minúsculas) e números!', { variant: 'error' });
        }
      } finally {
        if (setIsResetting) setIsResetting(false);
      }
    }
  };

  return (
    <StyledResetPassword>
      <Helmet>
        <meta property="og:title" content="Resetar Senha - SaaS Hublocal" />
        <meta property="og:description" content="Resetar senha." />

        <title>Alterar Senha - SaaS HubLocal</title>
      </Helmet>

      <img src={hublocalLogo} className="hublocal-logo" alt="Hublocal" />
      <StyledForm onSubmit={handleChangePasswordClicked}>
        <Password />
        <ConfirmPassword />
        <Button
          className="submit-button"
          type="submit"
          disabled={isResetting}
        >
          {isResetting ? <Loading /> : 'Alterar senha'}
        </Button>
      </StyledForm>
    </StyledResetPassword>
  );
};

export default ResetPassword;
