import React from 'react';
import { debounce } from 'lodash';
import { isNil } from 'ramda';

import type { TChangedColumn } from '../../types/TTable';

export const changedColumnState: TChangedColumn = {
  changedColumn: null,
  action: null,
};

export const onDebounceSearchChange = debounce(
  (setQuery: React.Dispatch<React.SetStateAction<string>>, text: string) => setQuery(text),
  500,
);

export const onDownloadCSVDataMount = (dataToMount: any) => dataToMount
  .filter((dataColumn: any) => dataColumn?.visible === true && !isNil(dataColumn))
  .map((dataColumn: any) => dataColumn?.value);

export const onDownloadCSVDataBuildHeadColumns = (dataColumnToMount: any) => dataColumnToMount
  .filter((dataColumn: any) => dataColumn.name !== 'expandable'
    && dataColumn.name !== 'actions'
    && dataColumn.display === 'true');

// TODO: tipar corretamente
export const optionsHandleOnDownloadCSV = (
  buildHead: any,
  buildBody: any,
  onDownlodColumns: any,
  onDownloadData: any,
) => {
  const buildBodyData = onDownloadData.map(({
    data,
    index,
  }: any) => ({
    index,
    data: onDownloadCSVDataMount(data),
  }));

  return `\uFEFF${
    buildHead(onDownloadCSVDataBuildHeadColumns(onDownlodColumns))
  } ${buildBody(buildBodyData)}`;
};
