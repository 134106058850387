import styled from 'styled-components';
import Chip from '@material-ui/core/Chip';
import { shade } from 'polished';

export const StyledChip = styled(Chip)`
    color: ${(props) => props.theme.colors.white};
    background: ${(props) => props.theme.colors.secondary};
    font-size: 1.5rem;
    font-family: Poppins;
    width: 25rem;

    &:hover,
    &:focus {
      background: ${(props) => shade(0.2, props.theme.colors.secondary)};
    }
`;
