import React, { useState } from 'react';
import { Tab, TabBar } from '../../components';
import Monitor from './monitor';
import Requests from './requests';
import { StyledSyncMonitoring } from './sync-monitoring-styles';
import SyncPermissions from './sync-permissions';

const SyncMonitoring = () => {
  const [isTabDisabled, setIsTabDisabled] = useState(false);
  const [activeTab, setActiveTab] = useState(0);

  const a11yProps = (index: number) => ({
    id: `full-width-tab-${index}`,
    'aria-controls': `full-width-tabpanel-${index}`,
    className: 'tabbar-tab',
  });

  return (
    <StyledSyncMonitoring>
      <TabBar
        handleChange={(_: any, newValue: number) => setActiveTab(newValue)}
        activeTab={activeTab}
        noBorderTop
      >
        <Tab
          label="Solicitações"
          disabled={isTabDisabled}
          isActive={activeTab === 0}
          {...a11yProps(0)}
        />

        <Tab
          label="Monitor"
          disabled={isTabDisabled}
          isActive={activeTab === 1}
          {...a11yProps(1)}
        />

        <Tab
          label="Permissão de Sincronização"
          disabled={isTabDisabled}
          isActive={activeTab === 2}
          {...a11yProps(1)}
        />
      </TabBar>
      {activeTab === 0 && <Requests />}
      { activeTab === 1 && <Monitor />}
      {activeTab === 2 && <SyncPermissions />}
    </StyledSyncMonitoring>
  );
};

export default SyncMonitoring;
