import styled from 'styled-components';
import { devices } from '../../../../assets/styles/devices';

export const StyledLinks = styled.div`
  width: 100%;
`;

export const StyledInputGroup = styled.div`
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  grid-column-gap: 2rem;
  grid-row-gap: 1rem;
  margin-bottom: 2rem;

  @media ${devices.tabletLandscape} {
    grid-template-columns: repeat(2, 1fr);
  }

  &:last-of-type {
    margin-bottom: 0;
  }
`;

export const StyledSectionTitle = styled.h3`
  font-size: 1.4rem;
  font-weight: bold;
  color: ${props => props.theme.colors.primary};
  margin-bottom: 1.5rem;
  line-height: 1;
`;
