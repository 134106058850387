import React from 'react';

import {
  StyledProgressBar, StyledProgressValue, StyledProgress, StyledProgressBarWrapper,
} from './progress-bar-styles';

const ProgressBar = ({ progressValue, className }) => (
  <StyledProgressBarWrapper>
    <StyledProgressValue
      style={{ left: `${progressValue}%` }}
      isDifference={progressValue > 70}
    >
      {Math.floor(progressValue)}
      %
    </StyledProgressValue>

    <StyledProgress
      style={{ left: `${progressValue}%` }}
      isDifference={progressValue > 70}
    />

    <StyledProgressBar className={className} variant="determinate" value={0} />
  </StyledProgressBarWrapper>
);

export default ProgressBar;
