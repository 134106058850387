import React from 'react';

import Dialog from '../dialog';
import Button from '../button';
import Loading from '../loading';

import {
  StyledContent,
  StyledBottomActions,
} from './confirm-dialog-styles';

const ConfirmDialog = ({
  title,
  open,
  onClose,
  children,
  onCancel,
  onConfirm,
  confirmTitle = 'Confirmar',
  confirmType = 'primary',
  contentNoPadding = false,
  isLoading = false,
}) => (
  <Dialog
    open={open}
    onClose={onClose}
    title={title}
  >
    <StyledContent
      contentNoPadding={contentNoPadding}
    >
      {children}
    </StyledContent>
    <StyledBottomActions>
      <Button
        className="button"
        buttonType="cancel"
        onClick={onCancel}
      >
        Cancelar
      </Button>
      <Button
        disabled={isLoading}
        className="button"
        onClick={onConfirm}
        buttonType={confirmType}
      >
        {isLoading && <Loading className="is-loading-confirm-dialog" />}
        {confirmTitle}
      </Button>
    </StyledBottomActions>
  </Dialog>
);

export default ConfirmDialog;
