export const mapLocationFields = {
  name: 'Nome',
  address1: 'Endereço',
  address2: 'Complemento',
  address: 'Endereço',
  'address-number': 'Número',
  'address-complement': 'Complemento',
  zipcode: 'CEP',
  zip_code: 'CEP',
  city: 'Cidade',
  'state-code': 'Estado',
  neighborhood: 'Bairro',
  short_description: 'Descrição Curta',
  long_description: 'Descrição',
  store_code: 'Código da loja',
  'store-code': 'Código da loja',
  country: 'País',
  'country-code': 'País',
  phone: 'Telefone',
  'main-phone': 'Telefone',
  'secondary-phone': 'Telefone secundário',
  secondary_phone: 'Telefone Secundário',
  email: 'E-mail',
  does_not_serve_at_this_address: 'Atende no local?',
  is_active: 'Está ativo?',
  latitude: 'Latitude',
  longitude: 'Longitude',
  google_categories: 'Categorias do Google',
  facebook_categories: 'Categorias do Facebook',
  foursquare_categories: 'Categorias do Foursquare',
  working_hours: 'Horários de Funcionamento',
  special_dates: 'Datas Especiais',
  category_groupins: 'Grupos de categorias',
  website: 'Website',
  facebook: 'Facebook',
  instagram: 'Instagram',
  twitter: 'Twitter',
  youtube: 'Youtube',
  foursquare: 'Foursquare',
  linkedin: 'Linkedin',
  yelp: 'Yelp',
  menu: 'Cardápio',
  schedule: 'Calendário',
  reservations: 'Reservas',
  advanceOrder: 'Pedido antecipado',
  'answer-at-that-address': 'Atende no local?',
  state: 'Estado',
  mediaItems: 'Mídias',
  topicType: 'Tipo de postagem',
  termsConditions: 'Termos e Condições',
  couponCode: 'Código do Cupom',
  redeemOnlineUrl: 'Link de resgate',
  actionType: 'Botão de Ação',
  url: 'URL do botão',
  title: 'Título',
  links: 'Links',
  basic_infos: 'Informações Básicas',
  google_service_area: 'Área de cobertura',
};

export const getActionType = (actionType) => {
  switch (actionType) {
    case 'BOOK':
      return 'Reservar';
    case 'ORDER':
      return 'Fazer o Pedido';
    case 'SHOP':
      return 'Comprar';
    case 'LEARN_MORE':
      return 'Saiba mais';
    case 'SIGN_UP':
      return 'Inscrever-se';
    case 'CALL':
      return 'Ligar';
    default:
      return 'Sem tipo específico';
  }
};

export const isWorkingHours = (field) => field === 'Horários de Funcionamento';
export const phoneNotEmpty = (field) => field !== '__ ____-____';
export const isSpecialDate = (field) => field === 'Datas Especiais';
export const isServiceArea = (field) => field === 'Área de cobertura';

