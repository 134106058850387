import axios from 'axios';

export default async function getInvite({ accessToken, id, setTokenLikeExpired }) {
  try {
    const { data } = await axios.get(
      `${process.env.REACT_APP_INVITES_API_URL}/validate/${id}`,
    );

    return data;
  } catch (err) {
    if (err.response?.status === 401 && setTokenLikeExpired) return setTokenLikeExpired();

    return err;
  }
}
