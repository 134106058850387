import styled from 'styled-components';

import { devices } from '../../assets/styles/devices';

export const StyledHeaderMobile = styled.header`
  height: 7rem;
  background: ${props => props.theme.colors.primary};
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 0 2rem;
  position: fixed;
  z-index: 1101;

  @media ${devices.tabletLandscape} {
    display: none;
  }
`;

export const Logo = styled.div`
  display: flex;
  max-width: 4rem;
`;
