import React from 'react';
import { debounce } from 'lodash';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { isNil } from 'ramda';

import {
  Loading, IconButton, Input, SelectMultiple,
} from '../..';

import { History } from '../../../assets/icons';

import { translations } from '../../../translations';
import { useAuth } from '../../../hooks';

import removeLocation from '../../../services/locations/deleteLocation';
import removeCompany from '../../../services/companies/deleteCompany';

import ActionLogDialog from '../../../pages/batch-update-posts-status/action-log-dialog';

import {
  locationGooglePostsLocationStatus,
  batchPostStatusInverted,
} from './helpers';

import {
  deleteLocalPostsLocations,
  getLocalPostsLocationsByGroup,
  updateLocalPostsLocations,
  localPoststLocationsManualPublish,
} from '../../../services/google-batch-modules';

import {
  StyledSubTable,
  StyledCellCustomButtons,
  StyledSubtableFilters,
} from './sub-table-styles';

const SubDataTable = ({
  rowId,
  columns,
  firstOperationName = null,
  onFirstOperationClicked = null,
  clickable = false,
  onRowClickURL,
  onRowClickOperation = null,
  dataType,
  className,
  colspan = '6',
  editable = true,
  batchPostSubtable = false,
  onUpdateClicked,
}) => {
  const reduxDispatch = useDispatch();
  const history = useHistory();
  const { userProfileName } = useAuth();

  const {
    language,
    user: { accessToken, user: { name: user_name, email: user_email, id: user_id } },
  } = useSelector((state) => state.AuthReducer);

  const { data_table: dataTableTranslations } = translations[language];

  const [dataTable, setDataTable] = React.useState([]);

  const [loading, setLoading] = React.useState(false);
  const [page, setPage] = React.useState(0);
  const [pageSize, setPageSize] = React.useState(10);
  const [searchQuery, setSearchQuery] = React.useState('');
  const [count, setCount] = React.useState(10);

  const [openActionLogDialog, setOpenActionLogDialog] = React.useState(false);
  const [actionLogDialogLoading, setActionLogDialogLoading] = React.useState(false);
  const [logToVisualize, setLogToVisualize] = React.useState(null);
  const [postIdToLogVisualize, setPostIdToLogVisualize] = React.useState(null);

  const [selectedPostStatusFilter, setSelectedPostStatusFilter] = React.useState([]);

  const handleOpenActionLogDialog = (postId) => {
    setPostIdToLogVisualize(postId);
    setOpenActionLogDialog(true);
  };

  const getData = React.useCallback(async () => {
    const status = selectedPostStatusFilter
      .map((item) => batchPostStatusInverted[item])
      .join(', ');

    if (dataType === 'BATCH_POST') {
      const localPostsLocations = await getLocalPostsLocationsByGroup({
        userAccessToken: accessToken,
        localPostsUUID: rowId,
        isFetching: setLoading,
        page,
        pageSize,
        query: searchQuery,
        status,
      });

      if (isNil(localPostsLocations)) return;

      const [localPostsLocationsData, localPostsLocationsCount] = localPostsLocations;

      setDataTable(
        localPostsLocationsData.map((localPostsLocationsItem) => [
          localPostsLocationsItem.id,
          localPostsLocationsItem.name,
          localPostsLocationsItem.post_id,
          localPostsLocationsItem.post_link,
          localPostsLocationsItem.status,
        ]),
      );
      setCount(localPostsLocationsCount);
    }
  }, [page, pageSize, searchQuery, accessToken, selectedPostStatusFilter]);

  const handleCancelLocalPostsLocations = React.useCallback(
    async (localPostsLocationsId) => {
      const localPostsLocationsCancelResponse = await updateLocalPostsLocations(
        {
          userAccessToken: accessToken,
          localPostsLocationId: localPostsLocationsId,
          localPostsLocationStatus: 'CANCELLED',
          isUpdating: setLoading,
          action: 'CANCEL',
          user_id,
          user_name,
          user_email,
        },
      );

      if (isNil(localPostsLocationsCancelResponse)) return;

      getData();
      await onUpdateClicked();
    },
    [
      accessToken,
      user_id,
      user_name,
      user_email,
      page,
      pageSize
    ],
  );

  const handleDeletePost = React.useCallback(
    async (localPostLocationId) => {
      const deleteLocalPostLocationResponse = await deleteLocalPostsLocations({
        userAccessToken: accessToken,
        localPostLocationId,
        isDeleting: setLoading,
        user_id,
        user_name,
        user_email,
      });
      if (isNil(deleteLocalPostLocationResponse)) return;

      getData();
      await onUpdateClicked();
    },
    [
      accessToken,
      user_id,
      user_name,
      user_email,
    ],
  );

  const userAsAdminOrOperator = userProfileName !== 'Usuário Padrão';

  const handleForcePublish = React.useCallback(
    async (localPostsLocationId) => {
      const forcePublishResponse = await localPoststLocationsManualPublish({
        userAccessToken: accessToken,
        localPostsLocationId,
        isPublishing: setLoading,
        user_id,
        user_name,
        user_email,
      });

      if (isNil(forcePublishResponse)) return;

      getData();
    },
    [
      accessToken,
      user_id,
      user_name,
      user_email,
    ],
  );

  const dataColumns = [
    ...columns,
    {
      name: 'Histórico',
      options: {
        filter: true,
        sort: false,
        empty: true,
        customBodyRender: (value, meta) => (
          <StyledCellCustomButtons>
            {loading && <Loading className="loading-left" />}
            {!loading && !isNil(meta.rowData[0]) && (
            <IconButton
              tooltip="Visualizar Histórico de Ações da Postagem"
              onClick={() => {
                handleOpenActionLogDialog(meta.rowData[0]);
              }}
              showCustomIcon
              CustomIcon={History}
            />
            )}
          </StyledCellCustomButtons>
        ),
      },
    },
    {
      name: dataTableTranslations.operations,
      options: {
        filter: true,
        sort: false,
        empty: true,
        customBodyRender: (value, meta) => (
          <StyledCellCustomButtons>
            {loading && <Loading className="loading-left" />}

            {!loading && !isNil(meta.rowData[3]) && (
              <>
                <IconButton
                  icon="VisibilityOutlined"
                  tooltip="Visualizar Postagem"
                  onClick={() => {
                    window.open(`${meta.rowData[3]}`, '_blank');
                  }}
                />
                <IconButton
                  icon="Delete"
                  tooltip="Remover Postagem"
                  onClick={() => handleDeletePost(meta.rowData[0])}
                />
              </>
            )}

            {!loading
              && (meta.rowData[4] === 'PENDING'
                || meta.rowData[4] === 'ERROR')
                && userAsAdminOrOperator && (
                <IconButton
                  icon="Close"
                  tooltip="Cancelar postagem"
                  onClick={() => handleCancelLocalPostsLocations(meta.rowData[0])}
                />
            )}

            {!loading && meta.rowData[4] === 'ERROR' && (
              <IconButton
                icon="CloudUpload"
                tooltip="Forçar postagem"
                onClick={() => handleForcePublish(meta.rowData[0])}
              />
            )}
          </StyledCellCustomButtons>
        ),
      },
    },
  ];

  React.useEffect(() => {
    getData();
  }, [getData]);

  const onDeleteClicked = async (id) => {
    if (dataType === 'LOCATION') {
      await removeLocation({ accessToken, id });
    }
    if (dataType === 'COMPANY') {
      await removeCompany({ accessToken, id });
    }
  };

  const options = {
    search: false,
    filter: false,
    download: false,
    print: false,
    viewColumns: false,
    selectableRows: false,
    onSearchChange: (searchText) => {
      if (!searchText) {
        setSearchQuery('');
        return;
      }
      setPage(0);
      setSearchQuery(searchText);
    },
    onSearchClose: () => {
      setPage(0);
      setSearchQuery('');
    },
    serverSide: true,
    page,
    pageSize,
    onChangePage: (currentPage) => {
      setPage(currentPage);
    },
    onChangeRowsPerPage: (numberOfRows) => {
      setPage(0);
      setPageSize(numberOfRows);
    },
    rowsPerPageOptions: [2, 10, 100],
    count,
    elevation: 0,
    responsive: 'simple',
    draggableColumns: { enabled: false },
    onRowClick: clickable
      ? (rowData) => {
        onRowClickOperation(rowData);
        history.push(`${onRowClickURL}`);
      }
      : () => {},
    onRowsDelete(rowsDeleted) {
      rowsDeleted.data.map(({ dataIndex }) => {
        if (dataTable[dataIndex].id) {
          onDeleteClicked(dataTable[dataIndex].id);
          return;
        }

        const id = dataTable[dataIndex][0];
        const functionName = dataTable[dataIndex][1];
        onDeleteClicked(id, functionName);
      });
    },
    textLabels: {
      body: {
        noMatch: loading ? (
          <Loading className="loading" />
        ) : (
          dataTableTranslations.no_register
        ),
        toolTip: dataTableTranslations.sort,
        columnHeaderTooltip: (column) => `${dataTableTranslations.sort_by} ${column.label}`,
      },
      pagination: {
        next: dataTableTranslations.next_page,
        previous: dataTableTranslations.previous_page,
        rowsPerPage: dataTableTranslations.rows_per_page,
        displayRows: dataTableTranslations.display_rows,
      },
      toolbar: {
        search: dataTableTranslations.search,
        downloadCsv: dataTableTranslations.download_csv,
        print: dataTableTranslations.print,
        viewColumns: dataTableTranslations.view_columns,
        filterTable: dataTableTranslations.filter_table,
      },
      filter: {
        all: dataTableTranslations.all_filters,
        title: dataTableTranslations.title_filters,
        reset: dataTableTranslations.reset_filters,
      },
      viewColumns: {
        title: dataTableTranslations.title_view_columns,
        titleAria: dataTableTranslations.title_aria_view_columns,
      },
      selectedRows: {
        text: dataTableTranslations.text_selected_rows,
        delete: dataTableTranslations.delete_selected_rows,
        deleteAria: dataTableTranslations.delete_aria_selected_rows,
      },
    },
  };

  const handleChangeSearchQuery = debounce(
    (value) => setSearchQuery(value),
    500,
  );

  const renderHeaderFilters = (
    <StyledSubtableFilters>
      <Input
        className="add-special-date-input"
        label="Pesquisar locais"
        onChange={(e) => handleChangeSearchQuery(e.target.value)}
        inputOptions={{
          type: 'text',
          name: 'specia-date-title',
        }}
        border
      />

      <SelectMultiple
        name="post-type"
        value={selectedPostStatusFilter}
        label="Status da Postagem"
        options={locationGooglePostsLocationStatus}
        onChange={(target) => setSelectedPostStatusFilter(target)}
        border
        className="filter-content"
      />
    </StyledSubtableFilters>
  );

  return (
    <tr>
      <td colSpan={colspan}>
        <StyledSubTable
          className={className}
          title={renderHeaderFilters}
          data={dataTable}
          columns={dataColumns}
          options={options}
        />
      </td>

      {openActionLogDialog && (
        <ActionLogDialog
          open={openActionLogDialog}
          setOpen={setOpenActionLogDialog}
          logDialogLoading={actionLogDialogLoading}
          postIdToLogVisualize={postIdToLogVisualize}
          setPostIdToLogVisualize={setPostIdToLogVisualize}
          logToVisualize={logToVisualize}
          setLogToVisualize={setLogToVisualize}
        />
      )}
    </tr>

  );
};

export default SubDataTable;
