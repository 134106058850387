import styled from 'styled-components';

export const StyledPageBindedProfile = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 1rem;
    background: #fff;
    border-radius: 0.5rem;
    margin-bottom: 2rem;

    img {
        width: 6rem;
        height: 6rem;
        margin-bottom: 1rem;
    }

    span {
        text-align: center;
    }
`;
