import { createActions, createReducer } from 'reduxsauce';

export const { Types, Creators } = createActions({
  setGoogleLoginData: ['googleLoginData'],
  setGoogleAccessToken: ['googleAccessToken'],
});

const initialState = {
  googleLoginData: {},
  googleAccessToken: '',
};

const reduxOnSetGoogleLoginData = (state = initialState, { googleLoginData }) => (
  { ...state, googleLoginData }
);

const reduxOnSetGoogleAccessToken = (state = initialState, { googleAccessToken }) => (
  { ...state, googleAccessToken }
);

export default createReducer(initialState, {
  [Types.SET_GOOGLE_LOGIN_DATA]: reduxOnSetGoogleLoginData,
  [Types.SET_GOOGLE_ACCESS_TOKEN]: reduxOnSetGoogleAccessToken,
});
