export const menuAttr = {
  name: 'menu',
  hasBorder: true,
  label: 'Cardápio',
};

export const scheduleAttr = {
  name: 'schedule',
  hasBorder: true,
  label: 'Agendamento',
};

export const reservationsAttr = {
  name: 'reservations',
  hasBorder: true,
  label: 'Reservas',
};

export const advanceOrderAttr = {
  name: 'advance-order',
  hasBorder: true,
  label: 'Pedido antecipado',
};
