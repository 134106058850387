import React from 'react';
import { GoogleLogin } from 'react-google-login';
import FacebookLoginBtn from 'react-facebook-login/dist/facebook-login-render-props';
import { isNil } from 'ramda';
import { IconButton, InputSwitch } from '../..';
import { showButtonOptions } from '../helpers';
import {
  StyledLocationConnectionItemWrapper,
  StyledConnectionInfo,
  StyledButtonsWrapper,
  SwitchWrapper,
  StyledLocationName,
  StyledConnectionInfoWrapper,
  StyledLabel,
} from './location-connection-item-styles';

import { LocationConnectionStatusEnum, TLocationConnectionItem } from '../../../types/TPlatformsLinkWithLocation';
import ConnectionStatusIcon from './connection-status-icon';
import { getFacebookPermissions } from '../../../pages/platforms-link-with-location/platforms/facebook/helpers';

const LocationConnectionItem = ({
  connection,
  handleDeleteConnection,
  userProfileName,
  userId,
  handleRefreshExpiredToken,
  handleStatusSwitch,
  platform = 'Google',
}: TLocationConnectionItem) => {
  const [checked, setChecked] = React.useState(true);

  React.useEffect(() => {
    setChecked(connection.status === 'VALID');
  }, [connection]);

  const switchStatus = React.useMemo(() => (
    connection.status === LocationConnectionStatusEnum.VALID
      ? LocationConnectionStatusEnum.STANDBY
      : LocationConnectionStatusEnum.VALID),
  [connection]);

  const showSwitchButton = React.useMemo(
    () => connection.status === LocationConnectionStatusEnum.VALID
    || connection.status === LocationConnectionStatusEnum.STANDBY,
    [connection],
  );

  const showRefreshConnectionButton = React.useMemo(
    () => connection.status === LocationConnectionStatusEnum.REFRESH_TOKEN_EXPIRED,
    [connection],
  );

  const RelogButton = React.useCallback(() => {
    if (platform === 'Google') {
      return (
        <GoogleLogin
          clientId={String(process.env.REACT_APP_GOOGLE_CLIENT_ID)}
          onSuccess={handleRefreshExpiredToken}
          scope="https://www.googleapis.com/auth/business.manage"
          prompt="consent"
          responseType="code"
          uxMode="redirect"
          redirectUri="https://saas.hublocal.com.br/receive-foursquare-code"
          render={(renderProps) => (
            <IconButton tooltip="Atualizar conexão" icon="History" iconSize={3.1} onClick={renderProps.onClick} />
          )}
        />
      );
    }

    return (<></>);
  }, []);

  return (
    <StyledLocationConnectionItemWrapper>
      <ConnectionStatusIcon status={connection.status} iconSize={2.5} />
      <StyledConnectionInfoWrapper>
        <StyledLocationName>{connection.connection_info.location_name}</StyledLocationName>
        <StyledConnectionInfo>{connection.connection_info.user_email}</StyledConnectionInfo>
        <StyledLabel>
          Agrupamento:
          <StyledConnectionInfo>
            {connection.connection_info.grouping_name}
          </StyledConnectionInfo>
        </StyledLabel>
      </StyledConnectionInfoWrapper>
      {showButtonOptions(userProfileName, userId, connection) && (
        <StyledButtonsWrapper>
          {showSwitchButton
            && (
              <SwitchWrapper>
                <InputSwitch checked={checked} changeChecked={() => handleStatusSwitch(connection.id, setChecked, switchStatus)} />
              </SwitchWrapper>
            )}
          {showRefreshConnectionButton
            && (
              <RelogButton />
            )}
          <IconButton tooltip="Deletar conexão" icon="Delete" tooltipVariant="red" isError onClick={() => handleDeleteConnection(connection.id)} iconSize={3.1} />
        </StyledButtonsWrapper>
      )}
    </StyledLocationConnectionItemWrapper>
  );
};

export default LocationConnectionItem;
