import React, { useEffect, useMemo, useState } from 'react';
import { TableBodyCell, TableBodyRow } from '../../../components';
import { TTableRowData } from '../helpers';
import { TRenderCellDataProfileHistoryRow, TServiceProfileHistoryRowColumnsName } from './helpers';

const ProfileHistoryRow = ({ rowData, rowId }: any) => {
  const [currentRowData, setCurrentRowData] = useState<TTableRowData[]>([]);
  useEffect(() => {
    setCurrentRowData(rowData);
  }, [rowData]);

  const renderCellData = useMemo<TRenderCellDataProfileHistoryRow>(
    () => ({
      id: (value) => value,
      created_at: (value) => value,
      ip_address: (value) => value,
      ip_location: (value) => value,
    }),
    [],
  );
  return (
    <TableBodyRow
      key={rowId}
      dataRow={rowData}
      hiddenCheckbox
    >
      {currentRowData.map(
        (dataColumnCell: any) => dataColumnCell?.visible && (
        <TableBodyCell>
          {renderCellData[
            dataColumnCell.columnRef as TServiceProfileHistoryRowColumnsName
          ]?.(dataColumnCell.value)}
        </TableBodyCell>
        ),
      )}
    </TableBodyRow>
  );
};
export default ProfileHistoryRow;
