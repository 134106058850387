import axios from 'axios';

import { TCreateTicket } from '../../types/TTickets';

export default async function createTicket({
  accessToken,
  changedData,
  type,
  location_id,
  zendesk_id = null,
  created_by,
  setTokenLikeExpired = null,
  feedbackMessage = null,
  isSync = null,
}: TCreateTicket) {
  try {
    if (isSync) isSync(true);

    const requestOptions = {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    };

    const { data } = await axios.post(
      `${process.env.REACT_APP_TICKETS_API_URL}`,
      {
        data: changedData,
        type,
        location_id,
        zendesk_id,
        created_by,
      },
      requestOptions,
    );

    if (feedbackMessage) { feedbackMessage('Sincronização iniciada', { variant: 'success' }); }

    return data;
  } catch (err: any) {
    if (err.response?.status === 401 && setTokenLikeExpired) setTokenLikeExpired();

    if (feedbackMessage)feedbackMessage('Erro ao tentar sincronizar', { variant: 'error' });

    return null;
  } finally {
    if (isSync) isSync(false);
  }
}
