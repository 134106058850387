import { TFieldsName } from './types';

export const fieldsName: TFieldsName = {
  infoBasic: {
    name: { order: 1, name: 'Nome do local' },
    phone: { order: 2, name: 'Telefone Principal' },
    secondary_phone: { order: 3, name: 'Telefone Secundário' },
  },
  categories: {
    categoriesGroup: { order: 4, name: 'Agrupamento de Categorias' },
    google_categories: { order: 5, name: 'Categorias do Google' },
    facebook_categories: { order: 6, name: 'Categorias do Facebook' },
    foursquare_categories: { order: 7, name: 'Categorias do Foursquare' },
  },
  addressInfo: {
    zip_code: { order: 8, name: 'CEP' },
    country_code: { order: 9, name: 'País' },
    state_code: { order: 10, name: 'Código do Estado' },
    city: { order: 11, name: 'Cidade' },
    neighborhood: { order: 12, name: 'Bairro' },
    address1: { order: 13, name: 'Logradouro' },
    address2: { order: 14, name: 'Complemento' },
    does_not_serve_at_this_address: { order: 15, name: 'Atende nesse endereço?' },
  },
};

export const infoBasic = [
  'NAME',
  'PHONE',
  'SECONDARY_PHONE',
  'SHORT_DESCRIPTION',
  'LONG_DESCRIPTION',
];

export const categories = [
  'GOOGLE_CATEGORIES',
  'FACEBOOK_CATEGORIES',
  'FOURSQUARE_CATEGORIES',
];

export const addressInfo = [
  'ZIP_CODE',
  'COUNTRY_CODE',
  'ADDRESS1',
  'ADDRESS2',
  'NEIGHBORHOOD',
  'CITY',
  'STATE_CODE',
  'DOES_NOT_SERVE_AT_THIS_ADDRESS',
];

export const links = ['LINKS',
  'WEBSITE',
  'FACEBOOK',
  'INSTAGRAM',
  'TWITTER',
  'YOUTUBE',
  'FOURSQUARE',
  'LINKEDIN',
  'YELP',
  'OTHER_LINKS',
  'EMAIL',
];

export const schedule = [
  'WORKING_HOURS',
];

export const special_dates = [
  'SPECIAL_DATES',
];

export const splitDataIntoSections = array => array.map((fieldData) => {
  if (infoBasic.includes(fieldData.label)) return { ...fieldData, groupingValue: 'infoBasic' };
  if (categories.includes(fieldData.label)) return { ...fieldData, groupingValue: 'categories' };
  if (addressInfo.includes(fieldData.label)) return { ...fieldData, groupingValue: 'addressInfo' };
  if (links.includes(fieldData.label)) return { ...fieldData, groupingValue: 'links' };
  if (schedule.includes(fieldData.label)) return { ...fieldData, groupingValue: 'schedule' };
  if (special_dates.includes(fieldData.label)) return { ...fieldData, groupingValue: 'special_dates' };
}).reduce((accumulator, fieldData) => {
  (accumulator[fieldData.groupingValue] = accumulator[fieldData.groupingValue] || []).push(fieldData);
  return accumulator;
}, {});

export const formatDataToList = (dataToConvert) => {
  if (!dataToConvert) return [];

  return dataToConvert.map((field, index) => {
    let fieldOrder = index;
    let { value } = field.new_data;
    const { status, label } = field;

    if (categories.includes(label)) {
      const names: string[] = [];
      const parsedValue = JSON.parse(value);
      parsedValue.forEach((valueArrayItem) => {
        if (valueArrayItem.name) names.push(valueArrayItem.name);
      });
      value = names.join(', ');
    }
    if (fieldsName[label.toLowerCase()]) {
      fieldOrder = fieldsName[label.toLowerCase()].order;
    }

    return {
      label,
      order: fieldOrder,
      new_data: { label, value },
      status,
    };
  });
};

export const getSwitchValue = {
  APPROVED: true,
  REJECTED: false,
  PENDING: null,
  PROGRESS: null,
};
