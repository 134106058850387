import styled, { css } from 'styled-components';
import { lighten, shade, rgba } from 'polished';
import { ArrowDownward } from '@material-ui/icons';
import { devices } from '../../../../../../assets/styles/devices';

export const StyledTimeOfDay = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: stretch;
  width: 100%;
  background: ${props => props.theme.colors.white};

  @media ${devices.widescreen} {
    flex-direction: row;
    padding-bottom: 0;
  }

  &:nth-child(even) {
    background: ${props => lighten(0.96, props.theme.colors.black)};

    .day-title {
      background: ${props => shade(0.1, props.theme.colors.primary)};
    }
  }

  .button-24h {
    margin-left: 1rem;
  };
`;

export const StyledHoursDayName = styled.span`
  font-size: 1.6rem;
  background: ${props => props.theme.colors.primary};
  color: ${props => props.theme.colors.white};
  padding: 2rem;
  min-width: 15rem;
  align-self: stretch;
  display: flex;
  align-items: center;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;

  .white-label {
    color: ${props => props.theme.colors.white};
  }

  p {
    margin-bottom: 0.5rem;
  }

  @media ${devices.widescreen} {
    flex-direction: column;
    width: unset;
    margin-bottom: 0;
  }

  &:last-child {
    margin-bottom: 0;
  }
`;

export const StyledTimes = styled.div`
  display: flex;
  flex-direction: column;

  ${props => props.isSeveralTimes && css`
    padding: 1rem 0;
  `}
`;

export const StyledTimesRow = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 0.5rem;

  &:last-child {
    margin-bottom: 0;
  }

  .time {
    margin-right: 2rem;

    &:last-of-type {
      margin-right: 0;
    }
  }
  
  .time-row-icon:first-of-type {
    margin-left: 1rem;
  }
`;

export const StyledReplicate = styled.span`
  padding: 0.5rem 1.5rem;
  border-radius: 3rem;
  background: ${props => props.theme.colors.primary};
  color: ${props => props.theme.colors.white};
  font-size: 1.3rem;
  cursor: pointer;
  margin-left: 1rem;
  transition: 0.3s;
  text-align: center;
  
  &:hover {
    transform: scale(1.1);
    background: ${props => shade(0.1, props.theme.colors.primary)};
  }
`;

export const StyledArrowDownward = styled(ArrowDownward)`
  margin-right: 0.5rem;
`;

export const Styled24HourReplicateWrapper = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 1rem;
  align-items: center;

  @media ${devices.widescreen} {
    width: unset;
    margin-top: 0;
  }
`;

export const StyledOverlay = styled.div`
  position: absolute;
  z-index: 1;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: ${props => rgba(props.theme.colors.black, 0.4)};
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const StyledHoursWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  width: 100%;
  padding: 1rem 2rem;
  justify-content: center;
  
  p {
    margin-bottom: 0;
  }
  
  @media ${devices.widescreen} {
    flex-direction: row;
    justify-content: flex-start;
  }
`;
