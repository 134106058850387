import axios from 'axios';

export default async function remove({
  accessToken,
  autoResponseId,
  setTokenLikeExpired,
  setIsFetching,
}) {
  if (setIsFetching) setIsFetching(true);
  try {
    const { data } = await axios.delete(
      `${process.env.REACT_APP_AUTO_RESPONSES_API_URL}/${autoResponseId}`,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      },
    );

    return data;
  } catch (err) {
    if (err.response?.status === 401 && setTokenLikeExpired) setTokenLikeExpired();

    return err;
  } finally {
    setIsFetching(false);
  }
}
