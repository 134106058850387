import * as R from 'ramda';

import APICreateCompany from '../../services/companies/createCompany';
import APIAddCompanies from '../../services/users/addCompanies';
import APIAddResponsibles from '../../services/companies/addResponsibles';

export const saveCompanyInDB = async ({
  userHublocalToken,
  userHublocalID,
  setTokenLikeExpired,
  responsibles,
  companyData,
  setIsCreating,
  feedbackMessage,
  resetCompanyFields,
  redirectTo,
  setActiveCompanyWithNewCompany,
}) => {
  try {
    if (setIsCreating) setIsCreating(true);

    const mainResponsible = R.dissoc('main', responsibles.filter(responsible => responsible.main === true)[0]);
    const responsibleWithoutMain = responsibles.filter(responsible => responsible.main !== true).map(responsible => responsible.id);

    const {
      name, website, is_active, data_studio,
    } = companyData;

    const newCompany = await APICreateCompany({
      accessToken: userHublocalToken,
      name,
      description: '',
      website,
      data_studio,
      main_responsible: mainResponsible,
      is_active,
      created_by: userHublocalID,
      feedbackMessage,
      setTokenLikeExpired,
    });

    if (R.isNil(newCompany)) return;

    if (responsibleWithoutMain.length) {
      await APIAddResponsibles({
        accessToken: userHublocalToken,
        companyId: newCompany.id,
        setTokenLikeExpired,
        responsible_ids: responsibleWithoutMain,
        feedbackMessage,
      });
    }

    const addedCompany = await APIAddCompanies({
      setTokenLikeExpired,
      accessToken: userHublocalToken,
      userId: userHublocalID,
      company_ids: [newCompany.id],
      feedbackMessage,
    });

    if (R.isNil(addedCompany)) return;

    if (resetCompanyFields) resetCompanyFields();

    setActiveCompanyWithNewCompany(newCompany);

    redirectTo();
  } catch (err) {
    feedbackMessage('Não foi possível criar a empresa. Tente novamente!', { variant: 'error' });
  } finally {
    if (setIsCreating) setIsCreating(false);
  }
};
