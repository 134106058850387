import React from 'react';
import { MUIDataTableOptions } from 'mui-datatables';
import { debounce } from 'lodash';

import { Table } from '../../../../components';
import FiltersBar from './filters-bar';
import { ServiceBatchLocationsRow } from './service-batch-locations-row';
import {
  StyledServiceBatchLocationsTable,
  StyledHeader,
} from './service-batch-locations-table-styles';

import { useAuth } from '../../../../hooks';
import { getDataRowCellValue } from '../../helpers';
import {
  initialTableColumns,
  normalizeServiceBatchLocationsToTable,
} from './helpers';

import type { TTableData, TTableRowData } from '../../../../types/TTable';
import type { EBatchPlatformStatus } from '../../../../types/TBatchGoogleModules';

import { findServiceBatchLocations } from '../../../../services/google-batch-modules/batch-services';

export type TServiceBatchesLocationsSubTable = {
  batchId: string;
  serviceBatchRowData: TTableRowData;
  updateBatch: () => Promise<void>;
};

export function ServiceBatchLocationsTable({
  batchId,
  serviceBatchRowData,
  updateBatch,
}: TServiceBatchesLocationsSubTable) {
  const [serviceBatchLocations, setServiceBatchLocations] =
    React.useState<TTableData>([]);
  const [serviceBatchLocationsCount, setServiceBatchLocationsCount] =
    React.useState(0);

  const [page, setPage] = React.useState(0);
  const [pageSize, setPageSize] = React.useState(10);
  const [query, setQuery] = React.useState('');
  const [isLoading, setIsLoading] = React.useState(false);

  const [selectedStatus, setSelectedStatus] = React.useState('');

  const { userAccessToken } = useAuth();

  const status = React.useMemo(
    () =>
      getDataRowCellValue({
        columnRef: 'status',
        dataRowCell: serviceBatchRowData,
      }),
    [serviceBatchRowData]
  );

  const getServiceBatchLocations = React.useCallback(async () => {
    if (status === 'Cancelando') return;

    const serviceBatchLocationsResponse = await findServiceBatchLocations({
      batchId,
      userAccessToken,
      page,
      pageSize,
      query,
      status: selectedStatus as EBatchPlatformStatus,
      isFetching: setIsLoading,
    });

    if (
      serviceBatchLocationsResponse.status !== 'SUCCESS' ||
      !Array.isArray(serviceBatchLocationsResponse.data)
    ) {
      return;
    }

    const [serviceBatchLocations, locationsCount] =
      serviceBatchLocationsResponse.data;

    const serviceBatchLocationsDataNormalized =
      normalizeServiceBatchLocationsToTable(serviceBatchLocations);

    setServiceBatchLocations(serviceBatchLocationsDataNormalized);
    setServiceBatchLocationsCount(locationsCount);
  }, [batchId, status, page, pageSize, query, selectedStatus]);

  React.useEffect(() => {
    getServiceBatchLocations();
  }, [getServiceBatchLocations]);

  const tableOptions = React.useMemo(
    (): MUIDataTableOptions => ({
      count: serviceBatchLocationsCount,
      page,
      selectableRows: 'none',
      search: false,
    }),
    [serviceBatchLocationsCount, page]
  );

  const tableColumns = React.useMemo(
    () => [...initialTableColumns],
    [initialTableColumns]
  );

  const handleSearchWithDebounce = debounce(
    (text: string) => setQuery(text),
    500
  );

  return (
    <StyledServiceBatchLocationsTable>
      {status === 'Cancelando' ? (
        'Processo de cancelamento em andamento...'
      ) : (
        <Table
          loading={isLoading}
          options={tableOptions}
          title={
            (
              <StyledHeader>
                <h3>Locais afetados</h3>
                <FiltersBar
                  selectedStatus={selectedStatus}
                  onChangeSearchQuery={handleSearchWithDebounce}
                  setSelectedStatus={setSelectedStatus}
                />
              </StyledHeader>
            ) as React.ReactNode & string
          }
          columns={tableColumns}
          data={serviceBatchLocations}
          row={(rowData) => (
            <ServiceBatchLocationsRow
              data={rowData}
              batchId={batchId}
              batchLocationId={rowData[0].value}
              updateBatch={updateBatch}
            />
          )}
          setPage={setPage}
          setPageSize={setPageSize}
          setQuery={setQuery}
        />
      )}
    </StyledServiceBatchLocationsTable>
  );
}
