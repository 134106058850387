import axios from 'axios';
import type { TBatchCreateInfos } from '../../types/TBatchUpdate';

export default async function batchCreateInfos({
  accessToken,
  groupType,
  groupId,
  dataInfos,
  locations = null,
  setIsLoading = null,
  setTokenLikeExpired,
  feedbackMessage,
  userData,
}: TBatchCreateInfos) {
  try {
    if (setIsLoading) setIsLoading(true);

    const { data } = await axios.post(
      `${process.env.REACT_APP_GOOGLE_API_URL}/infos/batch`,
      {
        group_type: groupType,
        group_id: groupId,
        data_infos: dataInfos,
        locations,
        user_data: userData,
      },
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      },
    );

    if (data.status === 406) {
      if (feedbackMessage) {
        feedbackMessage(
          'Nenhum local foi selecionado ao iniciar a atualização de informações em massa. Selecione um ou mais locais e tente novamente.',
          { variant: 'warning' },
        );
      }
      return data;
    }

    if (feedbackMessage) {
      feedbackMessage(
        'Atualização de informações em massa iniciada com sucesso',
        { variant: 'success' },
      );
    }

    return data;
  } catch (err: any) {
    if (err.response?.status === 401 && setTokenLikeExpired) setTokenLikeExpired();

    if (feedbackMessage) {
      feedbackMessage(
        'Erro ao atualizar informações em massa, tente novamente!',
        { variant: 'error' },
      );
    }

    return null;
  } finally {
    if (setIsLoading) setIsLoading(false);
  }
}
