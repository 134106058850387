import axios from 'axios';

import { TGetLocation, TLocationEntity } from '../../types/TLocation';

export default async function getLocation({
  accessToken,
  id,
  setIsFetching,
  feedbackMessage,
  setTokenLikeExpired,
}: TGetLocation): Promise<TLocationEntity | null> {
  try {
    if (setIsFetching) setIsFetching(true);
    if (feedbackMessage) feedbackMessage('Buscando local...', { variant: 'warning' });

    const { data } = await axios.get(
      `${process.env.REACT_APP_LOCATIONS_API_URL}/${id}`,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      },
    );

    return data;
  } catch (err: any) {
    const { response } = err;

    if (response?.status === 401 && setTokenLikeExpired) setTokenLikeExpired();
    if (feedbackMessage) feedbackMessage('Erro ao tentar encontrar o local', { variant: 'error' });

    return null;
  } finally {
    if (setIsFetching) setIsFetching(false);
  }
}
