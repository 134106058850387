import api from './api';

const facebookAPIGetCover = async ({ pageID, token }) => {
  try {
    const response = await api({
      method: 'GET',
      url: `${pageID}?fields=photos.limit(1){images,height=600,width=600}`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    const imageSource = await response.photos.data[0].images[0].source;

    return imageSource;
  } catch (err) {
    console.log(err);
  }
};

export default facebookAPIGetCover;
