import React from "react";
import {
  ListItem,
  ListItemText,
  List,
  ListItemSecondaryAction,
  IconButton,
} from "@material-ui/core";
import { Add, Remove } from "@material-ui/icons";

import "./item-board-styles.css";

export default ({
  availableItemText,
  itemsToAdd,
  onAvailableItemClicked,
  addedItemText,
  addedItems,
  onAddedItemClicked,
}) => (
  <div className="items-div">
    <div>
      <label className="font-poppins blue-font">{availableItemText}</label>
      <div className="items-column">
        <List>
          {itemsToAdd.map((element) => (
            <ListItem key={element.id}>
              <ListItemText>
                <text className="font-poppins">
                  {element.full_name ? element.full_name : element.name}
                </text>
              </ListItemText>
              <ListItemSecondaryAction>
                <IconButton
                  onClick={() => {
                    onAvailableItemClicked(element.id);
                  }}
                >
                  <Add />
                </IconButton>
              </ListItemSecondaryAction>
            </ListItem>
          ))}
        </List>
      </div>
    </div>
    <div>
      <label className="font-poppins blue-font">{addedItemText}</label>
      <div className="items-column">
        <List>
          {addedItems.map((element) => (
            <ListItem key={element.id}>
              <ListItemText>
                <text className="font-poppins">{element.full_name ? element.full_name : element.name}</text>
              </ListItemText>
              <ListItemSecondaryAction edge="end">
                <IconButton
                  onClick={() => {
                    onAddedItemClicked(element.id);
                  }}
                >
                  <Remove />
                </IconButton>
              </ListItemSecondaryAction>
            </ListItem>
          ))}
        </List>
      </div>
    </div>
  </div>
);
