import React from 'react';

import {
  StyledSwipeableViews,
} from './swipeable-views-styles';

const SwipeableViews = ({
  children,
  activeTab,
  handleChangeIndex,
}) => (
  <StyledSwipeableViews
    axis="x"
    index={activeTab}
    onChangeIndex={handleChangeIndex}
  >
    {children}
  </StyledSwipeableViews>
);

export default SwipeableViews;
