import axios from 'axios';

export default async function completeVerification(
  accessToken,
  userId,
  locationId,
  verificationId,
  pinCode,
) {
  const requestOptions = {
    headers: {
      'access-token': accessToken,
    },
  };

  try {
    const data = await axios.post(
      `${process.env.REACT_APP_GOOGLE_API_URL}/complete/verification/process/${userId}/${locationId}`,
      {
        verificationId,
        pinCode,
      },
      requestOptions,
    );

    return data;
  } catch (err) {
    console.log(err.response);
    throw err;
  }
}
