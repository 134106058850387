import styled, { css } from "styled-components";
import { Link } from "react-router-dom";

import { lighten, shade } from "polished";

import { devices } from "../../assets/styles/devices";

export const StyledMenuMobile = styled.div`
  transition: 0.3s;
  width: 30rem;
  margin-left: -30rem;
  background: ${props => lighten(0.15, props.theme.colors.black)};
  display: flex;
  flex-direction: column;

  @media ${devices.tabletLandscape} {
    display: none;
  };

  ${props => props.open && css`
    margin-left: 0;
    width: 25rem;
  `}
`;

export const StyledHeader = styled.header`
  height: 7rem;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 1rem 2rem;
  color: ${props => props.theme.colors.white};
  background: ${props => lighten(0.1, props.theme.colors.black)};
`;


export const StyledUser = styled.div`
  width: 5rem;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 700;
    background: ${props => props.theme.colors.white};
    border-radius: 0.5rem;
    margin-right: 2rem;
    color: ${props => props.theme.colors.primary};
    font-size: 2.5rem;
`;

export const StyledLink = styled(Link)`
  display: flex;
  align-items: center;
  margin-right: auto;
  color: ${props => props.theme.colors.white};
  height: 100%;
  padding-right: 2rem;
  border-radius: 0.5rem;
  transition: 0.4s;
  background: transparent;

  &:hover,
  &:focus {
    text-decoration: none;
    background: ${props => shade(0.1, props.theme.colors.white)};

    .user-name {
      color: ${props => props.theme.colors.primary};
    }
  }
`;

export const StyledName = styled.div``;

export const StyledLinks = styled.nav`
  width: 100%;
  padding: 0;
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow-y: auto;
`;