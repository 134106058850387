import styled from 'styled-components';
import { Paper } from '@material-ui/core';

import { lighten } from 'polished';

export const StyledConfigCard = styled(Paper)`
    display: flex;
    flex-direction: column;
    border-radius: 0.5rem;
    overflow: hidden;
    border: 1px solid ${props => lighten(0.2, props.theme.colors.grey)};
    height: fit-content;
`;

export const StyledHeader = styled.div`
    display: flex;
    width: 100%;
    background: ${props => props.theme.colors.white};
    padding: 2rem;

    h3 {
        font-weight: 700;
        font-size: 1.6rem;
        margin-bottom: 0;
    }
`;

export const StyledButtonsContainer = styled(StyledHeader)`
    display: flex;
    flex-direction: column;
    padding: 2rem;
`;

export const StyleContent = styled.div`
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
    background: ${props => props.theme.page.body};
    font-size: 1.4rem;
`;
