import axios from 'axios';
import { TDeleteLocationConnection } from '../../types/TPlatformsLinkWithLocation';

export default async function deleteLocationConnection({
  accessToken,
  id,
  setTokenLikeExpired,
}: TDeleteLocationConnection) {
  try {
    const { data } = await axios.delete(
      `${process.env.REACT_APP_LOCATION_CONNECTIONS}/${id}`,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      },
    );

    return data;
  } catch (err: any) {
    if (err.response?.status === 401 && setTokenLikeExpired) setTokenLikeExpired();

    return null;
  }
}
