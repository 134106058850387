import React from 'react';
import { Button } from '../../components';

import noAccesIllustration from '../../assets/images/no-access.png';

import {
  StyledNoAcess,
} from './no-acess-styles';

const NoAcess = () => (
  <StyledNoAcess>
    <img
      className="no-acess-illustration"
      src={noAccesIllustration}
      alt="Sem acesso"
    />

    <h2>Seu acesso a esse recurso ainda não foi liberado!</h2>
    <p>
      Aguarde algum operador de suporte liberar o seu acesso ou
      entre em contato conosco.
    </p>
    <a
      style={{ textDecoration: 'none' }}
      href="https://hublocations.movidesk.com/form/5557/"
      target="_blank"
      rel="noreferrer"
    >
      <Button>Fale conosco</Button>
    </a>
  </StyledNoAcess>
);

export default NoAcess;
