import axios from 'axios';

import type { EGroupType } from '../../types/TLocation';

export type TGetAllAccountIdsByCompanyOrLocationGroup = {
  accessToken: string;
  groupId: number;
  groupType: EGroupType;
  setIsLoading?: React.Dispatch<React.SetStateAction<boolean>>;
  setTokenLikeExpired?: (() => void) | null;
};

export default async function getAllAccountIdsByCompanyOrLocationGroup({
  accessToken,
  groupId,
  groupType,
  setIsLoading,
  setTokenLikeExpired,
}: TGetAllAccountIdsByCompanyOrLocationGroup): Promise<{googleAccountId: string, refreshToken: string}[] | null> {
  try {
    if (setIsLoading) setIsLoading(true);

    const { data } = await axios.get(
      `${process.env.REACT_APP_LOCATIONS_API_URL}/google-accounts/${groupId}`,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
        params: {
          groupType,
        },
      },
    );

    return data;
  } catch (err: any) {
    if (err.response?.status === 401 && setTokenLikeExpired) { setTokenLikeExpired(); }

    return null;
  } finally {
    if (setIsLoading) setIsLoading(false);
  }
}
