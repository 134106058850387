export const websiteAttr = {
  name: 'website',
  hasBorder: true,
  label: 'Website',
};

export const facebookAttr = {
  name: 'facebook',
  hasBorder: true,
  label: 'Facebook',
};

export const instagramAttr = {
  name: 'instagram',
  hasBorder: true,
  label: 'Instagram',
};

export const twitterAttr = {
  name: 'twitter',
  hasBorder: true,
  label: 'Twitter',
};

export const youtubeAttr = {
  name: 'youtube',
  hasBorder: true,
  label: 'Youtube',
};

export const foursquareAttr = {
  name: 'foursquare',
  hasBorder: true,
  label: 'Foursquare',
};

export const linkedinAttr = {
  name: 'linkedin',
  hasBorder: true,
  label: 'Linkedin',
};

export const yelpAttr = {
  name: 'yelp',
  hasBorder: true,
  label: 'Yelp',
};

export const googlemapsAttr = {
  name: 'googlemaps',
  hasBorder: true,
  label: 'Google Maps',
};

export const wazerAttr = {
  name: 'wazer',
  hasBorder: true,
  label: 'Wazer',
};
