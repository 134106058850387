const getAddressNumber = (address: string) => {
  const formattedNumber = String(address).trim().split(/\s/g);

  return formattedNumber[formattedNumber.length - 1];
};

const getAddressName = (address: string) => {
  const formattedAddress = address.replace(/[,|]/gi, '').split(/\s/g);

  let completeAddress = '';

  formattedAddress.forEach((e, index) => {
    if (index !== formattedAddress.length - 1) { completeAddress += ` ${e}`; }
  });

  return completeAddress.trim();
};

export const formattedAddress = (
  address1: string,
) => [getAddressName(address1), getAddressNumber(address1)];

export const zipcodeAttr = {
  name: 'zip_code',
  type: 'text',
  label: 'CEP',
  description: 'CEP da localidade',
  hasBorder: true,
  required: true,
  hasMask: true,
};

export const countryCodeAttr = {
  name: 'country',
  type: 'text',
  label: 'País*',
  description: 'Identificação do país da localidade',
  hasBorder: true,
  defaultValue: 'Brazil',
  disabled: true,
};

export const addressAttr = {
  name: 'address1',
  type: 'text',
  label: 'Logradouro',
  description: 'Endereço da localidade',
  hasBorder: true,
  required: true,
};

export const addressNumberAttr = {
  name: 'address-number',
  type: 'text',
  label: 'Número do Logradouro',
  description: 'Número da localidade',
  hasBorder: true,
  required: true,
};

export const addressComplementAttr = {
  name: 'address2',
  type: 'text',
  label: 'Complemento',
  description: 'Detalhes adicionais sobre o endereço da localidade',
  hasBorder: true,
};

export const neighborhoodAttr = {
  name: 'neighborhood',
  type: 'text',
  label: 'Bairro',
  description: 'Bairro da localidade',
  hasBorder: true,
  required: true,
};

export const cityAttr = {
  name: 'city',
  type: 'text',
  label: 'Cidade',
  description: 'Cidade da localidade',
  hasBorder: true,
  required: true,
};

export const stateCodeAttr = {
  name: 'state',
  type: 'text',
  label: 'Código do Estado',
  description: 'Estado da localidade, em formato sigla',
  hasBorder: true,
  required: true,
  maxLength: 2,
  uppercase: true,
};
