import React from 'react';
import { isEmpty } from 'lodash';
import { isNil } from 'ramda';
import { useSelector } from 'react-redux';
import LocationConnectionItem from './location-connection-item';
import { StyledNoLinkWrapper, StyledLocationConnectionListContainer } from './location-connection-list-styles';
import { LocationConnectionStatusEnum, TLocationConnectionList } from '../../types/TPlatformsLinkWithLocation';
import APIUpdateLocationConnection from '../../services/location-connections/updateLocationConnection';
import APIGenerateRefreshToken from '../../services/google/generateRefreshToken';
import APIGetLocationConnections from '../../services/location-connections/getAllLocationConnectionsFromLocation';
import { getAccountData } from '../../services/google/getAccountData';
import type { TRootStateRedux } from '../../types/TRootStateRedux';
import APIDeleteLocationConnection from '../../services/location-connections/deleteLocationConnection';
import isNilOrEmpty from '../../utils/is-null-or-empty';

const LocationConnectionList = ({
  userAccessToken,
  userProfileName,
  userId,
  setLocationConnectionList,
  locationConnections,
  getLocationConnections,
  platform = 'Google',
}: TLocationConnectionList) => {
  const { activeLocation } = useSelector((state: TRootStateRedux) => state.LocationReducer);

  const handleDeleteConnection = React.useCallback(async (locationConnectionId: string) => {
    const deleteLocationConnectionResponse = await APIDeleteLocationConnection({ accessToken: userAccessToken, id: locationConnectionId });

    if (isNilOrEmpty(deleteLocationConnectionResponse)) return;

    setLocationConnectionList(prev => prev.filter((connections) => connections.id !== locationConnectionId));
  }, []);

  const handleRefreshExpiredToken = React.useCallback(async (loginResponse: any) => {
    const { code } = loginResponse;

    const tokenData = await APIGenerateRefreshToken({ authorizationCode: code });

    const {
      access_token: responseAccessToken,
      refresh_token: refreshToken,
      expires_in: expiresIn,
    } = tokenData;

    if (isNil(refreshToken)) return;

    const googleProfile = await getAccountData({ accessToken: responseAccessToken });

    const tokenExpirationDate = new Date();

    tokenExpirationDate.setSeconds(tokenExpirationDate.getSeconds() + expiresIn);

    const getExpiredLocationConnectionResponse = await APIGetLocationConnections({
      accessToken: userAccessToken,
      locationId: activeLocation.id,
      status: LocationConnectionStatusEnum.REFRESH_TOKEN_EXPIRED,
    });

    const [expiredLocationConnection] = getExpiredLocationConnectionResponse;

    if (isEmpty(expiredLocationConnection)) return;

    const updateLocationConnectionResponse = await APIUpdateLocationConnection({
      accessToken: userAccessToken,
      id: expiredLocationConnection[0].id,
      userId,
      token: responseAccessToken,
      code,
      tokenExpiresAt: tokenExpirationDate.toUTCString(),
      refreshToken,
      status: LocationConnectionStatusEnum.VALID,
      connectionInfo: {
        ...expiredLocationConnection[0].connection_info,
        google_account_id: googleProfile.id,
        user_email: googleProfile.email,
      },
    });
    if (isNil(updateLocationConnectionResponse)) return;
    getLocationConnections();
  }, []);

  const handleStatusSwitch = React.useCallback(async (
    connectionId: string,
    setChecked: React.Dispatch<React.SetStateAction<boolean>>,
    status: LocationConnectionStatusEnum) => {
    setChecked(prev => !prev);

    const updateLocationConnectionResponse = await APIUpdateLocationConnection({
      accessToken: userAccessToken, id: connectionId, userId, status,
    });

    if (isNil(updateLocationConnectionResponse)) return setChecked(prev => !prev);
    getLocationConnections();
  }, []);

  return (
    <StyledLocationConnectionListContainer>
      {!isEmpty(locationConnections) ? locationConnections.map((locationConnection) => (
        <LocationConnectionItem
          key={locationConnection.id}
          connection={locationConnection}
          userProfileName={userProfileName}
          userId={userId}
          handleStatusSwitch={handleStatusSwitch}
          handleDeleteConnection={handleDeleteConnection}
          handleRefreshExpiredToken={handleRefreshExpiredToken}
          platform={platform}
        />
      ))
        : (
          <StyledNoLinkWrapper>
            <b>Esse local ainda não possui nenhuma conexão.</b>
            <p>
              Adicione uma conexão com um local do
              {` ${platform} `}
              para usufruir de mais funcionalidades.
            </p>
          </StyledNoLinkWrapper>
        )}
    </StyledLocationConnectionListContainer>
  );
};

export default LocationConnectionList;
