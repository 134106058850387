import * as Ramda from 'ramda';
import { map } from 'lodash';
import api from './api';

import mountHours from '../../utils/mount-hours';

const facebookAPIGetCover = async ({
  pageID,
  pageToken,
  payloadData,
  setIsFetching,
  tokenValidate,
}) => {
  const {
    snackbar,
    setConnected,
    setProfileHeader,
    resetSetFacebookData,
    FBLogout,
  } = tokenValidate;

  try {
    if (setIsFetching) setIsFetching(true);

    const {
      website,
      short_description: about,
      email,
      city,
      country,
      zip_code: zip,
      address1: street,
      state,
      phone,
      working_hours: workingHours,
      facebook_categories,
    } = payloadData;

    const hours = mountHours(workingHours);

    let dataToSync = {};
    let location = {};
    const facebookCategoriesID = facebook_categories ? JSON.parse(facebook_categories).map(category => category.id) : null;

    if (city && country && zip && state && street) {
      location = {
        city, country, zip, street, state,
      };
    }

    if (website) dataToSync = { ...dataToSync, website };
    if (about) dataToSync = { ...dataToSync, about };
    if (email) dataToSync = { ...dataToSync, emails: [email.trim()] };
    if (phone.trim()) dataToSync = { ...dataToSync, phone: `+55${phone.replace(/[^A-Z0-9]/ig, '')}` };
    if (!Ramda.isNil(hours)) dataToSync = { ...dataToSync, hours };
    if (!Ramda.isEmpty(facebook_categories)) dataToSync = { ...dataToSync, category_list: facebookCategoriesID };
    if (!Ramda.isNil(location)) dataToSync = { ...dataToSync, location };

    const response = await api({
      method: 'POST',
      url: `${pageID}`,
      data: dataToSync,
      headers: {
        Authorization: `Bearer ${pageToken}`,
      },
    });

    return response;
  } catch (err) {
    const { response } = err;

    if (response?.status === 401) {
      snackbar('Seu acesso a plataforma do Facebook expirou! Faça login novamente.', { variant: 'error' });
      setConnected(false);
      setProfileHeader(null);
      resetSetFacebookData();
      FBLogout();
    }

    if (response?.status === 400) snackbar(`${response?.data?.error?.error_user_msg}`, { variant: 'error' });

    console.log(err);
  } finally {
    if (setIsFetching) setIsFetching(false);
  }
};

export default facebookAPIGetCover;
