import styled from 'styled-components';
import { shade } from 'polished';

import { devices } from '../../../assets/styles/devices';

export const StyledFilter = styled.form`
  display: flex;
  flex-direction: column;
  padding: 2rem;
  border: 0.1rem solid ${(props) => shade(0.5, props.theme.colors.white)};
  border-radius: 0.5rem;
  margin-bottom: 2rem;
`;

export const StyledTitle = styled.h2`
  font-size: 1.9rem;
  font-weight: bold;
  margin-bottom: 3rem;
`;

export const StyledFields = styled.div`
  display: flex;
  flex-direction: column;
`;

export const StyledDataPickerWrap = styled.div`
  display: flex;
  flex-direction: column;

  @media ${devices.tabletLandscape} {
    flex-direction: row;
  }

  .filter-date-picker {
    width: 100%;
    margin-bottom: 2rem;

    @media ${devices.tabletLandscape} {
      margin-bottom: 0;
      margin-right: 2rem;
    }

    &:last-child {
      margin-bottom: 0;
      margin-right: 0;
    }
  }
`;
