import React from 'react';

import Button from '../button';
import Loading from '../loading';

import {
  StyledBlockWithSaveButton,
} from './block-with-action-button-styles';

const BlockWithActionButton = ({
  title,
  titleButton,
  loading,
  onClick,
}) => (
  <StyledBlockWithSaveButton>
    {title && <h3>{title}</h3>}
    <Button
      className="button"
      onClick={onClick}
      disabled={loading}
    >
      {loading ? <Loading /> : titleButton}
    </Button>
  </StyledBlockWithSaveButton>
);

export default BlockWithActionButton;
