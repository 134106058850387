import React from 'react';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { ThemeProvider } from 'styled-components';
import { SnackbarKey, SnackbarProvider } from 'notistack';
import { IconButton } from '@material-ui/core';
import { StylesProvider } from '@material-ui/core/styles';
import { Close as CloseIcon } from '@material-ui/icons';

import { store, persistor } from './redux';
import Routes from './routes';
import { light } from './assets/styles/themes';
import GlobalStyle from './assets/styles/global';
import ContactWidget from './components/contact-widget';

function App() {
  const snackbarProviderRef = React.createRef<SnackbarProvider>();
  const onClickDismissSnackbar = (key: SnackbarKey) => snackbarProviderRef?.current?.closeSnackbar(key);

  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <ThemeProvider theme={light}>
          <GlobalStyle />
          <StylesProvider injectFirst>
            <SnackbarProvider
              preventDuplicate
              classes={{
                containerRoot: 'snackbar-provider-custom',
              }}
              hideIconVariant={false}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'center',
              }}
              maxSnack={3}
              ref={snackbarProviderRef}
              action={(key: SnackbarKey) => (
                <IconButton onClick={() => onClickDismissSnackbar(key)}>
                  <CloseIcon style={{ color: '#FFF' }} />
                </IconButton>
              )}
            >
              <Routes />
              <ContactWidget />
            </SnackbarProvider>
          </StylesProvider>
        </ThemeProvider>
      </PersistGate>
    </Provider>
  );
}

export default App;
