import axios from 'axios';

export default async function signUpClientUser(
  {
    name,
    email,
    google_refresh_token,
    company_group_limit,
    location_group_limit,
    company_limit,
    location_limit,
    created_by = null,
    accessToken,
  },
) {
  try {
    const requestOptions = {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    };

    const { data } = await axios.post(
      `${process.env.REACT_APP_AUTH_API_URL}/signup-client`,
      created_by
        ? {
          name,
          email,
          google_refresh_token,
          company_group_limit,
          location_group_limit,
          company_limit,
          location_limit,
          created_by,
        }
        : {
          name,
          email,
          google_refresh_token,
          company_limit,
          company_group_limit,
          location_limit,
          location_group_limit,
        },
      requestOptions,
    );
    return data;
  } catch (err) {
    throw err.response;
  }
}
