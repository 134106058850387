import styled, { css } from 'styled-components';
import { lighten, shade } from 'polished';

import { devices } from '../../../assets/styles/devices';

export const StyledTicketOpened = styled.div`
    width: 100%;
    margin: 0 0 0 0.5rem;
    position: relative;
`;

export const StyledTitle = styled.div`
    display: flex;
    align-items: center;
    padding: 2rem 3rem;
    background: ${props => props.theme.colors.white};
    width: 100%;
    height: 11.5rem;
    margin-bottom: 0.2rem;
    justify-content: space-between;

    .close-icon-button {
        margin-left: 1rem;

        @media ${devices.desktop} {
            margin-left: 2rem;
        }
    }

    .close-icon {
        width: 3rem;
        height: 3rem;
    }

    .ticket-name {
        text-align: left;
        font-size: 1.5rem;
        margin-bottom: 0;
        margin-right: auto;
        max-width: 80rem;

        @media ${devices.desktop} {
            font-size: 2rem;
        }
    }
`;

export const StyledTicketStatusTag = styled.span`
    padding: 1rem 2rem;
    background: ${props => props.theme.colors.secondary};
    color: ${props => props.theme.colors.white};
    font-weight: bold;
    text-transform: uppercase;
    border-radius: 3rem;
    margin-right: 2rem;
    font-size: 1rem;
    text-align: center;

    ${props => props.status === 'in_progress' && css`
        background: ${props.theme.colors.primary};
    `}

    ${props => props.status === 'open' && css`
        background: ${props.theme.colors.green};
    `}

    ${props => props.status === 'done' && css`
        background: ${props.theme.colors.grey};
    `}

    @media ${devices.desktop} {
        padding: 1rem 3rem;
        font-size: 1.2rem;
    }
`;

export const StyledContent = styled.div`
    display: flex;
    flex-direction: column;
`;

export const StyledInstructions = styled.div`
    display: flex;
    padding: 2rem;
    margin: 1rem 0;
    background: ${props => lighten(0.4, props.theme.colors.red)};
    color: ${props => shade(0.5, props.theme.colors.red)};
`;

export const StyledReOpenTicket = styled.div`
    max-width: 25rem;
    border-radius: 3rem;
    background: ${props => props.theme.colors.secondary};
    padding: 1rem;
    font-weight: bold;
    margin: 1rem auto;
    display: flex;
    align-items: center;
    justify-content: center;
    color: ${props => props.theme.colors.white};
    cursor: pointer;
    transition: 0.3s;
    max-height: 4rem;

    &:hover {
        background: ${props => shade(0.2, props.theme.colors.secondary)};        
        transform: scale(1.1);
    }

    ${props => props.isReOpenTicket && css`
        background: ${shade(0.2, props.theme.colors.secondary)};        
        transform: scale(1.1);
    `}

    .reopen-ticket-icon {
        width: 2rem;
        height: 2rem;
        margin: 0 0.5rem;
    }
`;
