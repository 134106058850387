import React from 'react';
import { useMediaQuery } from 'react-responsive';
import { useSelector, useDispatch } from 'react-redux';
import { debounce } from 'lodash';
import * as R from 'ramda';
import { Helmet } from 'react-helmet';

import { Creators as AuthActions } from '../../redux/ducks/auth';

import { HeaderToolbar, Loading } from '../../components';

import Search from './search';
import Filters from './filters';
import TicketsList from './tickets-list';
import TicketOpened from './ticket-opened';

import { getFilterStatus, hasOperators } from './helpers';

import {
  StyledTickets,
  StyledContent,
  StyledAside,
  StyledRefresh,
  StyledIconRefreshTickets,
} from './tickets-styles';

import getAllTickets from '../../services/tickets/getAll';
import getAllAdminsAndOperators from '../../services/users/getAllAdminsAndOperators';

const Tickets = () => {
  const [page, setPage] = React.useState(0);
  const [pageSize, setPageSize] = React.useState(10);
  const [pagesQuantity, setPagesQuantity] = React.useState(0);
  const [query, setQuery] = React.useState('');
  const [tickets, setTickets] = React.useState([]);
  const [isLoadingList, setIsLoadingList] = React.useState(false);
  const [currentTicketId, setCurrentTicketId] = React.useState(null);
  const [statusFilter, setStatusFilter] = React.useState({
    open: false,
    in_progress: false,
    done: false,
  });
  const [amounts, setAmounts] = React.useState({ open: 0, in_progress: 0, done: 0 });
  const [operatorsList, setOperatorsList] = React.useState([]);
  const [ticketHasBeenUpdated, setTicketHasBeenUpdated] = React.useState(false);

  const reduxDispatch = useDispatch();

  const { user } = useSelector(state => state.AuthReducer);
  const { accessToken } = user;

  const isTabletLandscape = useMediaQuery({ minWidth: 900 });

  const setTokenLikeExpired = () => {
    reduxDispatch(AuthActions.setUser({ ...user, accessToken: 'expired' }));
  };

  const getAmounts = React.useCallback(async () => {
    const openTickets = await getAllTickets({
      accessToken,
      page,
      pageSize,
      query,
      setIsFetching: setIsLoadingList,
      status: 'OPEN',
    });

    const inProgressTickets = await getAllTickets({
      accessToken, page, pageSize, query, setIsFetching: setIsLoadingList, status: 'IN_PROGRESS',
    });

    const doneTickets = await getAllTickets({
      accessToken,
      page,
      pageSize,
      query,
      setIsFetching: setIsLoadingList,
      status: 'DONE',
    });

    setAmounts({
      open: openTickets[1],
      in_progress: inProgressTickets[1],
      done: doneTickets[1],
    });
  }, [query]);

  const getTickets = React.useCallback(async () => {
    const getAllTicketsParams = {
      accessToken,
      page,
      pageSize,
      query,
      setIsFetching: setIsLoadingList,
      setTokenLikeExpired,
    };

    if (
      (!statusFilter.open && !statusFilter.in_progress && !statusFilter.done)
      || ticketHasBeenUpdated
    ) {
      const ticketsResponse = await getAllTickets(getAllTicketsParams);

      setPagesQuantity(Math.ceil(ticketsResponse[1] / pageSize));
      setTickets(ticketsResponse[0]);
      setTicketHasBeenUpdated(false);
    } else {
      let ticketsListing = [];

      R.forEachObjIndexed(async (statusValue, status) => {
        if (statusValue) {
          const ticketsResponse = await getAllTickets({
            ...getAllTicketsParams,
            status: getFilterStatus[status],
          });

          ticketsListing = [...ticketsListing, ...ticketsResponse[0]];
          setTickets(ticketsListing);
          setPagesQuantity(Math.ceil(ticketsResponse[1] / pageSize));
        }
      }, statusFilter);
    }
  }, [statusFilter, query, page, ticketHasBeenUpdated]);

  const getOperators = React.useCallback(async () => {
    const operators = await getAllAdminsAndOperators({
      accessToken,
      query: '',
      setTokenLikeExpired,
    }) || null;

    if (R.isNil(operators)) return;
    if (!hasOperators(operators[0])) return;

    const formatOperators = operators[0]
      .map(element => ({ name: element.name, value: element.id }));

    setOperatorsList(formatOperators);
  }, []);

  React.useEffect(() => { getTickets(); }, [getTickets]);
  React.useEffect(() => { getAmounts(); }, [getAmounts]);
  React.useEffect(() => { getOperators(); }, [getOperators]);

  const handleChangeQuery = debounce((text) => setQuery(text), 500);

  const handleSelectTicket = (ticketId) => {
    setCurrentTicketId(null);

    if (isTabletLandscape) {
      const activeTicket = tickets.filter(ticket => ticket.id === ticketId)[0];
      setCurrentTicketId(activeTicket.id);
    }
  };

  const handleUpdateTicketsListAndAmount = () => {
    setCurrentTicketId(null);
    getTickets();
    getAmounts();
  };

  return (
    <StyledTickets>
      <Helmet>
        <meta property="og:title" content="Tickets - SaaS Hublocal" />
        <meta property="og:description" content="Gerenciamento de Tickets." />

        <title>Tickets - SaaS Hublocal</title>
      </Helmet>
      <HeaderToolbar title="Tickets" />
      <StyledContent>
        <StyledAside>
          <StyledRefresh
            onClick={handleUpdateTicketsListAndAmount}
            isRefresh={isLoadingList}
          >
            {isLoadingList ? (
              <>
                <Loading className="loading-update" />
                Atualizando...
              </>
            ) : (
              <>
                <StyledIconRefreshTickets />
                {' '}
                Atualizar
              </>
            )}
          </StyledRefresh>
          <Search handleOnChangeInput={e => handleChangeQuery(e.target.value)} />
          <Filters
            filterValues={statusFilter}
            setFilterValues={setStatusFilter}
            openAmount={amounts.open}
            inProgressAmount={amounts.in_progress}
            doneAmount={amounts.done}
          />

          <TicketsList
            isLoading={isLoadingList}
            handleSelectTicket={handleSelectTicket}
            dataTickets={tickets}
            page={page}
            setPage={setPage}
            pageSize={pageSize}
            setPageSize={setPageSize}
            pagesQuantity={pagesQuantity}
          />

        </StyledAside>

        {(currentTicketId && isTabletLandscape) && (
          <TicketOpened
            currentPlatformUser={user}
            setTokenLikeExpired={setTokenLikeExpired}
            onCloseTicket={() => { setCurrentTicketId(null); }}
            accessToken={accessToken}
            ticketId={currentTicketId}
            operatorsList={operatorsList}
            getTickets={getTickets}
            setTicketHasBeenUpdated={setTicketHasBeenUpdated}
            getAmounts={getAmounts}
          />
        )}
      </StyledContent>
    </StyledTickets>
  );
};

export default Tickets;
