import React from 'react';
import { debounce } from 'lodash';

import * as R from 'ramda';
import Invite from './invite';

import Input from '../../input';
import Pagination from '../../pagination/pagination';

import {
  StyledPendingInvites,
  StyledTitle,
  StyledInvites,
} from './pending-invites-styles';

const PendingInvites = ({
  pendingInvitesQuantity,
  pendingInvitesPage,
  setPendingInvitesPage,
  setPendingInvitesInputSearch,
  data,
  pendingInvitesInputSearch,
  removeInvite,
  resendInviteEmail,
  loading,
}) => {
  const handleSearch = debounce(text => {
    setPendingInvitesInputSearch(text);
    setPendingInvitesPage(0);
  }, 500);

  return (
    <StyledPendingInvites>
      <StyledTitle>
        <h4>Convites enviados:</h4>
      </StyledTitle>

      <Input
        onChange={(e) => handleSearch(e.target.value)}
        disabled={pendingInvitesQuantity <= 1 && R.isEmpty(pendingInvitesInputSearch)}
        border
        className="pending-invites-input-search"
      />

      <StyledInvites>
        {data.map(({
          email: pendingInvitationEmail,
          id: pendingInvitationId,
        }) => (
          <Invite
            key={pendingInvitationId}
            email={pendingInvitationEmail}
            loading={loading}
            onDeleteInviteClicked={() => removeInvite(pendingInvitationId)}
            onResendEmailClicked={() => resendInviteEmail(pendingInvitationId)}
          />
        ))}
      </StyledInvites>

      {(!R.isEmpty(data) && pendingInvitesQuantity > 1) && (
      <Pagination
        className="pending-invites-pagination"
        page={pendingInvitesPage}
        pagesQuantity={pendingInvitesQuantity}
        setPage={setPendingInvitesPage}
      />
      )}
    </StyledPendingInvites>
  );
};

export default PendingInvites;
