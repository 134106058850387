import styled from 'styled-components';

export const StyledForgotPassword = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 40rem;
    width: 100%;
    margin: auto;
    padding: 0 2rem;

    img {
        max-width: 16.1rem;
        margin-bottom: 2rem;
    }

    .button-submit {
        margin-top: 2rem;
        width: 100%;
    }

    .feedBackMessage {
        margin-bottom: 2rem;
        font-size: 1.2rem;
    }

    .input-email {
      &,
      input {
        width: 100%; 
      }
    }
`;

export const StyledForm = styled.form`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
`;
