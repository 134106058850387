import { TDefaultPropOption } from '../../types/TSelect';

export const starFilterTypes: TDefaultPropOption[] = [
  {
    id: 1,
    name: 'Classificação',
    value: 'Classificação',
  },
  {
    id: 2,
    name: 'Maior que',
    value: 'Maior que',
  },
  {
    id: 3,
    name: 'Menor que',
    value: 'Menor que',
  },
];

export const platformTypes: TDefaultPropOption[] = [
  {
    id: 1,
    name: 'Google',
    value: 'Google',
  },
];

export const reviewTypes: TDefaultPropOption[] = [
  {
    id: 1,
    name: 'Todas as avaliações',
    value: 'Todas as avaliações',
  },
  {
    id: 2,
    name: 'Respondidas',
    value: 'Respondidas',
  },
  {
    id: 3,
    name: 'Não Respondidas',
    value: 'Não Respondidas',
  },
];

export const LocationList: TDefaultPropOption[] = [
  {
    id: 1,
    name: '4Pump Suplementos Center Natal: Creatina, Whey Protein, Proteínas em Natal RN, Se não os nomes...',
    value: '4Pump Suplementos Center Natal: Creatina, Whey Protein, Proteínas em Natal RN, Se não os nomes...',
  },
];

export const reviewOrderTypes: TDefaultPropOption[] = [
  {
    id: 1,
    name: 'Mais Recentes',
    value: 'Mais Recentes',
  },
  {
    id: 2,
    name: 'Mais Antigas',
    value: 'Mais Antigas',
  },
];
