import styled from 'styled-components';
import { Dialog } from '@material-ui/core';

export const StyledDialog = styled(Dialog)`

    .dialog-title{
        font-size: 2rem;
        font-family: Poppins;
    };

    .container{
        margin: 1rem;
        justify-content: center;
        align-items: center;
        display: flex;
        flex-direction: column;
        gap: 1rem;
        width: 95%;
    }
`;
