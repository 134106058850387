export const fieldResponsibleName = {
  name: 'responsible-name',
  nickname: 'Nome do responsável',
  type: 'text',
  required: true,
  hasborder: 'yes',
  label: 'Nome do responsável',
};

export const fieldResponsiblePhone = {
  name: 'responsible-phone',
  type: 'text',
  required: true,
  hasborder: 'yes',
  label: 'Telefone do responsável',
  mask: '99 99999-9999',
  phone: true,
  nickname: 'Telefone do responsável',
};

export const fieldResponsibleEmail = {
  name: 'responsible-email',
  type: 'text',
  required: true,
  hasborder: 'yes',
  nickname: 'E-mail do responsável',
  label: 'E-mail do responsável',
  email: true,
};
