import React from 'react';

import { Paper, IconButton } from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { Typeahead } from 'react-bootstrap-typeahead';

import { useCreateLinkModel } from './hook';

import {
  CompanyNavBar,
  CompanyInfoNavBar,
} from '../../components';

const CreateLinkModel = () => {
  const {
    form,
    renderButton,
    handleChangeForm,
    otherLinks,
    setOtherLinks,
    onBackArrowClicked,
  } = useCreateLinkModel();

  return (
    <div>
      <CompanyNavBar returnUrl="/company/create-link-model" />
      <CompanyInfoNavBar activeButton={1} />
      <div style={{ padding: '1em' }}>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
          }}
        >
          <IconButton onClick={onBackArrowClicked}>
            <ArrowBackIcon />
          </IconButton>
          <text className="font-poppins page-title">Modelo de links</text>
        </div>
        <Paper elevation={0} className="font-poppins" variant="outlined">
          <div style={{ margin: '1em' }}>
            <label>Nome do Modelo</label>
            <input
              className="form-control"
              name="name"
              value={form.name}
              onChange={handleChangeForm}
            />
            <label>Facebook</label>
            <input
              className="form-control"
              name="facebook"
              value={form.facebook}
              onChange={handleChangeForm}
            />
            <label>Instagram</label>
            <input
              className="form-control"
              name="instagram"
              value={form.instagram}
              onChange={handleChangeForm}
            />
            <label>Twitter</label>
            <input
              className="form-control"
              name="twitter"
              value={form.twitter}
              onChange={handleChangeForm}
            />
            <label>Youtube</label>
            <input
              className="form-control"
              name="youtube"
              value={form.youtube}
              onChange={handleChangeForm}
            />
            <label>Linkedin</label>
            <input
              className="form-control"
              name="linkedin"
              value={form.linkedin}
              onChange={handleChangeForm}
            />
            <label>Yelp</label>
            <input
              className="form-control"
              name="yelp"
              value={form.yelp}
              onChange={handleChangeForm}
            />
            <label>Foursquare</label>
            <input
              className="form-control"
              name="foursquare"
              value={form.foursquare}
              onChange={handleChangeForm}
            />
            <label>Website</label>
            <input
              className="form-control"
              name="website"
              value={form.website}
              onChange={handleChangeForm}
            />
            <label>Outros Links</label>
            <Typeahead
              allowNew
              id="basic-typeahead-multiple"
              multiple
              labelKey="name"
              newSelectionPrefix="Adicionar novo item: "
              options={[]}
              selected={otherLinks}
              onChange={setOtherLinks}
            />
            <div style={{ marginTop: '2em' }}>
              <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                {renderButton()}
              </div>
              {form.name === '' ? (
                <div
                  style={{
                    marginTop: '0.6em',
                    display: 'flex',
                    justifyContent: 'flex-end',
                  }}
                >
                  <text style={{ fontSize: '0.8em' }}>
                    * Preencha os campos necessários
                  </text>
                </div>
              ) : (
                <></>
              )}
            </div>
          </div>
        </Paper>
      </div>
    </div>
  );
};

export default CreateLinkModel;
