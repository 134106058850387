import React from 'react';
import { useHistory } from 'react-router-dom';
import * as R from 'ramda';

import { isEmpty } from 'lodash';
import OverlayLoading from '../../overlay-loading';
import Loading from '../../loading';
import Input from '../../input';
import Select from '../../select';
import Button from '../../button';

import {
  formatLocationAccessLevelProfiles,
} from '../helpers';

import {
  StyledSendInvite,
  StyledFormButtons,
  StyledLink,
  StyledChevronRight,
} from './send-invite-styles';

const SendInvite = ({
  setOpenFormToSendInvite,
  guestAccessLevelProfiles,
  isSendingInvite,
  handleSendInvite,
  inviteLoading,
  sendInviteEmailRef,
  sendInviteProfileRef,
  isLocationPage = false,
}) => {
  const history = useHistory();

  return (
    <StyledSendInvite>
      {isSendingInvite && <OverlayLoading textToLoading="Enviando convite..." />}

      {isSendingInvite && <Loading className="loading-pending-invite" />}

      {!isSendingInvite && (
      <Input
        fieldRef={sendInviteEmailRef}
        inputOptions={{ name: 'email' }}
        label="E-mail"
        border
      />
      )}

      <>
        {(!R.isNil(guestAccessLevelProfiles) && !isSendingInvite) && (
        <Select
          className="select-profile"
          label="Perfil"
          fieldRef={sendInviteProfileRef}
          border
          options={formatLocationAccessLevelProfiles(guestAccessLevelProfiles)}
        />

        )}

        {isLocationPage && (
          <StyledLink onClick={() => history.push('/location-access-level-profiles')}>
            {isEmpty(guestAccessLevelProfiles) ? 'Criar Perfis' : 'Gerenciar Perfis'}
            <StyledChevronRight />
          </StyledLink>
        )}
      </>

      <StyledFormButtons>
        <Button
          className="button"
          onClick={handleSendInvite}
          disabled={inviteLoading}
          type="submit"
        >
          {inviteLoading ? <Loading /> : 'Enviar convite'}
        </Button>

        <Button
          className="company-guest-form-button"
          onClick={() => setOpenFormToSendInvite(false)}
          buttonType="cancel"
        >
          Cancelar
        </Button>
      </StyledFormButtons>
    </StyledSendInvite>
  );
};

export default SendInvite;
