import React from 'react';

import limitedText from '../../../../utils/limited-text';

import IconButton from '../../../icon-button';
import Loading from '../../../loading';

import {
  StyledButtonsContainer,
  StyledInvite,
  StyledSpan,
} from './invite-styles';

const Invite = ({
  email = 'email@email.com',
  status = 'Enviado',
  loading,
  onResendEmailClicked,
  onDeleteInviteClicked,
}) => (
  <StyledInvite>
    <StyledSpan className="pending-invite-email">
      {limitedText(email, 25)}
    </StyledSpan>
    <div className="pending-invite-status">
      Status:
      <b>{status}</b>
    </div>
    {loading ? <Loading />
      : (
        <StyledButtonsContainer>
          <IconButton tooltip="Reenviar Email" icon="Email" onClick={onResendEmailClicked} />
          <IconButton tooltip="Excluir Convite" icon="Delete" onClick={onDeleteInviteClicked} />
        </StyledButtonsContainer>
      )}
  </StyledInvite>
);

export default Invite;
