import React from 'react';
import {
  Done,
  Loop,
  Flag,
  Block,
} from '@material-ui/icons';

import Tooltip from '../../../tooltip';

import {
  StyledStatus,
} from './status-styles';

const Status = ({ syncStatus }) => (
  <StyledStatus>
    {syncStatus && (
      <>
        {syncStatus === 'DONE' && (
          <Tooltip title="Sincronizado!" arrow>
            <Done className="done" />
          </Tooltip>
        )}
        {syncStatus === 'IN_PROGRESS' && (
          <Tooltip title="Sincronizando..." arrow>
            <Loop className="in-progress" />
          </Tooltip>
        )}
        {syncStatus === 'OPEN' && (
          <Tooltip title="Em Aberto" arrow>
            <Flag className="open" />
          </Tooltip>
        )}
        {syncStatus === 'NO_ACCESS' && (
          <Tooltip title="Sem Acesso" arrow>
            <Block className="no-access" />
          </Tooltip>
        )}
      </>
    )}
  </StyledStatus>
);

export default Status;
