import axios from 'axios';

export default async function getTicket({
  accessToken,
  ticketId,
  setIsFetching,
  setTokenLikeExpired,
}) {
  try {
    if (setIsFetching) setIsFetching(true);

    const requestOptions = {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    };

    const { data } = await axios.get(
      `${process.env.REACT_APP_TICKETS_API_URL}/${ticketId}`,
      requestOptions,
    );

    return data;
  } catch (err) {
    if (err.response?.status === 401 && setTokenLikeExpired) setTokenLikeExpired();

    console.log(err);

    return err;
  } finally {
    if (setIsFetching) setIsFetching(false);
  }
}
