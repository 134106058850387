import React from 'react';
import * as R from 'ramda';

import {
  StyledSelect,
  StyledInputValidate,
  StyledChevronDown,
  StyledSelectWrapper,
} from './select-styles';

import { TSelectBaseProps } from '../../types/TSelect';

const Select = <T, >({
  options = [],
  name = 'select',
  selectValidate,
  onChange,
  fieldRef,
  label,
  disabled,
  borderRadius,
  border,
  lightBorder,
  className,
  hasPlaceholder,
  placeholder = 'Selecione uma opção',
  value = '',
  noMargin,
}: TSelectBaseProps<T>) => {
  const handleOnChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    if (!R.isNil(onChange)) onChange(event);
  };

  return (
    <StyledSelect
      className={className}
      borderRadius={borderRadius}
      border={border}
      lightBorder={lightBorder}
      noMargin={noMargin}
    >
      {label && <label htmlFor={name}>{label}</label>}
      <StyledSelectWrapper>
        <StyledChevronDown />

        <select
          value={value}
          ref={fieldRef}
          onChange={handleOnChange}
          name={name}
          disabled={disabled}
        >
          {hasPlaceholder && <option value="" disabled selected hidden>{placeholder}</option>}

          {!R.isEmpty(options) && (
            options.map((option) => (
              <option
                key={option.id || option.value || option.name}
                value={option.value || option.name}
              >
                {option.name}
              </option>
            ))
          )}
        </select>
      </StyledSelectWrapper>

      {selectValidate && selectValidate.visible && (
        <StyledInputValidate type={selectValidate.type}>
          {selectValidate.message}
        </StyledInputValidate>
      )}
    </StyledSelect>
  );
};

export default Select;
