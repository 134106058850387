import styled from 'styled-components';
import { Dialog, DialogContent } from '@material-ui/core';
import { lighten, darken } from 'polished';
import { Error } from '@material-ui/icons';

export const StyledDialog = styled(Dialog)`
  .MuiPaper-root {
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
  }
`;

export const StyledDialogWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const StyledDialogHeader = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid ${(props) => lighten(0.4, props.theme.colors.black)};
  padding: 1rem 2rem;
  background-color: ${props => props.theme.colors.primary};
  margin-bottom: 2rem;
`;

export const StyleDialogTitle = styled.h1`
  font-size: 1.8rem;
  font-weight: 700;
  line-height: 2rem;
  color: ${props => props.theme.colors.white};
  margin-right: 0.8rem;
  margin-bottom: 0;
`;

export const StyledDialogContent = styled(DialogContent)`
  padding: 1rem 2rem;
`;

export const StyledDialogList = styled.ul`
  list-style: none;
`;

export const StyledDialogItem = styled.li`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid ${props => darken(0.87, props.theme.colors.white)};
  padding: 1rem 0;

  &:last-child {
    border: none;
  }
`;

export const StyledErrorIcon = styled(Error)`
  color: ${props => props.theme.colors.god};
  height: 2.2rem;
  width: 2.2rem;
`;

export const StyledDialogItemTitle = styled.h2`
  font-size: 1.6rem;
  font-weight: bold;
`;

export const StyledDialogItemContent = styled.p<{ isGray?: boolean }>`
  color: ${props => (props.isGray ? darken(0.5, props.theme.colors.white) : darken(0.87, props.theme.colors.white))};
  font-size: ${props => (props.isGray ? '1.6rem' : '1.2rem')};
  margin-bottom: 0;
`;
