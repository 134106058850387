import React from 'react';
import FadeInEffect from 'react-fade-in';
import { useSelector } from 'react-redux';
import * as R from 'ramda';
import * as MaterialUIIcons from '@material-ui/icons/';

import { uniqueId } from 'lodash';
import { useReviewsContext } from '../../../contexts/reviews';
import { useAuth } from '../../../hooks';

import { OverlayLoading } from '../../../components';

import SectionHeader from '../section-header';
import SectionBody from '../section-body';
import SensitiveWord from './sensitive-word';
import FormAddSensitiveWord from './form-add-sensitive-word';

import {
  StyledSensitiveWords,
  StyledSensitiveWordsList,
  StyledFilteredSensitiveWord,
  StyledFilteredSensitiveWordContainer,
  StyledIconButtonWrapper,
  StyledDeleteAllButton,
} from './sensitive-words-styles';

import addSensibleWord from '../../../services/companies/addSensibleWord';
import removeSensibleWord from '../../../services/companies/removeSensibleWord';
import removeAllSensibleWords from '../../../services/companies/removeAllSensibleWords';
import { TSensitiveWord } from '../../../types/TReviews';

const SensitiveWords = () => {
  const DeleteIcon = MaterialUIIcons.Delete;

  const {
    reviewsDataListing,
    starRating: { currentStarRating },
    listAutomaticResponsesBy: { currentListBy },
    sensitiveWordsToFilter,
    setSensitiveWordsToFilter,
    locationsToFilter,
    reviewsData,
  } = useReviewsContext();

  const { userAccessToken } = useAuth();
  const { activeCompanyId } = useSelector((state) => state.CompanyReducer);
  const [isRemovedWord, setIsRemovedWord] = React.useState(false);

  const handleAddSensitiveWord = async (words) => {
    if (R.isEmpty(words) || R.isNil(words)) return;

    const wordExist = words.every((word) => sensitiveWordsToFilter.find((sensitiveWord) => sensitiveWord === word));

    if (wordExist) return;

    const addSensibleResponse = await addSensibleWord({
      accessToken: userAccessToken,
      sensibleWords: words,
      companyId: activeCompanyId,
    });

    if (R.isNil(addSensibleResponse)) return;

    const { sensible_words: sensibleWords } = addSensibleResponse;

    const sensitiveWordsNormalized = sensibleWords.map((word) => ({
      id: uniqueId(),
      word,
      isFiltering: false,
    }));

    setSensitiveWordsToFilter(sensitiveWordsNormalized);
  };

  const handleRemoveWord = async (word) => {
    const currentSensitiveWordsToFilter = sensitiveWordsToFilter.filter(
      (sensitiveWord) => sensitiveWord !== word,
    );

    setSensitiveWordsToFilter(currentSensitiveWordsToFilter);

    const removeSensibleResponse = await removeSensibleWord({
      accessToken: userAccessToken,
      companyId: activeCompanyId,
      sensibleWord: word,
      setIsDelete: setIsRemovedWord,
    });

    if (R.isNil(removeSensibleResponse)) return;

    const { sensible_words: sensibleWords } = removeSensibleResponse;

    const sensitiveWordsNormalized = sensibleWords.map((word) => ({
      id: uniqueId(),
      word,
      isFiltering: false,
    }));

    setSensitiveWordsToFilter(sensitiveWordsNormalized);
  };

  const handleRemoveAllWords = async () => {
    setSensitiveWordsToFilter([]);

    const data = await removeAllSensibleWords({
      accessToken: userAccessToken,
      companyId: activeCompanyId,
      setIsDelete: setIsRemovedWord,
    });

    if (R.isNil(data)) return;

    setSensitiveWordsToFilter([]);
  };

  return (
    <StyledSensitiveWords>
      <FadeInEffect>
        <SectionHeader title="Palavras sensíveis" />
        <SectionBody>
          {isRemovedWord && (
            <OverlayLoading textToLoading="Removendo palavra..." />
          )}
          <FormAddSensitiveWord
            handleAddSensitiveWord={handleAddSensitiveWord}
            handleRemoveWord={handleRemoveWord}
            handleRemoveAllWords={handleRemoveAllWords}
          />
          <StyledSensitiveWordsList>
            {!R.isNil(sensitiveWordsToFilter)
            && !R.isEmpty(sensitiveWordsToFilter) ? (
                sensitiveWordsToFilter.map((sensitiveWord) => (
                  <SensitiveWord
                    key={sensitiveWord.id}
                    sensitiveWord={sensitiveWord}
                    handleRemoveWord={handleRemoveWord}
                    sensitiveWordsToFilter={sensitiveWordsToFilter}
                    setSensitiveWordsToFilter={setSensitiveWordsToFilter}
                    reviewsDataListing={reviewsDataListing}
                  />
                ))
              ) : (
                <span>Nenhuma palavra cadastrada!</span>
              )}
          </StyledSensitiveWordsList>

          {sensitiveWordsToFilter.some(word => word.isFiltering === true) && (
              <StyledFilteredSensitiveWordContainer>
                <span>
                  Filtrando toda avaliação que
                  {' '}
                  <b>contém a(s) palavra(s)</b>
                  :
                </span>
                <StyledFilteredSensitiveWord>
                  {sensitiveWordsToFilter.map((sensitiveWord) => (
                    sensitiveWord.isFiltering === true && (
                    <li key={sensitiveWord.id}>{sensitiveWord.word}</li>
                    )
                  ))}
                </StyledFilteredSensitiveWord>
              </StyledFilteredSensitiveWordContainer>
          )}

          {!R.isNil(sensitiveWordsToFilter)
            && !R.isEmpty(sensitiveWordsToFilter) && (
              <StyledIconButtonWrapper onClick={() => handleRemoveAllWords()}>
                <StyledDeleteAllButton>
                  <span>Remover Todas</span>
                  <DeleteIcon className="delete-icon" />
                </StyledDeleteAllButton>
              </StyledIconButtonWrapper>
          )}
        </SectionBody>
      </FadeInEffect>
    </StyledSensitiveWords>
  );
};

export default SensitiveWords;
