import * as R from 'ramda';

import { TIsWorkingHoursPrepare } from '../../../../../types/TWorkingHours';

export const isWorkingHoursPrepare = ({
  times,
  is24Hours,
  isOpen,
}: TIsWorkingHoursPrepare) => () => {
  if (R.isNil(times) || R.isEmpty(times)) return false;
  if (is24Hours) return false;
  if (!isOpen) return false;

  return true;
};
