import React from 'react';

import { Paper, IconButton, Switch } from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';

import { useCreateSpecialDateModel } from './hook';

import {
  CompanyNavBar,
  CompanyInfoNavBar,
} from '../../components';

import './CreateSpecialDateModel.css';

const CreateSpecialDateModel = () => {
  const {
    handleChangeForm,
    onBackArrowClicked,
    onChangeIsOpen,
    renderButton,
    form,
    isOpen,
  } = useCreateSpecialDateModel();

  return (
    <div>
      <CompanyNavBar returnUrl="/company/create-special-date-models" />
      <CompanyInfoNavBar activeButton={2} />
      <div style={{ padding: '1em' }}>
        <div style={{ marginTop: '2em' }}>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
            }}
          >
            <IconButton onClick={onBackArrowClicked}>
              <ArrowBackIcon />
            </IconButton>
            <text className="font-poppins page-title">
              Modelos de Datas Especiais
            </text>
          </div>
          <Paper elevation={0} square className="font-poppins paper-style">
            <div style={{ margin: '1em' }}>
              <div>
                <div>
                  <div style={{ display: 'flex' }}>
                    <div style={{ marginRight: '1em', width: '25em' }}>
                      <label>Nome do Modelo</label>
                      <input
                        className="form-control"
                        name="modelName"
                        value={form.modelName}
                        onChange={handleChangeForm}
                      />
                    </div>
                    <div style={{ width: '25em' }}>
                      <label>Selecionar Data</label>
                      <input
                        className="form-control"
                        type="date"
                        name="modelDate"
                        value={form.modelDate}
                        onChange={handleChangeForm}
                      />
                    </div>
                  </div>
                  <div>
                    <label>Está aberto</label>
                    <Switch
                      checked={isOpen}
                      onChange={onChangeIsOpen}
                      color="primary"
                    />
                  </div>
                  <div style={{ display: 'flex' }}>
                    <div style={{ marginRight: '1em' }}>
                      <label>Hora de abertura</label>
                      <input
                        className="form-control hour-input"
                        type="time"
                        name="openingHour"
                        disabled={!isOpen}
                        value={form.openingHour}
                        onChange={handleChangeForm}
                      />
                    </div>
                    <div style={{ marginRight: '1em' }}>
                      <label>Hora de Fechamento</label>
                      <input
                        className="form-control hour-input"
                        type="time"
                        name="closingHour"
                        disabled={!isOpen}
                        value={form.closingHour}
                        onChange={handleChangeForm}
                      />
                    </div>
                  </div>

                  <div style={{ marginTop: '2em' }}>
                    <div
                      style={{ display: 'flex', justifyContent: 'flex-end' }}
                    >
                      {renderButton()}
                    </div>
                    {form.modelName === '' || form.modelDate === '' ? (
                      <div
                        style={{
                          marginTop: '0.6em',
                          display: 'flex',
                          justifyContent: 'flex-end',
                        }}
                      >
                        <text style={{ fontSize: '0.8em' }}>
                          * Preencha os campos necessários
                        </text>
                      </div>
                    ) : (
                      <></>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </Paper>
        </div>
      </div>
    </div>
  );
};

export default CreateSpecialDateModel;
