import { shade } from 'polished';
import styled from 'styled-components';

export const StyledSection = styled.section`
  width: 100%;
  background-color: ${props => props.theme.colors.primary};
  color: ${props => props.theme.colors.white};
  display: flex;
  align-items: flex-start;
  flex-direction:column;
`;

export const StyledBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  padding: 1rem 2rem;
  width: 100%;

  h2 {
    margin-bottom: 0;
  }
`;

export const StyledSectionHeader = styled.div`
  display: flex;
  flex: 1;
  justify-content: space-between;
  width: 100%;
  background-color: ${props => shade(0.3, props.theme.colors.primary)} ;

  /* div:first-child {
    border-left: 1px solid black;
  } */

  div {
  display: flex;
  align-items: center;
  justify-content: center;
  /* border: 1px solid black; */
  border-left: none;
  padding: 0.5rem 0 ;
  }
`;

export const StyledPlatformHeader = styled.div`
  flex: 3;
`;

export const StyledStatusHeader = styled.div`
  flex: 1;
`;
