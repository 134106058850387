import React, { useMemo, useCallback, useEffect } from 'react';
import * as R from 'ramda';

import type { TSubtableRow } from '../../../../../types/TBatchUpdate';
import type {
  TBatchGalleryLocation,
  TBatchGalleryLocationMediaData,
} from '../../../../../types/TBatchGallery';

import { History } from '../../../../../assets/icons';
import {
  TableBodyRow,
  IconButton,
  TableBodyCell,
  Loading,
  BatchStatusIcon,
} from '../../../../../components';

import { MediasLocationsPopover } from '../medias-locations-popover';

import { useAuth } from '../../../../../hooks';
import { EBatchPlatformStatus } from '../../../../../types/TBatchGoogleModules';

import {
  constants,
  getDataRowCellValue,
  updateRowDataByColumn,
} from '../../../helpers';

import { StyledActionLoadingWrap } from '../../gallery-batch-row-styles';
import { StyledActionsWrap } from './gallery-batch-locations-row-styles';
import {
  findOneBatchGalleryLocation,
  forceUpdateBatchGalleryLocations,
  updateBatchGalleryLocation,
} from '../../../../../services/google-batch-modules/batch-gallery';
import { isResponseDefinedAndSuccessful } from '../../../../../utils/is-response-success';
import APIFindBatchLocationLog from '../../../../../services/google-batch-modules/batch-infos/find-batch-location-log';
import GalleryBatchLocationsHistory from './gallery-batch-locations-history';
import LogHistoryData from '../../../../../components/log-history-data';
import { THistoryDataFormat } from '../../../../../components/log-history-data/log-history-data';

export function GalleryBatchLocationsRow({
  data,
  updateBatch,
  batchId,
}: TSubtableRow) {
  const { userAccessToken } = useAuth();

  const [currentRowData, setCurrentRowData] = React.useState<any[]>([]);
  const [checked, setChecked] = React.useState(false);
  const [isFetchingStatus, setIsFetchingStatus] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);
  const [historyModalOpened, setHistoryModalOpened] = React.useState(false);

  const [histories, setHistories] = React.useState<THistoryDataFormat[]>([] as THistoryDataFormat[]);

  React.useEffect(() => {
    setCurrentRowData(data);
  }, [data]);

  const batchGalleryLocationsId = React.useMemo(
    () => getDataRowCellValue({ columnRef: 'id', dataRowCell: currentRowData }),
    [currentRowData],
  );

  const batchGalleryLocationsName = React.useMemo(
    () => getDataRowCellValue({ columnRef: 'location-name', dataRowCell: currentRowData }),
    [currentRowData],
  );

  const batchGalleryLocationStatus = React.useMemo(
    (): string | null => getDataRowCellValue({
      dataRowCell: currentRowData,
      columnRef: 'status',
    }),
    [currentRowData],
  );

  const getBatchGalleryLocations = React.useCallback(async () => {
    setIsFetchingStatus(true);
    const batchLocationId = getDataRowCellValue({
      dataRowCell: currentRowData,
      columnRef: 'id',
    });

    const { status: responseStatus, data: responseData } = await findOneBatchGalleryLocation({
      batchGalleryId: batchLocationId,
      userAccessToken,
    });

    if (!isResponseDefinedAndSuccessful<TBatchGalleryLocation>(responseStatus, responseData)) {
      setTimeout(() => setIsFetchingStatus(false), 800);
      return;
    }

    const {
      status: batchLocationStatus,
      location_media_data: locationMediaData,
    } = responseData;

    setCurrentRowData((prevState: any[]) => prevState.map((columnRowData: any) => {
      if (columnRowData?.columnRef === 'location-media-data') {
        return {
          ...columnRowData,
          value: locationMediaData,
        };
      }

      if (columnRowData?.columnRef === 'status') {
        return {
          ...columnRowData,
          value: batchLocationStatus,
        };
      }

      return columnRowData;
    }));

    getHistoryData(batchLocationId);
    setTimeout(() => setIsFetchingStatus(false), 500);
  }, [currentRowData]);

  React.useEffect(() => {
    const getBatchGalleryLocationsByInterval = setInterval(() => {
      const cellDataStatus = currentRowData.find(
        (cellData: any) => cellData.columnRef === 'status',
      );
      if (
        cellDataStatus.value === 'Pendente'
        || cellDataStatus.value === 'Processando'
      ) { getBatchGalleryLocations(); }
    }, constants.BATCH_INFOS_LOCATION_FETCH_STATUS_SETINTERVAL);

    return () => clearInterval(getBatchGalleryLocationsByInterval);
  }, [currentRowData]);

  const popoverData = React.useMemo(
    () => getDataRowCellValue({ dataRowCell: currentRowData, columnRef: 'location-media-data' }),
    [currentRowData],
  );

  const [anchorEl, setAnchorEl] = React.useState(null);

  const batchGalleryLocationMediaData = useMemo(() : TBatchGalleryLocationMediaData[] | null => {
    const locationMediaData: TBatchGalleryLocationMediaData[] | null = getDataRowCellValue({
      dataRowCell: currentRowData,
      columnRef: 'location-media-data',
    });

    if (R.isNil(locationMediaData) || R.isEmpty(locationMediaData)) return null;

    return R.isEmpty(locationMediaData) ? null : locationMediaData;
  }, [currentRowData]);

  const getHistoryData = React.useCallback(
    async (batchGalleryLocationsId?: string) => {
      if (
        R.isNil(batchGalleryLocationsId) ||
        R.isEmpty(batchGalleryLocationsId)
      ) {
        return;
      }
      const response = await APIFindBatchLocationLog({
        userAccessToken,
        batchId,
        batchLocationId: batchGalleryLocationsId,
        page: 0,
        pageSize: 9999,
      });

      if (response.status === 'ERROR') return;

      const [historiesResponse] = response.data;
      setHistories(historiesResponse);
    },
    []
  );

  const cancelBatchGalleryLocations = React.useCallback(async () => {
    const { status: responseStatus, data: responseData } = await updateBatchGalleryLocation({
      batchGalleryLocationId: batchGalleryLocationsId,
      status: 'CANCELLED' as EBatchPlatformStatus.CANCELLED,
      userAccessToken,
      isUpdating: setIsLoading,
    });

    if (
      !isResponseDefinedAndSuccessful<TBatchGalleryLocation>(responseStatus, responseData)
    ) return;

    const { status } = responseData;

    setCurrentRowData((prevState: any) => updateRowDataByColumn({
      rowData: prevState,
      payload: {
        status,
      },
    }));

    await updateBatch();
    await getHistoryData(batchGalleryLocationsId);
  }, [currentRowData, batchGalleryLocationsId]);

  const handleForceUpdateBatchGalleryLocations = React.useCallback(async () => {
    const { status: responseStatus, data: responseData } = await forceUpdateBatchGalleryLocations({
      batchGalleryLocationsId,
      userAccessToken,
      isUpdating: setIsFetchingStatus,
    });

    if (!isResponseDefinedAndSuccessful<TBatchGalleryLocation>(responseStatus, responseData)) {
      setCurrentRowData((prevState: any) => updateRowDataByColumn({
        rowData: prevState,
        payload: {
          status: 'ERROR' as EBatchPlatformStatus.ERROR,
        },
      }));

      return;
    }

    const { status } = responseData;

    setCurrentRowData((prevState: any) => updateRowDataByColumn({
      rowData: prevState,
      payload: {
        status,
      },
    }));
    await getHistoryData(batchGalleryLocationsId);
  }, [batchGalleryLocationsId]);

  const handleOpenMediasPopover = React.useCallback(
    (event) => {
      setAnchorEl(event.currentTarget);
    },
    [setAnchorEl],
  );

  const creationDate = React.useMemo(
    () => getDataRowCellValue({
      columnRef: 'created-at',
      dataRowCell: currentRowData,
    }),
    [currentRowData],
  );

  useEffect(() => {
    getHistoryData(batchGalleryLocationsId);
  }, [batchGalleryLocationsId]);

  const handleGalleryHistory = useCallback(() => {
    if (R.isNil(batchGalleryLocationsId)) return;

    setHistoryModalOpened((state) => !state);
  }, [batchGalleryLocationsId]);
  return (
    <TableBodyRow
      key={batchGalleryLocationsId}
      checked={checked}
      setChecked={setChecked}
      hiddenCheckbox
    >
      {currentRowData
        && currentRowData.map(
          (dataColumnCell: any) => dataColumnCell?.visible && (
          <TableBodyCell
            key={`${batchGalleryLocationsId}-${dataColumnCell.columnRef}`}
          >
            {dataColumnCell.columnRef === 'status'
              ? isFetchingStatus
                ? 'Atualizando status...'
                : <BatchStatusIcon batchType="gallery" status={dataColumnCell.value} />
              : dataColumnCell.value}
          </TableBodyCell>
          ),
        )}
      <TableBodyCell>
        <IconButton
          tooltip="Exibir histórico de ações da Galeria"
          onClick={handleGalleryHistory}
          CustomIcon={History}
          showCustomIcon
        />
      </TableBodyCell>
      <TableBodyCell>
        <StyledActionsWrap>
          {isLoading && (
            <StyledActionLoadingWrap>
              <Loading className="action-loading" />
              Aplicando ação...
            </StyledActionLoadingWrap>
          )}

          {!isLoading && (
            <>
              {!R.isEmpty(batchGalleryLocationMediaData)
                && !R.isNil(batchGalleryLocationMediaData) && (
                  <IconButton
                    tooltip="Visualizar mídias"
                    icon="VisibilityOutlined"
                    onClick={handleOpenMediasPopover}
                  />
              )}
              {batchGalleryLocationStatus === 'ERROR' && !isFetchingStatus && (
                <IconButton
                  tooltip="Forçar atualização"
                  onClick={handleForceUpdateBatchGalleryLocations}
                  icon="CloudUpload"
                />
              )}
              {batchGalleryLocationStatus === 'PENDING' && (
                <IconButton
                  tooltip="Cancelar atualização"
                  onClick={cancelBatchGalleryLocations}
                  icon="Close"
                />
              )}
            </>
          )}
        </StyledActionsWrap>
      </TableBodyCell>

      {!R.isNil(anchorEl) && (
        <MediasLocationsPopover
          open={!R.isNil(anchorEl)}
          data={popoverData || []}
          onClose={() => setAnchorEl(null)}
          creationDate={creationDate}
          galleryBatchLocationId={batchGalleryLocationsId}
          updateBatch={updateBatch}
          updateBatchLocation={getBatchGalleryLocations}
          userAccessToken={userAccessToken}
          batchLocationStatus={batchGalleryLocationStatus as EBatchPlatformStatus}
        />
      )}

      {historyModalOpened && (
        <LogHistoryData
          open={historyModalOpened}
          close={handleGalleryHistory}
          batchLocationName={batchGalleryLocationsName}
          data={histories}
        />
      )}
    </TableBodyRow>
  );
}
