import { websiteRegExp } from './regexp';

export default function isValidDataStudioUrl(dataStudioUrl: string): boolean {
  const isValidUrl = websiteRegExp.test(dataStudioUrl);
  const isUrlFromDataStudio = dataStudioUrl.includes('datastudio.google.com');

  const isReportingUrl = dataStudioUrl.includes('reporting');

  return isValidUrl && isUrlFromDataStudio && isReportingUrl;
};
