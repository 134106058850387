const limitedText = (text, limit) => {
  if (!text) return text;

  if (text.length < limit) return text;

  for (let i = limit; i > 0; i--) {
    const conditionRetincence = text.charAt(i) === ' '
      && (text.charAt(i - 1) !== ','
      || text.charAt(i - 1) !== '.'
      || text.charAt(i - 1) !== ';');

    if (conditionRetincence) return `${text.substring(0, i)}...`;
  }

  return `${text.substring(0, limit)}...`;
};

export default limitedText;
