import type { TBillingTypeOption } from '../../location-services/create-service-dialog/helpers';

export const billingTypes: TBillingTypeOption[] = [
  {
    id: 1,
    name: 'Sem Preço',
  },
  {
    id: 2,
    name: 'Grátis',
  },
  {
    id: 3,
    name: 'Fixo',
  },
];

export const constants = {
  SERVICE_NAME_MAX_LENGTH: 120,
  SERVICE_DESCRIPTION_MAX_LENGTH: 300,
};
