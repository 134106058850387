import React from 'react';
import * as R from 'ramda';

import WorkingHoursContext from '../../working-hours-context';

import TimeRange from '../time-range';
import ReplicatedPopover from './replicated-popover';
import Is24HoursButton from '../../is-24-hours-button';

import {
  InputSwitch,
} from '../../../../../components';

import {
  StyledTimeOfDay,
  StyledHoursDayName,
  StyledTimes,
  StyledTimesRow,
  StyledReplicate,
  StyledArrowDownward,
  Styled24HourReplicateWrapper,
} from './time-of-day-styles';

const TimeOfDay = ({
  dayTitle = 'Dia da semana',
  isOpen = false,
  times = [],
  isReplicated,
  changeIsOpenDay,
  changeIs24Hours,
  is24hours,
  dropDownToBottom,
}) => {
  const [switchIsOpen, setSwitchIsOpen] = React.useState(isOpen);
  const [currentIs24Hours, setcurrentIs24Hours] = React.useState(is24hours);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [replicatedDaysSelected, setReplicatedDaysSelected] = React.useState([]);

  const {
    setWorkingHoursPerDay,
    workingHoursPerDay,
  } = React.useContext(WorkingHoursContext);

  const replicatedPopoverOpen = Boolean(anchorEl);
  const replicatedPopoverId = replicatedPopoverOpen ? 'replicated-popover' : undefined;

  const handleIsOpen = () => {
    setSwitchIsOpen(!switchIsOpen);

    changeIsOpenDay({
      workingHoursStateData: setWorkingHoursPerDay,
      dayTarget: dayTitle,
      isOpen: !switchIsOpen,
    });
  };

  const handleIsChangeIs24Hours = () => {
    setcurrentIs24Hours(!currentIs24Hours);
    changeIs24Hours({
      workingHoursStateData: setWorkingHoursPerDay,
      dayTarget: dayTitle,
      is24hours: !currentIs24Hours,
    });
  };

  const handleOpenReplicatedPopover = e => {
    setReplicatedDaysSelected([]);
    setAnchorEl(e.currentTarget);
  };

  const handleReplicatedApply = () => {
    if (R.isEmpty(replicatedDaysSelected)) return;

    const workingHoursDayToReplicated = R.take(1, workingHoursPerDay)[0] || {};

    R.forEach(replicatedDaySelected => {
      setWorkingHoursPerDay(prevWorkingHours => prevWorkingHours.map(workingHoursDay => {
        if (workingHoursDay.day_title === replicatedDaySelected) {
          const { times: timesReplicated } = workingHoursDayToReplicated;
          const { times: oldTimesRangeToReplace } = workingHoursDay;

          const newTimesFromReplicated = timesReplicated.map(timeReplicated => ({
            ...timeReplicated,
            id: 'a',
          }));

          const oldTimesRange = oldTimesRangeToReplace.map(oldTimeRangeToReplace => ({
            ...oldTimeRangeToReplace,
            is_deleted: true,
          }));

          return {
            ...workingHoursDayToReplicated,
            id: workingHoursDay.id || 0,
            day_title: replicatedDaySelected,
            is_replicated: false,
            times: [
              ...oldTimesRange,
              ...newTimesFromReplicated,
            ],
          };
        }

        return workingHoursDay;
      }));
    }, replicatedDaysSelected);
  };

  React.useEffect(() => {
    if (replicatedPopoverOpen) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style = '';
    }
  }, [anchorEl]);

  return (
    <StyledTimeOfDay>
      <StyledHoursDayName
        className="day-title"
      >
        {dayTitle}
      </StyledHoursDayName>
      <InputSwitch
        alignRow
        changeChecked={handleIsOpen}
        checked={switchIsOpen}
        label={switchIsOpen ? 'Aberto' : 'Fechado'}
        className="input-switch"
      />

      {switchIsOpen && (
      <Styled24HourReplicateWrapper>
        <Is24HoursButton
          isActive={currentIs24Hours}
          onClick={handleIsChangeIs24Hours}
          className="button-24h"
        />

        {(switchIsOpen && isReplicated) && (
        <StyledReplicate
          aria-describedby={replicatedPopoverId}
          onClick={handleOpenReplicatedPopover}
        >
          <StyledArrowDownward />
          Replicar
        </StyledReplicate>
        )}
      </Styled24HourReplicateWrapper>
      )}

      {(switchIsOpen && !currentIs24Hours) && (
        <StyledTimes
          isSeveralTimes={times.length >= 1}
        >
            {times.filter(time => time.is_deleted !== true).map(time => (
              <StyledTimesRow
                key={Math.random(Math.floor())}
              >
                <TimeRange
                  open={time.open}
                  close={time.close}
                  timeRange={time.id}
                  dropDownToBottom={dropDownToBottom}
                  setIsOpen={setSwitchIsOpen}
                  dayTitle={dayTitle}
                  changeIsOpenDay={changeIsOpenDay}
                  timesCount={times.filter(timeItem => timeItem.is_deleted !== true).length}
                />
              </StyledTimesRow>
            ))}
        </StyledTimes>
      )}

      <ReplicatedPopover
        id={replicatedPopoverId}
        open={replicatedPopoverOpen}
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(null)}
        handleReplicatedApply={handleReplicatedApply}
        handleSetSelectedDays={setReplicatedDaysSelected}
        replicatedDaysSelected={replicatedDaysSelected}
      />
    </StyledTimeOfDay>
  );
};

export default TimeOfDay;
