import axios from 'axios';

export default async function getFunctions(accessToken, page = 0, pageSize = 100, query = '') {
  const requestOptions = {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  };

  const { data } = await axios.get(
    `${process.env.REACT_APP_FUNCTIONS_API_URL}?page=${page}&pageSize=${pageSize}&query=${query}`,
    requestOptions,
  );

  return data;
}
