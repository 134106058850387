import styled, { css } from 'styled-components';
import { shade, lighten } from 'polished';

export const StyledHeaderToolbarAction = styled.div`
    display: flex;
    flex: 1;
    flex-direction: column;
    align-items: center;
    height: 9.2rem;
    padding: 2rem;
    font-size: 1.2rem;
    border-bottom: 0.6rem solid ${props => lighten(0.91, props.theme.colors.black)};
    cursor: pointer;
    transition: 0.3s;

    &:nth-child(even) {
        border-color: ${props => lighten(0.85, props.theme.colors.black)};
    }

    &:hover {
        border-bottom: 0.9rem solid ${props => shade(0.2, props.theme.colors.primary)};
        background: ${props => props.theme.colors.primary};
        color:  ${props => props.theme.colors.white};

        .icon {
            fill: ${props => props.theme.colors.white};
        }
    }

    .icon {
        margin-bottom: 0.5rem;
        width: 2.5rem;
        height: 2.5rem;
    }

    .title {
      text-align: center;
    }

    ${props => props.disabled && css`
      cursor: default;
      color:  ${lighten(0.91, props.theme.colors.black)};
      
      &:nth-child(even) {
        border-color: ${lighten(0.91, props.theme.colors.black)};
      }

      .icon {
        fill: ${lighten(0.91, props.theme.colors.black)};
      }

      &:hover {
        background: ${props.theme.colors.white};
        color:  ${lighten(0.91, props.theme.colors.black)};
        border-bottom: 0.6rem solid ${lighten(0.91, props.theme.colors.black)};    

        .icon {
          fill: ${lighten(0.91, props.theme.colors.black)};
        }
      }
    `}
`;
