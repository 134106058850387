import React from 'react';
import axios from 'axios';
import { TLocationEntity } from '../../types/TLocation';

type TCreateWorkingHourscreateWorkingHours = {
  accessToken: string;
  dayTitle: string;
  dropdownToBottom: boolean;
  is24Hours: boolean;
  isOpen: boolean;
  isReplicated: boolean;
  times: any[];
  type?: any;
  setTokenLikeExpired?: () => void;
  isCreateWorkingHours: React.Dispatch<React.SetStateAction<boolean>>;
  location: TLocationEntity[];
};

export default async function createWorkingHours({
  accessToken,
  dayTitle,
  dropdownToBottom,
  is24Hours,
  isOpen,
  isReplicated,
  times,
  type = null,
  setTokenLikeExpired,
  isCreateWorkingHours,
  location,
}: TCreateWorkingHourscreateWorkingHours) {
  const requestOptions = {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  };

  try {
    if (isCreateWorkingHours) isCreateWorkingHours(true);

    const { data } = await axios.post(
      `${process.env.REACT_APP_WORKING_HOURS_API_URL}`,
      {
        day_title: dayTitle,
        dropdown_to_bottom: dropdownToBottom,
        is_24_hours: is24Hours,
        is_open: isOpen,
        is_replicated: isReplicated,
        times,
        type,
        location,
      },
      requestOptions,
    );

    return data;
  } catch (err: any) {
    if (err.response?.status === 401 && setTokenLikeExpired) return setTokenLikeExpired();

    return null;
  } finally {
    if (isCreateWorkingHours) isCreateWorkingHours(false);
  }
}
