import styled from 'styled-components';
import { Paper } from '@material-ui/core';

export const StyledBatchUpdateInfoStatus = styled.div`
  h3 {
    margin-bottom: 0;
  }
`;

export const StyledContent = styled.div`
  margin: 2rem;

  .info-nav-button {
    white-space: nowrap;
  }
`;

export const StyledTitleWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  margin-bottom: 2rem;
`;

export const StyledInfo = styled(Paper)`
  display: flex;
  padding: 2rem;
  align-items: center;
  margin-bottom: 2rem;
`;
