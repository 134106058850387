import styled from 'styled-components';

export const StyledResetPassword = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0 2rem;
    max-width: 40rem;
    width: 100%;

    .hublocal-logo {
        width: 20rem;
        margin-bottom: 3rem;
    }

    .submit-button {
        margin-top: 1.5rem;
    }
`;

export const StyledForm = styled.form`
    display: grid;
    grid-template-columns: 1fr;
    row-gap: 2rem;
`;
