import styled from 'styled-components';

import HeaderToolbar from '../header-toolbar';

export const StyledLocationNavBar = styled(HeaderToolbar)`
  height: 100%;
`;

export const StyledDropDownGroup = styled.div`
  display: flex;
  height: 100%;
`;

export const StyledGroupAbasIcon = styled.div`
  display: flex;
  height: 100%;
`;
