import React from 'react';

import { ButtonTypeStyleProps, StyledContainer } from './button-card-styles';

type ButtonProps = {
  children: any
  typeButton: ButtonTypeStyleProps;
  onClick?(): void;
}

const ButtonCard = ({ children, typeButton = 'PRIMARY', onClick }:ButtonProps) => (
  <StyledContainer
    typeButton={typeButton}
    type="button"
    onClick={onClick}
  >
    {children}
  </StyledContainer>
);

export default ButtonCard;
