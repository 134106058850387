import styled from 'styled-components';

export const StyledTimeRange = styled.div`
  display: flex;
  align-items: center;
  padding: 0 2rem;
  margin-bottom: 0.5rem;

  &:last-child {
    margin-bottom: 0;
  }

  .time {
    margin-right: 2rem;

    &:last-of-type {
      margin-right: 0;
    }
  }

  .time-row-icon:first-of-type {
    margin-left: 1rem;
  }
`;
