import React from 'react';
import { isNil } from 'ramda';

import { useAuth } from '../../../hooks';

import {
  BatchStatusIcon,
  IconButton,
  Loading,
  TableBodyCell,
  TableBodyRow,
} from '../../../components';

import { BatchGalleriesLocationsTable } from './gallery-batch-locations-table';
import { MediasPopover } from './medias-popover';

import {
  constants,
  getDataRowCellValue,
  updateRowDataByColumn,
} from '../helpers';
import { isResponseDefinedAndSuccessful } from '../../../utils/is-response-success';

import {
  findOneBatchGallery,
  updateBatchGallery,
} from '../../../services/google-batch-modules/batch-gallery';

import {
  StyledActionLoadingWrap,
  StyledCellCustomButtons,
} from './gallery-batch-row-styles';

import type { EBatchPlatformStatus } from '../../../types/TBatchGoogleModules';
import type {
  TBatchGalleryRowColumnsName,
  TBatchInfosTableRowBaseProps,
  TRenderCellDataBatchGalleryRow,
} from '../../../types/TBatchUpdate';
import type {
  TBatchGallery,
} from '../../../types/TBatchGallery';

export const BatchGalleryRow = ({
  rowData,
  dataTableOptions,
}: TBatchInfosTableRowBaseProps) => {
  const { userAccessToken, userProfileName } = useAuth();

  const [currentRowData, setCurrentRowData] = React.useState<any[]>([]);

  const [checked, setChecked] = React.useState(false);
  const [isExpandable, setIsExpandable] = React.useState(false);
  const [actionLoading, setActionLoading] = React.useState(false);
  const [fetchingStateLoading, setFetchingStateLoading] = React.useState(false);

  const [anchorEl, setAnchorEl] = React.useState(null);
  const isOpen = Boolean(anchorEl);

  React.useEffect(() => {
    setCurrentRowData(rowData);
  }, [rowData]);

  const batchGalleryId = React.useMemo(
    () => getDataRowCellValue({
      columnRef: 'id',
      dataRowCell: rowData,
    }),
    [rowData],
  );

  const batchGalleryStatus = React.useMemo(
    () => getDataRowCellValue({
      columnRef: 'status',
      dataRowCell: currentRowData,
    }),
    [currentRowData],
  );

  const handleOpenMediasPopover = React.useCallback(
    (event) => {
      setAnchorEl(event.currentTarget);
    },
    [setAnchorEl],
  );

  const renderCellData = React.useMemo<TRenderCellDataBatchGalleryRow>(
    () => ({
      id: (value) => value,
      'updated-at': (value) => value,
      'created-at': (value) => value,
      status: (value) => (fetchingStateLoading ? (
        'Atualizando status...'
      ) : (
        <BatchStatusIcon status={value} batchType="gallery" />
      )),
      actions: (value) => value,
      category: (value) => value,
    }),
    [fetchingStateLoading],
  );

  const showCancel = React.useMemo(() => {
    const userAsAdminOrOperator = userProfileName !== 'Usuário Padrão';
    if (
      batchGalleryStatus === 'Pendente'
      && !actionLoading
      && userAsAdminOrOperator
    ) { return true; }
    return false;
  }, [actionLoading, batchGalleryStatus]);

  const onCancel = React.useCallback(async () => {
    const currentBatchGallery = rowData.find(
      (rowDataCell: any) => rowDataCell.columnRef === 'id',
    );

    const { status: responseStatus, data: responseData } = await updateBatchGallery({
      batchGalleryId: currentBatchGallery.value,
      userAccessToken,
      status: 'CANCELING' as EBatchPlatformStatus.CANCELING,
      isUpdating: setActionLoading,
    });

    if (
      !isResponseDefinedAndSuccessful<TBatchGallery>(
        responseStatus,
        responseData,
      )
    ) {
      return;
    }

    const { status } = responseData;

    setCurrentRowData((prevState: any) => updateRowDataByColumn({
      rowData: prevState,
      payload: {
        status: status as EBatchPlatformStatus,
      },
    }));
  }, [currentRowData, setCurrentRowData]);

  const getBatchGallery = React.useCallback(async (shouldCollapse: boolean = false) => {
    if (shouldCollapse) {
      setIsExpandable(false);
    }

    setFetchingStateLoading(true);

    const { status: responseStatus, data: responseData } = await findOneBatchGallery({
      batchGalleryId,
      userAccessToken,
    });

    if (!isResponseDefinedAndSuccessful<TBatchGallery>(responseStatus, responseData)) {
      setTimeout(() => setFetchingStateLoading(false), 800);
      return;
    }

    const { status: rowDataStatus, media_data: mediaData } = responseData;

    setCurrentRowData((prevState: any[]) => prevState.map((columnRowData: any) => {
      if (columnRowData?.columnRef === 'media-data') {
        return {
          ...columnRowData,
          value: mediaData,
        };
      }

      if (columnRowData?.columnRef === 'status') {
        return {
          ...columnRowData,
          value: rowDataStatus,
        };
      }

      return columnRowData;
    }));

    setTimeout(() => setFetchingStateLoading(false), 500);
  }, [batchGalleryId]);

  const popoverData = React.useMemo(
    () => getDataRowCellValue({ dataRowCell: currentRowData, columnRef: 'media-data' }),
    [currentRowData],
  );

  const creationDate = React.useMemo(() => getDataRowCellValue({
    columnRef: 'created-at',
    dataRowCell: currentRowData,
  }), [currentRowData]);

  React.useEffect(() => {
    const getBatchGalleryByInterval = setInterval(() => {
      const cellDataStatus = currentRowData.find(
        (cellData: any) => cellData.columnRef === 'status',
      );
      if (
        cellDataStatus.value === 'Pendente'
        || cellDataStatus.value === 'Processando'
        || cellDataStatus.value === 'Cancelando'
      ) { getBatchGallery(); }
    }, constants.FETCH_STATUS_SETINTERVAL);

    return () => clearInterval(getBatchGalleryByInterval);
  }, [currentRowData]);

  return (
    <TableBodyRow
      key={batchGalleryId}
      dataTableOptions={dataTableOptions}
      checked={checked}
      setChecked={setChecked}
      isExpandable={isExpandable}
      setIsExpandable={setIsExpandable}
      subTable={(data: any) => (
        <BatchGalleriesLocationsTable
          batchGalleries={data}
          updateBatchGallery={getBatchGallery}
        />
      )}
      dataRow={currentRowData}
      subTableColSpan={6}
      hiddenCheckbox
    >
      {currentRowData.map(
        (dataColumnCell: any) => dataColumnCell?.visible && (
        <TableBodyCell>
          {renderCellData[
                dataColumnCell.columnRef as TBatchGalleryRowColumnsName
          ]?.(dataColumnCell.value)}
        </TableBodyCell>
        ),
      )}
      <TableBodyCell>
        <StyledCellCustomButtons>
          {actionLoading && (
            <StyledActionLoadingWrap>
              <Loading className="action-loading" />
              Aplicando ação...
            </StyledActionLoadingWrap>
          )}

          <IconButton
            tooltip="Visualizar mídias"
            icon="VisibilityOutlined"
            onClick={handleOpenMediasPopover}
          />

          {showCancel && (
            <IconButton tooltip="Cancelar" icon="Cancel" onClick={onCancel} />
          )}
        </StyledCellCustomButtons>
      </TableBodyCell>

      {!isNil(anchorEl) && (
        <MediasPopover
          open={isOpen}
          data={popoverData || []}
          onClose={() => setAnchorEl(null)}
          creationDate={creationDate}
          galleryBatchId={batchGalleryId}
          userAccessToken={userAccessToken}
          updateBatch={getBatchGallery}
          galleryBatchStatus={batchGalleryStatus}
        />
      )}
    </TableBodyRow>
  );
};
