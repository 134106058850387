import styled, { css } from 'styled-components';
import { lighten } from 'polished';

import { TIs24HoursBaseProps } from '../../../../types/TWorkingHours';

export const StyledIs24HoursButton = styled.span<Pick<TIs24HoursBaseProps, 'isActive'>>`
  padding: 0.5rem 1.5rem;
  border-radius: 2rem;
  text-transform: uppercase;
  font-size: 1.2rem;
  background: ${props => lighten(0.30, props.theme.colors.grey)};
  cursor: pointer;
  font-weight: 400;
  transition: 0.3s;
  text-align: center;

  &:hover {
    background: ${props => props.theme.colors.secondary};
    color: ${props => props.theme.colors.white};
    transform: scale(1.1);
  }

  ${props => props.isActive && css`
    background: ${props.theme.colors.secondary};
    color: ${props.theme.colors.white};
    transform: scale(1.1);
    /* margin-left: 1rem; */
  `};
`;
