import type { TGoogleMoney } from '../types/TGoogle';

export function convertGooogleMoneyToNumber(googleMoney: TGoogleMoney): number {
  const { units, nanos } = googleMoney;

  const centsPart = nanos ? nanos * Math.pow(10, -9) : 0;

  const integerPart = units ? parseInt(units) : 0;

  return integerPart + centsPart;
}
