import axios from 'axios';

export default async function getCompanyGroups({
  accessToken,
  page = 0,
  pageSize = 20,
  query = '',
  setTokenLikeExpired,
  feedbackMessage,
}) {
  try {
    if (feedbackMessage) feedbackMessage('Carregando grupos de empresas...', { variant: 'warning' });

    const { data } = await axios.get(
      `${process.env.REACT_APP_COMPANY_GROUPS_API_URL}?page=${page}&pageSize=${pageSize}&query=${query}`,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      },
    );

    return data;
  } catch (err) {
    if (err.response?.status === 401 && setTokenLikeExpired) setTokenLikeExpired();

    if (feedbackMessage) feedbackMessage('Ocorreu um erro ao tentar carregar os grupos de empresas...', { variant: 'error' });

    return err;
  }
}
