import { createActions, createReducer } from 'reduxsauce';

export const { Types, Creators } = createActions({
  setActiveLocationGroup: ['activeLocationGroup'],
  setActiveLocationGroupId: ['activeLocationGroupId'],
  setLocationGroupLocations: ['locationGroupLocations'],
  setShowLocationsByGroup: ['showLocationsByGroup'],
});

const initialState = {
  activeLocationGroup: {},
  activeLocationGroupId: null,
  locationGroupLocations: null,
  showLocationsByGroup: false,
};

const reduxOnSetActiveLocationGroup = (
  state = initialState, { activeLocationGroup },
) => ({ ...state, activeLocationGroup });

const reduxOnSetActiveLocationGroupId = (
  state = initialState, { activeLocationGroupId },
) => ({ ...state, activeLocationGroupId });

const reduxOnSetLocationGroupLocations = (
  state = initialState, { locationGroupLocations },
) => ({ ...state, locationGroupLocations });

const reduxOnSetShowLocationsByGroup = (
  state = initialState, { showLocationsByGroup },
) => ({ ...state, showLocationsByGroup });

export default createReducer(initialState, {
  [Types.SET_ACTIVE_LOCATION_GROUP]: reduxOnSetActiveLocationGroup,
  [Types.SET_ACTIVE_LOCATION_GROUP_ID]: reduxOnSetActiveLocationGroupId,
  [Types.SET_LOCATION_GROUP_LOCATIONS]: reduxOnSetLocationGroupLocations,
  [Types.SET_SHOW_LOCATIONS_BY_GROUP]: reduxOnSetShowLocationsByGroup,
});
