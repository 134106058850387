import { format } from 'date-fns';
import { debounce } from 'lodash';
import { MUIDataTableOptions } from 'mui-datatables';
import { isNil } from 'ramda';
import React from 'react';
import { HeaderToolbar, Table } from '../../components';
import { useAuth } from '../../hooks';
import getAllLoginHistory from '../../services/login-history/get-all-login-history';
import { initialDateColumns, TTableData } from './helpers';
import LoginHistoryFilter from './login-history-filter';
import { StyledContent, StyledLoginHistory } from './login-history-styles';
import RowTableLoginHistory from './row-table-login-history/row-table-login-history';

const LoginHistory = () => {
  const { userAccessToken, userSetTokenLikeExpired } = useAuth();
  const [userName, setUserName] = React.useState('');
  const [userEmail, setUserEmail] = React.useState('');
  const [ipAddress, setIpAddress] = React.useState('');
  const [ipLocation, setIpLocation] = React.useState('');
  const [tableData, setTableData] = React.useState<any[][]>([]);
  const [loginHistoryCount, setLoginHistoryCount] = React.useState(0);
  const [isLoading, setIsLoading] = React.useState(false);
  const [page, setPage] = React.useState(0);
  const [pageSize, setPageSize] = React.useState(10);
  const [ofPeriod, setOfPeriod] = React.useState<Date | null>(null);
  const [toPeriod, setToPeriod] = React.useState<Date | null>(null);

  const tableColuns = React.useMemo(
    () => [...initialDateColumns], [initialDateColumns],
  );

  const handleChangeFilter = (filter: any, setField: React.Dispatch<React.SetStateAction<any>>) => {
    setPage(0);
    setField(filter);
  };

  const handleSearchUserNameDebounce = React.useCallback(
    debounce((text: string) => handleChangeFilter(text, setUserName), 500),
    [],
  );

  const handleSearchUserEmailDebounce = React.useCallback(
    debounce((text: string) => handleChangeFilter(text, setUserEmail), 500),
    [],
  );

  const handleSearchIpAddressDebounce = React.useCallback(
    debounce((text: string) => handleChangeFilter(text, setIpAddress), 500),
    [],
  );

  const handleSearchIpLocationDebounce = React.useCallback(
    debounce((text: string) => handleChangeFilter(text, setIpLocation), 500),
    [],
  );

  const tableOptions = React.useMemo(
    (): MUIDataTableOptions => ({
      count: loginHistoryCount,
      search: false,
      sort: false,
      download: true,
      draggableColumns: { enabled: false },
      selectableRowsHideCheckboxes: true,
      page,
    }),
    [loginHistoryCount, page],
  );

  const formattedTableData = React.useCallback((data: TTableData[]) => data.map((item:
    TTableData) => [
    { columnRef: 'id', visible: false, value: item.id },
    { columnRef: 'user_name', visible: true, value: item.user.name },
    { columnRef: 'email', visible: true, value: item.user.email },
    { columnRef: 'ip_address', visible: true, value: isNil(item.ip_address) ? 'Desconhecido' : item.ip_address },
    { columnRef: 'ip_location', visible: true, value: isNil(item.ip_location) ? 'Desconhecido' : item.ip_location },
    { columnRef: 'created_at', visible: true, value: format(new Date(item.created_at), 'dd/MM/yyyy hh:mm:ss a') },
  ]), []);

  const formattedData = React.useCallback((date: Date | null) => (date
    ? (new Date(date)).toISOString()
    : ''),
  []);

  const getLoginHistoryData = React.useCallback(async () => {
    const syncLoginHistoryResponse = await getAllLoginHistory({
      accessToken: userAccessToken,
      setTokenLikeExpired: userSetTokenLikeExpired,
      setIsFetching: setIsLoading,
      username: userName,
      email: userEmail,
      ip_address: ipAddress,
      ip_location: ipLocation,
      start_date: formattedData(ofPeriod),
      end_date: formattedData(toPeriod),
      page,
      pageSize,
    });

    if (!syncLoginHistoryResponse) return;

    const newTableData = formattedTableData(syncLoginHistoryResponse[0]);

    setTableData(newTableData);
    setLoginHistoryCount(syncLoginHistoryResponse[1]);
  }, [
    userAccessToken,
    userName,
    userEmail,
    ipAddress,
    ipLocation,
    ofPeriod,
    toPeriod,
    page,
    pageSize,
  ]);

  React.useEffect(() => {
    getLoginHistoryData();
  }, [getLoginHistoryData]);

  return (
    <StyledLoginHistory>
      <HeaderToolbar title="Histórico de Login" />
      <StyledContent>
        <LoginHistoryFilter
          searchUserLabel="Nome do Usuário"
          onChangeUserQuery={handleSearchUserNameDebounce}
          searchEmailLabel="E-mail"
          onChangeEmailQuery={handleSearchUserEmailDebounce}
          ofPeriod={ofPeriod}
          setOfPeriod={(filter) => handleChangeFilter(filter, setOfPeriod)}
          toPeriod={toPeriod}
          setToPeriod={(filter) => handleChangeFilter(filter, setToPeriod)}
          searchIPLocationLabel="Localização do IP"
          onChangeIPLocationQuery={handleSearchIpLocationDebounce}
          searchIPLabel="IP"
          onChangeIPQuery={handleSearchIpAddressDebounce}
        />
        <Table
          loading={isLoading}
          title="Registros"
          data={tableData}
          columns={tableColuns}
          setPage={setPage}
          setPageSize={setPageSize}
          options={tableOptions}
          row={(rowData) => (
            <RowTableLoginHistory
              rowData={rowData}
              ronId={rowData[0]?.value}
            />
          )}
        />
      </StyledContent>
    </StyledLoginHistory>
  );
};

export default LoginHistory;
