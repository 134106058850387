import styled from 'styled-components';

export const StyledTicketBatchInfosLocations = styled.div`
  display: flex;
  flex-direction: column;
  padding: 3rem 3rem;
  background: ${(props) => props.theme.colors.white};
  margin-bottom: 0.2rem;

  h3 {
    font-size: 3rem;
    margin-bottom: 2rem;
  }
`;

export const StyledLoadingWrap = styled.div`
  display: flex;

  .is-fetching-loading {
    margin-right: 0.5rem;
  }
`;
