import axios from 'axios';

import { TGetUserAssociatedAccounts } from '../../types/TGoogle';

export default async function getUserAssociatedAccounts({
  accessToken,
  isFetching,
  pageToken = '',
}: TGetUserAssociatedAccounts) {
  const requestOptions = {
    headers: {
      'access-token': accessToken,
    },
  };

  try {
    if (isFetching) isFetching(true);

    const { data } = await axios.get(
      `${process.env.REACT_APP_GOOGLE_API_URL}?pageToken=${pageToken}`,
      requestOptions,
    );

    return data;
  } catch (err: any) {
    return err.response;
  } finally {
    if (isFetching) isFetching(true);
  }
}
