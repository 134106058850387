import axios from 'axios';

import { TUpdateWorkingHours } from '../../types/TWorkingHours';

export default async function createWorkingHours({
  accessToken,
  dayTitle = '',
  dropdownToBottom = false,
  is24Hours = false,
  isOpen = false,
  isReplicated = false,
  times = [],
  setTokenLikeExpired,
  isCreateWorkingHours,
  workingHoursId,
}: TUpdateWorkingHours) {
  const requestOptions = {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  };

  try {
    if (isCreateWorkingHours) isCreateWorkingHours(true);

    const { data } = await axios.put(
      `${process.env.REACT_APP_WORKING_HOURS_API_URL}/${workingHoursId}`,
      {
        day_title: dayTitle,
        dropdown_to_bottom: dropdownToBottom,
        is_24_hours: is24Hours,
        is_open: isOpen,
        is_replicated: isReplicated,
        times,
      },
      requestOptions,
    );

    return data;
  } catch (err: any) {
    if (err.response?.status === 401 && setTokenLikeExpired) return setTokenLikeExpired();

    return null;
  } finally {
    if (isCreateWorkingHours) isCreateWorkingHours(false);
  }
}
