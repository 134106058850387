import React from 'react';

import ConfigCard from '../../../components/config-card';

import {
  StyledDeletedLocations,
} from './deleted-locations-styles';

const HandlerList = ({ navigation }) => (
  <ConfigCard
    title="Locais Apagados"
    onClick={() => navigation.push('/deleted-locations/list')}
  >
    <StyledDeletedLocations>
      Gerência de locais apagados pelo usuário proprietário
    </StyledDeletedLocations>
  </ConfigCard>
);

export default HandlerList;
