import {
  facebookAPIGetPicture,
  facebookAPIGetCover,
  facebookAPIPageCategories,
  facebookAPIGetPageLocations,
} from '../../../../../services/facebook';

export const FacebookLogin = ({
  reduxDispatch, setFacebookData, setIsLogin, setIsFetchingPlatform, snackbar,
}) => {
  window.FB.login(responseLogin => {
    if (responseLogin?.authResponse) {
      const { authResponse } = responseLogin;

      const {
        accessToken: userToken,
        expiresIn: userExpiresIn,
        userID,
      } = authResponse;

      window.FB.api('/oauth/access_token', 'GET', {
        grant_type: 'fb_exchange_token',
        client_id: `${process.env.REACT_APP_FACEBOOK_APP_ID}`,
        client_secret: `${process.env.REACT_APP_FACEBOOK_APP_SECRET}`,
        fb_exchange_token: `${userToken}`,
      }, async (responseLongToken) => {
        if (!responseLongToken) return;

        const { access_token: longToken } = responseLongToken;

        window.FB.api(`${userID}/accounts?access_token${longToken}?limit=1000`, 'get', async ({ data: pagesData }) => {
          window.FB.api('/me', { fields: 'name, first_name' }, async (responseMe) => {
            const { name: userName, first_name: userFirstName } = responseMe;

            const userPicture = await facebookAPIGetPicture({ userID, token: longToken });
            const userPageCategories = await facebookAPIPageCategories(longToken);

            const pages = pagesData ? await Promise.all(pagesData.map(async (page) => {
              const cover = await facebookAPIGetCover({ pageID: page.id, token: longToken });
              const locations = await facebookAPIGetPageLocations({ userToken: longToken, pageID: page.id });

              return {
                id: page.id,
                token: page.access_token,
                category: page.category,
                name: page.name,
                cover,
                locations,
              };
            })) : [];

            reduxDispatch(setFacebookData({
              userAuthResponse: authResponse,
              userID,
              userName,
              userFirstName,
              userToken: longToken,
              userExpiresIn,
              userPicture,
              userPages: pages,
              userPageCategories,
            }));

            setIsLogin(false);
            setIsFetchingPlatform(false);
          });
        });
      });
    } else {
      snackbar('Erro ao tentar se conectar com o Facebook. Tente novamente!', { variant: 'error' });
      setIsLogin(false);
      setIsFetchingPlatform(false);
    }
  }, {
    perms: 'pages_manage_cta, pages_read_user_content, pages_show_list, business_management, pages_manage_metadata, email, public_profile',
  });
};
