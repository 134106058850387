import React from 'react';
import { DialogTitle } from '@material-ui/core';

import { StyledDialog } from './complete-verification-dialog-styles';

import Input from '../../../../../input';
import Button from '../../../../../button';
import Loading from '../../../../../loading';
import Snackbar from '../../../../../snackbar';

import completeGoogleVerification from '../../../../../../services/google/completeGoogleVerification';

const CompleteVerificationDialog = ({
  isOpen, handleClose, loading, googleAccessToken, googleId, locationId, verificationId,
}) => {
  const [pinCode, setPincode] = React.useState('');
  const [isFetching, setIsFetching] = React.useState(false);
  const [snackbarOpen, setSnackbarOpen] = React.useState(false);
  const [snackbarMessage, setSnackbarMessage] = React.useState('');
  const [snackbarSeverity, setSnackbarSeverity] = React.useState('success');

  const handleChangePinCode = ({ target: { value } }) => {
    setPincode(value);
  };

  const onSendClicked = async (event) => {
    event.preventDefault();
    setIsFetching(true);
    try {
      await completeGoogleVerification(googleAccessToken, googleId, locationId, verificationId, pinCode);
      setSnackbarSeverity('success');
      setSnackbarMessage('Verificação confirmada com sucesso');
    } catch (err) {
      console.log(err.response);
      setSnackbarSeverity('error');
      setSnackbarMessage('Algo deu errado, verifique que o código está correto e tente novamente');
    } finally {
      setIsFetching(false);
      setSnackbarOpen(true);
    }
  };

  const handleCloseSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setSnackbarOpen(false);
    if (snackbarSeverity === 'success') handleClose();
  };

  return (
    <StyledDialog open={isOpen} onClose={handleClose}>
      <DialogTitle>
        <span className="dialog-title">Digite o código de confirmação</span>
      </DialogTitle>
      <div className="container">
        {loading ? <Loading /> : (
          <>
            <form onSubmit={onSendClicked}>
              <Input
                disabled={isFetching || snackbarOpen}
                label="Código"
                inputOptions={{ name: 'pinCode' }}
                border
                value={pinCode}
                onChange={handleChangePinCode}
              />
            </form>
            <div className="button-div">
              <Button
                disabled={isFetching || snackbarOpen}
                type="submit"
                onClick={onSendClicked}
              >
                {isFetching ? <Loading /> : 'Enviar'}
              </Button>
            </div>
          </>
        )}
      </div>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        severity={snackbarSeverity}
        message={snackbarMessage}
        onClose={handleCloseSnackbar}
      />
    </StyledDialog>
  );
};

export default CompleteVerificationDialog;
