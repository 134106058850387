import React from 'react';
import * as R from 'ramda';
import { useSelector } from 'react-redux';

import { useUncontrolledField } from '../../../../../hooks';

import {
  Button,
  OverlayLoading,
} from '../../../../../components';

import { responseTextScheme } from './fields-scheme';

import {
  StyledAddAutomaticResponses,
  StyledNewAutomaticResponse,
  StyledAddResponseBottomContainer,
  StyledRadioButton,
  StyledRadioWrapper,
  StyledRadioGroupContainer,
  StyledRadioGroupContainerContent,
  StyledActiveStar,
  StyledLabel,
} from './add-automatic-response-styles';

import getAutoResponse from '../../../../../services/auto-responses/getOne';

const AddAutomaticResponse = ({
  open,
  onClose,
  onConfirmClicked,
  isUpdate = false,
  id = null,
  loading,
  feedbackMessage,
}) => {
  const [fields, setFields] = React.useState([]);
  const [selectedStarValue, setSelectedStarValue] = React.useState(0);
  const [defaultText, setDefaultText] = React.useState('');

  const { user: { accessToken } } = useSelector(state => state.AuthReducer);

  const { Field: FieldAutomaticResponse } = useUncontrolledField({
    fieldScheme: responseTextScheme,
    saveIn: setFields,
  });

  const fetchAutoResponse = React.useCallback(async () => {
    if (id) {
      const data = await getAutoResponse({ accessToken, id });
      setDefaultText(data.text);
      setSelectedStarValue(data.star_rating);
    }
  }, [id]);

  React.useEffect(() => { fetchAutoResponse(); }, [fetchAutoResponse]);

  React.useEffect(() => {
    if (R.isEmpty(defaultText)) return;
    if (R.isEmpty(fields)) return;

    const [fieldAutomaticResponse] = fields;
    fieldAutomaticResponse.setDefaultValue(defaultText);
  }, [defaultText]);

  const handleStarValueChange = (event) => {
    if (!R.isEmpty(fields)) {
      fields.forEach(({ setDefaultValue, getValue }) => setDefaultValue(getValue()));
    }
    setSelectedStarValue(Number.parseInt(event.target.value));
  };

  const handleConfirmClicked = () => {
    if (R.isEmpty(fields[0].getValue())) {
      feedbackMessage('Digite o corpo da reposta', { variant: 'warning' });
      return;
    }

    if (selectedStarValue === 0) {
      feedbackMessage('A classificação da resposta é obrigatória', { variant: 'warning' });
      return;
    }

    if (isUpdate) {
      onConfirmClicked(id, fields[0].getValue(), selectedStarValue);
      return;
    }

    onConfirmClicked(fields[0].getValue(), selectedStarValue);
  };

  return (
    <StyledAddAutomaticResponses
      open={open}
      onClose={onClose}
    >
      {loading && <OverlayLoading textToLoading={isUpdate ? 'Atualizando...' : 'Cadastrando'} />}
      <StyledNewAutomaticResponse>
        <h3>{isUpdate ? 'Atualizar uma resposta' : 'Cadastrar um nova resposta'}</h3>

        <FieldAutomaticResponse />

        <StyledAddResponseBottomContainer>
          <StyledRadioGroupContainer>
            <StyledLabel>
              <StyledActiveStar />
              <span>Classificação</span>
            </StyledLabel>

            <StyledRadioGroupContainerContent>
              {[1, 2, 3, 4, 5].map((item) => (
                <StyledRadioWrapper key={item}>
                  <StyledRadioButton
                    checked={selectedStarValue === item}
                    onChange={handleStarValueChange}
                    value={item}
                    label={item}
                  />
                  {item}
                </StyledRadioWrapper>
              ))}
            </StyledRadioGroupContainerContent>
          </StyledRadioGroupContainer>

          <Button
            disabled={loading}
            onClick={handleConfirmClicked}
            className="create-automatic-response-button"
          >
            {isUpdate ? 'Atualizar' : 'Cadastrar'}
          </Button>
        </StyledAddResponseBottomContainer>
      </StyledNewAutomaticResponse>
    </StyledAddAutomaticResponses>
  );
};

export default AddAutomaticResponse;
