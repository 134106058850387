import { Paper } from '@material-ui/core';
import styled from 'styled-components';
import { devices } from '../../../assets/styles/devices';

export const StyledContainer = styled.main`
  width: 100%;
  padding: 2rem;
`;

export const StyledTitle = styled.h2`
`;

export const StyledInfo = styled(Paper)`
  display: flex;
  padding: 2rem;
  align-items: center;
  margin-bottom: 2rem;
`;

export const StyledContent = styled.section`
  background-color: white;
  padding: 2rem;
`;

export const StyledDatesWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex-basis: 32rem;
  flex-grow: 1;
  gap: 2rem;
  width: 100%;

  @media ${devices.mobile} {
    flex-direction: row;
  }
`;

export const StyledMonitorButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-bottom: 2rem;
`;

export const StyledInput = styled.input`
  width: 5rem;
  margin-right: 1rem;
`;
