import React from 'react';

import { TableBodyRow, IconButton, TableBodyCell } from '../../../components';

import { TRow } from './types';
import { StyledCellCustomButtons } from './row-styles';

export const Row = ({ dataTableOptions, rowData }: TRow) => {
  const currentRowData = React.useMemo(() => rowData, [rowData]);

  return (
    <TableBodyRow
      hiddenCheckbox
      dataRow={currentRowData}
      dataTableOptions={dataTableOptions}
    >
      {currentRowData.map(
        (dataColumnCell: any) => dataColumnCell?.visible && (
        <TableBodyCell
          isCentered={dataColumnCell?.columnRef === 'platform'}
        >
          {dataColumnCell?.value}
        </TableBodyCell>
        ),
      )}
      <TableBodyCell>
        <StyledCellCustomButtons>
          <IconButton
            tooltip="Ver dados"
            icon="Visibility"
            onClick={() => {}}
          />
          <IconButton tooltip="Ver dados" icon="Refresh" onClick={() => {}} />
        </StyledCellCustomButtons>
      </TableBodyCell>
    </TableBodyRow>
  );
};
