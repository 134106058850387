import styled from 'styled-components';
import { shade } from 'polished';
import { Paper } from '@material-ui/core';
import { SpeakerNotesOff as SpeakerNotesOffIcon } from '@material-ui/icons';
import { devices } from '../../../assets/styles/devices';

export const StyledReviewsListing = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    max-width: 90rem;

    .fade-in-effect {
        width: 100%;
    }

    .google-reviews-pagination {
      width: 100%;
      background: ${props => shade(0.1, props.theme.colors.white)};
      border-radius: 0.5rem;
    }

    .google-reviews-pagination--bottom {
      background: ${props => props.theme.colors.white};
    }
`;

export const StyledReviewsByLocation = styled.ul`
    margin-top: 2rem;
    display: flex;
    flex-direction: column;
`;

export const StyledReviewsByLocationLocation = styled.li`
    display: flex;
    flex-direction: column;
`;

export const StyledReviewByLocationHeader = styled.header`
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 2rem 2rem 6rem;
    margin-bottom: -3.5rem;
    background: ${props => props.theme.colors.primary};
    border-top-left-radius: 0.5rem;
    border-top-right-radius: 0.5rem;

    h3 {
        color: ${props => props.theme.colors.white};
        font-size: 3rem;
    }
`;

export const StyledReviewListingByLocation = styled.ul`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    overflow: hidden;
    border-radius: 0.5rem;
    width: 100%;
    box-shadow: 0px 2px 1px -1px 
                rgb(0 0 0 / 20%), 0px 1px 1px 0px 
                rgb(0 0 0 / 14%), 0px 1px 3px 0px 
                rgb(0 0 0 / 12%);
`;

export const StyledAllAnswered = styled.span`
  font-size: 1.8rem;
  margin-bottom: 3rem;
  margin-top: 2rem;
  padding: 2rem;
  background: ${props => props.theme.colors.primary};
  color: ${props => props.theme.colors.white};
  display: block;
  border-radius: 0.5rem;
`;

export const StyledGooglePaginationWrapper = styled(Paper)`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin: 4rem 0 2rem;
  padding: 2rem;
  border-radius: 0.5rem;

  
  h3 {
    font-size: 2rem;
    margin-bottom: 0.5rem;
  }
  
  small {
    font-size: 1.4rem;
    color: ${props => props.theme.colors.red};
    margin-bottom: 1rem;
    display: flex;
  }
  
  h4 {
    font-size: 1.6rem;
  }
`;

export const StyledReviewsInfos = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-top: 2rem;
  padding-top: 2rem;
  border-top: 0.1rem solid ${props => shade(0.1, props.theme.colors.white)};
  width: 100%;
  
  .filterBy,
  p {
    display: flex;
    align-items: center;
    margin: 0;
    font-size: 1.4rem;
    
    b {
      margin-left: 0.5rem;
      color: ${props => props.theme.colors.red};
    }
  }
  
  .filterBy {
    align-items: flex-start;
    flex-direction: column;
  }
`;

export const StyledReviewsNotFound = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  max-width: 45rem;
  padding-top: 4rem;
  padding-bottom: 4rem;

  @media ${devices.mobile} {
    padding-top: 6rem;
  }

  .reviews-notfound-subtitle {
    text-align: center;
    font-size: 2rem;
    color: ${(props) => shade(0.4, props.theme.colors.white)};
  }
`;

export const StyledSpeakerNotesOff = styled(SpeakerNotesOffIcon)`
  width: 7rem;
  height: 7rem;
  margin-bottom: 2rem;
  fill: ${(props) => shade(0.3, props.theme.colors.white)};

  @media ${devices.mobile} {
    width: 10rem;
    height: 10rem;
  }
`;

