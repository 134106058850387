import React from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import {
  isNil, isEmpty, includes, toLower,
} from 'ramda';
import { useSnackbar } from 'notistack';
import { uniqueId } from 'lodash';

import * as R from 'ramda';

import getCompany from '../../services/companies/getCompany';
import APIGetValidGoogleAccessToken from '../../services/getValidGoogleAccessTokenFromLocation';
import APIGetCompanyLocationsGoogleLinked from '../../services/locations/getCompanyLocationsWithValidConnections';
import APIGetLocationsGroup from '../../services/locations/getAllGroupLocation';

import { getReviewsByLocations } from '../../services/google/reviews/get-reviews-by-locations';
import { getGoogleUserAccountsUtil } from '../../services/google/get-google-user-accounts.util';
import getLocationConnectionsByGoogleLocations from '../../services/location-connections/getLocationConnectionsByGoogleLocations';
import { getGoogleAccountsLocations } from '../../services/google/get-google-accounts-locations';

import type { TGoogleUserAccount } from '../../types/TGoogle';
import { EGroupType, TLocationEntity } from '../../types/TLocation';
import type { TRootStateRedux } from '../../types/TRootStateRedux';
import type {
  TSensitiveWord,
} from '../../types/TReviews';

import { useReviewsContext } from '../../contexts/reviews';

import { useAuth, useHasAccess } from '../../hooks';

import {
  HeaderToolbar, BackToTop, Loading, NoAccessCard,
} from '../../components';

import SensitiveWords from './sensitive-words';
import ReviewsListing from './reviews-listing';
import LocationsFilter from './locations-filter';

import {
  StyledReviews,
  StyledLoadingInfos,
  StyledContent,
} from './reviews-styles';
import getAllAccountIdsByCompanyOrLocationGroup from '../../services/locations/getAllAccountIdsByCompanyOrLocationGroup';

const Reviews = () => {
  const { hasAccess, guestType } = useHasAccess();

  const routerParams = useParams<{ location: string }>();

  const { activeCompanyId, showLocationsByCompany } = useSelector(
    (state: TRootStateRedux) => state.CompanyReducer,
  );
  const { activeLocationGroupId, showLocationsByGroup } = useSelector(
    (state: TRootStateRedux) => state.LocationGroupReducer,
  );
  const { activeLocation } = useSelector(
    (state: TRootStateRedux) => state.LocationReducer,
  );

  const { enqueueSnackbar } = useSnackbar();

  const activeCompanyOrLocationGroup = React.useMemo(() => {
    if (showLocationsByGroup) {
      if (!activeLocationGroupId) return null;
      return {
        id: activeLocationGroupId,
        type: EGroupType.LOCATIONS_GROUP,
      };
    }

    if (showLocationsByCompany) {
      if (!activeCompanyId) return null;
      return {
        id: activeCompanyId,
        type: EGroupType.COMPANY,
      };
    }

    return null;
  }, [
    activeCompanyId,
    activeLocationGroupId,
    showLocationsByCompany,
    showLocationsByGroup,
  ]);

  const {
    userId,
    userAccessToken,
    userSetTokenLikeExpired,
    userProfileName,
  } = useAuth();

  const {
    googleUserAccessToken,
    setGoogleUserAccessToken,
    googleUserAccounts,
    setGoogleUserAccounts,
    googleLocationsByAccountRef,
    setReviewsDataListing,
    setReviewsData,
    locationsToFilter,
    setLocationsToFilter,
    setGoogleLocationsByAccountId,
    sensitiveWordsToFilter,
    setSensitiveWordsToFilter,
    reviewsData,
    listAutomaticResponsesBy: { currentListBy },
    starRating: { currentStarRating },
    setGoogleReviewsInitialPageToken,
    setEllegibleLocations,
    ellegibleLocations,
    getLocationsToGetReviews,
  } = useReviewsContext();

  const [isLoading, setIsLoading] = React.useState(true);
  const [currentParentGetLocations, setCurrentParentGetLocations] = React.useState<number | null>(null);

  const setInitialLocationsToFilter = React.useCallback(async () => {
    const { location } = routerParams;

    if (R.isNil(location)) return;

    const {
      location_connections: locationConnections,
      name: locationName,
    } = activeLocation;

    const { connection_info: connectionInfo } = locationConnections[0];

    setLocationsToFilter((prevState) => [
      ...prevState,
      {
        googleLocationId: connectionInfo.google_location_id,
        name: locationName,
      },
    ]);
  }, []);

  React.useEffect(() => {
    setInitialLocationsToFilter();
  }, []);

  const reviewsFiltering = React.useCallback(() => {
    if (isEmpty(reviewsData)) return;

    const newReviewsToDataListing = reviewsData
      .filter((reviewItem) => {
        const { review } = reviewItem;

        if (isEmpty(sensitiveWordsToFilter) && isNil(sensitiveWordsToFilter)) return reviewItem;

        return sensitiveWordsToFilter.every((sensitiveWord) => {
          if (!sensitiveWord.isFiltering === true) return true;

          return includes(
            toLower(sensitiveWord.word),
            toLower(review.comment || ''),
          );
        });
      })
      .filter((reviewItem) => {
        if (currentListBy === 'answered') return R.has('reviewReply', reviewItem.review);
        if (currentListBy === 'unanswered') return !R.has('reviewReply', reviewItem.review);

        return true;
      })
      .filter((reviewItem) => {
        if (!R.isNil(currentStarRating.word)) {
          return reviewItem.review.starRating === currentStarRating.word;
        }

        return reviewItem;
      });

    setReviewsDataListing(newReviewsToDataListing);
  }, [reviewsData, currentStarRating, currentListBy, sensitiveWordsToFilter]);

  React.useEffect(() => {
    reviewsFiltering();
  }, [reviewsFiltering]);

  const getGoogleAccessToken = React.useCallback(async () => {
    if (!hasAccess) return;

    const getGoogleAccessTokenResponse = await APIGetValidGoogleAccessToken({
      accessToken: userAccessToken,
      userProfile: userProfileName,
      userId,
      feedbackMessage: enqueueSnackbar,
      locationId: activeLocation.id,
    });

    if (isNil(getGoogleAccessTokenResponse)) {
      setIsLoading(false);
      return;
    }
    const { googleAccessToken } = getGoogleAccessTokenResponse;
    setGoogleUserAccessToken(googleAccessToken);
    setIsLoading(false);
  }, [hasAccess]);

  React.useEffect(() => {
    getGoogleAccessToken();
  }, [getGoogleAccessToken]);

  const getCompanySensitiveWords = React.useCallback(async () => {
    const companyResponse = await getCompany({
      accessToken: userAccessToken,
      id: activeCompanyId,
    });

    if (R.isNil(companyResponse)) return;

    const { sensible_words: sensibleWords } = companyResponse;

    if (R.isNil(sensibleWords)) return;

    const sensitiveWordsNormalized: TSensitiveWord[] = sensibleWords.map(
      (word: string): TSensitiveWord => ({
        id: uniqueId(),
        word,
        isFiltering: false,
      }),
    );

    setSensitiveWordsToFilter(sensitiveWordsNormalized || []);
  }, [activeCompanyId]);

  React.useEffect(() => {
    getCompanySensitiveWords();
  }, []);

  const getGoogleLocations = React.useCallback(
    async (googleAccounts: { googleAccountId: string, refreshToken: string }[]) => {
      if (isEmpty(googleAccounts)) return;

      const getGoogleAccountsLocationsResponse = await getGoogleAccountsLocations({
        userAccessToken,
        googleAccounts,
        isFetching: setIsLoading,
      });

      if (isNil(getGoogleAccountsLocationsResponse)) return;

      Object.entries(getGoogleAccountsLocationsResponse).forEach(
        ([accountId, locations]) => {
          googleLocationsByAccountRef.current[accountId] = locations || [];
        },
      );
    },
    [googleUserAccessToken],
  );

  const getCompanyLocationsLinkedGoogle = React.useCallback(async (): Promise<
    TLocationEntity[]
  > => {
    const companyLocationsResponse = await APIGetCompanyLocationsGoogleLinked({
      accessToken: userAccessToken,
      companyId: activeCompanyId,
      pageSize: 1000,
      setTokenLikeExpired: userSetTokenLikeExpired,
      setIfFetching: setIsLoading,
    });

    if (isNil(companyLocationsResponse)) return [];

    const [companyLocationsData] = companyLocationsResponse;

    return companyLocationsData;
  }, [userAccessToken, activeCompanyId]);

  const getLocationsGroup = React.useCallback(async (): Promise<
    TLocationEntity[]
  > => {
    const locationsGroupResponse = await APIGetLocationsGroup({
      accessToken: userAccessToken,
      pageSize: 1000,
      setTokenLikeExpired: userSetTokenLikeExpired,
      locationGroupId: activeLocationGroupId,
      setIfFetching: setIsLoading,
      googleLinked: true,
    });

    if (isNil(locationsGroupResponse)) return [];

    const [locationsGroupData] = locationsGroupResponse;

    return locationsGroupData;
  }, [userAccessToken, activeLocationGroupId]);

  React.useEffect(() => {
    if (isNil(activeCompanyOrLocationGroup) || isNil(currentParentGetLocations)) {
      return;
    }

    if (currentParentGetLocations !== activeCompanyOrLocationGroup.id) {
      setLocationsToFilter([]);
    }
  }, [currentParentGetLocations, activeCompanyOrLocationGroup]);

  const getLocationsReviews = React.useCallback(async () => {
    const resetData = () => {
      setReviewsData([]);
      setReviewsDataListing([]);
    };
    if (isNil(googleUserAccessToken) || isNil(activeCompanyOrLocationGroup)) return resetData();

    const companyOrGroupAccountIds = await getAllAccountIdsByCompanyOrLocationGroup({
      accessToken: userAccessToken,
      groupId: activeCompanyOrLocationGroup.id,
      groupType: activeCompanyOrLocationGroup.type,
    });

    if (isNil(companyOrGroupAccountIds) || isEmpty(companyOrGroupAccountIds)) return resetData();

    if (isEmpty(companyOrGroupAccountIds)) return resetData();

    const accountsIdsWithoutLocations = companyOrGroupAccountIds.filter(({ googleAccountId }) => R.isNil(googleLocationsByAccountRef.current[googleAccountId]));

    if (!isEmpty(accountsIdsWithoutLocations)) { await getGoogleLocations(accountsIdsWithoutLocations); }

    const googleLocations = R.flatten(
      companyOrGroupAccountIds
        .map(({ googleAccountId }) => googleLocationsByAccountRef.current[googleAccountId])
        .filter((accountLocations) => !R.isNil(accountLocations)),
    );

    let currentEllegibleLocations = ellegibleLocations;

    if (currentParentGetLocations !== activeCompanyOrLocationGroup.id) {
      currentEllegibleLocations = activeCompanyOrLocationGroup.type === EGroupType.COMPANY
        ? await getCompanyLocationsLinkedGoogle()
        : await getLocationsGroup();
      setCurrentParentGetLocations(activeCompanyOrLocationGroup.id);
      setEllegibleLocations(currentEllegibleLocations);
    }

    if (isEmpty(currentEllegibleLocations)) return resetData();

    const googleLocationsToGetReviews = getLocationsToGetReviews({
      ellegibleLocations: currentEllegibleLocations,
      googleLocations,
      locationsFiltered: locationsToFilter,
      userId,
      guestType,
      userProfileName,
    });

    if (isEmpty(googleLocationsToGetReviews)) return resetData();

    const locationConnectionsForGoogleLocations = await getLocationConnectionsByGoogleLocations({
      googleLocationIds: googleLocations.map(({ name: googleLocationNameId }) => googleLocationNameId.split('/')[3]),
      accessToken: userAccessToken,
    });

    if (isEmpty(locationConnectionsForGoogleLocations)) return resetData();

    const reviewsByLocations = await getReviewsByLocations({
      userAccessToken,
      googleAccounts: companyOrGroupAccountIds,
      locations: googleLocationsToGetReviews,
      isFetching: setIsLoading,
    });

    if (isNil(reviewsByLocations)) return;

    const { reviews, googleLocationsByAccountId, nextPageToken } = reviewsByLocations;

    setReviewsData(reviews);
    setReviewsDataListing(reviews);
    setGoogleLocationsByAccountId(googleLocationsByAccountId);
    setGoogleReviewsInitialPageToken(nextPageToken);
  }, [
    activeCompanyOrLocationGroup,
    googleUserAccessToken,
    googleUserAccounts,
    locationsToFilter,
    getCompanyLocationsLinkedGoogle,
    getLocationsGroup,
  ]);

  React.useEffect(() => {
    getLocationsReviews();
  }, [getLocationsReviews]);

  return (
    <StyledReviews>
      <Helmet>
        <meta property="og:title" content="Avaliações - SaaS Hublocal" />
        <meta
          property="og:description"
          content="Gerêncie as avaliações de seus locais por plataforma."
        />

        <title>Avaliações - SaaS Hublocal</title>
      </Helmet>

      <HeaderToolbar
        title="Avaliações"
        paddingLeft
        overlayLoadingNoDisplay
        dropdownToggle
      />

      {!hasAccess && <NoAccessCard />}

      {hasAccess && (
        <StyledContent>
          {isLoading && (
            <StyledLoadingInfos>
              <Loading />
              <span className="loading-infos-label">
                Carregando informações...
              </span>
            </StyledLoadingInfos>
          )}

          {!isLoading && (
            <>
              <LocationsFilter />
              <SensitiveWords />
              <ReviewsListing />
            </>
          )}
        </StyledContent>
      )}
      <BackToTop />
    </StyledReviews>
  );
};

export default Reviews;
