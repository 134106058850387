import React from 'react';

import { StyledSwitch } from './switch-styles';

const Switch = ({
  checked,
  changeChecked,
  id,
  name,
  fieldRefSwitch,
  disabled,
  secondarySwitch,
}) => (
  <StyledSwitch
    ref={fieldRefSwitch}
    disabled={disabled}
    name={name}
    id={id}
    checked={checked}
    onClick={changeChecked}
    focusVisibleClassName="focus-visible"
    disableRipple
    secondarySwitch={secondarySwitch}
  />
);

export default Switch;
