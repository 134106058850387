import styled from 'styled-components';
import MUIDataTable from 'mui-datatables';
import { Button } from '..';
import { devices } from '../../assets/styles/devices';

export const StyledDataTable = styled(MUIDataTable)`
  width: 100%;
  border-radius: 0 !important;

  @media ${devices.tablet} {
    .MuiToolbar-root {
      padding-left: 3.2rem;
      padding-right: 3.2rem;
    }

    .MuiTable-root {
      th:first-child,
      td:first-child {
        ${(props) =>
          props.options?.selectableRows === 'none' &&
          'padding-left: 3.2rem;'}
      }
      th:last-child,
      td:last-child {
        ${(props) =>
          props.options?.selectableRows === 'none' &&
          'padding-right: 3.2rem;'}
      }
    }
  }

  .post-states {
    padding: 0.5rem 2rem;
    border-radius: 3rem;
    font-size: 1.2rem;
    color: ${props => props.theme.colors.white};
    
    &.LIVE {
      background: ${props => props.theme.colors.primary};
    }

    &.REJECTED {
      background: ${props => props.theme.colors.red};
    }
  } 

  .loading {
    margin: 0 auto;
  }

  .tag {
    color: ${props => props.theme.colors.white};
    border-radius: 5rem;
    padding: 0.5rem 2rem;
    text-transform: capitalize;
    font-size: 1.2rem;

    &.owner {
      background-color: ${props => props.theme.colors.primary};
    }

    &.guest {
      background-color: ${props => props.theme.colors.secondary};
    }

    &.locationGuest {
      background-color: ${props => props.theme.colors.god};
      white-space: nowrap;
    }
  }

  .MuiTypography-h6{
    font-family: Poppins;
    font-size: 2rem;
  }
  
  .MuiButton-label{
    font-size: 1.5rem;
  }
  .MuiTableCell-head {
    font-size: 1.5rem;
    font-weight: 600;
    font-family: Poppins;
  }

  .MuiTableCell-body {
    font-size: 1.5rem;
    font-weight: 400;
    font-family: Poppins;
  }

  .MuiSvgIcon-root {
    width: 2.4rem;
    height: 2.4rem;
    fill: ${props => props.theme.colors.primary};
  }

  .MuiPaper-root {
    background: ${props => props.theme.colors.primary};
    border-top-left-radius: 0;
    border-top-right-radius: 0;

    .MuiTypography-subtitle1 {
      font-size: 1.6rem;
      color: ${props => props.theme.colors.white};
    }
  }

  .MuiTablePagination-toolbar {
    .MuiTablePagination-caption,
    .MuiTablePagination-input {
      font-size: 1.5rem;
      font-family: Poppins;
    }
  }

  .MuiChip-label {
    font-size: 1.5rem;
  }

  .MuiToolbar-root {
    div[class*="MUIDataTableToolbar-actions"] {
      display: flex;
      align-items: center;
      flex-direction: row-reverse;
    }
  }

  .MuiInputBase-root   {
    font-size: 1.4rem;
  }

  .MuiPaper-elevation1 {
      .MuiSvgIcon-root {
        fill: ${props => props.theme.colors.white};
      }
  }
`;

export const StyledCellCustomButtons = styled.div`
  display: flex;

  button {
    color: ${props => props.theme.colors.white};

    svg {
      fill: ${props => props.theme.colors.white} !important;
    }
  }
`;

export const StyledEmptyTableContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2rem 0;

  h4 {
    font-weight: 400;
    font-family: Poppins;
  }
`;

export const StyledButton = styled(Button)`
  margin-top: 1rem;
`;

export const StyledImg = styled.img`
  height: 5rem;
  object-fit: cover;
`;
