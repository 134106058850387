import styled from 'styled-components';

export const StyledContent = styled.div`
    width: 60%; 
    display: grid; 
    grid-gap: 1em`;

export const StyledRow = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
`;
