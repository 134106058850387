import { lighten } from 'polished';
import styled, { css } from 'styled-components';
import { devices } from '../../../../assets/styles/devices';

export const StyledSectionTitle = styled.h3`
    font-size: 1.4rem;
    font-weight: bold;
    color: ${props => props.theme.colors.primary};
    margin-bottom: 1.5rem;
    line-height: 1;
`;

export const StyledFieldsWrapper = styled.div<{threeColumns?: boolean, storeCode?: boolean}>`
    width: 100%;
    display: grid;
    grid-template-columns: 1fr;
    row-gap: 2rem;
    margin-bottom: 2rem;

    @media ${devices.desktop} {
        grid-template-columns: repeat(2, 1fr);
        column-gap: 2rem;
    }

    ${props => props.threeColumns && css`
        @media ${devices.fullhd} {
            grid-template-columns: repeat(3, 1fr);
        }
    `}

    ${props => props.storeCode && css`
        @media ${devices.tablet} {
            grid-template-columns: repeat(3, 1fr);
        }

        @media ${devices.tabletLandscape} {
            grid-template-columns: repeat(4, 1fr);
        }

        @media ${devices.desktop} {
            grid-template-columns: repeat(5, 1fr);
        }
    `}
`;

export const StyledSeparator = styled.div`
    width: 100%;
    border-bottom: 1px solid ${props => lighten(0.9, props.theme.colors.black)};
    margin: 3rem 0;

    &:last-of-type {
        margin-bottom: 0;
    }
`;
