import styled from 'styled-components';

export const StyledContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;

    padding: 2rem;
`;

export const StyledButtonsWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 2rem;
  padding: 1rem;
  justify-content: center;
  align-items: center;

  .confirm-button,
  .cancel-button {
    min-width: 22rem;
  }

  .data-studio-loading {
    height: 4rem;
    width: 4rem;
  }
`;
