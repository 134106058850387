import React from 'react';

import { isEmpty, isNil } from 'ramda';
import {
  findOneBatchInfos,
  updateBatchInfos,
} from '../../../services/google-batch-modules/batch-infos';

import type { EBatchPlatformStatus } from '../../../types/TBatchGoogleModules';
import type {
  TBatchInfosRowColumnsName,
  TBatchInfosTableRowBaseProps,
  TRenderCellDataBatchInfosRow,
} from '../../../types/TBatchUpdate';

import { useAuth } from '../../../hooks';

import {
  IconButton,
  Loading,
  TableBodyCell,
  TableBodyRow,
  Dialog,
  BatchStatusIcon,
} from '../../../components';

import { BatchInfosLocationsTable } from './batch-infos-locations-table';

import {
  StyledActionLoadingWrap,
  StyledCellCustomButtons,
} from './batch-infos-row-styles';
import {
  batchInfosConstants,
  getDataRowCellValue,
  updateRowDataByColumn,
} from '../helpers';
import { InfosPopover } from './infos-popover';
import { InfosUpdatedIcons } from './infos-updated-icons';

export const BatchInfosRow = ({
  rowData,
  dataTableOptions,
}: TBatchInfosTableRowBaseProps) => {
  const { userAccessToken, userProfileName } = useAuth();

  const [currentRowData, setCurrentRowData] = React.useState<any[]>([]);

  const [checked, setChecked] = React.useState(false);
  const [isExpandable, setIsExpandable] = React.useState(false);
  const [actionLoading, setActionLoading] = React.useState(false);
  const [fetchingStateLoading, setFetchingStateLoading] = React.useState(false);

  const [anchorEl, setAnchorEl] = React.useState(null);
  const infosPopoverOpen = Boolean(anchorEl);
  const infosPopoverId = infosPopoverOpen ? 'infos-popover' : undefined;

  const [openAffectedInfosDialog, setOpenAffectedInfosDialog] = React.useState(false);

  React.useEffect(() => {
    setCurrentRowData(rowData);
  }, [rowData]);

  const batchInfosId = React.useMemo(
    () => getDataRowCellValue({
      columnRef: 'id',
      dataRowCell: rowData,
    }),
    [rowData],
  );

  const batchInfosStatus = React.useMemo(
    () => getDataRowCellValue({
      columnRef: 'status',
      dataRowCell: currentRowData,
    }),
    [currentRowData],
  );

  const dataInfos = React.useMemo(() => {
    const currentBatchInfos = rowData.find(
      (rowDataCell: any) => rowDataCell.columnRef === 'data-infos',
    );

    return currentBatchInfos.value;
  }, [rowData]);

  const renderCellData = React.useMemo<TRenderCellDataBatchInfosRow>(() => ({
    id: (value) => value,
    'applied-informations': () => (
      <InfosUpdatedIcons
        basicInfos={!isNil(dataInfos.basic_infos)}
        links={!isNil(dataInfos.links)}
        workingHours={!isNil(dataInfos.working_hours) && !isEmpty(dataInfos.working_hours)}
        specialDates={!isNil(dataInfos.special_dates) && !isEmpty(dataInfos.special_dates)}
      />
    ),
    'updated-at': (value) => value,
    'created-at': (value) => value,
    status: (value) => (fetchingStateLoading ? (
      'Atualizando status...'
    ) : (
      <BatchStatusIcon status={value} batchType="info" />
    )),
    actions: (value) => value,
  }), [fetchingStateLoading, dataInfos]);

  const showBatchInfosCancel = React.useMemo(() => {
    const userAsAdminOrOperator = userProfileName !== 'Usuário Padrão';
    if (
      batchInfosStatus === 'Pendente'
      && !actionLoading
      && userAsAdminOrOperator
    ) { return true; }
    return false;
  }, [actionLoading, batchInfosStatus]);

  const onCancel = React.useCallback(async () => {
    const currentBatchInfos = rowData.find(
      (rowDataCell: any) => rowDataCell.columnRef === 'id',
    );

    const batchInfosResponse = await updateBatchInfos({
      batchInfosId: currentBatchInfos.value,
      userAccessToken,
      status: 'CANCELING' as EBatchPlatformStatus.CANCELING,
      isUpdating: setActionLoading,
    });

    if (batchInfosResponse.status === 'ERROR') return;

    const { status } = batchInfosResponse.data;

    setCurrentRowData((prevState: any) => updateRowDataByColumn({
      rowData: prevState,
      payload: {
        status: status as EBatchPlatformStatus,
      },
    }));
  }, [currentRowData, setCurrentRowData]);

  const getBatchInfos = React.useCallback(async () => {
    setFetchingStateLoading(true);

    const batchGetInfosResponse = await findOneBatchInfos({
      batchInfosLocationId: batchInfosId,
      userAccessToken,
    });

    if (batchGetInfosResponse.status === 'ERROR') {
      setTimeout(() => setFetchingStateLoading(false), 800);
      return;
    }

    const { status } = batchGetInfosResponse.data;

    setTimeout(() => setFetchingStateLoading(false), 500);
    setCurrentRowData((prevState: any) => updateRowDataByColumn({
      rowData: prevState,
      payload: {
        status: status as EBatchPlatformStatus,
      },
    }));
  }, [batchInfosId]);

  React.useEffect(() => {
    const getBatchInfosLocationsByInterval = setInterval(() => {
      const cellDataStatus = currentRowData.find(
        (cellData: any) => cellData.columnRef === 'status',
      );
      if (
        cellDataStatus.value === 'PENDING'
        || cellDataStatus.value === 'PROCESSING'
        || cellDataStatus.value === 'CANCELING'
      ) { getBatchInfos(); }
    }, batchInfosConstants.BATCH_INFOS_FETCH_STATUS_SETINTERVAL);

    return () => clearInterval(getBatchInfosLocationsByInterval);
  }, [currentRowData]);

  return (
    <TableBodyRow
      key={batchInfosId}
      dataTableOptions={dataTableOptions}
      checked={checked}
      setChecked={setChecked}
      isExpandable={isExpandable}
      setIsExpandable={setIsExpandable}
      subTable={(data: any) => (
        <BatchInfosLocationsTable
          batchInfos={data}
          updateBatchInfos={getBatchInfos}
        />
      )}
      dataRow={currentRowData}
      subTableColSpan={6}
      hiddenCheckbox
    >
      {currentRowData.map(
        (dataColumnCell: any) => dataColumnCell?.visible && (
        <TableBodyCell>
          {renderCellData[
                dataColumnCell.columnRef as TBatchInfosRowColumnsName
          ]?.(dataColumnCell.value)}
        </TableBodyCell>
        ),
      )}
      <TableBodyCell>
        {actionLoading ? (
          <StyledActionLoadingWrap>
            <Loading className="action-loading" />
            Aplicando ação...
          </StyledActionLoadingWrap>
        ) : (
          <StyledCellCustomButtons>
            <IconButton
              tooltip="Visualizar informações afetadas"
              icon="VisibilityOutlined"
              onClick={() => setOpenAffectedInfosDialog(true)}
            />
            {showBatchInfosCancel && (
              <IconButton
                tooltip="Cancelar"
                icon="Cancel"
                onClick={onCancel}
              />
            )}
          </StyledCellCustomButtons>
        )}
      </TableBodyCell>

      {openAffectedInfosDialog && (
        <Dialog
          onClose={() => setOpenAffectedInfosDialog(false)}
          open={openAffectedInfosDialog}
          title="Informações afetadas"
          wideDialog
        >
          <InfosPopover
            id={infosPopoverId}
            data={dataInfos}
          />
        </Dialog>
      )}
    </TableBodyRow>
  );
};
