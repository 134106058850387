import React from 'react';

import {
  StyledWhatsapp,
} from './whatsapp-styles';

const Whatsapp = () => (
  <StyledWhatsapp>
    Uber
  </StyledWhatsapp>
);

export default Whatsapp;
