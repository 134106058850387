import * as R from 'ramda';

import INITIAL_FIELDS from './fields';

export const notShowField = ({ notShowList, currentPlatform }) => {
  const currentPlatformList = currentPlatform.trim().split(', ');

  return currentPlatformList.every(currentPlatformItem => {
    const notShowFieldThisPlatform = R.includes(currentPlatformItem);
    return !notShowFieldThisPlatform(notShowList);
  });
};

export const mountDataToRenderField = (fieldsToTable) => {
  const newFields = [];

  R.forEachObjIndexed(value => {
    newFields.push(value);
  }, fieldsToTable);

  return newFields;
};

export const convertBooleanInYesOrNo = (boolExpression) => (boolExpression ? 'Sim' : 'Não');

const filterPhone = (phone) => {
  if (phone) return phone.replace(/[_() -]/g, '');
};

const filterZipCode = (zipCode) => {
  if (zipCode) {
    return zipCode.replace(/[-. ]/g, '');
  }
};

export const mountFieldsToRender = ({
  dataFromLocation,
  dataFromPlatform,
}) => {
  const data = mountDataToRenderField({
    ...INITIAL_FIELDS,
    city: {
      ...INITIAL_FIELDS.city,
      valueHublocal: dataFromLocation ? dataFromLocation.city : null,
      valuePlatform: dataFromPlatform ? dataFromPlatform.city : null,
    },
    name: {
      ...INITIAL_FIELDS.name,
      valueHublocal: dataFromLocation ? dataFromLocation.name : null,
      valuePlatform: dataFromPlatform ? dataFromPlatform.name : null,
    },
    address: {
      ...INITIAL_FIELDS.address,
      valueHublocal: dataFromLocation ? dataFromLocation.address1 : null,
      valuePlatform: dataFromPlatform ? dataFromPlatform.street : null,
    },
    complement: {
      ...INITIAL_FIELDS.complement,
      valueHublocal: dataFromLocation ? dataFromLocation.complementLocal : null,
      valuePlatform: dataFromPlatform ? dataFromPlatform.address2 : null,
    },
    email: {
      ...INITIAL_FIELDS.email,
      valueHublocal: dataFromLocation ? dataFromLocation.email : null,
      valuePlatform: dataFromPlatform ? dataFromPlatform.email : null,
    },
    state: {
      ...INITIAL_FIELDS.state,
      valueHublocal: dataFromLocation ? dataFromLocation.state : null,
      valuePlatform: dataFromPlatform ? dataFromPlatform.state : null,
    },
    phone: {
      ...INITIAL_FIELDS.phone,
      valueHublocal: dataFromLocation ? filterPhone(dataFromLocation.phone) : null,
      valuePlatform: dataFromPlatform ? filterPhone(dataFromPlatform.phone) : null,
    },
    secondaryPhone: {
      ...INITIAL_FIELDS.secondaryPhone,
      valueHublocal: dataFromLocation ? filterPhone(dataFromLocation.secondary_phone) : null,
      valuePlatform: dataFromPlatform ? filterPhone(dataFromPlatform.secondaryPhone) : null,
    },
    tertiaryPhone: {
      ...INITIAL_FIELDS.tertiaryPhone,
      valueHublocal: dataFromLocation ? dataFromLocation.tertiary_phone : null,
      valuePlatform: null,
    },
    zipcode: {
      ...INITIAL_FIELDS.zipcode,
      valueHublocal: dataFromLocation ? filterZipCode(dataFromLocation.zip_code) : null,
      valuePlatform: dataFromPlatform ? filterZipCode(dataFromPlatform.zip) : null,
    },
    doesNotAttend: {
      ...INITIAL_FIELDS.doesNotAttend,
      valueHublocal: dataFromLocation ? convertBooleanInYesOrNo(dataFromLocation.does_not_serve_at_this_address) : null,
      valuePlatform: dataFromPlatform ? convertBooleanInYesOrNo(dataFromPlatform.does_not_serve_at_this_address) : null,
    },
    hangTags: {
      ...INITIAL_FIELDS.hangTags,
      valueHublocal: dataFromLocation ? dataFromLocation.labels : null,
      valuePlatform: dataFromPlatform ? dataFromPlatform.labels : null,
    },
    hours: {
      ...INITIAL_FIELDS.hours,
      valueHublocal: dataFromLocation ? dataFromLocation.working_hours[0] : null,
      valuePlatform: dataFromPlatform ? dataFromPlatform.hours : null,
    },
    country: {
      ...INITIAL_FIELDS.country,
      valueHublocal: dataFromLocation ? dataFromLocation.country : null,
      valuePlatform: dataFromPlatform ? dataFromPlatform.country : null,
    },
    neighborhood: {
      ...INITIAL_FIELDS.neighborhood,
      valueHublocal: dataFromLocation ? dataFromLocation.neighborhood : null,
      valuePlatform: dataFromPlatform ? dataFromPlatform.neighborhood : null,
    },
    shortDescription: {
      ...INITIAL_FIELDS.shortDescription,
      valueHublocal: dataFromLocation ? dataFromLocation.short_description : null,
      valuePlatform: dataFromPlatform ? dataFromPlatform.shortDescription : null,
    },
    longDescription: {
      ...INITIAL_FIELDS.longDescription,
      valueHublocal: dataFromLocation ? dataFromLocation.long_description : null,
      valuePlatform: dataFromPlatform ? dataFromPlatform.longDescription : null,
    },
    website: {
      ...INITIAL_FIELDS.website,
      valueHublocal: dataFromLocation ? dataFromLocation.website : null,
      valuePlatform: dataFromPlatform ? dataFromPlatform.website : null,
    },
    googleCategories: {
      ...INITIAL_FIELDS.googleCategories,
      valueHublocal: dataFromLocation ? dataFromLocation.google_categories : null,
      valuePlatform: dataFromPlatform ? dataFromPlatform.google_categories : null,
    },
    facebookCategories: {
      ...INITIAL_FIELDS.facebookCategories,
      valueHublocal: dataFromLocation ? dataFromLocation.facebook_categories : null,
      valuePlatform: dataFromPlatform ? dataFromPlatform.categories : null,
    },
    facebook: {
      ...INITIAL_FIELDS.facebook,
      valueHublocal: dataFromLocation ? dataFromLocation.facebook : null,
      valuePlatform: dataFromPlatform ? dataFromPlatform.link : null,
    },
  });

  return data || null;
};
