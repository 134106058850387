import React from 'react';
import {
  Search as SearchIcon,
} from '@material-ui/icons';
import { debounce } from 'lodash';

import filterSearch from '../../../../utils/filter-search';

import Header from './header';
import ListingItem from './listing-item';

import {
  StyleListingDialog,
  StyledPagesListing,
  StyledSearch,
  StyledTotalListing,
} from './listing-dialog-styles';

const ListingDialog = ({
  allowCreate = false,
  open,
  handleClose,
  data,
  foundData,
  handleBindWithLocation,
  handleVinculatePlaceWithAccount,
  headerTitle,
  headerTitleCreateButton,
  handleHeaderCreateButton,
  image,
  keyToFilter,
  name,
  description,
  noImages = false,
}) => {
  const [searchInput, setSearchInput] = React.useState('');

  const handleChangeInputSearch = debounce(text => setSearchInput(text), 500);

  return (
    <StyleListingDialog
      open={open}
      onClose={handleClose}
    >
      <Header
        allowCreate={allowCreate}
        headerTitle={headerTitle}
        createButtonTitle={headerTitleCreateButton}
        handleCreateTitle={handleHeaderCreateButton}
        hasItems={data.length > 0}
      />
      <StyledSearch>
        <input
          type="text"
          placeholder="Pesquisar"
          onChange={e => handleChangeInputSearch(e.target.value)}
        />
        <div className="search-icon">
          <SearchIcon />
        </div>
      </StyledSearch>
      <StyledTotalListing>
        Total:
        {' '}
        <small>{data.length}</small>
      </StyledTotalListing>
      <StyledPagesListing>
        {data.length > 0 ? data.filter(item => filterSearch({ item, key: keyToFilter, query: searchInput })).map(item => (
          <ListingItem
            key={item.id}
            id={item.id}
            item={item}
            name={item[name]}
            image={item[image]}
            description={item[description] || null}
            handleBind={handleBindWithLocation}
            noImages={noImages}
            sublisting={item.locations}
            keyToFilter={keyToFilter}
          />
        )) : (
          <span>
            Nenhum registro encontrado
          </span>
        )}
      </StyledPagesListing>
    </StyleListingDialog>
  );
};

export default ListingDialog;
