import React from 'react';
import NavItem from '../nav-item';
import SubMenuItem from './submenu-item';

import {
  StyledSubMenu,
} from './submenu-styles';

const SubMenu = ({
  items,
  setIsOpenSideMenu,
  openAccordionMenu,
  setOpenAccordionMenu,
}) => (
  <StyledSubMenu>
    {items
      .filter(item => item.name !== 'Modelos')
      .map(({
        id: itemId,
        name: itemName,
        icon: itemIcon,
        functions: itemSubmenu,
      }) => (
        <SubMenuItem
          key={itemId}
          itemId={itemId}
          itemName={itemName}
          itemIcon={itemIcon}
          itemSubmenu={itemSubmenu}
          setIsOpenSideMenu={setIsOpenSideMenu}
          openAccordionMenu={openAccordionMenu}
          setOpenAccordionMenu={setOpenAccordionMenu}
        />
      ))}
  </StyledSubMenu>
);

export default SubMenu;
