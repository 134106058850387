import axios from 'axios';

export default async function sendEmailInvite({
  accessToken,
  inviteId,
  setTokenLikeExpired,
  isLoading,
}) {
  try {
    if (isLoading) isLoading(true);

    const requestOptions = {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    };

    const { data } = await axios.post(
      `${process.env.REACT_APP_INVITES_API_URL}/send-email/${inviteId}`,
      null,
      requestOptions,
    );

    return data;
  } catch (err) {
    if (err.response?.status === 401 && setTokenLikeExpired) return setTokenLikeExpired();

    return null;
  } finally {
    if (isLoading) isLoading(false);
  }
}
