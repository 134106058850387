import React from 'react';
import { MUIDataTableColumn } from 'mui-datatables';
import { uniqueId } from 'lodash';

import { findBatchInfosLocations } from '../../../../services/google-batch-modules/batch-infos';

import { useAuth } from '../../../../hooks';

import type { TTicketBatchInfosLocations } from '../../../../types/TTickets';
import type { TTableData, TTableRowData } from '../../../../types/TTable';
import type { TDefaultPropOption } from '../../../../types/TSelect';

import { Table, SelectMultiple } from '../../../../components';

import { LocationRow } from './location-row';

import { StyledTicketBatchInfosLocations } from './ticket-batch-infos-locations-styles';

export const TicketBatchInfosLocations = ({
  batchInfosId,
}: TTicketBatchInfosLocations) => {
  const { userAccessToken } = useAuth();

  const [batchInfosLocations, setBatchInfosLocations] = React.useState<TTableData>([]);
  const [batchInfosLocationsCount, setBatchInfosLocationsCount] = React.useState(0);
  const [batchInfosLocationsQuery, setBatchInfosLocationsQuery] = React.useState('');
  const [batchInfosLocationsPageSize, setBatchInfosLocationsPageSize] = React.useState(10);
  const [batchInfosLocationsPage, setBatchInfosLocationsPage] = React.useState(0);
  const [isFetching, setIsFetching] = React.useState(false);
  const [pendingAffectedPlatfomsOption, setPendingAffectedPlatfomsOptions] = React.useState<TDefaultPropOption[]>([
    {
      name: 'FACEBOOK',
      value: 'FACEBOOK',
      id: uniqueId(),
    },
    {
      name: 'GOOGLE',
      value: 'GOOGLE',
      id: uniqueId(),
    },
    {
      name: 'YELP',
      value: 'YELP',
      id: uniqueId(),
    },
    {
      name: 'FOURSQUARE',
      value: 'FOURSQUARE',
      id: uniqueId(),
    },
    {
      name: 'LINKEDIN',
      value: 'LINKEDIN',
      id: uniqueId(),
    },
  ]);
  const [pendingAffectedPlatforms, setPendingAffectedPlatforms] = React.useState<string[]>([]);

  const getBatchInfosLocationsByBatchInfos = React.useCallback(async () => {
    const batchInfosLocationsResponse = await findBatchInfosLocations({
      batchInfosId,
      userAccessToken,
      isFetching: setIsFetching,
      page: batchInfosLocationsPage,
      pageSize: batchInfosLocationsPageSize,
      query: batchInfosLocationsQuery,
      affectedPlatforms: pendingAffectedPlatforms.join(', '),
    });

    if (batchInfosLocationsResponse.status === 'ERROR') return;

    const [batchInfosLocationsData, batchInfosLocationsDataCount] = batchInfosLocationsResponse.data;

    const batchInfosLocationsDataNormalized = batchInfosLocationsData.map(
      (batchInfosLocation: any): TTableRowData => [
        {
          columnRef: 'id',
          visible: false,
          value: batchInfosLocation.id,
        },
        {
          columnRef: 'locationName',
          visible: true,
          value: batchInfosLocation.name,
        },
        {
          columnRef: 'locationAddress',
          visible: true,
          value: batchInfosLocation.location_address,
        },
        {
          columnRef: 'affectedPlatforms',
          visible: false,
          value: batchInfosLocation.affected_platforms,
        },
      ],
    );

    setBatchInfosLocations(batchInfosLocationsDataNormalized);
    setBatchInfosLocationsCount(batchInfosLocationsDataCount);
  }, [
    batchInfosId,
    batchInfosLocationsQuery,
    batchInfosLocationsPage,
    batchInfosLocationsPageSize,
    batchInfosLocationsQuery,
    pendingAffectedPlatforms,
  ]);

  React.useEffect(() => {
    getBatchInfosLocationsByBatchInfos();
  }, [getBatchInfosLocationsByBatchInfos]);

  const tableColumns = React.useMemo<MUIDataTableColumn[]>(
    () => [
      {
        label: 'Id',
        name: 'id',
        options: {
          display: false,
          filter: false,
        },
      },
      {
        label: 'Nome do local',
        name: 'locationName',
        options: {
          filter: false,
        },
      },
      {
        label: 'Endereço',
        name: 'locationAddress',
        options: {
          filter: false,
        },
      },
      {
        label: 'Plataformas (status)',
        name: 'platformsStatus',
        options: {
          filter: true,
          filterType: 'custom',
          sort: false,
          filterOptions: {
            display: () => (
              <SelectMultiple
                label="Filtrar por plataformas pendentes"
                options={pendingAffectedPlatfomsOption}
                name="pending-affected-platfosm"
                value={pendingAffectedPlatforms}
                onChange={(value) => setPendingAffectedPlatforms(value)}
              />
            ),
          },
        },
      },
    ],
    [pendingAffectedPlatfomsOption, pendingAffectedPlatforms],
  );

  return (
    <StyledTicketBatchInfosLocations>
      <Table
        setQuery={setBatchInfosLocationsQuery}
        setPage={setBatchInfosLocationsPage}
        setPageSize={setBatchInfosLocationsPageSize}
        columns={tableColumns}
        data={batchInfosLocations}
        title="Locais afetados"
        options={{
          count: batchInfosLocationsCount,
          page: batchInfosLocationsPage,
          selectableRowsHideCheckboxes: true,
        }}
        row={(rowData) => <LocationRow rowData={rowData} hiddenCheckbox />}
        loading={isFetching}
      />
    </StyledTicketBatchInfosLocations>
  );
};
