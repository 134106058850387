import axios from 'axios';

export default async function updateLinkModel(
  accessToken,
  linkModelId,
  name,
  facebook,
  instagram,
  twitter,
  youtube,
  foursquare,
  linkedin,
  yelp,
  website,
  other_links,
) {
  const requestOptions = {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  };

  const { data } = await axios.put(
    `${process.env.REACT_APP_LINK_MODEL_API_URL}/${linkModelId}`,
    {
      name,
      facebook,
      instagram,
      twitter,
      youtube,
      foursquare,
      linkedin,
      yelp,
      website,
      other_links,
    },
    requestOptions,
  );

  return data;
}
