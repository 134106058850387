export enum EBIDataRowStatus {
  INITIAL_LOAD = 'INITIAL_LOAD',
  DAILY_LOAD = 'DAILY_LOAD',
  RE_PROCESSING = 'RE_PROCESSING',
  BACKUP = 'BACKUP',
  WARNING = 'WARNING',
}

export enum EPlatforms {
  FACEBOOK = 'FACEBOOK',
  GOOGLE = 'GOOGLE',
  FOURSQUARE = 'FOURSQUARE',
  YELP = 'HELP',
}

export enum EBIModule {
  GOOGLE_LOCATION_REVIEWS = 'GOOGLE_LOCATION_REVIEWS',
  GOOGLE_LOCATION_INFOS = 'GOOGLE_LOCATION_INFOS',
  GOOGLE_LOCATION_POSTS = 'GOOGLE_LOCATION_POSTS',
  GOOGLE_LOCATION_MEDIAS = 'GOOGLE_LOCATION_MEDIAS',
}
