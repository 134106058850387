import axios, { AxiosResponse } from 'axios';
import {
  TBatchGalleryLocation,
  TUpdateBatchGalleryLocation,
} from '../../../types/TBatchGallery';

export const updateBatchGalleryLocation = async ({
  batchGalleryLocationId,
  userAccessToken,
  status,
  isUpdating,
  affectedTicketPlatforms,
}: TUpdateBatchGalleryLocation): Promise<{
  status: 'ERROR' | 'SUCCESS';
  data: TBatchGalleryLocation | object;
}> => {
  try {
    if (isUpdating) isUpdating(true);

    const { data }: AxiosResponse<TBatchGalleryLocation> = await axios.put(
      `${process.env.REACT_APP_SAAS_BATCH_GOOGLE_MODULES}/gallery-batch-locations/${batchGalleryLocationId}`,
      {
        status,
        affected_platforms: affectedTicketPlatforms,
      },
      {
        headers: {
          Authorization: `Bearer ${userAccessToken}`,
        },
      }
    );

    return {
      status: 'SUCCESS',
      data,
    };
  } catch (err: any) {
    return {
      status: 'ERROR',
      data: err.response.data,
    };
  } finally {
    if (isUpdating) isUpdating(false);
  }
};
