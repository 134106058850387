import axios from 'axios';

export default async function getVenueDetails(accessToken, venueId) {
  const requestOptions = {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  };

  try {
    const { data: { response } } = await axios.get(`${process.env.REACT_APP_FOURSQUARE_API_URL}/details/${venueId}`, requestOptions);

    return response;
  } catch (err) {
    return err.respoonse;
  }
}
