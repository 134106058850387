import styled from 'styled-components';
import { Dialog } from '@material-ui/core';
import { GrClose } from 'react-icons/gr';

export const StyledGalleryBatchForm = styled(Dialog)`
  .MuiDialog-paper {
    max-width: 60%;
    max-height: 90%;
    background: #E5E5E5;

    border-radius: 12px 12px 0 0;
  }
`;

export const StyledDialogHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;

  background: #F2F2F2;
  border-bottom: 1px solid #000000;
`;

export const StyledCloseIcon = styled(GrClose)`
  cursor: pointer;
`;

export const StyledDialogBody = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 2rem;

  padding: 1rem;
`;

export const StyledDialogForm = styled.form`
  width: 100%;
  height: max-content;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: 1rem;

  background: #F2F2F2;
  gap: 1rem;

  .loading-icon {
    margin-right: 0.5rem;
  }
`;

export const StyledDialogSubmitButton = styled.button`
  color: #FFF;
  background: #0385FD;
  padding: 1rem;
  border: 0;
  border-radius: 0.5rem;

  ::after {
    content: 'Publicar';
  }
`;

export const LoadingContainer = styled.div`
  width: 100%;
  height: 100%;

  display: flex;
  justify-content: center;
  align-items: center;
`;
