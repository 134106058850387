import styled from 'styled-components';
import { lighten, shade } from 'polished';

export const StyledReceiveInvite = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-width: 60rem;
  width: 100%;
  padding: 0 2rem;
  margin: auto;
  margin-top: 4rem;
  margin-bottom: 4rem;
  position: relative;

  .hublocal-logo {
    max-width: 20rem;
    margin-bottom: 2rem;
  }
`;

export const StyledForm = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: 40rem;

  .confirm-button {
    margin-bottom: 0;
  }

  small {
    font-size: 1.4rem;
    text-align: center;
    margin-bottom: 2rem;
  }
`;

export const StyledFieldsGroup = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  row-gap: 2rem;
  margin-bottom: 2rem;
  width: 100%;
`;

export const StyledExpiredInvite = styled.span`
  padding: 1rem 2rem;
  border-radius: 0.5rem;
  background: ${props => lighten(0.40, props.theme.colors.red)};
  color: ${props => shade(0.40, props.theme.colors.red)};
`;
