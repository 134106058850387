import React from 'react';

import {
  StyledAppleMaps,
} from './apple-maps-styles';

const AppleMaps = () => (
  <StyledAppleMaps>
    AppleMaps
  </StyledAppleMaps>
);

export default AppleMaps;
