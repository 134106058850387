import React from 'react';
import { useSelector } from 'react-redux';

import {
  getCategoryGroupingPaginated,
} from '../../../services/category-groupings';

import ConfiCard from '../../../components/config-card';

import {
  StyledContent,
  StyledLog,
  StyledDisclaimer,
  StyledQuantityGroups,
} from './category-grouping-styles';

const CategoryGrouping = ({ navigation }) => {
  const { user: { accessToken } } = useSelector(state => state.AuthReducer);

  const [quantiyOfCategoryGroupings, setQuantiyOfCategoryGroupings] = React.useState(0);

  const loadQuantityOfCategoryGroupings = React.useCallback(async () => {
    const response = await getCategoryGroupingPaginated({
      accessToken,
    });

    if(response?.totalGroupings) setQuantiyOfCategoryGroupings(response?.totalGroupings);
  }, [accessToken]);

  React.useEffect(() => {
    loadQuantityOfCategoryGroupings();
  }, [loadQuantityOfCategoryGroupings]);

  return (
    <ConfiCard
      title="Agrupamento de categorias"
      onClick={() => navigation.push('/category-grouping')}
    >
      <StyledContent>
        <StyledLog>
          Grupos cadastrados:
          {' '}
          <StyledQuantityGroups>{quantiyOfCategoryGroupings}</StyledQuantityGroups>
        </StyledLog>
        <StyledLog>
          <span>
            <StyledDisclaimer>Você não tem nenhum plataforma conectada.</StyledDisclaimer>
            {' '}
            Para cadastrar um grupo de categorias precisa ter no minímo
            duas plataformas conectadas.
          </span>
        </StyledLog>
      </StyledContent>
    </ConfiCard>
  );
};

export default CategoryGrouping;
