import React from 'react';
import * as R from 'ramda';

import APIGetActiveLocation from '../../../../../services/locations/getLocation';

import type {
  TSpecialDateEntity,
  TSpecialDatesBatchBaseProps,
  TWorkingHoursContext,
} from '../../../../../types/TWorkingHours';

import type { TLocationEntity } from '../../../../../types/TLocation';

import { Loading } from '../../../../../components';

import { useAuth } from '../../../../../hooks';

import WorkinkHoursContext from '../working-hours-context';

import CurrentDates from './current-dates';
import AddSpecialDate from './add-special-date';

import { StyledSectionTitle } from '../working-hours-styles';

import {
  StyledSpecialDates,
  StyledContent,
} from './special-dates-styles';

const SpecialDates = ({ activeLocationId }: TSpecialDatesBatchBaseProps) => {
  const { setSpecialDates } = React.useContext<TWorkingHoursContext>(WorkinkHoursContext);

  const {
    userAccessToken,
    userSetTokenLikeExpired,
    userId,
  } = useAuth();

  const [specialDatesData, setSpecialDatesData] = React.useState <TSpecialDateEntity[]>([]);
  const [currentSpecialDates, setCurrentSpecialDates] = React.useState<TSpecialDateEntity[]>([]);
  const [specialDateToEdit, setSpecialDateToEdit] = React.useState<TSpecialDateEntity | null>(null);
  const [activeLocationInfos, setActiveLocationInfos] = React.useState<TLocationEntity | null>(null);
  const [isLoadingActiveLocationInfos, setIsLoadingActiveLocationInfos] = React.useState(false);

  const getActiveLocationInfos = React.useCallback(async () => {
    if (R.isNil(activeLocationId)) return;

    const activeLocationInfosResponse = await APIGetActiveLocation({
      accessToken: userAccessToken,
      setTokenLikeExpired: userSetTokenLikeExpired,
      id: activeLocationId,
      setIsFetching: setIsLoadingActiveLocationInfos,
    });

    if (R.isNil(activeLocationInfosResponse)) return;

    setActiveLocationInfos(activeLocationInfosResponse);
  }, [activeLocationId]);

  React.useEffect(() => { getActiveLocationInfos(); }, [getActiveLocationInfos]);

  React.useEffect(() => {
    if (R.isNil(activeLocationInfos)) return;

    const { special_dates: activeLocationSpecialDate } = activeLocationInfos;

    if (R.isNil(activeLocationSpecialDate) || R.isEmpty(activeLocationSpecialDate)) {
      setSpecialDatesData([]);

      return;
    }

    setCurrentSpecialDates(activeLocationSpecialDate);
    setSpecialDatesData(activeLocationSpecialDate);
    setSpecialDates(activeLocationSpecialDate);
  }, [activeLocationInfos]);

  const handleHasSpecialDates = (data: TSpecialDateEntity[]) => {
    if (R.isNil(data)) return false;
    if (R.isEmpty(data)) return false;

    return true;
  };

  return (
    <StyledSpecialDates>
      <StyledSectionTitle>
        {isLoadingActiveLocationInfos && <Loading className="is-loading-infos" />}

        Datas especiais
      </StyledSectionTitle>
      {!R.isNil(activeLocationInfos) && (
        <StyledContent>
          <AddSpecialDate
            hasSpeciaDates={handleHasSpecialDates(specialDatesData)}
            activeLocation={activeLocationInfos}
            currentSpecialDates={currentSpecialDates}
            specialDateToEdit={specialDateToEdit}
            setSpecialDateToEdit={setSpecialDateToEdit}
            userAccessToken={userAccessToken}
            userSetTokenLikeExpired={userSetTokenLikeExpired}
            userId={userId}
            getActiveLocationInfos={getActiveLocationInfos}
          />
          <CurrentDates
            data={specialDatesData}
            activeLocation={activeLocationInfos}
            setSpecialDateToEdit={setSpecialDateToEdit}
            getActiveLocationInfos={getActiveLocationInfos}
          />
        </StyledContent>
      )}
    </StyledSpecialDates>
  );
};

export default SpecialDates;
