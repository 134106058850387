import styled from 'styled-components';
import { shade } from 'polished';

export const StyledResponseOption = styled.li`
    font-size: 1.4rem;
    padding: 2rem;
    display: flex;
    align-items: center;
    cursor: pointer;
    transition: 0.3s;

    &:nth-child(even) {
        background: ${props => shade(0.05, props.theme.colors.white)};
    }

    &:hover {
        background: ${props => props.theme.colors.secondary};
        color: ${props => props.theme.colors.white};
    }
`;
