import React from 'react';

import { IconButton } from '..';

import {
  StyledDialog,
  StyledDialogContent,
  StyledDialogTitle,
  StyledDialogTitleWrapper,
} from './dialog-styles';

export type TDialogProps = {
	children: React.ReactElement;
	open: boolean;
	onClose: () => void;
	title?: string | null;
  wideDialog?: boolean;
  closeIcon?: boolean;
  maxWidth?: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | false;
  fullWidth?: boolean;
  isHeaderGray?: boolean;
  isHeaderBlue?: boolean;
}

export default ({
  children,
  open,
  onClose,
  title = '',
  wideDialog = false,
  closeIcon = false,
  maxWidth,
  fullWidth,
  isHeaderGray = false,
  isHeaderBlue = false,
}: TDialogProps) => (
  <StyledDialog
    open={open}
    onClose={onClose}
    wideDialog={wideDialog}
    maxWidth={maxWidth}
    fullWidth={fullWidth}
  >
    <>
      {title && (
      <StyledDialogTitle isHeaderBlue={isHeaderBlue}>
        <StyledDialogTitleWrapper
          isHeaderGray={isHeaderGray}
          isHeaderBlue={isHeaderBlue}
        >
          <span className="title">{title}</span>
          <IconButton
            icon="Close"
            tooltip="Fechar"
            onClick={() => onClose()}
            isWhite={isHeaderBlue}
          />
        </StyledDialogTitleWrapper>
      </StyledDialogTitle>
      )}
      <StyledDialogContent>
        {children}
      </StyledDialogContent>
    </>
  </StyledDialog>
);
