import axios from 'axios';

import type {
  TGetActionLog,
  TLocalPostActionLogEntity,
} from '../../types/TBatchGoogleModules';

const getActionLog = async ({
  accessToken,
  postId,
}: TGetActionLog): Promise<TLocalPostActionLogEntity[] | null> => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_SAAS_BATCH_GOOGLE_MODULES}/local-posts-action-log/${postId}`,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      },
    );

    return response.data;
  } catch (err) {
    return null;
  }
};

export default getActionLog;
