import styled, { css } from 'styled-components';
import { Dialog, DialogContent } from '@material-ui/core';
import { lighten } from 'polished';
import { TDestructiveActionDialogProps } from './destructive-action-dialog';

type TStyledDialogProps = Pick<TDestructiveActionDialogProps, 'wideDialog'>;

export const StyledDialog = styled(Dialog)`
  .MuiDialog-paperWidthSm {
    ${(props: TStyledDialogProps) =>
      props.wideDialog &&
      css`
        width: 100%;
      `}
  }
`;

export const StyledDialogWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const StyledDialogHeader = styled.header`
  display: flex;
  justify-content: center;
  align-items: center;
  border-bottom: 1px solid ${(props) => lighten(0.4, props.theme.colors.black)};
  padding: 1rem 0;

  h1 {
    font-size: 1.8rem;
    font-weight: 700;
  }
`;

export const StyledDialogContent = styled(DialogContent)`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2rem 4rem;

  p {
    font-weight: 700;
    color: ${(props) => props.theme.colors.primary};
    text-align: center;
    &:last-child {
      margin: 0;
    }
  }
`;

export const StyledDialogButtonsWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 2rem;
  padding: 1rem;
  margin-bottom: 2rem;
  justify-content: center;
  align-items: center;

  .confirm-button,
  .cancel-button {
    min-width: 22rem;
  }
`;
