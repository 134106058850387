export const parseCategoriesToString = ({ categories, propToGet }) => JSON.parse(categories).map(category => category[propToGet]).join(', ');

export const renderCategoriesData = (data) => data.map(item => ({
  name: item.name,
  categories: parseCategoriesToString({
    categories: item.categories,
    propToGet: item.propToGet,
  }),
}));

export const normalizedGroupingsData = groupingsData => groupingsData.map(grouping => {
  const {
    facebook_categories,
    google_categories,
    foursquare_categories,
  } = grouping;

  const categories = renderCategoriesData([
    {
      name: 'Facebook',
      categories: facebook_categories,
      propToGet: 'name',
    },
    {
      name: 'Google',
      categories: google_categories,
      propToGet: 'name',
    },
    {
      name: 'Foursquare',
      categories: foursquare_categories,
      propToGet: 'name',
    },
  ]);

  return {
    ...grouping,
    categories,
  };
});
