import styled, { css } from 'styled-components';
import { lighten } from 'polished';

import { devices } from '../../../../assets/styles/devices';

export const StyledTicketInfos = styled.div`
    display: flex;
    flex-direction: column;
    padding: 3rem 3rem;
    background: ${props => props.theme.colors.white};
    margin-bottom: 0.2rem;

    h3 {
        font-size: 3rem;
        margin-bottom: 2rem;
    }

    .is-apply-loading {
        margin-right: 1rem;
    }
`;

export const StyledContent = styled.div`
    display: flex;
    flex-direction: column;
`;

export const StyledBasicInfos = styled(StyledContent)`
    display: grid;
    grid-template-columns: 1fr;
    row-gap: 2rem;
    margin-bottom: 2rem;

    @media ${devices.desktop} {
        grid-template-columns: repeat(2, 1fr);
        column-gap: 2rem;
    }

    @media ${devices.widescreen} {
        grid-template-columns: repeat(4, 1fr);
    }
`;

export const StyledActions = styled(StyledContent)``;

export const StyledActionsOptions = styled.div`
    display: grid;
    grid-template-columns: 1fr;
    row-gap: 2rem;

    @media ${devices.tabletLandscape} {
        grid-template-columns: repeat(3, 1fr);
    }

    @media ${devices.desktop} {
        grid-template-columns: repeat(3, 1fr);
    }

    @media ${devices.fullhd} {
        grid-template-columns: repeat(4, 1fr);
    }

    ${props => props.hasOperator && css`
        grid-template-columns: 1fr;
    `}
`;

export const StyledWrapInfo = styled.div`
    display: flex;
    flex-direction: column;

    span {
        font-size: 1.4rem;
    }

    small {
        color: ${props => props.theme.colors.primary};
    }
`;

export const StyledWrapInfoTitle = styled.h4`
    font-size: 1.6rem;
    color: ${props => props.theme.colors.primary};
`;

export const StyledRequesterInfo = styled.div`
    display: flex;
    flex-direction: column;
    margin-bottom: 0;
`;

export const StyledResponsibleInfo = styled.div`
    display: flex;
    flex-direction: column;
    margin-bottom: 2rem;
`;

export const StyledInfosSelect = styled.div`
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    max-width: 50rem;

    .ticket-select {
        margin-right: 1.5rem;
        max-width: 30rem;
    }

    .apply-button {
        margin-bottom: 0.5rem;
    }
`;

export const StyledComment = styled.div`
    display: flex;
    flex-direction: column;
    margin-top: 2rem;

    .comment-to-done {
        margin-top: 0.5rem;
    }
    
    .add-comment-button {
        max-width: 260rem;
        margin-top: 2rem;
        margin-left: auto;
    }
`;

export const StyledCommentList = styled.ul`
    padding: 2rem;
    background: ${props => lighten(0.97, props.theme.colors.black)};
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-top: 1rem;
`;

export const StyledCommentItem = styled.li`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    color: ${props => props.theme.colors.black};
    padding-bottom: 2rem;
    margin-bottom: 2rem;
    border-bottom: 1px solid ${props => lighten(0.85, props.theme.colors.black)};

    &:last-child {
        padding-bottom: 0;
        margin-bottom: 0;
        border-bottom: none;
    }

    &:nth-child(even) {
        .comment-name {
            color: ${props => props.theme.colors.secondary};
        }

        .comment-avatar-letter {
            background: ${props => props.theme.colors.secondary};
        }
        
    }

    .comment-profile {
        display: flex;
        align-items: center;
        margin-bottom: 1rem;
    }

    .comment-infos {
        display: flex;
        flex-direction: column;
    }

    .comment-avatar-letter {
        width: 4rem;
        height: 4rem;
        border-radius: 3rem;
        background: ${props => props.theme.colors.primary};
        color: ${props => props.theme.colors.white};
        font-weight: bold;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 1rem;
    }

    .comment-name {
        font-weight: bold;
        margin-bottom: 0;
        line-height: 1;
        color: ${props => props.theme.colors.primary};
    }

    .comment-text {
        font-size: 1.4rem;
        font-style: italic;
        padding-left: 5rem;
    }
`;

export const StyledDisclaimer = styled.small`
    color: ${props => props.theme.colors.red};
    margin-bottom: 1rem;
`;
