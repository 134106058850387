export const warningMessageType = {
  notLinked: 'Notamos que seu local ainda não está conectado com nenhuma plataforma (Google, Facebook). Logo, algumas funções ficarão indisponíveis enquanto o processo de conexão não for realizado. Deseja realizar a conexão?',
  defaultLinked: 'Notamos que seu local possui a conexão padrão realizada pela equipe da Hublocal. Para uma melhor performance, conecte os locais a sua conta de email. Deseja realizar a conexão?',
  companyWithUnlinked: 'Algumas funções ficarão indisponíveis ou limitadas para esses locais enquanto sua conexão não for realizada. Deseja realizar as conexões?',
};

export const companyWithUnlinkedText = {
  mainMessage: 'Existem Locais que estão com a conexão pendente:',
  subMessage: 'Os locais abaixo terão suas funcionalidades bloqueadas se não estiverem conectados.',
  countMessage: (count: number) => (count > 6 ? `E outros ${count - 5} locais` : 'E mais 1 outro local'),
};
