import React from 'react';

import Loading from '../loading';
import Button from '../button';

import {
  StyledConfigCard,
  StyledHeader,
  StyledButtonsContainer,
  StyleContent,
} from './config-card-styles';

const ConfigCard = ({
  title = 'Sem título',
  children,
  onClick,
  buttonTitle = 'Administrar',
  loading = false,
}) => (
  <StyledConfigCard>
    <StyledHeader>
      <h3>{title}</h3>
    </StyledHeader>
    <StyleContent>
      {children}
    </StyleContent>
    <StyledButtonsContainer>
      <Button
        disabled={loading}
        type="submit"
        onClick={onClick}
      >
        {loading
          ? <Loading />
          : buttonTitle }
      </Button>
    </StyledButtonsContainer>
  </StyledConfigCard>
);

export default ConfigCard;
