import axios from 'axios';

export default async function getCompanyWorkingHoursModels({
  accessToken,
  id,
  setTokenLikeExpired,
}) {
  const requestOptions = {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  };

  try {
    const { data } = await axios.get(
      `${process.env.REACT_APP_COMPANIES_API_URL}/get-relation/working_hours_models/${id}`,
      requestOptions,
    );

    return data;
  } catch (err) {
    if (err.response?.status === 401 && setTokenLikeExpired) return setTokenLikeExpired();

    console.log(err);
  }
}
