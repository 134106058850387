import styled from 'styled-components';
import { devices } from '../../assets/styles/devices';

export const StyledLoginHistory = styled.div`
  display: flex;
  flex-direction: column;
`;

export const StyledContent = styled.div`
  display: grid;
  grid-template-columns: 1;
  row-gap: 2rem;
  padding: 4rem 2rem 2rem;

  @media ${devices.desktop} {
    grid-template-columns: 30% 68%;
    column-gap: 2rem;
  }

  @media ${devices.widescreen} {
    grid-template-columns: 25% 72%;
  }
`;
