import styled from 'styled-components';
import { Paper } from '@material-ui/core';

import { devices } from '../../assets/styles/devices';

export const StyledResponsibleInfo = styled.main`
  display: flex;
  flex-direction: column;

  .button {
    min-width: 20rem;
  }

  .add-responsible {
    max-width: 22rem;
    width: 100%;
    margin-left: auto;
  }
`;

export const StyledTitlePage = styled.h3`
  display: flex;
  font-weight: 700;
  font-size: 1.5rem;
  align-self: center;
  margin-bottom: 0;
  margin-right: 0.5rem;
`;

export const StyledResponsibleInfoTitleWrapper = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 1.5rem;
`;

export const StyledResponsibleInfoFields = styled(Paper)`
  display: flex;
  flex-direction: column;
  padding: 3.2rem 2rem;

  .button-create-responsible {
    max-width: 30.3rem;
    margin-left: auto;
  }
`;

export const StyledDisclaimer = styled.span`
  font-size: 1.2rem;
  font-weight: 400;
  color: ${props => props.theme.colors.red}; 
  margin-bottom: 2.3rem;
`;

export const StyledTitleCreateResponsible = styled.span`
  font-size: 1.5rem;
  font-weight: 600;
  margin-bottom: 2rem;
`;

export const StyledForm = styled.form`
    display: grid;
    grid-template-columns: 1fr;
    row-gap: 2rem;
`;

export const StyledFields = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  row-gap: 2rem;

  @media ${devices.desktop} {
    grid-template-columns: repeat(3, 1fr);
    column-gap: 2rem;
  }
`;

export const StyledCardListing = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  row-gap: 2rem;
  margin-bottom: 3rem;

  @media ${devices.tabletLandscape} {
    grid-template-columns: repeat(2, 1fr);
    column-gap: 2rem;
  }

  @media ${devices.desktop} {
    grid-template-columns: repeat(3, 1fr);
  }

  @media ${devices.fullhd} {
    grid-template-columns: repeat(4, 1fr);
  }
`;

export const StyledButtonsEditing = styled.div`
  display: flex;
  flex-direction: column;

  @media ${devices.tabletLandscape} {
    flex-direction: row;
    margin-left: auto;
  }
  
  .button {
    margin-bottom: 1.5rem;

  @media ${devices.tabletLandscape} {
    margin-bottom: 0;
    margin-right: 1.5rem;
  }

    &:last-of-type {
      margin-bottom: 0;
      margin-right: 0;
    }
  }
`;
