import styled from 'styled-components';

export const StyledListItemWrapper = styled.div<{isLastOfSection: boolean}>`
  display: flex;
  align-items: center;
  justify-content: space-around;
  margin: ${({ isLastOfSection }) => (isLastOfSection ? '1rem 3rem 0 3rem' : '1rem 3rem')};
  width: calc(100% - 6rem);
  border-bottom:  ${({ isLastOfSection }) => (isLastOfSection ? 'none' : '1px solid #757575')};
  
  button.MuiIconButton-root {
    margin-top: -14px;
    margin-right: 20px;
  }

  `;

export const StyledListItem = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin: 0 auto 0 0;
  width: 70%;

  p.title {
    font-weight: bold;
  }

  p {
    font-size: 1.4rem;
  }
`;
