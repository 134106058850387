import axios from 'axios';

import {
  TUpdateContractType,
  TUpdateContractTypeResponse,
} from '../../types/TContractTypes';

export default async function updateContractType({
  accessToken,
  locationId,
  contractId,
  setIsFetching,
  setTokenLikeExpired,
  feedbackMessage,
}: TUpdateContractType): Promise<TUpdateContractTypeResponse | null> {
  try {
    if (setIsFetching) setIsFetching(true);
    if (feedbackMessage) feedbackMessage('Alterando tipo de contrato', { variant: 'warning' });

    const requestOptions = {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    };

    const { data } = await axios.put(
      `${process.env.REACT_APP_LOCATIONS_API_URL}/update-contract/${locationId}`,
      {
        contractId,
      },
      requestOptions,
    );

    if (feedbackMessage) feedbackMessage('Contrato alterado com sucesso!', { variant: 'success' });
    return data;
  } catch (err: any) {
    if (err.response?.status === 401 && setTokenLikeExpired) setTokenLikeExpired();

    if (feedbackMessage) feedbackMessage('Erro ao alterar contrato', { variant: 'error' });
    return null;
  } finally {
    if (setIsFetching) setIsFetching(false);
  }
}
