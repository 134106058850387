import { MUIDataTableColumn } from 'mui-datatables';

import {
  isNil,
  isEmpty,
  prop,
  has,
  uniq,
  groupBy,
} from 'ramda';
import {v4 as uuidv4} from 'uuid';

import { TGoogleMoney } from '../../types/TGoogle';
import { TServiceItem } from '../../types/TLocationService';
import type {
  TUpdateRowDataByColumn,
  TGetDataRowCellValue,
  TTableRowData,
} from '../../types/TTable';

import { convertGooogleMoneyToNumber } from '../../utils/google-money';

export type TServiceType = 'free-form' | 'structured';

export type TServiceTableColumnName = 'service' | 'price' | 'serviceType';

export type TRenderCellDataServiceTableRow = Record<
  TServiceTableColumnName,
  (value: any) => any
>;

export const initialTableColumns: MUIDataTableColumn[] = [
  {
    name: 'id',
    label: 'Id',
    options: { sort: false, viewColumns: false, display: false },
  },
  {
    name: 'service',
    label: 'Serviço',
    options: { sort: false },
  },
  {
    name: 'price',
    label: 'Preço',
    options: { sort: false },
  },
  {
    name: 'operations',
    label: 'Operações',
    options: { sort: false },
  },
  {
    name: 'serviceType',
    label: 'Tipo',
    options: { sort: false, viewColumns: false, display: false },
  },
  {
    name: 'isDuplicate',
    label: 'Duplicado',
    options: { sort: false, viewColumns: false, display: false },
  },
];

export const getDataRowCellValue = ({
  dataRowCell,
  columnRef,
}: TGetDataRowCellValue) => {
  const currentRowDataCell = dataRowCell.find(
    (rowDataCell: any) => rowDataCell && rowDataCell.columnRef === columnRef,
  );

  return !isNil(currentRowDataCell) ? currentRowDataCell.value : '';
};

export const updateRowDataByColumn = ({
  rowData,
  payload,
}: TUpdateRowDataByColumn): any[] => rowData.map((cellData: any) => {
  if (!isNil(cellData) && has(cellData.columnRef, payload)) {
    return {
      ...cellData,
      value: prop(cellData.columnRef, payload),
    };
  }

  return cellData;
});

export const getServiceDisplayPrice = (
  servicePrice: TGoogleMoney | undefined,
): string => {
  if (isNil(servicePrice)) return 'Sem preço';

  const { units, nanos, currencyCode } = servicePrice;

  if ((isNil(units) || units === '0') && (isNil(nanos) || nanos === 0)) { return 'Grátis'; }

  return convertGooogleMoneyToNumber(servicePrice).toLocaleString(undefined, {
    style: 'currency',
    currency: currencyCode,
  });
};

export const groupServiceItemsByName = (serviceItems: TServiceItem[]) =>
  groupBy((serviceItem) => {
    const { structuredServiceItem, freeFormServiceItem } = serviceItem;
    const displayName = freeFormServiceItem
      ? freeFormServiceItem.label.displayName
      : structuredServiceItem?.displayName || '';
    return displayName.toUpperCase();
  }, serviceItems);

export const normalizeServiceItemsDataForTable = (
  serviceItems: TServiceItem[]
): TTableRowData[] => {
  const serviceItemsGroupedByName = groupServiceItemsByName(serviceItems);

  return serviceItems.map((serviceItem): TTableRowData => {
    const { structuredServiceItem, freeFormServiceItem, price } = serviceItem;
    const displayName = freeFormServiceItem
      ? freeFormServiceItem.label.displayName
      : structuredServiceItem?.displayName || '';

    const servicesWithSameName =
      serviceItemsGroupedByName[displayName.toUpperCase()];

    return [
      { columnRef: 'id', visible: false, value: uuidv4() },
      { columnRef: 'service', visible: true, value: displayName },
      {
        columnRef: 'price',
        visible: true,
        value: price,
      },
      {
        columnRef: 'serviceType',
        visible: false,
        value: freeFormServiceItem ? 'free-form' : 'structured',
      },
      {
        columnRef: 'isDuplicate',
        visible: false,
        value: servicesWithSameName?.length > 1 || false,
      },
    ];
  });
};

export const isListHasDuplicates = (serviceItemsList: TServiceItem[] | null) => {
  if (isNil(serviceItemsList)) return false;

  const displayNamesList = serviceItemsList.map(
    item => item.freeFormServiceItem?.label.displayName
    || item.structuredServiceItem?.displayName,
  ).map(item => (!isNil(item) ? item.toUpperCase() : '')).filter(item => !isEmpty(item));

  return uniq(displayNamesList).length !== serviceItemsList.length;
};
