import React from 'react';

import filterSearch from '../../../../../../utils/filter-search';

import ListingItem from '../listing-item';

import {
  StyledSublisting,
} from './sublisting-styles';

const Sublisting = ({
  data,
  name,
  image,
  description,
  handleBind,
  noImages = false,
  keyToFilter,
  mainPageId,
}) => (
  <StyledSublisting>
    {data.length > 0 ? data.filter(item => filterSearch({ item, key: keyToFilter, query: '' })).map(item => (
      <ListingItem
        mainPageId={mainPageId}
        key={item.id}
        id={item.id}
        item={item}
        name={item[name]}
        image={item[image]}
        description={item[description] || null}
        handleBind={handleBind}
        noImages={noImages}
        sublisting={item.locations}
      />
    )) : (
      <span>
        Nenhum registro encontrado
      </span>
    )}
  </StyledSublisting>
);

export default Sublisting;
