import React, { useState, useEffect } from 'react';

import { Button, CircularProgress } from '@material-ui/core';
import { useHistory, useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

import { Creators as CompanyActions } from "../../redux/ducks/company";

import createWorkingHoursModel from '../../services/workingHours/createWorkingHours';
import addWorkingHoursModels from '../../services/companies/addWorkingHoursModels';
import updateWorkingHoursModel from '../../services/workingHours/updateWorkingHours';
import getCompany from '../../services/companies/getCompany';

const buttonStyle = {
  background: '#F2F2F2',
  fontFamily: 'Poppins',
  fontWeight: 'bold',
  marginLeft: '1em',
};

export const useCreateWorkingHoursModel = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const params = useParams();
  const [loading, setLoading] = useState(false);
  const [modelName, setModelName] = useState('');
  const [periodsForm, setPeriodsForm] = useState({
    mondayOpen: '',
    mondayClose: '',
    tuesdayOpen: '',
    tuesdayClose: '',
    wednesdayOpen: '',
    wednesdayClose: '',
    thursdayOpen: '',
    thursdayClose: '',
    fridayOpen: '',
    fridayClose: '',
    saturdayOpen: '',
    saturdayClose: '',
    sundayOpen: '',
    sundayClose: '',
  });
  const [isDefault, setIsDefault] = useState(false);

  const { workingHoursModelId } = params;

  const { user } = useSelector((state) => state.AuthReducer);
  const { activeCompany } = useSelector((state) => state.CompanyReducer);

  useEffect(() => {
    if (workingHoursModelId) {
      let currentModel;
      const companyWHModels = activeCompany.working_hours_models;
      for (let i = 0; i < companyWHModels.length; i++) {
        if (workingHoursModelId === companyWHModels[i].id.toString()) { currentModel = companyWHModels[i]; }
      }
      setModelName(currentModel.description);
      setIsDefault(currentModel.is_default);
      const [mondayOpen, mondayClose] = currentModel.monday.split(' - ');
      const [tuesdayOpen, tuesdayClose] = currentModel.tuesday.split(' - ');
      const [wednesdayOpen, wednesdayClose] = currentModel.wednesday.split(
        ' - ',
      );
      const [thursdayOpen, thursdayClose] = currentModel.thursday.split(' - ');
      const [fridayOpen, fridayClose] = currentModel.friday.split(' - ');
      const [saturdayOpen, saturdayClose] = currentModel.saturday.split(' - ');
      const [sundayOpen, sundayClose] = currentModel.sunday.split(' - ');
      setPeriodsForm({
        mondayOpen,
        mondayClose,
        tuesdayOpen,
        tuesdayClose,
        wednesdayOpen,
        wednesdayClose,
        thursdayOpen,
        thursdayClose,
        fridayOpen,
        fridayClose,
        saturdayOpen,
        saturdayClose,
        sundayOpen,
        sundayClose,
      });
    }
  }, []);

  const onChangePeriodsForm = ({ target: { name, value } }) => {
    setPeriodsForm({ ...periodsForm, [name]: value });
  };

  const onChangeIsDefault = () => {
    setIsDefault(!isDefault);
  };

  const onChangeModelName = ({ target: { value } }) => {
    setModelName(value);
  };

  const onModelUpdated = async () => {
    setLoading(true);

    await updateWorkingHoursModel(
      user.accessToken,
      workingHoursModelId,
      modelName,
      isDefault,
      `${periodsForm.mondayOpen} - ${periodsForm.mondayClose}`,
      `${periodsForm.tuesdayOpen} - ${periodsForm.tuesdayClose}`,
      `${periodsForm.wednesdayOpen} - ${periodsForm.wednesdayClose}`,
      `${periodsForm.thursdayOpen} - ${periodsForm.thursdayClose}`,
      `${periodsForm.fridayOpen} - ${periodsForm.fridayClose}`,
      `${periodsForm.saturdayOpen} - ${periodsForm.saturdayClose}`,
      `${periodsForm.sundayOpen} - ${periodsForm.sundayClose}`,
    );

    const company = await getCompany(user.accessToken, activeCompany.id);

    dispatch(CompanyActions.setActiveCompany(company));
    setLoading(false);
    history.push('/company/working-hours-models');
  };

  const onModelCreated = async () => {
    setLoading(true);

    const workingHoursModel = await createWorkingHoursModel(
      user.accessToken,
      modelName,
      isDefault,
      `${periodsForm.mondayOpen} - ${periodsForm.mondayClose}`,
      `${periodsForm.tuesdayOpen} - ${periodsForm.tuesdayClose}`,
      `${periodsForm.wednesdayOpen} - ${periodsForm.wednesdayClose}`,
      `${periodsForm.thursdayOpen} - ${periodsForm.thursdayClose}`,
      `${periodsForm.fridayOpen} - ${periodsForm.fridayClose}`,
      `${periodsForm.saturdayOpen} - ${periodsForm.saturdayClose}`,
      `${periodsForm.sundayOpen} - ${periodsForm.sundayClose}`,
      user.user.id,
    );

    const company = await addWorkingHoursModels(
      user.accessToken,
      activeCompany.id,
      [workingHoursModel.id],
    );
    dispatch(CompanyActions.setActiveCompany(company));

    setLoading(false);

    history.push('/company/working-hours-models');
  };

  const onBackArrowClicked = () => {
    history.push('/company/working-hours-models');
  };

  const renderButton = () => {
    if (loading) {
      return <CircularProgress />;
    }
    return (
      <>
        <Button
          style={buttonStyle}
          disabled={modelName === ''}
          variant="contained"
          onClick={workingHoursModelId ? onModelUpdated : onModelCreated}
        >
          {workingHoursModelId ? 'Modificar Horário' : 'Criar Horário'}
        </Button>
      </>
    );
  };

  return {
    onBackArrowClicked,
    modelName,
    onChangeModelName,
    onChangePeriodsForm,
    periodsForm,
    isDefault,
    onChangeIsDefault,
    renderButton,
    modelName,
  };
};
