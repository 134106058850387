import React, { ReactElement } from 'react';
import { Help } from '@material-ui/icons';

import * as CustomIconsSVG from '../assets/custom-icons-svg';

export const useCustomIconSVG = (
  iconTitle = 'Google',
  size = 25,
): ReactElement => {
  const CustomIcon = CustomIconsSVG[iconTitle];
  return (
    <CustomIcon className="custom-icon-svg" width={size} height={size} /> || (
      <Help />
    )
  );
};
