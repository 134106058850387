import axios from 'axios';

export default async function createCompanyGroupInvite({
  accessToken,
  email,
  company_group_id,
  created_by = 1,
  isLoading,
}) {
  try {
    if (isLoading) isLoading(true);

    const requestOptions = {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    };

    const { data } = await axios.post(
      `${process.env.REACT_APP_INVITES_API_URL}/company-group`,
      {
        email, company_group_id, created_by,
      },
      requestOptions,
    );

    return data;
  } catch (err) {
    return null;
  } finally {
    if (isLoading) isLoading(false);
  }
}
