import React, { useState } from 'react';
import { useHistory, Redirect } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { Button } from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';

import { Creators as AuthActions } from '../../redux/ducks/auth';

import {
  Can,
  DataTable,
} from '../../components';

import getUsers from '../../services/users/getUsers';
import getUserName from '../../services/users/getUserName';
import getProfiles from '../../services/profiles/getProfiles';
import deleteProfile from '../../services/profiles/deleteProfile';

import formatDate from '../../utils/formatDate';

const ProfileList = () => {
  const history = useHistory();
  const reduxDispatch = useDispatch();

  const [profiles, setProfiles] = useState([]);
  const [loading, setLoading] = useState(false);
  const [searchQuery, setSearchQuery] = React.useState('');
  const [page, setPage] = React.useState(0);
  const [pageSize, setPageSize] = React.useState(10);
  const [dataCount, setDataCount] = React.useState(0);

  const { user } = useSelector((state) => state.AuthReducer);

  const setTokenLikeExpired = () => {
    reduxDispatch(AuthActions.setUser({ ...user, accessToken: 'expired' }));
  };

  const columns = [
    { name: 'Id', options: { display: false, sort: false } },
    { name: 'Nome', options: { sort: false } },
    { name: 'Descrição', options: { sort: false } },
    { name: 'Data da Criação', options: { sort: false } },
    { name: 'Data da ultima atualização', options: { sort: false } },
    { name: 'Criado por', options: { sort: false } },
  ];

  const data = [];
  if (profiles.length > 0) {
    for (let i = 0; i < profiles.length; i++) {
      data.push([
        profiles[i].id,
        profiles[i].name,
        profiles[i].description,
        profiles[i].date_created,
        profiles[i].date_updated,
        profiles[i].created_by,
      ]);
    }
  }

  React.useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      const profiles = await getProfiles({
        accessToken: user.accessToken,
        page,
        pageSize,
        searchQuery,
        setTokenLikeExpired,
      });
      setProfiles(profiles[0]);
      setDataCount(profiles[1]);
      setLoading(false);
    };
    fetchData();
  }, [page, pageSize, searchQuery]);

  const onAddProfileClicked = () => {
    history.push('/profiles/create');
  };

  const onUpdateProfileClicked = (id) => {
    history.push(`/profiles/update/${id}`);
  };

  const onDeleteProfileClicked = async (id) => {
    await deleteProfile({
      accessToken: user.accessToken,
      profileId: id,
      setTokenLikeExpired,
    });
  };

  return (
    <>
      <Can
        perform="Profiles/List"
        yes={() => (
          <div style={{ padding: '1em' }}>
            <div className="create-function-button">
              <Button
                variant="contained"
                startIcon={<FontAwesomeIcon icon={faPlus} />}
                onClick={onAddProfileClicked}
                style={{
                  marginBottom: '1em',
                  color: 'white',
                  backgroundColor: '#31ab70',
                  fontFamily: 'Poppins',
                  fontWeight: 'bold',
                }}
              >
                Criar Profile
              </Button>
            </div>
            <DataTable
              count={dataCount}
              page={page}
              setPage={setPage}
              pageSize={pageSize}
              setPageSize={setPageSize}
              searchQuery={searchQuery}
              setSearchQuery={setSearchQuery}
              title="Profiles"
              columns={columns}
              loading={loading}
              data={data}
              onUpdateClicked={onUpdateProfileClicked}
              onDeleteClicked={onDeleteProfileClicked}
            />
          </div>
        )}
        no={() => <Redirect to="/main" />}
      />
    </>
  );
};

export default ProfileList;
