import styled from 'styled-components';
import { List } from '@material-ui/core';
import { shade } from 'polished';

export const StyledPendingInvites = styled.div`
  padding: 2rem;
  background: ${props => shade(0.05, props.theme.colors.white)};
  margin-bottom: 3rem;

  .pending-invites-pagination {
    padding-top: 0;
    padding-bottom: 0;
    margin-top: 2rem;
    justify-content: center;
  }
  
  .pending-invites-input-search {
    margin-bottom: 1rem !important;
  }
`;

export const StyledTitle = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 1rem;

  h4 {
    font-size: 1.4rem;
    font-weight: 700;
    width: 100%;
    margin-bottom: 0;
  }

  span {
    font-weight: normal;
    cursor: pointer;
    font-size: 1.2rem;
    color: ${props => props.theme.colors.red};
    text-decoration: underline;
  }
`;

export const StyledInvites = styled(List)`
  margin-bottom: 0.1rem;
`;
