import React from 'react';
import {
  StyledBottomActions,
  StyledWarningMessage,
  StyledDialogBody,
  StyledButton,
} from './confirm-link-warning-styles';

const ConfirmLinkWarning = ({ onConfirm, onClose }) => (
  <StyledDialogBody>
    <StyledWarningMessage>Esse local já possui conexões com outro local do Google, ao conectar com um local diferente essas conexões serão deletadas. Deseja conectar?</StyledWarningMessage>
    <StyledBottomActions>
      <StyledButton onClick={onConfirm} className="button">Sim, desejo conectar</StyledButton>
      <StyledButton isCancelButton onClick={onClose} className="button">Cancelar</StyledButton>
    </StyledBottomActions>
  </StyledDialogBody>
);

export default ConfirmLinkWarning;
