import styled from 'styled-components';
import { Dialog } from '@material-ui/core';
import { shade } from 'polished';

export const StyledCreatePostDialog = styled(Dialog)`
  .MuiDialog-paper {
    min-width: 28rem;
    width: 100%;
    max-width: 50rem;
  }
`;

export const StyledHeader = styled.header`
  padding: 1rem 2rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 0.1rem solid ${props => shade(0.2, props.theme.colors.white)};
  background: ${props => shade(0.05, props.theme.colors.white)};

  h2 {
    margin-bottom: 0;
  }
`;

export const StyledOptionsPost = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 2rem;
`;

export const StyledFormContent = styled.div`
  display: flex;
  width: 100%;
  padding: 2rem;
  background: ${props => shade(0.1, props.theme.colors.white)};
  
  .post-dialog-loading {
    margin: 0 auto;
  }
`;
