import axios from 'axios';
import type { EBatchPlatformStatus } from '../../../types/TBatchGoogleModules';
import type { TServiceBatch } from '../../../types/TServiceBatch';

export type TUpdateServiceBatch = {
  userAccessToken: string;
  serviceBatchId: string;
  status: EBatchPlatformStatus;
  setIsLoading?: React.Dispatch<React.SetStateAction<boolean>>;
};

type TSuccessResponse = {
  status: 'SUCCESS';
  data: TServiceBatch;
};

type TErrorResponse = {
  status: 'ERROR';
  data: any;
};

export const updateServiceBatch = async ({
  serviceBatchId,
  userAccessToken,
  status,
  setIsLoading,
}: TUpdateServiceBatch): Promise<TSuccessResponse | TErrorResponse> => {
  try {
    if (setIsLoading) setIsLoading(true);

    const { data } = await axios.put(
      `${process.env.REACT_APP_SAAS_BATCH_GOOGLE_MODULES}/service-batch/${serviceBatchId}`,
      {
        status,
      },
      {
        headers: {
          Authorization: `Bearer ${userAccessToken}`,
        },
      },
    );

    return {
      status: 'SUCCESS',
      data,
    };
  } catch (err: any) {
    return {
      status: 'ERROR',
      data: err.response.data,
    };
  } finally {
    if (setIsLoading) setIsLoading(false);
  }
};
