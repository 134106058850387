export default {
  title: 'default',

  colors: {
    primary: '#0385fdff',
    secondary: '#00da91ff',
    tertiary: '#fbbf0d',
    quartenaria: '#fbbf0d',

    white: '#FFFFFF',
    black: '#000000',
    grey: '#8a8a8a',
    greyLight: '#F4F5F6',
    darkGrey: '#6a6a6a',
    text: '#000000',
    red: '#f00f00',
    yellow: '#d19e63',
    green: '#2ECC40',
    god: '#f9b404',
    pink: '#f50056',
    ocean: '#025DB1',
    babyBlue: '#40a3ff',
    warning: '#d19e63',
    danger: '#f00f00',
    success: '#00da91ff',
    lightGrey: '#F2F2F2',

    alertSucessBg: '#d4edda',
    alertSucessBorder: '#c3e6cb',
    alertSucessColor: '#155724',
    alertDangerBg: '#f8d7da',
    alertDangerBorder: '#f5c6cb',
    alertDangerColor: '#721c24',
    alertWarningBg: '#fff3cd',
    alertWarningBorder: '#ffeeba',
    alertWarningColor: '#856404',

    headerToolbarBg: '#E0E0E0',
  },

  page: {
    body: '#F2F2F2',
  },

  profile: {
    info: '#FFFFFF',
    defaultIconColor: '#D3D3D3',
  },
};
