import api from './api';

const facebookAPIUserLogout = async (userID) => {
  try {
    const response = await api.post(`${userID}/logout`);
    return response;
  } catch (err) {
    console.log(err);
  }
};

export default facebookAPIUserLogout;
