import React from 'react';
import { getActionType } from '../helper';

import {
  StyledNewsPostInfo,
  StyledMediaItems,
} from './news-post-info-styles';

type TNewsPostInfo = {
  newsPostData: any;
  info: any
}

const NewsPostInfo = ({ newsPostData, info = null }:TNewsPostInfo) => {
  const {
    topicType,
    mediaItems,
    summary,
    actionType,
    url,
  } = newsPostData;

  return (
    <StyledNewsPostInfo>
      {info === 'topicType' && <p>Novidade</p>}

      {info === 'mediaItems' && (
      <StyledMediaItems>
        {mediaItems.map((element:any) => <li><a href={element.sourceUrl}>{element.sourceUrl}</a></li>)}
      </StyledMediaItems>
      )}

      {info === 'summary' && summary}

      {info === 'actionType' && <h5>{`Tipo: ${getActionType(actionType)}`}</h5>}
      {info === 'url' && <h5>{`Url: ${url}`}</h5>}

    </StyledNewsPostInfo>
  );
};

export default NewsPostInfo;
