import React from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import * as R from 'ramda';
import { useSnackbar } from 'notistack';

import APIGetUserLocations from '../../services/locations/get-user-locations';
import APIGetAllLocations from '../../services/locations/getAllLocations';
import getAllLocationGroupInvites from '../../services/invites/getAllLocationGroupInvites';
import APIGetLocationsGroupResponsibles from '../../services/location-groups/getLocationGroupResponsibles';
import APIGetAllGroupLocations from '../../services/locations/getAllGroupLocation';
import APIAddLocationGroup from '../../services/location-groups/addLocations';
import APIRemoveLocationGroup from '../../services/location-groups/removeLocations';
import APIUpdateLocationGroup from '../../services/location-groups/update';
import getLocationGroupGuests from '../../services/guest/getLocationGroupGuests';
import createLocationGroupInvite from '../../services/invites/createLocationGroupInvite';

import {
  HeaderToolbar,
  BlockWithActionButton,
  ResponsibleInfo,
  HealderToolbarAction,
  GroupItems,
  Input,
  Textarea,
  GuestList,
} from '../../components';

import {
  useAuth,
  useCompany,
} from '../../hooks';

import { isValidData } from './helpers';

import {
  StyledCreateLocationGroup,
  StyledButtonsGroup,
  StyledContentMain,
  StyledContentAside,
  StyledContent,
  StyledGroupFormCreate,
  StyledGroupFormCreateWrapper,
  StyledGroupFormCreateTitle,
} from './edit-location-group-styles';

const EditLocationGroup = () => {
  const { enqueueSnackbar } = useSnackbar();

  const {
    push: pushToScreen,
  } = useHistory();

  const {
    activeCompany,
    activeCompanyId,
  } = useSelector(state => state.CompanyReducer);

  const {
    activeLocationGroup,
  } = useSelector(state => state.LocationGroupReducer);

  const {
    userId,
    userAccessToken,
    userProfileName,
    userSetTokenLikeExpired,
  } = useAuth();

  const {
    userCompanies,
    userCompaniesPageIndex,
    userCompaniesPageQuantity,
    userCompaniesPageQuery,
    setUserCompaniesPageIndex,
    setUserCompaniesPageQuery,
  } = useCompany();

  const responsibleData = React.useRef(null);

  const [companyLocations, setCompanyLocations] = React.useState([]);
  const [companyLocationsPageSize] = React.useState(10);
  const [companyLocationsPageNumber, setCompanyLocationsPageNumber] = React.useState(0);
  const [companyLocationsPageQuery, setCompanyLocationsPageQuery] = React.useState('');
  const [companyLocationsIsLoading, setcompanyLocationsIsLoading] = React.useState(false);
  const [companyLocationsCount, setCompanyLocationsCount] = React.useState(null);

  const [companyFilter, setCompanyFilter] = React.useState(0);

  const [addedLocationsQuery, setAddedLocationsQuery] = React.useState('');

  const [locationsGroup, setLocationsGroup] = React.useState([]);
  const [locationsGroupPageSize] = React.useState(10);
  const [locationsGroupPageNumber, setLocationsGroupPageNumber] = React.useState(0);
  const [locationsGroupPageQuery, setLocationsGroupPageQuery] = React.useState('');
  const [locationsGroupIsLoading, setLocationsGroupIsLoading] = React.useState(false);
  const [locationsGroupCount, setLocationsGroupCount] = React.useState(null);

  const [groupNameField, setGroupNameField] = React.useState('');
  const [groupDescriptionField, setGroupDescriptionField] = React.useState('');

  const [isCreatingGroup, setIsCreatingGroup] = React.useState(false);

  const [responsibleInitialDate, setResponsibleInitialDate] = React.useState([]);
  const [mainResponsible, setMainResponsible] = React.useState({});

  const handlePushToMyLocationGroups = () => pushToScreen('/my-location-groups');

  const getUserLocations = React.useCallback(async () => {
    const getCompanyLocationsResponse = await APIGetUserLocations({
      accessToken: userAccessToken,
      setTokenLikeExpired: userSetTokenLikeExpired,
      userId,
      companyId: companyFilter,
      page: companyLocationsPageNumber,
      query: companyLocationsPageQuery,
      setIfFetching: setcompanyLocationsIsLoading,
    });

    if (R.isNil(getCompanyLocationsResponse)) return;

    const [companyLocationsData, companyLocationsDataCount] = getCompanyLocationsResponse || [];

    setCompanyLocations(companyLocationsData);
    setCompanyLocationsCount(Math.ceil(companyLocationsDataCount / companyLocationsPageSize));
  }, [
    userAccessToken,
    companyLocationsPageNumber,
    companyLocationsPageQuery,
    userCompanies,
    companyFilter,
  ]);

  const getAllLocations = React.useCallback(async () => {
    const getCompanyLocationsResponse = await APIGetAllLocations({
      accessToken: userAccessToken,
      page: companyLocationsPageNumber,
      pageSize: 10,
      query: companyLocationsPageQuery,
      companyId: companyFilter,
    });

    if (R.isNil(getCompanyLocationsResponse)) return;

    const [companyLocationsData, companyLocationsDataCount] = getCompanyLocationsResponse || [];

    setCompanyLocations(companyLocationsData);
    setCompanyLocationsCount(Math.ceil(companyLocationsDataCount / companyLocationsPageSize));
  }, [
    userAccessToken,
    companyLocationsPageNumber,
    companyLocationsPageQuery,
    userCompanies,
    companyFilter,
  ]);

  const handleGetLocations = () => {
    if (userProfileName === 'Usuário Padrão') {
      getUserLocations();
    } else {
      getAllLocations();
    }
  };

  const getLocationGroupResponsibles = React.useCallback(async () => {
    const locationsGroupResponsiblesReesponse = await APIGetLocationsGroupResponsibles({
      accessToken: userAccessToken,
      locationGroupId: activeLocationGroup.id,
    });

    if (R.isNil(locationsGroupResponsiblesReesponse)) return;

    const { main_responsible: mainResponsible } = locationsGroupResponsiblesReesponse;

    setMainResponsible(mainResponsible);
    setResponsibleInitialDate([{
      ...mainResponsible,
      main: true,
    }]);
  }, [activeLocationGroup]);

  const getLocationsGroup = React.useCallback(async () => {
    if (!R.isEmpty(locationsGroupPageQuery)) setLocationsGroupPageNumber(0);

    const locationGroupResponse = await APIGetAllGroupLocations({
      accessToken: userAccessToken,
      locationGroupId: activeLocationGroup.id,
      setTokenLikeExpired: userSetTokenLikeExpired,
      page: locationsGroupPageQuery ? 0 : locationsGroupPageNumber,
      setIfFetching: setLocationsGroupIsLoading,
      query: locationsGroupPageQuery,
    });

    if (R.isNil(locationGroupResponse)) return;

    const [locationsGroupData, locationsGroupDataCount] = locationGroupResponse || [];

    setLocationsGroup(locationsGroupData);
    setLocationsGroupCount(Math.ceil(locationsGroupDataCount / locationsGroupPageSize));
  }, [
    locationsGroupPageNumber,
    locationsGroupPageQuery,
    activeLocationGroup,
  ]);

  React.useEffect(() => { handleGetLocations(); }, [getUserLocations, getAllLocations]);
  React.useEffect(() => { getLocationGroupResponsibles(); }, [getLocationGroupResponsibles]);
  React.useEffect(() => { getLocationsGroup(); }, [getLocationsGroup]);

  React.useEffect(() => {
    if (!R.isNil(activeLocationGroup)) {
      const {
        name: groupName,
        description: groupDescription,
      } = activeLocationGroup;

      setGroupNameField(groupName);
      setGroupDescriptionField(groupDescription);
    }
  }, [activeLocationGroup]);

  const handleSave = React.useCallback(async () => {
    const {
      responsibles,
    } = responsibleData.current;

    if (!isValidData({
      responsibles,
      groupNameField,
      locationsGroup,
      enqueueSnackbar,
    })) return;

    const updateLocationGroup = await APIUpdateLocationGroup({
      accessToken: userAccessToken,
      locationGroupId: activeLocationGroup.id,
      name: groupNameField,
      description: groupDescriptionField,
      setTokenLikeExpired: userSetTokenLikeExpired,
      feedbackMessage: enqueueSnackbar,
      main_responsible_id: mainResponsible.id,
    });

    if (R.isNil(updateLocationGroup)) return;

    handlePushToMyLocationGroups();
  }, [
    responsibleData,
    groupNameField,
    groupDescriptionField,
    activeLocationGroup,
    locationsGroup,
    mainResponsible,
  ]);

  const handleAddToGroupItems = async (locationToAdd) => {
    const hasLocationOnLocationsGroup = locationsGroup.find(
      locationGroup => locationGroup.id === locationToAdd.id,
    );

    if (!R.isNil(hasLocationOnLocationsGroup)) return;

    const addLocationsGroupResponse = await APIAddLocationGroup({
      accessToken: userAccessToken,
      locationGroupId: activeLocationGroup.id,
      locationIds: [locationToAdd.id],
      setTokenLikeExpired: userSetTokenLikeExpired,
      isAddLocationToGroup: locationsGroupIsLoading,
      feedbackMessage: enqueueSnackbar,
    });

    if (R.isNil(addLocationsGroupResponse)) return;

    getLocationsGroup();
  };

  const handleRemoveAddedGroupItems = async (locationAddedToRemove) => {
    const removeLocationGroupResponse = await APIRemoveLocationGroup({
      accessToken: userAccessToken,
      setTokenLikeExpired: userSetTokenLikeExpired,
      locationGroupId: activeLocationGroup.id,
      locationIds: [locationAddedToRemove.id],
      feedbackMessage: enqueueSnackbar,
      isDeleting: locationsGroupIsLoading,
    });

    if (R.isNil(removeLocationGroupResponse)) return;

    getLocationsGroup();
  };

  const handleChangeCompany = (company) => {
    setCompanyLocationsPageNumber(0);
    setCompanyFilter(company);
  };

  const handleSearchCompany = (query) => {
    setCompanyLocationsPageNumber(0);
    setCompanyLocationsPageQuery(query);
  };

  return (
    <StyledCreateLocationGroup>
      <HeaderToolbar
        dropdownsLocationGroups
      >
        <StyledButtonsGroup>
          <HealderToolbarAction
            title="Lista de grupos"
            icon="List"
            onClick={handlePushToMyLocationGroups}
          />
        </StyledButtonsGroup>
      </HeaderToolbar>
      <StyledContent>
        <StyledContentMain>
          <ResponsibleInfo
            className="block-form"
            ref={responsibleData}
            maxResponsibles={1}
            initialData={responsibleInitialDate}
            editingMode
          />

          <StyledGroupFormCreate>
            <StyledGroupFormCreateTitle>Informação do Grupo</StyledGroupFormCreateTitle>

            <StyledGroupFormCreateWrapper>
              <Input
                label="Nome do grupo*"
                inputOptions={{
                  name: 'group-name',
                  type: 'text',
                }}
                onChange={({ target }) => setGroupNameField(target.value)}
                value={groupNameField}
                border
              />
              <Textarea
                label="Descrição do grupo"
                name="group-description"
                border
                onChange={({ value }) => setGroupDescriptionField(value)}
                value={groupDescriptionField}
              />
            </StyledGroupFormCreateWrapper>
          </StyledGroupFormCreate>

          <GroupItems
            mainTitle="Adicionar locais"
            itemsToAdd={companyLocations}
            primaryTextKey="name"
            buttonTooltipAddTitle="Adicionar"
            buttonTooltipRemoveTitle="Remover"
            itemsToAddPagesQuantity={companyLocationsCount}
            iten
            loadingItemsToAdd={companyLocationsIsLoading}
            currentItensToAddPage={companyLocationsPageNumber}
            setItensToAddPage={setCompanyLocationsPageNumber}
            setQueryItensToAddPage={handleSearchCompany}
            queryItensToAddPage={companyLocationsPageQuery}
            onItemToAddClicked={handleAddToGroupItems}
            onAddedItemClicked={handleRemoveAddedGroupItems}
            queryAddedItensPage={addedLocationsQuery}
            addedItemsPagesQuantity={locationsGroupCount}
            loadingAddedItems={locationsGroupIsLoading}
            setQueryAddedItensPage={setLocationsGroupPageQuery}
            addedItens={locationsGroup}
            inputLabelToAdd="Pesquisar"
            inputLabelAdded="Pesquisar"
            setCompanyFilter={handleChangeCompany}
            userCompanies={userCompanies}
            userCompaniesPageIndex={userCompaniesPageIndex}
            userCompaniesPageQuantity={userCompaniesPageQuantity}
            userCompaniesPageQuery={userCompaniesPageQuery}
            setUserCompaniesPageIndex={setUserCompaniesPageIndex}
            setUserCompaniesPageQuery={setUserCompaniesPageQuery}
            locationGroupCreation
          />
        </StyledContentMain>

        <StyledContentAside>
          <BlockWithActionButton
            onClick={handleSave}
            titleButton="Salvar"
            loading={isCreatingGroup}
          />

          {!R.isNil(activeLocationGroup) && (
            <GuestList
              APIGetPendingInvites={getAllLocationGroupInvites}
              userAccessToken={userAccessToken}
              userSetTokenLikeExpired={userSetTokenLikeExpired}
              targetParamToGetPendingInvites="locationGroupId"
              activeRegisterIdToGetData={activeLocationGroup.id}
              APIGetAllGuest={getLocationGroupGuests}
              targetParamToGetGuests="locationGroupId"
              userId={userId}
              APICreateInvite={createLocationGroupInvite}
              targetParamToCreateInvites="location_group_id"
              isGroup
            />
          )}
        </StyledContentAside>
      </StyledContent>

    </StyledCreateLocationGroup>
  );
};

export default EditLocationGroup;
