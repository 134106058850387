import * as R from 'ramda';
import {
  emailValidate,
  passwordValidate,
} from '../../utils/fields-validate';

import { propNameToCamelCases } from '../../utils/prop-name-to-camel-case';

export const handleFormValidation = ({
  fields,
  addedProfiles,
  googleRefreshToken,
  enqueueSnackbar,
}) => {
  let checkIfAnyEmptyField = false;
  let checkIfEmailIsInvalid = false;
  let checkIfPasswordIsInvalid = false;
  let checkIfInvalidLimit = false;

  fields.map(field => {
    if (field.getValue() === '') {
      checkIfAnyEmptyField = true;
    }

    if (field.getValue() < 0) {
      checkIfInvalidLimit = true;
    }

    if (field.name === 'field-user-email') {
      if (!emailValidate(field.getValue())) {
        checkIfEmailIsInvalid = true;
      }
    }

    if (field.name === 'field-user-password') {
      if (!passwordValidate(field.getValue())) {
        checkIfPasswordIsInvalid = true;
      }
    }
  });

  if (checkIfAnyEmptyField) {
    enqueueSnackbar('Todos os campos devem ser preenchidos', { variant: 'warning' });
    return true;
  }

  if (checkIfEmailIsInvalid) {
    enqueueSnackbar('Formato de email inválido', { variant: 'warning' });
    return true;
  }

  if (checkIfPasswordIsInvalid) {
    enqueueSnackbar('Senha deve conter 8 letras, números e uma letra maiúscula', { variant: 'warning' });
    return true;
  }

  if (googleRefreshToken === '') {
    enqueueSnackbar('Insira um Google Refresh Token válido', { variant: 'warning' });
    return true;
  }

  if (checkIfInvalidLimit) {
    enqueueSnackbar('Os limites de registro não podem ser negativos', { variant: 'warning' });
    return true;
  }

  if (R.isEmpty(addedProfiles)) {
    enqueueSnackbar('Adicione um perfil de usuário antes de prosseguir', { variant: 'warning' });
    return true;
  }

  return false;
};

export const listProfileIdsToCreate = ({ userData, addedProfiles }) => {
  const { id } = userData;

  const profileIds = [];
  addedProfiles.map(profile => {
    profileIds.push(profile.id);
  });

  return {
    id,
    profileIds,
  };
};

export const listProfileIds = (profiles) => {
  const profileIds = [];
  profiles.map(profile => {
    profileIds.push(profile.id);
  });

  return profileIds;
};

export const getFieldsCurrentValue = ({ fields, googleRefreshToken }) => ({
  name: fields[0].getValue(),
  email: fields[1].getValue(),
  google_refresh_token: googleRefreshToken,
  phone: fields[2].getValue(),
  password: fields[3].getValue(),
  company_limit: fields[4].getValue(),
  company_group_limit: fields[5].getValue(),
  location_limit: fields[6].getValue(),
  location_group_limit: fields[7].getValue(),
});

export const setFieldsToDefault = (fields) => {
  fields.map(field => {
    field.setDefaultValue(field.getValue());
  });
};

export const getFieldsActions = (fields) => {
  let fieldsByName = {};

  fields.forEach(({ name, setDefaultValue, getValue }) => {
    fieldsByName = {
      ...fieldsByName,
      [propNameToCamelCases(`set-${name}`)]: setDefaultValue,
      [propNameToCamelCases(`get-${name}`)]: name.includes('limit') ? parseInt(getValue()) : getValue(),
    };
  });

  return fieldsByName;
};
