import axios from 'axios';
import type { TCreatePost } from '../../../types/TLocationPost';

export default async function createPost({
  accessToken,
  locationId,
  inputData,
  mediaType = 'url', // Ou 'bytes'
  postType = 'call-to-action', // ou 'offer' ou 'event',
  setIsPublish,
  feedbackMessage,
}: TCreatePost): Promise<any | null> {
  const requestOptions = {
    headers: {
      'access-token': accessToken,
    },
  };

  try {
    if (setIsPublish) setIsPublish(true);

    const { data } = await axios.post(
      `${process.env.REACT_APP_GOOGLE_API_URL}/posts/${postType}/${mediaType}/${locationId}`,
      inputData,
      requestOptions,
    );

    return data;
  } catch (err) {
    if (postType === 'event') return err;
    if (feedbackMessage) feedbackMessage('Erro ao criar postagem, tente novamente!', { variant: 'error' });
    return null;
  } finally {
    if (setIsPublish) setIsPublish(false);
  }
}
