import React from 'react';
import ReactFadeIn from 'react-fade-in';
import { useSnackbar } from 'notistack';
import {
  isNil, isEmpty, unionWith, eqBy, prop,
} from 'ramda';
import { uniqueId } from 'lodash';

import APIDeleteTime from '../../../services/workingHours/delete-time-range';

import {
  Input,
  InputSwitch,
  InputDatePicker,
  Button,
  Loading,
  IconButton,
} from '../..';

import type { TTimeData } from '../../../types/TTime';
import type { TAddSpecialDate } from '../../../types/TSpecialDates';

import WorkingHoursConfig from '../working-hours-config';
import Is24Hours from '../is-24-hours-button';

import {
  mockedTimes,
  setFieldsToIsValid,
} from './helpers';

import {
  timesMocked,
} from '../../time/dropdowm-times/helpers';

import {
  StyledAddSpecialDate,
  StyledHeader,
  StyledFieldsWrapper,
  StyledHeaderTitle,
  StyledEditTitle,
  StyledEditActions,
  StyledSpecialDateIs24Hours,
} from './add-special-date-styles';
import { TSpecialDateEntity } from '../../../types/TSpecialDates';
import { isValidHour } from '../../../utils/isValidHour';

const AddSpecialDate = ({
  hasSpeciaDates,
  data,
  currentSpecialDates = [],
  specialDateToEdit,
  setSpecialDateToEdit,
  userSetTokenLikeExpired,
  userAccessToken,
  setSpecialDatesData,
}: TAddSpecialDate) => {
  const { enqueueSnackbar } = useSnackbar();

  const [newSpecialDateIsOpen, setNewSpecialDateIsOpen] = React.useState(false);
  const [newSpecialDateTitle, setNewSpecialDateTitle] = React.useState('');
  const [newSpecialDateDate, setNewSpecialDateDate] = React.useState('');
  const [newDateIs24Hours, setNewDateIs24Hours] = React.useState(false);
  const [newDateTimes, setNewDateTimes] = React.useState<TTimeData[]>(mockedTimes);

  const [isCreatingSpecialDate, setIsCreatingSpecialDate] = React.useState(false);
  const [isUpdateSpecialDate, setIsUpdateSpecialDate] = React.useState(false);

  React.useEffect(() => {
    if (isNil(specialDateToEdit)) return;

    const {
      is_open: isOpen,
      name,
      date,
      is_24_hours: is24Hours,
      times,
    } = specialDateToEdit;

    setNewSpecialDateTitle(name);
    setNewSpecialDateDate(date);
    setNewSpecialDateIsOpen(isOpen);
    setNewDateIs24Hours(is24Hours);

    setNewDateTimes(isEmpty(times) || isNil(times) ? mockedTimes : times);
  }, [specialDateToEdit]);

  const fieldsIsValid = setFieldsToIsValid({
    title: newSpecialDateTitle,
    date: newSpecialDateDate,
    feedbackMessage: enqueueSnackbar,
    currentSpecialDates,
  });

  const resetFields = () => {
    setSpecialDateToEdit(null);
    setNewSpecialDateIsOpen(false);
    setNewSpecialDateTitle('');
    setNewSpecialDateDate('');
    setNewDateTimes(mockedTimes);
    setNewDateIs24Hours(false);
  };

  const handleSaveEdit = React.useCallback(async () => {
    if (!fieldsIsValid({ isUpdateMode: true })) return;
    if (isNil(specialDateToEdit)) return;

    if (!isEmpty(data)) {
      const dateAlreadyExists = data.filter(
        (item) => (item.date === newSpecialDateDate) && (item.id !== specialDateToEdit.id),
      );

      if (!isEmpty(dateAlreadyExists)) {
        enqueueSnackbar('Data já foi cadastrada!', { variant: 'warning' });
        return;
      }
    }

    const newTimesRangeFiltered = newDateTimes.filter(({ open, close }) => !(open === '00:00' && close === '00:00'));

    if (isEmpty(newTimesRangeFiltered) && newSpecialDateIsOpen && !newDateIs24Hours) {
      enqueueSnackbar('Horário inserido inválido!', { variant: 'warning' });
      return;
    }

    let validTimes = true;

    newTimesRangeFiltered.map(({ open, close }) => {
      const mappedOpenIndex = timesMocked.findIndex(time => time === open);
      const mappedCloseIndex = timesMocked.findIndex(time => time === close);

      if (mappedOpenIndex === -1 || mappedCloseIndex === -1) {
        validTimes = false;
      }
    });

    if (!validTimes) {
      enqueueSnackbar('Horário inserido inválido!', { variant: 'warning' });
      return;
    }

    const newSpecialDate: TSpecialDateEntity[] = [{
      date: newSpecialDateDate,
      id: specialDateToEdit.id,
      is_24_hours: newDateIs24Hours,
      is_open: newSpecialDateIsOpen,
      name: newSpecialDateTitle,
      times: newTimesRangeFiltered,
    }];

    const newData = unionWith(eqBy<any>(prop('id')), newSpecialDate, data);

    setSpecialDatesData(newData);

    resetFields();
  }, [
    newSpecialDateTitle,
    newSpecialDateDate,
    specialDateToEdit,
    newSpecialDateIsOpen,
    newDateIs24Hours,
    newDateTimes,
  ]);

  const handleAddSpecialDate = React.useCallback(async () => {
    if (!fieldsIsValid({ isUpdateMode: false })) return;

    if (!isEmpty(data)) {
      const dateAlreadyExists = data.filter(item => item.date === newSpecialDateDate);

      if (!isEmpty(dateAlreadyExists)) {
        enqueueSnackbar('Data já foi cadastrada!', { variant: 'warning' });
        return;
      }
    }

    const newTimesRangeFiltered = newDateTimes.filter(({ open, close }) => !(open === '00:00' && close === '00:00')
      && isValidHour(open)
      && isValidHour(close));

    if (isEmpty(newTimesRangeFiltered) && newSpecialDateIsOpen && !newDateIs24Hours) {
      enqueueSnackbar('Horário inserido inválido!', { variant: 'warning' });
      return;
    }

    let validTimes = true;

    newTimesRangeFiltered.map(({ open, close }) => {
      if (!isValidHour(open) && !isValidHour(close)) {
        validTimes = false;
      }
    });

    if (!validTimes) {
      enqueueSnackbar('Horário inserido inválido!', { variant: 'warning' });
      return;
    }

    setSpecialDatesData(prevState => [
      ...prevState,
      {
        id: uniqueId(),
        date: newSpecialDateDate,
        name: newSpecialDateTitle,
        times: newTimesRangeFiltered,
        is_open: newSpecialDateIsOpen,
        is_24_hours: newDateIs24Hours,
      },
    ]);

    resetFields();
  }, [
    newSpecialDateTitle,
    newSpecialDateDate,
    newSpecialDateIsOpen,
    newDateIs24Hours,
    newDateTimes,
  ]);

  const handleIs24Hours = () => {
    setNewDateIs24Hours(!newDateIs24Hours);
    setNewSpecialDateIsOpen(true);
  };

  const handleIsOpen = (isOpenState: boolean) => {
    setNewSpecialDateIsOpen(isOpenState);

    if (!isOpenState) setNewDateIs24Hours(false);
  };

  return (
    <StyledAddSpecialDate hasSpeciaDates={hasSpeciaDates}>
      <StyledHeader
        newSpecialDateIsOpen={newSpecialDateIsOpen}
      >
        {specialDateToEdit
          ? (
            <StyledEditTitle>
              Editando data
            </StyledEditTitle>
          )
          : (
            <StyledHeaderTitle>
              Adicionar uma nova data
            </StyledHeaderTitle>
          )}
        <StyledFieldsWrapper>
          <Input
            className="add-special-date-input"
            label="Título"
            value={newSpecialDateTitle}
            onChange={e => setNewSpecialDateTitle(e.target.value)}
            inputOptions={{
              type: 'text',
              name: 'specia-date-title',
            }}
            border
          />

          <Input
            className="add-special-date-input"
            label="Data"
            value={newSpecialDateDate}
            onChange={e => setNewSpecialDateDate(e.target.value)}
            inputOptions={{
              type: 'text',
              name: 'specia-date-title',
            }}
            mask="99/99/9999"
            border
          />

          <InputSwitch
            label="Aberto?"
            checked={newSpecialDateIsOpen}
            changeChecked={() => handleIsOpen(!newSpecialDateIsOpen)}
            alignRow
            className="add-special-date-switch"
          />

          <Is24Hours
            isActive={newDateIs24Hours}
            className="is-24-hours-button"
            onClick={handleIs24Hours}
          />

          {isNil(specialDateToEdit) ? (
            <Button
              className="add-special-date-button"
              disabled={isCreatingSpecialDate}
              onClick={handleAddSpecialDate}
            >
              {isCreatingSpecialDate && (
                <Loading
                  className="add-special-date-loading"
                />
              )}
              Adicionar
            </Button>
          ) : (
            <StyledEditActions>
              <Button
                className="edit-special-date-button"
                disabled={isUpdateSpecialDate}
                onClick={handleSaveEdit}
                buttonType="secondary"
              >
                {isUpdateSpecialDate && (
                  <Loading
                    className="update-special-date-loading"
                  />
                )}
                Salvar
              </Button>
              <IconButton
                icon="Close"
                onClick={() => resetFields()}
                tooltip="Cancelar edição"
              />
            </StyledEditActions>
          )}
        </StyledFieldsWrapper>
      </StyledHeader>

      {(newSpecialDateIsOpen && newDateIs24Hours) && (
        <StyledSpecialDateIs24Hours>
          Não há faixas de horários para datas fixadas como aberta 24 horas
        </StyledSpecialDateIs24Hours>
      )}

      {(newSpecialDateIsOpen && !newDateIs24Hours) && (
        <ReactFadeIn>
          <WorkingHoursConfig
            times={newDateTimes}
            setTimes={(newTimes) => setNewDateTimes(newTimes)}
          />
        </ReactFadeIn>
      )}
    </StyledAddSpecialDate>
  );
};

export default AddSpecialDate;
