import React from 'react';
import * as R from 'ramda';

import {
  timesMocked,
} from './helpers';

import {
  StyledDropDownTimes,
  StyledItem,
} from './dropdown-times-styles';

const DropDownTimes = ({
  setTimeFromItem,
  dropDownToBottom,
  timeInputValue,
  filter = '00:00',
  filterType = 'min',
}) => {
  const [filteredTimes, setFilteredTimes] = React.useState([]);

  React.useEffect(() => {
    const filterIndex = timesMocked.findIndex(time => time === filter);

    if (filterIndex === 0 || filterIndex === -1) {
      setFilteredTimes(timesMocked);
      return;
    }

    setFilteredTimes(timesMocked.filter((time, index) => (filterType === 'min' ? index > filterIndex : index < filterIndex)));
  }, [timeInputValue, filter, filterType]);

  return (
    <StyledDropDownTimes
      dropDownToBottom={dropDownToBottom}
      className="drop-down-time"
    >
      {filteredTimes.map((timeItem, index) => (
        <StyledItem
          onClick={() => setTimeFromItem(timeItem)}
          key={Math.floor(Math.random() + index)}
        >
          {timeItem}
        </StyledItem>
      ))}
    </StyledDropDownTimes>
  );
};

export default DropDownTimes;
