import axios from 'axios';

import { TUpdateAllLocationConnectionsByRefreshToken } from '../../types/TGoogle';

export default async function updateAllLocationConnectionsByRefreshToken({
  accessToken,
  refreshToken,
}: TUpdateAllLocationConnectionsByRefreshToken) {
  const requestOptions = {
    headers: {
      'access-token': accessToken,
    },
  };

  try {
    const { data } = await axios.post(
      `${process.env.REACT_APP_LOCATION_CONNECTIONS}/refresh-token/`,
      {
        refresh_token: refreshToken,
      },
      requestOptions,
    );

    return data;
  } catch (err: any) {
    return err.response;
  }
}
