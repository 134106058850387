import React, { useCallback, useState } from 'react';
import { debounce } from 'lodash';
import { MUIDataTableOptions } from 'mui-datatables';

import { RoundedButton, Table } from '../../../components';

import MonitorFilter from './monitor-filter';
import MonitorRow from './monitor-row';
import MonitorDialog from './monitor-dialog';
import ErrorDialog from './monitor-dialog/error-dialog';

import { tableColumns } from './helpers';

import { useAuth } from '../../../hooks';
import { getAllMonitoring } from '../../../services/platform-sync-modules/get-all-monitoring';
import { runMonitoring } from '../../../services/platform-sync-modules/run-monitoring';

import {
  StyledContainer,
  StyledContent,
  StyledInfo,
  StyledMonitorButtonWrapper,
  StyledInput,
} from './monitor-styles';

import type { TPlatformSyncMonitoringEntity } from '../../../services/platform-sync-modules/types';
import type { TTableRowData } from '../../../types/TMonitor';

import isNilOrEmpty from '../../../utils/is-null-or-empty';
import {
  THandleChangeSearch, THandleChangePeriodFilter, THandleChangePlatformFilter,
} from '../../../types/TSyncPermissions';

const Monitor = () => {
  const { userAccessToken, userProfileName, userSetTokenLikeExpired } = useAuth();

  const [company, setCompany] = useState('');
  const [location, setLocation] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [page, setPage] = React.useState(0);
  const [pageSize, setPageSize] = React.useState(10);
  const [monitoringCount, setMonitoringCount] = useState(0);
  const [tableData, setTableData] = useState<TTableRowData[][]>([]);
  const [selectedPlatformFilter, setSelectedPlatformFilter] = useState<string[]>([]);
  const [selectedStatusFilter, setSelectedStatusFilter] = useState('');
  const [ofPeriod, setOfPeriod] = useState<Date | null>(null);
  const [toPeriod, setToPeriod] = useState<Date | null>(null);
  const [isMonitorsDialogOpen, setIsMonitorsDialogOpen] = useState(false);
  const [isErrorDialogOpen, setIsErrorDialogOpen] = useState(false);
  const [selectedRow, setSelectedRow] = useState({ id: '', platform: '' });

  const [monitoringPage, setMonitoringPage] = React.useState(0);

  const onMonitorDialogClose = useCallback(() => {
    setIsMonitorsDialogOpen(false);
    setSelectedRow({ id: '', platform: '' });
  }, []);

  const onMonitorsDialogOpen = useCallback(({ id, platform }: {id: string, platform: string}) => {
    setIsMonitorsDialogOpen(true);
    setSelectedRow({ id, platform });
  }, []);

  const onErrorDialogClose = useCallback(() => {
    setIsErrorDialogOpen(false);
    setSelectedRow({ id: '', platform: '' });
  }, []);

  const onErrorDialogOpen = useCallback(({ id, platform }: {id: string, platform: string}) => {
    setIsErrorDialogOpen(true);
    setSelectedRow({ id, platform });
  }, []);

  const handleChangeSearch = ({ text, setField }: THandleChangeSearch) => {
    setPage(0);
    setField(text);
  };

  const handleChangePeriodFilter = ({ period, setField }: THandleChangePeriodFilter) => {
    setPage(0);
    setField(period);
  };

  const handleChangePlatformFilter = ({ platforms, setField }: THandleChangePlatformFilter) => {
    setPage(0);
    setField(platforms);
  };

  const handleSearchGroupWithDebounce = useCallback(
    debounce((text: string) => handleChangeSearch({ text, setField: setCompany }), 500),
    [],
  );

  const handleSearchLocationWithDebounce = useCallback(
    debounce((text: string) => handleChangeSearch({ text, setField: setLocation }), 500),
    [],
  );

  const tableOptions = React.useMemo(
    (): MUIDataTableOptions => ({
      count: monitoringCount,
      search: false,
      download: true,
      page,
      selectableRows: 'none',
    }),
    [monitoringCount, page],
  );

  const formattedTableData = useCallback((data: TPlatformSyncMonitoringEntity[]) => data.map((syncMonitoring: TPlatformSyncMonitoringEntity) => [
    { columnRef: 'id', value: syncMonitoring.id, visible: false },
    { columnRef: 'platform', value: syncMonitoring.platform, visible: true },
    { columnRef: 'company', value: syncMonitoring.location.company?.name, visible: true },
    { columnRef: 'location', value: syncMonitoring.location.name, visible: true },
    { columnRef: 'created_at', value: syncMonitoring.date_created, visible: true },
    { columnRef: 'status', value: syncMonitoring.status, visible: true },
    { columnRef: 'operations', value: { id: syncMonitoring.id, platform: syncMonitoring.platform }, visible: true },
  ]), []);

  const formattedDate = useCallback((date: Date | null) => (date
    ? new Date(date).toISOString().split('T').toString()
    : ''),
  []);

  const getMonitoringData = React.useCallback(async () => {
    let requestBody: any = {
      userAccessToken,
      status: selectedStatusFilter,
      ofPeriod: formattedDate(ofPeriod),
      toPeriod: formattedDate(toPeriod),
      locationName: location,
      companyName: company,
      isFetching: setIsLoading,
      page,
      pageSize,
      setTokenLikeExpired: userSetTokenLikeExpired,
    };

    if (!isNilOrEmpty(selectedPlatformFilter)) {
      requestBody = {
        ...requestBody,
        platforms: JSON.stringify(selectedPlatformFilter),
      };
    }

    const syncMonitoringResponse = await getAllMonitoring(requestBody);

    if (!syncMonitoringResponse) return;
    const newTableData = formattedTableData(syncMonitoringResponse[0]);

    setTableData(newTableData);
    setMonitoringCount(syncMonitoringResponse[1]);
  }, [
    company,
    location,
    selectedStatusFilter,
    page,
    pageSize,
    selectedPlatformFilter,
    ofPeriod,
    toPeriod,
  ]);

  React.useEffect(() => {
    getMonitoringData();
  }, [getMonitoringData]);

  const handleRunMonitoring = React.useCallback(async () => {
    await runMonitoring({
      userAccessToken,
      isFetching: setIsLoading,
      page: monitoringPage,
    });

    getMonitoringData();
  }, [monitoringPage]);

  return (
    <StyledContainer>
      {userProfileName !== 'Usuário Padrão' && (
        <StyledMonitorButtonWrapper>
          <StyledInput
            onChange={({ target }) => setMonitoringPage(parseInt(target.value))}
            type="number"
            defaultValue={0}
          />

          <RoundedButton
            title="Executar Monitoramento"
            onClick={handleRunMonitoring}
            disabled={isLoading}
            icon="CheckCircle"
          />
        </StyledMonitorButtonWrapper>
      )}

      <StyledInfo>
        <MonitorFilter
          onChangeCompanyQuery={handleSearchGroupWithDebounce}
          onChangeLocationQuery={handleSearchLocationWithDebounce}
          selectedPlatformFilter={selectedPlatformFilter}
          setSelectedPlatformFilter={(platforms) => handleChangePlatformFilter({ platforms, setField: setSelectedPlatformFilter })}
          selectedTypeFilter={selectedStatusFilter}
          setSelectedTypeFilter={(text) => handleChangeSearch({ text, setField: setSelectedStatusFilter })}
          ofPeriod={ofPeriod}
          setOfPeriod={(period) => handleChangePeriodFilter({ period, setField: setOfPeriod })}
          toPeriod={toPeriod}
          setToPeriod={(period) => handleChangePeriodFilter({ period, setField: setToPeriod })}
        />
      </StyledInfo>

      <StyledContent>
        <Table
          loading={isLoading}
          title="Monitor de sincronizações"
          setPage={setPage}
          setPageSize={setPageSize}
          columns={tableColumns}
          data={tableData}
          row={(rowData) => (
            <MonitorRow
              rowData={rowData}
              rowId={rowData[0]?.value}
              dataTableOptions={tableOptions}
              onMonitorsDialogOpen={onMonitorsDialogOpen}
              onErrorDialogOpen={onErrorDialogOpen}
            />
          )}
          options={tableOptions}
        />
      </StyledContent>

      {isMonitorsDialogOpen && (
        <MonitorDialog
          isDialogOpen={isMonitorsDialogOpen}
          onClose={onMonitorDialogClose}
          monitorId={selectedRow.id}
          platform={selectedRow.platform}
        />
      )}

      {isErrorDialogOpen && (
        <ErrorDialog
          isDialogOpen={isErrorDialogOpen}
          onClose={onErrorDialogClose}
          monitorId={selectedRow.id}
        />
      )}
    </StyledContainer>
  );
};
export default Monitor;
