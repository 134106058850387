import React from 'react';
import { useSelector } from 'react-redux';
import { GoogleLogin } from 'react-google-login';
import { isEmpty } from 'ramda';
import { useSnackbar } from 'notistack';

import {
  ConfigCard, IconButton, Loading, Input,
} from '../../../components';

import { useAuth, useUncontrolledField } from '../../../hooks';

import {
  fieldRegisterClientName,
  fieldRegisterClientEmail,
  fieldRegisterClientGoogleTokenRefresh,
  fieldRegisterClientCompanyGroupLimit,
  fieldRegisterClientCompanyLimit,
  fieldRegisterClientLocationGroupLimit,
  fieldRegisterClientLocationLimit,
} from './fields-scheme';

import {
  emailValidate as emailIsValid,
} from '../../../utils/fields-validate';

import signUpClientUser from '../../../services/signUpClientUser';
import generateRefreshToken from '../../../services/google/generateRefreshToken';
import createConnection from '../../../services/connections/createConnection';

import {
  StyledRegisterClients, StyleInputGroup, StyledForm, StyledRefreshTokenDiv,
} from './register-client-styles';

import { translations } from '../../../translations';

const RegisterClient = () => {
  const { enqueueSnackbar } = useSnackbar();

  const { language, user } = useSelector((state) => state.AuthReducer);
  const { register: registerTranslations } = translations[language];
  const [loading, setLoading] = React.useState(false);
  const [isLoadingRefreshToken, setIsLoadingRefreshToken] = React.useState(false);
  const [refreshToken, setRefreshToken] = React.useState('');
  const [googleGeneratedAccessToken, setGoogleGeneratedAccessToken] = React.useState(null);
  const [googleTokenExpirationDate, setGoogleTokenExpirationDate] = React.useState(null);
  const [googleAuthCode, setGoogleAuthCode] = React.useState(null);

  const [fields, setFields] = React.useState([]);

  const { Field: Name } = useUncontrolledField({
    fieldScheme: {
      ...fieldRegisterClientName,
      label: registerTranslations.name,
    },
    saveIn: setFields,
  });

  const { Field: Email } = useUncontrolledField({
    fieldScheme: {
      ...fieldRegisterClientEmail,
      label: registerTranslations.email,
    },
    saveIn: setFields,
  });

  const { Field: CompanyGroupLimit } = useUncontrolledField({
    fieldScheme: {
      ...fieldRegisterClientCompanyGroupLimit,
      label: registerTranslations.company_group_limit,
    },
    saveIn: setFields,
  });

  const { Field: CompanyLimit } = useUncontrolledField({
    fieldScheme: {
      ...fieldRegisterClientCompanyLimit,
      label: registerTranslations.company_limit,
    },
    saveIn: setFields,
  });

  const { Field: LocationGroupLimit } = useUncontrolledField({
    fieldScheme: {
      ...fieldRegisterClientLocationGroupLimit,
      label: registerTranslations.location_group_limit,
    },
    saveIn: setFields,
  });

  const { Field: LocationLimit } = useUncontrolledField({
    fieldScheme: {
      ...fieldRegisterClientLocationLimit,
      label: registerTranslations.location_limit,
    },
    saveIn: setFields,
  });

  const {
    userAccessToken,
  } = useAuth();

  const handleSubmit = async (e) => {
    e.preventDefault();
    const nameFieldValue = fields[0].getValue();
    const emailFieldValue = fields[1].getValue();
    const companyGroupLimitFieldValue = fields[2].getValue();
    const companyLimitFieldValue = fields[3].getValue();
    const locationGroupLimitFieldValue = fields[4].getValue();
    const locationLimitFieldValue = fields[5].getValue();

    if (isEmpty(nameFieldValue.trim())) {
      enqueueSnackbar('Campo de Nome é obrigatório', { variant: 'warning' });
      return;
    }

    if (nameFieldValue.length < 4) {
      enqueueSnackbar('O nome do usuário deve ter pelo menos 4 caracteres', { variant: 'warning' });
      return;
    }

    if (isEmpty(emailFieldValue.trim())) {
      enqueueSnackbar('Campo de Email é obrigatório', { variant: 'warning' });
      return;
    }

    if (!emailIsValid(emailFieldValue)) {
      enqueueSnackbar('Formato de Email inserido não é válido', { variant: 'warning' });
      return;
    }

    if (!companyGroupLimitFieldValue) {
      enqueueSnackbar('Campo de Limite de Grupos de Empresas é obrigatório', { variant: 'warning' });
      return;
    }

    if (companyGroupLimitFieldValue < 0 || isNaN(companyGroupLimitFieldValue)) {
      enqueueSnackbar('Valor de Limite de Grupos Empresas inválido', { variant: 'warning' });
      return;
    }

    if (!companyLimitFieldValue) {
      enqueueSnackbar('Campo de Limite de Empresas é obrigatório', { variant: 'warning' });
      return;
    }

    if (companyLimitFieldValue < 0 || isNaN(companyLimitFieldValue)) {
      enqueueSnackbar('Valor de Limite de Empresas inválido', { variant: 'warning' });
      return;
    }

    if (!locationGroupLimitFieldValue) {
      enqueueSnackbar('Campo de Limite de Grupos de Locais é obrigatório', { variant: 'warning' });
      return;
    }

    if (locationGroupLimitFieldValue < 0 || isNaN(locationGroupLimitFieldValue)) {
      enqueueSnackbar('Valor de Limite de Grupos de Locais inválido', { variant: 'warning' });
      return;
    }

    if (!locationLimitFieldValue) {
      enqueueSnackbar('Campo de Limite de Locais é obrigatório', { variant: 'warning' });
      return;
    }

    if (locationLimitFieldValue < 0 || isNaN(locationLimitFieldValue)) {
      enqueueSnackbar('Valor de Limite de Grupos de Locais inválido', { variant: 'warning' });
      return;
    }

    setLoading(true);
    try {
      const { userId } = await signUpClientUser(
        {
          name: nameFieldValue,
          email: emailFieldValue,
          google_refresh_token: refreshToken,
          company_group_limit: Number(companyGroupLimitFieldValue),
          location_group_limit: Number(locationGroupLimitFieldValue),
          company_limit: Number(companyLimitFieldValue),
          location_limit: Number(locationLimitFieldValue),
          created_by: user.user.id,
          accessToken: userAccessToken,
        },
      );

      await createConnection({
        accessToken: user.accessToken,
        channel: 'google',
        createdBy: user.user.id,
        userId,
        refreshToken,
        token: googleGeneratedAccessToken,
        tokenExpiresIn: googleTokenExpirationDate,
        code: googleAuthCode,
      });

      fields.map((field) => {
        field.reset();
      });

      enqueueSnackbar(
        'Usuário cadastrado com sucesso. Enviamos um link para o email contendo a url para redefinição de senha',
        { variant: 'success' },
      );
    } catch (err) {
      const variantError = 'error';

      if (err.status !== 409 && err.status !== 400) {
        enqueueSnackbar('Error ao tentar cadastrar seu usuário. Tente mais tarde!', { variant: variantError });
      }

      if (err.status === 409) enqueueSnackbar('Já existe uma conta com esse email.', { variant: variantError });
      if (err.status === 400) enqueueSnackbar('Formato de senha inválido.', { variant: variantError });
    }

    setLoading(false);
  };

  const googleSuccessResponse = async (response) => {
    setGoogleAuthCode(response.code);
    const {
      refresh_token: googleRefreshToken,
      access_token: googleAccessToken,
      expires_in: expiresIn,
    } = await generateRefreshToken({
      authorizationCode: response.code,
      isLoading: setIsLoadingRefreshToken,
    });
    setRefreshToken(googleRefreshToken);
    setGoogleGeneratedAccessToken(googleAccessToken);
    const tokenExpirationDate = new Date();
    tokenExpirationDate.setSeconds(tokenExpirationDate.getSeconds() + expiresIn);

    setGoogleTokenExpirationDate(tokenExpirationDate.toUTCString());
  };

  return (
    <StyledForm onSubmit={handleSubmit}>
      <ConfigCard
        title="Registrar Cliente"
        buttonTitle="Registrar"
        loading={loading}
      >
        <StyledRegisterClients>
          <StyleInputGroup>
            <Name />
            <Email />
            <StyledRefreshTokenDiv>
              <Input
                value={refreshToken}
                disabled
                border
                label="Refresh Token"
                inputOptions={{ name: 'refresh-token' }}
              />
              <GoogleLogin
                clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}
                onSuccess={googleSuccessResponse}
                onFailure={() => { }}
                scope="https://www.googleapis.com/auth/business.manage"
                prompt="consent"
                responseType="code"
                uxMode="redirect"
                redirectUri="https://saas.hublocal.com.br/receive-foursquare-code"
                render={(renderProps) => (isLoadingRefreshToken ? <Loading /> : (
                  <IconButton
                    tooltip="Gerar Token do Google"
                    icon="VpnKey"
                    onClick={renderProps.onClick}
                  />
                ))}
              />
            </StyledRefreshTokenDiv>
            <CompanyGroupLimit />
            <CompanyLimit />
            <LocationGroupLimit />
            <LocationLimit />
          </StyleInputGroup>
        </StyledRegisterClients>
      </ConfigCard>
    </StyledForm>
  );
};

export default RegisterClient;
