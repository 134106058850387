import axios from 'axios';
import * as R from 'ramda';

export default async function createLocation({
  accessToken,
  name,
  address1,
  address2,
  neighborhood,
  city,
  country,
  state,
  zip_code,
  categories,
  google_categories,
  facebook_categories,
  foursquare_categories,
  main_phone,
  data_studio,
  secondary_phone,
  storeCode,
  isActive = true,
  doesNotServeAtThisAddress,
  googleServiceArea,
  responsible,
  setTokenLikeExpired,
  setIsCreating,
  feedbackMessage,
  email,
  company_id,
  contract_type,
  other_links,
  latitude,
  longitude,
  setOpenChooseUserDialog
}) {
  try {
    if (setIsCreating) setIsCreating(true);
    if (feedbackMessage) feedbackMessage('Criando local...', { variant: 'warning' });

    const { data } = await axios.post(`${process.env.REACT_APP_LOCATIONS_API_URL}`,
      {
        name,
        address1,
        address2,
        city,
        neighborhood,
        country,
        email,
        state,
        data_studio,
        phone: main_phone,
        secondary_phone,
        zip_code,
        store_code: storeCode,
        categories,
        google_categories,
        facebook_categories,
        foursquare_categories,
        is_active: isActive,
        google_service_area: googleServiceArea,
        does_not_serve_at_this_address: doesNotServeAtThisAddress,
        responsible,
        latitude,
        longitude,
        company_id,
        contract_type,
        other_links,
      },
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });

    if (feedbackMessage) { feedbackMessage('Local criado com sucesso', { variant: 'success' }); }

    return data;
  } catch (err) {
    if (err.response?.status === 401 && setTokenLikeExpired) return setTokenLikeExpired();
    let message = 'Erro ao criar o local';

    if (err.response?.data?.errorCode === 'location-limit') message = 'Você atingiu o limite de locais';
    if (err.response?.data?.errorCode === 'owner-deleted') {
      message = 'O dono dessa empresa encontra-se deletado!';
      if (setOpenChooseUserDialog) setOpenChooseUserDialog(true);
    };

    if (feedbackMessage) feedbackMessage(message, { variant: 'error' });

    return null;
  } finally {
    if (setIsCreating) setIsCreating(false);
  }
}
