import React from 'react';
import { HighlightOff as HighlightOffIcon } from '@material-ui/icons';
import { debounce } from 'lodash';
import * as R from 'ramda';
import { useSnackbar } from 'notistack';

import getPlacesWithAutocomplete from '../../services/google/getPlacesWithAutocomplete';

import {
  StyledGoogleServiceAreaInput,
  StyledInputSearch,
  StyledListingSearch,
  StyledSelectedOptions,
  StyledSelectedOption,
  StyledSelectedActionsWrapper,
  StyledInputSwitchWrap,
  StyledDisabledInput,
} from './google-service-area-places-input-styles';
import { Tooltip } from '..';
import InputSwitch from '../input-switch';

import type { TGooglePlaceAutocompletePrediction } from '../../types/TGooglePlaces';
import type { TGooglePlaceInfo } from '../../types/TGoogle';

export type TGoogleServiceAreaInput = {
  label?: string;
  initialSelectedPlaces?: TGooglePlaceInfo[];
  onSelectedPlacesChange?: (
    selectedPlaces: TGooglePlaceInfo[] | 'MUST_BE_EMPTY'
  ) => void;
  className?: string;
  disabledDeleteOption?: boolean;
  setOtherFieldsToDefault?: any;
  withSwitch?: boolean;
  showDisclaimer?: boolean;
};

const GoogleServiceAreaPlacesInput = ({
  label,
  initialSelectedPlaces,
  className = 'google-service-area-input',
  disabledDeleteOption = false,
  setOtherFieldsToDefault = null,
  withSwitch = false,
  onSelectedPlacesChange,
  showDisclaimer = false,
}: TGoogleServiceAreaInput) => {
  const [openListing, setOpenListing] = React.useState(false);
  const [inputSearch, setInputSearch] = React.useState('');
  const [disclaimer, setDisclaimer] = React.useState('');
  const [isSwitchOn, setIsSwitchOn] = React.useState(false);
  const [placesToSelect, setPlacesToSelect] = React.useState<
    TGooglePlaceAutocompletePrediction[]
  >([]);
  const [selectedPlaces, setSelectedPlaces] = React.useState<
    TGooglePlaceInfo[] | 'MUST_BE_EMPTY'
  >(initialSelectedPlaces || []);

  const inputRef = React.useRef<HTMLInputElement>(null);
  const { enqueueSnackbar } = useSnackbar();

  const handleInputSearch = debounce(async (text: string) => {
    setInputSearch(text);
    const textNormalizedUtf8 = text
      .normalize('NFD')
      .replace(/[\u0300-\u036f]/g, '');
    const { predictions } = (await getPlacesWithAutocomplete({
      keyword: textNormalizedUtf8,
      types:
          'locality|sublocality|administrative_area_level_3|country|political',
    })) || {};
    if (R.isNil(predictions)) {
      setDisclaimer('Houve um erro ao buscar áreas de serviço');
      return;
    }
    setPlacesToSelect(predictions);
    setDisclaimer('');
  }, 400);

  const handleCleanInputText = () => {
    setInputSearch('');
    setPlacesToSelect([]);
    setOpenListing(false);

    if (inputRef.current) inputRef.current.value = '';
  };

  const handleAddPlace = (placeToAdd: TGooglePlaceAutocompletePrediction) => {
    if (setOtherFieldsToDefault) setOtherFieldsToDefault();
    if (selectedPlaces.length >= 20) {
      enqueueSnackbar('Você já atingiu o limite de locais adicionados', { variant: 'error' });
      return;
    }
    setSelectedPlaces((prevSelected) => {
      const currentSelected = prevSelected === 'MUST_BE_EMPTY' ? [] : prevSelected;

      if (!placeToAdd.place_id) return prevSelected;

      const isAlreadyAdded = !!currentSelected.find(
        (placeSelected) => placeSelected.placeId === placeToAdd.place_id
          || placeSelected.placeName === placeToAdd.description,
      );

      if (isAlreadyAdded) return prevSelected;

      return [
        ...currentSelected,
        { placeName: placeToAdd.description, placeId: placeToAdd.place_id },
      ];
    });
    handleCleanInputText();
    setDisclaimer('');
  };

  const handleRemovePlace = (placeToDelete: TGooglePlaceInfo) => {
    if (setOtherFieldsToDefault) setOtherFieldsToDefault();

    setSelectedPlaces((prevSelected) => {
      if (prevSelected === 'MUST_BE_EMPTY') return prevSelected;
      const filteredPlaces = prevSelected.filter(
        (selectedPlace) => selectedPlace.placeId !== placeToDelete.placeId,
      );
      if (!withSwitch || !isSwitchOn || filteredPlaces?.length > 0) { return filteredPlaces; }
      return 'MUST_BE_EMPTY';
    });
  };

  React.useEffect(() => {
    setSelectedPlaces(
      (withSwitch && isSwitchOn) ? 'MUST_BE_EMPTY' : (initialSelectedPlaces || []),
    );
  }, [isSwitchOn, initialSelectedPlaces]);

  React.useEffect(() => {
    if (onSelectedPlacesChange) { onSelectedPlacesChange(selectedPlaces); }
  }, [selectedPlaces]);

  return (
    <StyledGoogleServiceAreaInput className={className}>
      {label && <label htmlFor="options">{label}</label>}
      <StyledInputSearch>
        <>
          {(!withSwitch || isSwitchOn) && (
            <>
              <input
                ref={inputRef}
                type="text"
                placeholder="Buscar"
                onChange={(e) => handleInputSearch(e.target.value)}
                autoComplete="off"
                onClick={() => setOpenListing(true)}
                onFocus={() => setOpenListing(true)}
                onBlur={() => setTimeout(() => setOpenListing(false), 500)}
              />
              {(openListing
                || inputSearch !== ''
                || !R.isEmpty(selectedPlaces)) && (
                <HighlightOffIcon
                  className="clean-text-input"
                  onClick={handleCleanInputText}
                />
              )}
            </>
          )}

          {withSwitch && !isSwitchOn && (
            <StyledDisabledInput>Desabilitado</StyledDisabledInput>
          )}

          {withSwitch && (
            <StyledInputSwitchWrap>
              <InputSwitch
                changeChecked={() => setIsSwitchOn((prevState) => !prevState)}
                checked={isSwitchOn}
              />
            </StyledInputSwitchWrap>
          )}
        </>

        {openListing && (
          <StyledListingSearch>
            {placesToSelect
              && placesToSelect.map((placeToSelect) => (
                <li
                  key={`${placeToSelect.place_id}-${placeToSelect.description}`}
                  role="presentation"
                  onClick={() => {
                    handleAddPlace(placeToSelect);
                  }}
                >
                  {placeToSelect.description}
                </li>
              ))}
          </StyledListingSearch>
        )}
      </StyledInputSearch>

      {(!withSwitch || isSwitchOn)
        && Array.isArray(selectedPlaces)
        && selectedPlaces.length > 0 && (
          <StyledSelectedOptions>
            {selectedPlaces.map((selectedPlace) => (
              <StyledSelectedOption key={`${selectedPlace.placeId}-${selectedPlace.placeName}`}>
                <Tooltip
                  title={selectedPlace.placeName}
                  placement="bottom"
                  variant="babyBlue"
                  arrow
                >
                  <div className="option-display-name">{selectedPlace.placeName}</div>
                </Tooltip>
                {' '}
                <StyledSelectedActionsWrapper>
                  {!disabledDeleteOption && (
                    <HighlightOffIcon
                      onClick={() => handleRemovePlace(selectedPlace)}
                    />
                  )}
                </StyledSelectedActionsWrapper>
              </StyledSelectedOption>
            ))}
          </StyledSelectedOptions>
      )}
      {showDisclaimer && disclaimer && (
        <small className="disclaimer">{disclaimer}</small>
      )}
    </StyledGoogleServiceAreaInput>
  );
};

export default GoogleServiceAreaPlacesInput;
