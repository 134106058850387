import styled from 'styled-components';
import { Paper } from '@material-ui/core';
import { devices } from '../../../assets/styles/devices';
import { Button } from '../../../components';

export const StyledPersonalInfo = styled.div``;

export const StyledPersonalInfoContainer = styled(Paper)`
  display: flex;
  flex-direction: column;
  padding: 2rem;
  margin-top: 2rem;
  margin-bottom: 2rem;
  background-color: ${props => props.theme.colors.white};
`;

export const StyledInputGroup = styled.div`
  display: grid;

  @media ${devices.tabletLandscape} {
    column-gap: 2rem;
    grid-template-columns: repeat(2,1fr);
  }

  @media ${devices.desktop} {
    grid-template-columns: repeat(3,1fr);
  }
`;

export const StyledTitleLabel = styled.h3`
  display: flex;
  font-weight: 700;
  font-size: 1.5rem;
  margin-bottom: 0;
`;

export const StyledTitleLabelWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

export const StyledTextWrapper = styled.div`
  flex-direction: column;
  justify-content: center;
  margin-bottom: 1.5rem;
  word-wrap: break-word;
  overflow: hidden;

  @media ${devices.desktop} {
    margin-bottom: 0;
  }

  &:last-of-type {
    margin-bottom: 0;
  }
`;

export const StyledTextLabel = styled.h3`
  font-size: 1.4rem;
  font-weight: bold;
  color: ${props => props.theme.colors.primary};
  line-height: 1;
`;

export const StyledPersonalInfoInput = styled.div``;

export const StyledButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;

  .is-loading-save-infos {
    margin-right: 1rem;
  }
`;

export const StyledButton = styled(Button)`
  margin-top: 2rem;
  margin-left: 1rem;
`;
