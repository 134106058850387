import styled from 'styled-components';
import { shade } from 'polished';

export const StyledChooseUserDialog = styled.div``;

export const StyledChooseUserDialogContent = styled.div`
  display: flex;
  flex-direction: column;
  max-height: 30rem;
  overflow: hidden;
  background: ${props => shade(0.1, props.theme.colors.white)};
  padding: 2rem;
  
  &:hover {
    overflow-y: scroll;
  }
  
  .choose-user-loading {
    margin: 0 auto;
  }
  
  .choose-user-pagination {
    margin-top: 0.2rem;
  }
`;

export const StyledDisclaimer = styled.div`
  display: flex;
  margin-bottom: 2rem;
`;
