import React from 'react';

import { StyledBurguer, StyledBurguerLine } from './burguer-styles';

const Burguer = ({ status, changeStatus, className }) => (
  <StyledBurguer
    className={className}
    onClick={() => changeStatus(!status)}
    open={status}
  >
    <StyledBurguerLine className="start" />
    <StyledBurguerLine className="center" />
    <StyledBurguerLine className="end" />
  </StyledBurguer>
);

export default Burguer;
