import styled from 'styled-components';
import { devices } from '../../../../assets/styles/devices';

export const StyledInfos = styled.div`
  width: 100%;
`;

export const StyledInputGroup = styled.div`
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  margin-bottom: 2rem;
  column-gap: 2rem;
  row-gap: 1rem;

  @media ${devices.tabletLandscape} {
    grid-template-columns: repeat(2, 1fr);
    row-gap: 0;
  }

  @media ${devices.desktop} {
    grid-template-columns: repeat(4, 1fr);
  }

  &:last-of-type {
    margin-bottom: 0;
  }

  .autocomplete-input {
    margin-bottom: 1rem;
  }
`;

export const StyledSectionTitle = styled.h3`
  font-size: 1.5rem;
  font-weight: bold;
  color: ${props => props.theme.colors.primary};
  margin-bottom: 1.5rem;
  line-height: 1;
`;

export const StyledDescriptionWrapper = styled.div`
    display: flex;
    flex-direction: column;
    row-gap: 1.5rem;
`;
