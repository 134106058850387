import axios from 'axios';

import type { TFindBatchInfosLocations } from '../../../types/TBatchGoogleModules';

export const findBatchInfosLocations = async ({
  batchInfosId,
  isFetching,
  userAccessToken,
  page = 0,
  pageSize = 50,
  status,
  query = '',
  affectedPlatforms = '',
  affectedPlatformsChecked = false,
}: TFindBatchInfosLocations): Promise<{
  status: 'SUCCESS' | 'ERROR';
  data: any;
}> => {
  try {
    if (isFetching) isFetching(true);

    let queryPath = '';

    if (batchInfosId) queryPath += `batchId=${batchInfosId}`;
    if (status) queryPath += `status=${status}`;

    const { data } = await axios.get(
      `${process.env.REACT_APP_SAAS_BATCH_GOOGLE_MODULES}/batch-infos-locations?${queryPath}&page=${page}&pageSize=${pageSize}&query=${query}&affectedPlatforms=${affectedPlatforms}&affectedPlatformsChecked=${affectedPlatformsChecked}`,
      {
        headers: {
          Authorization: `Bearer ${userAccessToken}`,
        },
      },
    );

    return {
      status: 'SUCCESS',
      data,
    };
  } catch (err: any) {
    return {
      status: 'ERROR',
      data: err.response,
    };
  } finally {
    if (isFetching) isFetching(false);
  }
};
