import axios from 'axios';

export default async function createFunction(
  accessToken,
  name,
  description,
  friendly_name,
  handlerId,
  path_to_redirect,
  icon,
  slug,
  direct_access,
  is_available_in_menu,
  created_by = null,
) {
  const requestOptions = {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  };

  try {
    const { data } = await axios.post(
      `${process.env.REACT_APP_FUNCTIONS_API_URL}`,
      created_by
        ? {
          name,
          description,
          friendly_name,
          handlerId,
          slug,
          icon,
          path_to_redirect,
          direct_access,
          is_available_in_menu,
          created_by,
        }
        : {
          name,
          description,
          friendly_name,
          handlerId,
          icon,
          slug,
          path_to_redirect,
          direct_access,
          is_available_in_menu,
        },
      requestOptions,
    );

    console.log(data);
    return data;
  } catch (err) {
    console.log(err);
    return err;
  }
}
