import { v4 as UUIDV4 } from 'uuid';

import { EBIStatus } from '../../../services/business-intelligence/bi-job/enums';
import { EBIModule, EPlatforms } from '../../../services/business-intelligence/enums';
import { TDefaultPropOption } from '../../../types/TSelect';

export const filterSelectPlatformsOptions: TDefaultPropOption[] = [
  {
    id: UUIDV4(),
    name: 'Facebook',
    value: EPlatforms.FACEBOOK,
  },
  {
    id: UUIDV4(),
    name: 'Google',
    value: EPlatforms.GOOGLE,
  },
  {
    id: UUIDV4(),
    name: 'Yelp',
    value: EPlatforms.YELP,
  },
  {
    id: UUIDV4(),
    name: 'Foursquare',
    value: EPlatforms.FOURSQUARE,
  },
];

export const filterSelectStatus: TDefaultPropOption[] = [
  {
    id: UUIDV4(),
    name: 'Pendente',
    value: EBIStatus.PENDING,
  },
  {
    id: UUIDV4(),
    name: 'Processando',
    value: EBIStatus.PROCESSING,
  },
  {
    id: UUIDV4(),
    name: 'Re-Processando',
    value: EBIStatus.RE_PROCESSING,
  },
  {
    id: UUIDV4(),
    name: 'Alerta',
    value: EBIStatus.WARNING,
  },
  {
    id: UUIDV4(),
    name: 'Erro',
    value: EBIStatus.ERROR,
  },
  {
    id: UUIDV4(),
    name: 'Concluído',
    value: EBIStatus.DONE,
  },
];

export const filterSelectErrorByModule: TDefaultPropOption[] = [
  {
    id: UUIDV4(),
    name: 'Google Local: Informações',
    value: EBIModule.GOOGLE_LOCATION_INFOS,
  },
  {
    id: UUIDV4(),
    name: 'Google Local: Postagens',
    value: EBIModule.GOOGLE_LOCATION_POSTS,
  },
  {
    id: UUIDV4(),
    name: 'Google Local: Avaliações',
    value: EBIModule.GOOGLE_LOCATION_REVIEWS,
  },
  {
    id: UUIDV4(),
    name: 'Google Local: Medias',
    value: EBIModule.GOOGLE_LOCATION_MEDIAS,
  },
];
