import React from 'react';
import { isEmpty, isNil } from 'ramda';

import { format } from 'date-fns';
import type { EActionLogActions } from '../../types/TBatchGoogleModules';

import IconButton from '../icon-button';

import {
  StyledDialog,
  StyledFormContent,
  StyledHeader,
  StyledInfo,
  StyledInfoWrapper,
  StyledLogDate,
  StyledLogRow,
  StyledPostAndFilterWrapper,
  StyledRowInfo,
  StyledText,
  StyledLogsWrapper,
} from './log-history-data-styles';
import { actionLogStatus } from '../../utils/action-log-status';
import { useAuth } from '../../hooks';
import LogErrorDataAccordion from '../log-error-data-accordion';

export type THistoryDataFormat = {
  'id': string,
  'user_name': string,
  'user_email': string,
  'description': string,
  'action': EActionLogActions,
  'error_data': any,
  'created_at': Date,
}

type TLogHistoryData = {
  open: boolean,
  close: () => void,
  data: THistoryDataFormat[],
  batchLocationName: string
}

const LogHistoryData = ({
  open = false,
  close,
  data,
  batchLocationName,
}: TLogHistoryData) => {
  const { userProfileName } = useAuth();

  const shouldShowErrorDetailsAccordion = React.useCallback(
    (logAction: EActionLogActions) => logAction === 'ERROR'
      && (userProfileName === 'Admin' || userProfileName === 'Operacional'),
    [userProfileName],
  );

  return (
    <StyledDialog
      open={open}
      aria-labelledby="simple-dialog-title"
      onClose={close}
    >
      <StyledHeader>
        Histórico de Status
        {batchLocationName ? ` | ${batchLocationName}` : ''}
        <IconButton
          icon="Close"
          tooltip="Fechar"
          onClick={close}
        />
      </StyledHeader>

      {isEmpty(data) && <StyledText>Nenhum Hístorico para ser exibido</StyledText>}

      {!isNil(data) && !isEmpty(data) && (
      <StyledLogsWrapper>
        {data.map(history => (
          <StyledPostAndFilterWrapper key={history.id}>
            <StyledInfoWrapper>
              <StyledFormContent>
                <StyledLogRow>
                  <StyledInfo>
                    <StyledLogDate>
                      Data da Ação:
                      {' '}
                      {format(
                        new Date(history.created_at),
                        'dd/MM/yyyy HH:mm:ss',
                      )}
                    </StyledLogDate>
                  </StyledInfo>

                  <StyledRowInfo>
                    <StyledInfo>
                      <p>
                        Status:&nbsp;
                        <b>{actionLogStatus[history.action]}</b>
                      </p>
                    </StyledInfo>

                    <StyledInfo>
                      <p>
                        Usuário:&nbsp;
                        <b>{history.user_name}</b>
                      </p>
                    </StyledInfo>
                  </StyledRowInfo>

                  <StyledRowInfo>
                    <StyledInfo>
                      <p>
                        Descrição:&nbsp;
                        {history.description}
                      </p>
                    </StyledInfo>

                    <StyledInfo>
                      <p>
                        Email:&nbsp;
                        <b>{history.user_email}</b>
                      </p>
                    </StyledInfo>
                  </StyledRowInfo>
                  {shouldShowErrorDetailsAccordion(history.action) && (
                  <StyledRowInfo>
                    <StyledInfo>
                      <LogErrorDataAccordion
                        errorData={history.error_data}
                      />
                    </StyledInfo>
                  </StyledRowInfo>
                  )}
                </StyledLogRow>
              </StyledFormContent>
            </StyledInfoWrapper>
          </StyledPostAndFilterWrapper>
        ))}
      </StyledLogsWrapper>
      )}
    </StyledDialog>
  );
};

export default LogHistoryData;
