import React from 'react';

import {
  Paper,
  IconButton, 
} from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';

import { useCreateWorkingHoursModel } from './hook';

import {
  CompanyNavBar,
  CompanyInfoNavBar,
  DaySelector,
} from '../../components';

const CreateWorkingHoursModel = () => {
  const {
    isDefault,
    modelName,
    onBackArrowClicked,
    onChangeIsDefault,
    onChangeModelName,
    onChangePeriodsForm,
    periodsForm,
    renderButton,
  } = useCreateWorkingHoursModel();

  return (
    <div>
      <CompanyNavBar returnUrl="/company/create-working-hours-model" />
      <CompanyInfoNavBar activeButton={2} />
      <div style={{ padding: '1em' }}>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
          }}
        >
          <IconButton onClick={onBackArrowClicked}>
            <ArrowBackIcon />
          </IconButton>
          <text className="font-poppins page-title">
            Modelos de Funcionamento
          </text>
        </div>
        <Paper elevation={0} className="font-poppins" variant="outlined">
          <div style={{ margin: '1em' }}>
            <label>Descrição do Modelo</label>
            <input
              className="form-control"
              value={modelName}
              onChange={onChangeModelName}
            />

            <DaySelector
              onChange={onChangePeriodsForm}
              mondayOpen={periodsForm.mondayOpen}
              mondayClose={periodsForm.mondayClose}
              tuesdayOpen={periodsForm.tuesdayOpen}
              tuesdayClose={periodsForm.tuesdayClose}
              wednesdayOpen={periodsForm.wednesdayOpen}
              wednesdayClose={periodsForm.wednesdayClose}
              thursdayOpen={periodsForm.thursdayOpen}
              thursdayClose={periodsForm.thursdayClose}
              fridayOpen={periodsForm.fridayOpen}
              fridayClose={periodsForm.fridayClose}
              saturdayOpen={periodsForm.saturdayOpen}
              saturdayClose={periodsForm.saturdayClose}
              sundayOpen={periodsForm.sundayOpen}
              sundayClose={periodsForm.sundayClose}
            />

            <div style={{ marginTop: '1em' }}>
              <label style={{ marginRight: '1em' }}>Horário Padrão?</label>
              <input
                type="checkbox"
                checked={isDefault}
                onChange={onChangeIsDefault}
              />
            </div>
            <div style={{ marginTop: '2em' }}>
              <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                {renderButton()}
              </div>
              {modelName === '' ? (
                <div
                  style={{
                    marginTop: '0.6em',
                    display: 'flex',
                    justifyContent: 'flex-end',
                  }}
                >
                  <text style={{ fontSize: '0.8em' }}>
                    * Preencha os campos necessários
                  </text>
                </div>
              ) : (
                <></>
              )}
            </div>
          </div>
        </Paper>
      </div>
    </div>
  );
};

export default CreateWorkingHoursModel;
