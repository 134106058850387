import styled from 'styled-components';
import {
  InputLabel,
  MenuItem,
  FormControl,
  Select,
} from '@material-ui/core';

export const StyledActionSelect = styled.div`
  width: 100%;

  .MuiFormControl-root {
    width: 100%;
  }

  .MuiSelect-filled,
  .MuiFormLabel-root {
    font-size: 1.6rem;
  }
`;

export const StyledInputLabel = styled(InputLabel)``;

export const StyledMenuItem = styled(MenuItem)`
  font-size: 1.6rem;
`;

export const StyledFormControl = styled(FormControl)``;

export const StyledSelect = styled(Select)``;
