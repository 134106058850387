import styled, { css } from 'styled-components';
import { Checkbox } from '@material-ui/core';

import { lighten } from 'polished';

import { TSelectBaseProps } from '../../types/TSelect';

type TStyledSelect = Pick<
  TSelectBaseProps<{}>,
  'border' | 'lightBorder' | 'borderRadius' | 'noBackground'
>;

export const StyledSelect = styled.div<TStyledSelect>`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  margin-bottom: 2rem;

  &:last-of-type {
    margin-bottom: 0;
  }

  label {
    font-size: 1.5rem;
    line-height: 1.9rem;
    font-weight: normal;
    color: ${(props) => props.theme.colors.black};
    margin-bottom: 1rem;
    font-family: "Poppins";
  }

  select {
    width: 100%;
    height: 4.1rem;
    padding: 0 1.5rem;
    border: none;
    appearance: none;
    padding-right: 4.5rem;
    background: ${(props) => props.theme.colors.white};

    ${(props) => props.border
      && css`
        border: 2px solid ${lighten(0.4, props.theme.colors.black)};
      `};

    ${(props) => props.lightBorder
      && css`
        border: 2px solid ${lighten(0.3, props.theme.colors.grey)};
      `};

    ${(props) => props.borderRadius
      && css`
        border-radius: 0.4rem;
      `};
  }

  .MuiInputBase-root {
    width: 100%;
    border: none;
    height: 100%;
    border: 2px solid #666;
    height: 4.1rem;
    margin-top: 0;

    &::before,
    &::after {
      display: none;
    }
  }

  .MuiSelect-root {
    padding: 0;
    font-size: 1.6rem;
    font-family: "Poppins";
    width: 100%;
    padding: 0 3rem 0 1.5rem;
    height: 100%;
    display: flex;
    align-items: center;

    ${(props) => props.noBackground
      && css`
        background: transparent;
      `};
  }

  .MuiSelect-nativeInput {
    display: none;
  }

  .MuiSelect-icon {
    top: unset;
    height: unset;
    width: 2.5rem;
  }
`;

export const StyledInputValidate = styled.span<{ type: string }>`
  margin-top: 0.3rem;
  font-size: 1rem;
  font-weight: 500;
  text-transform: uppercase;

  ${(props) => props.type === 'error'
    && css`
      color: ${props.theme.colors.red};
    `};
`;

export const StyledCheckbox = styled(Checkbox)`
  color: ${(props) => props.theme.colors.primary};

  .MuiSvgIcon-root {
    fill: ${(props) => props.theme.colors.primary};
  }
`;
