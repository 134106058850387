import React, { useCallback } from 'react';
import SyncStatusIconMonitor from '../../../../../../components/sync-status-icon-monitor';
import { ESyncMonitoringPlatformStatus } from '../../../../../../components/sync-status-icon-monitor/types';
import { fieldsName } from '../helpers';
import {
  StyledListItem,
  StyledListItemWrapper,
  StyledIconWrapper,
} from './monitor-list-popover.styles';
import { TMonitorsListPopoverProps } from './types';

const MonitorsListPopover = ({
  data,
}: TMonitorsListPopoverProps) => {
  const getFieldTitle = useCallback((key: string) => {
    const {
      infoBasic, categories, addressInfo, links, other_links,
    } = fieldsName;

    if (infoBasic[key]) return infoBasic[key].name;
    if (categories[key]) return categories[key].name;
    if (addressInfo[key]) return addressInfo[key].name;
    if (links[key]) return links[key].name;
    if (other_links[key]) return other_links[key].name;
  }, [fieldsName]);

  return (
    <section>
      {
      data.sort(
        (firstArg, secondArg) => firstArg.order - secondArg.order,
      ).map(item => (
        <StyledListItemWrapper>
          <StyledListItem className="saasField">
            <p className="title">{getFieldTitle(item.label.toLowerCase())}</p>
            <p>
              {item.saas_data.value}
            </p>
          </StyledListItem>

          <StyledListItem className="platformField">
            <p className="title">{getFieldTitle(item.label.toLowerCase())}</p>
            <p>
              {item.platform_data.value}
            </p>
          </StyledListItem>

          <StyledIconWrapper className="statusIcon">
            <SyncStatusIconMonitor
              status={ESyncMonitoringPlatformStatus[item.status]}
              syncType="monitor"
            />
          </StyledIconWrapper>
        </StyledListItemWrapper>
      ))
    }
    </section>
  );
};

export default MonitorsListPopover;
