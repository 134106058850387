import React from 'react';
import { useSelector } from 'react-redux';
import FadeInEffect from 'react-fade-in';
import { uniqueId } from 'lodash';
import {
  isNil, isEmpty, last, flatten,
} from 'ramda';
import { GooglePagination } from '../../../components';

import { getReviewsByLocations } from '../../../services/google/reviews/get-reviews-by-locations';

import { useReviewsContext } from '../../../contexts/reviews';

import { isReview, isReviewFiltered } from './helpers';

import SectionHeader from '../section-header';
import Actions from './actions';

import AutomaticResponse from './automatic-response';
import Review from './review';

import {
  StyledReviewsListing,
  StyledReviewsByLocation,
  StyledGooglePaginationWrapper,
  StyledReviewsInfos,
  StyledReviewsNotFound,
  StyledSpeakerNotesOff,
} from './reviews-listing-styles';
import { useHasAccess } from '../../../hooks';

const ReviewsListing = () => {
  const { guestType } = useHasAccess();

  const {
    user: {
      user: {
        id: userId,
        profiles,
      },
    },
  } = useSelector(state => state.AuthReducer);

  const { name: userProfileName } = profiles[0];

  const {
    reviewsData,
    googleUserAccessToken,
    reviewsDataListing,
    locationsToFilter,
    setReviewsDataListing,
    setReviewsData,
    starRating: { currentStarRating },
    listAutomaticResponsesBy: { currentListBy },
    sensitiveWordsToFilter,
    setReviewsChecked,
    googleReviewsInitialPageToken,
    ellegibleLocations,
    getLocationsToGetReviews,
    googleLocationsByAccountRef,
  } = useReviewsContext();

  const [
    googleLocationReviewsNextPageToken,
    setGoogleLocationReviewsNextPageToken,
  ] = React.useState(null);

  const [googlePaginationLoading, setGooglePaginationLoading] = React.useState(false);

  const [googlePaginationPageIndex, setGooglePaginationPageIndex] = React.useState(1);
  const [googlePaginationPageList, setGooglePaginationPageList] = React.useState(['']);
  const [showAutomaticResponses, setShowAutomaticResponses] = React.useState(false);

  React.useEffect(() => {
    setReviewsChecked([]);
  }, []);

  React.useEffect(() => {
    setGoogleLocationReviewsNextPageToken(googleReviewsInitialPageToken);
  }, [googleReviewsInitialPageToken]);

  const handleShowAutomaticResponses = () => {
    setShowAutomaticResponses(!showAutomaticResponses);
  };

  const handleGooglePaginationOnNextPageClicked = React.useCallback(async pageToken => {
    const googleLocations = flatten(
      Object.values(googleLocationsByAccountRef.current)
        .map((locations) => locations)
        .filter((locations) => !isNil(locations)),
    );

    const googleLocationsToGetReviews = getLocationsToGetReviews({
      ellegibleLocations,
      googleLocations,
      locationsFiltered: locationsToFilter,
      guestType,
      userId,
      userProfileName,
    });

    if (isEmpty(googleLocationsToGetReviews)) {
      setReviewsData([]);
      setReviewsDataListing([]);

      return;
    }

    const reviewsByLocations = await getReviewsByLocations({
      googleAccessToken: googleUserAccessToken,
      locations: googleLocationsToGetReviews,
      isFetching: setGooglePaginationLoading,
      pageToken,
    });

    if (isNil(reviewsByLocations)) return;

    const { reviews, nextPageToken } = reviewsByLocations;

    setReviewsData(reviews);
    setReviewsDataListing(reviews);
    setGoogleLocationReviewsNextPageToken(nextPageToken);

    window.scrollTo(0, 300);
  }, [
    ellegibleLocations,
    googleUserAccessToken,
    locationsToFilter,
    googleLocationReviewsNextPageToken,
    userId,
    userProfileName,
    guestType,
  ]);

  const handleGooglePaginationOnBackPageClicked = () => {
    googlePaginationPageList.pop();
    const lastTokenFromList = last(googlePaginationPageList);

    handleGooglePaginationOnNextPageClicked(lastTokenFromList);

    window.scrollTo(0, 300);
  };

  const handleGooglePaginationOnResetPage = () => {
    setGooglePaginationPageList(['']);
    handleGooglePaginationOnNextPageClicked(null);
  };

  return (
    <StyledReviewsListing>
      <FadeInEffect className="fade-in-effect">
        <SectionHeader title="Avaliações" />
        <Actions handleShowAutomaticResponses={handleShowAutomaticResponses} />

        {showAutomaticResponses && (
          <AutomaticResponse
            handleShowAutomaticResponses={handleShowAutomaticResponses}
            reviews={reviewsDataListing}
            currentListBy={currentListBy}
          />
        )}

        <StyledGooglePaginationWrapper>
          <h3>Paginação das avaliações</h3>

          <small>
            Para exibir mais avaliações é necessário avançar para a próxima
            página.
          </small>

          <GooglePagination
            className="google-reviews-pagination"
            nextPageToken={googleLocationReviewsNextPageToken}
            pageIndex={googlePaginationPageIndex}
            setPageIndex={setGooglePaginationPageIndex}
            onNextPageClicked={() => handleGooglePaginationOnNextPageClicked(googleLocationReviewsNextPageToken)}
            onBackPageClicked={handleGooglePaginationOnBackPageClicked}
            onResetClicked={handleGooglePaginationOnResetPage}
            loading={googlePaginationLoading}
            isLabelButton
            pageList={googlePaginationPageList}
            setPageList={setGooglePaginationPageList}
          />

          {!isNil(reviewsData) && !isEmpty(reviewsData) && (
            <StyledReviewsInfos>
              <h4>Informações:</h4>

              <p>
                Quantidade de avaliações nessa página:
                {isReview(reviewsData) ? <b>{reviewsData.length}</b> : <b>0</b>}
              </p>

              {isReviewFiltered({
                currentListBy,
                currentStarRating,
                sensitiveWordsToFilter,
                locationsToFilter,
              }) && (
                <>
                  <div className="filterBy">
                    Filtros aplicados:
                    <ul>
                      {currentListBy !== 'all' && (
                        <li>
                          <b>
                            {currentListBy === 'answered'
                              ? 'Respondidas'
                              : 'Não respondidas'}
                          </b>
                        </li>
                      )}
                      {currentStarRating.rateNumber !== 0 && (
                        <li>
                          <b>
                            {currentStarRating.rateNumber}
                            {' '}
                            Estrela(s)
                          </b>
                        </li>
                      )}
                      {sensitiveWordsToFilter.some(word => word.isFiltering === true) && (
                        <li>
                          {sensitiveWordsToFilter.map((sensitiveWord) => (
                            sensitiveWord.isFiltering === true && (
                            <>
                              <b key={sensitiveWord.id}>
                                {sensitiveWord.word}
                                {' '}
                              </b>
                              ,
                            </>
                            )
                          ))}
                        </li>
                      )}
                      {!isEmpty(locationsToFilter) && (
                        <li>
                          {locationsToFilter.map((location) => (
                            <>
                              <b key={uniqueId()}>{location.name}</b>
                              ,
                            </>
                          ))}
                        </li>
                      )}
                    </ul>
                  </div>

                  <p>
                    Total de avaliações filtradas:
                    {isReview(reviewsDataListing) ? <b>{reviewsDataListing.length}</b> : <b>0</b>}
                  </p>
                </>
              )}
            </StyledReviewsInfos>
          )}
        </StyledGooglePaginationWrapper>

        {(!isEmpty(reviewsDataListing) && !googlePaginationLoading) && (
          <>
            <StyledReviewsByLocation>
              {reviewsDataListing.map((reviewData, index) => (
                <Review
                  reviewData={reviewData}
                  reviewOverlayOrder={reviewsData.length - index}
                  reviewId={reviewData.review.reviewId}
                  as="li"
                  key={reviewData.review.name}
                  reviewerName={reviewData.review.reviewer.displayName}
                  reviewerProfilePhoto={
                    reviewData.review.reviewer.profilePhotoUrl
                  }
                  starRatingWord={reviewData.review.starRating}
                  reviwerComment={reviewData.review.comment}
                  createTime={reviewData.review.createTime}
                  reviewReply={reviewData.review.reviewReply}
                  locationThisReview={reviewData.review.name}
                />
              ))}
            </StyledReviewsByLocation>

            <GooglePagination
              className="google-reviews-pagination google-reviews-pagination--bottom"
              nextPageToken={googleLocationReviewsNextPageToken}
              pageIndex={googlePaginationPageIndex}
              setPageIndex={setGooglePaginationPageIndex}
              onNextPageClicked={() => handleGooglePaginationOnNextPageClicked(googleLocationReviewsNextPageToken)}
              onBackPageClicked={handleGooglePaginationOnBackPageClicked}
              onResetClicked={handleGooglePaginationOnResetPage}
              loading={googlePaginationLoading}
              isLabelButton
              pageList={googlePaginationPageList}
              setPageList={setGooglePaginationPageList}
            />
          </>
        )}

        {(!googlePaginationLoading && isEmpty(reviewsDataListing)) && (
          <StyledReviewsNotFound>
            <StyledSpeakerNotesOff />

            <h3 className="reviews-notfound-subtitle">
              Nenhum avaliação a ser exibida!
            </h3>
          </StyledReviewsNotFound>
        )}
      </FadeInEffect>
    </StyledReviewsListing>
  );
};

export default ReviewsListing;
