import styled, { css } from 'styled-components';
import { shade } from 'polished';

import { devices } from '../../assets/styles/devices';

export const StyledLocationInfoNavBar = styled.nav`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 3rem;
  border-bottom: 2px solid  ${props => shade(0.2, props.theme.colors.primary)};

  @media ${devices.tabletLandscape} {
    flex-direction: row;
  }
`;

export const StyledLocationInfoNavBarItem = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.4rem;
  flex: 1;
  padding: 2rem 2rem;
  color: ${props => props.theme.colors.black};
  text-transform: uppercase;
  background: ${props => shade(0.05, props.theme.colors.headerToolbarBg)};
  transition: 0.3s;
  border-bottom: 1px solid ${props => shade(0.2, props.theme.colors.headerToolbarBg)};

  @media ${devices.tabletLandscape} {
    border-bottom: 0;
    border-right: 1px solid ${props => shade(0.2, props.theme.colors.headerToolbarBg)};
  }

  &:last-of-type {
    border-bottom: 0;
    border-right: 0;
  }

  ${props => props.active && css`
    background: ${props => shade(0.2, props.theme.colors.primary)};
    border-color: transparent !important;
    color: ${props => props.theme.colors.white};
  `}

  &:hover {
    text-decoration: none;
    color: ${props => props.theme.colors.white};
    background: ${props => shade(0.3, props.theme.colors.primary)};
  }
`;
