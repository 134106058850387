import { createActions, createReducer } from 'reduxsauce';

export const { Types, Creators } = createActions({
  setSensitiveWords: ['sensitiveWords'],
});

const initialState = {
  sensitiveWords: [],
};

const setSensitveWords = (state = initialState, { sensitiveWords }) => ({
  ...state,
  sensitiveWords,
});

export default createReducer(initialState, {
  [Types.SET_SENSITIVE_WORDS]: setSensitveWords,
});
