import axios from 'axios';

import { TDeleteResponsible } from '../../types/TLocation';

export default async function deleteResponsible({
  accessToken,
  responsibleId,
  setTokenLikeExpired,
  setIsDeletingResponsible,
  feedbackMessage,
}: TDeleteResponsible) {
  try {
    if (setIsDeletingResponsible) setIsDeletingResponsible(true);
    if (feedbackMessage) feedbackMessage('Removendo responsável...', { variant: 'warning' });

    const { data } = await axios.delete(`${process.env.REACT_APP_RESPONSIBLES_API_URL}/${responsibleId}`,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });

    if (feedbackMessage) feedbackMessage('Responsável removido com sucesso!', { variant: 'success' });

    return data;
  } catch (err: any) {
    if (err.response?.status === 401 && setTokenLikeExpired) setTokenLikeExpired();

    if (feedbackMessage) feedbackMessage('Erro ao tentar remover o responsável', { variant: 'error' });

    console.log(err.response);

    return err;
  } finally {
    if (setIsDeletingResponsible) setIsDeletingResponsible(false);
  }
}
