import styled from 'styled-components';
import { Paper } from '@material-ui/core';

export const StyledChecklist = styled(Paper)`
  display: flex;
  flex-direction: column;
  padding: 2rem;
  height: max-content;
  margin-bottom: 2rem;
  width: 100%;

  svg {
    width: 2rem;
    height: 2rem;
  }

  .MuiCheckbox-colorSecondary.Mui-checked {
    color: ${props => props.theme.colors.primary};
  }

  .MuiFormControlLabel-root {
    margin-left: 0;
    margin-right: 0;
  }
`;

export const StyledTitle = styled.h3`
  display: inline-block;
  width: 100%;
  font-size: 1.5rem;
  font-weight: 700;
  margin-bottom: 2rem;
`;

export const StyledEmptyList = styled.p`
  display: flex;
  margin: 1rem;
  justify-content: center;
`;

export const StyledCheckAllWrapper = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid ${props => props.theme.colors.grey};
`;

export const StyledLocationOptions = styled.div`
  display: flex;
  align-items: center;
`;

export const StyledCountSelectedLocations = styled.p`
  font-size: 8pt;
  color: #4F4F4F;
  margin: 0;
`;

export const StyledLabel = styled.h4`
  margin-right: 1rem;
  margin-bottom: 0;
  font-weight: 700;
  line-height: 2rem;
  font-size: 1.3rem;
`;

export const StyledListContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;

  div {
    width: 100%;
  }
`;
