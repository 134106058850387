import React from 'react';
import * as R from 'ramda';
import * as MaterialUIIcons from '@material-ui/icons/';
import { useHistory } from 'react-router-dom';

import {
  StyledNavItem,
  StyledNavItemName,
} from './nav-item-styles';

const NavItem = ({
  name = 'Sem nome',
  icon = 'Help',
  url = '/',
  hasSubMenu,
  openAccordionMenu,
  setOpenAccordionMenu,
  subItem,
  setIsOpenSideMenu,
  exit = null,
}) => {
  const { push: pushToScreen } = useHistory();

  React.useEffect(() => {
    if (!hasSubMenu) return;
  }, [openAccordionMenu, hasSubMenu]);

  const NavItemIcon = MaterialUIIcons[icon];

  const handlePushToScreen = () => {
    if (!R.isNil(exit)) {
      exit();
      return;
    }

    if (hasSubMenu) {
      setIsOpenSideMenu(true);
    } else {
      setIsOpenSideMenu(false);
      if (setOpenAccordionMenu) setOpenAccordionMenu(0);
      pushToScreen(url);
    }
  };

  return (
    <StyledNavItem
      onClick={handlePushToScreen}
    >
      <NavItemIcon className="nav-item-icon" />
      <StyledNavItemName
        subItem={subItem}
        className="nav-item-label"
      >
        {name}
      </StyledNavItemName>
    </StyledNavItem>
  );
};

export default NavItem;
