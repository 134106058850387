import styled from 'styled-components';

import { devices } from '../../assets/styles/devices';

export const StyledLocationsAsGuest = styled.main`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    height: 100%;
    width: 100%;
    padding: 2rem;
`;

export const StyledHeader = styled.header`
  padding-top: 2rem;
  padding-bottom: 4rem;
  display: flex;
  width: 100%;

  h2 {
    font-size: 2rem;
    line-height: 2.2rem;
    font-weight: 700;
    margin-bottom: 2rem;

    @media ${devices.tabletLandscape} {
      margin-bottom: 0;
    }
  }
`;
