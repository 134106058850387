import React from 'react';
import { isNil, isEmpty, has } from 'ramda';
import { MUIDataTableOptions } from 'mui-datatables';
import { useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';
import { FaSearch } from 'react-icons/fa';
import { Helmet } from 'react-helmet';

import {
  InputWithIcon,
  LocationHeader,
  RoundedButton,
  Table,
  NotificationBox,
  DestructiveActionDialog,
  NoAccessCard,
} from '../../components';

import CreateServiceDialog from './create-service-dialog';

import {
  StyledCreateButtonWrapper,
  StyledFilter,
  StyledContent,
  StyledLocationServices,
  StyledContentFilter,
  StyledTableHeader,
  StyledTableWrapper,
  StyledCard,
} from './location-services-styles';
import { ServicesTableRow } from './services-table-row';

import { initialTableColumns, normalizeServiceItemsDataForTable, isListHasDuplicates } from './helpers';
import type { TTableRowData } from '../../types/TTable';
import type { TRootStateRedux } from '../../types/TRootStateRedux';
import type { TEditingService, TServiceItem } from '../../types/TLocationService';

import { useAuth, useHasAccess } from '../../hooks';
import isNilOrEmpty from '../../utils/is-null-or-empty';

import {
  getServiceItems as APIGetServiceItems,
  updateServiceItems as APIUpdateServiceItems,
  getLocationCategories as APIGetLocationCategories,
} from '../../services/google-business-profile';
import APIGetValidGoogleAccessTokenFromLocation from '../../services/getValidGoogleAccessTokenFromLocation';
import useMemoizedCallback from '../../hooks/use-memoized-callback';
import DoubleDuplicationServiceDialog from '../../components/double-duplication-service-dialog';

function LocationServices() {
  const { hasAccess } = useHasAccess();
  const { userAccessToken, userId } = useAuth();
  const [openDialog, setOpenDialog] = React.useState(false);
  const [connectedLocation, setConnectedLocation] = React.useState(false);
  const [isDeleteMultipleDialogOpen, setIsDeleteMultipleDialogOpen] = React.useState(false);
  const [isDoubleDuplicationServiceDialogOpen, setIsDoubleDuplicationServiceDialogOpen] = React.useState(false);
  const [googleAccessToken, setGoogleAccessToken] = React.useState<string | null>(null);
  const [googleLocationId, setGoogleLocationId] = React.useState<string | null>(null);

  const { activeLocation } = useSelector(
    (state: TRootStateRedux) => state.LocationReducer,
  );
  const { enqueueSnackbar } = useSnackbar();

  const [loading, setLoading] = React.useState(false);

  const [serviceItemsList, setServiceItemsList] = React.useState<TServiceItem[]>([]);
  const [editingService, setEditingService] = React.useState<TEditingService | null>(null);
  const [tableData, setTableData] = React.useState<
    TTableRowData[]
  >([]);

  const [filteredTableData, setFilteredTableData] = React.useState<
    TTableRowData[]
  >([]);

  const [categoryDisplayName, setCategoryDisplayName] = React.useState<string>('');
  const [listHasDuplicates, setListHasDuplicates] = React.useState(false);

  const [selectedRows, setSelectedRows] = React.useState<number[]>([]);

  const [canManageServices, setCanManageServices] = React.useState(true);

  const setIsRowSelected = React.useCallback(
    (rowIndex: number, isSelected: boolean) => {
      if (isSelected) {
        setSelectedRows(prevState => [rowIndex, ...prevState]);
        return;
      }
      setSelectedRows(prevState => prevState.filter(index => index !== rowIndex));
    },
    [],
  );

  const [nameFilter, setNameFilter] = React.useState('');

  const hasDoubleDuplicatedRows = React.useCallback((indexesToRemove: number[]) => {
    const countRepetition: { [key: string]: number } = {};
    for (let index = 0; index < tableData.length; index += 1) {
      const data = tableData[index];
      const serviceName: string = data[1].value.toLowerCase();
      const isDuplicated = countRepetition[serviceName];

      if (indexesToRemove.includes(index)) continue;

      if (isDuplicated) {
        countRepetition[serviceName] += 1;
      } else {
        countRepetition[serviceName] = 1;
      }
    }

    return Object
      .keys(countRepetition)
      .some(key => (countRepetition[key] !== 1));
  }, [tableData]);

  const dataTableOptions = React.useMemo(
    (): MUIDataTableOptions => ({
      search: false,
      selectableRowsHideCheckboxes: !listHasDuplicates || (canManageServices === false),
      selectableRowsHeader: false,
      pagination: false,
      rowsSelected: selectedRows,
      onRowsDelete: () => {
        setIsDeleteMultipleDialogOpen(true);
        return false;
      },
    }),
    [selectedRows, listHasDuplicates, canManageServices],
  );

  const getGoogleAccessToken = React.useCallback(async () => {
    if (!hasAccess) return;

    const googleAccessTokenResponse = await APIGetValidGoogleAccessTokenFromLocation({
      accessToken: userAccessToken,
      userId,
      locationId: activeLocation.id,
    });

    if (isNil(googleAccessTokenResponse)) return;

    const {
      googleAccessToken: accessTokenGoogle,
      connectionInfo,
    } = googleAccessTokenResponse;

    setGoogleLocationId(connectionInfo.google_location_id);
    setGoogleAccessToken(accessTokenGoogle);
  }, [hasAccess, activeLocation]);

  React.useEffect(() => { getGoogleAccessToken(); }, [getGoogleAccessToken]);

  const getLocationCategory = React.useCallback(async () => {
    if (!hasAccess) return;

    if (isNil(googleAccessToken)) {
      setCategoryDisplayName('');
      return;
    }

    if (isNil(googleLocationId)) {
      setCategoryDisplayName('');
      return;
    }

    const locationCategories = await APIGetLocationCategories({
      googleAccessToken,
      googleLocationId,
    });

    if (isNil(locationCategories)) return;

    const hasServiceTypes = has('serviceTypes');

    if (!hasServiceTypes(locationCategories.primaryCategory)) {
      setCanManageServices(false);
    } else {
      setCanManageServices(true);
    }

    setCategoryDisplayName(locationCategories?.primaryCategory?.displayName || '');
  }, [
    activeLocation,
    userAccessToken,
    userId,
    googleAccessToken,
    hasAccess,
  ]);

  const getServiceItems = React.useCallback(async () => {
    if (!hasAccess) return;

    if (isNil(googleAccessToken)) {
      setServiceItemsList([]);
      return;
    }

    if (isNil(googleLocationId)) {
      setServiceItemsList([]);
      return;
    }

    const serviceItemsResponse = await APIGetServiceItems({
      googleAccessToken,
      googleLocationId,
    });

    if (isNil(serviceItemsResponse)) return;

    setServiceItemsList(serviceItemsResponse);
  }, [
    activeLocation,
    userAccessToken,
    userId,
    googleAccessToken,
    hasAccess,
  ]);

  const updateServiceItems = React.useCallback(async (serviceItems: TServiceItem[]) => {
    setLoading(true);
    try {
      if (isNil(googleLocationId) || isNil(googleAccessToken)) return { status: 'ERROR', data: [] };
      const { status, data } = await APIUpdateServiceItems({
        googleAccessToken,
        userAccessToken,
        locationId: googleLocationId,
        serviceItems,
      });
      if (status === 'SUCCESS' && data) setServiceItemsList(data);
      return { status, data };
    } finally {
      setLoading(false);
    }
  }, [activeLocation, userAccessToken, userId, googleAccessToken]);

  const selectServiceToEdit = useMemoizedCallback((serviceIndex: number | null) => {
    if (isNil(serviceIndex)) {
      setOpenDialog(false);
      setEditingService(null);
      return;
    }
    if (serviceIndex > (serviceItemsList.length - 1)) return;
    setEditingService({ index: serviceIndex, service: serviceItemsList[serviceIndex] });
    setOpenDialog(true);
  }, [serviceItemsList]);

  const deleteServiceItems = useMemoizedCallback(async (indexesToRemove: number[]) => {
    if (hasDoubleDuplicatedRows(indexesToRemove)) {
      setIsDoubleDuplicationServiceDialogOpen(true);
      return { status: 'ERROR', data: [] };
    }

    const updatedServiceItems = serviceItemsList.filter((service, index) => !indexesToRemove.includes(index));

    const isMultipleDelete = indexesToRemove.length > 1;

    const updateResponse = await updateServiceItems(updatedServiceItems);
    if (updateResponse.status === 'ERROR') {
      enqueueSnackbar(`Erro ao deletar serviço${isMultipleDelete ? 's' : ''}`, { variant: 'error' });
      return updateResponse;
    }
    enqueueSnackbar(`Serviço${isMultipleDelete ? 's' : ''} removido${isMultipleDelete ? 's' : ''} com sucesso`, { variant: 'success' });
    return updateResponse;
  }, [serviceItemsList, tableData]);

  const onDeleteSelectedRows = React.useCallback(async () => {
    setLoading(true);
    try {
      setIsDeleteMultipleDialogOpen(false);
      await deleteServiceItems(selectedRows);
    } finally {
      setLoading(false);
    }
  }, [selectedRows]);

  const handleChangeLocation = React.useCallback(async () => {
    if (isNilOrEmpty(googleLocationId)) {
      setConnectedLocation(false);
    } else {
      setConnectedLocation(true);
    }
  }, [activeLocation, googleLocationId]);

  const duplicatedRows = React.useMemo(() => tableData.filter((data) => data[4].value), [tableData]);

  React.useEffect(() => {
    getLocationCategory();
  }, [getLocationCategory]);

  React.useEffect(() => {
    getServiceItems();
  }, [getServiceItems]);

  React.useEffect(() => {
    const normalizedServiceItemsData = normalizeServiceItemsDataForTable(serviceItemsList);
    setListHasDuplicates(isListHasDuplicates(serviceItemsList));
    setTableData(normalizedServiceItemsData);
    setFilteredTableData(normalizedServiceItemsData);
    setSelectedRows([]);
  }, [serviceItemsList]);

  React.useEffect(() => {
    setNameFilter('');
  }, [activeLocation]);

  React.useEffect(() => {
    if (nameFilter === '') return setFilteredTableData(tableData);

    const data: any[] = [];
    tableData
      .forEach(item => item
        .filter(result => !isNil(result.value))
        .map(result => {
          if (
            result.columnRef === 'service'
            && result.value.toLowerCase().includes(nameFilter.toLowerCase())
          ) {
            data.push(item);
            return item;
          }

          return [];
        })
        .filter(result => !isEmpty(result)));

    setFilteredTableData(data);
  }, [nameFilter]);

  React.useEffect(() => {
    handleChangeLocation();
  }, [handleChangeLocation]);

  return (
    <StyledLocationServices>
      <Helmet>
        <meta property="og:title" content="Gerência de Serviços" />
        <meta
          property="og:description"
          content="Gerência de Serviços"
        />

        <title>Local - Serviços</title>
      </Helmet>

      <LocationHeader
        hideDashboard={!activeLocation?.data_studio}
        linkedLocation={!isNil(googleAccessToken)}
      />

      {!hasAccess && connectedLocation && <NoAccessCard />}

      {hasAccess && !connectedLocation && (
        <StyledCard>
          <p>Local não conectado, entre em contato com o operacional para acessar esta funcionalidade</p>
        </StyledCard>
      )}

      {hasAccess && connectedLocation && (
        <StyledContent>
          <StyledCreateButtonWrapper>
            <RoundedButton
              title="Adicionar Serviço"
              icon="AddCircle"
              onClick={() => { setOpenDialog(true); }}
              className="add-service"
              disabled={listHasDuplicates || loading || !canManageServices}
            />
          </StyledCreateButtonWrapper>

          <StyledFilter>
            <h2>Filtros</h2>
            <StyledContentFilter>
              <InputWithIcon
                value={nameFilter}
                setValue={setNameFilter}
                placeholder="Nome"
              >
                <FaSearch />
              </InputWithIcon>
            </StyledContentFilter>
          </StyledFilter>

          {canManageServices && listHasDuplicates && (
            <NotificationBox
              text="Notamos que esse local possui serviços duplicados ou com o mesmo nome. Selecione um dos serviços que estão duplicados e exclua-os, em conjunto, para que a situação seja regularizada. Nenhuma outra ação poderá ser feita até que esse problema seja corrigido."
              type="WARNING"
            />
          )}

          {!canManageServices && (
            <NotificationBox
              text="A categoria deste local não permite o gerenciamento de serviços"
              type="WARNING"
            />
          )}

          <StyledTableWrapper>
            <Table
              loading={loading}
              data={filteredTableData}
              title={
                (
                  <StyledTableHeader>
                    <h1>Serviços</h1>
                    {!isEmpty(categoryDisplayName) && (
                      <h2>
                        Categoria principal:
                        {' '}
                        {categoryDisplayName}
                      </h2>
                    )}
                  </StyledTableHeader>
                ) as React.ReactNode & string
              }
              columns={initialTableColumns}
              options={dataTableOptions}
              row={(rowData, rowIndex) => (
                <ServicesTableRow
                  data={rowData}
                  rowIndex={rowIndex}
                  key={rowData[0].value}
                  deleteServiceItems={deleteServiceItems}
                  isTableLoading={loading}
                  selectServiceToEdit={selectServiceToEdit}
                  setIsRowSelected={setIsRowSelected}
                  hiddenCheckbox={!listHasDuplicates || (canManageServices === false)}
                  canManageServices={canManageServices}
                />
              )}
            />
          </StyledTableWrapper>

          {openDialog && (
            <CreateServiceDialog
              openDialog={openDialog}
              setOpenDialog={setOpenDialog}
              serviceItems={serviceItemsList}
              getServiceItems={getServiceItems}
              editingService={editingService}
              setEditingService={setEditingService}
              googleAccessToken={googleAccessToken}
              googleLocationId={googleLocationId}
            />
          )}

          <DestructiveActionDialog
            open={isDeleteMultipleDialogOpen}
            onClose={() => {
              setIsDeleteMultipleDialogOpen(false);
            }}
            warningMessage={[
              'Você está prestes a excluir os serviços selecionados.',
              'Deseja continuar?',
            ]}
            confirmButtonLabel="SIM, DESEJO CONTINUAR"
            cancelButtonLabel="CANCELAR"
            onConfirm={onDeleteSelectedRows}
          />

          {isDoubleDuplicationServiceDialogOpen && (
            <DoubleDuplicationServiceDialog
              open={isDoubleDuplicationServiceDialogOpen}
              onClose={() => setIsDoubleDuplicationServiceDialogOpen(false)}
              services={duplicatedRows}
              total={tableData.length}
            />
          )}
        </StyledContent>
      )}
    </StyledLocationServices>
  );
}

export default LocationServices;
