import styled, { css } from 'styled-components';
import { lighten } from 'polished';
import { Paper } from '@material-ui/core';
import { devices } from '../../../assets/styles/devices';

export const StyledContent = styled(Paper)`
  margin-left: 0;
  height: max-content;
  margin-top: 2rem;
  padding: 2rem;

  @media ${devices.desktop} {
    margin-left: 2rem;
    margin-top: 0;
  };

  h3 {
    display: inline-block;
    margin-bottom: 2rem;
    width: 100%;
    font-size: 1.5rem;
    font-weight: 700;
  };

  label, input {
    font-size: 1.2rem;
  }

  .exists-button {
    cursor: default;
    margin-left: .5rem;
  }


`;

export const StyledListItem = styled.li<{ isDisabled: boolean }>`
  font-size: 1.3rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid ${(props) => lighten(0.9, props.theme.colors.black)};
  background: ${props => props.theme.colors.white};
  padding-bottom: 1rem;
  margin-bottom: 1rem;

  &:last-of-type {
    border-bottom: none;
  }

  ${props => props.isDisabled && css`
    color: ${props.theme.colors.grey}
  `}
`;

export const StyledLoadingDiv = styled.div`
  display: flex;
  margin: 2rem;
  justify-content: center;
`;

export const StyledSelectDropdownWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-right: 1rem;
  margin-bottom: 1rem;
  justify-content: space-between;

  @media ${devices.tablet} {
    margin-bottom: 0;
  }
`;

export const StyledFilterWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 2rem;

  @media ${devices.tablet} {
    flex-direction: row;
  }

  @media ${devices.desktop} {
    flex-direction: column;
    grid-row-gap: 1rem;
  }

  h5 {
    font-size: 1.2rem;
    font-weight: normal;
    color: ${props => props.theme.colors.black};
  }
`;

export const StyledLocationListWrapper = styled.div`
  position: relative;
`;

export const StyledEmptyList = styled.div`
  display: flex;
  justify-content: center;
  padding: 2rem
`;
