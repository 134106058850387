export const moduleMenuItems = [
  {
    title: 'Conectar',
    icon: 'Power',
    link: '/platforms-link-with-location',
  },
  {
    title: 'Dashboard',
    icon: 'Dashboard',
    link: '/location/data-studio',
  },
  {
    title: 'Informações',
    icon: 'Store',
    link: '/location/profile',
  },
  {
    title: 'Editar',
    icon: 'Edit',
    link: '/location/profile',
  },
  {
    title: 'Avaliações',
    icon: 'Star',
    link: '/reviews/location',
  },
  {
    title: 'Postagens',
    icon: 'PostAdd',
    link: '/location/posts',
  },
  {
    title: 'Galerias',
    icon: 'PhotoCamera',
    link: '/location/gallery',
  },
  {
    title: 'Serviços',
    icon: 'ViewList',
    link: '/location/services',
  },
];

export const disableClickTitles = {
  Conectar: false,
  Dashboard: false,
  Informações: false,
  Avaliações: true,
  Postagens: true,
  Galerias: true,
  Serviços: true,
};

export const redirectWarningLabel = {
  Avaliações: 'Avaliações Bloqueadas.',
  Postagens: 'Postagens Bloqueadas.',
  Galerias: 'Galeria Bloqueada.',
  Serviços: 'Serviços Bloqueados.',
};
