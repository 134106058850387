import { Card } from '@material-ui/core';
import styled from 'styled-components';
import { devices } from '../../../assets/styles/devices';

export const StyledContainer = styled.div``;

export const StyledLabel = styled.text`
  font-size: 1.3rem;
  font-weight: bold;
  line-height: 1.4rem;
  padding: 12px 0;
`;

export const StyledContent = styled(Card)`
    width: 100%;
    height: 10rtem;
    background-color: ${(props) => props.theme.colors.greyLight};
    border-radius: 6px;
    margin-top: 1rem;
    padding: 1rem;
`;

export const StyledContentRow = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;
    margin: 1rem 0;

    @media ${devices.tabletLandscape} {
        flex-direction: row;
    }
`;

export const StyledTitleFilter = styled.p`
    font-size: 1.2rem;
    font-weight: 400;
    line-height: 2.4rem;
    margin: 0;
    white-space: nowrap;
`;

export const StyledSelectedFilterListOne = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    
    @media ${devices.tabletLandscape} {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
    }
`;

export const StyledSelectedFilterListTwo = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 1rem;
`;
