import React, { useState } from 'react';

import SyncInfoListPopover from './sync-info-list-popover';

import {
  StyledRequestsPopover,
} from './sync-info-popover-styles';
import SyncInfoSectionPopover from './sync-info-section-popover';
import isNilOrEmpty from '../../../utils/is-null-or-empty';

import {
  formatDataToList,
  normalizeWorkingHoursData,
  splitDataIntoSections,
} from '../helpers';
import WorkingHoursInfo from './working-hours-info';
import SpecialDatesInfo from './special-dates-info';

const SyncInfoPopover = ({ data }: any) => {
  const [isBasicInfoOpen, setIsBasicInfoOpen] = useState(true);
  const [isLinksOpen, setIsLinksOpen] = useState(true);
  const [isScheduleOpen, setIsScheduleOpen] = useState(true);
  const [isSpecialDatesOpen, setIsSpecialDatesOpen] = useState(true);

  const dataSplitIntoSections = splitDataIntoSections(data);
  return (
    <StyledRequestsPopover>
      {!isNilOrEmpty(dataSplitIntoSections.basic_infos) && (
        <>
          <SyncInfoSectionPopover
            open={isBasicInfoOpen}
            setOpen={() => setIsBasicInfoOpen(prev => !prev)}
            title="Informações básicas"
          />
          {isBasicInfoOpen && (
            <SyncInfoListPopover
              data={formatDataToList(dataSplitIntoSections.basic_infos)}
            />
          )}
        </>
      )}

      {!isNilOrEmpty(dataSplitIntoSections.links) && (
        <>
          <SyncInfoSectionPopover
            open={isLinksOpen}
            setOpen={() => setIsLinksOpen(prev => !prev)}
            title="Links"
          />
          {isLinksOpen && (
            <SyncInfoListPopover
              data={formatDataToList(dataSplitIntoSections.links)}
            />
          )}
        </>
      )}

      {!isNilOrEmpty(dataSplitIntoSections.working_hours) && (
        <>
          <SyncInfoSectionPopover
            open={isScheduleOpen}
            setOpen={() => setIsScheduleOpen(prev => !prev)}
            title="Horários de funcionamento"
          />
          {isScheduleOpen && (
            <WorkingHoursInfo workingHoursData={normalizeWorkingHoursData(dataSplitIntoSections.working_hours[0].new_data.value)} status={dataSplitIntoSections.working_hours[0].status} />
          )}
        </>
      )}

      {!isNilOrEmpty(dataSplitIntoSections.special_dates) && (
        <>
          <SyncInfoSectionPopover
            open={isSpecialDatesOpen}
            setOpen={() => setIsSpecialDatesOpen(prev => !prev)}
            title="Datas especiais"
          />
          {isSpecialDatesOpen && (
            <SpecialDatesInfo
              data={dataSplitIntoSections.special_dates[0].new_data.value}
              status={dataSplitIntoSections.special_dates[0].status}
            />
          )}
        </>
      )}
    </StyledRequestsPopover>
  );
};

export default SyncInfoPopover;
