import styled from 'styled-components';
import { lighten, shade } from 'polished';
import { Popover } from '@material-ui/core';

export const StyledReviewReply = styled.div`
    display: flex;
    flex-direction: column;
    padding: 2rem 2rem 3rem;
    background: ${props => lighten(0.93, props.theme.colors.black)};
    margin-top: 2rem;
`;

export const StyledButtons = styled.div`
    display: flex;
    align-items: center;
    margin-left: auto;
    margin-top: 2rem;

    .button {
        margin-right: 1rem;

        &:last-child {
            margin-right: 0;
        }
    }
`;

export const StyledPopoverDefaultResponses = styled(Popover)`
    .MuiPopover-paper {
        box-shadow: 
            0px 5px 5px -3px rgb(0 0 0 / 20%), 
            0px 8px 10px 1px rgb(0 0 0 / 14%), 
            0px 3px 14px 2px rgb(0 0 0 / 12%);
    }

    h3 {
        font-size: 1.6rem;
        margin-bottom: 2rem;
        padding: 2rem;
        color: ${props => props.theme.colors.primary};
    }

    ul {
        max-width: 60rem;
        display: flex;
        flex-direction: column;
    }
`;

export const StyledResponseOptions = styled.div`
    font-size: 1.6rem;
    margin-right: 2rem;
    color: ${props => props.theme.colors.primary};
    text-decoration: underline;
    margin-top: auto;
    cursor: pointer;
`;
