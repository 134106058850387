import React from 'react';

import Button from '../button';

import FirstAccessIllustration from '../../assets/images/first-access-illustration.png';

import {
  StyledLetsStart,
  StyledContent,
} from './lets-start-styles';

const LetsStart = ({ begin }) => (
  <StyledLetsStart>
    <StyledContent>
      <img
        className="first-access-illustration"
        src={FirstAccessIllustration}
        alt="Página não encontrada"
      />

      <h2>Preparar para decolar!</h2>

      <p>
        Seja bem vindo a HubLocal. Vamos iniciar os preparativos
        para decolar sua empresa.
      </p>

      <Button onClick={begin}>Começar</Button>
    </StyledContent>
  </StyledLetsStart>
);

export default LetsStart;
