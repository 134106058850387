import styled from 'styled-components';
import button from '../../button';

export const StyledLastSyncHistory = styled.div`
  padding-top: 2rem;
`;

export const StyledTitle = styled.h3`
  font-weight: bold;
  font-size: 1.25rem;
`;

export const StyledButton = styled(button)`
  width: 100%;
  margin-top: 2rem;

  &.button {
    margin-bottom: 0;
  }
`;
