import axios from 'axios';

export default async function sendAutoVerification(
  accessToken,
  userId,
  locationId,
) {
  const requestOptions = {
    headers: {
      'access-token': accessToken,
    },
  };

  try {
    const { data } = await axios.post(
      `${process.env.REACT_APP_GOOGLE_API_URL}/initiate/verification/auto-process/${userId}/${locationId}`,
      {

      },
      requestOptions,
    );

    return data;
  } catch (err) {
    throw err.response.data;
  }
}
