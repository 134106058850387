import React from 'react';

import { TUploadedImagesListing } from '../../../../types/TLocationPost';

import {
  StyledRemoveUploadedImage,
  StyledUploadedImageListingItem,
  StyledUploadedImagesListing,
} from './uploaded-images-listing-styles';

const UploadedImagesListing = ({
  mediaToUpdate,
  handleDeleteImage,
  editable,
}: TUploadedImagesListing) => (
  <StyledUploadedImagesListing>
    {mediaToUpdate && mediaToUpdate.map((media:any, index: number) => (
      <StyledUploadedImageListingItem key={Math.floor(Math.random() + index)}>
        {editable && <StyledRemoveUploadedImage onClick={() => handleDeleteImage(index)} />}
        {media.isVideo ? (
          <video controls>
            <source src={media.sourceUrl} type="video/mp4" />
            <source src={media.sourceUrl} type="video/ogg" />
            Your browser does not support HTML video
          </video>
        ) : (
          <img src={media.sourceUrl} alt="" />
        )}
      </StyledUploadedImageListingItem>
    ))}
  </StyledUploadedImagesListing>
);

export default UploadedImagesListing;
