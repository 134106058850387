import React from 'react';

import {
  StyledSectionBody,
} from './section-body-styles';

const SectionBody = ({ children }) => (
  <StyledSectionBody>{children}</StyledSectionBody>
);

export default SectionBody;
