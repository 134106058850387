import React from 'react';
import { isNil } from 'ramda';
import { StyledListItem, StyledListItemWrapper } from './infos-list-popover-styles';

type TItemList = {
  name: string,
  value: string,
}

type TInfosListPopoverProps = {
  data: TItemList[],
}

const renderValue = (value: string | string[]) => {
  let outputText = '';

  if (Array.isArray(value)) {
    value.map((time: any) => {
      outputText += `${time} `;
    });

    return outputText;
  }

  return value;
};

export const InfosListPopover = ({
  data,
}: TInfosListPopoverProps) => (
  <>
    {
      data.map(item => (
        (
          !isNil(item.value)
          && (
            <StyledListItemWrapper>
              <StyledListItem>
                <p className="title">{item.name}</p>
                <p>
                  {isNil(item.value)
                    ? 'Não alterado'
                    : renderValue(
                      item.value === 'MUST_BE_EMPTY'
                        ? 'Informação removida'
                        : item.value,
                    )}
                </p>
              </StyledListItem>

              <p className="status">Alterado!</p>
            </StyledListItemWrapper>
          )
        )
      ))
    }
  </>
);
