export default {
  name: {
    name: 'name',
    title: 'Nome',
    valueHublocal: null,
    valuePlatform: null,
    notShow: ['facebook'],
  },
  categories: {
    name: 'categories',
    title: 'Categorias',
    valueHublocal: null,
    valuePlatform: null,
    notShow: ['google', 'facebook'],
  },
  googleCategories: {
    name: 'googleCategories',
    title: 'Categorias do Google',
    valueHublocal: null,
    valuePlatform: null,
    notShow: ['facebook'],
  },
  facebookCategories: {
    name: 'facebook-categories',
    title: 'Categorias do Facebook',
    valueHublocal: null,
    valuePlatform: null,
    notShow: ['google'],
  },
  country: {
    name: 'country',
    title: 'País',
    valueHublocal: null,
    valuePlatform: null,
    notShow: [],
  },
  address: {
    name: 'address',
    title: 'Logradouro',
    valueHublocal: null,
    valuePlatform: null,
    notShow: [],
  },
  complement: {
    name: 'complement',
    title: 'Complemento',
    valueHublocal: null,
    valuePlatform: null,
    notShow: ['facebook'],
  },
  zipcode: {
    name: 'zipcode',
    title: 'CEP',
    valueHublocal: null,
    valuePlatform: null,
    notShow: [],
  },
  phone: {
    name: 'phone',
    title: 'Telefone',
    valueHublocal: null,
    valuePlatform: null,
    notShow: [],
  },
  secondaryPhone: {
    name: 'secondary-phone',
    title: 'Telefone Secundário',
    valueHublocal: null,
    valuePlatform: null,
    notShow: ['facebook', 'facebook'],
  },
  tertiaryPhone: {
    name: 'tertiary-phone',
    title: 'Telefone Terciario',
    valueHublocal: null,
    valuePlatform: null,
    notShow: ['facebook', 'google'],
  },
  hours: {
    name: 'hours',
    title: 'Horários de funcionamento',
    valueHublocal: null,
    valuePlatform: null,
    notShow: [],
  },
  email: {
    name: 'email',
    title: 'Email',
    valueHublocal: null,
    valuePlatform: null,
    notShow: ['google', 'page-location'],
  },
  neighborhood: {
    name: 'neighborhood',
    title: 'Bairro',
    valueHublocal: null,
    valuePlatform: null,
    notShow: ['facebook', 'facebook'],
  },
  city: {
    name: 'city',
    title: 'Cidade',
    valueHublocal: null,
    valuePlatform: null,
    notShow: [],
  },
  state: {
    name: 'state',
    title: 'Código do Estado',
    valueHublocal: null,
    valuePlatform: null,
    notShow: [],
  },
  doesNotAttend: {
    name: 'does-not-attend',
    title: 'Não atende nesse endereço',
    valueHublocal: null,
    valuePlatform: null,
    notShow: ['facebook'],
  },
  hangTags: {
    name: 'hang-tags',
    title: 'Etiquetas',
    valueHublocal: null,
    valuePlatform: null,
    notShow: ['facebook'],
  },
  shortDescription: {
    name: 'shor-description',
    title: 'Descricação curta',
    valueHublocal: null,
    valuePlatform: null,
    notShow: ['google', 'page-location'],
  },
  longDescription: {
    name: 'long-description',
    title: 'Descricação longa',
    valueHublocal: null,
    valuePlatform: null,
    notShow: ['facebook'],
  },
  groups: {
    name: 'groups',
    title: 'Grupos',
    valueHublocal: null,
    valuePlatform: null,
    notShow: ['facebook', 'google'],
  },
  website: {
    name: 'website',
    title: 'Website',
    valueHublocal: null,
    valuePlatform: null,
    notShow: ['page-location'],
  },
  facebook: {
    name: 'facebook',
    title: 'Facebook',
    valueHublocal: null,
    valuePlatform: null,
    notShow: ['facebook', 'google'],
  },
  yelp: {
    name: 'yelp',
    title: 'Yelp',
    valueHublocal: null,
    valuePlatform: null,
    notShow: ['facebook', 'google'],
  },
  foursquare: {
    name: 'foursquare',
    title: 'Foursquare',
    valueHublocal: null,
    valuePlatform: null,
    notShow: ['facebook', 'google'],
  },
  instagram: {
    name: 'instagram',
    title: 'Instagram',
    valueHublocal: null,
    valuePlatform: null,
    notShow: ['facebook', 'google'],
  },
  linkedin: {
    name: 'linkedin',
    title: 'Linkedin',
    valueHublocal: null,
    valuePlatform: null,
    notShow: ['facebook', 'google'],
  },
  twitter: {
    name: 'twitter',
    title: 'Twitter',
    valueHublocal: null,
    valuePlatform: null,
    notShow: ['facebook', 'google'],
  },
  youtube: {
    name: 'youtube',
    title: 'Youtube',
    valueHublocal: null,
    valuePlatform: null,
    notShow: ['facebook', 'google'],
  },
};
