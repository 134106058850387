import styled from 'styled-components';
import { Dialog } from '@material-ui/core';
import { lighten } from 'polished';

export const StyleListingDialog = styled(Dialog)`
    .MuiDialog-paper {
        width: 100%;
    }
`;

export const StyledSearch = styled.div`
    display: flex;
    align-items: center;
    position: relative;
    padding: 1rem 2rem;
    border-bottom: 1px solid ${props => lighten(0.4, props.theme.colors.grey)};

    .search-icon {
        display: flex;
        align-items: center;
        position: absolute;
        right: 2.2rem;
        height: 3.6rem;
        padding: 0 2rem;
        background: ${props => props.theme.colors.primary};
        border-top-right-radius: 0.5rem;
        border-bottom-right-radius: 0.5rem;
        
        .MuiSvgIcon-root {
            width: 2.5rem;
            height: 2.5rem;
            fill: ${props => props.theme.colors.white};
        }
    }

    input {
        height: 4rem;
        width: 100%;
        padding: 0 7.5rem 0 2rem;
        border-radius: 0.5rem;
        border: 1px solid ${props => lighten(0.3, props.theme.colors.grey)};
    }
`;

export const StyledTotalListing = styled.span`
    font-size: 1.4rem;
    display: flex;
    padding: 1rem 2rem;
    color: ${props => lighten(0.2, props.theme.colors.black)};

    small {
        font-size: 1.4rem;
        font-style: normal;
        color: ${props => props.theme.colors.primary};
        font-weight: 600;
        margin-left: 0.5rem;
    }
`;

export const StyledPagesListing = styled.ul`
    display: flex;
    flex-direction: column;
    padding: 0 2rem;
    max-height: 40rem;
    overflow-y: scroll;

    img {
        width: 2rem;
        height: 2rem;
        margin-right: 1.5rem;
        display: block;
    }
`;
