import axios from 'axios';

import type {
  TGetAllLinkedLocations,
} from '../../types/TLocation';

export default async function getAllLinkedLocations({
  accessToken,
  page = 0,
  pageSize = 10,
  companyName = null,
  locationName = null,
  orderByName = 'ASC',
  setTokenLikeExpired = null,
  status = null,
}: TGetAllLinkedLocations) {
  const requestOptions = {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
    params: {
      page,
      pageSize,
      companyName,
      locationName,
      orderByName,
      status,
    },
  };

  try {
    const { data } = await axios.get(
      `${process.env.REACT_APP_LOCATIONS_API_URL}/all-linked-locations`,
      requestOptions,
    );
    return data;
  } catch (err: any) {
    if (err.response?.status === 401 && setTokenLikeExpired) setTokenLikeExpired();

    return null;
  }
}
