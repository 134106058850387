import axios from 'axios';

export default async function patchPost({
  accessToken,
  postId,
  inputData,
  mediaType = 'url', // Ou 'bytes'
  postType = 'call-to-action', // ou 'offer' ou 'event',
  updateMask,
  setIsFetching,
  feedbackMessage,
}) {
  const requestOptions = {
    headers: {
      'access-token': accessToken,
    },
  };

  if (setIsFetching) setIsFetching(true);

  try {
    const { data } = await axios.patch(
      `${process.env.REACT_APP_GOOGLE_API_URL}/posts/${postType}/${mediaType}/${postId}?updateMask=${updateMask}`,
      inputData,
      requestOptions,
    );

    return data;
  } catch (err) {
    if (err.response.status === 403) {
      if (feedbackMessage) feedbackMessage('Esta postagem pode ter sido capturada pelo filtro de spam do Google, tente refazê-la!', { variant: 'error' });
      return null;
    }

    if (err.response.status === 503) {
      if (feedbackMessage) feedbackMessage('Serviço indisponível no momento, tente novamente em alguns instantes', { variant: 'warning' });
      return null;
    }

    if (feedbackMessage) feedbackMessage('Erro ao atualizar postagem, tente novamente!', { variant: 'error' });
    return null;
  } finally {
    if (setIsFetching)setIsFetching(false);
  }
}
