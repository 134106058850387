import axios, { AxiosResponse } from 'axios';
import type {
  TBatchGallery,
  TFindBatchGalleries,
} from '../../../types/TBatchGallery';

import type { TArrayResponseWithCount } from '../../../types/TArrayResponseWithCount';

export const findBatchGalleries = async ({
  isFetching,
  userAccessToken,
  page = 0,
  pageSize = 10,
  status,
  mediaType,
  groupType,
  groupTypeId,
  startDate,
  endDate,
  category,
}: TFindBatchGalleries): Promise<{
  status: 'SUCCESS' | 'ERROR';
  data: TArrayResponseWithCount<TBatchGallery> | object;
}> => {
  try {
    if (isFetching) isFetching(true);
    let pathUrl = `gallery-batch?groupTypeId=${groupTypeId}&groupType=${groupType}&pageSize=${pageSize}&page=${page}`;
    if (status) pathUrl += `&status=${status}`;
    if (mediaType) pathUrl += `&mediaType=${mediaType}`;
    if (startDate) pathUrl += `&startDate=${startDate}`;
    if (endDate) pathUrl += `&endDate=${endDate}`;
    if (category) pathUrl += `&category=${category}`;

    const { data }: AxiosResponse<TArrayResponseWithCount<TBatchGallery>> = await axios.get(
      `${process.env.REACT_APP_SAAS_BATCH_GOOGLE_MODULES}/${pathUrl}`,
      {
        headers: {
          Authorization: `Bearer ${userAccessToken}`,
        },
      },
    );

    return {
      status: 'SUCCESS',
      data,
    };
  } catch (err: any) {
    return {
      status: 'ERROR',
      data: err.response,
    };
  } finally {
    if (isFetching) isFetching(false);
  }
};
