import axios from 'axios';

import { TUpdateCompanyGroup } from '../../types/TCompanyGroup';

export default async function updateCompanyGroup({
  accessToken,
  companyGroupId,
  name,
  description,
  website,
  owner_user_id,
  main_responsible_id,
  created_by,
  setTokenLikeExpired,
  feedbackMessage,
  setIsCreatingCompany,
}: TUpdateCompanyGroup) {
  try {
    if (setIsCreatingCompany) setIsCreatingCompany(true);
    if (feedbackMessage) feedbackMessage('Atualizando grupo...', { variant: 'warning' });

    const { data } = await axios.put(
      `${process.env.REACT_APP_COMPANY_GROUPS_API_URL}/${companyGroupId}`,
      {
        name,
        description,
        website,
        owner_user_id,
        main_responsible_id,
        created_by,
      },
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      },
    );

    if (feedbackMessage) feedbackMessage('Grupo de empresa atualizado com sucesso', { variant: 'success' });

    return data;
  } catch (err: any) {
    if (err.response?.status === 401 && setTokenLikeExpired) setTokenLikeExpired();

    return err;
  } finally {
    if (setIsCreatingCompany) setIsCreatingCompany(false);
  }
}
