import styled, { css } from 'styled-components';

export const StyledNotificationBox = styled.div<{
  noBottomMargin: boolean,
  noBorderRadius: boolean,
}>`
  width: 100%;
  border: 0;
  border-radius: 1rem;
  background: ${(props) => props.color};

  p {
    padding: 1rem;
    margin-bottom: 0;
    text-align: center;
    color: white;
    font-size: 9pt;
  }

  margin-bottom: 16px;

  ${props => props.noBottomMargin && css`
    margin-bottom: 0;
  `}

  ${props => props.noBorderRadius && css`
    border-radius: 0;
  `}
`;
