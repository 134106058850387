import React from 'react';
import { useSelector } from 'react-redux';
import { useHistory, Redirect } from 'react-router-dom';
import { Button, IconButton, SvgIcon } from '@material-ui/core';
import './FunctionList.css';
import * as Icons from '@material-ui/icons';

import {
  DataTable,
  Dialog,
  Can,
} from '../../components';

import getUsers from '../../services/users/getUsers';
import getAllUserFunctions from '../../utils/getAllUserFunctions';
import getFunctions from '../../services/functions/getFunctions';
import deleteFunction from '../../services/functions/deleteFunction';
import getUserName from '../../services/users/getUserName';

import formatDate from '../../utils/formatDate';

const FunctionList = () => {
  const history = useHistory();
  const [loading, setLoading] = React.useState(false);
  const [functions, setFunctions] = React.useState([]);
  const [dialogOpen, setDialogOpen] = React.useState(false);
  const [selectedFunction, setSelectedFunction] = React.useState({});
  const [functionGotDeleted, setFunctionGotDeleted] = React.useState(true);
  const [tableData, setTableData] = React.useState([]);

  const [searchQuery, setSearchQuery] = React.useState('');
  const [page, setPage] = React.useState(0);
  const [pageSize, setPageSize] = React.useState(10);
  const [dataCount, setDataCount] = React.useState(0);

  const { user } = useSelector((state) => state.AuthReducer);
  const { accessToken } = user;

  const columns = [
    { name: 'Id', options: { display: false } },
    { name: 'Nome', options: { sort: false } },
    { name: 'Descrição', options: { sort: false } },
    { name: 'Handler', options: { sort: false } },
    { name: 'Nome Amigável', options: { sort: false } },
    { name: 'URL', options: { sort: false } },
    {
      name: 'Ícone',
      options: {
        sort: false,
        customBodyRender(value, tableMeta, updateValue) {
          return <SvgIcon component={Icons[value]} />;
        },
      },
    },
    { name: 'Slug', options: { sort: false } },
    {
      name: 'Acesso Direto', options: { sort: false },
    },
    {
      name: 'Disponível no Menu', options: { sort: false },
    },
    { name: 'Data da Criação', options: { sort: false } },
    { name: 'Data da ultima atualização', options: { sort: false } },
    { name: 'Criado por', options: { sort: false } },
  ];

  const getFunctionList = React.useCallback(async () => {
    const getFunctionResponse = await getFunctions(accessToken, page, pageSize, searchQuery);

    const [functionData, functionCount] = getFunctionResponse;

    setFunctions(functionData);
    setDataCount(functionCount);
  }, [
    accessToken,
    page,
    pageSize,
    searchQuery,
  ]);

  React.useEffect(() => {
    setLoading(true);
    getFunctionList();

    setLoading(false);
  }, [functionGotDeleted, getFunctionList]);

  React.useEffect(() => {
    const data = [];
    functions.map(element => {
      data.push([
        element.id,
        element.name,
        element.description,
        element.__handler__.name,
        element.friendly_name,
        element.path_to_redirect,
        element.icon,
        element.slug,
        element.direct_access ? 'Sim' : 'Não',
        element.is_available_in_menu ? 'Sim' : 'Não',
        element.date_created,
        element.date_updated,
        element.created_by,
      ]);
    });
    setTableData(data);
  }, [functions]);

  const onAddFunctionClicked = () => {
    history.push('/functions/create');
  };

  const onUpdateFunctionClicked = (id) => {
    history.push(`/functions/update/${id}`);
  };

  const onDeleteFunctionClicked = async (id) => {
    const deleteFunctionResponse = await deleteFunction(user.accessToken, id);
    if (deleteFunctionResponse) getFunctionList();
  };

  return (
    <Can
      perform="Functions/List"
      yes={() => (
        <div style={{ padding: '1em' }}>
          <Can
            perform="Functions/Create"
            yes={() => (
              <div className="create-function-button">
                <Button
                  variant="contained"
                  startIcon={<SvgIcon component={Icons.Add} />}
                  onClick={onAddFunctionClicked}
                  style={{
                    marginBottom: '1em',
                    color: 'white',
                    backgroundColor: '#31ab70',
                    fontFamily: 'Poppins',
                    fontWeight: 'bold',
                  }}
                >
                  Criar Função
                </Button>
              </div>
            )}
            no={() => <></>}
          />
          <DataTable
            count={dataCount}
            page={page}
            setPage={setPage}
            pageSize={pageSize}
            setPageSize={setPageSize}
            searchQuery={searchQuery}
            setSearchQuery={setSearchQuery}
            title="Funções"
            columns={columns}
            data={tableData}
            onUpdateClicked={onUpdateFunctionClicked}
            onDeleteClicked={onDeleteFunctionClicked}
            addRegisterLabel="Criar Função"
            addRegisterLink="/functions/create"
          />
        </div>
      )}
      no={() => <Redirect to="/main" />}
    />
  );
};

export default FunctionList;
