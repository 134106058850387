import React from 'react';
import { useDropzone } from 'react-dropzone';

import Button from '../button';
import Loading from '../loading';

import {
  fileExtensionIsValide,
  acceptedFilesItens,
  rejectionFilesItens,
} from './helpers';

import {
  StyledBatchCreationDialog,
  StyledDropZone,
  StyledDropZoneListingFile,
  StyledDialogActions,
} from './batch-creation-dialog-styles';

const BatchCreationDialog = ({
  open,
  handleOnClose,
  handleSubmit,
  isSubmit,
}) => {
  const {
    acceptedFiles,
    getRootProps,
    fileRejections,
    getInputProps,
  } = useDropzone({ validator: file => fileExtensionIsValide(file, 'xlsx') });

  const AcceptedFilesItens = acceptedFilesItens(acceptedFiles);
  const FileRejection = rejectionFilesItens(fileRejections);

  return (
    <StyledBatchCreationDialog
      open={open}
      onClose={handleOnClose}
    >
      <StyledDropZone>
        <div {...getRootProps({ className: 'dropzone' })}>
          <input {...getInputProps()} />
          <p>Arraste e solte alguns arquivos aqui ou clique para selecionar arquivos</p>
        </div>
        <StyledDropZoneListingFile>
          <h4>Files</h4>
          <ul>
            {AcceptedFilesItens}
            {FileRejection}
          </ul>
        </StyledDropZoneListingFile>
      </StyledDropZone>
      <StyledDialogActions>
        <Button
          className="submit-button"
          disabled={isSubmit}
          onClick={() => handleSubmit(acceptedFiles[0])}
        >
          {isSubmit ? <Loading /> : 'Submeter'}
        </Button>
      </StyledDialogActions>
    </StyledBatchCreationDialog>
  );
};

export default BatchCreationDialog;
