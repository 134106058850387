export const mainPhone = {
  name: 'main-phone',
  nickname: 'Telefone Principal',
  type: 'text',
  label: 'Telefone Principal',
  hasborder: 'yes',
  mask: '99 99999-9999',
  landline: '99 9999-9999',
  isDisabledInput: true,
};

export const secondaryPhone = {
  name: 'secondary-phone',
  nickname: 'Telefone Secundário',
  type: 'text',
  label: 'Telefone Secundário',
  hasborder: 'yes',
  mask: '99 99999-9999',
  landline: '99 9999-9999',
  isDisabledInput: true,
};

export const shortDescription = {
  name: 'short-description',
  nickname: 'Descrição curta',
  label: 'Descrição curta',
  textarea: {
    rows: 5,
  },
  hasborder: 'yes',
  hasradius: 'yes',
  shortDescription: 250,
  isDisabledInput: true,
};

export const longDescription = {
  name: 'long-description',
  nickname: 'Descrição',
  label: 'Descrição',
  textarea: {
    rows: 5,
  },
  hasborder: 'yes',
  hasradius: 'yes',
  longDescription: 750,
  isDisabledInput: true,
};
