import React from 'react';
import IconEmojis from '../../assets/images/emojis.svg';
import IconLocalization from '../../assets/images/localization.svg';
import IconUser from '../../assets/images/user.svg';
import ActionButton from './action-button';
import { StyledGroupActionButton } from './group-action-button-styled';

type groupActionButtonProps = {
    onClickIconEmojis(): void;
    onClickIconUser(): void;
    onClickIconLocalization(): void;
}

const GroupActionButton = ({ onClickIconEmojis, onClickIconUser, onClickIconLocalization }: groupActionButtonProps) => (
  <StyledGroupActionButton>
    <ActionButton iconImage={IconEmojis} onClick={onClickIconEmojis} />
    <ActionButton iconImage={IconUser} onClick={onClickIconUser} />
    <ActionButton iconImage={IconLocalization} onClick={onClickIconLocalization} />
  </StyledGroupActionButton>
);

export default GroupActionButton;
