import React from 'react';
import { HighlightOff as HighlightOffIcon } from '@material-ui/icons';
import { debounce, isEmpty, uniqueId } from 'lodash';

import * as R from 'ramda';
import { IconButton } from '@material-ui/core';
import { TAutocompleteInput } from '../../types/TAutocompleteInput';
import InputSwitch from '../input-switch';

import filterSearch from '../../utils/filter-search';

import {
  StyledAutocompleteInput,
  StyledInputSearch,
  StyledListingSearch,
  StyledSelectedOptions,
  StyledSelectedOption,
  StyledSelectedActionsWrapper,
  StyledInputSwitchWrap,
  StyledDisabledInput,
  StyledPinIcon,
  StyledTooltip,
} from './autocomplete-input-styles';

const AutocompleteInput = ({
  label,
  options,
  saveSelectedOpionsIn,
  initialValue,
  filterSearchKey,
  categoryId,
  saveAllObject = false,
  className = 'autocomplete-input',
  disclaimer,
  disabledDeleteOption = false,
  apiSearchMode = null,
  singleMode = false,
  currentInputValueByRef,
  setOtherFieldsToDefault = null,
  withSwitch = false,
}: TAutocompleteInput) => {
  const [openListing, setOpenListing] = React.useState(false);
  const [disabled, setDisabled] = React.useState(false);
  const [inputSearch, setInputSearch] = React.useState('');
  const [selectedOptions, setSelectedOptions] = React.useState<any[]>([]);
  const [autoCompleteSwitch, setAutoCompleteSwitch] = React.useState(false);

  const inputRef = React.useRef<HTMLInputElement>(null);

  const handleInputSearch = debounce((text: any) => {
    if (currentInputValueByRef && currentInputValueByRef.current) {
      currentInputValueByRef.current.value = text;
    }

    if (apiSearchMode) {
      apiSearchMode(text);

      return;
    }

    setInputSearch(text);
  }, 400);

  React.useEffect(() => {
    if (!options) setDisabled(true);
    if (options) setDisabled(false);

    setSelectedOptions(initialValue === 'MUST_BE_EMPTY' ? [] : initialValue);

    if (!isEmpty(initialValue)) setAutoCompleteSwitch(true);
  }, [initialValue, options]);

  const handleAddOption = (newOption: any) => {
    if (setOtherFieldsToDefault) {
      setOtherFieldsToDefault();
    }

    if (singleMode && inputRef.current) {
      inputRef.current.value = newOption[filterSearchKey];
    }

    let newSelectedOptions;

    if (!saveAllObject) {
      newSelectedOptions = [
        newOption[filterSearchKey],
        ...selectedOptions.filter((option) => option !== newOption),
      ];
    }

    if (saveAllObject) {
      newSelectedOptions = [
        newOption,
        ...selectedOptions.filter(
          (option) => option[categoryId] !== newOption[categoryId],
        ),
      ];
    }

    setSelectedOptions(newSelectedOptions || []);
    saveSelectedOpionsIn(newSelectedOptions);
  };

  const handleRemoveOption = (optionToDelete: any) => {
    if (setOtherFieldsToDefault) setOtherFieldsToDefault();
    const newSelectedOptions = selectedOptions.filter(
      (option) => option !== optionToDelete,
    );

    setSelectedOptions(newSelectedOptions);

    saveSelectedOpionsIn(
      withSwitch && isEmpty(newSelectedOptions) && autoCompleteSwitch
        ? 'MUST_BE_EMPTY'
        : newSelectedOptions
    );
  };

  const handleCleanInputText = () => {
    if (singleMode) {
      setSelectedOptions([]);
      saveSelectedOpionsIn([]);
      handleInputSearch('');
    }

    setInputSearch('');
    setOpenListing(false);

    if (inputRef.current) inputRef.current.value = '';
  };

  const handleMainOption = (optionToSetMain: any) => {
    if (setOtherFieldsToDefault) setOtherFieldsToDefault();
    const selectedOptionsWithoutOptionMain = selectedOptions.filter(
      (option) => option !== optionToSetMain,
    );
    const newSelectedOptions = [
      optionToSetMain,
      ...selectedOptionsWithoutOptionMain,
    ];
    setSelectedOptions(newSelectedOptions);
    saveSelectedOpionsIn(newSelectedOptions);
  };

  const handleAutoCompleteSwicth = React.useCallback(() => {
    const newAutoCompleteSwitchState = !autoCompleteSwitch;
    setAutoCompleteSwitch(newAutoCompleteSwitchState);

    if (newAutoCompleteSwitchState && isEmpty(selectedOptions)) saveSelectedOpionsIn('MUST_BE_EMPTY');
    if (!newAutoCompleteSwitchState) saveSelectedOpionsIn([]);
  }, [autoCompleteSwitch, selectedOptions]);

  const isAutoCompletSwitch = React.useMemo(() => !withSwitch || autoCompleteSwitch, [withSwitch, autoCompleteSwitch]);

  return (
    <StyledAutocompleteInput
      className={className}
      onBlur={() => setTimeout(() => setOpenListing(false), 500)}
    >
      {label && <label htmlFor="options">{label}</label>}
      <StyledInputSearch>
        {!disabled && (
          <>
            {isAutoCompletSwitch && (
              <>
                <input
                  disabled={singleMode && !R.isEmpty(selectedOptions)}
                  ref={inputRef}
                  type="text"
                  id="options"
                  placeholder="Buscar"
                  onChange={(e) => handleInputSearch(e.target.value)}
                  autoComplete="off"
                  onClick={() => setOpenListing(true)}
                  onFocus={() => setOpenListing(true)}
                />
                {(openListing
              || inputSearch !== ''
              || (singleMode && !R.isEmpty(selectedOptions))) && (
              <HighlightOffIcon
                className="clean-text-input"
                onClick={handleCleanInputText}
              />
                )}
              </>
            )}

            {(withSwitch && !autoCompleteSwitch) && <StyledDisabledInput>Desabilitado</StyledDisabledInput>}

            {withSwitch && (
              <StyledInputSwitchWrap>
                <InputSwitch
                  changeChecked={handleAutoCompleteSwicth}
                  checked={autoCompleteSwitch}
                />
              </StyledInputSwitchWrap>
            )}
          </>
        )}

        {openListing && (
          <StyledListingSearch>
            {options
              && options
                .filter((option: any) => filterSearch({
                  item: option,
                  key: filterSearchKey,
                  query: inputSearch,
                }))
                .map((option: any) => (
                  <li
                    key={option[categoryId]}
                    role="presentation"
                    onClick={() => {
                      handleAddOption({
                        ...option,
                        [filterSearchKey]: option[filterSearchKey],
                        [categoryId]: option[categoryId],
                      });
                    }}
                  >
                    {option[filterSearchKey]}
                  </li>
                ))}
          </StyledListingSearch>
        )}
      </StyledInputSearch>

      {(autoCompleteSwitch && selectedOptions.length > 0) && (
        <StyledSelectedOptions>
          {(!singleMode && selectedOptions) && selectedOptions.map((option, index) => (
            <StyledSelectedOption key={uniqueId()} primary={index === 0}>
              {option[filterSearchKey] || option}
              {' '}
              {index === 0 && ' (principal)'}

              <StyledSelectedActionsWrapper>
                {index !== 0 && (
                  <StyledTooltip title="Definir como principal">
                    <IconButton
                      onClick={() => handleMainOption(option)}
                    >
                      <StyledPinIcon />
                    </IconButton>
                  </StyledTooltip>
                )}
                {(!disabled
                    && !disabledDeleteOption)
                    && <HighlightOffIcon onClick={() => handleRemoveOption(option)} />}
              </StyledSelectedActionsWrapper>
            </StyledSelectedOption>
          ))}
        </StyledSelectedOptions>
      )}
      {disabled && disclaimer && (
        <small className="disclaimer-disabled">{disclaimer}</small>
      )}
    </StyledAutocompleteInput>
  );
};
export default AutocompleteInput;
