import styled from 'styled-components';
import MUIDataTable from 'mui-datatables';
import { lighten } from 'polished';

import { devices } from '../../../assets/styles/devices';

export const StyledSubTable = styled(MUIDataTable)`
  width: 100%;
  border-radius: 0 !important;
  background: ${props => lighten(0.93, props.theme.colors.black)} !important;
  padding: 2rem;

  .loading {
    margin: 0 auto;
  }

  .loading-left {
    margin-right: auto;
  }

  .MuiToolbar-root,
  .MuiTableBody-root,
  .MuiTableCell-footer {
    background: ${props => props.theme.colors.white};
  }

  .MuiTypography-h6,
  .MuiTableCell-head {
    font-size: 1.5rem;
    font-weight: 600;
    font-family: Poppins;
    border-bottom: none;
    vertical-align: middle;
  }

  .MuiTableCell-body {
    font-size: 1.5rem;
    font-weight: 400;
  }

  .MuiSvgIcon-root {
    width: 2.4rem;
    height: 2.4rem;
    fill: ${props => props.theme.colors.primary};
  }

  .MuiPaper-root {
    background: ${props => props.theme.colors.primary};

    .MuiSvgIcon-root {
      fill: ${props => props.theme.colors.white};
    }

    .MuiTypography-subtitle1 {
      font-size: 1.6rem;
      color: ${props => props.theme.colors.white};
    }
  }

  .MuiTablePagination-toolbar {
    .MuiTablePagination-caption,
    .MuiTablePagination-input {
      font-size: 1.5rem;
    }
  }

  .MuiChip-label {
    font-size: 1.5rem;
  }

  th, td {
    padding: 1rem 2rem;
  }
`;

export const StyledCellCustomButtons = styled.div`
  display: flex;

  button {
    margin-right: 2rem;
    color: ${props => props.theme.colors.white};

    svg {
      fill: ${props => props.theme.colors.white} !important;
    }

    &:last-of-type {
      margin-right: 0;
    }
  }
`;

export const StyledSubtableFilters = styled.div`
  display: flex;
  flex-direction: column;
  column-gap: 1rem;
  padding-top: 1rem;
  padding-bottom: 2rem;
  width: 100%;

  @media ${devices.tablet} {
    flex-direction: row;
    padding-bottom: 0rem;
  }
`;
