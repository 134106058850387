import styled, { css } from 'styled-components';
import { Checkbox } from '@material-ui/core';
import { devices } from '../../../../../../assets/styles/devices';

export const StyledMediaWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding: 1rem;
`;

export const StyledCreationDate = styled.div`
  font-size: 1.3rem;
  line-height: 2rem;
  color: ${props => props.theme.colors.primary};
`;

export const StyledCheckbox = styled(Checkbox)`
  color: ${(props) => props.theme.colors.primary};
  margin-right: 1rem;

  .MuiSvgIcon-root {
    width: 2.2rem;
    height: 2.2rem;
    fill: ${(props) => props.theme.colors.primary};
  }
`;

export const StyledInfoAndActionsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 1rem;
`;

export const StyledImage = styled.img<{ deleted: boolean }>`
  ${props => props.deleted && css`
    filter: grayscale(1);
    opacity: 0.5;
  `}
`;

export const StyledVideo = styled.video<{ deleted: boolean }>`
  ${props => props.deleted && css`
    filter: grayscale(1);
    opacity: 0.5;
  `}
`;
