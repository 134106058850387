import React from 'react';
import { Close, Delete } from '@material-ui/icons';
import * as R from 'ramda';

import APIDeleteGuest from '../../../../services/guest/delete';

import limitedText from '../../../../utils/limited-text';

import {
  Button,
  OverlayLoading,
} from '../../..';

import {
  StyledGuest,
  StyledHeaderAction,
  StyledGuestActionsWrapper,
  StyledContent,
  StyledInfoWrapper,
  StyledContentOutsideWrapper,
  StyledLoadingWrapper,
} from './company-guest-style';

const CompanyGuest = ({
  guestId,
  userName,
  userEmail,
  userAccessToken,
  userSetTokenLikeExpired,
  getGuestsList,
}) => {
  const [companyGuestLoading, setCompanyGuestLoading] = React.useState(false);
  const [isDeleting, setIsDeleting] = React.useState(false);

  const handleToggleDeleteInviteForm = () => {
    setIsDeleting(!isDeleting);
  };

  const handleRemoveCompanyGuest = React.useCallback(async () => {
    setIsDeleting(false);
    setCompanyGuestLoading(true);

    const deleteGuestResponse = await APIDeleteGuest({
      accessToken: userAccessToken,
      id: guestId,
      setTokenLikeExpired: userSetTokenLikeExpired,
      setIsFetching: setCompanyGuestLoading,
    });

    if (R.isNil(deleteGuestResponse)) return;

    setCompanyGuestLoading(false);

    getGuestsList();
  }, []);

  return (
    <StyledGuest>
      <StyledContentOutsideWrapper>
        {companyGuestLoading && (
          <StyledLoadingWrapper>
            <OverlayLoading />
          </StyledLoadingWrapper>
        )}
        <StyledInfoWrapper>
          {isDeleting ? (
            <Button
              className="button"
              onClick={handleRemoveCompanyGuest}
              type="red"
            >
              Deletar
            </Button>
          ) : (
            <StyledContent>
              <span data-testid="user-name" className="email">
                <h4>{limitedText(userName, 25)}</h4>
              </span>
              <span data-testid="user-email" className="profile">
                <small>{limitedText(userEmail, 20)}</small>
              </span>
            </StyledContent>
          )}
          <StyledGuestActionsWrapper>
            <StyledHeaderAction onClick={handleToggleDeleteInviteForm}>
              {isDeleting ? <Close /> : <Delete />}

            </StyledHeaderAction>
          </StyledGuestActionsWrapper>
        </StyledInfoWrapper>
      </StyledContentOutsideWrapper>
    </StyledGuest>
  );
};

export default CompanyGuest;
