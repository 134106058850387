import styled from 'styled-components';
import { Dialog } from '@material-ui/core';

export const StyledBatchCreationDialog = styled(Dialog)`
    .submit-button {
        max-width: 15rem;
        width: 100%;
    }
`;

export const StyledDialogTitle = styled.h2`
    font-size: 2rem;
`;

export const StyledDialogActions = styled.div`
    padding: 2rem;
    display: flex;
    justify-content: flex-end;
`;

export const StyledDropZone = styled.div`
    padding: 2rem 2rem 0;

    .dropzone {
        flex: 1;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 20px;
        border-width: 2px;
        border-radius: 2px;
        border-color: #eeeeee;
        border-style: dashed;
        background-color: #fafafa;
        color: #bdbdbd;
        outline: none;
        transition: border .24s ease-in-out;
        margin-bottom: 2rem;

        &:focus {
            border-color: ${props => props.theme.colors.primary};
        }
    }

    p {
        margin-bottom: 0;
        font-size: 1.4rem;
    }
`;

export const StyledDropZoneListingFile = styled.div`
    h4 {
        text-transform: uppercase;
        font-size: 1.4rem;
        margin-bottom: 1rem;
    }

    li {
        font-size: 1.2rem;
        color: ${props => props.theme.colors.primary};
    }

    .error-rejection {
        color: ${props => props.theme.colors.red};
    }
`;
