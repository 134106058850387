import axios from 'axios';

import type {
  EBatchPlatformStatus,
  TAffectedPlatforms,
} from '../../../types/TBatchGoogleModules';
import type { TServiceBatchLocation } from '../../../types/TServiceBatch';

export type TUpdateServiceBatchLocation = {
  userAccessToken: string;
  serviceBatchLocationId: string;
  status?: EBatchPlatformStatus;
  setIsLoading?: React.Dispatch<React.SetStateAction<boolean>>;
  affectedTicketPlatforms?: TAffectedPlatforms;
};

type TSuccessResponse = {
  status: 'SUCCESS';
  data: TServiceBatchLocation;
};

type TErrorResponse = {
  status: 'ERROR';
  data: any;
};

export const updateServiceBatchLocation = async ({
  serviceBatchLocationId,
  userAccessToken,
  status,
  setIsLoading,
  affectedTicketPlatforms,
}: TUpdateServiceBatchLocation): Promise<TSuccessResponse | TErrorResponse> => {
  try {
    if (setIsLoading) setIsLoading(true);

    const { data } = await axios.put(
      `${process.env.REACT_APP_SAAS_BATCH_GOOGLE_MODULES}/service-batch-locations/${serviceBatchLocationId}`,
      {
        status,
        affected_platforms: affectedTicketPlatforms,
      },
      {
        headers: {
          Authorization: `Bearer ${userAccessToken}`,
        },
      }
    );

    return {
      status: 'SUCCESS',
      data,
    };
  } catch (err: any) {
    return {
      status: 'ERROR',
      data: err?.response?.data,
    };
  } finally {
    if (setIsLoading) setIsLoading(false);
  }
};
