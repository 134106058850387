export const formattedAddress = (address1: string) => {
  const address = address1.trim();
  const canSplit = address.includes(',');
  if (canSplit) {
    const [addressName, number] = address.split(',');
    return [addressName.trim(), number.trim()];
  }

  const undefinedIndex = address.indexOf('undefined');
  let newAddress = address;
  if (undefinedIndex > 0) {
    newAddress = address.substring(0, undefinedIndex);
  }

  let number = '';
  for (let i = newAddress.length - 1; i >= 0; i -= 1) {
    const char = newAddress[i];
    if (char !== ' ' && !Number.isNaN(parseInt(char, 10))) {
      number += char;
    } else break;
  }
  const numberIndex = newAddress.indexOf(number);
  if (numberIndex > 0) {
    return [newAddress.substring(0, numberIndex).trim(), number];
  }
  return [newAddress, ''];
};

export const formattedCategories = (categories: string) => {
  if (!categories) return [];

  const categoriesList = JSON.parse(categories);

  if (categoriesList === '[]') return [];

  categoriesList.length = Object.keys(categoriesList).length;
  return categoriesList;
};

export const storeCodeAttr = {
  name: 'store_code',
  type: 'text',
  label: 'Código do Local',
  description: 'Código único para registro do local',
  hasBorder: true,
};

export const answerAtThatAddressAttr = {
  name: 'does_not_serve_at_this_address',
  type: 'text',
  label: 'Atende nesse endereço?',
  description: 'Este endereço é apenas para referência?',
  isSwitch: true,
};
