import styled from 'styled-components';

export const StyledInfosPopover = styled.div`
  .button-apply-replicated {
    margin: 0 auto 2rem;
  }
`;

export const StyledMainItem = styled.div`
  padding: 1rem 2rem;
  border-bottom: 1px solid black;

  display: flex;
  align-items: center;
  justify-content: space-between;

  p {
    margin: 0;
  }
`;

export const StyledWrapper = styled.div`
  width:100%
`;
