import updateCompany from '../../services/companies/updateCompany';
import addResponsibles from '../../services/companies/addResponsibles';
import removeResponsibles from '../../services/companies/removeResponsibles';
import isNilOrEmpty from '../../utils/is-null-or-empty';

export const saveEditionInDB = async ({
  userHublocalToken,
  setTokenLikeExpired,
  responsibles,
  companyData,
  companyID,
  setIsSaveEdition,
  feedbackMessage,
  redirect,
}) => {
  try {
    if (setIsSaveEdition) setIsSaveEdition(true);
    let mainResponsible = null;
    if (!isNilOrEmpty(responsibles)) {
      const { id: mainResponsibleID } = responsibles
        .filter(responsible => responsible.main === true)[0];

      mainResponsible = mainResponsibleID;

      const responsibleWithoutMain = responsibles
        .filter(responsible => responsible.main !== true).map(responsible => responsible.id);

      await removeResponsibles({
        accessToken: userHublocalToken,
        companyId: companyID,
        responsible_ids: [mainResponsibleID],
        setTokenLikeExpired,
      });

      await addResponsibles({
        accessToken: userHublocalToken,
        companyId: companyID,
        setTokenLikeExpired,
        responsible_ids: responsibleWithoutMain,
      });
    }

    const {
      name, website, data_studio, is_active,
    } = companyData;

    const company = await updateCompany({
      accessToken: userHublocalToken,
      companyId: companyID,
      name,
      website,
      data_studio,
      is_active,
      main_responsible_id: mainResponsible,
      feedbackMessage,
      setIsSaveEdition,
      setTokenLikeExpired,
    });
    if (redirect) redirect();

    return company;
  } catch (err) {
    feedbackMessage('Não foi possível criar a empresa. Tente novamente!', { variant: 'error' });
  } finally {
    if (setIsSaveEdition) setIsSaveEdition(false);
  }
};
