import React from 'react';

import { StyledStar, StyledIsActiveStar } from './star-styles';

const Star = ({
  starRating,
  currentStarRating,
  setStarRating,
  currentStarRatingPosition,
  starRatingAll,
}) => {
  const [isActive, setIsActive] = React.useState(false);

  React.useState(() => {
    if (starRating.rateNumber <= currentStarRating.rateNumber) {
      setIsActive(!isActive);
    }
  }, [currentStarRating]);

  const handleIsActive = () => {
    let newCurrentStarRating = starRating;

    if (isActive && currentStarRatingPosition === 0) {
      newCurrentStarRating = {
        rateNumber: 0,
        word: null,
      };
    }

    if (isActive && currentStarRatingPosition !== 0) {
      setIsActive(!isActive);

      newCurrentStarRating = starRatingAll[currentStarRatingPosition - 1];
    }

    setStarRating((prevStarRating) => ({
      ...prevStarRating,
      currentStarRating: newCurrentStarRating,
    }));
  };

  return isActive ? (
    <StyledIsActiveStar onClick={handleIsActive} />
  ) : (
    <StyledStar onClick={handleIsActive} />
  );
};

export default Star;
