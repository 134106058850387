import styled from 'styled-components';

export const StyledBasicInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const StyledList = styled.ul`
  li:not(:last-child) {
    margin-bottom: 0.8rem;
  }
`;
