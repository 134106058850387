import axios from 'axios';

export type TCountCompanyReplicatedLocationsOnBi = {
  accessToken: string;
  companyId: number;
  setTokenLikeExpired?: (() => void) | null;
  setIfFetching?: React.Dispatch<React.SetStateAction<boolean>> | null;
};

export default async function countCompanyReplicatedLocationsOnBi({
  accessToken,
  companyId,
  setTokenLikeExpired,
  setIfFetching,
}: TCountCompanyReplicatedLocationsOnBi): Promise<number | null> {
  try {
    if (setIfFetching) setIfFetching(true);

    const { data } = await axios.get<number>(
      `${process.env.REACT_APP_LOCATIONS_API_URL}/count-replicated-company-locations/${companyId}`,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );

    return data;
  } catch (err: any) {
    if (err.response?.status === 401 && setTokenLikeExpired) {
      setTokenLikeExpired();
    }

    return null;
  } finally {
    if (setIfFetching) setIfFetching(false);
  }
}
