import styled from 'styled-components';
import { Paper } from '@material-ui/core';

import { devices } from '../../assets/styles/devices';

export const StyledCreateLocationGroup = styled.div`
    display: flex;
    flex-direction: column;
    margin-bottom: 4rem;

    .batch-creation,
    .block-form {
        margin-top: 2rem;
        margin-bottom: 3rem;
    }
`;

export const StyledButtonsGroup = styled.div`
    display: flex;

    .button {
        margin-right: 1.5rem;

        &:last-of-type {
            margin-right: 0;
        }
    }
`;

export const StyledContent = styled.div`
    display: grid;
    grid-template-columns: 1fr;
    row-gap: 2rem;
    padding: 3rem 2rem 2rem;

    @media ${devices.tabletLandscape} {
        grid-template-columns: 75% 23%;
        column-gap: 2%;
    }
`;

export const StyledContentMain = styled.div`
    display: flex;
    flex-direction: column;
`;

export const StyledContentAside = styled.aside`
    display: flex;
    flex-direction: column;
    height: max-content;
    position: sticky;
    top: 1rem;

    @media ${devices.desktop} {
        padding-top: 3.8rem;
    }
`;

export const StyledGroupFormCreate = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 3rem;
`;

export const StyledGroupFormCreateTitle = styled.h3`
    display: flex;
    font-weight: 700;
    font-size: 1.5rem;
    margin-bottom: 1.5rem;
`;

export const StyledGroupFormCreateWrapper = styled(Paper)`
  padding: 2rem;
  width: 100%;

  .group-field {
    margin-bottom: 2rem;
  }
`;
