import styled, { css } from 'styled-components';
import { Link } from 'react-router-dom';
import { shade, rgba } from 'polished';

import { devices } from '../../assets/styles/devices';

export const StyledSideMenu = styled.aside`
    display: none;
    background: ${props => props.theme.colors.primary};
    width: 7rem;
    height: 100vh;
    left: 0;
    top: 0;
    bottom: 0;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    position: fixed;
    z-index: 1101;
    overflow: hidden;
    transition: 0.3s;

    .nav-item-label {
      transition: .3s;
      margin-left: 5rem;
    }

    .nav-item-logo {
      transition: .3s;
      margin-left: 0;
    }

    ${props => props.isOpenSideMenu && css`
      transition: 0.3s;
      width: 27rem;

      .nav-item-label {
        transition: .3s;
        margin-left: 1rem;
      }

      .nav-item-logo  {
        transition: .3s;
      }

      .MuiIconButton-root {
        right: 1.2rem;
      }
    `};

    @media ${devices.tabletLandscape} {
        display: flex;
    }
`;

export const StyledSideMenuLogo = styled(Link)`
    display: flex;
    padding: 2.4rem 1.5rem;
    opacity: 0.3s;
    background: ${props => props.theme.colors.primary};
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    transition: background 0.4s;
    cursor: pointer;

    &:hover {
        background: ${props => shade(0.1, props.theme.colors.primary)};
        text-decoration: none;
    }

    img {
        width: 4rem;
    }

    ${props => props.isOpenSideMenu && css`
      margin-left: 2rem;
    `};
`;

export const StyledItemsGroup = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  width: 100%;
  height: 100%;
  padding-top: 8.2rem;
  overflow-y: auto;
  overflow-x: hidden;

  ::-webkit-scrollbar {
    width: 5px;
  }

  ::-webkit-scrollbar-track {
    box-shadow: none;
    border-radius: 3rem;
  }

  ::-webkit-scrollbar-thumb {
    background: ${props => rgba(props.theme.colors.black, 0.5)};
    border-radius: 3rem;
  }
  
  ::-webkit-scrollbar-thumb:hover {
    background: ${props => rgba(props.theme.colors.black, 1)};
  }
`;

export const StyledItemGroup = styled.nav`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;

    ${props => props.align === 'start' && css`
        margin-bottom: auto;
    `}

    ${props => props.align === 'end' && css`
        margin-top: auto;
    `}
`;

export const StyledUserAvatar = styled(Link)`
    padding: 1rem 1.9rem;
    background: ${props => props.theme.colors.primary};
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    cursor: pointer;
    margin-top: auto;
    transition: background 0.3s;

    &:hover {
        text-decoration: none;
        background: ${props => shade(0.1, props.theme.colors.primary)};

        .letter {
            border-radius: 50%;
        }
    }
`;

export const StyledUserAvatarLetter = styled.div`
    height: 3.2rem;
    width: 3.2rem;
    min-width: 3.2rem;
    border-radius: 0.5rem;
    background: ${props => props.theme.colors.white};
    color: ${props => props.theme.colors.black};
    font-weight: 700;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 2px solid transparent;
    transition: 0.3s;
`;

export const StyledUserAvatarLabel = styled.div`
  color: ${props => props.theme.colors.white};
  margin-left: 2rem;
  word-spacing: 30rem;
  font-size: 1.4rem;
`;

export const StyledSideMenuLogoLabel = styled.h3`
  margin-bottom: 0;
  margin-left: 0;
  font-size: 3rem;
  font-weight: 600;
  color: ${props => props.theme.colors.white};
`;

export const StyledSideMenuLogoLabel2 = styled.span`
  font-size: 3rem;
  font-weight: 300;
  color: ${props => props.theme.colors.white};
`;

export const StyledSideMenuLogoImg = styled.img`
  margin-left: 0;
  transition: 0.3s;

  ${props => props.isOpenSideMenu && css`
    margin-left: 2rem;
    transition: 0.3s;
  `};
`;
