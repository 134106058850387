import axios from 'axios';

export default async function addLocations(
  {
    accessToken,
    companyId,
    location_ids,
    setTokenLikeExpired,
    feedbackMessage,
  },
) {
  const requestOptions = {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  };

  try {
    const { data } = await axios.post(
      `${process.env.REACT_APP_COMPANIES_API_URL}/location/add/${companyId}`,
      { location_ids },
      requestOptions,
    );

    return data;
  } catch (err) {
    if (err.response?.status === 401 && setTokenLikeExpired) setTokenLikeExpired();
    if (err.response?.status === 403) feedbackMessage('Falha ao vincular local: Você já alcançou o limite de locais disponíveis', { variant: 'error' });

    return null;
  }
}
