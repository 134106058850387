// eslint-disable-next-line no-use-before-define
import React from 'react';

import MainLinks from './main-links';
import OtherLinks from './other-links';
import { TLinksProps } from './types';

const Links = ({
  initialValue,
  setUpdatedLocation,
  fieldOnFocus,
  setFieldOnFocus,
}: TLinksProps) => (initialValue ? (
  <>
    <MainLinks
      className="main-links"
      initialData={initialValue}
      setUpdatedLocation={setUpdatedLocation}
      fieldOnFocus={fieldOnFocus}
      setFieldOnFocus={setFieldOnFocus}
    />
    <OtherLinks
      className="other-links"
      updatedLocation={initialValue}
      setUpdatedLocation={setUpdatedLocation}
    />
  </>
) : <span>Carregando informações...</span>);

export default React.memo(Links);
