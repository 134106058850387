export const fieldRegisterClientName = {
  classNameInputWrapper: 'register-client-input',
  name: 'register-client-name',
  nickname: 'Nome',
  type: 'text',
  label: '',
  hasborder: 'yes',
  required: true,
};

export const fieldRegisterClientEmail = {
  classNameInputWrapper: 'register-client-input',
  name: 'register-client-email',
  nickname: 'Email',
  type: 'text',
  label: '',
  hasborder: 'yes',
  required: true,
};

export const fieldRegisterClientGoogleTokenRefresh = {
  classNameInputWrapper: 'register-client-input',
  name: 'register-client-google-token-refresh',
  nickname: 'Google Refresh Token',
  type: 'text',
  label: '',
  hasborder: 'yes',
  required: false,
};

export const fieldRegisterClientCompanyGroupLimit = {
  classNameInputWrapper: 'register-client-input',
  name: 'register-client-name',
  nickname: 'Limite de grupos de empresa',
  type: 'number',
  label: '',
  hasborder: 'yes',
  required: true,
};

export const fieldRegisterClientCompanyLimit = {
  classNameInputWrapper: 'register-client-input',
  name: 'register-client-company-limit',
  nickname: 'Limite de Empresas',
  type: 'number',
  label: '',
  hasborder: 'yes',
  required: true,
};

export const fieldRegisterClientLocationGroupLimit = {
  classNameInputWrapper: 'register-client-input',
  name: 'register-client-name',
  nickname: 'Limite de grupos de locais',
  type: 'number',
  label: '',
  hasborder: 'yes',
  required: true,
};

export const fieldRegisterClientLocationLimit = {
  classNameInputWrapper: 'register-client-input',
  name: 'register-client-location-limit',
  nickname: 'Limite de locais',
  type: 'number',
  label: '',
  hasborder: 'yes',
  required: true,
};
