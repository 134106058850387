import axios from 'axios';

export default async function copyLocation({
  accessToken,
  infoToCopy,
  name,
  company_id,
  feedbackMessage,
  setTokenLikeExpired,
  setIsCreating,
}) {
  try {
    if (setIsCreating) setIsCreating(true);
    if (feedbackMessage) feedbackMessage('Criando local...', { variant: 'warning' });

    const { data } = await axios.post(`${process.env.REACT_APP_LOCATIONS_API_URL}`,
      {
        ...infoToCopy,
        name,
        company_id,
      },
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });

    if (feedbackMessage) { feedbackMessage('Local criado com sucesso', { variant: 'success' }); }

    return data;
  } catch (err) {
    if (err.response?.status === 401 && setTokenLikeExpired) return setTokenLikeExpired();

    if (feedbackMessage) feedbackMessage('Erro ao criar o local', { variant: 'error' });

    return null;
  } finally {
    if (setIsCreating) setIsCreating(false);
  }
}
