import React from 'react';

import ConfigCard from '../../../components/config-card';

import {
  StyledDeletedLocationGroups,
} from './deleted-location-groups-styles';

const HandlerList = ({ navigation }) => (
  <ConfigCard
    title="Grupos Apagados de Locais"
    onClick={() => navigation.push('/deleted-location-groups/list')}
  >
    <StyledDeletedLocationGroups>
      Gerência de grupos de locais apagados pelo usuário proprietário
    </StyledDeletedLocationGroups>
  </ConfigCard>
);

export default HandlerList;
