import React from 'react';

import {
  StyledReplyBox,
  StyledText,
  StyledTitle,
} from './reply-box-styles';

type TReplyBox = {
  title: string;
  text: string;
  isSelected: boolean;
  onClick: () => void;
}

const ReplyBox = ({
  title,
  text,
  isSelected,
  onClick,
}: TReplyBox) => (
  <StyledReplyBox
    isSelected={isSelected}
    onClick={onClick}
  >
    <StyledTitle isSelected={isSelected}>
      {title}
    </StyledTitle>

    <StyledText isSelected={isSelected}>
      {text}
    </StyledText>
  </StyledReplyBox>
);

export default ReplyBox;
