import React from 'react';
import { isEmpty } from 'ramda';
import { useSelector, useDispatch } from 'react-redux';
import { useSnackbar } from 'notistack';

import { translations } from '../../translations';

import {
  Dialog,
  Input,
  Button,
  Loading,
} from '..';

import { emailValidate as emailIsValid } from '../../utils/fields-validate';
import { StyledContentText, StyledDialogActions, StyledForm } from './login-dialog-styles';

import { Creators as AuthActions } from '../../redux/ducks/auth';
import { Creators as RulesActions } from '../../redux/ducks/rules';

import getProfileFunctions from '../../services/profiles/getProfileFunctions';
import getProfileMenu from '../../services/profiles/getProfileMenu';
import signInUser from '../../services/signInUser';
import getProfiles from '../../services/profiles/getProfiles';

const LoginDialog = ({
  title,
  open,
  onClose,
  contentText,
  onCancelClicked,
  onLogIn,
}) => {
  const dispatch = useDispatch();
  const { language, user } = useSelector((state) => state.AuthReducer);
  const { login: loginTranslations } = translations[language];

  const [loading, setLoading] = React.useState(false);
  const [showPassword, setShowPassword] = React.useState(false);

  const { enqueueSnackbar } = useSnackbar();

  const emailRef = React.useRef(null);
  const [emailValidate, setEmailValidate] = React.useState({
    visible: false,
    type: 'error',
    message: '',
  });

  const passwordRef = React.useRef(null);
  const [passwordValidate, setPasswordValidate] = React.useState({
    visible: false,
    type: 'error',
    message: '',
  });

  const setTokenLikeExpired = () => {
    dispatch(AuthActions.setUser({ ...user, accessToken: 'expired' }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const emailFieldValue = emailRef.current.value;
    const passwordFieldValue = passwordRef.current.value;

    if (isEmpty(emailFieldValue)) {
      setEmailValidate((oldState) => ({
        ...oldState,
        visible: true,
        message: loginTranslations.fill_in_the_field,
      }));
      return;
    }

    if (!emailIsValid(emailFieldValue)) {
      setEmailValidate((oldState) => ({
        ...oldState,
        visible: true,
        message: loginTranslations.invalid_format_email,
      }));
      return;
    }
    setEmailValidate((oldState) => ({
      ...oldState,
      visible: false,
      message: '',
    }));

    if (isEmpty(passwordFieldValue)) {
      setPasswordValidate((oldState) => ({
        ...oldState,
        visible: true,
        message: loginTranslations.fill_in_the_field,
      }));
      return;
    }

    setPasswordValidate((oldState) => ({
      ...oldState,
      visible: false,
      message: '',
    }));
    setLoading(true);
    try {
      const data = await signInUser(emailFieldValue, passwordFieldValue);
      if (data.user.profiles[0].name === 'Admin' || data.user.profiles[0].name === 'Operacional') {
        const userProfile = data.user.profiles[0];

        const { functions: profileFunctions } = await getProfileFunctions({
          accessToken: data.accessToken,
          profileId: userProfile.id,
          setTokenLikeExpired,
        });

        const { menu: profileMenu } = await getProfileMenu({
          accessToken: data.accessToken,
          profileId: userProfile.id,
          setTokenLikeExpired,
        });

        const formattedProfile = { ...userProfile, functions: profileFunctions, menu: profileMenu };

        const formattedData = { ...data, user: { ...data.user, profiles: [formattedProfile] } };

        dispatch(AuthActions.setUser(formattedData));

        const rules = {};
        const profiles = await getProfiles({ accessToken: data.accessToken, setTokenLikeExpired });

        profiles[0].forEach(async (element) => {
          const { functions } = await getProfileFunctions({ accessToken: data.accessToken, profileId: element.id, setTokenLikeExpired });
          const funcs = [];
          functions.forEach((func) => {
            funcs.push(func.full_name);
          });
          rules[element.name] = { static: funcs };
          dispatch(RulesActions.setRules(rules));
        });

        onLogIn();
      } else throw 'err';
    } catch (err) {
      enqueueSnackbar('Acesso Negado', { variant: 'error' });
    }

    setLoading(false);
  };

  return (
    <Dialog title={title} open={open} onClose={onClose} width="10rem">
      <StyledContentText>
        <span>{contentText}</span>
      </StyledContentText>
      <StyledForm onSubmit={e => handleSubmit(e)}>
        <Input
          fieldRef={emailRef}
          label={loginTranslations.user}
          inputOptions={{ name: 'user', type: 'text' }}
          border
          inputValidate={emailValidate}
        />
        <Input
          fieldRef={passwordRef}
          label={loginTranslations.password}
          inputOptions={{ name: 'password', type: `${showPassword ? 'text' : 'password'}` }}
          border
          password
          showPassword={showPassword}
          changeShowPassword={setShowPassword}
          inputValidate={passwordValidate}
        />

        <StyledDialogActions>
          <Button disabled={loading} onClick={onCancelClicked}>Cancelar</Button>
          <Button disabled={loading} type="submit">
            {loading ? <Loading /> : 'Ok'}
          </Button>
        </StyledDialogActions>
      </StyledForm>
    </Dialog>
  );
};

export default LoginDialog;
