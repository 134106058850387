import React from 'react';

import FacebookLoginBtn from 'react-facebook-login/dist/facebook-login-render-props';

import { isEmpty } from 'lodash';
import { isNil } from 'ramda';

import { useSelector } from 'react-redux';
import { useAuth } from '../../../../hooks';
import { TRootStateRedux } from '../../../../types/TRootStateRedux';
import {
  StyledHeader,
  StyledFacebook,
  StyledHeaderLogoWrapper,
  StyledFacebookIcon,
  StyledIconLabelBold,
  StyledActionsWrapper,
  StyledAddConnectionsButton,
  StyledShowConnectionsButton,
  StyledNoAccountLinkedWrapper,
  StyledConnectButton,
  StyledContent,
  StyledConfigContent,
} from './facebook-styles';

import { DropDownDialog, Loading } from '../../../../components';
import { TFacebookLoginResponse, TFacebookUserAccountsData } from './types';

import APIGetUserConnections from '../../../../services/connections/getUserConnections';
import APIUpdateConnection from '../../../../services/connections/updateConnection';
import APICreateConnection from '../../../../services/connections/createConnection';
import { TLocationConnectionInfo } from '../../../../types/TPlatformsLinkWithLocation';
import { GetFacebookPages } from '../../../../services/facebook/get-facebook-pages';
import getValidFacebookAccessToken from '../../../../services/facebook/get-valid-facebook-access-token';
import getUserInfo from '../../../../services/facebook/get-user-info';
import FacebookLoggedAccount from './facebook-logged-account';
import LocationList from './location-list';
import LocationConnectionList from '../../../../components/location-connection-list';
import { getFacebookPermissions } from './helpers';
import APIGetLocationConnections from '../../../../services/location-connections/getAllLocationConnectionsFromLocation';


const Facebook = () => {
  const [facebookAccessToken, setFacebookAccessToken] = React.useState('');
  const [loadFacebookAccessToken, setLoadFacebookAccessToken] = React.useState(false);
  const [facebookUserId, setFacebookUserId] = React.useState('');

  const [isExpanded, setIsExpanded] = React.useState(false);
  const [addConnectionIsSelected, setAddConnectionIsSelected] = React.useState(false);
  const [viewConnectionsIsSelected, setViewConnectionsIsSelected] = React.useState(false);

  const [facebookUserPagesData, setFacebookUserPagesData] = React.useState<TFacebookUserAccountsData[]>([]);
  const [facebookUserAccountsLoading, setFacebookUserAccountsLoading] = React.useState(false);
  const [facebookPaginationPageList, setFacebookPaginationPageList] = React.useState(['']);

  const [facebookUserLocations, setFacebookUserLocations] = React.useState([]);
  const [locationConnections, setLocationConnections] = React.useState<any[]>([]);

  const [locationConnectionInfo, setLocationConnectionInfo] = React.useState<TLocationConnectionInfo>();
  const [facebookUserCurrentPagesNextPage, setFacebookUserCurrentPagesNextPage] = React.useState('');
  const [facebookUserCurrentPages, setFacebookUserCurrentPages] = React.useState<TFacebookUserAccountsData>();
  const [facebookUserCurrentSubPages, setFacebookUserCurrentSubPages] = React.useState<any>([]);

  const { activeCompany } = useSelector((state: TRootStateRedux) => state.CompanyReducer);
  const { activeLocation } = useSelector((state: TRootStateRedux) => state.LocationReducer);

  const {
    userId,
    userProfileName,
    userAccessToken,
    userSetTokenLikeExpired,
    userProfile,
  } = useAuth();

  const getFacebookUserAccountsData = React.useCallback(
    async (accessToken: string, nextPageToken = '') => {
      setFacebookUserAccountsLoading(true);
      const { pages, next }: any = await GetFacebookPages({
        accessToken: userAccessToken,
        facebookUserAccessToken: accessToken,
        nextPageToken,
      });

      const nextToken = pages.length >= 10 ? next : undefined;

      setFacebookUserPagesData(pages || []);
      setFacebookUserCurrentSubPages(pages[0].sub_pages);
      setFacebookUserCurrentPages(pages[0]);
      setFacebookUserCurrentPagesNextPage(nextToken);
      setFacebookUserAccountsLoading(false);
    }, [],
  );

  const getFacebookAccessToken = React.useCallback(async () => {
    setLoadFacebookAccessToken(true);

    const facebookAccessTokenResponse = await getValidFacebookAccessToken({
      accessToken: userAccessToken,
      userId,
      setTokenLikeExpired: userSetTokenLikeExpired,
      userProfile,
    });

    if (isNil(facebookAccessTokenResponse)) {
      setLoadFacebookAccessToken(false);
      return;
    }

    const {
      token,
      expirationTime,
    } = facebookAccessTokenResponse;

    const response = await getUserInfo(token, 'email,name');

    if (isNil(response)) {
      setFacebookAccessToken('');
      setFacebookUserId('');
      setLocationConnectionInfo(undefined);
      setLoadFacebookAccessToken(false);
      return;
    }

    const { name, email, id } = response;

    await getFacebookUserAccountsData(token, id);

    setFacebookAccessToken(token);
    setFacebookUserId(id);
    setLocationConnectionInfo({
      token,
      token_expires_in: expirationTime,
      email,
      grouping_name: 'Desagrupada',
    });

    setLoadFacebookAccessToken(false);
  }, []);


  React.useEffect(() => { getFacebookAccessToken(); }, []);

  const getLocationConnections = React.useCallback(async () => {
    const [locationConnections] = await APIGetLocationConnections({
      accessToken: userAccessToken,
      locationId: activeLocation.id,
      platform: 'facebook',
    });

    if (isNil(locationConnections)) return;

    setLocationConnections(locationConnections);
  }, [activeLocation]);

  React.useEffect(() => {
    getLocationConnections();
  }, [activeLocation]);

  React.useEffect(() => { getFacebookAccessToken(); }, []);

  const onLogin = async (responseToken1H: TFacebookLoginResponse) => {
    const {
      accessToken,
      userID,
      email,
      expiresIn,
      status,
    } = responseToken1H;

    if (!isNil(status) && status === 'unknown') return;

    const tokenExpirationDate = new Date();
    // const longDurationExpiresIn = 5183944;

    tokenExpirationDate.setSeconds(tokenExpirationDate.getSeconds() + expiresIn);

    const connectionInfoToUpdate = {
      token: accessToken || '',
      token_expires_in: tokenExpirationDate.toUTCString(),
      email,
      grouping_name: 'Desagrupada',
    };

    const [
      facebookConnectionsData,
      facebookConnectionsAmount,
    ] = await APIGetUserConnections({
      accessToken: userAccessToken,
      query: 'facebook',
      userId,
    });

    if (facebookConnectionsAmount > 0) {
      await APIUpdateConnection({
        accessToken: userAccessToken,
        connectionId: facebookConnectionsData[0].id,
        inputData: connectionInfoToUpdate,
        setTokenLikeExpired: userSetTokenLikeExpired,
      });
    } else {
      await APICreateConnection({
        accessToken: userAccessToken,
        userId,
        channel: 'facebook',
        token: connectionInfoToUpdate.token,
        refreshToken: 'dummyToken',
        tokenExpiresIn: connectionInfoToUpdate.token_expires_in,
        setTokenLikeExpired: userSetTokenLikeExpired,
        createdBy: userId,
      });
    }

    setLocationConnectionInfo(connectionInfoToUpdate);
    setFacebookUserId(userID);
    getFacebookUserAccountsData(accessToken, userID);
    setFacebookAccessToken(accessToken || '');
  };

  const handleFacebookLogout = React.useCallback(() => {
    setFacebookAccessToken('');
    setFacebookUserPagesData([]);
    setIsExpanded(false);
    setAddConnectionIsSelected(false);
    setViewConnectionsIsSelected(false);
  }, []);

  const handleClickAddConnection = () => {
    if (addConnectionIsSelected) {
      setAddConnectionIsSelected(prev => !prev);
      return setIsExpanded(false);
    }
    setIsExpanded(true);
    setViewConnectionsIsSelected(false);
    setAddConnectionIsSelected(true);
  };

  const handleClickViewConnections = () => {
    if (viewConnectionsIsSelected) {
      setViewConnectionsIsSelected(prev => !prev);
      return setIsExpanded(false);
    }
    setIsExpanded(true);
    setAddConnectionIsSelected(false);
    setViewConnectionsIsSelected(true);
  };

  const handleResetDropDownDialog = React.useCallback(async () => {
    setFacebookPaginationPageList(['']);
    getFacebookAccessToken();
  }, []);

  const isAdminOrOwner = React.useMemo(() => {
    if (userProfileName !== 'Usuário Padrão') return true;
    if (!isNil(activeCompany) && activeCompany.owner_user.id === userId) return true;
    return false;
  }, [userProfileName, activeCompany]);

  const handleChangeFacebookPage = React.useCallback((pageId: string) => {
    const currentPage = facebookUserPagesData.find(page => page.id === pageId);

    if (isNil(currentPage)) {
      setFacebookUserCurrentPages(undefined);
      return;
    }

    setFacebookUserCurrentSubPages(currentPage.sub_pages);

    if (currentPage.sub_pages.length === 0)
      setFacebookUserCurrentSubPages([currentPage]);
      
    setFacebookUserCurrentPages(currentPage);
  }, [facebookUserPagesData]);

  return (
    <StyledFacebook expanded={isExpanded}>
      <StyledHeader>
        {loadFacebookAccessToken && <span>Carregando informações...</span>}

        {(!loadFacebookAccessToken && isNil(activeLocation)) && <p>Nenhum local ativo encontrado!</p>}

        {(!isNil(activeCompany)) && !isAdminOrOwner && <p>Seu perfil não possui permissão para alterar conexões desse local</p>}

        {(!loadFacebookAccessToken && !isNil(activeLocation)) && isAdminOrOwner && (
          <>
            <StyledHeaderLogoWrapper>
              <StyledFacebookIcon />
              <>
                <StyledIconLabelBold>Facebook</StyledIconLabelBold>
              </>
            </StyledHeaderLogoWrapper>

            <StyledActionsWrapper>
              <StyledAddConnectionsButton
                selected={addConnectionIsSelected}
                onClick={handleClickAddConnection}
              >
                Adicionar conexão
              </StyledAddConnectionsButton>
              <StyledShowConnectionsButton
                selected={viewConnectionsIsSelected}
                onClick={handleClickViewConnections}
              >
                Ver conexões
              </StyledShowConnectionsButton>
            </StyledActionsWrapper>
          </>
        )}
      </StyledHeader>

      {isEmpty(facebookUserPagesData) && isExpanded && !loadFacebookAccessToken && (
        <StyledNoAccountLinkedWrapper>
          <b>Você ainda não possui nenhuma conta vinculada.</b>
          <p>Conecte-se com uma conta do Facebook para ter acesso a listagem dos Locais e, em seguida, prosseguir com as conexões.</p>

          <FacebookLoginBtn
            appId={process.env.REACT_APP_FACEBOOK_APP_ID || ''}
            autoLoad
            fields="name,email"
            callback={onLogin}
            scope={getFacebookPermissions()}
            redirectUri="https://saas.hublocal.com.br/"
            render={(renderProps) => (facebookUserAccountsLoading ? <Loading /> : (
              <StyledConnectButton onClick={renderProps.onClick}>Conectar</StyledConnectButton>
            ))}
          />

        </StyledNoAccountLinkedWrapper>
      )}

      {!isEmpty(facebookUserPagesData) && isExpanded && addConnectionIsSelected && (
        <StyledContent>
          <StyledConfigContent>
            {!isNil(locationConnectionInfo)
            && (
              <FacebookLoggedAccount
                handleFacebookLogout={handleFacebookLogout}
                facebookUserEmail={locationConnectionInfo.email}
              />
            )}
            <DropDownDialog
              classNameDropDownContainer="dropdown-facebook-accounts"
              title="Páginas"
              icon="Business"
              currentOption={facebookUserCurrentPages}
              options={facebookUserPagesData}
              googlePagination
              onResetClicked={handleResetDropDownDialog}
              nextPageToken={facebookUserCurrentPagesNextPage}
              onNextPageClicked={() => getFacebookUserAccountsData(
                facebookAccessToken,
                facebookUserCurrentPagesNextPage,
              )}
              onBackPageClicked={() => console.log('TODO')}
              onChange={handleChangeFacebookPage}
              googlePaginationLoading={facebookUserAccountsLoading}
              pageList={facebookPaginationPageList}
              setPageList={setFacebookPaginationPageList}
            />
          </StyledConfigContent>

          {!isNil(facebookUserCurrentPages) && !isNil(locationConnectionInfo) && (
            <LocationList
              data={facebookUserLocations}
              facebookAccessToken={facebookAccessToken}
              isAdminOrOwner={isAdminOrOwner}
              locationConnectionList={locationConnections}
              locationsFrom={facebookUserCurrentPages}
              pageList={facebookPaginationPageList}
              setLocationConnectionList={setLocationConnections}
              setPageList={setFacebookPaginationPageList}
              userAccessToken={userAccessToken}
              userId={userId}
              facebookUserEmail={locationConnectionInfo.email}
              userSetTokenLikeExpired={userSetTokenLikeExpired}
              locationsList={facebookUserCurrentSubPages}
              locationConnectionInfo={locationConnectionInfo}
            />
          )}
        </StyledContent>
      )}

      {!isEmpty(facebookUserPagesData) && isExpanded && viewConnectionsIsSelected && (
        <LocationConnectionList
          userAccessToken={userAccessToken}
          userProfileName={userProfileName}
          userId={userId}
          setLocationConnectionList={setLocationConnections}
          locationConnections={locationConnections}
          getLocationConnections={getLocationConnections}
          platform="Facebook"
        />
      )}
    </StyledFacebook>
  );
};

export default Facebook;
