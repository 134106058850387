import React from 'react';
import { IconButton } from '@material-ui/core';
import {
  Clear as ClearIcon,
  Edit as EditIcon,
} from '@material-ui/icons';

import type { TCard } from '../../../types/TResponsibleInfo';

import Loading from '../../loading';

import {
  StyledCard,
  StyledCardHeader,
  StyleCardHeaderControlls,
  StyledContent,
  StyledCheckBox,
  StyledOverlayLoading,
} from './card-styles';

const Card = ({
  checked,
  changeChecked,
  mainText,
  labels,
  labelContent,
  removeMainResponsible,
  editResponsible,
  enabledLoadingDelete,
  isSaveMainResponsible,
}: TCard) => {
  const [isDeleting, setIsDeletin] = React.useState(false);

  const handleDelete = () => {
    if (enabledLoadingDelete) setIsDeletin(true);
    removeMainResponsible();
  };

  return (
    <StyledCard
      checked={checked}
    >
      {(isDeleting || isSaveMainResponsible) && (
        <StyledOverlayLoading>
          <Loading />
          <span className="text">
            {isSaveMainResponsible && 'Gravando responsável principal...'}
            {isDeleting && 'Apagando...'}
            {(!isDeleting && !isSaveMainResponsible) && '...'}
          </span>
        </StyledOverlayLoading>
      )}

      <StyledCardHeader>
        <StyledCheckBox
          checked={checked}
          onChange={changeChecked}
          inputProps={{ 'aria-label': 'primary checkbox' }}
        />

        <span className="main-responsible-label">{mainText}</span>

        <StyleCardHeaderControlls>
          <IconButton
            className="icon-button"
            onClick={handleDelete}
          >
            <ClearIcon />
          </IconButton>
          <IconButton
            className="icon-button"
            onClick={editResponsible}
          >
            <EditIcon />
          </IconButton>
        </StyleCardHeaderControlls>
      </StyledCardHeader>
      <StyledContent>
        <div className="responsible-info">
          <span>
            {labels.name}
            :
          </span>
          {labelContent.name}
        </div>
        <div className="responsible-info">
          <span>
            {labels.email}
            :
          </span>
          {labelContent.email}
        </div>
        <div className="responsible-info">
          <span>
            {labels.phone}
            :
          </span>
          {labelContent.phone}
        </div>
      </StyledContent>
    </StyledCard>
  );
};

export default Card;
