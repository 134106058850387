import api from './api';

const facebookAPIGetPageInfos = async ({
  pageID,
  token,
  setIsFetching,
  tokenValidate,
}) => {
  const {
    snackbar,
    setConnected,
    setProfileHeader,
    resetSetFacebookData,
    FBLogout,
  } = tokenValidate;

  try {
    if (setIsFetching) setIsFetching(true);

    const response = await api({
      method: 'GET',
      url: `${pageID}?fields=location,name,website,phone,category_list,emails,about,hours,link`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    const {
      link,
      name,
      phone,
      website,
      location,
      category_list: categoriesList,
      description,
      emails,
      about: shortDescription,
      hours,
    } = await response;

    const categories = categoriesList.map(category => category.name);

    const mountHours = {
      monday: `${hours?.mon_1_open || ''} - ${hours?.mon_1_close || ''}`,
      tuesday: `${hours?.tue_1_open || ''} - ${hours?.tue_1_close || ''}`,
      wednesday: `${hours?.wed_1_open || ''} - ${hours?.wed_1_close || ''}`,
      thursday: `${hours?.thu_1_open || ''} - ${hours?.thu_1_close || ''}`,
      friday: `${hours?.fri_1_open || ''} - ${hours?.fri_1_close || ''}`,
      saturday: `${hours?.sat_1_open || ''} - ${hours?.sat_1_close || ''}`,
      sunday: `${hours?.sun_1_open || ''} - ${hours?.sun_1_close || ''}`,
    };

    return {
      link,
      name,
      phone,
      website,
      categories: categories.join(', '),
      description,
      email: emails ? emails[0] : null,
      shortDescription,
      hours: mountHours,
      ...location,
    };
  } catch (err) {
    if (err?.response?.status === 401) {
      snackbar('Seu acesso a conta do Facebook expirou. Faça login novamente!', { variant: 'warning' });
      setConnected(false);
      setProfileHeader(null);
      resetSetFacebookData();
      FBLogout();
    } else {
      snackbar('Seu acesso a conta do Facebook expirou. Faça login novamente!', { variant: 'error' });

      console.log(err);
    }
  } finally {
    if (setIsFetching) setIsFetching(false);
  }
};

export default facebookAPIGetPageInfos;
