import axios from 'axios';

export default async function getLocationGroupGuests({
  accessToken,
  locationGroupId,
  page = 0,
  pageSize = 10,
  query = '',
}) {
  const requestOptions = {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  };

  const { data } = await axios.get(
    `${process.env.REACT_APP_GUESTS_API_URL}/location-group/${locationGroupId}?page=${page}&pageSize=${pageSize}&query=${query}`,
    requestOptions,
  );

  return data;
}
