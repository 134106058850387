import axios from 'axios';

export default async function getCompanyLinkModels(accessToken, id) {
  const requestOptions = {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  };

  try {
    const { data } = await axios.get(
      `${process.env.REACT_APP_COMPANIES_API_URL}/get-relation/link_models/${id}`,
      requestOptions,
    );

    return data;
  } catch (err) {
    return err;
  }
}
