import React, { useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import * as R from 'ramda';
import JSFileDownloader from 'js-file-downloader';
import { LoadScript as LoadGoogleMapsScript } from '@react-google-maps/api';

import APICreateLocation from '../../services/locations/createLocation';
import APIGetCoordinates from '../../services/getCoordinates';

import SpreadSheetModelLocation from '../../assets/planilha-meus-locais.xlsx';

import APICreateBatchLocation from '../../services/locations/createBatchLocations';
import APICreateTicket from '../../services/tickets/create';

import APIGetAllContracts from '../../services/contract-types/getAllContractTypes';

import {
  useCompany,
  useAuth,
} from '../../hooks';

import {
  formatContractList,
} from './helpers';

import {
  getActiveCompanyId,
} from '../../utils/active-company';
import isValidDataStudioUrl from '../../utils/is-valid-data-studio';

import {
  HeaderToolbar,
  BlockWithActionButton,
  LocationFormUncontrolled,
  BatchCreation,
  BatchCreationDialog,
  BatchCreationEnable,
  HealderToolbarAction,
  Select,
  EditMarker as EditGoogleMapsMarker,
  ChooseUserDialog,
} from '../../components';

import {
  StyledCreateLocation,
  StyledContent,
  StyledContentMain,
  StyledContentAside,
  StyledContractListWrapper,
} from './create-location-styles';
import { changeCompanyOwner } from '../../services/companies/changeCompanyOwner';
import { APICreateResponsible } from '../../services/responsibles';
import { CountryList } from '../../utils/country-list';

const CreateLocation = () => {
  const { push: routerPush } = useHistory();
  const { enqueueSnackbar } = useSnackbar();

  const {
    userId,
    userSetTokenLikeExpired,
    userAccessToken,
    userLanguage,
    userLocationLimit,
    userProfileName,
    userMainData,
  } = useAuth();

  const {
    activeCompany,
    activeCompanyId,
  } = useCompany();

  const locationFormRef = React.useRef(null);

  const [isCreating, setIsCreating] = React.useState(false);
  const [openImportModal, setOpenImportModal] = React.useState(false);
  const [isCreateBatchLocation, setIsCreateBatchLocation] = React.useState(false);
  const [isBatchCreation, setIsBatchCreation] = React.useState(false);
  const [selectedContract, setSelectedContract] = React.useState(null);
  const [contractList, setContractList] = React.useState([]);

  const [countryCode, setCountryCode] = React.useState('Brazil');

  const [editLatLng, setEditLatLng] = React.useState(null);
  const [latLng, setLatLng] = React.useState(null);
  const [isLoadingMap, setIsLoadingMap] = React.useState(false);
  const [isOpenMap, setIsOpenMap] = React.useState(false);

  const [userToOwnerCompany, setUserToOwnerCompany] = React.useState(null);
  const [openChooseUserDialog, setOpenChooseUserDialog] = React.useState(false);

  const handleCreateResponsible = useCallback(async () => {
    const { user } = userMainData;

    const { name, phone, email } = user;

    const newResponsible = await APICreateResponsible({
      accessToken: userAccessToken,
      email,
      feedbackMessage: enqueueSnackbar,
      name,
      phone: phone || '11 11111-1111',
      setTokenLikeExpired: userSetTokenLikeExpired,
      created_by: userId,
    });

    newResponsible.main = true;

    return newResponsible;
  }, []);

  const handleChangeCompanyOwner = React.useCallback(async () => {
    try {
      await changeCompanyOwner({
        companyId: activeCompanyId,
        ownerId: userToOwnerCompany.id,
        accessToken: userAccessToken,
        setTokenLikeExpired: userSetTokenLikeExpired,
      });
      enqueueSnackbar('Dono da empresa alterado com sucesso!', { variant: 'success'});
    } catch (error) {
      enqueueSnackbar('Erro ao alterar dono da empresa', { variant: 'error' })
      return error;
    } finally {
      setOpenChooseUserDialog(false);
    }
  });

  const onMapClicked = ({ latLng: { lat, lng } }) => {
    setEditLatLng({ lat: lat(), lng: lng() });
  };

  const handlePushToLocationsListing = () => routerPush('/my-locations');

  const handleSaveLocation = async ({ latLng = null }) => {
    const {
      fields,
      locationNameByGoogleRef,
      selectedServiceAreaPlaceInfosRef,
    } = locationFormRef.current || {};

    const {
      current: locationNameByGoogle,
    } = locationNameByGoogleRef;

    const locationNameValue = locationNameByGoogle.value;

    if (R.is(Object, locationNameByGoogle) && (R.isNil(locationNameValue) || R.isEmpty(locationNameValue))) {
      enqueueSnackbar('O nome do local é obrigatório', { variant: 'warning' });
      return;
    }

    const resetFromFields = fields.map(field => field.reset);
    const checksFromFields = fields.map(field => field.firedCheck);

    let dataFields = {};
    R.forEach(field => {
      const isFieldName = field.name === 'name';
      dataFields = {
        ...dataFields,
        [field.name]: isFieldName ? (locationNameValue || locationNameByGoogle) : field.getValue(),
      };
    }, fields);

    const dataStudio = dataFields['data-studio'];
    const dataStudioIsEmpty = R.isNil(dataStudio) || R.isEmpty(dataStudio);
    const isValidDataStudioLink = dataStudioIsEmpty
      ? true
      : isValidDataStudioUrl(dataStudio);
    if (!isValidDataStudioLink) {
      return enqueueSnackbar('Link do Data Studio inválido', {
        variant: 'error',
      });
    }

    if (checksFromFields.every(check => check(countryCode !== 'Brazil'))) {
      const placeInfos = selectedServiceAreaPlaceInfosRef?.current;
      const hasServiceArea = Array.isArray(placeInfos) && placeInfos?.length > 0;

      const googleServiceArea = hasServiceArea
        ? {
          businessType: dataFields['answer-at-that-address']
            ? 'CUSTOMER_AND_BUSINESS_LOCATION'
            : 'CUSTOMER_LOCATION_ONLY',
          places: {
            placeInfos,
          },
          regionCode: CountryList[countryCode],
        }
        : undefined;

      let coordinates = latLng;
      const address1 = `${dataFields.address}, ${dataFields['address-number']}`;
      const city = dataFields.city;
      if (!coordinates) {
        coordinates = await APIGetCoordinates({ address: `${address1}. ${city}` });
        if (R.isNil(coordinates) || !coordinates?.lat || !coordinates?.lng) {
          enqueueSnackbar('Não foi possível obter as coordenadas do local a partir do endereço. Selecione a localização no mapa.', {
            variant: 'error',
          });
          setIsOpenMap(true);
          return;
        }

        setLatLng(coordinates);
      }
      const { lat, lng } = coordinates;

      const newResponsible = await handleCreateResponsible();

      const newLocation = await APICreateLocation({
        accessToken: userAccessToken,
        address1,
        address2: dataFields['address-complement'],
        zip_code: dataFields.zipcode,
        city,
        data_studio: dataFields['data-studio'],
        state: dataFields['state-code'],
        country: countryCode,
        doesNotServeAtThisAddress: !dataFields['answer-at-that-address'],
        google_categories: '[]',
        facebook_categories: '[]',
        foursquare_categories: '[]',
        googleServiceArea,
        main_phone: dataFields['main-phone'],
        name: dataFields.name,
        neighborhood: dataFields.neighborhood,
        responsible: newResponsible,
        secondary_phone: dataFields['secondary-phone'],
        storeCode: dataFields['store-code'],
        email: dataFields.email,
        company_id: activeCompanyId,
        contract_type: selectedContract,
        other_links: '{}',
        latitude: lat,
        longitude: lng,
        feedbackMessage: enqueueSnackbar,
        setTokenLikeExpired: userSetTokenLikeExpired,
        setIsCreating,
        setOpenChooseUserDialog
      });

      if (R.isNil(newLocation)) return;

      const platformsSyncInitialStatus = {
        google: 'IN_PROGRESS',
        facebook: 'IN_PROGRESS',
        foursquare: 'IN_PROGRESS',
        yelp: 'IN_PROGRESS',
        linkedin: 'IN_PROGRESS',
      };

      APICreateTicket({
        accessToken: userAccessToken,
        changedData: {
          info_to_update: {
            ...dataFields,
            'answer-at-that-address': dataFields['answer-at-that-address'] ? 'Sim' : 'Não',
            google_service_area: googleServiceArea,
          },
          new: true,
          status: platformsSyncInitialStatus,
        },
        type: 'ACTIVATION',
        location_id: newLocation.id,
        created_by: userId,
      });

      resetFromFields.map(reset => reset());
      routerPush('/location/list');
    }
  };

  const onSaveMap = () => {
    setIsLoadingMap(true);
    if (!editLatLng) {
      enqueueSnackbar('Clique ou arraste o Pin para o local adequado.', { variant: 'error' });
      setIsLoadingMap(false);
      return;
    }
    setLatLng(editLatLng);
    setIsLoadingMap(false);
    handleSaveLocation({ latLng: editLatLng });
    setIsOpenMap(false);
  };

  const handleCreateBatchLocation = async (file) => {
    if (!file) {
      enqueueSnackbar('Nenhum arquivo foi inserido', { variant: 'warning' });
      return;
    }

    const createBatchResponse = await APICreateBatchLocation({
      accessToken: userAccessToken,
      locationsFile: file,
      companyId: getActiveCompanyId(activeCompany),
      feedbackMessage: enqueueSnackbar,
      setTokenLikeExpired: userSetTokenLikeExpired,
      userId,
      setIsCreatingLocations: setIsCreateBatchLocation,
    });

    if (createBatchResponse.status === 'OK') setTimeout(() => routerPush('/my-locations'), 500);
  };

  const downloadSpreadsheetModel = async () => {
    new JSFileDownloader({
      url: SpreadSheetModelLocation,
      filename: 'modelo-meus-locais.xlsx',
    });
  };

  const getContractTypes = React.useCallback(async () => {
    const getContractTypesResponse = await APIGetAllContracts({
      accessToken: userAccessToken,
    });

    if (R.isNil(getContractTypesResponse)) return;

    const formattedContractList = formatContractList(getContractTypesResponse);
    const emptyContract = {
      name: 'Nenhum',
      id: 0,
    };

    setContractList([
      emptyContract,
      ...formattedContractList,
    ]);
  }, []);

  React.useEffect(() => { getContractTypes(); }, [getContractTypes]);

  return (
    <StyledCreateLocation>
      <HeaderToolbar
        dropdownsCompany
      >
        <HealderToolbarAction
          title="Lista de locais"
          icon="List"
          onClick={handlePushToLocationsListing}
        />
      </HeaderToolbar>
      <StyledContent>
        {!R.isNil(activeCompany) ? (
          <>
            <StyledContentMain>
              <LoadGoogleMapsScript
                googleMapsApiKey={process.env.REACT_APP_GOOGLE_MAPS_KEY}
              >
                <EditGoogleMapsMarker
                  isOpen={isOpenMap}
                  isLoading={isLoadingMap}
                  onClose={() => setIsOpenMap(false)}
                  latitude={editLatLng?.lat || -4.2195}
                  longitude={editLatLng?.lng || -38.04293}
                  onMapClicked={onMapClicked}
                  onSave={onSaveMap}
                  zoomLevel={18}
                />
              </LoadGoogleMapsScript>
              {isBatchCreation ? (
                <BatchCreation
                  title="Criar vários locais"
                  instructionTitle="Instruções para criação de vários locais"
                  downloadSpreadsheetModel={downloadSpreadsheetModel}
                  className="batch-creation"
                  handleImportModal={() => setOpenImportModal(!openImportModal)}
                />
              ) : (
                <>
                  <LocationFormUncontrolled
                    ref={locationFormRef}
                    ignoreNameField
                    setCountryCode={setCountryCode}
                    countryCode={countryCode}
                  />
                </>
              )}
            </StyledContentMain>
            <StyledContentAside>
              <BlockWithActionButton
                titleButton="Criar local"
                onClick={() => handleSaveLocation({ latLng })}
                loading={isCreating}
              />

              {userProfileName !== 'Usuário Padrão' && (
                <StyledContractListWrapper>
                  <Select
                    className="select-profile"
                    label="Tipo de Contrato"
                    value={selectedContract}
                    onChange={({ target }) => setSelectedContract(target.value)}
                    border
                    options={contractList}
                  />
                </StyledContractListWrapper>
              )}

              <BatchCreationEnable
                isBatchCreation={isBatchCreation}
                title="Você possui mais de um local?"
                disclaimer="Caso possua mais de um local você pode automatizar o processo de criação"
                titleButton="Automatizar"
                onClick={() => setIsBatchCreation(!isBatchCreation)}
              />
            </StyledContentAside>
          </>
        ) : <span>Selecione uma empresa para o cadastro do local</span>}
      </StyledContent>

      {openImportModal && (
        <BatchCreationDialog
          open={openImportModal}
          handleOnClose={() => setOpenImportModal(!openImportModal)}
          handleSubmit={handleCreateBatchLocation}
          isSubmit={isCreateBatchLocation}
        />
      )}
      <ChooseUserDialog
        open={openChooseUserDialog}
        onCancel={() => setOpenChooseUserDialog(false)}
        onConfirm={handleChangeCompanyOwner}
        selectedUser={userToOwnerCompany}
        setSelectedUser={setUserToOwnerCompany}
        disclaimer="Deseja selecionar outro usuário como dono dessa empresa?"
      />
    </StyledCreateLocation>
  );
};

export default CreateLocation;
