import axios from 'axios';

export default async function deleteCompany({
  accessToken,
  id,
  snackbar,
  isDeleting,
  setTokenLikeExpired,
}) {
  const requestOptions = {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  };

  try {
    if (isDeleting) isDeleting(true);
    if (snackbar) snackbar('Deletando empresa...', { variant: 'warning' });

    const { data } = await axios.delete(
      `${process.env.REACT_APP_COMPANIES_API_URL}/${id}`,
      requestOptions,
    );

    if (data) snackbar('Empresa deletada com sucesso!', { variant: 'success' });

    return true;
  } catch (err) {
    const { response } = err;

    if (response?.status === 401 && setTokenLikeExpired) setTokenLikeExpired();
    if (snackbar) snackbar('Não foi possível deletar o registro. Tente novamente!', { variant: 'error' });

    return false;
  } finally {
    if (isDeleting) isDeleting(false);
  }
}
