import styled, { css } from 'styled-components';
import { Tab } from '@material-ui/core';
import { shade } from 'polished';

export const StyledTab = styled(Tab)`
  background: ${props => shade(0.05, props.theme.colors.headerToolbarBg)};
  transition: all 0.5s;

  &:hover {
    background: ${props => shade(0.3, props.theme.colors.primary)};

    .MuiTab-wrapper {
      color: ${props => props.theme.colors.white};
    }
  }

  &:focus {
    outline: none;
  }

  ${props => props.isActive && css`
    background: ${shade(0.2, props.theme.colors.primary)};

    .MuiTab-wrapper {
      color: ${props.theme.colors.white};
    }
  `};

  ${props => props.roundedFirst && css`
    border-top-left-radius: 0.3rem;
  `};

  ${props => props.roundedLast && css`
    border-top-right-radius: 0.3rem;
  `};
`;
