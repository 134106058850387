import axios from 'axios';

type TUpdateWorkingHoursTimesRange = {
  accessToken: string;
  open: string;
  close: string;
  idTimeRange: any;
  setTokenLikeExpired?: () => void;
  isCreateWorkingHours?: any,
};

export default async function updateWorkingHoursTimesRange({
  accessToken,
  open,
  close,
  idTimeRange,
  setTokenLikeExpired,
  isCreateWorkingHours = null,
}: TUpdateWorkingHoursTimesRange) {
  const requestOptions = {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  };

  try {
    if (isCreateWorkingHours) isCreateWorkingHours(true);

    const { data } = await axios.put(
      `${process.env.REACT_APP_TIME_API_URL}/${idTimeRange}`,
      {
        open,
        close,
      },
      requestOptions,
    );

    return data;
  } catch (err: any) {
    if (err.response?.status === 401 && setTokenLikeExpired) return setTokenLikeExpired();

    return null;
  } finally {
    if (isCreateWorkingHours) isCreateWorkingHours(false);
  }
}
