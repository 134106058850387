import React from 'react';
import { useDispatch } from 'react-redux';

import { TDropdownLocationGroupsDialog } from '../../types/TDropdownLocationGroupsDialog';

import { Creators as LocationGroupActions } from '../../redux/ducks/location-group';

import DropDownDialog from '../dropdown-dialog';

import { useCompany, useLocationGroups } from '../../hooks';

const DropdownLocationGroupsDialog = ({
  overlayLoadingNoDisplay,
}: TDropdownLocationGroupsDialog) => {
  const reduxDispatch = useDispatch();

  const {
    setUserCompaniesPageQuery,
    setUserCompaniesPageIndex,
  } = useCompany();

  const {
    locationGroups,
    activeLocationGroup,
    page,
    pageQuantity,
  } = useLocationGroups();

  const [isLoading, setIsLoading] = React.useState(false);

  const handleChangeCompany = async (locationGroupId: number) => {
    setIsLoading(true);
    const newActiveLocationGroup = locationGroups.filter(companyItem => companyItem.id === locationGroupId)[0];

    reduxDispatch(LocationGroupActions.setActiveLocationGroup(newActiveLocationGroup));

    setTimeout(() => {
      setIsLoading(false);
    }, 1500);
  };

  return (
    <DropDownDialog
      options={locationGroups}
      currentOption={activeLocationGroup}
      companyType={false}
      locationGroupType
      icon="Business"
      title="Grupos de Locais"
      onChange={handleChangeCompany}
      optionDisableIfNotHaveLocations
      page={page}
      setPage={setUserCompaniesPageIndex}
      pageQuantity={pageQuantity}
      setQuery={setUserCompaniesPageQuery}
      isLoading={isLoading}
      showPagination
      overlayLoadingNoDisplay={overlayLoadingNoDisplay}
    />
  );
};

export default DropdownLocationGroupsDialog;
