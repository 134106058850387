import { format } from 'date-fns';
import { useSnackbar } from 'notistack';
import React, { useMemo, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useDispatch } from 'react-redux';
import { Table } from '../../components';
import { useAuth } from '../../hooks';
import { initialDateColumns } from './helpers';
import ProfileHistoryRow from './profile-history-row';

import { Creators as AuthActions } from '../../redux/ducks/auth';

import {
  StyledContent,
  StyledHistory, StyledUserProfile
} from './user-profile-styles';

import { getLoginHistoryFromUser } from '../../services/login-history/get-login-history-from-user';
import PasswordInfo from './password-info';
import PersonalInfo from './personal-info';
import ProfileHeader from './profile-header';

const UserProfile = () => {
  const user = useAuth();
  const { enqueueSnackbar } = useSnackbar();
  const dispatchRedux = useDispatch();
  const [isLoading, setIsLoading] = useState(false);

  const [loginHistory, setLoginHistory] = useState([]);
  const [page, setPage] = React.useState(0);
  const [pageSize, setPageSize] = React.useState(10);
  const [loginHistoryCount, setLoginHistoryCount] = React.useState(0);

  const tableColumns = useMemo(
    () => [...initialDateColumns], [initialDateColumns],
  );

  const tableOptions = useMemo(() => ({
    count: loginHistoryCount,
    selectableRowsHideCheckboxes: true,
    search: false,
    sort: false,
    draggableColumns: { enabled: false },
    page,
  }), [loginHistoryCount, page]);

  const formattedTableData = React.useCallback((data) => data.map((item) => [
    { columnRef: 'id', visible: false, value: item.id },
    { columnRef: 'created_at', visible: true, value: format(new Date(item.created_at), 'dd/MM/yyyy hh:mm:ss a') },
    { columnRef: 'ip_address', visible: true, value: item.ip_address },
    { columnRef: 'ip_location', visible: true, value: item.ip_location },
  ]), []);

  const getLoginHistory = React.useCallback(async () => {
    const loginHistoryResponse = await getLoginHistoryFromUser({
      userId: user.userId,
      accessToken: user.userAccessToken,
      page,
      pageSize,
      setTokenLikeExpired: user.userSetTokenLikeExpired,
    });

    if (!loginHistoryResponse) return;

    const [loginHistoryData, loginHistoryCount] = loginHistoryResponse;

    const loginHistoryDataFormatted = formattedTableData(loginHistoryData);

    setLoginHistoryCount(loginHistoryCount);
    setLoginHistory(loginHistoryDataFormatted);
  }, [user.userId, user.userAccessToken, page, pageSize]);

  React.useEffect(() => {
    getLoginHistory();
  }, [getLoginHistory]);

  return (
    <StyledUserProfile>
      <Helmet>
        <meta property="og:title" content="Perfil - SaaS Hublocal" />
        <meta
          property="og:description"
          content="Veja as informações do seu perfil."
        />
        <title>Perfil - SaaS Hublocal</title>
      </Helmet>

      <ProfileHeader
        {...user}
      />

      <StyledContent>
        <PersonalInfo
          {...user}
          feedbackMessage={enqueueSnackbar}
          dispatchRedux={dispatchRedux}
          AuthActions={AuthActions}
        />

        <PasswordInfo
          {...user}
          feedbackMessage={enqueueSnackbar}
          dispatchRedux={dispatchRedux}
          AuthActions={AuthActions}
        />
      </StyledContent>
      <StyledHistory>
        <Table
          count={loginHistoryCount}
          loading={isLoading}
          title="Histórico de Logins"
          data={loginHistory}
          columns={tableColumns}
          page={page}
          setPage={setPage}
          pageSize={pageSize}
          setPageSize={setPageSize}
          options={tableOptions}
          row={(rowData) => (
            <ProfileHistoryRow
              rowData={rowData}
              ronId={rowData[0]?.value}
            />
          )}
        />
      </StyledHistory>
    </StyledUserProfile>
  );
};

export default UserProfile;
