import React from "react";
import * as MaterialUIIcons from "@material-ui/icons/";

import { StyledSimpleLink } from "./simple-link-styles";

const SimpleLink = ({ 
  title, 
  url, 
  icon, 
  className, 
  closeMenuMobile,
  exitToApp
}) => {
  const SimpleLinkIcon = MaterialUIIcons[icon || "Help"];

  const handleCloseMenuMobile = () => {
    if(closeMenuMobile) closeMenuMobile(false);

    if(exitToApp) exitToApp();
  };

  return (
    <StyledSimpleLink 
      onClick={handleCloseMenuMobile} 
      className={className} 
      to={url || "/my-companies"}
    >
      <SimpleLinkIcon className="simple-link-icon" />
      <span>{title}</span>
    </StyledSimpleLink>
  );
};

export default SimpleLink;