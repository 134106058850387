import { Paper } from '@material-ui/core';
import styled from 'styled-components';

export const StyledContainer = styled.main`
  width: 100%;
  padding: 2rem;
`;

export const StyledTitle = styled.h2`
`;

export const StyledInfo = styled(Paper)`
  display: flex;
  padding: 2rem;
  align-items: center;
  margin-bottom: 2rem;
`;

export const StyledContent = styled.section`
  background-color: white;
  padding: 2rem;
`;
