import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory, Redirect } from 'react-router-dom';
import { Helmet } from 'react-helmet';

import { Can, DataTable } from '../../components';

import { StyledLocationsAsGuest, StyledHeader } from './locations-as-guest-styles';

import { Creators as AuthActions } from '../../redux/ducks/auth';
import { Creators as GuestRulesActions } from '../../redux/ducks/guest-rules';
import { Creators as LocationActions } from '../../redux/ducks/location';
import { Creators as CompanyActions } from '../../redux/ducks/company';

import getAllLocationsWhereUserIsGuest from '../../services/guest/getAllLocationsWhereUserIsGuest';
import getGuest from '../../services/guest/getOne';
import getLocation from '../../services/locations/getLocation';
import getCompany from '../../services/companies/getCompany';

const LocationsAsGuest = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [page, setPage] = React.useState(0);
  const [pageSize, setPageSize] = React.useState(10);
  const [data, setData] = React.useState([]);
  const [tableData, setTableData] = React.useState([]);
  const [query, setQuery] = React.useState('');

  const { user } = useSelector(state => state.AuthReducer);

  const columns = [
    {
      name: 'id',
      options: { display: false, sort: false },
    },
    { name: 'Local', options: { sort: false } },
    { name: 'Empresa', options: { sort: false } },
    { name: 'Função', options: { sort: false } },
  ];

  const setTokenLikeExpired = () => {
    dispatch(AuthActions.setUser({ ...user, accessToken: 'expired' }));
  };

  React.useEffect(() => {
    const fetchData = async () => {
      const guestsData = await getAllLocationsWhereUserIsGuest(
        {
          accessToken: user.accessToken,
          userId: user.user.id,
          page,
          pageSize,
          query,
          setTokenLikeExpired,
        },
      );
      setData(guestsData[0]);
      const guestDataNormalized = guestsData[0].map(guestData => [
        guestData.id,
        guestData.location.name,
        guestData.location.company.name,
        guestData.location_access_level_profile.name,
      ]);

      setTableData(guestDataNormalized);
    };
    fetchData();
  }, []);

  const onUpdateLocationClicked = async (id) => {
    const guest = await getGuest({ accessToken: user.accessToken, id });
    const location = await getLocation({ accessToken: user.accessToken, id: guest.location.id });
    const company = await getCompany(user.accessToken, location.company.id);
    dispatch(CompanyActions.setActiveCompany((company)));
    dispatch(GuestRulesActions.setGuestRules({ isOwner: false, ...JSON.parse(guest.location_access_level_profile.rules) }));
    dispatch(LocationActions.setActiveLocation(
      {
        ...location,
        facebook_page_data: JSON.parse(location.facebook_page_data),
      },
    ));

    const userGuestLocations = await getAllLocationsWhereUserIsGuest({
      accessToken: user.accessToken, userId: user.user.id, page: 0, pageSize: 20, query: '',
    });

    const formattedGuestLocations = [];
    userGuestLocations[0].map(element => {
      let formattedLocation = element.location;
      formattedLocation = { ...formattedLocation, location_access_level_profile: element.location_access_level_profile };
      formattedGuestLocations.push(formattedLocation);
    });

    dispatch(AuthActions.setGuestLocations(formattedGuestLocations));
    history.push('/location/profile');
  };

  return (
    <StyledLocationsAsGuest>
      <Helmet>
        <meta property="og:title" content="Locais como convidado - SaaS Hublocal" />
        <meta property="og:description" content="Gerencie locais como convidado." />

        <title>Locais como convidado - SaaS Hublocal</title>
      </Helmet>
      <Can
        perform="Locations/AsGuest"
        yes={() => (
          <>
            <StyledHeader>
              <h2>Listando todos os locais dos quais o usuário é convidado</h2>
            </StyledHeader>
            <DataTable
              title="Locais"
              columns={columns}
              data={tableData}
              clickable={false}
              onDeleteClicked={() => {}}
              onUpdateClicked={onUpdateLocationClicked}
            />
          </>
        )}
        no={() => <Redirect to="/my-companies" />}
      />

    </StyledLocationsAsGuest>
  );
};

export default LocationsAsGuest;
