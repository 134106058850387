import React from 'react';
import { useHistory } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';
import { useDispatch, useSelector } from 'react-redux';
import * as R from 'ramda';

import { useSnackbar } from 'notistack';
import { isEmpty } from 'lodash';
import { Creators as LocationActions } from '../../redux/ducks/location';
import { Creators as CompanyActions } from '../../redux/ducks/company';
import { Creators as LocationGroupActions } from '../../redux/ducks/location-group';
import { Creators as GuestRulesActions } from '../../redux/ducks/guest-rules';

import filterLocationOwnerOrGuest from '../../utils/filter-locations-owner-or-guest';

import APIGetLocation from '../../services/locations/getLocation';
import deleteLocation from '../../services/locations/deleteLocation';
import APICopyLocation from '../../services/locations/copyLocation';
import APICreateResponsible from '../../services/responsibles/createResponsible';
import AddOtherResponsibles from '../../services/locations/addOtherResponsibles';
import APIAddLocations from '../../services/companies/addLocations';
import APIGetAllContractTypes from '../../services/contract-types/getAllContractTypes';
import APIGetTicketFromLocation from '../../services/tickets/getAllFromLocation';
import APICreateTicket from '../../services/tickets/create';
import APIUpdateTicket from '../../services/tickets/update';
import APIGetUser from '../../services/users/getUser';

import APIGetUnlinkedLocationsFromCompany from '../../services/locations/getAllLocationsFromCompanyWithoutLink';

import {
  DataTable,
  ConfirmDialog,
  BatchHeader,
} from '../../components';

import {
  useAuth,
  useUncontrolledField,
} from '../../hooks';

import { fieldLocationName } from './field-scheme';

import {
  mountDataTableFormt,
  initialTableWidths,
  getColumnsToTable,
} from './helper';

import {
  StyledMyLocations,
  StyledContent,
} from './my-locations-styles';
import { LinkInfoDialogType } from '../../components/link-info-dialog/types';
import LinkInfoDialog from '../../components/link-info-dialog';

const MyLocations = () => {
  const reduxDispatch = useDispatch();
  const routerHistory = useHistory();
  const { enqueueSnackbar } = useSnackbar();

  const {
    userCompanies,
    activeCompany,
    activeCompanyId,
    userCompaniesLoading,
  } = useSelector(state => state.CompanyReducer);

  const {
    userAccessToken,
    userSetTokenLikeExpired,
    userId,
    userProfileName,
  } = useAuth();

  const [contractsList, setContractsList] = React.useState([]);
  const [contractFilter, setContractFilter] = React.useState(null);

  const [companyLocationsPage, setCompanyLocationsPage] = React.useState(0);
  const [companyLocationsPageSize, setCompanyLocationsPageSize] = React.useState(10);
  const [companyLocationsPageQuery, setCompanyLocationsPageQuery] = React.useState('');
  const [companyLocationsOrderByName, setCompanyLocationsOrderByName] = React.useState(null);
  const [companyLocationsOrderByContract, setCompanyLocationsOrderByContract] = React.useState(null);

  const [companyLocationsIsLoading, setCompanyLocationsIsLoading] = React.useState(true);
  const [companyLocations, setCompanyLocations] = React.useState([]);
  const [companyLocationsCount, setCompanyLocationsCount] = React.useState(0);
  const [rowWidths, setRowWidths] = React.useState(initialTableWidths);

  const [confirmLocationCopyDialog, setConfirmLocationCopyDialog] = React.useState(false);

  const [copyLocationName, setCopyLocationName] = React.useState([]);
  const [copyLocationInfo, setCopyLocationInfo] = React.useState({});
  const [copyLocationLoading, setCopyLocationLoading] = React.useState(false);
  const [linkedDialogOpen, setLinkedDialogOpen] = React.useState(false);
  const [unlinkedLocations, setUnlinkedLocations] = React.useState([]);

  const [companyLocationsColunsTable, setCompanyLocationsColunsTable] = React.useState([]);

  const getContractsList = React.useCallback(async () => {
    const getContractsListResponse = await APIGetAllContractTypes({
      accessToken: userAccessToken,
    });

    if (R.isNil(getContractsListResponse)) return;

    setContractsList(getContractsListResponse.map(contract => contract));
  }, []);

  React.useEffect(() => {
    getContractsList();
  }, [getContractsList]);

  const renderColumnToTable = React.useCallback(() => {
    const tableColumns = getColumnsToTable({ userProfile: userProfileName, contractsList });
    setCompanyLocationsColunsTable(tableColumns);
  }, [contractsList]);

  React.useEffect(() => {
    renderColumnToTable();
  }, [renderColumnToTable]);

  const getCompanyLocationsWithGuests = filterLocationOwnerOrGuest({
    currentCompany: activeCompany,
    userId,
    userProfileName,
    userAccessToken,
    companyLocationsPage,
    companyLocationsPageSize,
    companyLocationsPageQuery,
    companyLocationsOrderByName,
    companyLocationsOrderByContract,
    filterByContract: contractFilter,
    userSetTokenLikeExpired,
    setCompanyLocationsIsLoading,
    isDataTable: true,
    mountDataTable: mountDataTableFormt,
  });

  const { Field: LocationName } = useUncontrolledField({
    fieldScheme: fieldLocationName,
    saveIn: setCopyLocationName,
  });

  const getCompanyLocations = React.useCallback(async () => {
    if (userCompaniesLoading) return;
    if (R.isNil(activeCompanyId)) return;
    if (!R.isEmpty(companyLocationsPageQuery)) setCompanyLocationsPage(0);

    const getLocationListResponse = await getCompanyLocationsWithGuests();

    if (R.isNil(getLocationListResponse)) return;

    const { locationDataList, locationCount } = getLocationListResponse;

    setCompanyLocations(locationDataList);
    setCompanyLocationsCount(locationCount);
  }, [
    companyLocationsPage,
    companyLocationsPageSize,
    companyLocationsPageQuery,
    companyLocationsOrderByName,
    companyLocationsOrderByContract,
    contractFilter,
    userCompaniesLoading,
    activeCompanyId,
  ]);

  React.useEffect(() => { getCompanyLocations(); }, [getCompanyLocations]);

  const getTicketThisLocation = React.useCallback(async (id) => {
    if (R.isNil(id)) return [];

    const ticketsThisLocation = await APIGetTicketFromLocation({
      accessToken: userAccessToken,
      locationId: id,
      setTokenLikeExpired: userSetTokenLikeExpired,
    });

    if (R.isNil(ticketsThisLocation)) return [];

    if (R.isEmpty(ticketsThisLocation[0]) || R.isNil(ticketsThisLocation[0])) return [];

    const openedTickets = ticketsThisLocation[0].filter(
      (ticket) => ticket.status === 'OPEN',
    );

    return openedTickets;
  });

  const onDeleteLocationClicked = async (id) => {
    enqueueSnackbar('Removendo local...', { variant: 'warning' });
    const ticketsFromThisLocation = await getTicketThisLocation(id);

    try {
      if (!isEmpty(ticketsFromThisLocation)) {
        const ticketData = JSON.parse(ticketsFromThisLocation[0].data);

        const finishedStatus = {
          google: 'NO_ACCESS',
          facebook: 'NO_ACCESS',
          foursquare: 'NO_ACCESS',
          yelp: 'NO_ACCESS',
          linkedin: 'NO_ACCESS',
        };

        const { name, email, profiles } = await APIGetUser({
          accessToken: userAccessToken,
          id: process.env.REACT_APP_DEFAULT_TICKET_USER_ID,
          setTokenLikeExpired: userSetTokenLikeExpired,
        });

        await APIUpdateTicket({
          accessToken: userAccessToken,
          ticketId: ticketsFromThisLocation[0].id,
          dataToUpdate: {
            data: JSON.stringify({
              ...ticketData,
              status: finishedStatus,
            }),
            operator_id: process.env.REACT_APP_DEFAULT_TICKET_USER_ID,
            status: 'DONE',
            comment: JSON.stringify([{
              name,
              email,
              profile: profiles[0].name,
              comment: 'O local vinculado a este ticket foi removido, portanto este ticket foi encerrado.',
            }]),
          },
          setTokenLikeExpired: userSetTokenLikeExpired,
        });
      }

      const deleteLocationResponse = await deleteLocation({
        accessToken: userAccessToken,
        id,
        setTokenLikeExpired: userSetTokenLikeExpired,
      });

      if (deleteLocationResponse) getCompanyLocations();
      enqueueSnackbar('Local removido com sucesso!', { variant: 'success' });
    } catch (err) {
      enqueueSnackbar('Erro ao remover local, tente novamente!', { variant: 'error' });
    }
  };

  const onLocationReportClicked = React.useCallback(
    async (pushRedirectId) => {
      const getLocationResponse = await APIGetLocation({
        accessToken: userAccessToken,
        id: pushRedirectId,
      });

      if (R.isNil(getLocationResponse)) return;

      reduxDispatch(LocationActions.setActiveLocation(getLocationResponse));
      routerHistory.push('/location/report');
    },
    [userAccessToken],
  );

  const onUpdateLocationClicked = async (locationId) => {
    const location = await APIGetLocation({
      accessToken: userAccessToken,
      id: locationId,
      setTokenLikeExpired: userSetTokenLikeExpired,
    });

    reduxDispatch(GuestRulesActions.setGuestRules({ isOwner: true }));
    reduxDispatch(LocationActions.setActiveLocation({
      ...location,
      facebook_page_data: JSON.parse(location.facebook_page_data),
    }));
    reduxDispatch(LocationGroupActions.setShowLocationsByGroup(false));
    reduxDispatch(CompanyActions.setShowLocationsByCompany(true));

    routerHistory.push('/location/profile');
  };

  const onLinkLocationClicked = async (locationId) => {
    const location = await APIGetLocation({
      accessToken: userAccessToken,
      id: locationId,
      setTokenLikeExpired: userSetTokenLikeExpired,
    });

    reduxDispatch(GuestRulesActions.setGuestRules({ isOwner: true }));
    reduxDispatch(LocationActions.setActiveLocation({
      ...location,
      facebook_page_data: JSON.parse(location.facebook_page_data),
    }));
    reduxDispatch(LocationGroupActions.setShowLocationsByGroup(false));
    reduxDispatch(CompanyActions.setShowLocationsByCompany(true));

    routerHistory.push('/platforms-link-with-location');
  };

  const parseCategoriesFromData = ({
    google_categories,
    facebook_categories,
    foursquare_categories,
  }) => {
    const parsedGoogleCategories = JSON.parse(google_categories) || [];
    const parsedFacebookCategories = JSON.parse(facebook_categories) || [];
    const parsedFoursquareCategories = JSON.parse(foursquare_categories) || [];

    return {
      google_categories: parsedGoogleCategories.map(category => category.name).join(', '),
      facebook_categories: parsedFacebookCategories.map(category => category.name).join(', '),
      foursquare_categories: parsedFoursquareCategories.map(category => category.name).join(', '),
    };
  };

  const handleCopyLocation = async () => {
    copyLocationName[0].setDefaultValue(copyLocationName[0].getValue());

    setCopyLocationLoading(true);
    enqueueSnackbar('Copiando Local...', { variant: 'warning' });

    const {
      name: responsibleName,
      email: responsibleEmail,
      phone: responsiblePhone,
    } = copyLocationInfo.responsible;

    const {
      google_categories,
      facebook_categories,
      foursquare_categories,
    } = parseCategoriesFromData(copyLocationInfo);

    const newResponsibleResponse = await APICreateResponsible({
      accessToken: userAccessToken,
      created_by: userId,
      setTokenLikeExpired: userSetTokenLikeExpired,
      name: responsibleName,
      phone: responsiblePhone,
      email: responsibleEmail,
    });

    if (R.isNil(newResponsibleResponse)) return;

    const newLocation = await APICopyLocation({
      accessToken: userAccessToken,
      setTokenLikeExpired: userSetTokenLikeExpired,
      feedbackMessage: enqueueSnackbar,
      name: copyLocationName[0].getValue(),
      infoToCopy: copyLocationInfo,
      company_id: activeCompanyId,
    });

    if (R.isNil(newLocation)) return;

    if (!R.isEmpty(copyLocationInfo.other_responsibles)) {
      await AddOtherResponsibles({
        accessToken: userAccessToken,
        locationId: newLocation.id,
        setTokenLikeExpired: userSetTokenLikeExpired,
        responsibleIds: copyLocationInfo.other_responsibles,
      });
    }

    const addToLocationCompany = await APIAddLocations({
      accessToken: userAccessToken,
      companyId: activeCompanyId,
      location_ids: [
        newLocation.id,
      ],
      setTokenLikeExpired: userSetTokenLikeExpired,
      feedbackMessage: enqueueSnackbar,
    });

    if (R.isNil(addToLocationCompany)) return;

    const platformsSyncInitialStatus = {
      google: 'IN_PROGRESS',
      facebook: 'IN_PROGRESS',
      foursquare: 'IN_PROGRESS',
      yelp: 'IN_PROGRESS',
      linkedin: 'IN_PROGRESS',
    };

    const createTicketResponse = await APICreateTicket({
      accessToken: userAccessToken,
      changedData: {
        info_to_update: {
          ...copyLocationInfo,
          facebook_categories,
          google_categories,
          foursquare_categories,
          id: newLocation.id,
          does_not_serve_at_this_address: copyLocationInfo.does_not_serve_at_this_address ? 'Sim' : 'Não',
          is_active: null,
          responsible: newResponsibleResponse,
        },
        new: true,
        status: platformsSyncInitialStatus,
      },
      type: 'ACTIVATION',
      location_id: newLocation.id,
      created_by: userId,
    });

    enqueueSnackbar('Local Copiado com sucesso!', { variant: 'success' });
    setCopyLocationLoading(false);
    setConfirmLocationCopyDialog(false);
    getCompanyLocations();
  };

  const handleConfirmDialogOpen = async (rowId) => {
    setConfirmLocationCopyDialog(true);

    const getLocationToCopyResponse = await APIGetLocation({
      accessToken: userAccessToken,
      id: rowId,
      setTokenLikeExpired: userSetTokenLikeExpired,
    });

    if (R.isNil(getLocationToCopyResponse)) return;

    setCopyLocationInfo({
      ...getLocationToCopyResponse,
      id: null,
    });

    copyLocationName[0].setDefaultValue(`Cópia de ${getLocationToCopyResponse.name}`);
    copyLocationName[0].setCurrentValue(`Cópia de ${getLocationToCopyResponse.name}`);
  };

  const handleConfirmDialogClose = () => {
    setConfirmLocationCopyDialog(false);
  };

  const handleChangedColumns = (changedColumn) => {
    let currentNameIndex = 2;

    setCompanyLocationsColunsTable(prevState => prevState.map(column => {
      if (column.name === 'Id' && changedColumn === 'Id') {
        currentNameIndex = !column.options.display ? 3 : 2;

        return {
          ...column,
          options: { display: !column.options.display },
        };
      }

      return column;
    }));

    setRowWidths({
      [`&:nth-child(${currentNameIndex})`]: {
        width: '30%',
      },
    });
  };

  const handleChangeFilter = async (changedColumn, filterValue) => {
    if (changedColumn === 'Contrato') {
      if (!R.isEmpty(filterValue[2])) {
        const findContractByNameResponse = contractsList.filter(contract => filterValue[2].includes(contract.name)).map(contract => contract.id);

        if (R.isNil(findContractByNameResponse)) {
          setContractFilter(null);
          return;
        }

        setContractFilter(findContractByNameResponse);
        return;
      }

      setContractFilter(null);
      return;
    }

    if (R.isNil(changedColumn)) {
      setContractFilter(null);
    }
  };

  const onSortLocationsClicked = (changedColumn, direction) => {
    if (changedColumn === 'Nome') {
      setCompanyLocationsOrderByContract(null);
      setCompanyLocationsOrderByName(direction.toUpperCase());
    }

    if (changedColumn === 'Contrato') {
      setCompanyLocationsOrderByName(null);
      setCompanyLocationsOrderByContract(direction.toUpperCase());
    }
  };

  const getMuiTheme = () => createMuiTheme({
    overrides: {
      MUIDataTableHeadCell: {
        root: rowWidths,
      },
    },
  });

  const getUnlinkedLocationFromCompany = React.useCallback(async () => {
    const getUnlinkedLocationsFromCompanyResponse = await APIGetUnlinkedLocationsFromCompany({
      accessToken: userAccessToken,
      companyId: activeCompany.id,
    });

    if (R.isNil(getUnlinkedLocationsFromCompanyResponse)) return;

    const [unlinkedLocationsWithoutCount] = getUnlinkedLocationsFromCompanyResponse;

    if (R.isEmpty(unlinkedLocationsWithoutCount)) return;

    setLinkedDialogOpen(true);
    setUnlinkedLocations(getUnlinkedLocationsFromCompanyResponse);
  }, [activeCompany]);

  React.useEffect(() => { getUnlinkedLocationFromCompany(); },
    [getUnlinkedLocationFromCompany]);

  return (
    <StyledMyLocations
      isEmptyCompanies={R.isEmpty(userCompanies)}
    >
      <Helmet>
        <meta property="og:title" content="Meus locais - SaaS Hublocal" />
        <meta property="og:description" content="Gerencie seus locais." />

        <title>Meus locais - SaaS Hublocal</title>
      </Helmet>

      <ConfirmDialog
        open={confirmLocationCopyDialog}
        onClose={handleConfirmDialogClose}
        onCancel={handleConfirmDialogClose}
        title="Cópia de Local"
        onConfirm={() => handleCopyLocation()}
        confirmTitle="Confirmar"
        confirmType="blue"
        isLoading={copyLocationLoading}
      >
        <LocationName />
      </ConfirmDialog>

      {linkedDialogOpen && !isEmpty(unlinkedLocations) && (
        <LinkInfoDialog
          onClose={() => setLinkedDialogOpen(false)}
          isDialogOpen={linkedDialogOpen}
          onConfirm={() => {
            routerHistory.push('/platforms-link-with-location/');
            setLinkedDialogOpen(false);
          }}
          type={LinkInfoDialogType.companyWithUnlinked}
          unlinkedLocations={unlinkedLocations}
        />
      )}

      {activeCompany && (
        <BatchHeader isMyLocations />
      )}

      <StyledContent>
        <MuiThemeProvider theme={getMuiTheme()}>
          <DataTable
            count={companyLocationsCount}
            page={companyLocationsPage}
            setPage={setCompanyLocationsPage}
            pageSize={companyLocationsPageSize}
            setPageSize={setCompanyLocationsPageSize}
            searchQuery={companyLocationsPageQuery}
            setSearchQuery={setCompanyLocationsPageQuery}
            loading={companyLocationsIsLoading}
            title="Locais"
            data={companyLocations}
            columns={companyLocationsColunsTable}
            clickable={false}
            onUpdateClicked={onUpdateLocationClicked}
            onLinkClicked={onLinkLocationClicked}
            onDeleteClicked={onDeleteLocationClicked}
            onReportClicked={onLocationReportClicked}
            addRegisterLabel="Adicionar Local"
            addRegisterLink="/locations/create"
            emptyTableTitle="Esta empresa não tem nenhum local cadastrado"
            emptyTableTitleButton="Adicionar local"
            copyLocationAllow
            onConfirmCopyLocationDialogClicked={confirmLocationCopyDialog}
            onOpenCopyLocationDialog={handleConfirmDialogOpen}
            onSortClicked={onSortLocationsClicked}
            handleChangedColumns={handleChangedColumns}
            handleChangeFilter={handleChangeFilter}
            isLocationTable
            filterList={contractsList}
            userProfileName={userProfileName}
          />
        </MuiThemeProvider>
      </StyledContent>
    </StyledMyLocations>
  );
};

export default MyLocations;
