import React from 'react';
import * as R from 'ramda';

import {
  StyledGuests,
  StyledGuestsList,
} from './guests-styles';

import { StyledTitle } from '../guest-list-styles';

import LocationGuest from './location-guest';
import CompanyGuest from './company-guest';

const Guests = ({
  data,
  guestAccessLevelProfiles,
  refreshGuestListListener,
  userAccessToken,
  userSetTokenLikeExpired,
  getGuestsList,
}) => (
  <StyledGuests>
    <StyledTitle>
      <h3>Convidados:</h3>
    </StyledTitle>

    <StyledGuestsList>
      {R.isNil(guestAccessLevelProfiles) ? (
        data.map(guest => (
          <CompanyGuest
            guestId={guest.id}
            userName={guest.user.name}
            userEmail={guest.user.email}
            refreshGuestListListener={refreshGuestListListener}
            userAccessToken={userAccessToken}
            userSetTokenLikeExpired={userSetTokenLikeExpired}
            getGuestsList={getGuestsList}
          />
        ))
      ) : (
        data.map(guest => (
          <LocationGuest
            key={guest.user.id}
            guestId={guest.id}
            userName={guest.user.name}
            userEmail={guest.user.email}
            profileName={guest.location_access_level_profile.name}
            profileId={guest.location_access_level_profile.id}
            guestAccessLevelProfiles={guestAccessLevelProfiles}
            userAccessToken={userAccessToken}
            userSetTokenLikeExpired={userSetTokenLikeExpired}
            getGuestsList={getGuestsList}
          />
        ))
      )}
    </StyledGuestsList>
  </StyledGuests>
);

export default Guests;
