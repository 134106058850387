import styled, { css } from 'styled-components';
import {
  StarBorder as StarIcon,
  StarRate as IsActiveStar,
} from '@material-ui/icons';

const starHover = css`
  transform: scale(1.1);
`;

export const StyledStar = styled(StarIcon)`
  width: 4rem;
  height: 4rem;
  cursor: pointer;
  transition: .2s;

  &:hover {
    ${starHover}
  }
`;

export const StyledIsActiveStar = styled(IsActiveStar)`
  width: 4rem;
  height: 4rem;
  cursor: pointer;
  transition: .2s;
  fill: ${props => props.theme.colors.god};

  &:hover {
    ${starHover}
  }
`;
