import axios, { AxiosResponse } from 'axios';
import type { TUpdateServiceItems, TServiceItem } from '../../../types/TLocationService';

const updateServiceItems = async ({
  userAccessToken,
  locationId,
  serviceItems,
  googleAccessToken,
  isFetching,
}: TUpdateServiceItems): Promise<{
  status: 'SUCCESS' | 'ERROR',
  data: TServiceItem[],
}> => {
  try {
    const requestOptions = {
      headers: {
        Authorization: `Bearer ${userAccessToken}`,
      },
    };

    if (isFetching) isFetching(true);

    const { data }: AxiosResponse<TServiceItem[]> = await axios.patch(
      `${process.env.REACT_APP_GOOGLE_BUSINESS_PROFILE_API_URL}/location/${locationId}/serviceItems`,
      {
        serviceItems,
        googleAccessToken,
      },
      requestOptions,
    );

    return {
      status: 'SUCCESS',
      data,
    };
  } catch (err: any) {
    return {
      status: 'ERROR',
      data: err?.response,
    };
  } finally {
    if (isFetching) isFetching(false);
  }
};

export default updateServiceItems;
