import axios from 'axios';

export default async function getPlaceDetails(
  placeId, key,
) {
  try {
    const { data } = await axios.get(
      `${process.env.REACT_APP_GOOGLE_API_URL}/search/location/details?key=${key}&place-id=${placeId}`,
    );

    return data;
  } catch (err) {
    return err.response;
  }
}
