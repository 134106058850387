import axios from 'axios';
import type { TServiceBatchLocation } from '../../../types/TServiceBatch';

export type TFindOneServiceBatchLocations = {
  serviceBatchLocationId: string;
  isFetching?: React.Dispatch<React.SetStateAction<boolean>>;
  userAccessToken: string;
};

type TSuccessResponse = {
  status: 'SUCCESS',
  data: TServiceBatchLocation;
};

type TErrorResponse = {
  status: 'ERROR',
  data: any;
};

export const findOneServiceBatchLocation = async ({
  serviceBatchLocationId,
  isFetching,
  userAccessToken,
}: TFindOneServiceBatchLocations): Promise<TSuccessResponse | TErrorResponse> => {
  try {
    if (isFetching) isFetching(true);

    const { data } = await axios.get(
      `${process.env.REACT_APP_SAAS_BATCH_GOOGLE_MODULES}/service-batch-locations/${serviceBatchLocationId}`,
      {
        headers: {
          Authorization: `Bearer ${userAccessToken}`,
        },
      }
    );
    return {
      status: 'SUCCESS',
      data,
    };
  } catch (err: any) {
    return {
      status: 'ERROR',
      data: err.response,
    };
  } finally {
    if (isFetching) isFetching(false);
  }
};
