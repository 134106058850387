import React from 'react';
import { isEmpty } from 'ramda';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { debounce, isNil } from 'lodash';

import Loading from '../loading';
import IconButton from '../icon-button';
import ConfirmDialog from '../confirm-dialog';

import { translations } from '../../translations';

import {
  StyledGroupDataTable,
  StyledCellCustomButtons,
  StyledEmptyLabel,
} from './group-data-table-styles';

import SubTable from './sub-table';
import { useAuth } from '../../hooks';

const GroupDataTable = ({
  setSearchQuery,
  page,
  setPage,
  pageSize,
  setPageSize,
  count,
  title = '',
  data,
  columns,
  subTableColumns,
  onUpdateClicked,
  onDeleteClicked,
  onCancelClicked,
  onPreviewClicked,
  firstOperationName = null,
  onFirstOperationClicked = null,
  clickable = false,
  onRowClickURL,
  loading,
  onRowClickOperation = null,
  noUpdate = false,
  className,
  subTableDataType,
  addRegisterLabel = 'Adicionar Registro',
  addRegisterLink,
  editable = true,
  statusTable = false,
  colspan = '6',
}) => {
  const history = useHistory();

  const { userProfileName } = useAuth();
  const { language } = useSelector((state) => state.AuthReducer);

  const { data_table: dataTableTranslations } = translations[language];

  const [dataRender, setDataRender] = React.useState(null);
  const [dialogDataTable, setDialogDataTable] = React.useState({
    open: false,
    onConfirm: () => {},
  });
  const [expandedRows, setExpandedRows] = React.useState([]);
  React.useEffect(() => {
    const selectedRows = [];
    data.forEach((rowDataItem, index) => {
      const row = expandedRows.find(item => item.id === rowDataItem[0]);
      const isSameIndex = index === row?.index;
      if (rowDataItem[4] === 'Processando' || (!isNil(row) && isSameIndex)) {
        selectedRows.push({ id: rowDataItem[0], index });
      }
    });
    setExpandedRows(selectedRows);
    setDataRender(data);
  }, [data]);

  const handleUpdate = (id) => {
    onUpdateClicked();
    setExpandedRows(prev => prev.filter(row => row.id !== id));
  };

  const dataColumns = [
    ...columns,
    {
      name: dataTableTranslations.operations,
      options: {
        customBodyRender: (value, meta) => (
          <StyledCellCustomButtons>
            {firstOperationName && (
              <IconButton
                icon="Store"
                tooltip={firstOperationName}
                onClick={() => {
                  onFirstOperationClicked(meta.rowData[0]);
                }}
              />
            )}
            {statusTable && (
              <IconButton
                icon="VisibilityOutlined"
                tooltip="Visualizar"
                onClick={() => {
                  onPreviewClicked(meta.rowData[0]);
                }}
              />
            )}
            {statusTable && (
              <IconButton
                icon="Refresh"
                tooltip="Atualizar"
                onClick={() => handleUpdate(meta.rowData[0])}
              />
            )}
            {statusTable
            && userProfileName !== 'Usuário Padrão'
            && (meta.rowData[4] === 'DONE' || meta.rowData[4] === 'ERROR')
            && !loading && (
              <IconButton
                icon="Delete"
                tooltip="Remover"
                onClick={() => {
                  onDeleteClicked(meta.rowData[0]);
                }}
              />
            )}
            {statusTable && userProfileName !== 'Usuário Padrão'
            && (meta.rowData[4] === 'PENDING' || meta.rowData[4] === 'PROCESSING')
            && !loading && (
            <IconButton
              icon="Cancel"
              tooltip="Cancelar"
              onClick={() => onCancelClicked(meta.rowData[0])}
            />
            )}

            {statusTable && meta.rowData[4] === 'DONE' && loading && (
              <Loading className="is-removing-batch" />
            )}

            {editable && (
              <IconButton
                icon="Create"
                tooltip="Editar"
                onClick={() => {
                  onUpdateClicked(meta.rowData[0]);
                }}
              />
            )}
          </StyledCellCustomButtons>
        ),
      },
    },
  ];

  const handleConfirmDialogClose = () => setDialogDataTable((prevState) => ({ ...prevState, open: false }));
  const handleSearchWithDebounce = debounce(
    (text) => setSearchQuery(text),
    500,
  );

  const rowsExpanded = React.useMemo(() => expandedRows.map(row => row.index), [expandedRows]);

  const options = {
    filter: false,
    sort: false,
    empty: true,
    viewColumns: false,
    search: false,
    print: false,
    download: false,
    expandableRows: true,
    expandableRowsHeader: false,
    expandableRowsOnClick: false,
    selectableRows: 'none',
    rowsExpanded,
    onRowExpansionChange: (rows) => {
      const currentExpandedRow = rows[0];
      const { index } = currentExpandedRow;
      const currentExpandedRowId = data[index]?.[0];
      setExpandedRows((prev) => {
        const isRowAlreadySelected = prev.some((row) => row.id === currentExpandedRowId);

        const rowsAfterUnselectCurrent = prev.filter((row) => row.id !== currentExpandedRowId);
        const rowsAfterSelectCurrent = [...prev, { id: currentExpandedRowId, index }];
        return isRowAlreadySelected ? rowsAfterUnselectCurrent : rowsAfterSelectCurrent;
      });
    },
    renderExpandableRow: (rowData) => (
      <SubTable
        dataType={subTableDataType}
        className={className}
        rowId={rowData[0]}
        columns={subTableColumns}
        colspan={colspan}
        editable={false}
        batchPostSubtable={statusTable}
        onUpdateClicked={onUpdateClicked}
      />
    ),
    onSearchChange: (searchText) => {
      if (!searchText) {
        setSearchQuery('');
        return;
      }

      setPage(0);
      handleSearchWithDebounce(searchText);
    },
    onSearchClose: () => {
      setPage(0);
      setSearchQuery('');
    },
    serverSide: true,
    page,
    pageSize,
    onChangePage: (currentPage) => {
      setPage(currentPage);
    },
    onChangeRowsPerPage: (numberOfRows) => {
      setPage(0);
      setPageSize(numberOfRows);
    },
    rowsPerPageOptions: [2, 10, 100],
    count,
    elevation: 0,
    responsive: 'simple',
    draggableColumns: { enabled: false },
    onRowClick: clickable
      ? (rowData) => {
        onRowClickOperation(rowData);
        history.push(`${onRowClickURL}`);
      }
      : () => {},
    onRowsDelete(rowsDeleted) {
      const onDeleteRows = () => {
        rowsDeleted.data.map(({ dataIndex }) => {
          if (data[dataIndex].id) {
            onDeleteClicked(data[dataIndex].id);
            return;
          }

          const id = data[dataIndex][0];
          const functionName = data[dataIndex][1];
          onDeleteClicked(id, functionName);
        });

        handleConfirmDialogClose();
      };

      setDialogDataTable((prevState) => ({
        ...prevState,
        open: true,
        onConfirm: onDeleteRows,
      }));
    },
    textLabels: {
      body: {
        noMatch: loading ? (
          <Loading className="loading" />
        ) : (
          <StyledEmptyLabel>
            <h4>{dataTableTranslations.no_register}</h4>
          </StyledEmptyLabel>
        ),
        toolTip: dataTableTranslations.sort,
        columnHeaderTooltip: (column) => `${dataTableTranslations.sort_by} ${column.label}`,
      },
      pagination: {
        next: dataTableTranslations.next_page,
        previous: dataTableTranslations.previous_page,
        rowsPerPage: dataTableTranslations.rows_per_page,
        displayRows: dataTableTranslations.display_rows,
      },
      toolbar: {
        search: dataTableTranslations.search,
        downloadCsv: dataTableTranslations.download_csv,
        print: dataTableTranslations.print,
        viewColumns: dataTableTranslations.view_columns,
        filterTable: dataTableTranslations.filter_table,
      },
      filter: {
        all: dataTableTranslations.all_filters,
        title: dataTableTranslations.title_filters,
        reset: dataTableTranslations.reset_filters,
      },
      viewColumns: {
        title: dataTableTranslations.title_view_columns,
        titleAria: dataTableTranslations.title_aria_view_columns,
      },
      selectedRows: {
        text: dataTableTranslations.text_selected_rows,
        delete: dataTableTranslations.delete_selected_rows,
        deleteAria: dataTableTranslations.delete_aria_selected_rows,
      },
    },
  };

  return (
    <>
      <ConfirmDialog
        open={dialogDataTable.open}
        onClose={handleConfirmDialogClose}
        title="Listagem de empresas"
        onCancel={handleConfirmDialogClose}
        onConfirm={dialogDataTable.onConfirm}
        confirmTitle="Deletar"
        confirmType="red"
      >
        Tem certeza que deseja excluir?
      </ConfirmDialog>

      {dataRender && (
        <StyledGroupDataTable
          className={className}
          title={title}
          data={dataRender}
          columns={noUpdate ? columns : dataColumns}
          options={options}
        />
      )}
    </>
  );
};

export default GroupDataTable;
