import React from 'react';
import IconButton from '../../../icon-button';

import { iconByStatus } from './helpers';
import { TConnectionStatusIcon } from './types';

export default ({ status, iconSize }: TConnectionStatusIcon) => (
  <IconButton
    tooltip={iconByStatus[status].tooltip}
    icon={iconByStatus[status].icon}
    className="status-icon"
    isSuccess={iconByStatus[status].isSuccess}
    isWarning={iconByStatus[status].isWarning}
    isError={iconByStatus[status].isError}
    label={iconByStatus[status].label}
    tooltipVariant={iconByStatus[status].tooltipVariant}
    iconSize={iconSize}
  />
);
