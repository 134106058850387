import axios from 'axios';

export default async function createCompany({
  accessToken,
  name,
  description,
  website,
  data_studio = '',
  main_responsible,
  location_limit = 1000,
  is_active,
  created_by = 1,
  setTokenLikeExpired,
  feedbackMessage,
  setIsCreatingCompany,
}) {
  try {
    if (setIsCreatingCompany) setIsCreatingCompany(true);
    if (feedbackMessage) feedbackMessage('Criando empresa...', { variant: 'warning' });

    const { data } = await axios.post(
      `${process.env.REACT_APP_COMPANIES_API_URL}`,
      {
        name,
        description,
        website,
        data_studio,
        main_responsible,
        is_active,
        location_limit,
        created_by,
      },
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      },
    );

    if (feedbackMessage) feedbackMessage('Empresa criada com sucesso', { variant: 'success' });

    return data;
  } catch (err) {
    if (err.response?.status === 401 && setTokenLikeExpired) setTokenLikeExpired();

    return null;
  } finally {
    if (setIsCreatingCompany) setIsCreatingCompany(false);
  }
}
