import React from 'react';
import { useSelector } from 'react-redux';
import * as R from 'ramda';
import { useSnackbar } from 'notistack';

import createTicket from '../../../../services/tickets/create';
import putObject from '../../../../services/aws/putObject';
import type { TRootStateRedux } from '../../../../types/TRootStateRedux';
import APIBatchCreatePosts from '../../../../services/batch-update/batchCreatePosts';
import getValidGoogleAccessToken from '../../../../services/getValidGoogleAccessToken';

import DragAndDropFiles from '../../../../components/drag-and-drop-file';
import putObjectInAWSS3Bucket from '../../../../services/aws/putObject';
import patchNewsPost from '../../../../services/google/post/patchPost';

import type {
  TObjectInAWSS3BucketReturn,
  TLocationPostAddButtonType,
  TBatchPostFormNewsBaseProps,
} from '../../../../types/TLocationPost';

import { useAuth } from '../../../../hooks';

import { websiteValidate } from '../../../../utils/fields-validate';

import {
  getActionType,
  locationPostAddButtonsType,
  getActionTypeReverse,
  imagePostValidation,
} from '../../helpers';

import {
  isVideoFiles,
} from '../helpers';

import {
  Textarea,
  Select,
  Input,
  Button,
  Loading,
} from '../../../../components';

import {
  StyledPostFormNews,
  StyledAlertMessage,
} from './post-form-news-styles';

const PostFormNews = ({
  closeModal,
  dataToUpdate,
  affectedLocations,
  getLocalPosts,
}: TBatchPostFormNewsBaseProps) => {
  const { enqueueSnackbar } = useSnackbar();

  const {
    userAccessToken,
    userId,
    userName,
    userEmail,
    userProfile,
    userSetTokenLikeExpired,
  } = useAuth();

  const { activeLocationGroupId, showLocationsByGroup } = useSelector((state: TRootStateRedux) => state.LocationGroupReducer);
  const { activeCompanyId, showLocationsByCompany } = useSelector((state: TRootStateRedux) => state.CompanyReducer);

  const [newsStatusText, setNewsStatusText] = React.useState('');
  const [addedButtonType, setAddedButtonType] = React.useState('Nenhum');
  const [addedButtonTypeValue, setAddedButtonTypeValue] = React.useState<string>('');
  const [isPublish, setIsPublish] = React.useState(false);
  const [isAddedButton, setIsAddedButton] = React.useState(false);
  const [isAddedButtonPhoneType, setIsAddedButtonPhoneType] = React.useState(false);
  const [filesToPost, setFilesToPost] = React.useState<any[]>([]);

  const isValidInput = React.useCallback(() => {
    const actionType = getActionType(addedButtonType);

    if (R.isEmpty(newsStatusText)) {
      enqueueSnackbar('O texto da postagem não pode ser vazio', { variant: 'warning' });
      return false;
    }

    if (newsStatusText.length > 1500) {
      enqueueSnackbar('O texto da postagem não pode conter mais de 1500 caracteres', { variant: 'warning' });
      return false;
    }

    const isInvalidActionType = (
      !R.isNil(actionType)
      && actionType !== 'CALL'
    ) && R.isEmpty(addedButtonTypeValue.trim());

    if (isInvalidActionType) {
      enqueueSnackbar('A URL do botão de ação não pode ser vazio!', { variant: 'warning' });

      return false;
    }

    if (actionType && actionType !== 'CALL') {
      if ((!R.isEmpty(addedButtonTypeValue) || !R.isNil(addedButtonTypeValue))) {
        if (!websiteValidate(addedButtonTypeValue)) {
          enqueueSnackbar('O formato do link inserido é inválido!', { variant: 'warning' });
          return false;
        }
      }
    }
    return true;
  }, [
    newsStatusText,
    addedButtonType,
    addedButtonTypeValue,
  ]);

  const handleAddButton = (value: any) => {
    setAddedButtonType(value);

    if (value === 'Nenhum') setAddedButtonTypeValue('');
  };

  const handlePublishClicked = React.useCallback(async () => {
    const googleAccessToken = await getValidGoogleAccessToken({
      accessToken: userAccessToken,
      userId,
      userProfile,
    });

    const actionType = getActionType(addedButtonType);

    if (!isValidInput()) return;

    if (R.isNil(googleAccessToken)) {
      enqueueSnackbar('Não foi possível adquirir um token do Google para criar postagem', { variant: 'warning' });
      return;
    }

    setIsPublish(true);

    let mediaData: any = null;

    if (!R.isEmpty(filesToPost)) {
      mediaData = await Promise.all(filesToPost.map(async (fileToPost) => {
        const putObjectData = await putObject({
          accessToken: userAccessToken,
          bucketName: 'hub-saas-media',
          file: fileToPost,
          googleAccountId: null,
          googleLocationId: null,
          batchFile: true,
          mediaType: fileToPost.type === 'video/mp4' ? 'VIDEO' : 'PHOTO',
        });

        const mediaFileUrl = putObjectData.fileUrl;

        return {
          mediaFormat: fileToPost.type === 'video/mp4' ? 'VIDEO' : 'PHOTO',
          sourceUrl: mediaFileUrl,
        };
      }));
    }

    const postData = {
      media_url: R.isNil(mediaData) ? null : mediaData[0].sourceUrl,
      type: 'STANDARD',
      call_to_action_type: actionType,
      call_to_action_value: !R.isEmpty(addedButtonTypeValue) ? addedButtonTypeValue : null,
      summary: newsStatusText,
    };

    const createNewsPostResponse = await APIBatchCreatePosts({
      accessToken: userAccessToken,
      created_by: userId,
      groupType: showLocationsByCompany ? 'COMPANY' : 'LOCATIONS_GROUP',
      groupId: showLocationsByCompany ? activeCompanyId : activeLocationGroupId,
      locations: affectedLocations,
      postData,
      feedbackMessage: enqueueSnackbar,
      setIsLoading: setIsPublish,
      setTokenLikeExpired: userSetTokenLikeExpired,
      userData: {
        user_id: userId,
        user_name: userName,
        user_email: userEmail,
      },
    });

    if (R.isNil(createNewsPostResponse) || createNewsPostResponse.status === 406) return;

    getLocalPosts();
    closeModal();
  }, [
    userAccessToken,
    newsStatusText,
    filesToPost,
    addedButtonType,
    addedButtonTypeValue,
    showLocationsByCompany,
    activeCompanyId,
    activeLocationGroupId,
    affectedLocations,
    userId,
    userName,
    userEmail,
  ]);

  React.useEffect(() => {
    if (R.isNil(dataToUpdate)) return;

    const {
      media_url,
      call_to_action_type,
      call_to_action_value,
      type,
      summary,
    } = dataToUpdate.data_post;

    if (type !== 'STANDARD') return;

    if (summary) setNewsStatusText(summary);

    if (call_to_action_type) setAddedButtonType(getActionTypeReverse(call_to_action_type));
    if (call_to_action_value) setAddedButtonTypeValue(call_to_action_value);

    if (media_url) {
      setFilesToPost([{
        mediaFormat: 'PHOTO',
        sourceUrl: media_url,
      }]);
    }
  }, [dataToUpdate]);

  React.useEffect(() => {
    if (addedButtonType !== 'Nenhum' && addedButtonType !== 'Ligar agora') {
      setIsAddedButton(true);
      setIsAddedButtonPhoneType(false);
    }

    if (addedButtonType === 'Ligar agora') {
      setIsAddedButtonPhoneType(true);
      setIsAddedButton(false);
    }

    if (addedButtonType === 'Nenhum') {
      setIsAddedButton(false);
      setIsAddedButtonPhoneType(false);
    }
  }, [addedButtonType]);

  return (
    <StyledPostFormNews>
      {R.isNil(dataToUpdate) && (
        <DragAndDropFiles
          filesToPost={filesToPost}
          setFilesToPost={setFilesToPost}
          imageFileValidation={imagePostValidation}
          maxVideoSize={70}
          singleMode
          editable={R.isNil(dataToUpdate)}
        />
      )}

      <Textarea
        name="news-status"
        onChange={setNewsStatusText}
        label="Escrever Postagem *"
        border
        disabled={!R.isNil(dataToUpdate)}
        value={newsStatusText}
        className="news-status-textarea"
      />

      <Select<TLocationPostAddButtonType>
        label="Adicionar um botão (opcional)"
        value={addedButtonType}
        name="select-button-type"
        disabled={!R.isNil(dataToUpdate)}
        border
        onChange={({ target }) => handleAddButton(target.value)}
        options={locationPostAddButtonsType}
      />

      {(isAddedButton && !isAddedButtonPhoneType) && (
        <Input
          value={addedButtonTypeValue}
          disabled={!R.isNil(dataToUpdate)}
          border
          label="Link para o botão"
          onChange={(e) => setAddedButtonTypeValue(e.target.value)}
        />
      )}

      {R.isNil(dataToUpdate) && (
        <Button
          className="publish-button"
          disabled={isPublish}
          onClick={handlePublishClicked}
        >
          {isPublish && <Loading className="is-button-loading" />}
          Publicar
        </Button>
      )}
    </StyledPostFormNews>
  );
};

export default PostFormNews;
