import React from 'react';
import {
  StyledAvatar,
  StyledList,
  StyledListItem,
  StyledListItemAvatar,
  StyledListItemText,
  StyledListSecondaryAction,
} from './list-styles';

import IconButton from '../icon-button';

const List = ({
  data,
  primaryTextKey,
  secondaryTextKey = null,
  avatarKey = null,
  icon = null,
  onClick = null,
  buttonTooltip = 'Sem título',
  buttonTooltipClassName,
  tooltipVariant,
}) => (
  <StyledList>
    {data.map(element => (
      <StyledListItem
        key={element.id}
        isDisable={element.is_disable}
      >
        {avatarKey && (
          <StyledListItemAvatar>
            <StyledAvatar src={avatarKey && element[avatarKey]} />
          </StyledListItemAvatar>
        )}
        <StyledListItemText
          primary={element[primaryTextKey]}
          secondary={secondaryTextKey && element[secondaryTextKey]}
        />
        {(icon && !element.is_disable) && (
          <StyledListSecondaryAction>
            <IconButton
              icon={icon}
              onClick={() => onClick(element)}
              tooltip={buttonTooltip}
              tooltipVariant={tooltipVariant}
              className={buttonTooltipClassName}
            />
          </StyledListSecondaryAction>
        )}
      </StyledListItem>
    ))}
  </StyledList>
);

export default List;
