import React from 'react';
import { useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';
import { Divider } from '@material-ui/core';
import * as R from 'ramda';

import uploadMediaToLocationWithUrl from '../../../services/google/media/uploadMediaToLocationWithUrl';
import putObject from '../../../services/aws/putObject';
import getValidGoogleAccessTokenFromLocation from '../../../services/getValidGoogleAccessTokenFromLocation';

import type {
  TGalleryMediaFormat,
  TLocationGalleryMediaCategory,
} from '../../../types/TLocationGallery';

import {
  categories,
  platforms,
  mediaFormatByFileType,
  photoValidateObject,
} from './helpers';

import { useAuth } from '../../../hooks';

import {
  Select,
  DragAndDropFile,
  Button,
  Loading,
} from '../../../components';

import { TAddMediaForm } from '../../../types/TAddMediaForm';
import { TRootStateRedux } from '../../../types/TRootStateRedux';

import {
  StyledAddMediaForm,
  StyledDialog,
  StyledAddMediaFormContent,
  StyledPublishButton,
} from './add-media-form-dialog-styles';

const AddMediaFormDialog = ({
  open,
  setOpen,
  getLocationMediaList,
}: TAddMediaForm) => {
  const [loading, setLoading] = React.useState(false);
  const [filesToPost, setFilesToPost] = React.useState<any[]>([]);
  const [platform, setPlatform] = React.useState('Google');
  const [category, setCategory] = React.useState<TLocationGalleryMediaCategory>('CATEGORY_UNSPECIFIED');
  const [type, setType] = React.useState<TGalleryMediaFormat>('PHOTO');
  const [googleAccessToken, setGoogleAccessToken] = React.useState<string | null>(null);
  const [googleAccountId, setGoogleAccountId] = React.useState<string | null>(null);
  const [googleLocationId, setGoogleLocationId] = React.useState<string | null>(null);

  const { activeLocation } = useSelector((state: TRootStateRedux) => state.LocationReducer);

  const { userAccessToken, userId, userProfile } = useAuth();

  const { enqueueSnackbar } = useSnackbar();

  const getValidGoogleAccessToken = React.useCallback(async () => {
    const getValidGoogleAccessTokenResponse = await getValidGoogleAccessTokenFromLocation({
      accessToken: userAccessToken,
      userId,
      userProfile,
      locationId: activeLocation.id,
    });

    if (R.isNil(getValidGoogleAccessTokenResponse)) return;

    const { googleAccessToken: accessTokenGoogle, connectionInfo } = getValidGoogleAccessTokenResponse;

    setGoogleAccessToken(accessTokenGoogle);
    setGoogleAccountId(connectionInfo.google_account_id);
    setGoogleLocationId(connectionInfo.google_location_id);
  }, []);
  React.useEffect(() => { getValidGoogleAccessToken(); }, [getValidGoogleAccessToken]);

  const resetFormValues = () => {
    setFilesToPost([]);
    setCategory('CATEGORY_UNSPECIFIED');
  };

  const handleOnCloseModal = () => {
    setOpen(false);
  };

  React.useEffect(() => {
    if (R.isEmpty(filesToPost)) return;

    filesToPost.forEach(fileItem => {
      const {
        type: currentFileType,
      } = fileItem;

      const [, fileType] = currentFileType.split('/');
      const mediaFormatType = mediaFormatByFileType[fileType];

      setType(mediaFormatType);
    });
  }, [
    filesToPost,
    category,
  ]);

  const handleOnPublishClicked = async () => {
    if (filesToPost.length === 0) {
      enqueueSnackbar('Selecione um arquivo', { variant: 'warning' });
      return;
    }

    if (!platform) {
      enqueueSnackbar('Selecione uma plataforma', { variant: 'warning' });
      return;
    }

    if (!category) {
      enqueueSnackbar('Selecione uma categoria', { variant: 'warning' });
      return;
    }

    if (!type) {
      enqueueSnackbar('Selecione um tipo', { variant: 'warning' });
      return;
    }

    const fileData = await putObject({
      accessToken: userAccessToken,
      googleAccountId,
      googleLocationId,
      bucketName: 'hub-saas-media',
      file: filesToPost[0],
    });

    if (R.isNil(fileData)) {
      enqueueSnackbar('Não foi possível fazer o upload da mídia. Tente novamente!', { variant: 'success' });

      return;
    }

    if (R.isNil(googleAccessToken)) {
      enqueueSnackbar(
        'Não foi possível gerar um token de acesso para criar a mídia. Tente novamente!',
        { variant: 'warning' },
      );

      return;
    }

    const uploadMediaResponse = await uploadMediaToLocationWithUrl({
      accountId: googleAccountId,
      locationId: googleLocationId,
      accessToken: googleAccessToken,
      mediaFormat: type,
      sourceUrl: fileData.fileUrl,
      category,
      isUpload: setLoading,
    });

    if (uploadMediaResponse?.error === 'Not Acceptable') {
      enqueueSnackbar(
        uploadMediaResponse.message,
        { variant: 'warning' },
      );

      return;
    }

    if (R.isNil(uploadMediaResponse)) {
      enqueueSnackbar(
        'Não foi possível adicionar essa mídia. Tente novamente!',
        { variant: 'error' },
      );

      handleOnCloseModal();

      return;
    }

    resetFormValues();

    enqueueSnackbar('Mídia criada com sucesso', { variant: 'success' });
    getLocationMediaList();
    handleOnCloseModal();
  };

  return (
    <StyledDialog
      open={open}
      aria-labelledby="simple-dialog-title"
      onClose={handleOnCloseModal}
    >
      <StyledAddMediaForm>
        <p>Adicionar Mídia</p>
        <Divider />
        <StyledAddMediaFormContent>
          <Select
            name="platforms"
            border
            disabled
            hasPlaceholder
            placeholder="Selecione uma Plataforma"
            label="Plataformas"
            options={platforms}
            value={platform}
            onChange={({ target: { value } }) => setPlatform(value)}
          />
          <Select
            name="categories"
            border
            hasPlaceholder
            placeholder="Seleciona uma Categoria"
            label="Categoria"
            options={categories}
            value={category}
            onChange={({ target: { value } }) => {
              setCategory(value as TLocationGalleryMediaCategory);
            }}
          />

          <DragAndDropFile
            filesToPost={filesToPost}
            setFilesToPost={setFilesToPost}
            imageFileValidation={photoValidateObject}
            maxVideoSize={70}
          />

          <StyledPublishButton>
            <Button
              disabled={loading}
              onClick={handleOnPublishClicked}
            >
              {loading && <Loading className="is-uploaded-media" />}
              Publicar
            </Button>
          </StyledPublishButton>
        </StyledAddMediaFormContent>
      </StyledAddMediaForm>

    </StyledDialog>
  );
};

export default AddMediaFormDialog;
