export const replyCommentField = {
  name: 'reply-comment',
  nickname: 'Sua resposta',
  label: 'Sua resposta',
  textarea: {
    rows: 6,
  },
  hasborder: 'yes',
  hasradius: 'yes',
  longDescription: 750,
  required: true,
};
