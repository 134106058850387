import axios from 'axios';

export default async function updateGuest({
  accessToken,
  guestId,
  locationAccessLevelProfileId,
  setTokenLikeExpired,
  setIsFetching,
}) {
  const requestOptions = {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  };

  try {
    setIsFetching(true);

    const { data } = await axios.put(
      `${process.env.REACT_APP_GUESTS_API_URL}/${guestId}`,
      {
        location_access_level_profile_id: locationAccessLevelProfileId,
      },
      requestOptions,
    );

    return data;
  } catch (err) {
    if (err.response?.status === 401 && setTokenLikeExpired) setTokenLikeExpired();

    return null;
  } finally {
    setIsFetching(false);
  }
}
