import React from 'react';
import * as R from 'ramda';

import APIChangePassword from '../../../services/users/changePassword';
import APIValidatePassword from '../../../services/users/validatePassword';

import { Loading } from '../../../components';

import {
  StyledPasswordInfo,
  StyledPasswordInfoContainer,
  StyledInputGroup,
  StyledTextWrapper,
  StyledTextLabel,
  StyledTitleLabel,
  StyledSubtitleLabel,
  StyledButtonContainer,
  StyledButton,
} from './password-info-styles';

import {
  fieldPasswordInfoCurrentPassword,
  fieldPasswordInfoNewPassword,
  fieldPasswordInfoConfirmNewPassword,
} from './fields-scheme';

import { useUncontrolledField } from '../../../hooks';

const PasswordInfo = ({
  userId,
  userEmail,
  userAccessToken,
  feedbackMessage,
  userSetTokenLikeExpired,
}) => {
  const [fields, setFields] = React.useState([]);
  const [isLoading, setIsLoading] = React.useState(false);

  const { Field: CurrentPassword } = useUncontrolledField({
    fieldScheme: {
      ...fieldPasswordInfoCurrentPassword,
    },
    saveIn: setFields,
  });

  const { Field: NewPassword } = useUncontrolledField({
    fieldScheme: {
      ...fieldPasswordInfoNewPassword,
    },
    saveIn: setFields,
  });

  const { Field: ConfirmNewPassword } = useUncontrolledField({
    fieldScheme: {
      ...fieldPasswordInfoConfirmNewPassword,
    },
    saveIn: setFields,
  });

  const updatePassword = React.useCallback(async () => {
    const [fieldCurrentPassword, fieldNewPassword, fieldConfirmNewPassword] = fields.map(field => field.getValue());

    if (fieldCurrentPassword === '' || fieldNewPassword === '' || fieldConfirmNewPassword === '') {
      feedbackMessage('Todos os campos de senha são obrigatórios', { variant: 'warning' });
      return;
    }

    if (fieldNewPassword !== fieldConfirmNewPassword) {
      feedbackMessage('A confirmação de senha é inválida, tente novamente', { variant: 'warning' });
      return;
    }

    const validatePasswordResponse = await APIValidatePassword({
      accessToken: userAccessToken,
      email: userEmail,
      password: fieldCurrentPassword,
      setTokenLikeExpired: userSetTokenLikeExpired,
      feedbackMessage,
    });

    if (R.isNil(validatePasswordResponse) || validatePasswordResponse?.status !== 'Ok') {
      feedbackMessage('A senha inserida é inválida', { variant: 'warning' });
      return;
    }

    const updatePasswordResponse = await APIChangePassword({
      accessToken: userAccessToken,
      id: userId.toString(),
      password: fieldNewPassword,
      setTokenLikeExpired: userSetTokenLikeExpired,
      feedbackMessage,
      isLoading: setIsLoading,
    });
  }, [fields]);

  return (
    <StyledPasswordInfo>
      <StyledTitleLabel>Alterar Senha</StyledTitleLabel>

      <StyledPasswordInfoContainer>
        <StyledSubtitleLabel>A senha deve conter pelo menos 8 caracteres e uma letra maiúscula</StyledSubtitleLabel>

        <StyledInputGroup>
          <StyledTextWrapper>
            <StyledTextLabel>Senha Atual</StyledTextLabel>
            <CurrentPassword />
          </StyledTextWrapper>

          <StyledTextWrapper>
            <StyledTextLabel>Nova Senha</StyledTextLabel>
            <NewPassword />
          </StyledTextWrapper>

          <StyledTextWrapper>
            <StyledTextLabel>Confirmar Nova Senha</StyledTextLabel>
            <ConfirmNewPassword />
          </StyledTextWrapper>
        </StyledInputGroup>

        <StyledButtonContainer>
          <StyledButton
            onClick={updatePassword}
            className="button"
            buttonType="primary"
            disabled={isLoading}
          >
            {isLoading && <Loading className="is-loading-save-infos" />}
            Salvar
          </StyledButton>
        </StyledButtonContainer>
      </StyledPasswordInfoContainer>
    </StyledPasswordInfo>
  );
};

export default PasswordInfo;
