import styled from 'styled-components';
import { Paper } from '@material-ui/core';

export const StyledSpecialDates = styled.div``;

export const StyledContent = styled(Paper)``;

export const StyledContentTitle = styled.h4`
    font-size: 1.4rem;
`;
