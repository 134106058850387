import stringToArray from './string-to-array';

const filterSearch = ({ item, key, query }) => {
  const regexMatch = new RegExp([query.trim()], 'gi');
  const itemSearchMatch = key ? item[key] : item;

  if (regexMatch.test(itemSearchMatch.trim())) return item;
};

export default filterSearch;
