import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { lighten } from 'polished';

export const StyledLogin = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 40rem;
  width: 100%;
  padding: 0 2rem;
  margin: auto;

  .select-language {
    width: 100%;
    padding: 0;
    margin-bottom: 2rem;

    select {
      border-radius: 0;
    }
  }

  > img {
    max-width: 16.2rem;
    display: block;
    margin-bottom: 1.5rem;
  }

  .feedBackMessage {
    margin-bottom: 2rem;
    font-size: 1.2rem;
  }
`;

export const StyledForm = styled.form`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const StyledWelcome = styled.span`
  font-weight: 700;
  font-size: 2rem;
  margin-bottom: 2.8rem;
`;

export const StyleInputGroup = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 2rem;
`;

export const StyledForgotPassword = styled(Link)`
  font-weight: 400;
  font-size: 1.5rem;
  display: inline-block;
  margin-top: 0.5rem;
  color: ${(props) => lighten(0.1, props.theme.colors.primary)};

  &:hover {
    text-decoration: underline;
  }
`;

export const Disclaimer = styled.div`
  color: ${(props) => lighten(0.2, props.theme.colors.black)};
  font-size: 1.2rem;
  text-align: center;

  a {
    font-weight: 400;
  }
`;

export const StyledButtonsGroup = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;

  .buttons-group-button {
    width: 100%;
  }

  .buttons-group-or {
    color: ${props => props.theme.colors.grey};
    font-size: 1.2rem;
    margin: 1.5rem 0;
    line-height: 1;
  }
`;
