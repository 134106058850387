import React from 'react';
import { useSelector } from 'react-redux';
import * as R from 'ramda';
import { useSnackbar } from 'notistack';

import APICreateGooglePost from '../../../../services/google/post/createPost';
import getValidGoogleAccessTokenFromLocation from '../../../../services/getValidGoogleAccessTokenFromLocation';
import APIPatchGooglePost from '../../../../services/google/post/patchPost';

import type { TRootStateRedux } from '../../../../types/TRootStateRedux';

import type {
  TPostFormCovid19,
  TLocationPostAddButtonType,
} from '../../../../types/TLocationPost';

import {
  useAuth,
} from '../../../../hooks';

import { websiteValidate } from '../../../../utils/fields-validate';

import {
  locationPostAddButtonsType,
  getActionType,
  getActionTypeReverse,
} from '../../helpers';

import {
  Textarea,
  Select,
  Input,
  Button,
  Loading,
} from '../../../../components';

import {
  StyledPostFormCovid19,
} from './post-form-covid-19-styles';

const PostFormCovid19 = ({
  closeModal,
  dataToUpdate,
}: TPostFormCovid19) => {
  const { enqueueSnackbar } = useSnackbar();

  const {
    userAccessToken,
    userId,
    userProfile,
  } = useAuth();

  const { activeLocation } = useSelector((state: TRootStateRedux) => state.LocationReducer);

  const [covid19StatusText, setCovid19StatusText] = React.useState('');
  const [addedButtonType, setAddedButtonType] = React.useState('Nenhum');
  const [addedButtonTypeValue, setAddedButtonTypeValue] = React.useState<string>('');
  const [isPublish, setIsPublish] = React.useState<React.SetStateAction<boolean>>(false);
  const [isAddedButton, setIsAddedButton] = React.useState(false);
  const [isAddedButtonPhoneType, setIsAddedButtonPhoneType] = React.useState(false);
  const [googleAccessToken, setGoogleAccessToken] = React.useState<string | null>(null);
  const [googleAccountId, setGoogleAccountId] = React.useState<string | null>(null);
  const [googleLocationId, setGoogleLocationId] = React.useState<string | null>(null);

  const getValidGoogleAccessToken = React.useCallback(async () => {
    const getValidGoogleAccessTokenResponse = await getValidGoogleAccessTokenFromLocation({
      accessToken: userAccessToken,
      userId,
      userProfile,
      locationId: activeLocation.id,
    });

    if (R.isNil(getValidGoogleAccessTokenResponse)) return;

    const { googleAccessToken: accessTokenGoogle, connectionInfo } = getValidGoogleAccessTokenResponse;

    setGoogleAccessToken(accessTokenGoogle);
    setGoogleAccountId(connectionInfo.google_account_id);
    setGoogleLocationId(connectionInfo.google_location_id);
  }, []);

  const isValidInput = React.useCallback(() => {
    const postActionType = getActionType(addedButtonType);

    if (R.isEmpty(covid19StatusText)) {
      enqueueSnackbar('O conteúdo da postagem não pode ser vazio!', { variant: 'warning' });
      return false;
    }

    if (covid19StatusText.length > 1500) {
      enqueueSnackbar('O conteúdo da postagem não podem conter mais de 1500 caracteres!', { variant: 'warning' });
      return false;
    }

    if (R.isEmpty(addedButtonTypeValue) && R.isNil(addedButtonTypeValue) && !R.isNil(postActionType) && postActionType !== 'CALL') {
      enqueueSnackbar('O link do botão da postagem não pode ser vazio!', { variant: 'warning' });
      return false;
    }

    if (!R.isEmpty(postActionType) && !R.isNil(postActionType)) {
      if (postActionType !== 'CALL' && !websiteValidate(addedButtonTypeValue)) {
        enqueueSnackbar('O formato do link inserido é inválido!', { variant: 'warning' });
        return false;
      }
    }

    return true;
  }, [
    covid19StatusText,
    addedButtonType,
    addedButtonTypeValue,
  ]);

  React.useEffect(() => {
    if (dataToUpdate) {
      const {
        topicType,
        alertType,
        summary,
        callToAction,
      } = dataToUpdate;
      if (topicType === 'ALERT' && alertType === 'COVID_19') {
        setCovid19StatusText(summary);
        setAddedButtonType(getActionTypeReverse(callToAction?.actionType || 'Nenhum'));
        setAddedButtonTypeValue(callToAction?.url);
      }
    }
  }, [dataToUpdate]);

  React.useEffect(() => { getValidGoogleAccessToken(); }, [getValidGoogleAccessToken]);

  React.useEffect(() => {
    if (addedButtonType !== 'Nenhum' && addedButtonType !== 'Ligar agora') {
      setIsAddedButton(true);
      setIsAddedButtonPhoneType(false);
    }

    if (addedButtonType === 'Ligar agora') {
      setIsAddedButtonPhoneType(true);
      setIsAddedButton(false);
    }

    if (addedButtonType === 'Nenhum') {
      setIsAddedButton(false);
      setIsAddedButtonPhoneType(false);
    }
  }, [addedButtonType]);

  const createGooglePost = React.useCallback(async () => {
    if (R.isNil(googleAccessToken)) return;
    if (R.isNil(activeLocation)) return;

    if (!isValidInput()) return;

    const postActionType = getActionType(addedButtonType);

    const postActionUrl = postActionType !== 'CALL' ? addedButtonTypeValue : null;

    const createGooglePostResponse = await APICreateGooglePost({
      accessToken: googleAccessToken,
      locationId: `accounts%2F${googleAccountId}%2Flocations%2F${googleLocationId}`,
      inputData: {
        actionType: postActionType,
        url: postActionUrl || null,
        languageCode: 'pt-BR',
        summary: covid19StatusText,
        topicType: 'ALERT',
        alertType: 'COVID_19',
      },
      setIsPublish,
      feedbackMessage: enqueueSnackbar,
    });

    if (!R.isNil(createGooglePostResponse)) enqueueSnackbar('Postagem publicada com sucesso', { variant: 'success' });

    closeModal();
  }, [
    addedButtonTypeValue,
    addedButtonType,
    covid19StatusText,
    googleAccessToken,
    activeLocation,
  ]);

  const updateGooglePost = React.useCallback(async () => {
    const { name: postId } = dataToUpdate;

    if (!isValidInput()) return;

    const actionType = getActionType(addedButtonType);

    const inputData = {
      summary: covid19StatusText,
      actionType,
      url: actionType !== 'CALL' ? addedButtonTypeValue : null,
    };

    const updateGooglePostResponse = await APIPatchGooglePost({
      accessToken: googleAccessToken,
      postId: postId.replaceAll('/', '%2F'),
      inputData,
      updateMask: 'callToAction.actionType,callToAction.url,summary',
      setIsFetching: setIsPublish,
      feedbackMessage: enqueueSnackbar,
    });

    if (!R.isNil(updateGooglePostResponse)) enqueueSnackbar('Postagem atualizada com sucesso', { variant: 'success' });

    closeModal();
  }, [
    dataToUpdate,
    covid19StatusText,
    addedButtonType,
    addedButtonTypeValue,
    activeLocation,
  ]);

  const handleAddButton = (value: any) => {
    setAddedButtonType(value);

    if (value === 'Nenhum') setAddedButtonTypeValue('');
  };

  return (
    <StyledPostFormCovid19>
      <Textarea
        onChange={setCovid19StatusText}
        name="covid-status"
        label="Atualizar Status de Covid-19 *"
        border
        value={covid19StatusText}
        className="covid-status-textarea"
      />

      <Select<TLocationPostAddButtonType>
        label="Adicionar um botão (opcional)"
        value={addedButtonType}
        name="select-button-type"
        border
        onChange={({ target }) => handleAddButton(target.value)}
        options={locationPostAddButtonsType}
      />

      {(isAddedButton && !isAddedButtonPhoneType) && (
        <Input
          border
          value={addedButtonTypeValue}
          label="Link para o botão"
          onChange={({ target }) => setAddedButtonTypeValue(target.value)}
        />
      )}

      <Button
        className="publish-button"
        disabled={Boolean(isPublish)}
        onClick={dataToUpdate ? updateGooglePost : createGooglePost}
      >
        {isPublish && <Loading className="is-button-loading" />}
        {dataToUpdate ? 'Atualizar' : 'Publicar'}
      </Button>
    </StyledPostFormCovid19>
  );
};

export default PostFormCovid19;
