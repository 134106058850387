import axios from 'axios';

import { TGetAllGroupLocation } from '../../types/TLocation';

export default async function getAllGroupLocations({
  accessToken,
  locationGroupId,
  page = 0,
  pageSize = 10,
  query = '',
  searchId = 0,
  setTokenLikeExpired = null,
  setIfFetching = null,
  googleLinked = false,
  googleLocationId = undefined,
}: TGetAllGroupLocation) {
  try {
    if (setIfFetching) setIfFetching(true);

    const { data } = await axios.get(
      `${process.env.REACT_APP_LOCATIONS_API_URL}/group-locations/${locationGroupId}?page=${page}&pageSize=${pageSize}&query=${query}&googleLinked=${googleLinked}&googleLocationId=${googleLocationId}&searchId=${searchId}`,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      },
    );

    return data;
  } catch (err: any) {
    if (err.response?.status === 401 && setTokenLikeExpired) return setTokenLikeExpired();

    return null;
  } finally {
    if (setIfFetching) setIfFetching(false);
  }
}
