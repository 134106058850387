import * as R from 'ramda';
import getCompanyLocations from '../../services/locations/getCompanyLocations';
import type { TIsLocationFromThisCompany } from '../../types/TUseCompanyLocations';

export const isLocationFromThisCompany = async ({
  activeLocationName,
  activeCompanyId,
  userAccessToken,
}: TIsLocationFromThisCompany) => {
  const [locationsArray] = await getCompanyLocations({
    accessToken: userAccessToken,
    companyId: activeCompanyId,
    query: activeLocationName,
  });

  if (R.isNil(locationsArray)) return false;
  if (R.isEmpty(locationsArray)) return false;

  return true;
};
