import axios from 'axios';

import type { TUpdatedTicket } from '../../types/TTickets';

export default async function updateTicket({
  accessToken,
  ticketId,
  dataToUpdate,
  setIsFetching,
  setTokenLikeExpired,
  feedbackMessage,
}: TUpdatedTicket): Promise<any> {
  try {
    if (setIsFetching) setIsFetching(true);

    const { data } = await axios.put(
      `${process.env.REACT_APP_TICKETS_API_URL}/${ticketId}`,
      dataToUpdate,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      },
    );

    if (!data) return;

    if (feedbackMessage) {
      feedbackMessage(
        'A sincronização em andamento para este local foi atualizado com sucesso',
        { variant: 'success' },
      );
    }

    return data;
  } catch (err: any) {
    if (err.response?.status === 401 && setTokenLikeExpired) setTokenLikeExpired();

    if (feedbackMessage) {
      feedbackMessage('Erro ao tentar atualizar o ticket', {
        variant: 'error',
      });
    }

    return null;
  } finally {
    if (setIsFetching) setIsFetching(false);
  }
}
