import React, { SetStateAction } from 'react';
import { ESyncMonitoringPlatformStatus } from '../../../../components/sync-status-icon/types';

export type TStatusOptions ={
  id: number,
  name: string;
  value: ESyncMonitoringPlatformStatus | '';
}

export const StatusOptions: TStatusOptions[] = [
  { id: 0, name: 'Selecione uma opção', value: '' },
  { id: 1, name: 'Rejeitado', value: ESyncMonitoringPlatformStatus.REJECTED },
  { id: 2, name: 'Aprovado', value: ESyncMonitoringPlatformStatus.APPROVED },
  { id: 3, name: 'Pendente', value: ESyncMonitoringPlatformStatus.PENDING },
  { id: 4, name: 'Em progresso', value: ESyncMonitoringPlatformStatus.PROGRESS },
  { id: 5, name: 'Finalizado', value: ESyncMonitoringPlatformStatus.DONE },
  { id: 6, name: 'Erro', value: ESyncMonitoringPlatformStatus.ERROR },
  { id: 7, name: 'Local Excluído', value: ESyncMonitoringPlatformStatus.LOCATION_DELETED },
];

export interface TRequestsFilterProps {
  searchCompanyLabel: string;
  onChangeCompanyQuery: React.Dispatch<string>;
  searchLocationLabel: string;
  onChangeLocationQuery: React.Dispatch<string>;
  selectedTypeFilter: string;
  setSelectedTypeFilter: React.Dispatch<string>;
  statusLabel: string;
  ofPeriod: Date | null;
  setOfPeriod: React.Dispatch<SetStateAction<Date | null>>;
  toPeriod: Date | null;
  setToPeriod: React.Dispatch<SetStateAction<Date | null>>;
}
