import React from 'react';

import type { TSectionHeader } from '../../../types/TSectionHeader';

import {
  StyledSectionHeader,
  StyledTitleSection,
} from './section-header-styles';

const SectionHeader = ({
  title = 'Título da página',
  children,
}: TSectionHeader) => (
  <StyledSectionHeader>
    <StyledTitleSection>{title}</StyledTitleSection>
    {children}
  </StyledSectionHeader>
);

export default SectionHeader;
