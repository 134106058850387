import axios from 'axios';

export default async function updateResponsible({
  accessToken,
  responsibleId,
  name,
  email,
  phone,
  setTokenLikeExpired,
  setIsUpdatingResponsible,
  feedbackMessage,
}) {
  try {
    if (setIsUpdatingResponsible) setIsUpdatingResponsible(true);
    if (feedbackMessage) feedbackMessage('Atualizando responsável...', { variant: 'warning' });

    const { data } = await axios.put(`${process.env.REACT_APP_RESPONSIBLES_API_URL}/${responsibleId}`,
      {
        name,
        email,
        phone,
      },
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });

    if (feedbackMessage) feedbackMessage('Responsável atualizado com sucesso!', { variant: 'success' });

    return data;
  } catch (err) {
    if (err.response?.status === 401 && setTokenLikeExpired) setTokenLikeExpired();

    return err;
  } finally {
    if (setIsUpdatingResponsible) setIsUpdatingResponsible(false);
  }
}
