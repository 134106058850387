import React from 'react';
import { Helmet } from 'react-helmet';
import { useHistory } from 'react-router-dom';

import { Can } from '../../components';

import BatchPlatformsLinkWithLocation from './batch-platforms-link-with-location';
import BIReport from './bi-monitor';
import CategoryGrouping from './category-grouping';
import DeletedCompanies from './deleted-companies';
import DeletedCompanyGroups from './deleted-company-groups';
import DeletedLocationGroups from './deleted-location-groups';
import DeletedLocations from './deleted-locations';
import Functions from './functions';
import Groups from './groups';
import HandlerList from './handler-list';
import LoginHistory from './login-history';
import Menus from './menus';
import PlatformsLinkWithLocation from './platforms-link-with-location';
import RegisterClient from './register-client';
import SyncMonitoring from './sync-monitoring';
import Users from './users';

import {
  StyledConfigs, StyledContent, StyledHeader, StyledOptionsAccess
} from './configs-styles';

const Configs = () => {
  const routerHistory = useHistory();

  return (
    <StyledConfigs>
      <Helmet>
        <meta property="og:title" content="Configurações - SaaS Hublocal" />
        <meta
          property="og:description"
          content="Configurações da plataforma."
        />

        <title>Configurações - SaaS Hublocal</title>
      </Helmet>

      <Can
        perform="Access/Config"
        yes={() => (
          <>
            <StyledHeader>
              <h2>Configurações</h2>
            </StyledHeader>
            <StyledOptionsAccess>
              <RegisterClient />
            </StyledOptionsAccess>
            <StyledContent>
              <HandlerList navigation={routerHistory} />
              <Functions navigation={routerHistory} />
              <Groups navigation={routerHistory} />
              <Menus navigation={routerHistory} />
              <Users navigation={routerHistory} />
              <CategoryGrouping navigation={routerHistory} />
              <DeletedCompanies navigation={routerHistory} />
              <DeletedLocations navigation={routerHistory} />
              <DeletedCompanyGroups navigation={routerHistory} />
              <DeletedLocationGroups navigation={routerHistory} />
              <PlatformsLinkWithLocation navigation={routerHistory} />
              <BatchPlatformsLinkWithLocation navigation={routerHistory} />
              <SyncMonitoring navigation={routerHistory} />
              <BIReport navigation={routerHistory} />
              <LoginHistory navigation={routerHistory} />
            </StyledContent>
          </>
        )}
        no={() => <PlatformsLinkWithLocation navigation={routerHistory} />}
      />
    </StyledConfigs>
  );
};

export default Configs;
