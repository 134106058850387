import styled from 'styled-components';
import { Paper } from '@material-ui/core';

export const StyledLocationGallery = styled.div`
  .is-loading-medias {
    margin: 2rem auto;
  }
`;

export const StyledContent = styled.div`
  display: flex;
  flex-direction: column;
  padding: 2rem;
  width: 100%;

  .gallery-filters {
    margin-bottom: 3rem;
  }
`;

export const StyledAlert = styled(Paper)`
  display: flex;
  padding: 2rem;
  font-size: 1.4rem;
  color: ${props => props.theme.colors.red};
`;

export const StyledCard = styled(Paper)`
  display: flex;
  padding: 2rem;
  align-items: center;
  justify-content: center;
  margin: 2rem;

  p {
    color: ${props => props.theme.colors.red};
    margin-bottom: 0;
  }
`;
