import axios from 'axios';

const getCategoryGrouping = async ({
  accessToken,
  setTokenLikeExpired,
  groupingID,
  setIsFetching,
  snackbar,
}) => {
  try {
    if (setIsFetching) setIsFetching(true);

    const response = await axios({
      method: 'GET',
      url: `${process.env.REACT_APP_CATEGORY_GROUPINGS_API_URL}/${groupingID}`,
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });

    const { data } = await response;

    return data;
  } catch (err) {
    const {
      response,
    } = err;

    if (response?.status === 401 && setTokenLikeExpired) setTokenLikeExpired();

    if (response?.status === 404 && snackbar) {
      snackbar('Registro não encontrado.', { variant: 'error' });
    } else if (snackbar) {
      snackbar('Deu erro ao tentar criar agrupamento. Tente novamente!', { variant: 'error' });
    }
  } finally {
    if (setIsFetching) setIsFetching(false);
  }
};

export default getCategoryGrouping;
