import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { CircularProgress } from '@material-ui/core';

import './CreateOrUpdateGroup.css';

import { ItemBoard } from '../../components';

import getFunctions from '../../services/functions/getFunctions';
import createGroup from '../../services/groups/createGroup';
import addFunctions from '../../services/groups/addFunctions';
import removeFunctions from '../../services/groups/removeFunctions';
import getGroup from '../../services/groups/getGroup';
import updateGroup from '../../services/groups/updateGroup';

const CreateGroup = () => {
  const history = useHistory();
  const params = useParams();
  const [loading, setLoading] = useState(false);
  const [name, setName] = useState('');
  const [icon, setIcon] = useState('');
  const [functionsToAdd, setFunctionsToAdd] = useState([]);
  const [addedFunctions, setAddedFunctions] = useState([]);
  const [groupPreviousFunctions, setGroupPreviousFunctions] = useState([]);

  const { user } = useSelector((state) => state.AuthReducer);

  const { id } = params;

  React.useEffect(() => {
    const fetchFunctions = async () => {
      let groupData;
      if (id) {
        groupData = await getGroup(user.accessToken, id);
        setName(groupData.name);
        setIcon(groupData.icon);
        setGroupPreviousFunctions(groupData.functions);
        setAddedFunctions(groupData.functions);
      }

      const [data] = await getFunctions(user.accessToken);
      const functionData = [];
      for (let i = 0; i < data.length; i++) {
        if (data[i].is_available_in_menu) {
          functionData.push(data[i]);
        }
      }

      if (id) {
        groupData.functions.map((element) => {
          const { id } = element;
          for (let i = 0; i < functionData.length; i++) {
            if (functionData[i].id === id) {
              functionData.splice(i, 1);
            }
          }
        });
      }
      setFunctionsToAdd(functionData);
    };

    fetchFunctions();
  }, []);

  const renderButton = () => {
    if (loading) return <CircularProgress />;
    return (
      <button
        className="btn btn-primary button-margin-top font-poppins"
        onClick={id ? handleOnUpdateGroupClicked : handleOnCreateGroupClicked}
      >
        {id ? 'Salvar Grupo' : 'Criar Grupo'}
      </button>
    );
  };

  const handleChangeName = ({ target: { value } }) => {
    setName(value);
  };

  const handleChangeIcon = ({ target: { value } }) => {
    setIcon(value);
  };

  const handleOnCreateGroupClicked = async () => {
    setLoading(true);
    const group = await createGroup(user.accessToken, name, icon, user.user.id);
    const { id } = group;
    const functionIds = [];
    for (let i = 0; i < addedFunctions.length; i++) {
      functionIds.push(addedFunctions[i].id);
    }
    const data = await addFunctions(user.accessToken, id, functionIds);
    console.log(data);
    setLoading(false);
    history.push('/groups/list');
  };

  const handleOnUpdateGroupClicked = async () => {
    setLoading(true);
    const group = await updateGroup(user.accessToken, id, name, icon);
    const previousFunctionIds = [];
    groupPreviousFunctions.map((element) => {
      previousFunctionIds.push(element.id);
    });

    const functionIds = [];
    addedFunctions.map((element) => {
      functionIds.push(element.id);
    });
    await removeFunctions(user.accessToken, id, previousFunctionIds);
    await addFunctions(user.accessToken, id, functionIds);
    setLoading(false);

    history.push('/groups/list');
  };

  const onAvailableFunctionClicked = (id) => {
    const func = functionsToAdd.filter((element) => element.id === id);
    setAddedFunctions([...addedFunctions, func[0]]);
    setFunctionsToAdd(functionsToAdd.filter((item) => item.id !== func[0].id));
  };

  const onAddedFunctionClicked = (id) => {
    const func = addedFunctions.filter((element) => element.id === id);
    setFunctionsToAdd([...functionsToAdd, func[0]]);
    setAddedFunctions(addedFunctions.filter((item) => item.id !== func[0].id));
  };

  return (
    <div style={{ padding: '1em' }}>
      <div className="title-div">
        <text className="font-poppins page-title">
          {id ? 'Editar Grupo' : 'Cadastro de grupo'}
        </text>
      </div>
      <label className="font-poppins">Nome</label>
      <input
        className="form-control font-poppins"
        name="name"
        value={name}
        onChange={handleChangeName}
      />

      <label htmlFor="icons" className="font-poppins">
        Ícone
      </label>

      <input
        className="form-control font-poppins"
        name="icon"
        value={icon}
        onChange={handleChangeIcon}
      />

      <ItemBoard
        availableItemText="Funções Disponíveis"
        itemsToAdd={functionsToAdd}
        onAvailableItemClicked={onAvailableFunctionClicked}
        addedItemText="Funções Adicionadas"
        addedItems={addedFunctions}
        onAddedItemClicked={onAddedFunctionClicked}
      />
      <div className="create-button-div">{renderButton()}</div>
    </div>
  );
};

export default CreateGroup;
