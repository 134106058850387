import React from 'react';
import * as R from 'ramda';

import { InputSwitch, Tooltip } from '../index';

import {
  StyledUncontrolledField,
  StyledDisclaimer,
  StyledLabel,
  StyledInputWrap,
  StyledTooltipWarning,
  StyledTooltipPassword,
  StyledWarningIcon,
  StyledInput,
  StyledVisibilityPasswordIcon,
  StyledVisibilityOffPasswordIcon,
  StyledInputMask,
  StyledTextarea,
  StyledPhoneAndroidIcon,
  StyledPhoneIcon,
  StyledTooltipLandline,
  StyledFloatingIcons,
  StyledDescriptionAreaCount,
  StyledDescriptionWrapper,
  StyledSwitchInputEnable,
  StyledDisabledInput,
} from './uncontrolled-field-styles';
import isNilOrEmpty from '../../utils/is-null-or-empty';

const UncontrolledField = ({
  type,
  required,
  disclaimer,
  defaultValue,
  name,
  nickname,
  id,
  disabled,
  label,
  description,
  fieldRef,
  hasborder,
  hasradius,
  password,
  mask,
  typeSwitch,
  switchRef,
  maxLength,
  uppercase,
  getZipCodeData,
  textarea,
  landline,
  className,
  classNameInputWrapper,
  shortDescription,
  longDescription,
  placeholder,
  isDisabledInput,
  isDisabledInputRef,
  isOutsideBrazil = false,
}) => {
  const [typeField, setTypeField] = React.useState(type);
  const [fieldMaskingValue, setFieldMaskingValue] = React.useState(
    defaultValue || '',
  );

  const [controlledTooltip, setControlledTooltip] = React.useState(false);
  const [inputSwitch, setInputSwitch] = React.useState(defaultValue || false);
  const [inputMaskMask, setInputMaskMask] = React.useState(mask);
  const [landlineEnable, setLandlineEnable] = React.useState(
    defaultValue || false,
  );
  const [inputTextCounter, setInputTextCounter] = React.useState('');
  const [switchInputEnableContent, setSwitchInputEnableContent] = React.useState(!!defaultValue);

  const [inputFocus, setInputFocus] = React.useState(false);

  const isShowInputContent = React.useMemo(() => {
    if (isDisabledInput && !switchInputEnableContent) return false;
    return true;
  }, [isDisabledInput, switchInputEnableContent]);

  React.useEffect(() => {
    if (!isNilOrEmpty(defaultValue)) {
      setInputTextCounter(defaultValue);
    }

    if (isDisabledInput) {
      setSwitchInputEnableContent(isDisabledInputRef.current.value);
      isDisabledInputRef.current.value = isDisabledInputRef.current.value ? true : !!defaultValue;
    }

    if (typeSwitch) {
      switchRef.current.value = defaultValue;
    }

    if (landline) {
      const normalPhone = /\d{2}\s\d{5}-\d{4}/g;
      const landlineMode = /\d{2}\s\d{4}-\d{4}/g;
      if (landlineMode.test(defaultValue)) {
        setLandlineEnable(false);
        setInputMaskMask(landline);
      }

      if (normalPhone.test(defaultValue)) {
        setLandlineEnable(true);
        setInputMaskMask(mask);
      }

      if (!defaultValue) setInputMaskMask(landline);
    }
  }, [defaultValue]);

  const handleInputHasNoValue = () => {
    if (mask) {
      const maskValueRegexTest = /_/g;

      if (
        maskValueRegexTest.test(fieldMaskingValue)
        || R.isEmpty(fieldMaskingValue)
      ) return true;
      return false;
    }

    if (inputTextCounter.length === 0) return true;

    return false;
  };

  const showTooltip = () => required && handleInputHasNoValue() && setControlledTooltip(true);
  const closeTooltip = () => setControlledTooltip(false);

  const handleChangeInputSwitch = () => {
    setInputSwitch(!inputSwitch);
    switchRef.current.value = !inputSwitch;
  };

  const handleChangeSwitchInputEnableContent = () => {
    const newSwitchInputEnableContent = !switchInputEnableContent;

    setSwitchInputEnableContent(newSwitchInputEnableContent);
    isDisabledInputRef.current.value = newSwitchInputEnableContent;
  };

  const handleChangeInputMask = (value) => {
    if (name === 'zipcode' && isOutsideBrazil) {

      const isNumber = /\d/g;
      const lastInputChar = value[value.length - 1];

      if (isNilOrEmpty(value) || isNumber.test(lastInputChar)) {
        setFieldMaskingValue(value);
        getZipCodeData(value);
      }

      return setFieldMaskingValue;
    }

    setFieldMaskingValue(value);
    if (name === 'zipcode' && !isOutsideBrazil) getZipCodeData(value);
  };

  const changePhoneType = () => {
    if (landlineEnable) {
      setInputMaskMask(landline);
      setLandlineEnable(false);
    }

    if (!landlineEnable) {
      setInputMaskMask(mask);
      setLandlineEnable(true);
    }
  };

  return (
    <StyledUncontrolledField className={classNameInputWrapper}>
      {label && !typeSwitch && (
        <StyledLabel htmlFor={id}>
          {description ? (
            <Tooltip title={description} arrow placement="right">
              <span>
                {label}
                { required && '*' }
              </span>
            </Tooltip>
          ) : (
            <>
              { label }
              { required && '*' }
            </>
          )}
        </StyledLabel>
      )}

      <StyledInputWrap
        className={className}
        hasborder={hasborder}
        hasradius={hasradius}
        inputFocus={inputFocus}
      >
        {isShowInputContent && (
          <>
            {!mask && !typeSwitch && !textarea && (
              <StyledTooltipWarning
                placement="top"
                open={controlledTooltip}
                onOpen={showTooltip}
                onClose={closeTooltip}
                title="Campo obrigatório"
                arrow
              >
                <StyledInput
                  placeholder={placeholder}
                  autoComplete={password ? 'new-password' : 'off'}
                  ref={fieldRef}
                  name={name}
                  type={typeField}
                  defaultValue={defaultValue}
                  disabled={disabled}
                  id={id}
                  nickname={nickname}
                  password={password}
                  maxLength={maxLength}
                  uppercase={uppercase}
                  isrequerid={required ? 'yes' : 'not'}
                  onChange={({ target }) => setInputTextCounter(target.value)}
                />
              </StyledTooltipWarning>
            )}

            {typeSwitch && (
              <InputSwitch
                checked={inputSwitch}
                changeChecked={handleChangeInputSwitch}
                label={`${label}${required ? '*' : ''}`}
                name={name}
              />
            )}

            {!mask && !typeSwitch && textarea && (
              <StyledDescriptionWrapper>
                <StyledTooltipWarning
                  placement="top"
                  open={controlledTooltip}
                  onOpen={showTooltip}
                  onClose={closeTooltip}
                  title="Campo obrigatório"
                  arrow
                >
                  <StyledTextarea
                    placeholder={placeholder}
                    autoComplete="off"
                    ref={fieldRef}
                    name={name}
                    type={typeField}
                    defaultValue={defaultValue}
                    disabled={disabled}
                    id={id}
                    nickname={nickname}
                    hasborder={hasborder}
                    hasradius={hasradius}
                    password={password}
                    maxLength={maxLength}
                    uppercase={uppercase}
                    isrequerid={
                      required && inputTextCounter.length === 0 ? 'yes' : 'not'
                    }
                    rows={textarea.rows}
                    onChange={({ target }) => setInputTextCounter(target.value)}
                    isMaxLength={
                      inputTextCounter.length
                      > (shortDescription || longDescription)
                    }
                  />
                </StyledTooltipWarning>

                {inputTextCounter.length > 0
                  && (shortDescription || longDescription) && (
                    <StyledDescriptionAreaCount
                      isMaxLength={
                        inputTextCounter.length
                        > (shortDescription || longDescription)
                      }
                    >
                      {inputTextCounter.length}
                      /
                      {shortDescription || longDescription}
                    </StyledDescriptionAreaCount>
                )}
              </StyledDescriptionWrapper>
            )}

            {mask && !typeSwitch && !textarea && (
              <StyledTooltipWarning
                placement="top"
                open={controlledTooltip}
                onOpen={showTooltip}
                onClose={closeTooltip}
                title="Campo obrigatório"
                arrow
              >
                <StyledInputMask
                  autoComplete="off"
                  ref={fieldRef}
                  name={name}
                  type={typeField}
                  disabled={disabled}
                  id={id}
                  nickname={nickname}
                  hasborder={hasborder}
                  hasradius={hasradius}
                  password={password}
                  value={fieldMaskingValue}
                  onChange={(e) => handleChangeInputMask(e.target.value)}
                  mask={!isOutsideBrazil ? inputMaskMask : ''}
                  isrequerid={required ? 'yes' : 'not'}
                  alwaysShowMask
                  onFocus={() => setInputFocus(true)}
                  onBlur={() => setInputFocus(false)}
                />
              </StyledTooltipWarning>
            )}

            <StyledFloatingIcons>
              {password && typeField === 'text' && (
                <StyledTooltipPassword title="Esconder senha" arrow>
                  <StyledVisibilityOffPasswordIcon
                    className="icon-margin"
                    onClick={() => setTypeField('password')}
                  />
                </StyledTooltipPassword>
              )}

              {password && typeField === 'password' && (
                <StyledTooltipPassword title="Mostrar senha" arrow>
                  <StyledVisibilityPasswordIcon
                    className="icon-margin"
                    onClick={() => setTypeField('text')}
                  />
                </StyledTooltipPassword>
              )}

              {landline && !landlineEnable && (
                <StyledTooltipLandline
                  placement="top"
                  title="Mudar para telefone fixo"
                  arrow
                >
                  <StyledPhoneIcon
                    className="icon-margin"
                    onClick={changePhoneType}
                  />
                </StyledTooltipLandline>
              )}

              {landline && landlineEnable && (
                <StyledTooltipLandline
                  placement="top"
                  title="Mudar para telefone celular"
                  arrow
                >
                  <StyledPhoneAndroidIcon onClick={changePhoneType} />
                </StyledTooltipLandline>
              )}

              {required && handleInputHasNoValue() && <StyledWarningIcon />}
            </StyledFloatingIcons>
          </>
        )}

        {isDisabledInput && !switchInputEnableContent && (
          <StyledDisabledInput>Desabilitado</StyledDisabledInput>
        )}

        {isDisabledInput && (
          <StyledSwitchInputEnable>
            <InputSwitch
              changeChecked={handleChangeSwitchInputEnableContent}
              checked={switchInputEnableContent}
            />
          </StyledSwitchInputEnable>
        )}
      </StyledInputWrap>
      {disclaimer && <StyledDisclaimer>{disclaimer}</StyledDisclaimer>}
    </StyledUncontrolledField>
  );
};

export default React.memo(UncontrolledField);
