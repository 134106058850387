import React from 'react';
import { useDispatch } from 'react-redux';
import * as R from 'ramda';
import { useSnackbar } from 'notistack';

import useAuth from '../use-auth';

import APIGetUserCompanyGroupsWithGuests from '../../services/company-groups/getUserCompanyGroupsWithGuests';
import APIGetAllCompanyGroups from '../../services/company-groups/getAll';
import APIGetCompanyGroup from '../../services/company-groups/getOne';
import APIDeleteCompanyGroup from '../../services/company-groups/remove';

import { Creators as CompanyGroupsActions } from '../../redux/ducks/company-group';

import addTagOwnerOrGuestColumn from '../../utils/add-tag-owner-or-guest-column';

import {
  addStatusTagToCompanyOrLocation,
} from './helpers';

const useCompanyGroups = () => {
  const reduxDispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  const {
    userId,
    userSetTokenLikeExpired,
    userAccessToken,
    userProfileName,
  } = useAuth();

  const [userCompanyGroups, setUserCompanyGroups] = React.useState([]);
  const [userCompanyGroupsCount, setUserCompanyGroupsCount] = React.useState(0);
  const [userCompanyGroupsLoading, setUserCompanyGroupsLoading] = React.useState(false);

  const [userCompanyGroupsPageIndex, setUserCompanyGroupsPageIndex] = React.useState(0);
  const [userCompanyGroupsPageSize, setUserCompanyGroupsPageSize] = React.useState(10);
  const [userCompanyGroupsPageQuantity, setUserCompanyGroupsPageQuantity] = React.useState(0);
  const [userCompanyGroupsPageQuery, setUserCompanyGroupsPageQuery] = React.useState('');

  const getAdminAndOperatorCompaniesGroup = React.useCallback(async () => {
    const getCompanyGroupsResponse = await APIGetAllCompanyGroups({
      accessToken: userAccessToken,
      page: userCompanyGroupsPageQuery ? 0 : userCompanyGroupsPageIndex,
      pageSize: userCompanyGroupsPageSize,
      query: userCompanyGroupsPageQuery,
      setTokenLikeExpired: userSetTokenLikeExpired,
    });

    if (R.isNil(getCompanyGroupsResponse)) return;

    const [userCompanyGroupsData, userCompanyGroupsDataCount] = getCompanyGroupsResponse;

    setUserCompanyGroupsPageQuantity(Math.ceil(userCompanyGroupsDataCount / userCompanyGroupsPageSize));
    setUserCompanyGroups(userCompanyGroupsData);
    setUserCompanyGroupsCount(userCompanyGroupsDataCount);
  }, [
    userCompanyGroupsPageIndex,
    userCompanyGroupsPageSize,
    userCompanyGroupsPageQuery,
  ]);

  const getOtherUsersCompaniesGroup = React.useCallback(async () => {
    const getCompanyGroupsResponse = await APIGetUserCompanyGroupsWithGuests({
      accessToken: userAccessToken,
      userId,
      page: userCompanyGroupsPageQuery ? 0 : userCompanyGroupsPageIndex,
      pageSize: userCompanyGroupsPageSize,
      query: userCompanyGroupsPageQuery,
      setTokenLikeExpired: userSetTokenLikeExpired,
    });

    if (R.isNil(getCompanyGroupsResponse)) return;

    const [userCompanyGroupsData, userCompanyGroupsDataCount] = getCompanyGroupsResponse;

    const userCompanyGroupsWithTag = addTagOwnerOrGuestColumn({
      registerTarget: userCompanyGroupsData,
      currentUserId: userId,
    });

    setUserCompanyGroups(userCompanyGroupsWithTag);
    reduxDispatch(CompanyGroupsActions.setUserCompanyGroups(userCompanyGroupsWithTag));
    setUserCompanyGroupsCount(userCompanyGroupsDataCount);
  }, []);

  const getCompaniesGroupByUser = async (userProfile) => {
    if (userProfile !== 'Usuário Padrão') {
      await getAdminAndOperatorCompaniesGroup();

      return;
    }

    await getOtherUsersCompaniesGroup();
  };

  const getCompanyGroups = React.useCallback(async () => {
    if (userCompanyGroupsPageQuery) setUserCompanyGroupsPageIndex(0);
    if (R.isNil(userProfileName)) return;

    setUserCompanyGroupsLoading('Carregando empresas...');

    await getCompaniesGroupByUser(userProfileName);
  }, [
    userCompanyGroupsPageIndex,
    userCompanyGroupsPageSize,
    userCompanyGroupsPageQuery,
  ]);

  React.useEffect(() => {
    getCompanyGroups();
  }, [getCompanyGroups]);

  const getCompanyGroup = React.useCallback(async (companyGroupId) => {
    const getCompanyGroupResponse = await APIGetCompanyGroup({
      accessToken: userAccessToken,
      setTokenLikeExpired: userSetTokenLikeExpired,
      id: companyGroupId,
    });

    return getCompanyGroupResponse;
  }, []);

  const deleteCompanyGroup = React.useCallback(async (companyGroupId) => {
    const deleteCompanyGroupResponse = await APIDeleteCompanyGroup({
      accessToken: userAccessToken,
      setTokenLikeExpired: userSetTokenLikeExpired,
      id: companyGroupId,
      feedbackMessage: enqueueSnackbar,
    });

    return deleteCompanyGroupResponse;
  }, []);

  return {
    userCompanyGroups,
    userCompanyGroupsLoading,
    userCompanyGroupsCount,
    userCompanyGroupsPageIndex,
    userCompanyGroupsPageSize,
    userCompanyGroupsPageQuantity,
    userCompanyGroupsPageQuery,
    setUserCompanyGroupsPageQuery,
    setUserCompanyGroupsPageIndex,
    setUserCompanyGroupsPageSize,
    getCompanyGroup,
    deleteCompanyGroup,
    getCompanyGroups,
  };
};

export default useCompanyGroups;
