import React from 'react';

import {
  Button,
} from '../../components';

import MainContainer from '../main-container';

import IllustrationErrorPage from '../../assets/images/illustration-page-error.png';

import {
  StyledErrorBoundary,
  StyledContent,
} from './error-boundarys-styles';

export default class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      error: null,
      errorInfo: null,
    };
  }

  componentDidCatch(error, errorInfo) {
    this.setState({
      error,
      errorInfo,
    });
  }

  render() {
    const {
      state: {
        errorInfo,
        error,
      },
      props: {
        children,
      },
    } = this;

    if (errorInfo || error) {
      return (
        <MainContainer>
          <StyledErrorBoundary>
            <StyledContent>
              <img className="illustration-error-page" src={IllustrationErrorPage} alt="Error Page" />
              <h2>Houston, nós temos um problema!</h2>
              <p>
                Identificamos que você acessou uma área do sistema que apresentou um erro.
                Por favor, ajude-nos a resolver, entre em contato conosco!
              </p>

              <Button>Fale conosco</Button>
            </StyledContent>
          </StyledErrorBoundary>
        </MainContainer>
      );
    }

    return children;
  }
}
