/* eslint-disable react/jsx-props-no-spreading */
// eslint-disable-next-line no-use-before-define
import React, { useCallback } from 'react';
import ControlledInput from '../../controlled-input';
import { StyledFieldsWrapper, StyledSectionTitle } from './address-fields-styles';
import {
  zipcodeAttr,
  addressAttr,
  addressComplementAttr,
  addressNumberAttr,
  cityAttr,
  countryCodeAttr,
  neighborhoodAttr,
  stateCodeAttr,
} from './helpers';
import { TAddressFieldsProps } from './types';

const AddressFields = ({
  setLocationInfo,
  initialData,
  fieldOnFocus,
  setFieldOnFocus,
}: TAddressFieldsProps) => {
  const ZipCode = useCallback(() => (
    <ControlledInput
      {...zipcodeAttr}
      fieldOnFocus={fieldOnFocus}
      setFieldOnFocus={setFieldOnFocus}
      setParentValue={setLocationInfo}
      defaultValue={initialData.zip_code}
      country={initialData.country}
    />
  ), [initialData, fieldOnFocus]);

  const CountryCode = useCallback(() => (
    <ControlledInput
      {...countryCodeAttr}
      fieldOnFocus={fieldOnFocus}
      setFieldOnFocus={setFieldOnFocus}
      setParentValue={setLocationInfo}
      defaultValue={initialData.country}
    />
  ), [initialData, fieldOnFocus]);

  const Address = useCallback(() => (
    <ControlledInput
      {...addressAttr}
      fieldOnFocus={fieldOnFocus}
      setFieldOnFocus={setFieldOnFocus}
      setParentValue={setLocationInfo}
      defaultValue={initialData.address}
    />
  ), [initialData, fieldOnFocus]);

  const AddressNumber = useCallback(() => (
    <ControlledInput
      {...addressNumberAttr}
      fieldOnFocus={fieldOnFocus}
      setFieldOnFocus={setFieldOnFocus}
      setParentValue={setLocationInfo}
      defaultValue={initialData['address-number']}
    />
  ), [initialData, fieldOnFocus]);

  const AddressComplement = useCallback(() => (
    <ControlledInput
      {...addressComplementAttr}
      fieldOnFocus={fieldOnFocus}
      setFieldOnFocus={setFieldOnFocus}
      setParentValue={setLocationInfo}
      defaultValue={initialData.address2}
    />
  ), [initialData, fieldOnFocus]);

  const Neighborhood = useCallback(() => (
    <ControlledInput
      {...neighborhoodAttr}
      fieldOnFocus={fieldOnFocus}
      setFieldOnFocus={setFieldOnFocus}
      setParentValue={setLocationInfo}
      defaultValue={initialData.neighborhood}
    />
  ), [initialData, fieldOnFocus]);

  const City = useCallback(() => (
    <ControlledInput
      {...cityAttr}
      fieldOnFocus={fieldOnFocus}
      setFieldOnFocus={setFieldOnFocus}
      setParentValue={setLocationInfo}
      defaultValue={initialData.city}
    />
  ), [initialData, fieldOnFocus]);

  const StateCode = useCallback(() => (
    <ControlledInput
      {...stateCodeAttr}
      fieldOnFocus={fieldOnFocus}
      setFieldOnFocus={setFieldOnFocus}
      setParentValue={setLocationInfo}
      defaultValue={initialData.state}
    />
  ), [initialData, fieldOnFocus]);

  return (
    <>
      <StyledSectionTitle>Informações de endereço</StyledSectionTitle>
      <StyledFieldsWrapper threeColumns>
        <ZipCode />
        <CountryCode />
        <Address />
        <AddressNumber />
        <AddressComplement />
        <Neighborhood />
        <City />
        <StateCode />
      </StyledFieldsWrapper>
    </>
  );
};

export default AddressFields;
