import React from 'react';

import {
  StyledFilterOption,
  StyledCheck,
  StyledCheckBox,
} from './filter-option-styles';

const FilterOption = ({
  filterOption,
  quantity,
  value,
  setValue,
}) => (
  <StyledFilterOption>
    <StyledCheck>
      <StyledCheckBox
        checked={value}
        onChange={setValue}
        inputProps={{ 'aria-label': 'primary checkbox' }}
      />
      <span className="check-name">{filterOption}</span>
    </StyledCheck>
    <small className="filter-quantity">{quantity}</small>
  </StyledFilterOption>
);

export default FilterOption;
