import React from 'react';
import {
  TableBodyRow,
  IconButton,
  TableBodyCell,
  Loading,
  DestructiveActionDialog,
} from '../../../components';
import { TGoogleMoney } from '../../../types/TGoogle';
import { TTableRowData } from '../../../types/TTable';

import {
  getDataRowCellValue,
  updateRowDataByColumn,
  TServiceTableColumnName,
  TRenderCellDataServiceTableRow,
  getServiceDisplayPrice,
  TServiceType,
} from '../helpers';

import {
  StyledActionsWrap,
  StyledActionLoadingWrap,
  StyledServiceNameWrap,
} from './services-table-row-styles';

type TServiceTableRow = {
  data: TTableRowData;
  rowIndex: number;
  isDuplicate?: boolean;
  deleteServiceItems: (indexex: number[]) => Promise<void>;
  selectServiceToEdit: (index: number) => void;
  isTableLoading: boolean;
  setIsRowSelected?: (rowIndex: number, isSelected: boolean) => void;
  hiddenCheckbox?: boolean;
  disabledCheckbox?: boolean;
  canManageServices?: boolean;
};

export const ServicesTableRow = ({
  data,
  rowIndex,
  isTableLoading,
  deleteServiceItems,
  selectServiceToEdit,
  setIsRowSelected,
  hiddenCheckbox = true,
  canManageServices = true,
}: TServiceTableRow) => {
  const [currentRowData, setCurrentRowData] = React.useState<TTableRowData>([]);
  const [checked, setChecked] = React.useState(false);
  const [isRowLoading, setIsRowLoading] = React.useState(false);
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = React.useState(false);

  React.useEffect(() => {
    setCurrentRowData(data);
  }, [data]);

  React.useEffect(() => {
    if (!hiddenCheckbox) setChecked(false);
  }, [hiddenCheckbox]);

  React.useEffect(() => {
    if (setIsRowSelected) setIsRowSelected(rowIndex, checked);
  }, [checked]);

  const isDuplicate: boolean = React.useMemo<boolean>(() => getDataRowCellValue({ dataRowCell: data, columnRef: 'isDuplicate' }), [data]);

  const renderCellData = React.useMemo<TRenderCellDataServiceTableRow>(
    () => ({
      service: (value: string) => (
        <StyledServiceNameWrap>
          {value}
          {!!canManageServices && isDuplicate && (
            <IconButton
              isWarning
              tooltipVariant="yellow"
              icon="Warning"
              tooltip="Há outro(s) serviço(s) com mesmo nome. Selecione os que deseja excluir."
            />
          )}
        </StyledServiceNameWrap>
      ),
      price: (value: TGoogleMoney) => getServiceDisplayPrice(value),
      serviceType: (value: TServiceType) => value,
    }),
    [isDuplicate, canManageServices],
  );

  const onDelete = React.useCallback(async () => {
    setIsRowLoading(true);
    try {
      setIsDeleteDialogOpen(false);
      await deleteServiceItems([rowIndex]);
    } finally {
      setIsRowLoading(false);
    }
  }, []);

  return (
    <TableBodyRow
      key={data[0].value}
      checked={checked}
      setChecked={setChecked}
      hiddenCheckbox={hiddenCheckbox}
    >
      {currentRowData.map(
        (dataColumnCell) => dataColumnCell?.visible && (
        <TableBodyCell
          key={dataColumnCell.columnRef}
        >
          {renderCellData[
                dataColumnCell.columnRef as TServiceTableColumnName
          ]?.(dataColumnCell.value)}
        </TableBodyCell>
        ),
      )}
      <TableBodyCell>
        <StyledActionsWrap>
          {(isRowLoading)
            ? (
              <StyledActionLoadingWrap>
                <Loading className="action-loading" />
              </StyledActionLoadingWrap>
            ) : (
              <>
                <IconButton
                  icon="Edit"
                  tooltip="Editar"
                  onClick={() => {
                    selectServiceToEdit(rowIndex);
                  }}
                  disabled={isTableLoading || !canManageServices}
                />
                <IconButton
                  icon="Delete"
                  tooltip="Remover"
                  onClick={() => {
                    setIsDeleteDialogOpen(true);
                  }}
                  disabled={isTableLoading || !canManageServices}
                />
              </>
            )}
        </StyledActionsWrap>
      </TableBodyCell>
      <DestructiveActionDialog
        open={isDeleteDialogOpen}
        warningMessage={[
          'Você está prestes a excluir o serviço cadastrado nesse local.',
          'Deseja continuar?',
        ]}
        confirmButtonLabel="SIM, DESEJO CONTINUAR"
        cancelButtonLabel="CANCELAR"
        onConfirm={onDelete}
        onClose={() => {
          setIsDeleteDialogOpen(false);
        }}
      />
    </TableBodyRow>
  );
};
