import React from 'react';
import styled from 'styled-components';
import { lighten } from 'polished';

const CellInfoCategories = styled.div`
    display: flex;
    flex-direction: column;

    .category-platform {
        margin-bottom: 0.5rem;
        padding-bottom: 0.5rem;
        border-bottom: 1px solid ${props => lighten(0.3, props.theme.colors.grey)};

        &:last-of-type {
            margin-bottom: 0;
            border-bottom: 0;
        }
    }
`;

export default ({ categoriesData }) => (
  <CellInfoCategories>
    {categoriesData.map(categoryData => (
      <div   
        key={`${Math.random(Math.floor())}${categoryData.categories}`}
        className="category-platform"
      >
        <strong>
          {`${categoryData.name}: `}
        </strong>
        <small>{categoryData.categories}</small>
      </div>
    ))}
  </CellInfoCategories>
);
