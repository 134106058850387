import React from 'react';
import { MUIDataTableOptions } from 'mui-datatables';
import { debounce } from 'lodash';

import { Table } from '../../../../components';
import { useAuth } from '../../../../hooks';
import { getDataRowCellValue } from '../../helpers';
import {
  initialTableColumns,
  normalizeGalleryLocationToTable,
} from './helpers';

import { findBatchGalleryLocations } from '../../../../services/google-batch-modules/batch-gallery/find-batch-gallery-locations';

import type { TArrayResponseWithCount } from '../../../../types/TArrayResponseWithCount';
import type { TBatchGalleryLocation } from '../../../../types/TBatchGallery';
import type { TTableData } from '../../../../types/TTable';
import type { EBatchPlatformStatus } from '../../../../types/TBatchGoogleModules';

import FiltersBar from './filters-bar';
import { GalleryBatchLocationsRow } from './gallery-batch-locations-row';
import { StyledGalleryBatchLocationsTable, StyledHeader } from './gallery-batch-locations-table-styles';
import { isResponseArrayAndSuccessful } from '../../../../utils/is-response-success';

export type TBatchGalleriesLocationsSubTable = {
  batchGalleries: any;
  updateBatchGallery: () => Promise<void>;
};

export function BatchGalleriesLocationsTable({
  batchGalleries,
  updateBatchGallery,
}: TBatchGalleriesLocationsSubTable) {
  const [batchGalleriesLocations, setBatchGalleriesLocations] = React.useState<TTableData>([]);
  const [batchGalleriesLocationsCount, setBatchGalleriesLocationsCount] = React.useState(0);
  const [batchGalleriesLocationsPage, setBatchGalleriesLocationsPage] = React.useState(0);
  const [batchGalleriesLocationsPageSize, setBatchGalleriesLocationsPageSize] = React.useState(10);
  const [batchGalleriesLocationsQuery, setBatchGalleriesLocationsQuery] = React.useState('');
  const [isLoading, setIsLoading] = React.useState(false);

  const [selectedStatus, setSelectedStatus] = React.useState('');

  const { userAccessToken } = useAuth();

  const batchGalleryId = React.useMemo(
    () => getDataRowCellValue({ columnRef: 'id', dataRowCell: batchGalleries }),
    [batchGalleries],
  );
  const status = React.useMemo(
    () => getDataRowCellValue({ columnRef: 'status', dataRowCell: batchGalleries }),
    [batchGalleries],
  );

  const getBatchGalleriesLocations = React.useCallback(async () => {
    if (status === 'Cancelando') return;

    const { status: responseStatus, data: responseData } = await findBatchGalleryLocations({
      batchGalleryId,
      userAccessToken,
      page: batchGalleriesLocationsPage,
      pageSize: batchGalleriesLocationsPageSize,
      query: batchGalleriesLocationsQuery,
      status: selectedStatus as EBatchPlatformStatus,
      isFetching: setIsLoading,
    });

    if (
      !isResponseArrayAndSuccessful<
        TArrayResponseWithCount<TBatchGalleryLocation>
      >(responseStatus, responseData)
    ) {
      return;
    }

    const [
      batchGalleriesLocationsData,
      batchGalleriesLocationsDataCount,
    ] = responseData;

    const batchGalleriesLocationsDataNormalized = normalizeGalleryLocationToTable(
      batchGalleriesLocationsData,
    );

    setBatchGalleriesLocations(batchGalleriesLocationsDataNormalized);
    setBatchGalleriesLocationsCount(batchGalleriesLocationsDataCount);
  }, [
    batchGalleryId,
    status,
    batchGalleriesLocationsPage,
    batchGalleriesLocationsPageSize,
    batchGalleriesLocationsQuery,
    selectedStatus,
  ]);

  React.useEffect(() => {
    getBatchGalleriesLocations();
  }, [getBatchGalleriesLocations]);

  const tableOptions = React.useMemo(
    (): MUIDataTableOptions => ({
      count: batchGalleriesLocationsCount,
      page: batchGalleriesLocationsPage,
      selectableRows: 'none',
      search: false
    }),
    [batchGalleriesLocationsCount, batchGalleriesLocationsPage],
  );

  const tableColumns = React.useMemo(
    () => [...initialTableColumns],
    [initialTableColumns],
  );

  const handleSearchWithDebounce = debounce(
    (text: string) => setBatchGalleriesLocationsQuery(text),
    500,
  );

  return (
    <StyledGalleryBatchLocationsTable>
      {status === 'Cancelando' ? (
        'Processo de cancelamento em andamento...'
      ) : (
        <Table
          loading={isLoading}
          options={tableOptions}
          title={
            (
              <StyledHeader>
                <h3>Locais afetados</h3>
                <FiltersBar
                  selectedStatus={selectedStatus}
                  onChangeSearchQuery={handleSearchWithDebounce}
                  setSelectedStatus={setSelectedStatus}
                />
              </StyledHeader>
            ) as React.ReactNode & string
          }
          columns={tableColumns}
          data={batchGalleriesLocations}
          row={(rowData) => (
            <GalleryBatchLocationsRow
              data={rowData}
              batchId={batchGalleryId}
              updateBatch={updateBatchGallery}
            />
          )}
          setPageSize={setBatchGalleriesLocationsPageSize}
          setPage={setBatchGalleriesLocationsPage}
          setQuery={setBatchGalleriesLocationsQuery}
        />
      )}
    </StyledGalleryBatchLocationsTable>
  );
}
