import styled, { css } from 'styled-components';
import { lighten } from 'polished';
import { DateRange as DateRangeIcon } from '@material-ui/icons';

import { TInputBaseProps, TInputValidate } from '../../types/TInput';

type StyledInputCustomProps = Pick<
  TInputBaseProps,
  | 'border'
  | 'borderRadius'
  | 'disabled'
  | 'isDateInput'
  | 'borderAlert'
  | 'noBackground'
>;

export const StyledInput = styled.div<StyledInputCustomProps>`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  margin-bottom: 2rem;
  position: relative;

  &:last-of-type {
    margin-bottom: 0;
  }

  label {
    font-size: 1.5rem;
    font-weight: normal;
    color: ${(props) => props.theme.colors.black};
  }

  input {
    width: 100%;
    height: 4.1rem;
    padding: 0 1.5rem;
    border: none;

    ${(props) => props.noBackground
      && css`
        background: transparent;
      `}

    ${(props) => props.border
      && !props.borderAlert
      && css`
        border: 2px solid ${lighten(0.4, props.theme.colors.black)};
      `};

    ${(props) => props.border
      && props.borderAlert
      && css`
        border: 2px solid ${props.theme.colors.red};
      `};

    ${(props) => props.borderRadius
      && css`
        border-radius: 0.4rem;
      `};

    ${(props) => props.isDateInput
      && css`
        padding-left: 3.5rem;
      `}
  }

  .show-password-icon {
    position: absolute;
    right: 1.5rem;
    width: 2.5rem;
    height: 2.5rem;
    bottom: 0.8rem;
    z-index: 2;
    cursor: pointer;
  }
`;

export const StyledInputValidate = styled.span<Pick<TInputValidate, 'type'>>`
  margin-top: 0.3rem;
  font-size: 1rem;
  font-weight: 500;
  text-transform: uppercase;

  ${(props) => props.type === 'error'
    && css`
      color: ${props.theme.colors.red};
    `};
`;

export const StyledInputWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  position: relative;
`;

export const StyledDateRangeIcon = styled(DateRangeIcon)`
  width: 2rem;
  height: 2rem;
  position: absolute;
  left: 1rem;
`;
