import React from 'react';

export const fileExtensionIsValide = (file, extension) => {
  if (file?.name) {
    const { name } = file;

    const fileExtension = name.split('.')[1];

    if (fileExtension !== extension) {
      return {
        code: 'extension-no-valide',
        message: 'Extensão do arquivo não é válido',
      };
    }
  }
};

export const acceptedFilesItens = data => data.map(file => (
  <li key={file.path}>
    {file.path}
    {' '}
    -
    {file.size}
    {' '}
    bytes
  </li>
));

export const rejectionFilesItens = data => data.map(({ file, errors }) => (
  <li key={file.path}>
    {file.path}
    {' '}
    -
    {file.size}
    {' '}
    bytes
    <ul>
      {errors.map(e => (
        <li className="error-rejection" key={e.code}>{e.message}</li>
      ))}
    </ul>
  </li>
));
