import styled from "styled-components";
import { Link } from "react-router-dom";
import { rgba, lighten } from "polished";

export const StyledSimpleLink = styled(Link)`
    padding: 2rem;
    background: transparent;
    border-bottom: 1px dashed ${props => rgba(props.theme.colors.white, 0.2)};
    color: ${props => props.theme.colors.white};
    display: flex;
    align-items: center;
    width: 100%;

    &:hover,
    &:focus {
      color: ${props => props.theme.colors.white};
      text-decoration: none;
      background: ${props => lighten(0.16, props.theme.colors.black)};
    }

    .simple-link-icon {
      width: 2rem;
      height: 2rem;
      fill: ${props => props.theme.colors.white};
      margin-right: 0.5rem;
    }

    span {
      font-size: 1.4rem;
      line-height: 1;
      line-height: 1.8rem;
    }
`;