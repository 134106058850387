import axios from 'axios';

export default async function changePassword({
  accessToken,
  id,
  password,
  isLoading,
  feedbackMessage,
  setTokenLikeExpired,
}) {
  const requestOptions = {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  };

  try {
    if (feedbackMessage) feedbackMessage('Atualizando senha...', { variant: 'warning' });
    if (isLoading) isLoading(true);

    const { data } = await axios.put(
      `${process.env.REACT_APP_AUTH_API_URL}/changePassword`,
      { id, password },
      requestOptions,
    );

    if (feedbackMessage) feedbackMessage('Atualização de senha concluída com sucesso', { variant: 'success' });

    return data;
  } catch (err) {
    if (feedbackMessage) feedbackMessage('Não foi possível alterar a senha, tente novamente.', { variant: 'error' });

    if (err.response?.status === 401 && setTokenLikeExpired) setTokenLikeExpired();

    return err.response;
  } finally {
    if (isLoading) isLoading(false);
  }
}
