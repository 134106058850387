import axios from 'axios';

export default async function getMenu(accessToken, menuId) {
  const requestOptions = {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  };

  console.log(`${process.env.REACT_APP_MENUS_API_URL}/${menuId}`);

  const { data } = await axios.get(
    `${process.env.REACT_APP_MENUS_API_URL}/${menuId}`,
    requestOptions,
  );

  return data;
}
