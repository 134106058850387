import axios from 'axios';

import {
  TGetCompanyLocationsWithGoogle,
} from '../../types/TLocation';

export default async function getCompanyLocationsLinkedWithGoogle({
  accessToken,
  companyId,
  page = 0,
  pageSize = 100,
  query = '',
  setTokenLikeExpired,
  setIfFetching,
}: TGetCompanyLocationsWithGoogle) {
  try {
    if (setIfFetching) setIfFetching(true);

    const { data } = await axios.get(
      `${process.env.REACT_APP_LOCATIONS_API_URL}/user-linked-company-locations/${companyId}?page=${page}&pageSize=${pageSize}&query=${query}`,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      },
    );

    return data;
  } catch (err: any) {
    if (err.response?.status === 401 && setTokenLikeExpired) setTokenLikeExpired();

    return null;
  } finally {
    if (setIfFetching) setIfFetching(false);
  }
}
