import { TLocationEntity } from './TLocation';
import { TGoogleLocation } from './TGoogle';

import { TLocationsToFilter } from './TReviewsContext';

export enum TStarRating {
  STAR_RATING_UNSPECIFIED = 'STAR_RATING_UNSPECIFIED',
  ONE = 'ONE',
  TWO = 'TWO',
  THREE = 'THREE',
  FOUR = 'FOUR',
  FIVE = 'FIVE',
}

export type TReviewer = {
  profilePhotoUrl: string;
  displayName: string;
  isAnonymous: boolean;
};

export type TReviewReply = {
  comment: string;
  updateTime: string;
};

export type TReview = {
  name: string;
  reviewId: string;
  reviewer: TReviewer;
  starRating: TStarRating;
  comment: string;
  createTime: string;
  updateTime: string;
  reviewReply: TReviewReply;
};

export type TLocationReview = {
  name: string;
  review: TReview;
};

export type TGetLocationsToGetReviews = {
  locationsFiltered?: TLocationsToFilter[];
  googleLocations: TGoogleLocation[];
  ellegibleLocations: TLocationEntity[];
  userId: number;
  guestType: string | null;
  userProfileName: string;
};

export type TSensitiveWord = {
  id: string;
  word: string;
  isFiltering: boolean;
};

export type TGetGoogleLocationsReviews = {
  googleLocations: TGoogleLocation[];
  googleAccessToken: string;
  pageToken: string | null;
};

export type TEllegibleLocationForGuest = {
  location: TLocationEntity;
  guestType: string | null;
  userId: number;
  userProfileName: string;
}
