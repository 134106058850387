import React, { useState, useEffect } from 'react';

import { useSelector, useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { CircularProgress, Button } from '@material-ui/core';

import { Creators as CompanyActions } from '../../redux/ducks/company';

import createLinkModel from '../../services/linkModels/createLinkModel';
import updateLinkModel from '../../services/linkModels/updateLinkModel';
import addLinkModels from '../../services/companies/addLinkModels';
import getCompanyLinkModels from '../../services/companies/getCompanyLinkModels';

const buttonStyle = {
  background: '#F2F2F2',
  fontFamily: 'Poppins',
  fontWeight: 'bold',
  marginLeft: '1em',
};

export const useCreateLinkModel = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const params = useParams();
  const [loading, setLoading] = useState(false);
  const [form, setForm] = useState({
    name: '',
    facebook: '',
    instagram: '',
    twitter: '',
    youtube: '',
    linkedin: '',
    yelp: '',
    foursquare: '',
    website: '',
  });
  const [otherLinks, setOtherLinks] = useState([]);

  const { linkModelId } = params;

  const { user } = useSelector((state) => state.AuthReducer);
  const { activeCompany } = useSelector((state) => state.CompanyReducer);

  useEffect(() => {
    const fetchData = async () => {
      if (linkModelId) {
        let currentModel;
        const { link_models: companyLinkModels } = await getCompanyLinkModels(activeCompany.id);
        for (let i = 0; i < companyLinkModels.length; i++) {
          if (linkModelId === companyLinkModels[i].id.toString()) {
            currentModel = companyLinkModels[i];
          }
        }
        setForm({
          name: currentModel.name,
          facebook: currentModel.facebook,
          instagram: currentModel.instagram,
          twitter: currentModel.twitter,
          foursquare: currentModel.foursquare,
          linkedin: currentModel.linkedin,
          website: currentModel.website,
          yelp: currentModel.yelp,
          youtube: currentModel.youtube,
        });

        const otherLinksArray = [];
        const otherLinksSplit = currentModel.other_links.split(' - ');
        for (let j = 0; j < otherLinksSplit.length - 1; j++) {
          otherLinksArray.push({
            customOption: true,
            id: `new-id-${j}`,
            name: otherLinksSplit[j],
          });
        }
        setOtherLinks(otherLinksArray);
      }
    };

    fetchData();
  }, []);

  const handleChangeForm = ({ target: { name, value } }) => {
    setForm({ ...form, [name]: value });
  };

  const onBackArrowClicked = () => {
    history.push('/company/link-models');
  };

  const onCancelClicked = () => {
    history.push('/company/link-models');
  };

  const onModelCreated = async () => {
    let formattedOtherLinks = '';
    for (let i = 0; i < otherLinks.length; i++) {
      formattedOtherLinks = `${otherLinks[i].name} - ${formattedOtherLinks}`;
    }
    const linkModel = await createLinkModel(
      user.accessToken,
      form.name,
      form.facebook,
      form.instagram,
      form.twitter,
      form.youtube,
      form.linkedin,
      form.yelp,
      form.foursquare,
      form.website,
      formattedOtherLinks,
      user.user.id,
    );

    const company = await addLinkModels(user.accessToken, activeCompany.id, [
      linkModel.id,
    ]);

    dispatch(CompanyActions.setActiveCompany(company));

    setLoading(false);

    history.push('/company/link-models');
  };

  const onModelUpdated = async () => {
    setLoading(true);
    let formattedOtherLinks = '';

    for (let i = 0; i < otherLinks.length; i++) {
      formattedOtherLinks = `${otherLinks[i].name} - ${formattedOtherLinks}`;
    }

    await updateLinkModel(
      user.accessToken,
      linkModelId,
      form.name,
      form.facebook,
      form.instagram,
      form.twitter,
      form.youtube,
      form.foursquare,
      form.linkedin,
      form.yelp,
      form.website,
      formattedOtherLinks,
    );

    setLoading(false);
    history.push('/company/link-models');
  };

  const renderButton = () => {
    if (loading) {
      return <CircularProgress />;
    }
    return (
      <>
        <Button
          style={buttonStyle}
          variant="contained"
          onClick={onCancelClicked}
        >
          Cancelar
        </Button>
        <Button
          style={buttonStyle}
          disabled={form.name === ''}
          variant="contained"
          onClick={linkModelId ? onModelUpdated : onModelCreated}
        >
          {linkModelId ? 'Modificar Modelo' : 'Criar Modelo'}
        </Button>
      </>
    );
  };

  return {
    form,
    renderButton,
    handleChangeForm,
    otherLinks,
    setOtherLinks,
    onBackArrowClicked,
  };
};
