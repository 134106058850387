import React from 'react';
import * as R from 'ramda';

import {
  StyledWorkingIHoursDays,
  StyledWorkingHoursInfo,
  StyledTimeRange,
} from './working-hours-info-styles';

const WorkingHoursInfo = ({ workingHoursData, ticketType }) => {
  const [workingHoursInfos, setWorkingHoursInfos] = React.useState([]);

  React.useEffect(() => {
    if (R.isNil(workingHoursData) || workingHoursData === 'MUST_BE_EMPTY') return;
    const workingHoursToUpdate = workingHoursData.filter(
      (workingHoursDay) => {
        if (ticketType === 'ACTIVATION') return workingHoursDay;
        return workingHoursDay.will_update;
      },
    );

    const workingHoursDataMerged = R.mergeAll(
      workingHoursToUpdate.map((workingHoursDay) => ({
        [workingHoursDay.day_title || workingHoursDay.dayTitle]: workingHoursDay,
      })),
    );

    const workingHoursDataNormalized = Object.values(workingHoursDataMerged);

    setWorkingHoursInfos(workingHoursDataNormalized);
  }, [workingHoursData]);

  const isOpen = (openValue) => {
    if (R.isNil(openValue)) return null;

    return openValue;
  };

  const is24Hours = (is24HoursValue, isOpenStatus) => {
    if (R.isNil(is24HoursValue) || !isOpenStatus) return null;

    return is24HoursValue;
  };

  const isTimeRange = ({ timeRange, isOpenStatus, is24HoursStatus }) => {
    if (is24HoursStatus || !isOpenStatus) return false;
    if (R.isNil(timeRange)) return false;
    return true;
  };

  return (
    <StyledWorkingHoursInfo>
      <StyledWorkingIHoursDays>
        {workingHoursInfos.map((workingHoursDay) => (
          <li key={Math.random(Math.floor())}>
            <em>{workingHoursDay.day_title || workingHoursDay.dayTitle}</em>

            {isOpen(workingHoursDay.is_open || workingHoursDay.isOpen) ? <b>Aberto</b> : <b>Fechado</b>}
            {is24Hours(workingHoursDay.is_24_hours || workingHoursDay.is24Hours, isOpen(workingHoursDay.is_open || workingHoursDay.isOpen)) && <b>24 Horas</b>}

            {isTimeRange({
              timeRange: workingHoursDay.times,
              isOpenStatus: isOpen(workingHoursDay.is_open || workingHoursDay.isOpen),
              is24HoursStatus: is24Hours(workingHoursDay.is_24_hours || workingHoursDay.is24Hours),
            }) && (
              <StyledTimeRange>
                {workingHoursDay.times
                  .filter((time) => !(time.is_deleted || time.isDeleted))
                  .map((time) => (
                    <li key={time.id}>
                      <b>Abre:</b>
                      {' '}
                      {time.open}
                      {' '}
                      <span className="divider">-</span>
                      {' '}
                      <b>Fecha:</b>
                      {' '}
                      {time.close}
                    </li>
                  ))}
              </StyledTimeRange>
            )}
          </li>
        ))}
      </StyledWorkingIHoursDays>
    </StyledWorkingHoursInfo>
  );
};

export default WorkingHoursInfo;
