import styled from 'styled-components';
import { Paper } from '@material-ui/core';

import { devices } from '../../assets/styles/devices';

import { Button } from '../../components';

export const StyledCreateUser = styled.div`
  padding-bottom: 6rem;

  .loading {
    margin-right: 1rem;
  }
`;

export const StyledInputWrapper = styled.main`
    display: flex;
    flex-direction: row;
`;

export const StyledTitleLabel = styled.div`
  margin-top: 1rem;
`;

export const StyledUsageLabel = styled.span`
  margin-left: 2rem;
  white-space: nowrap;
`;

export const StyledButton = styled(Button)`
  width: fit-content;
  margin-top: 1rem;
`;

export const StyledTitle = styled.h3`
  font-weight: 700;
  font-size: 1.5rem;
  margin-bottom: 0;
  padding: 1rem;
`;

export const StyledFields = styled(Paper)`
  display: flex;
  flex-direction: column;
  padding: 3rem 2rem;
  row-gap: 2rem;
  margin: 1rem 0 1rem 0;

  .input-wrapper {
    margin-bottom: 0;
  }

  .row-container {
    flex-direction: row;
  }

  .borderButton {
    margin-top: 2.5rem;
  }
`;

export const StyledFieldsWrapper = styled.div`
  margin: 2rem;
`;

export const StyledRowUserInfoFieldsWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  column-gap: 2rem;
  row-gap: 2rem;

  @media ${devices.tabletLandscape} {
    grid-template-columns: repeat(2, 1fr);
  };

  @media ${devices.desktop} {
    grid-template-columns: repeat(3, 1fr);
  };
`;

export const StyledRowLimitFieldsWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  column-gap: 2rem;
  row-gap: 2rem;

  @media ${devices.tabletLandscape} {
    grid-template-columns: repeat(2, 1fr);
  };
`;

export const StyledPasswordWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

export const StyledTitleLabelWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

export const StyledGroupItemsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  margin-top: 2rem;
  row-gap: 1rem;
`;

export const StyledGoogleTokenWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  column-gap: 2rem;
`;

export const StyledLimitWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
`;

export const StyledLimitValue = styled.h5`
  color: ${(props) => props.theme.colors.grey};
  margin-top: 1rem;
`;
