import axios from 'axios';

export default async function createCompanyGuest({
  accessToken,
  userId,
  companyId,
  created_by = 1,
  setTokenLikeExpired,
}) {
  try {
    const requestOptions = {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    };

    const { data } = await axios.post(
      `${process.env.REACT_APP_GUESTS_API_URL}/company`,
      {
        userId, companyId, created_by,
      },
      requestOptions,
    );

    return data;
  } catch (err) {
    if (err.response?.status === 401 && setTokenLikeExpired) return setTokenLikeExpired();

    return err;
  }
}
