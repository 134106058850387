import type { TSelectPlatforms } from '../types/TPlatforms';

export const locationPostPlatformsMocked: TSelectPlatforms = [
  {
    id: 1,
    name: 'Google',
  },
  {
    id: 2,
    name: 'Facebook',
  },
  {
    id: 3,
    name: 'Foursquare',
  },
  {
    id: 4,
    name: 'Yelp',
  },
];
