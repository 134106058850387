import { Paper } from '@material-ui/core';
import styled from 'styled-components';

export const StyledGalleryBatch = styled.div`
  display: flex;
  flex-direction: column;
`;

export const StyledContent = styled.div`
  margin: 2rem;
`;

export const StyledTitleWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
`;

export const StyledTitle = styled.h2`
`;

export const StyledInfo = styled(Paper)`
  display: flex;
  padding: 2rem;
  align-items: center;
  margin-bottom: 2rem;
`;
