import {
  KeyboardDatePicker, MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import { lighten } from 'polished';
import styled, { css } from 'styled-components';

export const StyledInputDatePicker = styled.div<{isBackground: boolean}>`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  
  .MuiFormControl-root {
    margin: 0;
  }
  
  .MuiInputLabel-root {
    display: none !important;
  }

  .MuiInputBase-root {
    margin-top: 0;
    border: 2px solid ${props => lighten(0.4, props.theme.colors.black)};
    height: 4.1rem;
    padding-left: 1.5rem;
    font-size: 1.6rem;
    ${props => props.isBackground && css`background-color: #fff;`};
    
    &::before,
    &::after {
      display: none !important;
    }
  }
  
  .MuiButtonBase-root {
    &:focus {
      outline: none;
    }
  }
`;

export const StyledMuiPickersUtilsProvider = styled(MuiPickersUtilsProvider)``;

export const StyledKeyboardDatePicker = styled(KeyboardDatePicker)`
`;

export const StyledLabel = styled.label`
  font-size: 1.5rem;
  font-weight: normal;
  color: ${props => props.theme.colors.black};
`;
