export default function removeSpecialCharacters(lowerCaseAddress) {
  let newAddress = lowerCaseAddress;
  newAddress = newAddress.replace(/[áàãâ]/g, 'a');
  newAddress = newAddress.replace(/[éèê]/g, 'e');
  newAddress = newAddress.replace(/[íìî]/g, 'i');
  newAddress = newAddress.replace(/[óòôõ]/g, 'o');
  newAddress = newAddress.replace(/[úùû]/g, 'u');
  newAddress = newAddress.replace(/ç/g, 'c');
  const newAddressParts = newAddress.split(' ');
  if (
    newAddressParts[0] === 'av'
    || newAddressParts[0] === 'av.'
    || newAddressParts[0] === 'r.'
    || newAddressParts[0] === 'r'
  ) {
    newAddress = '';
    for (let i = 1; i < newAddressParts.length; i++) {
      if (i < newAddressParts.length - 1) newAddress = `${newAddress}${newAddressParts[i]} `;
      else newAddress = `${newAddress}${newAddressParts[i]}`;
    }
  }
  return newAddress;
}
