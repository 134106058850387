import React from "react";
import { IconButton } from "@material-ui/core";
import { Close as CloseIcon } from "@material-ui/icons";

import {
  StyledSnackbar,
  StyledAlert
} from "./snackbar-styles";

const Snackbar = ({ 
  message, 
  severity, 
  open, 
  autoHideDuration,
  onClose
}) => {
  return (
    <StyledSnackbar
      open={open} 
      autoHideDuration={autoHideDuration} 
      onClose={onClose}
      anchorOrigin={{ 
        vertical: 'top', 
        horizontal: 'right' 
      }}
    >
      <StyledAlert 
        onClose={onClose}
        severity={severity}
        elevation={6} 
        variant="filled" 
      >
        {message}
      </StyledAlert>
    </StyledSnackbar>
  );
};

export default Snackbar;