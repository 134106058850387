import React from 'react';
import * as R from 'ramda';
import { debounce } from 'lodash';
import { useSelector } from 'react-redux';
import FadeInEffect from 'react-fade-in';

import {
  Button,
  ConfirmDialog,
  IconButton,
  Pagination,
  OverlayLoading,
  Input,
} from '../../../../components';

import { useReviewsContext } from '../../../../contexts/reviews';
import { useAuth } from '../../../../hooks';

import AddAutomaticResponses from './add-automatic-response';
import Response from './response';

import { capitalizeName, dropRepeatsReviews } from './helpers';

import {
  StyledAutomaticResponse,
  StyledResponsesBox,
  StyledAutomaticResponseListing,
  StyledAutomaticResponseListingWrapper,
  StyledTitleAndClose,
  StyledNoResponseContainer,
  StyledQueryWrapper,
  StyledSelect,
} from './automatic-response-styles';

import getAutoResponsesFromCompany from '../../../../services/auto-responses/getAllFromCompany';
import deleteAutoResponse from '../../../../services/auto-responses/delete';
import createAutoResponse from '../../../../services/auto-responses/create';
import updateAutoResponse from '../../../../services/auto-responses/update';
import replyToReview from '../../../../services/google/reviews/replyToReview';
import isNilOrEmpty from '../../../../utils/is-null-or-empty';

const AutomaticResponse = ({
  handleShowAutomaticResponses,
  reviews,
  currentListBy,
}) => {
  const {
    starRating: { currentStarRating },
    reviewsChecked,
    setReviewsChecked,
    reviewsData,
    setReviewsData,
    setReviewsDataListing,
    enqueueSnackbar,
    googleUserAccessToken,
  } = useReviewsContext();

  const { userSetTokenLikeExpired, userAccessToken } = useAuth();

  const { activeCompanyId } = useSelector((state) => state.CompanyReducer);

  const [autoResponses, setAutoResponses] = React.useState([]);
  const [isAutomaticResponsesDialog, setIsAutomaticResponsesDialog] = React.useState(false);
  const [editAutomaticResponseDialog, setEditAutomaticResponseDialog] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [page, setPage] = React.useState(0);
  const [pageSize, setPageSize] = React.useState(10);
  const [pagesQuantity, setPagesQuantity] = React.useState(1);
  const [query, setQuery] = React.useState('');
  const [updateResponses, setUpdateResponses] = React.useState(null);
  const [activeResponse, setActiveResponse] = React.useState(null);
  const [selectedResponse, setSelectedResponse] = React.useState(null);
  const [company, setCompany] = React.useState(null);

  const [isReviewReply, setIsReviewReply] = React.useState(false);

  const [openDialogConfirmAutoResponse, setOpenDialogConfirmAutoResponse] = React.useState(false);

  const [currentSortStarsBy, setCurrentSortStarsBy] = React.useState('DESC');
  const listSortStarsBy = [
    { name: 'Crescente', value: 'ASC' },
    { name: 'Decrescente', value: 'DESC' },
  ];

  const [currentFilterStarsBy, setcurrentFilterStarsBy] = React.useState('');
  const starListFilterOptions = [
    { name: 'Todas', value: '0' },
    { name: '1', value: '1' },
    { name: '2', value: '2' },
    { name: '3', value: '3' },
    { name: '4', value: '4' },
    { name: '5', value: '5' },
  ];

  const getAutoResponses = React.useCallback(async () => {
    const data = await getAutoResponsesFromCompany({
      accessToken: userAccessToken,
      companyId: activeCompanyId,
      stars: currentFilterStarsBy,
      page,
      pageSize,
      query,
      orderBy: currentSortStarsBy,
      setTokenLikeExpired: userSetTokenLikeExpired,
      setIsFetching: setLoading,
    });

    if (R.isNil(data)) return;

    setAutoResponses(data[0]);
    setPagesQuantity(Math.ceil(data[1] / pageSize));
    setSelectedResponse(null);
  }, [page, updateResponses, currentSortStarsBy, currentFilterStarsBy, query]);

  React.useEffect(() => {
    getAutoResponses();
  }, [getAutoResponses]);

  const handleOpenAutomaticaResponsesDialog = () => {
    setIsAutomaticResponsesDialog(!isAutomaticResponsesDialog);
  };

  const handleOpenEditAutoResponseDialog = () => {
    setEditAutomaticResponseDialog(!editAutomaticResponseDialog);
  };

  const onAddResponseClicked = async (text, starValue) => {
    const inputData = {
      name: 'auto_response',
      text,
      company_id: activeCompanyId,
      star_rating: starValue,
    };

    const data = await createAutoResponse({
      accessToken: userAccessToken,
      inputData,
      setIsFetching: setLoading,
    });

    setUpdateResponses(data);
    enqueueSnackbar('Resposta automática criada com sucesso', {
      variant: 'success',
    });
    setIsAutomaticResponsesDialog(false);
  };

  const onDeleteResponseClicked = async (autoResponseId) => {
    const data = await deleteAutoResponse({
      accessToken: userAccessToken,
      autoResponseId,
      setIsFetching: setLoading,
    });
    setUpdateResponses(data);
    enqueueSnackbar('Resposta excluída com sucesso', { variant: 'success' });
  };

  const onEditResponseClicked = async (autoResponseId) => {
    setActiveResponse(autoResponseId);
    setEditAutomaticResponseDialog(true);
  };

  const onConfirmEditResponseClicked = async (
    autoResponseId,
    text,
    starValue,
  ) => {
    const inputData = { text, star_rating: starValue };
    const data = await updateAutoResponse({
      accessToken: userAccessToken,
      autoResponseId,
      inputData,
      setIsFetching: setLoading,
    });
    setUpdateResponses(data);
    enqueueSnackbar('Resposta atualizada com sucesso', { variant: 'success' });
    setEditAutomaticResponseDialog(false);
  };

  const answerReviews = React.useCallback(async () => {
    if (R.isNil(selectedResponse) || R.isEmpty(selectedResponse)) return;

    setOpenDialogConfirmAutoResponse(false);

    const reviewsToResponse = R.isEmpty(reviewsChecked) ? reviews : reviewsChecked;

    const newReviewByReply = await Promise.all(
      reviewsToResponse.map(async (reviewItem) => {
        const { name, review } = reviewItem;

        const [, userId, , locationId] = name.split('/');

        const {
          reviewer: { displayName: reviwerName },
        } = review || {};

        const replyText = selectedResponse?.text || '';
        const isNameReviewer = R.includes(
          '[[fulano]]',
          replyText.toLowerCase(),
        );

        const isCompanyName = R.includes(
          '[[local]]',
          replyText.toLowerCase()
        );

        let replyTextWithProperties;

        if (isNameReviewer) {
          const reviewerNameCapitalized = capitalizeName(reviwerName);
          replyTextWithProperties = replyText.replace(
            /\[\[fulano\]\]/gi,
            reviewerNameCapitalized,
          );
        }

        if (isCompanyName) {
          replyTextWithProperties = replyText.replace(
            /\[\[local\]\]/gi,
            name,
          );
        }

        const replyToReviewResponse = await replyToReview({
          accessToken: googleUserAccessToken,
          userId,
          locationId,
          reviewId: review.reviewId,
          reply: (isNameReviewer || isCompanyName) ? replyTextWithProperties : selectedResponse.text,
          setIsReply: setIsReviewReply,
        });

        return {
          ...reviewItem,
          reviewId: review.reviewId,
          review: {
            ...reviewItem.review,
            reviewReply: {
              ...replyToReviewResponse,
            },
          },
        };
      }),
    );

    const repliedReviews = newReviewByReply
      .filter(reply => !isNilOrEmpty(reply?.review?.reviewReply));

    const someErrorOnReplying = newReviewByReply
      .some(reply => isNilOrEmpty(reply?.review?.reviewReply));

    if (!R.isNil(repliedReviews) || R.isEmpty(repliedReviews)) {
      setReviewsChecked(prevChecked => {
        return prevChecked.filter(({ review }) => {
          return newReviewByReply
            .find(({ review: repliedReview }) => (repliedReview.reviewId === review.reviewId && isNilOrEmpty(repliedReview?.reviewReply)));
        });
      });

      const newReviewsByLocation = dropRepeatsReviews({
        newColletion: repliedReviews,
        currentColletion: reviewsData,
      });

      setReviewsData(newReviewsByLocation);

      if (!reviewsChecked.length) {
        setReviewsDataListing([]);
      } else {
        const newReviewsByLocationFiltered = R.filter(
          ({ review }) => R.isNil(review.reviewReply),
          dropRepeatsReviews({
            newColletion: repliedReviews,
            currentColletion: reviews,
          }),
        );
        setReviewsDataListing(newReviewsByLocationFiltered);
      }

      setSelectedResponse(null);

      if (someErrorOnReplying) {
        enqueueSnackbar('Houve um problema em algumas das respostas automáticas aplicadas', {
          variant: 'warning',
        });
      } else {
        enqueueSnackbar('Respostas automáticas aplicadas com sucesso', {
          variant: 'success',
        });
      }
    }
  }, [
    reviewsData,
    googleUserAccessToken,
    selectedResponse,
    reviewsChecked,
  ]);

  const handleConfirmAutoResponse = () => {
    if (R.isNil(selectedResponse) || R.isEmpty(selectedResponse)) {
      enqueueSnackbar('Nenhuma resposta automática selecionada', {
        variant: 'warning',
      });
      return;
    }

    setOpenDialogConfirmAutoResponse(true);
  };

  const handleChangeSearchInput = debounce((text) => {
    setPage(0);
    setQuery(text);
  }, 500);

  const handleChangeStarSort = (event) => {
    setCurrentSortStarsBy(event.target.value);
  };

  const handleChangeStarFilter = (event) => {
    setPage(0);
    setcurrentFilterStarsBy(event.target.value);
  };

  return (
    <FadeInEffect>
      <ConfirmDialog
        open={openDialogConfirmAutoResponse}
        onClose={() => setOpenDialogConfirmAutoResponse(false)}
        title="Respostas Automáticas"
        onCancel={() => setOpenDialogConfirmAutoResponse(false)}
        onConfirm={answerReviews}
        confirmTitle="Aplicar"
        confirmType="blue"
      >
        Tem certeza que deseja aplicar estas respostas automáticas?
      </ConfirmDialog>

      <StyledAutomaticResponse>
        <StyledTitleAndClose>
          <StyledQueryWrapper>
            <StyledSelect
              className="list-by-select"
              label="Ordenar por:"
              border
              name="type"
              options={listSortStarsBy}
              value={currentSortStarsBy}
              onChange={handleChangeStarSort}
            />

            <StyledSelect
              className="list-by-select"
              label="Classificação:"
              border
              name="type"
              options={starListFilterOptions}
              value={currentFilterStarsBy}
              onChange={handleChangeStarFilter}
            />

            <Input
              className="search-to-auto-responses"
              inputOptions={{
                type: 'text',
              }}
              onChange={({ target: { value } }) => {
                handleChangeSearchInput(value);
              }}
              label="Filtrar Respostas"
              placeholder="Buscar"
              border
            />
          </StyledQueryWrapper>

          <IconButton
            icon="Close"
            tooltip="Fechar respostas automáticas"
            onClick={handleShowAutomaticResponses}
          />
        </StyledTitleAndClose>

        <StyledResponsesBox>
          {loading && <OverlayLoading />}
          {!R.isEmpty(autoResponses) ? (
            <StyledAutomaticResponseListingWrapper>
              <StyledAutomaticResponseListing>
                {autoResponses.map((response) => (
                  <Response
                    key={response.id}
                    className="automatic-response-item"
                    as="li"
                    loading={loading}
                    onDeleteClicked={() => onDeleteResponseClicked(response.id)}
                    onEditClicked={() => onEditResponseClicked(response.id)}
                    id={response.id}
                    text={response.text}
                    star_rating={response.star_rating}
                    createdBy={response.created_by}
                    createdAt={response.date_created}
                    selectedResponse={selectedResponse}
                    setSelectedResponse={setSelectedResponse}
                  />
                ))}
              </StyledAutomaticResponseListing>

              <Pagination
                page={page}
                setPage={setPage}
                pagesQuantity={pagesQuantity}
              />
            </StyledAutomaticResponseListingWrapper>
          ) : (
            <StyledNoResponseContainer>
              Nenhuma resposta encontrada
            </StyledNoResponseContainer>
          )}

          <small
            className="see-all-responses"
            onClick={handleOpenAutomaticaResponsesDialog}
            role="presentation"
          >
            Adicionar uma nova resposta
          </small>
        </StyledResponsesBox>

        <AddAutomaticResponses
          open={editAutomaticResponseDialog}
          onClose={handleOpenEditAutoResponseDialog}
          onConfirmClicked={onConfirmEditResponseClicked}
          isUpdate
          id={activeResponse}
          loading={loading}
          feedbackMessage={enqueueSnackbar}
        />

        <AddAutomaticResponses
          open={isAutomaticResponsesDialog}
          onClose={handleOpenAutomaticaResponsesDialog}
          onConfirmClicked={onAddResponseClicked}
          loading={loading}
          feedbackMessage={enqueueSnackbar}
        />

        {!R.isEmpty(reviews)
          && currentListBy === 'unanswered'
          && currentStarRating.rateNumber !== 0 && (
            <Button
              onClick={handleConfirmAutoResponse}
              className="apply-automatic-response-button"
              disabled={
                loading
                || R.isEmpty(autoResponses)
                || R.isNil(selectedResponse)
                || R.isEmpty(reviews)
              }
            >
              Aplicar resposta automática
            </Button>
          )}

        {isReviewReply && (
          <OverlayLoading textToLoading="Aplicando resposta automática..." />
        )}
      </StyledAutomaticResponse>
    </FadeInEffect>
  );
};

export default AutomaticResponse;
