import React from 'react';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import * as R from 'ramda';

import {
  StyledAccordion,
  StyledAccordionSummary,
  StyledAccordionDetails,
} from './log-error-data-accordion-styles';

type TLogErrorDataAccordionProps = {
  errorData: any;
};

export default ({ errorData }: TLogErrorDataAccordionProps) => (
  <StyledAccordion>
    <StyledAccordionSummary
      expandIcon={<ExpandMoreIcon />}
      aria-controls="error-information-content"
      id="error-information-header"
    >
      Visualizar informações do erro
    </StyledAccordionSummary>
    <StyledAccordionDetails>
      {R.isNil(errorData) || R.isEmpty(errorData) ? (
        'Não foi possível obter detalhes técnicos para este erro.'
      ) : (
        <code>{JSON.stringify(errorData, null, 2)}</code>
      )}
    </StyledAccordionDetails>
  </StyledAccordion>
);
