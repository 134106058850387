import React from "react";

import ConfigCard from "../../../components/config-card";

import { StyledBIMonitor } from "./bi-monitor-styles";

const BIMonitor = ({ navigation }: any) => (
  <ConfigCard title="BI Monitor" onClick={() => navigation.push("/bi-monitor")}>
    <StyledBIMonitor>Monitor de Business Intelligence</StyledBIMonitor>
  </ConfigCard>
);

export default BIMonitor;
