import React from 'react';

import {
  SideMenu,
  HeaderMobile,
  MenuMobile,
} from '../../components';

import {
  StyledMainContainer,
  StyledContainer,
  StyledShadowOverlay,
  StyledWrapperContent,
} from './main-container-styles';

const MainContainer = ({ children }) => {
  const [menuMobileIsOpen, setMenuMobileIsOpen] = React.useState(false);

  return (
    <StyledMainContainer>
      <MenuMobile
        menuMobile={{
          open: menuMobileIsOpen,
          openOrClose: setMenuMobileIsOpen,
        }}
      />
      <StyledContainer menuMobileIsOpen={menuMobileIsOpen}>
        {menuMobileIsOpen && (
          <StyledShadowOverlay onClick={() => setMenuMobileIsOpen(false)} />
        )}

        <HeaderMobile
          menuMobile={{
            open: menuMobileIsOpen,
            openOrClose: setMenuMobileIsOpen,
          }}
        />
        <SideMenu />
        <StyledWrapperContent>
          {children}
        </StyledWrapperContent>
      </StyledContainer>
    </StyledMainContainer>
  );
};

export default MainContainer;
