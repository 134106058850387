import React from 'react';
import { Helmet } from 'react-helmet';

import { HeaderToolbar } from '../../components';

import { FacebookPlatform, GooglePlatform } from './platforms';

import {
  StyledGoogleAccessTokenProfile,
  StyledContent,
} from './platforms-link-with-location-styles';

const PlatformsLinkWithLocation = () => (
  <StyledGoogleAccessTokenProfile>
    <Helmet>
      <meta property="og:title" content="Perfis de acesso - SaaS Hublocal" />
      <meta property="og:description" content="Crie perfis de acesso para os seus locais" />

      <title>Vincular local com uma plataforma - SaaS Hublocal</title>
    </Helmet>

    <HeaderToolbar
      dropdownToggle
      dropdownToggleWithLocations
    />

    <StyledContent>
      <GooglePlatform />
      <FacebookPlatform />
    </StyledContent>
  </StyledGoogleAccessTokenProfile>
);

export default PlatformsLinkWithLocation;
