import styled, { css } from 'styled-components';
import { IconButton, Popover } from '@material-ui/core';
import { shade } from 'polished';

export const StyledIconButton = styled(IconButton)<{
  tooltipVariant: string;
  forceUseDefaultCursor?: boolean;
  iconSize: number;
}>`
  display: flex;
  flex-direction: column;
  width: 3.5rem;
  height: 3.5rem;

  &:focus {
    outline: none;
  }

  .icon-button__icon {
    fill: ${(props) => props.theme.colors.primary} !important;
    width: ${(props) => props.iconSize}rem;
    height: ${(props) => props.iconSize}rem;

    ${(props) => props.tooltipVariant === 'secondary' && css`
      fill: ${props.theme.colors.secondary} !important;
    `};

    ${(props) => props.tooltipVariant === 'red'
      && css`
        fill: ${props.theme.colors.red} !important;
      `};

    ${(props) => props.tooltipVariant === 'secondary'
      && css`
        fill: ${props.theme.colors.secondary} !important;
      `};

    ${(props) => props.tooltipVariant === 'green'
      && css`
        fill: ${props.theme.colors.green} !important;
      `};

    ${(props) => props.tooltipVariant === 'yellow'
      && css`
        fill: ${props.theme.colors.god} !important;
      `};

    ${(props) => props.tooltipVariant === 'black'
      && css`
        fill: ${props.theme.colors.black} !important;
      `};

    ${(props) => props.tooltipVariant === 'grey'
      && css`
        fill: ${props.theme.colors.grey} !important;
      `};
  }

  ${(props) => !!props.forceUseDefaultCursor
    && css`
      cursor: default !important;
    `};

  .success-icon {
    fill: ${(props) => props.theme.colors.green} !important;
    width: ${(props) => props.iconSize}rem;
    height: ${(props) => props.iconSize}rem;
  }

  .black-icon {
    fill: ${(props) => props.theme.colors.black} !important;
    width: ${(props) => props.iconSize}rem;
    height: ${(props) => props.iconSize}rem;
  }

  .warning-icon {
    fill: ${(props) => props.theme.colors.god} !important;
    width: ${(props) => props.iconSize}rem;
    height: ${(props) => props.iconSize}rem;
  }

  .error-icon {
    fill: ${(props) => props.theme.colors.red} !important;
    width: ${(props) => props.iconSize}rem;
    height: ${(props) => props.iconSize}rem;
  }

  .white-icon {
    fill: ${(props) => props.theme.colors.white} !important;
    width: ${(props) => props.iconSize}rem;
    height: ${(props) => props.iconSize}rem;
  }

  &.disabled,
  .disabled {
    fill: ${(props) => props.theme.colors.grey} !important;
  }
`;

export const StyledDisabledIcon = styled.div<{
  iconSize?: number;
}>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 3.5rem;
  height: 3.5rem;

  .disabled {
    width: ${(props) => props.iconSize}rem;
    height: ${(props) => props.iconSize}rem;
    fill: ${(props) => props.theme.colors.grey} !important;
  }
`;

export const StyledIconButtonPopover = styled(Popover)`
  .MuiPaper-root {
    box-shadow: 0px 2px 1px -1px rgb(0 0 0 / 20%),
      0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%);
  }
`;

export const StyledPopoverContent = styled.div`
  padding: 1rem 2rem;
  display: flex;
  flex-direction: column;
  max-width: 25rem;
  font-size: 1.6rem;
  line-height: 2rem;
  font-weight: 500;
  text-align: center;
`;

export const StyledPopoverFooter = styled.div`
  padding: 1.4rem 2rem 1.8rem;
  background: ${(props) => shade(0.05, props.theme.colors.white)};

  .icon-button-popover-button {
    margin-bottom: 1rem;

    &:last-child {
      margin-bottom: 0;
    }
  }

  .popover-loading-button {
    margin-right: 0.5rem;
  }
`;

export const StyledLabel = styled.p<{ variant: string }>`
  font-size: 1.25rem;
  margin-bottom: 0;
  color: ${(props) => props.theme.colors.primary};

  ${(props) => props.variant === 'red'
    && css`
      color: ${props.theme.colors.red} !important;
    `};

  ${(props) => props.variant === 'secondary'
    && css`
      color: ${props.theme.colors.secondary} !important;
    `};

  ${(props) => props.variant === 'yellow'
    && css`
      color: ${props.theme.colors.god} !important;
    `};

  ${(props) => props.variant === 'green'
    && css`
      color: ${props.theme.colors.green} !important;
    `};

  ${(props) => props.variant === 'black'
    && css`
      color: ${props.theme.colors.black} !important;
    `};
`;

export const StyledWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;
