import axios from 'axios';

import { TUpdateCompany } from '../../types/TCompany';

export default async function updateCompany({
  accessToken,
  companyId,
  name,
  website,
  data_studio,
  main_responsible_id,
  is_active = false,
  location_limit = 1000,
  setIsSaveEdition,
  setTokenLikeExpired,
  feedbackMessage,
}: TUpdateCompany) {
  try {
    if (setIsSaveEdition) setIsSaveEdition(true);
    if (feedbackMessage) feedbackMessage('Salvando edição...', { variant: 'warning' });

    const { data } = await axios.put(
      `${process.env.REACT_APP_COMPANIES_API_URL}/${companyId}`,
      {
        name,
        website,
        data_studio,
        main_responsible_id,
        is_active,
        location_limit,
      },
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      },
    );

    if (feedbackMessage) feedbackMessage('Edição concluída com sucesso', { variant: 'success' });

    return data;
  } catch (err: any) {
    if (err.response?.status === 401 && setTokenLikeExpired) setTokenLikeExpired();
  } finally {
    if (setIsSaveEdition) setIsSaveEdition(false);
  }
}
