import React, { useCallback, useEffect } from 'react';
import * as R from 'ramda';
import { useSnackbar } from 'notistack';

import { History } from '../../../../../assets/icons';
import {
  TableBodyRow,
  IconButton,
  TableBodyCell,
  Loading,
  BatchStatusIcon,
  LogHistoryData,
  DestructiveActionDialog,
} from '../../../../../components';

import { useAuth } from '../../../../../hooks';

import type { EBatchPlatformStatus } from '../../../../../types/TBatchGoogleModules';
import type { THistoryDataFormat } from '../../../../../components/log-history-data/log-history-data';
import type { TTableRowData } from '../../../../../types/TTable';

import {
  constants,
  getDataRowCellValue,
  updateRowDataByColumn,
} from '../../../helpers';

import { StyledActionLoadingWrap } from '../../service-batch-row-styles';
import { StyledActionsWrap } from './service-batch-locations-row-styles';

import APIFindBatchLocationLog from '../../../../../services/google-batch-modules/batch-infos/find-batch-location-log';
import {
  deleteBatchLocationService,
  findOneServiceBatchLocation,
  forceUpdateServiceBatchLocation,
  updateServiceBatchLocation,
} from '../../../../../services/google-batch-modules/batch-services';

export type TServiceBatchSubtableRow = {
  data: TTableRowData;
  batchId: string;
  batchLocationId: string;
  updateBatch: () => Promise<void>;
};

export function ServiceBatchLocationsRow({
  data,
  updateBatch,
  batchId,
  batchLocationId,
}: TServiceBatchSubtableRow) {
  const { userAccessToken } = useAuth();
  const { enqueueSnackbar } = useSnackbar();

  const [currentRowData, setCurrentRowData] = React.useState<TTableRowData>([]);
  const [checked, setChecked] = React.useState(false);
  const [isFetchingStatus, setIsFetchingStatus] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);
  const [historyModalOpened, setHistoryModalOpened] = React.useState(false);
  const [isRemoveModalOpened, setIsRemoveModalOpened] = React.useState(false);

  const [histories, setHistories] = React.useState<THistoryDataFormat[]>([] as THistoryDataFormat[]);

  React.useEffect(() => {
    setCurrentRowData(data);
  }, [data]);

  const locationName = React.useMemo(
    () => getDataRowCellValue({ columnRef: 'location-name', dataRowCell: currentRowData }),
    [currentRowData],
  );

  const serviceBatchLocationStatus = React.useMemo(
    (): string | null => getDataRowCellValue({
      dataRowCell: currentRowData,
      columnRef: 'status',
    }),
    [currentRowData],
  );

  const getServiceBatchLocation = React.useCallback(async () => {
    setIsFetchingStatus(true);

    const findLocationResponse = await findOneServiceBatchLocation({
      serviceBatchLocationId: batchLocationId,
      userAccessToken,
    });

    if (findLocationResponse.status !== 'SUCCESS') return;

    const {
      status: batchLocationStatus,
    } = findLocationResponse.data;

    setCurrentRowData((prevState) => prevState.map((columnRowData) => {
      if (columnRowData?.columnRef === 'status') {
        return {
          ...columnRowData,
          value: batchLocationStatus,
        };
      }
      return columnRowData;
    }));

    getHistoryData(batchLocationId);
    setTimeout(() => setIsFetchingStatus(false), 500);
  }, [batchLocationId]);

  React.useEffect(() => {
    const getServiceBatchLocationByInterval = setInterval(() => {
      const cellDataStatus = currentRowData.find(
        (cellData) => cellData.columnRef === 'status',
      );
      if (
        cellDataStatus?.value === 'Pendente'
        || cellDataStatus?.value === 'Processando'
      ) { getServiceBatchLocation(); }
    }, constants.LOCATION_FETCH_STATUS_SETINTERVAL);

    return () => clearInterval(getServiceBatchLocationByInterval);
  }, [currentRowData]);

  const getHistoryData = React.useCallback(
    async (serviceBatchLocationId?: string) => {
      if (
        R.isNil(serviceBatchLocationId) ||
        R.isEmpty(serviceBatchLocationId)
      ) {
        return;
      }
      const response = await APIFindBatchLocationLog({
        userAccessToken,
        batchId,
        batchLocationId: serviceBatchLocationId,
        page: 0,
        pageSize: 9999,
      });

      if (response.status === 'ERROR') return;

      const [historiesResponse] = response.data;
      setHistories(historiesResponse);
    },
    []
  );

  const cancelServiceBatchLocations = React.useCallback(async () => {
    if (serviceBatchLocationStatus !== 'PENDING') return;
    const updateResponse = await updateServiceBatchLocation({
      serviceBatchLocationId: batchLocationId,
      status: 'CANCELLED' as EBatchPlatformStatus.CANCELLED,
      userAccessToken,
      setIsLoading,
    });

    getHistoryData(batchLocationId);

    if (updateResponse.status !== 'SUCCESS') {
      return enqueueSnackbar('Erro ao cancelar solicitação', { variant: 'error' });
    }

    const { data: updatedServiceBatchLocation } = updateResponse;

    setCurrentRowData((prevState: any) => updateRowDataByColumn({
      rowData: prevState,
      payload: {
        status: updatedServiceBatchLocation.status,
      },
    }));

    await updateBatch();
  }, [batchLocationId, serviceBatchLocationStatus]);

  const removeServiceFromLocation = React.useCallback(async () => {
    const canBeRemoved =
      serviceBatchLocationStatus === 'DONE' ||
      serviceBatchLocationStatus === 'ERROR_REMOVING';
    if (!canBeRemoved) return;
    setIsRemoveModalOpened(false);
    const updateResponse = await deleteBatchLocationService({
      serviceBatchLocationId: batchLocationId,
      userAccessToken,
      setIsLoading,
    });

    await getHistoryData(batchLocationId);

    if (updateResponse.status !== 'SUCCESS') {
      let errorMessage = 'Houve um erro ao remover o serviço';
      const { message } = updateResponse?.data || {};
      if (message === 'Serviço não encontrado')
        errorMessage = 'Serviço não encontrado. Você provavelmente já deletou este serviço para este local.';
      return enqueueSnackbar(errorMessage, { variant: 'error' });
    }

    const { data: { status } } = updateResponse;

    setCurrentRowData((prevState: any) =>
      updateRowDataByColumn({
        rowData: prevState,
        payload: {
          status,
        },
      })
    );

    await updateBatch();
  }, [batchLocationId, serviceBatchLocationStatus]);

  const handleForceUpdateServiceBatchLocations = React.useCallback(async () => {
    if (serviceBatchLocationStatus !== 'ERROR') return;
    const updateResponse = await forceUpdateServiceBatchLocation({
      serviceBatchLocationId: batchLocationId,
      userAccessToken,
      setIsLoading,
    });

    getHistoryData(batchLocationId);

    if (updateResponse?.status !== 'SUCCESS') {
      return enqueueSnackbar('Erro ao forçar atualização', {
        variant: 'error',
      });
    }

    const { data: { status } } = updateResponse;

    setCurrentRowData((prevState: any) =>
      updateRowDataByColumn({
        rowData: prevState,
        payload: {
          status,
        },
      })
    );
  }, [batchLocationId, serviceBatchLocationStatus]);


  useEffect(() => {
    getHistoryData(batchLocationId);
  }, [batchLocationId]);

  const handleServiceBatchLocationHistory = useCallback(() => {
    if (R.isNil(batchLocationId)) return;

    setHistoryModalOpened((state) => !state);
  }, [batchLocationId]);
  return (
    <TableBodyRow
      key={batchLocationId}
      checked={checked}
      setChecked={setChecked}
      hiddenCheckbox
    >
      {currentRowData
        && currentRowData.map(
          (dataColumnCell: any) => dataColumnCell?.visible && (
          <TableBodyCell
            key={`${batchLocationId}-${dataColumnCell.columnRef}`}
          >
            {dataColumnCell.columnRef === 'status'
              ? isFetchingStatus
                ? 'Atualizando status...'
                : <BatchStatusIcon batchType="service" status={dataColumnCell.value} />
              : dataColumnCell.value}
          </TableBodyCell>
          ),
        )}
      <TableBodyCell>
        <IconButton
          tooltip="Exibir histórico de ações"
          onClick={handleServiceBatchLocationHistory}
          CustomIcon={History}
          showCustomIcon
        />
      </TableBodyCell>
      <TableBodyCell>
        <StyledActionsWrap>
          {isLoading && (
            <StyledActionLoadingWrap>
              <Loading className="action-loading" />
              Aplicando ação...
            </StyledActionLoadingWrap>
          )}

          {!isLoading && (
            <>
              {serviceBatchLocationStatus === 'ERROR' && !isFetchingStatus && (
                <IconButton
                  tooltip="Forçar atualização"
                  onClick={handleForceUpdateServiceBatchLocations}
                  icon="CloudUpload"
                />
              )}
              {serviceBatchLocationStatus === 'PENDING' && (
                <IconButton
                  tooltip="Cancelar atualização"
                  onClick={cancelServiceBatchLocations}
                  icon="Close"
                />
              )}
              {(serviceBatchLocationStatus === 'DONE' ||
                serviceBatchLocationStatus === 'ERROR_REMOVING') && (
                <IconButton
                  tooltip={
                    serviceBatchLocationStatus === 'DONE'
                      ? 'Excluir serviço do local'
                      : 'Forçar exclusão de serviço do local'
                  }
                  onClick={() => setIsRemoveModalOpened(true)}
                  icon="Delete"
                />
              )}
            </>
          )}
        </StyledActionsWrap>
      </TableBodyCell>

      {historyModalOpened && (
        <LogHistoryData
          open={historyModalOpened}
          close={handleServiceBatchLocationHistory}
          batchLocationName={locationName}
          data={histories}
        />
      )}
      <DestructiveActionDialog
        open={isRemoveModalOpened}
        onClose={() => setIsRemoveModalOpened(false)}
        onConfirm={removeServiceFromLocation}
        warningMessage={[
          'Você está prestes a excluir o serviço cadastrado nesse local.',
          'Deseja continuar?',
        ]}
        confirmButtonLabel='SIM, DESEJO CONTINUAR'
        cancelButtonLabel='CANCELAR'
      />
    </TableBodyRow>
  );
}
