import React from 'react';
import * as R from 'ramda';
import { useDispatch } from 'react-redux';

import APIWorkingHoursCreateTimesRange from '../../../../services/workingHours/create-working-hours-times-range';
import APICreateWorkingHours from '../../../../services/workingHours/createWorkingHours';
import APIUpdateLocation from '../../../../services/locations/updateLocation';

import { Creators as LocationActions } from '../../../../redux/ducks/location';

import { useAuth } from '../../../../hooks';

import WorkingHoursContext from './working-hours-context';
import SpecialDates from './special-dates';

import { workingHoursMocked, sortByDay } from './helpers';

import OpeningHours from './opening-hours';

import { StyledLocationProfile } from './working-hours-styles';

const WorkingHours = ({ locationFormData, updatedWorkingHours }, ref) => {
  const reduxDispatch = useDispatch();

  const { userAccessToken, userSetTokenLikeExpired } = useAuth();

  const [currentLocationData, setCurrentLocationData] = React.useState(null);
  const [workingHoursPerDay, setWorkingHoursPerDay] = React.useState(workingHoursMocked);
  const [initialWorkingHours, setInitialWorkingHours] = React.useState(workingHoursMocked);
  const [specialDates, setSpecialDates] = React.useState(null);

  const [isCreateWorkingHours, setIsCreateWorkingHours] = React.useState(false);
  const [isCreateWorkingHoursText, setIsCreateWorkingHoursText] = React.useState('Processando...');

  const workingHoursProviderValue = {
    workingHoursPerDay,
    setWorkingHoursPerDay,
    specialDates,
    setSpecialDates,
    setInitialWorkingHours,
    initialWorkingHours,
  };

  const createWorkingHours = React.useCallback(async () => {
    const newWorkingHoursThisLocation = await Promise.all(
      workingHoursMocked.map(async (workingHoursDay) => {
        const {
          times,
          is_open: isOpen,
          is_replicated: isReplicated,
          day_title: dayTitle,
          is_24_hours: is24Hours,
          dropdown_to_bottom: dropdownToBottom,
        } = workingHoursDay;

        setIsCreateWorkingHoursText('Criando faixas de horários...');

        const newTimesThisWorkingHoursDay = await Promise.all(
          times.map(async ({ open, close }) => {
            const newTimeResponse = await APIWorkingHoursCreateTimesRange({
              accessToken: userAccessToken,
              setTokenLikeExpired: userSetTokenLikeExpired,
              open,
              close,
              isCreateWorkingHours: setIsCreateWorkingHours,
            });

            if (R.isNil(newTimeResponse)) return null;

            return {
              ...newTimeResponse,
              is_deleted: false,
            };
          }),
        );

        setIsCreateWorkingHoursText('Criando horário por dia...');

        const newWorkingHoursDay = await APICreateWorkingHours({
          accessToken: userAccessToken,
          setTokenLikeExpired: userSetTokenLikeExpired,
          isOpen,
          isReplicated,
          times: newTimesThisWorkingHoursDay,
          dropdownToBottom,
          is24Hours,
          dayTitle,
          isCreateWorkingHours: setIsCreateWorkingHours,
        });

        if (R.isNil(newWorkingHoursDay)) return null;

        return {
          ...newWorkingHoursDay,
        };
      }),
    );

    if (
      R.isNil(newWorkingHoursThisLocation)
      || R.isEmpty(newWorkingHoursThisLocation)
    ) return;

    setIsCreateWorkingHoursText(
      'Salvando horários de funcionamento neste local...',
    );

    if (R.isNil(locationFormData)) return;

    const updateLocationResponse = await APIUpdateLocation({
      accessToken: userAccessToken,
      setTokenLikeExpired: userSetTokenLikeExpired,
      locationId: locationFormData.id,
      inputData: {
        working_hours: newWorkingHoursThisLocation,
      },
      setIsFetching: setIsCreateWorkingHours,
      shouldNotGenerateRequest: true,
    });

    if (R.isNil(updateLocationResponse)) return;

    reduxDispatch(LocationActions.setActiveLocation(updateLocationResponse));
    setWorkingHoursPerDay(
      newWorkingHoursThisLocation.sort((prevDay, nextDay) => sortByDay({ prevDay, nextDay })),
    );
  }, []);

  const isUpdateCurrentLocationData = () => {
    if (!R.isNil(locationFormData) && !R.isNil(currentLocationData)) {
      return locationFormData.id !== currentLocationData.id;
    }
  };

  React.useEffect(() => {
    if (R.isNil(locationFormData)) return;

    if (isUpdateCurrentLocationData() || updatedWorkingHours) {
      setCurrentLocationData(locationFormData);

      return;
    }

    if (!R.isNil(currentLocationData)) return;

    setCurrentLocationData(locationFormData);
  }, [locationFormData, currentLocationData]);

  const appendIsDeletedInWorkingHours = (workingHours) => {
    const newWorkingHours = workingHours.map((workingHoursDay) => {
      const { times } = workingHoursDay;

      if (R.isEmpty(times) || R.isNil(times)) {
        return {
          ...workingHoursDay,
          times: [
            {
              id: 'a',
              open: '00:00',
              close: '00:00',
              is_deleted: false,
            },
          ],
        };
      }

      const newTimes = times.map((time) => ({ ...time, is_deleted: false }));

      return {
        ...workingHoursDay,
        times: newTimes,
      };
    });

    return newWorkingHours.sort((prevDay, nextDay) => sortByDay({ prevDay, nextDay }));
  };

  React.useEffect(() => {
    if (R.isNil(currentLocationData)) return;

    const { working_hours: workingHours } = currentLocationData;

    if (!R.isNil(workingHours) && !R.isEmpty(workingHours)) {
      setWorkingHoursPerDay(appendIsDeletedInWorkingHours(workingHours));

      return;
    }

    createWorkingHours();
  }, [currentLocationData]);

  React.useImperativeHandle(ref, () => ({
    workingHours: [...workingHoursPerDay],
    specialDates,
    initialWorkingHours,
  }));

  return (
    <StyledLocationProfile>
      <WorkingHoursContext.Provider value={workingHoursProviderValue}>
        <OpeningHours
          isCreateOpeningHours={isCreateWorkingHours}
          isCreateOpeningHoursStatus={isCreateWorkingHoursText}
        />
      </WorkingHoursContext.Provider>
    </StyledLocationProfile>
  );
};

export default React.forwardRef(WorkingHours);
