import axios from 'axios';

export default async function addCompanies(
  {
    accessToken,
    companyGroupId,
    company_ids,
    setTokenLikeExpired,
    feedbackMessage,
  },
) {
  const requestOptions = {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  };

  try {
    const { data } = await axios.post(
      `${process.env.REACT_APP_COMPANY_GROUPS_API_URL}/company/add/${companyGroupId}`,
      { company_ids },
      requestOptions,
    );

    return data;
  } catch (err) {
    if (err.response?.status === 401 && setTokenLikeExpired) setTokenLikeExpired();
    if (err.response?.status === 403) feedbackMessage('Falha ao vincular empresa: Você já alcançou o limite de empresas disponíveis', { variant: 'error' });

    console.log(err.response);

    return err;
  }
}
