const initialFieldScheme = {
  disabled: false,
  required: false,
  type: 'text',
  disclaimer: '',
  defaultValue: '',
  name: 'input-name',
  nickname: 'Input Name',
  id: 'input-id',
  label: 'input-label',
  hasborder: 'no',
  hasradius: 'no',
  password: false,
  mask: false,
  email: false,
  placeholder: 'input-placeholder',
};

export default initialFieldScheme;
