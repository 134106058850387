import React from 'react';

import { StyledFiltersContainer, StyledDatesWrapper } from './last-sync-filter-styles';

import {
  Input, Select, InputDatePicker, SelectMultiple,
} from '../../../../components';

import { StatusOptions, TypeOptions, TLastSyncFilterProps } from './helpers';

const LastSyncFilter = ({
  statusLabel,
  toPeriod,
  setToPeriod,
  ofPeriod,
  setOfPeriod,
  selectedStatusFilter,
  selectedTypeFilter,
  setSelectedStatusFilter,
  setSelectedTypeFilter,
  typeLabel,
}: TLastSyncFilterProps) => (
  <StyledFiltersContainer>

    <SelectMultiple<any>
      name="type-update-filter"
      value={selectedTypeFilter}
      label={typeLabel}
      options={TypeOptions ?? []}
      onChange={(target) => setSelectedTypeFilter(target)}
      border
      className="filter-content"
    />

    <Select<any>
      name="type-update-filter"
      value={selectedStatusFilter}
      label={statusLabel}
      options={StatusOptions ?? []}
      onChange={(event) => setSelectedStatusFilter(event.target.value === 'Selecione uma opção' ? '' : event.target.value)}
      border
      className="filter-content"
    />

    <StyledDatesWrapper>
      <InputDatePicker
        selectedDate={ofPeriod}
        setSelectedDate={setOfPeriod}
        label="De:"
        maxDate={toPeriod || null}
        className="input-date-picker"
      />

      <InputDatePicker
        selectedDate={toPeriod}
        setSelectedDate={setToPeriod}
        minDate={ofPeriod || null}
        label="Até:"
        className="input-date-picker"
      />
    </StyledDatesWrapper>
  </StyledFiltersContainer>
);

export default LastSyncFilter;
