import { isEmpty } from 'lodash';
import * as R from 'ramda';
import { TFieldsName } from './types';

export const fieldsName: TFieldsName = {
  infoBasic: {
    name: { order: 1, name: 'Nome do local' },
    phone: { order: 2, name: 'Telefone Principal' },
    secondary_phone: { order: 3, name: 'Telefone Secundário' },
    store_code: { order: 4, name: 'Código do Local' },
    email: { order: 17, name: 'Email' },
    long_description: { order: 28, name: 'Descrição' },
    short_description: { order: 29, name: 'Descrição curta' },
  },
  categories: {
    categoriesGroup: { order: 4, name: 'Agrupamento de Categorias' },
    google_categories: { order: 5, name: 'Categorias do Google' },
    facebook_categories: { order: 6, name: 'Categorias do Facebook' },
    foursquare_categories: { order: 7, name: 'Categorias do Foursquare' },
  },
  addressInfo: {
    zip_code: { order: 11, name: 'CEP' },
    country_code: { order: 14, name: 'País' },
    state_code: { order: 13, name: 'Código do Estado' },
    city: { order: 12, name: 'Cidade' },
    neighborhood: { order: 10, name: 'Bairro' },
    address1: { order: 8, name: 'Logradouro' },
    address2: { order: 9, name: 'Complemento' },
    does_not_serve_at_this_address: { order: 15, name: 'Atende nesse endereço?' },
    google_service_area: { order: 17, name: 'Área de Cobertura' },
  },
  special_dates: { order: 15, name: 'Datas especiais' },
  working_hours: { order: 16, name: 'Horário de funcionamento' },
  links: {
    website: { order: 18, name: 'Website' },
    google: { order: 19, name: 'Google' },
    facebook: { order: 20, name: 'Facebook' },
    foursquare: { order: 21, name: 'Foursquare' },
    instagram: { order: 22, name: 'Instagram' },
    linkedin: { order: 23, name: 'LinkedIn' },
    youtube: { order: 24, name: 'Youtube' },
    other_links: { order: 25, name: 'Outros links' },
    yelp: { order: 26, name: 'Yelp' },
    twitter: { order: 27, name: 'Twitter' },
  },
  other_links: {
    menu: { order: 30, name: 'Cardápio' },
    reservations: { order: 31, name: 'Reservas' },
    advanceorder: { order: 32, name: 'Pedido antecipado' },
    schedule: { order: 33, name: 'Agendamento' },
  },
};

export const infoBasic = [
  'NAME',
  'PHONE',
  'SECONDARY_PHONE',
  'STORE_CODE',
  'LONG_DESCRIPTION',
];

export const categories = [
  'GOOGLE_CATEGORIES',
  'FACEBOOK_CATEGORIES',
  'FOURSQUARE_CATEGORIES',
];

export const addressInfo = [
  'ZIP_CODE',
  'COUNTRY_CODE',
  'ADDRESS1',
  'ADDRESS2',
  'NEIGHBORHOOD',
  'CITY',
  'STATE_CODE',
  'DOES_NOT_SERVE_AT_THIS_ADDRESS',
  'GOOGLE_SERVICE_AREA',
];

export const links = [
  'WEBSITE',
  'FACEBOOK',
  'INSTAGRAM',
  'TWITTER',
  'YOUTUBE',
  'FOURSQUARE',
  'LINKEDIN',
  'YELP',
  'OTHER_LINKS',
  'EMAIL',
];

export const schedule = [
  'WORKING_HOURS',
];

export const special_dates = [
  'SPECIAL_DATES',
];

export const splitDataIntoSections = (array: any[]) => array.map((fieldData) => {
  if (infoBasic.includes(fieldData.label)) return { ...fieldData, groupingValue: 'infoBasic' };
  if (categories.includes(fieldData.label)) return { ...fieldData, groupingValue: 'categories' };
  if (addressInfo.includes(fieldData.label)) return { ...fieldData, groupingValue: 'addressInfo' };
  if (links.includes(fieldData.label)) return { ...fieldData, groupingValue: 'links' };
  if (schedule.includes(fieldData.label)) return { ...fieldData, groupingValue: 'schedule' };
  if (special_dates.includes(fieldData.label)) return { ...fieldData, groupingValue: 'special_dates' };
}).reduce((accumulator, fieldData) => {
  (accumulator[fieldData.groupingValue] = accumulator[fieldData.groupingValue] || []).push(fieldData);
  return accumulator;
}, {});

const handleSaasCategories = (value: any[]) => {
  const names: string[] = [];

  if (isEmpty(value)) return 'Nenhuma';

  value.forEach((valueArrayItem) => {
    if (valueArrayItem.name) names.push(valueArrayItem.name);
  });

  return names.join(', ');
};

const handleGoogleCategories = (value: any) => {
  const names: string[] = [];

  if (isEmpty(value)) return 'Nenhuma';

  value.forEach((valueArrayItem) => {
    if (valueArrayItem.name) names.push(valueArrayItem.name);
  });

  return names.join(', ');
};

export const normalizeSaasWorkingHoursData = (workingHoursData: any[]) => {
  const workingHoursDataMerged = R.mergeAll(
    workingHoursData.map((workingHoursDay) => ({
      [workingHoursDay.day_title || workingHoursDay.dayTitle]: workingHoursDay,
    })),
  );

  return Object.values(workingHoursDataMerged);
};

export const normalizeGoogleWorkingHoursData = (workingHoursData: any[]) => {
  const workingHoursDataMerged = R.mergeAll(
    workingHoursData.map((workingHoursDay) => ({
      [workingHoursDay.day_title || workingHoursDay.dayTitle]: workingHoursDay,
    })),
  );

  return Object.values(workingHoursDataMerged);
};

export const normalizeSpecialDates = (specialDatesData) => {
  const specialDatesMerged = R.mergeAll(specialDatesData.map(specialDate => ({ [specialDate.id]: specialDate })));

  return Object.values(specialDatesMerged);
};

const splitOtherLinks = (array, rawOtherLinks) => {
  const platformParsedValue = JSON.parse(array.filter((field) => field.label === 'OTHER_LINKS')[0].platform_data.value);
  const saasParsedValue = JSON.parse(array.filter((field) => field.label === 'OTHER_LINKS')[0].saas_data.value);

  const filteredPlatformValues = Object.keys(platformParsedValue)
    .filter((key) => platformParsedValue[key].trim() !== '')
    .reduce((res, key) => (res[key] = platformParsedValue[key], res), {});

  const filteredSaasValues = Object.keys(saasParsedValue)
    .filter((key) => saasParsedValue[key].trim() !== '')
    .reduce((res, key) => (res[key] = saasParsedValue[key], res), {});

  return Object.entries(filteredPlatformValues).map(([key, data]) => ({
    label: key,
    status: rawOtherLinks.status,
    order: fieldsName.other_links[key.toLowerCase()].order,
    platform_data: { label: key, value: data },
    saas_data: { label: key, value: filteredSaasValues[key] },
  }));
};

export const formatDataToList = (dataToConvert) => {
  if (!dataToConvert) return [];

  let otherLinks: any[] = [];

  const rawOtherLinks = dataToConvert.filter((field) => field.label === 'OTHER_LINKS')[0];

  const result = dataToConvert.filter((field) => field.label !== 'OTHER_LINKS').map((field, index) => {
    let fieldOrder = index;
    let { value: saasValue } = field.saas_data;
    let { value: platformValue } = field.platform_data;

    const { status, label, groupingValue } = field;

    const dataSection = fieldsName[groupingValue];

    if (dataSection[label.toLowerCase()]) {
      fieldOrder = dataSection[label.toLowerCase()].order;
    }

    if (label === 'GOOGLE_SERVICE_AREA') {
      saasValue = typeof saasValue === 'string' ? saasValue : saasValue.places.placeInfos.map((placeInfo) => placeInfo.placeName).join('  ||  ');
      platformValue = typeof platformValue === 'string' ? platformValue : platformValue.places.placeInfos.map((placeInfo) => placeInfo.placeName).join('  ||  ');
    }

    if (categories.includes(label)) {
      saasValue = handleSaasCategories(saasValue);
      platformValue = handleGoogleCategories(platformValue);
    }

    return {
      label,
      order: fieldOrder,
      saas_data: { value: saasValue, label },
      platform_data: { value: platformValue, label },
      status,
    };
  });

  if (rawOtherLinks) {
    otherLinks = splitOtherLinks(dataToConvert, rawOtherLinks);
    return [...result, ...otherLinks];
  }

  return result;
};
