import axios from 'axios';

export default async function updateLocationAccessLevelProfile({
  accessToken, id, name, description, rules,
}) {
  const requestOptions = {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  };

  const data = await axios.put(
    `${process.env.REACT_APP_LOCATION_ACCESS_LEVEL_PROFILE_API_URL}/${id}`,
    { name, description, rules },
    requestOptions,
  );

  return data;
}
