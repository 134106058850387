import styled, { css } from 'styled-components';

import { lighten } from 'polished';

export const StyledPlatform = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  border-bottom: 1px solid ${props => lighten(0.9, props.theme.colors.black)};

  &:last-of-type {
    border-bottom: 0;
  }
`;

export const StyledContent = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 2rem;
  background: ${props => lighten(0.9, props.theme.colors.black)};
  overflow: hidden;
  height: 0;
  transition: 0.3s ease;

  ${props => props.openContent && css`
    height: auto;
    overflow: visible;
    min-height: 0px;
    padding: 2rem;
    transition-duration: 204ms;
  `}

  .content-button {
    width: 100%;
    margin-bottom: 1rem;

    &:last-of-type {
      margin-bottom: 0;
    }
  }
`;
