import styled from 'styled-components';

export const StyledActionLoadingWrap = styled.div`
  display: flex;
  align-items: center;

  .action-loading {
    margin-right: 1rem;
  }
`;

export const StyledCellCustomButtons = styled.div`
  display: flex;

  button {
    margin: 0 1rem;
    color: ${props => props.theme.colors.white};

    svg {
      fill: ${props => props.theme.colors.white} !important;
    }

    &:last-of-type {
      margin-right: 0;
    }
  }
`;

export const StyledInformationsWrap = styled.div`
  display: flex;
  align-items: center;
`;
