import React from 'react';
import axios from 'axios';

type TCreateWorkingHoursTimesRange = {
  accessToken: string;
  open: string;
  close: string;
  setTokenLikeExpired?: () => void;
  isCreateWorkingHours?: React.Dispatch<React.SetStateAction<boolean>>;
};

export default async function createWorkingHoursTimesRange({
  accessToken,
  open,
  close,
  setTokenLikeExpired,
  isCreateWorkingHours,
}: TCreateWorkingHoursTimesRange) {
  const requestOptions = {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  };

  try {
    if (isCreateWorkingHours) isCreateWorkingHours(true);

    const { data } = await axios.post(
      `${process.env.REACT_APP_TIME_API_URL}`,
      {
        open,
        close,
      },
      requestOptions,
    );

    return data;
  } catch (err: any) {
    if (err.response?.status === 401 && setTokenLikeExpired) return setTokenLikeExpired();

    return null;
  } finally {
    if (isCreateWorkingHours) isCreateWorkingHours(false);
  }
}
