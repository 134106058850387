import styled from 'styled-components';
import { shade } from 'polished';

export const StyledOfferPostInfo = styled.div`
  display: flex;
  flex-direction: column;
  
  a {
    font-size: 1.2rem;
    color: ${props => shade(0.2, props.theme.colors.primary)};
  }
`;

export const StyledMediaItems = styled.ul`
  display: flex;
  flex-direction: column;
  
  li {
    font-size: 1.2rem;
    border-bottom: 0.1rem solid ${props => shade(0.1, props.theme.colors.white)};
    margin-bottom: 2rem;
    padding-bottom: 2rem;
    
    &:last-child {
      margin-bottom: 0;
      padding-bottom: 0;
      border-bottom: 0;
    }
  }
`;
