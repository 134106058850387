import React from 'react';
import { useSnackbar } from 'notistack';

import { TLocationReview, TSensitiveWord } from '../../types/TReviews';
import { TGoogleUserAccount, TGoogleLocation } from '../../types/TGoogle';

import type {
  TReviewsProvider,
  TReviewsContext,
  TLocationsToFilter,
} from '../../types/TReviewsContext';

import { ReviewsContext } from './reviews-context';
import { listAutomaticResponsesByState, starRatingState, getLocationsToGetReviews } from './helpers';
import { TLocationEntity } from '../../types/TLocation';

export const ReviewsProvider = ({ children }: TReviewsProvider) => {
  const { enqueueSnackbar } = useSnackbar();

  const [googleUserAccessToken, setGoogleUserAccessToken] = React.useState<
    string | null
  >(null);
  const [locationsToFilter, setLocationsToFilter] = React.useState<
    TLocationsToFilter[]
  >([]);
  const [googleUserAccounts, setGoogleUserAccounts] = React.useState<
    TGoogleUserAccount[]
  >([]);
  const googleLocationsByAccountRef = React.useRef<Record<string, TGoogleLocation[]>>({});
  const [reviewsData, setReviewsData] = React.useState<TLocationReview[]>([]);
  const [reviewsDataListing, setReviewsDataListing] = React.useState<
    TLocationReview[]
  >([]);
  const [googleLocationsByAccountId, setGoogleLocationsByAccountId] = React.useState({} as any);
  const [listAutomaticResponsesBy, setListAutomaticResponsesBy] = React.useState(listAutomaticResponsesByState);
  const [starRating, setStarRating] = React.useState(starRatingState);
  const [sensitiveWordsToFilter, setSensitiveWordsToFilter] = React.useState<
    TSensitiveWord[]
  >([]);
  const [reviewsChecked, setReviewsChecked] = React.useState<TLocationReview[]>(
    [],
  );
  const [googleReviewsInitialPageToken, setGoogleReviewsInitialPageToken] = React.useState<string | null>(null);
  const [ellegibleLocations, setEllegibleLocations] = React.useState<TLocationEntity[]>([]);

  const value: TReviewsContext = {
    googleUserAccessToken,
    setGoogleUserAccessToken,
    locationsToFilter,
    setLocationsToFilter,
    googleUserAccounts,
    setGoogleUserAccounts,
    googleLocationsByAccountRef,
    setReviewsDataListing,
    reviewsData,
    setReviewsData,
    reviewsDataListing,
    googleLocationsByAccountId,
    setGoogleLocationsByAccountId,
    listAutomaticResponsesBy,
    starRating,
    setStarRating,
    setListAutomaticResponsesBy,
    sensitiveWordsToFilter,
    setSensitiveWordsToFilter,
    enqueueSnackbar,
    reviewsChecked,
    setReviewsChecked,
    googleReviewsInitialPageToken,
    setGoogleReviewsInitialPageToken,
    ellegibleLocations,
    setEllegibleLocations,
    getLocationsToGetReviews,
  };

  return (
    <ReviewsContext.Provider value={value}>{children}</ReviewsContext.Provider>
  );
};
