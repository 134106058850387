import styled, { css } from 'styled-components';

export const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const StyledSelect = styled.select`
  height: 4.5rem;
  padding: 0 1.5rem;
  border: none;
  transition: 0.3s;
  padding-right: 4.2rem;

  background-color: transparent;
  width: 100%;
`;

export const StyledLabel = styled.label`
  font-size: 1.5rem;
  font-weight: normal;
  color: ${(props) => props.theme.colors.black};
`;

export const StyledWrapper = styled.div<{ hasBorder?: boolean; }>`
  ${props => props.hasBorder && css` border: 2px solid #666;`}
  padding-right: ${props => (props.hasBorder ? '1rem' : '0')};
  width: 100%;
`;
