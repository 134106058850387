import styled from 'styled-components';
import { lighten, rgba, shade } from 'polished';
import { devices } from '../../assets/styles/devices';

export const StyledGoogleServiceAreaInput = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;

  .disclaimer {
    color: ${(props) => props.theme.colors.red};
    font-size: 1rem;
  }

  label {
    font-size: 1.5rem;
  }
`;

export const StyledInputSearch = styled.div`
  display: flex;
  border: 2px solid ${(props) => lighten(0.4, props.theme.colors.black)};
  min-height: 4rem;
  position: relative;

  .clean-text-input {
    position: absolute;
    width: 2rem;
    height: 2rem;
    right: 2rem;
    bottom: 1rem;
    z-index: 2rem;
    cursor: pointer;
  }

  input {
    height: 100%;
    width: 100%;
    border: none;
    min-height: 3.62rem;
    padding: 1rem 3rem 1rem 2rem;
    font-size: 1.4rem;
  }
`;

export const StyledSelectedOptions = styled.div`
  display: grid;
  grid-template-columns: repeat(1, minmax(0, 1fr));
  row-gap: 1rem;
  column-gap: 2rem;
  margin-top: 1.4rem;

  @media ${devices.mobile} {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }

  @media ${devices.tabletLandscape} {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }

  @media ${devices.desktop} {
    grid-template-columns: repeat(4, minmax(0, 1fr));
  }
`;

export const StyledSelectedOption = styled.span`
  background: ${(props) => props.theme.colors.babyBlue};
  border-radius: 3rem;
  color: ${(props) => props.theme.colors.white};
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  font-weight: 300;
  font-size: 1.4rem;
  text-align: left;

  .option-display-name {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  padding: 0.5rem 1rem;

  svg {
    margin-left: 0.5rem;
    cursor: pointer;
  }
`;

export const StyledSelectedActionsWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const StyledListingSearch = styled.ul`
  background-color: red;
  display: flex;
  flex-direction: column;
  position: absolute;
  width: 100%;
  background: ${(props) => props.theme.colors.white};
  top: 98%;
  z-index: 10;
  box-shadow: 0 1px 3px 0px ${(props) => lighten(0.5, props.theme.colors.black)};
  max-height: 25rem;
  overflow-y: scroll;

  &:hover {
    box-shadow: 0 1px 3px 0px
      ${(props) => lighten(0.5, props.theme.colors.black)};
  }

  li {
    padding: 1rem 2rem;
    border-bottom: 1px solid ${(props) => rgba(props.theme.colors.black, 0.12)};
    font-size: 1.4rem;
    cursor: pointer;
    transition: 0.3s;

    &:hover {
      color: ${(props) => props.theme.colors.white};
      background: ${(props) => props.theme.colors.primary};
    }

    &:last-child {
      border: 0;
    }
  }
`;

export const StyledInputSwitchWrap = styled.div`
  display: flex;
  align-items: center;
  border-left: 2px solid ${(props) => lighten(0.4, props.theme.colors.black)};
  padding: 0rem 1rem;
`;

export const StyledDisabledInput = styled.div`
  font-size: 1.4rem;
  font-style: italic;
  font-weight: 500;
  display: block;
  align-self: center;
  margin-right: auto;
  margin-bottom: 0;
  color: ${(props) => shade(0.2, props.theme.colors.white)};

  padding: 1rem 2rem;
`;
