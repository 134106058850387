import React from 'react';

import { Dialog } from '../../../../components';
import { TServiceItemSaaS } from '../../../../types/TLocationService';
import { getServiceBillingType } from '../../../location-services/create-service-dialog/helpers';
import { getServiceDisplayPrice } from '../../../location-services/helpers';

import {
  StyledDialogContentContainer,
  StyledInfoContainer,
  StyledInfoTitle,
  StyledInfoColumn,
} from './service-batch-info-dialog-styles';

export type TServiceBatchInfoDialogProps = {
  isOpen: boolean;
  onClose: () => void;
  serviceItemInfo: TServiceItemSaaS;
};

export function ServiceBatchInfoDialog({
  isOpen,
  onClose,
  serviceItemInfo,
}: TServiceBatchInfoDialogProps) {
  const {
    price,
    freeFormServiceItem: {
      label: { displayName, description },
    },
  } = serviceItemInfo || {};
  const serviceBillingType = getServiceBillingType(price);
  return (
    <Dialog
      title="Informações do serviço"
      open={isOpen}
      onClose={onClose}
      isHeaderGray
    >
      <StyledDialogContentContainer>
        <StyledInfoContainer>
          <StyledInfoTitle>Nome do serviço *</StyledInfoTitle>
          <p>{displayName}</p>
        </StyledInfoContainer>
        <StyledInfoContainer isRow>
          <StyledInfoColumn>
            <StyledInfoTitle>Tipo de cobrança *</StyledInfoTitle>
            <p>{serviceBillingType}</p>
          </StyledInfoColumn>
          {serviceBillingType === 'Fixo' && price && (
            <StyledInfoColumn>
              <StyledInfoTitle>Preço</StyledInfoTitle>
              <p>{getServiceDisplayPrice(price)}</p>
            </StyledInfoColumn>
          )}
        </StyledInfoContainer>
        {description && (
          <StyledInfoContainer>
            <StyledInfoTitle>Descrição do Serviço</StyledInfoTitle>
            <p>{description}</p>
          </StyledInfoContainer>
        )}
      </StyledDialogContentContainer>
    </Dialog>
  );
}
