import styled from 'styled-components';

import { devices } from '../../assets/styles/devices';

export const StyledMyCompanyGroups = styled.main`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    height: 100%;
    width: 100%;
`;

export const StyledEmptyMyCompanyGroups = styled.div`
    display:flex;
    flex-direction:column;
    align-items: center;
    justify-content: center;
    margin:auto;
    padding:0 2rem;

    .add-company-button{
        max-width:30.3rem;
        text-transform: uppercase;
    }  
`;

export const StyledButtonsGroup = styled.div`
    display: flex;
    margin-left: auto;

    .button {
        margin-right: 1.5rem;

        &:last-of-type {
            margin-right: 0;
        }
    }
`;

export const StyledEmptyMyCompanyGroupsText = styled.span`
    font-size:1.6rem;
    font-weight:700;
    margin-bottom:4.1rem;
    text-align:center;
    max-width:58.9rem;

    @media ${devices.tablet} {
        font-size:2rem;
    }
`;

export const StyledHeader = styled.header`
  padding-top: 2rem;
  padding-bottom: 4rem;
  display: flex;
  width: 100%;

  h2 {
    font-size: 2rem;
    line-height: 2.2rem;
    font-weight: 700;
    margin-bottom: 2rem;

    @media ${devices.tabletLandscape} {
      margin-bottom: 0;
    }
  }
`;

export const StyledContent = styled.div`
  padding: 3rem 2rem 2rem;
  width: 100%;
`;