import axios from 'axios';
import { TServiceBatchLocation } from '../../../types/TServiceBatch';

export type TDeleteBatchLocationService = {
  userAccessToken: string;
  serviceBatchLocationId: string;
  setIsLoading?: React.Dispatch<React.SetStateAction<boolean>>;
};

type TSuccessResponse = {
  status: 'SUCCESS';
  data: TServiceBatchLocation;
};

type TErrorResponse = {
  status: 'ERROR';
  data: any;
};

export const deleteBatchLocationService = async ({
  serviceBatchLocationId,
  userAccessToken,
  setIsLoading,
}: TDeleteBatchLocationService): Promise<TSuccessResponse | TErrorResponse> => {
  try {
    if (setIsLoading) setIsLoading(true);

    const { data } = await axios.post(
      `${process.env.REACT_APP_SAAS_BATCH_GOOGLE_MODULES}/service-batch-locations/manual-delete/${serviceBatchLocationId}`,
      undefined,
      {
        headers: {
          Authorization: `Bearer ${userAccessToken}`,
        },
      }
    );

    return {
      status: 'SUCCESS',
      data,
    };
  } catch (err: any) {
    return {
      status: 'ERROR',
      data: err?.response?.data,
    };
  } finally {
    if (setIsLoading) setIsLoading(false);
  }
};
