import React from 'react';

import { OptionsObject, SnackbarKey, SnackbarMessage } from 'notistack';
import {
  TWorkingHoursEntity,
  TSpecialDateEntity,
} from './TWorkingHours';

import { TLocationGroupEntity } from './TLocationGroup';
import { TCategoryEntity } from './TCategory';
import { TInviteEntity } from './TInvite';
import { TGuestEntity } from './TGuest';
import { TCompanyEntity } from './TCompany';
import { TResponsibleEntity } from './TResponsible';
import { TPhotoEntity } from './TPhoto';
import { TVideoEntity } from './TVideo';
import { TProductEntity } from './TProduct';
import { TServiceEntity } from './TService';
import { TContractTypeEntity } from './TContractTypes';
import { TGoogleServiceAreaBusiness } from './TGoogle';
import { TLocationConnection } from './TPlatformsLinkWithLocation';

export type TGoogleVerificationStatus = 'NO_OWNERSHIP' | 'UNVERIFIED' | 'PENDING' | 'REVIEW' | 'VERIFIED';

export type TGrouper = {
  name: string;
  company: string[];
};

export type TCategory = {
  name: string;
  platform_id: string;
  platform: string;
};

export type TTag = {
  name: string;
};

export type TLocationEntity = {
  id: number;
  bi_id: string | null;
  is_replicated_on_BI: boolean;
  name: string;
  address1: string;
  address2: string;
  store_code: string;
  short_description: string;
  long_description: string;
  data_studio?: string | null;
  zip_code: string;
  neighborhood: string;
  city: string;
  state: string;
  country: string;
  phone: string;
  secondary_phone: string;
  tertiary_phone: string;
  email: string;
  sync_status: string;
  is_active: boolean;
  does_not_serve_at_this_address: boolean;
  google_service_area: TGoogleServiceAreaBusiness;
  google_verification_id: string;
  google_verification_status: TGoogleVerificationStatus;
  latitude: number;
  longitude: number;
  google_categories: string;
  facebook_categories: string;
  foursquare_categories: string;
  website: string;
  facebook: string;
  instagram: string;
  twitter: string;
  youtube: string;
  foursquare: string;
  linkedin: string;
  yelp: string;
  other_links: string;
  is_verified: boolean;
  facebook_page_id: string;
  google_account_id: string;
  google_location_id: string;
  foursquare_venue_id: string;
  labels: string;
  facebook_page_data: string;
  groupers: TGrouper[];
  categories: TCategory[];
  tags: TTag[];
  working_hours: TWorkingHoursEntity[];
  special_dates: TSpecialDateEntity[];
  location_group: TLocationGroupEntity[];
  category_groupings: TCategoryEntity[];
  invites: TInviteEntity[];
  guests: TGuestEntity[];
  photos: TPhotoEntity[];
  videos: TVideoEntity[];
  products: TProductEntity[];
  services: TServiceEntity[];
  company: TCompanyEntity;
  responsible: TResponsibleEntity;
  other_responsibles: TResponsibleEntity[];
  created_at: Date;
  updated_at: Date;
  contract_type: TContractTypeEntity;
  sync_permission: boolean;
  location_connections: TLocationConnection[];
};

export type TGetLocations = {
  accessToken: string;
  page?: number;
  pageSize?: number;
  query?: string;
  setIsFetching?: React.Dispatch<React.SetStateAction<boolean>>;
  setTokenLikeExpired?: (() => void) | null;
}

export type TGetLocationFromUser = {
  accessToken: string;
  userId: number;
  query?: string;
  setIsFetching?: React.Dispatch<React.SetStateAction<boolean>>;
  setTokenLikeExpired?: (() => void) | null;
}

export type TGetAllGroupLocation = {
  accessToken: string;
  locationGroupId: number;
  page?: number;
  pageSize?: number;
  query?: string;
  searchId?: number;
  setTokenLikeExpired?: (() => void) | null;
  setIfFetching?: (React.Dispatch<React.SetStateAction<boolean>>) | null;
  googleLinked?: boolean;
  googleLocationId?: number | null;
};

export type TGetAllLocationsFromCompanyWithoutLink = {
  accessToken: string;
  companyId: number;
  limit?: number;
  setIfFetching?: (React.Dispatch<React.SetStateAction<boolean>>) | null;
  setTokenLikeExpired?: (() => void) | null;

}

export type TGetGroupLocations = {
  accessToken: string;
  locationGroupId: number;
  page?: number;
  pageSize?: number;
  query?: string;
  setTokenLikeExpired?: (() => void) | null;
  setIfFetching?: (React.Dispatch<React.SetStateAction<boolean>>) | null;
};

export type TGetCompanyLocations = {
  accessToken: string;
  companyId: number;
  page?: number;
  pageSize?: number;
  query?: string;
  orderByName?: 'ASC' | 'DESC' | null;
  orderByContract?: 'ASC' | 'DESC' | null;
  filterByContract?: string | null;
  setTokenLikeExpired?: (() => void) | null;
  setIfFetching?: (React.Dispatch<React.SetStateAction<boolean>>) | null;
  isGuest?: boolean;
  userId?: number | null;
};

export type TRenderExistingLocation = TLocationEntity & {
  isDisabled: boolean;
}

export type TGetLocation = {
  accessToken: string;
  id: number;
  setIsFetching?: React.Dispatch<React.SetStateAction<boolean>>;
  feedbackMessage?: ((message: SnackbarMessage, options?: OptionsObject) => SnackbarKey) | null;
  setTokenLikeExpired: () => void;
};

export type TUpdateLocation = {
  accessToken: string;
  locationId: number;
  inputData: any;
  deleteGoogle?: boolean;
  deleteFacebook?: boolean;
  deleteFoursquare?: boolean;
  setIsFetching?: React.Dispatch<React.SetStateAction<boolean>>;
  setTokenLikeExpired?: () => void;
  feedbackMessage?: ((message: SnackbarMessage, options?: OptionsObject) => SnackbarKey) | null;
  googleAccessToken?: string;
  shouldNotGenerateRequest?: boolean;
}

export type TRemoveOtherResponsibles = {
   accessToken: string;
  locationId: number;
  responsibleIds: number[];
  setTokenLikeExpired: () => void;
  setIsRemovingResponsibles?: React.Dispatch<React.SetStateAction<boolean>>;
};

export type TAddOtherResponsibles = {
  accessToken: string;
  locationId: number;
  responsibleIds: number[];
  setTokenLikeExpired: () => void;
  setIsAddingResponsibles?: React.Dispatch<React.SetStateAction<boolean>>;
};

export type TDeleteResponsible = {
  accessToken: string;
  responsibleId: number;
  setTokenLikeExpired: () => void;
  setIsDeletingResponsible?: React.Dispatch<React.SetStateAction<boolean>>;
  feedbackMessage: ((message: SnackbarMessage, options?: OptionsObject) => SnackbarKey) | null;
};

export enum EGroupType {
  COMPANY = 'COMPANY',
  LOCATIONS_GROUP = 'LOCATIONS_GROUP'
}

export type TGetLocationsWithoutLinked = {
  userAccessToken: string;
  group: EGroupType;
  groupId: number;
  isFetching?: React.Dispatch<React.SetStateAction<boolean>>;
};

export type TGetLocationsWithoutLinkedResponse = {
  locations: Array<string>;
  count: number;
} | null;

export type TGetCompanyLocationsWithGoogle = {
  accessToken: string;
  companyId: number;
  page?: number;
  pageSize?: number;
  query?: string;
  setTokenLikeExpired?: () => void;
  setIfFetching?: React.Dispatch<React.SetStateAction<boolean>>;
};

export type TGetAllLinkedLocations = {
  accessToken: string;
  page?: number;
  pageSize?: number;
  companyName: string | null;
  locationName: string | null;
  status?: string | null;
  companyId?: number;
  orderByName?: string;
  setIsFetching?: React.Dispatch<React.SetStateAction<boolean>>;
  setTokenLikeExpired?: (() => void) | null;
}
