import { props } from 'ramda';
import styled from 'styled-components';

export const StyledLocationIsLinked = styled.div`
    display: flex;
    font-size: 1.4rem;
    color: ${props => props.theme.colors.red};
    margin-bottom: 2rem;
    background: ${props => props.theme.colors.white};
    padding: 1rem;

    a {
        font-size: inherit;
        color: ${props => props.theme.colors.primary};
        display: inline-block;
    }
`;
