import styled from 'styled-components';
import { devices } from '../../../../assets/styles/devices';

export const StyledStarRating = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;

    @media ${devices.tabletLandscape} {
      align-items: flex-start;
      margin-right: auto;
    }
`;

export const StyledTitle = styled.label`
    font-size: 1.5rem;
    font-weight: normal;
    margin-bottom: 0;
`;

export const StyledStarListing = styled.ul`
    display: flex;
    align-items: center;
    margin-bottom: 0;
    min-height: 4.1rem;
`;
