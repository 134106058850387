import React, { useState } from 'react';
import { getPlatformLogo } from '../../../../assets/images/svg';
import { History } from '../../../../assets/icons';

import { IconButton, TableBodyCell, TableBodyRow } from '../../../../components';
import SyncStatusIconMonitor from '../../../../components/sync-status-icon-monitor';

import { ESyncMonitoringPlatformStatus } from '../../../../components/sync-status-icon-monitor/types';

import type {
  TMonitorRowColumnsName,
  TRenderCellDataMonitorsRow,
  TTableRowData,
} from '../../../../types/TMonitor';

import { StyledIconWrapper, SwitchWrapper } from './monitor-row-styles';

const MonitorRow = ({
  rowData,
  rowId,
  dataTableOptions,
  onMonitorsDialogOpen,
  onErrorDialogOpen,
}: any) => {
  const [checked, setChecked] = useState(false);
  const [currentRowData, setCurrentRowData] = useState<TTableRowData[]>([]);

  React.useEffect(() => {
    setCurrentRowData(rowData);
  }, [rowData]);

  const renderCellData = React.useMemo<TRenderCellDataMonitorsRow>(
    () => ({
      id: (value) => value,
      platform: (value) => (
        <StyledIconWrapper>
          {getPlatformLogo({
            platform: value,
            className: 'platform-icon',
          })}
        </StyledIconWrapper>
      ),
      company: (value) => value,
      location: (value) => value,
      created_at: (value) => value,
      status: (value) => <SyncStatusIconMonitor status={value} syncType="requests" />,
      operations: (value) => (
        <>
          {rowData[5].value === ESyncMonitoringPlatformStatus.ADJUSTED && (
          <SwitchWrapper>
            <IconButton
              icon="VisibilityOutlined"
              tooltip="Visualizar Solicitações"
              onClick={() => onMonitorsDialogOpen(value)}
            />
          </SwitchWrapper>
          )}

          {rowData[5].value === ESyncMonitoringPlatformStatus.ERROR && (
          <SwitchWrapper>
            <IconButton
              CustomIcon={History}
              tooltip="Visualizar Erros"
              onClick={() => onErrorDialogOpen(value)}
              showCustomIcon
            />
          </SwitchWrapper>
          )}
        </>
      ),
    }),
    [rowData],
  );

  return (
    <TableBodyRow
      key={rowId}
      dataTableOptions={dataTableOptions}
      dataRow={rowData}
      checked={checked}
      setChecked={setChecked}
      hiddenCheckbox
    >
      {currentRowData.map(
        (dataColumnCell: any) => dataColumnCell.visible && (
          <TableBodyCell>
            {renderCellData[
                dataColumnCell.columnRef as TMonitorRowColumnsName
            ]?.(dataColumnCell.value)}
          </TableBodyCell>
        ),
      )}
    </TableBodyRow>
  );
};

export default MonitorRow;
