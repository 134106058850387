import styled from 'styled-components';
import { Paper } from '@material-ui/core';

export const StyledSectionBody = styled(Paper)`
    padding: 2rem;
    margin-bottom: 3rem;
    position: relative;
    overflow: hidden;

    &:last-child {
        margin-bottom: 0;
    }
`;
