import React from 'react';
import { IconButton } from '@material-ui/core';
import { Close, ConfirmationNumber } from '@material-ui/icons';
import * as R from 'ramda';
import { useSnackbar } from 'notistack';

import getTicket from '../../../services/tickets/getOne';
import APIGetTicketFromLocation from '../../../services/tickets/getAllFromLocation';
import APIGetUser from '../../../services/users/getUser';
import APIUpdatedTicked from '../../../services/tickets/update';

import { Loading } from '../../../components';

import TicketInfos from './ticket-infos';
import PlatformsStatus from './platforms-status';
import InfosToUpdate from './infos-to-update';
import TicketLoading from './ticket-loading';
import { TicketBatchInfosLocations } from './ticket-batch-infos-locations';

import { ticketStatus, platformStatus, ticketStatusBoxData } from '../helpers';

import { hasOperator, isDone } from './helpers';

import {
  StyledTicketOpened,
  StyledTitle,
  StyledContent,
  StyledTicketStatusTag,
  StyledInstructions,
  StyledReOpenTicket,
} from './ticket-opened-styles';

function TicketOpened({
  ticketId,
  accessToken,
  onCloseTicket,
  operatorsList,
  setTokenLikeExpired,
  setTicketHasBeenUpdated,
  getAmounts,
  currentPlatformUser,
}) {
  const [ticketStatusTag, setTicketStatusTag] = React.useState(null);
  const [ticketData, setTicketData] = React.useState(null);
  const [isLoadingTicket, setIsLoadingTicket] = React.useState(false);
  const [isReOpenTicket, setIsReOpenTicket] = React.useState(false);

  const [isTicketLoading, setIsTicketLoading] = React.useState(false);

  const { enqueueSnackbar } = useSnackbar();

  const getTicketData = React.useCallback(async () => {
    setTicketData(null);

    const ticket = await getTicket({
      accessToken,
      ticketId,
      setIsFetching: setIsLoadingTicket,
      setTokenLikeExpired,
    });

    const ticketStatusBoxDataResponse = ticketStatusBoxData[ticket.status];

    if (!ticket) return;

    const getUserResponse = await APIGetUser({
      accessToken,
      id: ticket.created_by,
      setTokenLikeExpired,
    });

    if (R.isNil(getUserResponse)) {
      setTicketData({ ...ticket, requesterName: 'Usuário Removido', requesterEmail: '' });
    } else {
      const { name: requesterName, email: requesterEmail } = getUserResponse;
      setTicketData({ ...ticket, requesterName, requesterEmail });
    }

    setTicketStatusTag(ticketStatusBoxDataResponse);
  }, [ticketId]);

  const reOpenTicket = React.useCallback(async () => {
    const { type: ticketType } = ticketData || {};
    const isBatchTicket = R.includes('BATCH', ticketType);
    if (isBatchTicket) return;

    const { location: locationThisTicket } = ticketData || {};
    const { id: locationThisTicketId } = locationThisTicket || {};
    const ticketsThisLocation = await APIGetTicketFromLocation({
      accessToken,
      locationId: locationThisTicketId,
      setTokenLikeExpired,
    });
    if (!ticketsThisLocation) return;

    const openedTickets = ticketsThisLocation[0].filter(
      (ticket) => ticket.status === 'OPEN',
    );

    if (!R.isEmpty(openedTickets)) {
      enqueueSnackbar(
        'Esse ticket não pode ser reaberto por que outro desse mesmo local ainda está pendente',
        { variant: 'error' },
      );
      return;
    }

    const ticketUpdateResponse = await APIUpdatedTicked({
      accessToken,
      dataToUpdate: {
        status: 'OPEN',
      },
      setIsFetching: setIsReOpenTicket,
      ticketId: ticketData.id,
      setTokenLikeExpired,
    });

    if (!ticketUpdateResponse) return;

    getTicketData();
    getAmounts();
    setTicketHasBeenUpdated(true);
  }, [ticketData]);

  React.useEffect(() => {
    getTicketData();
  }, [getTicketData]);

  return (
    <StyledTicketOpened>
      {isLoadingTicket && <Loading className="is-loading" />}

      {!isLoadingTicket && isDone(ticketData || {}) && (
        <StyledReOpenTicket
          onClick={reOpenTicket}
          isReOpenTicket={isReOpenTicket}
        >
          {isReOpenTicket && <Loading />}
          <ConfirmationNumber className="reopen-ticket-icon" />
          Reabrir Ticket
        </StyledReOpenTicket>
      )}

      {!isLoadingTicket && !R.isNil(ticketData) && (
        <>
          <StyledTitle>
            {ticketStatusTag && (
              <StyledTicketStatusTag status={ticketStatusTag.status}>
                {ticketStatusTag.translateTitle}
              </StyledTicketStatusTag>
            )}
            {ticketStatusTag && (
              <h2 className="ticket-name">{ticketData.name}</h2>
            )}
            <IconButton className="close-icon-button" onClick={onCloseTicket}>
              <Close className="close-icon" />
            </IconButton>
          </StyledTitle>
          <StyledContent>
            {!hasOperator(ticketData) && (
              <StyledInstructions>
                Para habilitar as demais ações desse ticket você precisa
                escolher um operador.
              </StyledInstructions>
            )}

            <TicketInfos
              setIsTicketLoading={setIsTicketLoading}
              getTicketData={getTicketData}
              accessToken={accessToken}
              ticketStatus={ticketStatus}
              ticketData={ticketData}
              operatorsList={operatorsList}
              setTokenLikeExpired={setTokenLikeExpired}
              setTicketHasBeenUpdated={setTicketHasBeenUpdated}
              getAmounts={getAmounts}
              currentPlatformUser={currentPlatformUser}
            />

            <InfosToUpdate
              data={ticketData.data}
              ticketType={ticketData.type}
            />

            {(hasOperator(ticketData) && ticketData.status !== 'DONE' && !R.isNil(ticketData.batch_infos_id)) && (
              <TicketBatchInfosLocations
                batchInfosId={ticketData.batch_infos_id}
              />
            )}

            {!R.includes(ticketData.type, 'BATCH_INFOS') && (
              <PlatformsStatus
                setIsTicketLoading={setIsTicketLoading}
                getTicketData={getTicketData}
                accessToken={accessToken}
                platformStatus={platformStatus}
                ticketData={ticketData}
                setTokenLikeExpired={setTokenLikeExpired}
              />
            )}
          </StyledContent>
        </>
      )}

      {!isLoadingTicket && R.isEmpty(ticketData) && (
        <small>Não foi possível carregar os dados do ticket</small>
      )}

      {isTicketLoading && <TicketLoading />}
    </StyledTicketOpened>
  );
}

export default TicketOpened;
