/* eslint-disable react/jsx-props-no-spreading */
// eslint-disable-next-line no-use-before-define
import React, { useCallback } from 'react';

import {
  StyledDescription,
  StyledForm,
  StyledTitle,
  StyledDisclaimer,
} from './descriptions-styles';
import ControlledInput from '../../controlled-input';
import { longDescriptionAttr, } from './helpers';
import { TDescriptionProps } from './types';

const Descriptions = ({
  initialData,
  setLocationInfo,
  fieldOnFocus,
  setFieldOnFocus,
}: TDescriptionProps) => {
  const {
    long_description: initialLongDescription,
  } = initialData || {};

  const LongDescription = useCallback(() => (
    <ControlledInput
      {...longDescriptionAttr}
      fieldOnFocus={fieldOnFocus}
      setFieldOnFocus={setFieldOnFocus}
      setParentValue={setLocationInfo}
      defaultValue={initialLongDescription}
    />
  ), [initialData, fieldOnFocus]);

  return (
    <StyledDescription>
      <StyledTitle>Descrição</StyledTitle>

      <StyledDisclaimer>
        Descreva de modo simples seu local.
      </StyledDisclaimer>

      <StyledForm>
        <LongDescription />
      </StyledForm>
    </StyledDescription>
  );
};

export default React.memo(Descriptions);
