import styled from 'styled-components';

export const StyledLink = styled.a`
  position: fixed;
  right: 4rem;
  bottom: 4rem;
  background: #1bd741;
  padding: 1rem;
  border-radius: 10px;
  transition: filter 0.2s;
  z-index: 999999;

  &:hover {
    filter: brightness(0.85);
  }

  &:active {
    filter: brightness(0.75);
  }

  .contact-icon {
    width: 30px;
    height: 30px;

    & path:nth-child(3) {
      fill: #1bd741;
    }
  }
`;
