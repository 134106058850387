import React from 'react';

import type { 
  TTicketBatchInfosLocationRow,
} from '../../../../../types/TTickets';

import type { 
  TAffectedTicketPlatforms,
} from '../../../../../types/TBatchUpdate';

import { useAuth } from '../../../../../hooks';

import {
  TableBodyRow,
  TableBodyCell,
} from '../../../../../components';

import { CheckPlatform } from './check-platform';

import {
  StyledPlatformsCheck,
} from './location-row-styles';

export const LocationRow = ({ rowData, hiddenCheckbox }: TTicketBatchInfosLocationRow) => {
  const [locationRowData, setLocationRowData] = React.useState<any[]>([]);
  const [checked, setChecked] = React.useState(false);
  const [isLoadingCheckPlatform, setIsLoadingCheckPlatform] = React.useState(false);

  React.useEffect(() => {
    setLocationRowData(rowData);
  }, [rowData]);

  const affectedPlatformsLocation = React.useMemo(
    (): TAffectedTicketPlatforms[] => locationRowData.find(
      (dataCell) => dataCell.columnRef === 'affectedPlatforms',
    )?.value,
    [locationRowData],
  );

  const locationRowIdCell = React.useMemo(
    () => rowData.find((dataCell) => dataCell.columnRef === 'id'),
    [rowData],
  );

  return (
    <TableBodyRow setChecked={setChecked} checked={checked} hiddenCheckbox={hiddenCheckbox}>
      {locationRowData.map(
        (dataColumnCell) => dataColumnCell?.visible && (
        <TableBodyCell>{dataColumnCell.value}</TableBodyCell>
        ),
      )}
      <TableBodyCell>
        <StyledPlatformsCheck>
          {affectedPlatformsLocation
            && affectedPlatformsLocation.map(({ platform, affected }: any) => (
              <CheckPlatform
                platform={platform}
                affected={affected}
                platformsData={affectedPlatformsLocation}
                locationRowId={locationRowIdCell?.value}
                setLocationRowData={setLocationRowData}
                isLoadingCheckPlatform={isLoadingCheckPlatform}
                setIsLoadingCheckPlatform={setIsLoadingCheckPlatform}
              />
            ))}
        </StyledPlatformsCheck>
      </TableBodyCell>
    </TableBodyRow>
  );
};
