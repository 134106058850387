import styled from 'styled-components';
import { Paper } from '@material-ui/core';

export const StyledServiceBatch = styled.div`
`;

export const StyledButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
`;

export const StyledFilters = styled.section`
  background-color: white;
  
  margin: 2rem;
  padding: 1rem 2rem;
`;

export const StyledTitle = styled.h2`
`;

export const StyledContent = styled.section`
  background-color: white;
  padding: 2rem;
`;

export const StyledContainer = styled.div`
  margin: 2rem;
`;

export const StyledInfo = styled(Paper)`
  display: flex;
  padding: 2rem;
  align-items: center;
  margin-bottom: 2rem;
`;
