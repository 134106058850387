import { isEmpty, isNil } from 'ramda';

import type {
  TTopicType,
} from '../../../types/TLocationPost';

export const getTopicType = (topicTypeToGet: string): string => {
  const topicTypes: TTopicType = {
    ALERT: 'Atualizações da COVID-19',
    STANDARD: 'Novidades',
    OFFER: 'Oferta',
    EVENT: 'Evento',
  };

  return topicTypes[topicTypeToGet];
};

export const isEmptyOrNull = (value: any) => isEmpty(value) || isNil(value);
