import { createActions, createReducer } from 'reduxsauce';

export const { Types, Creators } = createActions({
  setGoogleData: ['googleData'],
  setFacebookData: ['facebookData'],
  setFoursquareData: ['foursquareData'],
  setLocationsList: ['locationsList'],
});

const initialState = {
  googleData: {},
  facebookData: {},
  foursquareData: {},
  locationsList: {},
};

const reduxOnSetGoogleData = (state = initialState, { googleData }) => (
  { ...state, googleData }
);

const reduxOnSetFacebookData = (state = initialState, { facebookData }) => (
  { ...state, facebookData }
);

const reduxOnSetFoursquareData = (state = initialState, { foursquareData }) => (
  { ...state, foursquareData }
);

const reduxOnSetLocationsList = (state = initialState, { locationsList }) => (
  { ...state, locationsList }
);

export default createReducer(initialState, {
  [Types.SET_GOOGLE_DATA]: reduxOnSetGoogleData,
  [Types.SET_FACEBOOK_DATA]: reduxOnSetFacebookData,
  [Types.SET_FOURSQUARE_DATA]: reduxOnSetFoursquareData,
  [Types.SET_LOCATIONS_LIST]: reduxOnSetLocationsList,
});
