import React from 'react';
import * as R from 'ramda';
import SpecialDateRow from './special-date-row';

const SpecialDatesInfos = ({
  specialDates = [],
}) => (
  <>
    {(!R.isEmpty(specialDates) && !R.isNil(specialDates)) && (
      <section>
          {specialDates.map((specialDateInfo) => (
            <SpecialDateRow
              status={specialDateInfo.status}
              saasData={specialDateInfo.saas_data.value}
              platformData={specialDateInfo.platform_data.value}
            />
          ))}
      </section>
    )}
  </>
);

export default SpecialDatesInfos;
