import styled from 'styled-components';
import { devices } from '../../../../assets/styles/devices';

export const StyledInfosToUpdate = styled.div`
    display: flex;
    flex-direction: column;
    padding: 3rem 3rem;
    background: ${props => props.theme.colors.white};
    margin-bottom: 0.2rem;

    h3 {
        font-size: 3rem;
        margin-bottom: 2rem;
    }
`;

export const StyledInfosWrap = styled.div`
    display: grid;
    grid-template-columns: 1fr;
    row-gap: 2rem;

    @media ${devices.desktop} {
        grid-template-columns: repeat(3, 1fr);
        column-gap: 2rem;
    }

    @media ${devices.widescreen} {
        grid-template-columns: repeat(4, 1fr);
        column-gap: 2rem;
    }
`;

export const StyledInfo = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    padding-right: 1rem;
    word-break: break-all;

    h4 {
        font-size: 1.6rem;
        color: ${props => props.theme.colors.primary};
    }

    span {
        font-size: 1.4rem;
        max-width: 150rem;
    }
`;
