import styled, { css } from 'styled-components';
import { Paper } from '@material-ui/core';
import {
  AddCircle as AddCircleIcon,
  List as ListIcon,
  ViewComfy as ViewComfyIcon,
} from '@material-ui/icons';
import { shade, lighten } from 'polished';

import { devices } from '../../../assets/styles/devices';

export const StyledFilterPostHeader = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 0;
  margin: 2rem auto;
`;

export const StyledHeader = styled.header`
  display: flex;
  flex-direction: column-reverse;
  margin-bottom: 1rem;
  
  @media ${devices.tablet} {
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-end;
  }
`;

export const StyledTitle = styled.h2`
  font-size: 1.6rem;
  display: flex;
  margin-bottom: 0;
`;

export const StyledContent = styled(Paper)`
  padding: 2rem;
  width: 100%;
`;

export const StyledAddCircle = styled(AddCircleIcon)`
  fill: ${props => props.theme.colors.white};
  margin-bottom: 0;
  width: 2rem;
  height: 2rem;
  margin-right: 0.5rem;
`;

export const StyledAddPostButton = styled.div<{
  disabled: boolean,
}>`
  display: flex;
  align-content: center;
  justify-content: center;
  border-radius: 3rem;
  font-size: 1.4rem;
  font-weight: 500;
  background: ${props => props.theme.colors.primary};
  color: ${props => props.theme.colors.white};
  padding: 1rem 2rem;
  margin-bottom: 2rem;
  cursor: pointer;
  transition: 0.5s;
  
  &:hover {
    background: ${props => shade(0.1, props.theme.colors.primary)};
    transform: scale(1.1);
  }

  ${props => props.disabled === true && css`
    background: ${shade(0.25, props.theme.colors.white)};
    pointer-events: none;
    
    &:hover {
      transform: none;
    }
  `}

  @media ${devices.tablet} {
    margin-bottom: 0;
    margin-left: auto;
  }
`;

export const StyledPeriods = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  
  h3 {
    font-size: 1.5rem;
    font-weight: normal;
  }
`;

export const StyledPeriodsWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  
  @media ${devices.tablet} {
    flex-direction: row;
  }
  
  .period-input {
    @media ${devices.tablet} {
      margin-bottom: 0;
    }

    &:first-child {
      @media ${devices.tablet} {
        margin-right: 2rem;
      }
    }
  }
`;
