import React, { SetStateAction } from 'react';

export const StatusOptions = [
  { id: 0, name: 'Selecione uma opção', value: '' },
  { id: 1, name: 'Rejeitado', value: 'REJECTED' },
  { id: 2, name: 'Aprovado', value: 'APPROVED' },
  { id: 3, name: 'Pendente', value: 'PENDING' },
  { id: 4, name: 'Em progresso', value: 'PROGRESS' },
  { id: 5, name: 'Finalizado', value: 'DONE' },
  { id: 6, name: 'Em análise', value: 'ANALYSIS' },
  { id: 7, name: 'Erro', value: 'ERROR' },
];

export const TypeOptions = [
  { id: 0, name: 'Informações básicas', value: 'infoBasic' },
  { id: 1, name: 'Links', value: 'links' },
  { id: 2, name: 'Horário de funcionamento', value: 'schedule' },
  { id: 3, name: 'Datas Especiais', value: 'special_dates' },
];

export interface TLastSyncFilterProps {
  typeLabel: string;
  selectedTypeFilter: string[];
  setSelectedTypeFilter: React.Dispatch<React.SetStateAction<string[]>>;
  statusLabel: string;
  selectedStatusFilter: string;
  setSelectedStatusFilter: React.Dispatch<string>;
  ofPeriod: Date | null;
  setOfPeriod: React.Dispatch<SetStateAction<Date | null>>;
  toPeriod: Date | null;
  setToPeriod: React.Dispatch<SetStateAction<Date | null>>;

}
