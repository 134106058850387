import axios, { AxiosRequestConfig } from 'axios';
import { TGetAllMonitoringProps, TGetAllMonitoringPropsResponse } from './types';

export const getAllMonitoring = async ({
  companyName = '',
  locationName = '',
  userAccessToken,
  page = 0,
  pageSize = 10,
  query = '',
  status = '',
  ofPeriod = '',
  toPeriod = '',
  platforms,
  isFetching,
  setTokenLikeExpired,
}: TGetAllMonitoringProps): Promise<TGetAllMonitoringPropsResponse> => {
  try {
    if (isFetching) isFetching(true);
    const apiPath = `${process.env.REACT_APP_SYNC_PLATFORM_MONITORING}`;

    const requestOptions: AxiosRequestConfig = {
      headers: {
        Authorization: `Bearer ${userAccessToken}`,
      },
      params: {
        page,
        pageSize,
        query,
        status,
        ofPeriod,
        toPeriod,
        locationName,
        companyName,
        platforms,
      },
    };

    const { data } = await axios.get(apiPath, requestOptions);

    return data;
  } catch (err: any) {
    if (err.response?.status === 401 && setTokenLikeExpired) setTokenLikeExpired();

    return null;
  } finally {
    if (isFetching) isFetching(false);
  }
};
