import React from 'react';

import {
  StyledLocationIsLinked,
} from './location-is-linked-styles';

const LocationIsLinked = ({ facebookLinkedPageData }) => {
  const [pageLinked, setPageLinked] = React.useState([]);

  React.useEffect(() => {
    const pageLinkedId = facebookLinkedPageData.split(',')[1];

    if (pageLinkedId) setPageLinked(pageLinkedId);
  }, [facebookLinkedPageData]);

  return (
    <StyledLocationIsLinked>
      <span>
        Este local já foi vinculado com uma
        {' '}
        <a href={`https://www.facebook.com/${pageLinked}`} target="_blank" rel="noopener noreferrer">página</a>
        {' '}
        de outra conta.
      </span>
    </StyledLocationIsLinked>
  );
};

export default LocationIsLinked;
