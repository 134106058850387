import styled, { css } from 'styled-components';
import Button from '@material-ui/core/Button';

export type ButtonTypeStyleProps = 'PRIMARY' | 'SECONDARY' | 'GREY' | 'INVERTED_PRIMARY' | 'INVERTED_GREY'

type Props = {
  typeButton: ButtonTypeStyleProps;
}

export const StyledContainer = styled(Button)<Props>`
  border: none;
  border-radius: 0.6rem;
  min-height: 4rem;
  
  display: flex;
  align-items: center;
  justify-content: center;
  
  background: ${(props) => props.theme.colors.primary};
  color: ${(props) => props.theme.colors.white};
  position: relative;
  transition: 0.3s;
  padding: 0 2rem;
  
  font-size: 1.2rem;
  text-transform: capitalize;
  font-weight: normal;

  .MuiButton-label {
    font-size: 1.2rem;
    font-weight: normal;
  }

  svg {
    margin-right: 0.5rem;
  }

  &:hover,
  &:focus {
    background: ${(props) => props.theme.colors.ocean};
    color: ${(props) => props.theme.colors.white};
    outline: none;
    text-decoration:none;
  }

  ${(props) => props.typeButton === 'SECONDARY' && css`
    background: ${props.theme.colors.white};
    color: ${props.theme.colors.grey};
    
    &:hover,
    &:focus {
      background: ${props.theme.colors.grey};
      color: ${props.theme.colors.white};
    }
  `}

  ${(props) => props.typeButton === 'GREY' && css`
    background: ${props.theme.colors.grey};
    color: ${props.theme.colors.white};
    
    &:hover,
    &:focus {
      background: ${props.theme.colors.darkGrey};
      color: ${props.theme.colors.white};
    }
  `}
  
  ${(props) => props.typeButton === 'INVERTED_PRIMARY' && css`
    background: ${props.theme.colors.white};
    color: ${props.theme.colors.primary};
    
    &:hover,
    &:focus {
      background: ${props.theme.colors.primary};
      color: ${props.theme.colors.white};
    }
  `}
`;
