import React, { SetStateAction } from 'react';
import { ESyncMonitoringPlatformStatus } from '../../../../components/sync-status-icon-monitor/types';

export type TPlatformOptions = {
  id: number;
  name: string;
};

export type TStatusOptions = {
  id: number;
  name: string;
  value: ESyncMonitoringPlatformStatus | '';
}

export type TPlatformsOptions = TPlatformOptions[]

export const PlatformOptions: TPlatformsOptions = [
  { id: 0, name: 'Google' },
  { id: 1, name: 'Facebook' },
  { id: 2, name: 'Yelp' },
  { id: 3, name: 'Linkedin' },
  { id: 4, name: 'Foursquare' },
];

export const StatusOptions: TStatusOptions[] = [
  { id: 0, name: 'Selecione uma opção', value: '' },
  { id: 1, name: 'Não Existe', value: ESyncMonitoringPlatformStatus.NON_AVAILABLE },
  { id: 2, name: 'Erro', value: ESyncMonitoringPlatformStatus.ERROR },
  { id: 3, name: 'Corrigido', value: ESyncMonitoringPlatformStatus.ADJUSTED },
  { id: 4, name: 'Verificado', value: ESyncMonitoringPlatformStatus.DONE },
  { id: 5, name: 'Pulado', value: ESyncMonitoringPlatformStatus.SKIPPED },
];

export interface TMonitorFilterProps {
  onChangeCompanyQuery: React.Dispatch<string>;
  onChangeLocationQuery: React.Dispatch<string>;
  selectedPlatformFilter: string[];
  setSelectedPlatformFilter: React.Dispatch<React.SetStateAction<string[]>>;
  selectedTypeFilter: string;
  setSelectedTypeFilter: React.Dispatch<string>;
  ofPeriod: Date | null;
  setOfPeriod: React.Dispatch<SetStateAction<Date | null>>;
  toPeriod: Date | null;
  setToPeriod: React.Dispatch<SetStateAction<Date | null>>;
}
