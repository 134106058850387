import styled, { css } from 'styled-components';

import { devices } from '../../../assets/styles/devices';

export const StyledContent = styled.div`
  display: flex;
  flex-direction: column;
  padding: 2rem;

  .service-description {   
    textarea {
      min-height: 3rem;
      resize: vertical;
    }
  }
  
  button {
    margin-top: 1rem;
  }

`;

export const StyledBoldText = styled.h3`
  font-size: 1.4rem;
  font-weight: bold;
  color: ${props => props.theme.colors.primary};
  margin-bottom: 1.5rem;
  line-height: 1;
`;

export const StyledRowWrapper = styled.div`
  display: flex;
  flex-direction: column;
  column-gap: 1rem;
  margin-bottom: 2rem;
  justify-content: center;

  @media ${devices.tablet} {
    flex-direction: row;
    margin-bottom: 0;
  }

  .select-billing-type {
    &:last-of-type {
      margin-bottom: 2rem;
    }
  }
`;

export const StyledColumnWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`;

export const StyledTextCounter = styled.span<{ count: boolean; }>`
  font-size: 1.2rem;

  ${props => props.count && css`
    color:  ${props.theme.colors.red};
  `}
`;

export const StyledTextInputAlert = styled.span`
  font-size: 1.2rem;
  align-self: flex-start;
  margin-top: 0.8rem;
  margin-bottom: 1.6rem;
`;
