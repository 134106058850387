import styled from 'styled-components';
import { Paper } from '@material-ui/core';

import { devices } from '../../assets/styles/devices';

export const StyledCompanyInfo = styled.div`
  display: flex;
  flex-direction: column;
`;

export const StyledCompanyInfoFields = styled(Paper)`
  display: flex;
  flex-direction: column;
  padding: 3rem 2rem;
  row-gap: 2rem;
  margin-bottom: 2rem;

  .input-wrapper {
    margin-bottom: 0;
  }

  &:last-of-type {
    margin-bottom: 0;
  }
`;

export const StyledGroupField = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  row-gap: 2rem;

  @media ${devices.desktop} {
    grid-template-columns: 40% 40% 10%;
    column-gap: 2rem;
    row-gap: 0;
  }
`;

export const StyledTitle = styled.h3`
  font-weight: 700;
  font-size: 1.5rem;
  margin-bottom: 2rem;
`;
