import axios from 'axios';

export default async function createBatchLocations({
  accessToken,
  locationsFile,
  companyId,
  userId,
  setIsCreatingLocations = null,
  feedbackMessage,
  setTokenLikeExpired,
}) {
  const formData = new FormData();
  formData.append('file', locationsFile);

  try {
    if (setIsCreatingLocations) setIsCreatingLocations(true);
    if (feedbackMessage) feedbackMessage('Criando locais...', { variant: 'warning' });

    const { data } = await axios.post(
      `${process.env.REACT_APP_LOCATIONS_API_URL}/batch/${companyId}/${userId}`,
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: `Bearer ${accessToken}`,
        },
      },
    );

    if (feedbackMessage) feedbackMessage('Locais criados com sucesso', { variant: 'success' });

    return data;
  } catch (err) {
    const { response } = err || {};
    const { data } = response || {};

    if (err.response?.status === 401 && setTokenLikeExpired) setTokenLikeExpired();

    if (feedbackMessage && data?.message) feedbackMessage(data?.message, { variant: 'error' });

    console.log(response);

    return err;
  } finally {
    if (setIsCreatingLocations) setIsCreatingLocations(false);
  }
}
