import React from 'react';
import { isNil } from 'ramda';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { debounce } from 'lodash';
import { Creators as CompanyActions } from '../../redux/ducks/company';
import { Creators as LocationActions } from '../../redux/ducks/location';
import { Creators as LocationGroupActions } from '../../redux/ducks/location-group';

import { Loading, IconButton, ConfirmDialog } from '..';

import { translations } from '../../translations';

import APIGetCompany from '../../services/companies/getCompany';
import APIGetLocation from '../../services/locations/getLocation';

import { permissionsList } from './helpers';

import {
  StyledDataTable,
  StyledCellCustomButtons,
  StyledEmptyTableContent,
  StyledButton,
} from './data-table-styles';

const DataTable = ({
  setSearchQuery,
  page,
  setPage,
  pageSize,
  setPageSize,
  count,
  title,
  data,
  columns,
  onSortClicked = null,
  onUpdateClicked,
  onLinkClicked = null,
  onDeleteClicked,
  onRestoreClicked,
  onReportClicked = null,
  firstOperationName = null,
  onFirstOperationClicked = null,
  onOpenCopyLocationDialog = null,
  clickable = false,
  onRowClickURL = null,
  loading,
  onRowClickOperation = null,
  noUpdate = false,
  isDeleteTable = false,
  className,
  addRegisterLabel = 'Adicionar Registro',
  addRegisterLink,
  emptyTableTitle = 'Essa tabela não tem registro',
  emptyTableTitleButton = 'Adicionar registro',
  isGuestTable = false,
  confirmDialogTitle = 'Confirmação de operação',
  confirmDialogContentText = 'Tem certeza que deseja executar essa operação?',
  handleChangedColumns,
  handleChangeFilter = null,
  userProfileName,
  companyTable = false,
  hasModuleOperations = false,
  isLocationTable = false,
  isLocationGroupTable = false,
  disabledRowsSelection = false,
}) => {
  const reduxDispatch = useDispatch();
  const history = useHistory();

  const {
    user: { accessToken, user },
    language,
  } = useSelector((state) => state.AuthReducer);

  const { profiles } = user;

  const profileName = profiles[0].name;

  const { data_table: dataTableTranslations } = translations[language];

  const [dataRender, setDataRender] = React.useState(null);
  const [selectedRows, setSelectedRows] = React.useState([]);

  const [dialogDataTable, setDialogDataTable] = React.useState({
    open: false,
    onConfirm: () => {},
  });

  React.useEffect(() => {
    setDataRender(data);
  }, [data]);

  const closeDialog = () => setDialogDataTable((prevState) => ({ ...prevState, open: false }));

  const EmptyTableContent = () => (
    <StyledEmptyTableContent>
      <h4>{emptyTableTitle}</h4>
      {!isGuestTable && (
        <StyledButton onClick={() => history.push(addRegisterLink)}>
          {emptyTableTitleButton}
        </StyledButton>
      )}
    </StyledEmptyTableContent>
  );

  const disabledReportButtonCheck = (rowData) => {
    if (!isLocationTable && rowData[3] === 'locationGuest') {
      return true;
    }

    return false;
  };

  const disabledReportButtonCheckForLocation = (rowData) => {
    const isReplicatedOnBi = rowData[6];
    return !isReplicatedOnBi;
  };

  const handleSearchWithDebounce = debounce(
    (text) => setSearchQuery(text),
    500,
  );

  const isDisableOperation = React.useCallback(
    (operation) => {
      if (operation === 'post') {
        if (companyTable) return true;
        if (isGuestTable) return true;

        return false;
      }

      if (operation === 'gallery') {
        if (companyTable) return true;
        if (isGuestTable) return true;

        return false;
      }

      return true;
    },
    [companyTable, isGuestTable],
  );

  const handlePushToReviews = React.useCallback(
    async (pushRedirectId) => {
      if (companyTable) {
        const getCompanyResponse = await APIGetCompany({
          accessToken,
          id: pushRedirectId,
        });

        if (isNil(getCompanyResponse)) return;

        reduxDispatch(CompanyActions.setActiveCompany(getCompanyResponse));
        reduxDispatch(CompanyActions.setActiveCompanyId(getCompanyResponse.id));
        history.push('reviews');
      }

      if (isLocationTable) {
        const getLocationResponse = await APIGetLocation({
          accessToken,
          id: pushRedirectId,
        });

        if (isNil(getLocationResponse)) return;

        reduxDispatch(LocationActions.setActiveLocation(getLocationResponse));

        history.push('/reviews/location');
      }
    },
    [accessToken, companyTable, isLocationTable],
  );

  const handlePushToPosts = React.useCallback(
    async (pushRedirectId) => {
      if (companyTable) {
        const getCompanyResponse = await APIGetCompany({
          accessToken,
          id: pushRedirectId,
        });

        if (isNil(getCompanyResponse)) return;

        reduxDispatch(CompanyActions.setActiveCompany(getCompanyResponse));
        reduxDispatch(CompanyActions.setActiveCompanyId(getCompanyResponse.id));
        history.push('/batch-update-posts-status');
        return;
      }

      const getLocationResponse = await APIGetLocation({
        accessToken,
        id: pushRedirectId,
      });

      if (isNil(getLocationResponse)) return;

      if (isLocationTable) {
        reduxDispatch(LocationGroupActions.setShowLocationsByGroup(false));
        reduxDispatch(CompanyActions.setShowLocationsByCompany(true));
        reduxDispatch(LocationActions.setActiveLocation(getLocationResponse));
        history.push('/location/posts');
      }

      if (isLocationGroupTable) {
        reduxDispatch(LocationGroupActions.setShowLocationsByGroup(true));
        reduxDispatch(CompanyActions.setShowLocationsByCompany(false));
        reduxDispatch(LocationActions.setActiveLocation(getLocationResponse));
        history.push('/location/posts');
      }
    },
    [accessToken, companyTable, isLocationTable, isLocationGroupTable],
  );

  const handlePushToGallery = React.useCallback(
    async (pushRedirectId) => {
      if (companyTable) {
        const getCompanyResponse = await APIGetCompany({
          accessToken,
          id: pushRedirectId,
        });

        if (isNil(getCompanyResponse)) return;

        reduxDispatch(CompanyActions.setActiveCompany(getCompanyResponse));
        reduxDispatch(CompanyActions.setActiveCompanyId(getCompanyResponse.id));
        history.push('/gallery-batch');
        return;
      }

      const getLocationResponse = await APIGetLocation({
        accessToken,
        id: pushRedirectId,
      });

      if (isNil(getLocationResponse)) return;

      if (isLocationTable) {
        reduxDispatch(LocationGroupActions.setShowLocationsByGroup(false));
        reduxDispatch(CompanyActions.setShowLocationsByCompany(true));
        reduxDispatch(LocationActions.setActiveLocation(getLocationResponse));
        history.push('/location/gallery');
      }

      if (isLocationGroupTable) {
        reduxDispatch(LocationGroupActions.setShowLocationsByGroup(true));
        reduxDispatch(CompanyActions.setShowLocationsByCompany(false));
        reduxDispatch(LocationActions.setActiveLocation(getLocationResponse));
        history.push('/location/gallery');
      }
    },
    [accessToken, companyTable, isLocationTable, isLocationGroupTable],
  );

  const handlePushToServices = React.useCallback(
    async (pushRedirectId) => {
      if (companyTable) {
        const getCompanyResponse = await APIGetCompany({
          accessToken,
          id: pushRedirectId,
        });

        if (isNil(getCompanyResponse)) return;

        reduxDispatch(CompanyActions.setActiveCompany(getCompanyResponse));
        reduxDispatch(CompanyActions.setActiveCompanyId(getCompanyResponse.id));
        history.push('/service-batch');
      }

      const getLocationResponse = await APIGetLocation({
        accessToken,
        id: pushRedirectId,
      });

      if (isNil(getLocationResponse)) return;

      if (isLocationTable) {
        reduxDispatch(LocationGroupActions.setShowLocationsByGroup(false));
        reduxDispatch(CompanyActions.setShowLocationsByCompany(true));
        reduxDispatch(LocationActions.setActiveLocation(getLocationResponse));
        history.push('/location/services');
      }

      if (isLocationGroupTable) {
        reduxDispatch(LocationGroupActions.setShowLocationsByGroup(true));
        reduxDispatch(CompanyActions.setShowLocationsByCompany(false));
        reduxDispatch(LocationActions.setActiveLocation(getLocationResponse));
        history.push('/service-batch');
      }
    },
    [accessToken, companyTable, isLocationTable, isLocationGroupTable],
  );

  const handleDisabledReportButton = (rowData) => {
    if (isLocationTable) return disabledReportButtonCheckForLocation(rowData);
    return disabledReportButtonCheck(rowData);
  };

  const handleDisabledEditButton = (rowData) => {
    if (userProfileName !== 'Usuário Padrão') return false;
    if (companyTable && rowData[3] === 'locationGuest') return true;
    if (isLocationTable && rowData[2] === 'owner') return false;
    if (isLocationTable && rowData[2] === 'guest') return false;
    if (isLocationTable && !isNil(rowData[8])) return !rowData[8].infoPermission;
    return true;
  };

  const handleDisabledOperation = (rowData, permission = null) => {
    if (isNil(permission)) return true;
    if (isLocationTable && rowData[7]) return true;
    if (isLocationTable && !isNil(rowData[8])) return !rowData[8][permissionsList[permission]];
    if (companyTable && rowData[2] === 0) return true;
    if (companyTable && rowData[3] === 'locationGuest') return true;
    return false;
  };

  const dataColumns = [
    ...columns,
    {
      name: dataTableTranslations.operations,
      options: {
        filter: false,
        sort: false,
        empty: true,
        customBodyRender: (value, meta) => (isDeleteTable ? (
          <IconButton
            tooltip="Restaurar"
            icon="RestoreFromTrash"
            onClick={() => onRestoreClicked(meta.rowData[0])}
          />
        ) : (
          <StyledCellCustomButtons>
            <IconButton
              disabled={handleDisabledEditButton(meta.rowData)}
              className={
                    meta.rowData[3] === 'locationGuest' ? 'location-guest' : ''
                  }
              icon="Create"
              tooltip="Editar"
              onClick={() => {
                onUpdateClicked(meta.rowData[0]);
              }}
            />

            {!isNil(onLinkClicked) && (
              <IconButton
                disabled={meta.rowData[2] === 'guest'}
                icon={meta.rowData[7] ? 'Power' : 'PowerOff'}
                tooltip={meta.rowData[7] ? 'Conectar' : 'Desconectar'}
                onClick={() => {
                  onLinkClicked(meta.rowData[0]);
                }}
              />
            )}

            {(companyTable || isLocationTable) && (
              <>
                <IconButton
                  disabled={handleDisabledReportButton(meta.rowData)}
                  icon="TrendingUp"
                  tooltip="Relatórios"
                  onClick={() => onReportClicked(meta.rowData[0])}
                />

                <IconButton
                  disabled={handleDisabledOperation(meta.rowData, 'Avaliações')}
                  tooltip={
                  isLocationTable
                  && meta.rowData[7]
                    ? 'Avaliações (Entre em contato com nossos operadores para liberar o acesso a essa funcionalidade)' : 'Avaliações'
                }
                  icon="Star"
                  onClick={() => handlePushToReviews(meta.rowData[0])}
                />

                <IconButton
                  disabled={handleDisabledOperation(meta.rowData, 'Postagens')}
                  tooltip={
                  isLocationTable
                  && meta.rowData[7]
                    ? 'Postagens (Entre em contato com nossos operadores para liberar o acesso a essa funcionalidade)' : 'Postagens'
                }
                  icon="PostAdd"
                  onClick={() => handlePushToPosts(meta.rowData[0])}
                />

                <IconButton
                  disabled={handleDisabledOperation(meta.rowData, 'Galeria')}
                  tooltip={
                  isLocationTable
                  && meta.rowData[7]
                    ? 'Galeria (Entre em contato com nossos operadores para liberar o acesso a essa funcionalidade)' : 'Galeria'
                  }
                  icon="PhotoCamera"
                  onClick={() => handlePushToGallery(meta.rowData[0])}
                />

                <IconButton
                  disabled={handleDisabledOperation(meta.rowData, 'Serviços')}
                  tooltip={
                    isLocationTable
                    && meta.rowData[7]
                      ? 'Serviços (Entre em contato com nossos operadores para liberar o acesso a essa funcionalidade)' : 'Serviços'
                  }
                  icon="List"
                  onClick={() => handlePushToServices(meta.rowData[0])}
                />
              </>
            )}

            {firstOperationName && (
            <IconButton
              icon="Store"
              tooltip={firstOperationName}
              onClick={() => {
                onFirstOperationClicked(meta.rowData[0]);
              }}
            />
            )}

            {isLocationTable && (
            <IconButton
              icon="FilterNone"
              tooltip="Copiar"
              onClick={() => {
                onOpenCopyLocationDialog(meta.rowData[0]);
              }}
            />
            )}
          </StyledCellCustomButtons>
        )),
      },
    },
  ];

  const options = {
    ...(disabledRowsSelection ? { selectableRows: 'none' } : {}),
    onColumnSortChange: (changeColumn, direction) => {
      onSortClicked(changeColumn, direction);
      setSelectedRows([]);
    },
    onSearchChange: (searchText) => {
      if (!searchText) {
        setSearchQuery('');
        return;
      }
      setPage(0);
      handleSearchWithDebounce(searchText);
    },
    onSearchClose: () => {
      setPage(0);
      setSearchQuery('');
    },
    serverSide: true,
    download: false,
    filter: false,
    page,
    pageSize,
    onChangePage: (currentPage) => {
      setPage(currentPage);
      setSelectedRows([]);
    },
    onChangeRowsPerPage: (numberOfRows) => {
      setPage(0);
      setPageSize(numberOfRows);
      setSelectedRows(previousSelectedRows => previousSelectedRows.filter((row) => row < numberOfRows));
    },
    onFilterChange: (changedColumn, filterList) => {
      handleChangeFilter(changedColumn, filterList);
    },
    onRowSelectionChange: (_, __, rowsSelected) => {
      setSelectedRows(rowsSelected);
    },
    rowsSelected: selectedRows,
    onViewColumnsChange: handleChangedColumns,
    rowsPerPageOptions: [2, 10, 100],
    count,
    elevation: 0,
    responsive: 'simple',
    draggableColumns: { enabled: true },
    onRowClick: clickable
      ? (rowData) => {
        onRowClickOperation(rowData);
        history.push(`${onRowClickURL}`);
      }
      : () => {},
    onRowsDelete(rows) {
      const onDeleteRows = () => {
        rows.data.map(({ dataIndex }) => {
          if (data[dataIndex].id) {
            onDeleteClicked(data[dataIndex].id);
            return;
          }

          const id = data[dataIndex][0];
          const functionName = data[dataIndex][1];
          onDeleteClicked(id, functionName);
        });

        closeDialog();
      };

      setDialogDataTable((prevState) => ({
        ...prevState,
        open: true,
        onConfirm: onDeleteRows,
      }));
    },
    textLabels: {
      body: {
        noMatch: loading ? (
          <Loading className="loading" />
        ) : (
          <EmptyTableContent />
        ),
        toolTip: dataTableTranslations.sort,
        columnHeaderTooltip: (column) => `${dataTableTranslations.sort_by} ${column.label}`,
      },
      pagination: {
        next: dataTableTranslations.next_page,
        previous: dataTableTranslations.previous_page,
        rowsPerPage: dataTableTranslations.rows_per_page,
        displayRows: dataTableTranslations.display_rows,
      },
      toolbar: {
        search: dataTableTranslations.search,
        downloadCsv: dataTableTranslations.download_csv,
        print: dataTableTranslations.print,
        viewColumns: dataTableTranslations.view_columns,
        filterTable: dataTableTranslations.filter_table,
      },
      filter: {
        all: dataTableTranslations.all_filters,
        title: dataTableTranslations.title_filters,
        reset: dataTableTranslations.reset_filters,
      },
      viewColumns: {
        title: dataTableTranslations.title_view_columns,
        titleAria: dataTableTranslations.title_aria_view_columns,
      },
      selectedRows: {
        text: dataTableTranslations.text_selected_rows,
        delete: dataTableTranslations.delete_selected_rows,
        deleteAria: dataTableTranslations.delete_aria_selected_rows,
      },
    },
  };

  return (
    <>
      <ConfirmDialog
        open={dialogDataTable.open}
        onClose={closeDialog}
        title={confirmDialogTitle}
        onCancel={closeDialog}
        onConfirm={dialogDataTable.onConfirm}
        confirmTitle="Deletar"
        confirmType="red"
      >
        {confirmDialogContentText}
      </ConfirmDialog>

      {dataRender && (
        <StyledDataTable
          className={className}
          title={title}
          data={dataRender}
          columns={noUpdate ? columns : dataColumns}
          options={options}
        />
      )}
    </>
  );
};

export default DataTable;
