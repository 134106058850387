import axios from 'axios';

import { TPutObject } from '../../types/TAWS';

export default async function putObject({
  accessToken,
  file,
  bucketName,
  batchFile = false,
  googleAccountId,
  googleLocationId,
  isCreating,
  mediaType = 'PHOTO',
}: TPutObject) {
  const requestOptions = {
    headers: {
      'Content-Type': 'multipart/form-data',
      Authorization: `Bearer ${accessToken}`,
    },
  };

  const bodyFormData = new FormData();
  bodyFormData.append('file', file);

  try {
    if (isCreating) isCreating(true);

    const { data } = await axios.post(
      `${process.env.REACT_APP_AWS_API_URL}/upload-file/${bucketName}/${googleAccountId}/${googleLocationId}/${batchFile}?mediaType=${mediaType}`,
      bodyFormData,
      requestOptions,
    );

    return data;
  } catch (err) {
    return null;
  } finally {
    if (isCreating) isCreating(false);
  }
}
