import axios from 'axios';

import { TGetLocationConnectionsByGoogleLocations } from '../../types/TGoogle';

export default async function getLocationConnectionsByGoogleLocations({
  accessToken,
  googleLocationIds,
}: TGetLocationConnectionsByGoogleLocations) {
  const requestOptions = {
    headers: {
      'access-token': accessToken,
    },
  };

  try {
    const { data } = await axios.get(
      `${process.env.REACT_APP_LOCATION_CONNECTIONS}/locations-google/${googleLocationIds}`,
      requestOptions,
    );

    return data;
  } catch (err: any) {
    return err.response;
  }
}
