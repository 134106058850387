export const locationNameAttr = {
  name: 'name',
  type: 'text',
  label: 'Nome do Local',
  description: 'Nome completo da localidade',
  hasBorder: true,
  required: true,
};

export const emailAttr = {
  name: 'email',
  type: 'text',
  label: 'E-mail',
  description: 'E-mail para contato',
  hasBorder: true,
};

export const mainPhoneAttr = {
  name: 'phone',
  type: 'text',
  label: 'Telefone Principal',
  description: 'Telefone para contato',
  hasBorder: true,
  hasMask: true,
  isPhone: true,
};

export const secondaryPhoneAttr = {
  name: 'secondary_phone',
  type: 'text',
  label: 'Telefone Secundário',
  description: 'Telefone alternativo para contato',
  hasBorder: true,
  hasMask: true,
  isPhone: true,
};
