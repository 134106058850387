import React from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Card } from 'react-bootstrap';
import { Button, Link } from '@material-ui/core';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';

import getCompanyLinkModels from '../../services/companies/getCompanyLinkModels';

import {
  CompanyNavBar,
  CompanyInfoNavBar,
} from '../../components';

const cardButtonStyle = {
  color: 'white',
  fontFamily: 'Poppins',
  fontWeight: 'bold',
  backgroundColor: '#4385F5',
  width: '15em',
};

const CompanyLinkModels = () => {
  const history = useHistory();

  const [linkModels, setLinkModels] = React.useState([]);

  const { user } = useSelector((state) => state.AuthReducer);
  const { activeCompany } = useSelector((state) => state.CompanyReducer);

  React.useEffect(() => {
    const fetchData = async () => {
      const { link_models: linkModelsArray } = await getCompanyLinkModels(user.accessToken, activeCompany.id);
      setLinkModels(linkModelsArray);
    };

    fetchData();
  }, [activeCompany]);

  const onEditClicked = (id) => {
    history.push(`/company/update-link-model/${id}`);
  };

  return (
    <div>
      <CompanyNavBar returnUrl="/company/link-models" />
      <CompanyInfoNavBar activeButton={1} />
      <div className="font-poppins" style={{ padding: '1em' }}>
        <text className="page-title">Modelos de Links</text>
        <div className="model-card-div">
          <Card>
            <Card.Body>
              <div className="creation-card-style">
                <AddCircleOutlineIcon
                  style={{ fontSize: '10em', color: 'gray' }}
                />
                <Button
                  style={cardButtonStyle}
                  variant="contained"
                  onClick={() => {
                    history.push('/company/create-link-model');
                  }}
                >
                  Criar Modelo
                </Button>
              </div>
            </Card.Body>
          </Card>
          {linkModels.map((element, key) => (
            <Card className="card-spacing" key={key}>
              <Card.Body>
                <Card.Title className="font-poppins">{element.name}</Card.Title>

                <Card.Text>
                  <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <text style={{ color: 'gray' }}>Website:</text>
                    <Link
                      href="#"
                      onClick={(event) => {
                        event.preventDefault();
                        window.open(element.website);
                      }}
                    >
                      {element.website}
                    </Link>
                  </div>
                </Card.Text>
                <Card.Text>
                  <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <text style={{ color: 'gray' }}>Facebook:</text>
                    <Link
                      href="#"
                      onClick={(event) => {
                        event.preventDefault();
                        window.open(element.facebook);
                      }}
                    >
                      {element.facebook}
                    </Link>
                  </div>
                </Card.Text>
                <Card.Text>
                  <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <text style={{ color: 'gray' }}>Instagram:</text>
                    <Link
                      href="#"
                      onClick={(event) => {
                        event.preventDefault();
                        window.open(element.instagram);
                      }}
                    >
                      {element.instagram}
                    </Link>
                  </div>
                </Card.Text>
                <Card.Text>
                  <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <text style={{ color: 'gray' }}>Twitter:</text>
                    <Link
                      href="#"
                      onClick={(event) => {
                        event.preventDefault();
                        window.open(element.twitter);
                      }}
                    >
                      {element.twitter}
                    </Link>
                  </div>
                </Card.Text>
                <Card.Text>
                  <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <text style={{ color: 'gray' }}>Youtube:</text>
                    <Link
                      href="#"
                      onClick={(event) => {
                        event.preventDefault();
                        window.open(element.youtube);
                      }}
                    >
                      {element.youtube}
                    </Link>
                  </div>
                </Card.Text>
                <Card.Text>
                  <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <text style={{ color: 'gray' }}>Foursquare:</text>
                    <Link
                      href="#"
                      onClick={(event) => {
                        event.preventDefault();
                        window.open(element.foursquare);
                      }}
                    >
                      {element.foursquare}
                    </Link>
                  </div>
                </Card.Text>
                <Card.Text>
                  <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <text style={{ color: 'gray' }}>Linkedin:</text>
                    <Link
                      href="#"
                      onClick={(event) => {
                        event.preventDefault();
                        window.open(element.linkedin);
                      }}
                    >
                      {element.linkedin}
                    </Link>
                  </div>
                </Card.Text>
                <Card.Text>
                  <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <text style={{ color: 'gray' }}>Yelp:</text>
                    <Link
                      href="#"
                      onClick={(event) => {
                        event.preventDefault();
                        window.open(element.yelp);
                      }}
                    >
                      {element.yelp}
                    </Link>
                  </div>
                </Card.Text>
                <Card.Text>
                  <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <text style={{ color: 'gray' }}>Outros Links:</text>
                    {element.other_links.split(' - ').map((link, key) => (
                      <Link
                        key={key}
                        href="#"
                        onClick={(event) => {
                          event.preventDefault();
                          window.open(link);
                        }}
                      >
                        {link}
                      </Link>
                    ))}
                  </div>
                </Card.Text>
                <Button
                  style={cardButtonStyle}
                  variant="contained"
                  onClick={() => {
                    onEditClicked(element.id);
                  }}
                >
                  Editar
                </Button>
              </Card.Body>
            </Card>
          ))}
        </div>
      </div>
    </div>
  );
};

export default CompanyLinkModels;
