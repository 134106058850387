import axios from 'axios';

export default async function getProfileMenu({
  accessToken,
  profileId,
  setTokenLikeExpired,
}) {
  const requestOptions = {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  };

  try {
    const { data } = await axios.get(
      `${process.env.REACT_APP_PROFILES_API_URL}/get-relation/menu/${profileId}`,
      requestOptions,
    );

    return data;
  } catch (err) {
    if (err.response?.status === 401 && setTokenLikeExpired) return setTokenLikeExpired();

    return err;
  }
}
