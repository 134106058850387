import styled from 'styled-components';

import { devices } from '../../assets/styles/devices';

export const StyledConfigs = styled.main`
  padding: 2rem;
  display: table;
`;

export const StyledHeader = styled.header`
  padding-top: 2rem;
  padding-bottom: 4rem;
  display: flex;
  width: 100%;

  h2 {
    font-size: 2rem;
    line-height: 2.2rem;
    font-weight: 700;

    @media ${devices.tabletLandscape} {
      margin-bottom: 0;
    }
  }
`;

export const StyledOptionsAccess = styled.div`  
  margin-bottom: 2rem;
  

  @media ${devices.tablet} {
    margin-right: 2rem;
    float: left;
  }

  @media ${devices.desktop} {
    margin-right: 2rem;
    float: left;
  }

  @media ${devices.widescreen} {
    margin-right: 2rem;
    float: left;
  }

  @media ${devices.fullhd} {
    margin-right: 2rem;
    float: left;
  }
`;

export const StyledContent = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  row-gap: 2rem;
  
  @media ${devices.tablet} {
    grid-template-columns: repeat(2, 1fr);
    column-gap: 2rem;
  }

  @media ${devices.desktop} {
    grid-template-columns: repeat(3, 1fr);
  }

  @media ${devices.widescreen} {
    grid-template-columns: repeat(4, 1fr);
  }

  @media ${devices.fullhd} {
    grid-template-columns: repeat(5, 1fr);
  }
`;
