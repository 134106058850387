import styled from 'styled-components';
import { lighten } from 'polished';

export const StyledFieldsRow = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 1rem 2rem;
    background: ${props => props.theme.colors.white};
    border-bottom: 1px solid ${props => lighten(0.3, props.theme.colors.grey)};

    &:last-of-type {
        margin-bottom: 0;
    }

    .status-text {
        display: flex;
        flex-direction: column;
        align-items: center;
        font-size: 1rem;
        padding-right: 2rem;
        text-align: center;
        word-wrap: initial;
    }

    .platform-value {
        display: flex;
    } 

    .platform-value-raw {
        max-width: 24rem;
    }

    .icon-status {
        margin: auto;
        width: 2rem;
    }

    .icon-right {
        fill: ${props => props.theme.colors.green};
    }

    .nonexistent,
    .wrong,
    .icon-warning {
        fill: ${props => props.theme.colors.yellow};
    }

    .empty,
    .icon-error {
        fill: ${props => props.theme.colors.red};
    }
`;

export const StyledFieldsName = styled.div`
    display: flex;
    align-items: center;

    span {
        line-height: 1;
        font-size: 1.2rem;
        font-weight: 700;
        margin-right: 1rem;
    }
`;

export const StyledFieldsGroup = styled.div`
    display: grid;
    grid-template-columns: repeat(2, 1fr) ;
    position: relative;
    width: 100%;

    span {        
        display: flex;
        align-items: center;    
        padding: 0 2rem;
        min-height: 4.4rem;
        word-break: break-all;

        &.hublocal-value.hours {
            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-start;
        }
    }

    .hours-day {
        display: flex;
        border-bottom: 1px solid ${props => lighten(0.3, props.theme.colors.grey)};
        margin-bottom: 0.5rem;
        margin-top: 1rem;

        &:last-child {
            border-bottom: 0;
            margin-bottom: 0;
        }

        small {
            margin-right: 1rem;
        }
    }
`;
