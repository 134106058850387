import React from 'react';

import {
  StyledLinkedin,
} from './linkedin-styles';

const Linkedin = () => (
  <StyledLinkedin>
    Linkedin
  </StyledLinkedin>
);

export default Linkedin;
