import styled from 'styled-components';
import { shade, rgba } from 'polished';
import {
  Card,
  CardMedia,
  CardContent,
  CardActions,
  Popover,
  MenuList,
  MenuItem,
} from '@material-ui/core';
import { devices } from '../../../../assets/styles/devices';

type TPostCardMedia = {
  component: string;
  height:string;
}

export const StyledPostCard = styled(Card)`
  display: flex;
  flex-direction: column;
  padding-bottom: 1rem;
  min-width: 20rem;

 span {
   font-size: 1.2rem;
 }
 `;

export const StyledRejectedPost = styled.div`
  position: absolute;
  z-index: 10;
  background: ${props => rgba(props.theme.colors.red, 0.6)};
  color: ${props => props.theme.colors.white};
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 2rem;
  font-weight: bold;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
`;

export const StyledPostCardHeaderDiv = styled.div`
  display: flex;
  flex-direction: column;
`;

export const StyledPostCardMedia = styled(CardMedia)<TPostCardMedia>``;
export const StyledPostCardContent = styled(CardContent)`
`;
export const StyledPostCardActions = styled(CardActions)`
  justify-content: space-between;
  width: 100%;
  margin-bottom: 1rem;
  padding: 0 16px;
`;

export const StyledPopover = styled(Popover)``;

export const StyledCardMenuList = styled(MenuList)``;

export const StyledCardMenuListItem = styled(MenuItem)`
font-size: 1.5rem;
font-family: Poppins;
`;

export const StyledDateValue = styled.div`
  position: absolute;
  padding: 0.5rem 1rem;
  color: ${props => props.theme.colors.white};
  z-index: 3;

  b {
    font-size: 1.2rem;
  }
`;

export const StyledPostCardMediaOverlay = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 2;
  background: -moz-linear-gradient(bottom,  rgba(0,0,0,0) 0%, rgba(0,0,0,0) 2%, rgba(0,0,0,0.6) 100%);
  background: -webkit-linear-gradient(bottom,  rgba(0,0,0,0) 0%,rgba(0,0,0,0) 2%,rgba(0,0,0,0.6) 100%);
  background: linear-gradient(to top,  rgba(0,0,0,0) 0%,rgba(0,0,0,0) 2%,rgba(0,0,0,0.6) 100%); 
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00000000', endColorstr='#e6000000',GradientType=0 );
`;

export const StyledMediaCardMedia = styled.div`
  display: flex;
  width: 100%;
  height: 30rem;
  position: relative;
  background: ${props => shade(0.1, props.theme.colors.white)};
  font-size: 1.2rem;
  overflow: hidden;

  @media ${devices.tablet} {
    height: 20rem;
  };

  @media ${devices.desktop} {
    height: 30rem;
  };

  @media ${devices.fullhd} {
    height: 35rem;
  };
`;

export const StyledLinkButton = styled.div`
  display: flex;
  align-content: center;
  justify-content: center;
  border-radius: 2rem;
  font-size: 1.2rem;
  font-weight: 400;
  background: ${props => props.theme.colors.primary};
  color: ${props => props.theme.colors.white};
  padding: 0.5rem 2rem;
  margin-bottom: 2rem;
  cursor: pointer;
  transition: 0.5s;
  
  
  &:hover {
    color: ${props => props.theme.colors.white};
    background: ${props => shade(0.1, props.theme.colors.primary)};
    transform: scale(1.1);
    text-decoration: none;
  }

  @media ${devices.tablet} {
    margin-bottom: 0;
    margin-left: auto;
    margin-right: 2rem;
  }
`;
