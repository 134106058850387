import React from 'react';
import * as R from 'ramda';

import type {
  TSpecialDateEntity,
  TSpecialDatesBaseProps,
  TWorkingHoursContext,
} from '../../../../types/TWorkingHours';

import { useAuth } from '../../../../hooks';

import WorkinkHoursContext from '../working-hours-context';

import CurrentDates from './current-dates';
import AddSpecialDate from './add-special-date';

import { StyledSectionTitle } from '../working-hours-styles';

import {
  StyledSpecialDates,
  StyledContent,
} from './special-dates-styles';

const SpecialDates = ({ currentLocation }: TSpecialDatesBaseProps) => {
  const { setSpecialDates, specialDates } = React.useContext<TWorkingHoursContext>(WorkinkHoursContext);

  const {
    userAccessToken,
    userSetTokenLikeExpired,
    userId,
    userProfileName,
  } = useAuth();

  const [specialDatesData, setSpecialDatesData] = React.useState <TSpecialDateEntity[]>([]);
  const [specialDateToEdit, setSpecialDateToEdit] = React.useState<TSpecialDateEntity | null>(null);

  React.useEffect(() => {
    if (R.isNil(currentLocation)) return;

    if (R.isNil(specialDates) || R.isEmpty(specialDates)) {
      setSpecialDatesData([]);

      return;
    }

    setSpecialDatesData(specialDates);
    setSpecialDates(specialDates);
  }, [currentLocation]);

  const handleHasSpecialDates = (data: TSpecialDateEntity[]) => {
    if (R.isNil(data)) return false;
    if (R.isEmpty(data)) return false;

    return true;
  };

  return (
    <StyledSpecialDates>
      <StyledSectionTitle>

        Datas especiais
      </StyledSectionTitle>
      {!R.isNil(currentLocation) && (
        <StyledContent>
          <AddSpecialDate
            hasSpeciaDates={handleHasSpecialDates(specialDatesData)}
            activeLocation={currentLocation}
            currentSpecialDates={specialDatesData}
            specialDateToEdit={specialDateToEdit}
            setSpecialDateToEdit={setSpecialDateToEdit}
            userAccessToken={userAccessToken}
            userSetTokenLikeExpired={userSetTokenLikeExpired}
            userId={userId}
            userProfile={userProfileName}
            setSpecialDates={setSpecialDates}
          />
          <CurrentDates
            data={specialDatesData}
            activeLocation={currentLocation}
            setSpecialDateToEdit={setSpecialDateToEdit}
            setSpecialDates={setSpecialDates}
          />
        </StyledContent>
      )}
    </StyledSpecialDates>
  );
};

export default SpecialDates;
