import React from 'react';
import InputMask from 'react-input-mask';
import * as MaterialUIIcons from '@material-ui/icons/';

import { TInputBaseProps } from '../../types/TInput';

import {
  StyledInput,
  StyledInputValidate,
  StyledInputWrapper,
  StyledDateRangeIcon,
} from './input-styles';

const Input = ({
  inputOptions,
  inputValidate,
  fieldRef,
  label,
  disabled,
  borderRadius,
  border,
  mask,
  showPassword,
  changeShowPassword,
  password,
  className,
  value,
  onChange,
  defaultValue,
  onFocus,
  onBlur,
  placeholder,
  submitFunction,
  autoComplete,
  isDateInput = false,
  inputRefMask,
  pattern,
  borderAlert = false,
  noBackground = false,
}: TInputBaseProps) => {
  const IconToShowPassword = MaterialUIIcons[showPassword ? 'VisibilityOff' : 'Visibility'];

  const [inputMaskValueState, setInputMaskValueState] = React.useState<
    string | undefined
  >('');

  React.useEffect(() => {
    setInputMaskValueState(value);
  }, [value]);

  return (
    <StyledInput
      disabled={disabled}
      borderRadius={borderRadius}
      border={border}
      borderAlert={borderAlert}
      className={className}
      isDateInput={isDateInput}
      noBackground={noBackground}
    >
      {label && <label htmlFor={inputOptions?.name}>{label}</label>}

      <StyledInputWrapper>
        {isDateInput && <StyledDateRangeIcon />}

        {mask ? (
          <InputMask
            value={inputMaskValueState}
            onChange={(e) => {
              if (onChange) onChange(e);
              setInputMaskValueState(e.target.value);
            }}
            inputRef={(e) => {
              if (inputRefMask) inputRefMask(e);
            }}
            type={inputOptions?.type || 'text'}
            name={inputOptions?.name}
            disabled={disabled}
            mask={mask}
            onFocus={onFocus}
            onBlur={onBlur}
            autoComplete={autoComplete}
          />
        ) : (
          <input
            placeholder={placeholder}
            value={value}
            defaultValue={defaultValue}
            onChange={onChange}
            ref={fieldRef}
            type={inputOptions?.type || 'text'}
            name={inputOptions?.name || 'input-name'}
            disabled={disabled}
            onFocus={onFocus}
            onBlur={onBlur}
            onKeyDown={submitFunction}
            autoComplete={autoComplete}
            max={inputOptions?.type === 'date' ? '2999-12-31' : undefined}
            pattern={pattern}
          />
        )}

        {password && changeShowPassword && (
          <IconToShowPassword
            className="show-password-icon"
            onClick={() => changeShowPassword(!showPassword)}
          />
        )}
      </StyledInputWrapper>

      {inputValidate && inputValidate.visible && (
        <StyledInputValidate type={inputValidate.type}>
          {inputValidate.message}
        </StyledInputValidate>
      )}
    </StyledInput>
  );
};

export default Input;
