import styled from 'styled-components';
import { AppBar, Tabs } from '@material-ui/core';
import { lighten, shade } from 'polished';
import { devices } from '../../assets/styles/devices';

export const StyledTabBar = styled(AppBar)`
    box-shadow: none;
    background: ${props => props.theme.colors.white};
    border-top: ${props => (props.noBorderTop ? 'none' : `1px solid ${lighten(0.3, props.theme.colors.grey)}`)};

    .tabbar-tab {
      font-size: 1.4rem;
      font-weight: normal;
      color: ${props => props.theme.colors.black};
      padding: 2rem;
    }

    .tabbar-smalltab {
      font-size: 1.4rem;
      font-weight: normal;
      color: ${props => props.theme.colors.black};
    }

    .MuiTabs-indicator {
      display: none !important;
    }

    .MuiTabs-flexContainer {
      flex-direction: column;

      @media ${devices.tablet} {
        flex-direction: row;
      }
    }
`;

export const StyledTabs = styled(Tabs)``;
