import styled from 'styled-components';
import { Dialog } from '@material-ui/core';
import { lighten, darken } from 'polished';

export const StyledToCompareDialog = styled(Dialog)`
    .MuiDialog-paper {
        max-width: 76.8rem;
        width: 100%;
        background: ${props => darken(0.05, props.theme.colors.white)};
    }
`;

export const StyledHeader = styled.div`
    display: flex;
    background: ${props => props.theme.colors.white};

    .column {
        width: 50%;
        padding: 2rem;
        border-bottom: 1px solid ${props => lighten(0.3, props.theme.colors.grey)};
        font-weight: bold;
        color: ${props => props.theme.colors.primary};

        &:last-of-type {
            border-right: 0;
        }
    }
`;

export const StyledContent = styled.div`
    display: flex;
    flex-direction: column;
    padding-bottom: 3rem;
`;
