import React from 'react';
import { useMediaQuery } from 'react-responsive';

import TicketActionMobile from './ticket-action-mobile';

import limitedText from '../../../../utils/limited-text';

import { ticketStatusBoxData } from '../../helpers';

import {
  StyledTicket,
  StyledBoxTypeTicket,
  StyledContent,
  StyledTicketDetails,
  StyledTicketTitle,
} from './ticket-styles';

const Tickets = ({ ticket, onClick }) => {
  const [ticketStatusBox, setTicketStatusBox] = React.useState(null);

  const isMobile = useMediaQuery({ maxWidth: 899 });

  React.useEffect(() => {
    const ticketStatusBoxResponse = ticketStatusBoxData[ticket.status];
    setTicketStatusBox(ticketStatusBoxResponse);
  }, [ticket]);

  return (
    <StyledTicket
      onClick={onClick}
      status={ticketStatusBox && ticketStatusBox.status}
    >
      {ticketStatusBox
        && (
        <StyledBoxTypeTicket status={ticketStatusBox.status}>
          {ticketStatusBox.letter}
        </StyledBoxTypeTicket>
        )}
      <StyledContent>
        <StyledTicketTitle>
          {limitedText(ticket.name, 60)}
        </StyledTicketTitle>
        {isMobile ? <TicketActionMobile status={ticketStatusBox && ticketStatusBox.status} /> : (
          <StyledTicketDetails>
            <div className="line-detail">
              <span>
                Data de criação:
                <small>
                  {new Date(ticket.created_at).toLocaleString('pt-BR', {
                    timeZone: 'America/Fortaleza',
                  })}

                </small>
              </span>
            </div>
          </StyledTicketDetails>
        )}
      </StyledContent>
    </StyledTicket>
  );
};

export default Tickets;
