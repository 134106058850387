import axios from 'axios';

export default async function getFunction(accessToken, functionId) {
  const requestOptions = {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  };

  console.log(`${process.env.REACT_APP_FUNCTIONS_API_URL}/${functionId}`);

  const { data } = await axios.get(
    `${process.env.REACT_APP_FUNCTIONS_API_URL}/${functionId}`,
    requestOptions,
  );

  return data;
}
