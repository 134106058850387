import axios from 'axios';
import { TGetAllConnectionsFromLocation } from '../../types/TPlatformsLinkWithLocation';

export default async function getAllConnectionsFromLocation({
  accessToken,
  page = 0,
  pageSize = 10,
  locationId,
  status,
  googleLocationId,
  setTokenLikeExpired,
  platform = 'google',
}: TGetAllConnectionsFromLocation) {
  try {
    const { data } = await axios.get(
      `${process.env.REACT_APP_LOCATION_CONNECTIONS}/location/${locationId}`,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
        params: {
          page,
          pageSize,
          locationId,
          status,
          googleLocationId,
          platform,
        },
      },
    );

    return data;
  } catch (err: any) {
    if (err.response?.status === 401 && setTokenLikeExpired) setTokenLikeExpired();

    return null;
  }
}
