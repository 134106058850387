import React from 'react';

import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from '@material-ui/core';

import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import UpdateInfoContext from '../update-info-context';

import Infos from './infos';
import Links from './links';
import WorkingHours from './working-hours/working-hours';
import SpecialDates from '../../../components/special-dates';

import {
  StyledInfoUpdate,
  StyledTitle,
  StyledWorkingHoursWrapper,
} from './info-update-styles';

const InfoUpdate = () => {
  const {
    userAccessToken,
    userSetTokenLikeExpired,
    infoFields,
    setInfoFields,
    linksFields,
    setLinksFields,
    otherLinksFields,
    setOtherLinksFields,
    categoryGroupingsSelected,
    setCategoryGroupingsSelected,
    selectedGoogleCategories,
    setSelectedGoogleCategories,
    selectedFacebookCategories,
    setSelectedFacebookCategories,
    selectedFoursquareCategories,
    setSelectedFoursquareCategories,
    handleChangeCategories,
    locationFormData,
    updatedWorkingHours,
    handleChangeTab,
    workingHoursRef,
    specialDatesRef,
    expanded,
    setFieldsToDefault,
  } = React.useContext(UpdateInfoContext);

  return (
    <StyledInfoUpdate>
      <Accordion
        expanded={expanded === 'panel1'}
        onChange={handleChangeTab('panel1')}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1bh-content"
          id="panel1bh-header"
          className="first-accordion"
        >
          <StyledTitle>Informações Gerais</StyledTitle>
        </AccordionSummary>
        <AccordionDetails>
          <Infos
            userAccessToken={userAccessToken}
            userSetTokenLikeExpired={userSetTokenLikeExpired}
            categoryGroupingsSelected={categoryGroupingsSelected}
            setCategoryGroupingsSelected={setCategoryGroupingsSelected}
            selectedGoogleCategories={selectedGoogleCategories}
            setSelectedGoogleCategories={setSelectedGoogleCategories}
            selectedFacebookCategories={selectedFacebookCategories}
            setSelectedFacebookCategories={setSelectedFacebookCategories}
            selectedFoursquareCategories={selectedFoursquareCategories}
            setSelectedFoursquareCategories={setSelectedFoursquareCategories}
            fields={infoFields}
            setFields={setInfoFields}
            setFieldsToDefault={setFieldsToDefault}
          />
        </AccordionDetails>
      </Accordion>
      <Accordion
        expanded={expanded === 'panel2'}
        onChange={handleChangeTab('panel2')}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2bh-content"
          id="panel2bh-header"
        >
          <h4>Links</h4>
        </AccordionSummary>
        <AccordionDetails>
          <Links
            linksFields={linksFields}
            setLinksFields={setLinksFields}
            otherLinksFields={otherLinksFields}
            setOtherLinksFields={setOtherLinksFields}
          />
        </AccordionDetails>
      </Accordion>
      <Accordion
        expanded={expanded === 'panel3'}
        onChange={handleChangeTab('panel3')}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel3bh-content"
          id="panel3bh-header"
        >
          <h4>Horários de Funcionamento</h4>
        </AccordionSummary>
        <AccordionDetails>
          <StyledWorkingHoursWrapper>
            <WorkingHours
              ref={workingHoursRef}
              locationFormData={locationFormData}
              updatedWorkingHours={updatedWorkingHours}
            />
          </StyledWorkingHoursWrapper>
        </AccordionDetails>
      </Accordion>
      <Accordion
        expanded={expanded === 'panel4'}
        onChange={handleChangeTab('panel4')}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel3bh-content"
          id="panel3bh-header"
          className="last-accordion"
        >
          <h4>Datas Especiais</h4>
        </AccordionSummary>
        <AccordionDetails>
          <StyledWorkingHoursWrapper>
            <SpecialDates ref={specialDatesRef} />
          </StyledWorkingHoursWrapper>
        </AccordionDetails>
      </Accordion>
    </StyledInfoUpdate>
  );
};

export default InfoUpdate;
