import React from 'react';
import { MUIDataTableColumn } from 'mui-datatables';

export const tableColumns: MUIDataTableColumn[] = [
  {
    label: 'Id',
    name: 'id',
    options: {
      display: false,
      sort: false,
    },
  },
  {
    label: 'Informações atualizadas',
    name: 'updated_info',
    options: {
      sort: false,
    },
  },
  {
    label: 'Data da atualização',
    name: 'created_at',
    options: {
      sort: false,
    },
  },
  {
    label: 'Status',
    name: 'status',
    options: {
      sort: false,
      customHeadRender: (columnMeta): React.ReactNode => React.createElement('th', { style: { textAlign: 'center' } }, columnMeta.label),
    },
  },
  {
    label: 'Operações',
    name: 'operations',
    options: {
      sort: false,
      customHeadRender: (columnMeta): React.ReactNode => React.createElement('th', { style: { textAlign: 'center' } }, columnMeta.label),
    },
  },
];

export const infoBasic = [
  'NAME',
  'MAIN_PHONE',
  'SECONDARY_PHONE',
  'SHORT_DESCRIPTION',
  'LONG_DESCRIPTION',
  'ZIP_CODE',
  'COUNTRY_CODE',
  'ADDRESS1',
  'ADDRESS2',
  'NEIGHBORHOOD',
  'CITY',
  'STATE_CODE',
  'DOES_NOT_SERVE_AT_THIS_ADDRESS',
  'GOOGLE_CATEGORIES',
  'FACEBOOK_CATEGORIES',
  'FOURSQUARE_CATEGORIES',
];

export const links = ['LINKS',
  'WEBSITE',
  'FACEBOOK',
  'INSTAGRAM',
  'TWITTER',
  'YOUTUBE',
  'FOURSQUARE',
  'LINKEDIN',
  'YELP',
  'OTHER_LINKS',
  'EMAIL',
];

export const schedule = [
  'WORKING_HOURS',
];

export const special_dates = [
  'SPECIAL_DATES',
];
