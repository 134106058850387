import styled, { css } from 'styled-components';
import { ArrowDropDown } from '@material-ui/icons';
import { Popover } from '@material-ui/core';
import { lighten } from 'polished';

export const StyledSelectDropdownContainer = styled.div`
  width: 100%;
`;

export const StyledSelectDropdown = styled.div`
  width: 100%;
  height: 4.1rem;
  padding: 0 1.5rem;
  border: 2px solid ${(props) => lighten(0.4, props.theme.colors.black)};
  background: ${(props) => props.theme.colors.white};
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const StyledChevronDown = styled(ArrowDropDown)`
  width: 2rem;
  height: 2rem;
`;

export const StyledPopover = styled(Popover)`
  .MuiPopover-paper {
    display: flex;
    flex-direction: column;
    box-shadow: 0px 5px 5px -3px rgb(0 0 0 / 20%),
      0px 8px 10px 1px rgb(0 0 0 / 14%), 0px 3px 14px 2px rgb(0 0 0 / 12%);
  }

  .input-search {
    padding: 1rem 2rem;
    border-bottom: 0.1rem solid
      ${(props) => lighten(0.9, props.theme.colors.black)};
  }

  .loading-data {
    margin: 2rem auto;
  }
`;

export const StyledOptionsList = styled.ul`
  display: flex;
  flex-direction: column;
`;

export const StyledOption = styled.li`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  padding: 1rem 2rem;
  cursor: pointer;
  transition: 0.3s;

  &:nth-child(even):hover,
  &:hover {
    color: ${(props) => props.theme.colors.white};
    background: ${(props) => props.theme.colors.primary};
  }

  &:nth-child(even) {
    background: ${(props) => lighten(0.9, props.theme.colors.black)};
  }

  ${(props) => props.isDisabled === true
    && css`
      pointer-events: none;
      opacity: 0.3;
    `};
`;

export const StyledSubLabelText = styled.span`
  font-size: 1.3rem;
  color: ${props => lighten(0.6, props.theme.colors.black)};
`;
