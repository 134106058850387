import React from 'react';
import axios from 'axios';

import type {
  EBatchPlatformGroupType,
  EBatchPlatformStatus,
} from '../../../types/TBatchGoogleModules';
import type { TArrayResponseWithCount } from '../../../types/TArrayResponseWithCount';
import type { TServiceBatch } from '../../../types/TServiceBatch';

export type TFindServiceBatches = {
  userAccessToken: string;
  groupType: EBatchPlatformGroupType;
  groupTypeId: number;
  isFetching?: React.Dispatch<React.SetStateAction<boolean>>;
  query?: string;
  pageSize?: number;
  page?: number;
  status?: EBatchPlatformStatus;
  startDate?: string | null;
  endDate?: string | null;
};

type TSuccessResponse = {
  status: 'SUCCESS',
  data: TArrayResponseWithCount<TServiceBatch>;
};

type TErrorResponse = {
  status: 'ERROR',
  data: any;
};

export const findServiceBatches = async ({
  isFetching,
  userAccessToken,
  groupType,
  groupTypeId,
  page = 0,
  pageSize = 10,
  status,
  startDate,
  endDate,
  query,
}: TFindServiceBatches): Promise<TSuccessResponse | TErrorResponse> => {
  try {
    if (isFetching) isFetching(true);

    let pathUrl = `service-batch?groupTypeId=${groupTypeId}&groupType=${groupType}&pageSize=${pageSize}&page=${page}`;
    if (status) pathUrl += `&status=${status}`;
    if (startDate) pathUrl += `&startDate=${startDate}`;
    if (endDate) pathUrl += `&endDate=${endDate}`;
    if (query) pathUrl += `&query=${query}`;

    const { data } = await axios.get<TArrayResponseWithCount<TServiceBatch>>(
      `${process.env.REACT_APP_SAAS_BATCH_GOOGLE_MODULES}/${pathUrl}`,
      {
        headers: {
          Authorization: `Bearer ${userAccessToken}`,
        },
      }
    );

    return {
      status: 'SUCCESS',
      data,
    };
  } catch (err: any) {
    return {
      status: 'ERROR',
      data: err.response,
    };
  } finally {
    if (isFetching) isFetching(false);
  }
};
