import React from 'react';

import Button from '../../../../../../button';
import Loading from '../../../../../../loading';

import {
  StyledHeader,
} from './header-styles';

const Header = ({
  loadingCreate,
  allowCreate = false,
  headerTitle,
  createButtonTitle,
  handleCreateTitle,
  hasItems,
}) => (
  <StyledHeader>
    <h3>{headerTitle}</h3>
    {(!hasItems || allowCreate)
      && (
      <Button
        onClick={handleCreateTitle}
        disabled={loadingCreate}
      >
        {loadingCreate ? <Loading /> : createButtonTitle}
      </Button>
      )}
  </StyledHeader>
);

export default Header;
