import React from 'react';
import { MUIDataTableOptions, MUIDataTableColumn } from 'mui-datatables';
import { format } from 'date-fns';

import type { TBatchInfosLocationsSubTable } from '../../../../types/TBatchUpdate';
import type { EBatchPlatformStatus } from '../../../../types/TBatchGoogleModules';

import { findBatchInfosLocations } from '../../../../services/google-batch-modules/batch-infos';

import { Table } from '../../../../components';
import { useAuth } from '../../../../hooks';

import { BatchInfosLocationsRow } from './batch-infos-locations-row';

import { StyledBatchInfosLocationsTable } from './batch-infos-locations-table-styles';
import { getDataRowCellValue } from '../../helpers';

export const BatchInfosLocationsTable = ({
  batchInfos,
  updateBatchInfos,
}: TBatchInfosLocationsSubTable) => {
  const [batchInfosLocations, setBatchInfosLocations] = React.useState([]);
  const [batchInfosLocationsPage, setBatchInfosLocationsPage] = React.useState(0);
  const [batchInfosLocationsPageSize, setBatchInfosLocationsPageSize] = React.useState(10);
  const [batchInfosLocationsCount, setBatchInfosLocationsCount] = React.useState(0);
  const [batchInfosLocationsQuery, setBatchInfosLocationsQuery] = React.useState('');
  const [isLoading, setIsLoading] = React.useState(false);

  const { userAccessToken } = useAuth();

  const batchInfosId = React.useMemo(() => getDataRowCellValue({ columnRef: 'id', dataRowCell: batchInfos }), [batchInfos]);
  const batchInfosStatus = React.useMemo(() => getDataRowCellValue({ columnRef: 'status', dataRowCell: batchInfos }), [batchInfos]);

  const getBatchInfosLocations = React.useCallback(async () => {
    if (batchInfosStatus === 'CANCELING') return;

    const batchInfosLocationsResponse = await findBatchInfosLocations({
      batchInfosId,
      userAccessToken,
      page: batchInfosLocationsPage,
      pageSize: batchInfosLocationsPageSize,
      query: batchInfosLocationsQuery,
      isFetching: setIsLoading,
    });

    if (batchInfosLocationsResponse.status === 'ERROR') return;

    const [
      batchInfosLocationsData,
      batchInfosLocationsDataCount,
    ] = batchInfosLocationsResponse.data;

    const batchInfosLocationsDataNormalized = batchInfosLocationsData.map(
      (batchInfosLocation: any) => [
        {
          columnRef: 'id',
          visible: false,
          value: batchInfosLocation.id,
        },
        {
          columnRef: 'location-name',
          visible: true,
          value: batchInfosLocation.name,
        },
        {
          columnRef: 'updated_at',
          visible: true,
          value: format(
            new Date(batchInfosLocation.updated_at),
            'dd/MM/yyyy HH:mm:ss',
          ),
        },
        {
          columnRef: 'history',
          visible: true,
          value: `${batchInfosId}/${batchInfosLocation.id}`,
        },
        {
          columnRef: 'status',
          visible: true,
          value: batchInfosLocation.status as EBatchPlatformStatus,
        },
        {
          columnRef: 'maps_url',
          visible: false,
          value: batchInfosLocation.maps_url,
        },
      ],
    );

    setBatchInfosLocations(batchInfosLocationsDataNormalized);
    setBatchInfosLocationsCount(batchInfosLocationsDataCount);
  }, [
    batchInfosId,
    batchInfosStatus,
    batchInfosLocationsPage,
    batchInfosLocationsPageSize,
    batchInfosLocationsQuery,
  ]);

  React.useEffect(() => {
    getBatchInfosLocations();
  }, [getBatchInfosLocations]);

  const tableOptions = React.useMemo(
    (): MUIDataTableOptions => ({
      count: batchInfosLocationsCount,
      page: batchInfosLocationsPage,
      selectableRows: 'none',
    }),
    [batchInfosLocationsPage, batchInfosLocationsCount],
  );

  const tableColumns = React.useMemo(
    (): MUIDataTableColumn[] => [
      {
        name: 'id',
        label: 'Id',
        options: {
          display: false,
          sort: false,
        },
      },
      {
        name: 'location-name',
        label: 'Nome do local',
        options: {
          sort: false,
        },
      },
      {
        name: 'updated-at',
        label: 'Data de atualização',
        options: {
          sort: false,
        },
      },
      {
        name: 'history',
        label: 'Histórico',
        options: {
          sort: false,
        },
      },
      {
        name: 'status',
        label: 'Status',
        options: {
          sort: false,
        },
      },
      {
        name: 'actions',
        label: 'Ações',
        options: {
          sort: false,
        },
      },
    ],
    [],
  );

  return (
    <StyledBatchInfosLocationsTable>
      {
        batchInfosStatus === 'CANCELING' ? 'Processo de cancelamento em andamento...' : (
          <Table
            loading={isLoading}
            options={tableOptions}
            title="Locais afetados"
            columns={tableColumns}
            data={batchInfosLocations}
            row={(rowData) => (
              <BatchInfosLocationsRow
                data={rowData}
                batchId={batchInfosId}
                updateBatch={updateBatchInfos}
              />
            )}
            setPage={setBatchInfosLocationsPage}
            setPageSize={setBatchInfosLocationsPageSize}
            setQuery={setBatchInfosLocationsQuery}
          />
        )
      }
    </StyledBatchInfosLocationsTable>
  );
};
