import styled from 'styled-components';
import { Paper } from '@material-ui/core';

import { devices } from '../../assets/styles/devices';

export const StyledCreateLocation = styled.main`
    display: flex;
    flex-direction: column;

    .batch-creation {
        margin-bottom: 3rem;
    }

    .tabpanel {
        display: grid;
        grid-template-columns: 1fr;
        row-gap: 2rem;
        padding: 3rem 2rem 2rem;

        @media ${devices.tabletLandscape} {
            grid-template-columns: 75% 23%;
            column-gap: 2%;
        }
    }
`;

export const StyledContent = styled.div`
    display: grid;
    grid-template-columns: 1fr;
    row-gap: 2rem;
    padding: 3rem 2rem 2rem;

    @media ${devices.tabletLandscape} {
        grid-template-columns: 75% 23%;
        column-gap: 2%;
    }
`;

export const StyledContentMain = styled.div`
    display: flex;
    flex-direction: column;

    .responsible-info-block {
        margin-bottom: 3rem;
    }
`;

export const StyledContentAside = styled.aside`
    display: flex;
    flex-direction: column;
    height: max-content;
    position: sticky;
    top: 1rem;

    @media ${devices.desktop} {
        padding-top: 3.8rem;
    }
`;

export const StyledContractListWrapper = styled(Paper)`
  display: flex;
  flex-direction: column;
  margin-bottom: 3rem;
  padding: 2rem;
`;
