import React from 'react';
import { useSelector } from 'react-redux';
import * as R from 'ramda';
import { useSnackbar } from 'notistack';

import { format, parse } from 'date-fns';

import { ExpandLess, ExpandMore } from '@material-ui/icons';

import type { TRootStateRedux } from '../../../../types/TRootStateRedux';
import createTicket from '../../../../services/tickets/create';
import createEventPost from '../../../../services/google/post/createPost';
import getValidGoogleAccessTokenFromLocation from '../../../../services/getValidGoogleAccessTokenFromLocation';

import putObject from '../../../../services/aws/putObject';
import { applyMaskToTime } from '../../../../utils/apply-mask-to-time';

import {
  imagePostValidation,
  locationPostAddButtonsType,
  getActionType,
  getActionTypeReverse,
  formatDateObjectToString,
} from '../../helpers';

import {
  isValidPostStartToEndDate,
  isValidPostStartToEndTime,
  handleFormHasManyImagesOrVideo,
  isVideoFiles,
} from '../helpers';

import { inputTimeToArrayData } from '../../../../utils/input-time-to-array-data';

import {
  Input,
  InputSwitch,
  InputDatePicker,
  Textarea,
  Time,
  Button,
  Loading,
  Select,
} from '../../../../components';

import type {
  TLocationPostAddButtonType,
  TPostFormEventBaseProps,
  TObjectInAWSS3BucketReturn,
} from '../../../../types/TLocationPost';

import { websiteValidate } from '../../../../utils/fields-validate';

import { useAuth, useLocationTickets } from '../../../../hooks';

import APIUpdateTicket from '../../../../services/tickets/update';

import DragAndDropFiles from '../../../../components/drag-and-drop-file';
import patchEventPost from '../../../../services/google/post/patchPost';

import {
  StyledPostFormEvent,
  StyledButton,
  StyledInputSwitchWrapper,
  StyledAdditionalDetailsWrapper,
  StyledHourFieldWrapper,
  StyledAlertMessage,
} from './post-form-event-styles';

const PostFormEvent = ({ closeModal, dataToUpdate }: TPostFormEventBaseProps) => {
  const [eventTitle, setEventTitle] = React.useState('');
  const [hasHour, setHasHour] = React.useState(false);
  const [addedButtonType, setAddedButtonType] = React.useState('Nenhum');
  const [addedButtonTypeValue, setAddedButtonTypeValue] = React.useState<string>('');
  const [addDetails, setAddDetails] = React.useState(false);
  const [openingHour, setOpeningHour] = React.useState<string>('00:00');
  const [closingHour, setClosingHour] = React.useState<string>('00:00');
  const [eventDetails, setEventDetails] = React.useState('');
  const [filesToPost, setFilesToPost] = React.useState<any[]>([]);
  const [isPublish, setIsPublish] = React.useState<React.SetStateAction<boolean>>(false);
  const [isAddedButton, setIsAddedButton] = React.useState(false);
  const [isAddedButtonPhoneType, setIsAddedButtonPhoneType] = React.useState(false);
  const [startDate, setStartDate] = React.useState<Date | null>(null);
  const [endDate, setEndDate] = React.useState<Date | null>(null);
  const [postNameId, setPostNameId] = React.useState<number | null>(null);
  const [googleAccessToken, setGoogleAccessToken] = React.useState<string | null>(null);
  const [googleAccountId, setGoogleAccountId] = React.useState<string | null>(null);
  const [googleLocationId, setGoogleLocationId] = React.useState<string | null>(null);

  const { activeLocation } = useSelector((state: TRootStateRedux) => state.LocationReducer);

  const { enqueueSnackbar } = useSnackbar();

  const {
    userAccessToken,
    userId,
    userProfile,
    userSetTokenLikeExpired,
  } = useAuth();

  const {
    locationPostTickets,
    locationPostOpenTickets,
  } = useLocationTickets();

  const getValidGoogleAccessToken = React.useCallback(async () => {
    const getValidGoogleAccessTokenResponse = await getValidGoogleAccessTokenFromLocation({
      accessToken: userAccessToken,
      userId,
      userProfile,
      locationId: activeLocation.id,
    });

    if (R.isNil(getValidGoogleAccessTokenResponse)) return;

    const { googleAccessToken: accessTokenGoogle, connectionInfo } = getValidGoogleAccessTokenResponse;

    setGoogleAccessToken(accessTokenGoogle);
    setGoogleAccountId(connectionInfo.google_account_id);
    setGoogleLocationId(connectionInfo.google_location_id);
  }, []);

  React.useEffect(() => { getValidGoogleAccessToken(); }, [getValidGoogleAccessToken]);

  const getObjectInAWSS3Bucket = React.useCallback(
    async (element: any): Promise<TObjectInAWSS3BucketReturn> => {
      if (R.isNil(googleAccountId) || R.isNil(googleLocationId)) return null;

      if (!R.isEmpty(element)) {
        const putObjectData = await putObject({
          accessToken: userAccessToken,
          bucketName: 'hub-saas-media',
          file: element,
          googleAccountId,
          googleLocationId,
          isCreating: setIsPublish,
          mediaType: element.type === 'video/mp4' ? 'VIDEO' : 'PHOTO',
        });

        const mediaFileUrl = await putObjectData.fileUrl;

        return {
          mediaFormat: element.type === 'video/mp4' ? 'VIDEO' : 'PHOTO',
          mediaSourceUrl: mediaFileUrl,
        };
      }

      return null;
    }, [googleAccountId, googleLocationId],
  );

  const getInputDateData = inputTimeToArrayData('/');
  const getInputTimeData = inputTimeToArrayData(':');

  const isValidInput = ({
    isValidEventDate,
    messageEventDateFeedBack,
    isValidEventTime,
    messageEventTimeFeedBack,
    actionType,
  }: any) => {
    if (R.isEmpty(eventTitle)) {
      enqueueSnackbar('O Título do Evento não pode ser vazio!', { variant: 'warning' });

      return false;
    }

    if (!isValidEventDate) {
      enqueueSnackbar(messageEventDateFeedBack, { variant: 'warning' });

      return false;
    }

    if (!isValidEventTime) {
      enqueueSnackbar(messageEventTimeFeedBack, { variant: 'warning' });

      return false;
    }

    if (R.isNil(googleAccessToken)) {
      enqueueSnackbar('Não foi possível adquirir um token do Google para criar postagem', { variant: 'warning' });

      return false;
    }

    const isInvalidActionType = (!R.isNil(actionType) && actionType !== 'CALL') && R.isEmpty(addedButtonTypeValue.trim());

    if (isInvalidActionType) {
      enqueueSnackbar('A URL do botão de ação não pode ser vazio!', { variant: 'warning' });

      return false;
    }

    if (!R.isEmpty(addedButtonTypeValue) && !R.isNil(addedButtonTypeValue)) {
      if (!websiteValidate(addedButtonTypeValue)) {
        enqueueSnackbar('O formato do link inserido é inválido!', { variant: 'warning' });
        return false;
      }
    }

    if (!R.isEmpty(eventDetails) && !R.isNil(eventDetails)) {
      if (eventDetails.length > 1500) {
        enqueueSnackbar('Os detalhes da postagem não podem conter mais de 1500 caracteres', { variant: 'warning' });
        return false;
      }
    }

    return true;
  };

  const hasOpenTicket = React.useCallback(async () => {
    if (R.isNil(activeLocation)) return null;

    const thisPostOpenTicket = locationPostOpenTickets.find(ticket => {
      const { post_id } = ticket.data;

      return post_id === postNameId;
    });

    if (!R.isNil(thisPostOpenTicket)) return thisPostOpenTicket;

    return null;
  }, [
    activeLocation,
    locationPostTickets,
    locationPostOpenTickets,
    postNameId,
  ]);

  const handleCreateLocationPostTicket = React.useCallback(async (inputData: any) => {
    const platformsSyncInitialStatus = {
      google: 'IN_PROGRESS',
      facebook: 'IN_PROGRESS',
      foursquare: 'IN_PROGRESS',
      yelp: 'IN_PROGRESS',
      linkedin: 'IN_PROGRESS',
    };

    const createOfferPostTicket = await createTicket({
      accessToken: userAccessToken,
      location_id: activeLocation.id,
      type: 'POST',
      changedData: {
        info_to_update: JSON.stringify(inputData),
        post_id: postNameId,
        new: true,
        status: platformsSyncInitialStatus,
      },
      setTokenLikeExpired: null,
      created_by: userId,
      isSync: setIsPublish,
    });

    if (!R.isNil(createOfferPostTicket)) {
      enqueueSnackbar(
        'Sua postagem está sendo processada e será lançada em breve',
        { variant: 'success' },
      );
    }
  }, [
    userAccessToken,
    activeLocation,
    userId,
    postNameId,
  ]);

  const handlePublishClicked = React.useCallback(async () => {
    if (R.isNil(activeLocation)) return;

    if (R.isNil(startDate)) return;
    if (R.isNil(endDate)) return;

    const formattedStartDate = format(startDate, 'dd/MM/yyyy');
    const formattedEndDate = format(endDate, 'dd/MM/yyyy');

    const startDateData = getInputDateData(formattedStartDate);
    const [startDay, startMonth, startYear] = startDateData;

    const endDateData = getInputDateData(formattedEndDate);
    const [endDay, endMonth, endYear] = endDateData;

    const actionType = getActionType(addedButtonType);

    const {
      isValid: isValidEventDate,
      message: messageEventDateFeedBack,
    } = isValidPostStartToEndDate({
      startDateData,
      endDateData,
      hasHour,
    });

    const openingHourData = hasHour ? getInputTimeData(openingHour) : [0, 0];
    const [openingHours, openingMinutes] = openingHourData;

    const closingHourData = hasHour ? getInputTimeData(closingHour) : [23, 59];
    const [closingHours, closingMinutes] = closingHourData;

    if (R.isNil(googleAccessToken)) return;

    const {
      isValid: isValidEventTime,
      message: messageEventTimeFeedBack,
    } = isValidPostStartToEndTime({
      openingHourData,
      closingHourData,
      startDateData,
      endDateData,
      hasHour,
    });

    if (!isValidInput({
      actionType,
      googleAccessToken,
      isValidEventDate,
      isValidEventTime,
      messageEventDateFeedBack,
      messageEventTimeFeedBack,
    })) return;

    const inputData: any = {
      title: eventTitle,
      schedule: {
        startDate: {
          day: startDay,
          month:
            startMonth,
          year: startYear,
        },
        endDate: {
          day: endDay,
          month: endMonth,
          year: endYear,
        },
        startTime: {
          hours: closingHours === 0 && openingHours === 0 ? 0 : openingHours,
          minutes: closingHours === 0 && openingHours === 0 ? 0 : openingMinutes,
          seconds: 0,
          nanos: 0,
        },
        endTime: {
          hours: closingHours === 0 && openingHours === 0 ? 0 : closingHours,
          minutes: closingHours === 0 && openingHours === 0 ? 0 : closingMinutes,
          seconds: 0,
          nanos: 0,
        },
      },

      actionType,
      url: addedButtonTypeValue || null,
      summary: eventDetails,
      topicType: 'EVENT',
      languageCode: 'pt-BR',
      mediaItems: [],
    };

    setIsPublish(true);

    if (!R.isEmpty(filesToPost)) {
      inputData.mediaItems = await Promise.all(filesToPost.map(async (fileToPost) => {
        const putObjectData = await putObject({
          accessToken: userAccessToken,
          bucketName: 'hub-saas-media',
          file: fileToPost,
          googleAccountId,
          googleLocationId,
          mediaType: fileToPost.type === 'video/mp4' ? 'VIDEO' : 'PHOTO',
        });

        const mediaFileUrl = putObjectData.fileUrl;

        return {
          mediaFormat: fileToPost.type === 'video/mp4' ? 'VIDEO' : 'PHOTO',
          sourceUrl: mediaFileUrl,
        };
      }));
    }

    if (isVideoFiles(filesToPost) || filesToPost.length > 1) {
      handleCreateLocationPostTicket(inputData);
    } else {
      const createEventPostResponse = await createEventPost({
        mediaType: 'url',
        locationId: `accounts%2F${googleAccountId}%2Flocations%2F${googleLocationId}`,
        postType: 'event',
        inputData,
        accessToken: googleAccessToken,
        setIsPublish,
        feedbackMessage: enqueueSnackbar,
      });

      if (!R.isNil(createEventPostResponse)) enqueueSnackbar('Postagem publicada com sucesso', { variant: 'success' });
    }

    closeModal();
  }, [
    activeLocation,
    userAccessToken,
    userId,
    userProfile,
    startDate,
    endDate,
    openingHour,
    closingHour,
    hasHour,
    eventDetails,
    eventTitle,
    addedButtonType,
    addedButtonTypeValue,
  ]);

  const handleUpdateClicked = React.useCallback(async () => {
    if (R.isNil(startDate)) return;
    if (R.isNil(endDate)) return;
    if (R.isNil(googleAccessToken)) return;
    const { name: postId, searchUrl } = dataToUpdate;

    const hasOpenedTicketResponse = await hasOpenTicket();

    const formattedStartDate = format(startDate, 'dd/MM/yyyy');
    const formattedEndDate = format(endDate, 'dd/MM/yyyy');

    const startDateData = getInputDateData(formattedStartDate);
    const [startDay, startMonth, startYear] = startDateData;

    const endDateData = getInputDateData(formattedEndDate);
    const [endDay, endMonth, endYear] = endDateData;

    const {
      isValid: isValidEventDate,
      message: messageEventDateFeedBack,
    } = isValidPostStartToEndDate({
      startDateData,
      endDateData,
      hasHour,
    });

    const openingHourData = hasHour ? getInputTimeData(applyMaskToTime(openingHour)) : [0, 0];
    const [openingHours, openingMinutes] = openingHourData;

    const closingHourData = hasHour ? getInputTimeData(applyMaskToTime(closingHour)) : [23, 59];
    const [closingHours, closingMinutes] = closingHourData;

    const {
      isValid: isValidEventTime,
      message: messageEventTimeFeedBack,
    } = isValidPostStartToEndTime({
      openingHourData,
      closingHourData,
      startDateData,
      endDateData,
      hasHour,
    });

    const actionType = getActionType(addedButtonType);

    if (!isValidInput({
      actionType,
      googleAccessToken,
      isValidEventDate,
      isValidEventTime,
      messageEventDateFeedBack,
      messageEventTimeFeedBack,
    })) return;

    const inputData: any = {
      title: eventTitle,
      summary: eventDetails,
      mediaItems: [],
      actionType,
      topicType: 'EVENT',
      url: addedButtonTypeValue,
      schedule: {
        startDate: { day: startDay, month: startMonth, year: startYear },
        endDate: { day: endDay, month: endMonth, year: endYear },
        startTime: {
          hours: closingHours === 0 && openingHours === 0 ? 0 : openingHours,
          minutes: closingHours === 0 && openingHours === 0 ? 0 : openingMinutes,
          seconds: 0,
          nanos: 0,
        },
        endTime: {
          hours: closingHours === 0 && openingHours === 0 ? 23 : closingHours,
          minutes: closingHours === 0 && openingHours === 0 ? 59 : closingMinutes,
          seconds: 0,
          nanos: 0,
        },
      },
    };

    if (!R.isEmpty(filesToPost)) {
      inputData.mediaItems = await Promise.all(filesToPost.map(async (element: any) => {
        if (!R.isNil(element.mediaFormat)) return ({ mediaFormat: element.type === 'video/mp4' ? 'VIDEO' : 'PHOTO', sourceUrl: element.sourceUrl });

        const mediaItems = await getObjectInAWSS3Bucket(element);

        return {
          mediaFormat: element.type === 'video/mp4' ? 'VIDEO' : 'PHOTO',
          sourceUrl: mediaItems ? mediaItems.mediaSourceUrl : null,
        };
      }));
    }

    const platformsSyncInitialStatus = {
      google: 'IN_PROGRESS',
      facebook: 'IN_PROGRESS',
      foursquare: 'IN_PROGRESS',
      yelp: 'IN_PROGRESS',
      linkedin: 'IN_PROGRESS',
    };

    if (!R.isNil(hasOpenedTicketResponse)) {
      if (isVideoFiles(filesToPost) || filesToPost.length > 1) {
        const updateOfferPostTicket = await APIUpdateTicket({
          accessToken: userAccessToken,
          ticketId: hasOpenedTicketResponse.id,
          dataToUpdate: {
            data: {
              info_to_update: JSON.stringify(inputData),
              post_id: postNameId,
              searchUrl,
              status: platformsSyncInitialStatus,
            },
            status: 'OPEN',
          },
          setTokenLikeExpired: userSetTokenLikeExpired,
          setIsFetching: setIsPublish,
        });

        if (!R.isNil(updateOfferPostTicket)) enqueueSnackbar('Sua postagem está sendo processada e será lançada em breve', { variant: 'success' });
        closeModal();

        return;
      }

      const editEventPostResponse = await patchEventPost({
        accessToken: googleAccessToken,
        postType: 'event',
        postId: postId.replaceAll('/', '%2F'),
        inputData,
        updateMask: 'callToAction.actionType,callToAction.url,summary,event.schedule,photo,media',
        setIsFetching: setIsPublish,
        feedbackMessage: enqueueSnackbar,
      });

      if (R.isNil(editEventPostResponse)) return;

      const platformsSyncDoneStatus = {
        google: 'DONE',
        facebook: 'DONE',
        foursquare: 'DONE',
        yelp: 'DONE',
        linkedin: 'DONE',
      };

      const updateOfferPostTicket = await APIUpdateTicket({
        accessToken: userAccessToken,
        ticketId: hasOpenedTicketResponse.id,
        dataToUpdate: {
          data: {
            info_to_update: JSON.stringify(inputData),
            post_id: postNameId,
            searchUrl,
            status: platformsSyncDoneStatus,
          },
          status: 'DONE',
          operator_id: userId,
        },
        setTokenLikeExpired: userSetTokenLikeExpired,
        setIsFetching: setIsPublish,
      });

      if (!R.isNil(updateOfferPostTicket)) enqueueSnackbar('Sua postagem foi atualizada com sucesso', { variant: 'success' });
      closeModal();

      return;
    }

    if (isVideoFiles(filesToPost) || filesToPost.length > 1) {
      const createEventPostTicket = await createTicket({
        accessToken: userAccessToken,
        location_id: activeLocation.id,
        type: 'POST',
        changedData: {
          info_to_update: JSON.stringify(inputData),
          new: true,
          status: platformsSyncInitialStatus,
          post_id: postNameId,
          searchUrl,

        },
        setTokenLikeExpired: null,
        created_by: userId,
        isSync: setIsPublish,
      });

      if (!R.isNil(createEventPostTicket)) enqueueSnackbar('Sua postagem está sendo processada e será lançada em breve', { variant: 'success' });
    } else {
      const editEventPostResponse = await patchEventPost({
        accessToken: googleAccessToken,
        postType: 'event',
        postId: postId.replaceAll('/', '%2F'),
        inputData,
        updateMask: 'callToAction.actionType,callToAction.url,summary,event.schedule,photo,media',
        setIsFetching: setIsPublish,
        feedbackMessage: enqueueSnackbar,
      });

      if (!R.isNil(editEventPostResponse)) enqueueSnackbar('Postagem atualizada com sucesso', { variant: 'success' });
    }

    closeModal();
  }, [
    dataToUpdate,
    eventTitle,
    eventDetails,
    addedButtonType,
    addedButtonTypeValue,
    startDate,
    endDate,
    hasHour,
    openingHour,
    closingHour,
    filesToPost,
    postNameId,
    userAccessToken,
    activeLocation,
    hasOpenTicket,
  ]);

  const handleAddDetails = () => {
    setAddDetails(!addDetails);

    setEventDetails('');
  };

  const handleAddButton = (value: any) => {
    setAddedButtonType(value);

    if (value === 'Nenhum') setAddedButtonTypeValue('');
  };

  React.useEffect(() => {
    if (dataToUpdate) {
      const {
        topicType,
        media,
        summary,
        callToAction,
        name,
        event: {
          title,
          schedule,
        },
      } = dataToUpdate;

      const {
        formattedEndDate,
        formattedEndTime = '',
        formattedStartDate,
        formattedStartTime = '',
      } = formatDateObjectToString(schedule);

      if (topicType === 'EVENT') {
        setEventTitle(title);
        if (summary) setAddDetails(true);

        setPostNameId(name);
        setEventDetails(summary);
        setStartDate(parse(formattedStartDate, 'dd/MM/yyyy', new Date()));
        setEndDate(parse(formattedEndDate, 'dd/MM/yyyy', new Date()));

        if (formattedStartTime && formattedEndTime) {
          if (formattedStartTime === '0000' && formattedEndTime === '2359') setHasHour(false);
          else setHasHour(true);
        }

        setOpeningHour(formattedStartTime);
        setClosingHour(formattedEndTime);
        setAddedButtonType(getActionTypeReverse(callToAction?.actionType || 'Nenhum'));
        setAddedButtonTypeValue(callToAction?.url || '');

        if (media) {
          setFilesToPost(media.map((file: any) => ({
            mediaFormat: 'PHOTO',
            sourceUrl: file.googleUrl,
          })));
        }
      }
    }
  }, [dataToUpdate]);

  React.useEffect(() => {
    if (addedButtonType !== 'Nenhum' && addedButtonType !== 'Ligar agora') {
      setIsAddedButton(true);
      setIsAddedButtonPhoneType(false);
    }

    if (addedButtonType === 'Ligar agora') {
      setIsAddedButtonPhoneType(true);
      setIsAddedButton(false);
    }

    if (addedButtonType === 'Nenhum') {
      setIsAddedButton(false);
      setIsAddedButtonPhoneType(false);
    }
  }, [addedButtonType]);

  return (
    <StyledPostFormEvent>
      <DragAndDropFiles
        filesToPost={filesToPost}
        imageFileValidation={imagePostValidation}
        setFilesToPost={setFilesToPost}
        maxVideoSize={70}
      />

      <Input
        value={eventTitle}
        border
        label="Título do Evento *"
        onChange={(e) => setEventTitle(e.currentTarget.value)}
      />

      <StyledInputSwitchWrapper>
        <InputSwitch
          name="has-hour"
          label="Adicionar Hora?"
          checked={hasHour}
          changeChecked={() => setHasHour(!hasHour)}
          alignRow
          className="event-status-hour-toggle"
        />
      </StyledInputSwitchWrapper>

      <StyledHourFieldWrapper hasHour={hasHour}>
        <InputDatePicker
          selectedDate={startDate}
          setSelectedDate={e => setStartDate(e)}
          label="De: *"
          className="input-date-picker"
        />

        {hasHour && (
          <Time
            label="Horário de Inicio"
            timeValue={openingHour}
            setTimeValue={setOpeningHour}
            columnAlign
          />
        )}
      </StyledHourFieldWrapper>

      <StyledHourFieldWrapper hasHour={hasHour}>
        <InputDatePicker
          selectedDate={endDate}
          setSelectedDate={e => setEndDate(e)}
          label="Até: *"
          className="input-date-picker"
        />

        {hasHour && (
          <Time
            label="Horário de Término"
            timeValue={closingHour}
            setTimeValue={setClosingHour}
            columnAlign
          />
        )}
      </StyledHourFieldWrapper>

      <Select<TLocationPostAddButtonType>
        label="Adicionar um botão (opcional)"
        value={addedButtonType}
        name="select-button-type"
        border
        onChange={({ target }) => handleAddButton(target.value)}
        options={locationPostAddButtonsType}
        className="field-last-select"
      />

      {(isAddedButton && !isAddedButtonPhoneType) && (
        <Input
          border
          label="Link para o botão"
          value={addedButtonTypeValue}
          onChange={(e) => setAddedButtonTypeValue(e.currentTarget.value)}
          className="field-last-select"
        />
      )}

      <StyledButton
        onClick={() => handleAddDetails()}
      >
        Adicionar detalhes (opcional)
        {addDetails ? <ExpandLess /> : <ExpandMore />}
      </StyledButton>
      {addDetails && (

        <StyledAdditionalDetailsWrapper>
          <Textarea
            border
            label="Detalhes do Evento"
            name="event-details"
            className="event-details"
            value={eventDetails}
            onChange={setEventDetails}
          />
        </StyledAdditionalDetailsWrapper>
      )}

      {!handleFormHasManyImagesOrVideo(filesToPost) ? (
        <Button
          className="publish-button"
          disabled={Boolean(isPublish)}
          onClick={dataToUpdate ? handleUpdateClicked : handlePublishClicked}
        >
          {isPublish && <Loading className="is-button-loading" />}
          {dataToUpdate ? 'Atualizar' : 'Publicar'}
        </Button>
      ) : (
        <StyledAlertMessage>
          No momento não é possível atualizar uma postagem que contenha videos ou múltiplas imagens
        </StyledAlertMessage>
      )}
    </StyledPostFormEvent>
  );
};

export default PostFormEvent;
