import * as R from 'ramda';

export const isReview = (reviews) => {
  if (R.isEmpty(reviews[0])) return false;
  if (R.isNil(reviews)) return false;
  if (R.isEmpty(reviews)) return false;
  return true;
};

export const isReviewFiltered = ({
  currentListBy,
  currentStarRating,
  sensitiveWordsToFilter,
  locationsToFilter,
}) => {
  if (
    currentListBy !== 'all' || 
    currentStarRating.rateNumber !== 0 || 
    !R.isEmpty(sensitiveWordsToFilter) || 
    R.isEmpty(locationsToFilter)
  ) return true;

  return false;
};
