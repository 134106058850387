import axios from 'axios';

import type { TUpdateBatchInfos } from '../../../types/TBatchUpdate';

export const updateBatchInfos = async ({
  batchInfosId,
  userAccessToken,
  status,
  isUpdating,
}: TUpdateBatchInfos): Promise<{
  status: 'ERROR' | 'SUCCESS';
  data: any;
}> => {
  try {
    if (isUpdating) isUpdating(true);

    const { data } = await axios.put(
      `${process.env.REACT_APP_SAAS_BATCH_GOOGLE_MODULES}/batch-infos/${batchInfosId}`,
      {
        status,
      },
      {
        headers: {
          Authorization: `Bearer ${userAccessToken}`,
        },
      },
    );

    return {
      status: 'SUCCESS',
      data,
    };
  } catch (err: any) {
    return {
      status: 'ERROR',
      data: err.response.data,
    };
  } finally {
    if (isUpdating) isUpdating(false);
  }
};
