export default [
  {
    title: 'Google',
    subplatforms: [
      'Google Search',
      'Google Maps',
      'Google Business Profile',
      '99 Pop',
      'Airbnb',
      'Booking',
      'Eat24',
      'Google AR',
      'Google Earth',
      'Google Street View',
      'Ifood',
      'Trivago',
      'Uber',
      'Uber Eats',
      'Waze',
      'Cabify',
      'Indriver',
      'Rappi',
      'James',
    ],
  },
  {
    title: 'Facebook',
    subplatforms: [
      'Facebook Places',
      'Whatsapp Locations',
      'Instagram',
      'Facebook',
      'Moovit',
    ],
  },
  {
    title: 'Foursquare',
    subplatforms: [
      'Foursquare',
      'Whatsapp Locations',
      'Apple Maps',
      'Siri',
      'Alexa',
      'Amazon',
      'DuckDuckGo',
      'Twitter',
      'Bing Maps',
      'TomTom',
      'Moovit',
    ],
  },
  {
    title: 'Yelp',
    subplatforms: ['Yelp', 'TomTom', 'Amazon Locations', 'Yahoo', 'Alexa'],
  },
  {
    title: 'Linkedin',
    subplatforms: ['Linkedin'],
  },
];
