import styled from 'styled-components';

export const StyledGroupsListing = styled.div`
    display: flex;
    flex-direction: column;

    h3 {
        font-weight: 700;
        font-size: 1.5rem;
        margin-bottom: 2rem;
    }

    .data-table-groups {
        box-shadow: 
            0px 2px 1px -1px rgba(0,0,0,0.2), 
            0px 1px 1px 0px rgba(0,0,0,0.14), 
            0px 1px 3px 0px rgba(0,0,0,0.12);
        border-radius: 0.4rem !important;
    }
`;
