import React from 'react';

import { Loading, OverviewDataCount } from '../../../components';
import { TBiJobsSummary } from '../../../services/business-intelligence/bi-job/types';

import {
  StyledCurrentOverview,
  StyledHeader,
  StyledContent,
  StyledHeaderTitle,
} from './current-overview-styles';

export type TCurrentOverviewProps = {
  summary: TBiJobsSummary | null;
};

export const CurrentOverview = ({
  summary,
}: TCurrentOverviewProps) => (
  <StyledCurrentOverview>
    <StyledHeader>
      <StyledHeaderTitle>Resumo atual</StyledHeaderTitle>
    </StyledHeader>
    <StyledContent>
      <OverviewDataCount
        size={80}
        dataLabel="Total"
        totalValue={summary?.totalCount || 0}
        progressValue={summary?.totalCount || 0}
        themeColor="primary"
      />
      <OverviewDataCount
        dataLabel="Error"
        size={80}
        totalValue={summary?.totalCount || 0}
        progressValue={summary?.errorCount || 0}
        themeColor="danger"
      />
      <OverviewDataCount
        dataLabel="Concluídos"
        size={80}
        totalValue={summary?.totalCount || 0}
        progressValue={summary?.doneCount || 0}
        themeColor="success"
      />
    </StyledContent>
  </StyledCurrentOverview>
);
