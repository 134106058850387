import axios from 'axios';

export default async function getAllLocationInvites({
  accessToken,
  locationId,
  page = 0,
  pageSize = 10,
  query = '',
  isLoading,
  setTokenLikeExpired,
}) {
  try {
    if (isLoading) isLoading(true);

    const requestOptions = {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    };

    const { data } = await axios.get(
      `${process.env.REACT_APP_INVITES_API_URL}/location/${locationId}?page=${page}&pageSize=${pageSize}&query=${query}`,
      requestOptions,
    );

    return data;
  } catch (err) {
    if (err.response?.status === 401 && setTokenLikeExpired) return setTokenLikeExpired();

    return null;
  } finally {
    if (isLoading) isLoading(false);
  }
}
