import React from 'react';
import { debounce } from 'lodash';

import type {
  TSelectPlatform,
} from '../../../types/TPlatforms';

import type {
  TGalleryMediaType,
  TGalleryCategory,
} from '../../../types/TLocationGallery';

import {
  TLocationGalleryFiltersBaseProps,
} from '../../../types/TLocationGalleryFilters';

import {
  Select,
  RoundedButton,
  InputDatePicker,
  SelectMultiple,
} from '../../../components';

import {
  locationPostPlatformsMocked,
} from '../../../utils/select-platforms-mocked';

import {
  galleryMediasType,
  galleryCategories,
} from '../helpers';

import {
  StyledLocationGalleryFilters,
  StyledDatesWrapper,
  StyledContentFilter,
} from './location-gallery-filters-styles';

import AddMediaFormDialog from '../add-media-form-dialog';

const LocationGalleryFilters = ({
  selectedGalleryMediaType,
  setOfDate,
  setSelectedGalleryCategories,
  setSelectedGalleryMediaType,
  setToDate,
  className,
  toDate,
  ofDate,
  selectedGalleryCategories,
  getLocationMediaList,
}: TLocationGalleryFiltersBaseProps) => {
  const handleChangeSelectGalleryMediasType = (selectedOption: string) => {
    setSelectedGalleryMediaType(selectedOption);
  };

  const [openDialog, setOpenDialog] = React.useState(false);

  const handleChangeOfDate = debounce(value => setOfDate(value), 500);
  const handleChangeToDate = debounce(value => setToDate(value), 500);

  return (
    <StyledLocationGalleryFilters
      className={className}
    >
      <RoundedButton
        title="Adicionar mídia"
        icon="AddCircle"
        onClick={() => { setOpenDialog(true); }}
        className="add-gallery-midia"
      />

      {openDialog && (
        <AddMediaFormDialog
          open={openDialog}
          setOpen={setOpenDialog}
          getLocationMediaList={getLocationMediaList}
        />
      )}

      <StyledContentFilter>
        <Select<TSelectPlatform>
          disabled
          name="location-platforms"
          border
          label="Plataforma"
          options={locationPostPlatformsMocked}
          className="select-filter"
        />
        <Select<TGalleryMediaType>
          value={selectedGalleryMediaType}
          name="gallery-medias-type"
          border
          label="Tipo de Mídia"
          options={galleryMediasType}
          hasPlaceholder
          onChange={event => handleChangeSelectGalleryMediasType(event.target.value)}
          className="select-filter"
        />

        <SelectMultiple<TGalleryCategory>
          name="gallery-categories"
          border
          label="Categorias"
          options={galleryCategories}
          value={selectedGalleryCategories}
          onChange={(target) => setSelectedGalleryCategories(target)}
        />

        <StyledDatesWrapper>
          <InputDatePicker
            clearable
            selectedDate={ofDate}
            setSelectedDate={handleChangeOfDate}
            label="De:"
            className="input-date-picker"
          />

          <InputDatePicker
            minDate={ofDate}
            clearable
            selectedDate={toDate}
            setSelectedDate={handleChangeToDate}
            label="Até:"
            className="input-date-picker"
          />
        </StyledDatesWrapper>
      </StyledContentFilter>
    </StyledLocationGalleryFilters>
  );
};

export default LocationGalleryFilters;
