import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { isNil, isEmpty } from 'ramda';
import { format } from 'date-fns';
import { MUIDataTableOptions } from 'mui-datatables';

import { useAuth, useHasAccess } from '../../hooks';

import {
  RoundedButton, BatchHeader, BatchFilter, Table, NoAccessCard,
} from '../../components';

import { BatchGalleryRow } from './gallery-batch-row';

import {
  isValidDateInput,
  parseDateInput,
} from '../batch-update-posts-status/helpers';
import { initialTableColumns } from './helpers';
import { isResponseArrayAndSuccessful } from '../../utils/is-response-success';
import { batchGalleryCategories } from '../../utils/batch-gallery-categories';

import { findBatchGalleries } from '../../services/google-batch-modules/batch-gallery';
import APIGetCompanyLocations from '../../services/locations/getCompanyLocations';
import APIGetLocationGroupLocations from '../../services/locations/getAllGroupLocation';

import { TRootStateRedux } from '../../types/TRootStateRedux';
import { TArrayResponseWithCount } from '../../types/TArrayResponseWithCount';
import { TBatchGallery } from '../../types/TBatchGallery';
import {
  EBatchPlatformGroupType,
  EBatchPlatformStatus,
  TGetActiveGroupTypeResponse,
} from '../../types/TBatchGoogleModules';

import {
  StyledGalleryBatch,
  StyledTitle,
  StyledInfo,
  StyledTitleWrapper,
  StyledContent,
} from './gallery-batch-styles';
import { getLocationsWithoutLinked } from '../../services/locations/get-locations-without-linked';
import { EGroupType, TGetLocationsWithoutLinkedResponse } from '../../types/TLocation';
import AddMidiaDialog from './add-midia-dialog';

function GalleryBatch() {
  const { hasAccess } = useHasAccess();

  const { activeLocationGroupId, showLocationsByGroup } = useSelector(
    (state: TRootStateRedux) => state.LocationGroupReducer,
  );
  const { activeCompanyId, showLocationsByCompany } = useSelector(
    (state: TRootStateRedux) => state.CompanyReducer,
  );
  const { userAccessToken } = useAuth();
  const [page, setPage] = React.useState(0);
  const [pageSize, setPageSize] = React.useState(10);
  const [batchGalleriesData, setBatchGalleriesData] = React.useState<any[]>([]);
  const [batchGalleriesDataCount, setBatchGalleriesDataCount] = React.useState(0);
  const [selectedStatus, setSelectedStatus] = useState('');
  const [selectedCategory, setSelectedCategory] = useState('');
  const [selectedTypeFilter, setSelectedTypeFilter] = React.useState<string[]>(
    [],
  );
  const [ofPeriod, setOfPeriod] = React.useState<Date | null>(null);
  const [toPeriod, setToPeriod] = React.useState<Date | null>(null);
  const [openedDialog, setOpenedDialog] = React.useState(false);

  const [disabledPostButton, setDisabledPostButton] = React.useState(false);

  const activeGroupType = React.useMemo((): TGetActiveGroupTypeResponse => {
    if (showLocationsByCompany) {
      return {
        type: 'COMPANY' as EBatchPlatformGroupType,
        id: activeCompanyId,
      };
    }
    return {
      type: 'LOCATIONS_GROUP' as EBatchPlatformGroupType,
      id: activeLocationGroupId,
    };
  }, [
    showLocationsByGroup,
    showLocationsByCompany,
    activeLocationGroupId,
    activeCompanyId,
  ]);

  const updateTypeOptions = [
    { id: 0, name: 'Imagem' },
    { id: 1, name: 'Vídeo' },
  ];

  const [loading, setLoading] = React.useState(false);
  const [unlikedLocalsLoading, setUnlinkedLocalsLoading] = React.useState(false);
  const [locationsWithoutLinkedList, setLocationsWithoutLinkedList] = React.useState<TGetLocationsWithoutLinkedResponse>(null);

  const getBatchGalleriesData = React.useCallback(async () => {
    const requestBody: any = {
      groupType: activeGroupType.type,
      groupTypeId: activeGroupType.id,
      page,
      pageSize,
      setLoading,
      status: selectedStatus as EBatchPlatformStatus || undefined,
      userAccessToken,
      startDate: isValidDateInput(ofPeriod)
        ? parseDateInput({ input: ofPeriod, dayPart: 'start' })
        : undefined,
      endDate: isValidDateInput(toPeriod)
        ? parseDateInput({ input: toPeriod, dayPart: 'end' })
        : undefined,
      mediaType:
        !isNil(selectedTypeFilter) && selectedTypeFilter.length > 0
          ? JSON.stringify(selectedTypeFilter)
          : undefined,
      category:
        !isEmpty(selectedCategory)
          ? selectedCategory
          : undefined,
    };

    const { status, data } = await findBatchGalleries(requestBody);

    if (
      !isResponseArrayAndSuccessful<TArrayResponseWithCount<TBatchGallery>>(
        status,
        data,
      )
    ) {
      return;
    }

    const [batchGalleries, batchGalleriesCount] = data;

    const batchGalleriesNormalized = batchGalleries.map((batchGalleryItem) => [
      { columnRef: 'id', visible: false, value: batchGalleryItem.id },
      {
        columnRef: 'media-data',
        visible: false,
        value: batchGalleryItem.media_data,
      },
      {
        columnRef: 'category',
        visible: true,
        value:
          batchGalleryItem.media_data && batchGalleryItem.media_data.length > 0
            ? batchGalleryCategories[batchGalleryItem.media_data[0].category]
            : '',
      },
      {
        columnRef: 'updated-at',
        visible: true,
        value: format(
          new Date(batchGalleryItem.updated_at),
          'dd/MM/yyyy HH:mm:ss',
        ),
      },
      {
        columnRef: 'created-at',
        visible: true,
        value: format(
          new Date(batchGalleryItem.created_at),
          'dd/MM/yyyy HH:mm:ss',
        ),
      },
      {
        columnRef: 'status',
        visible: true,
        value: batchGalleryItem.status,
      },
    ]);

    setBatchGalleriesDataCount(batchGalleriesCount);
    setBatchGalleriesData(batchGalleriesNormalized);
  }, [
    activeGroupType,
    page,
    pageSize,
    selectedStatus,
    selectedTypeFilter,
    ofPeriod,
    toPeriod,
    selectedCategory,
  ]);

  const tableColumns = React.useMemo(
    () => [...initialTableColumns],
    [initialTableColumns],
  );

  const dataTableOptions = React.useMemo(
    (): MUIDataTableOptions => ({
      count: batchGalleriesDataCount,
      search: false,
      page,
      selectableRows: 'none',
    }),
    [batchGalleriesDataCount, page],
  );

  const handleCloseModal = (refreshData = false) => {
    setOpenedDialog(false);

    if (!refreshData) return;

    getBatchGalleriesData();
  };

  const handlePostButton = React.useCallback(async () => {
    if (showLocationsByCompany) {
      const getCompanyLocationsResponse = await APIGetCompanyLocations({
        accessToken: userAccessToken,
        companyId: activeCompanyId,
        pageSize: 1,
      });

      if (isNil(getCompanyLocationsResponse)) return;

      const [companyLocationsData] = getCompanyLocationsResponse;

      if (isEmpty(companyLocationsData)) setDisabledPostButton(true);
      if (!isEmpty(companyLocationsData))setDisabledPostButton(false);
    }

    if (showLocationsByGroup) {
      const getGroupLocationsResponse = await APIGetLocationGroupLocations({
        accessToken: userAccessToken,
        locationGroupId: activeLocationGroupId,
        pageSize: 1,
      });

	  const groupLocationsData = getGroupLocationsResponse ? getGroupLocationsResponse[0] : [];

      if (isEmpty(groupLocationsData)) setDisabledPostButton(true);
      if (!isEmpty(groupLocationsData)) setDisabledPostButton(false);
    }
  }, [activeCompanyId, activeLocationGroupId, showLocationsByGroup, showLocationsByCompany]);

  React.useEffect(() => {
    handlePostButton();
  }, [handlePostButton]);

  React.useEffect(() => {
    getBatchGalleriesData();
  }, [
    getBatchGalleriesData,
  ]);

  const getLocationWithoutLinked = React.useCallback(async () => {
    const group = showLocationsByCompany ? EGroupType.COMPANY : EGroupType.LOCATIONS_GROUP;
    const groupId = showLocationsByCompany ? activeCompanyId : activeLocationGroupId;

    const locationsWithoutLinked = await getLocationsWithoutLinked({
      group,
      groupId,
      userAccessToken,
      isFetching: setUnlinkedLocalsLoading,
    });

    if (isNil(locationsWithoutLinked)) return;

    setLocationsWithoutLinkedList(locationsWithoutLinked);
    setOpenedDialog(true);
  }, [
    activeCompanyId,
    activeLocationGroupId,
    showLocationsByGroup,
    showLocationsByCompany,
  ]);

  const handleChangeFilter = (filter: any, setField: React.Dispatch<React.SetStateAction<any>>) => {
    setPage(0);
    setField(filter);
  };

  return (
    <StyledGalleryBatch>

      <BatchHeader />

      {!hasAccess && <NoAccessCard />}

      {hasAccess && (
      <StyledContent>
        <StyledTitleWrapper>
          <RoundedButton
            onClick={() => getLocationWithoutLinked()}
            className="info-nav-button"
            title="Adicionar Mídia"
            disabled={disabledPostButton}
            loading={unlikedLocalsLoading}
            icon="AddCircle"
          />
        </StyledTitleWrapper>

        <StyledTitle>Filtros</StyledTitle>
        <StyledInfo>
          <BatchFilter
            typeLabel="Tipo de mídia"
            selectedTypeFilter={selectedTypeFilter}
            setSelectedTypeFilter={(filter) => handleChangeFilter(filter, setSelectedTypeFilter)}
            selectedStatusFilter={selectedStatus}
            setSelectedStatusFilter={(filter) => handleChangeFilter(filter, setSelectedStatus)}
            ofPeriod={ofPeriod}
            setOfPeriod={(filter) => handleChangeFilter(filter, setOfPeriod)}
            toPeriod={toPeriod}
            setToPeriod={(filter) => handleChangeFilter(filter, setToPeriod)}
            filterType={{
              type: 'MEDIA',
              updateTypeOptions,
            }}
            selectedCategory={selectedCategory}
            setSelectedCategory={(filter) => handleChangeFilter(filter, setSelectedCategory)}
          />
        </StyledInfo>

        <StyledInfo>
          <Table
            loading={loading}
            setPage={setPage}
            setPageSize={setPageSize}
            data={batchGalleriesData}
            title="Listagem das Galerias em Massa"
            columns={tableColumns}
            options={dataTableOptions}
            row={(rowData, rowDataIndex, rowIndex) => (
              <BatchGalleryRow
                rowData={rowData}
                rowDataIndex={rowDataIndex}
                rowIndex={rowIndex}
                dataTableOptions={dataTableOptions}
              />
            )}
          />
        </StyledInfo>
      </StyledContent>
      )}
      {openedDialog && (
      <AddMidiaDialog open={openedDialog} closeModal={handleCloseModal} locationsWithoutLinkedList={locationsWithoutLinkedList} />
      )}
    </StyledGalleryBatch>
  );
}

export default GalleryBatch;
