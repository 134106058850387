import axios from 'axios';

interface IRegisterLoginHistoryDto {
  userId: string | null;
  accessToken: string;
}

export async function registerLoginHistory({ userId, accessToken }: IRegisterLoginHistoryDto) {
  try {
    const GET_IP_ADDRESS_API_URL = 'https://geolocation-db.com/json/';

    try {
      const response = await axios.get(GET_IP_ADDRESS_API_URL);
      if (!response) return;

      const { IPv4 } = response.data;

      await axios.post(`${process.env.REACT_APP_LOGIN_HISTORY_API_URL}/register`, {
        ip_address: IPv4,
        user: userId
      },
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });
    } catch (error) {
      await axios.post(`${process.env.REACT_APP_LOGIN_HISTORY_API_URL}/register`, {
        ip_address: 'Desconhecido',
        user: userId
      },
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });
    }
  } catch (error) {
    return error;
  }
}