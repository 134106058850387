export const passwordScheme = {
  name: 'password',
  nickname: 'Senha',
  label: 'Senha',
  required: true,
  hasborder: true,
  type: 'password',
  password: true,
  disclaimer: 'Faça uma senha de 8 a 20 digitos que contenha letras maiúsculas/minúsculas e números',
};

export const confirmPasswordScheme = {
  name: 'confirm-password',
  nickname: 'Confirmar Senha',
  label: 'Confirmar Senha',
  required: true,
  hasborder: true,
  type: 'password',
  password: true,
};
