import React from 'react';
import * as R from 'ramda';

import { useUncontrolledField } from '../../hooks';

import {
  fieldCompanyInfoName,
  fieldCompanyInfoWebsite,
  fieldCompanyInfoIsActive,
  fieldCompanyInfoDataStudioLink,
} from './fields-scheme';

import {
  StyledCompanyInfo,
  StyledCompanyInfoFields,
  StyledTitle,
  StyledGroupField,
} from './company-info-styles';

const CompanyInfo = ({
  className,
  title,
  initialData,
  userProfileType,
}, ref) => {
  const [company, setCompany] = React.useState([]);

  const { Field: Name } = useUncontrolledField({
    fieldScheme: initialData ? {
      ...fieldCompanyInfoName,
      defaultValue: initialData.name || '',
    } : fieldCompanyInfoName,
    saveIn: setCompany,
  });

  const { Field: Website } = useUncontrolledField({
    fieldScheme: initialData ? {
      ...fieldCompanyInfoWebsite,
      defaultValue: initialData.website || '',
    } : fieldCompanyInfoWebsite,
    saveIn: setCompany,
  });

  const { Field: IsActive } = useUncontrolledField({
    fieldScheme: initialData ? {
      ...fieldCompanyInfoIsActive,
      defaultValue: initialData.is_active || false,
    } : fieldCompanyInfoIsActive,
    saveIn: setCompany,
  });

  const { Field: DataStudioLink } = useUncontrolledField({
    fieldScheme: initialData ? {
      ...fieldCompanyInfoDataStudioLink,
      defaultValue: initialData.data_studio || '',
    } : fieldCompanyInfoDataStudioLink,
    saveIn: setCompany,
  });

  React.useImperativeHandle(ref, () => ({
    company,
  }));

  const handleShowField = typeUser => {
    if (typeUser === 'Admin') return true;
    if (typeUser === 'Operacional') return true;

    return false;
  };

  return (
    <StyledCompanyInfo className={className}>
      {title && <StyledTitle>{title}</StyledTitle>}
      <StyledCompanyInfoFields>
        <StyledGroupField>
          <Name />
          <Website />
          {handleShowField(userProfileType) && <IsActive />}
        </StyledGroupField>
      </StyledCompanyInfoFields>

      {handleShowField(userProfileType) && (
        <>
          <StyledTitle>Link Data Studio</StyledTitle>
          <StyledCompanyInfoFields>
            <DataStudioLink />
          </StyledCompanyInfoFields>
        </>
      )}
    </StyledCompanyInfo>
  );
};

export default React.memo(React.forwardRef(CompanyInfo));
