import axios from 'axios';

export default async function deleteLocation({ accessToken, id, setTokenLikeExpired }) {
  const requestOptions = {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  };

  try {
    const { data } = await axios.delete(
      `${process.env.REACT_APP_LOCATIONS_API_URL}/${id}`,
      requestOptions,
    );

    return data;
  } catch (err) {
    if (err.response?.status === 401 && setTokenLikeExpired) return setTokenLikeExpired();

    console.log(err);

    return null;
  }
}
