import styled from 'styled-components';
import { Dialog } from '@material-ui/core';
import { lighten } from 'polished';

export const StyleListingDialog = styled(Dialog)`
    .MuiDialog-paper {
        width: 100%;
    }
`;

export const StyledIsFetchingContainer = styled.div`
    padding:3rem;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    .is-fetching-search {
        margin-right: 1rem;
    }
`;

export const StyledSearch = styled.div`
    display: flex;
    align-items: center;
    position: relative;
    padding: 1rem 2rem;
    border-bottom: 1px solid ${props => lighten(0.4, props.theme.colors.grey)};

    .search-icon {
        display: flex;
        align-items: center;
        position: absolute;
        right: 2.2rem;
        height: 3.6rem;
        padding: 0 2rem;
        background: ${props => props.theme.colors.primary};
        border-top-right-radius: 0.5rem;
        border-bottom-right-radius: 0.5rem;
        
        .MuiSvgIcon-root {
            width: 2.5rem;
            height: 2.5rem;
            fill: ${props => props.theme.colors.white};
        }
    }

    input {
        height: 4rem;
        width: 100%;
        padding: 0 7.5rem 0 2rem;
        border-radius: 0.5rem;
        border: 1px solid ${props => lighten(0.3, props.theme.colors.grey)};
    }
`;

export const StyledPagesListing = styled.ul`
    display: flex;
    flex-direction: column;
    padding: 0 2rem;
    max-height: 40rem;
    overflow-y: scroll;

    img {
        width: 2rem;
        height: 2rem;
        margin-right: 1.5rem;
        display: block;
    }

    .google-text{
        font-size: 1.4rem;
        margin-top: 1rem;
        margin-bottom: 0.5rem;
    }
`;
