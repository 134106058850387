import axios from 'axios';

export default async function getGroup(accessToken, groupId) {
  const requestOptions = {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  };

  console.log(`${process.env.REACT_APP_GROUPS_API_URL}/${groupId}`);

  const { data } = await axios.get(
    `${process.env.REACT_APP_GROUPS_API_URL}/${groupId}`,
    requestOptions,
  );

  return data;
}
