import React from 'react';
import * as R from 'ramda';
import { useHistory } from 'react-router-dom';

import {
  IconButton,
} from '../../../components';

import {
  StyledUpdateInfoDialog,
  StyledHeader,
  StyledFooter,
  StyledLocationsWithoutLinkWrapper,
  StyledHasLocationsWithoutLinked,
  StyledErrorLocationList,
  StyledErrorIcon,
  StyledRow,
  StyledConfirmButton,
} from './update-info-dialog-styles';
import { TBatchUpdateInfoDialogBaseProps } from '../../../types/TBatchUpdate';

const UpdateInfoDialog = ({
  open = false,
  setOpen,
  locationsWithoutLinkedList,
}: TBatchUpdateInfoDialogBaseProps) => {
  const history = useHistory();

  const handleOnCloseModal = () => {
    setOpen(false);
  };

  const onConfirmClicked = () => {
    history.push('/batch-update-info-form');
  };

  return (
    <StyledUpdateInfoDialog
      open={open}
      aria-labelledby="simple-dialog-title"
      onClose={handleOnCloseModal}
    >
      <StyledHeader>
        <p>Atenção!</p>
        <IconButton
          icon="Close"
          tooltip="Fechar"
          onClick={handleOnCloseModal}
        />
      </StyledHeader>
      <StyledLocationsWithoutLinkWrapper>
        <StyledHasLocationsWithoutLinked>
          <b>Existem Locais que estão com a vinculação pendente:</b>
          <p>Os locais abaixo não serão afetados pela atualização das Informações se não estiverem vinculados.</p>

          {!R.isNil(locationsWithoutLinkedList) && (
          <StyledErrorLocationList>
            {locationsWithoutLinkedList.locations.map(location => (
              <StyledRow>
                <StyledErrorIcon />
                {' '}
                {location}
              </StyledRow>
            ))}
          </StyledErrorLocationList>
          )}
        </StyledHasLocationsWithoutLinked>
      </StyledLocationsWithoutLinkWrapper>
      <StyledFooter>
        <StyledConfirmButton
          onClick={onConfirmClicked}
        >
          Prosseguir mesmo assim
        </StyledConfirmButton>
      </StyledFooter>
    </StyledUpdateInfoDialog>
  );
};

export default UpdateInfoDialog;
