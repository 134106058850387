import styled from 'styled-components';
import { Paper } from '@material-ui/core';

import { devices } from '../../assets/styles/devices';

export const StyledBatchCreation = styled.div`
    display: flex;
    flex-direction: column;
`;

export const StyledTitle = styled.h3`
  font-weight: 700;
  font-size: 1.5rem;
  margin-bottom: 2rem;
`;

export const StyledContent = styled(Paper)`
    padding: 3rem 2rem;
    display: grid;
    grid-template-columns: 1fr;
    row-gap: 3rem;

    @media ${devices.widescreen} {
        grid-template-columns: repeat(2, 1fr);
        column-gap: 2rem;
        row-gap: 0;
    }
`;

export const StyledDisclaimer = styled.div`
    display: flex;
    flex-direction: column;

    h4 {
        margin-bottom: 1rem;
        font-size: 1.6rem;
    }

    ul {
        padding-left: 2rem;
        font-size: 1.3rem;
        list-style: disc;
        margin-bottom: 0;
    }
`;

export const StyledBatchOptions = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;

    @media ${devices.desktop} {
        flex-direction: row;
    }
`;

export const StyledBatchOption = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    padding: 2rem;
    border: 2px solid ${props => (props.primary ? props.theme.colors.primary : props.theme.colors.secondary)};
    border-radius: 0.5rem;
    margin-bottom: 2rem;
    width: 100%;


    @media ${devices.desktop} {
        max-width: 30rem;
        margin-bottom: 0;
        margin-right: 2rem;
    }

    @media ${devices.widescreen} {
        max-width: 40rem;
    }

    &:last-child {
        margin-bottom: 0;
        margin-left: 0;
    }

    small {
        text-align: center;
        margin-bottom: 1rem;
    }

    .button {
        width: 100%;
    }
`;
