import React, { useState, useEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import {
  IconButton, Paper, CircularProgress, Button,
} from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';

import { Typeahead } from 'react-bootstrap-typeahead';

import {
  CompanyNavBar,
  CompanyInfoNavBar,
} from '../../components';

import getCategories from '../../services/categories/getCategories';
import getTags from '../../services/tags/getTags';
import addInfoModel from '../../services/companies/addInfoModels';
import createInfoModel from '../../services/infoModels/createInfoModel';
import updateInfoModel from '../../services/infoModels/updateInfoModel';

const buttonStyle = {
  background: '#F2F2F2',
  fontFamily: 'Poppins',
  fontWeight: 'bold',
  marginLeft: '1em',
};

const CreateInfoModel = () => {
  const history = useHistory();
  const params = useParams();
  const [loading, setLoading] = useState(false);
  const [categories, setCategories] = useState([]);
  const [tags, setTags] = useState([]);
  const [form, setForm] = useState({
    name: '',
    shortDescription: '',
    longDescription: '',
  });
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [selectedTags, setSelectedTags] = useState([]);

  const { infoModelId } = params;

  const { user } = useSelector((state) => state.AuthReducer);
  const { activeCompany } = useSelector((state) => state.CompanyReducer);

  useEffect(() => {
    if (infoModelId) {
      let currentModel;
      const companyInfoModels = activeCompany.info_models;
      for (let i = 0; i < companyInfoModels.length; i++) {
        if (infoModelId === companyInfoModels[i].id.toString()) {
          currentModel = companyInfoModels[i];
        }
      }
      setForm({
        name: currentModel.name,
        shortDescription: currentModel.short_description,
        longDescription: currentModel.long_description,
      });

      setSelectedCategories(currentModel.categories);
      setSelectedTags(currentModel.tags);
    }

    const fetchData = async () => {
      setCategories(await getCategories(user.accessToken));
      setTags(await getTags(user.accessToken));
    };

    fetchData();
  }, []);

  const renderButton = () => {
    if (loading) {
      return <CircularProgress />;
    }
    return (
      <>
        <Button
          style={buttonStyle}
          variant="contained"
          onClick={onCancelClicked}
        >
          Cancelar
        </Button>
        <Button
          style={buttonStyle}
          disabled={
            form.name === ''
            || form.shortDescription === ''
            || form.longDescription === ''
            || selectedCategories.length === 0
          }
          variant="contained"
          onClick={infoModelId ? onModelUpdated : onModelCreated}
        >
          {infoModelId ? 'Modificar Modelo' : 'Criar Modelo'}
        </Button>
      </>
    );
  };

  const handleChangeForm = ({ target: { name, value } }) => {
    setForm({ ...form, [name]: value });
  };

  const onBackArrowClicked = () => {
    history.push('/company/info-models');
  };

  const onCancelClicked = () => {
    history.push('/company/info-models');
  };

  const onModelUpdated = async () => {
    setLoading(true);
    await updateInfoModel(
      user.accessToken,
      infoModelId,
      form.name,
      form.shortDescription,
      form.longDescription,
      selectedCategories,
      selectedTags,
    );

    setLoading(false);
    history.push('/company/info-models');
  };

  const onModelCreated = async () => {
    setLoading(true);

    const infoModel = await createInfoModel(
      user.accessToken,
      form.name,
      form.shortDescription,
      form.longDescription,
      selectedCategories,
      selectedTags,
      user.user.id,
    );

    await addInfoModel(user.accessToken, activeCompany.id, [
      infoModel.id,
    ]);

    setLoading(false);

    history.push('/company/info-models');
  };

  return (
    <div>
      <CompanyNavBar returnUrl="/company/create-info-model" />
      <CompanyInfoNavBar activeButton={0} />
      <div style={{ padding: '1em' }}>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
          }}
        >
          <IconButton onClick={onBackArrowClicked}>
            <ArrowBackIcon />
          </IconButton>
          <text className="font-poppins page-title">Modelo de informações</text>
        </div>
        <Paper elevation={0} className="font-poppins" variant="outlined">
          <div style={{ margin: '1em' }}>
            <label>Nome do Modelo</label>
            <input
              className="form-control"
              name="name"
              value={form.name}
              onChange={handleChangeForm}
            />
            <label>Categorias</label>
            <Typeahead
              id="basic-typeahead-multiple"
              multiple
              labelKey="name"
              options={categories}
              selected={selectedCategories}
              onChange={setSelectedCategories}
            />
            <label>Etiquetas</label>
            <Typeahead
              id="basic-typeahead-multiple"
              multiple
              labelKey="name"
              options={tags}
              selected={selectedTags}
              onChange={setSelectedTags}
            />
            <label>Descrição</label>
            <textarea
              className="form-control"
              name="longDescription"
              value={form.longDescription}
              onChange={handleChangeForm}
            />

            <div style={{ marginTop: '2em' }}>
              <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                {renderButton()}
              </div>
              {form.name === ''
              || form.shortDescription === ''
              || form.longDescription === ''
              || selectedCategories.length === 0 ? (
                <div
                  style={{
                    marginTop: '0.6em',
                    display: 'flex',
                    justifyContent: 'flex-end',
                  }}
                >
                  <text style={{ fontSize: '0.8em' }}>
                    * Preencha os campos necessários
                  </text>
                </div>
                ) : (
                  <></>
                )}
            </div>
          </div>
        </Paper>
      </div>
    </div>
  );
};

export default CreateInfoModel;
