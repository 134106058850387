import styled from 'styled-components';

export const StyledFilters = styled.div`
    padding: 2rem;
    margin-top: 0.1rem;
    background: ${props => props.theme.colors.white};
    width: 100%;
    margin-bottom: 1rem;

    h3 {
        font-size: 1.6rem;
        font-weight: 500;
        color: ${props => props.theme.colors.black};
        margin-bottom: 1rem; 
    }
`;

export const StyledFiltersListing = styled.ul`
    display: grid;
    grid-template-columns: 1fr;
    row-gap: 1rem;
`;

export const StyledFilterItem = styled.li`
    display: flex;
    justify-content: space-between;
    align-items: center;
`;
