import styled from 'styled-components';

export const StyledListItemWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 2rem;
  border-bottom: 1px solid #757575;

  p.status {
    padding: 0 0 0 1rem;
    color: #52AE3B;
    margin: 0 auto;
  }
`;

export const StyledListItem = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  border-right: 1px solid #757575;
  width: 100%;

  p.title {
    font-weight: bold;
  }
`;
