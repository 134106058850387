import axios from 'axios';

export default async function getCategoryByPlatformId(accessToken, platformId) {
  const requestOptions = {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  };

  const { data } = await axios.get(
    `${process.env.REACT_APP_CATEGORIES_API_URL}/by-platform-id/${platformId}`,
    requestOptions,
  );

  return data;
}
