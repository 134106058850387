import React from 'react';

import { StyledCard } from './no-access-card-styles';

const NoAccessCard = () => (
  <StyledCard>
    <p>Você não tem permissão para acessar este conteúdo</p>
  </StyledCard>
);

export default NoAccessCard;
