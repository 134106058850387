import axios from 'axios';

import { TGetUserCompaniesWithGuests } from '../../types/TCompany';

export default async function getUserCompaniesWithGuests({
  accessToken,
  userId,
  page = 0,
  pageSize = 10,
  query = '',
  setTokenLikeExpired,
  setIsFetching,
  orderByName,
}: TGetUserCompaniesWithGuests) {
  try {
    if (setIsFetching) setIsFetching(true);

    const { data } = await axios.get(
      `${process.env.REACT_APP_COMPANIES_API_URL}/user-companies-with-guests/${userId}?page=${page}&pageSize=${pageSize}&query=${query}&orderByName=${orderByName}`,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      },
    );

    return data;
  } catch (err: any) {
    if (err.response?.status === 401 && setTokenLikeExpired) setTokenLikeExpired();

    return null;
  } finally {
    if (setIsFetching) setIsFetching(false);
  }
}
