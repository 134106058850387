import styled from 'styled-components';
import { devices } from '../../assets/styles/devices';

export const StyledNoAcess = styled.div`
    height: 100vh;
    width: 100%;
    padding: 2rem;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    .no-acess-illustration {
        margin-bottom: 3rem;
        max-width: 40rem;

        @media ${devices.widescreen} {
            max-width: 60rem;
        }
    }

    h2 {
        font-weight: bold;
        margin-bottom: 0;
        color: ${props => props.theme.colors.black};
        max-width: 50rem;
        text-align: center;

        @media ${devices.tablet} {
            font-size: 3rem;
            margin-bottom: 2rem;
        }
    }

    p {
        max-width: 60rem;
        width: 100%;
        text-align: center;
        font-size: 2rem;
        line-height: 2.5rem;
        margin-bottom: 2rem;
    }
`;
