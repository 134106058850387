import axios from 'axios';

export default async function deletePost({
  accessToken,
  postId,
  setIsDeleting = null,
}) {
  const requestOptions = {
    headers: {
      'access-token': accessToken,
    },
  };

  if (setIsDeleting) setIsDeleting(true);

  try {
    const data = await axios.delete(
      `${process.env.REACT_APP_GOOGLE_API_URL}/posts/${postId}`,
      requestOptions,
    );

    return data;
  } catch (err) {
    console.log('err', err.response);
    return err.response;
  } finally {
    if (setIsDeleting) setIsDeleting(false);
  }
}
