import * as R from 'ramda';

export const dropRepeatsReviews = ({ newColletion, currentColletion }) => R.dropRepeatsWith(
  R.eqBy(R.prop('reviewIdInFirstLevel')),
  R.sortBy(R.prop('reviewIdInFirstLevel'), [...newColletion, ...currentColletion].map(reviewData => ({
    ...reviewData,
    reviewIdInFirstLevel: reviewData.review.reviewId,
    review: reviewData.review,
  }))),
);

export const capitalizeName = (name) => {
  return name
    .replace(/(^\w{1})|(\s+\w{1})/g, letter => letter.toUpperCase());
}