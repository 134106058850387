import * as R from 'ramda';

export const searchInputIsDisable = ({ dataList, dataListQuery }) => () => {
  if (!R.isEmpty(dataListQuery) || !R.isNil(dataListQuery)) return false;
  if (R.isEmpty(dataList)) return true;
};

export const showPagination = ({
  pagesQuantity = 0,
  dataList = [],
  isCreation = false,
}) => () => {
  if (R.isNil(pagesQuantity) || pagesQuantity === 0) return false;
  if (isCreation) return false;
  if (R.isEmpty(dataList)) return false;

  return true;
};
