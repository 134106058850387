import React from 'react';
import * as R from 'ramda';

import { StyledContainer, StyledList } from './service-area-info-styles';
import type { TGoogleServiceAreaBusiness } from '../../../../../types/TGoogle';

type TServiceAreaInfoProps = {
  data: TGoogleServiceAreaBusiness;
};

function ServiceAreaInfos({ data }: TServiceAreaInfoProps) {
  return (
    <StyledContainer>
      <StyledList>
        {data.places.placeInfos.map((placeInfo) => (
          <li key={`${placeInfo.placeId}-${placeInfo.placeName}`}>
            {placeInfo.placeName}
          </li>
        ))}
      </StyledList>
    </StyledContainer>
  );
}

export default ServiceAreaInfos;
