import React, { useState } from 'react';
import { isNil, isEmpty } from 'ramda';
import { useSelector } from 'react-redux';
import { debounce } from 'lodash';
import { MUIDataTableOptions } from 'mui-datatables';
import { useAuth, useHasAccess } from '../../hooks';
import {
  BatchFilter, BatchHeader, Table, NoAccessCard, RoundedButton,
} from '../../components';
import { ServiceBatchRow } from './service-batch-row';
import type {
  EBatchPlatformGroupType,
  EBatchPlatformStatus,
  TGetActiveGroupTypeResponse,
} from '../../types/TBatchGoogleModules';
import type { TRootStateRedux } from '../../types/TRootStateRedux';
import type { TTableRowData } from '../../types/TTable';
import { findServiceBatches } from '../../services/google-batch-modules/batch-services';
import APIGetCompanyLocations from '../../services/locations/getCompanyLocations';
import APIGetLocationGroupLocations from '../../services/locations/getAllGroupLocation';

import { normalizeServiceBatches, tableColumns } from './helpers';
import {
  isValidDateInput,
  parseDateInput,
} from '../batch-update-posts-status/helpers';
import {
  StyledButtonWrapper,
  StyledContent,
  StyledInfo,
  StyledServiceBatch,
  StyledTitle,
  StyledContainer,

} from './service-batch-styles';
import { EGroupType, TGetLocationsWithoutLinkedResponse } from '../../types/TLocation';
import { getLocationsWithoutLinked } from '../../services/locations/get-locations-without-linked';
import CreateServiceDialog from './create-service-dialog';

const ServiceBatch = () => {
  const { hasAccess } = useHasAccess();

  const { activeLocationGroupId, showLocationsByGroup } = useSelector(
    (state: TRootStateRedux) => state.LocationGroupReducer,
  );
  const { activeCompanyId, showLocationsByCompany } = useSelector(
    (state: TRootStateRedux) => state.CompanyReducer,
  );
  const { userAccessToken } = useAuth();

  const [serviceBatchesData, setServiceBatchesData] = React.useState<
    TTableRowData[]
  >([]);
  const [serviceBatchesCount, setServiceBatchesCount] = React.useState(0);
  const [selectedStatusFilter, setSelectedStatusFilter] = useState<string>('');
  const [query, setQuery] = useState('');
  const [ofPeriod, setOfPeriod] = useState<Date | null>(null);
  const [toPeriod, setToPeriod] = useState<Date | null>(null);
  const [page, setPage] = React.useState(0);
  const [pageSize, setPageSize] = React.useState(10);
  const [isLoading, setIsLoading] = useState(false);

  const [openedDialog, setOpenedDialog] = React.useState(false);

  const [disabledPostButton, setDisabledPostButton] = React.useState(false);

  const activeGroupType = React.useMemo((): TGetActiveGroupTypeResponse => {
    if (showLocationsByCompany) {
      return {
        type: 'COMPANY' as EBatchPlatformGroupType,
        id: activeCompanyId,
      };
    }
    return {
      type: 'LOCATIONS_GROUP' as EBatchPlatformGroupType,
      id: activeLocationGroupId,
    };
  }, [
    showLocationsByGroup,
    showLocationsByCompany,
    activeLocationGroupId,
    activeCompanyId,
  ]);

  const tableOptions = React.useMemo(
    (): MUIDataTableOptions => ({
      count: serviceBatchesCount,
      search: false,
      page,
      selectableRows: 'none',
    }),
    [serviceBatchesCount, page],
  );

  const handleChangeFilter = (filter: any, setField: React.Dispatch<React.SetStateAction<any>>) => {
    setPage(0);
    setField(filter);
  };

  const handleSearchWithDebounce = React.useCallback(
    debounce((text: string) => handleChangeFilter(text, setQuery), 500),
    [],
  );

  const getServiceBatchesData = React.useCallback(async () => {
    const serviceBatchesResponse = await findServiceBatches({
      groupType: activeGroupType.type,
      groupTypeId: activeGroupType.id,
      page,
      pageSize,
      isFetching: setIsLoading,
      status: (selectedStatusFilter as EBatchPlatformStatus) || undefined,
      userAccessToken,
      startDate: isValidDateInput(ofPeriod)
        ? parseDateInput({ input: ofPeriod, dayPart: 'start' })
        : undefined,
      endDate: isValidDateInput(toPeriod)
        ? parseDateInput({ input: toPeriod, dayPart: 'end' })
        : undefined,
      query,
    });

    if (serviceBatchesResponse.status !== 'SUCCESS') {
      return;
    }

    const {
      data: [serviceBatches, count],
    } = serviceBatchesResponse;

    const serviceBatchesNormalized = normalizeServiceBatches(serviceBatches);

    setServiceBatchesCount(count);
    setServiceBatchesData(serviceBatchesNormalized);
  }, [
    activeGroupType,
    page,
    pageSize,
    selectedStatusFilter,
    ofPeriod,
    toPeriod,
    query,
  ]);

  React.useEffect(() => {
    getServiceBatchesData();
  }, [getServiceBatchesData]);

  const [locationsWithoutLinkedList, setLocationsWithoutLinkedList] = React.useState<TGetLocationsWithoutLinkedResponse>(null);
  const [unlikedLocalsLoading, setUnlinkedLocalsLoading] = React.useState(false);

  const getLocationWithoutLinked = React.useCallback(async () => {
    const group = showLocationsByCompany ? EGroupType.COMPANY : EGroupType.LOCATIONS_GROUP;
    const groupId = showLocationsByCompany ? activeCompanyId : activeLocationGroupId;

    const locationsWithoutLinked = await getLocationsWithoutLinked({
      group,
      groupId,
      userAccessToken,
      isFetching: setUnlinkedLocalsLoading,
    });

    if (isNil(locationsWithoutLinked)) return;

    setLocationsWithoutLinkedList(locationsWithoutLinked);
    setOpenedDialog(true);
  }, [
    activeCompanyId,
    activeLocationGroupId,
    showLocationsByGroup,
    showLocationsByCompany,
  ]);

  const handleCloseModal = (refreshData = false) => {
    setOpenedDialog(false);

    if (!refreshData) return;

    getServiceBatchesData();
  };

  const handlePostButton = React.useCallback(async () => {
    if (showLocationsByCompany) {
      const getCompanyLocationsResponse = await APIGetCompanyLocations({
        accessToken: userAccessToken,
        companyId: activeCompanyId,
        pageSize: 1,
      });

      if (isNil(getCompanyLocationsResponse)) return;

      const [companyLocationsData] = getCompanyLocationsResponse;

      if (isEmpty(companyLocationsData)) setDisabledPostButton(true);
      if (!isEmpty(companyLocationsData)) setDisabledPostButton(false);
    }

    if (showLocationsByGroup) {
      const getGroupLocationsResponse = await APIGetLocationGroupLocations({
        accessToken: userAccessToken,
        locationGroupId: activeLocationGroupId,
        pageSize: 1,
      });

      const groupLocationsData = getGroupLocationsResponse ? getGroupLocationsResponse[0] : [];

      if (isEmpty(groupLocationsData)) setDisabledPostButton(true);
      if (!isEmpty(groupLocationsData)) setDisabledPostButton(false);
    }
  }, [activeCompanyId, activeLocationGroupId, showLocationsByGroup, showLocationsByCompany]);

  React.useEffect(() => {
    handlePostButton();
  }, [handlePostButton]);

  return (
    <StyledServiceBatch>
      <BatchHeader />

      {!hasAccess && <NoAccessCard />}

      {hasAccess && (
        <StyledContainer>

          <StyledButtonWrapper>
            <RoundedButton
              onClick={() => getLocationWithoutLinked()}
              className="info-nav-button"
              title="Adicionar Serviço"
              disabled={disabledPostButton}
              loading={unlikedLocalsLoading}
              icon="AddCircle"
            />
          </StyledButtonWrapper>

          <StyledTitle>Filtros</StyledTitle>
          <StyledInfo>
            <BatchFilter
              selectedStatusFilter={selectedStatusFilter}
              setSelectedStatusFilter={(filter) => handleChangeFilter(filter, setSelectedStatusFilter)}
              onChangeSearchQuery={handleSearchWithDebounce}
              searchQueryLabel="Nome do serviço"
              ofPeriod={ofPeriod}
              setOfPeriod={(filter) => handleChangeFilter(filter, setOfPeriod)}
              toPeriod={toPeriod}
              setToPeriod={(filter) => handleChangeFilter(filter, setToPeriod)}
            />
          </StyledInfo>

          <StyledContent>
            <Table
              loading={isLoading}
              title="Serviços em Massa"
              setPage={setPage}
              setPageSize={setPageSize}
              columns={tableColumns}
              data={serviceBatchesData}
              row={(rowData, rowDataIndex, rowIndex) => (
                <ServiceBatchRow
                  batchId={rowData[0].value}
                  rowData={rowData}
                  dataTableOptions={tableOptions}
                />
              )}
              options={tableOptions}
            />
          </StyledContent>
        </StyledContainer>
      )}
      {openedDialog && (
        <CreateServiceDialog
          open={openedDialog}
          closeModal={handleCloseModal}
          locationsWithoutLinkedList={locationsWithoutLinkedList}
        />
      )}
    </StyledServiceBatch>
  );
};

export default ServiceBatch;
