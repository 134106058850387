import styled, { css } from 'styled-components';
import { shade } from 'polished';

export const StyledContent = styled.div<{ contentNoPadding: boolean }>`
    display: flex;
    flex-direction: column;
    padding: 2rem;
    width: 40rem;
    border-top: 1px solid ${props => shade(0.1, props.theme.colors.white)};
  
    ${props => props.contentNoPadding && css`
      padding: 0;
    `};

    em {
        color: ${props => props.theme.colors.red};
        font-style: normal;
    }
`;

export const StyledBottomActions = styled.div`
    display: flex;
    justify-content: flex-end;
    padding: 2rem;
    background: ${props => shade(0.1, props.theme.colors.white)};

    .button {
        margin-right: 1rem;

        &:last-child {
            margin-right: 0;
        }
    }

    .is-loading-confirm-dialog {
      margin-right: 1rem;
    }
`;
