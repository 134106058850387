import React from 'react';
import { Helmet } from 'react-helmet';

import { HeaderToolbar, IconButton, Table } from '../../components';
import { useCustomIconSVG } from '../../hooks';

import { CurrentOverview } from './current-overview';
import { Filter } from './filter';
import { Row } from './row';

import { initialDateColumns } from './helpers';

import {
  StyledBIMonitor,
  StyledContent,
  StyledAside,
} from './bi-monitor-styles';
import { TBIJob, TBiJobsSummary } from '../../services/business-intelligence/bi-job/types';
import { EBIStatus } from '../../services/business-intelligence/bi-job/enums';
import { getAllBIJob as APIGetAllBIJobs } from '../../services/business-intelligence/bi-job';
import { getBIJobsSummary as APIGetBIJobsSummary } from '../../services/business-intelligence/bi-job';
import type { EPlatforms, EBIModule } from '../../services/business-intelligence/enums';
import { useAuth } from '../../hooks';

const BIMonitor = () => {
  const tableColumns = React.useMemo(
    () => [...initialDateColumns],
    [initialDateColumns],
  );

  const tableMockedData = [
    [
      { columnRef: 'id', visible: false, value: 12 },
      {
        columnRef: 'platform',
        visible: true,
        value: useCustomIconSVG('Google'),
      },
      {
        columnRef: 'company',
        visible: true,
        value: 'Empresa A',
      },
      {
        columnRef: 'local',
        visible: true,
        value: 'Local X',
      },
      {
        columnRef: 'address',
        visible: true,
        value: 'Rua 123, casa 1',
      },
      {
        columnRef: 'date',
        visible: true,
        value: '00/00/0000',
      },
      {
        columnRef: 'status',
        visible: true,
        value: (() => (
          <IconButton
            tooltipVariant="secondary"
            icon="CheckCircle"
            label="Concluído"
            isSuccess
          />
        ))(),
      },
    ],
  ];
  const [biJobs, setBiJobs] = React.useState<TBIJob[]>([]);
  const [page, setPage] = React.useState(0);
  const [selectedPlatforms, setSelectedPlatforms] = React.useState<string[]>(
    []
  );
  const [selectedStatus, setSelectedStatus] = React.useState<string[]>([]);
  const [selectedErrorByModule, setSelectedErrorByModule] = React.useState<
    string[]
  >([]);
  const [companyField, setCompanyField] = React.useState('');
  const [placeField, setPlaceField] = React.useState('');
  const [addressField, setAddressField] = React.useState('');
  const [selectedDateOf, setSelectedDateOf] = React.useState<Date | null>(null);
  const [selectedDateTo, setSelectedDateTo] = React.useState<Date | null>(null);

  const [biJobsSummary, setBiJobsSummary] = React.useState<TBiJobsSummary | null>(null);

  const { userAccessToken } = useAuth();

  const getBiJobsSummary = React.useCallback(async () => {
    const getSummaryReturn = await APIGetBIJobsSummary({
      userToken: userAccessToken,
    });

    if (getSummaryReturn.type !== 'SUCCESS') return;

    setBiJobsSummary(getSummaryReturn.response);
  }, [userAccessToken]);

  const getBiJobs = React.useCallback(async () => {
    const getBiJobsReturn = await APIGetAllBIJobs({
      userToken: userAccessToken,
      companyName: companyField,
      locationName: placeField,
      locationAddress: addressField,
      startDate: selectedDateOf || undefined,
      endDate: selectedDateTo || undefined,
      page,
      statusList: selectedStatus as EBIStatus[],
      platformList: selectedPlatforms as EPlatforms[],
      errorByModuleList: selectedErrorByModule as EBIModule[],
    });

    if (getBiJobsReturn.type !== 'SUCCESS') return;

    setBiJobs(getBiJobsReturn.response[0] as TBIJob[]);

    // TODO - Remover depois da integração com a tabela
    console.log('biJobs: ', getBiJobsReturn.response[0]);
  }, [
    userAccessToken,
    page,
    companyField,
    placeField,
    addressField,
    selectedDateOf,
    selectedDateTo,
    selectedStatus,
    selectedPlatforms,
    selectedErrorByModule,
  ]);

  React.useEffect(() => {
    getBiJobs();
  }, [getBiJobs]);

  React.useEffect(() => {
    getBiJobsSummary();
  }, [getBiJobsSummary]);

  return (
    <StyledBIMonitor>
      <Helmet>
        <meta property="og:title" content="BI Monitor - SaaS Hublocal" />
        <meta
          property="og:description"
          content="Monitor de atividades do BI - SaaS HubLocal"
        />
        <title>Relatório de BI- SaaS Hublocal</title>
      </Helmet>
      <HeaderToolbar title="Monitoramente de Business Intelligense" />
      <StyledContent>
        <StyledAside>
          <Filter
            selectedPlatforms={selectedPlatforms}
            setSelectedPlatforms={setSelectedPlatforms}
            selectedStatus={selectedStatus}
            setSelectedStatus={setSelectedStatus}
            selectedErrorByModule={selectedErrorByModule}
            setSelectedErrorByModule={setSelectedErrorByModule}
            setCompanyField={setCompanyField}
            setPlaceField={setPlaceField}
            setAddressField={setAddressField}
            selectedDateOf={selectedDateOf}
            setSelectedDateOf={setSelectedDateOf}
            selectedDateTo={selectedDateTo}
            setSelectedDateTo={setSelectedDateTo}
          />
          <CurrentOverview
            summary={biJobsSummary}
          />
        </StyledAside>
        <Table
          title="Registros"
          data={tableMockedData}
          columns={tableColumns}
          options={{
            selectableRowsHideCheckboxes: true,
            search: false,
            sort: false,
          }}
          row={(rowData, rowDataIndex, rowIndex) => (
            <Row
              rowIndex={rowIndex}
              rowData={rowData}
              rowDataIndex={rowDataIndex}
            />
          )}
        />
      </StyledContent>
    </StyledBIMonitor>
  );
};

export default BIMonitor;
