import axios from 'axios';

export default async function addUserAccessibleLocation({
  accessToken,
  userId,
  locationId,
  setTokenLikeExpired,
}) {
  const requestOptions = {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  };

  try {
    const { data } = await axios.post(
      `${process.env.REACT_APP_AUTH_API_URL}/add-user-accessible-location/${userId}`,
      {
        locationId,
      },
      requestOptions,
    );

    return data;
  } catch (err) {
    if (err.response?.status === 401 && setTokenLikeExpired) return setTokenLikeExpired();

    return err;
  }
}
