import styled from 'styled-components';
import { Snackbar } from '@material-ui/core';
import MuiAlert from '@material-ui/lab/Alert';

export const StyledSnackbar = styled(Snackbar)``;

export const StyledAlert = styled(MuiAlert)`
  align-items: center;

  .MuiAlert-message {
    font-size: 1.4rem;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    max-width: 28rem;
  }

  .MuiButtonBase-root:focus {
    outline: none;
  }

  .MuiSvgIcon-root {
    width: 2rem;
    height: 2rem;
  }
`;
