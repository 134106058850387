import * as R from 'ramda';
import { format } from 'date-fns';

import { EBatchPlatformStatus, ELocalPostTopicType } from '../../types/TBatchGoogleModules';

import type {
  TLocationPostGooglePostsType,
  TCreateLocationPostGooglePostsType,
  TLocationPostAddButtonsType,
  TCallToActionType,
  TTopicType,
  TFormatScheduleBaseProps,
  TFormatScheduleReturn,
  TPostStates,
} from '../../types/TLocationPost';

import type { TImageFileValidation } from '../../types/TDragAndDropFiles';

type TParseDateInput = {
  input: Date | null;
  dayPart: string;
}

export const locationGooglePostsType: TLocationPostGooglePostsType = [
  {
    id: 0,
    name: 'Atualizações da COVID-19',
  },
  {
    id: 1,
    name: 'Oferta',
  },
  {
    id: 2,
    name: 'Novidades',
  },
  {
    id: 3,
    name: 'Evento',
  },
];

export const createLocationGooglePostsType: TCreateLocationPostGooglePostsType = [
  {
    id: Math.floor(Math.random() * 1),
    name: 'Atualizações da COVID-19',
  },
  {
    id: Math.floor(Math.random() * 2),
    name: 'Oferta',
  },
  {
    id: Math.floor(Math.random() * 3),
    name: 'Novidades',
  },
  {
    id: Math.floor(Math.random() * 4),
    name: 'Evento',
  },
];

export const locationPostAddButtonsType: TLocationPostAddButtonsType = [
  {
    id: Math.floor(Math.random() * 1),
    name: 'Nenhum',
  },
  {
    id: Math.floor(Math.random() * 2),
    name: 'Reserva',
  },
  {
    id: Math.floor(Math.random() * 3),
    name: 'Fazer o Pedido',
  },
  {
    id: Math.floor(Math.random() * 4),
    name: 'Comprar',
  },
  {
    id: Math.floor(Math.random() * 5),
    name: 'Saiba Mais',
  },
  {
    id: Math.floor(Math.random() * 6),
    name: 'Inscrever-se',
  },
  {
    id: Math.floor(Math.random() * 7),
    name: 'Ligar agora',
  },
];

export const getActionType = (actionTypeToGet: string): string | null => {
  const actionTypes: TCallToActionType = {
    Nenhum: null,
    Reserva: 'BOOK',
    'Fazer o Pedido': 'ORDER',
    Comprar: 'SHOP',
    'Saiba Mais': 'LEARN_MORE',
    'Inscrever-se': 'SIGN_UP',
    'Ligar agora': 'CALL',
  };

  return actionTypes[actionTypeToGet];
};

export const getActionTypeReverse = (actionTypeToGet: string): string => {
  const actionTypes: Record<string, string> = {
    BOOK: 'Reserva',
    ORDER: 'Fazer o Pedido',
    SHOP: 'Comprar',
    LEARN_MORE: 'Saiba Mais',
    SIGN_UP: 'Inscrever-se',
    CALL: 'Ligar agora',
    Nenhum: 'Nenhum',
  };

  return actionTypes[actionTypeToGet];
};

export const getTopicType = (topicTypeToGet: string): string => {
  const topicTypes: TTopicType = {
    ALERT: 'Atualizações da COVID-19',
    STANDARD: 'Novidades',
    OFFER: 'Oferta',
    EVENT: 'Evento',
  };

  return topicTypes[topicTypeToGet];
};

export const matchKeyword = (post:string, search:string) => {
  if (R.isEmpty(search)) return true;
  if (R.isNil(post)) return false;

  const formattedPost = post.toUpperCase();
  const formattedSearch = search.toUpperCase();

  if (formattedPost.includes(formattedSearch)) return true;

  return false;
};

export const convertPostType = (postType: string) => {
  if (postType === 'EVENT') return 'Evento';
  if (postType === 'STANDARD') return 'Novidades';
  if (postType === 'OFFER') return 'Oferta';
  if (postType === 'ALERT') return 'Atualizações da COVID-19';
  return '';
};

export const formatDateObjectToString = ({
  endDate,
  endTime,
  startDate,
  startTime,
}: TFormatScheduleBaseProps): TFormatScheduleReturn => {
  const padString = (input:number) => String(input).padStart(2, '0');

  let formattedSchedule: TFormatScheduleReturn = {
    formattedEndDate: '',
    formattedEndTime: '2359',
    formattedStartDate: '',
    formattedStartTime: '0000',
  };

  const {
    day: endDateDay,
    month: endDateMonth,
    year: endDateYear,
  } = endDate;

  formattedSchedule = {
    ...formattedSchedule,
    formattedEndDate: `${endDateDay}/${padString(endDateMonth)}/${padString(endDateYear)}`,
  };

  if (endTime && !R.isEmpty(endTime)) {
    const {
      hours: endTimeHours = 0,
      minutes: endTimeMinutes = 0,
    } = endTime;

    formattedSchedule = {
      ...formattedSchedule,
      formattedEndTime: `${padString(endTimeHours)}${padString(endTimeMinutes)}`,
    };
  }

  const {
    day: startDateDay,
    month: startDateMonth,
    year: startDateYear,
  } = startDate;

  formattedSchedule = {
    ...formattedSchedule,
    formattedStartDate: `${startDateDay}/${padString(startDateMonth)}/${padString(startDateYear)}`,
  };

  if (startTime && !R.isEmpty(startTime)) {
    const {
      hours: startTimeHours = 0,
      minutes: startTimeMinutes = 0,
    } = startTime;

    formattedSchedule = {
      ...formattedSchedule,
      formattedStartTime: `${padString(startTimeHours)}${padString(startTimeMinutes)}`,
    };
  }

  return formattedSchedule;
};

export const imagePostValidation: TImageFileValidation = {
  maxImageHeight: 1192,
  maxImageWidth: 2120,
  minImageHeight: 250,
  minImageWidth: 250,
  imageSize: 10240,
  imageSizeMessage: (imageName) => `O tamanho em KB da imagem ${imageName} é muito pequeno`,
  tooSmallImageMessage: (imageName) => `A imagem ${imageName} é muito pequena. Tamanho min: 250px/250px (w/h)`,
  tooLargeImageMessage: (imageName) => `A imagem ${imageName} é muito grande. Tamanho max: 2120px/1192px (w/h)`,
  wrongAspectRatioImageMessage: (imageName) => `A imagem ${imageName} não possui um formato válido`,
  isSingleUploadMessage: 'Não é possível enviar videos para publicação em massa',
};

export const parseDateInput = ({ input, dayPart }: TParseDateInput) => {
  if (R.isNil(input)) return null;

  if (Number.isNaN(input?.getTime())) return null;

  const formattedInput = input;

  if (dayPart === 'start') {
    formattedInput.setHours(0);
    formattedInput.setMinutes(0);
  }

  if (dayPart === 'end') {
    formattedInput.setHours(23);
    formattedInput.setMinutes(59);
  }

  return input.toISOString();
};

export const isValidDateInput = (input: Date | null) => {
  if (R.isNil(input)) return false;

  if (Number.isNaN(input?.getTime())) return false;

  return true;
};

export const postStates: TPostStates = {
  LIVE: 'Ativa',
  REJECTED: 'Rejeitada',
};

export const batchPostType: Record<ELocalPostTopicType, string> = {
  ALERT: 'Atualização Covid-19',
  EVENT: 'Evento',
  LOCAL_POST_TOPIC_TYPE_UNSPECIFIED: 'Não especificado',
  OFFER: 'Oferta',
  STANDARD: 'Novidades',
};
