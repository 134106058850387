import styled from 'styled-components';
import { ArrowUpward } from '@material-ui/icons';
import { devices } from '../../assets/styles/devices';

export const StyledBackToTop = styled.div`
  display: flex;
  position: fixed;
  align-items: center;
  font-size: 1.2rem;
  color: ${props => props.theme.colors.white};
  background-color: ${props => props.theme.colors.primary};
  right: 2rem;
  bottom: 2rem;
  padding: 1rem 2rem;
  border-radius: 3rem;
  opacity: 0.5;
  transition: 0.5s;
  cursor: pointer;
  z-index: 10;

  &:hover {
    opacity: 1;
  }

  @media ${devices.tabletLandscape} {
    display: none;
  };
`;

export const StyledArrowUpward = styled(ArrowUpward)`
  margin-right: 0.5rem;
`;
