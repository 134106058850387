import styled, { css } from 'styled-components';
import { shade } from 'polished';

const styledNavItemIsActive = css`
  background: ${props => shade(0.1, props.theme.colors.primary)};
`;

export const StyledNavItem = styled.div`
  display: flex;
  align-items: center;
  padding: 1rem 2.5rem;
  cursor: pointer;
  transition: .5s;
  width: 100%;

  &:hover {
    ${styledNavItemIsActive}
  }

  ${props => props.subItem && css`
    .nav-item-icon {
      width: 1.8rem;
      height: 1.8rem;
    }
  `}

  .nav-item-icon {
    width: 2rem;
    height: 2rem;
    fill: ${props => props.theme.colors.white};
  }

  .log-out-icon {
    width: 100%;
  }
`;

export const StyledNavItemName = styled.span`
  color: ${props => props.theme.colors.white};
  font-size: 1.4rem;
  margin-left: 1rem;
  margin-right: auto;
  white-space: nowrap;

  ${props => props.subItem && css`
    font-size: 1.4rem;
  `}
`;
