import React from 'react';
import { getPlatformLogo } from '../../assets/images/svg';
import { StyledLink } from './contact-widget-styles';

const ContactWidget = () => {
  return (
    <StyledLink href="https://api.whatsapp.com/send?phone=558009441340" target="_blank">
      {getPlatformLogo({ platform: 'Whatsapp', className: 'contact-icon' })}
    </StyledLink>
  );
};

export default ContactWidget;
