import React from 'react';
import * as R from 'ramda';

import type {
  TSpecialDateEntity,
} from '../../types/TSpecialDates';

import { Loading } from '..';

import { useAuth } from '../../hooks';

import CurrentDates from './current-dates';
import AddSpecialDate from './add-special-date';

import {
  StyledSpecialDates,
  StyledContent,
  StyledSectionTitle,
} from './special-dates-styles';

const SpecialDates: React.ForwardRefRenderFunction<{ specialDates: any }> = ({}, ref) => {
  const {
    userAccessToken,
    userSetTokenLikeExpired,
  } = useAuth();

  const [specialDatesData, setSpecialDatesData] = React.useState <TSpecialDateEntity[]>([]);
  const [currentSpecialDates, setCurrentSpecialDates] = React.useState<TSpecialDateEntity[]>([]);
  const [specialDateToEdit, setSpecialDateToEdit] = React.useState<TSpecialDateEntity | null>(null);
  const [isLoadingActiveLocationInfos, setIsLoadingActiveLocationInfos] = React.useState(false);

  const handleHasSpecialDates = (data: TSpecialDateEntity[]) => {
    if (R.isNil(data)) return false;
    if (R.isEmpty(data)) return false;

    return true;
  };

  React.useImperativeHandle(ref, () => ({ specialDates: specialDatesData }));

  return (
    <StyledSpecialDates>
      <StyledSectionTitle>
        {isLoadingActiveLocationInfos && <Loading className="is-loading-infos" />}

        Datas especiais
      </StyledSectionTitle>
      <StyledContent>
        <AddSpecialDate
          hasSpeciaDates={handleHasSpecialDates(specialDatesData)}
          data={specialDatesData}
          currentSpecialDates={currentSpecialDates}
          specialDateToEdit={specialDateToEdit}
          setSpecialDateToEdit={setSpecialDateToEdit}
          userAccessToken={userAccessToken}
          userSetTokenLikeExpired={userSetTokenLikeExpired}
          setSpecialDatesData={setSpecialDatesData}
        />
        <CurrentDates
          data={specialDatesData}
          setSpecialDateToEdit={setSpecialDateToEdit}
          setSpecialDatesData={setSpecialDatesData}
        />
      </StyledContent>
    </StyledSpecialDates>
  );
};

export default React.forwardRef(SpecialDates);
