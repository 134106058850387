import React from 'react';

import FieldRow from './fields-row';

import {
  mountFieldsToRender,
  notShowField,
} from './helpers';

import {
  StyledToCompareDialog,
  StyledHeader,
  StyledContent,
} from './to-compare-dialog-styles';

const ToCompareDialog = ({
  open,
  handleClose,
  titlePlatformColumn,
  dataFromLocation,
  dataFromPlatform,
  currentPlatform,
}) => {
  const [fields, setFields] = React.useState(null);

  React.useEffect(() => {
    setFields(mountFieldsToRender({ dataFromLocation, dataFromPlatform }));
  }, [dataFromLocation, dataFromPlatform]);

  const handleFieldsFilter = field => notShowField({ notShowList: field.notShow, currentPlatform });

  return (
    <StyledToCompareDialog
      open={open}
      onClose={handleClose}
    >
      <StyledHeader>
        <div className="column">
          Local (Hublocal)
        </div>
        <div className="column">
          {titlePlatformColumn}
        </div>
      </StyledHeader>
      <StyledContent>
        {fields && fields.filter(handleFieldsFilter).map(field => (
          <FieldRow
            key={Math.random() * 1000}
            title={field.title}
            valueHublocal={field.valueHublocal}
            valuePlatform={field.valuePlatform}
            name={field.name}
          />
        ))}
      </StyledContent>
    </StyledToCompareDialog>
  );
};

export default ToCompareDialog;
