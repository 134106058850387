import React from 'react';
import * as R from 'ramda';

import { TGooglePagination } from '../../types/TGooglePagination';

import IconButton from '../icon-button';
import Loading from '../loading';

import {
  StyledPagination,
  StyledPaginationButton,
  StyledPageButtonWrapper,
  StyledPageIndex,
  StyledPageText,
} from './facebook-pagination-styles';

export default ({
  nextPageToken,
  pageIndex = 1,
  setPageIndex,
  pageList,
  setPageList,
  onNextPageClicked,
  onBackPageClicked,
  onResetClicked,
  className = 'facebook-pagination',
  loading = false,
  isLabelButton = false,
}: TGooglePagination) => {
  const handleResetPaginationClicked = () => {
    if (R.isNil(nextPageToken)) return;

    if (setPageIndex) setPageIndex(1);
    onResetClicked();
  };

  const handleNextPageClicked = () => {
    if (R.isNil(nextPageToken)) return;

    const nextPageIndex = pageIndex + 1;
    if (setPageIndex) setPageIndex(nextPageIndex);
    if (setPageList) {
      const pageTokenList = [
        ...pageList,
        nextPageToken,
      ];

      setPageList(pageTokenList);
    }

    onNextPageClicked();
  };

  const handleBackPageClicked = () => {
    if (pageList.length === 1) return;

    const nextPageIndex = pageIndex - 1;
    if (setPageIndex) setPageIndex(nextPageIndex);
    onBackPageClicked();
  };

  return (
    <StyledPagination className={className}>
      {loading ? <Loading className="inner-facebook-pagination-loading" /> : (
        <>
          <StyledPaginationButton
            onClick={handleResetPaginationClicked}
            isLabelButton={isLabelButton}
            disabled={pageIndex === 1}
          >
            {isLabelButton && 'Primeira Página'}
            <IconButton
              disabled={pageIndex === 1}
              className="icon-pagination"
              icon="RotateLeft"
              tooltip="Primeira página"
            />
          </StyledPaginationButton>

          <StyledPageIndex>
            <StyledPageText>
              Pág.
              {' '}
              {pageIndex}
            </StyledPageText>
          </StyledPageIndex>

          <StyledPageButtonWrapper>
            <StyledPaginationButton
              disabled={pageList.length === 1}
              isLabelButton={isLabelButton}
              onClick={handleBackPageClicked}
            >
              <IconButton
                disabled={pageList.length === 1}
                className={isLabelButton ? 'icon-pagination-back' : ''}
                icon="ChevronLeft"
                tooltip="Página Anterior"
              />
              {isLabelButton && 'Página Anterior'}
            </StyledPaginationButton>

            <StyledPaginationButton
              disabled={R.isNil(nextPageToken)}
              isLabelButton={isLabelButton}
              onClick={handleNextPageClicked}
            >
              {isLabelButton && 'Próxima página'}
              <IconButton
                disabled={R.isNil(nextPageToken)}
                className="icon-pagination"
                icon="ChevronRight"
                tooltip="Próxima Página"
              />
            </StyledPaginationButton>
          </StyledPageButtonWrapper>
        </>
      )}
    </StyledPagination>
  );
};
