import React, {
  useState,
} from 'react';

import isNilOrEmpty from '../../../../../utils/is-null-or-empty';

import {
  formatDataToList,
  splitDataIntoSections,
} from './helpers';

import MonitorListPopover from './monitor-list-popover';
import MonitorSectionPopover from './monitor-section-popover';
import SpecialDatesInfo from './special-dates-info';
import WorkingHoursInfo from './working-hours-info';

import {
  StyledRequestsPopover,
} from './monitor-popover.styles';

import { TMonitorPopoverProps } from './types';

const MonitorsPopover = ({
  data, platform,
}: TMonitorPopoverProps) => {
  const [isBasicInfoOpen, setIsBasicInfoOpen] = useState(true);
  const [isCategoriesOpen, setIsCategoriesOpen] = useState(true);
  const [isAddressOpen, setIsAddressOpen] = useState(true);
  const [isLinksOpen, setIsLinksOpen] = useState(true);
  const [isScheduleOpen, setIsScheduleOpen] = useState(true);
  const [isSpecialDatesOpen, setIsSpecialDatesOpen] = useState(true);

  const dataSplitIntoSections = splitDataIntoSections(data);

  const {
    addressInfo, infoBasic, categories, links, schedule, special_dates,
  } = dataSplitIntoSections;

  return (
    <StyledRequestsPopover>
      {!isNilOrEmpty(infoBasic) && (
      <>
        <MonitorSectionPopover
          open={isBasicInfoOpen}
          setOpen={() => setIsBasicInfoOpen(prev => !prev)}
          title="Informações básicas"
          platform={platform}
        />

        {isBasicInfoOpen && (
          <MonitorListPopover
            data={formatDataToList(infoBasic)}
          />
        )}
      </>
      )}

      {!isNilOrEmpty(categories) && (
      <>
        <MonitorSectionPopover
          open={isCategoriesOpen}
          setOpen={() => setIsCategoriesOpen(prev => !prev)}
          title="Categorias"
          platform={platform}
        />
        {isCategoriesOpen
        && (
        <MonitorListPopover
          data={formatDataToList(categories)}
        />
        )}
      </>
      )}

      {!isNilOrEmpty(addressInfo) && (
      <>
        <MonitorSectionPopover
          open={isAddressOpen}
          setOpen={() => setIsAddressOpen(prev => !prev)}
          title="Endereço"
          platform={platform}
        />
        {isAddressOpen
        && (
        <MonitorListPopover
          data={formatDataToList(addressInfo)}
        />
        )}
      </>
      )}

      {!isNilOrEmpty(links) && (
      <>
        <MonitorSectionPopover
          open={isLinksOpen}
          setOpen={() => setIsLinksOpen(prev => !prev)}
          title="Links"
          platform={platform}
        />
        {isLinksOpen
        && (
        <MonitorListPopover
          data={formatDataToList(links)}
        />
        )}
      </>
      )}

      {!isNilOrEmpty(schedule) && (
      <>
        <MonitorSectionPopover
          open={isScheduleOpen}
          setOpen={() => setIsScheduleOpen(prev => !prev)}
          title="Horário de funcionamento"
          platform={platform}
        />
        {isScheduleOpen
        && (
        <WorkingHoursInfo
          saasWorkingHours={schedule[0].saas_data.value}
          platformWorkingHours={schedule[0].platform_data.value}
          status={schedule[0].status}
        />
        )}
      </>
      )}

      {!isNilOrEmpty(special_dates) && (
      <>
        <MonitorSectionPopover
          open={isSpecialDatesOpen}
          setOpen={() => setIsSpecialDatesOpen(prev => !prev)}
          title="Datas especiais"
          platform={platform}
        />
        {isSpecialDatesOpen
        && (
          <SpecialDatesInfo
            specialDates={special_dates}
          />
        )}
      </>
      )}
    </StyledRequestsPopover>
  );
};

export default MonitorsPopover;
