import React from 'react';

import { IconButton } from '..';
import NotificationBox from '../notification-box';

import {
  StyledDialog,
  StyledDialogHeader,
  StyledDialogWrapper,
  StyledDialogContent,
  StyledDialogList,
  StyledDialogItem,
  StyledErrorIcon,
  StyledDialogItemTitle,
  StyledDialogItemContent,
  StyleDialogTitle,
} from './double-duplicaion-service-dialog-styles';
import { TTableRowData } from '../../types/TTable';

export interface TDoubleDuplicationServiceDialogProps {
  open: boolean;
  onClose: () => void;
  services?: TTableRowData[];
  total?: number;
}

export default ({
  onClose, open, services = [], total = 0,
}: TDoubleDuplicationServiceDialogProps) => (
  <StyledDialog open={open} onClose={onClose}>
    <StyledDialogWrapper>
      <StyledDialogHeader>
        <StyleDialogTitle>Erro ao executar ação</StyleDialogTitle>
        <IconButton
          icon="Close"
          tooltip="Fechar"
          onClick={() => onClose()}
          isWhite
        />
      </StyledDialogHeader>
      <StyledDialogContent>
        <NotificationBox
          text="Notamos que existe mais de um serviço duplicado ou com o mesmo nome. Para que a solicitação seja concluída com sucesso será preciso selecionar, dentre as opções listadas abaixo, uma de cada serviço que está duplicado e excluí-las em conjunto, deixando apenas uma opção válida. Nenhuma ação poderá ser feita até que esse problema seja corrigido."
          type="WARNING"
        />
        <StyledDialogList>
          <StyledDialogItem>
            <StyledDialogItemTitle>Serviços</StyledDialogItemTitle>
            <StyledDialogItemContent>
              {`(${services.length} de ${total} totais)`}
            </StyledDialogItemContent>
          </StyledDialogItem>
          {services.map(service => (
            <StyledDialogItem>
              <StyledDialogItemContent isGray>{service[1].value}</StyledDialogItemContent>
              <StyledErrorIcon />
            </StyledDialogItem>
          ))}
        </StyledDialogList>
      </StyledDialogContent>
    </StyledDialogWrapper>
  </StyledDialog>
);
