import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import * as R from 'ramda';
import { useSnackbar } from 'notistack';

import { Creators as AuthActions } from '../../redux/ducks/auth';

import updateCompany from '../../services/companies/updateCompany';
import removeResponsibles from '../../services/companies/removeResponsibles';
import addResponsibles from '../../services/companies/addResponsibles';

import updateCompanyGroup from '../../services/company-groups/update';
import removeResponsiblesCompanyGroup from '../../services/company-groups/removeResponsibles';
import addResponsiblesCompanyGroup from '../../services/company-groups/addResponsibles';

import updateLocationGroup from '../../services/location-groups/update';
import removeResponsiblesLocationGroup from '../../services/location-groups/removeResponsibles';
import addResponsiblesLocationGroup from '../../services/location-groups/addResponsibles';

import updateLocation from '../../services/locations/updateLocation';
import removeResponsiblesLocation from '../../services/locations/removeOtherResponsibles';
import addResponsiblesLocation from '../../services/locations/addOtherResponsibles';

import { TRootStateRedux } from '../../types/TRootStateRedux';
import { TResponsibleInfo } from '../../types/TResponsibleInfo';

import { translations } from '../../translations';

import Loading from '../loading';

import {
  APICreateResponsible,
  APIUpdateResponsible,
  APIDeleteResponsible,
} from '../../services/responsibles';

import { useUncontrolledField } from '../../hooks';

import {
  fieldResponsibleName,
  fieldResponsiblePhone,
  fieldResponsibleEmail,
} from './fields-scheme';

import { Button, IconButton } from '..';

import Card from './card';

import {
  StyledResponsibleInfo,
  StyledTitlePage,
  StyledResponsibleInfoTitleWrapper,
  StyledResponsibleInfoFields,
  StyledDisclaimer,
  StyledTitleCreateResponsible,
  StyledFields,
  StyledForm,
  StyledCardListing,
  StyledButtonsEditing,
} from './responsible-info-styles';

const ResponsibleInfo = (
  {
    className,
    initialData = [],
    editingMode = false,
    maxResponsibles = 10,
    isCompanyGroup = false,
    groupId,
    isCompany = false,
    isLocation = false,
    isLocationGroup = false,
    locationId,
  }: TResponsibleInfo,
  ref: any,
) => {
  const { enqueueSnackbar } = useSnackbar();
  const reduxDispatch = useDispatch();

  const { language } = useSelector((state: TRootStateRedux) => state.AuthReducer);
  const { responsible_info: translation } = translations[language];

  const { user } = useSelector((state: TRootStateRedux) => state.AuthReducer);
  const { activeCompany } = useSelector((state: TRootStateRedux) => state.CompanyReducer);

  const {
    accessToken: userHublocalToken,
    user: { id: userHublocalID },
  } = user;

  const [addResponsible, setAddResponsible] = React.useState(false);
  const [editResponsible, setEditResponsible] = React.useState(false);

  const [responsibles, setResponsibles] = React.useState<any[]>([]);

  const [responsibleFields, setResponsibleFields] = React.useState<any[]>([]);
  const [responsibleToUpdate, setResponsibleToUpdate] = React.useState<any>(null);

  const [isCreatingResponsible, setIsCreatingResponsible] = React.useState(false);
  const [isUpdatingResponsible, setIsUpdatingResponsible] = React.useState(false);
  const [isSaveMainResponsible, setIsSaveMainResponsible] = React.useState(false);

  const [companyId] = React.useState<number | null>(activeCompany.id || null);

  const { Field: ResponsibleName } = useUncontrolledField({
    fieldScheme: fieldResponsibleName,
    saveIn: setResponsibleFields,
  });

  const { Field: ResponsiblePhone } = useUncontrolledField({
    fieldScheme: fieldResponsiblePhone,
    saveIn: setResponsibleFields,
  });

  const { Field: ResponsibleEmail } = useUncontrolledField({
    fieldScheme: fieldResponsibleEmail,
    saveIn: setResponsibleFields,
  });

  const setTokenLikeExpired = () => {
    reduxDispatch(AuthActions.setUser({ ...user, accessToken: 'expired' }));
  };

  const companiesGroupResponsiblesController = async ({
    mainResponsibleId,
    otherResponsibles,
  }: any) => {
    await removeResponsiblesCompanyGroup({
      accessToken: userHublocalToken,
      companyGroupId: groupId,
      responsible_ids: [mainResponsibleId],
      setTokenLikeExpired,
    });

    if (!R.isEmpty(otherResponsibles)) {
      await addResponsiblesCompanyGroup({
        accessToken: userHublocalToken,
        companyGroupId: groupId,
        setTokenLikeExpired,
        responsible_ids: otherResponsibles,
      });
    }

    if (!groupId) return;

    await updateCompanyGroup({
      setTokenLikeExpired,
      accessToken: userHublocalToken,
      companyGroupId: groupId,
      main_responsible_id: mainResponsibleId,
      setIsCreatingCompany: setIsSaveMainResponsible,
    });
  };

  const locationsGroupResponsiblesController = async ({
    mainResponsibleId,
    otherResponsibles,
  }: any) => {
    await removeResponsiblesLocationGroup({
      accessToken: userHublocalToken,
      locationGroupId: groupId,
      responsibleIds: [mainResponsibleId],
      setTokenLikeExpired,
    });

    if (!R.isEmpty(otherResponsibles) && groupId) {
      await addResponsiblesLocationGroup({
        accessToken: userHublocalToken,
        locationGroupId: groupId,
        setTokenLikeExpired,
        responsibleIds: otherResponsibles,
      });
    }

    if (!groupId) return;

    await updateLocationGroup({
      setTokenLikeExpired,
      accessToken: userHublocalToken,
      locationGroupId: groupId,
      main_responsible_id: mainResponsibleId,
      setIsCreatingLocationGroup: setIsSaveMainResponsible,
    });
  };

  const companyResponsiblesController = async ({
    mainResponsibleId,
    otherResponsibles,
  }: any) => {
    await removeResponsibles({
      accessToken: userHublocalToken,
      companyId,
      responsible_ids: [mainResponsibleId],
      setTokenLikeExpired,
    });

    if (!R.isEmpty(otherResponsibles)) {
      await addResponsibles({
        accessToken: userHublocalToken,
        companyId,
        setTokenLikeExpired,
        responsible_ids: otherResponsibles,
      });
    }

    if (!companyId) return;

    await updateCompany({
      setTokenLikeExpired,
      accessToken: userHublocalToken,
      companyId,
      main_responsible_id: mainResponsibleId,
      setIsSaveEdition: setIsSaveMainResponsible,
    });
  };

  const locationResponsiblesController = async ({
    mainResponsible,
    otherResponsibles,
  }: any) => {
    if (!locationId) return;

    await removeResponsiblesLocation({
      accessToken: userHublocalToken,
      locationId,
      responsibleIds: [mainResponsible.id],
      setTokenLikeExpired,
    });

    if (!R.isEmpty(otherResponsibles)) {
      await addResponsiblesLocation({
        accessToken: userHublocalToken,
        locationId,
        setTokenLikeExpired,
        responsibleIds: otherResponsibles,
      });
    }

    await updateLocation({
      setTokenLikeExpired,
      accessToken: userHublocalToken,
      locationId,
      inputData: {
        responsible: mainResponsible,
      },
      setIsFetching: setIsSaveMainResponsible,
    });
  };

  const setMainResponsibleOnNewIsChecked = React.useCallback(
    async (newResponsibles: any) => {
      const mainResponsible = newResponsibles
        ? newResponsibles.filter((responsible: any) => responsible.main === true)[0]
        : responsibles.filter((responsible: any) => responsible.main === true)[0];

      const { id: mainResponsibleId } = mainResponsible;

      const otherResponsibles = newResponsibles
        ? newResponsibles
          .filter((responsible: any) => responsible.main !== true)
          .map((responsible: any) => responsible.id)
        : responsibles
          .filter((responsible: any) => responsible.main !== true)
          .map((responsible: any) => responsible.id);

      if (!R.isNil(mainResponsibleId) || !R.isEmpty(mainResponsibleId)) {
        if (isCompany || isLocation) {
          companyResponsiblesController({
            mainResponsibleId,
            otherResponsibles,
          });
        }
        if (isCompanyGroup) {
          companiesGroupResponsiblesController({
            mainResponsibleId,
            otherResponsibles,
          });
        }
        if (isLocation) {
          locationResponsiblesController({
            mainResponsible,
            otherResponsibles,
          });
        }
        if (isLocationGroup) {
          locationsGroupResponsiblesController({
            mainResponsibleId,
            otherResponsibles,
          });
        }
      }
    },
    [responsibles],
  );

  React.useEffect(() => {
    if (!R.isNil(initialData) && !R.isEmpty(initialData)) setResponsibles(initialData);
  }, [initialData]);

  const handleCreateResponsible = React.useCallback(
    async (e: any) => {
      e.preventDefault();

      if (responsibleFields.every((field: any) => field.firedCheck())) {
        const newResponsible = await APICreateResponsible({
          accessToken: userHublocalToken,
          created_by: userHublocalID,
          setIsCreatingResponsible,
          setTokenLikeExpired,
          feedbackMessage: enqueueSnackbar,
          name: responsibleFields[0].getValue(),
          phone: responsibleFields[1].getValue(),
          email: responsibleFields[2].getValue(),
        });

        if (!newResponsible) return;

        const newResponsibles = [
          ...responsibles,
          {
            ...newResponsible,
            main: responsibles.length === 0,
          },
        ];

        setResponsibles(newResponsibles);

        setAddResponsible(false);

        if (editingMode) setMainResponsibleOnNewIsChecked(newResponsibles);
      }
    },
    [responsibleFields, responsibles],
  );

  const handleChangeMainResponsible = React.useCallback(
    async (targetResponsible: any) => {
      const newReponsibles = responsibles.map((responsible) => {
        if (responsible.id === targetResponsible.id) {
          return {
            ...responsible,
            main: true,
          };
        }

        return {
          ...responsible,
          main: false,
        };
      });

      setResponsibles(newReponsibles);

      if (editingMode) setMainResponsibleOnNewIsChecked(newReponsibles);
    },
    [responsibles, editingMode],
  );

  const handleRemoveResponsibleClicked = React.useCallback(
    async (targetResponsibleId: any) => {
      const newResponsibles = responsibles.filter(
        (responsible) => responsible.id !== targetResponsibleId,
      );
      const { main: isMainResponsible } = responsibles.filter(
        (responsible) => responsible.id === targetResponsibleId,
      )[0];

      const currentLengthResponsiblesData = newResponsibles.length;

      if (editingMode) {
        if (responsibles.length < 2) {
          enqueueSnackbar(
            'Não é possível deletar esse responsável. A empresa tem que ter pelo menos um!',
            { variant: 'error' },
          );

          return;
        }
      }

      if (!isMainResponsible) {
        await APIDeleteResponsible({
          accessToken: userHublocalToken,
          feedbackMessage: enqueueSnackbar,
          responsibleId: targetResponsibleId,
          setTokenLikeExpired,
        });
      }

      if (R.isEmpty(newResponsibles)) {
        setResponsibles([]);

        if (editingMode) setMainResponsibleOnNewIsChecked(newResponsibles);
        return;
      }

      let newResponsiblesChecked: any = [];

      if (isMainResponsible && currentLengthResponsiblesData > 1) {
        newResponsiblesChecked = newResponsibles.map((responsible, index) => {
          if (index === 0) {
            return {
              ...responsible,
              main: true,
            };
          }

          return responsible;
        });
      }

      if (!isMainResponsible && currentLengthResponsiblesData > 1) newResponsiblesChecked = newResponsibles;

      if (currentLengthResponsiblesData === 1) {
        newResponsiblesChecked = newResponsibles.map((responsible) => ({
          ...responsible,
          main: true,
        }));
      }

      setResponsibles(newResponsiblesChecked);
      setMainResponsibleOnNewIsChecked(newResponsiblesChecked);
    },
    [responsibles],
  );

  const handleEditResponsible = React.useCallback(
    ({ responsible }: any) => {
      setEditResponsible(true);
      setResponsibleToUpdate(responsible);

      if (!addResponsible) setAddResponsible(true);

      if (responsible) {
        const { name, phone, email } = responsible;

        if (R.isNil(name) && R.isNil(phone) && R.isNil(email)) return;

        responsibleFields[0].setDefaultValue(name);
        responsibleFields[1].setDefaultValue(phone);
        responsibleFields[2].setDefaultValue(email);
      }
    },
    [responsibleFields, addResponsible],
  );

  const handleCancelEditing = React.useCallback(() => {
    setEditResponsible(false);
    setAddResponsible(false);
    responsibleFields.map((field) => field.reset());
  }, [editResponsible]);

  const handleUpdateResponsible = React.useCallback(
    async (e: any) => {
      e.preventDefault();

      if (responsibleFields.every((field) => field.firedCheck())) {
        const name = responsibleFields[0].getValue();
        const phone = responsibleFields[1].getValue();
        const email = responsibleFields[2].getValue();
        const responsibleId = responsibleToUpdate.id;

        setAddResponsible(false);

        await APIUpdateResponsible({
          accessToken: userHublocalToken,
          responsibleId,
          setIsUpdatingResponsible,
          setTokenLikeExpired,
          feedbackMessage: enqueueSnackbar,
          name,
          phone,
          email,
        });

        const newResponsibles = responsibles.map((responsible) => {
          if (responsible.id === responsibleToUpdate.id) {
            return {
              ...responsible,
              name,
              phone,
              email,
            };
          }

          return responsible;
        });

        setResponsibles(newResponsibles);
        setEditResponsible(false);

        responsibleFields[0].setDefaultValue('');
        responsibleFields[1].setDefaultValue('');
        responsibleFields[2].setDefaultValue('');
      }
    },
    [responsibleFields, responsibles, responsibleToUpdate],
  );

  React.useImperativeHandle(ref, () => ({
    responsibles,
    reset: () => setResponsibles([]),
  }));

  return (
    <StyledResponsibleInfo className={className}>
      <StyledResponsibleInfoTitleWrapper>
        <StyledTitlePage>{translation.responsible_info}</StyledTitlePage>

        <IconButton
          icon="Info"
          placement="right"
          tooltip="Responsável é o encarregado pelo contato desta empresa/localidade"
        />
      </StyledResponsibleInfoTitleWrapper>

      <StyledResponsibleInfoFields>
        {R.isEmpty(responsibles) && (
          <StyledDisclaimer>{translation.no_responsible}</StyledDisclaimer>
        )}
        {!R.isEmpty(responsibles) && (
          <StyledCardListing>
            {R.sortBy(R.prop('name'), responsibles).map(
              (responsible, index) => (
                <Card
                  key={responsible.id}
                  mainText={translation.main}
                  isSaveMainResponsible={
                    responsible.main && isSaveMainResponsible
                  }
                  enabledLoadingDelete={responsibles.length > 2}
                  labels={{
                    name: translation.name,
                    email: translation.email,
                    phone: translation.phone,
                  }}
                  labelContent={{
                    name: responsible.name,
                    email: responsible.email,
                    phone: responsible.phone,
                  }}
                  checked={responsible.main || false}
                  changeChecked={() => handleChangeMainResponsible(responsible)}
                  removeMainResponsible={() => handleRemoveResponsibleClicked(responsible.id)}
                  editResponsible={() => handleEditResponsible({ responsible, index })}
                />
              ),
            )}
          </StyledCardListing>
        )}

        {addResponsible ? (
          <>
            <StyledTitleCreateResponsible>
              {translation.create_responsible}
            </StyledTitleCreateResponsible>
            <StyledForm onSubmit={handleCreateResponsible}>
              <StyledFields>
                <ResponsibleName />
                <ResponsiblePhone />
                <ResponsibleEmail />
              </StyledFields>

              {editResponsible ? (
                <StyledButtonsEditing>
                  <Button
                    onClick={handleCancelEditing}
                    buttonType="cancel"
                    className="button button-cancel-responsible"
                  >
                    Cancelar
                  </Button>

                  <Button
                    disabled={isUpdatingResponsible}
                    onClick={(e) => handleUpdateResponsible(e)}
                    className="button button-update-responsible"
                  >
                    {isUpdatingResponsible ? <Loading /> : 'Salvar'}
                  </Button>
                </StyledButtonsEditing>
              ) : (
                <Button
                  disabled={isCreatingResponsible}
                  type="submit"
                  className="button button-create-responsible"
                >
                  {isCreatingResponsible ? (
                    <Loading />
                  ) : (
                    translation.create_responsible
                  )}
                </Button>
              )}
            </StyledForm>
          </>
        ) : (
          <Button
            disabled={responsibles.length >= maxResponsibles}
            className="button add-responsible"
            onClick={() => setAddResponsible(true)}
          >
            Adicionar responsável
          </Button>
        )}
      </StyledResponsibleInfoFields>
    </StyledResponsibleInfo>
  );
};

export default React.memo(React.forwardRef(ResponsibleInfo));
