export const workingHoursMocked = [
  {
    is_open: false,
    is_replicated: true,
    day_title: 'Segunda',
    times: [
      {
        id: 0,
        open: '00:00',
        close: '00:00',
        is_deleted: false,
      },
    ],
    is_24_hours: false,
    dropdown_to_bottom: false,
    is_deleted: false,
    will_update: false,
  },
  {
    is_open: false,
    is_replicated: false,
    day_title: 'Terça',
    times: [
      {
        id: 0,
        open: '00:00',
        close: '00:00',
        is_deleted: false,
      },
    ],
    is_24_hours: false,
    dropdown_to_bottom: false,
    is_deleted: false,
    will_update: false,
  },
  {
    is_open: false,
    is_replicated: false,
    day_title: 'Quarta',
    times: [
      {
        id: 0,
        open: '00:00',
        close: '00:00',
        is_deleted: false,
      },
    ],
    is_24_hours: false,
    dropdown_to_bottom: false,
    is_deleted: false,
    will_update: false,
  },
  {
    is_open: false,
    is_replicated: false,
    day_title: 'Quinta',
    times: [
      {
        id: 0,
        open: '00:00',
        close: '00:00',
        is_deleted: false,
      },
    ],
    is_24_hours: false,
    dropdown_to_bottom: false,
    is_deleted: false,
    will_update: false,
  },
  {
    is_open: false,
    is_replicated: false,
    day_title: 'Sexta',
    times: [
      {
        id: 0,
        open: '00:00',
        close: '00:00',
        is_deleted: false,
      },
    ],
    is_24_hours: false,
    dropdown_to_bottom: false,
    is_deleted: false,
    will_update: false,
  },
  {
    is_open: false,
    is_replicated: false,
    day_title: 'Sábado',
    times: [
      {
        id: 0,
        open: '00:00',
        close: '00:00',
        is_deleted: false,
      },
    ],
    is_24_hours: false,
    dropdown_to_bottom: true,
    is_deleted: true,
    will_update: false,
  },
  {
    is_open: false,
    is_replicated: false,
    day_title: 'Domingo',
    times: [
      {
        id: 0,
        open: '00:00',
        close: '00:00',
        is_deleted: false,
      },
    ],
    is_24_hours: false,
    dropdown_to_bottom: true,
    is_deleted: false,
    will_update: false,
  },
];

export const sortByDay = ({ prevDay, nextDay }) => {
  const sorter = {
    Segunda: 1,
    Terça: 2,
    Quarta: 3,
    Quinta: 4,
    Sexta: 5,
    Sábado: 6,
    Domingo: 7,
  };

  const prevDayTitle = prevDay?.day_title;
  const nextDayTitle = nextDay?.day_title;

  return sorter[prevDayTitle] - sorter[nextDayTitle];
};
