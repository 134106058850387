import React, { useEffect, useState } from 'react';
import { StyledNotificationBox } from './notification-box-styles';

type TNotificationBoxProps = {
  text: string | JSX.Element;
  type: 'SUCCESS' | 'WARNING' | 'ERROR';
  noBottomMargin?: boolean;
  noBorderRadius?: boolean;
}

const NotificationBox = ({
  text,
  type,
  noBottomMargin = false,
  noBorderRadius = false,
}: TNotificationBoxProps) => {
  const [color, setColor] = useState('');
  useEffect(() => {
    const colors = {
      SUCCESS: 'green',
      WARNING: '#E3871A',
      ERROR: 'red',
    };

    setColor(colors[type]);
  }, [type]);
  return (
    <StyledNotificationBox
      color={color}
      noBottomMargin={noBottomMargin}
      noBorderRadius={noBorderRadius}
    >
      <p>{text}</p>
    </StyledNotificationBox>
  );
};

export default NotificationBox;
