import React from 'react';
import {
  Power as PowerIcon,
  Place as PlaceIcon,
} from '@material-ui/icons';

import Loading from '../../../../loading';

import Sublisting from './sublisting';

import {
  StyledListingItem,
  StyledAction,
  StyledRowInfo,
} from './listing-item-styles';

const ListingItem = ({
  id: pageId,
  mainPageId,
  name,
  image,
  description,
  handleBind,
  noImages = false,
  sublisting,
  keyToFilter,
}) => {
  const [isBindedPage, setIsBindedPage] = React.useState(false);
  const [openSublisting, setOpenSublisting] = React.useState(false);

  const handleOnClickBind = () => {
    if (mainPageId) {
      handleBind({ selectedPageId: `${mainPageId},${pageId}`, setLoading: setIsBindedPage });
    } else {
      handleBind({ selectedPageId: pageId, setLoading: setIsBindedPage });
    }
  };

  return (
    <StyledListingItem>
      <StyledRowInfo>
        {!noImages && <img src={image || 'https://place-hold.it/600x600?text=Image'} alt={name || 'Alt Image'} />}
        <div className="infos-name-and-description">
          <h4>{name || ''}</h4>
          <span>{description}</span>
        </div>
        {!sublisting ? (
          <StyledAction
            onClick={handleOnClickBind}
            aria-hidden="true"
          >
            {isBindedPage ? <Loading /> : (
              <>
                Vincular
                <PowerIcon />
              </>
            )}
          </StyledAction>
        ) : (
          <StyledAction
            onClick={() => setOpenSublisting(!openSublisting)}
            secondary
            aria-hidden="true"
          >
            {openSublisting ? 'Fechar' : 'Ver Locais'}
            <PlaceIcon />
          </StyledAction>
        )}
      </StyledRowInfo>
      {(sublisting && openSublisting) && (
      <Sublisting
        mainPageId={pageId}
        data={sublisting}
        name="name"
        image="image"
        description="single_line_address"
        handleBind={handleBind}
        noImages={false}
        keyToFilter="name"
      />
      )}
    </StyledListingItem>
  );
};

export default ListingItem;
