/* eslint-disable react/jsx-props-no-spreading */
// eslint-disable-next-line no-use-before-define
import React, {
  useCallback,
  useEffect,
  useState,
} from 'react';

import { GoogleServiceAreaPlacesInput, MapInfoContainer } from '../../../components';
import { useAuth } from '../../../hooks';
import { CountryList } from '../../../utils/country-list';
import { isEmptyOrNull } from '../../batch-update-posts-status/create-post-dialog/helpers';

import ControlledInput from '../controlled-input';
import AddressFields from './address-fields';
import CategoriesFields from './categories-fields';
import Descriptions from './descriptions';

import {
  answerAtThatAddressAttr,
  formattedAddress,
  formattedCategories,
  storeCodeAttr,
} from './helpers';

import {
  StyledFieldsWrapper,
  StyledForm,
  StyledFormContainer,
  StyledLocationForm,
  StyledSectionTitle,
  StyledSeparator,
  StyledTitleBlock,
} from './infos-styles';
import LocationInfo from './location-info';

import {
  EInfoLocationKeys,
  TInfosProps,
  TInitialInfoData,
} from './types';

const Infos = ({
  updateLocation,
  setUpdateLocation,
  fieldOnFocus,
  setFieldOnFocus,
}: TInfosProps) => {
  const { userProfileName } = useAuth();

  const [locationInfo, setLocationInfo] = useState<TInitialInfoData>({
    facebook_categories: [],
    categoryGroupingsSelected: [],
    foursquare_categories: [],
    google_categories: [],
    selectedServiceAreaPlaceInfos: [],
  });

  const StoreCode = useCallback(() => (
    <ControlledInput
      {...storeCodeAttr}
      fieldOnFocus={fieldOnFocus}
      setFieldOnFocus={setFieldOnFocus}
      setParentValue={setUpdateLocation}
      defaultValue={locationInfo.store_code}
    />
  ), [locationInfo, fieldOnFocus]);

  const AnswerAtThatAddress = useCallback(() => (
    <ControlledInput
      {...answerAtThatAddressAttr}
      fieldOnFocus={fieldOnFocus}
      setFieldOnFocus={setFieldOnFocus}
      setParentValue={setUpdateLocation}
      defaultValue={!locationInfo.does_not_serve_at_this_address}
    />
  ), [locationInfo, fieldOnFocus]);

  useEffect(() => {
    const initialLocation = { ...locationInfo };
    Object.keys(updateLocation).forEach(key => {
      if (key in EInfoLocationKeys) {
        if (key === 'address1') {
          const [address, number] = formattedAddress(updateLocation[key]);
          initialLocation.address = address;
          initialLocation['address-number'] = number;
        } else if (key === 'address2') {
          initialLocation.address2 = updateLocation.address2;
        } else if (key.includes('categories')) {
          initialLocation[key] = formattedCategories(updateLocation[key]);
        } else initialLocation[EInfoLocationKeys[key]] = updateLocation[key];
      }
    });

    setLocationInfo(initialLocation);
  }, [updateLocation]);

  const onChangeSelectedPlaces = useCallback((selectedPlaces) => {
    setUpdateLocation(prev => ({
      ...prev,
      placeInfos: selectedPlaces,
      google_service_area: !isEmptyOrNull(selectedPlaces) ? {
        businessType: !prev.does_not_serve_at_this_address
          ? 'CUSTOMER_AND_BUSINESS_LOCATION'
          : 'CUSTOMER_LOCATION_ONLY',
        places: { placeInfos: selectedPlaces },
        regionCode: CountryList[updateLocation.country],
      } : null,
    }));
  }, []);

  return updateLocation ? (
    <>
      <StyledLocationForm>
        <StyledTitleBlock>Informações do local</StyledTitleBlock>
        <StyledFormContainer>
          <StyledForm as="div">
            <StyledSectionTitle>Novo local</StyledSectionTitle>
            <StyledFieldsWrapper storeCode>
              <StoreCode />
            </StyledFieldsWrapper>

            <StyledSeparator />

            <LocationInfo
              updatedLocation={locationInfo}
              setUpdatedLocation={setUpdateLocation}
              fieldOnFocus={fieldOnFocus}
              setFieldOnFocus={setFieldOnFocus}
            />

            <StyledSeparator />

            { userProfileName !== 'Usuário Padrão' && (
              <CategoriesFields
                locationInfo={locationInfo}
                setLocationInfo={setUpdateLocation}
              />
            )}

            <AddressFields
              setLocationInfo={setUpdateLocation}
              initialData={locationInfo}
              fieldOnFocus={fieldOnFocus}
              setFieldOnFocus={setFieldOnFocus}
            />

            <GoogleServiceAreaPlacesInput
              label="Área de cobertura"
              className="google-service-area-input"
              initialSelectedPlaces={updateLocation?.google_service_area?.places?.placeInfos}
              onSelectedPlacesChange={onChangeSelectedPlaces}
            />
            <AnswerAtThatAddress />
          </StyledForm>
        </StyledFormContainer>
      </StyledLocationForm>

      <StyledTitleBlock>Localização</StyledTitleBlock>

      <MapInfoContainer isEditLocation />

      <Descriptions
        initialData={locationInfo}
        setLocationInfo={setUpdateLocation}
        fieldOnFocus={fieldOnFocus}
        setFieldOnFocus={setFieldOnFocus}
      />
    </>
  ) : <span>Carregando informações do local...</span>;
};

export default Infos;
