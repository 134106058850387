import {
  Checkbox, FormGroup, Paper, Switch, TextareaAutosize, TextField,
} from '@material-ui/core';
import styled from 'styled-components';
import { devices } from '../../assets/styles/devices';

export const StyledContainer = styled(Paper)`
    padding: 2rem 4rem;
`;

export const StyledDate = styled.span`
    font-size: 1rem;
    font-weight: normal;
    color: ${(props) => props.theme.colors.grey};
`;

export const StyledFormGroup = styled(FormGroup)``;

export const StyledCheckbox = styled(Checkbox)`
    .MuiSvgIcon-root { 
        font-size: 20px;
        color: ${(props) => props.theme.colors.primary} !important;
    }
`;

export const StyledPositionCheckbox = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

export const StyledLabel = styled.text`
    font-size: 1.3rem;
    font-weight: bold;
    line-height: 1.4rem;
    padding: 12px 0;
`;

export const StyledInput = styled(TextField)`
    width: 100%;
    border-radius: 6px;
    
    .MuiInputBase-root{
        font-size: 1.3rem;
        border-radius: 6px;
    }
    
    .MuiInputBase-input {
        box-sizing: inherit !important;
    }
`;

export const StyledTextArea = styled(TextareaAutosize)`
    border-radius: 6px;
    padding: 1rem;
    margin-bottom: 1rem;
    border: 0.1rem solid ${(props) => props.theme.colors.grey} !important;
`;

export const StyledFooter = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;  
    margin: 2rem 0;

    @media ${devices.tabletLandscape} {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }
`;

export const StyledResponseState = styled.div`
    display: flex;
    align-items: center;
    text-align: center;

    .FormControlLabel {
        margin: 0;
        font-size: 1rem;
        font-weight: normal;
        color: ${(props) => props.theme.colors.grey};
    }
`;

export const StyledTitleFooter = styled.text`
    font-size: 1.2rem;
    line-height: 1rem;
    color: ${(props) => props.theme.colors.grey};
`;

export const StyledSwitch = styled(Switch)`
    .MuiSwitch-switchBase.Mui-checked {
        color: ${(props) => props.theme.colors.secondary};
    }
    
    .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track {
        background-color: ${(props) => props.theme.colors.secondary};
    }
`;

export const StyledGroupButtonCard = styled.div`
    display: flex;
    gap: 20px;
`;

export const StyledPositionButtonAction = styled.div`
    display: flex;
    justify-content: space-between;
    margin-top: 0.5rem;
`;

export const StyledGroupActionButton = styled.div`
    display: flex;
    gap: 1rem;
`;
