import { isNil, isEmpty } from 'ramda';
import isNilOrEmpty from './is-null-or-empty';

import type { TEllegibleLocationForGuest } from '../types/TReviews';

const guestTypes = {
  Owner: true,
  CompanyGuest: true,
  Location: true,
  LocationGuest: true,
};

const isEllebigleLocationForGuest = ({
  location,
  guestType,
  userId,
  userProfileName,
}: TEllegibleLocationForGuest): boolean => {
  if (userProfileName !== 'Usuário Padrão') return true;
  if (isNil(guestType)) return false;
  if (guestType === 'Owner' || guestType === 'CompanyGuest') return true;
  if (isEmpty(location.guests)) return false;

  const foundMyGuest = location.guests.filter(
    (guest) => guest.user.id === userId,
  );

  if (isEmpty(foundMyGuest)) return false;
  const guestPermissions = foundMyGuest[0].location_access_level_profile.rules;

  if (isNilOrEmpty(guestPermissions)) return false;
  const parsedPermissions = JSON.parse(guestPermissions);

  return parsedPermissions.reviewsPermission;
};

export default isEllebigleLocationForGuest;
