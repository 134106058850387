import styled, { css } from 'styled-components';

export const StyledSectionTitle = styled.h3`
  font-weight: 700;
  font-size: 1.5rem;
  margin-bottom: 2rem;
  display: flex;
  align-items: center;

  .is-loading-infos {
    margin-right: 1rem;
  }
`;

export const StyledLocationProfile = styled.div <{ hasNoContent: boolean }>`
  display: flex;
  flex-direction: column;
  /* padding-bottom: 4rem; */
  position: relative;
  height: auto;

  ${props => props.hasNoContent && css`
    height: 100vh;
  `}
 
  .location-form,
  .general-information-form,
  .location-responsibles,
  .progress-info {
    margin-bottom: 3rem;
  }

  .save-button {
    max-width: 15rem;
    width: 100%;
    margin-right: 2rem;
    margin-top: 4rem;
    margin-left: auto;
  }

  .group-empty-or-company {
    margin: 2rem;
  }
`;
