import * as R from 'ramda';

import { TCompanyEntity } from '../../types/TCompany';

export const isEmptyData = (data: any) => R.or(R.isNil(data), R.isEmpty(data));

export const getActiveCompanyId = (activeCompany: any) => {
  if (isEmptyData(activeCompany)) return [];

  return activeCompany?.length > 1 ? activeCompany[0].id : activeCompany.id;
};

export const hasActiveCompany = (activeCompany: TCompanyEntity) => {
  if (R.has('company', activeCompany)) return false;
  if (isEmptyData(activeCompany)) return false;
  if (R.is(Array, activeCompany)) return false;

  return true;
};

export const activeCompanyIsArray = (activeCompany: any) => R.is(Array, activeCompany);
