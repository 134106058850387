import styled from 'styled-components';
import { IconButton } from '@material-ui/core';
import { shade, lighten } from 'polished';
import Button from '../../../button';

export const StyledGuest = styled.li`
  width: 100%;
  justify-content: space-between;
  align-items: center;
  position: relative;
  margin-bottom: 1rem;
  padding-bottom: 1rem;
  border-bottom: 0.1rem solid ${props => shade(0.1, props.theme.colors.white)};
  
  &:last-child {
    margin-bottom: 0;
    padding-bottom: 0;
    border-bottom: none;
  }
  
  h4 {
    font-size: 1.4rem;
    margin-bottom: 0;
  }
  
  .linear-progress {
    position: absolute;
    bottom: 0;
    left: 0;
  }
  
  .profile-header {
    width: 2rem;
    height: 2rem;
    margin-right: 1rem;
  }

  .select-profile {
    width: 100%;
    margin-right: 1rem;
  }

  .button {
    width: 100%;
    
  }
`;

export const StyledTitle = styled.div`
  display: flex;
  flex-direction: column;
  
  .header-platform-icon {
    height: 2rem;
    width: 2rem;
    margin-right: 1rem;
  }

  h4 {
    display: flex;
    align-items: center;
  }
`;

export const StyledContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;

  .header-platform-icon {
    height: 2rem;
    width: 2rem;
    margin-right: 1rem;
  }

  .name {
    font-size: 1.4rem;
  }

  small {
    color: ${props => props.theme.colors.primary};
    font-size: inherit;
  }

  span {
    margin: 0;
    font-size: 1.2rem;
  }
`;

export const StyledHeaderAction = styled(IconButton)`
  padding: 0.5rem;
  margin-left: 1rem;

  &:not(.no-event):hover {
    background-color: ${props => props.theme.colors.primary};

    .MuiSvgIcon-root {
      fill: ${props => props.theme.colors.white};
    }
  }

  &.no-event:hover {
    background: none;
    cursor: default;
  }

  &:focus {
    outline: none;
  }

  .MuiSvgIcon-root {
    width: 2rem;
    height: 2rem;
  }
`;

export const StyledGuestActionsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const StyledGuestEditFieldsWrapper = styled.div`
  width: 100%;
  padding: 2rem;
  margin-top: 1rem;
  background: ${props => lighten(0.95, props.theme.colors.black)};

  .access-profile-edit-loading {
    margin-right: 1rem;
  }
`;

export const StyledInfoWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const StyledContentOutsideWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const StyledLoadingWrapper = styled.div`
  padding: 1rem;
  align-items: center;
`;
