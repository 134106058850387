import axios from 'axios';

export default async function getCompanyInfoModels(accessToken, id, reduxAuth) {
  const requestOptions = {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  };

  try {
    const { data } = await axios.get(
      `${process.env.REACT_APP_COMPANIES_API_URL}/get-relation/info_models/${id}`,
      requestOptions,
    );

    return data;
  } catch (err) {
    if (err.response?.status === 401 && reduxAuth) {
      const {
        AuthActions,
        reduxDispatch,
        userData,
      } = reduxAuth;

      reduxDispatch(AuthActions.setUser({ ...userData, accessToken: 'expired' }));
    }

    return err;
  }
}
