import React from 'react';
import * as R from 'ramda';

import getAllLocationInvites from '../../../services/invites/getAllLocationInvites';
import getAllLocationGuests from '../../../services/guest/getAllLocationGuests';
import getAllUserLocationAccessLevelProfiles from '../../../services/locationAccessLevelProfiles/getByUser';
import APICreateInvite from '../../../services/invites/create';

import { useAuth } from '../../../hooks';

import {
  ProgressInfo,
  ManualSyncPlatforms,
  GuestList,
  Select,
  Button,
} from '../../../components';

import {
  DataStudioDialog,
} from './data-studio-dialog';

import getLocationCompleteness from '../../../utils/getLocationCompleteness';

import {
  StyledContenSide,
  StyledContractListWrapper,
  StyledDataStudioWrapper,
} from '../location-profile-styles';

const Side = ({
  activeLocation,
  handleSync,
  syncStatusData,
  isSync,
  fieldsToBeFilled,
  contractList,
  selectedContract,
  setSelectedContract,
  contractListLoading,
  onLastSyncDialogOpen,
  latestLocationRequests,
}) => {
  const {
    userAccessToken,
    userId,
    userProfileName,
    userSetTokenLikeExpired,
  } = useAuth();

  const [locationAccessLevelProfiles, setLocationAccessLevelProfiles] = React.useState([]);

  const [isDataStudioDialogOpen, setIsDataStudioDialogOpen] = React.useState(false);

  const getLocationAccessLevelProfiles = React.useCallback(async () => {
    const locationAccessLevelProfilesResponse = await getAllUserLocationAccessLevelProfiles(
      {
        accessToken: userAccessToken,
        userId,
        page: 0,
        pageSize: 20,
        query: '',
      },
    );

    if (R.isNil(locationAccessLevelProfilesResponse)) return;

    const [locationAccessLevelProfilesData] = locationAccessLevelProfilesResponse;

    setLocationAccessLevelProfiles(locationAccessLevelProfilesData);
  }, [activeLocation]);

  React.useEffect(() => { getLocationAccessLevelProfiles(); }, [getLocationAccessLevelProfiles]);

  return (
    <StyledContenSide>
      <ManualSyncPlatforms
        isSync={isSync}
        ticketStatusData={syncStatusData || null}
        onSyncClicked={handleSync}
        lastSyncHistory={latestLocationRequests}
        onLastSyncDialogOpen={onLastSyncDialogOpen}
      />

      <GuestList
        userAccessToken={userAccessToken}
        userSetTokenLikeExpired={userSetTokenLikeExpired}
        targetParamToGetPendingInvites="locationId"
        activeRegisterIdToGetData={activeLocation.id}
        APIGetPendingInvites={getAllLocationInvites}
        APIGetAllGuest={getAllLocationGuests}
        guestAccessLevelProfiles={locationAccessLevelProfiles}
        targetParamToGetGuests="locationId"
        userId={userId}
        APICreateInvite={APICreateInvite}
        targetParamToCreateInvites="location_id"
        isLocationPage
      />

      {userProfileName !== 'Usuário Padrão' && (
        <StyledContractListWrapper>
          <Select
            disabled={contractListLoading}
            className="select-profile"
            label="Tipo de Contrato"
            value={selectedContract}
            onChange={({ target }) => setSelectedContract(target.value)}
            border
            options={contractList}
          />
        </StyledContractListWrapper>
      )}

      {userProfileName !== 'Usuário Padrão' && (
        <StyledDataStudioWrapper>
          <h3>Link do Data Studio</h3>
          {activeLocation?.data_studio && (
            <a href={activeLocation?.data_studio} target="_blank" rel="noreferrer">
              {activeLocation?.data_studio}
            </a>
          )}
          <Button
            className="button"
            onClick={() => {
              setIsDataStudioDialogOpen(true);
            }}
          >
            {activeLocation?.data_studio ? 'Editar' : 'Adicionar'}
          </Button>
        </StyledDataStudioWrapper>
      )}

      <ProgressInfo
        className="progress-info"
        progress={getLocationCompleteness(activeLocation)[0]}
        isMissing={getLocationCompleteness(activeLocation)[1]}
        fieldsToBeFilled={fieldsToBeFilled}
      />

      {isDataStudioDialogOpen && (
        <DataStudioDialog
          initialValue={activeLocation?.data_studio}
          open={isDataStudioDialogOpen}
          onClose={() => { setIsDataStudioDialogOpen(false); }}
          locationId={activeLocation?.id}
        />
      )}
    </StyledContenSide>
  );
};

export default Side;
