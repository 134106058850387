import { format } from 'date-fns';
import { MUIDataTableColumn } from 'mui-datatables';
import * as R from 'ramda';
import type { TServiceBatch } from '../../types/TServiceBatch';
import type {
  TGetDataRowCellValue,
  TTableRowData,
  TUpdateRowDataByColumn,
} from '../../types/TTable';

export type TServiceBatchRowColumnsName =
  | 'id'
  | 'service-name'
  | 'service-price'
  | 'service-data'
  | 'updated-at'
  | 'created-at'
  | 'status'
  | 'actions';

export type TRenderCellDataServiceBatchRow = Record<
  TServiceBatchRowColumnsName,
  (value: any) => any
>;

export const tableColumns: MUIDataTableColumn[] = [
  {
    label: 'Expandir',
    name: 'expand',
  },
  {
    label: 'Serviço',
    name: 'service-name',
  },
  {
    label: 'Preço',
    name: 'service-price',
  },
  {
    label: 'Última Atualização',
    name: 'updated-at',
  },
  {
    label: 'Data de Criação',
    name: 'created-at',
  },
  {
    label: 'Status',
    name: 'status',
  },
  {
    label: 'Ações',
    name: 'actions',
  },
];

export const getDataRowCellValue = ({
  dataRowCell,
  columnRef,
}: TGetDataRowCellValue) => {
  const currentRowDataCell = dataRowCell.find(
    (rowDataCell: any) => rowDataCell && rowDataCell.columnRef === columnRef
  );

  return !R.isNil(currentRowDataCell) ? currentRowDataCell.value : '';
};

export const updateRowDataByColumn = ({
  rowData,
  payload,
}: TUpdateRowDataByColumn): any[] =>
  rowData.map((cellData: any) => {
    if (!R.isNil(cellData) && R.has(cellData.columnRef, payload)) {
      return {
        ...cellData,
        value: R.prop(cellData.columnRef, payload),
      };
    }

    return cellData;
  });

export const constants = {
  FETCH_STATUS_SETINTERVAL: 300000,
  LOCATION_FETCH_STATUS_SETINTERVAL: 150000,
};

export const normalizeServiceBatches = (
  serviceBatches: TServiceBatch[]
): TTableRowData[] => {
  return serviceBatches.map((serviceBatch): TTableRowData => {
    return [
      { columnRef: 'id', visible: false, value: serviceBatch.id },
      {
        columnRef: 'service-name',
        visible: true,
        value: serviceBatch.service_item_name,
      },
      {
        columnRef: 'service-data',
        visible: false,
        value: serviceBatch.service_item_info,
      },
      {
        columnRef: 'service-price',
        visible: true,
        value: serviceBatch.service_item_info.price,
      },
      {
        columnRef: 'updated-at',
        visible: true,
        value: format(new Date(serviceBatch.updated_at), 'dd/MM/yyyy HH:mm:ss'),
      },
      {
        columnRef: 'created-at',
        visible: true,
        value: format(new Date(serviceBatch.created_at), 'dd/MM/yyyy HH:mm:ss'),
      },
      {
        columnRef: 'status',
        visible: true,
        value: serviceBatch.status,
      },
    ];
  });
};
