import axios from 'axios';

import { TGetCompanyLocations } from '../../types/TLocation';

export default async function getCompanyLocations({
  accessToken,
  companyId,
  page = 0,
  pageSize = 10,
  query = '',
  orderByName = null,
  orderByContract = null,
  filterByContract = null,
  setTokenLikeExpired,
  setIfFetching,
  isGuest = false,
  userId = null,
}: TGetCompanyLocations) {
  try {
    if (setIfFetching) setIfFetching(true);

    const { data } = await axios.get(
      `${process.env.REACT_APP_LOCATIONS_API_URL}/company-locations/${companyId}`,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
        params: {
          page,
          pageSize,
          query,
          orderByName,
          orderByContract,
          filterByContract,
          isGuest,
          userId,
        },
      },
    );

    return data;
  } catch (err: any) {
    if (err.response?.status === 401 && setTokenLikeExpired) return setTokenLikeExpired();

    return null;
  } finally {
    if (setIfFetching) setIfFetching(false);
  }
}
