import axios from 'axios';

export default async function addLocations(
  {
    accessToken,
    locationGroupId,
    locationIds,
    setTokenLikeExpired,
    feedbackMessage,
    isAddLocationToGroup,
  },
) {
  const requestOptions = {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  };

  try {
    if (isAddLocationToGroup) isAddLocationToGroup(true);
    if (feedbackMessage) feedbackMessage('Adicionando locais ao grupo...', { variant: 'warning' });

    const { data } = await axios.post(
      `${process.env.REACT_APP_LOCATION_GROUPS_API_URL}/location/add/${locationGroupId}`,
      { locationIds },
      requestOptions,
    );

    if (feedbackMessage) feedbackMessage('Locais adicionados com sucesso!', { variant: 'success' });

    return data;
  } catch (err) {
    if (err.response?.status === 401 && setTokenLikeExpired) setTokenLikeExpired();
    if (err.response?.status === 403) feedbackMessage('Falha ao vincular local: Você já alcançou o limite de locais disponíveis', { variant: 'error' });

    return null;
  } finally {
    if (isAddLocationToGroup) isAddLocationToGroup(false);
  }
}
