import { darken } from 'polished';
import styled from 'styled-components';

export const StyledList = styled.ul`
  list-style: none;
`;

export const StyledItem = styled.li`
  display: flex;
  align-items: center;
  padding: 1rem 1rem 1rem 0;

  &:nth-child(even) {
    background-color: ${props => darken(0.05, props.theme.colors.white)};
  }
`;

export const StyledSpan = styled.span`
  font-size: 1.4rem;
  font-weight: 600;
  flex: 1;
`;
