import styled from 'styled-components';
import { Paper } from '@material-ui/core';

import { devices } from '../../../../assets/styles/devices';

export const StyledOtherLinks = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
`;

export const StyledTitle = styled.h3`
  font-weight: 700;
  font-size: 1.5rem;
  margin-bottom: 2rem;
`;

export const StyledContent = styled(Paper)`
    display: grid;
    grid-template-columns: 1fr;
    row-gap: 2rem;
    width: 100%;
    padding: 3rem 2rem;

    @media ${devices.desktop} {
        grid-template-columns: repeat(2, 1fr);
        column-gap: 2rem;
    }
`;
