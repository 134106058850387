import React from 'react';
import { Create, Close, Delete } from '@material-ui/icons';
import * as R from 'ramda';

import APIDeleteGuest from '../../../../services/guest/delete';
import APIUpdateGuest from '../../../../services/guest/update';

import limitedText from '../../../../utils/limited-text';

import {
  Select,
  Button,
  Loading,
  OverlayLoading,
} from '../../..';

import {
  formatLocationAccessLevelProfiles,
} from '../../helpers';

import {
  StyledGuest,
  StyledHeaderAction,
  StyledGuestActionsWrapper,
  StyledContent,
  StyledInfoWrapper,
  StyledContentOutsideWrapper,
  StyledGuestEditFieldsWrapper,
  StyledLoadingWrapper,
} from './location-guest-style';

const LocationGuest = ({
  guestId,
  userName,
  userEmail,
  profileName,
  profileId,
  guestAccessLevelProfiles,
  userAccessToken,
  userSetTokenLikeExpired,
  getGuestsList,
}) => {
  const [userProfile, setUserProfile] = React.useState(profileId);
  const [isEditing, setIsEditing] = React.useState(false);
  const [isDeleting, setIsDeleting] = React.useState(false);
  const [locationGuestLoading, setLocationGuestLoading] = React.useState(false);

  const handleChangeProfile = (value) => {
    setUserProfile(value);
  };

  const handleToggleEditInviteForm = () => {
    setIsDeleting(false);
    setIsEditing(!isEditing);
  };

  const handleToggleDeleteInviteForm = () => {
    setIsEditing(false);
    setIsDeleting(!isDeleting);
  };

  const handleRemoveLocationGuest = React.useCallback(async () => {
    const deleteGuestResponse = await APIDeleteGuest({
      accessToken: userAccessToken,
      id: guestId,
      setTokenLikeExpired: userSetTokenLikeExpired,
      setIsFetching: setLocationGuestLoading,
    });

    if (R.isNil(deleteGuestResponse)) return;

    getGuestsList();
  }, [userAccessToken]);

  const handleUpdateLocationGuest = React.useCallback(async () => {
    if (userProfile === profileId) return;

    await APIUpdateGuest({
      accessToken: userAccessToken,
      guestId,
      locationAccessLevelProfileId: userProfile,
      setTokenLikeExpired: userSetTokenLikeExpired,
      setIsFetching: setLocationGuestLoading,
    });

    setIsEditing(false);

    getGuestsList();
  }, [userProfile]);

  return (
    <StyledGuest>
      <StyledContentOutsideWrapper>
        {locationGuestLoading && (
          <StyledLoadingWrapper>
            <OverlayLoading />
          </StyledLoadingWrapper>
        )}
        <StyledInfoWrapper>
          {isDeleting ? (
            <Button
              className="button"
              onClick={handleRemoveLocationGuest}
              type="submit"
            >
              Deletar
            </Button>
          ) : (
            <StyledContent>
              <h4 className="name">{userName}</h4>
              <span className="email">
                E-mail:
                {' '}
                <small>{limitedText(userEmail, 25)}</small>
              </span>
              <span className="profile">
                Perfil:
                {' '}
                <small>{limitedText(profileName, 20)}</small>
              </span>
            </StyledContent>
          )}
          <StyledGuestActionsWrapper>
            <StyledHeaderAction onClick={handleToggleEditInviteForm}>
              {isEditing ? <Close /> : <Create />}
            </StyledHeaderAction>
            <StyledHeaderAction onClick={handleToggleDeleteInviteForm}>
              {isDeleting ? <Close /> : <Delete />}
            </StyledHeaderAction>
          </StyledGuestActionsWrapper>
        </StyledInfoWrapper>
      </StyledContentOutsideWrapper>

      {isEditing && (
      <StyledGuestEditFieldsWrapper>
        {!R.isNil(guestAccessLevelProfiles) ? (
          <>
            <Select
              className="select-profile"
              label="Perfil"
              value={userProfile}
              onChange={({ target }) => handleChangeProfile(target.value)}
              border
              options={formatLocationAccessLevelProfiles(guestAccessLevelProfiles)}
            />

            <Button
              className="submit-edit-button"
              onClick={handleUpdateLocationGuest}
              type="submit"
              disabled={locationGuestLoading}
            >
              {locationGuestLoading && <Loading />}
              {' '}
              Atualizar Perfil
            </Button>
          </>
        ) : (
          <span>Nenhum Perfil Cadastrado</span>
        )}
      </StyledGuestEditFieldsWrapper>
      )}
    </StyledGuest>
  );
};

export default LocationGuest;
