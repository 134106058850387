import styled from 'styled-components';
import { Dialog } from '@material-ui/core';

export const StyledDialog = styled(Dialog)`

    .dialog-title{
        font-size: 2rem;
        font-family: Poppins;
    }
    .list-primary-text{
        font-size: 2rem;
    }
    .list-secondary-text{
        font-size: 1.8rem;
    }

    .input{
        margin: 1rem;
        width: 90%;
    }

    .button-div{
        margin: 1rem;
        justify-content: center;
        display: flex;
    }

    .container{
        margin: 1rem;
        display: flex;
        justify-content: center;
        width: 90%;
    }

    span {
        font-family: Poppins;
    }
`;
