import styled from 'styled-components';
import { shade } from 'polished';
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from '@material-ui/core';

export const StyledAccordion = styled(Accordion)`
  width: 100%;
`;

export const StyledAccordionSummary = styled(AccordionSummary)`
  background: ${(props) => shade(0.15, props.theme.colors.white)};
  border-radius: 0.5rem;
  width: 100%;

  .Mui-expanded {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    min-height: 4.8rem;
    margin: 0;
    align-items: center;
  }
`;

export const StyledAccordionDetails = styled(AccordionDetails)`
  background: ${(props) => shade(0.15, props.theme.colors.white)};
  border-bottom-left-radius: 0.5rem;
  border-bottom-right-radius: 0.5rem;

  code {
    white-space: pre-wrap;
    color: ${props => props.theme.colors.text};
    word-break: break-word;
  }
`;
