import { MUIDataTableColumn } from 'mui-datatables';
import { TTableMockData } from '../../../types/TMonitor';

const mockData: TTableMockData[] = [
  {
    id: 0,
    platform: 'Google',
    company: 'Lorem Ipsum dolor sit amet, consectetur adipiscing elit',
    location: 'Lorem Ipsum dolor sit amet, Fortaleza, Ceará | CEP: 00000-000',
    created_at: '00/00/0000 ás 00:00',
    status: 'VERIFIED',
  },
  {
    id: 1,
    platform: 'Facebook',
    company: 'Lorem Ipsum dolor sit amet, consectetur adipiscing elit',
    location: 'Lorem Ipsum dolor sit amet, Fortaleza, Ceará | CEP: 00000-000',
    created_at: '00/00/0000 ás 00:00',
    status: 'ADJUSTED',
  },
  {
    id: 2,
    platform: 'Yelp',
    company: 'Lorem Ipsum dolor sit amet, consectetur adipiscing elit',
    location: 'Lorem Ipsum dolor sit amet, Fortaleza, Ceará | CEP: 00000-000',
    created_at: '00/00/0000 ás 00:00',
    status: 'ERROR',
  },
  {
    id: 3,
    platform: 'Linkedin',
    company: 'Lorem Ipsum dolor sit amet, consectetur adipiscing elit',
    location: 'Lorem Ipsum dolor sit amet, Fortaleza, Ceará | CEP: 00000-000',
    created_at: '00/00/0000 ás 00:00',
    status: 'DOES_NOT_EXIST',
  },
  {
    id: 4,
    platform: 'Foursquare',
    company: 'Lorem Ipsum dolor sit amet, consectetur adipiscing elit',
    location: 'Lorem Ipsum dolor sit amet, Fortaleza, Ceará | CEP: 00000-000',
    created_at: '00/00/0000 ás 00:00',
    status: 'VERIFIED',
  },
];

export const TableDataPlatformMock = mockData.map((platformItem) => [
  {
    columnRef: 'id',
    visible: false,
    value: platformItem.id,
  },
  {
    columnRef: 'platform',
    visible: true,
    value: platformItem.platform,
  },
  {
    columnRef: 'company',
    visible: true,
    value: platformItem.company,
  },
  {
    columnRef: 'location',
    visible: true,
    value: platformItem.location,
  },
  {
    columnRef: 'created_at',
    visible: true,
    value: platformItem.created_at,
  },
  {
    columnRef: 'status',
    visible: true,
    value: platformItem.status,
  },
  {
    columnRef: 'operations',
    visible: true,
    value: '',
  },
]);

export const tableColumns: MUIDataTableColumn[] = [
  {
    label: 'ID',
    name: 'id',
    options: {
      display: false,
      sort: false,
    },
  },
  {
    label: 'Plataforma',
    name: 'platform',
    options: {
      sort: false,
    },
  },
  {
    label: 'Empresa',
    name: 'company',
    options: {
      sort: false,
    },
  },
  {
    label: 'Local',
    name: 'location',
    options: {
      sort: false,
    },
  },
  {
    label: 'Data',
    name: 'date',
    options: {
      sort: false,
    },
  },
  {
    label: 'Status',
    name: 'status',
    options: {
      sort: false,
    },
  },
  {
    label: 'Operações',
    name: 'operations',
    options: {
      sort: false,
    },
  },
];
