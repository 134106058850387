import styled, { css } from 'styled-components';
import { IconButton } from '@material-ui/core';
import { shade } from 'polished';
import { devices } from '../../assets/styles/devices';

export const StyledPagination = styled.div`
    background: ${props => props.theme.colors.white};
    padding: 1rem 2rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 0.2rem;
  
  .inner-google-pagination-loading {
    margin: 1rem auto;
  }
`;

export const StyledPaginationButton = styled.div<{
  isLabelButton: boolean,
  disabled: boolean
}>`
  display: flex;
  align-items: center;
  cursor: pointer;
  transition: 0.3s;
  border-radius: 0.5rem;

  ${props => props.isLabelButton && css`
    color: ${props.theme.colors.primary};
    font-size: 1.3rem;
    padding: 0.5rem 2rem;

    .icon-pagination {
      margin-left: 0.5rem;
    }

    &:hover {
      background: ${shade(0.25, props.theme.colors.white)};
    }

    ${props.disabled && css`
      pointer-events: none;
      color: ${shade(0.2, props.theme.colors.white)};
      
      svg.icon {
        fill: ${shade(0.2, props.theme.colors.white)} !important;
      }
    `}
  `}
  
  .icon-pagination {
    width: 2rem;
    height: 2rem;
  }

  .icon-pagination-back {
    margin-right: 0;
  }
`;

export const StyledIconButton = styled(IconButton)``;

export const StyledPageIndex = styled.div`
  display: flex;
  align-items: center;
`;

export const StyledPageText = styled.p`
  margin-bottom: 0;
  font-size: 1.3rem;
  font-weight: 500;
  color: ${props => props.theme.colors.grey};
`;

export const StyledPageButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;

  @media ${devices.tablet} {
    flex-direction: row;
  }
`;
