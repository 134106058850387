import React from 'react';

import { IconButton } from '../../../../components';

import { StyledContainer } from './infos-updated-icons-style';

type TInfosUpdatedIconsProps = {
  basicInfos: boolean;
  links: boolean;
  workingHours: boolean;
  specialDates: boolean;
}

const InfosUpdatedIcons = ({
  basicInfos,
  links,
  workingHours,
  specialDates,
}: TInfosUpdatedIconsProps) => (
  <StyledContainer>
    {basicInfos && (
      <IconButton
        tooltip="Informações básicas"
        icon="DescriptionOutlined"
        disabled={!basicInfos}
        forceUseDefaultCursor
      />
    )}

    {links && (
      <IconButton
        tooltip="Links"
        icon="LinkOutlined"
        disabled={!links}
        forceUseDefaultCursor
      />
    )}

    {workingHours && (
      <IconButton
        tooltip="Horários de funcionamento"
        icon="AccessTimeOutlined"
        disabled={!workingHours}
        forceUseDefaultCursor
      />
    )}

    {specialDates && (
      <IconButton
        tooltip="Datas especiais"
        icon="CalendarTodayOutlined"
        disabled={!specialDates}
        forceUseDefaultCursor
      />
    )}
  </StyledContainer>
);

export { InfosUpdatedIcons };
