import React from 'react';

import {
  StyledInstagram,
} from './instagram-styles';

const Instagram = () => (
  <StyledInstagram>
    Uber
  </StyledInstagram>
);

export default Instagram;
