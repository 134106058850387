import axios from 'axios';

export default async function searchForVenue(accessToken, latitude, longitude, name) {
  const requestOptions = {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  };

  try {
    const { data: { response } } = await axios.get(`${process.env.REACT_APP_FOURSQUARE_API_URL}?name=${name}&latitude=${latitude}&longitude=${longitude}`, requestOptions);

    return response;
  } catch (err) {
    return err.respoonse;
  }
}
