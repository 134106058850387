import { LocationConnectionStatusEnum } from '../../../../types/TPlatformsLinkWithLocation';
import {
  TIconByStatusPropsValue,
} from './types';

export const iconByStatus: Record<LocationConnectionStatusEnum, TIconByStatusPropsValue> = {
  VALID: {
    tooltip: 'Conexão ativa',
    label: 'Válida',
    icon: 'CheckCircle',
    isSuccess: true,
    tooltipVariant: 'green',
  },
  STANDBY: {
    tooltip: 'Conexão pausada',
    label: 'Pausada',
    icon: 'Warning',
    isWarning: true,
    tooltipVariant: 'yellow',
  },
  UNAUTHORIZED: {
    tooltip: 'Conexão não possui autorização necessária',
    label: 'Não autorizado',
    icon: 'Error',
    isError: true,
    tooltipVariant: 'red',
  },
  REFRESH_TOKEN_EXPIRED: {
    tooltip: 'Conexão expirou',
    label: 'Expirada',
    icon: 'Error',
    isWarning: true,
    tooltipVariant: 'yellow',
  },
};
