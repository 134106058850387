import React from 'react';
import { Redirect, Route } from 'react-router-dom';

import { ErrorBoundary } from '../../containers';

const PrivateRoute = ({
  isAuthenticated,
  component: Component,
  ...rest
}) => {

  return (
  <Route
    {...rest}
    render={props => (!isAuthenticated()
      ? (
        <ErrorBoundary>
          <Component {...props} />
        </ErrorBoundary>
      )
      : (
        <Redirect to={{
          pathname: '/company/report',
          state: {
            from: props.location,
            redirected: true,
          },
        }}
        />
      ))}
  />
)};

export default PrivateRoute;
