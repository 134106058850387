import styled from 'styled-components';
import { Popover } from '@material-ui/core';

export const StyledReplicatedPopover = styled(Popover)`
  .MuiPopover-paper {
    box-shadow: 
      0px 5px 5px -3px rgb(0 0 0 / 20%), 
      0px 8px 10px 1px rgb(0 0 0 / 14%), 
      0px 3px 14px 2px rgb(0 0 0 / 12%);  
  }

  .button-apply-replicated {
    margin: 0 auto 2rem;
  }
`;

export const StyledDayOptions = styled.ul`
  display: flex;
  flex-direction: column;
`;
