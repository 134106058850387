import React from 'react';
import { isNil } from 'ramda';
import CurrencyInputField from 'react-currency-input-field';

import {
  TCurrencyInput,
} from '../../types/TInput';

import {
  StyledCurrencyInput,
  StyledInputWrapper,
} from './currency-input-styles';

const CurrencyInput = ({
  id = '',
  inputOptions,
  label = '',
  className = '',
  placeholder = 'Digite um valor',
  value,
  disabled = false,
  integerLimit,
  decimalsLimit = 2,
  onValueChange,
  prefix = 'R$',
}: TCurrencyInput) => {
  const onChange = React.useCallback(
    (newValue: string | undefined) => {
      const integerPart = newValue?.split(',')[0] || '';
      if (!isNil(integerLimit) && integerPart.length > integerLimit) return;

      onValueChange(newValue);
    },
    [integerLimit]
  );

  return (
    <StyledCurrencyInput>
      {label && <label htmlFor={inputOptions?.name}>{label}</label>}

      <StyledInputWrapper
        disabled={disabled}
      >
        <CurrencyInputField
          id={id}
          value={value}
          className={className}
          placeholder={placeholder}
          disabled={disabled}
          decimalsLimit={decimalsLimit}
          onValueChange={onChange}
          prefix={prefix}
        />
      </StyledInputWrapper>
    </StyledCurrencyInput>
  );
};

export default CurrencyInput;
