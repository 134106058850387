import axios from 'axios';

export default async function addUserAccessibleLocationGroups({
  accessToken,
  userId,
  locationGroupId,
  setTokenLikeExpired,
}) {
  const requestOptions = {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  };

  try {
    const { data } = await axios.post(
      `${process.env.REACT_APP_AUTH_API_URL}/add-user-accessible-location-groups/${userId}`,
      {
        locationGroupId,
      },
      requestOptions,
    );

    return data;
  } catch (err) {
    if (err.response?.status === 401 && setTokenLikeExpired) return setTokenLikeExpired();

    return null;
  }
}
