import React from 'react';
import { debounce } from 'lodash';

import {
  BatchFilter, Loading,
} from '../../../components';

import type {
  TFiltersBatchPostHeader,
} from '../../../types/TLocationPost';

import {
  locationGooglePostsType,
} from '../helpers';

import {
  StyledFilterPostHeader,
  StyledContent,
  StyledHeader,
  StyledAddPostButton,
  StyledAddCircle,
} from './filters-post-header-styles';

const FiltersPostHeader = ({
  onAddPostClicked,
  selectedPostTypeFilter,
  setSelectedPostTypeFilter,
  setSelectedStatusFilter,
  selectedStatusFilter,
  ofPeriod,
  setOfPeriod,
  toPeriod,
  setToPeriod,
  disabledPostButton,
  loadingLocalsWithoutLinks,
}: TFiltersBatchPostHeader) => (
  <StyledFilterPostHeader>
    <StyledHeader>
      <StyledAddPostButton
        onClick={onAddPostClicked}
        disabled={disabledPostButton || loadingLocalsWithoutLinks}
      >
        {loadingLocalsWithoutLinks ? <Loading />
          : (
            <>
              <StyledAddCircle />

              Adicionar Postagem
            </>
          )}
      </StyledAddPostButton>
    </StyledHeader>
    <h2>Filtros</h2>
    <StyledContent>
      <BatchFilter
        typeLabel="Tipo de postagem"
        selectedTypeFilter={selectedPostTypeFilter}
        setSelectedTypeFilter={setSelectedPostTypeFilter}
        selectedMultipleStatusFilter={selectedStatusFilter}
        setSelectedMultipleStatusFilter={setSelectedStatusFilter}
        ofPeriod={ofPeriod}
        setOfPeriod={setOfPeriod}
        toPeriod={toPeriod}
        setToPeriod={setToPeriod}
        filterType={{
          type: 'UPDATE',
          updateTypeOptions: locationGooglePostsType,
        }}
      />
    </StyledContent>
  </StyledFilterPostHeader>
);

export default FiltersPostHeader;
