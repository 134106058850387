import React from 'react';
import {
  Search as SearchIcon,
} from '@material-ui/icons';
import { debounce } from 'lodash';

import filterSearch from '../../../../../../utils/filter-search';

import Header from './header';
import ListingItem from './listing-item';
import Select from '../../../../../select';

import {
  StyleListingDialog,
  StyledPagesListing,
  StyledSearch,
  StyledIsFetchingContainer,
} from './listing-dialog-styles';
import Loading from '../../../../../loading';

const ListingDialog = ({
  selectedGroup,
  groups,
  loadingCreate,
  allowCreate = false,
  open,
  handleClose,
  data = [],
  noOwnershipData = [],
  handleBindWithLocation,
  handleBindNoOwnershipPlaceWithLocation,
  handleChangeGoogleSearchInput,
  headerTitle,
  headerTitleCreateButton,
  handleHeaderCreateButton,
  image,
  keyToFilter,
  noOwnershipDataKeyToFilter,
  name,
  description,
  noImages = false,
  noOwnershipDataName,
  noOwnershipDataDescription,
  noOwnershipDataImage,
  isFetchingSearch,
  onChangeAccountGroup,
}) => {
  const [searchInput, setSearchInput] = React.useState('');

  const handleChangeInputSearch = debounce(text => handleChangeGoogleSearchInput(text), 500);

  return (
    <StyleListingDialog
      open={open}
      onClose={handleClose}
    >
      <Header
        loadingCreate={loadingCreate}
        allowCreate={allowCreate}
        headerTitle={headerTitle}
        createButtonTitle={headerTitleCreateButton}
        handleCreateTitle={handleHeaderCreateButton}
        hasItems={data.length > 0}
      />
      <Select
        value={selectedGroup}
        lightBorder
        label="Grupos"
        options={groups}
        onChange={onChangeAccountGroup}
      />
      <StyledSearch>
        <input
          type="text"
          placeholder="Pesquisar"
          onChange={e => handleChangeInputSearch(e.target.value)}
        />
        <div className="search-icon">
          <SearchIcon />
        </div>
      </StyledSearch>

      {isFetchingSearch
        ? (
          <StyledIsFetchingContainer>
            <Loading className="is-fetching-search" />
            Carregando...
          </StyledIsFetchingContainer>
        )
        : (
          <StyledPagesListing>
            {data.length > 0
              ? data.map(item => (
                <ListingItem
                  key={item.id}
                  id={item.id}
                  item={item}
                  name={item[name]}
                  image={item[image]}
                  description={item[description] || null}
                  handleSync={() => handleBindWithLocation(item)}
                  noImages={noImages}
                />
              )) : (
                <span>
                  Nenhum registro encontrado
                </span>
              )}
            {noOwnershipData.length > 0 && <span className="google-text">Encontramos os seguintes locais no Google baseados nas suas informações:</span> }
            {noOwnershipData.length > 0 && noOwnershipData.filter(item => filterSearch({ item, key: noOwnershipDataKeyToFilter, query: searchInput })).map(item => (
              <ListingItem
                key={item.id}
                id={item.id}
                item={item}
                name={item[noOwnershipDataName]}
                image={item[noOwnershipDataImage]}
                description={item[noOwnershipDataDescription] || null}
                handleSync={() => handleBindNoOwnershipPlaceWithLocation(item)}
                noImages={noImages}
              />
            )) }
          </StyledPagesListing>
        )}
    </StyleListingDialog>
  );
};

export default ListingDialog;
