import React from 'react';

import FilterOption from './filter-option';

import {
  StyledFilters,
  StyledFiltersListing,
} from './filter-styles';

const Filters = ({
  openAmount,
  inProgressAmount,
  doneAmount,
  filterValues,
  setFilterValues,
}) => (
  <StyledFilters>
    <h3>Filter</h3>
    <StyledFiltersListing>
      <FilterOption
        value={filterValues.open}
        setValue={() => setFilterValues({ ...filterValues, open: !filterValues.open })}
        filterOption="Aberto"
        quantity={openAmount}
      />
      <FilterOption
        value={filterValues.in_progress}
        setValue={() => setFilterValues({ ...filterValues, in_progress: !filterValues.in_progress })}
        filterOption="Em Progresso"
        quantity={inProgressAmount}
      />
      <FilterOption
        value={filterValues.done}
        setValue={() => setFilterValues({ ...filterValues, done: !filterValues.done })}
        filterOption="Concluído"
        quantity={doneAmount}
      />
    </StyledFiltersListing>
  </StyledFilters>
);

export default Filters;
