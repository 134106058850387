import axios from 'axios';
import { TDeleteAllConnectionsFromLocation } from '../../types/TPlatformsLinkWithLocation';

export default async function deleteAllConnectionsFromLocation({
  accessToken,
  locationId,
  setTokenLikeExpired,
}: TDeleteAllConnectionsFromLocation) {
  try {
    const { data } = await axios.delete(
      `${process.env.REACT_APP_LOCATION_CONNECTIONS}/location/${locationId}`,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      },
    );

    return data;
  } catch (err: any) {
    if (err.response?.status === 401 && setTokenLikeExpired) setTokenLikeExpired();

    return null;
  }
}
