import React from 'react';
import { getActionType } from '../helper';

import {
  StyledEventPostInfo,
  StyledMediaItems,
} from './event-post-info-styles';

type TEventPostInfo = {
  eventPostData: any;
  info: any
}

const EventPostInfo = ({ eventPostData, info = null }:TEventPostInfo) => {
  const [eventPostInfo, setEventPostInfo] = React.useState([]);
  const {
    topicType,
    mediaItems,
    schedule,
    schedule: {
      startDate, endDate, startTime, endTime,
    },
    summary,
    title,
    actionType,
    url,
  } = eventPostData;

  return (
    <StyledEventPostInfo>
      {info === 'topicType' && <p>Evento</p>}

      {info === 'title' && <p>{title}</p>}

      {info === 'mediaItems' && (
      <StyledMediaItems>
        {mediaItems.map((element:any) => <li><a href={element.sourceUrl}>{element.sourceUrl}</a></li>)}
      </StyledMediaItems>
      )}

      {info === 'schedule'
        && (
        <>
          <span>
            {`Data de início: ${startDate.day}/${startDate.month}/${startDate.year}`}
          </span>
          <span>{`Horário de início: ${startTime.hours}:${startTime.minutes}`}</span>
          <span>
            {`Data de término: ${endDate.day}/${endDate.month}/${endDate.year}`}
          </span>
          <span>{`Horário de término: ${endTime.hours}:${endTime.minutes}`}</span>
        </>
        )}

      {info === 'summary' && (
      <>
        <h5>{summary}</h5>
      </>
      )}

      {info === 'actionType' && <h5>{`Tipo: ${getActionType(actionType)}`}</h5>}
      {info === 'url' && <h5>{`Url: ${url}`}</h5>}

    </StyledEventPostInfo>
  );
};

export default EventPostInfo;
