import * as R from 'ramda';

import { TGetValidFacebookAccessToken } from '../../types/TConnections';

import getUserConnections from '../connections/getUserConnections';

export const refreshTokenInvalid = ({
  feedbackMessage,
  userProfile,
}: Pick<TGetValidFacebookAccessToken, 'feedbackMessage' | 'userProfile'>) => () => {
  if (R.isNil(feedbackMessage)) return;

  if (userProfile !== 'Usuário Padrão') {
    feedbackMessage('O Refresh token não é mais valido ou não existe!', {
      variant: 'warning',
    });

    return;
  }

  feedbackMessage(
    'Encontramos um problema no seu token de acesso às avaliações. Entre em contato com o suporte!',
    { variant: 'warning' },
  );
};

export default async function getValidFacebookAccessToken({
  userId,
  accessToken,
  setTokenLikeExpired,
  feedbackMessage = null,
  userProfile,
}: TGetValidFacebookAccessToken) {
  const feedbackMessageTokenInvalidByUser = refreshTokenInvalid({
    feedbackMessage,
    userProfile,
  });

  const getUserConnectionsResponse = await getUserConnections({
    accessToken,
    userId,
    setTokenLikeExpired,
    query: 'facebook',
  });

  if (R.isNil(getUserConnectionsResponse)) return null;

  const [data, amount] = getUserConnectionsResponse;

  if (amount === 0) {
    feedbackMessageTokenInvalidByUser();
    return null;
  }

  const {
    token_expires_in: expirationTime,
    token,
  } = data[0];

  const currentDate = new Date();
  const expirationDate = new Date(expirationTime);

  const isValid = currentDate < expirationDate;

  if (isValid) {
    return {
      token,
      expirationTime,
    };
  }

  return null;
}
