import React from 'react';

import ConfigCard from '../../../components/config-card';

import { StyledLocalAccessLevelProfiles } from './local-access-level-profiles-styles';

export default ({ navigation }) => (
  <ConfigCard
    title="Perfis de acesso a um local"
    onClick={() => navigation.push('/location-access-level-profiles')}
  >
    <StyledLocalAccessLevelProfiles>
      Perfis de acesso a um local
    </StyledLocalAccessLevelProfiles>
  </ConfigCard>
);
