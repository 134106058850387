import React from 'react';

import { isEmpty } from 'ramda';
import { StyledListItem, StyledListItemWrapper } from './sync-info-list-popover-styles';
import SyncStatusIcon from '../../../sync-status-icon';
import { fieldsName } from '../../helpers';
import { TSyncInfoListPopoverProps } from './types';

const SyncInfoListPopover = ({
  data,
}: TSyncInfoListPopoverProps) => {
  const displayItemValue = (value) => {
    if (isEmpty(value)) return 'Excluído';
    if (value === '__ ____-____' || value === '__ _____-____') return `${value} (Excluído)`;
    return value;
  };
  return (
    <>
      {
      data.sort(
        (firstArg, secondArg) => firstArg.order - secondArg.order,
      ).map((item, index) => (
        (
          <StyledListItemWrapper isLastOfSection={index + 1 === data.length}>
            <StyledListItem>
              <p className="title">{fieldsName[item.label.toLowerCase()].name}</p>
              <p>
                {displayItemValue(item.new_data.value)}
              </p>
            </StyledListItem>
            {item.status === 'APPROVED' ? (
              <SyncStatusIcon syncType="requests" status={item.status} />
            ) : (
              <SyncStatusIcon syncType="requests" status={item.status} />
            )}
          </StyledListItemWrapper>
        )
      ))
    }
    </>
  );
};

export default SyncInfoListPopover;
