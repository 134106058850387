import styled, { css } from 'styled-components';
import { Paper } from '@material-ui/core';
import { devices } from '../../../../assets/styles/devices';

export const StyledOpeningHours = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 3rem;
  position: relative;
`;

export const StyledHours = styled(Paper)`
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  overflow-x: hidden;
  
  @media ${devices.desktop} {
    grid-template-columns: repeat(2, 1fr);
  }
  @media ${devices.widescreen} {
    display: flex;
    flex-direction: column;
    margin-bottom: 0;
  }
`;
