export const emailRegExp = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const websiteRegExp = /^(https?:\/\/)?(www\.)?([a-zA-Z0-9]+(-?[a-zA-Z0-9])*\.)+[\w]{2,}(\/\S*)?$/;

export const phoneRegExp = /^\d{2}\s\d{4,5}-\d{4}$/;

export const zipCodeRegExp = /^\d{2}\.{0,1}\d{3}-\d{3}$/;

export const passwordRegExp = /((?=.*\d)|(?=.*\W+))(?![.\n])(?=.*[A-Z])(?=.*[a-z]).*$/;

export const numberRegExp = /^[0-9]+$/;

export const defaultDateFormatRegExp = /^\d{2}\/\d{2}\/\d{4}/;
