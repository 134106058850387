import React from 'react';

import TabBar from '../tabbar';
import Tab from '../tab';

import {
  StyledLocationInfoNavBar,
} from './location-info-nav-bar-styles';

const LocationInfoNavBar = ({ activeTab, setActiveTab, disabled }) => {
  const a11yProps = (index) => ({
    id: `full-width-tab-${index}`,
    'aria-controls': `full-width-tabpanel-${index}`,
    className: 'tabbar-tab',
  });

  return (
    <StyledLocationInfoNavBar>
      <TabBar
        handleChange={(_, newValue) => setActiveTab(newValue)}
        activeTab={activeTab}
      >
        <Tab label="Informações" disabled={disabled} isActive={activeTab === 0} {...a11yProps(0)} />
        <Tab label="Links" disabled={disabled} isActive={activeTab === 1} {...a11yProps(1)} />
        <Tab label="Horários de funcionamento" disabled={disabled} isActive={activeTab === 2} {...a11yProps(2)} />
      </TabBar>
    </StyledLocationInfoNavBar>
  );
};

export default LocationInfoNavBar;
