import axios from 'axios';

export default async function getAllCompanyInvites({
  accessToken,
  companyId,
  page = 0,
  pageSize = 10,
  query = '',
}) {
  try {
    const requestOptions = {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    };

    const { data } = await axios.get(
      `${process.env.REACT_APP_INVITES_API_URL}/company/${companyId}?page=${page}&pageSize=${pageSize}&query=${query}`,
      requestOptions,
    );

    return data;
  } catch (err) {
    return null;
  }
}
