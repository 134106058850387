import axios, { AxiosRequestConfig } from 'axios';
import type { TGoogleLocationCategoriesData } from '../../../types/TGoogle';

export type TGetLocation = {
  googleAccessToken: string;
  googleLocationId: string;
  readMask?: string | null;
};

export default async function getLocationCategories({
  googleAccessToken,
  googleLocationId,
}: TGetLocation): Promise<TGoogleLocationCategoriesData | null> {
  try {
    const requestOptions: AxiosRequestConfig = {
      headers: {
        'access-token': googleAccessToken,
      },
      params: {
        readMask: 'categories',
      },
    };

    const { data } = await axios.get(
      `${process.env.REACT_APP_GOOGLE_BUSINESS_PROFILE_API_URL}/location/${googleLocationId}`,
      requestOptions
    );

    return data?.categories || null;
  } catch (err: any) {
    return null;
  }
}
