import React from "react";

import { StyledTooltip, StyledTooltipTitleAsList } from "./tooltip-styles";

export default ({ children, title, placement, arrow, variant = "primary" }) => {
  const titleIsArray = React.useMemo(() => Array.isArray(title), []);
  return (
    <StyledTooltip
      title={
        titleIsArray ? (
          <StyledTooltipTitleAsList variant={variant} >
            {title.map((titleItem) => (
              <li key={titleItem}>{titleItem}</li>
            ))}
          </StyledTooltipTitleAsList>
        ) : (
          title
        )
      }
      placement={placement}
      arrow={arrow}
      variant={variant}
      enterTouchDelay={50}
    >
      {children}
    </StyledTooltip>
  );
};
