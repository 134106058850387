import Axios from 'axios';
import type { TServiceBatchLocation } from '../../../types/TServiceBatch';

export type TForceUpdateServiceBatchLocation = {
  userAccessToken: string;
  serviceBatchLocationId: string;
  setIsLoading?: React.Dispatch<React.SetStateAction<boolean>>;
};

type TSuccessResponse = {
  status: 'SUCCESS';
  data: TServiceBatchLocation;
};

type TErrorResponse = {
  status: 'ERROR';
  data: any;
};

export const forceUpdateServiceBatchLocation = async ({
  serviceBatchLocationId,
  userAccessToken,
  setIsLoading,
}: TForceUpdateServiceBatchLocation): Promise<
  TSuccessResponse | TErrorResponse
> => {
  try {
    if (setIsLoading) setIsLoading(true);

    const { data } = await Axios.post(
      `${process.env.REACT_APP_SAAS_BATCH_GOOGLE_MODULES}/service-batch-locations/manual-update/${serviceBatchLocationId}`,
      undefined,
      {
        headers: {
          Authorization: `Bearer ${userAccessToken}`,
        },
      }
    );
    return {
      status: 'SUCCESS',
      data,
    };
  } catch (err: any) {
    return {
      status: 'ERROR',
      data: err?.response?.data,
    };
  } finally {
    if (setIsLoading) setIsLoading(false);
  }
};
