import React from 'react';

import ConfigCard from '../../../components/config-card';

import {
  StyledGroups,
} from './groups-styles';

const HandlerList = ({ navigation }) => (
  <ConfigCard
    title="Grupos"
    onClick={() => navigation.push('/groups/list')}
  >
    <StyledGroups>
      Grupos
    </StyledGroups>
  </ConfigCard>
);

export default HandlerList;
