import React, { useCallback, useState } from 'react';

import { MUIDataTableOptions } from 'mui-datatables';
import LastSyncFilter from './last-sync-filter';
import { Table } from '../../../components';

import { StyledContainer, StyledInfo, StyledContent } from './last-sync-styles';
import { tableColumns } from './helpers';
import LastSyncRow from './last-sync-row';
import SyncInfoDialog from '../../../components/sync-info-dialog';
import { TPlatformSyncRequestsEntity } from '../../../services/platform-sync-modules/types';
import { getAllRequestsByLocation } from '../../../services/platform-sync-modules/get-all-requests-by-location';
import { useAuth } from '../../../hooks';
import isNilOrEmpty from '../../../utils/is-null-or-empty';

const LastSync = ({ locationId }: any) => {
  const { userAccessToken } = useAuth();

  const [selectedTypeFilter, setSelectedTypeFilter] = useState<string[]>([]);
  const [selectedStatusFilter, setSelectedStatusFilter] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [requestsCount, setRequestsCount] = useState(0);
  const [tableData, setTableData] = useState<any[][]>([]);
  const [ofPeriod, setOfPeriod] = useState<Date | null>(null);
  const [toPeriod, setToPeriod] = useState<Date | null>(null);
  const [isLastSyncDialogOpen, setIsLastSyncDialogOpen] = useState(false);
  const [selectedRow, setSelectedRow] = useState('');

  const onLastSyncDialogClose = useCallback(() => {
    setIsLastSyncDialogOpen(false);
    setSelectedRow('');
  }, []);

  const onLastSyncDialogOpen = useCallback((rowId: string) => {
    setIsLastSyncDialogOpen(true);
    setSelectedRow(rowId);
  }, []);

  const tableOptions = React.useMemo(
    (): MUIDataTableOptions => ({
      count: requestsCount,
      search: false,
      page,
      selectableRows: 'none',
    }),
    [requestsCount, page],
  );

  const categories = ['BASIC_INFOS', 'LINKS', 'WORKING_HOURS', 'SPECIAL_DATES'];

  const checkIfRequestHasAnyFieldWithCategory = (syncFieldData, categoryIndex: number) => syncFieldData.some((field) => field.category === categories[categoryIndex]);

  const createArrayOfUpdatedInfoColumn = (syncRequest: TPlatformSyncRequestsEntity) => [0, 1, 2, 3]
    .map((index) => checkIfRequestHasAnyFieldWithCategory(syncRequest.platformSyncFieldData, index));

  const formattedTableData = (data: TPlatformSyncRequestsEntity[]) => data.map((syncRequest: TPlatformSyncRequestsEntity) => [
    { columnRef: 'id', value: syncRequest.id, visible: false },
    {
      columnRef: 'updated_info',
      value: createArrayOfUpdatedInfoColumn(syncRequest),
      visible: true,
    },
    { columnRef: 'created_at', value: syncRequest.date_created, visible: true },
    { columnRef: 'status', value: syncRequest.status, visible: true },
    { columnRef: 'operations', value: syncRequest.id, visible: true },
  ]);

  const formattedDate = useCallback((date: Date | null) => (date
    ? new Date(date).toISOString().split('T').toString()
    : ''),
  []);

  const getRequestsData = useCallback(async () => {
    let requestBody: any = {
      locationId,
      userAccessToken,
      page,
      pageSize,
      status: selectedStatusFilter,
      ofPeriod: formattedDate(ofPeriod),
      toPeriod: formattedDate(toPeriod),
      isFetching: setIsLoading,
      withFields: true,
    };

    if (!isNilOrEmpty(selectedTypeFilter)) {
      requestBody = {
        ...requestBody,
        hasTypes: JSON.stringify(selectedTypeFilter),
      };
    }
    const syncRequestsResponse = await getAllRequestsByLocation(requestBody);

    if (!syncRequestsResponse) return;

    const newTableData = formattedTableData(syncRequestsResponse[0]);

    setTableData(newTableData);
    setRequestsCount(syncRequestsResponse[1]);
  }, [
    page,
    pageSize,
    locationId,
    selectedStatusFilter,
    ofPeriod,
    toPeriod,
    selectedTypeFilter,
  ]);

  React.useEffect(() => {
    getRequestsData();
  }, [getRequestsData]);

  return (
    <StyledContainer>
      <StyledInfo>
        <LastSyncFilter
          ofPeriod={ofPeriod}
          selectedStatusFilter={selectedStatusFilter}
          selectedTypeFilter={selectedTypeFilter}
          setOfPeriod={setOfPeriod}
          setSelectedStatusFilter={setSelectedStatusFilter}
          setSelectedTypeFilter={setSelectedTypeFilter}
          setToPeriod={setToPeriod}
          statusLabel="Status da atualização"
          toPeriod={toPeriod}
          typeLabel="Tipo de atualização"
        />
      </StyledInfo>
      <StyledContent>
        <Table
          loading={isLoading}
          title="Últimas sincronizações"
          setPage={setPage}
          setPageSize={setPageSize}
          columns={tableColumns}
          data={tableData}
          row={(rowData, rowDataIndex, rowIndex) => (
            <LastSyncRow
              rowData={rowData}
              rowId={rowData[0]?.value}
              dataTableOptions={tableOptions}
              onLastSyncDialogOpen={onLastSyncDialogOpen}
            />
          )}
          options={tableOptions}
        />
      </StyledContent>
      {isLastSyncDialogOpen && (
      <SyncInfoDialog
        isDialogOpen={isLastSyncDialogOpen}
        onClose={onLastSyncDialogClose}
        syncDialogId={selectedRow}
      />
      )}
    </StyledContainer>
  );
};

export default LastSync;
