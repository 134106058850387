import axios, { AxiosRequestConfig } from 'axios';
import { TGetAllRequestsResponse, TGetAllFieldsByRequestProps } from './types';

export const getAllFieldsByRequest = async ({
  requestId,
  userAccessToken,
  isFetching,
}: TGetAllFieldsByRequestProps): Promise<TGetAllRequestsResponse> => {
  try {
    if (isFetching) isFetching(true);
    const apiPath = `${process.env.REACT_APP_SYNC_PLATFORM_REQUEST_FIELDS}/request-fields/${requestId}`;

    const requestOptions: AxiosRequestConfig = {
      headers: {
        Authorization: `Bearer ${userAccessToken}`,
      },
      params: {
        requestId,
      },
    };

    const { data } = await axios.get(apiPath, requestOptions);

    return data;
  } catch (err) {
    return null;
  } finally {
    if (isFetching) isFetching(false);
  }
};
