import React from 'react';
import { useSelector } from 'react-redux';
import * as R from 'ramda';
import { debounce } from 'lodash';

import {
  IconButton,
  Select,
  Loading,
  Checklist,
} from '../../../components';

import { useAuth } from '../../../hooks';

import { TCreateBatchPostDialogBaseProps } from '../../../types/TCreatePostDialog';
import { TSelectPlatform } from '../../../types/TPlatforms';
import { TLocationPostGooglePostType } from '../../../types/TLocationPost';
import { EGroupType, TGetLocationsWithoutLinkedResponse } from '../../../types/TLocation';

import {
  GooglePostFormCovid19,
  GooglePostFormNews,
  GooglePostFormOffer,
  GooglePostFormEvent,
} from '../google-posts-form';

import {
  locationPostPlatformsMocked,
} from '../../../utils/select-platforms-mocked';

import APIGetCompanyLocations from '../../../services/locations/getCompanyLocations';
import APIGetAllGroupLocations from '../../../services/locations/getAllGroupLocation';
import { getLocationsWithoutLinked } from '../../../services/locations/get-locations-without-linked';
import { getOneLocalPost as APIGetOneLocalPost } from '../../../services/google-batch-modules';

import {
  createLocationGooglePostsType,
  getTopicType,
} from '../helpers';

import type { TRootStateRedux } from '../../../types/TRootStateRedux';
import type { TChecklistType } from '../../../types/TChecklist';

import {
  StyledCreatePostDialog,
  StyledHeader,
  StyledFooter,
  StyledOptionsPost,
  StyledFormContent,
  StyledPostWrapper,
  StyledPostAndFilterWrapper,
  StyledHasLocationsWithoutLinked,
  StyledErrorLocationList,
  StyledErrorIcon,
  StyledRow,
  StyledConfirmButton,
} from './create-post-dialog-styles';

const CreatePostDialog = ({
  open = false,
  setOpen,
  postDialogLoading,
  postIdToVisualize = null,
  infoToVisualize = null,
  setInfoToVisualize,
  getLocalPosts,
  loadingLocalsWithoutLinks,
  setLoadingLocalsWithoutLinks,
}: TCreateBatchPostDialogBaseProps) => {
  const {
    userAccessToken,
  } = useAuth();

  const { activeLocationGroupId, showLocationsByGroup } = useSelector((state: TRootStateRedux) => state.LocationGroupReducer);
  const { activeCompanyId, showLocationsByCompany } = useSelector((state: TRootStateRedux) => state.CompanyReducer);

  const [currentSelectedPlatform, setCurrentSelectedPlatform] = React.useState<string>('Google');
  const [currentSelectedPostType, setCurrentSelectedPostType] = React.useState<string>('');

  const [locationsList, setLocationsList] = React.useState<any[]>([]);
  const [locationsListLength, setLocationsListLength] = React.useState(0);
  const [locationsListPageIndex, setLocationsListPageIndex] = React.useState(0);
  const [locationsListPageSize, setLocationsListPageSize] = React.useState(10);
  const [locationsListPageQuantity, setLocationsListPageQuantity] = React.useState(0);
  const [locationsListQuery, setLocationsListQuery] = React.useState('');
  const [locationsToExclude, setLocationsToExclude] = React.useState<TChecklistType>({
    type: 'EXCLUDED',
    ids: [],
  });
  const [masterCheckbox, setMasterCheckbox] = React.useState(true);
  const [locationsWithoutLinkedList, setLocationsWithoutLinkedList] = React.useState<TGetLocationsWithoutLinkedResponse>(null);
  const [hasLocationsWithoutLinked, setHasLocationsWithoutLinked] = React.useState(false);

  const handleCloseModal = React.useCallback(() => {
    setCurrentSelectedPostType('');
    setOpen(false);
  }, [currentSelectedPostType]);

  const handleOnCloseModal = () => {
    setCurrentSelectedPostType('');
    setOpen(false);
  };

  const renderPostFormType = React.useCallback((currentPostType: string): React.ReactComponentElement<any> => {
    if (currentPostType === 'Atualizações da COVID-19') {
      return (
        <GooglePostFormCovid19
          closeModal={handleCloseModal}
          dataToUpdate={infoToVisualize}
          affectedLocations={locationsToExclude}
          getLocalPosts={getLocalPosts}
        />
      );
    }
    if (currentPostType === 'Novidades') {
      return (
        <GooglePostFormNews
          closeModal={handleCloseModal}
          dataToUpdate={infoToVisualize}
          affectedLocations={locationsToExclude}
          getLocalPosts={getLocalPosts}
        />
      );
    }
    if (currentPostType === 'Oferta') {
      return (
        <GooglePostFormOffer
          closeModal={handleCloseModal}
          dataToUpdate={infoToVisualize}
          affectedLocations={locationsToExclude}
          getLocalPosts={getLocalPosts}
        />
      );
    }
    if (currentPostType === 'Evento') {
      return (
        <GooglePostFormEvent
          closeModal={handleCloseModal}
          dataToUpdate={infoToVisualize}
          affectedLocations={locationsToExclude}
          getLocalPosts={getLocalPosts}
        />
      );
    }
    return <span>Nenhum tipo de post selecionado!</span>;
  },
  [infoToVisualize, locationsToExclude]);

  const getActiveCompanyOrGroupLocations = React.useCallback(async () => {
    if (!R.isNil(postIdToVisualize)) return;

    if (showLocationsByCompany) {
      const getActiveCompanyLocationsResponse = await APIGetCompanyLocations({
        accessToken: userAccessToken,
        companyId: activeCompanyId,
        query: locationsListQuery,
        page: locationsListPageIndex,
      });

      if (R.isNil(getActiveCompanyLocationsResponse)) return;
      const [companyLocationsData, companyLocationsCount] = getActiveCompanyLocationsResponse;

      setLocationsList(companyLocationsData);
      setLocationsListLength(companyLocationsCount);
      setLocationsListPageQuantity(Math.ceil(companyLocationsCount / locationsListPageSize));
    }

    if (showLocationsByGroup) {
      const getActiveGroupLocationsResponse = await APIGetAllGroupLocations({
        accessToken: userAccessToken,
        locationGroupId: activeLocationGroupId,
        page: locationsListPageIndex,
        query: locationsListQuery,
      });

      if (R.isNil(getActiveGroupLocationsResponse)) return;
      const [groupLocationsData, groupLocationsCount] = getActiveGroupLocationsResponse;

      setLocationsList(groupLocationsData);
      setLocationsListLength(groupLocationsCount);
      setLocationsListPageQuantity(Math.ceil(groupLocationsCount / locationsListPageSize));
    }
  }, [
    showLocationsByGroup,
    showLocationsByCompany,
    userAccessToken,
    activeCompanyId,
    activeLocationGroupId,
    locationsListPageIndex,
    locationsListPageQuantity,
    locationsListQuery,
  ]);

  const getInfoToVisualize = React.useCallback(async () => {
    if (R.isNil(postIdToVisualize)) return;

    const getInfoToVisualizeResponse = await APIGetOneLocalPost({
      userAccessToken,
      postId: postIdToVisualize,
    });

    if (R.isNil(getInfoToVisualizeResponse)) return;

    const { post_type } = getInfoToVisualizeResponse;

    setCurrentSelectedPostType(getTopicType(post_type));
    setInfoToVisualize(getInfoToVisualizeResponse);
  }, [postIdToVisualize]);
  const getLocationWithoutLinked = React.useCallback(async () => {
    if (!R.isNil(postIdToVisualize)) return;

    const group = showLocationsByCompany ? EGroupType.COMPANY : EGroupType.LOCATIONS_GROUP;
    const groupId = showLocationsByCompany ? activeCompanyId : activeLocationGroupId;

    const locationsWithoutLinked = await getLocationsWithoutLinked({
      group,
      groupId,
      userAccessToken,
      isFetching: setLoadingLocalsWithoutLinks,
    });

    if (R.isNil(locationsWithoutLinked)) return;

    setLocationsWithoutLinkedList(locationsWithoutLinked);
    setHasLocationsWithoutLinked(locationsWithoutLinked.count > 0);
  }, [
    activeCompanyId,
    activeLocationGroupId,
    showLocationsByGroup,
    showLocationsByCompany,
  ]);

  const handleSearchLocation = (locationName: string) => {
    setLocationsListPageIndex(0);
    setLocationsListQuery(locationName);
  };

  React.useEffect(() => {
    getActiveCompanyOrGroupLocations();
  }, [getActiveCompanyOrGroupLocations]);

  React.useEffect(() => {
    getInfoToVisualize();
  }, [getInfoToVisualize]);

  React.useEffect(() => { getLocationWithoutLinked(); }, []);

  const onConfirmClicked = () => {
    setHasLocationsWithoutLinked(false);
  };

  const handleInputSearch = debounce(value => handleSearchLocation(value), 500);

  return (
    <StyledCreatePostDialog
      open={open && !loadingLocalsWithoutLinks}
      aria-labelledby="simple-dialog-title"
      onClose={handleOnCloseModal}
    >
      <StyledHeader>
        {R.isNil(postIdToVisualize) && hasLocationsWithoutLinked ? (
          <p>Atenção!</p>
        ) : (<p>Criar Postagens</p>)}
        <IconButton
          icon="Close"
          tooltip="Fechar"
          onClick={handleOnCloseModal}
        />
      </StyledHeader>
      <StyledPostAndFilterWrapper>
        {(!R.isNil(postIdToVisualize) || !hasLocationsWithoutLinked) && !loadingLocalsWithoutLinks && (
          <StyledPostWrapper>
            <StyledOptionsPost>
              {R.isNil(infoToVisualize) && (
                <Select<TSelectPlatform>
                  border
                  value={currentSelectedPlatform}
                  label="Plataforma"
                  options={locationPostPlatformsMocked}
                  name="platform-to-post"
                  onChange={({ target }) => setCurrentSelectedPlatform(target.value)}
                  disabled
                />
              )}
              <Select<TLocationPostGooglePostType>
                border
                disabled={!R.isNil(infoToVisualize)}
                value={currentSelectedPostType}
                label="Tipo de Post"
                options={createLocationGooglePostsType}
                name="posts-type"
                onChange={({ target }) => setCurrentSelectedPostType(target.value)}
                hasPlaceholder
              />
            </StyledOptionsPost>
            <StyledFormContent>
              {postDialogLoading ? <Loading className="post-dialog-loading" /> : renderPostFormType(currentSelectedPostType)}
            </StyledFormContent>
          </StyledPostWrapper>
        )}

        {R.isNil(postIdToVisualize)
        && !hasLocationsWithoutLinked
        && !loadingLocalsWithoutLinks && (
          <Checklist
            options={locationsList}
            optionsPageIndex={locationsListPageIndex}
            setOptionsPageIndex={setLocationsListPageIndex}
            optionsPageQuantity={locationsListPageQuantity}
            handleInputSearch={handleInputSearch}
            selectedOptions={locationsToExclude}
            setSelectedOptions={setLocationsToExclude}
            masterCheckbox={masterCheckbox}
            setMasterCheckbox={setMasterCheckbox}
            optionsTotalLength={locationsListLength}
            optionsNoEllegible={locationsWithoutLinkedList?.count || 0}
          />
        )}

        {R.isNil(postIdToVisualize) && hasLocationsWithoutLinked && (
          <StyledHasLocationsWithoutLinked>
            <b>Existem Locais que estão com a vinculação pendente:</b>
            <p>Os locais abaixo não serão afetados pela criação da Postagem se não estiverem vinculados.</p>

            {!R.isNil(locationsWithoutLinkedList) && locationsWithoutLinkedList.count > 0 && (
              <StyledErrorLocationList>
                {locationsWithoutLinkedList.locations.map(location => (
                  <StyledRow>
                    <StyledErrorIcon />
                    {' '}
                    {location}
                  </StyledRow>
                ))}
              </StyledErrorLocationList>
            )}
          </StyledHasLocationsWithoutLinked>
        )}
      </StyledPostAndFilterWrapper>

      {R.isNil(postIdToVisualize)
      && hasLocationsWithoutLinked
      && !R.isNil(locationsWithoutLinkedList)
      && locationsWithoutLinkedList.count > 0
      && (
        <StyledFooter>
          <StyledConfirmButton
            onClick={onConfirmClicked}
          >
            Prosseguir mesmo assim
          </StyledConfirmButton>
        </StyledFooter>
      )}
    </StyledCreatePostDialog>
  );
};

export default CreatePostDialog;
