import { isEmpty, isNil } from 'ramda';
import { TBatchWorkingHoursEntity } from '../../types/TWorkingHours';
import { isValidHour } from '../../utils/isValidHour';

export const isValidWorkingHours = (
  workingHours: TBatchWorkingHoursEntity[] | undefined,
): boolean => {
  if (isNil(workingHours)) return false;
  if (isEmpty(workingHours)) return false;

  let validTimes = true;

  workingHours.map((workingHour) => {
    if (isNil(workingHour.times)) return null;

    workingHour.times.map((time) => {
      if (!isValidHour(time.open) || !isValidHour(time.close)) {
        validTimes = false;
      }
    });
  });

  return validTimes;
};

export const isInvalidPhone = (phoneValue: any) => {
  if (isNil(phoneValue) || phoneValue === 'MUST_BE_EMPTY') return false;
  const isInvalidPhoneRegExp = /_/g;
  return isInvalidPhoneRegExp.test(phoneValue);
};
