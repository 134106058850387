import React from 'react';
import { useHistory } from 'react-router-dom';
import * as R from 'ramda';
import { useSnackbar } from 'notistack';

import APIGetUserLocations from '../../services/locations/get-user-locations';
import APIGetAllLocations from '../../services/locations/getAllLocations';
import APICreateLocationsGroup from '../../services/location-groups/create';
import APIAddLocationsGroup from '../../services/location-groups/addLocations';

import {
  HeaderToolbar,
  BlockWithActionButton,
  ResponsibleInfo,
  HealderToolbarAction,
  GroupItems,
  Input,
  Textarea,
} from '../../components';

import {
  isValidData,
} from './helpers';

import {
  useAuth,
  useCompany,
} from '../../hooks';

import {
  StyledCreateLocationGroup,
  StyledButtonsGroup,
  StyledContentMain,
  StyledContentAside,
  StyledContent,
  StyledGroupFormCreate,
  StyledGroupFormCreateWrapper,
  StyledGroupFormCreateTitle,
} from './create-location-group-styles';

const CreateLocationGroup = () => {
  const { enqueueSnackbar } = useSnackbar();

  const {
    push: pushToScreen,
  } = useHistory();

  const {
    userCompanies,
    userCompaniesPageIndex,
    userCompaniesPageQuantity,
    userCompaniesPageQuery,
    setUserCompaniesPageIndex,
    setUserCompaniesPageQuery,
  } = useCompany();

  const {
    userId,
    userProfileName,
    userAccessToken,
    userSetTokenLikeExpired,
  } = useAuth();

  const [companyLocations, setCompanyLocations] = React.useState([]);
  const [companyLocationsPageSize] = React.useState(10);
  const [companyLocationsPageNumber, setCompanyLocationsPageNumber] = React.useState(0);
  const [companyLocationsPageQuery, setCompanyLocationsPageQuery] = React.useState('');
  const [companyLocationsIsLoading, setcompanyLocationsIsLoading] = React.useState(false);
  const [companyLocationsCount, setCompanyLocationsCount] = React.useState(null);

  const [companyFilter, setCompanyFilter] = React.useState(0);

  const [addedLocationsQuery, setAddedLocationsQuery] = React.useState('');

  const [groupNameField, setGroupNameField] = React.useState('');
  const [groupDescriptionField, setGroupDescriptionField] = React.useState('');

  const [isCreatingGroup, setIsCreatingGroup] = React.useState(false);

  const [locationsGroup, setLocationsGroup] = React.useState([]);

  const responsibleData = React.useRef(null);

  const handlePushToMyLocationGroups = () => pushToScreen('/my-location-groups');

  const getUserLocations = React.useCallback(async () => {
    const getCompanyLocationsResponse = await APIGetUserLocations({
      accessToken: userAccessToken,
      setTokenLikeExpired: userSetTokenLikeExpired,
      userId,
      companyId: companyFilter,
      page: companyLocationsPageNumber,
      query: companyLocationsPageQuery,
      setIfFetching: setcompanyLocationsIsLoading,
    });

    if (R.isNil(getCompanyLocationsResponse)) return;

    const [companyLocationsData, companyLocationsDataCount] = getCompanyLocationsResponse || [];

    setCompanyLocations(companyLocationsData);
    setCompanyLocationsCount(Math.ceil(companyLocationsDataCount / companyLocationsPageSize));
  }, [
    userAccessToken,
    companyLocationsPageNumber,
    companyLocationsPageQuery,
    userCompanies,
    companyFilter,
  ]);

  const getAllLocations = React.useCallback(async () => {
    const getCompanyLocationsResponse = await APIGetAllLocations({
      accessToken: userAccessToken,
      page: companyLocationsPageNumber,
      pageSize: 10,
      query: companyLocationsPageQuery,
      companyId: companyFilter,
    });

    if (R.isNil(getCompanyLocationsResponse)) return;

    const [companyLocationsData, companyLocationsDataCount] = getCompanyLocationsResponse || [];

    setCompanyLocations(companyLocationsData);
    setCompanyLocationsCount(Math.ceil(companyLocationsDataCount / companyLocationsPageSize));
  }, [
    userAccessToken,
    companyLocationsPageNumber,
    companyLocationsPageQuery,
    userCompanies,
    companyFilter,
  ]);

  const handleGetLocations = () => {
    if (userProfileName === 'Usuário Padrão') {
      getUserLocations();
    } else {
      getAllLocations();
    }
  };

  React.useEffect(() => { handleGetLocations(); }, [getUserLocations, getAllLocations]);

  const handleSave = React.useCallback(async () => {
    const { responsibles, reset: resetResponsibles } = responsibleData.current;

    if (!isValidData({
      responsibles,
      groupNameField,
      locationsGroup,
      enqueueSnackbar,
    })) return;

    const mainResponsibleId = responsibles.filter(responsible => responsible.main).map(responsible => responsible.id)[0];
    const locationsAddedIds = locationsGroup.map(locationGroup => locationGroup.id);

    const createLocationsGroupResponse = await APICreateLocationsGroup({
      accessToken: userAccessToken,
      setTokenLikeExpired: userSetTokenLikeExpired,
      name: groupNameField,
      description: groupDescriptionField,
      created_by: userId,
      feedbackMessage: enqueueSnackbar,
      owner_user_id: userId,
      main_responsible_id: mainResponsibleId,
      isCreatingGroup,
    });

    if (R.isNil(createLocationsGroupResponse)) {
      enqueueSnackbar('Erro ao criar grupo de local, tente novamente!', { variant: 'error' });
      return;
    }

    const addLocationsToGroupResponse = await APIAddLocationsGroup({
      accessToken: userAccessToken,
      setTokenLikeExpired: userSetTokenLikeExpired,
      locationGroupId: createLocationsGroupResponse.id,
      locationIds: locationsAddedIds,
      feedbackMessage: enqueueSnackbar,
      isAddLocationToGroup: isCreatingGroup,
    });

    if (R.isNil(addLocationsToGroupResponse)) {
      enqueueSnackbar('Erro ao adicionar locais ao grupo, tente novamente!', { variant: 'error' });
      return;
    }

    resetResponsibles();
    setGroupDescriptionField('');
    setGroupNameField('');
    setLocationsGroup([]);
    handlePushToMyLocationGroups();
  }, [
    userId,
    userAccessToken,
    responsibleData,
    groupNameField,
    locationsGroup,
    groupDescriptionField,
  ]);

  const handleAddToGroupItems = locationToAdd => {
    const hasLocationOnLocationsGroup = locationsGroup.find(locationGroup => locationGroup.id === locationToAdd.id);

    if (!R.isNil(hasLocationOnLocationsGroup)) return;

    setLocationsGroup(prevState => [...prevState, locationToAdd]);
  };

  const handleRemoveAddedGroupItems = locationAddedToRemove => {
    if (R.isNil(locationsGroup.find(removedGroup => removedGroup.id === locationAddedToRemove.id))) return;

    setLocationsGroup(prevState => prevState.filter(locationAdded => locationAdded.id !== locationAddedToRemove.id));
  };

  const handleChangeCompany = (company) => {
    setCompanyLocationsPageNumber(0);
    setCompanyFilter(company);
  };

  const handleSearchCompany = (query) => {
    setCompanyLocationsPageNumber(0);
    setCompanyLocationsPageQuery(query);
  };

  return (
    <StyledCreateLocationGroup>
      <HeaderToolbar
        title="Criar Grupo de Local"
      >
        <StyledButtonsGroup>
          <HealderToolbarAction
            title="Lista de grupos"
            icon="List"
            onClick={handlePushToMyLocationGroups}
          />
        </StyledButtonsGroup>
      </HeaderToolbar>
      <StyledContent>
        <StyledContentMain>
          <ResponsibleInfo
            className="block-form"
            ref={responsibleData}
            maxResponsibles={1}
          />

          <StyledGroupFormCreate>
            <StyledGroupFormCreateTitle>Informação do Grupo</StyledGroupFormCreateTitle>

            <StyledGroupFormCreateWrapper>
              <Input
                label="Nome do grupo*"
                inputOptions={{
                  name: 'group-name',
                  type: 'text',
                }}
                onChange={({ target }) => setGroupNameField(target.value)}
                value={groupNameField}
                border
              />
              <Textarea
                label="Descrição do grupo"
                name="group-description"
                border
                onChange={(event) => {
                  setGroupDescriptionField(event);
                }}
                value={groupDescriptionField}
              />
            </StyledGroupFormCreateWrapper>
          </StyledGroupFormCreate>

          <GroupItems
            mainTitle="Adicionar locais"
            itemsToAdd={companyLocations}
            primaryTextKey="name"
            itemsToAddPagesQuantity={companyLocationsCount}
            loadingItemsToAdd={companyLocationsIsLoading}
            currentItensToAddPage={companyLocationsPageNumber}
            setItensToAddPage={setCompanyLocationsPageNumber}
            setQueryItensToAddPage={handleSearchCompany}
            queryItensToAddPage={companyLocationsPageQuery}
            onItemToAddClicked={handleAddToGroupItems}
            onAddedItemClicked={handleRemoveAddedGroupItems}
            addedItens={locationsGroup}
            setCompanyFilter={handleChangeCompany}
            userCompanies={userCompanies}
            userCompaniesPageIndex={userCompaniesPageIndex}
            userCompaniesPageQuantity={userCompaniesPageQuantity}
            userCompaniesPageQuery={userCompaniesPageQuery}
            setUserCompaniesPageIndex={setUserCompaniesPageIndex}
            setUserCompaniesPageQuery={setUserCompaniesPageQuery}
            setQueryAddedItensPage={setAddedLocationsQuery}
            queryAddedItensPage={addedLocationsQuery}
            inputLabelToAdd="Pesquisar"
            inputLabelAdded="Pesquisar"
            buttonTooltipAddTitle="Adicionar Local"
            buttonTooltipRemoveTitle="Remover Local"
            emptyItemsToAdd="Nenhum local a adicionar"
            emptyAddedItems="Nenhum local adicionado"
            isCreation
            locationGroupCreation
          />
        </StyledContentMain>
        <StyledContentAside>
          <BlockWithActionButton
            onClick={handleSave}
            titleButton="Criar"
            loading={isCreatingGroup}
          />

        </StyledContentAside>
      </StyledContent>

    </StyledCreateLocationGroup>
  );
};

export default CreateLocationGroup;
