import axios from "axios";

export default async function sendValidationMail(userId) {
  try {
    const { data } = await axios.post(
      `${process.env.REACT_APP_AUTH_API_URL}/send-validation-mail/${userId}`
    );

    return data;
  } catch (err) {
    return err;
  }
}
