import axios from 'axios';
import { TGetAllConnectionsFromFacebookPageId } from '../../types/TPlatformsLinkWithLocation';

export default async function getAllConnectionsFromFacebookLocation({
  accessToken,
  page = 0,
  pageSize = 10,
  status,
  facebook_page_id,
  setTokenLikeExpired,
}: TGetAllConnectionsFromFacebookPageId) {
  try {
    const { data } = await axios.get(
      `${process.env.REACT_APP_LOCATION_CONNECTIONS}/location-facebook/${facebook_page_id}`,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
        params: {
          page,
          pageSize,
          status,
          facebook_page_id,
        },
      },
    );

    return data;
  } catch (err: any) {
    if (err.response?.status === 401 && setTokenLikeExpired) setTokenLikeExpired();

    return null;
  }
}
