import axios from 'axios';
import { TChangeCompanyOwner } from '../../types/TChangeCompanyOwner';

export async function changeCompanyOwner({
  accessToken,
  setIsFetching,
  setTokenLikeExpired,
  ownerId,
  companyId,
}: TChangeCompanyOwner) {
  try {
    if (setIsFetching) setIsFetching(true);

    await axios.patch(
      `${process.env.REACT_APP_COMPANIES_API_URL}/change-owner/${companyId}`,
      {
        owner_id: ownerId,
      },
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );
  } catch (err: any) {
    if (err.response?.status === 401 && setTokenLikeExpired)
      setTokenLikeExpired();

    return null;
  } finally {
    if (setIsFetching) setIsFetching(false);
  }
}
