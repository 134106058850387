import axios from 'axios';

export default async function createVenue({ accessToken, foursquareToken, createVenueData }) {
  const requestOptions = {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  };

  try {
    const { data } = await axios.post(`${process.env.REACT_APP_FOURSQUARE_API_URL}/create-venue?foursquare-access-token=${foursquareToken}`, createVenueData, requestOptions);

    return data;
  } catch (err) {
    throw err.respoonse;
  }
}
