import React from 'react';
import { useHistory } from 'react-router-dom';
import { useAuth } from '../../hooks';

import { HeaderToolbar, HeaderToolbarAction } from '..';
import { TBatchHeaderProps } from './types';

const BatchHeader = ({ isMyLocations = false }: TBatchHeaderProps) => {
  const {
    userProfileName,
  } = useAuth();

  const history = useHistory();

  return (
    <HeaderToolbar dropdownToggle>
      {userProfileName !== 'Usuário Padrão' && (
        <HeaderToolbarAction
          title="Informações"
          icon="Info"
          onClick={() => {
            history.push('/batch-update-info-status');
          }}
        />
      )}

      <HeaderToolbarAction
        title="Avaliações"
        icon="Star"
        onClick={() => history.push('/reviews')}
      />

      <HeaderToolbarAction
        title="Postagens"
        icon="PostAdd"
        onClick={() => history.push('/batch-update-posts-status')}
      />
      <HeaderToolbarAction
        title="Galeria"
        icon="PhotoCamera"
        onClick={() => history.push('/gallery-batch')}
      />

      <HeaderToolbarAction
        title="Serviços"
        icon="ViewList"
        onClick={() => history.push('/service-batch')}
      />
      {isMyLocations && (
        <HeaderToolbarAction
          title="Adicionar local"
          icon="AddLocation"
          onClick={() => history.push('/locations/create')}
        />
      )}

    </HeaderToolbar>
  );
};

export default BatchHeader;
