import axios from 'axios';

export default async function createLocationGroup({
  accessToken,
  name,
  description,
  owner_user_id,
  main_responsible_id,
  created_by = 1,
  setTokenLikeExpired,
  feedbackMessage,
  isCreatingGroup,
}) {
  try {
    if (isCreatingGroup) isCreatingGroup(true);
    if (feedbackMessage) feedbackMessage('Criando Grupo de locais...', { variant: 'warning' });

    const { data } = await axios.post(
      `${process.env.REACT_APP_LOCATION_GROUPS_API_URL}`,
      {
        name,
        description,
        owner_user_id,
        main_responsible_id,
        created_by,
      },
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      },
    );

    if (feedbackMessage) feedbackMessage('Grupo criado com sucesso', { variant: 'success' });

    return data;
  } catch (err) {
    if (err.response?.status === 401 && setTokenLikeExpired) setTokenLikeExpired();

    return null;
  } finally {
    if (isCreatingGroup) isCreatingGroup(false);
  }
}
