// eslint-disable-next-line no-use-before-define
import React from 'react';

import Dialog from '../../../components/dialog';
import Button from '../../../components/button';
import Loading from '../../../components/loading';

import {
  StyledContent,
  StyledBottomActions,
} from './leave-dialog-styles';
import { LeaveDialogProps } from './types';

const LeaveDialog = ({
  title,
  open,
  onClose,
  children,
  onCancel,
  onConfirm,
  confirmTitle = 'Sincronizar',
  confirmType = 'primary',
  contentNoPadding = false,
  isLoading = false,
}: LeaveDialogProps) => (
  <Dialog
    open={open}
    onClose={onClose}
    title={title}
  >
    <>
      <StyledContent
        contentNoPadding={contentNoPadding}
      >
        {children}
      </StyledContent>
      <StyledBottomActions>
        <Button
          className="button"
          buttonType="cancel"
          onClick={onCancel}
        >
          Sair
        </Button>
        <Button
          disabled={isLoading}
          className="button"
          onClick={onConfirm}
          buttonType={confirmType}
        >
          {isLoading && <Loading className="is-loading-confirm-dialog" />}
          {confirmTitle}
        </Button>
      </StyledBottomActions>
    </>
  </Dialog>
);

export default LeaveDialog;
