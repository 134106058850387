import styled from 'styled-components';
import { withStyles } from '@material-ui/core/styles';
import { Switch } from '@material-ui/core';

import { light } from '../../assets/styles/themes';

export const StyledSwitch = withStyles({
  root: {
    width: 42,
    height: 26,
    padding: 0,
    margin: '0.1rem',
  },
  switchBase: {
    padding: '0.1rem !important',
    '&$checked': {
      transform: 'translateX(16px)',
      color: light.colors.white,
      '& + $track': {
        backgroundColor: light.colors.primary,
        opacity: 1,
        border: 'none',
      },
    },
    '&$focusVisible $thumb': {
      color: '#52d869',
      border: `6px solid ${light.colors.grey}`,
    },
  },
  thumb: {
    width: 24,
    height: 24,
  },
  track: {
    borderRadius: 26 / 2,
    border: `1px solid ${light.colors.grey}`,
    backgroundColor: light.colors.grey,
    opacity: 1,
    transition: 'background-color, border 0.3s',
  },
  checked: {},
})(Switch);
