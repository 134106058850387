import axios, { AxiosRequestConfig } from 'axios';
import { TGetAllRequestsResponse, TGetAllRequestsByLocationProps } from './types';

export const getAllRequestsByLocation = async ({
  locationId,
  // companyName = '',
  // locationName = '',
  userAccessToken,
  page = 0,
  pageSize = 4,
  // query = '',
  status = '',
  ofPeriod = '',
  toPeriod = '',
  isFetching,
  withFields = false,
  hasTypes,
}: TGetAllRequestsByLocationProps): Promise<TGetAllRequestsResponse> => {
  try {
    if (isFetching) isFetching(true);
    const apiPath = `${process.env.REACT_APP_SYNC_PLATFORM_REQUESTS}/location-requests/${locationId}`;

    const requestOptions: AxiosRequestConfig = {
      headers: {
        Authorization: `Bearer ${userAccessToken}`,
      },
      params: {
        page,
        pageSize,
        locationId,
        withFields,
        status,
        ofPeriod,
        toPeriod,
        hasTypes,
      },
    };

    const { data } = await axios.get(apiPath, requestOptions);

    return data;
  } catch (err) {
    return null;
  } finally {
    if (isFetching) isFetching(false);
  }
};
