import React from 'react';
import IconButton from '../../../icon-button';
import { StyledItem, StyledList, StyledSpan } from './last-sync-list-styles';

type TLastSyncListProps = {
  data: any[];
  onLastSyncDialogOpen(id: string): void;
}

const splitDateUpdatedAt = (date) => {
  const dateArray = date.split(' ');

  return `${dateArray[0]} às ${dateArray[1]}`;
};

const LastSyncList = ({ data, onLastSyncDialogOpen }: TLastSyncListProps) => (
  <StyledList>
    {data.map(item => (
      <StyledItem>
        <StyledSpan>{splitDateUpdatedAt(item.date_updated)}</StyledSpan>
        <IconButton
          label=""
          tooltip=""
          className="openDialogButton"
          icon="KeyboardArrowRight"
          onClick={() => onLastSyncDialogOpen(item.id)}
        />
      </StyledItem>
    ))}
  </StyledList>
);

export default LastSyncList;
