import styled from 'styled-components';
import { lighten } from 'polished';

export const StyledHeader = styled.header`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 2rem;
    border-bottom: 1px solid ${props => lighten(0.3, props.theme.colors.grey)};

    h3 {
        margin-bottom: 0;
    }
`;
