import styled from 'styled-components';
import { shade } from 'polished';

export const StyledSpecialDatesInfosContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const StyledSpecialDatesInfos = styled.ul``;

export const StyledSpecialDatesInfo = styled.li`
  display: flex;
  flex-direction: column;
  background: ${props => shade(0.1, props.theme.colors.white)};
  margin-bottom: 0.2rem;
  padding: 1rem;

  &:nth-child(even) {
    background: ${props => shade(0.15, props.theme.colors.white)};
  }
`;

export const StyledSpecialDatesInfoLine = styled.div`
  display: flex;

  b {
    font-size: 1.2rem;
    margin-right: 0.5rem;
  }

  small {
    max-width: 17rem;
  }
`;

export const StyledSpecialDatesInfoTimes = styled.ul`
  display: flex;
  flex-direction: column;

  li {
    padding: 1rem;
    background: ${props => shade(0.05, props.theme.colors.white)};
    margin-bottom: 0.1rem;
  }

  span {
    margin: 0 0.5rem;
  }
`;
