import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as R from 'ramda';
import { useSnackbar } from 'notistack';

import { TRootStateRedux } from '../../types/TRootStateRedux';
import { TCompanyEntity, TCompanyOrderByName } from '../../types/TCompany';
import { TUpdateStateUseCompany } from '../../types/TUseCompany';

import APIGetUserCompaniesWithGuests from '../../services/companies/getUserCompaniesWithGuests';
import APIGetAllCompanies from '../../services/companies/getCompanies';
import APIGetCompany from '../../services/companies/getCompany';
import APIDeleteCompany from '../../services/companies/deleteCompany';
import APIDeleteGuest from '../../services/guest/delete';

import { Creators as CompanyActions } from '../../redux/ducks/company';

import addTagOwnerOrGuestColumn from '../../utils/add-tag-owner-or-guest-column';

import useAuth from '../use-auth';

import {
  getActiveCompanyId,
  hasActiveCompany,
  activeCompanyIsArray,
  isEmptyData,
} from './helpers';

const useCompany = () => {
  const reduxDispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  const [activeCompany, setActiveCompany] = React.useState<TCompanyEntity | null>(null);
  const [activeCompanyId, setActiveCompanyId] = React.useState<number | null>(null);
  const [userCompanies, setUserCompanies] = React.useState<TCompanyEntity[]>([]);
  const [userCompaniesCount, setUserCompaniesCount] = React.useState(0);

  const [userCompaniesPageIndex, setUserCompaniesPageIndex] = React.useState(0);
  const [userCompaniesPageSize, setUserCompaniesPageSize] = React.useState(10);
  const [userCompaniesPageQuery, setUserCompaniesPageQuery] = React.useState('');
  const [userCompaniesPageQuantity, setUserCompaniesPageQuantity] = React.useState(0);
  const [userCompaniesOrderByName, setUserCompaniesOrderByName] = React.useState<TCompanyOrderByName>('ASC');

  const [overlayLoadingTextUseCompany, setOverlayLoadingTextUseCompany] = React.useState('Carregando...');
  const [userCompaniesLoading, setUserCompaniesLoading] = React.useState(false);

  const {
    userId,
    userSetTokenLikeExpired,
    userAccessToken,
    userProfileName,
  } = useAuth();

  const {
    showLocationsByCompany,
    activeCompany: activeCompanyRedux,
  } = useSelector((state: TRootStateRedux) => state.CompanyReducer);

  const setActiveCompanyInRedux = (newActiveCompany: TCompanyEntity) => {
    reduxDispatch(CompanyActions.setActiveCompany(newActiveCompany));
    reduxDispatch(CompanyActions.setActiveCompanyId(newActiveCompany.id));
    setActiveCompanyId(newActiveCompany.id || null);
  };

  const validateActiveCompanyToOtherUser = async (userCompaniesData: any) => {
    const userCompaniesWithGuest = await APIGetUserCompaniesWithGuests({
      accessToken: userAccessToken,
      setTokenLikeExpired: userSetTokenLikeExpired,
      userId,
      query: activeCompanyRedux.name || '',
    });

    if (!R.isNil(userCompaniesWithGuest) && userProfileName === 'Usuário Padrão') {
      const [userHasCurrenActiveCompany] = userCompaniesWithGuest;
      if (R.isEmpty(userHasCurrenActiveCompany) && !R.isEmpty(userCompaniesData)) {
        const isUserDataCompanyEqualToCurrentActiveCompany = activeCompanyRedux?.id === userCompaniesData[0].id;
        if (isUserDataCompanyEqualToCurrentActiveCompany) return;
        reduxDispatch(CompanyActions.setActiveCompany(userCompaniesData[0]));
        reduxDispatch(CompanyActions.setActiveCompanyId(userCompaniesData[0].id));
        setActiveCompanyId(userCompaniesData[0].id);
      }
    }
  };

  const updateStateUseCompany = async ({
    userCompaniesDataCount,
    userCompaniesData,
  }: TUpdateStateUseCompany) => {
    setUserCompaniesPageQuantity(Math.ceil(userCompaniesDataCount / userCompaniesPageSize));
    setUserCompanies(userCompaniesData);
    setUserCompaniesCount(userCompaniesDataCount);
    reduxDispatch(CompanyActions.setUserCompanies(userCompaniesData));
    reduxDispatch(CompanyActions.setUserCompaniesLoading(false));

    if (R.isEmpty(userCompaniesData)) return;

    if (R.isEmpty(activeCompanyRedux) || R.isNil(activeCompanyRedux)) {
      reduxDispatch(CompanyActions.setActiveCompany(userCompaniesData[0]));
      reduxDispatch(CompanyActions.setActiveCompanyId(userCompaniesData[0].id));
      setActiveCompanyId(userCompaniesData[0].id || null);

      return;
    }

    await validateActiveCompanyToOtherUser(userCompaniesData);
  };

  const getAdminAndOperatorCompanies = React.useCallback(async () => {
    reduxDispatch(CompanyActions.setUserCompaniesLoading(true));

    const userCompaniesResponse = await APIGetAllCompanies({
      accessToken: userAccessToken,
      setTokenLikeExpired: userSetTokenLikeExpired,
      page: userCompaniesPageQuery ? 0 : userCompaniesPageIndex,
      pageSize: userCompaniesPageSize,
      query: userCompaniesPageQuery,
      orderByName: userCompaniesOrderByName,
      setIsFetching: setUserCompaniesLoading,
    });

    if (R.isNil(userCompaniesResponse)) return;

    const [userCompaniesData, userCompaniesDataCount] = userCompaniesResponse;

    await updateStateUseCompany({
      userCompaniesData,
      userCompaniesDataCount,
    });
  }, [
    userCompaniesPageQuery,
    userCompaniesPageSize,
    userCompaniesPageIndex,
    userCompaniesOrderByName,
    activeCompanyRedux,
    activeCompanyId,
  ]);

  const getOtherUserCompanies = React.useCallback(async () => {
    reduxDispatch(CompanyActions.setUserCompaniesLoading(true));

    const otherUserCompaniesResponse = await APIGetUserCompaniesWithGuests({
      accessToken: userAccessToken,
      userId,
      setTokenLikeExpired: userSetTokenLikeExpired,
      page: userCompaniesPageQuery ? 0 : userCompaniesPageIndex,
      pageSize: userCompaniesPageSize,
      query: userCompaniesPageQuery,
      orderByName: userCompaniesOrderByName,
      setIsFetching: setUserCompaniesLoading,
    });

    if (R.isNil(otherUserCompaniesResponse)) return;

    const [otherUserCompanies, otherUserCompaniesCount] = otherUserCompaniesResponse;

    const companiesWithTag = addTagOwnerOrGuestColumn({
      registerTarget: otherUserCompanies,
      currentUserId: userId,
    });

    await updateStateUseCompany({
      userCompaniesData: companiesWithTag,
      userCompaniesDataCount: otherUserCompaniesCount,
    });
  }, [
    userId,
    userAccessToken,
    userCompaniesPageIndex,
    userCompaniesPageQuery,
    userCompaniesPageSize,
    userCompaniesOrderByName,
    activeCompanyRedux,
    activeCompanyId,
  ]);

  const getCompaniesByUser = async (userProfile: string) => {
    if (userProfile !== 'Usuário Padrão') {
      await getAdminAndOperatorCompanies();
      return;
    }

    await getOtherUserCompanies();
  };

  const getUserCompanies = React.useCallback(async () => {
    if (userCompaniesPageQuery) setUserCompaniesPageIndex(0);
    if (R.isNil(userProfileName)) return;

    setOverlayLoadingTextUseCompany('Carregando empresas...');

    await getCompaniesByUser(userProfileName);
  }, [
    userProfileName,
    userCompaniesPageSize,
    userCompaniesPageIndex,
    userCompaniesPageQuery,
    userCompaniesOrderByName,
    activeCompanyId,
    activeCompanyRedux,
  ]);

  const getUserCompany = React.useCallback(async (companyIdToGetCompany: any) => {
    const companyResponse = await APIGetCompany({
      accessToken: userAccessToken,
      setTokenLikeExpired: userSetTokenLikeExpired,
      id: companyIdToGetCompany,
    });

    if (R.isNil(companyResponse)) return null;

    return companyResponse;
  }, []);

  const deleteUserCompany = React.useCallback(async companyIdToDeleteCompany => {
    setOverlayLoadingTextUseCompany('Deletando empresa...');

    const deleteCompanyResponse = await APIDeleteCompany({
      accessToken: userAccessToken,
      id: companyIdToDeleteCompany,
      setTokenLikeExpired: userSetTokenLikeExpired,
      snackbar: enqueueSnackbar,
      isDeleting: setUserCompaniesLoading,
    });

    if (deleteCompanyResponse) await getUserCompanies();

    return deleteCompanyResponse;
  }, []);

  const deleteGuestUserFromCompany = React.useCallback(async guestIdToDelete => {
    setOverlayLoadingTextUseCompany('Revogando convite...');

    const deleteGuestCompanyResponse = await APIDeleteGuest({
      accessToken: userAccessToken,
      id: guestIdToDelete,
      setTokenLikeExpired: userSetTokenLikeExpired,
      setIsFetching: setUserCompaniesLoading,
    });

    if (deleteGuestCompanyResponse) await getUserCompanies();

    return deleteGuestCompanyResponse;
  }, []);

  const setNewActiveCompany = React.useCallback(userCompaniesData => {
    if (R.isNil(userCompaniesData) || R.isEmpty(userCompaniesData)) return;

    const newActiveCompany = userCompaniesData[0];
    const newCurrentActiveCompanyId = getActiveCompanyId(userCompaniesData[0]);

    setActiveCompany(newActiveCompany);
    setActiveCompanyId(newCurrentActiveCompanyId);
    setActiveCompanyInRedux(newActiveCompany);
    reduxDispatch(CompanyActions.setActiveCompanyId(newCurrentActiveCompanyId));
  }, []);

  const setCurrentActiveCompany = React.useCallback(async () => {
    if (R.isNil(activeCompanyRedux) || R.isEmpty(activeCompanyRedux)) return;
    if (R.isEmpty(userCompanies)) return;

    const hasCurrentActiveCompany = hasActiveCompany(activeCompanyRedux);
    const currentActiveCompanyIsArray = activeCompanyIsArray(activeCompanyRedux);
    const isEmptyActiveCompany = isEmptyData(activeCompanyRedux);
    const isEmptyUserCompanies = isEmptyData(userCompanies);

    if (hasCurrentActiveCompany) {
      const currentActiveCompanyId = getActiveCompanyId(activeCompanyRedux);

      setActiveCompany(activeCompanyRedux);
      setActiveCompanyId(currentActiveCompanyId);
      reduxDispatch(CompanyActions.setActiveCompanyId(currentActiveCompanyId));

      return;
    }

    if (!isEmptyActiveCompany && currentActiveCompanyIsArray) setNewActiveCompany(userCompanies);

    if (isEmptyActiveCompany && !isEmptyUserCompanies) setNewActiveCompany(userCompanies);

    if (isEmptyActiveCompany && isEmptyUserCompanies) {
      setActiveCompanyId(null);
      setActiveCompany(null);
    }
  }, [activeCompanyRedux, userCompanies]);

  React.useEffect(() => { getUserCompanies(); }, [getUserCompanies]);
  React.useEffect(() => { setCurrentActiveCompany(); }, [setCurrentActiveCompany]);

  return {
    showLocationsByCompany,
    activeCompany,
    userCompanies,
    userCompaniesCount,
    activeCompanyId,
    userCompaniesLoading,
    overlayLoadingTextUseCompany,
    getUserCompany,
    deleteUserCompany,
    deleteGuestUserFromCompany,
    setUserCompaniesPageQuery,
    setUserCompaniesPageSize,
    setUserCompaniesPageIndex,
    setUserCompaniesOrderByName,
    userCompaniesPageQuantity,
    userCompaniesPageIndex,
    userCompaniesPageSize,
    userCompaniesPageQuery,
    userCompaniesOrderByName,
  };
};

export default useCompany;
