import styled, { css } from 'styled-components';
import { Paper } from '@material-ui/core';
import { devices } from '../../assets/styles/devices';

export const StyledMapInfoContainer = styled(Paper)<{ isEditLocation?: boolean; }>`
  display: flex;
  flex-direction: column;
  padding: 2rem;

  h3 {
    font-size: 1.5rem;
    font-weight: 700;
    margin-bottom: 2rem;
  }

  .edit-button {
    margin-bottom: 1rem;
  }

  .edit-button-icon {
    width: 1.5rem;
    height: 1.5rem;
    margin-left: 0.5rem;
  }

  ${props => props.isEditLocation && css`
    margin-bottom: 4rem;
  `}
`;

export const StyledMapView = styled.div`
  height: 25rem;
  width: 100%;
  margin-bottom: 1.5rem;
  overflow: hidden;
  border-radius: 0.5rem;

  @media ${devices.tabletLandscape} {
    height: 45rem;
  }
`;

export const StyledAddress = styled.div`
  font-size: 1.4rem;
  text-align: center;
  margin-bottom: 1rem;
`;
