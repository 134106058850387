import styled from 'styled-components';

import { Dialog, Checkbox, Radio } from '@material-ui/core';
import { shade, lighten, transparentize } from 'polished';

import {
  StarRate as ActiveStar,
} from '@material-ui/icons';

import { devices } from '../../../../../assets/styles/devices';

export const StyledAddAutomaticResponses = styled(Dialog)`
    .MuiDialog-paper {
        padding: 3rem 2rem;
        width: 100%;
        position: relative;
    }

    .response-item {
        cursor: pointer;
        
        &:hover {
            border-left-width: 0.8rem;
        }

        &:nth-child(even) {
            border-left-color: ${props => shade(0.2, props.theme.colors.primary)};
            background: ${props => lighten(0.96, props.theme.colors.black)};
        }
    }

    .response-listing {
        display: flex;
        flex-direction: column;
        margin-bottom: 3rem;
        max-height: 40rem;

        &:hover {
            overflow-y: scroll;
        }
    }

    h3 {
        color: ${props => props.theme.colors.primary};
        line-height: 1rem;
    }

    small {
        margin-bottom: 2rem;
    }
`;

export const StyledNewAutomaticResponse = styled.div`
    padding: 2rem;
    background: ${props => lighten(0.90, props.theme.colors.black)};
    border-radius: 0.5rem;

    h3 {
        margin-bottom: 1rem;
        line-height: 2rem;
    }
`;

export const StyledCheckboxContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
`;

export const StyledCheckBox = styled(Checkbox)`
    padding: 0;
    margin-right: 1rem;

   .MuiSvgIcon-root {
       fill: ${props => props.theme.colors.primary};
       width: 2.5rem;
       height: 2.5rem;
   }

   .MuiButtonBase-root {
       width: 2.5rem;
       height: 2.5rem;

   }
`;

export const StyledStarFilterListWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  margin-bottom: 2rem;

  @media ${devices.tablet} {
    grid-template-columns: repeat(5, 1fr);
    margin-bottom: 0;
  };

  @media ${devices.tabletLandscape} {
    grid-template-columns: repeat(5, 1fr);
  };
`;

export const StyledAddResponseBottomContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  margin-top: 2rem; 

  @media ${devices.tablet} {
    flex-direction: row;
  };

  .MuiIconButton-colorSecondary {
    color: ${props => props.theme.colors.primary};
    
    &:hover {
      background-color: ${props => transparentize(0.9, props.theme.colors.primary)};
    }
  }
`;

export const StyledActiveStar = styled(ActiveStar)`
  width: 2rem;
  height: 2rem;
  padding: 0;
  margin-right: 0.5rem;
  fill: ${props => props.theme.colors.god};
`;

export const StyledStarLabelText = styled.span`
  font-size: 1.5rem;
  font-weight: 700;
  cursor: pointer;
`;

export const StyledSingleStarWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;

  @media ${devices.tablet} {
    flex-direction: row;
  };
`;

export const StyledRadioButton = styled(Radio)``;

export const StyledRadioWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-right: 1rem;
`;

export const StyledRadioGroupContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-bottom: 1rem;

  @media ${devices.tabletLandscape} {
    margin-bottom: 0;
  };
`;

export const StyledRadioGroupContainerContent = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`;

export const StyledLabel = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  @media ${devices.tablet} {
    justify-content: flex-start;
  };
`;
