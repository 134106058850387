import React from 'react';

import { IconButton } from '..';
import { iconByStatus, syncEntityNames } from './helpers';
import { TSyncMonitoringStatusIconProps } from './types';

export default ({ status, syncType }: TSyncMonitoringStatusIconProps) => {
  const syncEntityName = syncEntityNames[syncType];

  return (
    <IconButton
      tooltip={iconByStatus[status || iconByStatus.PENDING].tooltip(syncEntityName)}
      icon={iconByStatus[status || iconByStatus.PENDING].icon}
      className="status-icon"
      isSuccess={iconByStatus[status || iconByStatus.PENDING].isSuccess}
      isWarning={iconByStatus[status || iconByStatus.PENDING].isWarning}
      isError={iconByStatus[status || iconByStatus.PENDING].isError}
      label={iconByStatus[status || iconByStatus.PENDING].label}
      tooltipVariant={iconByStatus[status || iconByStatus.PENDING].tooltipVariant}
    />
  );
};
