import React from 'react';
import {
  StyledInputWithIcon,
  StyledInput,
  StyledIconSearchButton,
} from './input-with-icons-styles';

type TInputWithIcon = {
  value: string;
  setValue: React.Dispatch<React.SetStateAction<string>>;
  placeholder: string;
  children: any;
}

const InputWithIcon = ({
  children,
  value,
  setValue,
  placeholder = '',
}: TInputWithIcon) => (
  <StyledInputWithIcon>
    <StyledInput
      placeholder={placeholder}
      value={value}
      onChange={(event) => setValue(event.target.value)}
    />
    <StyledIconSearchButton>
      {children}
    </StyledIconSearchButton>
  </StyledInputWithIcon>
);

export default InputWithIcon;
