import React from 'react';
import { format } from 'date-fns';
import { isNil, isEmpty } from 'ramda';

import {
  IconButton,
  Loading,
  LogErrorDataAccordion,
} from '../../../components';

import { useAuth } from '../../../hooks';

import type { EActionLogActions } from '../../../types/TBatchGoogleModules';
import type { TActionLogDialogBaseProps } from '../../../types/TPostActionLogDialog';

import APIGetActionLog from '../../../services/google-batch-modules/get-action-log';

import { actionLogStatus } from './helpers';

import {
  StyledCreatePostDialog,
  StyledHeader,
  StyledFormContent,
  StyledPostWrapper,
  StyledPostAndFilterWrapper,
  StyledLogRow,
  StyledInfo,
  StyledRowInfo,
  StyledLogDate,
} from './action-log-dialog-styles';

const CreatePostDialog = ({
  open = false,
  setOpen,
  logDialogLoading,
  postIdToLogVisualize = null,
  setPostIdToLogVisualize,
  logToVisualize = null,
  setLogToVisualize,
}: TActionLogDialogBaseProps) => {
  const { userAccessToken, userProfileName } = useAuth();

  const handleOnCloseModal = () => {
    setLogToVisualize(null);
    setPostIdToLogVisualize(null);
    setOpen(false);
  };

  const getInfoToVisualize = React.useCallback(async () => {
    if (isNil(postIdToLogVisualize)) return;

    const getActionLogResponse = await APIGetActionLog({
      accessToken: userAccessToken,
      postId: postIdToLogVisualize,
    });

    if (isNil(getActionLogResponse)) return;

    setLogToVisualize(getActionLogResponse);
  }, [postIdToLogVisualize]);

  const shouldShowErrorDetailsAccordion = React.useCallback(
    (logAction: EActionLogActions) => logAction === 'ERROR'
      && (userProfileName === 'Admin' || userProfileName === 'Operacional'),
    [userProfileName],
  );

  React.useEffect(() => {
    getInfoToVisualize();
  }, [getInfoToVisualize]);

  return (
    <StyledCreatePostDialog
      open={open}
      aria-labelledby="simple-dialog-title"
      onClose={handleOnCloseModal}
    >
      <StyledHeader>
        Histórico de Status
        <IconButton
          icon="Close"
          tooltip="Fechar"
          onClick={handleOnCloseModal}
        />
      </StyledHeader>

      <StyledPostAndFilterWrapper>
        {!logDialogLoading && (
          <StyledPostWrapper>
            <StyledFormContent>
              {logDialogLoading && <Loading className="post-dialog-loading" />}

              {!isNil(logToVisualize)
                && logToVisualize.map((logItem) => (
                  <StyledLogRow>
                    <StyledInfo>
                      <StyledLogDate>
                        Data da Ação:
                        {' '}
                        {format(
                          new Date(logItem.created_at),
                          'dd/MM/yyyy HH:mm:ss',
                        )}
                      </StyledLogDate>
                    </StyledInfo>

                    <StyledRowInfo>
                      <StyledInfo>
                        <p>
                          Status:&nbsp;
                          <b>{actionLogStatus[logItem.action]}</b>
                        </p>
                      </StyledInfo>

                      <StyledInfo>
                        <p>
                          Usuário:&nbsp;
                          <b>{logItem.user_name}</b>
                        </p>
                      </StyledInfo>
                    </StyledRowInfo>

                    <StyledRowInfo>
                      <StyledInfo>
                        <p>
                          Descrição:&nbsp;
                          {logItem.description}
                        </p>
                      </StyledInfo>

                      <StyledInfo>
                        <p>
                          Email:&nbsp;
                          <b>{logItem.user_email}</b>
                        </p>
                      </StyledInfo>
                    </StyledRowInfo>
                    {shouldShowErrorDetailsAccordion(logItem.action) && (
                      <StyledRowInfo>
                        <StyledInfo>
                          <LogErrorDataAccordion
                            errorData={logItem.error_data}
                          />
                        </StyledInfo>
                      </StyledRowInfo>
                    )}
                  </StyledLogRow>
                ))}

              {!logDialogLoading && isEmpty(logToVisualize) && (
                <p>
                  Não foi possível processar um histórico para esta postagem em
                  massa!
                </p>
              )}
            </StyledFormContent>
          </StyledPostWrapper>
        )}
      </StyledPostAndFilterWrapper>
    </StyledCreatePostDialog>
  );
};

export default CreatePostDialog;
