import styled, { css } from 'styled-components';
import { shade } from 'polished';

import { TAddSpecialDate } from '../../../../../../types/TWorkingHours';

export const StyledAddSpecialDate = styled.div<Pick<TAddSpecialDate, 'hasSpeciaDates'>>`
  display: flex;
  flex-direction: column;
  padding: 3rem 2rem;
  
  ${props => props.hasSpeciaDates === true && css`
    border-bottom: 0.2rem solid ${shade(0.05, props.theme.colors.white)};
    margin-bottom: 2rem;
    padding-bottom: 4rem;
  `};
`;

export const StyledHeader = styled.header<{ newSpecialDateIsOpen: boolean }>`
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  width: 100%;

  .add-special-date-input {
    margin-bottom: 0;
  }
  
  .is-24-hours-button {
    margin-top: 2rem;
  }
  
  ${props => props.newSpecialDateIsOpen === true && css`
    margin-bottom: 2rem;  
  `};
`;

export const StyledHeaderTitle = styled.h2`
  font-size: 1.6rem;
  line-height: 1;
  margin-bottom: 2rem;
  color: ${props => props.theme.colors.primary};
`;

export const StyledFieldsWrapper = styled.div`
  display: grid;
  grid-template-columns: 30% 20% 12% 12% 17%;
  column-gap: 2rem;
  align-items: center;
  width: 100%;
  
  .add-special-date-button {
    margin-top: auto;
    margin-bottom: 0.6rem;
  }
  
  .add-special-date-switch {
    margin-top: 2rem;
  }
  
  .add-special-date-loading {
    margin-right: 1rem;
  }
`;

export const StyledEditActions = styled.div`
  display: flex;
  align-items: center;
  margin-top: auto;
  margin-bottom: 0.5rem;
  
  .edit-special-date-button,
  .update-special-date-loading {{
    margin-right: 1rem;
  }
`;

export const StyledSpecialDateIs24Hours = styled.span`
  font-size: 1.4rem;
  color: ${props => props.theme.colors.red};
`;
