import axios from 'axios';

import type { TFindBatchLocationLog } from '../../../types/TBatchUpdate';

const findBatchLocationLog = async ({
  userAccessToken,
  batchId,
  batchLocationId,
  page = 0,
  pageSize = 10,
  isFetching,
}: TFindBatchLocationLog): Promise<{
  data: any;
  status: 'ERROR' | 'SUCCESS';
}> => {
  try {
    if (isFetching) isFetching(true);

    const { data } = await axios.get(
      `${process.env.REACT_APP_SAAS_BATCH_GOOGLE_MODULES}/batch-location-log?batchId=${batchId}&batchLocationId=${batchLocationId}&page=${page}&pageSize=${pageSize}`,
      {
        headers: {
          Authorization: `Bearer ${userAccessToken}`,
        },
      },
    );

    return {
      status: 'SUCCESS',
      data,
    };
  } catch (err: any) {
    return {
      status: 'ERROR',
      data: err.response,
    };
  } finally {
    if (isFetching) isFetching(false);
  }
};

export default findBatchLocationLog;
