import React from 'react';
import { SvgIconComponent } from '@material-ui/icons/';
import * as Icons from '@material-ui/icons/';

import * as R from 'ramda';

import Tooltip from '../tooltip';

import { TIconButtonBaseProps } from '../../types/TIconButton';

import {
  StyledIconButton,
  StyledDisabledIcon,
  StyledLabel,
  StyledWrapper,
} from './icon-button-style';

const IconButton = ({
  tooltip,
  onClick,
  label,
  icon = 'Help',
  className,
  placement = 'bottom',
  isWhite = false,
  isBlack = false,
  isError = false,
  isWarning = false,
  isSuccess = false,
  tooltipVariant = 'primary',
  disabled = false,
  showCustomIcon = false,
  CustomIcon,
  forceUseDefaultCursor = false,
  iconSize = 2.2,
}: TIconButtonBaseProps) => {
  const [IconComponent, setIconComponent] = React.useState<SvgIconComponent>(
    Icons.Help,
  );

  const handleClassName = () => {
    if (isSuccess) return 'success-icon';
    if (isWarning) return 'warning-icon';
    if (isError) return 'error-icon';
    if (isWhite) return 'white-icon';
    if (isBlack) return 'black-icon';
    if (disabled) return 'disabled';
    return 'icon-button__icon';
  };

  React.useEffect(() => {
    R.forEachObjIndexed(
      (SVGIcon: SvgIconComponent, SVGComponentName: string) => {
        if (SVGComponentName === icon) setIconComponent(SVGIcon);
      },
      Icons,
    );
  }, [icon]);

  if (disabled) {
    return (
      <Tooltip title={tooltip} arrow placement={placement} variant="grey">
        <StyledDisabledIcon iconSize={iconSize} className={className}>
          <IconComponent className={handleClassName()} />
        </StyledDisabledIcon>
      </Tooltip>
    );
  }

  const handleOnClick = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ) => {
    if (disabled) return;
    if (onClick) onClick(event);
  };

  return tooltip ? (
    <Tooltip
      title={tooltip}
      arrow
      placement={placement}
      variant={tooltipVariant}
    >
      <StyledIconButton
        className={`${className} ${handleClassName()}`}
        onClick={(event) => handleOnClick(event)}
        tooltipVariant={tooltipVariant}
        forceUseDefaultCursor={forceUseDefaultCursor}
        iconSize={iconSize}
      >
        <StyledWrapper>
          {showCustomIcon && <CustomIcon className={handleClassName()} />}
          {!showCustomIcon && <IconComponent className={handleClassName()} />}
          {label && <StyledLabel variant={tooltipVariant}>{label}</StyledLabel>}
        </StyledWrapper>
      </StyledIconButton>
    </Tooltip>
  ) : (
    <StyledWrapper>
      <StyledIconButton
        className={`${className} ${handleClassName()}`}
        onClick={(event) => handleOnClick(event)}
        tooltipVariant={tooltipVariant}
        forceUseDefaultCursor={forceUseDefaultCursor}
        iconSize={iconSize}
      >
        {showCustomIcon && <CustomIcon className={handleClassName()} />}
        {!showCustomIcon && <IconComponent className={handleClassName()} />}
      </StyledIconButton>
      {label && <StyledLabel variant={tooltipVariant}>{label}</StyledLabel>}
    </StyledWrapper>
  );
};
export default IconButton;
