import React from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { Button } from '@material-ui/core';
import { Card } from 'react-bootstrap';

import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';

import { Creators as AuthActions } from '../../redux/ducks/auth';

import {
  CompanyNavBar,
  CompanyInfoNavBar,
} from '../../components';

import getCompanyWorkingHoursModels from '../../services/companies/getCompanyWorkingHoursModels';
import getCompanySpecialDatesModels from '../../services/companies/getCompanySpecialDatesModels';

const cardButtonStyle = {
  color: 'white',
  fontFamily: 'Poppins',
  fontWeight: 'bold',
  backgroundColor: '#4385F5',
  width: '15em',
};

const MyCompanyScheduleModels = () => {
  const history = useHistory();
  const reduxDispatch = useDispatch();

  const [workingHours, setWorkingHours] = React.useState([]);
  const [specialDates, setSpecialDates] = React.useState([]);

  const { user } = useSelector((state) => state.AuthReducer);
  const { activeCompany } = useSelector((state) => state.CompanyReducer);

  const setTokenLikeExpired = () => {
    reduxDispatch(AuthActions.setUser({ ...user, accessToken: 'expired' }));
  };

  React.useEffect(() => {
    const fetchData = async () => {
      const { working_hours_models: workingHoursModelsArray } = await getCompanyWorkingHoursModels({
        accessToken: user.accessToken,
        id: activeCompany.id,
        setTokenLikeExpired,
      });

      const { special_date_models: specialDateModelsArray } = await getCompanySpecialDatesModels({
        accessToken: user.accessToken,
        id: activeCompany.id,
        setTokenLikeExpired,
      });

      setWorkingHours(workingHoursModelsArray);
      setSpecialDates(specialDateModelsArray);
    };

    fetchData();
  }, [activeCompany]);

  const onEditClicked = (id) => {
    history.push(`/company/update-working-hours-model/${id}`);
  };

  const onEditSpecialDateClicked = (id) => {
    history.push(`/company/update-special-date-model/${id}`);
  };

  return (
    <div>
      <CompanyNavBar returnUrl="/company/working-hours-models" />
      <CompanyInfoNavBar activeButton={2} />
      <div style={{ padding: '1em' }}>
        <div className="font-poppins">
          <text className="page-title">Modelos de Funcionamento</text>
          <div className="model-card-div">
            <Card>
              <Card.Body>
                <div className="creation-card-style">
                  <AddCircleOutlineIcon
                    style={{ fontSize: '10em', color: 'gray' }}
                  />
                  <Button
                    style={cardButtonStyle}
                    variant="contained"
                    onClick={() => {
                      history.push('/company/create-working-hours-model');
                    }}
                  >
                    Criar Modelo
                  </Button>
                </div>
              </Card.Body>
            </Card>
            {workingHours.map((element, key) => (
              <Card className="card-spacing" key={key}>
                <Card.Body>
                  <Card.Title className="font-poppins">
                    {element.description}
                  </Card.Title>
                  {element.is_default ? (
                    <Card.Subtitle className="font-poppins">
                      Horário Padrão
                    </Card.Subtitle>
                  ) : (
                    <></>
                  )}
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      margin: '0.5em',
                    }}
                  >
                    <text>
                      {`Segunda: ${
                        element.monday === 'closed' ? 'fechado' : element.monday
                      }`}

                    </text>
                    <text>
                      {`Terça: ${
                        element.tuesday === 'closed' ? 'fechado' : element.tuesday
                      }`}

                    </text>
                    <text>
                      {`Quarta: ${
                        element.wednesday === 'closed'
                          ? 'fechado'
                          : element.wednesday
                      }`}

                    </text>
                    <text>
                      {`Quinta: ${
                        element.thursday === 'closed'
                          ? 'fechado'
                          : element.thursday
                      }`}

                    </text>
                    <text>
                      {`Sexta: ${
                        element.friday === 'closed' ? 'fechado' : element.friday
                      }`}

                    </text>
                    <text>
                      {`Sábado: ${
                        element.saturday === 'closed'
                          ? 'fechado'
                          : element.saturday
                      }`}

                    </text>
                    <text>
                      {`Domingo: ${
                        element.sunday === 'closed' ? 'fechado' : element.sunday
                      }`}

                    </text>
                  </div>
                  <Button
                    style={cardButtonStyle}
                    variant="contained"
                    onClick={() => {
                      onEditClicked(element.id);
                    }}
                  >
                    Editar
                  </Button>
                </Card.Body>
              </Card>
            ))}
          </div>
        </div>
        <div className="font-poppins">
          <text className="page-title">Modelos de datas especiais</text>
          <div className="model-card-div">
            <Card>
              <Card.Body>
                <div className="creation-card-style">
                  <AddCircleOutlineIcon
                    style={{ fontSize: '10em', color: 'gray' }}
                  />
                  <Button
                    style={cardButtonStyle}
                    variant="contained"
                    onClick={() => {
                      history.push('/company/create-special-date-model');
                    }}
                  >
                    Criar Modelo
                  </Button>
                </div>
              </Card.Body>
            </Card>
            {specialDates.map((element, key) => (
              <Card className="card-spacing">
                <Card.Body>
                  <Card.Title className="font-poppins">
                    {element.name}
                  </Card.Title>

                  <Card.Text>
                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                      <text style={{ color: 'gray' }}>Data:</text>
                      <text>{element.date}</text>
                    </div>
                  </Card.Text>
                  <Card.Text>
                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                      <text style={{ color: 'gray' }}>Funcionamento:</text>
                      <text>{element.is_open ? 'Aberto' : 'Fechado'}</text>
                    </div>
                  </Card.Text>
                  <Card.Text>
                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                      <text style={{ color: 'gray' }}>Horário:</text>
                      <text>{`${element.opening_hour} - ${element.closing_hour}`}</text>
                    </div>
                  </Card.Text>

                  <Button
                    style={cardButtonStyle}
                    variant="contained"
                    onClick={() => {
                      onEditSpecialDateClicked(element.id);
                    }}
                  >
                    Editar
                  </Button>
                </Card.Body>
              </Card>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default MyCompanyScheduleModels;
