import styled, { css } from 'styled-components';
import { lighten } from 'polished';

export const StyledListingItem = styled.li`
    display: flex;
    flex-direction: column;
    border-bottom: 1px solid ${props => lighten(0.3, props.theme.colors.grey)};

    &:last-of-type {
        border-bottom: 0;
    }
`;

export const StyledRowInfo = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem 0;

    &:last-of-type {
        border-bottom: 0;
    }
    
    .infos-name-and-description {
      width: 75%;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      font-size: 1.2rem;
      margin-right: auto;
    }
    
    h4 {
        width: 100%;
        text-align: left;
        font-size: 1.4rem;
        margin-bottom: 0;
    }
`;

export const StyledAction = styled.span`
    font-size: 1.2rem;
    padding-left: 1.5rem;
    display: flex;
    color: ${props => props.theme.colors.primary};
    cursor: pointer;

    ${props => props.secondary && css`
        color: ${props.theme.colors.secondary};
    `}
`;
