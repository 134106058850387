import * as R from 'ramda';

export const isResponseDefinedAndSuccessful = <TExpectedResponse>(
  status: 'SUCCESS' | 'ERROR',
  data: any,
): data is TExpectedResponse => {
  return status === 'SUCCESS' && !R.isNil(data);
};

export const isResponseArrayAndSuccessful = <TExpectedResponse>(
  status: 'SUCCESS' | 'ERROR',
  data: any,
): data is TExpectedResponse => {
  return status === 'SUCCESS' && Array.isArray(data);
};
