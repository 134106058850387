import styled, { css } from 'styled-components';

import {
  Avatar,
  List,
  ListItem,
  ListItemText,
  ListItemAvatar,
  ListItemSecondaryAction,
} from '@material-ui/core';
import { shade } from 'polished';

export const StyledAvatar = styled(Avatar)``;

export const StyledList = styled(List)`
  .MuiListItem-container:last-child {
    .MuiListItem-root {
      border-bottom: none;
    }
  }
`;

export const StyledListItem = styled(ListItem)`
  border-bottom: 0.1rem solid ${props => shade(0.1, props.theme.colors.white)};

  ${props => props.isDisable && css`
    opacity: 0.5;
    pointer-events: none;
  `}
`;

export const StyledListItemText = styled(ListItemText)`
  .MuiTypography-body1{
    font-size: 1.4rem;
    font-family: Poppins;  
  }
`;

export const StyledListItemAvatar = styled(ListItemAvatar)``;

export const StyledListSecondaryAction = styled(ListItemSecondaryAction)``;
