import axios from 'axios';
import { TUpdateLocationConnection } from '../../types/TPlatformsLinkWithLocation';

export default async function updateLocationConnection({
  accessToken,
  token,
  refreshToken,
  tokenExpiresAt,
  status,
  code,
  userId,
  connectionInfo,
  id,
  setTokenLikeExpired,
}: TUpdateLocationConnection) {
  try {
    const { data } = await axios.put(
      `${process.env.REACT_APP_LOCATION_CONNECTIONS}/${id}`,
      {
        accessToken,
        token,
        refresh_token: refreshToken,
        token_expires_at: tokenExpiresAt,
        status,
        code,
        user_id: userId,
        connection_info: connectionInfo,
      },
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      },
    );

    return data;
  } catch (err: any) {
    if (err.response?.status === 401 && setTokenLikeExpired) setTokenLikeExpired();

    return null;
  }
}
