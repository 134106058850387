// eslint-disable-next-line no-use-before-define
import React, {
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';

import { useSnackbar } from 'notistack';
import { isNil } from 'ramda';
import { InputSwitch, Tooltip } from '../../../components';

import {
  StyledControlledInput,
  StyledDescriptionAreaCount,
  StyledDescriptionWrapper,
  StyledDisabledInput,
  StyledDisclaimer,
  StyledFloatingIcons,
  StyledInput,
  StyledInputMask,
  StyledInputWrap,
  StyledLabel,
  StyledPhoneAndroidIcon,
  StyledPhoneIcon,
  StyledSwitchInputEnable,
  StyledTextarea,
  StyledTooltipLandline,
  StyledTooltipPassword,
  StyledTooltipWarning,
  StyledVisibilityOffPasswordIcon,
  StyledVisibilityPasswordIcon,
  StyledWarningIcon,
} from './controlled-input-styles';

import {
  getLocationAddress,
  masks,
} from './helpers';

import { TControlledInput } from './types';
import { formattedAddress } from '../infos/helpers';
import { EFieldsToBeFilled } from '../types';
import CountryCodeSelect from '../../../components/country-code-select';
import isNilOrEmpty from '../../../utils/is-null-or-empty';
import { TLocationEntity } from '../../../types/TLocation';

const ControlledInput = ({
  id,
  disclaimer,
  setParentValue,
  label,
  descriptionSize = 1,
  hasMask = false,
  isSwitch = false,
  isTextArea = false,
  isPassword = false,
  isPhone = false,
  hasBorder = false,
  hasRadius = false,
  uppercase = false,
  isDisabled = false,
  defaultValue,
  description,
  type,
  required,
  className,
  disabled,
  placeholder,
  maxLength,
  name,
  fieldOnFocus = '',
  defaultLandline = false,
  setDefaultLandline,
  country = 'Brazil',
  setFieldOnFocus = () => {},
}: TControlledInput) => {
  const { enqueueSnackbar } = useSnackbar();

  const ref = React.createRef<any>();

  const [fieldType, setFieldType] = useState(type);
  const [inputFocus, setInputFocus] = useState(false);
  const [value, setValue] = useState(defaultValue || '');
  const [switchEnable, setSwitchEnable] = useState(defaultValue);
  const [isTooltipOpen, setIsToolTipOpen] = useState(false);
  const [landlineEnable, setLandlineEnabled] = useState(false);

  const hasToShowInput = useMemo(() => !(isDisabled && !switchEnable), [isDisabled, switchEnable]);

  useEffect(() => {
    setValue(defaultValue || '');
    setLandlineEnabled(defaultLandline);
  }, [defaultValue, defaultLandline]);

  useEffect(() => {
    if (name === EFieldsToBeFilled[fieldOnFocus]) {
      const { current } = ref;
      if (current.focus) {
        current.focus();
      } else {
        const node = current.getInputDOMNode();
        node.focus();
      }
    }
  }, [fieldOnFocus]);

  const showTooltip = useCallback(() => (required ? required && value.length < 1 : false), [value]);

  const handleChangeInputMask = useCallback(async (newValue: string) => {
    if (name === 'zip_code' && country !== 'Brazil') {
      const isNumber = /\d/g;
      const lastInputChar = newValue[newValue.length - 1];

      return setValue(prev => {
        if (isNilOrEmpty(newValue)) return newValue;
        return (isNumber.test(lastInputChar) ? newValue : prev);
      });
    }
    setValue(newValue);
  }, [value, country, name]);

  const handleBlurEvent = useCallback(async (show:boolean = true) => {
    let newValue = value;
    setFieldOnFocus('');

    if (isSwitch || (!show && disabled)) {
      newValue = !switchEnable;
    }

    if (name === 'zip_code') {
      if (country !== 'Brazil') return setParentValue(prev => ({ ...prev, [name]: newValue }));

      const zipCodeRegex = /\d{2}\.\d{3}-\d{3}/g;

      if (!zipCodeRegex.test(newValue)) {
        return setParentValue(prev => ({ ...prev, [name]: newValue }));
      }

      const {
        bairro,
        cep,
        localidade,
        logradouro,
        uf,
      } = await getLocationAddress({ zipcode: newValue, feedbackMessage: enqueueSnackbar }) || {};

      return setParentValue(prev => {
        if (prev.zip_code === newValue) return prev;
        const [, number] = formattedAddress(prev.address1);
        return ({
          ...prev,
          city: localidade,
          state: uf,
          zip_code: cep,
          neighborhood: bairro,
          address: logradouro,
          address1: `${logradouro}, ${number}`,
          'address-number': number,
        });
      });
    }

    if (name === 'address1') {
      return setParentValue(prev => {
        const [, number] = formattedAddress(prev.address1);
        return {
          ...prev,
          address: newValue,
          address1: `${newValue || ' '}, ${number}`,
          'address-number': number,
        };
      });
    }

    if (name === 'address-number') {
      return setParentValue(prev => {
        const [address] = formattedAddress(prev.address1);
        return {
          ...prev,
          address1: `${address}, ${newValue}`,
          'address-number': newValue,
        };
      });
    }

    if (name === 'does_not_serve_at_this_address') {
      return setParentValue(prev => ({ ...prev, [name || '']: !newValue }));
    }

    return setParentValue(prev => ({ ...prev, [name || '']: newValue }));
  }, [value, switchEnable]);

  const changePhoneType = useCallback(() => {
    if (setDefaultLandline) {
      setDefaultLandline(!landlineEnable);
    }
    setLandlineEnabled(prev => !prev);
  }, [landlineEnable]);

  const getInput = useCallback((show:boolean = false) => {
    if (name === 'country') {
      return (
        <CountryCodeSelect
          countryCode={defaultValue}
          setCountryCode={
            (newValue: string) => setParentValue((prev: TLocationEntity) => ({
              ...prev,
              [name]: newValue,
              address1: '',
              address: '',
              'address-number': '',
              zip_code: '',
              city: '',
              state: '',
              neighborhood: '',
            }))
          }
          hasLabel={false}
        />
      );
    }

    if (!show && isDisabled && !switchEnable) {
      return <StyledDisabledInput>Desabilitado</StyledDisabledInput>;
    }

    if (!show && isDisabled) {
      return (
        <StyledSwitchInputEnable>
          <InputSwitch
            changeChecked={() => {
              setSwitchEnable(prev => !prev);
              handleBlurEvent(false);
            }}
            checked={switchEnable}
          />
        </StyledSwitchInputEnable>
      );
    }

    if (show && isSwitch) {
      return (
        <InputSwitch
          checked={switchEnable}
          changeChecked={() => {
            setSwitchEnable(prev => !prev);
            handleBlurEvent();
          }}
          label={label}
          name={name}
        />
      );
    }

    if (show && hasMask) {
      let mask = masks.phone;
      if (isPhone && !landlineEnable) {
        mask = masks.cellphone;
      }
      if (!isPhone) {
        mask = country === 'Brazil' ? masks.zipcode : '';
      }
      return (
        <StyledTooltipWarning
          placement="top"
          open={isTooltipOpen}
          onOpen={() => setIsToolTipOpen(showTooltip())}
          onClose={() => setIsToolTipOpen(false)}
          title="Campo obrigatório"
          arrow
        >
          <StyledInputMask
            ref={ref}
            autoComplete="off"
            name={name}
            type={fieldType}
            disabled={disabled}
            id={id}
            value={value}
            onChange={(e) => handleChangeInputMask(e.target.value)}
            mask={mask}
            isRequired={!!required}
            alwaysShowMask
            onFocus={() => setInputFocus(true)}
            onBlur={() => {
              setInputFocus(false);
              handleBlurEvent();
            }}
          />
        </StyledTooltipWarning>
      );
    }

    if (show && isTextArea) {
      return (
        <StyledDescriptionWrapper>
          <StyledTooltipWarning
            placement="top"
            open={isTooltipOpen}
            onOpen={() => setIsToolTipOpen(showTooltip())}
            onClose={() => setIsToolTipOpen(false)}
            title="Campo obrigatório"
            arrow
          >
            <StyledTextarea
              ref={ref}
              placeholder={placeholder}
              autoComplete="off"
              name={name}
              type={fieldType}
              defaultValue={defaultValue}
              disabled={disabled}
              id={id}
              isPassword={isPassword}
              maxLength={maxLength}
              uppercase={uppercase}
              rows={5}
              isRequired={(!isNil(required) ? required && value.length === 0 : false)}
              onChange={({ target }) => setValue(target.value)}
              onBlur={() => handleBlurEvent()}
              isMaxLength={value.length > descriptionSize}
            />
          </StyledTooltipWarning>

          {value.length > 0
            && (descriptionSize) && (
              <StyledDescriptionAreaCount
                isMaxLength={value.length > (descriptionSize)}
              >
                {value.length}
                /
                {descriptionSize}
              </StyledDescriptionAreaCount>
          )}
        </StyledDescriptionWrapper>
      );
    }

    if (show) {
      return (
        <StyledTooltipWarning
          placement="top"
          open={isTooltipOpen}
          onOpen={() => setIsToolTipOpen(showTooltip())}
          onClose={() => setIsToolTipOpen(false)}
          title="Campo obrigatório"
          arrow
        >
          <StyledInput
            ref={ref}
            placeholder={placeholder}
            autoComplete={isPassword ? 'new-password' : 'off'}
            name={name}
            type={fieldType}
            defaultValue={defaultValue}
            disabled={disabled}
            id={id}
            isPassword={isPassword}
            maxLength={maxLength}
            uppercase={uppercase}
            isRequired={!!required}
            onChange={({ target }) => setValue(target.value)}
            onBlur={() => handleBlurEvent()}
          />
        </StyledTooltipWarning>
      );
    }

    return <></>;
  }, [
    hasToShowInput,
    handleChangeInputMask,
    defaultValue,
    landlineEnable,
  ]);

  const getIcon = useCallback(() => {
    if (isPassword) {
      const title = type === 'password' ? 'Mostrar senha' : 'Esconder senha';
      return (
        <StyledTooltipPassword title={title} arrow>
          {
            fieldType === 'text'
              ? (
                <StyledVisibilityOffPasswordIcon
                  className="icon-margin"
                  onClick={() => setFieldType('password')}
                />
              )
              : (
                <StyledVisibilityPasswordIcon
                  className="icon-margin"
                  onClick={() => setFieldType('text')}
                />
              )
          }
        </StyledTooltipPassword>
      );
    }
    if (isPhone) {
      let mask = masks.phone;
      if (!landlineEnable) {
        mask = masks.cellphone;
      }
      if (required && value === mask.replaceAll('9', '_')) {
        return <StyledWarningIcon />;
      }
      const title = !landlineEnable ? 'Mudar para telefone fixo' : 'Mudar para telefone celular';
      return (
        <StyledTooltipLandline
          placement="top"
          title={title}
          arrow
        >
          {landlineEnable
            ? (
              <StyledPhoneIcon
                className="icon-margin"
                onClick={changePhoneType}
              />
            )
            : (
              <StyledPhoneAndroidIcon onClick={changePhoneType} />
            )}
        </StyledTooltipLandline>
      );
    }

    if (required && !value) {
      return <StyledWarningIcon />;
    }
    return <></>;
  }, [changePhoneType, value]);

  return (
    <StyledControlledInput>
      {label && !isSwitch && (
      <StyledLabel htmlFor={label}>
        {description ? (
          <Tooltip title={description} arrow placement="right">
            <span>
              {label}
              { required && '*' }
            </span>
          </Tooltip>
        ) : (
          <>
            { label }
            { required && '*' }
          </>
        )}
      </StyledLabel>
      )}
      <StyledInputWrap
        className={className}
        hasBorder={hasBorder}
        hasRadius={hasRadius}
        inputFocus={inputFocus}
      >
        {hasToShowInput && (
          <>
            {getInput(hasToShowInput)}
            <StyledFloatingIcons>
              {getIcon()}
            </StyledFloatingIcons>
          </>
        )}
        {isDisabled && getInput()}
      </StyledInputWrap>

      {disclaimer && <StyledDisclaimer>{disclaimer}</StyledDisclaimer>}
    </StyledControlledInput>
  );
};

export default React.memo(ControlledInput);
