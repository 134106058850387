import React from 'react';

import { Input } from '../../../../components';

import { StyledFormAddSensitiveWord } from './form-add-sensitive-word-styles';

const FormAddSensitveWord = ({ handleAddSensitiveWord }) => {
  const handleSubmitLocationToFilter = ({ key: keyPressed, target: inputTarget }) => {
    if (keyPressed !== 'Enter') return;

    const inputValue = inputTarget.value.trim();
    if (!inputValue) return;

    const newSensitiveWords = inputValue.split(' ');
    handleAddSensitiveWord(newSensitiveWords);
    const inputField = inputTarget;
    inputField.value = [];
  };

  return (
    <StyledFormAddSensitiveWord>
      <Input
        className="sensitive-words"
        inputOptions={{ type: 'text' }}
        placeholder="Adicionar palavras sensíveis"
        border
        submitFunction={handleSubmitLocationToFilter}
      />
    </StyledFormAddSensitiveWord>
  );
};

export default FormAddSensitveWord;
