import React, { useEffect } from 'react';

import { StyledSwitch } from './switch-styles';

const Switch = ({
  checked,
  changeChecked,
  id,
  name,
  fieldRefSwitch,
  disabled,
  secondarySwitch,
}) => {
  const [isChecked, setIsChecked] = React.useState(false);

  useEffect(() => {
    setIsChecked(checked);
  }, [checked]);

  return (
    <StyledSwitch
      ref={fieldRefSwitch}
      disabled={disabled}
      name={name}
      id={id}
      checked={isChecked}
      onClick={changeChecked}
      focusVisibleClassName="focus-visible"
      disableRipple
      secondarySwitch={secondarySwitch}
    />
  );
};

export default Switch;
