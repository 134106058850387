import axios from 'axios';

type TGetLoginHistoryFromUser = {
  userId: string;
  accessToken: string;
  page: number;
  pageSize: number;
  setTokenLikeExpired?: () => void;
}

export async function getLoginHistoryFromUser({ userId, accessToken, page, pageSize, setTokenLikeExpired }: TGetLoginHistoryFromUser) {
  try {
    const response = await axios.get(`${process.env.REACT_APP_LOGIN_HISTORY_API_URL}/get-all-from-user/${userId}`,
    {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
      params: {
        page,
        pageSize
      }
    });
    return response.data;
  } catch (error: any) {
    if (error.response?.status === 401 && setTokenLikeExpired) setTokenLikeExpired();
    return error;
  }
}