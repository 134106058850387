/* eslint-disable consistent-return */
import React, { useEffect, useState } from 'react';
import { isNil } from 'ramda';
import { useSelector } from 'react-redux';

import type { TRootStateRedux } from '../types/TRootStateRedux';
import type { TListLocationsProps } from '../types/TListLocations';
import { EGroupType } from '../types/TLocation';

import APIGetCompanyLocations from '../services/locations/getCompanyLocations';
import APIGetAllGroupLocations from '../services/locations/getAllGroupLocation';
import { getLocationsWithoutLinked } from '../services/locations/get-locations-without-linked';

export const ListLocations = ({
  accessToken,
  page,
  query,
}: TListLocationsProps) => {
  const { activeLocationGroupId } = useSelector(
    (state: TRootStateRedux) => state.LocationGroupReducer,
  );
  const { activeCompanyId, showLocationsByCompany } = useSelector(
    (state: TRootStateRedux) => state.CompanyReducer,
  );

  const [locations, setLocations] = useState<any>([]);
  const [locationsNoEligibles, setLocationsNoEligibles] = useState<any>([]);
  const [isFetching, setIsFetching] = useState(true);

  const getLocations = async () => {
    let requestOptions = {
      accessToken,
      query,
      page,
      companyId: 0,
      locationGroupId: 0,
    };

    if (showLocationsByCompany) {
      requestOptions = { ...requestOptions, companyId: activeCompanyId };
    } else {
      requestOptions = { ...requestOptions, locationGroupId: activeLocationGroupId };
    }

    const locationsDataFetch = showLocationsByCompany
      ? await APIGetCompanyLocations(requestOptions)
      : await APIGetAllGroupLocations(requestOptions);

    if (isNil(locationsDataFetch)) return;

    const noEligiblesLocations = await getLocationsWithoutLinked({
      group: showLocationsByCompany ? EGroupType.COMPANY : EGroupType.LOCATIONS_GROUP,
      groupId: showLocationsByCompany ? activeCompanyId : activeLocationGroupId,
      userAccessToken: accessToken,
    });

    if (isNil(noEligiblesLocations)) return;

    setLocations(locationsDataFetch);
    setLocationsNoEligibles(noEligiblesLocations);
    setIsFetching(false);

    return {
      locations,
      locationsNoEligibles,
      isFetching,
    };
  };

  useEffect((): any => {
    getLocations();
  }, [page, query]);

  return {
    locations,
    locationsNoEligibles,
    isFetching,
  };
};
