import axios from 'axios';
import { TCreateLocationConnection } from '../../types/TPlatformsLinkWithLocation';

export default async function createLocationConnection({
  accessToken,
  createdBy,
  token,
  refreshToken,
  tokenExpiresAt,
  status,
  code = '',
  platform,
  connectionInfo,
  location,
  setTokenLikeExpired,
}: TCreateLocationConnection) {
  try {
    const { data } = await axios.post(
      `${process.env.REACT_APP_LOCATION_CONNECTIONS}`,
      {
        accessToken,
        created_by: createdBy,
        token,
        refresh_token: refreshToken,
        token_expires_at: tokenExpiresAt,
        status,
        code,
        platform,
        connection_info: connectionInfo,
        location,
      },
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      },
    );

    return data;
  } catch (err: any) {
    if (err.response?.status === 401 && setTokenLikeExpired) setTokenLikeExpired();

    return null;
  }
}
