import { format } from 'date-fns';
import type { MUIDataTableColumn } from 'mui-datatables';
import type { TServiceBatchLocation } from '../../../../types/TServiceBatch';
import type { TTableData } from '../../../../types/TTable';

export const initialTableColumns: MUIDataTableColumn[] = [
  {
    name: 'id',
    label: 'Id',
    options: {
      display: false,
      sort: false,
    },
  },
  {
    name: 'location-name',
    label: 'Nome do local',
    options: {
      sort: false,
    },
  },
  {
    name: 'updated-at',
    label: 'Data de atualização',
    options: {
      sort: false,
    },
  },
  {
    name: 'created-at',
    label: 'Data de criação',
    options: {
      display: false,
      sort: false,
    },
  },
  {
    name: 'status',
    label: 'Status',
    options: {
      sort: false,
    },
  },
  {
    name: 'history',
    label: 'Histórico',
    options: {
      sort: false,
    },
  },
  {
    name: 'actions',
    label: 'Ações',
    options: {
      sort: false,
    },
  },
];

export const normalizeServiceBatchLocationsToTable = (
  serviceBatchLocations: TServiceBatchLocation[]
): TTableData =>
  serviceBatchLocations.map((serviceBatchLocation) => [
    {
      columnRef: 'id',
      visible: false,
      value: serviceBatchLocation.id,
    },
    {
      columnRef: 'location-name',
      visible: true,
      value: serviceBatchLocation.name,
    },
    {
      columnRef: 'updated-at',
      visible: true,
      value: format(
        new Date(serviceBatchLocation.updated_at),
        'dd/MM/yyyy HH:mm:ss'
      ),
    },
    {
      columnRef: 'created-at',
      visible: false,
      value: format(
        new Date(serviceBatchLocation.created_at),
        'dd/MM/yyyy HH:mm:ss'
      ),
    },
    {
      columnRef: 'status',
      visible: true,
      value: serviceBatchLocation.status,
    },
  ]);
