export const enableButtonAddCategoryGrouping = ({
  facebookData,
  googleData,
  foursquareData,
  selectedFoursquareCategories,
  selectedFacebookCategories,
  selectedGoogleCategories,
  facebookInitialValue,
  googleInitialValue,
  groupDescriptionValue,
  groupNameValue,
}) => {
  const selectedsCategoriesNoEmpty = selectedFacebookCategories.length > 0 || selectedGoogleCategories.length > 0 || selectedFoursquareCategories.length > 0;
  const platformsNoEmpty = facebookData && googleData && foursquareData;
  const nameAndDescription = groupNameValue && groupDescriptionValue;

  if (facebookInitialValue !== null && googleInitialValue !== null) {
    const initialPlatformsValueNoEmpty = facebookInitialValue && googleInitialValue;

    if (initialPlatformsValueNoEmpty) {
      return true;
    }
  }

  if (selectedsCategoriesNoEmpty && nameAndDescription) {
    return true;
  } if (selectedsCategoriesNoEmpty && platformsNoEmpty && nameAndDescription) {
    return true;
  }

  return false;
};

export const resetField = ({
  setSelectedFacebookCategories,
  setSelectedGoogleCategories,
  setSelectedFoursquareCategories,
}) => {
  setSelectedFacebookCategories([]);
  setSelectedGoogleCategories([]);
  setSelectedFoursquareCategories([]);
};

export const fieldsIsOk = ({
  fields,
  snackbar,
}) => {
  const {
    groupNameValue,
    groupDescriptionValue,
    selectedFacebookCategories,
    selectedGoogleCategories,
    selectedFoursquareCategories,
  } = fields;

  let validateIsOk = true;

  if (!String(groupNameValue).trim()) {
    snackbar('Preencha o campo nome!', { variant: 'error' });
    
    validateIsOk = false;
  }

  if (!String(groupDescriptionValue).trim()) {
    snackbar('Preencha o campo descrição!', { variant: 'error' });

    validateIsOk = false;
  }

  if (!selectedFacebookCategories.length) {
    snackbar('Escolha pelo menos uma categoria do facebook!', { variant: 'error' });
    validateIsOk = false;
  }

  if (!selectedGoogleCategories.length) {
    snackbar('Escolha pelo menos uma categoria do google!', { variant: 'error' });
    validateIsOk = false;
  }

  if (!selectedFoursquareCategories.length) {
    snackbar('Escolha pelo menos uma categoria do foursquare!', { variant: 'error' });
    validateIsOk = false;
  }

  return validateIsOk;
};
