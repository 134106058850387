import styled from 'styled-components';
import { Paper } from '@material-ui/core';

import { devices } from '../../assets/styles/devices';

export const StyledChooseUser = styled.div`
  display: flex;
  flex-direction: column;
`;

export const StyledChooseUserTitle = styled.h3`
  font-weight: 700;
  font-size: 1.5rem;
  margin-bottom: 2rem;
`;

export const StyledDisclaimer = styled.span`
  font-size: 1.4rem;
  margin-bottom: 2rem;
  display: block;
  
  em {
    font-style: normal;
    color: ${props => props.theme.colors.red};
  }
`;

export const StyledContent = styled(Paper)`
  padding: 2rem;
`;

export const StyledUserListing = styled.ul`
  display: grid;
  grid-template-columns: 1fr;
  row-gap: 2rem;
  
  @media ${devices.tablet} {
    grid-template-columns: repeat(2, 1fr);
    column-gap: 2rem;
  }

  @media ${devices.widescreen} {
    grid-template-columns: repeat(3, 1fr);
  }

  @media ${devices.fullhd} {
    grid-template-columns: repeat(4, 1fr);
  }
`;
