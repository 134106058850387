import React from 'react';
import * as R from 'ramda';

import { TDropDownDialogOption } from '../../../../types/TDropDownDialog';

import { useAuth } from '../../../../hooks';

import limitedText from '../../../../utils/limited-text';
import filterLocationsOwnerOrGuest from '../../../../utils/filter-locations-owner-or-guest';
import filterLocationsOwnerOrGuestLocationGroup from '../../../../utils/filter-locations-owner-or-guest-location-group';

import Loading from '../../../loading';

import {
  StyledOption,
  StyledLocationQuantity,
  StyledGuestInfosText,
  StyledGuestInfos,
} from './option-styles';

const Option = ({
  onClick: handleChange,
  companyId,
  name,
  companyType,
  locationGroupType,
  companyName = '',
  locationAccessLevelProfile = '',
  isGuest,
  setAnchorElement,
  option,
  currentSelected = null,
  disabled = false,
}: TDropDownDialogOption) => {
  const {
    userId,
    userAccessToken,
    userProfileName,
    userSetTokenLikeExpired,
  } = useAuth();

  const [isFetching] = React.useState(false);

  const [totalLocationThisOption, setTotalLocationThisOption] = React.useState<number | null>(null);

  const getLocationThisCompany = React.useCallback(async () => {
    if (R.isNil(companyId)) return;

    const companyLocationDataResponse = await filterLocationsOwnerOrGuest({
      currentCompany: option,
      userId,
      userProfileName,
      userAccessToken,
      companyLocationsPageSize: 500,
      userSetTokenLikeExpired,
    })();

    if (R.isNil(companyLocationDataResponse)) {
      setTotalLocationThisOption(0);
      return;
    }

    const { locationCount } = companyLocationDataResponse;

    setTotalLocationThisOption(locationCount);
  }, [userAccessToken, companyId]);

  const getLocationThisLocationGroup = React.useCallback(async () => {
    if (R.isNil(companyId)) return;

    const locationGroupLocationsDataResponse = await filterLocationsOwnerOrGuestLocationGroup({
      currentLocationGroup: option,
      userId,
      userProfileName,
      userAccessToken,
      userSetTokenLikeExpired,
    })();

    if (R.isNil(locationGroupLocationsDataResponse)) {
      setTotalLocationThisOption(0);
      return;
    }

    const { locationCount } = locationGroupLocationsDataResponse;

    setTotalLocationThisOption(locationCount);
  }, [userAccessToken, companyId]);

  React.useEffect(() => {
    if (companyType) {
      getLocationThisCompany();
    }
  }, [getLocationThisCompany]);

  React.useEffect(() => {
    if (locationGroupType) {
      getLocationThisLocationGroup();
    }
  }, [getLocationThisLocationGroup]);

  const handleChangeCompany = () => {
    handleChange(companyId);
    setAnchorElement(null);
  };

  return (
    <StyledOption
      disableOption={disabled}
      onClick={disabled ? undefined : handleChangeCompany}
      aria-hidden="true"
      isGuest={isGuest}
      currentSelected={currentSelected}
      optionName={name}
    >
      <span className="option-text">{limitedText(name, companyType ? 30 : 100)}</span>

      {isGuest && (
        <StyledGuestInfos>
          <StyledGuestInfosText>
            <b>Empresa:</b>
            {' '}
            {companyName}
          </StyledGuestInfosText>
          <StyledGuestInfosText>
            <b>Perfil:</b>
            {' '}
            {locationAccessLevelProfile}
          </StyledGuestInfosText>
        </StyledGuestInfos>
      )}
      {((companyType || locationGroupType) && !R.isNil(totalLocationThisOption)) && (
        <StyledLocationQuantity
          isFetching={isFetching}
          hasLocation={totalLocationThisOption > 0}
        >
          {isFetching ? <Loading /> : `Locais: ${totalLocationThisOption}`}
        </StyledLocationQuantity>
      )}
    </StyledOption>
  );
};

export default Option;
