import styled, { css } from 'styled-components';
import { Dialog, DialogTitle, DialogContent } from '@material-ui/core';
import { lighten, shade } from 'polished';
import { TDialogProps } from './dialog';

export const StyledDialog = styled(Dialog)`
  .MuiDialog-paperWidthSm {
    ${(props: TDialogProps) => props.wideDialog && css`
      width:100%;
    `}
  }
`;

export const StyledDialogTitle = styled.div<{isHeaderBlue: boolean}>`
  span {
    font-size: 1.8rem;
    font-family: Poppins;

    ${props => props.isHeaderBlue && css`
      color: ${props.theme.colors.white};
    `}
  }
`;

export const StyledDialogTitleWrapper = styled.div<{isHeaderGray: boolean, isHeaderBlue: boolean}>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 2rem;
  border-bottom: 1px solid ${props => lighten(0.9, props.theme.colors.black)};
  font-weight: 600;

  ${props => props.isHeaderGray && css`
    background-color: ${shade(0.1, props.theme.colors.white)};
  `}

  ${props => props.isHeaderBlue && css`
    background-color: ${props.theme.colors.primary};
  `}

  button {
    margin-left: 0.8rem;
  }
`;

export const StyledDialogContent = styled(DialogContent)`
  padding: 0;
`;
