import axios from 'axios';

export default async function getGuest({ accessToken, id }) {
  const requestOptions = {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  };

  const { data } = await axios.get(
    `${process.env.REACT_APP_GUESTS_API_URL}/${id}`,
    requestOptions,
  );

  return data;
}
