import { TGooglePlaceInfo } from '../../../types/TGoogle';
import { TLocationEntity } from '../../../types/TLocation';

export type TInfosProps = {
  updateLocation: TLocationEntity,
  setUpdateLocation(location): void;
  fieldOnFocus: string;
  setFieldOnFocus(focus): void;
};

export type TInitialInfoData = {
  address?: string;
  address1?: string;
  address2?: string;
  'address-number'?: string;
  categoryGroupingsSelected?: string[];
  city?: string;
  country?: string;
  data_studio?: string;
  does_not_serve_at_this_address?: boolean;
  email?: string;
  facebook_categories?: string[];
  foursquare_categories?: string[];
  google_categories?: string[];
  long_description?: string;
  name?: string;
  neighborhood?: string;
  phone?: string;
  secondary_phone?: string;
  selectedServiceAreaPlaceInfos?: TGooglePlaceInfo[];
  short_description?: string;
  state?: string;
  store_code?: string;
  zip_code?: string;
}

export enum EInfoLocationKeys {
  'store_code' = 'store_code',
  name = 'name',
  'phone' = 'phone',
  'secondary_phone' = 'secondary_phone',
  email = 'email',
  'data_studio' = 'data_studio',
  facebook_categories = 'facebook_categories',
  foursquare_categories = 'foursquare_categories',
  google_categories = 'google_categories',
  zip_code = 'zip_code',
  address1 = 'address1',
  address2 = 'address2',
  city = 'city',
  'country' = 'country',
  neighborhood = 'neighborhood',
  'state' = 'state',
  does_not_serve_at_this_address = 'does_not_serve_at_this_address',
  long_description = 'long_description',
  short_description = 'short_description',
  google_service_area = 'google_service_area'
};
