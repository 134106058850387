import styled from 'styled-components';

export const StyledPostFormCovid19 = styled.div`
  padding: 2rem 2rem 4rem;
  width: 100%;
  background: ${props => props.theme.colors.white};
  border-radius: 0.5rem;
  
  .covid-status-textarea {
    margin-bottom: 2rem;
    
    textarea {
      min-height: 20rem;
      resize: vertical;
    }
  }
  
  .publish-button {
    margin-left: auto;
    margin-top: 2rem;
  }
  
  .is-button-loading {
    margin-right: 1rem;
  }
`;
