import React from 'react';
import * as R from 'ramda';

import {
  IconButton,
} from '../../../components';

import {
  StyledCreateServiceDialog,
  StyledHeader,
  StyledFooter,
  StyledPostAndFilterWrapper,
  StyledHasLocationsWithoutLinked,
  StyledErrorLocationList,
  StyledErrorIcon,
  StyledRow,
  StyledConfirmButton,
} from './create-service-dialog-styles';
import { TBatchCreateServiceDialogBaseProps } from '../../../types/TServiceBatch';
import { ServiceBatchForm } from '../service-batch-form';

const CreateServiceDialog = ({
  open = false,
  closeModal,
  locationsWithoutLinkedList,
}: TBatchCreateServiceDialogBaseProps) => {
  const [showBatchForm, setShowBatchForm] = React.useState(!R.isNil(locationsWithoutLinkedList)
  && locationsWithoutLinkedList.count === 0);

  const onConfirmClicked = () => {
    setShowBatchForm(true);
  };

  return (
    <StyledCreateServiceDialog
      open={open}
      aria-labelledby="simple-dialog-title"
    >
      {!showBatchForm && (
      <>
        <StyledHeader>
          <p>Atenção!</p>
          <IconButton
            icon="Close"
            tooltip="Fechar"
            onClick={() => closeModal(false)}
          />
        </StyledHeader>
        <StyledPostAndFilterWrapper>

          <StyledHasLocationsWithoutLinked>
            <b>Existem Locais que estão com a vinculação pendente:</b>
            <p>Os locais abaixo não serão afetados pela criação do Serviço se não estiverem vinculados.</p>

            {!R.isNil(locationsWithoutLinkedList) && locationsWithoutLinkedList.count > 0 && (
            <StyledErrorLocationList>
              {locationsWithoutLinkedList.locations.map(location => (
                <StyledRow>
                  <StyledErrorIcon />
                  {' '}
                  {location}
                </StyledRow>
              ))}
            </StyledErrorLocationList>
            )}
          </StyledHasLocationsWithoutLinked>
        </StyledPostAndFilterWrapper>

        <StyledFooter>
          <StyledConfirmButton
            onClick={onConfirmClicked}
          >
            Prosseguir mesmo assim
          </StyledConfirmButton>
        </StyledFooter>
      </>
      )}
      {
       showBatchForm
       && (
       <ServiceBatchForm
         open={showBatchForm}
         closeModal={closeModal}
       />
       )
      }
    </StyledCreateServiceDialog>
  );
};

export default CreateServiceDialog;
