import styled from 'styled-components';

export const StyledContent = styled.div`
    display: flex;
    flex-direction: column;
    padding: 2rem;
    width: 100%;
`;

export const StyledLog = styled.div`
    display: flex;
    align-items: center;
    width: 100%;
    margin-bottom: 1rem;

    &:last-of-type {
        margin-bottom: 0;
    }
`;

export const StyledDisclaimer = styled.small`
    font-size: 1.2rem;
    color: ${props => props.theme.colors.red};
    display: inline-block;
`;

export const StyledQuantityGroups = styled.span`
    font-weight: bold;
    font-size: 1.6rem;
    color: ${props => props.theme.colors.primary};
    margin: 0 1rem;
`;
