import axios from 'axios';

import { AxiosRequestConfig } from 'axios';

type TGetFacebookPageProps = {
  accessToken: string;
  facebookUserAccessToken?: string;
  nextPageToken?: string;
};

export const GetFacebookPages = async ({
  accessToken,
  nextPageToken,
  facebookUserAccessToken,
}: TGetFacebookPageProps) => {
  try {
    const params: AxiosRequestConfig = {
      params: {
        next: nextPageToken && nextPageToken,
      },
      headers: {
        fb_user_access_token: facebookUserAccessToken,
        Authorization: `Bearer ${accessToken}`,
      },
    };

    const response = await axios.get(
      `${process.env.REACT_APP_FACEBOOK_API_URL}/pages`,
      params
    );

    return {
      pages: response.data.pages,
      next: response.data.cursor.next,
    };
  } catch (error) {
    return null;
  }
};
