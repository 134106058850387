import styled from 'styled-components';
import { Paper } from '@material-ui/core';
import { lighten } from 'polished';

export const StyledGuestList = styled(Paper)`
  display: flex;
  flex-direction: column;
  margin-bottom: 3rem;
  max-height: 33rem;
  padding: 3rem 2rem 4rem;
  overflow-y: auto;

  .loading-guests {
    margin: 1rem auto;
  }
`;

export const StyledTitle = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 1rem;

  h3 {
    font-size: 1.5rem;
    font-weight: 700;
    width: 100%;
    margin-bottom: 0;
  }

  h4 {
    font-size: 1.4rem;
    font-weight: 600;
    width: 100%;
    margin-bottom: 0;
    line-height: 1;
  }

  span {
    font-weight: normal;
    cursor: pointer;
    font-size: 1.2rem;
    color: ${props => props.theme.colors.red};
    text-decoration: underline;
  }
`;

export const StyledFormContent = styled.div`
  background: ${props => lighten(0.95, props.theme.colors.black)};
  padding: 2rem;
  position: relative;

  .button {
    margin-bottom: 1.5rem;

    &:last-of-type {
      margin-bottom: 0;
    }
  }

  .empty-profiles {
    color: ${props => props.theme.colors.red};
  }
  
  .loading-pending-invite {
    margin: 1rem auto;
  }
`;
