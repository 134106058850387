/* eslint-disable react/jsx-props-no-spreading */
// eslint-disable-next-line no-use-before-define
import React, {
  useCallback, useEffect, useState,
} from 'react';

import {
  StyledOtherLinks,
  StyledTitle,
  StyledContent,
} from './other-links-styles';
import ControlledInput from '../../controlled-input';

import {
  advanceOrderAttr, menuAttr, reservationsAttr, scheduleAttr,
} from './helpers';

import { TOthersLinks, TOthersLinksFields } from './types';

const OtherLinks = ({
  setUpdatedLocation,
  updatedLocation,
  className,
}: TOthersLinks) => {
  const { other_links: otherLinks } = updatedLocation;

  const {
    menu,
    schedule,
    reservations,
    advanceOrder,
  } = JSON.parse(otherLinks || '{}') || {};

  const [fields, setFields] = useState<TOthersLinksFields>({
    'advance-order': advanceOrder,
    menu,
    reservations,
    schedule,
  });

  useEffect(() => {
    const {
      menu: updatedMenu,
      schedule: updatedSchedule,
      reservations: updatedReservations,
      'advance-order': updatedAdvanceOrder,
    } = fields;
    const links = {
      menu: updatedMenu,
      schedule: updatedSchedule,
      reservations: updatedReservations,
      advanceOrder: updatedAdvanceOrder,
    };

    const otherLinksString = JSON.stringify(links);
    setUpdatedLocation(prev => ({ ...prev, other_links: otherLinksString }));
  }, [fields]);

  const Menu = useCallback(() => (
    <ControlledInput
      {...menuAttr}
      setParentValue={setFields}
      defaultValue={menu}
    />
  ), [updatedLocation]);

  const Schedule = useCallback(() => (
    <ControlledInput
      {...scheduleAttr}
      setParentValue={setFields}
      defaultValue={schedule}
    />
  ), [updatedLocation]);

  const AdvanceOrder = useCallback(() => (
    <ControlledInput
      {...advanceOrderAttr}
      setParentValue={setFields}
      defaultValue={advanceOrder}
    />
  ), [updatedLocation]);

  const Reservations = useCallback(() => (
    <ControlledInput
      {...reservationsAttr}
      setParentValue={setFields}
      defaultValue={reservations}
    />
  ), [updatedLocation]);

  return (
    <StyledOtherLinks className={className}>
      <StyledTitle>Outros Links</StyledTitle>
      <StyledContent>
        <Menu />
        <Schedule />
        <AdvanceOrder />
        <Reservations />
      </StyledContent>
    </StyledOtherLinks>
  );
};

export default React.memo(OtherLinks);
