import React from 'react';
import { isNil, isEmpty } from 'ramda';
import { keyBy } from 'lodash';
import { Select, MenuItem } from '@material-ui/core';

import limitedText from '../../utils/limited-text';

import {
  StyledSelect,
  StyledInputValidate,
  StyledCheckbox,
} from './select-multiple-styles';

import {
  TDefaultPropOption,
  TSelectMultipleBaseProps,
} from '../../types/TSelect';

const SelectMultiple = <T, >({
  options = [],
  name = 'select',
  selectValidate,
  onChange,
  fieldRef,
  label,
  borderRadius,
  border,
  lightBorder,
  className,
  value = [],
  noBackground = false,
  shouldSelectValue = false,
}: TSelectMultipleBaseProps<T>) => {
  const multipleSelectRef = React.useRef<HTMLDivElement | null>(null);
  const [textLimitOnMultipleSelect, setTextLimitOnMultipleSelect] = React.useState(10);
  const [selectOptions, setSelectOptions] = React.useState<
    Array<TDefaultPropOption & T>
  >([]);

  const handleOnChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    onChange(event.target.value as string[]);
  };

  const optionsDictionary = React.useMemo(
    () => keyBy(options, 'value'),
    [options]
  );

  const convertValuesToNames = (values: string[]): string[] => {
    return values.map(value => optionsDictionary[value]?.name || '');
  };

  React.useEffect(() => {
    setSelectOptions(options);
  }, []);

  React.useEffect(() => {
    const redefineInputCharacterLimit = () => {
      if (isNil(multipleSelectRef.current)) return;

      setTextLimitOnMultipleSelect(
        multipleSelectRef?.current?.offsetWidth / 10,
      );
    };

    window.addEventListener('resize', redefineInputCharacterLimit);
    window.addEventListener('load', redefineInputCharacterLimit);

    return () => {
      window.removeEventListener('resize', redefineInputCharacterLimit);
      window.removeEventListener('load', redefineInputCharacterLimit);
    };
  }, []);

  return (
    <StyledSelect
      className={className}
      borderRadius={borderRadius}
      border={border}
      lightBorder={lightBorder}
      ref={multipleSelectRef}
      noBackground={noBackground}
    >
      {label && <label htmlFor={name}>{label}</label>}
      <Select
        multiple
        value={value}
        ref={fieldRef}
        onChange={handleOnChange}
        name={name}
        renderValue={(selected) => {
          const selectedToDisplay = shouldSelectValue
            ? convertValuesToNames(selected as string[])
            : selected;
          return limitedText(
            (selectedToDisplay as string[]).join(', '),
            textLimitOnMultipleSelect
          );
        }}
        placeholder="Categorias"
      >
        {!isEmpty(selectOptions)
          && selectOptions.map((option) => (
            <MenuItem
              key={option.name}
              value={shouldSelectValue ?  option.value : (option.name || option.value)}
            >
              <StyledCheckbox
                checked={value.indexOf(option.name as string) > -1}
              />
              <h4>{option.name}</h4>
            </MenuItem>
          ))}
      </Select>

      {selectValidate && selectValidate.visible && (
        <StyledInputValidate type={selectValidate.type}>
          {selectValidate.message}
        </StyledInputValidate>
      )}
    </StyledSelect>
  );
};
export default SelectMultiple;
