import axios from 'axios';

export default async function addWorkingHoursModels(
  accessToken,
  companyId,
  model_ids,
) {
  const requestOptions = {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  };

  try {
    const { data } = await axios.post(
      `${process.env.REACT_APP_COMPANIES_API_URL}/working-hours-models/add/${companyId}`,
      { model_ids },
      requestOptions,
    );

    console.log(data);
    return data;
  } catch (err) {
    console.log(err.response);
    return err;
  }
}
