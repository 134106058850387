import axios from 'axios';

export default async function addInfoModels(accessToken, companyId, model_ids) {
  const requestOptions = {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  };

  try {
    const { data } = await axios.post(
      `${process.env.REACT_APP_COMPANIES_API_URL}/info-models/add/${companyId}`,
      { model_ids },
      requestOptions,
    );

    return data;
  } catch (err) {
    return err;
  }
}
