import styled from 'styled-components';

export const StyledCheckPlatform = styled.li`
  display: flex;
  align-items: center;
  padding: 0.5rem;

  .platform-icon {
    width: 1.8rem;
    height: 1.8rem;
    margin-right: 0.5rem;
  }
`;
