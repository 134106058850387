export default function formatGoogleRegularHours(regularHours) {
  let formattedHours = {};

  for (let i = 0; i < regularHours.periods.length; i++) {
    const period = regularHours.periods[i];

    switch (period.openDay) {
      case 'MONDAY':
        formattedHours = {
          ...formattedHours,
          monday: `${period.openTime} - ${period.closeTime}`,
        }; break;
      case 'TUESDAY':
        formattedHours = {
          ...formattedHours,
          tuesday: `${period.openTime} - ${period.closeTime}`,
        }; break;
      case 'WEDNESDAY':
        formattedHours = {
          ...formattedHours,
          wednesday: `${period.openTime} - ${period.closeTime}`,
        }; break;
      case 'THURSDAY':
        formattedHours = {
          ...formattedHours,
          thursday: `${period.openTime} - ${period.closeTime}`,
        }; break;
      case 'FRIDAY':
        formattedHours = {
          ...formattedHours,
          friday: `${period.openTime} - ${period.closeTime}`,
        }; break;
      case 'SATURDAY':
        formattedHours = {
          ...formattedHours,
          saturday: `${period.openTime} - ${period.closeTime}`,
        }; break;
      case 'SUNDAY':
        formattedHours = {
          ...formattedHours,
          sunday: `${period.openTime} - ${period.closeTime}`,
        }; break;
      default: break;
    }
  }

  return formattedHours;
}
