import React from 'react';
import { Helmet } from 'react-helmet';

import {
  NewHeaderToolbar,
} from '../../components';

import AutomaticReplies from './automatic-replies';
import ReviewFilter from './review-filter';
import ReviewListing from './review-listing';
import SelectReplyDialog from './select-reply-dialog';
import SearchResult from './search-result/search-result';

import {
  StyledReviews,
  StyledSection,
} from './new-reviews-styles';

const NewReviews = () => {
  const [platformFilter, setPlatformFilter] = React.useState<string | null>(null);
  const [starTypeFilter, setStarTypeFilter] = React.useState<string | null>(null);
  const [starValueFilter, setStarValueFilter] = React.useState<number>(0);
  const [reviewTypeFilter, setReviewTypeFilter] = React.useState<string | null>(null);
  const [locationFilter, setLocationFilter] = React.useState<string[] | null>(null);
  const [wordFilter, setWordFilter] = React.useState<string[] | null>(null);
  const [selectedDateOf, setSelectedDateOf] = React.useState<Date | null>(null);
  const [selectedDateTo, setSelectedDateTo] = React.useState<Date | null>(null);

  const [isSelectReplyDialogOpen, setIsSelectReplyDialogOpen] = React.useState(false);

  const getReviews = React.useCallback(async () => {
    const getReviewsResponse = null;
  }, [
    platformFilter,
    starTypeFilter,
    starValueFilter,
    reviewTypeFilter,
    locationFilter,
    wordFilter,
    selectedDateOf,
    selectedDateTo,
  ]);

  return (
    <StyledReviews>
      <Helmet>
        <meta property="og:title" content="Avaliações - SaaS Hublocal" />
        <meta
          property="og:description"
          content="Gerêncie as avaliações de seus locais por plataforma."
        />

        <title>Avaliações - SaaS Hublocal</title>
      </Helmet>

      <NewHeaderToolbar
        title="Avaliações"
        paddingLeft
        overlayLoadingNoDisplay
        dropdownToggle
      />

      <AutomaticReplies />

      <StyledSection>
        <ReviewFilter
          platformFilter={platformFilter}
          setPlatformFilter={setPlatformFilter}
          starTypeFilter={starTypeFilter}
          setStarTypeFilter={setStarTypeFilter}
          starValueFilter={starValueFilter}
          setStarValueFilter={setStarValueFilter}
          reviewTypeFilter={reviewTypeFilter}
          setReviewTypeFilter={setReviewTypeFilter}
          locationFilter={locationFilter}
          setLocationFilter={setLocationFilter}
          wordFilter={wordFilter}
          setWordFilter={setWordFilter}
          selectedDateOf={selectedDateOf}
          setSelectedDateOf={setSelectedDateOf}
          selectedDateTo={selectedDateTo}
          setSelectedDateTo={setSelectedDateTo}
        />

        <SearchResult />

        <ReviewListing />
      </StyledSection>

      <SelectReplyDialog
        isOpen={isSelectReplyDialogOpen}
        setIsOpen={setIsSelectReplyDialogOpen}
      />

    </StyledReviews>
  );
};

export default NewReviews;
