import axios from 'axios';
import removeSpecialCharacters from '../utils/removeSpecialCharacters';

export default async function getEstablishment({ query, feedbackMessage }) {
  try {
    if (feedbackMessage) {
      feedbackMessage(
        'Buscando locais...',
        { variant: 'warning' },
      );
    }

    let formattedQuery = query?.toLowerCase().replace(/ /g, '+');
    if(formattedQuery) formattedQuery = removeSpecialCharacters(formattedQuery);

    const { data: { predictions } } = await axios.get(
      `${process.env.REACT_APP_GOOGLE_API_URL}/search/location/autocomplete?keyword=${formattedQuery}&location=&types=establishment&components=country:br&key=${process.env.REACT_APP_GOOGLE_PLACES_API_KEY}`,
    );

    const establishments = predictions.map(prediction => ({
      predictionId: prediction.place_id,
      predictionName: prediction.description,
    }))

    return establishments;
    
  } catch (err) {
    if (feedbackMessage) {
      feedbackMessage(
        'Erro ao tentar encontrar locais. Tente buscar novamente!',
        { variant: 'warning' },
      );
    }

    console.log(err);

    return null;
  }
}
