import { createActions, createReducer } from 'reduxsauce';

export const { Types, Creators } = createActions({
  setActiveLocation: ['activeLocation'],
});

const initialState = {
  activeLocation: null,
};

const reduxOnSetActiveLocation = (
  state = initialState, { activeLocation },
) => ({ ...state, activeLocation });

export default createReducer(initialState, {
  [Types.SET_ACTIVE_LOCATION]: reduxOnSetActiveLocation,
});
