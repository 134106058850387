import styled from 'styled-components';

import { devices } from '../../assets/styles/devices';

export const StyledLocalAccessLevelProfile = styled.main`
    display: flex;
    flex-direction: column;
    padding: 2rem 2rem 4rem 2rem;
`;

export const StyledHeader = styled.header`
  padding-top: 2rem;
  padding-bottom: 4rem;
  display: flex;
  width: 100%;

  h2 {
    font-size: 2rem;
    line-height: 2.2rem;
    font-weight: 700;
    margin-bottom: 2rem;

    @media ${devices.tabletLandscape} {
      margin-bottom: 0;
    }
  }
`;

export const StyledContent = styled.div`
    display: grid;
    grid-template-columns: 1fr;
    row-gap: 3rem;

    @media ${devices.tabletLandscape} {
      grid-template-columns: 1fr 2fr;
      column-gap: 3rem;
      row-gap: 0;
    }
`;
