import styled from 'styled-components';

export type StyleColorProps = 'PRIMARY' | 'SECONDARY'

type Props = {
    color: StyleColorProps;
}

export const StyledContainer = styled.div<Props>`
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 0.5rem;
`;

export const StyledCount = styled.div<Props>`
    background-color: ${(props) => (
    props.color === 'PRIMARY'
      ? props.theme.colors.primary
      : props.theme.colors.danger)};

    color: ${(props) => props.theme.colors.white};
    font-size: 1rem;
    padding: 0.3rem;
    width: 2.2rem;
    height: 2.2rem;
    border-radius: 99%;
    text-align: center;
    `;

export const StyledTitle = styled.p<Props>`
    color: ${(props) => (
    props.color === 'PRIMARY'
      ? props.theme.colors.primary
      : props.theme.colors.danger)};
    font-size: 1rem;
    line-height: 0.8rem;
    margin: 0;
`;
