import React from 'react';
import { debounce } from 'lodash';

import {
  Select,
  SelectMultiple,
  Input,
  InputDatePicker,
} from '../../../components';

import {
  TLocationPostGooglePostType,
  TFiltersPostHeader,
  TPostListView,
} from '../../../types/TLocationPost';

import {
  TSelectPlatform,
} from '../../../types/TPlatforms';

import {
  locationPostPlatformsMocked,
} from '../../../utils/select-platforms-mocked';

import {
  locationGooglePostsType,
} from '../helpers';

import {
  StyledFilterPostHeader,
  StyledTitle,
  StyledContent,
  StyledHeader,
  StyledAddPostButton,
  StyledAddCircle,
  StyledViewsTypeItemIconList,
  StyledViewsTypeItemIconViewColumn,
  StyledViewTypeItem,
  StyledViewType,
  StyledViewTypeItems,
  StyledDatesWrapper,
} from './filters-post-header-styles';

const FiltersPostHeader = ({
  onAddPostClicked,
  viewModePost,
  onViewModePost,
  selectedPostTypeFilter,
  setSelectedPostTypeFilter,
  setSelectedPlatformFilter,
  selectedPlatformFilter,
  ofPeriod,
  setOfPeriod,
  toPeriod,
  setToPeriod,
  setKeyWordsInput,
  keyWordsInputRef,
}: TFiltersPostHeader) => {
  const handleChangeOfDate = debounce(value => setOfPeriod(value), 500);
  const handleChangeToDate = debounce(value => setToPeriod(value), 500);

  return (
    <StyledFilterPostHeader>
      <StyledHeader>
        <StyledTitle>Filtros</StyledTitle>

        <StyledAddPostButton
          onClick={onAddPostClicked}
        >
          <StyledAddCircle />

          Adicionar Postagem
        </StyledAddPostButton>

        <StyledViewType>
          <h3>Visualizar Posts</h3>

          <StyledViewTypeItems>
            <StyledViewTypeItem
              isSelected={viewModePost === 'table'}
              onClick={() => onViewModePost('table')}
            >
              <StyledViewsTypeItemIconList />
            </StyledViewTypeItem>
            <StyledViewTypeItem
              isSelected={viewModePost === 'cards'}
              onClick={() => onViewModePost('cards')}
            >
              <StyledViewsTypeItemIconViewColumn />
            </StyledViewTypeItem>
          </StyledViewTypeItems>
        </StyledViewType>
      </StyledHeader>
      <StyledContent>
        <Select<TSelectPlatform>
          disabled
          name="platform-filter"
          value={selectedPlatformFilter}
          label="Plataforma"
          options={locationPostPlatformsMocked}
          onChange={({ target }) => setSelectedPlatformFilter(target.value)}
          border
          className="filter-content"
        />

        <SelectMultiple<TLocationPostGooglePostType>
          name="post-type"
          value={selectedPostTypeFilter}
          label="Tipo de Postagem"
          options={locationGooglePostsType}
          onChange={(target) => setSelectedPostTypeFilter(target)}
          border
          className="filter-content"
        />

        <Input
          border
          label="Palavra-Chave"
          autoComplete="off"
          className="filter-content"
          onChange={e => setKeyWordsInput(e.target.value)}
          fieldRef={keyWordsInputRef}
        />

        <StyledDatesWrapper>
          <InputDatePicker
            selectedDate={ofPeriod}
            setSelectedDate={handleChangeOfDate}
            label="De: *"
            className="input-date-picker"
          />

          <InputDatePicker
            selectedDate={toPeriod}
            setSelectedDate={handleChangeToDate}
            label="Até: *"
            className="input-date-picker"
          />
        </StyledDatesWrapper>

      </StyledContent>
    </StyledFilterPostHeader>
  );
};

export default FiltersPostHeader;
