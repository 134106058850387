import React from 'react';

import ConfigCard from '../../../components/config-card';

import {
  StyledDeletedCompanyGroups,
} from './deleted-company-groups-styles';

const HandlerList = ({ navigation }) => (
  <ConfigCard
    title="Grupos Apagados de Empresas "
    onClick={() => navigation.push('/deleted-company-groups/list')}
  >
    <StyledDeletedCompanyGroups>
      Gerência de grupos de empresas apagadas pelo usuário proprietário
    </StyledDeletedCompanyGroups>
  </ConfigCard>
);

export default HandlerList;
