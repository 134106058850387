import {
  Accordion, AccordionDetails, AccordionSummary, Tooltip,
} from '@material-ui/core';
import styled from 'styled-components';
import ErrorIcon from '@material-ui/icons/Error';
import { devices } from '../../../assets/styles/devices';

export type ButtonSelectOptionDataProps = 'SELECTED' | 'NOTSELECTED'

type Props = {
  selectedOption: ButtonSelectOptionDataProps;
}

export const StyledReviewFilter = styled.div`
  border-radius: 6px;
`;

export const StyledContentRow = styled.div`
display: flex;
flex-direction: column;
align-items: center;
justify-content: space-between;
margin-top: 1rem;
margin-bottom: 2rem;
column-gap: 2rem;
row-gap: 2rem;

select {
  border: 1px solid ${(props) => props.theme.colors.grey};
  height: 4rem;

  border-radius: 6px;
  font-size: 1.4rem;
  font-weight: 400;
}

&:last-of-type {
  margin-bottom: 0;
}

@media ${devices.tabletLandscape} {
  flex-direction: row;
}

.star-filter {
  max-width: 100%;
  
  @media ${devices.tabletLandscape} {
    max-width: 20%;
  }
}

.platform-filter {
  display: none;
  max-width: 100%;
  
  @media ${devices.tabletLandscape} {
    display: flex;
    max-width: 20%;
  }
}

.review-type-filter {
  max-width: 100%;
  
  @media ${devices.tabletLandscape} {
    max-width: 30%;
  }
}

.location-list {
  max-width: 100%;
}

.search-input {
  max-width: 100%;
  
  input {
    border: 1px solid ${(props) => props.theme.colors.grey};
    color: ${(props) => props.theme.colors.black};
    border-radius: 6px;
    font-size: 1.4rem;
    font-weight: 400;
  }
}
`;

export const StyledAccordionData = styled(Accordion)`
  height: 4rem;
  margin-top: -2rem;
  max-width: 100%;
  border: 1px solid ${(props) => props.theme.colors.grey};
  font-size: 1rem;
  font-weight: 400;
  border-radius: 6px;
  
  @media ${devices.tabletLandscape} {
    max-width: 40%;
  }

  p {
    margin-bottom: 0;
  }

  .MuiAccordionSummary-root {
    min-height: unset;
    align-items: center;
  }

  .MuiAccordionSummary-content {
    margin: 0;
  }

  &.Mui-expanded {
    margin: -2rem 0;
  }
  
  &.MuiAccordion-root:before {
    position: relative;
  }

  &.MuiPaper-elevation1 {
    box-shadow: none;
  }

  &.MuiCollapse-root {
    background: #000;
  }
`;

export const StyledAccordionSummary = styled(AccordionSummary)``;

export const StyledAccordionDetails = styled(AccordionDetails)`
  background-color: ${(props) => props.theme.colors.white};
  display: flex;
  flex-direction: column;
  gap: 1rem;

  .filter-date-picker{
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 1rem;

    .MuiInputBase-root {
      border: 1px solid ${(props) => props.theme.colors.grey};
      border-radius: 6px;
      height: 4rem;
    }
  }
`;

export const StyledContentTitle = styled.div`
  display: flex;
  flex-direction: row;
  padding: 1rem 0;
`;

export const StyledTitle = styled.h1`
  font-size: 1.8rem;
  font-weight: bold;
  line-height: 2rem;
`;

export const StyledTooltip = styled(Tooltip)``;

export const IconTooltip = styled(ErrorIcon)`
  color: ${(props) => props.theme.colors.primary} !important;
  font-size: large;
  margin-left: 0.5rem;
`;

export const StyledLabel = styled.text`
  font-size: 1.3rem;
  font-weight: bold;
  line-height: 1.4rem;
  padding: 12px 0;
`;

export const StyledDataPlaceholder = styled.p`
  font-size: 1.4rem;
  font-weight: 400;
  color: ${(props) => props.theme.colors.grey};
`;

export const StyledSelectOptionData = styled.div`
  margin: 1rem 0;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  text-align: center;
  align-items: center;
  
  background-color: ${(props) => props.theme.colors.greyLight};
  border-radius: 6px;

  svg {
    height: 2rem;
    width: 2rem;
  }
`;

export const StyledSelectOptionButton = styled.button<Props>`
  border: none;
  width: 100%;
  border-radius: 6px;
  font-size: 1.2rem;
  font-weight: 400;
  padding: 1rem;
  
  &:focus {
    outline: none;
  }

  background-color: ${({ theme, selectedOption }) => (selectedOption === 'SELECTED' ? theme.colors.primary : theme.colors.greyLight)};
  fill: ${({ theme, selectedOption }) => (selectedOption === 'SELECTED' ? theme.colors.white : theme.colors.grey)};
`;
