import React from 'react';
import * as R from 'ramda';

import { StyledLinksInfoContainer, StyledList } from './links-info-styles';

type TLinksInfosProps = {
  data: any;
};

const LinksInfos = ({ data }: TLinksInfosProps) => {
  const formatDataToList = React.useCallback((dataToConvert) => {
    if (!dataToConvert) return [];
    const dictionary: any = {
      website: { name: 'Website', order: 1 },
      facebook: { name: 'Facebook', order: 2 },
      instagram: { name: 'Instagram', order: 3 },
      twitter: { name: 'Twitter', order: 4 },
      youtube: { name: 'Youtube', order: 5 },
      foursquare: { name: 'Foursquare', order: 6 },
      linkedin: { name: 'Linkedin', order: 7 },
      yelp: { name: 'Yelp', order: 8 },
      menu: { name: 'Cardápio', order: 9 },
      schedule: { name: 'Agendamento', order: 10 },
      advance_order: { name: 'Pedido Antecipado', order: 11 },
      reservations: { name: 'Reservas', order: 12 },
    };
    const fields = Object.keys(dataToConvert);
    return fields.map((field, index) => {
      let fieldName = field;
      let fieldOrder = index;
      const value = dataToConvert[field];

      if (dictionary[field]) {
        fieldName = dictionary[field].name;
        fieldOrder = dictionary[field].order;
      }

      return {
        name: fieldName,
        order: fieldOrder,
        value,
      };
    });
  }, []);

  return (
    <StyledLinksInfoContainer>
      {!!data && !R.isEmpty(data) && (
        <StyledList>
          {formatDataToList(data)
            .sort((a, b) => a.order - b.order)
            .map((item) => {
              if (item.value) {
                return (
                  <li key={item.name}>
                    <b>
                      {item.name}
                      :
                      {' '}
                    </b>
                    {' '}
                    {item.value === 'MUST_BE_EMPTY' ? 'Informação removida' : item.value}
                  </li>
                );
              }
            })}
        </StyledList>
      )}
    </StyledLinksInfoContainer>
  );
};

export default LinksInfos;
