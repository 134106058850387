import axios from 'axios';

import type { TCreateSpecialDate } from '../../types/TSpecialDates';

export default async function createSpecialDate({
  accessToken,
  special_date_id,
  name,
  date,
  is_open,
  times,
  is_24_hours = false,
  setTokenLikeExpired,
  isLoading,
}: TCreateSpecialDate) {
  try {
    if (isLoading) isLoading(true);

    const { data } = await axios.put(
      `${process.env.REACT_APP_SPECIAL_DATE_API_URL}/${special_date_id}`,
      {
        name,
        date,
        is_open,
        times,
        is_24_hours,
      },
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      },
    );

    return data;
  } catch (err: any) {
    if (err?.response?.status === 401 && setTokenLikeExpired) return setTokenLikeExpired();

    return null;
  } finally {
    if (isLoading) isLoading(false);
  }
}
