import axios from 'axios';

export default async function createConnection({
  accessToken,
  userId,
  createdBy,
  channel,
  token,
  refreshToken,
  code = null,
  tokenExpiresIn,
  setTokenLikeExpired,
}) {
  try {
    const { data } = await axios.post(
      `${process.env.REACT_APP_CONNECTIONS_API_URL}`,
      {
        userId,
        created_by: createdBy,
        channel,
        token,
        refresh_token: refreshToken,
        code,
        token_expires_in: tokenExpiresIn,
      },
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      },
    );

    return data;
  } catch (err) {
    if (err.response?.status === 401 && setTokenLikeExpired) setTokenLikeExpired();

    return null;
  }
}
