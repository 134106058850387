import React from 'react';
import {
  StyledDialog,
  StyledDialogContent,
  StyledDialogTitle,
  StyledDialogTitleWrapper,
} from './automatic-replies-dialog-styles';

import { AutomaticRepliesDialogProps } from './types';

const AutomaticRepliesDialog = ({
  isOpen,
  onClose,
  children,
}: AutomaticRepliesDialogProps) => (
  <StyledDialog
    maxWidth="md"
    fullWidth
    open={isOpen}
    onClose={onClose}
  >
    <StyledDialogTitleWrapper>
      <StyledDialogTitle>Criar Resposta Automática</StyledDialogTitle>
    </StyledDialogTitleWrapper>
    <StyledDialogContent>
      {children}
    </StyledDialogContent>
  </StyledDialog>
);

export default AutomaticRepliesDialog;
