import { format, parse } from 'date-fns';
import * as R from 'ramda';

import type {
  TGalleryMediasType,
  TGalleryCategories,
  TLocationGalleryMediaCategories,
  TRenderAlertArea,
  TRenderAlertAreaReturn,
  TFilteredGalleryMedia,
  TLocationGalleryMedias,
  TAcceptedGalleryMediaFormats,
} from '../../types/TLocationGallery';

export const galleryMediasType: TGalleryMediasType = [
  {
    id: Math.floor(Math.random()),
    name: 'Todas',
  },
  {
    id: Math.floor(Math.random()),
    name: 'Imagem',
  },
  {
    id: Math.floor(Math.random()),
    name: 'Video',
  },
];

export const galleryCategories: TGalleryCategories = [
  {
    id: 0,
    name: 'No Trabalho',
  },
  {
    id: 1,
    name: 'Geral',
  },
  {
    id: 2,
    name: 'Exterior',
  },
  {
    id: 3,
    name: 'Interior',
  },
  {
    id: 4,
    name: 'Equipes',
  },
  {
    id: 5,
    name: 'Capa',
  },
  {
    id: 6,
    name: 'Logo',
  },
];

export const locationGalleryMediaCategories: TLocationGalleryMediaCategories = {
  AT_WORK: 'No Trabalho',
  COVER: 'Capa',
  TEAMS: 'Equipes',
  EXTERIOR: 'Exterior',
  INTERIOR: 'Interior',
  PROFILE: 'Logo',
  ADDITIONAL: 'Geral',
};

export const renderCreateTimeGalleryMedia = (dateToRender: string) => format(
  new Date(dateToRender),
  'dd/MM/yyyy',
);

export const renderAlertArea = ({
  locationGoogleName,
  locationGalleryMedias,
  activeLocation,
  isLoadingMedias,
}: TRenderAlertArea): TRenderAlertAreaReturn => {
  if (R.isNil(activeLocation) || R.isEmpty(activeLocation)) {
    return {
      isRenderAlertArea: true,
      renderAlertAreaMessage: 'Escolha um local para o carregamento das mídias!',
    };
  }

  if (!isLoadingMedias && R.isNil(locationGoogleName)) {
    return {
      isRenderAlertArea: true,
      renderAlertAreaMessage: 'Este local não foi configurado para a exibição das mídias. Entre em contato com os operadores!',
    };
  }

  if (!isLoadingMedias && R.isEmpty(locationGalleryMedias)) {
    return {
      isRenderAlertArea: true,
      renderAlertAreaMessage: 'Não existem mídias para esse local!',
    };
  }

  return {
    isRenderAlertArea: false,
    renderAlertAreaMessage: null,
  };
};

export const getMediaFormat = (mediaFormatTarget: string): string | undefined => {
  const acceptedMediaFormats: TAcceptedGalleryMediaFormats = {
    Video: 'VIDEO',
    Imagem: 'PHOTO',
  };

  return acceptedMediaFormats[mediaFormatTarget];
};

export const filteredGalleryMedias = ({
  mediaFormat,
  ofDate,
  toDate,
  categories,
}: TFilteredGalleryMedia) => (media: TLocationGalleryMedias) => media
  .filter(mediaItem => {
    if (R.isEmpty(mediaFormat) || mediaFormat === 'Todas') return mediaItem;

    return mediaItem.mediaFormat === getMediaFormat(mediaFormat);
  })
  .filter(mediaItem => {
    const { createTime } = mediaItem;

    if (R.isNil(ofDate)) return mediaItem;
    if (R.isNil(toDate)) return mediaItem;

    const hourFixedOfDate = ofDate;
    hourFixedOfDate.setHours(0, 0, 0);
    const hourFixedToDate = toDate;
    hourFixedToDate.setHours(23, 59, 59);

    const createTimeParsed = new Date(createTime);
    if (
      createTimeParsed >= (hourFixedOfDate || new Date())
      && createTimeParsed <= (hourFixedToDate || new Date())) return mediaItem;

    return false;
  })
  .filter(mediaItem => {
    if (R.isEmpty(categories)) return mediaItem;

    return R.includes(
      mediaItem.locationAssociation.category,
      categories.map(category => R.invertObj(locationGalleryMediaCategories)[category]),
    );
  });
