import React from 'react';
import * as MaterialUIIcons from '@material-ui/icons/';

import SimpleLink from '../simple-link';

import {
  StyledGroupLinks,
  StyledTitle,
  StyledChevron,
  StyledSubMenu,
} from './group-links-styles';

const GroupLinks = ({
  title, icon, links, closeMenuMobile,
}) => {
  const [openSubMenu, setOpenSubMenu] = React.useState(false);

  const GroupLinksIcon = MaterialUIIcons[icon || 'Help'];

  return (
    <StyledGroupLinks>
      {title !== 'Modelos'
      && (
        <StyledTitle onClick={() => setOpenSubMenu(!openSubMenu)}>
          <GroupLinksIcon className="group-links-icon" />
          <span>{title}</span>
          <StyledChevron>
            {openSubMenu ? <MaterialUIIcons.ArrowDropUp /> : <MaterialUIIcons.ArrowDropDown />}
          </StyledChevron>
        </StyledTitle>
      )}
      <StyledSubMenu open={openSubMenu}>
        {links.length > 0 && links.map(linkItem => (
          <SimpleLink
            key={linkItem.id}
            closeMenuMobile={closeMenuMobile}
            className="submenu-link"
            title={linkItem.friendly_name}
            url={linkItem.path_to_redirect}
            icon={linkItem.icon}
          />
        ))}
      </StyledSubMenu>
    </StyledGroupLinks>
  );
};

export default GroupLinks;
