import React from 'react';
import { Helmet } from 'react-helmet';

import { useAuth } from '../../hooks';

import {
  StyledLocalAccessLevelProfile,
  StyledHeader,
  StyledContent,
} from './location-access-level-profile-style';

import AddLocationAccessLevelProfile from './add-location-access-level-profile';
import ProfileListing from './profile-listing';

const LocationAccessLevelProfile = () => {
  const {
    userAccessToken,
    userId,
  } = useAuth();

  const [updateTable, setUpdateTable] = React.useState(false);
  const [dataToEdit, setDataToEdit] = React.useState(null);

  return (
    <StyledLocalAccessLevelProfile>
      <Helmet>
        <meta property="og:title" content="Perfis de acesso - SaaS Hublocal" />
        <meta property="og:description" content="Crie perfis de acesso para os seus locais" />

        <title>Perfis de acesso - SaaS Hublocal</title>
      </Helmet>

      <StyledHeader>
        <h2>Perfis de acesso</h2>
      </StyledHeader>

      <StyledContent>
        <AddLocationAccessLevelProfile
          userToken={userAccessToken}
          userId={userId}
          dataToEdit={dataToEdit}
          setDataToEdit={setDataToEdit}
          setUpdateTable={setUpdateTable}
        />
        <ProfileListing
          userId={userId}
          userToken={userAccessToken}
          updateTable={updateTable}
          setUpdateTable={setUpdateTable}
          setDataToEdit={setDataToEdit}
        />
      </StyledContent>

    </StyledLocalAccessLevelProfile>
  );
};

export default LocationAccessLevelProfile;
