import styled from 'styled-components';
import {
  Add as AddIcon,
  ArrowDropDown as ArrowDropDownIcon,
  ArrowDropUp as ArrowDropUpIcon,
} from '@material-ui/icons';
import { lighten } from 'polished';

import { Popover } from '@material-ui/core';

export const StyledSensitiveWords = styled.div`
    max-width: 90rem;
    margin: 0 auto 3rem;
    width: 100%;
`;

export const StyledAddSensitiveWordAction = styled.div`
    display: flex;
    align-items: flex-end;
    cursor: pointer;
    position: relative;
    z-index: 2;

    &:hover {
        text-decoration: underline;
    }

    .title {
        font-size: 1.4rem;
        line-height: 1;
    }
`;

export const StyledAddIcon = styled(AddIcon)`
    width: 2rem;
    height: 2rem;
`;

export const StyledArrowDropUpIcon = styled(ArrowDropUpIcon)`
    width: 2rem;
    height: 2rem;
`;

export const StyledArrowDropDownIcon = styled(ArrowDropDownIcon)`
    width: 2rem;
    height: 2rem;
`;

export const StyledSensitiveWordsList = styled.ul`
    display: flex;
    flex-wrap: wrap;     
`;

export const StyleSensitiveWordWrapper = styled.div`
   width: 100%;
`;

export const StyledPopoverAddNewSensitiveWord = styled(Popover)`
    .MuiPopover-paper {
        padding: 2rem;
        max-width: 30rem;
        box-shadow: 
            0px 5px 5px -3px rgb(0 0 0 / 20%), 
            0px 8px 10px 1px rgb(0 0 0 / 14%), 
            0px 3px 14px 2px rgb(0 0 0 / 12%);
    }

    .sensitive-word-input {
        margin-bottom: 2rem;
    }

    .button-add-sensitive-word {
        width: 100%;
    }
`;

export const StyledFilteredSensitiveWordContainer = styled.div`
    display: flex;
    flex-direction: column;
    padding: 2rem;
    background: ${props => lighten(0.94, props.theme.colors.black)};
`;

export const StyledFilteredSensitiveWord = styled.ul`
    display: flex;
    list-style: disc;
    flex-direction: column;
    padding-left: 2rem;
    margin-bottom: 0rem;

    li {
        font-size: 1.4rem;
        margin-bottom: 0rem;
    }
`;

export const StyledIconButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;

export const StyledDeleteAllButton = styled.div`
  padding: 0.5rem 2rem;
  border-radius: 0.5rem;
  transition: 0.3s;
  align-items: center;
  cursor: pointer;

  span {
    margin-bottom: 0;
    font-size: 1.2rem;
    color: ${props => props.theme.colors.primary}
  }

  .delete-icon {
    width: 2rem;
    height: 2rem;
    margin-left: 0.5rem;
    color: ${props => props.theme.colors.primary}
  }

  
  &:hover {
    background: ${props => props.theme.colors.primary};
    transition: 0.3s;

    span {
      color: ${props => props.theme.colors.white}
    }

    .delete-icon {
      color: ${props => props.theme.colors.white}
    }
  }
`;
