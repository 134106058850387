import React from 'react';
import { Select, Button, IconButton } from '../../../../components';

import StarRating from '../star-rating';

import { useReviewsContext } from '../../../../contexts/reviews';

import { StyledActions, StyledButtonWrapper } from './actions-styles';

const Actions = ({ handleShowAutomaticResponses }) => {
  const {
    listAutomaticResponsesBy: { listAutomaticResponsesBy, currentListBy },
    setListAutomaticResponsesBy,
  } = useReviewsContext();

  const handleChangeListBy = (newCurrentListBy) => {
    setListAutomaticResponsesBy((prevListAutomaticResponsesBy) => ({
      ...prevListAutomaticResponsesBy,
      currentListBy: newCurrentListBy,
    }));
  };

  return (
    <StyledActions>
      <Select
        className="list-by-select"
        label="Listar por:"
        border
        name="type"
        options={listAutomaticResponsesBy}
        value={currentListBy}
        onChange={({ target }) => handleChangeListBy(target.value)}
      />
      <StarRating />
      <StyledButtonWrapper>
        <IconButton
          className="icon-info"
          icon="Help"
          tooltip="Para habilitar as repostas automáticas você precisa filtrar por respostas não respondidas e por uma nota específica"
        />
        <Button onClick={handleShowAutomaticResponses}>
          Respostas automáticas
        </Button>
      </StyledButtonWrapper>
    </StyledActions>
  );
};

export default Actions;
