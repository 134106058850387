import React from 'react';

import Star from './star';

import { useReviewsContext } from '../../../../contexts/reviews';

import {
  StyledStarRating,
  StyledTitle,
  StyledStarListing,
} from './star-rating-styles';

const StarRating = () => {
  const {
    starRating: { starRating, currentStarRating },
    setStarRating,
    sensitiveWordsToFilter,
    listAutomaticResponsesBy: { currentListBy },
    locationsToFilter,
  } = useReviewsContext();

  return (
    <StyledStarRating>
      <StyledTitle>Classificação</StyledTitle>
      <StyledStarListing>
        {starRating.map((star, index) => (
          <Star
            currentStarRatingPosition={index}
            key={Math.random(Math.floor())}
            starRating={star}
            starRatingAll={starRating}
            currentStarRating={currentStarRating}
            setStarRating={setStarRating}
          />
        ))}
      </StyledStarListing>
    </StyledStarRating>
  );
};

export default StarRating;
