import React from 'react';
import { useSnackbar } from 'notistack';

import { isEmpty } from 'ramda';
import { Helmet } from 'react-helmet';

import axios from 'axios';
import { useUncontrolledField } from '../../hooks';

import {
  Button,
  Loading,
} from '../../components';

import { emailValidate as emailIsValid } from '../../utils/fields-validate';
import { fieldForgotPasswordEmail } from './fields-scheme';

import { StyledForgotPassword, StyledForm } from './forgot-password-styles';

import hublocalLogo from '../../assets/images/hublocal-logo.png';

const ForgotPassword = () => {
  const [isFetching, setIsFetching] = React.useState(false);

  const { enqueueSnackbar } = useSnackbar();

  const [fieldEmail, setFieldEmail] = React.useState([]);

  const { Field: Email } = useUncontrolledField({
    fieldScheme: {
      ...fieldForgotPasswordEmail,
    },
    saveIn: setFieldEmail,
  });

  const handleSubmit = async (e) => {
    e.preventDefault();

    const email = fieldEmail[0].getValue();

    if (isEmpty(email.trim())) {
      enqueueSnackbar('Insira um email válido', { variant: 'warning' });
      return;
    }

    if (!emailIsValid(email)) {
      enqueueSnackbar('Formato de email inválido!', { variant: 'warning' });
      return;
    }

    try {
      setIsFetching(true);

      await axios.post(
        `${process.env.REACT_APP_AUTH_API_URL}/requestPasswordChange`,
        { email },
      );

      enqueueSnackbar('Enviamos um link de redefinição de senha para o seu email', { variant: 'success' });
    } catch (err) {
      enqueueSnackbar('Não foi possível lhe enviar um email de recuperação. Tente novamente ou insira um email válido!', { variant: 'error' });
    } finally {
      setIsFetching(false);
    }
  };

  return (
    <StyledForgotPassword>
      <Helmet>
        <meta property="og:title" content="Recuperar Senha - SaaS Hublocal" />
        <meta property="og:description" content="Esqueceu a senha? Recupere-a para continuar acessando." />

        <title>Recuperar Senha - SaaS HubLocal</title>
      </Helmet>

      <img src={hublocalLogo} alt="SaaS Hublocal" />
      <StyledForm onSubmit={(e) => handleSubmit(e)}>
        <Email />

        <Button
          type="submit"
          className="button-submit"
        >
          {isFetching ? <Loading className="svg-loading" /> : 'Enviar'}
        </Button>
      </StyledForm>
    </StyledForgotPassword>
  );
};

export default ForgotPassword;
