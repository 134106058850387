import React from 'react';
import * as R from 'ramda';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { useSnackbar } from 'notistack';

import { Creators as CompanyActions } from '../../redux/ducks/company';

import { useAuth, useCompany } from '../../hooks';

import {
  DataTable,
  HeaderToolbar,
  HealderToolbarAction,
  OverlayLoading,
} from '../../components';

import { translations } from '../../translations';

import EmptyCompanies from './empty-companies';

import { getColumnsToTable, getNormalizedDataTable, isGuest } from './helpers';

import { StyledMyCompanies, StyledContent } from './my-companies-styles';

const MyCompanies = () => {
  const reduxHistory = useHistory();
  const reduxDispatch = useDispatch();

  const { enqueueSnackbar } = useSnackbar();

  const [userCompaniesDataTable, setUserCompaniesDataTable] = React.useState([]);
  const [userCompaniesDataTableCount, setUserCompaniesDataTableCount] = React.useState([]);

  const {
    userId,
    userAccessToken,
    userLanguage,
    userSetTokenLikeExpired,
    userProfileName,
  } = useAuth();

  const {
    userCompanies,
    userCompaniesCount,
    userCompaniesLoading,
    overlayLoadingTextUseCompany,
    getUserCompany,
    deleteUserCompany,
    deleteGuestUserFromCompany,
    userCompaniesPageIndex,
    userCompaniesPageQuery,
    userCompaniesPageSize,
    setUserCompaniesPageQuery,
    setUserCompaniesPageIndex,
    setUserCompaniesPageSize,
    setUserCompaniesOrderByName,
  } = useCompany();

  const {
    main: mainTranlations,
    data_table: dataTableTranslation,
  } = translations[userLanguage];

  const normalizeUserCompaniesDataTable = getNormalizedDataTable({
    userAccessToken,
    userId,
    userProfile: userProfileName,
    userSetTokenLikeExpired,
  });

  const mountDataTable = React.useCallback(async () => {
    setUserCompaniesDataTable(await normalizeUserCompaniesDataTable(userCompanies));
    setUserCompaniesDataTableCount(userCompaniesCount);
  }, [
    userCompanies,
    userCompaniesCount,
  ]);

  React.useEffect(() => { mountDataTable(); }, [mountDataTable]);

  const onUpdateCompanyClicked = async companyIdClicked => {
    const newActiveCompanyToEdit = await getUserCompany(companyIdClicked);

    if (R.isNil(newActiveCompanyToEdit)) {
      enqueueSnackbar(dataTableTranslation.on_update_company_error, { variant: 'error' });

      return;
    }

    reduxDispatch(CompanyActions.setActiveCompany(newActiveCompanyToEdit));
    reduxHistory.push('/companies/update');
  };

  const onCompanyReportClicked = async companyIdClicked => {
    const newActiveCompanyToReport = await getUserCompany(companyIdClicked);

    if (R.isNil(newActiveCompanyToReport)) {
      enqueueSnackbar('Erro ao buscar informações de relatório desta empresa, tente novamente!', { variant: 'error' });

      return;
    }

    reduxDispatch(CompanyActions.setActiveCompany(newActiveCompanyToReport));
    reduxHistory.push('/company/report');
  };

  const onDeleteCompanyClicked = async (companyIdToDelete) => {
    const userCompanyToDelete = userCompanies.find(
      company => company.id === companyIdToDelete,
    );

    if (isGuest(userCompanyToDelete)) {
      const guestUser = userCompanyToDelete.guests.find(guest => guest.user.id === userId);
      return deleteGuestUserFromCompany(guestUser.id);
    }

    return deleteUserCompany(companyIdToDelete);
  };

  const onLocationsClicked = async companyIdClicked => {
    const newActiveCompanyToEdit = await getUserCompany(companyIdClicked);

    if (R.isNil(newActiveCompanyToEdit)) {
      enqueueSnackbar(dataTableTranslation.on_update_company_error, { variant: 'error' });

      return;
    }

    reduxDispatch(CompanyActions.setActiveCompany(newActiveCompanyToEdit));
    reduxHistory.push('/location/list');
  };

  const handlePushToAddCompany = () => reduxHistory.push('/companies/create');

  const columnsToTable = getColumnsToTable(
    { translate: mainTranlations, userProfile: userProfileName },
  );

  const onSortCompaniesClicked = (changedColumn, direction) => {
    if (changedColumn === 'Nome') {
      setUserCompaniesOrderByName(direction.toUpperCase());
    }
  };

  return (
    <StyledMyCompanies>
      <Helmet>
        <meta property="og:title" content="Minhas Empresas - SaaS Hublocal" />
        <meta property="og:description" content="Gerencie sua empresas." />

        <title>Minhas Empresas - Saas Hublocal</title>
      </Helmet>

      {userCompaniesDataTable && (
        <HeaderToolbar
          title="Listagem de empresas"
        >
          <HealderToolbarAction
            title="Adicionar empresa"
            icon="AddBox"
            onClick={handlePushToAddCompany}
          />
        </HeaderToolbar>
      )}

      <StyledContent>
        {userCompaniesDataTable ? (
          <DataTable
            count={userCompaniesDataTableCount}
            page={userCompaniesPageIndex}
            setPage={setUserCompaniesPageIndex}
            pageSize={userCompaniesPageSize}
            setPageSize={setUserCompaniesPageSize}
            searchQuery={userCompaniesPageQuery}
            setSearchQuery={setUserCompaniesPageQuery}
            loading={userCompaniesLoading}
            title={mainTranlations.companies}
            columns={columnsToTable}
            data={userCompaniesDataTable}
            clickable={false}
            firstOperationName={mainTranlations.places}
            onSortClicked={onSortCompaniesClicked}
            onFirstOperationClicked={onLocationsClicked}
            onDeleteClicked={onDeleteCompanyClicked}
            onUpdateClicked={onUpdateCompanyClicked}
            onReportClicked={onCompanyReportClicked}
            addRegisterLabel="Adicionar Empresa"
            addRegisterLink="/companies/create"
            emptyTableTitle="Você não tem nenhuma empresa registrada"
            emptyTableTitleButton="Adicionar empresa"
            confirmDialogTitle="Listagem de empresas"
            confirmDialogContentText="Tem certeza que deseja excluir essa empresa?"
            userProfileName={userProfileName}
            companyTable
          />
        ) : (
          <EmptyCompanies
            isLoading={userCompaniesLoading}
            letsStartRedirect={() => reduxHistory.push('/companies/create')}
          />
        )}

        {userCompaniesLoading
        && <OverlayLoading textToLoading={overlayLoadingTextUseCompany} fullScreen />}
      </StyledContent>
    </StyledMyCompanies>
  );
};

export default MyCompanies;
