import React from 'react';
import * as R from 'ramda';

const statusTag = {
  owner: 'Proprietário',
  guest: 'Convidado',
};

export const getColumnsToTable = ({ translate, userProfile }) => {
  const customColumns = [
    {
      name: translate.id,
      options: { display: false, sort: false },
    },
    { name: translate.name, options: { sort: false } },
    { name: translate.creation_date, options: { sort: false } },
    { name: translate.status, options: { sort: false } },
  ];

  const columnStatusToInsert = {
    name: 'Permissão',
    options: {
      customBodyRender: (value) => (
        <span className={`tag ${value}`}>{statusTag[value]}</span>
      ),
    },
  };

  if (userProfile !== 'Usuário Padrão') return customColumns;

  return R.insert(2, columnStatusToInsert, customColumns);
};

export const getColumnValues = ({ companyGroup, userProfileName, translate }) => {
  const columnsList = [
    companyGroup.id,
    companyGroup.name,
    companyGroup?.date_created,
    companyGroup.is_active ? translate.active : translate.inactive,
  ];

  const columnStatusToInsert = companyGroup.permissionStatus;

  if (userProfileName !== 'Usuário Padrão') return columnsList;

  return R.insert(2, columnStatusToInsert, columnsList);
};

export const getNormalizedDataTable = ({
  userCompanyGroups,
  userProfileName,
  translate,
}) => {
  const normalizedDataTable = userCompanyGroups.map(companyGroup => {
    const normalizedDataField = getColumnValues({ companyGroup, userProfileName, translate });

    return normalizedDataField;
  });

  return normalizedDataTable;
};

export const hasCompanies = ({
  activeCompany,
  userCompanies,
  allCompanies,
}) => userProfile => {
  if (userProfile !== 'Usuário Padrão') {
    const status = !activeCompany || allCompanies.length === 0;
    const message = 'Nenhuma empresa cadastrada no sistema';

    return {
      status,
      message,
    };
  }
  return {
    status: !activeCompany || userCompanies.length === 0,
    message: 'Você não tem nenhuma empresa cadastrada ainda',
  };
};
