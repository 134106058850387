import React, { useEffect } from 'react';
import * as R from 'ramda';

import APIWorkingHoursCreateTimesRange from '../../../services/workingHours/create-working-hours-times-range';
import APICreateWorkingHours from '../../../services/workingHours/createWorkingHours';

import { useAuth } from '../../../hooks';

import WorkingHoursContext from './working-hours-context';
import SpecialDates from './special-dates';

import { workingHoursMocked, sortByDay } from './helpers';

import OpeningHours from './opening-hours';

import { StyledLocationProfile } from '../location-profile-styles';
import { TWorkingHoursProps } from './types';
import { TSpecialDateEntity, TWorkingHoursEntity } from '../../../types/TWorkingHours';

const WorkingHours = ({
  initialData,
  setUpdatedLocation,
  showSpecialDates = true,
}:TWorkingHoursProps) => {
  const { userAccessToken, userSetTokenLikeExpired } = useAuth();

  const { working_hours: workingHours, special_dates: initialSpecialDates } = initialData;

  const [workingHoursPerDay, setWorkingHoursPerDay] = React.useState<TWorkingHoursEntity[]>(workingHours || workingHoursMocked);
  const [specialDates, setSpecialDates] = React.useState<TSpecialDateEntity[] | null>(initialSpecialDates);
  const [currentLocation, setCurrentLocation] = React.useState(initialData);

  const [isCreateWorkingHours, setIsCreateWorkingHours] = React.useState(false);
  const [isCreateWorkingHoursText, setIsCreateWorkingHoursText] = React.useState('Processando...');

  const workingHoursProviderValue = {
    workingHoursPerDay,
    setWorkingHoursPerDay,
    specialDates,
    setSpecialDates,
  };

  useEffect(() => {
    setUpdatedLocation(prev => ({ ...prev, working_hours: workingHoursPerDay }));
  }, [workingHoursPerDay]);

  useEffect(() => {
    setUpdatedLocation(prev => ({ ...prev, special_dates: specialDates }));
  }, [specialDates]);

  useEffect(() => {
    if (currentLocation?.id !== initialData.id) {
      setCurrentLocation(initialData);
    }
    if (!R.equals(initialSpecialDates, specialDates)) {
      setSpecialDates(initialSpecialDates);
    }
  }, [initialData]);

  const createWorkingHours = React.useCallback(async () => {
    const newWorkingHoursThisLocation = await Promise.all(
      workingHoursMocked.map(async (workingHoursDay) => {
        const {
          times,
          is_open: isOpen,
          is_replicated: isReplicated,
          day_title: dayTitle,
          is_24_hours: is24Hours,
          dropdown_to_bottom: dropdownToBottom,
        } = workingHoursDay;

        setIsCreateWorkingHoursText('Criando faixas de horários...');

        const newTimesThisWorkingHoursDay = await Promise.all(
          times.map(async ({ open, close }) => {
            const newTimeResponse = await APIWorkingHoursCreateTimesRange({
              accessToken: userAccessToken,
              setTokenLikeExpired: userSetTokenLikeExpired,
              open,
              close,
              isCreateWorkingHours: setIsCreateWorkingHours,
            });

            if (R.isNil(newTimeResponse)) return null;

            return {
              ...newTimeResponse,
              is_deleted: false,
            };
          }),
        );

        setIsCreateWorkingHoursText('Criando horário por dia...');

        const newWorkingHoursDay = await APICreateWorkingHours({
          accessToken: userAccessToken,
          setTokenLikeExpired: userSetTokenLikeExpired,
          isOpen,
          isReplicated,
          times: newTimesThisWorkingHoursDay,
          dropdownToBottom,
          is24Hours,
          dayTitle,
          isCreateWorkingHours: setIsCreateWorkingHours,
          location: [initialData],
        });

        if (R.isNil(newWorkingHoursDay)) return null;

        return {
          ...newWorkingHoursDay,
        };
      }),
    );

    if (
      R.isNil(newWorkingHoursThisLocation)
      || R.isEmpty(newWorkingHoursThisLocation)
    ) return;

    setIsCreateWorkingHoursText(
      'Salvando horários de funcionamento neste local...',
    );

    if (R.isNil(initialData)) return;

    setWorkingHoursPerDay(
      newWorkingHoursThisLocation.sort((prevDay, nextDay) => sortByDay({ prevDay, nextDay })),
    );
  }, []);

  const appendIsDeletedInWorkingHours = (workingHoursList: TWorkingHoursEntity[]) => {
    const newWorkingHours = workingHoursList.map((workingHoursDay) => {
      const { times } = workingHoursDay;

      if (R.isEmpty(times) || R.isNil(times)) {
        return {
          ...workingHoursDay,
          times: [
            {
              id: 1,
              open: '00:00',
              close: '00:00',
              is_deleted: false,
            },
          ],
        };
      }

      const newTimes = times.map((time) => ({ ...time, is_deleted: false }));

      return {
        ...workingHoursDay,
        times: newTimes,
      };
    });

    return newWorkingHours.sort((prevDay, nextDay) => sortByDay({ prevDay, nextDay }));
  };

  React.useEffect(() => {
    if (R.isNil(initialData)) return;

    const { working_hours: initialWorkingHours } = initialData;

    if (!R.isNil(initialWorkingHours) && !R.isEmpty(initialWorkingHours)) {
      setWorkingHoursPerDay(
        appendIsDeletedInWorkingHours(initialWorkingHours),
      );
    } else {
      createWorkingHours();
    }
  }, [currentLocation]);

  return (
    <StyledLocationProfile hasNoContent={false}>
      <WorkingHoursContext.Provider value={workingHoursProviderValue}>
        <OpeningHours
          isCreateOpeningHours={isCreateWorkingHours}
          isCreateOpeningHoursStatus={isCreateWorkingHoursText}
        />
        {showSpecialDates && (
          <SpecialDates
            currentLocation={initialData}
          />
        )}
      </WorkingHoursContext.Provider>
    </StyledLocationProfile>
  );
};

export default React.forwardRef(WorkingHours);
