import React from 'react';

import { isEmpty } from 'ramda';
import ManualSyncPlatform from './manual-sync-platform';
import Button from '../button';
import Loading from '../loading';

import platforms from './platforms';

import {
  StyledPlatforms,
  StyledPlatformListing,
  StyledTitle,
} from './manual-sync-platforms-styles';
import LastSyncHistory from './last-sync-history';

export const ManualSyncPlatforms = ({
  ticketStatusData,
  onSyncClicked,
  isSync,
  lastSyncHistory = [],
  onLastSyncDialogOpen,
}) => (
  <StyledPlatforms>
    <StyledTitle>
      <h3>Plataformas</h3>
    </StyledTitle>
    <Button
      disabled={isSync}
      onClick={onSyncClicked}
      className="button"
    >
      {isSync && <Loading className="is-sync-loading" />}
      {' '}
      {isSync ? 'Sincronizando...' : 'Sincronizar'}
    </Button>
    <StyledPlatformListing>
      {platforms
        ? platforms.map(platform => (
          <ManualSyncPlatform
            key={Math.random(Math.floor())}
            title={platform.title}
            syncStatus={ticketStatusData && ticketStatusData[platform.title.toLowerCase()]}
            subplatforms={platform.subplatforms}
          />
        ))
        : <p>Nenhum plataforma encontrada!</p>}
    </StyledPlatformListing>
    {!isEmpty(lastSyncHistory) && <LastSyncHistory data={lastSyncHistory} onLastSyncDialogOpen={onLastSyncDialogOpen} />}
  </StyledPlatforms>
);

export default ManualSyncPlatforms;
