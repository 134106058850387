import React from 'react';
import { Helmet } from 'react-helmet';
import { useSelector } from 'react-redux';
import { LocationHeader } from '../../components';
import { TRootStateRedux } from '../../types/TRootStateRedux';
import isNilOrEmpty from '../../utils/is-null-or-empty';
import LastSync from './last-sync';

const LastSyncHistory = () => {
  const { activeLocation } = useSelector(
    (state: TRootStateRedux) => state.LocationReducer,
  );

  return (
    <>
      <Helmet>
        <meta property="og:title" content="Últimas Sincronizações - SaaS Hublocal" />
        <meta
          property="og:description"
          content="Veja as suas últimas sincronizações."
        />
        <title>Últimas Sincronizações - SaaS Hublocal</title>
      </Helmet>
      <LocationHeader
        hideDashboard={!activeLocation?.data_studio}
        linkedLocation={!isNilOrEmpty(activeLocation?.google_location_id)}
        isLastSyncHistory
      />
      <LastSync locationId={activeLocation.id} />
    </>
  );
};

export default LastSyncHistory;
