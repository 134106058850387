import React from 'react';

import NavItem from '../../nav-item';

import {
  StyledSubMenuItem,
  StyledAccordionMenu,
  StyledSAccordionSummary,
  StyledExpandIcon,
} from './submenu-item-styles';

const SubMenuItem = ({
  itemId,
  itemName,
  itemIcon,
  itemSubmenu,
  setIsOpenSideMenu,
  openAccordionMenu,
  setOpenAccordionMenu,
}) => {
  const handleOpenAccordion = (id) => (event, newExpanded) => {
    setOpenAccordionMenu(newExpanded ? id : 0);
  };

  return (
    <StyledSubMenuItem
      expanded={openAccordionMenu === itemId}
      onChange={handleOpenAccordion(itemId)}
    >
      <StyledSAccordionSummary
        expandIcon={<StyledExpandIcon />}
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        <NavItem
          key={itemId}
          name={itemName}
          icon={itemIcon}
          hasSubMenu
          openAccordionMenu={openAccordionMenu}
          setOpenAccordionMenu={setOpenAccordionMenu}
          setIsOpenSideMenu={setIsOpenSideMenu}
        />
      </StyledSAccordionSummary>

      <StyledAccordionMenu>
        {itemSubmenu.map(({
          id: itemSubmenuId,
          friendly_name: itemSubmenuName,
          icon: itemSubmenuIcon,
          path_to_redirect: itemSubmenuUrl,
        }) => (
          <NavItem
            key={itemSubmenuId}
            name={itemSubmenuName}
            icon={itemSubmenuIcon}
            url={itemSubmenuUrl}
            subItem
            setOpenAccordionMenu={setOpenAccordionMenu}
            openAccordionMenu={openAccordionMenu}
            setIsOpenSideMenu={setIsOpenSideMenu}
          />
        ))}
      </StyledAccordionMenu>
    </StyledSubMenuItem>
  );
};

export default SubMenuItem;
