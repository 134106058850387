import React from 'react';
import { isNil } from 'ramda';

import {
  TLocationGalleryListingBaseProps,
} from '../../../types/TLocationGalleryListing';

import {
  IconButton,
} from '../../../components';

import {
  locationGalleryMediaCategories,
  renderCreateTimeGalleryMedia,
} from '../helpers';

import {
  StyledGalleryListing,
  StyledMediaCardMedia,
  StyledMediaCardFooter,
  StyledMediaCard,
  StyledLineInfo,
  StyledMediaCardMediaOverlay,
  StyledCalendarIcon,
  StyledVisibilityIcon,
} from './location-gallery-listing-styles';

const LocationGalleryListing = ({
  data = [],
  setOpenMediaDialog,
  setSelectedMediaOnClickedIndex,
  handleDeleteMedia,
}: TLocationGalleryListingBaseProps) => {
  const handleOnClickedMedia = (indexMediaOnData: number) => {
    setOpenMediaDialog(true);
    setSelectedMediaOnClickedIndex(indexMediaOnData);
  };

  return (
    <StyledGalleryListing>
      {data.map((mediaItem, indexMedia) => (
        <StyledMediaCard>
          <StyledMediaCardMedia>
            {isNil(mediaItem.googleUrl) ? (
              <span>Nenhuma mídia inserida!</span>
            ) : (
              <img src={mediaItem.googleUrl} alt={mediaItem.name} />
            )}

            <StyledMediaCardMediaOverlay
              onClick={() => handleOnClickedMedia(indexMedia)}
            />
          </StyledMediaCardMedia>

          <StyledMediaCardFooter>
            <StyledLineInfo>
              <div className="gallery-categories">
                <b>Categorias:</b>
                {' '}
                {locationGalleryMediaCategories[mediaItem.locationAssociation.category]}
              </div>

              {locationGalleryMediaCategories[mediaItem.locationAssociation.category] !== 'Logo' && (
                <div className="gallery-views">
                  <StyledVisibilityIcon />

                  {isNil(mediaItem.insights.viewCount) ? 0 : mediaItem.insights.viewCount}
                </div>
              )}
            </StyledLineInfo>

            <StyledLineInfo>
              <div className="gallery-card-date">
                <StyledCalendarIcon />
                {renderCreateTimeGalleryMedia(mediaItem.createTime)}
              </div>

              <IconButton
                className=""
                tooltip="Apagar mídia"
                icon="Delete"
                tooltipVariant="red"
                onClick={() => handleDeleteMedia(mediaItem.name)}
                placement="bottom"
              />
            </StyledLineInfo>
          </StyledMediaCardFooter>
        </StyledMediaCard>
      ))}
    </StyledGalleryListing>
  );
};

export default LocationGalleryListing;
