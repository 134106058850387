import React from 'react';

import ConfigCard from '../../../components/config-card';

import {
  StyledHandlerList,
} from './handler-list-styles';

const HandlerList = ({ navigation }) => (
  <ConfigCard
    title="Controladores"
    onClick={() => navigation.push('/handlers/list')}
  >
    <StyledHandlerList>
      Controladores
    </StyledHandlerList>
  </ConfigCard>
);

export default HandlerList;
