import styled from 'styled-components';
import { List } from '@material-ui/core';
import { rgba } from 'polished';

export const StyledGuests = styled.div`
  margin-bottom: 2rem;
  display: flex;
  flex-direction: column;

  .guest-list-loading-text {
    color: ${props => props.theme.colors.white};
    font-weight: 400;
  }

  .guest-list-loading {
    align-self: center;
    margin-bottom: 1rem;
  }
`;

export const StyledGuestsList = styled(List)``;

export const StyledLoadingGuestList = styled.div`
  display: flex;
  flex-direction: column;
  padding: 1rem;
  background: ${props => rgba(props.theme.colors.black, 0.80)};
  text-align: center;
  justify-content: center;
`;
