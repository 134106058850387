import React from 'react';
import * as R from 'ramda';

import {
  AutocompleteInput,
  UncontrolledField,
  NotificationBox,
} from '../../../../components';

import { useUncontrolledField, useAuth } from '../../../../hooks';

import {
  mainPhone,
  secondaryPhone,
  longDescription,
} from './fields-scheme';

import type { TInfos } from '../../../../types/TBatchUpdate';

import { getCategoryGroupingPaginated } from '../../../../services/category-groupings';
import getPlatformCategories from '../../../../services/categories/getPlatformCategories';

import {
  filterRepeated,
} from './helpers';

import {
  StyledInfos,
  StyledInputGroup,
  StyledSectionTitle,
  StyledDescriptionWrapper,
} from './infos-styles';

const Infos = ({
  userAccessToken,
  userSetTokenLikeExpired,
  categoryGroupingsSelected,
  setCategoryGroupingsSelected,
  selectedGoogleCategories,
  setSelectedGoogleCategories,
  selectedFacebookCategories,
  setSelectedFacebookCategories,
  selectedFoursquareCategories,
  setSelectedFoursquareCategories,
  setFields,
  setFieldsToDefault,
}: TInfos) => {
  const [categoryGroupings, setCategoryGroupings] = React.useState([]);
  const [googleCategories, setGoogleCategories] = React.useState([]);
  const [facebookCategories, setFacebookCategories] = React.useState([]);
  const [foursquareCategories, setFoursquareCategories] = React.useState([]);

  const { fieldProps: mainPhoneProps } = useUncontrolledField({
    fieldScheme: { ...mainPhone },
    saveIn: setFields,
  });

  const { fieldProps: secondaryPhoneProps } = useUncontrolledField({
    fieldScheme: secondaryPhone,
    saveIn: setFields,
  });

  const { fieldProps: longDescriptionProps } = useUncontrolledField({
    fieldScheme: { ...longDescription },
    saveIn: setFields,
  });

  const { userProfileName } = useAuth();

  const userAsAdminOrOperator = userProfileName !== 'Usuário Padrão';

  const getCategoryGroupings = React.useCallback(async () => {
    const groupings = await getCategoryGroupingPaginated({
      accessToken: userAccessToken,
      setTokenLikeExpired: userSetTokenLikeExpired,
    });

    if (groupings) {
      const { groupingsData } = groupings;

      const groupingsDataNormalized = groupingsData.map((grouping: any) => ({
        id: grouping.id,
        name: grouping.name,
        facebookCategories: JSON.parse(grouping.facebook_categories),
        googleCategories: JSON.parse(grouping.google_categories),
        foursquareCategories: JSON.parse(grouping.foursquare_categories),
      }));

      setCategoryGroupings(groupingsDataNormalized);
    }
  }, [userAccessToken]);

  const getGoogleCategories = React.useCallback(async () => {
    const categories = await getPlatformCategories({
      accessToken: userAccessToken,
      query: '',
      platform: 'google',
    });

    setGoogleCategories(categories[0]);
  }, []);

  const getFacebookCategories = React.useCallback(async () => {
    const categories = await getPlatformCategories({
      accessToken: userAccessToken,
      query: '',
      platform: 'facebook',
    });

    setFacebookCategories(categories[0]);
  }, []);

  const getFoursquareCategories = React.useCallback(async () => {
    const categories = await getPlatformCategories({
      accessToken: userAccessToken,
      query: '',
      platform: 'foursquare',
    });

    setFoursquareCategories(categories[0]);
  }, []);

  const handleSetCategoryGroupingSelected = (categoryGroupingSelected: any) => {
    if (!R.isEmpty(categoryGroupingSelected)) {
      R.forEach(categoryGrouping => {
        const {
          facebookCategories: facebookCategoriesValue = [],
          googleCategories: googleCategoriesValue = [],
          foursquareCategories: foursquareCategoriesValue = [],
        }: any = categoryGrouping || {};

        setSelectedFacebookCategories((prevState:any) => [
          ...prevState,
          ...facebookCategoriesValue.filter((categoryItem: any) => filterRepeated({
            categoryItem,
            categoriesReference: prevState,
          })),
        ]);

        setSelectedGoogleCategories((prevState:any) => [
          ...prevState,
          ...googleCategoriesValue.filter((categoryItem: any) => filterRepeated({
            categoryItem,
            categoriesReference: prevState,
          })),
        ]);

        setSelectedFoursquareCategories((prevState:any) => [
          ...prevState,
          ...foursquareCategoriesValue.filter((categoryItem: any) => filterRepeated({
            categoryItem,
            categoriesReference: prevState,
          })),
        ]);
      }, categoryGroupingSelected);
    }

    setCategoryGroupingsSelected(categoryGroupingSelected);
  };

  React.useEffect(() => { getGoogleCategories(); }, [getGoogleCategories]);
  React.useEffect(() => { getFacebookCategories(); }, [getFacebookCategories]);
  React.useEffect(() => { getFoursquareCategories(); }, [getFoursquareCategories]);
  React.useEffect(() => { getCategoryGroupings(); }, [getCategoryGroupings]);

  return (
    <StyledInfos>
      <NotificationBox
        text="Atenção, talvez não seja possível atualizar seu número de telefone
          no momento devido a um processo interno de validação de dados do Google.
          As demais informações serão atualizadas normalmente."
        type="WARNING"
      />
      <StyledSectionTitle>Informações Gerais</StyledSectionTitle>

      <StyledInputGroup>
        <UncontrolledField {...mainPhoneProps} />
        <UncontrolledField {...secondaryPhoneProps} />
      </StyledInputGroup>

      {userAsAdminOrOperator && (
        <>
          <StyledSectionTitle>Categorias</StyledSectionTitle>

          <StyledInputGroup>
            <AutocompleteInput
              label="Grupo de Categorias"
              options={categoryGroupings}
              saveSelectedOpionsIn={handleSetCategoryGroupingSelected}
              initialValue={categoryGroupingsSelected}
              filterSearchKey="name"
              categoryId="id"
              saveAllObject
              disclaimer="Nenhuma categoria encontrada"
              setOtherFieldsToDefault={setFieldsToDefault}
            />

            <AutocompleteInput
              label="Google"
              options={googleCategories}
              saveSelectedOpionsIn={setSelectedGoogleCategories}
              initialValue={selectedGoogleCategories}
              filterSearchKey="name"
              categoryId="platform_id"
              saveAllObject
              disclaimer="Nenhuma categoria encontrada"
              setOtherFieldsToDefault={setFieldsToDefault}
            />

            <AutocompleteInput
              label="Facebook"
              options={facebookCategories}
              saveSelectedOpionsIn={setSelectedFacebookCategories}
              initialValue={selectedFacebookCategories}
              filterSearchKey="name"
              categoryId="platform_id"
              saveAllObject
              disclaimer="Nenhuma categoria encontrada"
              setOtherFieldsToDefault={setFieldsToDefault}
            />

            <AutocompleteInput
              label="Foursquare"
              options={foursquareCategories}
              saveSelectedOpionsIn={setSelectedFoursquareCategories}
              initialValue={selectedFoursquareCategories}
              filterSearchKey="name"
              categoryId="platform_id"
              saveAllObject
              disclaimer="Nenhuma categoria encontrada"
              setOtherFieldsToDefault={setFieldsToDefault}
            />
          </StyledInputGroup>
        </>
      )}

      <StyledSectionTitle>Descrição</StyledSectionTitle>

      <StyledDescriptionWrapper>
        <UncontrolledField {...longDescriptionProps} />
      </StyledDescriptionWrapper>
    </StyledInfos>
  );
};

export default Infos;
