import axios from 'axios';

export default async function createInvite({
  accessToken,
  email,
  location_access_level_profile_id,
  location_id,
  created_by = 1,
  isLoading,
  setTokenLikeExpired,
}) {
  try {
    if (isLoading) isLoading(true);

    const requestOptions = {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    };

    const { data } = await axios.post(
      `${process.env.REACT_APP_INVITES_API_URL}`,
      {
        email,
        location_access_level_profile_id,
        location_id,
        created_by,
      },
      requestOptions,
    );

    return data;
  } catch (err) {
    if (err.response?.status === 401 && setTokenLikeExpired) return setTokenLikeExpired();

    return null;
  } finally {
    if (isLoading) isLoading(false);
  }
}
