import React from 'react';
import { format } from 'date-fns';
import { isNil, isEmpty } from 'ramda';

import {
  IconButton,
  Loading,
  LogErrorDataAccordion,
} from '../../../../components';

import { useAuth } from '../../../../hooks';

import type { EActionLogActions } from '../../../../types/TBatchGoogleModules';

import { actionLogStatus } from './helpers';

import {
  StyledActionLogDialog,
  StyledHeader,
  StyledFormContent,
  StyledPostWrapper,
  StyledPostAndFilterWrapper,
  StyledLogRow,
  StyledInfo,
  StyledRowInfo,
  StyledLogDate,
} from './action-log-dialog-styles';
import { TRequestActionLogDialogBaseProps } from '../../../../types/TRequestActionLogDialog';
import { getAllLogsByRequest } from '../../../../services/platform-sync-modules/get-all-logs-by-request';

const ActionLogDialog = ({
  isDialogOpen,
  onClose,
  requestId,
}: TRequestActionLogDialogBaseProps) => {
  const { userAccessToken, userProfileName } = useAuth();

  const [actionLogs, setActionLogs] = React.useState<any[] | null>(null);
  const [loading, setLoading] = React.useState(true);

  const getInfoToVisualize = React.useCallback(async () => {
    if (isNil(requestId)) return;

    const getActionLogResponse = await getAllLogsByRequest({
      userAccessToken,
      requestId,
    });

    if (isNil(getActionLogResponse)) return;
    const [actionLogsData] = getActionLogResponse;

    setActionLogs(actionLogsData);
    setLoading(false);
  }, [requestId]);

  const shouldShowErrorDetailsAccordion = React.useCallback(
    (logAction: EActionLogActions) => logAction === 'ERROR'
      && (userProfileName === 'Admin' || userProfileName === 'Operacional'),
    [userProfileName],
  );

  React.useEffect(() => {
    getInfoToVisualize();
  }, [getInfoToVisualize]);

  return (
    <StyledActionLogDialog
      open={isDialogOpen}
      aria-labelledby="simple-dialog-title"
      onClose={onClose}
    >
      <StyledHeader>
        Histórico de Solicitação
        <IconButton
          icon="Close"
          tooltip="Fechar"
          onClick={onClose}
        />
      </StyledHeader>

      <StyledPostAndFilterWrapper>
        <StyledPostWrapper>
          <StyledFormContent>
            {loading && <Loading />}

            {!isNil(actionLogs) && !loading
                && actionLogs.map((logItem) => (
                  <StyledLogRow>
                    <StyledInfo>
                      <StyledLogDate>
                        Data da Ação:
                        {' '}
                        {format(
                          new Date(logItem.created_at),
                          'dd/MM/yyyy HH:mm:ss',
                        )}
                      </StyledLogDate>
                    </StyledInfo>

                    <StyledRowInfo>
                      <StyledInfo>
                        <p>
                          Status:&nbsp;
                          <b>{actionLogStatus[logItem.status]}</b>
                        </p>
                      </StyledInfo>

                      <StyledInfo>
                        <p>
                          Usuário:&nbsp;
                          <b>{logItem.name}</b>
                        </p>
                      </StyledInfo>
                    </StyledRowInfo>

                    <StyledRowInfo>
                      <StyledInfo>
                        <p>
                          Descrição:&nbsp;
                          {logItem.description}
                        </p>
                      </StyledInfo>

                      <StyledInfo>
                        <p>
                          Email:&nbsp;
                          <b>{logItem.email}</b>
                        </p>
                      </StyledInfo>
                    </StyledRowInfo>
                    {shouldShowErrorDetailsAccordion(logItem.status) && (
                      <StyledRowInfo>
                        <StyledInfo>
                          <LogErrorDataAccordion
                            errorData={logItem.error_data}
                          />
                        </StyledInfo>
                      </StyledRowInfo>
                    )}
                  </StyledLogRow>
                ))}

            {isEmpty(actionLogs) && !loading && (
            <p>
              Não foi possível processar um histórico para esta sincronização!
            </p>
            )}
          </StyledFormContent>
        </StyledPostWrapper>
      </StyledPostAndFilterWrapper>
    </StyledActionLogDialog>
  );
};

export default ActionLogDialog;
