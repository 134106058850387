export const longDescriptionAttr = {
  name: 'long_description',
  label: 'Descrição',
  description: 'Descrição da localidade',
  textarea: {
    rows: 5,
  },
  hasBorder: true,
  hasRadius: true,
  isTextArea: true,
  descriptionSize: 750,
};
