import axios from 'axios';

export default async function createResponsible({
  accessToken,
  name,
  email,
  phone,
  created_by = 1,
  setTokenLikeExpired,
  setIsCreatingResponsible,
  feedbackMessage,
}) {
  try {
    if (setIsCreatingResponsible) setIsCreatingResponsible(true);
    if (feedbackMessage) feedbackMessage('Criando responsável...', { variant: 'warning' });

    const { data } = await axios.post(`${process.env.REACT_APP_RESPONSIBLES_API_URL}`,
      {
        name,
        email,
        phone,
        created_by,
      },
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });

    if (feedbackMessage) feedbackMessage('Responsável criado com sucesso!', { variant: 'success' });

    return data;
  } catch (err) {
    if (err.response?.status === 401 && setTokenLikeExpired) setTokenLikeExpired();

    return err;
  } finally {
    if (setIsCreatingResponsible) setIsCreatingResponsible(false);
  }
}
