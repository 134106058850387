export const fieldCategoryGroupName = {
  classNameInputWrapper: 'add-category-group-field',
  name: 'category-group-name',
  nickname: 'Nome do Grupo',
  type: 'text',
  label: 'Nome',
  hasborder: 'yes',
  required: true,
};

export const fieldCategoryGroupDescription = {
  classNameInputWrapper: 'add-category-group-field',
  name: 'category-group-description',
  nickname: 'Descrição do Grupo',
  textarea: {
    rows: 5,
  },
  label: 'Descrição',
  hasborder: 'yes',
  required: true,
};
