import React from 'react';
import { TOverviewDataCount } from './types';

import {
  StyledOverviewDataCount,
  StyledCircularProgressBottom,
  StyledCircularProgressTop,
  StyledLabelCount,
  StyledDataLabel,
  StyledContentCircularProgress,
} from './overview-data-count-styles';

export const OverviewDataCount = ({
  themeColor = 'primary',
  size = 100,
  progressValue = 30,
  totalValue = 100,
  dataLabel = 'Label',
}: TOverviewDataCount) => (
  <StyledOverviewDataCount position="relative" display="inline-block">
    <StyledContentCircularProgress>
      <StyledCircularProgressTop
        themeColor={themeColor}
        variant="determinate"
        color="inherit"
        size={size}
        value={
          totalValue > 0
            ? Math.round((progressValue / totalValue) * 100)
            : undefined
        }
      />
      <StyledCircularProgressBottom
        variant="determinate"
        color="inherit"
        size={size}
        value={100}
      />
      <StyledLabelCount>
        <h3 className="label-text">{progressValue}</h3>
      </StyledLabelCount>
    </StyledContentCircularProgress>
    <StyledDataLabel>{dataLabel}</StyledDataLabel>
  </StyledOverviewDataCount>
);
