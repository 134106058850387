const dataNormalize = ({ data, pivotKey }) => {
  let newData = [];

  const recur = (dataToRecur, loopOfRecur = 0) => {
    if (loopOfRecur >= dataToRecur.length) return;

    const dataItem = dataToRecur[loopOfRecur];

    if (dataItem[pivotKey]) {
      recur(dataItem[pivotKey]);
    } else {
      newData = [
        ...newData,
        {
          name: dataItem.name,
          id: dataItem.id,
        },
      ];
    }

    recur(dataToRecur, loopOfRecur + 1);
  };

  recur(data);

  return newData;
};

export default dataNormalize;
