import styled from 'styled-components';
import { devices } from '../../../../../assets/styles/devices';

export const StyledFiltersContainer = styled.div`
  display: flex;
  flex-direction: column;
  column-gap: 1rem;
  padding-top: 1rem;
  padding-bottom: 2rem;
  width: 100%;

  @media ${devices.tablet} {
    flex-direction: row;
    padding-bottom: 0rem;
  }
`;

