export const propNameToCamelCases = (propName: string) => {
  if (typeof propName !== 'string') return propName;

  const dashRemove = String(propName.replace(/-/g, ' '));
  const propWithUpperCase = dashRemove.trim().toLowerCase().replace(/\w\S*/g, (w) => (w.replace(/^\w/, c => c.toUpperCase())));
  const spacesRemoved = propWithUpperCase.replace(/\s/g, '');

  const newPropNameWithCamelCase = Array.from(spacesRemoved).map((caractere, index) => {
    if (index === 0) return caractere.toLowerCase();
    return caractere;
  }).join('');

  return newPropNameWithCamelCase;
};
