import React from 'react';
import { TLocationEntity } from './TLocation';

export enum LocationConnectionStatusEnum {
  VALID = 'VALID',
  REFRESH_TOKEN_EXPIRED = 'REFRESH_TOKEN_EXPIRED',
  UNAUTHORIZED = 'UNAUTHORIZED',
  STANDBY = 'STANDBY',
}

export enum LocationConnectionPlatforms {
  GOOGLE = 'google',
  FACEBOOK = 'facebook',
  FOURSQUARE = 'foursquare',
  YELP = 'yelp',
}

export interface TCreateFacebookLocationConnection
  extends TCreateLocationConnection {
  connection_info: FacebookLocationConnectionInfo;
}

export type FacebookLocationConnectionInfo = {
  facebook_page_id: string;
  user_email: string;
  location_name: string;
  address: string | undefined;
};

export type GoogleLocationConnectionInfo = {
  google_account_id: string;
  google_location_id: string;
  user_email: string;
  location_name: string;
  address: string | undefined;
  grouping_name: string;
};

export type TCreateLocationConnection = {
  accessToken: string;
  createdBy: number;
  token: string;
  refreshToken?: string;
  tokenExpiresAt: string;
  status: LocationConnectionStatusEnum;
  code?: string;
  platform: LocationConnectionPlatforms;
  connectionInfo: GoogleLocationConnectionInfo | FacebookLocationConnectionInfo;
  location: TLocationEntity;
  setTokenLikeExpired?: () => void;
};

export type TUpdateLocationConnection = {
  accessToken: string;
  id: string;
  token?: string;
  refreshToken?: string;
  tokenExpiresAt?: string;
  status?: LocationConnectionStatusEnum;
  code?: string;
  connectionInfo?: GoogleLocationConnectionInfo;
  userId: number;
  setTokenLikeExpired?: () => void;
};

export type TDeleteLocationConnection = {
  accessToken: string;
  setTokenLikeExpired?: () => void;
  id: string;
};

export type TDeleteAllConnectionsFromLocation = {
  accessToken: string;
  setTokenLikeExpired?: () => void;
  locationId: number;
};

export type TGetAllConnectionsFromLocation = {
  accessToken: string;
  page?: number;
  pageSize?: number;
  locationId: number;
  status?: LocationConnectionStatusEnum;
  userId?: number;
  googleLocationId?: string;
  platform?: string;
  setTokenLikeExpired?: () => void;
};

export type TGetAllConnectionsFromGoogleLocationId = {
  accessToken: string;
  page?: number;
  pageSize?: number;
  status?: LocationConnectionStatusEnum;
  googleLocationId: string;
  setTokenLikeExpired?: () => void;
};

export type TGetAllConnectionsFromFacebookPageId = {
  accessToken: string;
  page?: number;
  pageSize?: number;
  status?: LocationConnectionStatusEnum;
  facebook_page_id: string;
  setTokenLikeExpired?: () => void;
};

export type TGetConnectionLocation = {
  accessToken: string;
  id: string;
  setTokenLikeExpired?: () => void;
};

export type TSetOptionsInDropDownAccounts = {
  googleAccessToken: string;
  googleUserAccountsPage: string;
};

export type TGoogleUserAccountsData = {
  id: string;
  name: string;
};

export type TSetOptionsInDropDownAccountsResponse = {
  googleUserAccounts: TGoogleUserAccountsData[];
  nextGoogleUserAccountPageToken: string;
};

export type TGetGoogleUserAccountLocations = {
  currentUserAccount: TGoogleUserAccountsData;
  userLocationsPage: string;
  queryUserLocation: string;
  accessToken: string;
  isFetching?: React.Dispatch<React.SetStateAction<boolean>>;
  filterInvalidLocations?: boolean;
};

export type TGetGoogleUserAccountLocationsResponse = {
  locations: any;
  locationsPageToken: string;
};

export type TLocationConnectionInfo = {
  token: string;
  code?: string;
  token_expires_in: string;
  refresh_token?: string;
  email: string;
  grouping_name: string;
};

export type TLocationList = {
  data: any;
  locationsFrom: TGoogleUserAccountsData;
  getGoogleUserLocations: (
    params: TGetGoogleUserAccountLocations
  ) => Promise<TGetGoogleUserAccountLocationsResponse>;
  initialDataToken: string;
  googleAccessToken: string;
  userAccessToken: string;
  userId: number;
  userSetTokenLikeExpired: () => void;
  pageList: string[];
  setPageList: React.Dispatch<React.SetStateAction<string[]>>;
  locationConnectionInfo: TLocationConnectionInfo;
  isAdminOrOwner: boolean;
  locationConnectionList: any[];
  setLocationConnectionList: React.Dispatch<React.SetStateAction<any[]>>;
};

export enum UserActionEnum {
  CREATE = 'CREATE',
  UPDATE = 'UPDATE',
  DELETE = 'DELETE',
  RESTORE = 'RESTORE',
}

export type TUserActionLog = {
  userId: number;
  date_created: string;
  user_action: UserActionEnum;
};

export type TLocationConnection = {
  id: string;
  token: string;
  refresh_token: string;
  token_expires_at: string;
  status: LocationConnectionStatusEnum;
  created_by: number;
  code: string;
  platform: LocationConnectionPlatforms;
  connection_info: GoogleLocationConnectionInfo;
  user_action_log: TUserActionLog[];
  location: Location;
};

type TPlatforms = 'Google' | 'Facebook';

export type TLocationConnectionList = {
  userAccessToken: string;
  userId: number;
  setLocationConnectionList: React.Dispatch<
    React.SetStateAction<TLocationConnection[]>
  >;
  userProfileName: string;
  locationConnections: TLocationConnection[];
  getLocationConnections: () => void;
  platform?: TPlatforms;
};

export type TLocationConnectionItem = {
  connection: TLocationConnection;
  handleDeleteConnection: (locationConnectionId: string) => void;
  handleRefreshExpiredToken: (loginResponse: any) => void;
  userProfileName: string;
  userId: number;
  handleStatusSwitch: (
    connectionId: string,
    setChecked: React.Dispatch<React.SetStateAction<boolean>>,
    status: LocationConnectionStatusEnum
  ) => void;
  platform?: TPlatforms;
};

export type TGoogleLoggedAccount = {
  handleGoogleLogout: () => void;
  loggedGoogleEmail: string;
};

export type TLocationListItem = {
  googleLocationName: string;
  googleLocationNameId: string;
  googleLocationAddress: string[] | null;
  userAccessToken: string;
  userId: number;
  googleUserId: any;
  disabledLink: boolean;
  userSetTokenLikeExpired: () => void;
  locationConnectionInfo: TLocationConnectionInfo;
  isAdminOrOwner: boolean;
  locationConnectionList: any[];
  setLocationConnectionList: React.Dispatch<React.SetStateAction<any[]>>;
};

export type TLinkedLocations = {
  data: any[];
  isLoading: boolean;
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
  disabledLink: boolean;
};

export type TLinkedLocation = {
  locationName: string;
  locationId: number;
  locationAddress: string;
  isLoading: boolean;
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
};
