import styled, { css } from 'styled-components';
import { rgba } from 'polished';

import { TOverlayLoadingBaseProps } from '../../types/TOverlayLoading';

export const StyledOverlayLoading = styled.div<Pick<TOverlayLoadingBaseProps, 'fullScreen'>>`
    position: absolute;
    z-index: 1200;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background: ${props => rgba(props.theme.colors.black, 0.80)};
    display: flex;
    align-items: center;
    justify-content: center;

    ${props => props.fullScreen && css`
      position: fixed;
    `};

    .text-disclaimer {
        font-size: 1.4rem;
        color: ${props => props.theme.colors.white};
        margin-left: 0.5rem;
    }
`;
