import React from "react";

const Hours = ({ dataHours }) => (
    <>
    {dataHours?.monday !== " - " && <div className="hours-day">
        <small>Segunda-Feira: </small>
        {dataHours?.monday || ''}
    </div>}

    {dataHours?.tuesday !== " - " && <div className="hours-day">
        <small>Terça-Feira: </small>
        {dataHours?.tuesday || ''}
    </div>}

    {dataHours?.wednesday !== " - " && <div className="hours-day">
        <small>Quarta-Feira: </small>
        {dataHours?.wednesday || ''}
    </div>}

    {dataHours?.thursday !== " - " && <div className="hours-day">
        <small>Quinta-Feira: </small>
        {dataHours?.thursday || ''}
    </div>}
    
    {dataHours?.friday !== " - " && <div className="hours-day">
        <small>Sexta-Feira: </small>
        {dataHours?.friday || ''}
    </div>}

    {dataHours?.saturday !== " - " && <div className="hours-day">
        <small>Sábado: </small>
        {dataHours?.saturday || ''}
    </div>}

    {dataHours?.sunday !== " - " && <div className="hours-day">
        <small>Domingo: </small>
        {dataHours?.sunday || ''}
    </div>}
  </>
)

export default Hours;
