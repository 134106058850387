import axios from 'axios';

export default async function getProfile(accessToken, profileId) {
  const requestOptions = {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  };

  const { data } = await axios.get(
    `${process.env.REACT_APP_PROFILES_API_URL}/${profileId}`,
    requestOptions,
  );

  return data;
}
