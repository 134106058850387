import styled from 'styled-components';
import { Checkbox } from '@material-ui/core';

export const StyledFilterOption = styled.li`
    display: flex;
    align-items: center;

    .filter-quantity {
        margin-left: auto;
        background: ${props => props.theme.colors.primary};
        color: ${props => props.theme.colors.white};
        font-size: 1.2rem;
        font-weight: bold;
        padding: 0.5rem;
        border-radius: 3rem;
        width: 3rem;
        height: 3rem;
        display: flex;
        align-items: center;
        justify-content: center;
    }
`;

export const StyledCheck = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;

    .check-name {
        margin-left: 1rem;
        font-size: 1.2rem;
        font-weight: normal;
    }
`;

export const StyledCheckBox = styled(Checkbox)`
    padding: 0;

   .MuiSvgIcon-root {
       fill: ${props => props.theme.colors.primary};
       width: 2rem;
       height: 2rem;
   }

   .MuiButtonBase-root {
       width: 2rem;
       height: 2rem;
   }
`;
