import React from 'react';
import * as R from 'ramda';

import { IconButton, Tooltip } from '../../../../components';

import {
  StyledSensitiveWord,
  StyledFilter,
  StyledSensitiveWordTitle,
} from './sensitive-word-styles';

const SensitiveWord = ({
  sensitiveWord,
  handleRemoveWord,
  setSensitiveWordsToFilter,
  sensitiveWordsToFilter,
  reviewsDataListing,
}) => {
  const [wordCounter, setWordCounter] = React.useState(0);

  React.useEffect(() => {
    const reviewsWithSensitiveWord = reviewsDataListing.filter(({ review }) => R.includes(R.toLower(sensitiveWord.word), R.toLower(review.comment || '')));
    setWordCounter(reviewsWithSensitiveWord.length);
  }, [reviewsDataListing]);

  const handleIsFilter = (sensitiveWordId) => {
    setSensitiveWordsToFilter(
      sensitiveWordsToFilter.map((sensitiveWordItem) => {
        if (sensitiveWordItem.id === sensitiveWordId) {
          return {
            ...sensitiveWord,
            isFiltering: !sensitiveWord.isFiltering,
          };
        }

        return sensitiveWordItem;
      }),
    );
  };

  return (
    <StyledSensitiveWord>
      <StyledSensitiveWordTitle hasNoWordCounter={wordCounter <= 0}>
        {sensitiveWord.word}
      </StyledSensitiveWordTitle>

      {(wordCounter > 0 || sensitiveWord.isFiltering === true) && (
        <Tooltip arrow title="Filtrar todas as avaliações com essa palavra">
          <StyledFilter
            onClick={() => handleIsFilter(sensitiveWord.id)}
            isFilter={sensitiveWord.isFiltering}
            className="apply-filter"
          >
            {sensitiveWord.isFiltering ? 'Filtrando...' : 'Filtrar'}
          </StyledFilter>
        </Tooltip>
      )}

      <Tooltip arrow title="Total de avaliações com essa palavra">
        <small className="count-word">{wordCounter}</small>
      </Tooltip>
      
      <IconButton
        onClick={() => handleRemoveWord(sensitiveWord.word)}
        className="icon-button-delete"
        icon="Close"
        tooltip="Apagar essa palavra"
      />
    </StyledSensitiveWord>
  );
};

export default React.memo(SensitiveWord);
