import { createActions, createReducer } from 'reduxsauce';

export const { Types, Creators } = createActions({
  setRules: ['rules'],
});

const initialState = {
  rules: {},
};

const reduxOnSetRules = (
  state = initialState, { rules },
) => ({ ...state, rules });

export default createReducer(initialState, {
  [Types.SET_RULES]: reduxOnSetRules,
});
