import { MUIDataTableColumn } from 'mui-datatables';
import { isNil, prop, has } from 'ramda';

import type {
  EBatchGalleryCategories,
  TBatchGalleryCategoriesLabel,
  TBatchGalleryLocationMediaData,
  TBatchGalleryMediaData,
} from '../../types/TBatchGallery';
import type {
  TUpdateRowDataByColumn,
  TGetDataRowCellValue,
} from '../../types/TBatchUpdate';

export const batchGalleryCategories: Record<EBatchGalleryCategories, TBatchGalleryCategoriesLabel> = {
  CATEGORY_UNSPECIFIED: 'Geral',
  COVER: 'Capa',
  LOGO: 'Logo',
};

export const initialTableColumns: MUIDataTableColumn[] = [
  {
    name: 'expandable',
    label: 'Expandir',
    options: {
      sort: false,
      viewColumns: false,
    },
  },
  {
    name: 'id',
    label: 'Id',
    options: { display: false },
  },
  {
    name: 'category',
    label: 'Categoria',
    options: { sort: false },
  },
  {
    name: 'updated-at',
    label: 'Última atualização',
    options: { sort: false },
  },
  {
    name: 'created-at',
    label: 'Data de criação',
    options: { sort: false },
  },
  {
    name: 'status',
    label: 'Status',
    options: { sort: false },
  },
  {
    name: 'operations',
    label: 'Operações',
    options: { sort: false },
  },
];

export const getDataRowCellValue = ({
  dataRowCell,
  columnRef,
}: TGetDataRowCellValue) => {
  const currentRowDataCell = dataRowCell.find(
    (rowDataCell: any) => rowDataCell && rowDataCell.columnRef === columnRef,
  );

  return !isNil(currentRowDataCell) ? currentRowDataCell.value : '';
};

export const constants: Record<string, any> = {
  FETCH_STATUS_SETINTERVAL: 300000,
  LOCATION_FETCH_STATUS_SETINTERVAL: 150000,
};

export const updateRowDataByColumn = ({
  rowData,
  payload,
}: TUpdateRowDataByColumn): any[] => rowData.map((cellData: any) => {
  if (!isNil(cellData) && has(cellData.columnRef, payload)) {
    return {
      ...cellData,
      value: prop(cellData.columnRef, payload),
    };
  }

  return cellData;
});

export const isMediaDataFromBatch = (
  mediaData: TBatchGalleryMediaData | TBatchGalleryLocationMediaData,
): mediaData is TBatchGalleryMediaData => mediaData.hasOwnProperty('sourceUrl');

export const actions = ['Cancelar'];
