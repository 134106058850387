import styled from 'styled-components';
import { Paper } from '@material-ui/core';
import { StarRate } from '@material-ui/icons';
import { devices } from '../../../../assets/styles/devices';

import { Select } from '../../../../components';

export const StyledAutomaticResponse = styled(Paper)`
    padding: 2rem;
    margin: 2rem auto;
    max-width: 90rem;
    width: 100%;
    position: relative;

    .apply-automatic-response-button {
        margin: 2rem 0 0 auto;
    }
`;

export const StyledTitleAndClose = styled.div`
    display: flex;
    flex-direction: column-reverse;
    align-items: flex-end;
    margin-bottom: 1rem;

    @media ${devices.tabletLandscape} {
    flex-direction: row;
    align-items: center;
  }
`;

export const StyledTitle = styled.div`
    display: flex;
    align-items: center;

    .title-text {
        font-size: 1.8rem;
    }

    .star-number {
        font-weight: bold;
        font-size: 2.5rem;
    }
`;

export const StyledStar = styled(StarRate)`
    width: 5rem;
    height: 5rem;
    fill: ${props => props.theme.colors.god};
`;

export const StyledStarListing = styled.ul`
    display: flex;
    align-items: center;
`;

export const StyledResponsesBox = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;    
    position: relative;

    .title-text {
        margin-bottom: 1rem;
    }

    .see-all-responses {
        color: ${props => props.theme.colors.primary};
        margin: 1rem 0 0 auto;
        cursor: pointer;
        text-decoration: underline;
    }
`;

export const StyledAutomaticResponseListing = styled.ul`
    display: flex;
    flex-direction: column;
    width: 100%;
    max-height: 50rem;
    overflow-y: scroll;

    .automatic-response-item {
        margin-bottom: 0.2rem;
        cursor: pointer;

        &:hover {
            border-left-width: 1rem;
        }
    }
`;

export const StyledAutomaticResponseListingWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
`;

export const StyledNoResponseContainer = styled.span`
  width: 100%;
  font-size: 1.8rem;
  margin-bottom: 3rem;
  padding: 2rem;
  background: ${props => props.theme.colors.primary};
  color: ${props => props.theme.colors.white};
  display: block;
  border-radius: 0.5rem;
`;

export const StyledQueryWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  column-gap: 2rem;
  align-items: center;
  width: 100%;

  @media ${devices.tabletLandscape} {
    grid-template-columns: repeat(3, 1fr);
    margin: 0 4rem 0 0;
  }

  .search-to-auto-responses {
    margin-bottom: 2rem;
  }
`;

export const StyledSelect = styled(Select)`
  width: 100%;
  white-space: nowrap;
  margin-right: 2rem;
`;
