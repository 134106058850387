import axios from 'axios';

export default async function removeCompanyGroup({
  accessToken,
  id,
  setTokenLikeExpired,
  feedbackMessage,
}) {
  try {
    if (feedbackMessage) feedbackMessage('Deletando grupo de empresa...', { variant: 'warning' });

    const { data } = await axios.delete(
      `${process.env.REACT_APP_COMPANY_GROUPS_API_URL}/${id}`,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      },
    );

    if (feedbackMessage) feedbackMessage('Grupo de empresa deletado com sucesso!', { variant: 'success' });

    return data;
  } catch (err) {
    if (err.response?.status === 401 && setTokenLikeExpired) setTokenLikeExpired();

    return null;
  }
}
