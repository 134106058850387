/* eslint-disable react/jsx-props-no-spreading */
// eslint-disable-next-line no-use-before-define
import React, { useCallback } from 'react';

import {
  StyledMainLinks,
  StyledTitle,
  StyledContent,
} from './main-links-styles';
import { TMainLinksProps } from './types';
import ControlledInput from '../../controlled-input';
import {
  facebookAttr,
  foursquareAttr,
  instagramAttr,
  linkedinAttr,
  twitterAttr,
  websiteAttr,
  yelpAttr,
  youtubeAttr,
} from './helpers';

const MainLinks = ({
  className,
  initialData,
  setUpdatedLocation,
  fieldOnFocus,
  setFieldOnFocus,
}: TMainLinksProps) => {
  const {
    website,
    facebook,
    instagram,
    twitter,
    youtube,
    linkedin,
    yelp,
    foursquare,
  } = initialData || {};

  const WebSite = useCallback(() => (
    <ControlledInput
      {...websiteAttr}
      fieldOnFocus={fieldOnFocus}
      setFieldOnFocus={setFieldOnFocus}
      setParentValue={setUpdatedLocation}
      defaultValue={website}
    />
  ), [initialData, fieldOnFocus]);

  const Facebook = useCallback(() => (
    <ControlledInput
      {...facebookAttr}
      fieldOnFocus={fieldOnFocus}
      setFieldOnFocus={setFieldOnFocus}
      setParentValue={setUpdatedLocation}
      defaultValue={facebook}
    />
  ), [initialData, fieldOnFocus]);

  const Instagram = useCallback(() => (
    <ControlledInput
      {...instagramAttr}
      fieldOnFocus={fieldOnFocus}
      setFieldOnFocus={setFieldOnFocus}
      setParentValue={setUpdatedLocation}
      defaultValue={instagram}
    />
  ), [initialData, fieldOnFocus]);

  const Twitter = useCallback(() => (
    <ControlledInput
      {...twitterAttr}
      fieldOnFocus={fieldOnFocus}
      setFieldOnFocus={setFieldOnFocus}
      setParentValue={setUpdatedLocation}
      defaultValue={twitter}
    />
  ), [initialData, fieldOnFocus]);

  const Youtube = useCallback(() => (
    <ControlledInput
      {...youtubeAttr}
      fieldOnFocus={fieldOnFocus}
      setFieldOnFocus={setFieldOnFocus}
      setParentValue={setUpdatedLocation}
      defaultValue={youtube}
    />
  ), [initialData, fieldOnFocus]);

  const Linkedin = useCallback(() => (
    <ControlledInput
      {...linkedinAttr}
      fieldOnFocus={fieldOnFocus}
      setFieldOnFocus={setFieldOnFocus}
      setParentValue={setUpdatedLocation}
      defaultValue={linkedin}
    />
  ), [initialData, fieldOnFocus]);

  const Yelp = useCallback(() => (
    <ControlledInput
      {...yelpAttr}
      fieldOnFocus={fieldOnFocus}
      setFieldOnFocus={setFieldOnFocus}
      setParentValue={setUpdatedLocation}
      defaultValue={yelp}
    />
  ), [initialData, fieldOnFocus]);

  const Foursquare = useCallback(() => (
    <ControlledInput
      {...foursquareAttr}
      fieldOnFocus={fieldOnFocus}
      setFieldOnFocus={setFieldOnFocus}
      setParentValue={setUpdatedLocation}
      defaultValue={foursquare}
    />
  ), [initialData, fieldOnFocus]);

  return (
    <StyledMainLinks className={className}>
      <StyledTitle>Links principais</StyledTitle>
      <StyledContent>
        <WebSite />
        <Facebook />
        <Instagram />
        <Twitter />
        <Youtube />
        <Foursquare />
        <Linkedin />
        <Yelp />
      </StyledContent>
    </StyledMainLinks>
  );
};

export default React.memo(React.forwardRef(MainLinks));
