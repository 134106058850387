import React from 'react';
import * as R from 'ramda';
import { debounce } from 'lodash';
import {
  Input,
  Pagination,
  List,
  SelectDropdown,
} from '..';

import {
  searchInputIsDisable,
  showPagination,
} from './helpers';

import {
  StyledGroupItensDiv,
  StyledGroupItensPartitionDiv,
  StyledListDiv,
  StyledTitle,
  StyledSides,
  StyledIsEmptyData,
  StyledHeaderFilterWrapper,
  StyledSelectDropdownWrapper,
} from './group-itens-styles';

import Loading from '../loading';

const GroupItems = ({
  itemsToAdd = [],
  addedItens = [],
  primaryTextKey = null,
  secondaryTextKey = null,
  onItemToAddClicked,
  onAddedItemClicked,
  currentItensToAddPage,
  currentAddedItensPage,
  setItensToAddPage,
  setAddedItensPage,
  loadingAddedItems,
  loadingItemsToAdd,
  itemsToAddPagesQuantity = 0,
  addedItemsPagesQuantity,
  className,
  buttonTooltipAddTitle,
  buttonTooltipRemoveTitle,
  setCompanyFilter,
  setQueryItensToAddPage,
  queryItensToAddPage = '',
  queryAddedItensPage = '',
  setQueryAddedItensPage,
  isCreation,
  locationGroupCreation = null,
  inputLabelToAdd = 'Pesquisar',
  inputLabelAdded = 'Pesquisar',
  mainTitle = 'Adicionar grupos',
  emptyItemsToAdd = 'Não foi possível carregar os locais dessa empresa',
  emptyAddedItems = 'Não foi possível carregar os locais dessa empresa',
  userCompanies = null,
  userCompaniesPageIndex,
  userCompaniesPageQuantity,
  setUserCompaniesPageIndex,
  setUserCompaniesPageQuery,
}) => {
  const [userCompaniesWithPlaceholder, setUserCompaniesWithPlaceholder] = React.useState([]);

  const handleSetQueryItensToAddPage = debounce(text => setQueryItensToAddPage(text), 500);

  const handleSetQueryAddedItens = debounce(text => setQueryAddedItensPage(text), 500);

  const itemsToAddSearchInputIsDisable = searchInputIsDisable({
    dataList: itemsToAdd,
    dataListQuery: queryItensToAddPage,
  });

  const showPaginationAddedItens = showPagination({
    dataList: addedItens,
    pagesQuantity: addedItemsPagesQuantity,
    isCreation,
  });

  const handleDropRepeats = (itemToAdd) => {
    const hasItemToAddInItensAdded = addedItens.find(addedItem => addedItem.id === itemToAdd.id);

    if (!R.isNil(hasItemToAddInItensAdded)) {
      return {
        ...itemToAdd,
        is_disable: true,
      };
    }

    return itemToAdd;
  };

  React.useEffect(() => {
    if (!R.isNil(userCompanies)) {
      const placeholderCompany = {
        name: 'Seleciona Opção',
        id: 0,
      };

      setUserCompaniesWithPlaceholder([
        placeholderCompany,
        ...userCompanies,
      ]);
    }
  }, [userCompanies]);

  return (
    <StyledGroupItensPartitionDiv className={className}>
      <StyledTitle>{mainTitle}</StyledTitle>

      <StyledSides>
        <StyledGroupItensDiv>
          <StyledHeaderFilterWrapper>
            {locationGroupCreation && (
              <StyledSelectDropdownWrapper>
                <h4 className="select-dropdown-company-label">Empresas</h4>
                <SelectDropdown
                  border
                  className="company-input"
                  onOptionClicked={(company) => setCompanyFilter(company.id)}
                  data={userCompaniesWithPlaceholder}
                  setQuery={setUserCompaniesPageQuery}
                  setPage={setUserCompaniesPageIndex}
                  page={userCompaniesPageIndex}
                  pageQuantity={userCompaniesPageQuantity}
                  searchLabel="Buscar Empresas"
                />
              </StyledSelectDropdownWrapper>
            )}
            <Input
              border
              inputOptions={{ type: 'text', name: 'items-to-add' }}
              className="input-margin"
              label={inputLabelToAdd}
              disabled={itemsToAddSearchInputIsDisable()}
              onChange={({ target }) => handleSetQueryItensToAddPage(target.value)}
            />
          </StyledHeaderFilterWrapper>
          {!R.isEmpty(itemsToAdd) ? (
            <StyledListDiv>
              {loadingItemsToAdd ? <Loading className="loading-data-group" /> : (
                <List
                  data={itemsToAdd.map(handleDropRepeats)}
                  primaryTextKey={primaryTextKey}
                  secondaryTextKey={secondaryTextKey}
                  icon="Add"
                  onClick={onItemToAddClicked}
                  hasDivider
                  buttonTooltip={buttonTooltipAddTitle}
                  buttonTooltipClassName="button-tooltip button-tooltip-add"
                  tooltipVariant="green"
                />
              )}
            </StyledListDiv>
          ) : <StyledIsEmptyData>{emptyItemsToAdd}</StyledIsEmptyData>}

          {(itemsToAddPagesQuantity !== 0 && !R.isEmpty(itemsToAdd)) && (
            <Pagination
              page={currentItensToAddPage}
              setPage={setItensToAddPage}
              pagesQuantity={itemsToAddPagesQuantity}
            />
          )}
        </StyledGroupItensDiv>

        <StyledGroupItensDiv>
          <Input
            border
            inputOptions={{ type: 'text', name: 'added-items' }}
            className="input-margin"
            label={inputLabelAdded}
            disabled={R.isEmpty(itemsToAdd)}
            onChange={({ target }) => handleSetQueryAddedItens(target.value)}
          />
          {!R.isEmpty(addedItens) ? (
            <StyledListDiv>
              {loadingAddedItems ? <Loading className="loading-data-group" /> : (
                <List
                  data={addedItens.length > 0 && addedItens.filter(element => element.name.toLowerCase().includes(queryAddedItensPage.toLowerCase()))}
                  primaryTextKey={primaryTextKey}
                  secondaryTextKey={secondaryTextKey}
                  icon="Remove"
                  onClick={onAddedItemClicked}
                  buttonTooltip={buttonTooltipRemoveTitle}
                  buttonTooltipClassName="button-tooltip button-tooltip-remove"
                  tooltipVariant="red"
                />
              )}
            </StyledListDiv>
          ) : <StyledIsEmptyData>{emptyAddedItems}</StyledIsEmptyData>}

          {showPaginationAddedItens() && (
            <Pagination
              page={currentAddedItensPage}
              setPage={setAddedItensPage}
              pagesQuantity={addedItemsPagesQuantity}
            />
          )}
        </StyledGroupItensDiv>
      </StyledSides>
    </StyledGroupItensPartitionDiv>
  );
};

export default GroupItems;
