import axios from 'axios';

export default async function getUser({
  accessToken,
  id,
  setTokenLikeExpired,
}) {
  try {
    const { data } = await axios.get(
      `${process.env.REACT_APP_AUTH_API_URL}/get-user/${id}`,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      },
    );

    return data;
  } catch (err) {
    if (err.response?.status === 401 && setTokenLikeExpired) setTokenLikeExpired();

    return null;
  }
}
