import styled from 'styled-components';
import { rgba, shade, lighten } from 'polished';
import { Avatar } from '@material-ui/core';

export const StyledUserCard = styled.li`
  display: flex;
  flex-direction: column;
  padding: 2rem;
  cursor: pointer;
  transition: 0.3s;
  border-radius: 0.5rem;
  background: ${props => props.theme.colors.white};
  box-shadow: 0.4rem 0.4rem 0.1rem  ${props => props.theme.colors.primary};
  border: 0.1rem solid ${props => rgba(props.theme.colors.black, 0.4)};
  opacity: ${props => (props.checked ? 1 : 0.6)};
  position: relative;

  &:hover,
  &:focus {
    box-shadow: 0.2rem 0.2rem 0.1rem  ${props => props.theme.colors.primary};
    opacity: 1;
  }
`;

export const StyledProfile = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 1rem;
  padding-bottom: 1rem;
  border-bottom: 0.1rem solid ${props => shade(0.1, props.theme.colors.white)};
`;

export const StyledAvatar = styled(Avatar)`
  margin-bottom: 0.5rem;
`;

export const StyledContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

export const StyledInfoRow = styled.div`
  display: flex;
  font-size: 1.4rem;
  color: ${props => props.theme.colors.black};
  font-weight: 400;
    
  &:last-of-type {
    padding-bottom: 0;
    margin-bottom: 0;
    border-bottom: none;
  }
    
  span {
    margin-right: 0.5rem;
    font-weight: 600;
    color: ${props => shade(0.2, props.theme.colors.primary)};
  }
`;
