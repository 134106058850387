import React from 'react';

import {
  Button,
} from '../../../../../../../components';

import { dayOptionsMocked } from './helpers';

import DayOption from './day-option';

import {
  StyledReplicatedPopover,
  StyledDayOptions,
} from './replicated-popover-styles';

const ReplicatedPopover = ({
  handleReplicatedApply,
  handleSetSelectedDays,
  replicatedDaysSelected,
  ...rest
}) => (
  <StyledReplicatedPopover
    {...rest}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'center',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'center',
    }}
  >
    <StyledDayOptions>
      {dayOptionsMocked && dayOptionsMocked.map(dayOption => (
        <DayOption
          dayOption={dayOption}
          key={Math.random(Math.floor())}
          handleSetSelectedDays={handleSetSelectedDays}
          replicatedDaysSelected={replicatedDaysSelected}
          allDays={dayOptionsMocked}
        />
      ))}
    </StyledDayOptions>
    <Button
      onClick={handleReplicatedApply}
      className="button-apply-replicated"
    >
      Aplicar
    </Button>
  </StyledReplicatedPopover>
);

export default ReplicatedPopover;
