export const normalizeWorkingHour = workingHoursDay => workingHoursDay.split('-').join('').trim();

const mountHours = (dataHours) => {
  let newHours = {};

  const monday = dataHours[0] ? normalizeWorkingHour(dataHours[0].monday) : null;
  const tuesday = dataHours[0] ? normalizeWorkingHour(dataHours[0].tuesday) : null;
  const wednesday = dataHours[0] ? normalizeWorkingHour(dataHours[0].wednesday) : null;
  const thursday = dataHours[0] ? normalizeWorkingHour(dataHours[0].thursday) : null;
  const friday = dataHours[0] ? normalizeWorkingHour(dataHours[0].friday) : null;
  const saturday = dataHours[0] ? normalizeWorkingHour(dataHours[0].saturday) : null;
  const sunday = dataHours[0] ? normalizeWorkingHour(dataHours[0].sunday) : null;

  if (monday) {
    const mon_1_open = dataHours[0].monday.split('-')[0].trim();
    const mon_1_close = dataHours[0].monday.split('-')[1].trim();

    newHours = { ...newHours, mon_1_open, mon_1_close };
  }

  if (tuesday) {
    const tue_1_open = dataHours[0].tuesday.split('-')[0].trim();
    const tue_1_close = dataHours[0].tuesday.split('-')[1].trim();

    newHours = { ...newHours, tue_1_open, tue_1_close };
  }

  if (wednesday) {
    const wed_1_open = dataHours[0].wednesday.split('-')[0].trim();
    const wed_1_close = dataHours[0].wednesday.split('-')[1].trim();

    newHours = { ...newHours, wed_1_open, wed_1_close };
  }

  if (thursday) {
    const thu_1_open = dataHours[0].thursday.split('-')[0].trim();
    const thu_1_close = dataHours[0].thursday.split('-')[1].trim();

    newHours = { ...newHours, thu_1_open, thu_1_close };
  }

  if (friday) {
    const fri_1_open = dataHours[0].friday.split('-')[0].trim();
    const fri_1_close = dataHours[0].friday.split('-')[1].trim();

    newHours = { ...newHours, fri_1_open, fri_1_close };
  }

  if (saturday) {
    const sat_1_open = dataHours[0].saturday.split('-')[0].trim();
    const sat_1_close = dataHours[0].saturday.split('-')[1].trim();

    newHours = { ...newHours, sat_1_open, sat_1_close };
  }

  if (sunday) {
    const sun_1_open = dataHours[0].sunday.split('-')[0].trim();
    const sun_1_close = dataHours[0].sunday.split('-')[1].trim();

    newHours = { ...newHours, sun_1_open, sun_1_close };
  }

  return newHours;
};

export default mountHours;
