import { MUIDataTableColumn } from 'mui-datatables';
import { isNil } from 'ramda';

import type { TLocationEntity } from '../../../types/TLocation';
import type { TTableLinkedLocation } from '../../../types/TSyncPermissions';

export const tableColumns: MUIDataTableColumn[] = [
  {
    label: 'Id',
    name: 'id',
    options: {
      display: false,
      sort: false,
    },
  },
  {
    label: 'Empresa',
    name: 'company',
    options: {
      sort: false,
    },
  },
  {
    label: 'Local',
    name: 'location',
    options: {
      sort: false,
    },
  },
  {
    label: 'Permitir Sincronização',
    name: 'sync_permission',
    options: {
      sort: false,
    },
  },
];

export const mountPermissionTableData = (
  tableData: TLocationEntity[] | null,
): TTableLinkedLocation[] | null => {
  if (isNil(tableData)) return null;

  const formattedData: TTableLinkedLocation[] = tableData.map(location => ({
    id: location.id,
    company: location.company.name,
    location: location.name,
    sync_permission: location.sync_permission,
  }));

  return formattedData;
};
