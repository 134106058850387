import axios from 'axios';

export default async function updateGoogleLocation(
  accessToken,
  userId,
  locationId,
  locationData,
) {
  const requestOptions = {
    headers: {
      'access-token': accessToken,
    },
  };

  try {
    const { data } = await axios.patch(
      `${process.env.REACT_APP_GOOGLE_API_URL}/${userId}/${locationId}`,
      locationData,
      requestOptions,
    );

    return data;
  } catch (err) {
    return err.response;
  }
}
