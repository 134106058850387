import {
  emailRegExp,
  phoneRegExp,
  passwordRegExp,
  zipCodeRegExp,
  websiteRegExp,
  numberRegExp,
} from './regexp';

export const emailValidate = fieldEmail => emailRegExp.test(String(fieldEmail).toLocaleLowerCase());

export const websiteValidate = field => websiteRegExp.test(String(field).toLocaleLowerCase());

export const phoneValidate = fieldPhone => phoneRegExp.test(String(fieldPhone).toLocaleLowerCase());

export const passwordValidate = fieldPassword => {
  if (
    fieldPassword.trim().length < 8
    || !passwordRegExp.test(String(fieldPassword))
  ) { return false; }

  return true;
};

export const passwordConfirmValidate = ({ password, passwordConfirm }) => {
  if (passwordConfirm !== password) return false;

  return true;
};

export const zipCodeIsValid = (zipcode, isOutsideBrazil) => {
  if (isOutsideBrazil) return true;

  return zipCodeRegExp.test(String(zipcode).toLocaleLowerCase());
};

export const isNumber = value => numberRegExp.test(value);
