import axios from 'axios';

export default async function signUpUser({
  form,
  created_by = null,
  setTokenLikeExpired,
  accessToken,
}) {
  const {
    name,
    email,
    google_refresh_token,
    phone,
    password,
    company_limit,
    company_group_limit,
    location_limit,
    location_group_limit,
  } = form;

  const requestOptions = {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  };

  try {
    const { data } = await axios.post(
      `${process.env.REACT_APP_AUTH_API_URL}/signup`,
      created_by
        ? {
          name,
          email,
          google_refresh_token,
          phone,
          password,
          company_limit: parseInt(company_limit),
          company_group_limit: parseInt(company_group_limit),
          location_limit: parseInt(location_limit),
          location_group_limit: parseInt(location_group_limit),
          created_by,
        } : {
          name,
          email,
          google_refresh_token,
          phone,
          password,
          company_limit: parseInt(company_limit),
          company_group_limit: parseInt(company_group_limit),
          location_limit: parseInt(location_limit),
          location_group_limit: parseInt(location_group_limit),
        },
      requestOptions,
    );
    return data;
  } catch (err) {
    if (err.response?.status === 401 && setTokenLikeExpired) setTokenLikeExpired();

    return null;
  }
}
