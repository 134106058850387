import React from 'react';

import { GoogleMap, Marker } from '@react-google-maps/api';

import Button from '../button';
import Loading from '../loading';

import {
  StyledEditMarker,
  StyledEditMarkerContent,
  StyledDisclaimer,
} from './edit-marker-styles';

export default ({
  isOpen,
  latitude,
  longitude,
  onMapClicked,
  onClose,
  onSave,
  isLoading,
  zoomLevel = 1,
}) => (
  <StyledEditMarker
    open={isOpen}
    onClose={onClose}
  >
    <StyledEditMarkerContent>
      <GoogleMap
        mapContainerStyle={{
          width: '100%',
          height: '35rem',
        }}
        onClick={onMapClicked}
        zoom={zoomLevel}
        center={{
          lat: latitude,
          lng: longitude,
        }}
      >
        <Marker
          draggable
          onDragEnd={onMapClicked}
          position={{
            lat: latitude,
            lng: longitude,
          }}
        />
      </GoogleMap>
      <StyledDisclaimer>
        Clique ou arraste o Pin para o local adequado.
      </StyledDisclaimer>
      <Button
        className="save-button"
        onClick={onSave}
        disabled={isLoading}
      >
        {isLoading ? <Loading /> : 'Salvar'}
      </Button>
    </StyledEditMarkerContent>
  </StyledEditMarker>
);
