import DateFnsUtils from '@date-io/date-fns';
import brLocation from 'date-fns/locale/pt-BR';
import * as R from 'ramda';
import React from 'react';

import {
  TInputDatePickerBaseProps,
} from '../../types/TInputDatePicker';

import {
  StyledInputDatePicker,
  StyledKeyboardDatePicker, StyledLabel, StyledMuiPickersUtilsProvider,
} from './input-date-picker-styles';

const InputDatePicker = ({
  selectedDate,
  setSelectedDate,
  label = null,
  className,
  disablePast,
  clearable,
  maxDate,
  minDate,
  disabled = false,
  isBackground = false,
}: TInputDatePickerBaseProps) => (
  <StyledInputDatePicker
    className={className}
    isBackground={isBackground}
  >
    {!R.isNil(label) && (<StyledLabel>{label}</StyledLabel>)}

    <StyledMuiPickersUtilsProvider utils={DateFnsUtils} locale={brLocation}>
      <StyledKeyboardDatePicker
        disabled={disabled}
        maxDate={maxDate}
        minDate={minDate}
        disablePast={disablePast}
        clearable={clearable}
        autoOk
        className={className}
        variant="inline"
        format="dd/MM/yyyy"
        margin="normal"
        id="date-picker-inline"
        label="Date picker inline"
        value={selectedDate}
        invalidLabel="Data inválida!"
        invalidDateMessage={null}
        onChange={(date) => setSelectedDate(date)}
        KeyboardButtonProps={{
          'aria-label': 'change date',
        }}
      />
    </StyledMuiPickersUtilsProvider>
  </StyledInputDatePicker>
);

export default InputDatePicker;
