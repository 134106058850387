import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { shade } from 'polished';

export const StyledTabMenu = styled.nav`
  display: flex;
  height: 100%;
  width: 100%;
`;

export const StyledTabMenuItem = styled(Link)`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 9.2rem;
  padding: 2rem;
  color: ${props => props.theme.colors.black};
  font-size: 1.2rem;
  background-color: ${props => (props.active ? shade(0.2, props.theme.colors.headerToolbarBg) : 'transparent')};
  min-width: 10rem;

  .aba-menu-item {
    margin-bottom: 0.5rem;
    width: 2.5rem;
    height: 2.5rem;
  }

  &:hover,
  &:focus {
    color: ${props => props.theme.colors.black};
    text-decoration: none;
    background-color: ${props => shade(0.2, props.theme.colors.headerToolbarBg)};
  }
`;
