import styled from 'styled-components';

export const StyledUserProfile = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 4rem;
`;

export const StyledContent = styled.div`
  display: flex;
  flex-direction: column;
  margin: 2rem;
`;

export const StyledHistory = styled.div`
  display: flex;
  flex-direction: column;
  margin: 2rem;
`;
