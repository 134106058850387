import React, { useCallback, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useDispatch, useSelector } from 'react-redux';

import {
  equals, is, isEmpty, isNil,
} from 'ramda';

import { useSnackbar } from 'notistack';
import type { TRootStateRedux } from '../../types/TRootStateRedux';

import { useAuth, useHasAccess } from '../../hooks';
import isNilOrEmpty from '../../utils/is-null-or-empty';
import APIUpdateLocation from '../../services/locations/updateLocation';

import type {
  TTicketPlatformStatus,
  TTicketsEntity,
} from '../../types/TTickets';

import {
  LocationInfoNavBar,
  LocationHeader,
  NoAccessCard,
  SwipeableViews,
  TabPanel,
  BackToTop,
  ConfirmDialog,
} from '../../components';

import SyncInfoDialog from '../../components/sync-info-dialog';
import { getAllRequestsByLocation } from '../../services/platform-sync-modules/get-all-requests-by-location';

import {
  StyledContent,
  StyledContentMain,
  StyledLocationProfile,
} from './location-profile-styles';

import Info from './infos';

import { Creators as LocationActions } from '../../redux/ducks/location';

import APIGetValidGoogleAccessTokenFromLocation from '../../services/getValidGoogleAccessTokenFromLocation';
import APIGetLocation from '../../services/locations/getLocation';
import APIGetAllContracts from '../../services/contract-types/getAllContractTypes';
import APIUpdateContract from '../../services/contract-types/updateContractType';
import APIGetTicketFromLocation from '../../services/tickets/getAllFromLocation';
import APIUpdateTicket from '../../services/tickets/update';
import APICreateTicket from '../../services/tickets/create';
import APIDeleteWorkingHoursTimeRange from '../../services/workingHours/delete-time-range';
import APIWorkingHoursCreateTimesRange from '../../services/workingHours/create-working-hours-times-range';
import APIUpdateWorkingHoursTimesRange from '../../services/workingHours/update-time-range';
import APIUpdateWorkingHoursDay from '../../services/workingHours/updateWorkingHours';
import APIDeleteSpecialDate from '../../services/specialDate/deleteSpecialDate';
import APICreateSpecialDate from '../../services/specialDate/createSpecialDate';
import APIUpdateSpecialDate from '../../services/specialDate/updateSpecialDate';
import APIGetAllLocationConnections from '../../services/location-connections/getAllLocationConnectionsFromLocation';

import {
  formatContractList,
  getDataToUpdate,
  getFieldsToChange,
  getFieldTabIndex,
  hasSomethingNew,
  setCurrentSyncStatusData,
  getGooglePlatformStatus,
  checkInputFields,
  getDeletedSpecialDates,
  noLongerHasGoogleCategories,
  validateFields,
} from './helpers';
import {
  TContractItem,
} from './types';
import Side from './side';
import Links from './links';
import WorkingHours from './working-hours';
import { TTimeData } from '../../types/TTime';
import LeaveDialog from './leave-dialog';
import LinkInfoDialog from '../../components/link-info-dialog';
import { LinkInfoDialogType } from '../../components/link-info-dialog/types';
import { LocationConnectionStatusEnum } from '../../types/TPlatformsLinkWithLocation';
import checkField from '../../hooks/use-uncontrolled-field/check-field';
import { checkMessage } from '../../hooks/use-uncontrolled-field/helpers';

const LocationProfile = ({ history }) => {
  const { hasAccess } = useHasAccess();
  const { enqueueSnackbar } = useSnackbar();
  const reduxDispatch = useDispatch();

  const { activeLocation } = useSelector(
    (state: TRootStateRedux) => state.LocationReducer,
  );
  const {
    userAccessToken,
    userId,
    userSetTokenLikeExpired,
    userProfileName,
  } = useAuth();

  const [updatedLocation, setUpdatedLocation] = useState(activeLocation);
  const [activeTab, setActiveTab] = useState(0);
  const [isSync, setIsSync] = useState(false);
  const [syncStatusData, setSyncStatusData] = useState<TTicketPlatformStatus | null>(null);
  const [contractList, setContractList] = useState<Array<TContractItem>>([]);
  const [isContractListLoading, setIsContractListLoading] = useState(false);
  const [selectedContract, setSelectedContract] = useState(1);
  const [isSyncDialogOpen, setIsSyncDialogOpen] = useState(false);
  const [fieldOnFocus, setFieldOnFocus] = useState('');
  const [leaveDialogOpen, setLeaveDialogOpen] = useState(false);
  const [redirectURL, setRedirectURL] = useState('');
  const [linkedDialogOpen, setLinkedDialogOpen] = useState(false);

  const [isSyncInfoDialogOpen, setIsSyncInfoDialogOpen] = React.useState(false);
  const [latestLocationRequests, setLatestLocationRequests] = React.useState<any[]>([]);
  const [syncDialogId, setSyncDialogId] = React.useState('');
  const requestStatusRef = React.useRef<string>('');

  const [googleAccessToken, setGoogleAccessToken] = React.useState('');
  const [googleLocationId, setGoogleLocationId] = React.useState<string | null>(null);

  const getGoogleAccessToken = React.useCallback(async () => {
    if (!hasAccess) return;

    const googleAccessTokenResponse = await APIGetValidGoogleAccessTokenFromLocation({
      accessToken: userAccessToken,
      userId,
      locationId: activeLocation.id,
    });

    if (isNil(googleAccessTokenResponse)) return;

    const { googleAccessToken: accessTokenGoogle, connectionInfo } = googleAccessTokenResponse;

    setGoogleLocationId(connectionInfo.google_location_id);
    setGoogleAccessToken(accessTokenGoogle);
  }, [hasAccess, activeLocation]);

  React.useEffect(() => { getGoogleAccessToken(); }, [getGoogleAccessToken]);

  const getContractTypes = useCallback(async () => {
    const getContractTypesResponse = await APIGetAllContracts({
      accessToken: userAccessToken,
    });

    if (isNil(getContractTypesResponse)) return;

    const formattedContractList = formatContractList(getContractTypesResponse);

    const emptyContract = {
      name: 'Nenhum',
      value: 1,
    };

    setContractList([emptyContract, ...formattedContractList]);
  }, []);

  const getTicketThisLocation = React.useCallback(async (): Promise<
    TTicketsEntity[]
  > => {
    if (isNil(activeLocation)) return [];

    const { id: activeLocationId } = activeLocation;

    const ticketsThisLocation = await APIGetTicketFromLocation({
      accessToken: userAccessToken,
      locationId: activeLocationId,
      setTokenLikeExpired: userSetTokenLikeExpired,
    });

    if (isNil(ticketsThisLocation)) return [];

    if (isEmpty(ticketsThisLocation[0]) || isNil(ticketsThisLocation[0])) { return []; }

    const openedTickets = ticketsThisLocation[0].filter(
      (ticket: TTicketsEntity) => ticket.status === 'OPEN',
    );

    if (isEmpty(openedTickets)) {
      setCurrentSyncStatusData({
        ticketData: ticketsThisLocation[0],
        setSyncStatusData,
      });
    }

    setCurrentSyncStatusData({
      ticketData: openedTickets,
      setSyncStatusData,
    });

    return openedTickets;
  }, [activeLocation]);

  const handleChangeContract = React.useCallback(
    async (contractId: number) => {
      setSelectedContract(contractId);

      await APIUpdateContract({
        accessToken: userAccessToken,
        contractId,
        locationId: activeLocation.id,
        setIsFetching: setIsContractListLoading,
        feedbackMessage: enqueueSnackbar,
      });
    },
    [userAccessToken, activeLocation],
  );

  const handleOpenSync = () => {
    if (noLongerHasGoogleCategories(activeLocation.google_categories, updatedLocation.google_categories)) {
      return enqueueSnackbar('Categoria principal do local não pode estar vazia', {
        variant: 'error',
      });
    }
    if (!hasSomethingNew(updatedLocation, activeLocation)) {
      return enqueueSnackbar('Não há informações a serem sincronizadas', {
        variant: 'warning',
      });
    }
    return setIsSyncDialogOpen(true);
  };

  const updateWorkingHours = useCallback(async () => {
    if (equals(
      updatedLocation.working_hours,
      activeLocation.working_hours,
    )) return [];

    const workingHours = updatedLocation.working_hours;

    if (!workingHours) return [];

    const updateWorkingHoursResponse = await Promise.all(
      workingHours.map(async (workingHoursDay) => {
        const {
          times,
          is_open: isOpen = false,
          is_replicated: isReplicated = false,
          is_24_hours: is24Hours = false,
          dropdown_to_bottom: dropdownToBottom = false,
          id: workingHoursId,
        } = workingHoursDay;

        const newTimes = times
          ? await Promise.all(
            times.map(async (time: TTimeData) => {
              if (!is(String, time.id) && time.is_deleted) {
                const deleteTimeRangeResponse = await APIDeleteWorkingHoursTimeRange({
                  accessToken: userAccessToken,
                  setTokenLikeExpired: userSetTokenLikeExpired,
                  timeRangeId: time.id,
                });

                if (isNil(deleteTimeRangeResponse)) return time;

                return null;
              }

              if (is(String, time.id) && !time.is_deleted) {
                const newTime = await APIWorkingHoursCreateTimesRange({
                  accessToken: userAccessToken,
                  setTokenLikeExpired: userSetTokenLikeExpired,
                  open: time.open,
                  close: time.close,
                });

                return newTime;
              }

              if (!is(String, time.id)) {
                const updateTime = await APIUpdateWorkingHoursTimesRange({
                  accessToken: userAccessToken,
                  setTokenLikeExpired: userSetTokenLikeExpired,
                  idTimeRange: time.id,
                  open: time.open,
                  close: time.close,
                });

                return updateTime;
              }

              return null;
            }),
          )
          : times;

        const newTimesWithoutNull = newTimes
          ? newTimes.filter((time) => !isNil(time))
          : [];

        if (!workingHoursId) return workingHoursDay;

        const updateWorkingHoursDay = await APIUpdateWorkingHoursDay({
          accessToken: userAccessToken,
          setTokenLikeExpired: userSetTokenLikeExpired,
          is24Hours,
          isReplicated,
          times: newTimesWithoutNull,
          isOpen,
          workingHoursId,
          dropdownToBottom,
        });

        return {
          ...updateWorkingHoursDay,
          times: newTimes,
        };
      }),
    );

    if (isNil(updateWorkingHoursResponse)) return [];

    return updateWorkingHoursResponse;
  }, [updatedLocation]);

  const updateSpecialDates = useCallback(async () => {
    if (equals(
      updatedLocation.special_dates,
      activeLocation.special_dates,
    )) return [];

    const specialDates = updatedLocation.special_dates;

    const specialDatesToBeDeleted = getDeletedSpecialDates(activeLocation, updatedLocation);

    if (specialDatesToBeDeleted) {
      specialDatesToBeDeleted.forEach(async (specialDate) => {
        await APIDeleteSpecialDate({
          accessToken: userAccessToken,
          setTokenLikeExpired: userSetTokenLikeExpired,
          id: specialDate.id,
          isLoading: null,
        });
      });
    }

    const updateSpecialDatesResponse = await Promise.all(
      specialDates.map(async (specialDate) => {
        const {
          times = [],
          is_open = false,
          date,
          is_24_hours = false,
          name,
          id = null,
        } = specialDate;

        if (!id) {
          const newTimes = isEmpty(times) ? [] : await Promise.all(times.map(async newTime => {
            const newTimeResponse = await APIWorkingHoursCreateTimesRange({
              accessToken: userAccessToken,
              setTokenLikeExpired: userSetTokenLikeExpired,
              open: newTime.open,
              close: newTime.close,
            });

            return newTimeResponse;
          }));

          const createdSpecialDateResponse = await APICreateSpecialDate({
            accessToken: userAccessToken,
            setTokenLikeExpired: userSetTokenLikeExpired,
            date,
            name,
            times: newTimes,
            created_by: userId,
            is_open,
            is_24_hours,
            isLoading: null,
            location: [activeLocation],
          });

          return {
            ...createdSpecialDateResponse,
            times: newTimes,
          };
        }

        const newTimes = times
          ? await Promise.all(
            times.map(async (time: TTimeData) => {
              if (time.is_mocked && !time.is_deleted) {
                const newTime = await APIWorkingHoursCreateTimesRange({
                  accessToken: userAccessToken,
                  setTokenLikeExpired: userSetTokenLikeExpired,
                  open: time.open,
                  close: time.close,
                });

                return newTime;
              }

              if (!time.is_mocked && !time.is_deleted) {
                const updateTime = await APIUpdateWorkingHoursTimesRange({
                  accessToken: userAccessToken,
                  setTokenLikeExpired: userSetTokenLikeExpired,
                  idTimeRange: time.id,
                  open: time.open,
                  close: time.close,
                });

                return updateTime;
              }

              return null;
            }),
          )
          : times;

        const updateSpecialDate = await APIUpdateSpecialDate({
          accessToken: userAccessToken,
          setTokenLikeExpired: userSetTokenLikeExpired,
          special_date_id: specialDate.id,
          name,
          date,
          is_open,
          is_24_hours,
          times: newTimes,
        });

        return {
          ...updateSpecialDate,
          times: newTimes,
        };
      }),
    );
    if (isNil(updateSpecialDatesResponse)) return [];

    return updateSpecialDatesResponse;
  }, [updatedLocation]);

  const handleUpdate = useCallback(async () => {
    if (!hasSomethingNew(updatedLocation, activeLocation)) return false;

    if (isNil(googleAccessToken)) return false;

    const locationData: any = { ...updatedLocation };

    const validations = validateFields({
      isOutsideBrazil: updatedLocation.country !== 'Brazil',
      message: checkMessage(enqueueSnackbar),
      updatedLocation,
    });

    const validationResults = validations.map((validation: any) => checkField(validation));

    const hasInvalidValue = validationResults.some(result => !result);

    if (hasInvalidValue) return false;

    const { google_service_area } = locationData;

    const googleServiceArea = !isNil(google_service_area)
      ? {
        ...google_service_area,
        businessType: !updatedLocation.does_not_serve_at_this_address
          ? 'CUSTOMER_AND_BUSINESS_LOCATION'
          : 'CUSTOMER_LOCATION_ONLY',
      }
      : null;

    const inputData = getDataToUpdate(updatedLocation, googleServiceArea);

    const fieldsAreValid = checkInputFields(inputData, enqueueSnackbar);

    if (!fieldsAreValid) return false;

    const updateResponse = await APIUpdateLocation({
      accessToken: userAccessToken,
      locationId: updatedLocation.id,
      setTokenLikeExpired: userSetTokenLikeExpired,
      setIsFetching: setIsSync,
      inputData,
      feedbackMessage: enqueueSnackbar,
      googleAccessToken,
    });

    if (isNil(updateResponse)) return false;

    requestStatusRef.current = updateResponse.requestStatus;

    if (requestStatusRef.current === 'ERROR') {
      reduxDispatch(LocationActions.setActiveLocation(updateResponse));
      return false;
    }
    const updatedSpecialDates = await updateSpecialDates();
    const specialDatesToUpdate = isEmpty(updatedSpecialDates) ? updateResponse.special_dates : updatedSpecialDates;

    const updatedWorkingHours = await updateWorkingHours();
    const workingHoursToupdate = isEmpty(updatedWorkingHours) ? updateResponse.working_hours : updatedWorkingHours;
    reduxDispatch(LocationActions.setActiveLocation({ ...updateResponse, special_dates: specialDatesToUpdate, working_hours: workingHoursToupdate }));

    return true;
  }, [updatedLocation]);

  const handleSync = useCallback(async () => {
    setIsSyncDialogOpen(false);

    if (!hasSomethingNew(updatedLocation, activeLocation)) return;

    const updatedSuccess: boolean = await handleUpdate();

    const requestStatus = requestStatusRef.current;

    const fieldsToChange = getFieldsToChange(updatedLocation, activeLocation, requestStatus);

    const platformsSyncInitialStatus: TTicketPlatformStatus = {
      google: getGooglePlatformStatus(userProfileName, activeLocation),
      facebook: 'IN_PROGRESS',
      foursquare: 'IN_PROGRESS',
      yelp: 'IN_PROGRESS',
      linkedin: 'IN_PROGRESS',
    };

    if (!updatedSuccess) {
      setIsSync(false);
      return;
    }

    const hasOpenedTicketResponse = await getTicketThisLocation();

    const hasOpenActivationTicket = hasOpenedTicketResponse.find(
      (ticket) => ticket.type === 'ACTIVATION',
    );

    if (
      !isEmpty(hasOpenActivationTicket)
      && !isNil(hasOpenActivationTicket)
    ) {
      const openedTicket = hasOpenActivationTicket;

      const { id: ticketId, data: oldTickedData } = openedTicket || {};

      const oldTickedDataNormalized = !isNil(oldTickedData)
        ? JSON.parse(oldTickedData)
        : {};

      const { info_to_update: infoToUpdate } = oldTickedDataNormalized;

      const newDataTicket = {
        ...oldTickedDataNormalized,
        info_to_update: {
          ...infoToUpdate,
          ...fieldsToChange,
        },
        status: platformsSyncInitialStatus,
      };

      await APIUpdateTicket({
        accessToken: userAccessToken,
        ticketId,
        dataToUpdate: {
          data: JSON.stringify(newDataTicket),
        },
        setIsFetching: null,
        setTokenLikeExpired: userSetTokenLikeExpired,
        feedbackMessage: enqueueSnackbar,
      });

      setSyncStatusData(platformsSyncInitialStatus);
    } else {
      const createdTicketResponse = await APICreateTicket({
        accessToken: userAccessToken,
        changedData: {
          info_to_update: fieldsToChange,
          status: platformsSyncInitialStatus,
        },
        created_by: userId,
        location_id: activeLocation.id,
        isSync: setIsSync,
        type: 'ACTIVATION',
        setTokenLikeExpired: userSetTokenLikeExpired,
        feedbackMessage: enqueueSnackbar,
      });

      if (!createdTicketResponse) return;

      setSyncStatusData(platformsSyncInitialStatus);
    }
  }, [selectedContract, updatedLocation]);

  const handleConfirmDialogClose = () => setIsSyncDialogOpen(false);

  const handleChangeInputFocus = useCallback((name: string) => {
    const fieldTabIndex = getFieldTabIndex(name);
    if (fieldTabIndex >= 0) {
      setActiveTab(fieldTabIndex);
      if (fieldTabIndex > 0) {
        window.scrollTo({
          top: 0,
          left: 0,
          behavior: 'smooth',
        });
      }
    }
    setFieldOnFocus(name);
  }, []);

  const handleRedirect = useCallback(() => {
    history.block(null);
    history.push(redirectURL);
  }, [redirectURL]);

  const handleConfirmLeaving = useCallback(() => {
    handleUpdate()
      .finally(() => {
        handleRedirect();
      });
  }, [handleUpdate, handleRedirect]);

  useEffect(() => {
    getTicketThisLocation();
  }, [getTicketThisLocation]);

  useEffect(() => {
    APIGetLocation({
      id: activeLocation.id,
      accessToken: userAccessToken,
      setTokenLikeExpired: userSetTokenLikeExpired,
    }).then(location => {
      const { contract_type: contractType } = location || { contract_type: null };

      setUpdatedLocation(location || activeLocation);

      setSelectedContract(contractType?.id || 1);

      if (!equals(location, activeLocation)) {
        reduxDispatch(LocationActions.setActiveLocation(location));
      }
    });
  }, [activeLocation]);

  useEffect(() => {
    getContractTypes();
  }, [getContractTypes]);

  useEffect(() => {
    if (hasSomethingNew(updatedLocation, activeLocation)) {
      history.block((tx) => {
        const { location } = history;
        if (tx.pathname === location.pathname) return true;
        setLeaveDialogOpen(true);
        setRedirectURL(tx.pathname);
        return false;
      });
    } else history.block(null);
  }, [updatedLocation, activeLocation]);

  const getlatestLocationRequests = React.useCallback(async () => {
    const latestLocationRequestsResponse = await getAllRequestsByLocation({
      locationId: activeLocation.id,
      userAccessToken,
    });

    if (isNil(latestLocationRequestsResponse)) return;

    const [latestLocationRequestsData] = latestLocationRequestsResponse;

    setLatestLocationRequests(latestLocationRequestsData);
  }, [activeLocation]);

  React.useEffect(() => { getlatestLocationRequests(); }, [getlatestLocationRequests]);

  const getAllValidLocationConnections = React.useCallback(async () => {
    const locationConnectionResponse = await APIGetAllLocationConnections({
      accessToken: userAccessToken,
      locationId: activeLocation.id,
      status: LocationConnectionStatusEnum.VALID,
    });
    if (!isNilOrEmpty(locationConnectionResponse)) return;
    setLinkedDialogOpen(true);
  }, [activeLocation]);

  React.useEffect(() => {
    getAllValidLocationConnections();
  }, [getAllValidLocationConnections]);

  const handleOpenDialog = (id: string) => {
    setIsSyncInfoDialogOpen(true);
    setSyncDialogId(id);
  };

  return (
    <StyledLocationProfile hasNoContent={isNil(activeLocation)}>
      <Helmet>
        <meta property="og:title" content="Local - Perfil - SaaS Hublocal" />
        <meta
          property="og:description"
          content="Veja as informações básicas de seus locais."
        />

        <title>Local - Perfil - SaaS Hublocal</title>
      </Helmet>

      <LocationHeader
        hideDashboard={!activeLocation?.data_studio}
        linkedLocation={!isNilOrEmpty(googleLocationId)}
      >
        {!isNil(activeLocation) && hasAccess && (
          <LocationInfoNavBar
            disabled={false}
            activeTab={activeTab}
            setActiveTab={setActiveTab}
          />
        )}
      </LocationHeader>

      {!hasAccess && <NoAccessCard />}

      <StyledContent hasNoContent={isNil(updatedLocation)}>
        {isNil(updatedLocation)
          ? (
            <span className="group-empty-or-company">
              Selecione uma empresa ou grupo que tenha locais!
            </span>
          ) : (
            <>
              <StyledContentMain>
                <SwipeableViews
                  handleChangeIndex={(index: number) => {
                    setActiveTab(index);
                  }}
                  activeTab={activeTab}
                >
                  <TabPanel
                    activeTab={activeTab}
                    activeTabIndex={0}
                    dir="ltr"
                    className="tabpanel"
                  >
                    <Info
                      setUpdateLocation={setUpdatedLocation}
                      updateLocation={updatedLocation}
                      fieldOnFocus={fieldOnFocus}
                      setFieldOnFocus={handleChangeInputFocus}
                    />
                  </TabPanel>
                  <TabPanel
                    activeTab={activeTab}
                    activeTabIndex={1}
                    dir="ltr"
                    className="tabpanel"
                  >
                    <Links
                      setUpdatedLocation={setUpdatedLocation}
                      initialValue={updatedLocation}
                      fieldOnFocus={fieldOnFocus}
                      setFieldOnFocus={handleChangeInputFocus}
                    />
                  </TabPanel>
                  <TabPanel
                    activeTab={activeTab}
                    activeTabIndex={2}
                    dir="ltr"
                    className="tabpanel"
                  >
                    <WorkingHours
                      initialData={updatedLocation}
                      setUpdatedLocation={setUpdatedLocation}
                    />
                  </TabPanel>
                </SwipeableViews>
              </StyledContentMain>
              <Side
                isSync={isSync}
                syncStatusData={syncStatusData}
                activeLocation={updatedLocation}
                handleSync={handleOpenSync}
                fieldsToBeFilled={
                  (field) => { handleChangeInputFocus(field.identifier); }
                }
                contractList={contractList}
                selectedContract={selectedContract}
                setSelectedContract={handleChangeContract}
                contractListLoading={isContractListLoading}
                onLastSyncDialogOpen={handleOpenDialog}
                latestLocationRequests={latestLocationRequests}
              />
            </>
          )}
      </StyledContent>
      <BackToTop />
      <ConfirmDialog
        open={isSyncDialogOpen}
        onClose={handleConfirmDialogClose}
        onCancel={handleConfirmDialogClose}
        title="Sincronização"
        onConfirm={() => handleSync()}
        confirmTitle="Confirmar"
        confirmType="blue"
      >
        Tem certeza que deseja sincronizar?
      </ConfirmDialog>

      <LeaveDialog
        open={leaveDialogOpen}
        onClose={() => { setLeaveDialogOpen(false); }}
        onCancel={() => {
          setLeaveDialogOpen(false);
          handleRedirect();
        }}
        title="Confirmação"
        onConfirm={() => handleConfirmLeaving()}
      >
        Você tem alterações para serem sincronizadas. Tem certeza que deseja sair?
      </LeaveDialog>
      {isSyncInfoDialogOpen && (
        <SyncInfoDialog
          isDialogOpen={isSyncInfoDialogOpen}
          syncDialogId={syncDialogId}
          onClose={() => setIsSyncInfoDialogOpen(false)}
        />
      )}
      {linkedDialogOpen && (
        <LinkInfoDialog
          onClose={() => setLinkedDialogOpen(false)}
          isDialogOpen={linkedDialogOpen}
          onConfirm={() => {
            history.push('/platforms-link-with-location/');
            setLinkedDialogOpen(false);
          }}
          type={LinkInfoDialogType.notLinked}
        />
      )}
    </StyledLocationProfile>
  );
};

export default LocationProfile;
