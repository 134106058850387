import styled from 'styled-components';
import { Dialog } from '@material-ui/core';

export const StyledFoursquare = styled.div`
    .primary-text{
        font-size: 1.4rem;
    }
    .secondary-text{
        font-size:1.2rem;
    }

    .venue-info{
        display: flex;
        flex-direction: column;
    }
`;

export const StyledDialog = styled(Dialog)`
    .primary-text{
        font-size: 2rem;
    }

    .secondary-text{
        font-size: 1.5rem;
    }
`;

export const StyledButtonGroup = styled.div`
    display: flex;
  flex-direction: column;

  .button {
    margin-bottom: 1.5rem;

    &:last-of-type {
      margin-bottom: 0;
    }
  }
`;
