import React, { useEffect } from 'react';

import WorkingHoursContext from '../working-hours-context';

import { OverlayLoading } from '../../../../../components';

import TimeOfDay from './time-of-day';

import {
  changeIsOpenDay,
  changeIs24Hours,
  changeWillUpdate,
} from './helpers';

import {
  StyledSectionTitle,
} from '../working-hours-styles';

import {
  StyledOpeningHours,
  StyledHours,
} from './opening-hours-styles';

const OpeningHours = ({
  isCreateOpeningHours,
  isCreateOpeningHoursStatus,
}) => {
  const { workingHoursPerDay } = React.useContext(WorkingHoursContext);

  return (
    <StyledOpeningHours>
      {isCreateOpeningHours && <OverlayLoading textToLoading={isCreateOpeningHoursStatus} /> }

      <StyledSectionTitle>Horários</StyledSectionTitle>

      <StyledHours>
        {workingHoursPerDay.map((day) => (
          <TimeOfDay
            key={day.day_title}
            dayTitle={day.day_title}
            isOpen={day.is_open}
            times={day.times}
            is24hours={day.is_24_hours}
            dropDownToBottom={day.dropdown_to_bottom}
            isReplicated={day.is_replicated}
            willUpdate={day.will_update}
            changeIsOpenDay={changeIsOpenDay}
            changeIs24Hours={changeIs24Hours}
            changeWillUpdate={changeWillUpdate}
          />
        ))}
      </StyledHours>
    </StyledOpeningHours>
  );
};

export default OpeningHours;
