import React from 'react';

import ConfigCard from '../../../components/config-card';

import {
  StyledFunctions,
} from './functions-styles';

const HandlerList = ({ navigation }) => (
  <ConfigCard
    title="Funções"
    onClick={() => navigation.push('/functions/list')}
  >
    <StyledFunctions>
      Funções
    </StyledFunctions>
  </ConfigCard>
);

export default HandlerList;
