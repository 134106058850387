import React from 'react';
import * as R from 'ramda';

import type {
  TBatchUpdateFilter,
  TBatchUpdateStatusFilter,
  TBatchUpdateTypeFilter,
} from '../../types/TBatchUpdate';

import { updateStatusOptions, updateMediaCategoryOptions, updateStatusOptionsWithAllOption } from './helpers';

import {
  Input, InputDatePicker, Select, SelectMultiple,
} from '..';
import {
  StyledFiltersContainer,
  StyledDatesWrapper,
} from './batch-filter-styles';

const BatchFilter = ({
  filterType,
  selectedCategory,
  setSelectedCategory,
  selectedStatusFilter,
  setSelectedStatusFilter,
  selectedMultipleStatusFilter,
  setSelectedMultipleStatusFilter,
  ofPeriod,
  setOfPeriod,
  toPeriod,
  setToPeriod,
  selectedTypeFilter,
  setSelectedTypeFilter,
  typeLabel = '',
  onChangeSearchQuery,
  searchQueryLabel = 'Busca',
}: TBatchUpdateFilter) => {
  const onChangeSelectedCategory = React.useCallback(
    (event: React.ChangeEvent<HTMLSelectElement>) => {
      if (!setSelectedCategory) return;
      setSelectedCategory(
        event.target.value === 'Todos' ? '' : event.target.value,
      );
    },
    [],
  );

  const onChangeSelectedStatus = React.useCallback(
    (event: React.ChangeEvent<HTMLSelectElement>) => {
      if (!R.isNil(setSelectedStatusFilter)) {
        setSelectedStatusFilter(
          event.target.value === 'Todos' ? '' : event.target.value,
        );
      }
    },
    [],
  );

  return (
    <StyledFiltersContainer>

      {onChangeSearchQuery && (
        <Input
          className="filter-content"
          label={searchQueryLabel}
          onChange={(e) => onChangeSearchQuery(e.target.value)}
          inputOptions={{
            type: 'text',
            name: 'batch-filter-search-query',
          }}
          border
        />
      )}

      {!R.isNil(selectedTypeFilter) && setSelectedTypeFilter && (
        <SelectMultiple<TBatchUpdateTypeFilter>
          name="type-update-filter"
          value={selectedTypeFilter}
          label={typeLabel}
          options={filterType?.updateTypeOptions ?? []}
          onChange={(target) => setSelectedTypeFilter(target)}
          border
          className="filter-content"
        />
      )}

      {!R.isNil(selectedCategory) && setSelectedCategory && (
        <Select<TBatchUpdateTypeFilter>
          name="type-update-filter"
          value={selectedCategory}
          label="Categoria"
          options={updateMediaCategoryOptions}
          onChange={onChangeSelectedCategory}
          border
          className="filter-content"
        />
      )}

      {!R.isNil(selectedStatusFilter) && !R.isNil(setSelectedStatusFilter) && (
        <Select<TBatchUpdateStatusFilter>
          name="type-update-filter"
          value={selectedStatusFilter}
          label="Status da solicitação"
          options={updateStatusOptionsWithAllOption}
          onChange={onChangeSelectedStatus}
          border
          className="filter-content"
        />
      )}

      {!R.isNil(selectedMultipleStatusFilter)
        && !R.isNil(setSelectedMultipleStatusFilter) && (
          <SelectMultiple<TBatchUpdateStatusFilter>
            name="type-update-filter"
            value={selectedMultipleStatusFilter}
            label="Status da solicitação"
            options={updateStatusOptions}
            onChange={(target) => setSelectedMultipleStatusFilter(target)}
            border
            className="filter-content"
          />
      )}

      <StyledDatesWrapper>
        <InputDatePicker
          selectedDate={ofPeriod}
          setSelectedDate={setOfPeriod}
          label="De:"
          maxDate={toPeriod || null}
          className="input-date-picker"
        />

        <InputDatePicker
          selectedDate={toPeriod}
          setSelectedDate={setToPeriod}
          minDate={ofPeriod || null}
          label="Até:"
          className="input-date-picker"
        />
      </StyledDatesWrapper>
    </StyledFiltersContainer>
  );
};

export default BatchFilter;
