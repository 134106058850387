import axios from 'axios';

export default async function create({
  accessToken,
  inputData,
  setTokenLikeExpired,
  setIsFetching,
}) {
  if (setIsFetching) setIsFetching(true);

  try {
    const { data } = await axios.post(
      `${process.env.REACT_APP_AUTO_RESPONSES_API_URL}`,
      inputData,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      },
    );

    return data;
  } catch (err) {
    if (err.response?.status === 401 && setTokenLikeExpired) setTokenLikeExpired();
    return err;
  } finally {
    if (setIsFetching) setIsFetching(false);
  }
}
