import React from 'react';

type TStatusOptions = {
  id: number;
  name: string;
  value: string | null;
}

export const StatusOptions: TStatusOptions[] = [
  { id: 0, name: 'Selecione uma opção', value: 'Todos' },
  { id: 1, name: 'Aprovado', value: 'true' },
  { id: 2, name: 'Rejeitado', value: 'false' },
];

export interface TSyncPermissionsFilterProps {
  searchCompanyLabel: string;
  onChangeCompanyQuery: React.Dispatch<string>;
  searchLocationLabel: string;
  onChangeLocationQuery: React.Dispatch<string>;
  selectedTypeFilter: string;
  setSelectedTypeFilter: React.Dispatch<string>;
  statusLabel: string;
}
