import styled from 'styled-components';
import { shade } from 'polished';

import { TWorkingHoursConfigBaseProps } from '../../../../../types/TWorkingHours';

export const StyledWorkingHoursConfig = styled.div<Pick<TWorkingHoursConfigBaseProps, 'className'>>`
  display: flex;
  align-items: center;
  padding: 2rem;
  border-radius: 0.5rem;
  background: ${props => shade(0.03, props.theme.colors.white)};
`;

export const StyledTimes = styled.ul`
  display: flex;
  flex-direction: column;
  margin-right: 2rem;
  margin-bottom: 0;
`;

export const StyledTimeItem = styled.li`
  display: flex;
  align-items: center;
  margin-bottom: 0.5rem;
  
  &:last-child {
    margin-bottom: 0;
  }

  .time-input {
    margin-right: 2rem;
    
    &:last-child {
      margin-right: 0;
    }
  }
`;

export const StyledAddNewTime = styled.span`
  cursor: pointer;
  font-size: 1.2rem;
  text-decoration: underline;
  display: flex;
  color: ${props => props.theme.colors.primary};
`;
