import React from 'react';

import type { TTableBodyRow } from '../../types/TTable';
import IconButton from '../icon-button';

import {
  StyledTableBodyRow,
  StyledTableBodyCell,
  StyledCheckbox,
} from './table-styles';

export const TableBodyRow = ({
  children,
  setChecked,
  checked = false,
  isExpandable = false,
  hiddenCheckbox = false,
  subTable,
  setIsExpandable,
  dataRow = [],
  subTableColSpan = 1,
}: TTableBodyRow) => {
  const handleSetChecked = () => {
    if (!setChecked) return;
    setChecked(!checked);
  };

  return (
    <>
      <StyledTableBodyRow selected={checked || isExpandable}>
        {!hiddenCheckbox && (
          <StyledTableBodyCell className="table-body-cell-checked">
            <StyledCheckbox checked={checked} onClick={handleSetChecked} />
          </StyledTableBodyCell>
        )}
        {subTable && (
          <StyledTableBodyCell>
            <IconButton
              tooltip="Visualizar locais"
              icon={isExpandable ? 'KeyboardArrowDown' : 'KeyboardArrowRight'}
              onClick={() => setIsExpandable && setIsExpandable(!isExpandable)}
            />
          </StyledTableBodyCell>
        )}
        {children}
      </StyledTableBodyRow>
      {isExpandable && subTable && (
        <tr>
          <td colSpan={subTableColSpan}>{subTable(dataRow)}</td>
        </tr>
      )}
    </>
  );
};
