export enum LinkInfoDialogType {
  notLinked = 'notLinked',
  defaultLinked = 'defaultLinked',
  companyWithUnlinked = 'companyWithUnlinked',
 }

export interface RequestDialogProps {
  isDialogOpen: boolean;
  onClose(): void;
  onConfirm(): void;
  type: LinkInfoDialogType;
  unlinkedLocations?: any[];
}

export interface TLinkInfoPopoverProps {
  onClose(): void;
  onConfirm(): void;
  type: LinkInfoDialogType;
  unlinkedLocationsInfo?: any[];
}
