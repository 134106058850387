import styled from 'styled-components';
import { Paper } from '@material-ui/core';

export const StyledSpecialDates = styled.div``;

export const StyledContent = styled(Paper)``;

export const StyledContentTitle = styled.h4`
    font-size: 1.4rem;
`;

export const StyledSectionTitle = styled.h3`
  font-weight: 700;
  font-size: 1.5rem;
  margin-bottom: 2rem;
  display: flex;
  align-items: center;

  .is-loading-infos {
    margin-right: 1rem;
  }
`;
