import { shade } from 'polished';
import styled from 'styled-components';

export const StyledRequestsPopover = styled.div`
`;

export const StyledDoubleCheckMessage = styled.div`
  background-color: ${props => props.theme.colors.primary};
  width: 100%;
  padding: 1rem 2rem;
`;

export const StyledTitle = styled.h2`
  color: ${props => props.theme.colors.white};
  font-weight: bold;
  text-align: center;
`;

export const StyledButtonsWrapper = styled.div`
  display: flex;
  width: 100%;
`;

export const StyledButton = styled.button<{disabled?: boolean}>`
  font-weight: bold;
  color: white;
  flex:1;
  padding: 2rem 1rem;
  border: none;
  text-transform: uppercase;

  &.approve {
    background-color: ${props => props.theme.colors.green};
  }

  &.reject {
    background-color: ${props => props.theme.colors.red};
  }

  &.back {
    background-color: ${props => props.theme.colors.grey};
  }

  &.initial {
    background-color: ${props => (props.disabled ? props.theme.colors.grey : shade(0.2, props.theme.colors.primary))};
  }
`;
