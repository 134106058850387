import { shade } from 'polished';
import styled from 'styled-components';

import { devices } from '../../../assets/styles/devices';

export const StyledFilter = styled.div`
  display: flex;
  height: fit-content;
  flex-direction: column;
  border: 0.1rem solid ${(props) => shade(0.5, props.theme.colors.white)};
  border-radius: 0.5rem;
`;

export const StyledTitle = styled.h2`
  font-size: 1.9rem;
  font-weight: bold;
  padding: 2rem;
  border-top-left-radius: 0.5rem;
  border-top-right-radius: 0.5rem;
  background-color: ${(props) => props.theme.colors.white};
  marginBottom: 0;
`;

export const StyledFiltersContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 3rem;
`;

export const StyledDatesWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2rem;
  margin-bottom: 2rem;

  .filter-content {
    margin: 0;
    width: 100%;
  }

  @media ${devices.tabletLandscape} {
    flex-direction: row;
  }

  .filter-date-picker {
    width: 100%;
    margin-bottom: 1rem;

    @media ${devices.tabletLandscape} {
      margin-bottom: 0;
      margin-right: 2rem;
    }

    &:last-child {
      margin-bottom: 0;
      margin-right: 0;
    }
}
`;
