import styled, { css } from 'styled-components';
import { Paper } from '@material-ui/core';

import { devices } from '../../assets/styles/devices';

export const StyledLocationProfile = styled.div<{ hasNoContent: boolean }>`
  display: flex;
  flex-direction: column;
  padding-bottom: 4rem;
  position: relative;
  height: auto;

  ${props => props.hasNoContent && css`
    height: 100vh;
  `}
 
  .location-form,
  .general-information-form,
  .location-responsibles,
  .progress-info {
    margin-bottom: 3rem;
  }

  .save-button {
    max-width: 15rem;
    width: 100%;
    margin-right: 2rem;
    margin-top: 4rem;
    margin-left: auto;
  }

  .group-empty-or-company {
    margin: 2rem;
  }
`;

export const StyledContent = styled.div<{ hasNoContent: boolean }>`
  display: grid;
  grid-template-columns: 1fr;
  row-gap: 2rem;
  padding: 0 2rem;
  align-items: flex-start;

  @media ${devices.tabletLandscape} {
    grid-template-columns: 75% 23%;
    column-gap: 2%;
  }

  @media ${devices.widescreen} {
    grid-template-columns: 81% 17%;
  }

  ${props => props.hasNoContent && css`
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 1;
  `}

  .main-links {
    margin-bottom: 3rem;
  }
`;

export const StyledContentMain = styled.div`
  display: flex;
  flex-direction: column;

  .content-main-block-title{
    font-size: 1.5rem;
    margin-bottom: 2rem;
    font-weight: 700;
  }
`;

export const StyledContenSide = styled.div`
  position: sticky;
  top: 0;
  padding-top: 3.7rem;
`;

export const StyledContractListWrapper = styled(Paper)`
  display: flex;
  flex-direction: column;
  margin-bottom: 3rem;
  padding: 2rem;
`;

export const StyledDataStudioWrapper = styled(Paper)`
  display: flex;
  flex-direction: column;
  margin-bottom: 3rem;
  padding: 2rem;

  a {
    word-wrap: break-word;
    margin-bottom: 1rem;
  }

  h3 {
    font-size: 1.5rem;
    font-weight: bold;
    margin-bottom: 1rem;
  }

`;
