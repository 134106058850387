import styled from 'styled-components';
import { shade } from 'polished';

export const StyledWorkingHoursInfo = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const StyledWorkingIHoursDays = styled.ul`
  display: flex;
  flex-direction: column;
  width: 100%;

  li {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    padding: 1rem;
    margin-bottom: 0.1rem;
    background: ${props => shade(0.1, props.theme.colors.white)};
  }
`;

export const StyledTimeRange = styled(StyledWorkingIHoursDays)`
  display: flex;
  flex-direction: column;

  li {
    flex-direction: row;
    padding: 0;
    background: transparent;
    margin-bottom: 0;
  }

  b { 
    margin-right: 0.5rem;
  }

  .divider {
    margin: 0 0.5rem;
  }
`;
