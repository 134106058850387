import { MUIDataTableColumn } from 'mui-datatables';

export const initialDateColumns: MUIDataTableColumn[] = [
  {
    name: 'id',
    label: 'Id',
    options: { display: false },
  },
  {
    name: 'user_name',
    label: 'Nome do Usuário',
  },
  {
    name: 'email',
    label: 'E-mail',
  },
  {
    name: 'ip_address',
    label: 'IP',
  },
  {
    name: 'ip_location',
    label: 'Localização IP',
  },
  {
    name: 'created_at',
    label: 'Data e Hora do Login',
  },
];

export type TTableData = {
  id: string;
  user: {
    name: string;
    email: string;
  }
  'ip_address': string;
  'ip_location': string;
  'created_at': string;
}

export type TTableRowData = {
  columnRef: string;
  visible: boolean;
  value: any;
};
