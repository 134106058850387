import axios from 'axios';

export default async function deleteInvite({ accessToken, id, setTokenLikeExpired }) {
  try {
    const requestOptions = {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    };

    const { data } = await axios.delete(
      `${process.env.REACT_APP_INVITES_API_URL}/${id}`,
      requestOptions,
    );

    return data;
  } catch (err) {
    if (err.response?.status === 401 && setTokenLikeExpired) return setTokenLikeExpired();

    return err;
  }
}
