import React from 'react';

import SpecialDateRow from '../special-date-row';

import {
  TCurrentDatesBaseProps,
} from '../../../types/TSpecialDates';

import {
  StyledCurrentDates,
  StyledSpecialDatesRowListing,
} from './current-dates-styles';

const CurrentDates = ({
  data = [],
  setSpecialDateToEdit,
  setSpecialDatesData,
}: TCurrentDatesBaseProps) => {
  const handleDelete = React.useCallback((specialDateIdToDelete: string) => {
    setSpecialDatesData(data.filter(dateItem => dateItem.id !== specialDateIdToDelete));
  }, [data]);

  return (
    <StyledCurrentDates>
      <StyledSpecialDatesRowListing>
        {data.map(specialDateRow => (
          <SpecialDateRow
            id={specialDateRow.id}
            key={specialDateRow.id}
            date={specialDateRow.date}
            isOpen={specialDateRow.is_open}
            name={specialDateRow.name}
            is24Hours={specialDateRow.is_24_hours}
            times={specialDateRow.times}
            specialDateRow={specialDateRow}
            setSpecialDateToEdit={setSpecialDateToEdit}
            handleDelete={handleDelete}
          />
        ))}
      </StyledSpecialDatesRowListing>
    </StyledCurrentDates>
  );
};

export default CurrentDates;
