import React from 'react';
import { useSelector } from 'react-redux';
import * as R from 'ramda';
import { useSnackbar } from 'notistack';

import { format, parse } from 'date-fns';

import { ExpandLess, ExpandMore } from '@material-ui/icons';

import createTicket from '../../../../services/tickets/create';
import type { TRootStateRedux } from '../../../../types/TRootStateRedux';

import getValidGoogleAccessToken from '../../../../services/getValidGoogleAccessToken';
import createEventPost from '../../../../services/google/post/createPost';
import putObject from '../../../../services/aws/putObject';
import { applyMaskToTime } from '../../../../utils/apply-mask-to-time';

import APIBatchCreatePosts from '../../../../services/batch-update/batchCreatePosts';

import {
  imagePostValidation,
  locationPostAddButtonsType,
  getActionType,
  getActionTypeReverse,
  formatDateObjectToString,
} from '../../helpers';

import {
  isValidPostStartToEndDate,
  isValidPostStartToEndTime,
  isVideoFiles,
} from '../helpers';

import { inputTimeToArrayData } from '../../../../utils/input-time-to-array-data';

import {
  Input,
  InputSwitch,
  InputDatePicker,
  Textarea,
  Time,
  Button,
  Loading,
  Select,
} from '../../../../components';

import type {
  TLocationPostAddButtonType,
  TBatchPostFormEventBaseProps,
} from '../../../../types/TLocationPost';

import { websiteValidate } from '../../../../utils/fields-validate';

import { useAuth } from '../../../../hooks';

import DragAndDropFiles from '../../../../components/drag-and-drop-file';

import {
  StyledPostFormEvent,
  StyledButton,
  StyledInputSwitchWrapper,
  StyledAdditionalDetailsWrapper,
  StyledHourFieldWrapper,
  StyledAlertMessage,
} from './post-form-event-styles';

const PostFormEvent = ({
  closeModal,
  dataToUpdate,
  affectedLocations,
  getLocalPosts,
}: TBatchPostFormEventBaseProps) => {
  const { enqueueSnackbar } = useSnackbar();

  const {
    userAccessToken,
    userId,
    userName,
    userEmail,
    userProfile,
    userSetTokenLikeExpired,
  } = useAuth();

  const { activeLocationGroupId, showLocationsByGroup } = useSelector((state: TRootStateRedux) => state.LocationGroupReducer);
  const { activeCompanyId, showLocationsByCompany } = useSelector((state: TRootStateRedux) => state.CompanyReducer);

  const [eventTitle, setEventTitle] = React.useState('');
  const [hasHour, setHasHour] = React.useState(false);
  const [addedButtonType, setAddedButtonType] = React.useState('Nenhum');
  const [addedButtonTypeValue, setAddedButtonTypeValue] = React.useState<string>('');
  const [addDetails, setAddDetails] = React.useState(false);
  const [openingHour, setOpeningHour] = React.useState<string>('00:00');
  const [closingHour, setClosingHour] = React.useState<string>('00:00');
  const [eventDetails, setEventDetails] = React.useState('');
  const [filesToPost, setFilesToPost] = React.useState<any[]>([]);
  const [isPublish, setIsPublish] = React.useState<React.SetStateAction<boolean>>(false);
  const [isAddedButton, setIsAddedButton] = React.useState(false);
  const [isAddedButtonPhoneType, setIsAddedButtonPhoneType] = React.useState(false);
  const [startDate, setStartDate] = React.useState<Date | null>(null);
  const [endDate, setEndDate] = React.useState<Date | null>(null);

  const getInputDateData = inputTimeToArrayData('/');
  const getInputTimeData = inputTimeToArrayData(':');

  const isValidInput = ({
    isValidEventDate,
    messageEventDateFeedBack,
    isValidEventTime,
    messageEventTimeFeedBack,
    googleAccessToken,
    actionType,
  }: any) => {
    if (R.isEmpty(eventTitle)) {
      enqueueSnackbar('O Título do Evento não pode ser vazio!', { variant: 'warning' });

      return false;
    }

    if (!isValidEventDate) {
      enqueueSnackbar(messageEventDateFeedBack, { variant: 'warning' });

      return false;
    }

    if (!isValidEventTime) {
      enqueueSnackbar(messageEventTimeFeedBack, { variant: 'warning' });

      return false;
    }

    if (R.isNil(googleAccessToken)) {
      enqueueSnackbar('Não foi possível adquirir um token do Google para criar postagem', { variant: 'warning' });

      return false;
    }

    const isInvalidActionType = (!R.isNil(actionType) && actionType !== 'CALL') && R.isEmpty(addedButtonTypeValue.trim());

    if (isInvalidActionType) {
      enqueueSnackbar('A URL do botão de ação não pode ser vazio!', { variant: 'warning' });

      return false;
    }

    if (!R.isEmpty(addedButtonTypeValue) && !R.isNil(addedButtonTypeValue)) {
      if (!websiteValidate(addedButtonTypeValue)) {
        enqueueSnackbar('O formato do link inserido é inválido!', { variant: 'warning' });
        return false;
      }
    }

    if (!R.isEmpty(eventDetails) && !R.isNil(eventDetails)) {
      if (eventDetails.length > 1500) {
        enqueueSnackbar('Os detalhes da postagem não podem conter mais de 1500 caracteres', { variant: 'warning' });
        return false;
      }
    }

    return true;
  };

  const handlePublishClicked = React.useCallback(async () => {
    if (R.isNil(startDate)) return;
    if (R.isNil(endDate)) return;

    const formattedStartDate = format(startDate, 'dd/MM/yyyy');
    const formattedEndDate = format(endDate, 'dd/MM/yyyy');

    const startDateData = getInputDateData(formattedStartDate);
    const [startDay, startMonth, startYear] = startDateData;

    const endDateData = getInputDateData(formattedEndDate);
    const [endDay, endMonth, endYear] = endDateData;

    const actionType = getActionType(addedButtonType);

    const {
      isValid: isValidEventDate,
      message: messageEventDateFeedBack,
    } = isValidPostStartToEndDate({
      startDateData,
      endDateData,
      hasHour,
    });

    const openingHourData = hasHour ? getInputTimeData(openingHour) : [0, 0];
    const [openingHours, openingMinutes] = openingHourData;

    const closingHourData = hasHour ? getInputTimeData(closingHour) : [23, 59];
    const [closingHours, closingMinutes] = closingHourData;

    const googleAccessToken = await getValidGoogleAccessToken({
      accessToken: userAccessToken,
      userId,
      userProfile,
    });

    const {
      isValid: isValidEventTime,
      message: messageEventTimeFeedBack,
    } = isValidPostStartToEndTime({
      openingHourData,
      closingHourData,
      startDateData,
      endDateData,
      hasHour,
    });

    if (!isValidInput({
      actionType,
      googleAccessToken,
      isValidEventDate,
      isValidEventTime,
      messageEventDateFeedBack,
      messageEventTimeFeedBack,
    })) return;

    setIsPublish(true);

    let mediaData: any = null;

    if (!R.isEmpty(filesToPost)) {
      mediaData = await Promise.all(filesToPost.map(async (fileToPost) => {
        const putObjectData = await putObject({
          accessToken: userAccessToken,
          bucketName: 'hub-saas-media',
          file: fileToPost,
          googleAccountId: null,
          googleLocationId: null,
          mediaType: fileToPost.type === 'video/mp4' ? 'VIDEO' : 'PHOTO',
        });

        const mediaFileUrl = putObjectData.fileUrl;

        return {
          mediaFormat: fileToPost.type === 'video/mp4' ? 'VIDEO' : 'PHOTO',
          sourceUrl: mediaFileUrl,
        };
      }));
    }

    if (isVideoFiles(filesToPost) || filesToPost.length > 1) {
      return;
    }

    let postData: any = {
      media_url: R.isNil(mediaData) ? null : mediaData[0].sourceUrl,
      type: 'EVENT',
      call_to_action_type: actionType,
      call_to_action_value: !R.isEmpty(addedButtonTypeValue) ? addedButtonTypeValue : null,
      title: eventTitle,
      schedule: {
        startDate: {
          day: startDay,
          month:
            startMonth,
          year: startYear,
        },
        endDate: {
          day: endDay,
          month: endMonth,
          year: endYear,
        },
        startTime: {
          hours: closingHours === 0 && openingHours === 0 ? 0 : openingHours,
          minutes: closingHours === 0 && openingHours === 0 ? 0 : openingMinutes,
          seconds: 0,
          nanos: 0,
        },
        endTime: {
          hours: closingHours === 0 && openingHours === 0 ? 0 : closingHours,
          minutes: closingHours === 0 && openingHours === 0 ? 0 : closingMinutes,
          seconds: 0,
          nanos: 0,
        },
      },
    };

    if (R.isEmpty(eventDetails) === false) {
      postData = {
        ...postData,
        details: eventDetails,
      };
    }

    const createEventPostResponse = await APIBatchCreatePosts({
      accessToken: userAccessToken,
      created_by: userId,
      groupType: showLocationsByCompany ? 'COMPANY' : 'LOCATIONS_GROUP',
      groupId: showLocationsByCompany ? activeCompanyId : activeLocationGroupId,
      locations: affectedLocations,
      postData,
      feedbackMessage: enqueueSnackbar,
      setIsLoading: setIsPublish,
      setTokenLikeExpired: userSetTokenLikeExpired,
      userData: {
        user_id: userId,
        user_name: userName,
        user_email: userEmail,
      },
    });

    if (R.isNil(createEventPostResponse) || createEventPostResponse.status === 406) return;

    getLocalPosts();
    closeModal();
  }, [
    userAccessToken,
    userProfile,
    startDate,
    endDate,
    openingHour,
    closingHour,
    hasHour,
    eventDetails,
    eventTitle,
    addedButtonType,
    addedButtonTypeValue,
    showLocationsByCompany,
    activeCompanyId,
    activeLocationGroupId,
    affectedLocations,
    userId,
    userName,
    userEmail,
  ]);

  const handleAddDetails = () => {
    setAddDetails(!addDetails);

    setEventDetails('');
  };

  const handleAddButton = (value: any) => {
    setAddedButtonType(value);

    if (value === 'Nenhum') setAddedButtonTypeValue('');
  };

  React.useEffect(() => {
    if (R.isNil(dataToUpdate)) return;

    const {
      media_url,
      call_to_action_type,
      call_to_action_value,
      title,
      schedule,
      type,
      details,
    } = dataToUpdate.data_post;

    const {
      formattedEndDate,
      formattedEndTime = '',
      formattedStartDate,
      formattedStartTime = '',
    } = formatDateObjectToString(schedule);

    if (type === 'EVENT') {
      setEventTitle(title);
      if (details) setAddDetails(true);
      if (details) setEventDetails(details);

      setStartDate(parse(formattedStartDate, 'dd/MM/yyyy', new Date()));
      setEndDate(parse(formattedEndDate, 'dd/MM/yyyy', new Date()));

      if (formattedStartTime && formattedEndTime) {
        if (formattedStartTime === '0000' && formattedEndTime === '2359') setHasHour(false);
        else setHasHour(true);
      }

      setOpeningHour(formattedStartTime);
      setClosingHour(formattedEndTime);
      if (call_to_action_type) setAddedButtonType(getActionTypeReverse(call_to_action_type) || 'Nenhum');
      if (call_to_action_value) setAddedButtonTypeValue(call_to_action_value || '');

      if (media_url) {
        setFilesToPost([{
          mediaFormat: 'PHOTO',
          sourceUrl: media_url,
        }]);
      }
    }
  }, [dataToUpdate]);

  React.useEffect(() => {
    if (addedButtonType !== 'Nenhum' && addedButtonType !== 'Ligar agora') {
      setIsAddedButton(true);
      setIsAddedButtonPhoneType(false);
    }

    if (addedButtonType === 'Ligar agora') {
      setIsAddedButtonPhoneType(true);
      setIsAddedButton(false);
    }

    if (addedButtonType === 'Nenhum') {
      setIsAddedButton(false);
      setIsAddedButtonPhoneType(false);
    }
  }, [addedButtonType]);

  return (
    <StyledPostFormEvent>
      {R.isNil(dataToUpdate) && (
        <DragAndDropFiles
          filesToPost={filesToPost}
          imageFileValidation={imagePostValidation}
          setFilesToPost={setFilesToPost}
          maxVideoSize={70}
          singleMode
          editable={R.isNil(dataToUpdate)}
        />
      )}

      <Input
        disabled={!R.isNil(dataToUpdate)}
        value={eventTitle}
        border
        label="Título do Evento *"
        onChange={(e) => setEventTitle(e.currentTarget.value)}
      />

      <StyledInputSwitchWrapper>
        {R.isNil(dataToUpdate) && (
          <InputSwitch
            name="has-hour"
            label="Adicionar Hora?"
            checked={hasHour}
            changeChecked={() => setHasHour(!hasHour)}
            alignRow
            className="event-status-hour-toggle"
          />
        )}
      </StyledInputSwitchWrapper>

      <StyledHourFieldWrapper hasHour={hasHour}>
        <InputDatePicker
          disabled={!R.isNil(dataToUpdate)}
          selectedDate={startDate}
          setSelectedDate={e => setStartDate(e)}
          label="De: *"
          className="input-date-picker"
        />

        {hasHour && (
          <Time
            readonly={!R.isNil(dataToUpdate)}
            label="Horário de Inicio"
            timeValue={openingHour}
            setTimeValue={setOpeningHour}
            columnAlign
          />
        )}
      </StyledHourFieldWrapper>

      <StyledHourFieldWrapper hasHour={hasHour}>
        <InputDatePicker
          disabled={!R.isNil(dataToUpdate)}
          selectedDate={endDate}
          setSelectedDate={e => setEndDate(e)}
          label="Até: *"
          className="input-date-picker"
        />

        {hasHour && (
          <Time
            readonly={!R.isNil(dataToUpdate)}
            label="Horário de Término"
            timeValue={closingHour}
            setTimeValue={setClosingHour}
            columnAlign
          />
        )}
      </StyledHourFieldWrapper>

      <Select<TLocationPostAddButtonType>
        label="Adicionar um botão (opcional)"
        value={addedButtonType}
        name="select-button-type"
        border
        disabled={!R.isNil(dataToUpdate)}
        onChange={({ target }) => handleAddButton(target.value)}
        options={locationPostAddButtonsType}
        className="field-last-select"
      />

      {(isAddedButton && !isAddedButtonPhoneType) && (
        <Input
          disabled={!R.isNil(dataToUpdate)}
          border
          label="Link para o botão"
          value={addedButtonTypeValue}
          onChange={(e) => setAddedButtonTypeValue(e.currentTarget.value)}
          className="field-last-select"
        />
      )}

      {R.isNil(dataToUpdate) && (
        <StyledButton
          onClick={() => handleAddDetails()}
        >
          Adicionar detalhes (opcional)
          {addDetails ? <ExpandLess /> : <ExpandMore />}
        </StyledButton>
      )}

      {addDetails && (
        <StyledAdditionalDetailsWrapper>
          <Textarea
            border
            label="Detalhes do Evento"
            name="event-details"
            className="event-details"
            value={eventDetails}
            onChange={setEventDetails}
            disabled={!R.isNil(dataToUpdate)}
          />
        </StyledAdditionalDetailsWrapper>
      )}

      {R.isNil(dataToUpdate) && (
        <Button
          className="publish-button"
          disabled={Boolean(isPublish)}
          onClick={handlePublishClicked}
        >
          {isPublish && <Loading className="is-button-loading" />}
          Publicar
        </Button>
      )}
    </StyledPostFormEvent>
  );
};

export default PostFormEvent;
