import React from 'react';

import {
  Loading,
} from '../../../../components';

import {
  StyledLoading,
} from './ticket-loading-styles';

const TicketLoading = () => (
  <StyledLoading>
    <Loading />
    <span className="text-disclaimer">Atualizando...</span>
  </StyledLoading>
);

export default TicketLoading;
