export const companyRoutes: Record<string, boolean> = {
  '/batch-update-posts-status': true,
  '/batch-update-posts-form': true,
  '/gallery-batch': true,
  '/service-batch': true,
  '/reviews': true,
};

export const locationRoutes: Record<string, boolean> = {
  '/location/profile': true,
  '/location/posts': true,
  '/location/gallery': true,
  '/location/services': true,
  '/reviews/location': true,
  '/reviews/:location': true,
};

export const permissionsList: Record<string, string> = {
  '/location/profile': 'infoPermission',
  '/location/posts': 'postPermission',
  '/location/gallery': 'mediaPermission',
  '/location/services': 'servicePermission',
  '/reviews/:location': 'reviewsPermission',
  '/reviews/location': 'reviewsPermission',
};
