import * as R from 'ramda';

import { TGetValidGoogleAccessToken } from '../types/TConnections';

import getUserConnections from './connections/getUserConnections';
import refreshGoogleAccessToken from './google/refreshGoogleAccessToken';
import updateConnection from './connections/updateConnection';
import APICreateConnection from './connections/createConnection';

import generateTokenExpirationDate from '../utils/generate-token-expiration-date';

export const refreshTokenInvalid = ({
  feedbackMessage,
  userProfile,
}: Pick<TGetValidGoogleAccessToken, 'feedbackMessage' | 'userProfile'>) => () => {
  if (R.isNil(feedbackMessage)) return;

  if (userProfile !== 'Usuário Padrão') {
    feedbackMessage('O Refresh token não é mais valido ou não existe!', {
      variant: 'warning',
    });

    return;
  }

  feedbackMessage(
    'Encontramos um problema no seu token de acesso às avaliações. Entre em contato com o suporte!',
    { variant: 'warning' },
  );
};

export default async function getValidGoogleAccessToken({
  userGoogleRefreshToken = null,
  userId,
  accessToken,
  setTokenLikeExpired,
  feedbackMessage = null,
  userProfile,
}: TGetValidGoogleAccessToken) {
  const feedbackMessageTokenInvalidByUser = refreshTokenInvalid({
    feedbackMessage,
    userProfile,
  });

  const getUserConnectionsResponse = await getUserConnections({
    accessToken,
    userId,
    setTokenLikeExpired,
    query: 'google',
  });

  if (R.isNil(getUserConnectionsResponse)) return null;

  const [data, amount] = getUserConnectionsResponse;

  if (amount === 0) {
    if (R.isNil(userGoogleRefreshToken) || R.isEmpty(userGoogleRefreshToken)) {
      feedbackMessageTokenInvalidByUser();
      return null;
    }

    const newGoogleAccessTokenResponse = await refreshGoogleAccessToken({
      refreshToken: userGoogleRefreshToken,
    });

    if (R.isNil(newGoogleAccessTokenResponse)) return null;

    const {
      access_token: googleAccessToken,
      code: newGoogleAuthorizationCode,
      expires_in: expirationTime,
    } = newGoogleAccessTokenResponse;

    await APICreateConnection({
      accessToken,
      userId,
      createdBy: userId,
      channel: 'google',
      token: googleAccessToken,
      code: newGoogleAuthorizationCode,
      tokenExpiresIn: generateTokenExpirationDate(expirationTime),
      setTokenLikeExpired,
      refreshToken: userGoogleRefreshToken,
    });

    return {
      googleAccessToken, googleRefreshToken: userGoogleRefreshToken, expirationTime, googleAuthCode: newGoogleAuthorizationCode,
    };
  }

  const {
    id: connectionId,
    token_expires_in: expirationTime,
    token: googleAccessToken,
    refresh_token: googleRefreshToken,
    code,
  } = data[0];

  const currentDate = new Date();
  const expirationDate = new Date(expirationTime);

  const isValid = currentDate < expirationDate;

  if (isValid) {
    return {
      googleAccessToken,
      expirationTime,
      googleRefreshToken,
      googleAuthCode: code,
    };
  }

  const refreshGoogleAccessTokenResponse = await refreshGoogleAccessToken({
    refreshToken: googleRefreshToken,
  });

  if (R.isNil(refreshGoogleAccessTokenResponse)) {
    feedbackMessageTokenInvalidByUser();
    return null;
  }

  const { access_token: refreshedAccessToken, expires_in: expiresIn } = refreshGoogleAccessTokenResponse;

  const connectionInfoToUpdate = {
    token: refreshedAccessToken,
    token_expires_in: generateTokenExpirationDate(expiresIn),
  };

  await updateConnection({
    accessToken,
    connectionId,
    inputData: connectionInfoToUpdate,
    setTokenLikeExpired,
  });

  return {
    googleAccessToken: refreshedAccessToken,
    expirationTime: expiresIn,
    googleRefreshToken,
    googleAuthCode: code,
  };
}
