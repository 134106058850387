import styled from 'styled-components';

export const StyledTicketsList = styled.ul`
    margin: 0;
    width: 100%;
    max-height: 59rem;
    overflow-y: auto;
    position: relative;
`;

export const StyledTicketListContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
`;
