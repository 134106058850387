import * as R from 'ramda';

import {
  emailValidate,
  passwordValidate,
} from '../../utils/fields-validate';

import { propNameToCamelCases } from '../../utils/prop-name-to-camel-case';

export const handleFormValidation = ({
  fields,
  addedProfiles,
  googleRefreshToken,
  enqueueSnackbar,
  willNotUpdatePassword = true,
}) => {
  let checkIfAnyEmptyField = false;
  let checkIfEmailIsInvalid = false;
  let checkIfInvalidLimit = false;
  let checkIfPasswordIsInvalid = false;

  fields.map(field => {
    if (field.getValue() === '' && field.name !== 'field-user-password') {
      checkIfAnyEmptyField = true;
    }

    if (field.name.includes('limit')) {
      if (parseInt(field.getValue()) < 0) checkIfInvalidLimit = true;
    }

    if (field.name === 'field-user-email') {
      if (!emailValidate(field.getValue())) {
        checkIfEmailIsInvalid = true;
      }
    }

    if (field.name === 'field-user-password' && !willNotUpdatePassword) {
      if (!passwordValidate(field.getValue())) {
        checkIfPasswordIsInvalid = true;
      }
    }
  });

  if (checkIfAnyEmptyField) {
    enqueueSnackbar('Todos os campos devem ser preenchidos', { variant: 'warning' });
    return true;
  }

  if (checkIfEmailIsInvalid) {
    enqueueSnackbar('Formato de email inválido', { variant: 'warning' });
    return true;
  }

  if (checkIfPasswordIsInvalid) {
    enqueueSnackbar('Senha deve conter 8 letras, números e uma letra maiúscula', { variant: 'warning' });
    return true;
  }

  if (googleRefreshToken === '') {
    enqueueSnackbar('Insira um Google Refresh Token válido', { variant: 'warning' });
    return true;
  }

  if (checkIfInvalidLimit) {
    enqueueSnackbar('Os limites de registro não podem ser negativos', { variant: 'warning' });
    return true;
  }

  if (checkIfInvalidLimit) {
    enqueueSnackbar('Os limites de registro não podem ser negativos', { variant: 'warning' });
    return true;
  }

  if (R.isEmpty(addedProfiles)) {
    enqueueSnackbar('Adicione um perfil de usuário antes de prosseguir', { variant: 'warning' });
    return true;
  }

  return false;
};

export const listProfileIds = (profiles) => {
  const profileIds = [];
  profiles.map(profile => {
    profileIds.push(profile.id);
  });

  return profileIds;
};

export const getFieldsCurrentValue = ({ fields, googleRefreshToken }) => ({
  name: fields[0].getValue(),
  email: fields[1].getValue(),
  google_refresh_token: googleRefreshToken,
  phone: fields[2].getValue(),
  password: fields[3].getValue(),
  company_limit: fields[4].getValue(),
  company_group_limit: fields[5].getValue(),
  location_limit: fields[6].getValue(),
  location_group_limit: fields[7].getValue(),
});

export const setFieldsToDefault = (fields) => {
  fields.map(field => {
    field.setDefaultValue(field.getValue());
  });
};

export const getFieldsActions = (fields) => {
  let fieldsByName = {};

  fields.forEach(({ name, setDefaultValue, getValue }) => {
    fieldsByName = {
      ...fieldsByName,
      [propNameToCamelCases(`set-${name}`)]: setDefaultValue,
      [propNameToCamelCases(`get-${name}`)]: name.includes('limit') ? parseInt(getValue()) : getValue(),
    };
  });

  return fieldsByName;
};

export const getUserInfosValues = (userInfosData) => {
  const {
    company_group_limit: userCompanyGroupLimit,
    company_limit: userCompanyLimit,
    email: userEmail,
    google_refresh_token: userGoogleRefreshToken,
    location_group_limit: userLocationGroupLimit,
    location_limit: userLocationLimit,
    name: userName,
    phone: userPhone,
    profiles: userProfiles,
  } = userInfosData;

  return {
    userCompanyGroupLimit: userCompanyGroupLimit || 0,
    userCompanyLimit: userCompanyLimit || 0,
    userEmail: userEmail || '',
    userGoogleRefreshToken: userGoogleRefreshToken || '',
    userLocationGroupLimit: userLocationGroupLimit || 0,
    userLocationLimit: userLocationLimit || 0,
    userName: userName || '',
    userPhone: userPhone || '',
    userProfiles: userProfiles || [],
  };
};
