import axios from 'axios';

import {
  TGetOneLocalPost,
  TGetOneLocalPostResponse,
} from '../../types/TBatchGoogleModules';

export const getOneLocalPost = async ({
  postId,
  userAccessToken,
  isFetching,
}: TGetOneLocalPost): Promise<
TGetOneLocalPostResponse
> => {
  try {
    if (isFetching) isFetching(true);

    const apiPath = `
    ${process.env.REACT_APP_SAAS_BATCH_GOOGLE_MODULES}/local-posts/find/${postId}
  `;

    const requestOptions = {
      headers: {
        Authorization: `Bearer ${userAccessToken}`,
      },
    };

    const { data } = await axios.get(apiPath, requestOptions);

    return data;
  } catch (err) {
    return null;
  } finally {
    if (isFetching) isFetching(false);
  }
};
