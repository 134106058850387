import React from 'react';

import { TIs24HoursBaseProps } from '../../../../../types/TWorkingHours';

import {
  StyledIs24HoursButton,
} from './is-24-hours-button-styles';

const Is24HoursButton = ({
  title = '24 horas',
  isActive = false,
  onClick,
  className,
}: TIs24HoursBaseProps) => (
  <StyledIs24HoursButton
    isActive={isActive}
    onClick={onClick}
    className={className}
  >
    {title}
  </StyledIs24HoursButton>
);

export default Is24HoursButton;
