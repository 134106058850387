import axios from 'axios';
import { TGetAllConnectionsFromGoogleLocationId } from '../../types/TPlatformsLinkWithLocation';

export default async function getAllConnectionsFromGoogleLocation({
  accessToken,
  page = 0,
  pageSize = 10,
  status,
  googleLocationId,
  setTokenLikeExpired,
}: TGetAllConnectionsFromGoogleLocationId) {
  try {
    const { data } = await axios.get(
      `${process.env.REACT_APP_LOCATION_CONNECTIONS}/location-google/${googleLocationId}`,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
        params: {
          page,
          pageSize,
          status,
          googleLocationId,
        },
      },
    );

    return data;
  } catch (err: any) {
    if (err.response?.status === 401 && setTokenLikeExpired) setTokenLikeExpired();

    return null;
  }
}
