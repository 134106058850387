import styled from 'styled-components';
import { lighten, shade } from 'polished';

export const StyledPostFormNews = styled.div`
  padding: 2rem 2rem 4rem;
  width: 100%;
  background: ${props => props.theme.colors.white};
  border-radius: 0.5rem;
  
  .news-status-textarea {
    margin-bottom: 2rem;
    
    textarea {
      min-height: 3rem;
      resize: vertical;
    }
  }
  
  .publish-button {
    margin-left: auto;
    margin-top: 2rem;
  }
  
  .is-button-loading {
    margin-right: 1rem;
  }
`;

export const StyledAlertMessage = styled.p`
  display: flex;
  align-items: center;
  text-align: left;
  padding: 0.5rem 1rem;
  border-radius: 0.25rem;
  background: ${props => lighten(0.4, props.theme.colors.red)};
  border: 0.1rem solid ${props => shade(0.05, props.theme.colors.red)};
  color: ${props => shade(0.2, props.theme.colors.red)};
  margin: 2rem 0;
  display: flex;
  width: 100%;
  font-size: 1.3rem;
`;
