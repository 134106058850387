import {
  emailValidate,
  phoneValidate,
  websiteValidate,
  zipCodeIsValid,
  isNumber,
} from '../../utils/fields-validate';

const checkField = ({
  nickname,
  message,
  value,
  scheme,
  isOutsideBrazil = false,
  isRequired = true,
}) => {
  if (isRequired && !value?.trim()) {
    message(`O campo ${nickname} é obrigatório`);
    return false;
  }

  if (scheme.phone && !phoneValidate(value)) {
    if (!isRequired && !value) return true;

    message(`O campo ${nickname} é inválido`);
    return false;
  }

  if (scheme.email && !emailValidate(value)) {
    message(`O campo ${nickname} é inválido`);
    return false;
  }

  if (scheme.website && !websiteValidate(value)) {
    message(`O campo ${nickname} é inválido`);
    return false;
  }

  if (scheme.zipcode && !zipCodeIsValid(value, isOutsideBrazil)) {
    message(`O campo ${nickname} é inválido`);
    return false;
  }

  if (scheme.number && !isNumber(Number(value))) {
    message(`O campo ${nickname} só pode conter números`);
    return false;
  }

  if (scheme.shortDescription && value.length > 250) {
    message(`O campo ${nickname} só pode conter no máximo 250 caracteres`);
    return false;
  }

  if (scheme.longDescription && value.length > 750) {
    message(`O campo ${nickname} só pode conter no máximo 750 caracteres`);
    return false;
  }

  return true;
};

export default checkField;
