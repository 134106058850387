import styled from 'styled-components';
import { lighten } from 'polished';
import { devices } from '../../../assets/styles/devices';

export const StyledLocationConnectionItemWrapper = styled.div`
   display: flex;
   flex-direction: column;
   padding: 1rem 2rem;
   align-items: center;
   background-color: ${props => props.theme.colors.lightGrey};

   &:nth-child(even) {
    background-color: ${props => lighten(0.1, props.theme.colors.lightGrey)};
   }

   @media ${devices.tablet} {
    flex-direction: row;
   }

   .status-icon {
    margin-bottom: 1rem;

    @media ${devices.tablet} {
      margin-bottom: 0;
    }
  }
`;
export const StyledConnectionInfo = styled.p`
  margin: 0;
  font-size: 1.3rem;
`;

export const StyledLabel = styled.label`
  margin: 0;
  font-size: 1.3rem;
  display: flex;

  p {
    padding-left: 0.5rem;
  }
`;

export const StyledButtonsWrapper = styled.div`
   display: flex;
   width: 10rem;
   justify-content: space-evenly;
   align-items: center;
`;

export const SwitchWrapper = styled.div`
  align-self: center;
  margin: 0 0.5rem 0 0;
`;

export const StyledLocationName = styled.h3`
  margin: 0;
`;

export const StyledConnectionInfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 1.5rem;
  flex: 1;

  @media ${devices.tablet} {
    align-items: flex-start;
    margin-bottom: 0;
    padding: 0 2rem;
  }
`;
