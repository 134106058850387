import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Creators as AuthActions } from '../../../redux/ducks/auth';

import NavItem from './nav-item';
import SubMenu from './submenu';

import {
  StyledNav,
} from './nav-styles';

const Nav = ({
  items,
  hasSubMenu,
  setIsOpenSideMenu,
  openAccordionMenu,
  setOpenAccordionMenu,
}) => {
  const reduxDispatch = useDispatch();

  const { user } = useSelector(state => state.AuthReducer);

  const handleExit = () => {
    reduxDispatch(AuthActions.setUser(null));
  };

  return (
    <StyledNav>
      {hasSubMenu
        ? (
          <SubMenu
            items={items}
            setIsOpenSideMenu={setIsOpenSideMenu}
            openAccordionMenu={openAccordionMenu}
            setOpenAccordionMenu={setOpenAccordionMenu}
          />
        ) : (
          <>
            {items
              .filter(item => item.name !== 'AsGuest')
              .map(item => (
                <NavItem
                  key={item.id}
                  name={item.friendly_name}
                  icon={item.icon}
                  url={item.path_to_redirect}
                  hasSubMenu={hasSubMenu}
                  setIsOpenSideMenu={setIsOpenSideMenu}
                  openAccordionMenu={openAccordionMenu}
                  setOpenAccordionMenu={setOpenAccordionMenu}
                />
              ))}

            <NavItem
              className="log-out-icon"
              name="Sair"
              icon="ExitToApp"
              exit={handleExit}
            />
          </>
        )}
    </StyledNav>
  );
};

export default Nav;
