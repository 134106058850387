import React from 'react';

import { StyledDataTable, StyledImg } from '../../../../components/data-table/data-table-styles';
import { ConfirmDialog, IconButton } from '../../../../components';

import limitedText from '../../../../utils/limited-text';
import { TPostState } from '../../../../types/TLocationPost';
import { postStates } from '../../helpers';

const noImage = `${process.env.PUBLIC_URL}/images/missing-image.png`;

type TPostTable = {
  data: any;
  onDeleteClicked: (element: any) => void;
  onUpdateClicked: (element: any) => void;
}

const translatePostNameType = (post:string) => {
  if (post === 'OFFER') return 'Oferta';
  if (post === 'ALERT') return 'Alerta Covid-19';
  if (post === 'EVENT') return 'Evento';
  if (post === 'STANDARD') return 'Novidades';
  return 'Desconhecido';
};

const PostTable = ({
  data,
  onDeleteClicked,
  onUpdateClicked,
}:TPostTable) => {
  const [, setDataRender] = React.useState(null);

  const [dialogDataTable, setDialogDataTable] = React.useState({
    open: false,
    onConfirm: () => {},
  });

  React.useEffect(() => {
    setDataRender(data);
  }, [data]);

  const closeDialog = () => setDialogDataTable(prevState => ({ ...prevState, open: false }));

  const columns = [
    {
      name: 'Mídia',
      options: {
        customBodyRender: (value: any) => (
          value
            ? <StyledImg src={value[0].googleUrl} width="100rem" />
            : <StyledImg src={noImage} width="100rem" />
        ),
        sort: false,
      },
    },
    {
      name: 'Conteúdo',
      options: {
        sort: false,
        customBodyRender: (value:string) => (
          value ? limitedText(value, 50) : null
        ),
      },
    },
    {
      name: 'Status',
      options: {
        sort: false,
        customBodyRender: (value:string) => (
          <span className={`post-states ${value}`}>{postStates[value as TPostState]}</span>
        ),
      },
    },
    {
      name: 'Data de Criação',
      options: {
        customBodyRender: (value:string) => (
          value ? new Date(value).toLocaleDateString() : null
        ),
        sort: true,
      },
    },
    {
      name: 'Tipo de Postagem',
      options: {
        display: false,
      },
    },
    {
      name: 'Tipo de Action',
      options: {
        display: false,
      },
    },
    {
      name: 'Horários',
      options: {
        display: false,
      },
    },
    {
      name: 'Id',
      options: {
        display: false,
      },
    },
    {
      name: 'Tipo',
      options: {
        customBodyRender: (value:string) => (
          translatePostNameType(value)
        ),
      },
    },
    {
      name: 'Name',
      options: {
        display: false,
      },
    },
    {
      name: 'Operações',
      options: {
        filter: true,
        sort: false,
        empty: true,
        customBodyRender: (value:any, meta:any) => (
          <IconButton
            tooltip="Editar"
            icon="Create"
            onClick={() => onUpdateClicked(meta.rowData[9])}
          />
        ),
      },
    },
  ];

  const tableData = data;

  const options:any = {
    search: false,
    filter: false,
    print: false,
    download: false,
    viewColumns: false,
    pagination: false,
    onRowsDelete(rows:any) {
      const onDeleteRows = () => {
        rows.data.map(({ dataIndex }: any) => {
          if (data[dataIndex].id) {
            onDeleteClicked(data[dataIndex]);
            return;
          }

          onDeleteClicked(data[dataIndex]);
        });

        closeDialog();
      };

      setDialogDataTable(prevState => ({
        ...prevState,
        open: true,
        onConfirm: onDeleteRows,
      }));
    },
  };

  return (
    <>
      <ConfirmDialog
        open={dialogDataTable.open}
        onClose={closeDialog}
        title="Confirmar exclusão"
        onCancel={closeDialog}
        onConfirm={dialogDataTable.onConfirm}
        confirmTitle="Deletar"
        confirmType="red"
      >
        Deseja mesmo excluir esta postagem ?
      </ConfirmDialog>
      <StyledDataTable
        title="Postagens"
        data={tableData}
        columns={columns}
        options={options}
      />
    </>
  );
};

export default PostTable;
