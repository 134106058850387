import React from 'react';
import { useDispatch } from 'react-redux';

import { Creators as CompanyActions } from '../../redux/ducks/company';
import { Creators as LocationGroupActions } from '../../redux/ducks/location-group';

import DropDownDialog from '../dropdown-dialog';

import { useCompany } from '../../hooks';

const DropdownCompaniesDialog = ({ overlayLoadingNoDisplay, shouldDisableOptionForLocationGuest = false }) => {
  const reduxDispatch = useDispatch();

  const {
    userCompanies,
    activeCompany,
    userCompaniesPageIndex,
    userCompaniesPageQuantity,
    setUserCompaniesPageQuery,
    setUserCompaniesPageIndex,
  } = useCompany();

  const [isLoading, setIsLoading] = React.useState(false);

  React.useEffect(() => {
    reduxDispatch(LocationGroupActions.setShowLocationsByGroup(false));
    reduxDispatch(CompanyActions.setShowLocationsByCompany(true));
  }, []);

  const handleChangeCompany = async companyId => {
    setIsLoading(true);
    const newActiveCompany = userCompanies.filter(companyItem => companyItem.id === companyId)[0];

    reduxDispatch(CompanyActions.setActiveCompany(newActiveCompany));
    reduxDispatch(CompanyActions.setActiveCompanyId(newActiveCompany.id));

    setTimeout(() => {
      setIsLoading(false);
    }, 1500);
  };

  return (
    <DropDownDialog
      options={userCompanies}
      currentOption={activeCompany}
      companyType
      icon="Business"
      title="Empresas"
      onChange={handleChangeCompany}
      optionDisableIfNotHaveLocations
      page={userCompaniesPageIndex}
      setPage={setUserCompaniesPageIndex}
      pageQuantity={userCompaniesPageQuantity}
      setQuery={setUserCompaniesPageQuery}
      isLoading={isLoading}
      showPagination
      overlayLoadingNoDisplay={overlayLoadingNoDisplay}
      shouldDisableOptionForLocationGuest={shouldDisableOptionForLocationGuest}
    />
  );
};

export default DropdownCompaniesDialog;
