import * as R from 'ramda';

type TFieldsName = {
  [key: string]: any;
}

export const fieldsName: TFieldsName = {
  name: { order: 1, name: 'Nome' },
  phone: { order: 2, name: 'Telefone Principal' },
  secondary_phone: { order: 3, name: 'Telefone Secundário' },
  google_categories: { order: 4, name: 'Categorias do Google' },
  facebook_categories: { order: 5, name: 'Categorias do Facebook' },
  foursquare_categories: { order: 6, name: 'Categorias do Foursquare' },
  zip_code: { order: 7, name: 'CEP' },
  country_code: { order: 8, name: 'País' },
  state_code: { order: 9, name: 'Código do Estado' },
  city: { order: 10, name: 'Cidade' },
  neighborhood: { order: 11, name: 'Bairro' },
  address1: { order: 12, name: 'Logradouro' },
  address2: { order: 13, name: 'Complemento' },
  does_not_serve_at_this_address: { order: 14, name: 'Atende nesse endereço?' },
  special_dates: { order: 15, name: 'Datas especiais' },
  working_hours: { order: 16, name: 'Horário de funcionamento' },
  email: { order: 17, name: 'Email' },
  website: { order: 18, name: 'Website' },
  google: { order: 19, name: 'Google' },
  facebook: { order: 20, name: 'Facebook' },
  foursquare: { order: 21, name: 'Foursquare' },
  instagram: { order: 22, name: 'Instagram' },
  linkedin: { order: 23, name: 'LinkedIn' },
  youtube: { order: 24, name: 'Youtube' },
  long_description: { order: 26, name: 'Descrição' },
  short_description: { order: 27, name: 'Descrição curta' },
  yelp: { order: 28, name: 'Yelp' },
  twitter: { order: 29, name: 'Twitter' },
  other_links: { order: 25, name: 'Outros links' },
  menu: { order: 30, name: 'Cardápio' },
  reservations: { order: 31, name: 'Reservas' },
  advanceorder: { order: 32, name: 'Pedido antecipado' },
  schedule: { order: 33, name: 'Agendamento' },
  store_code: { order: 34, name: 'Código do Local' },
  google_service_area: { order: 35, name: 'Área de Cobertura' },
};

export const splitDataIntoSections = array => array.reduce((accumulator, fieldData) => {
  (accumulator[fieldData.category.toLowerCase()] = accumulator[fieldData.category.toLowerCase()] || []).push(fieldData);
  return accumulator;
}, {});

export const normalizeWorkingHoursData = (workingHoursData) => {
  const workingHoursDataMerged = R.mergeAll(
    workingHoursData.map((workingHoursDay) => ({
      [workingHoursDay.day_title || workingHoursDay.dayTitle]: workingHoursDay,
    })),
  );

  return Object.values(workingHoursDataMerged);
};

export const formatDataToList = (dataToConvert) => {
  if (!dataToConvert) return [];

  let otherLinks: any[] = [];

  const rawOtherLinks = dataToConvert.filter((field) => field.label === 'OTHER_LINKS')[0];
  const splitOtherLinks = (array) => {
    const parsedValue = JSON.parse(array.filter((field) => field.label === 'OTHER_LINKS')[0].new_data.value);

    const filtered = Object.keys(parsedValue).reduce((res, key) => (res[key] = parsedValue[key], res), {});

    return Object.entries(filtered).map(([key, data]) => ({
      label: key,
      status: rawOtherLinks.status,
      order: fieldsName[key.toLowerCase()].order,
      new_data: { label: key, value: data },
    }));
  };

  const result = dataToConvert.filter((field) => field.label !== 'OTHER_LINKS').map((field, index) => {
    let fieldOrder = index;
    let { value } = field.new_data;
    const { status, label } = field;

    const categories = ['GOOGLE_CATEGORIES', 'FACEBOOK_CATEGORIES', 'FOURSQUARE_CATEGORIES'];

    if (categories.includes(label)) {
      const names: string[] = [];
      const parsedValue = JSON.parse(value);
      parsedValue.forEach((valueArrayItem) => {
        if (valueArrayItem.name) names.push(valueArrayItem.name);
      });
      value = names.join(', ');
    }

    if (label === 'GOOGLE_SERVICE_AREA') {
      value = R.isNil(value) ? '' : value.places.placeInfos.map((placeInfo) => placeInfo.placeName).join('  ||  ');
    }
    if (fieldsName[label.toLowerCase()]) {
      fieldOrder = fieldsName[label.toLowerCase()].order;
    }

    return {
      label,
      order: fieldOrder,
      new_data: { label, value },
      status,
    };
  });

  if (rawOtherLinks) {
    otherLinks = splitOtherLinks(dataToConvert);
    return [...result, ...otherLinks];
  }

  return result;
};

export const mockData = {
  infoBasic: [
    { name: 'secondary_phone', value: '(00) 0000-0000', status: 'PROCESSING' },
    { name: 'long_description', value: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Distinctio tempora possimus voluptates rerum at dolorum.', status: 'APPROVED' },
    { name: 'google_categories', value: 'Lorem ipsum dolor sit amet consectetur adipisicing elit.', status: 'REJECTED' },
    { name: 'short_description', value: 'Lorem ipsum dolor sit amet consectetur adipisicing elit.', status: 'APPROVED' },
    { name: 'facebook_categories', value: 'Lorem ipsum dolor sit amet.', status: 'APPROVED' },
  ],
  links: [
    { name: 'yelp', value: 'www.loremipsum.com.br', status: 'APPROVED' },
    { name: 'twitter', value: 'www.loremipsum.com.br', status: 'APPROVED' },
  ],
  schedule: [],
  special_dates: [],
};
