import { createActions, createReducer } from 'reduxsauce';

export const { Types, Creators } = createActions({
  setUser: ['user'],
  setUserCompanies: ['userCompanies'],
  setUserCompanyGroups: ['userCompanyGroups'],
  setAllCompanies: ['allCompanies'],
  setAllCompanyGroups: ['allCompanyGroups'],
  setLanguage: ['language'],
  setGuestLocations: ['guestLocations'],
});

const initialState = {
  user: null,
  userCompanyGroups: null,
  userCompanies: null,
  allCompanies: null,
  language: 'pt_BR',
  guestLocations: null,
};

const reduxOnSetUser = (
  state = initialState, { user },
) => ({ ...state, user });

const reduxOnSetUserCompanyGroups = (
  state = initialState, { userCompanyGroups },
) => ({ ...state, userCompanyGroups });

const reduxOnSetUserCompanies = (
  state = initialState, { userCompanies },
) => ({ ...state, userCompanies });

const reduxOnSetAllCompanies = (
  state = initialState, { allCompanies },
) => ({ ...state, allCompanies });

const reduxOnSetAllCompanyGroups = (
  state = initialState, { allCompanyGroups },
) => ({ ...state, allCompanyGroups });

const reduxOnSetLanguage = (
  state = initialState, { language },
) => ({ ...state, language });

const reduxOnSetGuestLocations = (
  state = initialState, { guestLocations },
) => ({ ...state, guestLocations });

export default createReducer(initialState, {
  [Types.SET_USER]: reduxOnSetUser,
  [Types.SET_USER_COMPANIES]: reduxOnSetUserCompanies,
  [Types.SET_USER_COMPANY_GROUPS]: reduxOnSetUserCompanyGroups,
  [Types.SET_ALL_COMPANIES]: reduxOnSetAllCompanies,
  [Types.SET_ALL_COMPANY_GROUPS]: reduxOnSetAllCompanyGroups,
  [Types.SET_LANGUAGE]: reduxOnSetLanguage,
  [Types.SET_GUEST_LOCATIONS]: reduxOnSetGuestLocations,
});
