import React from 'react';
import * as R from 'ramda';
import { debounce } from 'lodash';

import {
  StyledSelectDropdownContainer,
  StyledSelectDropdown,
  StyledChevronDown,
  StyledPopover,
  StyledOptionsList,
  StyledOption,
  StyledSubLabelText,
} from './select-dropdown-styles';

import type { TSelectDropdownBaseProps } from '../../types/TSelectDropdown';

import Input from '../input';
import Pagination from '../pagination';

import limitedText from '../../utils/limited-text';
import Loading from '../loading';

const SelectDropdown = ({
  className,
  onOptionClicked,
  data,
  setQuery,
  textKey = 'name',
  idKey = 'id',
  setPage,
  page = 0,
  pageQuantity = 10,
  isLoading = false,
  searchLabel = 'Buscar Local',
  selectedOptionsHidden = false,
  hasSubLabel = false,
  subLabelKey = 'address1',
}: TSelectDropdownBaseProps) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [selectedOption, setSelectedOption] = React.useState('Seleciona opção');

  const open = Boolean(anchorEl);

  const id = open ? 'simple-popover' : undefined;

  React.useEffect(() => {
    setQuery('');
  }, []);

  const handleSearch = debounce((text) => {
    setPage(0);
    setQuery(text);
  }, 500);

  const handleCloseSelectDropdown = () => {
    setAnchorEl(null);
    setQuery('');
  };

  const handleOnClick = (element: any) => {
    handleCloseSelectDropdown();
    onOptionClicked(element);
    setSelectedOption(element.name);
  };

  return (
    <StyledSelectDropdownContainer>
      <StyledSelectDropdown
        className={className}
        aria-describedby={id}
        onClick={(event: any) => setAnchorEl(event.currentTarget)}
      >
        {selectedOptionsHidden ? (
          searchLabel
        ) : (
          <span>{limitedText(selectedOption, 30)}</span>
        )}
        <StyledChevronDown />
      </StyledSelectDropdown>

      <StyledPopover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleCloseSelectDropdown}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <Input
          border
          onChange={(event) => handleSearch(event.target.value)}
          label={searchLabel}
          inputOptions={{ type: 'text', name: 'search-location' }}
          className="input-search"
        />

        {isLoading ? (
          <Loading className="loading-data" />
        ) : (
          <>
            {!R.isEmpty(data) && !R.isNil(data) && (
              <StyledOptionsList>
                {data.map((element: any) => (
                  <StyledOption
                    key={element[idKey]}
                    onClick={() => handleOnClick(element)}
                  >
                    <>
                      {element[textKey]}
                      {hasSubLabel && <StyledSubLabelText>{element[subLabelKey]}</StyledSubLabelText>}
                    </>
                  </StyledOption>
                ))}
              </StyledOptionsList>
            )}

            {pageQuantity > 0 && (
              <Pagination
                className="dropdown-popover-pagination"
                page={page}
                setPage={setPage}
                pagesQuantity={pageQuantity}
              />
            )}
          </>
        )}
      </StyledPopover>
    </StyledSelectDropdownContainer>
  );
};

export default SelectDropdown;
