import React, { useCallback } from 'react';
import * as R from 'ramda';
import { useHistory } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { useDispatch, useSelector } from 'react-redux';
import JSFileDownloader from 'js-file-downloader';

import APICreateBatchCompanies from '../../services/companies/createBatchCompanies';
import getUserCompanies from '../../services/companies/getUserCompanies';
import { Creators as AuthActions } from '../../redux/ducks/auth';
import { Creators as CompanyActions } from '../../redux/ducks/company';

import SpreadSheetModelCompany from '../../assets/planilha-minhas-empresa.xlsx';

import { useAuth } from '../../hooks';

import {
  HeaderToolbar,
  BlockWithActionButton,
  CompanyInfo,
  BatchCreation,
  BatchCreationDialog,
  BatchCreationEnable,
  HealderToolbarAction,
  ChooseUserDialog,
} from '../../components';

import { saveCompanyInDB } from './helpers';

import {
  StyledCreateCompany,
  StyledButtonsGroup,
  StyledContentMain,
  StyledContentAside,
  StyledContent,
} from './create-company-styles';
import isNilOrEmpty from '../../utils/is-null-or-empty';
import { APICreateResponsible } from '../../services/responsibles';

const CreateCompany = () => {
  const {
    push: pushToScreen,
  } = useHistory();

  const reduxDispatch = useDispatch();

  const { user } = useSelector(state => state.AuthReducer);

  const {
    accessToken: userHublocalToken,
    user: loggedUser,
    user: { id: userHublocalID, profiles },
  } = user || {};

  const { name: userProfileType } = profiles[0] || {};

  const { enqueueSnackbar } = useSnackbar();

  const { userProfileName, userCompanyLimit, userAccessToken } = useAuth();

  const companyInfoData = React.useRef(null);

  const [isCreating, setIsCreating] = React.useState(false);
  const [isCreateBatch, setIsCreateBatch] = React.useState(false);
  const [isBatchCreation, setIsBatchCreation] = React.useState(false);
  const [openImportModal, setOpenImportModal] = React.useState(false);

  const [openChooseUserDialog, setOpenChooseUserDialog] = React.useState(false);

  const [userToOwnerCompany, setUserToOwnerCompany] = React.useState(null);

  const setTokenLikeExpired = () => {
    reduxDispatch(AuthActions.setUser({ ...user, accessToken: 'expired' }));
  };

  const setActiveCompanyWithNewCompany = newCompany => {
    reduxDispatch(CompanyActions.setActiveCompany(newCompany));
  };

  const urlValidation = (url) => {
    if ((url.includes('https://datastudio.google.com/u/0/reporting/')
    || url.includes('https://datastudio.google.com/reporting/'))
    ) return true;

    return false;
  };

  const handleCreateResponsible = useCallback(async () => {
    const user = isNilOrEmpty(userToOwnerCompany) ? loggedUser : userToOwnerCompany;

    const { name, phone, email } = user;

    const newResponsible = await APICreateResponsible({
      accessToken: userHublocalToken,
      email,
      feedbackMessage: enqueueSnackbar,
      name,
      phone: phone || '11 11111-1111',
      setTokenLikeExpired,
      created_by: userHublocalID,
    });

    newResponsible.main = true;

    return newResponsible;
  }, [userToOwnerCompany]);

  const getDataToCreateCompany = () => {
    const { company } = companyInfoData.current;

    const companyChecks = company.map(companyField => companyField.firedCheck);
    const companyResets = company.map(companyField => companyField.reset);
    const companyValues = company.map(companyField => companyField.getValue());

    const companyData = {
      name: companyValues[0],
      website: companyValues[1],
      is_active: companyValues[2],
      data_studio: companyValues[3],
    };

    return {
      companyChecks,
      companyResets,
      companyValues,
      companyData,
    };
  };

  const handleSave = React.useCallback(async () => {
    setOpenChooseUserDialog(false);

    const {
      companyResets,
      companyData,
    } = getDataToCreateCompany();

    const newResponsible = await handleCreateResponsible();
    const responsibles = [newResponsible];

    if (userProfileName !== 'Usuário Padrão' && !R.isEmpty(companyData.data_studio)) {
      if (!urlValidation(companyData.data_studio)) {
        enqueueSnackbar('O formato do link de Data Studio está inválido', { variant: 'warning' });
        return;
      }
    }

    const [, amount] = await getUserCompanies({ userId: userHublocalID, accessToken: userAccessToken });

    if (amount === userCompanyLimit) {
      enqueueSnackbar('Você já atingiu o limite de empresas disponível', { variant: 'error' });
      return;
    }
    const userId = isNilOrEmpty(userToOwnerCompany) ? userHublocalID : userToOwnerCompany.id;

    saveCompanyInDB({
      companyData,
      responsibles,
      setIsCreating,
      setTokenLikeExpired,
      userHublocalToken,
      userHublocalID: userId,
      feedbackMessage: enqueueSnackbar,
      resetCompanyFields: () => companyResets.map(companyReset => companyReset()),
      redirectTo: () => pushToScreen('/my-locations'),
      setActiveCompanyWithNewCompany,
    });
  }, [companyInfoData, userToOwnerCompany]);

  const handleOpenChooseUser = () => {
    const {
      companyChecks,
    } = getDataToCreateCompany();

    if (companyChecks.every(companyFiredCheck => companyFiredCheck())) {
      if (userProfileName !== 'Usuário Padrão') {
        setOpenChooseUserDialog(true);
      } else {
        handleSave();
      }
    }
  };

  const handlePushToMyCompanies = () => pushToScreen('/my-companies');

  const handleCreateBatchCompanies = async (file) => {
    if (!file) {
      enqueueSnackbar('Nenhum arquivo foi inserido', { variant: 'warning' });
      return;
    }

    const createBatchResponse = await APICreateBatchCompanies({
      accessToken: userHublocalToken,
      companyFile: file,
      feedbackMessage: enqueueSnackbar,
      setTokenLikeExpired,
      userId: userHublocalID,
      setIsCreatingCompanies: setIsCreateBatch,
    });

    if (createBatchResponse.status === 'OK') setOpenImportModal(false);
  };

  const downloadSpreadsheetModel = async () => {
    new JSFileDownloader({
      url: SpreadSheetModelCompany,
      filename: 'modelo-minhas-empresas.xlsx',
    });
  };

  return (
    <StyledCreateCompany>
      <HeaderToolbar
        title="Adicionar empresa"
      >
        <StyledButtonsGroup>
          <HealderToolbarAction
            title="Lista de empresas"
            icon="List"
            onClick={handlePushToMyCompanies}
          />
        </StyledButtonsGroup>
      </HeaderToolbar>
      <StyledContent>
        <StyledContentMain>
          {isBatchCreation ? (
            <BatchCreation
              title="Criar várias empresas"
              instructionTitle="Instruções para criação de várias empresas"
              className="batch-creation"
              downloadSpreadsheetModel={downloadSpreadsheetModel}
              handleImportModal={() => setOpenImportModal(!openImportModal)}
              userProfileType={userProfileType}
            />
          ) : (
            <>
              <CompanyInfo
                ref={companyInfoData}
                className="block-form"
                title="Informações básicas da empresa"
                userProfileType={userProfileType}
              />
            </>
          )}
        </StyledContentMain>

        <StyledContentAside>
          <BlockWithActionButton
            onClick={handleOpenChooseUser}
            titleButton="Criar empresa"
            loading={isCreating}
          />
          <BatchCreationEnable
            isBatchCreation={isBatchCreation}
            title="Você possui mais de uma empresa?"
            disclaimer="Caso possua mais de uma empresa você pode automatizar o processo de criação"
            titleButton="Automatizar"
            onClick={() => setIsBatchCreation(!isBatchCreation)}
          />
        </StyledContentAside>
      </StyledContent>

      {openImportModal && (
        <BatchCreationDialog
          open={openImportModal}
          handleOnClose={() => setOpenImportModal(!openImportModal)}
          handleSubmit={handleCreateBatchCompanies}
          isSubmit={isCreateBatch}
        />
      )}

      {openChooseUserDialog && (
        <ChooseUserDialog
          open={openChooseUserDialog}
          onClose={() => setOpenChooseUserDialog(false)}
          onCancel={handleSave}
          onConfirm={handleSave}
          selectedUser={userToOwnerCompany}
          setSelectedUser={setUserToOwnerCompany}
          disclaimer="Deseja selecionar outro usuário como dono dessa empresa?"
        />
      )}
    </StyledCreateCompany>
  );
};

export default CreateCompany;
