import axios, { AxiosResponse } from 'axios';
import type {
  TBatchGalleryLocation,
  TFindOneBatchGallery,
} from '../../../types/TBatchGallery';

export const findOneBatchGalleryLocation = async ({
  batchGalleryId,
  isFetching,
  userAccessToken,
}: TFindOneBatchGallery): Promise<{
  status: 'SUCCESS' | 'ERROR';
  data: TBatchGalleryLocation | object;
}> => {
  try {
    if (isFetching) isFetching(true);

    const { data }: AxiosResponse<TBatchGalleryLocation> = await axios.get(
      `${process.env.REACT_APP_SAAS_BATCH_GOOGLE_MODULES}/gallery-batch-locations/${batchGalleryId}`,
      {
        headers: {
          Authorization: `Bearer ${userAccessToken}`,
        },
      }
    );
    return {
      status: 'SUCCESS',
      data,
    };
  } catch (err: any) {
    return {
      status: 'ERROR',
      data: err.response,
    };
  } finally {
    if (isFetching) isFetching(false);
  }
};
