const AddImageProcess = (imageSourcePath: string):
  Promise<{
  imageWidth: number,
  imageHeight: number
}> => new Promise((resolve, reject) => {
  const img = new Image();

  img.onload = () => resolve({
    imageHeight: img.height,
    imageWidth: img.width,
  });

  img.onerror = reject;
  img.src = imageSourcePath;
});

export default AddImageProcess;
