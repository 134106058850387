import styled from 'styled-components';
import { Input } from '..';

export const StyledInputWithIcon = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  border: 1px solid black;
`;

export const StyledInput = styled(Input)`
  width: 100%;
  margin: 0;
  border: none;
`;

export const StyledIconSearchButton = styled.div`
  cursor: pointer;
  padding: 1rem;
`;
