import styled, { css } from 'styled-components';
import { ArrowDropDown } from '@material-ui/icons';

import { lighten } from 'polished';

import { TSelectBaseProps } from '../../types/TSelect';

type TStyledSelect = Pick<TSelectBaseProps<{ }>, 'border' | 'lightBorder' | 'borderRadius' | 'noMargin'>;

export const StyledSelect = styled.div<TStyledSelect>`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  margin-bottom: 2rem;

  &:last-of-type {
    margin-bottom: 0;
  }

  label {
    font-size: 1.5rem;
    font-weight: normal;
    color: ${props => props.theme.colors.black};
  }

  select {
    width: 100%;
    height: 4.1rem;
    padding: 0 1.5rem;
    border: none;
    background: ${(props) => props.theme.colors.white};
    appearance: none;
    padding-right: 4.5rem;
    
    ${(props) => props.border
      && css`
        border: 2px solid ${lighten(0.4, props.theme.colors.black)};
      `};

    ${(props) => props.lightBorder
      && css`
        border: 2px solid ${lighten(0.3, props.theme.colors.grey)};
      `};

    ${(props) => props.borderRadius
      && css`
        border-radius: 0.4rem;
      `};
  }

  ${(props) => props.noMargin
      && css`
        margin-bottom: 0;
      `};
`;

export const StyledChevronDown = styled(ArrowDropDown)`
  position: absolute;
  right: 1.5rem;
  width: 2.5rem;
  height: 2.5rem;
`;

export const StyledSelectWrapper = styled.div`
  width: 100%;
  position: relative;
  display: flex;
  align-items: center;
`;

export const StyledInputValidate = styled.span<{ type: string }>`
  margin-top: 0.3rem;
  font-size: 1rem;
  font-weight: 500;
  text-transform: uppercase;

  ${(props) => props.type === 'error'
    && css`
      color: ${props.theme.colors.red};
    `};
`;
