import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { isNil } from 'ramda';

import { isEmpty } from 'lodash';
import APIGetLocationsGroup from '../../services/locations/getAllGroupLocation';

import { Creators as LocationsGroup } from '../../redux/ducks/location-group';
import { Creators as LocationActions } from '../../redux/ducks/location';

import { useAuth } from '..';
import { isLocationFromGroup } from './helpers';

import type { TRootStateRedux } from '../../types/TRootStateRedux';
import type { TUseLocationsGroup } from '../../types/TUseLocationsGroup';

const useLocationsGroup = ({ isLocationDropDown = false }: TUseLocationsGroup) => {
  const reduxDispatch = useDispatch();

  const {
    userAccessToken,
    userSetTokenLikeExpired,
  } = useAuth();

  const {
    activeLocationGroupId,
    activeLocationGroup,
    showLocationsByGroup,
  } = useSelector((state: TRootStateRedux) => state.LocationGroupReducer);

  const {
    activeLocation,
  } = useSelector((state: TRootStateRedux) => state.LocationReducer);

  const [locationsGroup, setLocationsGroup] = React.useState<any[]>([]);
  const [pageIndex, setPageIndex] = React.useState(0);
  const [pageSize, setPageSize] = React.useState(10);
  const [pageQuery, setPageQuery] = React.useState('');
  const [pageQuantity, setPageQuantity] = React.useState(0);
  const [isLoading, setIsLoading] = React.useState(false);
  const [currentActiveLocation, setCurrentActiveLocation] = React.useState<any>(null);

  const resetActivesAsNull = () => {
    reduxDispatch(LocationActions.setActiveLocation(null));
    reduxDispatch(LocationsGroup.setActiveLocationGroup(null));
  };

  const getLocationsGroup = React.useCallback(async () => {
    if (!showLocationsByGroup) return;

    if (isNil(activeLocationGroupId) || isNil(activeLocationGroup)) {
      resetActivesAsNull();
      return;
    }

    const locationsGroupResponse = await APIGetLocationsGroup({
      accessToken: userAccessToken,
      locationGroupId: activeLocationGroupId,
      setTokenLikeExpired: userSetTokenLikeExpired,
      page: pageIndex,
      pageSize,
      query: pageQuery,
      setIfFetching: setIsLoading,
    });

    if (isNil(locationsGroupResponse)) {
      resetActivesAsNull();
      return;
    }

    const [locationsGroupData, locationsGroupDataCount] = locationsGroupResponse;

    setLocationsGroup(locationsGroupData);
    setPageQuantity(Math.ceil(locationsGroupDataCount / pageSize));

    if (isNil(activeLocation)) {
      return;
    }

    const currentActiveLocationFromRedux = isLocationFromGroup({ activeLocationGroup, activeLocation });

    if (isNil(currentActiveLocationFromRedux)) {
      if (!showLocationsByGroup && isLocationDropDown) return;

      setCurrentActiveLocation(locationsGroupData[0]);
      reduxDispatch(LocationActions.setActiveLocation(locationsGroupData[0]));

      return;
    }

    setCurrentActiveLocation(currentActiveLocationFromRedux);
  }, [
    activeLocationGroup,
    activeLocationGroupId,
    pageQuery,
    pageIndex,
    showLocationsByGroup,
  ]);

  React.useEffect(() => { getLocationsGroup(); }, [getLocationsGroup]);

  return {
    locationsGroup,
    setPageQuantity,
    setIsLoading,
    setPageQuery,
    setPageSize,
    pageSize,
    pageIndex,
    pageQuantity,
    pageQuery,
    setPageIndex,
    isLoading,
    activeLocation,
    activeLocationGroup,
    activeLocationGroupId,
    currentActiveLocation,
    setCurrentActiveLocation,
  };
};

export default useLocationsGroup;
