import React from 'react';

import { ReviewsProvider } from '../../contexts/reviews';

import Reviews from './reviews';

const ReviewsContainer = () => (
  <ReviewsProvider>
    <Reviews />
  </ReviewsProvider>
);

export default ReviewsContainer;
