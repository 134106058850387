import React from 'react';

import { TLocationMediaItem } from '../../../../../../types/TBatchGallery';

import {
  StyledCheckbox,
  StyledMediaWrapper,
  StyledCreationDate,
  StyledInfoAndActionsWrapper,
  StyledImage,
  StyledVideo,
} from './media-item-styles';

const MediaItem = ({
  item,
  selected,
  setSelected,
  creationDate,
  lockedCheckboxes,
  batchLocationStatus,
}: TLocationMediaItem) => {
  const [checked, setChecked] = React.useState(false);

  const handleSetSelected = (itemTarget: any) => {
    if (!checked) {
      setSelected((prevState: any) => [...prevState, itemTarget]);
      return;
    }

    if (checked) {
      setSelected((prevState: any) => prevState.filter((media: any) => media !== itemTarget));
    }
  };

  React.useEffect(() => {
    const checkItemHasInSelecteds = Boolean(
      selected.find((itemSelected: any) => itemSelected === item.id),
    );

    if (checkItemHasInSelecteds) {
      setChecked(true);
      return;
    }

    if (!checkItemHasInSelecteds) {
      setChecked(false);
    }
  }, [selected, item]);

  return (
    <li key={item.id}>
      <StyledMediaWrapper>
        <>
          {item.mediaFormat === 'VIDEO' && (
          <StyledVideo
            deleted={item.status !== 'PUBLISHED'}
          >
            <source src={item.thumbnailUrl} type="video/mp4" />
            <source src={item.thumbnailUrl} type="video/ogg" />
            Your browser does not support HTML video
          </StyledVideo>
          )}

          {item.mediaFormat === 'PHOTO' && (
            <>
              <StyledImage
                src={item.thumbnailUrl}
                deleted={item.status !== 'PUBLISHED'}
              />
            </>
          )}
        </>

        <StyledInfoAndActionsWrapper>
          {
            item.status === 'PUBLISHED' &&
            batchLocationStatus !== 'PROCESSING' &&
            batchLocationStatus !== 'PENDING' && (
              <StyledCheckbox
                checked={checked}
                onChange={() => handleSetSelected(item.id)}
                disabled={lockedCheckboxes}
              />
            )
          }

          <StyledCreationDate>
            Data de Criação:
            <br />
            {creationDate}
          </StyledCreationDate>
        </StyledInfoAndActionsWrapper>

      </StyledMediaWrapper>
    </li>
  );
};
export { MediaItem };
