import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory, Redirect } from 'react-router-dom';
import { Button, SvgIcon } from '@material-ui/core';
import * as Icons from '@material-ui/icons';
import * as R from 'ramda';

import {
  Can,
  DataTable,
} from '../../components';

import getGroups from '../../services/groups/getGroups';
import deleteGroup from '../../services/groups/deleteGroup';

import './GroupList.css';

const GroupList = () => {
  const history = useHistory();
  const [groups, setGroups] = useState([]);
  const [loading, setLoading] = useState(false);
  const [searchQuery, setSearchQuery] = React.useState('');
  const [page, setPage] = React.useState(0);
  const [pageSize, setPageSize] = React.useState(10);
  const [dataCount, setDataCount] = React.useState(0);

  const { user } = useSelector((state) => state.AuthReducer);

  const columns = [
    { name: 'Id', options: { display: false, sort: false } },
    { name: 'Nome', options: { sort: false } },
    {
      name: 'Ícone',
      options: {
        sort: false,
        customBodyRender(value) {
          return <SvgIcon component={Icons[value]} />;
        },
      },
    },
    {
      name: 'Funções',
      options: {
        sort: false,
        customBodyRender: functionValue => (
          <div className="items-table-cell">
            {functionValue.map((element) => (
              <span key={element.id}>{`- ${element.full_name}`}</span>
            ))}
          </div>
        ),
      },
    },
    { name: 'Data da Criação', options: { sort: false } },
    { name: 'Data da última atualização', options: { sort: false } },
    { name: 'Criado por', options: { sort: false } },
  ];

  const getGroupListing = React.useCallback(async () => {
    const groupsResponse = await getGroups({
      accessToken: user.accessToken,
      page,
      pageSize,
      searchQuery,
      isGetgroups: setLoading,
    });

    if (R.isNil(groupsResponse)) return;

    const [groupsListing, groupCount] = groupsResponse;

    const groupsNormalized = groupsListing.map(group => [
      group.id,
      group.name,
      group.icon,
      group.functions,
      group.date_created,
      group.date_updated,
      group.created_by,
    ]);

    setGroups(groupsNormalized);
    setDataCount(groupCount);
  }, [searchQuery, page, pageSize]);

  React.useEffect(() => {
    getGroupListing();
  }, [getGroupListing]);

  const onAddGroupClicked = () => {
    history.push('/groups/create');
  };

  const onUpdateGroupClicked = (id) => {
    history.push(`/groups/update/${id}`);
  };

  const onDeleteGroupClicked = async (id) => {
    const deleteResponse = await deleteGroup(user.accessToken, id);
    if (deleteResponse) getGroupListing();
  };

  return (
    <div style={{ padding: '1em' }}>
      <Can
        perform="Groups/List"
        yes={() => (
          <>
            <Can
              perform="Groups/Create"
              yes={() => (
                <div className="create-function-button">
                  <Button
                    variant="contained"
                    startIcon={<SvgIcon component={Icons.Add} />}
                    onClick={onAddGroupClicked}
                    style={{
                      marginBottom: '1em',
                      color: 'white',
                      backgroundColor: '#31ab70',
                      fontFamily: 'Poppins',
                      fontWeight: 'bold',
                    }}
                  >
                    Adicionar Grupo
                  </Button>
                </div>
              )}
              no={() => <></>}
            />
            <div>
              <DataTable
                count={dataCount}
                page={page}
                setPage={setPage}
                pageSize={pageSize}
                setPageSize={setPageSize}
                searchQuery={searchQuery}
                setSearchQuery={setSearchQuery}
                title="Grupos"
                data={groups}
                loading={loading}
                columns={columns}
                onUpdateClicked={onUpdateGroupClicked}
                onDeleteClicked={onDeleteGroupClicked}
                addRegisterLabel="Adicionar Grupo"
                addRegisterLink="/groups/create"
              />
            </div>
          </>
        )}
        no={() => <Redirect to="/main" />}
      />
    </div>
  );
};

export default GroupList;
