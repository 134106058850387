import React from 'react';
import { Power as PowerIcon } from '@material-ui/icons';

import Loading from '../../../../../../loading';

import {
  StyledListingItem,
} from './listing-item-styles';

const ListingItem = ({
  id,
  name,
  image,
  description,
  handleSync,
  noImages = false,
}) => {
  const [isBindedPage, setIsBindedPage] = React.useState(false);

  return (
    <StyledListingItem>
      {!noImages && <img src={image || 'https://via.placeholder.com/600x600?text=Image'} alt={name || 'Alt Image'} />}
      <div className="infos-name-and-description">
        <h4>{name || ''}</h4>
        <span>{description}</span>
      </div>
      <span
        onClick={() => handleSync({ selectedPageId: id, setLoading: setIsBindedPage })}
        className="item-bind"
        aria-hidden="true"
      >
        {isBindedPage ? <Loading /> : (
          <>
            Vincular
            <PowerIcon />
          </>
        )}
      </span>
    </StyledListingItem>
  );
};

export default ListingItem;
