import { isNil } from 'ramda';
import React from 'react';
import { v4 as UUIDV4 } from 'uuid';
import SyncStatusIconMonitor from '../../../../../../../components/sync-status-icon-monitor';
import { StyledIconWrapper, StyledSpecialDatesDays, StyledSpecialDatesInfo } from './special-date-row-styles';

const SpecialDateRow = ({ status, saasData, platformData }) => {
  const dataIsArray = (data: any[] | string) => Array.isArray(data);
  const noDataIsArray = !dataIsArray(saasData) && !dataIsArray(platformData);
  const onlySaasDataIsArray = dataIsArray(saasData) && !dataIsArray(platformData);
  const onlyPlatformDataIsArray = !dataIsArray(saasData) && dataIsArray(platformData);
  const bothDatasAreArray = dataIsArray(saasData) && dataIsArray(platformData);

  const saasDataWithTag = dataIsArray(saasData) ? saasData.map((data) => ({ ...data, isSaasData: true })) : [];
  const platformDataWithTag = dataIsArray(platformData) ? platformData.map((data) => ({ ...data, isPlatformData: true })) : [];
  const mergedDataArray = [...saasDataWithTag, ...platformDataWithTag];
  const datasCoupledByDate: any[] = mergedDataArray.reduce((accumulator, data) => {
    (accumulator[data.date] = accumulator[data.date] || []).push(data);
    return accumulator;
  }, {});
  const sortedCoupledArrays = Object.values(datasCoupledByDate).map((coupleArray) => coupleArray.sort((a, b) => (a.isSaasData ? -1 : b.isSaasData ? 1 : 0)));

  const renderDoesNotExist = (className: string) => (
    <StyledSpecialDatesDays className={className}>
      <p>Não existe</p>
    </StyledSpecialDatesDays>
  );

  const renderIconStatus = () => (
    <StyledIconWrapper className="statusIcon">
      <SyncStatusIconMonitor syncType="monitor" status={status} />
    </StyledIconWrapper>
  );

  const renderTimes = (data) => (
    <p>
      {!data.isOpen && 'Fechado' }
      { data.isOpen && data.is24Hours && '24 Horas' }
      { data.isOpen && !data.is24Hours && `${data.times}` }
    </p>
  );

  const renderTimesComponent = (className: string, data: any) => (
    <StyledSpecialDatesDays className={className}>
      <li>
        <p className="title">{data.date}</p>

        {renderTimes(data)}
      </li>
    </StyledSpecialDatesDays>
  );

  return (
    <>
      {noDataIsArray && (
      <StyledSpecialDatesInfo
        key={UUIDV4()}
      >
        {renderDoesNotExist('saasField')}
        {renderDoesNotExist('platformField')}
        {renderIconStatus()}
      </StyledSpecialDatesInfo>
      )}

      {onlySaasDataIsArray && (
        saasData.map((data) => (
          <StyledSpecialDatesInfo
            key={UUIDV4()}
          >
            {renderTimesComponent('saasField', data)}
            {renderDoesNotExist('platformField')}
            {renderIconStatus()}

          </StyledSpecialDatesInfo>
        ))
      )}

      {onlyPlatformDataIsArray && (
        platformData.map((data) => (
          <StyledSpecialDatesInfo
            key={UUIDV4()}
          >
            {renderDoesNotExist('saasField')}
            {renderTimesComponent('platformField', data)}
            {renderIconStatus()}

          </StyledSpecialDatesInfo>
        ))
      )}

      {bothDatasAreArray && (
        sortedCoupledArrays.map((coupleArray) => {
          if (coupleArray.length > 1) {
            return (
              <StyledSpecialDatesInfo
                key={UUIDV4()}
              >
                <StyledSpecialDatesDays className="saasField">
                  <li>
                    <p className="title">{coupleArray[0].date}</p>

                    {renderTimes(coupleArray[0])}
                  </li>
                </StyledSpecialDatesDays>

                <StyledSpecialDatesDays className="platformField">
                  <li>
                    <p className="title">{coupleArray[1].date}</p>

                    {renderTimes(coupleArray[1])}
                  </li>
                </StyledSpecialDatesDays>
                <StyledIconWrapper className="statusIcon">
                  <SyncStatusIconMonitor syncType="monitor" status={status} />
                </StyledIconWrapper>
              </StyledSpecialDatesInfo>
            );
          }
          return (
            <StyledSpecialDatesInfo
              key={UUIDV4()}
            >
              <StyledSpecialDatesDays className="saasField">
                {isNil(coupleArray[0].isSaasData)
                  ? (
                    <p>
                      Não existe
                    </p>
                  )
                  : (
                    <li>
                      <p className="title">{coupleArray[0].date}</p>

                      {renderTimes(coupleArray[0])}
                    </li>
                  )}
              </StyledSpecialDatesDays>

              <StyledSpecialDatesDays className="platformField">
                {isNil(coupleArray[0].isPlatformData)
                  ? (
                    <p>
                      Não existe
                    </p>
                  )
                  : (
                    <li>
                      <p className="title">{coupleArray[0].date}</p>

                      {renderTimes(coupleArray[0])}
                    </li>
                  )}
              </StyledSpecialDatesDays>
              {renderIconStatus()}
            </StyledSpecialDatesInfo>
          );
        })
      )}
    </>
  );
};

export default SpecialDateRow;
