import React, { useState } from 'react';
import { useHistory, Redirect } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { Button } from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';

import { Creators as AuthActions } from '../../redux/ducks/auth';

import {
  Can,
  DataTable,
} from '../../components';

import getUsers from '../../services/users/getUsers';
import getUserName from '../../services/users/getUserName';
import getMenus from '../../services/menus/getMenus';
import deleteMenu from '../../services/menus/deleteMenu';

const MenuList = () => {
  const history = useHistory();

  const reduxDispatch = useDispatch();

  const [menus, setMenus] = useState([]);
  const [loading, setLoading] = useState(false);

  const [searchQuery, setSearchQuery] = React.useState('');
  const [page, setPage] = React.useState(0);
  const [pageSize, setPageSize] = React.useState(10);
  const [dataCount, setDataCount] = React.useState(0);

  const { user } = useSelector((state) => state.AuthReducer);

  const setTokenLikeExpired = () => {
    reduxDispatch(AuthActions.setUser({ ...user, accessToken: 'expired' }));
  };

  const columns = [
    { name: 'Id', options: { display: false, sort: false } },
    { name: 'Nome', options: { sort: false } },
    { name: 'Descrição', options: { sort: false } },
    { name: 'Slug', options: { sort: false } },
    {
      name: 'Grupos',
      options: {
        sort: false,
        customBodyRender(value, tableMeta, updateValue) {
          return (
            <div className="items-table-cell">
              {value.map((element) => (
                <text key={element.id}>{`- ${element.name}`}</text>
              ))}
            </div>
          );
        },
      },
    },
    {
      name: 'Funções',
      options: {
        sort: false,
        customBodyRender(value, tableMeta, updateValue) {
          return (
            <div className="items-table-cell">
              {value.map((element) => (
                <text key={element.id}>{`- ${element.name}`}</text>
              ))}
            </div>
          );
        },
      },
    },
    { name: 'Data da Criação', options: { sort: false } },
    { name: 'Data da ultima atualização', options: { sort: false } },
    { name: 'Criado por', options: { sort: false } },
  ];

  const data = [];
  for (let i = 0; i < menus.length; i++) {
    data.push([
      menus[i].id,
      menus[i].name,
      menus[i].description,
      menus[i].slug,
      menus[i].groups,
      menus[i].functions,
      menus[i].date_created,
      menus[i].date_updated,
      menus[i].created_by,
    ]);
  }

  const getListMenu = async () => {
    setLoading(true);
    const menus = await getMenus({
      page, pageSize, query: searchQuery, accessToken: user.accessToken, setTokenLikeExpired,
    });
    setMenus(menus[0]);
    setDataCount(menus[1]);
    setLoading(false);
  };

  React.useEffect(() => {
    getListMenu();
  }, [page, pageSize, searchQuery]);

  const onAddMenuClicked = () => {
    history.push('/menus/create');
  };

  const onUpdateMenuClicked = (id) => {
    history.push(`/menus/update/${id}`);
  };

  const onDeleteMenuClicked = async (id) => {
    const deleteMenuResponse = await deleteMenu({ accessToken: user.accessToken, menuId: id, setTokenLikeExpired });
    if (deleteMenuResponse) getListMenu();
  };

  return (
    <>
      <Can
        perform="Menus/List"
        yes={() => (
          <div style={{ padding: '1em' }}>
            <Can
              perform="Menus/Create"
              yes={() => (
                <div className="create-function-button">
                  <Button
                    variant="contained"
                    startIcon={<FontAwesomeIcon icon={faPlus} />}
                    onClick={onAddMenuClicked}
                    style={{
                      marginBottom: '1em',
                      color: 'white',
                      backgroundColor: '#31ab70',
                      fontFamily: 'Poppins',
                      fontWeight: 'bold',
                    }}
                  >
                    Criar Menu
                  </Button>
                </div>
              )}
              no={() => <></>}
            />

            <DataTable
              count={dataCount}
              page={page}
              setPage={setPage}
              pageSize={pageSize}
              setPageSize={setPageSize}
              searchQuery={searchQuery}
              setSearchQuery={setSearchQuery}
              title="Menus"
              columns={columns}
              data={data}
              loading={loading}
              onUpdateClicked={onUpdateMenuClicked}
              onDeleteClicked={onDeleteMenuClicked}
              addRegisterLabel="Criar Menu"
              addRegisterLink="/menus/create"
            />
          </div>
        )}
        no={() => <Redirect to="/main" />}
      />
    </>
  );
};

export default MenuList;
