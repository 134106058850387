import React from 'react';
import {
  KeyboardArrowDown,
  KeyboardArrowUp,
} from '@material-ui/icons';
import { IconButton } from '@material-ui/core';

import * as PlatformsIcons from '../platforms-icons';

import SubPlatform from './subplatform';

import Status from './status';

import {
  StyledPlatform,
  StyledContent,
  StyledHeader,
  StyledPlatformTitle,
  StyledHeaderInfoRight,
  StyledSubplatformsList,
} from './manual-sync-platform-styles';

export const ManualSyncPlatform = ({
  title,
  syncStatus,
  subplatforms = [],
}) => {
  const [open, setOpen] = React.useState(false);

  const PlatformIcon = PlatformsIcons[title];

  return (
    <StyledPlatform>
      <StyledHeader>
        <StyledPlatformTitle>
          <PlatformIcon className="platform-icon" />
          {title}
        </StyledPlatformTitle>

        <StyledHeaderInfoRight>
          <Status syncStatus={syncStatus} />

          {subplatforms.length > 0 && (
            <IconButton
              className="dropdown-icon-button"
              onClick={() => setOpen(!open)}
            >
              {open ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
            </IconButton>
          )}
        </StyledHeaderInfoRight>
      </StyledHeader>

      {subplatforms.length > 0 && (
        <StyledContent openContent={open}>
          <span>Essa plataforma também sincroniza com as seguintes plataformas:</span>

          <StyledSubplatformsList>
            {subplatforms.map((subplatformTitle) => (
              <SubPlatform key={Math.random(Math.floor())} title={subplatformTitle} />
            ))}
          </StyledSubplatformsList>
        </StyledContent>
      )}
    </StyledPlatform>
  );
};

export default ManualSyncPlatform;
