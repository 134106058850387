import React from 'react';
import * as MaterialUIIcons from '@material-ui/icons/';

import {
  StyledHeaderToolbarAction,
} from './header-toolbar-action-styles';

const HeaderToolbarAction = ({
  title = 'Default',
  onClick,
  isActive = false,
  icon = 'Help',
  disabled = false,
}) => {
  const Icon = MaterialUIIcons[icon];

  return (
    <StyledHeaderToolbarAction
      isActive={isActive}
      onClick={onClick}
      disabled={disabled}
    >
      <Icon className="icon" />
      <span className="title">{title}</span>
    </StyledHeaderToolbarAction>
  );
};

export default HeaderToolbarAction;
