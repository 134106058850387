import styled from 'styled-components';
import { devices } from '../../../assets/styles/devices';

type Props = {
  selectedOption: boolean;
}

export const StyledReviewListing = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 2rem 0;

  @media ${devices.tablet} {
    align-items: flex-end;
  }
`;

export const StyledListingHeaderTop = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: space-between;
  row-gap: 2rem;
  align-items: center;

  @media ${devices.tablet} {
    flex-direction: row;
  }

  span {
    font-size: 1.4rem;
    font-weight: 400;
  }

  svg {
    width: 2rem;
  }
`;

export const StyledListingHeaderBottom = styled.div`
  display: flex;
  /* max-width: 100%; */
  align-items: center;
  column-gap: 1rem;

  @media ${devices.tablet} {
    max-width: fit-content;
  }

  select {
    width: unset;
  }

  .icon {
    fill: #fff;
  }
`;

export const StyledSelectOptionData = styled.div`
  margin: 1rem 0;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  text-align: center;
  align-items: center;
  
  background-color: ${(props) => props.theme.colors.greyLight};
  border-radius: 6px;

  svg {
    height: 2rem;
    width: 2rem;
  }
`;

export const StyledSelectOptionButton = styled.button<Props>`
  border: none;
  width: 100%;
  border-radius: 6px;
  font-size: 1.2rem;
  font-weight: 400;
  padding: 1rem;
  
  &:focus {
    outline: none;
  }

  background-color: ${({ theme, selectedOption }) => (selectedOption ? theme.colors.primary : theme.colors.greyLight)};
  fill: ${({ theme, selectedOption }) => (selectedOption ? theme.colors.white : theme.colors.grey)};
`;
