import { invertObj } from 'ramda';

export const locationGooglePostsType = [
  {
    id: 0,
    name: 'Atualizações da COVID-19',
  },
  {
    id: 1,
    name: 'Oferta',
  },
  {
    id: 2,
    name: 'Novidades',
  },
  {
    id: 3,
    name: 'Evento',
  },
];

export const batchPostStatus = {
  CANCELLED: 'Cancelado',
  DONE: 'Publicado',
  ERROR: 'Erro',
  PENDING: 'Pendente',
  PROCESSING: 'Processando',
  REMOVED: 'Removido',
  REMOVING: 'Removendo',
  REJECTED: 'Rejeitada',
};

export const batchPostStatusInverted = invertObj(batchPostStatus);

export const locationGooglePostsLocationStatus = [
  {
    id: 0,
    name: 'Cancelado',
  },
  {
    id: 1,
    name: 'Publicado',
  },
  {
    id: 2,
    name: 'Erro',
  },
  {
    id: 3,
    name: 'Pendente',
  },
  {
    id: 4,
    name: 'Processando',
  },
  {
    id: 5,
    name: 'Removido',
  },
  {
    id: 6,
    name: 'Removendo',
  },
  {
    id: 7,
    name: 'Rejeitada',
  },
];
