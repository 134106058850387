import { isNil, isEmpty } from 'ramda';
import getGoogleUserLocations from './getGoogleUserLocations';
import refreshGoogleAccessToken from './refreshGoogleAccessToken';
import updateAllLocationConnectionsByRefreshToken from '../location-connections/updateAllLocationConnectionsByRefreshToken';

import {
  TGetGoogleAccountsLocations,
  TGoogleLocation,
} from '../../types/TGoogle';

export const getGoogleAccountsLocations = async ({
  userAccessToken,
  googleAccounts,
  isFetching,
}: TGetGoogleAccountsLocations): Promise<Record<string, TGoogleLocation[]> | null> => {
  try {
    if (isFetching) isFetching(true);
    const googleLocations: Record<string, TGoogleLocation[]> = {};
    if (isNil(googleAccounts)) return googleLocations;

    const accountsLocations = await Promise.all(
      googleAccounts.map(async ({ googleAccountId, refreshToken }) => {
        let accountLocations: TGoogleLocation[] = [];

        const recurFuntion = async (
          nextPageToken: string | null,
        ): Promise<TGoogleLocation[]> => {
          if (isNil(nextPageToken)) return accountLocations;

          const refreshGoogleAccessTokenResponse = await refreshGoogleAccessToken({
            refreshToken,
          });

          if (isNil(refreshGoogleAccessTokenResponse)) {
            await updateAllLocationConnectionsByRefreshToken({ accessToken: userAccessToken, refreshToken });
            return accountLocations;
          }

          const {
            access_token: googleAccessToken,
          } = refreshGoogleAccessTokenResponse;

          const registerResponse = await getGoogleUserLocations({
            accessToken: googleAccessToken,
            pageToken: nextPageToken,
            pageSize: 100,
            userId: googleAccountId,
          });

          if (isEmpty(registerResponse) || isNil(registerResponse)) return accountLocations;

          const formattedAccountLocations = registerResponse.locations.map((location: any) => ({
            ...location,
            name: `accounts/${googleAccountId}/${location.name}`,
          }));

          accountLocations = [
            ...accountLocations,
            ...formattedAccountLocations,
          ];

          const { nextPageToken: nextPageTokenResponse } = registerResponse;

          return recurFuntion(nextPageTokenResponse);
        };

        const userAssociatedAccountsAll = await recurFuntion('');

        return {
          googleAccountId,
          locations: userAssociatedAccountsAll.filter(
            (register: any) => !isNil(register),
          ),
        };
      }),
    );

    accountsLocations.forEach(account => {
      const { googleAccountId, locations } = account;
      if (!isNil(locations)) googleLocations[googleAccountId] = locations;
    });

    return googleLocations;
  } catch (err) {
    return null;
  } finally {
    if (isFetching) isFetching(false);
  }
};
