import React from 'react';
import { ArrowDropDown, ArrowDropUp } from '@material-ui/icons';

import { Select, Button } from '../../../../../components';

import { selectStatus } from '../../../helpers';

import {
  StyledTicketActionMobile,
} from './ticket-action-mobile-styles';

const TicketActionMobile = ({ status }) => {
  const [showChangeStatus, setShowChangeStatus] = React.useState(false);

  return (
    <StyledTicketActionMobile>
      {status === 'done' ? <small>Ticket feito!</small> : (
        <>
          <div
            className="drop-down-action"
            onClick={() => setShowChangeStatus(!showChangeStatus)}
            role="presentation"
          >
            Expandir
            {showChangeStatus ? <ArrowDropUp /> : <ArrowDropDown />}
          </div>
          {showChangeStatus && (
            <div className="ticket-action">
              <Select
                border
                label="Zendesk Ticket status:"
                name="status-ticket"
                options={selectStatus}
              />
              <Button className="button">
                Aplicar
              </Button>
            </div>
          )}
        </>
      )}
    </StyledTicketActionMobile>
  );
};

export default TicketActionMobile;
