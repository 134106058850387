import axios from 'axios';
import removeSpecialCharacters from '../utils/removeSpecialCharacters';

export default async function getCoordinates({ address, feedbackMessage }) {
  try {
    if (feedbackMessage) {
      feedbackMessage(
        'Buscando coordenadas do endereço...',
        { variant: 'warning' },
      );
    }

    let formatedAddress = address.toLowerCase().replace(/ /g, '+');
    formatedAddress = removeSpecialCharacters(formatedAddress);

    const { data } = await axios.get(
      `https://maps.googleapis.com/maps/api/geocode/json?address=${formatedAddress}&key=${process.env.REACT_APP_GOOGLE_PLACES_API_KEY}`,
    );

    if (data.status !== 'OK') return data;

    return data.results[0].geometry.location;
  } catch (err) {
    if (feedbackMessage) {
      feedbackMessage(
        'Erro ao tentar encontrar coordenadas do local. Verifique se o endereço está correto!',
        { variant: 'error' },
      );
    }

    return null;
  }
}
