import React from 'react';

import { IconButton } from '../../../../../../components';
import InputSwitchWithNeutral from '../../../../../../components/input-switch-with-neutral';

import { StyledBox, StyledSection } from './requests-section-popover-styles';
import { TRequestsSectionPopoverProps } from './types';

const RequestsSectionPopover = ({
  title,
  open,
  setOpen,
  isChecked,
  handleChecked,
  hasStatusAlready = false,
}: TRequestsSectionPopoverProps) => (
  <StyledSection>
    <StyledBox>
      <IconButton
        label=""
        tooltip=""
        icon={open ? 'KeyboardArrowDown' : 'KeyboardArrowRight'}
        isWhite
        onClick={() => setOpen(!open)}
      />
      <h2>{title}</h2>
    </StyledBox>

    {!hasStatusAlready && <InputSwitchWithNeutral checked={isChecked} changeChecked={() => handleChecked()} />}
  </StyledSection>
);

export default RequestsSectionPopover;
