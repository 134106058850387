import axios from 'axios';

import { TUpdateLocationGroup } from '../../types/TLocationGroup';

export default async function updateLocationGroup({
  accessToken,
  locationGroupId,
  name,
  description,
  main_responsible_id,
  setTokenLikeExpired,
  feedbackMessage,
  setIsCreatingLocationGroup,
}: TUpdateLocationGroup) {
  try {
    if (setIsCreatingLocationGroup) setIsCreatingLocationGroup(true);
    if (feedbackMessage) feedbackMessage('Atualizando grupo de locais...', { variant: 'warning' });

    const { data } = await axios.put(
      `${process.env.REACT_APP_LOCATION_GROUPS_API_URL}/${locationGroupId}`,
      {
        name,
        description,
        main_responsible_id,
      },
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      },
    );

    if (feedbackMessage) feedbackMessage('Grupo de locais atualizado com sucesso', { variant: 'success' });

    return data;
  } catch (err: any) {
    if (err.response?.status === 401 && setTokenLikeExpired) setTokenLikeExpired();

    return null;
  } finally {
    if (setIsCreatingLocationGroup) setIsCreatingLocationGroup(false);
  }
}
