import styled from 'styled-components';

export const StyledSpecialDatesInfosContainer = styled.div`
  display: flex;
  align-items: center;
  margin: 1rem 3rem;
  width: calc(100% - 6rem);
  flex-direction: column;
`;

export const StyledSpecialDateWrapper = styled.ul`
  display: flex;
  flex: 1;
  width: 100%;
  border-bottom:  ${({ isLastOfSection }) => (isLastOfSection ? 'none' : '1px solid #757575')};
  button.MuiIconButton-root {
    margin-top: 1rem;
    margin-right: 1rem;
  }
`;
export const StyledSpecialDatesInfo = styled.li`
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-around;
  flex: 1;
`;

export const StyledSpecialDatesInfoLine = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin: 0 auto 0 0;
  width: 70%;

  p.title {
    font-weight: bold;
  }

  p {
    font-size: 1.4rem;
  }
`;

export const StyledExcludedMessage = styled.p`
  align-self: center;
  font-size: 1.4rem;
  font-weight: bold;
  flex: 1;
  margin-bottom: 0;
`;
