import React from "react";
import { useDispatch, useSelector } from "react-redux";
import * as R from "ramda";
import { useSnackbar } from "notistack";

import { Creators as PlatformsActions } from "../../../../../redux/ducks/platforms";
import { Creators as LocationActions } from "../../../../../redux/ducks/location";
import { Creators as AuthActions } from "../../../../../redux/ducks/auth";

import updateLocation from "../../../../../services/locations/updateLocation";

import { FacebookLogin } from "./helpers";

import Button from "../../../../button";
import Loading from "../../../../loading";

import LocationIsLinked from "./location-is-linked";

import {
  facebookAPIGetPageInfos,
  facebookAPIPageSaveInfos,
} from "../../../../../services/facebook";

import ToCompareDialog from "../../to-compare-dialog";
import ListingDialog from "../../listing-dialog";
import PageBindedProfile from "./page-binded-profile";

import { StyledFacebook, StyledButtonsGroup } from "./facebooks-styles";

import { facebookLocationNotConnected } from "./utils";

const Facebook = ({
  connected,
  setConnected,
  setProfileHeader,
  setIsFetching: setIsFetchingPlatform,
}) => {
  const reduxDispatch = useDispatch();
  const { facebookData } = useSelector((state) => state.PlatformsReducer);

  const { activeLocation } = useSelector((state) => state.LocationReducer);
  const {
    zip_code,
    country,
    city,
    phone,
    state,
    facebook_categories,
    email,
    short_description,
    neighborhood,
    website,
    working_hours,
    address1,
    facebook_page_data: facebookPageData,
  } = activeLocation;

  const { user } = useSelector((state) => state.AuthReducer);

  const { accessToken: userHublocalAccessToken } = user;

  const setTokenLikeExpired = () => {
    reduxDispatch(AuthActions.setUser({ ...user, accessToken: 'expired' }))
  };

  const [isFetchingGetPageInfos, setIsFetchingGetPageInfos] = React.useState(
    false
  );
  const [isSyncInfos, setIsSyncInfos] = React.useState(false);
  const [isLogin, setIsLogin] = React.useState(false);
  const [isLogout, setIsLogout] = React.useState(false);
  const [isUnlink, setUnlink] = React.useState(false);

  const [dataFromPlatform, setDataFromPlatform] = React.useState(null);

  const [openDialogToCompare, setOpenDialogToCompare] = React.useState(false);
  const [openListingDialog, setOpenListingDialog] = React.useState(false);
  const [locationBinded, setLocationBinded] = React.useState(null);

  const { enqueueSnackbar } = useSnackbar();

  const [locationIsLinked, setLocationIsLinked] = React.useState(false);
  const [
    currentPlatformToCompare,
    setCurrentPlatformToCompare,
  ] = React.useState("facebook");

  const getPageInfos = React.useCallback(async () => {
    setDataFromPlatform(null);

    const { page, userToken } = facebookPageData;
    const { selectedLocation } = page;

    const responsePageInfos = await facebookAPIGetPageInfos({
      pageID: selectedLocation ? selectedLocation?.id : page?.id,
      token: userToken,
      setIsFetching: setIsFetchingGetPageInfos,
      tokenValidate: {
        snackbar: enqueueSnackbar,
        setConnected,
        setProfileHeader,
        resetSetFacebookData: () => reduxDispatch(PlatformsActions.setFacebookData({})),
        FBLogout: () => window.FB.logout(),
      },
    });

    if (responsePageInfos) {
      setDataFromPlatform((oldState) => ({
        ...oldState,
        ...responsePageInfos,
      }));
      setOpenDialogToCompare(true);
    }
  }, [facebookPageData]);

  React.useEffect(() => {
    if (
      facebookLocationNotConnected({
        facebookData,
        facebookPageData,
      })
    ) {
      setConnected(false);
      setProfileHeader(null);
      return;
    } else {
      setConnected(true);

      setProfileHeader({
        url: facebookPageData?.userPicture || facebookData?.userPicture,
        alt: facebookPageData?.userName || facebookData?.userFirstName,
      });

      if (facebookPageData) {
        setOpenListingDialog(false);
      } else {
        setOpenListingDialog(true);
      }
    }
  }, [facebookData, activeLocation]);

  const handleLogin = async () => {
    setIsLogin(true);
    setIsFetchingPlatform(true);

    FacebookLogin({
      reduxDispatch,
      setFacebookData: PlatformsActions.setFacebookData,
      setIsLogin,
      setIsFetchingPlatform,
      snackbar: enqueueSnackbar,
    });
  };

  const handleUnlink = async () => {
    await updateLocation({
        accessToken: userHublocalAccessToken,
        locationId: activeLocation.id,
        inputData:{
          facebook_page_data: null,
        },
        deleteGoogle: false,
        deleteFacebook: true,
        setIsFetching: setUnlink,
        setTokenLikeExpired,
    });

    reduxDispatch(
      LocationActions.setActiveLocation({
        ...activeLocation,
        facebook_page_data: null,
      })
    );

    setConnected(false);
    setLocationBinded(null);
  };

  const handleLogout = () => {
    window.FB.logout();

    reduxDispatch(PlatformsActions.setFacebookData({}));
    setConnected(false);
    setProfileHeader(null);
  };

  const handleBindedLocation = async ({ selectedPageId, setLoading }) => {
    const {
      userPicture,
      userToken,
      userName,
      userID,
      userPages,
    } = facebookData;

    let facebookPageData = {
      userPicture,
      userToken,
      userName,
      userID,
    };

    const selectedPageSplited = selectedPageId.split(",");

    if (selectedPageSplited.length === 2) {
      const mainPage = R.find(R.propEq("id", selectedPageSplited[0]))(
        userPages
      );

      if (!mainPage) {
        setLocationIsLinked(true);
        return;
      }

      setCurrentPlatformToCompare("facebook, page-location");
      setLocationIsLinked(false);

      const selectedLocationFromMainPage = R.find(
        R.propEq("id", selectedPageSplited[1])
      )(mainPage.locations);

      if (!selectedLocationFromMainPage) return;

      facebookPageData = {
        ...facebookPageData,
        page: {
          id: mainPage.id,
          name: mainPage.name,
          token: mainPage.token,
          selectedLocation: {
            id: selectedLocationFromMainPage.id,
            name: selectedLocationFromMainPage.name,
            token: selectedLocationFromMainPage.access_token,
            address: selectedLocationFromMainPage.single_line_address,
          },
        },
      };
    } else {
      const page = R.find(R.propEq("id", selectedPageSplited[0]))(userPages);

      if (!page) {
        setLocationIsLinked(true);
        return;
      }

      facebookPageData = {
        ...facebookPageData,
        page: {
          id: page.id,
          name: page.name,
          token: page.token,
          selectedLocation: null,
        },
      };
    }

    const responseUpdate = await updateLocation({
        accessToken: userHublocalAccessToken,
        locationId: activeLocation.id,
        inputData: {
          facebook_page_id: selectedPageId,
          facebook_page_data: JSON.stringify(facebookPageData),
        },
        deleteGoogle: false,
        deleteFacebook: false,
        setIsFetching: setLoading,
        setTokenLikeExpired,
    });

    if (responseUpdate) {
      reduxDispatch(
        LocationActions.setActiveLocation({
          ...activeLocation,
          facebook_page_id: selectedPageId,
          facebook_page_data: facebookPageData,
        })
      );
      setOpenListingDialog(false);
    }
  };

  const handleOpenToCompareDialog = () => getPageInfos();

  const handleSyncInfos = async () => {
    const { page } = facebookPageData;
    const { selectedLocation } = page;

    const responseSyncInfos = await facebookAPIPageSaveInfos({
      pageID: selectedLocation ? selectedLocation?.id : page?.id,
      pageToken: selectedLocation ? selectedLocation?.token : page?.token,
      payloadData: activeLocation,
      setIsFetching: setIsSyncInfos,
      tokenValidate: {
        snackbar: enqueueSnackbar,
        setConnected,
        setProfileHeader,
        resetSetFacebookData: () =>
          reduxDispatch(PlatformsActions.setFacebookData({})),
        FBLogout: () => window.FB.logout(),
      },
    });

    if (responseSyncInfos) {
      enqueueSnackbar("Sincronização com o Facebook realizada com sucesso!", {
        variant: "success",
      });
    }
  };

  const handleCheckField = (field) =>
    R.isEmpty(field) || R.isNil(field) || field === " - ";

  const handleCheckFieldHoursDay = (hoursDay) => hoursDay === " - ";

  const { userPages } = facebookData;

  return (
    <StyledFacebook>
      {connected ? (
        <StyledButtonsGroup>
          {facebookPageData && (
            <>
              {facebookPageData && (
                <PageBindedProfile facebookPageData={facebookPageData} />
              )}
              <Button
                disabled={isFetchingGetPageInfos}
                className="button"
                onClick={handleOpenToCompareDialog}
              >
                {isFetchingGetPageInfos ? <Loading /> : "Comparar"}
              </Button>

              <span className="disclaimer-sync">
                Preecha corretamente todos os campos que estão na{" "}
                <small>comparação</small> para habilitar a{" "}
                <small>sincronização.</small>
              </span>

              <Button
                disabled={
                  isSyncInfos ||
                  handleCheckField(zip_code) ||
                  handleCheckField(country) ||
                  handleCheckField(city) ||
                  handleCheckField(phone) ||
                  handleCheckField(state) ||
                  handleCheckField(neighborhood) ||
                  handleCheckField(address1) ||
                  handleCheckField(short_description) ||
                  handleCheckField(website) ||
                  (handleCheckFieldHoursDay(working_hours[0]?.monday) &&
                    handleCheckFieldHoursDay(working_hours[0]?.saturday) &&
                    handleCheckFieldHoursDay(working_hours[0]?.sunday) &&
                    handleCheckFieldHoursDay(working_hours[0]?.tuesday) &&
                    handleCheckFieldHoursDay(working_hours[0]?.wednesday) &&
                    handleCheckFieldHoursDay(working_hours[0]?.friday) &&
                    handleCheckFieldHoursDay(working_hours[0]?.thursday)) ||
                  handleCheckField(email) ||
                  handleCheckField(JSON.parse(facebook_categories))
                }
                className="button"
                onClick={handleSyncInfos}
              >
                {isSyncInfos ? <Loading /> : "Sincronizar"}
              </Button>
              <Button
                disabled={isUnlink}
                className="button"
                onClick={handleUnlink}
                buttonType="secondary"
              >
                {isUnlink ? <Loading /> : "Desvincular"}
              </Button>
            </>
          )}

          {!facebookPageData && (
            <>
              {locationIsLinked && (
                <LocationIsLinked
                  facebookLinkedPageData={activeLocation.facebook_page_id}
                />
              )}
              <Button
                className="button"
                onClick={() => setOpenListingDialog(true)}
              >
                Vincular página
              </Button>
              <Button
                disabled={isLogout}
                className="button"
                onClick={handleLogout}
                buttonType="secondary"
              >
                {isLogout ? <Loading /> : "Desconectar"}
              </Button>
            </>
          )}
        </StyledButtonsGroup>
      ) : (
        <Button disabled={isLogin} onClick={handleLogin}>
          {isLogin ? <Loading /> : "Conectar"}
        </Button>
      )}

      {openDialogToCompare && !isFetchingGetPageInfos && (
        <ToCompareDialog
          open={openDialogToCompare}
          handleClose={() => setOpenDialogToCompare(false)}
          titlePlatformColumn="Página (Facebook)"
          dataFromLocation={activeLocation}
          dataFromPlatform={dataFromPlatform}
          currentPlatform={currentPlatformToCompare}
        />
      )}

      {userPages && openListingDialog && (
        <ListingDialog
          headerTitleCreateButton="Criar uma página"
          headerTitle="Páginas"
          handleHeaderCreateButton={() => {}}
          handleBindWithLocation={handleBindedLocation}
          data={userPages}
          open={openListingDialog}
          image="cover"
          name="name"
          keyToFilter="name"
          handleClose={() => setOpenListingDialog(false)}
        />
      )}
    </StyledFacebook>
  );
};

export default Facebook;
