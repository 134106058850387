import styled from 'styled-components';
import {
  Dialog,
  DialogContent,
} from '@material-ui/core';

import { devices } from '../../assets/styles/devices';

export const StyledEditMarker = styled(Dialog)`
  .save-button {
    max-width: 15rem;
    width: 100%;
    margin-left: auto;
  }
`;

export const StyledEditMarkerContent = styled(DialogContent)`
  width: 30rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2rem !important;

  @media ${devices.tabletLandscape} {
    width: 60rem;
  }
`;

export const StyledDisclaimer = styled.p`
  font-size: 1.4rem;
  margin: 1rem 0 2rem;
`;
