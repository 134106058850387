import React from 'react';

import ConfigCard from '../../../components/config-card';

import {
  StyledMenus,
} from './menus-styles';

const HandlerList = ({ navigation }) => (
  <ConfigCard
    title="Menus"
    onClick={() => navigation.push('/menus/list')}
  >
    <StyledMenus>
      Menus
    </StyledMenus>
  </ConfigCard>
);

export default HandlerList;
