import React from 'react';
import { isEmpty } from 'ramda';
import Button from '../../button/button';
import { companyWithUnlinkedText, warningMessageType } from '../helpers';
import {
  StyledBottomActions,
  StyledMainMessage,
  StyledSubMessage,
  StyledWarningMessage,
  StyledUnlinkedLocation,
  StyledUnlinkedLocationsList,
  StyledCompanyWithUnlinkedSection,
  StyledDialogBody,
} from './link-info-popover-styles';
import IconButton from '../../icon-button';
import { TLinkInfoPopoverProps } from '../types';

const LinkInfoPopover = ({
  onConfirm,
  onClose,
  type,
  unlinkedLocationsInfo = [],
}: TLinkInfoPopoverProps) => (
  <StyledDialogBody>
    {!isEmpty(unlinkedLocationsInfo) && (
      <StyledCompanyWithUnlinkedSection>
        <StyledMainMessage>{companyWithUnlinkedText.mainMessage}</StyledMainMessage>
        <StyledSubMessage>{companyWithUnlinkedText.subMessage}</StyledSubMessage>
        <StyledUnlinkedLocationsList>
          {unlinkedLocationsInfo[0].map((location) => (
            <StyledUnlinkedLocation>
              <IconButton
                tooltip="Local sem vinculação"
                icon="Error"
                className="status-icon"
                isWarning
                tooltipVariant="yellow"
              />
              <p>{location.name}</p>
            </StyledUnlinkedLocation>
          ))}
          {unlinkedLocationsInfo[1] > 5 && (
          <StyledUnlinkedLocation>
            <IconButton
              tooltip="Local sem vinculação"
              icon="Error"
              className="status-icon"
              isWarning
              tooltipVariant="yellow"
            />
            <p>{companyWithUnlinkedText.countMessage(unlinkedLocationsInfo[1])}</p>
          </StyledUnlinkedLocation>
          )}
        </StyledUnlinkedLocationsList>
        <hr />
      </StyledCompanyWithUnlinkedSection>
    )}
    <StyledWarningMessage isPrimaryColor={type !== 'companyWithUnlinked'}>{warningMessageType[type]}</StyledWarningMessage>
    <StyledBottomActions>
      <Button buttonType="primary" onClick={onConfirm} className="button">Sim, desejo realizar</Button>
      <Button buttonType="cancel" onClick={onClose} className="button">Cancelar</Button>
    </StyledBottomActions>
  </StyledDialogBody>
);

export default LinkInfoPopover;
