// eslint-disable-next-line no-use-before-define
import React, { useCallback, useEffect, useState } from 'react';
import { isEmpty } from 'ramda';
import { AutocompleteInput } from '../../../../components';
import { useAuth } from '../../../../hooks';
import getPlatformCategories from '../../../../services/categories/getPlatformCategories';
import { getCategoryGroupingPaginated } from '../../../../services/category-groupings';
import { StyledSectionTitle, StyledFieldsWrapper, StyledSeparator } from './categories-fields-styles';
import { TCategoriesFieldsProps, TCategoriesGroup, TCategory } from './types';

const CategoriesFields = ({ locationInfo, setLocationInfo }: TCategoriesFieldsProps) => {
  const { userAccessToken, userSetTokenLikeExpired } = useAuth();

  const [categoryGroupings, setCategoryGroupings] = useState<TCategoriesGroup[]>([]);
  const [
    categoryGroupingsSelected,
    setCategoryGroupingsSelected,
  ] = useState<TCategoriesGroup[]>([]);
  const [googleCategories, setGoogleCategories] = useState([]);
  const [facebookCategories, setFacebookCategories] = useState([]);
  const [foursquareCategories, setFoursquareCategories] = useState([]);

  const handleSetCategoryGroupingSelected = (
    selectedCategoryGroup: TCategoriesGroup[],
  ) => {
    if (!isEmpty(selectedCategoryGroup)) {
      selectedCategoryGroup.forEach(categoryGroup => {
        const {
          facebookCategories: facebookCategoriesValue = [],
          googleCategories: googleCategoriesValue = [],
          foursquareCategories: foursquareCategoriesValue = [],
        } = categoryGroup || {};

        setLocationInfo(prev => {
          let previousFacebook: TCategory[] = [];
          let previousGoogle: TCategory[] = [];
          let previousFoursquare: TCategory[] = [];
          let facebook = [{}];
          let google = [{}];
          let foursquare = [{}];

          if (prev.facebook_categories) {
            previousFacebook = JSON.parse(prev.facebook_categories);

            facebook = [
              ...previousFacebook,
              ...facebookCategoriesValue
                .filter(category => !previousFacebook.find(
                  currentCategory => (currentCategory.id === category.id),
                )),
            ];
          } else {
            facebook = facebookCategoriesValue;
          }

          if (prev.google_categories) {
            previousGoogle = JSON.parse(prev.google_categories);

            google = [
              ...previousGoogle,
              ...googleCategoriesValue
                .filter(category => !previousGoogle.find(
                  currentCategory => (currentCategory.id === category.id),
                )),
            ];
          } else {
            google = googleCategoriesValue;
          }

          if (prev.foursquare_categories) {
            previousFoursquare = JSON.parse(prev.foursquare_categories);

            foursquare = [
              ...previousFoursquare,
              ...foursquareCategoriesValue
                .filter(category => !previousFoursquare.find(
                  (currentCategory => currentCategory.id === category.id),
                )),
            ];
          } else {
            foursquare = foursquareCategoriesValue;
          }

          return {
            ...prev,
            google_categories: JSON.stringify(google),
            facebook_categories: JSON.stringify(facebook),
            foursquare_categories: JSON.stringify(foursquare),
          };
        });
      });
    }
    setCategoryGroupingsSelected(selectedCategoryGroup);
  };

  const handleCategorySelection = useCallback((newCategories, key: string) => {
    setLocationInfo(prev => ({ ...prev, [key]: JSON.stringify(newCategories) }));
  }, []);

  const getGoogleCategories = useCallback(async () => {
    const categories = await getPlatformCategories({
      accessToken: userAccessToken,
      query: '',
      platform: 'google',
    });
    setGoogleCategories(categories[0]);
  }, []);

  const getFacebookCategories = useCallback(async () => {
    const categories = await getPlatformCategories({
      accessToken: userAccessToken,
      query: '',
      platform: 'facebook',
    });
    setFacebookCategories(categories[0]);
  }, []);

  const getFoursquareCategories = useCallback(async () => {
    const categories = await getPlatformCategories({
      accessToken: userAccessToken,
      query: '',
      platform: 'foursquare',
    });
    setFoursquareCategories(categories[0]);
  }, []);

  const getCategoryGroupings = useCallback(async () => {
    const groupings = await getCategoryGroupingPaginated({
      accessToken: userAccessToken,
      setTokenLikeExpired: userSetTokenLikeExpired,
    });

    if (groupings) {
      const { groupingsData } = groupings;

      const groupingsDataNormalized = groupingsData.map(grouping => ({
        id: grouping.id,
        name: grouping.name,
        facebookCategories: JSON.parse(grouping.facebook_categories),
        googleCategories: JSON.parse(grouping.google_categories),
        foursquareCategories: JSON.parse(grouping.foursquare_categories),
      }));

      setCategoryGroupings(groupingsDataNormalized);
    }
  }, [userAccessToken]);

  useEffect(() => { getGoogleCategories(); }, [getGoogleCategories]);
  useEffect(() => { getFacebookCategories(); }, [getFacebookCategories]);
  useEffect(() => { getFoursquareCategories(); }, [getFoursquareCategories]);
  useEffect(() => { getCategoryGroupings(); }, [getCategoryGroupings]);

  return (
    <>
      <StyledSectionTitle>Categorias</StyledSectionTitle>
      <StyledFieldsWrapper threeColumns>
        <AutocompleteInput
          label="Agrupamento de Categorias"
          options={categoryGroupings}
          saveSelectedOpionsIn={handleSetCategoryGroupingSelected}
          initialValue={categoryGroupingsSelected}
          filterSearchKey="name"
          categoryId="id"
          saveAllObject
          disclaimer="Nenhuma categoria encontrada"
        />
        <AutocompleteInput
          label="Google"
          options={googleCategories}
          saveSelectedOpionsIn={(newCategories) => {
            handleCategorySelection(newCategories, 'google_categories');
          }}
          initialValue={locationInfo.google_categories}
          filterSearchKey="name"
          categoryId="platform_id"
          saveAllObject
          disclaimer="Nenhuma categoria encontrada"
        />
        <AutocompleteInput
          label="Facebook"
          options={facebookCategories}
          saveSelectedOpionsIn={(newCategories) => {
            handleCategorySelection(newCategories, 'facebook_categories');
          }}
          initialValue={locationInfo.facebook_categories}
          filterSearchKey="name"
          categoryId="platform_id"
          saveAllObject
          disclaimer="Nenhuma categoria encontrada"
        />
        <AutocompleteInput
          label="Foursquare"
          options={foursquareCategories}
          saveSelectedOpionsIn={(newCategories) => {
            handleCategorySelection(newCategories, 'foursquare_categories');
          }}
          initialValue={locationInfo.foursquare_categories}
          filterSearchKey="name"
          categoryId="platform_id"
          saveAllObject
          disclaimer="Nenhuma categoria encontrada"
        />
      </StyledFieldsWrapper>

      <StyledSeparator />
    </>
  );
};

export default CategoriesFields;
