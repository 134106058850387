import React from 'react';

import { RoundedButton } from '..';

import {
  StyledDialog,
  StyledDialogWrapper,
  StyledDialogContent,
  StyledDialogHeader,
  StyledDialogButtonsWrapper,
} from './destructive-action-dialog-styles';

export type TDestructiveActionDialogProps = {
  open: boolean;
  onConfirm: () => void;
  onClose: () => (void | Promise<void>);
  warningMessage: string | string[];
  title?: string | null;
  confirmButtonLabel?: string;
  cancelButtonLabel?: string;
  wideDialog?: boolean;
};

export default ({
  open,
  onConfirm,
  onClose,
  warningMessage,
  title = 'Atenção:',
  confirmButtonLabel = 'CONTINUAR',
  cancelButtonLabel = 'CANCELAR',
  wideDialog = true,
}: TDestructiveActionDialogProps) => (
  <StyledDialog open={open} onClose={onClose} wideDialog={wideDialog}>
    <StyledDialogWrapper>
      {!!title && (
      <StyledDialogHeader>
        <h1>{title}</h1>
      </StyledDialogHeader>
      )}
      <StyledDialogContent>
        {Array.isArray(warningMessage) ? (
          warningMessage.map((pragraph) => <p key={pragraph}>{pragraph}</p>)
        ) : (
          <p>{warningMessage}</p>
        )}
      </StyledDialogContent>
      <StyledDialogButtonsWrapper>
        <RoundedButton
          title={confirmButtonLabel}
          onClick={onConfirm}
          icon={null}
          className="confirm-button"
        />
        <RoundedButton
          title={cancelButtonLabel}
          onClick={onClose}
          icon={null}
          variant="grey"
          className="cancel-button"
        />
      </StyledDialogButtonsWrapper>
    </StyledDialogWrapper>
  </StyledDialog>
);
