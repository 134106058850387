import axios from 'axios';

const getCategoryGroupingsPaginated = async ({
  accessToken,
  page = 0,
  pageSize = 1000,
  query = '',
  setTokenLikeExpired,
  setIsFetching = false,
}) => {
  try {
    if (setIsFetching) setIsFetching(true);

    const { data } = await axios({
      method: 'GET',
      url: `${process.env.REACT_APP_CATEGORY_GROUPINGS_API_URL}/?page=${page}&pageSize=${pageSize}&query=${query}`,
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });

    const groupingsData = await data[0];
    const totalGroupings = await data[1];
    const totalPages = totalGroupings / pageSize;

    return {
      groupingsData,
      totalGroupings,
      totalPages: Math.round(totalPages),
    };
  } catch (err) {
    const {
      response,
    } = err;

    if (response?.status === 401 && setTokenLikeExpired) setTokenLikeExpired();

    return null;
  } finally {
    if (setIsFetching) setIsFetching(false);
  }
};

export default getCategoryGroupingsPaginated;
