import axios from 'axios';

export default async function updateProfile(
  accessToken,
  id,
  name,
  description,
  menu_id,
) {
  const requestOptions = {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  };

  try {
    const { data } = await axios.put(
      `${process.env.REACT_APP_PROFILES_API_URL}/${id}`,
      { name, description, menu_id },
      requestOptions,
    );

    console.log(data);
    return data;
  } catch (err) {
    console.log(err);
    return err;
  }
}
