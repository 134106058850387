import styled, { css } from 'styled-components';
import { devices } from '../../../../assets/styles/devices';

export const StyledFieldsWrapper = styled.div<{threeColumns?: boolean, storeCode?: boolean}>`
    width: 100%;
    display: grid;
    grid-template-columns: 1fr;
    row-gap: 2rem;
    margin-bottom: 2rem;

    @media ${devices.desktop} {
        grid-template-columns: repeat(2, 1fr);
        column-gap: 2rem;
    }

    ${props => props.threeColumns && css`
        @media ${devices.fullhd} {
            grid-template-columns: repeat(3, 1fr);
        }
    `}

    ${props => props.storeCode && css`
        @media ${devices.tablet} {
            grid-template-columns: repeat(3, 1fr);
        }

        @media ${devices.tabletLandscape} {
            grid-template-columns: repeat(4, 1fr);
        }

        @media ${devices.desktop} {
            grid-template-columns: repeat(5, 1fr);
        }
    `}
`;
