import styled from 'styled-components';
import CancelRoundedIcon from '@material-ui/icons/CancelRounded';
import { lighten } from 'polished';
import { devices } from '../../assets/styles/devices';

export const StyledContainer = styled.div`
    background-color: ${props => lighten(0.3, props.theme.colors.primary)};
    border-radius: 20px;
    padding: 0.5rem 1rem;
    gap: 1rem;
    align-items: center;
    
    display: flex;
    justify-content: space-between;
`;

export const StyledTitle = styled.p`
    font-size: 1rem;
    font-weight: 400;
    line-height: 1rem;
    color: ${(props) => props.theme.colors.primary};
    margin: 0;
    
    @media ${devices.tabletLandscape} {
        white-space: nowrap;
    }
`;

export const StyledButtonClose = styled.button`
    background: transparent;
    border: none;

    :focus {
        outline: none;
    }
`;

export const StyledIconClose = styled(CancelRoundedIcon)`
    color: ${(props) => props.theme.colors.primary};
`;
