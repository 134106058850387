import * as R from 'ramda';

export const commentIsEmpty = comment => R.isEmpty(comment) || R.isNil(comment);

export const isDone = ({ status }) => status === 'DONE';

export const hasOperator = ({ operator }) => !R.isNil(operator);

export const platformsLabel = {
  google: 'Google',
  facebook: 'Facebook',
  instagram: 'Instagram',
  twitter: 'Twitter',
  youtube: 'Youtube',
  foursquare: 'Foursquare',
  linkedin: 'Linkedin',
  yelp: 'Yelp',
};
