import React from 'react';
import * as R from 'ramda';
import { useDispatch, useSelector } from 'react-redux';

import { Creators as CompanyActions } from '../../redux/ducks/company';
import { Creators as LocationGroupActions } from '../../redux/ducks/location-group';
import { Creators as LocationActions } from '../../redux/ducks/location';

import { TDropdownCompanyOrGroupDialog } from '../../types/TDropdownCompanyOrGroupDialog';
import { TRootStateRedux } from '../../types/TRootStateRedux';

import DropDownDialog from '../dropdown-dialog';

import {
  useCompany,
  useLocationGroups,
} from '../../hooks';

const DropdownCompanyOrGroupDialog = ({
  overlayLoadingNoDisplay = true,
}: TDropdownCompanyOrGroupDialog) => {
  const reduxDispatch = useDispatch();

  const { showLocationsByCompany } = useSelector((state: TRootStateRedux) => state.CompanyReducer);
  const { showLocationsByGroup, activeLocationGroup } = useSelector((state: TRootStateRedux) => state.LocationGroupReducer);

  const {
    userCompanies,
    activeCompany,
    userCompaniesPageIndex,
    userCompaniesPageQuantity,
    setUserCompaniesPageQuery,
    setUserCompaniesPageIndex,
  } = useCompany();

  const {
    locationGroups,
    page: locationGroupsPageIndex,
    setPage: setLocationGroupsPageIndex,
    pageQuantity: locationGroupsPageQuantity,
    setSearchQuery: setLocationGroupsSearchQuery,
  } = useLocationGroups();

  const [isLoading, setIsLoading] = React.useState(false);
  const [popoverActiveTab, setPopoverActiveTab] = React.useState(0);
  const [, setCompanyOrGroupDisplay] = React.useState('company');
  const searchQueryRef = React.useRef<HTMLInputElement>(null);

  React.useEffect(() => {
    if (!showLocationsByGroup && !showLocationsByCompany) {
      reduxDispatch(CompanyActions.setShowLocationsByCompany(true));
    }
    if (showLocationsByCompany) setPopoverActiveTab(0);
    if (showLocationsByGroup) setPopoverActiveTab(1);
  }, [
    showLocationsByCompany,
    showLocationsByGroup,
  ]);

  React.useEffect(() => {
    if (R.isEmpty(locationGroups)) return;
    if (showLocationsByGroup && R.isNil(locationGroups)) {
      reduxDispatch(LocationActions.setActiveLocation(null));
    }
  }, [showLocationsByGroup, locationGroups]);

  const handleChangeCompany = async (companyId: number) => {
    setIsLoading(true);
    setCompanyOrGroupDisplay('company');

    const newActiveCompany = userCompanies.filter(companyItem => companyItem.id === companyId)[0];

    reduxDispatch(CompanyActions.setActiveCompany(newActiveCompany));
    reduxDispatch(CompanyActions.setActiveCompanyId(newActiveCompany.id));

    setTimeout(() => {
      setIsLoading(false);
    }, 1500);
  };

  const handleChangeLocationGroup = async (locationGroupId: number) => {
    setIsLoading(true);
    setCompanyOrGroupDisplay('group');

    const newActiveLocationGroup = locationGroups.filter(locationGroupItem => locationGroupItem.id === locationGroupId)[0];

    reduxDispatch(LocationGroupActions.setActiveLocationGroup(newActiveLocationGroup));
    reduxDispatch(LocationGroupActions.setActiveLocationGroupId(newActiveLocationGroup.id));

    setTimeout(() => {
      setIsLoading(false);
    }, 1500);
  };

  const resetFilters = () => {
    setUserCompaniesPageQuery('');
    setLocationGroupsSearchQuery('');
    if (!R.isNil(searchQueryRef.current)) searchQueryRef.current.value = '';
  };

  const handleChangeTab = (event: any, newValue: number) => {
    resetFilters();
    reduxDispatch(CompanyActions.setShowLocationsByCompany(newValue === 0));
    reduxDispatch(LocationGroupActions.setShowLocationsByGroup(newValue === 1));

    setPopoverActiveTab(newValue);
  };

  return (
    <DropDownDialog
      companyType={popoverActiveTab === 0}
      locationGroupType={popoverActiveTab === 1}
      options={popoverActiveTab === 0 ? userCompanies : locationGroups}
      currentOption={popoverActiveTab === 0 ? activeCompany : activeLocationGroup}
      companyAndGroupType
      activeTab={popoverActiveTab}
      handleChangeTab={handleChangeTab}
      icon="Business"
      title="Empresas/Grupos de Locais"
      onChange={popoverActiveTab === 0 ? handleChangeCompany : handleChangeLocationGroup}
      optionDisableIfNotHaveLocations
      page={popoverActiveTab === 0 ? userCompaniesPageIndex : locationGroupsPageIndex}
      setPage={popoverActiveTab === 0 ? setUserCompaniesPageIndex : setLocationGroupsPageIndex}
      pageQuantity={popoverActiveTab === 0 ? userCompaniesPageQuantity : locationGroupsPageQuantity}
      setQuery={popoverActiveTab === 0 ? setUserCompaniesPageQuery : setLocationGroupsSearchQuery}
      searchQueryRef={searchQueryRef}
      isLoading={isLoading}
      showPagination
      overlayLoadingNoDisplay={overlayLoadingNoDisplay}
    />
  );
};

export default DropdownCompanyOrGroupDialog;
