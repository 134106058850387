import styled, { css } from 'styled-components';
import { lighten } from 'polished';
import { devices } from '../../../../assets/styles/devices';

export const StyledTicket = styled.li`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 2rem;
    width: 100%;
    background: ${props => props.theme.colors.white};
    margin-bottom: 0.2rem;
    cursor: pointer;
    transition: 0.3s ease-in-out;

    @media ${devices.tabletLandscape} {
        flex-direction: row;
        align-items: flex-start;
    }

    &:hover {
        @media ${devices.tabletLandscape} {
            border-left: 1rem solid ${props => lighten(0.9, props.theme.colors.black)};
        }
    }

    ${props => props.isSelected && css`
        border-left: 1rem solid ${lighten(0.9, props.theme.colors.black)};
    `}

    ${props => props.status === 'done' && css`
        opacity: 0.3;
    `}

    &:last-of-type {
        margin-bottom: 0;
    }
`;

export const StyledBoxTypeTicket = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 3.5rem;
    height: 3rem;
    font-size: 1.6rem;
    text-transform: uppercase;
    font-weight: bold;
    background: ${props => props.theme.colors.primary};
    color: ${props => props.theme.colors.white};
    margin-bottom: 2rem;

    @media ${devices.tabletLandscape} {
        margin-bottom: 0;
        margin-right: 2rem;
    }

    ${props => props.status === 'in_progress' && css`
        background: ${props.theme.colors.primary};
    `}

    ${props => props.status === 'open' && css`
        background: ${props.theme.colors.green};
    `}

    ${props => props.status === 'done' && css`
        background: ${props.theme.colors.grey};
    `}
`;

export const StyledContent = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;

    @media ${devices.tabletLandscape} {
        align-items: flex-start;
    }
`;

export const StyledTicketTitle = styled.h3`
    font-size: 1.6rem;
    font-weight: 500;
    padding-bottom: 0.5rem;
    margin-bottom: 0.5rem;
    border-bottom: 0.1rem solid ${props => lighten(0.9, props.theme.colors.black)};
`;

export const StyledTicketDetails = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-flex-start;


    span {
        font-size: 1.2rem;
        font-weight: 600;
    }

    small {
        /* color: ${props => lighten(0.7, props.theme.colors.black)}; */
        font-weight: 400;
        font-size: 1.2rem;
        margin-left: 0.5rem;
    }
`;
