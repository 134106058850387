import React from 'react';
import { isNil } from 'ramda';

import { History } from '../../../../../assets/icons';

import type { TSubtableRow, TRenderCellDataBatchInfosLocationsRow, TBatchInfosLocationsRowColumnsName } from '../../../../../types/TBatchUpdate';

import {
  TableBodyRow,
  IconButton,
  TableBodyCell,
  Loading,
  BatchStatusIcon,
  LogHistoryData,
} from '../../../../../components';

import { useAuth } from '../../../../../hooks';
import type { EBatchPlatformStatus } from '../../../../../types/TBatchGoogleModules';

import {
  findOneBatchInfosLocation,
  updateBatchInfosLocations,
  forceUpdateBatchInfosLocations,
} from '../../../../../services/google-batch-modules/batch-infos';
import APIFindBatchLocationLog from '../../../../../services/google-batch-modules/batch-infos/find-batch-location-log';

import { batchInfosConstants, getDataRowCellValue, updateRowDataByColumn } from '../../../helpers';

import { StyledActionLoadingWrap } from '../../batch-infos-row-styles';
import { StyledActionsWrap } from './batch-infos-locations-row-styles';

export const BatchInfosLocationsRow = ({ data, batchId, updateBatch }: TSubtableRow) => {
  const { userAccessToken } = useAuth();

  const [currentRowData, setCurrentRowData] = React.useState<any[]>([]);
  const [checked, setChecked] = React.useState(false);
  const [isFetchingStatus, setIsFetchingStatus] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);

  const [openBatchInfosLocationsLog, setOpenBatchInfosLocationsLog] = React.useState(false);
  const [locationLogId, setLocationLogId] = React.useState<string | null>(null);
  const [locationLogName, setLocationLogName] = React.useState<string | null>(null);
  const [logData, setLogData] = React.useState([]);

  React.useEffect(() => {
    setCurrentRowData(data);
  }, [data]);

  const batchInfosLocationsId = React
    .useMemo(() => getDataRowCellValue({ columnRef: 'id', dataRowCell: currentRowData }), [currentRowData]);

  const batchInfosLocationStatus = React.useMemo(
    (): string | null => getDataRowCellValue({
      dataRowCell: currentRowData,
      columnRef: 'status',
    }),
    [currentRowData],
  );

  const batchInfosLocationsName = React
    .useMemo(() => getDataRowCellValue({ columnRef: 'location-name', dataRowCell: currentRowData }), [currentRowData]);

  const batchInfosLocationMapsUrl = React.useMemo(
    (): string | null => getDataRowCellValue({
      dataRowCell: currentRowData,
      columnRef: 'maps_url',
    }),
    [currentRowData],
  );

  const getBatchInfosLocations = React.useCallback(async () => {
    setIsFetchingStatus(true);
    const [idColumnCell] = currentRowData;

    const batchInfosLocationResponse = await findOneBatchInfosLocation({
      batchInfosLocationId: idColumnCell.value,
      userAccessToken,
    });

    if (batchInfosLocationResponse.status === 'ERROR') {
      setTimeout(() => setIsFetchingStatus(false), 800);
      return;
    }

    const { status, maps_url } = batchInfosLocationResponse.data;

    setTimeout(() => setIsFetchingStatus(false), 500);
    setCurrentRowData((prevState: any) => updateRowDataByColumn({
      rowData: prevState,
      payload: {
        status: status as EBatchPlatformStatus,
        maps_url,
      },
    }));
  }, [currentRowData]);

  React.useEffect(() => {
    const getData = async () => {
      if (isNil(batchId) || isNil(locationLogId)) return;

      const getActionLogResponse = await APIFindBatchLocationLog({
        userAccessToken,
        batchId,
        batchLocationId: locationLogId,
      });

      if ((getActionLogResponse.status === 'ERROR')) return;

      setLogData(getActionLogResponse.data[0]);
    };
    getData();
  }, [batchId, locationLogId]);

  React.useEffect(() => {
    const getBatchInfosLocationsByInterval = setInterval(() => {
      const cellDataStatus = currentRowData.find(
        (cellData: any) => cellData.columnRef === 'status',
      );
      if (
        cellDataStatus.value === 'PENDING'
        || cellDataStatus.value === 'PROCESSING'
      ) getBatchInfosLocations();
    }, batchInfosConstants.BATCH_INFOS_LOCATION_FETCH_STATUS_SETINTERVAL);

    return () => clearInterval(getBatchInfosLocationsByInterval);
  }, [currentRowData]);

  const showBatchInfosLocation = React.useCallback(() => {
    if (isNil(batchInfosLocationMapsUrl)) return;
    window.open(batchInfosLocationMapsUrl, '_blank');
  }, [batchInfosLocationMapsUrl]);

  const cancelBatchInfosLocations = React.useCallback(async () => {
    const batchInfosLocationsResponse = await updateBatchInfosLocations({
      batchInfosLocationsId,
      status: 'CANCELLED' as EBatchPlatformStatus.CANCELLED,
      userAccessToken,
      isUpdating: setIsLoading,
    });

    if (batchInfosLocationsResponse.status === 'ERROR') return;

    const { status } = batchInfosLocationsResponse.data;

    setCurrentRowData((prevState: any) => updateRowDataByColumn({
      rowData: prevState,
      payload: {
        status: status as EBatchPlatformStatus,
      },
    }));

    await updateBatch();
  }, [currentRowData]);

  const handleForceUpdateBatchInfosLocations = React.useCallback(async () => {
    const batchInfosLocationsResponse = await forceUpdateBatchInfosLocations({
      batchInfosLocationsId,
      userAccessToken,
      isUpdating: setIsFetchingStatus,
    });

    if (batchInfosLocationsResponse.status === 'ERROR') {
      setCurrentRowData((prevState: any) => updateRowDataByColumn({
        rowData: prevState,
        payload: {
          status: 'ERROR' as EBatchPlatformStatus.ERROR,
        },
      }));

      return;
    }

    const { status, maps_url } = batchInfosLocationsResponse.data;

    setCurrentRowData((prevState: any) => updateRowDataByColumn({
      rowData: prevState,
      payload: {
        status: status as EBatchPlatformStatus,
        maps_url,
      },
    }));
  }, [batchInfosLocationsId]);

  const handleOpenLocationLogDialog = React.useCallback(() => {
    setLocationLogId(batchInfosLocationsId);
    setLocationLogName(batchInfosLocationsName);
    setOpenBatchInfosLocationsLog(true);
  }, [batchInfosLocationsId]);

  const renderCellData = React.useMemo<TRenderCellDataBatchInfosLocationsRow>(() => ({
    id: (value) => value,
    'location-name': (value) => value,
    updated_at: (value) => value,
    history: () => (
      <IconButton
        onClick={() => handleOpenLocationLogDialog()}
        tooltip="Visualizar Histórico de Ações da Postagem"
        showCustomIcon
        CustomIcon={History}
      />
    ),
    status: (value) => (isFetchingStatus ? (
      'Atualizando status...'
    ) : (
      <BatchStatusIcon status={value} batchType="info" />
    )),
    actions: (value) => value,
  }), [isFetchingStatus, handleOpenLocationLogDialog]);

  return (
    <TableBodyRow key={batchInfosLocationsId} checked={checked} setChecked={setChecked} hiddenCheckbox>
      {currentRowData.map(
        (dataColumnCell: any) => dataColumnCell?.visible && (
        <TableBodyCell>
          {renderCellData[dataColumnCell.columnRef as TBatchInfosLocationsRowColumnsName]?.(dataColumnCell.value)}
        </TableBodyCell>
        ),
      )}
      <TableBodyCell>
        <StyledActionsWrap>
          {isLoading && (
            <StyledActionLoadingWrap>
              <Loading className="action-loading" />
              Aplicando ação...
            </StyledActionLoadingWrap>
          )}

          {
            !isLoading && (
              <>
                {
                  !isNil(batchInfosLocationMapsUrl)
                  && (
                    <IconButton
                      tooltip="Visualizar local"
                      onClick={showBatchInfosLocation}
                      icon="VisibilityOutlined"
                    />
                  )
                }
                {
                  batchInfosLocationStatus === 'ERROR'
                  && !isFetchingStatus
                  && (
                    <IconButton
                      tooltip="Forçar atualização"
                      onClick={handleForceUpdateBatchInfosLocations}
                      icon="CloudUpload"
                    />
                  )
                }
                {
                  batchInfosLocationStatus === 'PENDING'
                  && (
                    <IconButton
                      tooltip="Cancelar atualização"
                      onClick={cancelBatchInfosLocations}
                      icon="Cancel"
                    />
                  )
                }
              </>
            )
          }
        </StyledActionsWrap>
      </TableBodyCell>

      {openBatchInfosLocationsLog && (
        <LogHistoryData
          open={openBatchInfosLocationsLog}
          batchLocationName={locationLogName as string}
          data={logData}
          close={() => setOpenBatchInfosLocationsLog(state => !state)}
        />
      )}
    </TableBodyRow>
  );
};
