import React, { useState } from 'react';
import * as R from 'ramda';
import { useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { CircularProgress } from '@material-ui/core';

import {
  ItemBoard,
} from '../../components';

import getGroups from '../../services/groups/getGroups';
import getFunctions from '../../services/functions/getFunctions';
import createMenu from '../../services/menus/createMenu';
import getMenu from '../../services/menus/getMenu';
import updateMenu from '../../services/menus/updateMenu';

const CreateOrUpdateMenu = () => {
  const history = useHistory();
  const params = useParams();
  const [loading, setLoading] = useState(false);
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [slug, setSlug] = useState('');
  const [groupsToAdd, setGroupsToAdd] = useState([]);
  const [addedGroups, setAddedGroups] = useState([]);
  const [functionsToAdd, setFunctionsToAdd] = useState([]);
  const [addedFunctions, setAddedFunctions] = useState([]);

  const { user } = useSelector((state) => state.AuthReducer);

  const { id } = params;

  React.useEffect(() => {
    const fetchData = async () => {
      const groupsResponse = await getGroups({ accessToken: user.accessToken });

      if (R.isNil(groupsResponse)) return;

      const groupData = groupsResponse[0];
      const [functionBruteData] = await getFunctions(user.accessToken);

      const functionData = [];
      for (let i = 0; i < functionBruteData.length; i++) {
        if (
          functionBruteData[i].direct_access
          && functionBruteData[i].is_available_in_menu
        ) {
          functionData.push(functionBruteData[i]);
        }
      }

      if (id) {
        const menuData = await getMenu(user.accessToken, id);
        setName(menuData.name);
        setDescription(menuData.description);
        setSlug(menuData.slug);
        setAddedGroups(menuData.groups);
        setAddedFunctions(menuData.functions);

        menuData.groups.map((element) => {
          const { id } = element;
          for (let i = 0; i < groupData.length; i++) {
            if (groupData[i].id === id) {
              groupData.splice(i, 1);
            }
          }
        });

        menuData.functions.map((element) => {
          const { id } = element;
          for (let j = 0; j < functionData.length; j++) {
            if (functionData[j].id === id) {
              functionData.splice(j, 1);
            }
          }
        });
      }

      setGroupsToAdd(groupData);
      setFunctionsToAdd(functionData);
    };

    fetchData();
  }, []);

  const onAvailableGroupClicked = (id) => {
    const group = groupsToAdd.filter((element) => element.id === id);
    console.log(addedGroups);
    setAddedGroups([...addedGroups, group[0]]);
    setGroupsToAdd(groupsToAdd.filter((item) => item.id !== group[0].id));
  };

  const onAddedGroupClicked = (id) => {
    const group = addedGroups.filter((element) => element.id === id);

    setGroupsToAdd([...groupsToAdd, group[0]]);
    setAddedGroups(addedGroups.filter((item) => item.id !== group[0].id));
  };

  const onAvailableFunctionClicked = (id) => {
    const func = functionsToAdd.filter((element) => element.id === id);
    setAddedFunctions([...addedFunctions, func[0]]);
    setFunctionsToAdd(functionsToAdd.filter((item) => item.id !== func[0].id));
  };

  const onAddedFunctionClicked = (id) => {
    const func = addedFunctions.filter((element) => element.id === id);
    setFunctionsToAdd([...functionsToAdd, func[0]]);
    setAddedFunctions(addedFunctions.filter((item) => item.id !== func[0].id));
  };

  const handleSetName = ({ target: { value } }) => {
    setName(value);
  };

  const handleSetDescription = ({ target: { value } }) => {
    setDescription(value);
  };

  const handleSetSlug = ({ target: { value } }) => {
    setSlug(value);
  };

  const handleOnCreateMenuClicked = async () => {
    setLoading(true);
    const groupIds = [];
    addedGroups.map((element) => {
      groupIds.push(element.id);
    });
    const functionIds = [];
    addedFunctions.map((element) => {
      functionIds.push(element.id);
    });
    const menu = await createMenu(
      user.accessToken,
      name,
      description,
      slug,
      addedGroups,
      addedFunctions,
      user.user.id,
    );
    console.log(menu);
    setLoading(false);
    history.push('/menus/list');
  };
  const handleOnUpdateMenuClicked = async () => {
    setLoading(true);

    const menu = await updateMenu(
      user.accessToken,
      id,
      name,
      description,
      addedGroups,
      addedFunctions,
      slug,
    );

    setLoading(false);

    history.push('/menus/list');
  };

  const renderButton = () => {
    if (loading) return <CircularProgress />;
    return (
      <button
        className="btn btn-primary button-margin-top font-poppins"
        onClick={id ? handleOnUpdateMenuClicked : handleOnCreateMenuClicked}
      >
        {id ? 'Salvar Menu' : 'Criar Menu'}
      </button>
    );
  };

  return (
    <div style={{ padding: '1em' }}>
      <div className="title-div">
        <text className="font-poppins page-title">
          {id ? 'Editar Menu' : 'Cadastro de menu'}
        </text>
      </div>
      <label className="font-poppins">Nome</label>
      <input
        className="form-control font-poppins"
        name="name"
        value={name}
        onChange={handleSetName}
      />

      <label className="font-poppins">Descrição</label>
      <input
        className="form-control font-poppins"
        name="description"
        value={description}
        onChange={handleSetDescription}
      />
      <label className="font-poppins">Slug</label>
      <input
        className="form-control font-poppins"
        name="slug"
        value={slug}
        onChange={handleSetSlug}
      />

      <ItemBoard
        availableItemText="Grupos Disponíveis"
        itemsToAdd={groupsToAdd}
        onAvailableItemClicked={onAvailableGroupClicked}
        addedItemText="Grupos Adicionados"
        addedItems={addedGroups}
        onAddedItemClicked={onAddedGroupClicked}
      />

      <ItemBoard
        availableItemText="Funções Disponíveis"
        itemsToAdd={functionsToAdd}
        onAvailableItemClicked={onAvailableFunctionClicked}
        addedItemText="Funções Adicionadas"
        addedItems={addedFunctions}
        onAddedItemClicked={onAddedFunctionClicked}
      />

      <div className="create-button-div">{renderButton()}</div>
    </div>
  );
};

export default CreateOrUpdateMenu;
