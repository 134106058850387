import React, { useCallback } from 'react';
import { isNil } from 'ramda';
import { useSelector } from 'react-redux';

import {
  StyledAddress,
  StyledLinkLocation,
  StyledLocationListItem,
  StyledLocationNameWrapper
} from './location-list-item-styles';

import { TLocationListItemProps } from './types';
import { TRootStateRedux } from '../../../../../../types/TRootStateRedux';
import { LocationConnectionPlatforms, LocationConnectionStatusEnum } from '../../../../../../types/TPlatformsLinkWithLocation';

import { Loading } from '../../../../../../components';
import { getButtonConfig } from '../../helpers';
import isNilOrEmpty from '../../../../../../utils/is-null-or-empty';

import LinkedLocation from './linked-location';
import ConfirmLinkDialog from './confirm-link-dialog';

import APIGetAllConnectionsByFacebookPageId from '../../../../../../services/location-connections/getAllLocationConnectionsFromFacebookLocation'
import APICreateLocationConnection from '../../../../../../services/location-connections/createLocationConnection';
import APIDeleteAllConnectionsFromLocation from '../../../../../../services/location-connections/deleteAllConnectionsFromLocation';
import APIRefreshPageToken from '../../../../../../services/facebook/refresh-page-token';

const LocationListItem = ({
  facebookUserAccessToken,
  facebookUserEmail,
  facebookPage,
  pageId,
  isAdminOrOwner,
  userAccessToken,
  userId,
  userSetTokenLikeExpired,
  locationConnectionList,
  setLocationConnectionList,
}: TLocationListItemProps) => {
  const { activeLocation } = useSelector((state: TRootStateRedux) => state.LocationReducer);

  const [linkLocationLoading, setLinkLocationLoading] = React.useState(false);
  const [confirmDialogIsOpen, setConfirmDialogIsOpen] = React.useState(false);
  const [facebookPagesConnections, setFacebookPagesConnections] = React.useState<any[]>([]);

  const updateFacebookPageId = React.useCallback(async () => {
    setLinkLocationLoading(true);

    const permanentPageAccessToken = await APIRefreshPageToken({
      accessToken: userAccessToken,
      facebookPageId: facebookPage.id,
      setTokenLikeExpired: userSetTokenLikeExpired,
      facebookUserAccessToken: facebookUserAccessToken
    })

    // TODO: Validar se o token é valido por 2 meses ou se é de fato eterno
    const tokenExpiresAt = new Date();
    tokenExpiresAt.setMonth(tokenExpiresAt.getMonth() + 2);

    const createdConnection = await APICreateLocationConnection({
      accessToken: userAccessToken,
      platform: LocationConnectionPlatforms.FACEBOOK,
      token: permanentPageAccessToken,
      tokenExpiresAt: tokenExpiresAt.toUTCString(),
      setTokenLikeExpired: userSetTokenLikeExpired,
      createdBy: userId,
      location: activeLocation,
      status: LocationConnectionStatusEnum.VALID,
      connectionInfo: {
        facebook_page_id: facebookPage.id,
        user_email: facebookUserEmail,
        location_name: facebookPage.name,
        address: facebookPage.single_line_address,
      },
    });

    if (isNil(createdConnection)) {
      setLinkLocationLoading(false);
      return;
    }

    setLocationConnectionList(prev => [...prev, createdConnection]);
    setLinkLocationLoading(false);
  }, [activeLocation]);

  const getLocationConnectionsFromFacebookPageId = React.useCallback(async () => {
    const locationConnectionsByFacebookPage = await APIGetAllConnectionsByFacebookPageId({
      accessToken: userAccessToken,
      facebook_page_id: facebookPage.id,
    });

    if (isNil(locationConnectionsByFacebookPage)) return;
    const activeLocationConnectionIds = locationConnectionList.map((connection) => connection.id);

    const filteredLocationConnectionsByFacebookPage = locationConnectionsByFacebookPage
      .filter((connection) => !activeLocationConnectionIds.includes(connection.id));

    setFacebookPagesConnections(filteredLocationConnectionsByFacebookPage);
  }, [locationConnectionList]);

  React.useEffect(() => {
    getLocationConnectionsFromFacebookPageId();
  }, [getLocationConnectionsFromFacebookPageId]);

  const handleLinkLocation = useCallback(async (isConfirmationNeeded: boolean) => {
    if (isConfirmationNeeded) {
      setConfirmDialogIsOpen(true);
      return;
    }

    updateFacebookPageId();
  }, [activeLocation]);

  const buttonConfig = getButtonConfig(
    locationConnectionList,
    pageId,
    isAdminOrOwner,
    userId,
  );

  const isConfirmationNeeded = buttonConfig ? buttonConfig.message.startsWith('Trocar') : false;

  const handleLinkSwitch = React.useCallback(async () => {
    const deleteAllActiveLinksResponse = await APIDeleteAllConnectionsFromLocation({ accessToken: userAccessToken, locationId: activeLocation.id });
  
    if (isNil(deleteAllActiveLinksResponse)) return;

    const permanentPageAccessToken = await APIRefreshPageToken({
      accessToken: userAccessToken,
      facebookPageId: facebookPage.id,
      setTokenLikeExpired: userSetTokenLikeExpired,
      facebookUserAccessToken: facebookUserAccessToken
    })

    // TODO: Validar se o token é valido por 2 meses ou se é de fato eterno
    const tokenExpiresAt = new Date();
    tokenExpiresAt.setMonth(tokenExpiresAt.getMonth() + 2);

    const createdConnection = await APICreateLocationConnection({
      accessToken: userAccessToken,
      platform: LocationConnectionPlatforms.FACEBOOK,
      token: permanentPageAccessToken,
      tokenExpiresAt: tokenExpiresAt.toUTCString(),
      setTokenLikeExpired: userSetTokenLikeExpired,
      createdBy: userId,
      location: activeLocation,
      status: LocationConnectionStatusEnum.VALID,
      connectionInfo: {
        facebook_page_id: facebookPage.id,
        user_email: facebookUserEmail,
        location_name: facebookPage.name,
        address: facebookPage.single_line_address,
      },
    });


    if (isNil(createdConnection)) {
      setLocationConnectionList([]);
      setConfirmDialogIsOpen(false);
      return;
    }
    setLocationConnectionList([createdConnection]);
    setConfirmDialogIsOpen(false);
  }, [activeLocation]);

  return (
    <StyledLocationListItem>
      <StyledLocationNameWrapper>
        <h4>{facebookPage.name}</h4>
        {!isNil(facebookPage.single_line_address) && (
          <StyledAddress>
            { facebookPage.single_line_address }
          </StyledAddress>
        )}
      </StyledLocationNameWrapper>

      {isNilOrEmpty(facebookPagesConnections) && !isNilOrEmpty(buttonConfig) && (
        <StyledLinkLocation
          isLoading={linkLocationLoading}
          disabledLink={buttonConfig.disabled}
          onClick={() => handleLinkLocation(isConfirmationNeeded)}
          isLinked={buttonConfig.message === 'Conectado'}
        >
          <>
            {linkLocationLoading && (
              <Loading className="is-link-load" />
            )}
            {linkLocationLoading ? 'Carregando...' : `${buttonConfig.message}`}
          </>
        </StyledLinkLocation>
      )}
      {!isNilOrEmpty(facebookPagesConnections)
            && (
              <LinkedLocation
                location={facebookPagesConnections[0].location}
              />
            )}
      {confirmDialogIsOpen
        && (
        <ConfirmLinkDialog
          onClose={() => setConfirmDialogIsOpen(false)}
          isDialogOpen={confirmDialogIsOpen}
          onConfirm={handleLinkSwitch}
        />
        )}

    </StyledLocationListItem>
  );
};

export default LocationListItem;
