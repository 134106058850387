import * as R from 'ramda';

import { TIsUserGuestOfCompany } from '../types/TUtils';

const isUserGuestOfCompany = ({
  currentCompany = null,
  userId,
  userProfileName,
}: TIsUserGuestOfCompany) => {
  if (userProfileName !== 'Usuário Padrão') return false;
  if (R.isNil(currentCompany)) return false;
  if (R.isNil(currentCompany?.id)) return false;

  const isUserGuest = currentCompany.owner_user?.id !== userId
    && R.isEmpty(currentCompany.guests?.filter(guest => guest?.user?.id === userId));

  return isUserGuest;
};

export default isUserGuestOfCompany;
