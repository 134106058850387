import React from 'react';
import * as R from 'ramda';
import { debounce } from 'lodash';

import DropDownTimes from './dropdowm-times';

import { TTimeBaseProps } from '../../types/TWorkingHours';

import {
  StyledTimeInput,
  StyledTime,
  StyledLabel,
} from './time-styles';

const Time = ({
  label = 'Abre/Fecha',
  dropDownToBottom = false,
  filter = '00:00',
  filterType = 'min',
  className = '',
  timeValue = '00:00',
  initialValue = '00:00',
  setTimeValue,
  readonly,
  columnAlign,
}: TTimeBaseProps) => {
  const [isOpenDropDownTimes, setIsOpenDropDownTimes] = React.useState(false);
  const [timeInputValue, setTimeInputValue] = React.useState(initialValue);

  React.useEffect(() => {
    setTimeInputValue(timeValue);
  }, [timeValue]);

  const handleOpenDropDownTimes = () => {
    setIsOpenDropDownTimes(!isOpenDropDownTimes);
  };

  const handleCloseDropDownTimes = debounce(() => {
    setIsOpenDropDownTimes(!isOpenDropDownTimes);
  }, 300);

  const handleSetTimeFromDropDownItem = (newCurrentTimeValue: string) => {
    if (newCurrentTimeValue === '24 horas') return;

    if (!R.isEmpty(newCurrentTimeValue) && !R.isNil(newCurrentTimeValue)) {
      setTimeInputValue(timeValue);
      setTimeValue(newCurrentTimeValue);
      setIsOpenDropDownTimes(!isOpenDropDownTimes);
    }
  };

  const handleHandleOnchangeTimeInput = ({
    target,
  }: React.ChangeEvent<HTMLInputElement>) => {
    setTimeInputValue(target.value);
    setTimeValue(target.value);
  };

  return (
    <StyledTime className={className} columnAlign={columnAlign}>
      <StyledLabel columnAlign={columnAlign}>{label}</StyledLabel>
      <StyledTimeInput
        mask="99:99"
        placeholder="00:00"
        value={timeInputValue}
        onChange={handleHandleOnchangeTimeInput}
        onFocus={handleOpenDropDownTimes}
        onBlur={handleCloseDropDownTimes}
        disabled={readonly}
      />
      {isOpenDropDownTimes && (
        <DropDownTimes
          filter={filter}
          filterType={filterType}
          timeInputValue={timeInputValue}
          dropDownToBottom={dropDownToBottom}
          setTimeFromItem={handleSetTimeFromDropDownItem}
        />
      )}
    </StyledTime>
  );
};

export default React.memo(Time);
