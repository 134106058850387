export default function formatSpecialDates(specialDates) {
  const specialHoursPeriods = [];
  for (let i = 0; i < specialDates.length; i++) {
    const {
      date, is_open: isOpen, opening_hour: openTime, closing_hour: closeTime,
    } = specialDates[i];

    const [year, month, day] = date.split('-');

    specialHoursPeriods.push({
      startDate: { year, month, day },
      endDate: { year, month, day },
      closed: !isOpen,
      openTime,
      closeTime,
    });
  }

  return specialHoursPeriods;
}
