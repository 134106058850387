import React from 'react';
import { useDispatch } from 'react-redux';
import FadeInEffect from 'react-fade-in';
import * as R from 'ramda';
import { GoogleLogin, GoogleLogout } from 'react-google-login';
import { debounce } from 'lodash';

import getUserAssociatedAccounts from '../../../services/google/getUserAssociatedAccounts';
import getGoogleUserLocations from '../../../services/google/getGoogleUserLocations';
import getCompanyLocationsLinkedWithGoogle from '../../../services/locations/getCompanyLocationsLinkedWithGoogle';
import getAllCompaniesWhereUserIsGuest from '../../../services/guest/getAllCompaniesWhereUserIsGuest';
import generateRefreshToken from '../../../services/google/generateRefreshToken';
import updateUserProfile from '../../../services/users/updateUserProfile';
import getUserConnections from '../../../services/connections/getUserConnections';
import updateConnection from '../../../services/connections/updateConnection';
import createConnection from '../../../services/connections/createConnection';
import getValidGoogleAccessToken from '../../../services/getValidGoogleAccessToken';

import { Creators as CompanyActions } from '../../../redux/ducks/company';
import { Creators as AuthActions } from '../../../redux/ducks/auth';

import {
  IconButton,
  Loading,
  DropDownDialog,
  Input,
  GooglePagination,
} from '../../../components';

import Context from '../context';

import { getUserAssociatedRegisters } from './helpers';

import { Google as GoogleIcon } from '../platforms-icons';

import LocationRow from './location-row';

import {
  StyledContent,
  StyledGoogle,
  StyledHeader,
  StyledHeaderPlatform,
  StyledHeaderActions,
  StyledLocationsList,
  StyledEmptyBindedLocations,
  StyledHeaderWrapper,
  StyledLocationsWrapper,
  StyledLoadingWrapper,
} from './google-style';

const Google = ({ isNoClient }) => {
  const {
    activeCompany,
    enqueueSnackbar,
    setIsLoadinInformationsText,
    setIsLoadingInformations,
    user,
    accessToken,
    setTokenLikeExpired,
    setReviwesByLocation,
    setReviewsByLocationFiltered,
    setGoogleAccessToken,
    companyLocations,
    getCompanyLocations,
    activeCompanyId,
    userCompanies,
    userProfile,
  } = React.useContext(Context);

  const reduxDispatch = useDispatch();

  const [isLoggedInGoogle, setIsLoggedInGoogle] = React.useState(false);
  const [isLoadingLocationRows, setIsLoadingLocationRows] = React.useState(false);
  const [loggedUserLocations, setLoggedUserLocations] = React.useState([]);

  const [isShowLocation, setIsShowLocation] = React.useState(false);

  const [activeAccountsNextPageToken, setActiveAccountsNextPageToken] = React.useState(null);
  const [accountsNextPageToken, setAccountsNextPageToken] = React.useState(null);
  const [activeLocationsNextPageToken, setActiveLocationsNextPageToken] = React.useState(null);
  const [locationsNextPageToken, setLocationsNextPageToken] = React.useState(null);
  const [googleAccessTokenState, setGoogleAccessTokenState] = React.useState('');
  const [dropDownLocationGroups, setDropDownLocationGroups] = React.useState(
    [],
  );
  const [activeGoogleAccount, setActiveGoogleAccount] = React.useState(null);
  const [googlePaginationLoading, setGooglePaginationLoading] = React.useState(false);
  const [locationsSearchInput, setLocationsSearchInput] = React.useState('');

  const [googlePaginationPageList, setGooglePaginationPageList] = React.useState(['']);

  const [
    googlePaginationLocationPageIndex,
    setGooglePaginationLocationPageIndex,
  ] = React.useState(1);
  const [
    googlePaginationLocationPageList,
    setGooglePaginationLocationPageList,
  ] = React.useState(['']);

  const setAccountsInDropdown = async (
    googleAccessToken,
    activeNextPageToken,
    setDropDownAccounts,
    setNextPageToken,
  ) => {
    const { accounts, nextPageToken } = await getUserAssociatedAccounts({
      accessToken: googleAccessToken,
      pageToken: activeNextPageToken,
      setGetUserAssociatedAccounts: setGooglePaginationLoading,
    });

    const formattedGoogleAccounts = accounts.map((account) => ({
      name: account.accountName,
      id: account.name.split('/')[1],
    }));

    setDropDownAccounts(formattedGoogleAccounts);
    setNextPageToken(nextPageToken);
  };

  const getAllUserElligibleLocations = async (allUserGoogleLocationsData) => {
    if (R.isNil(await allUserGoogleLocationsData)) {
      setIsLoadingInformations(false);

      return null;
    }

    const { userGoogleMyBusinessLocations, generatedAccessToken } = await allUserGoogleLocationsData;

    setIsLoadinInformationsText('Carregando todos os locais elegíveis...');

    const companyLocationsWithLinkedGoogle = await getCompanyLocationsLinkedWithGoogle({
      accessToken,
      companyId: activeCompanyId,
      setTokenLikeExpired,
      setIfFetching: setIsLoadingInformations,
    });

    if (
      R.isNil(companyLocationsWithLinkedGoogle)
      || R.isEmpty(companyLocationsWithLinkedGoogle)
    ) return null;

    const allCompanyLocationsLinkedWithGoogle = companyLocationsWithLinkedGoogle[0] || [];

    if (
      R.isNil(allCompanyLocationsLinkedWithGoogle)
      || R.isEmpty(allCompanyLocationsLinkedWithGoogle)
    ) return null;

    const companyLocationsLinkedGoogleOnlyId = allCompanyLocationsLinkedWithGoogle.map(
      (location) => location.google_location_id,
    );

    const allEligibleLocations = await userGoogleMyBusinessLocations.filter(
      ({ name: googleLocationNameAndId }) => {
        const locationId = googleLocationNameAndId.split('/')[3];

        return R.includes(locationId, companyLocationsLinkedGoogleOnlyId);
      },
    );

    return (
      {
        generatedAccessToken,
        allEligibleLocations,
      } || null
    );
  };

  const loadGoogleLocations = async (googleAccountsData) => {
    if (R.isNil(await googleAccountsData)) return null;

    const { userGoogleAccounts, generatedAccessToken } = await googleAccountsData;

    if (Array.isArray(userGoogleAccounts)) {
      setIsLoadinInformationsText('Carregando locais...');
      setIsLoadingInformations(true);

      const googleMyBusinessLocations = await Promise.all(
        userGoogleAccounts.map(async ({ name: accountGroupName }) => {
          const accountId = accountGroupName.split('/')[1];

          const accountGroupLocations = await getUserAssociatedRegisters({
            accessToken: generatedAccessToken,
            userId: accountId,
            dataKeyName: 'locations',
            APIToGetUserAssociated: getGoogleUserLocations,
          });

          return accountGroupLocations;
        }),
      );

      const googleMyBusinessLocationsFiltered = R.flatten(
        googleMyBusinessLocations.filter((locations) => !R.isNil(locations)),
      );

      return (
        {
          generatedAccessToken,
          userGoogleMyBusinessLocations: googleMyBusinessLocationsFiltered,
        } || null
      );
    }
  };

  const loadGoogleAccounts = async (generatedAccessToken) => {
    if (R.isNil(generatedAccessToken)) {
      setIsLoadingInformations(false);

      return null;
    }

    const userGoogleAccounts = await getUserAssociatedRegisters({
      APIToGetUserAssociated: getUserAssociatedAccounts,
      accessToken: generatedAccessToken,
      dataKeyName: 'accounts',
      setGetUserAssociatedAccounts: setIsLoadingInformations,
    });

    return (
      {
        userGoogleAccounts,
        generatedAccessToken,
      } || null
    );
  };

  const getLocationsReviewsByAccount = R.pipe(
    loadGoogleAccounts,
    loadGoogleLocations,
    getAllUserElligibleLocations,
  );

  const generateAccessToken = React.useCallback(async () => {
    setReviwesByLocation([]);
    setReviewsByLocationFiltered([]);

    if (!activeCompany || Array.isArray(activeCompany)) {
      const guestCompanies = await getAllCompaniesWhereUserIsGuest({
        accessToken,
        userId: user.user.id,
      });

      if (R.isNil(userCompanies)) return;

      if (userCompanies.length > 0) {
        reduxDispatch(CompanyActions.setActiveCompany(userCompanies[0]));
      } else if (guestCompanies[0].length > 0) {
        reduxDispatch(CompanyActions.setActiveCompany(guestCompanies[0][0]));
      }
    }

    setIsLoadinInformationsText('Gerando token de acesso...');

    const generatedAccessToken = await getValidGoogleAccessToken({
      userId: user.user.id,
      accessToken,
      feedbackMessage: enqueueSnackbar,
      userProfile: userProfile.name,
      userGoogleRefreshToken: user.user.google_refresh_token,
    });

    if (!R.isNil(generatedAccessToken) && !R.isEmpty(generatedAccessToken)) {
      getLocationsReviewsByAccount(generatedAccessToken);
      setGoogleAccessToken(generatedAccessToken);
    }
  }, [activeCompany, user]);

  React.useEffect(() => {
    generateAccessToken();
  }, [generateAccessToken]);

  const handleIsShowLocation = () => {
    setIsShowLocation(!isShowLocation);
  };

  const googleSuccessResponse = async (response) => {
    const { code } = response;
    const tokenData = await generateRefreshToken({ authorizationCode: code });

    setIsLoadingLocationRows(true);

    const {
      access_token: responseAccessToken,
      refresh_token: refreshToken,
      expires_in: expiresIn,
    } = tokenData;

    const tokenExpirationDate = new Date();
    tokenExpirationDate.setSeconds(
      tokenExpirationDate.getSeconds() + expiresIn,
    );

    const connectionInfoToUpdate = {
      token: responseAccessToken,
      code,
      token_expires_in: tokenExpirationDate.toUTCString(),
    };

    if (refreshToken) {
      await updateUserProfile({
        accessToken,
        id: user.user.id,
        updateProfileData: {
          google_refresh_token: refreshToken,
        },
      });

      connectionInfoToUpdate.refresh_token = refreshToken;

      reduxDispatch(
        AuthActions.setUser({
          ...user,
          user: { ...user.user, google_refresh_token: refreshToken },
        }),
      );
    }

    const [googleConnectionsData, googleConnectionsAmount] = await getUserConnections({
      accessToken,
      query: 'google',
      userId: user.user.id,
    });

    if (googleConnectionsAmount > 0) {
      await updateConnection({
        accessToken,
        connectionId: googleConnectionsData[0].id,
        inputData: connectionInfoToUpdate,
        setTokenLikeExpired,
      });
    } else {
      await createConnection({
        accessToken,
        userId: user.user.id,
        channel: 'google',
        code: connectionInfoToUpdate.code,
        token: connectionInfoToUpdate.token,
        refreshToken,
        tokenExpiresIn: connectionInfoToUpdate.token_expires_in,
        setTokenLikeExpired,
        createdBy: user.user.id,
      });
    }

    setGoogleAccessTokenState(responseAccessToken);

    const accountsData = await getUserAssociatedAccounts({
      accessToken: responseAccessToken,
    });
    const {
      accounts: responseGoogleAccounts,
      nextPageToken: nextPageTokenForAccounts,
    } = accountsData;

    const formattedGoogleAccounts = responseGoogleAccounts.map(
      (account, index) => ({
        name: index === 0 ? 'Desagrupada' : account.accountName,
        id: account.name.split('/')[1],
      }),
    );

    setAccountsNextPageToken(nextPageTokenForAccounts);

    setDropDownLocationGroups(formattedGoogleAccounts);

    setActiveGoogleAccount(formattedGoogleAccounts[0]);

    setIsLoadingLocationRows(false);
    setIsLoggedInGoogle(true);
  };

  const setGoogleAccountLocations = React.useCallback(async () => {
    let locationsData;
    let nextPageTokenData;
    if (activeGoogleAccount) {
      if (activeLocationsNextPageToken) {
        const { locations, nextPageToken } = await getGoogleUserLocations({
          accessToken: googleAccessTokenState,
          userId: activeGoogleAccount.id,
          setIsFetching: setIsLoadingLocationRows,
          pageToken: activeLocationsNextPageToken,
          query: locationsSearchInput,
        });
        locationsData = locations;
        nextPageTokenData = nextPageToken;
      } else {
        const { locations, nextPageToken } = await getGoogleUserLocations({
          accessToken: googleAccessTokenState,
          userId: activeGoogleAccount.id,
          setIsFetching: setIsLoadingLocationRows,
          query: locationsSearchInput,
        });

        locationsData = locations;
        nextPageTokenData = nextPageToken;
      }

      setLocationsNextPageToken(nextPageTokenData);
      setLoggedUserLocations(locationsData);
    }
  }, [activeGoogleAccount, activeLocationsNextPageToken, locationsSearchInput]);

  const getGoogleAccountsNextPage = React.useCallback(async () => {
    if (activeAccountsNextPageToken) {
      await setAccountsInDropdown(
        googleAccessTokenState,
        activeAccountsNextPageToken,
        setDropDownLocationGroups,
        setAccountsNextPageToken,
      );
    }
  }, [activeAccountsNextPageToken]);

  React.useEffect(() => {
    getGoogleAccountsNextPage();
  }, [getGoogleAccountsNextPage]);

  React.useEffect(() => {
    setGoogleAccountLocations();
  }, [setGoogleAccountLocations]);

  const googleLogoutSuccess = () => {
    setIsLoggedInGoogle(false);
    setIsShowLocation(false);
  };

  const onChangeActiveAccount = (e) => {
    const account = dropDownLocationGroups.filter(
      (element) => element.id === e,
    )[0];
    setActiveGoogleAccount(account);
  };

  const onNextPageClicked = () => {
    setActiveAccountsNextPageToken(accountsNextPageToken);
  };

  const onBackPageClicked = () => {
    const removeLastPositionFromList = googlePaginationPageList.pop();
    if (R.isNil(removeLastPositionFromList)) return;

    const lastTokenFromList = R.last(googlePaginationPageList);
    if (R.isNil(lastTokenFromList)) return;

    setActiveAccountsNextPageToken(lastTokenFromList);
  };

  const onLocationsNextPageClicked = () => {
    setActiveLocationsNextPageToken(locationsNextPageToken);
  };

  const onLocationsBackPageClicked = () => {
    const removeLastPositionFromList = googlePaginationLocationPageList.pop();
    if (R.isNil(removeLastPositionFromList)) return;

    const lastTokenFromList = R.last(googlePaginationLocationPageList);
    if (R.isNil(lastTokenFromList)) return;

    setActiveLocationsNextPageToken(lastTokenFromList);
  };

  const onResetAccountsPaginationClicked = () => {
    setGooglePaginationPageList(['']);
    setActiveAccountsNextPageToken(null);
    setAccountsInDropdown(
      googleAccessTokenState,
      '',
      setDropDownLocationGroups,
      setAccountsNextPageToken,
    );
  };

  const onResetLocationsPaginationClicked = async () => {
    setGooglePaginationLocationPageList(['']);
    setActiveLocationsNextPageToken(null);
    const { locations, nextPageToken } = await getGoogleUserLocations({
      accessToken: googleAccessTokenState,
      userId: activeGoogleAccount.id,
      setIsFetching: setIsLoadingLocationRows,
      pageToken: null,
    });
    setLocationsNextPageToken(nextPageToken);
    setLoggedUserLocations(locations);
  };

  const onChangeLocationSearchInput = debounce(
    (text) => setLocationsSearchInput(text),
    500,
  );

  return (
    <StyledGoogle>
      <StyledHeader>
        <StyledHeaderPlatform>
          <GoogleIcon className="platform-icon" />
          <h3>Google</h3>
        </StyledHeaderPlatform>

        <StyledHeaderActions>
          {!isLoggedInGoogle && isNoClient ? (
            <GoogleLogin
              clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}
              onSuccess={googleSuccessResponse}
              onFailure={() => {
                setIsLoadingLocationRows(false);
              }}
              scope="https://www.googleapis.com/auth/business.manage"
              prompt="consent"
              responseType="code"
              uxMode="redirect"
              redirectUri="https://saas.hublocal.com.br/receive-foursquare-code"
              render={(renderProps) => (isLoadingLocationRows ? (
                <Loading />
              ) : (
                <IconButton
                  tooltip="Conectar com o Google"
                  icon="Power"
                  onClick={renderProps.onClick}
                />
              ))}
            />
          ) : (
            <>
              {isNoClient && (
                <GoogleLogout
                  clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}
                  onLogoutSuccess={googleLogoutSuccess}
                  render={(renderProps) => (
                    <IconButton
                      className="platform-disconnect"
                      tooltip="Desconectar"
                      icon="Cancel"
                      onClick={renderProps.onClick}
                    />
                  )}
                />
              )}

              <IconButton
                className="platform-connected"
                tooltip="Conectado"
                icon="CheckCircle"
              />

              {isNoClient
                && (isShowLocation ? (
                  <IconButton
                    onClick={handleIsShowLocation}
                    className="platform-show-location"
                    tooltip="Fechar locais"
                    icon="ArrowDropUp"
                  />
                ) : (
                  <IconButton
                    onClick={handleIsShowLocation}
                    className="platform-show-location"
                    tooltip="Exibir locais"
                    icon="ArrowDropDown"
                  />
                ))}
            </>
          )}
        </StyledHeaderActions>
      </StyledHeader>

      {isNoClient && (
        <StyledContent>
          {isShowLocation && (
            <StyledLocationsWrapper>
              <StyledHeaderWrapper>
                <DropDownDialog
                  title="Agrupamentos"
                  icon="Business"
                  currentOption={activeGoogleAccount}
                  options={dropDownLocationGroups}
                  classNameDropDownContainer="google-review-config-dropdown"
                  pageQuantity={10}
                  googlePagination
                  nextPageToken={accountsNextPageToken}
                  onNextPageClicked={onNextPageClicked}
                  onBackPageClicked={onBackPageClicked}
                  onResetClicked={onResetAccountsPaginationClicked}
                  onChange={onChangeActiveAccount}
                  googlePaginationLoading={googlePaginationLoading}
                  pageList={googlePaginationPageList}
                  setPageList={setGooglePaginationPageList}
                />
              </StyledHeaderWrapper>
              <FadeInEffect>
                <Input
                  className="google-reviews-config-input-search"
                  placeholder="Pesquisar local"
                  border
                  onChange={(e) => onChangeLocationSearchInput(e.target.value)}
                  inputOptions={{
                    type: 'text',
                    name: 'location-grouping-search',
                  }}
                />

                {!isLoadingLocationRows ? (
                  R.isEmpty(loggedUserLocations)
                  || R.isNil(loggedUserLocations) ? (
                    <StyledEmptyBindedLocations>
                      Você não possui nenhum local na sua conta do Google My
                      Business
                    </StyledEmptyBindedLocations>
                    ) : (
                      <>
                        <StyledLocationsList>
                          {loggedUserLocations.map((location) => (
                            <LocationRow
                              key={location.name}
                              locationGoogleName={location.title}
                              locationAddress={location.storefrontAddress}
                              locationNameWithId={`accounts/${activeGoogleAccount.id}/${location.name}`}
                              companyLocations={companyLocations}
                              enqueueSnackbar={enqueueSnackbar}
                              accessToken={accessToken}
                              googleAccessToken={googleAccessTokenState}
                              setTokenLikeExpired={setTokenLikeExpired}
                              loadGoogleLocations={loadGoogleLocations}
                              getCompanyLocations={getCompanyLocations}
                              disabledLink={R.isNil(location.metadata?.hasVoiceOfMerchant)}
                            />
                          ))}
                        </StyledLocationsList>
                        <GooglePagination
                          onNextPageClicked={onLocationsNextPageClicked}
                          onBackPageClicked={onLocationsBackPageClicked}
                          loading={isLoadingLocationRows}
                          onResetClicked={onResetLocationsPaginationClicked}
                          nextPageToken={locationsNextPageToken}
                          pageIndex={googlePaginationLocationPageIndex}
                          setPageIndex={setGooglePaginationLocationPageIndex}
                          pageList={googlePaginationLocationPageList}
                          setPageList={setGooglePaginationLocationPageList}
                        />
                      </>
                    )
                ) : (
                  <StyledLoadingWrapper>
                    <Loading className="loading-location-rows" />
                  </StyledLoadingWrapper>
                )}
              </FadeInEffect>
            </StyledLocationsWrapper>
          )}
        </StyledContent>
      )}
    </StyledGoogle>
  );
};

export default Google;
