import updateCompanyGroup from '../../services/company-groups/update';
import addResponsibles from '../../services/company-groups/addResponsibles';
import removeResponsibles from '../../services/company-groups/removeResponsibles';
import addCompaniesToGroup from '../../services/company-groups/addCompanies';
import removeCompaniesFromGroup from '../../services/company-groups/removeCompanies';

export const saveEditionInDB = async ({
  userHublocalToken,
  setTokenLikeExpired,
  responsibles,
  companyGroupData,
  companyGroupID,
  setIsSaveEdition,
  feedbackMessage,
  redirect,
  companiesToAdd = [],
  companiesToRemove = [],
}) => {
  try {
    if (setIsSaveEdition) setIsSaveEdition(true);

    const { id: mainResponsibleID } = responsibles.filter(responsible => responsible.main === true)[0];
    const responsibleWithoutMain = responsibles.filter(responsible => responsible.main !== true).map(responsible => responsible.id);

    const { name, website, is_active } = companyGroupData;

    await removeResponsibles({
      accessToken: userHublocalToken,
      companyGroupId: companyGroupID,
      responsible_ids: [mainResponsibleID],
      setTokenLikeExpired,
    });

    await addResponsibles({
      accessToken: userHublocalToken,
      companyGroupId: companyGroupID,
      setTokenLikeExpired,
      responsible_ids: responsibleWithoutMain,
    });

    await updateCompanyGroup({
      accessToken: userHublocalToken,
      companyGroupId: companyGroupID,
      name,
      website,
      is_active,
      main_responsible_id: mainResponsibleID,
      feedbackMessage,
      setIsSaveEdition,
      setTokenLikeExpired,
    });

    if (companiesToAdd.length > 0) {
      await addCompaniesToGroup({
        accessToken: userHublocalToken,
        companyGroupId: companyGroupID,
        company_ids: companiesToAdd,
        feedbackMessage,
        setTokenLikeExpired,
      });
    }

    if (companiesToRemove.length > 0) {
      await removeCompaniesFromGroup({
        accessToken: userHublocalToken,
        companyGroupId: companyGroupID,
        company_ids: companiesToRemove,
        feedbackMessage,
        setTokenLikeExpired,
      });
    }

    if (redirect) redirect();
  } catch (err) {
    console.log(err);

    feedbackMessage('Não foi possível atualizar o grupo. Tente novamente!', { variant: 'error' });
  } finally {
    if (setIsSaveEdition) setIsSaveEdition(false);
  }
};
