import {
  Accordion, AccordionSummary, AccordionDetails, Tooltip,
} from '@material-ui/core';
import styled from 'styled-components';

import ErrorIcon from '@material-ui/icons/Error';

export const StyledContainer = styled(Accordion)`
    margin: 2rem;
    border: none;
    border-radius: 6px;

    &.MuiAccordion-root.Mui-expanded {
        margin: 2rem;
    }

    &.MuiAccordion-root:before {
        position: relative;
    }

    &.MuiPaper-elevation1 {
        box-shadow: none;
    }
`;

export const StyledTitle = styled.h1`
    font-size: 1.8rem;
    font-weight: bold;
    line-height: 2rem;
`;

export const StyledTooltip = styled(Tooltip)``;

export const IconTooltip = styled(ErrorIcon)`
    color: ${(props) => props.theme.colors.primary} !important;
    font-size: large;
    margin-left: 0.5rem;
`;

export const StyledAccordionSummary = styled(AccordionSummary)``;

export const StyledAccordionDetails = styled(AccordionDetails)`
    display: flex;
    flex-direction: column;
    
    gap: 3rem;
    margin-bottom: 3rem;
`;

export const StyledGroupButton = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;

`;
