import styled from 'styled-components';
import { shade } from 'polished';

export const StyledInvite = styled.li`
  border-bottom: 0.1rem solid ${props => shade(0.1, props.theme.colors.white)};
  padding-bottom: 1rem;
  margin-bottom: 1rem;
  overflow: hidden;
  justify-content: space-between;

  .pending-invite-email {
    font-size: 1.3rem;
    font-weight: 500;
    line-height: 1.6rem;
  }

  .pending-invite-status {
    font-size: 1.1rem;

    b {
      margin-left: 0.5rem;
    }
  }

  &:last-child {
    border-bottom: none;
    padding-bottom: 0;
    margin-bottom: 0;
  }
`;

export const StyledButtonsContainer = styled.div`
  display: flex;
`;

export const StyledSpan = styled.span``;
