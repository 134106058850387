import React from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import * as R from 'ramda';

import { Creators as CompanyGroupActions } from '../../redux/ducks/company-group';

import { useAuth, useCompanyGroups } from '../../hooks';

import {
  GroupDataTable,
  HeaderToolbar,
  HealderToolbarAction,
} from '../../components';

import { translations } from '../../translations';

import {
  StyledMyCompanyGroups,
  StyledContent,
} from './my-company-groups-styles';

import { getColumnsToTable, getNormalizedDataTable } from './helpers';

const MyCompanies = () => {
  const reduxHistory = useHistory();
  const reduxDispatch = useDispatch();

  const {
    userCompanyGroups,
    setUserCompanyGroupsPageSize,
    setUserCompanyGroupsPageQuery,
    userCompanyGroupsPageQuery,
    setUserCompanyGroupsPageIndex,
    userCompanyGroupsPageIndex,
    userCompanyGroupsCount,
    userCompanyGroupsPageSize,
    userCompanyGroupsLoading,
    getCompanyGroup,
    deleteCompanyGroup,
    getCompanyGroups,
  } = useCompanyGroups();

  const {
    userLanguage,
    userProfileName,
  } = useAuth();

  const [userCompanyGroupsDataTable, setUserCompanyGroupsDataTable] = React.useState([]);
  const [userCompanyGroupsDataTableCount, setUserCompanyGroupsDataTableCount] = React.useState([]);

  const { my_company_groups: myCompanyGroupsTranslation } = translations[userLanguage];

  const mountDataTable = React.useCallback(async () => {
    const normalizeUserCompanyGroupsDataTable = getNormalizedDataTable({
      userCompanyGroups,
      userProfileName,
      translate: myCompanyGroupsTranslation,
    });

    setUserCompanyGroupsDataTable(normalizeUserCompanyGroupsDataTable);
    setUserCompanyGroupsDataTableCount(userCompanyGroupsCount);
  }, [
    userCompanyGroupsLoading,
    userCompanyGroups,
    userCompanyGroupsCount,
  ]);

  React.useEffect(() => {
    mountDataTable();
  }, [mountDataTable]);

  const onUpdateCompanyGroupClicked = async (companyGroupId) => {
    const companyGroup = await getCompanyGroup(companyGroupId);

    if (R.isNil(companyGroup)) return;

    reduxDispatch(CompanyGroupActions.setActiveCompanyGroup(companyGroup));
    reduxHistory.push('/company-groups/update');
  };

  const onDeleteCompanyGroupClicked = async (companyGroupId) => {
    const deleteResponse = await deleteCompanyGroup(companyGroupId);

    if (R.isNil(deleteResponse)) return;

    await getCompanyGroups();
  };

  const handlePushToAddCompanyGroup = () => reduxHistory.push('/company-group/create');

  const columnsToTable = getColumnsToTable({
    translate: myCompanyGroupsTranslation,
    userProfile: userProfileName,
  });

  return (
    <StyledMyCompanyGroups>
      <Helmet>
        <meta property="og:title" content="Minhas Empresas - SaaS Hublocal" />
        <meta property="og:description" content="Gerencie sua empresas." />

        <title>Minhas Empresas - Saas Hublocal</title>
      </Helmet>

      <HeaderToolbar
        title="Grupos Empresariais"
      >
        <HealderToolbarAction
          title="Adicionar Grupo"
          icon="AddBox"
          onClick={handlePushToAddCompanyGroup}
        />
      </HeaderToolbar>

      <StyledContent>
        <GroupDataTable
          subTableDataType="COMPANY"
          count={userCompanyGroupsCount}
          page={userCompanyGroupsPageIndex}
          setPage={setUserCompanyGroupsPageIndex}
          pageSize={userCompanyGroupsPageSize}
          setPageSize={setUserCompanyGroupsPageSize}
          searchQuery={userCompanyGroupsPageQuery}
          setSearchQuery={setUserCompanyGroupsPageQuery}
          loading={userCompanyGroupsLoading}
          title={myCompanyGroupsTranslation.companies}
          data={userCompanyGroupsDataTable}
          columns={columnsToTable}
          subTableColumns={[{ name: 'Id', options: { display: false } }, { name: 'Nome' }]}
          clickable={false}
          firstOperationName="Empresas"
          onFirstOperationClicked={() => {}}
          onUpdateClicked={onUpdateCompanyGroupClicked}
          onDeleteClicked={onDeleteCompanyGroupClicked}
          addRegisterLabel="Adicionar Grupo de Empresas"
          addRegisterLink="/company-group/create"
        />
      </StyledContent>
    </StyledMyCompanyGroups>
  );
};

export default MyCompanies;
