import React from 'react';

import {
  getCategoryGroupingPaginated,
  deleteCategoryGrouping,
  getCategoryGrouping,
} from '../../../services/category-groupings';

import { DataTable } from '../../../components';

import {
  normalizedGroupingsData,
} from './helpers';

import CellInfoCategories from './cell-info-categories';

import {
  StyledGroupsListing,
} from './groups-listing-styles';

const GroupsListing = ({
  setTokenLikeExpired,
  snackbar,
  userToken,
  setUpdateTable,
  updateTable,
  setIsFetchingToEdit,
  setDataToEdit,
}) => {
  const [columnsToTable, setColumnsToTable] = React.useState([]);
  const [dataToTable, setDataToTable] = React.useState([]);

  const [isFetching, setIsFetching] = React.useState(false);
  const [isFecthingDelete, setIsFetchingDelete] = React.useState(false);

  const [searchQuery, setSearchQuery] = React.useState('');
  const [page, setPage] = React.useState(0);
  const [pageSize, setPageSize] = React.useState(10);
  const [dataCount, setDataCount] = React.useState(0);

  const loadCategoryGroupings = React.useCallback(async () => {
    const {
      groupingsData, totalGroupings,
    } = await getCategoryGroupingPaginated({
      accessToken: userToken,
      setIsFetching,
      setTokenLikeExpired,
      page,
      pageSize,
      query: searchQuery,
    });

    const dataToTableNormalized = normalizedGroupingsData(groupingsData);

    setDataCount(totalGroupings);
    setDataToTable(dataToTableNormalized);
    setUpdateTable(false);
  }, [updateTable, page, pageSize, searchQuery]);

  const handleDeleteGrouping = async (categoryGroupingID) => {
    const response = await deleteCategoryGrouping({
      accessToken: userToken,
      setTokenLikeExpired,
      categoryGroupingID,
      setIsFetching: setIsFetchingDelete,
      snackbar,
    });

    if (response?.status === 200) loadCategoryGroupings();
  };

  const handleUpdateGrouping = async (categoryGroupingID) => {
    const response = await getCategoryGrouping({
      accessToken: userToken,
      setTokenLikeExpired,
      groupingID: categoryGroupingID,
      setIsFetching: setIsFetchingToEdit,
      snackbar,
    });

    if (response) {
      setDataToEdit({
        ...response,
        facebook: JSON.parse(response.facebook_categories),
        google: JSON.parse(response.google_categories),
        foursquare: JSON.parse(response.foursquare_categories),
      });
    }
  };

  React.useEffect(() => {
    setColumnsToTable([
      { name: 'id', options: { display: false, sort: false } },
      { name: 'name', label: 'Nome', options: { sort: false } },
      { name: 'description', label: 'Descrição', options: { sort: false } },
      {
        name: 'categories',
        label: 'Categorias',
        options: {
          sort: false,
          customBodyRender: (value, ...args) => (
            <CellInfoCategories categoriesData={value} />
          ),
        },
      },
    ]);

    loadCategoryGroupings();
  }, [loadCategoryGroupings]);

  return (
    <StyledGroupsListing>
      <h3>Grupos cadastrados</h3>

      <DataTable
        count={dataCount}
        page={page}
        setPage={setPage}
        pageSize={pageSize}
        setPageSize={setPageSize}
        searchQuery={searchQuery}
        setSearchQuery={setSearchQuery}
        className="data-table-groups"
        columns={columnsToTable}
        data={dataToTable}
        loading={isFetching || isFecthingDelete}
        onDeleteClicked={handleDeleteGrouping}
        onUpdateClicked={handleUpdateGrouping}
        confirmDialogContentText="Tem certeza deseja excluir esse agrupamento?"
        confirmDialogTitle="Listagem de agrupamentos"
      />
    </StyledGroupsListing>
  );
};
export default GroupsListing;
