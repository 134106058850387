import React from 'react';
import * as R from 'ramda';
import { parse, format } from 'date-fns';

import { useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';

import {
  ExpandLess,
  ExpandMore,
} from '@material-ui/icons';

import createTicket from '../../../../services/tickets/create';
import getValidGoogleAccessTokenFromLocation from '../../../../services/getValidGoogleAccessTokenFromLocation';
import { TRootStateRedux } from '../../../../types/TRootStateRedux';
import createOfferPost from '../../../../services/google/post/createPost';
import putObject from '../../../../services/aws/putObject';
import DragAndDropFiles from '../../../../components/drag-and-drop-file';
import { inputTimeToArrayData } from '../../../../utils/input-time-to-array-data';

import { applyMaskToTime } from '../../../../utils/apply-mask-to-time';

import type {
  TPostFormOfferBaseProps,
  TObjectInAWSS3BucketReturn,
} from '../../../../types/TLocationPost';

import {
  Input,
  InputDatePicker,
  Textarea,
  Time,
  Button,
  Loading,
  InputSwitch,
} from '../../../../components';

import patchOfferPost from '../../../../services/google/post/patchPost';

import { websiteValidate } from '../../../../utils/fields-validate';

import { useAuth, useLocationTickets } from '../../../../hooks';

import APIUpdateTicket from '../../../../services/tickets/update';

import {
  StyledPostFormOffer,
  StyledButton,
  StyledAdditionalDetailsWrapper,
  StyledInputSwitchWrapper,
  StyledHourFieldWrapper,
  StyledAlertMessage,
} from './post-form-offer-styles';

import {
  formatDateObjectToString,
  imagePostValidation,
} from '../../helpers';

import {
  isValidPostStartToEndDate,
  isValidPostStartToEndTime,
  handleFormHasManyImagesOrVideo,
  isVideoFiles,
} from '../helpers';

const PostFormOffer = ({ closeModal, dataToUpdate }: TPostFormOfferBaseProps) => {
  const { enqueueSnackbar } = useSnackbar();

  const [offerTitle, setOfferTitle] = React.useState('');
  const [hasHour, setHasHour] = React.useState(false);
  const [addDetails, setAddDetails] = React.useState(false);
  const [addedButtonType, setAddedButtonType] = React.useState('Nenhum');
  const [addedButtonTypeValue, setAddedButtonTypeValue] = React.useState('');
  const [openingHour, setOpeningHour] = React.useState<string>('00:00');
  const [closingHour, setClosingHour] = React.useState<string>('00:00');
  const [offerDetails, setOfferDetails] = React.useState('');
  const [filesToPost, setFilesToPost] = React.useState<any[]>([]);
  const [isPublish, setIsPublish] = React.useState<React.SetStateAction<boolean>>(false);
  const [startDate, setStartDate] = React.useState<Date | null>(null);
  const [endDate, setEndDate] = React.useState<Date | null>(null);
  const [couponCode, setCouponCode] = React.useState('');
  const [offerLink, setOfferLink] = React.useState('');
  const [termsOfService, setTermsOfService] = React.useState('');
  const [postNameId, setPostNameId] = React.useState<number | null>(null);
  const [googleAccessToken, setGoogleAccessToken] = React.useState<string | null>(null);
  const [googleAccountId, setGoogleAccountId] = React.useState<string | null>(null);
  const [googleLocationId, setGoogleLocationId] = React.useState<string | null>(null);

  const {
    userAccessToken,
    userId,
    userProfile,
    userSetTokenLikeExpired,
  } = useAuth();

  const {
    locationPostTickets,
    locationPostOpenTickets,
  } = useLocationTickets();

  const { activeLocation } = useSelector((state: TRootStateRedux) => state.LocationReducer);

  const getValidGoogleAccessToken = React.useCallback(async () => {
    const getValidGoogleAccessTokenResponse = await getValidGoogleAccessTokenFromLocation({
      accessToken: userAccessToken,
      userId,
      userProfile,
      locationId: activeLocation.id,
    });

    if (R.isNil(getValidGoogleAccessTokenResponse)) return;

    const { googleAccessToken: accessTokenGoogle, connectionInfo } = getValidGoogleAccessTokenResponse;

    setGoogleAccessToken(accessTokenGoogle);
    setGoogleAccountId(connectionInfo.google_account_id);
    setGoogleLocationId(connectionInfo.google_location_id);
  }, []);

  React.useEffect(() => { getValidGoogleAccessToken(); }, [getValidGoogleAccessToken]);

  const getInputDateData = inputTimeToArrayData('/');
  const getInputTimeData = inputTimeToArrayData(':');

  const getObjectInAWSS3Bucket = React.useCallback(
    async (element: any): Promise<TObjectInAWSS3BucketReturn> => {
      if (R.isNil(googleAccountId) || R.isNil(googleLocationId)) return null;

      if (!R.isEmpty(element)) {
        const putObjectData = await putObject({
          accessToken: userAccessToken,
          bucketName: 'hub-saas-media',
          file: element,
          googleAccountId,
          googleLocationId,
          isCreating: setIsPublish,
          mediaType: element.type === 'video/mp4' ? 'VIDEO' : 'PHOTO',
        });

        const mediaFileUrl = await putObjectData.fileUrl;

        return {
          mediaFormat: element.type === 'video/mp4' ? 'VIDEO' : 'PHOTO',
          mediaSourceUrl: mediaFileUrl,
        };
      }

      return null;
    }, [googleAccountId, googleLocationId],
  );

  const handleCreateLocationPostTicket = React.useCallback(async (inputData: any) => {
    const platformsSyncInitialStatus = {
      google: 'IN_PROGRESS',
      facebook: 'IN_PROGRESS',
      foursquare: 'IN_PROGRESS',
      yelp: 'IN_PROGRESS',
      linkedin: 'IN_PROGRESS',
    };

    const createOfferPostTicket = await createTicket({
      accessToken: userAccessToken,
      location_id: activeLocation.id,
      type: 'POST',
      changedData: {
        info_to_update: JSON.stringify(inputData),
        post_id: postNameId,
        new: true,
        status: platformsSyncInitialStatus,
      },
      setTokenLikeExpired: null,
      created_by: userId,
      isSync: setIsPublish,
    });

    if (!R.isNil(createOfferPostTicket)) {
      enqueueSnackbar(
        'Sua postagem está sendo processada e será lançada em breve',
        { variant: 'success' },
      );
    }
  }, [
    userAccessToken,
    activeLocation,
    userId,
    postNameId,
  ]);

  const isInputFieldsValid = () => {
    if (R.isNil(activeLocation)) return false;

    if (R.isEmpty(offerTitle)) {
      enqueueSnackbar('O título não pode ser vazio!', { variant: 'warning' });
      return false;
    }

    if (R.isNil(startDate)) {
      enqueueSnackbar('A data de início não pode ser vazia!', { variant: 'warning' });
      return false;
    }

    if (R.isNil(endDate)) {
      enqueueSnackbar('A data de término não pode ser vazia!', { variant: 'warning' });
      return false;
    }

    if (offerDetails.length > 1500) {
      enqueueSnackbar('Os detalhes da postagem não podem conter mais de 1500 caracteres!', { variant: 'warning' });
      return false;
    }

    if (!R.isEmpty(offerLink) && !websiteValidate(offerLink)) {
      enqueueSnackbar('O link inserido não é válido', { variant: 'warning' });
      return false;
    }

    return true;
  };

  const hasOpenTicket = React.useCallback(async () => {
    if (R.isNil(activeLocation)) return null;

    const thisPostOpenTicket = locationPostOpenTickets.find(ticket => {
      const { post_id } = ticket.data;

      return post_id === postNameId;
    });

    if (!R.isNil(thisPostOpenTicket)) return thisPostOpenTicket;

    return null;
  }, [
    activeLocation,
    locationPostTickets,
    locationPostOpenTickets,
    postNameId,
  ]);

  const handlePublishClicked = React.useCallback(async () => {
    if (!isInputFieldsValid()) return;
    if (R.isNil(googleAccessToken)) {
      enqueueSnackbar(
        'Não foi possível adquirir um token do Google para criar postagem',
        { variant: 'warning' },
      );

      return;
    }

    if (R.isNil(startDate)) return;
    if (R.isNil(endDate)) return;

    const formattedStartDate = format(startDate, 'dd/MM/yyyy');
    const formattedEndDate = format(endDate, 'dd/MM/yyyy');

    const startDateData = getInputDateData(formattedStartDate);
    const [startDay, startMonth, startYear] = startDateData;

    const endDateData = getInputDateData(formattedEndDate);
    const [endDay, endMonth, endYear] = endDateData;

    const openingHourData = hasHour ? getInputTimeData(openingHour) : [0, 0];
    const [openingHours, openingMinutes] = openingHourData;

    const closingHourData = hasHour ? getInputTimeData(closingHour) : [23, 59];
    const [closingHours, closingMinutes] = closingHourData;

    const {
      isValid: isValidEventDate,
      message: messageEventDateFeedBack,
    } = isValidPostStartToEndDate({
      startDateData,
      endDateData,
      hasHour,
    });

    if (!isValidEventDate) {
      enqueueSnackbar(messageEventDateFeedBack, { variant: 'warning' });

      return;
    }

    if (offerDetails.length > 1500) {
      enqueueSnackbar('O texto da postagem deve conter no máximo 1500 caracteres', { variant: 'warning' });
      return;
    }

    const {
      isValid: isValidEventTime,
      message: messageEventTimeFeedBack,
    } = isValidPostStartToEndTime({
      openingHourData,
      closingHourData,
      startDateData,
      endDateData,
      hasHour,
    });

    if (!isValidEventTime) {
      enqueueSnackbar(messageEventTimeFeedBack, { variant: 'warning' });

      return;
    }

    const inputData: any = {
      summary: offerDetails,
      topicType: 'OFFER',
      languageCode: 'pt-BR',
      couponCode,
      redeemOnlineUrl: offerLink,
      termsConditions: termsOfService,
      title: offerTitle,
      schedule: {
        startDate: {
          day: startDay,
          month: startMonth,
          year: startYear,
        },
        endDate: { day: endDay, month: endMonth, year: endYear },
        startTime: {
          hours: openingHours,
          minutes: openingMinutes,
          seconds: 0,
          nanos: 0,
        },
        endTime: {
          hours: closingHours === 0 && openingHours === 0 ? 23 : closingHours,
          minutes: closingHours === 0 && openingHours === 0 ? 59 : closingMinutes,
          seconds: 0,
          nanos: 0,
        },
      },
    };

    setIsPublish(true);

    const mediasUploaded = await Promise.all(filesToPost.map(async (fileToPost) => {
      const putObjectData = await putObject({
        accessToken: userAccessToken,
        bucketName: 'hub-saas-media',
        file: fileToPost,
        googleAccountId,
        googleLocationId,
        mediaType: fileToPost.type === 'video/mp4' ? 'VIDEO' : 'PHOTO',
      });

      const mediaFileUrl = putObjectData.fileUrl;

      return {
        mediaFormat: fileToPost.type === 'video/mp4' ? 'VIDEO' : 'PHOTO',
        sourceUrl: mediaFileUrl,
      };
    }));

    inputData.mediaItems = mediasUploaded;

    if (isVideoFiles(filesToPost) || filesToPost.length > 1) {
      handleCreateLocationPostTicket(inputData);
    } else {
      const createOfferPostResponse = await createOfferPost({
        mediaType: 'url',
        locationId: `accounts%2F${googleAccountId}%2Flocations%2F${googleLocationId}`,
        postType: 'offer',
        inputData,
        accessToken: googleAccessToken,
        setIsPublish,
        feedbackMessage: enqueueSnackbar,
      });

      if (!R.isNil(createOfferPostResponse)) enqueueSnackbar('Postagem publicada com sucesso', { variant: 'success' });
    }

    closeModal();
  }, [
    filesToPost,
    offerTitle,
    startDate,
    endDate,
    openingHour,
    closingHour,
    hasHour,
    addDetails,
    offerDetails,
    couponCode,
    termsOfService,
    offerLink,
    addedButtonType,
    addedButtonTypeValue,
    activeLocation,
  ]);

  const handleUpdateClicked = React.useCallback(async () => {
    if (!isInputFieldsValid()) return;

    if (R.isNil(startDate)) return;
    if (R.isNil(endDate)) return;
    if (R.isNil(googleAccessToken)) return;

    const hasOpenedTicketResponse = await hasOpenTicket();

    const { name: postId, searchUrl } = dataToUpdate;

    const formattedStartDate = format(startDate, 'dd/MM/yyyy');
    const formattedEndDate = format(endDate, 'dd/MM/yyyy');

    const openingHourData = hasHour ? getInputTimeData(applyMaskToTime(openingHour)) : [0, 0];
    const [openingHours, openingMinutes] = openingHourData;

    const closingHourData = hasHour ? getInputTimeData(applyMaskToTime(closingHour)) : [23, 59];
    const [closingHours, closingMinutes] = closingHourData;

    const startDateData = getInputDateData(formattedStartDate);
    const [startDay, startMonth, startYear] = startDateData;

    const endDateData = getInputDateData(formattedEndDate);
    const [endDay, endMonth, endYear] = endDateData;

    const inputData: any = {
      summary: offerDetails,
      url: addedButtonTypeValue || null,
      couponCode,
      redeemOnlineUrl: offerLink || null,
      termsConditions: termsOfService,
      title: offerTitle,
      topicType: 'OFFER',
      schedule: {
        startDate: {
          day: startDay,
          month: startMonth,
          year: startYear,
        },
        endDate: { day: endDay, month: endMonth, year: endYear },
        startTime: {
          hours: openingHours,
          minutes: openingMinutes,
          seconds: 0,
          nanos: 0,
        },
        endTime: {
          hours: closingHours,
          minutes: closingMinutes,
          seconds: 0,
          nanos: 0,
        },
      },
    };

    if (!addDetails) {
      inputData.couponCode = '';
      inputData.summary = '';
      inputData.redeemOnlineUrl = '';
      inputData.termsConditions = '';
    }

    if (!R.isEmpty(filesToPost)) {
      inputData.mediaItems = await Promise.all(filesToPost.map(async (element: any) => {
        if (!R.isNil(element.mediaFormat)) return ({ mediaFormat: element.type === 'video/mp4' ? 'VIDEO' : 'PHOTO', sourceUrl: element.sourceUrl });

        const mediaItems = await getObjectInAWSS3Bucket(element);

        return {
          mediaFormat: 'PHOTO',
          sourceUrl: mediaItems ? mediaItems.mediaSourceUrl : null,
        };
      }));
    }

    const platformsSyncInitialStatus = {
      google: 'IN_PROGRESS',
      facebook: 'IN_PROGRESS',
      foursquare: 'IN_PROGRESS',
      yelp: 'IN_PROGRESS',
      linkedin: 'IN_PROGRESS',
    };

    if (!R.isNil(hasOpenedTicketResponse)) {
      if (isVideoFiles(filesToPost) || filesToPost.length > 1) {
        const updateOfferPostTicket = await APIUpdateTicket({
          accessToken: userAccessToken,
          ticketId: hasOpenedTicketResponse.id,
          dataToUpdate: {
            data: {
              info_to_update: JSON.stringify(inputData),
              post_id: postNameId,
              searchUrl,
              status: platformsSyncInitialStatus,
            },
            status: 'OPEN',
          },
          setTokenLikeExpired: userSetTokenLikeExpired,
          setIsFetching: setIsPublish,
        });

        if (!R.isNil(updateOfferPostTicket)) enqueueSnackbar('Sua postagem está sendo processada e será lançada em breve', { variant: 'success' });
        closeModal();

        return;
      }

      const editOfferPostResponse = await patchOfferPost({
        accessToken: googleAccessToken,
        postType: 'offer',
        postId: postId.replaceAll('/', '%2F'),
        inputData,
        updateMask: 'callToAction.actionType,callToAction.url,summary,event.schedule,photo,media,offer',
        setIsFetching: setIsPublish,
        feedbackMessage: enqueueSnackbar,
      });

      if (R.isNil(editOfferPostResponse)) return;

      const platformsSyncDoneStatus = {
        google: 'DONE',
        facebook: 'DONE',
        foursquare: 'DONE',
        yelp: 'DONE',
        linkedin: 'DONE',
      };

      const updateOfferPostTicket = await APIUpdateTicket({
        accessToken: userAccessToken,
        ticketId: hasOpenedTicketResponse.id,
        dataToUpdate: {
          data: {
            info_to_update: JSON.stringify(inputData),
            post_id: postNameId,
            searchUrl,
            status: platformsSyncDoneStatus,
          },
          status: 'DONE',
          operator_id: userId,
        },
        setTokenLikeExpired: userSetTokenLikeExpired,
        setIsFetching: setIsPublish,
      });

      if (!R.isNil(updateOfferPostTicket)) enqueueSnackbar('Sua postagem foi atualizada com sucesso', { variant: 'success' });
      closeModal();

      return;
    }

    if (isVideoFiles(filesToPost) || filesToPost.length > 1) {
      const createOfferPostTicket = await createTicket({
        accessToken: userAccessToken,
        location_id: activeLocation.id,
        type: 'POST',
        changedData: {
          info_to_update: JSON.stringify(inputData),
          new: true,
          status: platformsSyncInitialStatus,
          post_id: postNameId,
          searchUrl,
        },
        setTokenLikeExpired: null,
        created_by: userId,
        isSync: setIsPublish,
      });

      if (!R.isNil(createOfferPostTicket)) enqueueSnackbar('Sua postagem está sendo processada e será lançada em breve', { variant: 'success' });
    } else {
      const editOfferPostResponse = await patchOfferPost({
        accessToken: googleAccessToken,
        postType: 'offer',
        postId: postId.replaceAll('/', '%2F'),
        inputData,
        updateMask: 'callToAction.actionType,callToAction.url,summary,event.schedule,photo,media,offer',
        setIsFetching: setIsPublish,
        feedbackMessage: enqueueSnackbar,
      });

      if (!R.isNil(editOfferPostResponse)) enqueueSnackbar('Postagem atualizada com sucesso', { variant: 'success' });
    }
    closeModal();
  }, [
    dataToUpdate,
    filesToPost,
    offerTitle,
    startDate,
    endDate,
    openingHour,
    closingHour,
    hasHour,
    addDetails,
    offerDetails,
    couponCode,
    termsOfService,
    offerLink,
    addedButtonType,
    addedButtonTypeValue,
    locationPostTickets,
    locationPostOpenTickets,
    postNameId,
    userAccessToken,
    activeLocation,
    hasOpenTicket,
  ]);

  React.useEffect(() => {
    if (dataToUpdate) {
      const {
        topicType,
        summary = '',
        media = [],
        event: {
          title,
          schedule,
        },
        offer = {},
        name = '',
      } = dataToUpdate;

      const {
        formattedEndDate,
        formattedEndTime = '',
        formattedStartDate,
        formattedStartTime = '',
      } = formatDateObjectToString(schedule);

      if (formattedStartTime && formattedEndTime) {
        if (formattedStartTime === '0000' && formattedEndTime === '2359') setHasHour(false);
        else setHasHour(true);
      }

      setPostNameId(name);
      setOfferTitle(title);
      setStartDate(parse(formattedStartDate, 'dd/MM/yyyy', new Date()));

      if (formattedStartTime) setOpeningHour(formattedStartTime);

      setEndDate(parse(formattedEndDate, 'dd/MM/yyyy', new Date()));

      if (formattedEndTime) setClosingHour(formattedEndTime);

      if (!R.isEmpty(summary)) {
        setAddDetails(true);
        setOfferDetails(summary);
      }

      if (!R.isEmpty(offer)) {
        setAddDetails(true);

        const {
          couponCode: couponCodeResponse = '',
          redeemOnlineUrl = '',
          termsConditions = '',
        } = offer;

        setCouponCode(couponCodeResponse);
        setOfferLink(redeemOnlineUrl);
        setTermsOfService(termsConditions);
      }

      if (media && !R.isEmpty(media)) {
        setFilesToPost(dataToUpdate.media.map((file: any) => ({
          mediaFormat: 'PHOTO',
          sourceUrl: file.googleUrl,
        })));
      }
    }
  }, [dataToUpdate]);

  return (
    <StyledPostFormOffer>
      <DragAndDropFiles
        filesToPost={filesToPost}
        setFilesToPost={setFilesToPost}
        imageFileValidation={imagePostValidation}
        maxVideoSize={70}
      />

      <Input
        value={offerTitle}
        border
        label="Título da Oferta *"
        onChange={(e) => setOfferTitle(e.currentTarget.value)}
      />

      <StyledInputSwitchWrapper>
        <InputSwitch
          name="has-hour"
          label="Adicionar Hora?"
          checked={hasHour}
          changeChecked={() => setHasHour(!hasHour)}
          alignRow
          className="offer-status-hour-toggle"
        />
      </StyledInputSwitchWrapper>

      <StyledHourFieldWrapper hasHour={hasHour}>
        <InputDatePicker
          selectedDate={startDate}
          setSelectedDate={e => setStartDate(e)}
          label="De: *"
          className="input-date-picker"
        />

        {hasHour && (
          <Time
            label="Horário de Inicio"
            timeValue={openingHour}
            setTimeValue={setOpeningHour}
            columnAlign
          />
        )}
      </StyledHourFieldWrapper>

      <StyledHourFieldWrapper hasHour={hasHour} className="last-field">
        <InputDatePicker
          selectedDate={endDate}
          setSelectedDate={e => setEndDate(e)}
          label="Até: *"
          className="input-date-picker"
        />

        {hasHour && (
          <Time
            label="Horário de Término"
            timeValue={closingHour}
            setTimeValue={setClosingHour}
            columnAlign
          />
        )}
      </StyledHourFieldWrapper>

      <StyledButton
        onClick={() => setAddDetails(!addDetails)}
      >
        Adicionar detalhes (opcional)
        {addDetails ? <ExpandLess /> : <ExpandMore />}
      </StyledButton>

      {addDetails && (
        <StyledAdditionalDetailsWrapper>
          <Textarea
            border
            label="Detalhes da Oferta"
            name="offer-details"
            className="offer-details"
            value={offerDetails}
            onChange={setOfferDetails}
          />

          <Input
            label="Código do Cupom (Opcional)"
            border
            value={couponCode}
            onChange={(e) => setCouponCode(e.currentTarget.value)}
          />
          <Input
            label="Link para resgatar a oferta (Opcional)"
            border
            value={offerLink}
            onChange={(e) => setOfferLink(e.currentTarget.value)}
          />
          <Input
            label="Termos e condições (Opcional)"
            border
            value={termsOfService}
            onChange={(e) => setTermsOfService(e.currentTarget.value)}
          />
        </StyledAdditionalDetailsWrapper>
      )}

      {!handleFormHasManyImagesOrVideo(filesToPost) ? (
        <Button
          className="publish-button"
          disabled={Boolean(isPublish)}
          onClick={dataToUpdate ? handleUpdateClicked : handlePublishClicked}
        >
          {isPublish && <Loading className="is-button-loading" />}
          {dataToUpdate ? 'Atualizar' : 'Publicar'}
        </Button>
      ) : (
        <StyledAlertMessage>
          No momento não é possível atualizar uma postagem que contenha videos ou múltiplas imagens
        </StyledAlertMessage>
      )}
    </StyledPostFormOffer>
  );
};

export default PostFormOffer;
