import styled from 'styled-components';

export const StyledSectionTitle = styled.h3`
  font-weight: 700;
  font-size: 1.5rem;
  margin-bottom: 2rem;
  display: flex;
  align-items: center;

  .is-loading-infos {
    margin-right: 1rem;
  }
`;
