import React from 'react';
import * as R from 'ramda';
import filterLocationsOwnerOrGuest from '../../utils/filter-locations-owner-or-guest';

const statusTag = {
  owner: 'Proprietário',
  guest: 'Convidado',
  locationGuest: 'Conv. Local',
};

export const getColumnsToTable = ({ translate, userProfile }) => {
  const customColumns = [
    {
      name: translate.id,
      options: { display: false },
    },
    {
      name: translate.name,
      options: {
        sort: true,
        sortDescFirst: true,
      },
    },
    { name: 'Locais', options: { sort: false } },
    { name: 'Proprietário', options: { sort: false } },
    { name: 'Relatórios', options: { display: 'excluded', sort: false } },
  ];

  const columnStatusToInsert = {
    name: 'Permissão',
    options: {
      customBodyRender: (value) => (
        <span className={`tag ${value}`}>{statusTag[value]}</span>
      ),
    },
  };

  if (userProfile !== 'Usuário Padrão') return customColumns;

  return R.insert(3, columnStatusToInsert, customColumns);
};

export const getColumnValues = ({ company, userProfile }) => locationCount => {
  const columnsList = [
    company.id,
    company.name,
    locationCount,
    company?.main_responsible?.name || '',
    company.data_studio,
  ];

  const columnStatusToInsert = company.permissionStatus;

  if (userProfile !== 'Usuário Padrão') return columnsList;

  return R.insert(3, columnStatusToInsert, columnsList);
};

export const getNormalizedDataTable = ({
  userAccessToken,
  userId,
  userProfile,
  userSetTokenLikeExpired,
}) => async userCompanies => {
  const normalizedDataTable = await Promise.all(userCompanies.map(async company => {
    const normalizedCompanyDataTable = getColumnValues({ company, userProfile });

    const companyLocationDataResponse = await filterLocationsOwnerOrGuest({
      currentCompany: company,
      userId,
      userAccessToken,
      companyLocationsPageSize: 500,
      userSetTokenLikeExpired,
      userProfileName: userProfile,
    })();

    if (R.isNil(companyLocationDataResponse)) return normalizedCompanyDataTable(0);

    const { locationCount } = companyLocationDataResponse;

    return normalizedCompanyDataTable(locationCount);
  }));

  return normalizedDataTable;
};

export const isCompanies = ({
  userCompanies = null,
  allCompaniesFromSystem = null,
  guestCompanies = null,
}) => (userProfile = null) => {
  if (R.isNil(userProfile)) return false;

  const hasRegister = data => !R.isEmpty(data) && !R.isNil(data);

  if (userProfile.name === 'Admin' || userProfile.name === 'Operacional') {
    if (hasRegister(allCompaniesFromSystem) || hasRegister(guestCompanies)
      || hasRegister(userCompanies)) return true;

    return false;
  }

  if (userProfile.name === 'Usuário Padrão') {
    if (hasRegister(userCompanies)) return true;

    return false;
  }
};

export const isGuest = (userCompany) => userCompany.permissionStatus === 'guest';
