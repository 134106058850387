import styled, { css } from 'styled-components';
import { RemoveCircle } from '@material-ui/icons';
import { lighten, shade } from 'polished';

import { Button } from '@material-ui/core';

export const StyledPostFormOffer = styled.div`
  padding: 2rem 2rem 4rem;
  width: 100%;
  background: ${props => props.theme.colors.white};
  border-radius: 0.5rem;
  
  .publish-button {
    margin-left: auto;
    margin-top: 2rem;
  }
  
  .is-button-loading {
    margin-right: 1rem;
  }

  .secondary-input {
    background: ${props => props.theme.page.body};
    padding: 1rem;
    margin-bottom: 1rem;
  }

  .field-end-date {
    margin-bottom: 0;
  }

  .field-end-hour {
    margin-top: 2rem;
  }

  .last-field {
    margin-bottom: 0;
  }
`;

export const StyledButton = styled(Button)`
  margin-top: 2rem;
  font-size: 1.2rem;

  &:focus {
    outline: none !important;
  }
`;

export const StyledAdditionalDetailsWrapper = styled.div`
  background: ${props => props.theme.page.body};
  padding: 2rem;
  margin-top: 2rem;

  .offer-details {
    margin-bottom: 2rem;
    
    textarea {
      min-height: 3rem;
      resize: vertical;
    }
  }
`;

export const StyledInputSwitchWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-bottom: 1rem;
`;

export const StyledHourFieldWrapper = styled.div<{hasHour: boolean}>`
  display: grid;
  grid-template-columns: 1fr;
  margin-bottom: 2rem;
  column-gap: 2rem;

  ${props => props.hasHour && css`
    grid-template-columns: repeat(2, 1fr);
  `};
  
  .drop-down-time {
    top: 6.5rem;
  }

  .input-date-picker {
    width: 100%;
  }
`;

export const StyledRemoveCircle = styled(RemoveCircle)`
  margin-left: 0.5rem;
  cursor: pointer;
`;

export const StyledAlertMessage = styled.p`
  display: flex;
  align-items: center;
  text-align: left;
  padding: 0.5rem 1rem;
  border-radius: 0.25rem;
  background: ${props => lighten(0.4, props.theme.colors.red)};
  border: 0.1rem solid ${props => shade(0.05, props.theme.colors.red)};
  color: ${props => shade(0.2, props.theme.colors.red)};
  margin: 2rem 0;
  display: flex;
  width: 100%;
  font-size: 1.3rem;
`;
