import React from 'react';

import { IconButton } from '../../../../../components';

import { StyledSection } from './infos-section-popover-style';

type TInfosSectionPopoverProps = {
  title: string;
  open: boolean;
  setOpen: any;
}

const InfosSectionPopover = ({
  title,
  open,
  setOpen,
}: TInfosSectionPopoverProps) => (
  <StyledSection>
    <>
      <h2>{title}</h2>

      <IconButton
        label=""
        tooltip=""
        icon={open ? 'KeyboardArrowDown' : 'KeyboardArrowRight'}
        isWhite
        onClick={() => setOpen(!open)}
      />
    </>
  </StyledSection>
);

export { InfosSectionPopover };
