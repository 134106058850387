import React from 'react';
import * as R from 'ramda';

import type { TLocationEntity, TRenderExistingLocation } from '../../../types/TLocation';

import {
  TLocationsToAddBaseProps,
  TDropdownSelectProps,
} from '../../../types/TLocationsToAdd';

import {
  IconButton,
  Input,
  Pagination,
  SelectDropdown,
  OverlayLoading,
} from '../../../components';

import {
  StyledContent,
  StyledListItem,
  StyledFilterWrapper,
  StyledSelectDropdownWrapper,
  StyledLocationListWrapper,
  StyledEmptyList,
} from './locations-to-add-styles';

const LocationsToAdd = ({
  activeLocationGroupId,
  page,
  setPage,
  pageQuantity,
  handleChangeCompany,
  onAddClicked,
  locationsToAdd,
  locationsToAddLoading,
  handleInputSearch,
  setUserCompaniesPageQuery,
  setUserCompaniesPageIndex,
  userCompaniesPageIndex,
  userCompaniesPageQuantity,
  userCompaniesWithPlaceholder,
}: TLocationsToAddBaseProps) => {
  const handleRenderExistingLocation = (location: TLocationEntity): TRenderExistingLocation => {
    const { location_group } = location;

    if (R.isEmpty(location_group)) return { ...location, isDisabled: false };

    const existsInGroup = location_group.find(({ id }: any) => id === activeLocationGroupId);

    if (!R.isNil(existsInGroup)) return { ...location, isDisabled: true };

    return { ...location, isDisabled: false };
  };

  return (
    <StyledContent>
      <h3>Adicionar Locais</h3>
      <StyledFilterWrapper>
        <StyledSelectDropdownWrapper>
          <h5 className="select-dropdown-company-label">Empresas</h5>
          <SelectDropdown
            className="company-input"
            onOptionClicked={(company: any) => handleChangeCompany(company.id)}
            data={userCompaniesWithPlaceholder}
            setQuery={setUserCompaniesPageQuery}
            setPage={setUserCompaniesPageIndex}
            page={userCompaniesPageIndex}
            pageQuantity={userCompaniesPageQuantity}
            searchLabel="Buscar Empresas"
          />
        </StyledSelectDropdownWrapper>

        <Input
          border
          label="Buscar"
          className="input"
          onChange={({ target: { value } }) => { handleInputSearch(value); }}
        />
      </StyledFilterWrapper>

      <StyledLocationListWrapper>
        {locationsToAddLoading && <OverlayLoading textToLoading="Carregando..." />}
        {locationsToAdd.length > 0 ? (
          <ul>
            {locationsToAdd
              .map(handleRenderExistingLocation)
              .map((location: any) => (
                <StyledListItem
                  isDisabled={location.isDisabled}
                >
                  {location.name}

                  {!location.isDisabled && (
                    <IconButton
                      icon="Add"
                      tooltip="Adicionar"
                      onClick={() => onAddClicked(location)}
                    />
                  )}

                  {location.isDisabled && (
                    <IconButton
                      className="exists-button"
                      tooltipVariant="secondary"
                      icon="Check"
                      tooltip="Adicionado"
                    />
                  )}
                </StyledListItem>
              ))}
          </ul>
        ) : (
          <StyledEmptyList>Nenhum resultado a exibir</StyledEmptyList>
        )}
      </StyledLocationListWrapper>

      {locationsToAdd.length > 0 && (
        <Pagination page={page} setPage={setPage} pagesQuantity={pageQuantity} className="" />
      )}
    </StyledContent>
  );
};

export default LocationsToAdd;
