import styled, { css } from 'styled-components';
import { Box, CircularProgress } from '@material-ui/core';

import { TOverviewDataCount } from './types';

export const StyledOverviewDataCount = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

export const StyledContentCircularProgress = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 1.5rem;
`;

export const StyledDataLabel = styled.h3`
  font-size: 1.4rem;
  font-weight: 400;
  line-height: 1.6rem;
`;

export const StyledLabelCount = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: 1.6rem;
  position: absolute;

  .label-text {
    font-weight: 700;
    font-size: 2rem;
    line-height: 2rem;
  }
`;

export const StyledCircularProgressBottom = styled(CircularProgress)`
  color: rgba(0, 0, 0, 0.1);
  z-index: 0;
`;

export const StyledCircularProgressTop = styled(CircularProgress)<
  Pick<TOverviewDataCount, 'themeColor'>
>`
  position: absolute;
  z-index: 1;

  ${(props) => props.themeColor
    && css`
      color: ${props.theme.colors[props.themeColor]};
    `};
`;
