import Axios from 'axios';
import { TBatchGalleryLocation, TForceUpdateBatchGalleryLocations } from '../../../types/TBatchGallery';

export const forceUpdateBatchGalleryLocations = async ({
  batchGalleryLocationsId,
  userAccessToken,
  isUpdating,
}: TForceUpdateBatchGalleryLocations): Promise<{
  status: 'SUCCESS' | 'ERROR';
  data: TBatchGalleryLocation | object;
}> => {
  try {
    if (isUpdating) isUpdating(true);

    const { data } = await Axios.post(
      `${process.env.REACT_APP_SAAS_BATCH_GOOGLE_MODULES}/gallery-batch-locations/manual-update/${batchGalleryLocationsId}`,
      null,
      {
        headers: {
          Authorization: `Bearer ${userAccessToken}`,
        },
      }
    );
    return {
      status: 'SUCCESS',
      data,
    };
  } catch (err: any) {
    return {
      status: 'ERROR',
      data: err.response,
    };
  } finally {
    if (isUpdating) isUpdating(false);
  }
};
