import React from 'react';

import type { TTableBodyCell } from '../../types/TTable';

import { StyledTableBodyCell } from './table-styles';

export const TableBodyCell = ({
  children,
  isCentered = false,
}: TTableBodyCell) => (
  <StyledTableBodyCell isCentered={isCentered}>{children}</StyledTableBodyCell>
);
