import React from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { Creators as AuthActions } from '../../redux/ducks/auth';

import Burguer from '../burguer';

import SimpleLink from './simple-link';
import GroupLinks from './group-links';

import {
  StyledMenuMobile,
  StyledHeader,
  StyledUser,
  StyledName,
  StyledLink,
  StyledLinks,
} from './menu-mobile-styles';

const MenuMobile = ({ menuMobile }) => {
  const reduxDispatch = useDispatch();
  const dispatch = useDispatch();

  const { user: { user } } = useSelector(state => state.AuthReducer);
  const { profiles } = user;
  const { menu: { functions, groups } } = profiles[0];

  const handleExit = () => {
    reduxDispatch(AuthActions.setUser(null));
  };

  return (
    <StyledMenuMobile open={menuMobile.open}>
      <StyledHeader>
        <StyledLink to="/">
          <StyledUser>{user.name[0]}</StyledUser>
          <StyledName className="user-name">{user.name.split(' ')[0].trim()}</StyledName>
        </StyledLink>

        <Burguer status={menuMobile.open} changeStatus={menuMobile.openOrClose} />
      </StyledHeader>

      <StyledLinks>
        {groups.length > 0 && groups.map(group => (
          <GroupLinks
            key={group.id}
            closeMenuMobile={menuMobile.openOrClose}
            title={group.name}
            icon={group.icon}
            links={group.functions}
          />
        ))}

        {functions.length > 0 && functions.map(functionItem => (
          <SimpleLink
            key={functionItem.id}
            closeMenuMobile={menuMobile.openOrClose}
            title={functionItem.friendly_name}
            icon={functionItem.icon}
            url={functionItem.path_to_redirect}
          />
        ))}

        <SimpleLink
          exitToApp={handleExit}
          title="Sair"
          icon="ExitToApp"
        />
      </StyledLinks>
    </StyledMenuMobile>
  );
};

export default MenuMobile;
