import styled from 'styled-components';
import { lighten } from 'polished';

export const StyledTicketActionMobile = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;

    .ticket-action {
        margin-top: 1rem;
        width: 100%;
        padding: 2rem 1rem;
        background: ${props => lighten(0.9, props.theme.colors.black)};
    }

    .button {
        width: 100%;
        margin-top: 1.5rem;
    }
`;
