import React from 'react';
import * as R from 'ramda';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { TRootStateRedux } from '../../types/TRootStateRedux';
import { TLocationEntity } from '../../types/TLocation';

import { addTagOwnerOrGuest } from './helpers';

import getUserLocationGroupsWithGuests from '../../services/location-groups/getUserLocationGroupsWithGuests';

import { Creators as CompanyActions } from '../../redux/ducks/company';
import { Creators as AuthActions } from '../../redux/ducks/auth';
import { Creators as LocationGroupActions } from '../../redux/ducks/location-group';

import deleteLocationGroup from '../../services/location-groups/remove';

import getLocationGroup from '../../services/location-groups/getOne';
import { useAuth } from '..';

const useMyLocationGroups = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const {
    activeCompanyId,
    activeCompany,
  } = useSelector((state: TRootStateRedux) => state.CompanyReducer);
  const { activeLocationGroup } = useSelector((state: TRootStateRedux) => state.LocationGroupReducer);

  const {
    user,
    user: {
      user: loggedUser,
    },
    language,
  } = useSelector((state: TRootStateRedux) => state.AuthReducer);

  const { userId } = useAuth();

  const setTokenLikeExpired = () => {
    dispatch(AuthActions.setUser({ ...user, accessToken: 'expired' }));
  };

  const [searchQuery, setSearchQuery] = React.useState('');
  const [page, setPage] = React.useState(0);
  const [pageSize, setPageSize] = React.useState(10);
  const [dataCount, setDataCount] = React.useState(0);
  const [loading, setLoading] = React.useState(true);
  const [locationGroups, setLocationGroups] = React.useState<TLocationEntity[]>([]);
  const [activeTab, setActiveTab] = React.useState(0);
  const [pageQuantity, setPageQuantity] = React.useState(0);

  const data: any = [];

  for (let i = 0; i < locationGroups.length; i++) {
    data.push([
      locationGroups[i].id,
      locationGroups[i].name,
      addTagOwnerOrGuest({
        locationGroup: locationGroups[i],
        userId,
      }),
    ]);
  }

  const columns = [
    { name: 'Id', options: { display: false, sort: false } },
    { name: 'Nome', options: { sort: false } },
    { name: 'Permissão', options: { sort: false } },
  ];

  const getLocationGroups = React.useCallback(async () => {
    setLoading(true);

    const locationGroupsData = await getUserLocationGroupsWithGuests({
      accessToken: user.accessToken,
      page,
      pageSize,
      query: searchQuery,
      userId,
      setTokenLikeExpired,
    });

    if (R.isNil(locationGroupsData)) return;

    setDataCount(locationGroupsData[1]);
    setPageQuantity(Math.ceil(locationGroupsData[1] / pageSize));
    setLocationGroups(locationGroupsData[0]);

    dispatch(CompanyActions.setCompanyLocationGroups(locationGroups));
    setLoading(false);
  }, [
    page,
    pageSize,
    searchQuery,
    userId,
  ]);

  React.useEffect(() => {
    getLocationGroups();
  }, [getLocationGroups]);

  const onAddLocationGroupClicked = () => {
    dispatch(CompanyActions.setActiveCompany(activeCompany));
    history.push('/locations/create');
  };

  const onDeleteLocationGroupClicked = async (id: number) => {
    const deleteLocationGroupResponse = await deleteLocationGroup({
      accessToken: user.accessToken,
      id,
      setTokenLikeExpired,
    });

    if (R.isNil(deleteLocationGroupResponse)) return;

    getLocationGroups();
  };

  const onUpdateLocationGroupClicked = async (locationId: number) => {
    const locationGroup = await getLocationGroup({
      accessToken: user.accessToken,
      id: locationId,
      setTokenLikeExpired,
    });

    dispatch(LocationGroupActions.setActiveLocationGroup(locationGroup));
    history.push('/location-groups/edit');
  };

  const onLocationGroupLocationsClicked = async (locationId: number) => {
    const locationGroup = await getLocationGroup({
      accessToken: user.accessToken,
      id: locationId,
      setTokenLikeExpired,
    });

    dispatch(LocationGroupActions.setActiveLocationGroup(locationGroup));
    history.push('/location-groups/list-locations');
  };

  const resetSearch = () => {
    setSearchQuery('');
    setPage(0);
    setPageSize(10);
  };

  const handleChangeTab = (event: any, newValue: number) => {
    setActiveTab(newValue);
    resetSearch();
  };

  return {
    activeTab,
    handleChangeTab,
    searchQuery,
    setSearchQuery,
    page,
    setPage,
    pageSize,
    setPageSize,
    pageQuantity,
    dataCount,
    setDataCount,
    loading,
    columns,
    onAddLocationGroupClicked,
    data,
    onUpdateLocationGroupClicked,
    onDeleteLocationGroupClicked,
    onLocationGroupLocationsClicked,
    locationGroups,
    activeCompany: activeCompanyId,
    activeLocationGroup,
  };
};

export default useMyLocationGroups;
