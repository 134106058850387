import React from 'react';
import { useSelector } from 'react-redux';
import { isEmpty, isNil } from 'ramda';
import { format } from 'date-fns';

import {
  updateLocalPosts,
  getLocalPostsByGroupType,
} from '../../services/google-batch-modules';

import {
  EBatchPlatformGroupType,
  TLocalPostsData,
  EBatchPlatformStatus,
  ELocalPostTopicType,
  TLocalPostsEntity,
  TGetActiveGroupTypeResponse,
} from '../../types/TBatchGoogleModules';

import type { TRootStateRedux } from '../../types/TRootStateRedux';

import {
  batchPostType,
  parseDateInput,
  isValidDateInput,
} from './helpers';

import UpdateStatus from './update-status';
import CreatePostDialog from './create-post-dialog';
import FiltersPostHeader from './filters-post-header';

import {
  useAuth,
  useHasAccess,
} from '../../hooks';

import APIGetCompanyLocations from '../../services/locations/getCompanyLocations';
import APIGetLocationGroupLocations from '../../services/locations/getAllGroupLocation';

import {
  StyledBatchUpdatePostsStatus,
  StyledContent,
  StyledInfo,
} from './batch-update-posts-status-styles';
import {
  BatchHeader, NoAccessCard, DestructiveActionDialog,
} from '../../components';

const BatchUpdatePostsStatus = () => {
  const {
    userId,
    userName,
    userEmail,
    userAccessToken,
  } = useAuth();

  const { hasAccess } = useHasAccess();

  const {
    activeCompany,
    activeCompanyId,
    showLocationsByCompany,
  } = useSelector((state: TRootStateRedux) => state.CompanyReducer);

  const {
    activeLocationGroup,
    activeLocationGroupId,
    showLocationsByGroup,
  } = useSelector((state: TRootStateRedux) => state.LocationGroupReducer);

  const [openCreatePostDialog, setOpenCreatePostDialog] = React.useState(false);
  const [postIdToVisualize, setPostIdToVisualize] = React.useState<string | null>(null);
  const [infoToVisualize, setInfoToVisualize] = React.useState<TLocalPostsEntity | null>(null);
  const [postDialogLoading, setPostDialogLoading] = React.useState(false);

  const [ofPeriod, setOfPeriod] = React.useState<Date | null>(null);
  const [toPeriod, setToPeriod] = React.useState<Date | null>(null);
  const [selectedStatusFilter, setSelectedStatusFilter] = React.useState<string[]>([]);
  const [selectedPostTypeFilter, setSelectedPostTypeFilter] = React.useState<string[]>([]);
  const keyWordsInputRef = React.useRef<HTMLInputElement>(null);

  const [localPosts, setLocalPosts] = React.useState<TLocalPostsData[]>([]);
  const [localPostsCount, setLocalPostsCount] = React.useState(0);
  const [localPostsPage, setLocalPostsPage] = React.useState(0);
  const [localPostsPageSize, setLocalPostsPageSize] = React.useState(10);
  const [localPostsQuery, setLocalPostsQuery] = React.useState('');
  const [localPostsIsFetching, setLocalPostsIsFetching] = React.useState(false);
  const [loadingLocalsWithoutLinks,
    setLoadingLocalsWithoutLinks] = React.useState(false);
  const [disabledPostButton, setDisabledPostButton] = React.useState(false);

  const activeGroupType = React.useMemo((): TGetActiveGroupTypeResponse => {
    if (showLocationsByCompany) {
      return {
        type: 'COMPANY' as EBatchPlatformGroupType,
        id: activeCompanyId,
        guests: activeCompany.guests,
        owner_user: activeCompany.owner_user?.id,
      };
    }
    return {
      type: 'LOCATIONS_GROUP' as EBatchPlatformGroupType,
      id: activeLocationGroupId,
      guests: activeLocationGroup?.guests,
      owner_user: activeLocationGroup?.owner_user?.id,
    };
  }, [
    showLocationsByGroup,
    showLocationsByCompany,
    activeLocationGroup,
    activeLocationGroupId,
    activeCompany,
    activeCompanyId,
  ]);
  const [isRemoveModalOpen, setIsRemoveModalOpen] = React.useState(false);
  const [postIdToRemove, setPostIdToRemove] = React.useState<string>('');

  const getLocalPosts = React.useCallback(async () => {
    if (isNil(activeCompanyId) && isNil(activeLocationGroupId)) return;

    let requestBody: any = {
      groupType: activeGroupType.type,
      groupTypeId: activeGroupType.id,
      userAccessToken,
      page: localPostsPage,
      pageSize: localPostsPageSize,
      query: localPostsQuery,
      status: JSON.stringify(selectedStatusFilter),
      postType: JSON.stringify(selectedPostTypeFilter),
    };

    if (isValidDateInput(ofPeriod)) {
      requestBody = {
        ...requestBody,
        ofPeriod: parseDateInput({ input: ofPeriod, dayPart: 'start' }),
      };
    }

    if (isValidDateInput(toPeriod)) {
      requestBody = {
        ...requestBody,
        toPeriod: parseDateInput({ input: toPeriod, dayPart: 'end' }),
      };
    }

    const getLocalPostsResponse = await getLocalPostsByGroupType(requestBody);

    if (isNil(getLocalPostsResponse)) return;

    const [localPostsData, localPostsDataCount] = getLocalPostsResponse;

    setLocalPosts(localPostsData.map(localPostsItem => [
      localPostsItem.id,
      localPostsItem.data_post?.media_url,
      format(new Date(localPostsItem.created_at), 'dd/MM/yyyy'),
      batchPostType[localPostsItem.post_type as ELocalPostTopicType],
      localPostsItem.status as EBatchPlatformStatus,
    ]));
    setLocalPostsCount(localPostsDataCount);
  }, [
    activeGroupType,
    showLocationsByCompany,
    showLocationsByGroup,
    activeCompanyId,
    activeLocationGroupId,
    localPostsPage,
    localPostsPageSize,
    localPostsQuery,
    selectedStatusFilter,
    selectedPostTypeFilter,
    ofPeriod,
    toPeriod,
  ]);

  React.useEffect(() => { getLocalPosts(); }, [getLocalPosts]);

  const handleOpenCreatePostDialog = () => {
    setPostIdToVisualize(null);
    setInfoToVisualize(null);
    setOpenCreatePostDialog(true);
    setLoadingLocalsWithoutLinks(true);
  };

  const onRemovePost = (localPostsId: string) => {
    setPostIdToRemove(localPostsId);
    setIsRemoveModalOpen(true);
  };

  const handleRemoveLocalPosts = async (localPostsId: string) => {
    if (!localPostsId) return;
    setPostIdToRemove('');
    setIsRemoveModalOpen(false);

    const response = await updateLocalPosts({
      userAccessToken,
      isUpdating: setLocalPostsIsFetching,
      localPostsId,
      localPostsStatus: 'REMOVING' as EBatchPlatformStatus,
      user_id: userId,
      user_name: userName,
      user_email: userEmail,
    });

    if (isNil(response)) return;

    getLocalPosts();
  };

  const handleCancelLocalPosts = async (localPostsId: string) => {
    const response = await updateLocalPosts({
      userAccessToken,
      isUpdating: setLocalPostsIsFetching,
      localPostsId,
      localPostsStatus: 'CANCELING' as EBatchPlatformStatus,
      user_id: userId,
      user_name: userName,
      user_email: userEmail,
    });

    if (isNil(response)) return;

    getLocalPosts();
  };

  const handlePostButton = React.useCallback(async () => {
    if (showLocationsByCompany) {
      const getCompanyLocationsResponse = await APIGetCompanyLocations({
        accessToken: userAccessToken,
        companyId: activeCompanyId,
        pageSize: 1,
      });

      if (isNil(getCompanyLocationsResponse)) return;

      const [companyLocationsData] = getCompanyLocationsResponse;

      if (isEmpty(companyLocationsData)) setDisabledPostButton(true);
      if (!isEmpty(companyLocationsData))setDisabledPostButton(false);
    }

    if (showLocationsByGroup) {
      const getGroupLocationsResponse = await APIGetLocationGroupLocations({
        accessToken: userAccessToken,
        locationGroupId: activeLocationGroupId,
        pageSize: 1,
      });

	  const groupLocationsData = getGroupLocationsResponse ? getGroupLocationsResponse[0] : [];

      if (isEmpty(groupLocationsData)) setDisabledPostButton(true);
      if (!isEmpty(groupLocationsData)) setDisabledPostButton(false);
    }
  }, [activeCompanyId, activeLocationGroupId, showLocationsByGroup, showLocationsByCompany]);

  React.useEffect(() => {
    handlePostButton();
  }, [handlePostButton]);

  const handleChangeFilter = (filter: any, setFilter: React.Dispatch<React.SetStateAction<any>>) => {
    setLocalPostsPage(0);
    setFilter(filter);
  };

  return (
    <StyledBatchUpdatePostsStatus>
      <BatchHeader />

      {!hasAccess && (
        <NoAccessCard />
      )}

      {hasAccess && (
        <StyledContent>
          <FiltersPostHeader
            onAddPostClicked={handleOpenCreatePostDialog}
            ofPeriod={ofPeriod}
            setOfPeriod={(filter) => handleChangeFilter(filter, setOfPeriod)}
            toPeriod={toPeriod}
            setToPeriod={(filter) => handleChangeFilter(filter, setToPeriod)}
            selectedStatusFilter={selectedStatusFilter}
            setSelectedStatusFilter={(filter) => handleChangeFilter(filter, setSelectedStatusFilter)}
            selectedPostTypeFilter={selectedPostTypeFilter}
            setSelectedPostTypeFilter={(filter) => handleChangeFilter(filter, setSelectedPostTypeFilter)}
            disabledPostButton={disabledPostButton}
            loadingLocalsWithoutLinks={loadingLocalsWithoutLinks}
          />
          <StyledInfo>
            <UpdateStatus
              data={localPosts}
              setOpenCreatePostDialog={setOpenCreatePostDialog}
              setPostIdToVisualize={setPostIdToVisualize}
              getLocalPosts={getLocalPosts}
              localPostsCount={localPostsCount}
              localPostsPage={localPostsPage}
              setLocalPostsPage={setLocalPostsPage}
              setLocalPostsPageSize={setLocalPostsPageSize}
              localPostsPageSize={localPostsPageSize}
              setLocalPostsQuery={setLocalPostsQuery}
              isFetching={localPostsIsFetching}
              removeLocalPosts={onRemovePost}
              setInfoToVisualize={setInfoToVisualize}
              cancelLocalPosts={handleCancelLocalPosts}
            />
          </StyledInfo>
        </StyledContent>
      )}

      {openCreatePostDialog && (
      <CreatePostDialog
        open={openCreatePostDialog}
        setOpen={setOpenCreatePostDialog}
        postDialogLoading={postDialogLoading}
        postIdToVisualize={postIdToVisualize}
        infoToVisualize={infoToVisualize}
        setInfoToVisualize={setInfoToVisualize}
        getLocalPosts={getLocalPosts}
        loadingLocalsWithoutLinks={loadingLocalsWithoutLinks}
        setLoadingLocalsWithoutLinks={setLoadingLocalsWithoutLinks}
      />
      )}

      <DestructiveActionDialog
        open={isRemoveModalOpen}
        onClose={() => setIsRemoveModalOpen(false)}
        onConfirm={() => handleRemoveLocalPosts(postIdToRemove)}
        warningMessage={[
          'Você está prestes a excluir o post para todos os locais afetados.',
          'Deseja continuar?',
        ]}
        confirmButtonLabel="SIM, DESEJO CONTINUAR"
        cancelButtonLabel="CANCELAR"
      />
    </StyledBatchUpdatePostsStatus>
  );
};

export default BatchUpdatePostsStatus;
