import React, { useState } from 'react';
import * as R from 'ramda';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory, Redirect } from 'react-router-dom';
import { Button } from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';

import { Creators as AuthActions } from '../../redux/ducks/auth';

import {
  DataTable,
  Can,
} from '../../components';

import getUserName from '../../services/users/getUserName';
import getUsers from '../../services/users/getUsers';
import removeUser from '../../services/users/removeUser';

import { isEmpty } from 'lodash';
import { format } from 'date-fns';

const UserList = () => {
  const reduxDispatch = useDispatch();
  const history = useHistory();

  const [searchQuery, setSearchQuery] = React.useState('');
  const [page, setPage] = React.useState(0);
  const [pageSize, setPageSize] = React.useState(10);
  const [dataCount, setDataCount] = React.useState(0);
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(false);
  const { user } = useSelector((state) => state.AuthReducer);

  const setTokenLikeExpired = () => {
    reduxDispatch(AuthActions.setUser({ ...user, accessToken: 'expired' }));
  };

  const columns = [
    { name: 'Id', options: { display: false, sort: false } },
    { name: 'Nome', options: { sort: false } },
    { name: 'Email', options: { sort: false } },
    { name: 'Phone', options: { sort: false } },
    { name: 'Data de criação', options: { sort: false } },
    { name: 'Data da última atualização', options: { sort: false } },
    { name: 'Data do último login', options: { sort: false } },
    { name: 'Criado por', options: { display: false, sort: false } },
  ];

  const data = [];

  for (let i = 0; i < users.length; i++) {
    data.push([
      users[i].id,
      users[i].name,
      users[i].email,
      users[i].phone,
      users[i].date_created,
      users[i].date_updated,
      isEmpty(users[i].login_history) ? null : format(
          new Date(users[i].login_history.created_at), 
          'dd/MM/yyyy hh:mm:ss a'
        ),
      getUserName(users, users[i].created_by),
    ]);
  }

  const getListUsers = async () => {
    setLoading(true);
    const usersData = await getUsers(
      {
        accessToken: user.accessToken,
        page,
        pageSize,
        query: searchQuery,
        setTokenLikeExpired,
      },
    );

    if (R.isNil(usersData)) return;

    setUsers(usersData[0]);
    setDataCount(usersData[1]);
    setLoading(false);
  };

  React.useEffect(() => {
    getListUsers();
  }, [page, pageSize, searchQuery]);

  const onAddUserClicked = () => {
    history.push('/users/create');
  };

  const onUpdateUserClicked = (id) => {
    history.push(`/users/update/${id}`);
  };

  const onDeleteUserClicked = async (id) => {
    const deleteUsersResponse = await removeUser(
      { accessToken: user.accessToken, id, setTokenLikeExpired },
    );
    if (deleteUsersResponse) getListUsers();
  };

  return (
    <Can
      perform="Users/List"
      yes={() => (
        <div style={{ padding: '1em' }}>
          <Can
            perform="Users/Create"
            yes={() => (
              <div className="create-function-button">
                <Button
                  variant="contained"
                  startIcon={<FontAwesomeIcon icon={faPlus} />}
                  onClick={onAddUserClicked}
                  style={{
                    marginBottom: '1em',
                    color: 'white',
                    backgroundColor: '#31ab70',
                    fontFamily: 'Poppins',
                    fontWeight: 'bold',
                  }}
                >
                  Criar Usuário
                </Button>
              </div>
            )}
            no={() => <></>}
          />
          <DataTable
            count={dataCount}
            page={page}
            setPage={setPage}
            pageSize={pageSize}
            setPageSize={setPageSize}
            searchQuery={searchQuery}
            setSearchQuery={setSearchQuery}
            title="Usuários"
            columns={columns}
            data={data}
            loading={loading}
            onUpdateClicked={onUpdateUserClicked}
            onDeleteClicked={onDeleteUserClicked}
            addRegisterLabel="Criar Usuário"
            addRegisterLink="/users/create"
          />
        </div>
      )}
      no={() => <Redirect to="/main" />}
    />
  );
};

export default UserList;
