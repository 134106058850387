import React from 'react';
import { useSnackbar } from 'notistack';

import {
  StyledAddLocationAccessLevelProfile,
  StyledFieldsContainer,
  StyledFields,
  StyledButtonsGroup,
  StyledCheckboxGroup,
} from './add-location-access-level-profile-style';

import {
  Input, Textarea, Button, Checkbox, Loading,
} from '../../../components';

import {
  create as createLocationAccessLevelProfile,
  update as updateLocationAccessLevelProfile,
} from '../../../services/locationAccessLevelProfiles';

const AddLocationAccessLevelProfile = ({
  userToken,
  userId,
  dataToEdit,
  setDataToEdit,
  setUpdateTable,
}) => {
  const locationAccessLevelProfileName = React.useRef('');
  const locationAccessLevelProfileDescription = React.useRef('');

  const { enqueueSnackbar } = useSnackbar();

  const [idToEdit, setIdToEdit] = React.useState(null);
  const [infoPermission, setInfoPermission] = React.useState(false);
  const [postPermission, setPostPermission] = React.useState(false);
  const [mediaPermission, setMediaPermission] = React.useState(false);
  const [reviewsPermission, setReviewsPermission] = React.useState(false);
  const [servicePermission, setServicePermission] = React.useState(false);
  const [loading, setLoading] = React.useState(false);

  const [openUpdatedControls, setOpenUpdatedControls] = React.useState(false);

  React.useEffect(() => {
    if (dataToEdit) {
      locationAccessLevelProfileName.current.value = dataToEdit.name || '';
      locationAccessLevelProfileDescription.current.value = dataToEdit.description || '';

      setIdToEdit(dataToEdit.id);

      const rules = JSON.parse(dataToEdit.rules);

      setInfoPermission(rules.infoPermission || false);
      setPostPermission(rules.postPermission || false);
      setMediaPermission(rules.mediaPermission || false);
      setReviewsPermission(rules.reviewsPermission || false);
      setServicePermission(rules.servicePermission || false);
      setOpenUpdatedControls(true);
      setDataToEdit(null);
    }
  }, [dataToEdit]);

  const handleAddProfile = async () => {
    setLoading(true);
    const nameValue = locationAccessLevelProfileName.current.value;
    const descriptionValue = locationAccessLevelProfileDescription.current.value;

    if (nameValue.length < 4) {
      enqueueSnackbar('O nome do perfil de acesso precisa ter no mínimo 4 caracteres', { variant: 'error' });
      setLoading(false);
      return;
    }

    if (descriptionValue.length < 10) {
      enqueueSnackbar('A descrição do perfil de acesso precisa ter no mínimo 10 caracteres', { variant: 'error' });
      setLoading(false);
      return;
    }

    await createLocationAccessLevelProfile(
      {
        accessToken: userToken,
        userId,
        name: nameValue,
        description: descriptionValue,
        rules: JSON.stringify({
          infoPermission,
          postPermission,
          mediaPermission,
          reviewsPermission,
          servicePermission,
        }),
      },
    );

    enqueueSnackbar('Novo perfil criado com sucesso', { variant: 'success' });
    setLoading(false);
    setUpdateTable(true);
  };

  const handleCancel = () => {
    locationAccessLevelProfileName.current.value = null;
    locationAccessLevelProfileDescription.current.value = null;
    setDataToEdit(null);
    setInfoPermission(false);
    setMediaPermission(false);
    setReviewsPermission(false);
    setPostPermission(false);
    setServicePermission(false);
    setOpenUpdatedControls(false);
  };

  const handleUpdate = async () => {
    setLoading(true);
    const nameValue = locationAccessLevelProfileName.current.value;
    const descriptionValue = locationAccessLevelProfileDescription.current.value;

    if (nameValue.length < 4) {
      enqueueSnackbar('O nome do perfil de acesso precisa ter no mínimo 4 caracteres', { variant: 'error' });
      setLoading(false);
      return;
    }

    if (descriptionValue.length < 10) {
      enqueueSnackbar('A descrição do perfil de acesso precisa ter no mínimo 10 caracteres', { variant: 'error' });
      setLoading(false);
      return;
    }

    const response = await updateLocationAccessLevelProfile(
      {
        accessToken: userToken,
        id: idToEdit,
        name: nameValue,
        description: descriptionValue,
        rules: JSON.stringify({
          infoPermission,
          postPermission,
          mediaPermission,
          reviewsPermission,
          servicePermission,
        }),
      },
    );

    if (response?.status === 200) {
      enqueueSnackbar('Perfil de acesso atualizado com sucesso', { variant: 'success' });

      setUpdateTable(true);
    }

    handleCancel();
    setLoading(false);
  };

  return (
    <StyledAddLocationAccessLevelProfile>
      <h3>Adicionar Perfil de acesso</h3>
      <StyledFieldsContainer>
        <StyledFields>
          <Input fieldRef={locationAccessLevelProfileName} label="Nome" border className="field" inputOptions={{ name: 'name' }} />
          <Textarea
            fieldRef={locationAccessLevelProfileDescription}
            label="Descrição"
            className="field"
            onChange={() => {}}
          />
          <h4>Permissões</h4>
          <StyledCheckboxGroup>
            <Checkbox
              label={<span>Informações</span>}
              checked={infoPermission}
              onChange={() => { setInfoPermission(!infoPermission); }}
            />
            <Checkbox
              label={<span>Postagens</span>}
              checked={postPermission}
              onChange={() => { setPostPermission(!postPermission); }}
            />
            <Checkbox
              label={<span>Galeria</span>}
              checked={mediaPermission}
              onChange={() => { setMediaPermission(!mediaPermission); }}
            />
            <Checkbox
              label={<span>Avaliações</span>}
              checked={reviewsPermission}
              onChange={() => { setReviewsPermission(!reviewsPermission); }}
            />
            <Checkbox
              label={<span>Serviços</span>}
              checked={servicePermission}
              onChange={() => { setServicePermission(!servicePermission); }}
            />
          </StyledCheckboxGroup>
          {openUpdatedControls ? (
            <StyledButtonsGroup>
              <Button
                onClick={handleCancel}
              >
                {loading ? <Loading /> : 'Cancelar'}
              </Button>
              <Button
                disabled={loading}
                onClick={handleUpdate}
              >
                {loading ? <Loading /> : 'Atualizar'}
              </Button>
            </StyledButtonsGroup>
          ) : (
            <Button
              disabled={loading}
              onClick={handleAddProfile}
            >
              {loading ? <Loading /> : 'Adicionar'}
            </Button>
          )}
        </StyledFields>
      </StyledFieldsContainer>
    </StyledAddLocationAccessLevelProfile>
  );
};

export default AddLocationAccessLevelProfile;
