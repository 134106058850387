import React, { useState } from "react";
import { Dropdown, FormControl } from "react-bootstrap";

const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
  <text
    className="font-poppins"
    ref={ref}
    style={{ cursor: "pointer", fontSize: "1.2em" }}
    onClick={(e) => {
      onClick(e);
    }}
  >
    {children}
    &#x25bc;
  </text>
));

const CustomMenu = React.forwardRef(
  ({ children, style, className, "aria-labelledby": labeledBy }, ref) => {
    const [value, setValue] = useState("");

    return (
      <div
        ref={ref}
        style={style}
        className={className}
        aria-labelledby={labeledBy}
      >
        <FormControl
          autoFocus
          className="mx-3 my-2 w-auto font-poppins"
          placeholder="Pesquisar Local"
          onChange={(e) => setValue(e.target.value)}
          value={value}
        />

        <ul className="list-unstyled">
          {React.Children.toArray(children).filter(
            (child) =>
              !value ||
              child.props.children.toLowerCase().startsWith(value.toLowerCase())
          )}
        </ul>
      </div>
    );
  }
);

export default ({ activeCompany, userCompanies, onChange }) => (
  <Dropdown style={{ marginLeft: "0.3em" }}>
    <Dropdown.Toggle as={CustomToggle}>{activeCompany.name}</Dropdown.Toggle>
    <Dropdown.Menu as={CustomMenu}>
      {userCompanies.map((element, key) => (
        <Dropdown.Item
          onClick={() => onChange(element.id)}
          eventKey={element.id}
          className="font-poppins"
        >
          {element.name}
        </Dropdown.Item>
      ))}
    </Dropdown.Menu>
  </Dropdown>
);
