import React from 'react';
import * as R from 'ramda';

import {
  StyledAvatar,
  StyledCheckbox,
  StyledAccordion,
  StyledAccordionSummary,
  StyledExpandMoreIcon,
  StyledUserName,
  StyledAccordionDetails,
  StyledUserInfo,
} from './user-row-styles';

const UserRow = ({
  userId = null,
  userName = 'Nome do usuário',
  userEmail = 'Email do usuário',
  userPhone = '11 11111-1111',
  userCompanyLimit = 0,
  userLocationsLimit = 0,
  userCompanyGroupLimit = 0,
  userLocationGroupLimit = 0,
  selectedUser,
  setSelectedUser,
}) => {
  const [checked, setChecked] = React.useState((selectedUser?.id === userId));
  const [expand, setExpand] = React.useState(false);

  React.useEffect(() => {
    if (selectedUser?.id === userId) {
      setChecked(true);
    } else {
      setChecked(false);
      setExpand(false);
    }
  }, [selectedUser]);

  const handleSelect = () => {
    if (checked) {
      setChecked(false);
      setSelectedUser(null);
      setExpand(false);

      return;
    }

    setSelectedUser({
      id: userId,
      name: userName,
      email: userEmail,
      phone: userPhone,
    });
  };

  return (
    <StyledAccordion
      expanded={expand}
    >
      <StyledAccordionSummary
        expandIcon={<StyledExpandMoreIcon />}
        aria-controls="panel1a-content"
        id={`panel${userId}a-header`}
        onClick={() => setExpand(!expand)}
      >
        <StyledCheckbox
          checked={checked}
          onChange={handleSelect}
        />
        <StyledAvatar alt={userName} />
        <StyledUserName>{userName}</StyledUserName>
      </StyledAccordionSummary>
      <StyledAccordionDetails>
        <StyledUserInfo>
          <span>Email:</span>
          {userEmail || 'Informação não configurada'}
        </StyledUserInfo>
        <StyledUserInfo>
          <span>Limite de empresa:</span>
          {userCompanyLimit || 'Informação não configurada'}
        </StyledUserInfo>
        <StyledUserInfo>
          <span>Limite de grupo de empresas:</span>
          {userCompanyGroupLimit || 'Informação não configurada'}
        </StyledUserInfo>
        <StyledUserInfo>
          <span>Limite de locais:</span>
          {userLocationsLimit || 'Informação não configurada'}
        </StyledUserInfo>
        <StyledUserInfo>
          <span>Limite de grupo de locais:</span>
          {userLocationGroupLimit || 'Informação não configurada'}
        </StyledUserInfo>
      </StyledAccordionDetails>
    </StyledAccordion>
  );
};

export default UserRow;
