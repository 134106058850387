import React from 'react';
import * as R from 'ramda';

import APIGetUsers from '../../services/users/getUsers';

import { useAuth } from '../../hooks';

import UserCard from './user-card';

import {
  StyledChooseUser,
  StyledChooseUserTitle,
  StyledContent,
  StyledDisclaimer,
  StyledUserListing,
} from './choose-user-styles';

const ChooseUser = ({ className }) => {
  const [users, setUsers] = React.useState([]);

  const {
    userAccessToken,
    userSetTokenLikeExpired,
  } = useAuth();

  const getUsers = React.useCallback(async () => {
    const userResponse = await APIGetUsers({
      accessToken: userAccessToken,
      setTokenLikeExpired: userSetTokenLikeExpired,
    });

    if (R.isNil(userResponse)) return;

    const [userListingData] = userResponse;

    setUsers(userListingData);
  }, [userAccessToken, userSetTokenLikeExpired]);

  React.useEffect(() => {
    getUsers();
  }, []);

  return (
    <StyledChooseUser className={className}>
      <StyledChooseUserTitle>Selecione um usuário</StyledChooseUserTitle>
      <StyledContent>
        <StyledDisclaimer>
          Você pode escolher um usuário que será
          {' '}
          <em className="emphasis">dono</em>
          {' '}
          da empresa que será criada.
          {' '}
          Caso não escolha nenhum,
          {' '}
          <em className="emphasis">você será o dono por padrão.</em>
        </StyledDisclaimer>

        <StyledUserListing>
          {!R.isEmpty(users) && users.map(user => (
            <UserCard
              key={user.id}
              userId={user.id}
              userName={user.name}
              userEmail={user.email}
              userCompanyLimit={user.company_limit}
              userLocationsLimit={user.location_limit}
              userCompanyGroupLimit={user.company_group_limit}
              userLocationGroupLimit={user.location_group_limit}
            />
          ))}
        </StyledUserListing>
      </StyledContent>
    </StyledChooseUser>
  );
};

export default ChooseUser;
