import React from 'react';

import ConfigCard from '../../../components/config-card';

import { StyledBatchPlatformsLinkWithLocation } from './batch-platforms-link-with-location-styles';

const HandlerList = ({ navigation }) => (
  <ConfigCard
    title="Vinculação de locais com plataformas em massa"
    onClick={() => navigation.push('/batch-platforms-linked-with-locations')}
  >
    <StyledBatchPlatformsLinkWithLocation>
      Vincular um local com varias plataformas como:
      {' '}
      <b>Facebook, </b>
      <b>Google, </b>
      <b>Foursquare </b>
      {' '}
      e outras.
    </StyledBatchPlatformsLinkWithLocation>
  </ConfigCard>
);

export default HandlerList;
