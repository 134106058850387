import axios from 'axios';

export default async function updateInfo(
  accessToken,
  infoModelId,
  name,
  short_description,
  long_description,
  categories,
  tags,
) {
  const requestOptions = {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  };

  const { data } = await axios.put(
    `${process.env.REACT_APP_INFO_MODEL_API_URL}/${infoModelId}`,
    {
      name,
      short_description,
      long_description,
      categories,
      tags,
    },
    requestOptions,
  );

  return data;
}
