import React from 'react';
import RateReviewOutlinedIcon from '@material-ui/icons/RateReviewOutlined';
import { AlignRow, AlignColumn } from '../../../assets/icons';

import { ButtonV2, Checkbox, Select } from '../../../components';

import { reviewOrderTypes } from '../helpers';

import {
  StyledReviewListing,
  StyledListingHeaderTop,
  StyledListingHeaderBottom,
  StyledSelectOptionData,
  StyledSelectOptionButton,
} from './review-listing-styles';

const ReviewListing = () => {
  const [selected, setSelected] = React.useState('row');

  const test = React.useCallback(async () => {
    const testResponse = null;
  }, []);

  return (
    <StyledReviewListing>
      <StyledListingHeaderTop>
        <ButtonV2
          typeButton="PRIMARY"
          icon={<RateReviewOutlinedIcon />}
          onClick={() => console.log('1')}
        >
          Criar Resposta Automática
        </ButtonV2>

        <Checkbox
          onChange={null}
          label="Selecionar todas"
          labelPlacement="right"
        />
      </StyledListingHeaderTop>

      <StyledListingHeaderBottom>
        <Select
          options={reviewOrderTypes}
          name="Ordenar por"
          className="review-sort"
          lightBorder
          borderRadius
          noMargin
        />

        <StyledSelectOptionData>
          <StyledSelectOptionButton
            selectedOption={selected === 'row'}
            onClick={() => setSelected('row')}
          >
            <AlignRow />
          </StyledSelectOptionButton>

          <StyledSelectOptionButton
            selectedOption={selected === 'column'}
            onClick={() => setSelected('column')}
          >
            <AlignColumn />
          </StyledSelectOptionButton>
        </StyledSelectOptionData>

      </StyledListingHeaderBottom>
    </StyledReviewListing>
  );
};

export default ReviewListing;
