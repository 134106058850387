import React from 'react';
import { isNil, isEmpty, last } from 'ramda';

import { Loading } from '../../../../../components';

import {
  StyledLocationList,
  StyledListing,
  StyledTitle,
  StyledEmptyLocationList,
} from './location-list-styles';
import { TLocationListProps } from './types';
import LocationListItem from './location-list-item';
import FacebookPagination from '../../../../../components/facebook-pagination';

const LocationList = ({
  data,
  facebookAccessToken,
  isAdminOrOwner,
  locationConnectionList,
  setLocationConnectionList,
  userAccessToken,
  userId,
  userSetTokenLikeExpired,
  locationConnectionInfo,
  locationsFrom,
  pageList,
  setPageList,
  locationsList,
  facebookUserEmail
}: TLocationListProps) => {

  const [facebookPaginationPageToken, setFacebookPaginationPageToken] = React.useState('');
  const [facebookPaginationPageIndex, setFacebookPaginationPageIndex] = React.useState(1);
  const [facebookPaginationLoading, setGooglePaginationLoading] = React.useState(false);
  const [loadLocations, setLoadLocations] = React.useState(false);

  React.useEffect(() => {
    setFacebookPaginationPageToken('');
    setFacebookPaginationPageIndex(1);
  }, [locationsFrom]);

  const handleBackPageClicked = React.useCallback(async () => {
    const removeLastPositionFromList = pageList.pop();
    if (isNil(removeLastPositionFromList)) return;

    const lastTokenFromList = last(pageList);
    if (isNil(lastTokenFromList)) return;
  }, [facebookAccessToken, pageList]);

  const handleResetClicked = React.useCallback(async () => {
    setPageList(['']);

  }, [facebookAccessToken, pageList]);

  return (
    <StyledLocationList>
      <StyledTitle>
        Locais da página:
        {' '}
        <em>{locationsFrom.name}</em>
      </StyledTitle>
      {(
        (isNil(locationsList)
          || isEmpty(locationsList))
        && !loadLocations
      ) && (
        <StyledEmptyLocationList>
          Este agrupamento não possui locais com esse nome!
        </StyledEmptyLocationList>
      )}

      {(!isNil(locationsList) && !isEmpty(locationsList) && !loadLocations) && (
        <StyledListing>
          {locationsList.map((facebookLocation: any) => (
            <LocationListItem
              pageId={facebookLocation.id}
              key={facebookLocation.id}
              isAdminOrOwner={isAdminOrOwner}
              locationConnectionList={locationConnectionList}
              userId={userId}
              userAccessToken={userAccessToken}
              userSetTokenLikeExpired={userSetTokenLikeExpired}
              setLocationConnectionList={setLocationConnectionList}
              facebookPage={facebookLocation}
              facebookUserAccessToken={facebookAccessToken}
              facebookUserEmail={facebookUserEmail}
            />
          ))}
        </StyledListing>
      )}

      {loadLocations && <Loading className="load-locations" />}

      {(!loadLocations && !isNil(locationsList) && !isEmpty(locationsList)) && (
        <FacebookPagination
          isLabelButton
          loading={facebookPaginationLoading}
          nextPageToken={facebookPaginationPageToken}
          pageIndex={facebookPaginationPageIndex}
          setPageIndex={setFacebookPaginationPageIndex}
          onNextPageClicked={() => console.log('TODO: Paginacao')}
          onBackPageClicked={handleBackPageClicked}
          onResetClicked={handleResetClicked}
          pageList={pageList}
          setPageList={setPageList}
        />
      )}
    </StyledLocationList>
  );
};

export default LocationList;
