import { shade } from 'polished';
import styled from 'styled-components';

export const StyledSpecialDatesInfo = styled.div`
  display: flex;
  align-items: stretch;
  justify-content: space-around;
  width: 100%;
  min-height: 57px;

  .platformField {
    background-color: ${props => shade(0.1, props.theme.colors.white)};
    border-bottom: 1px solid  ${props => props.theme.colors.white};  
  }

  &:last-of-type {
    .saasField, .platformField, .statusIcon {
      border-bottom: none; 
    }
  }
`;
export const StyledSpecialDatesDays = styled.ul`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  max-width: calc(43% - 1px);
  flex: 1;
  padding: 1rem;
  margin-bottom: 0;
  border-bottom: 1px solid  ${props => shade(0.1, props.theme.colors.white)};  

  p.title {
    font-weight: bold;
  }

  p {
    font-size: 1.25rem;
    margin-bottom: 0;
  }
`;
export const StyledIconWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 14.25%;
  flex: 1;
  min-height: 57px;
  border-bottom: 1px solid  ${props => shade(0.1, props.theme.colors.white)};  
`;
