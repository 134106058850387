import React from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Button } from '@material-ui/core';
import { Card } from 'react-bootstrap';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';

import getCompanyInfoModels from '../../services/companies/getCompanyInfoModels';

import {
  CompanyNavBar,
  CompanyInfoNavBar,
} from '../../components';

const cardButtonStyle = {
  color: 'white',
  fontFamily: 'Poppins',
  fontWeight: 'bold',
  backgroundColor: '#4385F5',
  width: '15em',
};

const CompanyInfoModels = () => {
  const history = useHistory();

  const [infoModels, setInfoModels] = React.useState([]);

  const { user } = useSelector((state) => state.AuthReducer);
  const { activeCompany } = useSelector((state) => state.CompanyReducer);

  React.useEffect(() => {
    const fetchData = async () => {
      const { info_models: infoModelsArray } = await getCompanyInfoModels(user.accessToken, activeCompany.id);
      setInfoModels(infoModelsArray);
    };

    fetchData();
  }, [activeCompany]);

  const onEditClicked = (id) => {
    history.push(`/company/update-info-model/${id}`);
  };

  const onCreateClicked = () => {
    history.push('/company/create-info-model');
  };

  return (
    <div>
      <CompanyNavBar returnUrl="/company/info-models" />
      <CompanyInfoNavBar activeButton={0} />
      <div className="font-poppins" style={{ padding: '1em' }}>
        <text style={{ fontSize: '1.5em', marginLeft: '1em' }}>
          Modelos de Informações
        </text>
        <div className="model-card-div">
          <Card>
            <Card.Body>
              <div className="creation-card-style">
                <AddCircleOutlineIcon
                  style={{ fontSize: '10em', color: 'gray' }}
                />
                <Button
                  style={cardButtonStyle}
                  variant="contained"
                  onClick={onCreateClicked}
                >
                  Criar Modelo
                </Button>
              </div>
            </Card.Body>
          </Card>
          {infoModels.map((element, key) => (
            <Card className="card-spacing" key={key}>
              <Card.Body>
                <Card.Title style={{ fontWeight: '700' }}>
                  {element.name}
                </Card.Title>
                <Card.Text>
                  <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <text style={{ color: 'gray' }}>Categorias:</text>
                    <div>
                      {element.categories.map((element, key) => (
                        <text>{element.name}</text>
                      ))}
                    </div>
                  </div>
                </Card.Text>
                <Card.Text>
                  <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <text style={{ color: 'gray' }}>Etiquetas:</text>
                    <div>
                      {element.tags.map((element, key) => (
                        <text>{`${element.name} ,`}</text>
                      ))}
                    </div>
                  </div>
                </Card.Text>
                <Card.Text>
                  <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <text style={{ color: 'gray' }}>Descrição:</text>
                    <text>{element.long_description}</text>
                  </div>
                </Card.Text>
                <Button
                  style={cardButtonStyle}
                  variant="contained"
                  onClick={() => {
                    onEditClicked(element.id);
                  }}
                >
                  Editar
                </Button>
              </Card.Body>
            </Card>
          ))}
        </div>
      </div>
    </div>
  );
};

export default CompanyInfoModels;
