import axios from 'axios';

export default async function deleteProfile({ accessToken, profileId, setTokenLikeExpired }) {
  try {
    const requestOptions = {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    };

    const { data } = await axios.delete(
      `${process.env.REACT_APP_PROFILES_API_URL}/${profileId}`,
      requestOptions,
    );

    return data;
  } catch (err) {
    if (err.response?.status === 401 && setTokenLikeExpired) return setTokenLikeExpired();

    return err;
  }
}
