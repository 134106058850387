import styled, { css } from 'styled-components';
import { shade } from 'polished';

import { TRoundedButtonBaseProps } from '../../types/TRoundedButton';

export const StyledRoundedButton = styled.span<Pick<TRoundedButtonBaseProps, 'variant' | 'disabled'>>`
  display: flex;
  align-content: center;
  justify-content: center;
  border-radius: 3rem;
  font-size: 1.4rem;
  font-weight: 500;
  color: ${props => props.theme.colors.white};
  padding: 1rem 2rem;
  cursor: pointer;
  transition: 0.5s;
  border: none;
  background: ${(props) => props.theme.colors[props.variant || 'primary']};

  ${props => props.disabled === true && css`
    background: ${shade(0.25, props.theme.colors.white)};
    pointer-events: none;
    
    &:hover {
      transform: none;
    }
  `}
  
  &:hover {
    background: ${props => shade(0.1, props.theme.colors[props.variant || 'primary'])};
    transform: scale(1.1);
  }

  &:focus {
    outline: none;
  }

  .icon-rounded-button {
    fill: ${props => props.theme.colors.white};
    margin-bottom: 0;
    width: 2rem;
    height: 2rem;
    margin-right: 0.5rem;
  }
`;
