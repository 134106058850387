import styled from 'styled-components';

export const StyledRowWrap = styled.div``;

export const StyledActionsWrap = styled.div`
  display: flex;
  align-items: center;
`;

export const StyledServiceNameWrap = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  .warning-icon {
    margin-left: 0.4rem;
  }
`;

export const StyledActionLoadingWrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 3.5rem;
  padding: 0 1rem;
`;
