import styled from 'styled-components';
import { shade } from 'polished';

export const StyledListItemWrapper = styled.div`
  display: flex;
  align-items: stretch;
  justify-content: center;
  flex: 1;
  
  div {
    flex: 1;
    font-size:  1.25rem;
    max-width: calc(43% - 1px);
  }

  div:last-child {
    max-width: 14%;
  }
  
  .platformField {
    background-color: ${props => shade(0.1, props.theme.colors.white)};
    border-bottom: 1px solid  ${props => props.theme.colors.white};  
  }

  &:last-of-type {
    .saasField, .platformField, .statusIcon {
      border-bottom: none; 
    }
  }
`;

export const StyledIconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border-bottom: 1px solid  ${props => shade(0.1, props.theme.colors.white)};  
`;

export const StyledListItem = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  min-height: 57px;
  padding: 1rem;
  border-bottom: 1px solid  ${props => shade(0.1, props.theme.colors.white)};
  
  p.title {
    font-weight: bold;
  }

  p {
    margin-bottom: 0;
    word-wrap: break-word;
  }
`;
